<div class="w-100 h-100"
    style="position: fixed; left: 0; top: 0; bottom: 0; right: 0; background-color: #555; z-index: -1;">
</div>

<div style="padding: 30px;">
    <div class="container">
        <div style="text-align: center;">
            <img class="m-3" src="/assets/images/logo_aura.svg" />
        </div>
        <div class="box">
            <img class="code" [src]="qrLink" alt="Qr code image" 
                style="object-fit: contain;
                    /* min-height: 32rem;  */
                    background-image: url(/assets/images/qr_render_loading.gif); background-position: center; background-size: cover; background-repeat: no-repeat;" />
        </div>

        <div *ngIf="isStatusPayment('payment') || isStatusPayment('watingpayment')" class="alert alert-danger mt-4 box" style="height: fit-content; font-size: 1.5rem; line-height: 120%;">
            <span *ngIf="isStatusPayment('payment')"><i class="bi bi-exclamation-triangle"></i>&nbsp; Bạn đã mua khóa học này rồi!</span>
            <span *ngIf="isStatusPayment('watingpayment')"><i class="bi bi-exclamation-triangle"></i>&nbsp; Thông tin của bạn đã được ghi nhận. Vui lòng chờ nhân viên Aura đang kiểm tra và đăng ký khóa học cho bạn!</span>
        </div>

        <div class="mt-4 box border-0" style="font-size: 1.5em; line-height: 120%; background-color: #fff0; color: #fff;">
            <b>VIETCOMBANK</b>
            <ul>
                <li class="mt-2">
                    <b>Tên Tài khoản: </b>DAU MINH NHAT
                </li>
                <li class="mt-2">
                    <b>Số tài khoản: </b>0071 0034 56147
                </li>
                <li class="mt-2">
                    <b>Tại Ngân hàng: </b>Vietcombank
                </li>
                <li class="mt-2">
                    <b>Số tiền: </b>{{price}}
                </li>
                <li class="mt-2">
                    <div style="color: #f66;">
                        <b>Nội dung chuyển khoản:</b>
                        <br/>
                        &lt;HọVàTên&gt; &lt;SốĐiệnThoại&gt; FitiOnline
                        <br />
                        Vd: {{egContent}}
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col px-3 py-2">
                <button (click)="back()" class="btn btn-secondary w-100">Trở về</button>
            </div>
            <div class="col px-3 py-2">
                <button (click)="success()" class="btn btn-primary w-100">Đã chuyển xong</button>
            </div>
        </div>
    </div>
</div>