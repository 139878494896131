<div class="card position-relative" style="max-width: 40rem; min-width: 40vw; max-height: 100%;">
    <h2 class="card-header mb-0 position-absolute bg-white" style="left: 0; top: 0; right: 0; z-index: 4;">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="text-info">
                <i class="bi bi-cart-check"></i>&nbsp;
                Cart Info 
            </div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body position-relative" style="overflow-y: auto; max-height: 100vh;">
        <div class="my-5"></div>
        <div class="my-5"></div>
        <div class="my-5"></div>
        @if (!cartInfo) {
            <div class="w-100 h-100 text-center">
                <img class="w-100 p-5 border border-1 border-info bg-info rounded" style="box-sizing: border-box;" src="../../assets/images/gif-load.gif"/>
            </div>
        }
        <div *ngIf="cartInfo && !cartInfo.details.length">
            <h3 class="text-info text-center m-3">Your cart is currently empty, please add items to proceed with your purchase.</h3>
        </div>
        <div *ngIf="cartInfo && cartInfo.details.length" class="row h-100 w-100 m-0 p-0" [style]="{opacity: isLoading ? 0.5 : 1, transition: '300ms'}">
            <h2 class="text-info mb-2"><i class="bi bi-box-seam"></i>&nbsp;Productions <span *ngIf="isLoading">&nbsp;<i class="bi bi-hourglass-split"></i>&nbsp;</span></h2>
            <div class="col-12">
                @for (detail of cartInfo.details; track $index) {
                    <div class="card p-0 mb-3">
                        <div class="card-body">
                            <div class="row m-0 p-0">
                                <div class="col-2 p-0 pe-1">
                                    <img (click)="openProductDetail(detail)" role="button" class="w-100 border border-1 rounded img-fluid" [src]="detail.product_thumb"/> 
                                </div>
                                <div class="col p-0 ps-1">
                                    <div class="row">
                                        <div (click)="openProductDetail(detail)" role="button">
                                            <h2 class="col-12 m-1 mt-0" [innerText]="detail.product_name"></h2>
                                        </div>
                                        <div class="col">
                                            <div class="fs-5 text-primary mb-2"><i class="bi bi-tag fw-bold"></i>&nbsp;Unit:&nbsp;{{detail.original_price_formatted}}</div>
                                            <div class="fs-5 text-primary"><i class="bi bi-tags fw-bold"></i>&nbsp;Total:&nbsp;<b>{{detail.total_formatted}}</b></div>
                                        </div>
                                        <div class="col">
                                            <div class="input-group w-100">
                                                <button (click)="removeDetail(detail)" class="btn btn-danger me-1"><b class="bi bi-trash"></b></button>
                                                <button (click)="subtractDetail(detail)" class="btn btn-outline-info"><b class="bi bi-dash-lg"></b></button>
                                                <input type="number" class="border border-info form-control fw-bold" style="text-align: center; color: #0d6efd;" OnlyNumber="true" [(ngModel)]="detail.quantity" [(value)]="detail.quantity" (input)="submitInputDetail(detail)"/>
                                                <button (click)="addDetail(detail)" class="btn btn-outline-info"><b class="bi bi-plus-lg"></b></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div class="col-12 mb-3">
                <h2 class="text-info mb-2"><i class="bi bi-geo-alt"></i>&nbsp;Shipping Information <span *ngIf="isLoading">&nbsp;<i class="bi bi-hourglass-split"></i>&nbsp;</span></h2>
                <button (click)="updateAddress()" class="btn btn-info w-100">Update Shipping Information</button>
            </div>

            <div class="col-12 mb-3">
                <h2 class="text-info mb-2"><i class="bi bi-truck"></i>&nbsp;Shipping Methods <span *ngIf="isLoading">&nbsp;<i class="bi bi-hourglass-split"></i>&nbsp;</span></h2>
                <button class="btn btn-info w-100">Update Shipping Information</button>
            </div>

            <div class="col-12 mb-3">
                <h2 class="text-info mb-2"><i class="bi bi-wallet2"></i>&nbsp;Payment Methods <span *ngIf="isLoading">&nbsp;<i class="bi bi-hourglass-split"></i>&nbsp;</span></h2>
                <button class="btn btn-info w-100">Update Payment Method</button>
            </div>

            <div class="col-12 mt-2 mb-4">
                <hr class="col-12 m-0 p-0"/>
            </div>

            <div class="col-8">
                <table class="w-100">
                    @for (total of cartInfo.totals; track $index) {
                        <tr class="fs-5 text-primary {{total.code == 'total' ? 'fw-bold' : ''}}">
                            <td class="pb-2" [innerText]="total.title"></td> 
                            <td class="pb-2" [innerText]="total.text"></td> 
                        </tr>
                    }
                </table>
            </div>
            <div class="col-4 align-bottom mb-2">
                <button (click)="confirmOrder()" [disabled]="isLoading" class="w-100 h-100 btn btn-primary">
                    Confirm Order
                </button>
            </div>
        </div>
    </div>
</div>