import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CdsServices } from '../../logics/cds-services';
import { AuraService } from '../../../services/aura_services';

declare var localStorage: any;

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  providers: [CdsServices, AuraService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  constructor(
    private auraService: AuraService,
    private router: Router,
  ) {}

  public isSignIn = false;

  public async ngAfterViewInit() {
    if (await this.auraService.isLogin()) {
      this.isSignIn = true;
    }
    else {
      this.isSignIn = false;
    }
  }

  public logout() {
    localStorage.clear();
    this.ngAfterViewInit();
    this.router.navigate(['/']);
  }
    
}
