{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5s~qxubbxuofj[j[ayj[WBWBWBfQayR%ofaxj[juWBayjGWBoza{j[WBfjoffRj[j[ofj[j[oe",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}