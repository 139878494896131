{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5;~WWBayofj[fQj[ayayj[fQayayayazfkj[j[fQj[WBj[j[bHj[ayj[ayoej[jsayj[j[fQj[",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}