{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5=~pWBj[ofayf5j[ayayayaej[fRj[WVf6j[f7j[afaykCofjtayf6bHWBfRayj[j@fQf6fQfj",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}