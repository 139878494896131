<div class="card position-relative" style="max-width: 40rem; min-width: 40vw;">
    <h2 class="card-header mb-0 position-absolute bg-white" style="left: 0; top: 0; right: 0; z-index: 3;">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="text-info">
                <i class="bi bi-box-seam"></i>&nbsp;
                Product Details
            </div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body" style="overflow-y: auto; max-height: 100vh;">
    <div class="my-5"></div>
    <div class="my-5"></div>
    <div class="my-5"></div>
        <div *ngIf="!productInfo" class="w-100 h-100 text-center">
            <img class="w-100 p-5 border border-5 border-info bg-info rounded" style="box-sizing: border-box;" src="../../assets/images/gif-load.gif"/>
        </div>
        <div *ngIf="productInfo" class="row h-100" [style]="{opacity: isLoading ? 0.5 : 1, transition: '300ms'}" >
            <div *ngIf="productInfo?.gallery_images?.length" class="col col-12 p-3">
                <div class="img-thumbnail">
                    <ngb-carousel
                        #carousel
                        [interval]="3000">
                        @for (img of productInfo?.gallery_images; track img; let i = $index) {
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper rounded">
                                    <img class="w-100 rounded" [src]="img?.url" alt="{{img?.gallery_img_alt}}" />
                                </div>
                            </ng-template>
                        }
                    </ngb-carousel>
                </div>
            </div>
            <div class="col col-12 mb-2">
                <h1 class="text-info m-0 p-0 fw-bold">{{productInfo?.name}}</h1>
            </div>
            <div class="col col-12 mb-3">
                <span id="price" class="fs-5 text-primary "><i class="bi bi-tag fw-bold"></i>&nbsp;{{productInfo?.price_formatted}}</span>
            </div>
            
            <div class="col-sm-12 col-md-4 mb-3">
                <div class="input-group w-100">
                    <button (click)="subtractProduct()" class="col btn btn-outline-info"><b class="bi bi-dash-lg"></b></button>
                    <input type="number" class="col border border-info form-control fw-bold" style="text-align: center; color: #0d6efd;" [formControl]="inputQtyProduct"/>
                    <button (click)="addProduct()" class="col btn btn-outline-info"><b class="bi bi-plus-lg"></b></button>
                </div>
            </div>
            <div class="col-6 col-md-4 mb-3">
                <button (click)="addToCart()" class="btn btn-warning w-100">Add cart&nbsp;<i class="bi bi-cart-plus fw-bold"></i></button>
            </div>
            <div class="col-6 col-md-4 mb-3">
                <button (click)="buyNow()" class="btn btn-success w-100">Buy now&nbsp;<i class="bi bi-cart-check fw-bold"></i></button>
            </div>


            <div class="col col-12 mb-3">
                <a href="tel:425-922-9200" class="btn btn-info w-100">
                    <i class="bi bi-telephone-outbound"></i>&nbsp;
                    Hotline: <b>425-922-9200</b>
                </a>
            </div>

            <hr/>
            
            <div class="col col-12 mb-3">
                <div class="card w-100">
                    <div class="card-body">
                        <h2 class="m-0 p-0 fw-bold">
                            <i class="bi bi-info-circle"></i>&nbsp;Description
                        </h2>
                        <div class="w-100 p-2">
                            <div class="w-100 product-description">
                                <div class="w-100" [innerHTML]="productInfo?.short_description" ></div>
                            </div>
                            <hr/>
                            <div class="w-100 product-description">
                                <div class="w-100" [innerHTML]="productInfo?.description" ></div></div>
                            </div> 
                        </div>
                  </div>
            </div>
        </div>
    </div>
</div>