<div class="card">
    <h2 class="card-header mb-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div>Export config</div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body">
        <div class="row">
            <div class="col col-12">
                <a [href]="linkConfigFile" download="megaverse-config.json"><h3 class="mb-2">Click here if the download does not start automatically!</h3></a>
            </div>
        </div>
    </div>
</div>