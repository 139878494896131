<div class="cursor" [style.opacity]="isShowCusor ? 1 : 0" [style.width]="rotateRatio +'px'" [style.height]="rotateRatio+'px'" [style.top]="top" [style.left]="left" [style.rotate]="'1 0 0 ' + (rotateX || 45) + 'deg'" [ngClass]="{'expand':expand}"></div>

<div
    id="panorama"
    (contextmenu)="onrightClick($event)"
    (mousedown)="clickPanoramaCache = [$event.clientX, $event.clientY]"
    (mouseup)="clickPanorama($event)">
</div>


<audio id="theme-music" [src]="musicLink" autoplay loop>
    <embed [src]="musicLink" width="180" height="90" hidden="true" autoplay="true" loop="true"/>
</audio>
<div id="controls">
    <a (click)="showProfile()" role="button" class="pnlm-controls pnlm-control pnlm-control-custom" id="account"></a>
    <div class="pnlm-controls pnlm-control pnlm-control-custom" style="opacity: 0; pointer-events: none;"></div>
    <!-- <div (click)="openCart()" class="pnlm-controls pnlm-control pnlm-control-custom" id="cart"></div> -->
    <div (click)="openAuraBook()" class="pnlm-controls pnlm-control pnlm-control-custom" id="book"></div>
    <div (click)="toggleMusic()" class="pnlm-controls pnlm-control pnlm-control-custom" [id]="isPlayThemeMusic ? 'play-music' : 'stop-music'"></div>
</div>

<div *ngIf="isDev" class="position-fixed top-50 bottom-50 start-50 end-50">
    <img width="25rem" src="/assets/images/mouse-arrow.svg"/>
</div>
<div class="position-fixed" *ngIf="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
    <div class="card" style="min-width: 25vw; max-width: 75vw;">
        <h2 class="card-header mb-0">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div>{{panoViewer.getScene()}} | {{panoViewer.getHfov()}}<br/>{{panoViewer.getYaw()}}, {{panoViewer.getPitch()}}</div>
                <button class="btn btn-secondary close" type="button" (click)="disableContextMenu()"><b>&times;</b></button>
            </div>
        </h2>
        <div class="card-body">
            <div class="row">
                <div class="col col-6 mb-3">
                    <button (click)="sceneConfig()" class="btn btn-primary w-100">Add Scene</button>
                </div>
                <div class="col col-6 mb-3">
                    <button (click)="hotSpotConfig()" class="btn btn-primary w-100">Add HotSpot</button>
                </div>
                <div class="col col-12 mb-3">
                    <button (click)="removeHotSpot()" class="btn btn-danger w-100">Remove HotSpot</button>
                </div>
                <div class="col col-12 m-0 p-0 mb-3">
                    <hr class="p-0 m-0"/>
                </div>
                <div class="col col-6 mb-1">
                    <button (click)="exportConfig()" class="btn btn-primary w-100">Export Config</button>
                </div>
                <div class="col col-6 mb-1">
                    <button (click)="importConfig()" class="btn btn-primary w-100">Import Config</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div [hidden]="(animationFullscreenName || '') == ''" class="position-fixed top-0 bottom-0 start-0 end-0" style="z-index: 3;">
    <!-- <canvas 
        id="rive-canvas"
        [riv]="animationFullscreenName"
        [width]="1080"
        [height]="1080"
        fit="cover"
        alignment="center"
        style="transition-duration: 2000ms;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: #fff0;" >
        <riv-animation name="show" [play]="(animationFullscreenName || '') != ''"></riv-animation>
    </canvas> -->

    <div *ngIf="(animationFullscreenName || '') == 'aura-lms_info-right'" id="full-screen-html" style="transition-duration: 2000ms; width: 100%; height: 100%;">
        <app-info-lms-paid (onBack)="skipFullscreenAnimation()"></app-info-lms-paid>
    </div>
    <div *ngIf="(animationFullscreenName || '') == 'aura-lms_trailer'" id="full-screen-html" style="transition-duration: 2000ms; width: 100%; height: 100%;">
        <app-info-lms-trailer (onBack)="skipFullscreenAnimation()" (showPayment)="showPayment()" (showSign)="showSign()"></app-info-lms-trailer>
    </div>

    <h1 style="position: absolute; left: 24px; top: 24px; text-align: center;">
        <button (click)="skipFullscreenAnimation()" class="btn btn-secondary">
            <div class="px-3">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>Trở lại
            </div>
        </button>
    </h1>

</div>


<div *ngIf="isShowFullscreenVideo" class="position-fixed top-0 bottom-0 start-0 end-0" style="z-index: 3;">
    <video
        id="fullscreen-video"
        [style]="{opacity: fullscreenVideoOpacity}"
        [controls]="true"
        controlsList="nodownload"
        playsinline
        type="video/mp4"
        style="transition-duration: 2000ms;
            width: 100%; 
            height: 100%;
            object-fit: cover;
            background-color: #222;
            background-image: url(/assets/images/basic-loading.gif);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 52px 52px;" >
    </video>
    <h1 style="position: absolute;  left: 24px; top: 24px; text-align: center;">
        <button (click)="skipFullscreenVideo()" class="btn btn-secondary"><div class="px-3"><i class="fa fa-chevron-left" aria-hidden="true"></i>Trở lại</div></button>
    </h1>
</div>

<div *ngIf="isShowIntro" class="position-fixed top-0 bottom-0 start-0 end-0" style="z-index: 3;">
    <video 
        (click)="playIntroVideo()"
        (ended)="skipIntroVideo()"
        id="intro-video"
        style="width: 100%; height: 100%; object-fit: cover; background-color: #2225; background-image: url(/assets/images/click_effect.gif); background-position: center; background-repeat: no-repeat; background-size: 64px 64px;" >
    </video>
    <h1 style="position: absolute; bottom: 5vh; left: 0; right: 0; text-align: center;">
        <button (click)="skipIntroVideo()" class="btn btn-secondary"><div class="px-3">Bỏ qua giới thiệu</div></button>
    </h1>
</div>
