import { Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { HeaderComponent } from '../world/header/header.component';
import { AuraService } from '../../services/aura_services';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [HeaderComponent, ToastModule, CommonModule, ReactiveFormsModule, FormsModule,],
  providers: [AuraService, MessageService],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  constructor(
    private messageService: MessageService,
    private auraService: AuraService,
    private router: Router,
  ){}
  public formLogin = {
    inputName: '',
    inputPhone: '',
    inputEmail: '',
  };

  public isLoading: boolean = false;
  public validInputName: string | undefined = undefined;
  public validInputPhone: string | undefined = undefined;
  public validInputEmail: string | undefined = undefined;
  
  public async ngOnInit() {
    try {
      let uphonermm = localStorage.getItem('uphone_rmm') || localStorage.getItem('cache_uphone')
      this.formLogin.inputPhone = uphonermm || '';
      localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);

      const profile = (await this.auraService.profile()).data;
      this.formLogin.inputName = profile.full_name;
      this.formLogin.inputPhone = profile.username;
      this.formLogin.inputEmail = profile.email;
    }
    catch {
      this.router.navigate(['login']);
    }
  }

  public checkValidName(value: Event | undefined) {
    if (this.formLogin.inputName.trim() == '') {
      this.validInputName = 'Vui lòng nhập họ và tên!';
    }
    else {
      this.validInputName = '';
    }
  }
  public checkValidPhone(value: Event | undefined) {
    if (this.formLogin.inputPhone.trim() == '') {
      this.validInputPhone = 'Vui lòng nhập số điện thoại!';
    }
    else if (!RegExp('^[0-9\-\+]{9,15}$').test(this.formLogin.inputPhone)) {
      this.validInputPhone = 'Số điện thoại không đúng định dạng!';
    }
    else {
      this.validInputPhone = '';
    }
    localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
  }
  public checkValidEmail(value: Event | undefined) {
    if (this.formLogin.inputEmail.trim() == '') {
      this.validInputEmail = 'Vui lòng nhập địa chỉ email!';
    }
    else if (!RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(this.formLogin.inputEmail)) {
      this.validInputEmail = 'Địa chỉ email không đúng định dạng!';
    }
    else {
      this.validInputEmail = '';
    }
  }
  public validAll() {
    this.formLogin.inputPhone ||= '';
    this.formLogin.inputName ||= '';
    this.formLogin.inputName ||= '';
    this.checkValidName(undefined);
    this.checkValidPhone(undefined);
    this.checkValidEmail(undefined);
    return !(this.validInputPhone || this.validInputEmail || this.validInputName)
  }

  public async btnLogin() {
    this.router.navigate(['/']);
  }
}
