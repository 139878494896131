{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5R~qD*j[WBWBM|j?j[WBofayj[j[j[j[offQj@j[aya|RjWBWBt7WVj[t7fQofj[WBj[oej[j[",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}