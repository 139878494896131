import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { DialogProductDetailComponent } from '../dialog-product-detail/dialog-product-detail.component';
import { DialogUpdateAddressComponent } from './dialog-update-address/dialog-update-address.component';

export interface DialogCartData { }

@Component({
  selector: 'app-dialog-cart',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule, NgbCarouselModule],
  templateUrl: './dialog-cart.component.html',
  styleUrl: './dialog-cart.component.css'
})
export class DialogCartComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogCartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCartData,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {
    this.getCart();
  }
  private domain: string = 'https://api-demo.dsp.one';
  private headerRequrest: any = { Authorization: 'Bearer 226b116857c2788c685c66bf601222b56bdc3751b4f44b944361e84b2b1f002b' };

  public vrCart?: SafeUrl;
  public cartInfo: any;

  public inputQtyProduct: FormControl = new FormControl();
  public isLoading: number = 0;

  public addDetail(detail: any) {
    let num: number = parseInt(detail.quantity);
    if (!num) {
      num = 1;
    }
    num = Math.floor(num);
    detail.quantity = num + 1;
    this.submitInputDetail(detail);
  }

  public subtractDetail(detail: any) {
    let num: number = parseInt(detail.quantity);
    if (!num) {
      num = 1;
    }
    if (num - 1 <= 0) {
      return;
    }
    num = Math.floor(num);
    detail.quantity = num - 1;
    this.submitInputDetail(detail);
  }

  public async submitInputDetail(detail: any) {
    let num: number = parseInt(detail.quantity);
    if (!num) {
      num = 1;
    }
    num = Math.floor(num);
    detail.quantity = num;
    const cartSesson: string = await this.getSession();
    const putUpdateDetailQuantity = this.httpClient.put(
      `${this.domain}/v1/client/update-product-in-cart/${detail.id}`,
      { "product_id": detail.id, "quantity": num, "session_id": cartSesson },
      { headers: this.headerRequrest },
    );
    try {
      this.isLoading++;
      await lastValueFrom<any>(putUpdateDetailQuantity);
      await this.updateCartInfo();
    } finally {
      await this.getCart();
      this.isLoading--;
    }
  }  
  
  public async removeDetail(detail: any) {
    const cartSesson: string = await this.getSession();
    const deleteDetail = this.httpClient.request(
      'DELETE',
      `${this.domain}/v1/client/remove-product-in-cart/${detail.id}`,
      { 
        headers: this.headerRequrest,
        body: { "session_id": cartSesson },
      },
    );
    try {
      this.isLoading++;
      await lastValueFrom<any>(deleteDetail);
      await this.updateCartInfo();
    } finally {
      await this.getCart();
      this.isLoading--;
    }
  }

  private async getSession(): Promise<string> {
    let cartSesson: string | null = localStorage.getItem('cart-session');
    if (!cartSesson) {
      const getSessionApi = this.httpClient.get(
        `${this.domain}/v1/client/session`,
        { headers: this.headerRequrest }
      );
      cartSesson = (await lastValueFrom<any>(getSessionApi))?.data?.session_id;
    }
    localStorage.setItem('cart-session', cartSesson ?? '');
    return cartSesson ?? '';
  }

  private async getCart() {
    try {
      this.isLoading++;
      const cartSesson: string = await this.getSession();
      const getCartApi = this.httpClient.get(
        `${this.domain}/v1/client/cart?session_id=${cartSesson}`,
        { headers: this.headerRequrest }
      );
      this.cartInfo = (await lastValueFrom<any>(getCartApi))?.data ?? {details:[]};
    } finally {
      this.isLoading--;
    }
  }

  private async updateCartInfo() {
    try {
      this.isLoading++;
      const cartSesson: string = await this.getSession();
      const getCartApi = this.httpClient.put(
        `${this.domain}/v1/client/update-cart-info`,
        this.cartInfo,
        { headers: this.headerRequrest }
      );
      await lastValueFrom<any>(getCartApi);
    } finally {
      this.isLoading--;
    }
  }

  public async confirmOrder() {
    try {
      this.isLoading++;
      await this.updateCartInfo();
      await this.getCart();
    } finally {
      this.isLoading--;
    }
  }

  public openProductDetail(detail: any) {
    this.dialog.open(DialogProductDetailComponent, {
      data: {
        args: {
          productSlug: detail.product_slug,
        },
      },
      maxHeight: '100%',
      maxWidth: '100%',
    })
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public async updateAddress() {
    const dialogRef = this.dialog.open(DialogUpdateAddressComponent);
    const scr = dialogRef.afterClosed().subscribe((address) => {
      if (!address) {
        scr.unsubscribe();
        return;
      }
      
      alert(JSON.stringify(address));
      scr.unsubscribe();
    });
  }

}