import { Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { AsyncPipe } from '@angular/common';

export interface DialogSceneConfigData {
  panoViewer: any;
}

@Component({
  selector: 'app-dialog-scene-config',
  standalone: true,
  imports: [ReactiveFormsModule, MatAutocompleteModule, AsyncPipe],
  templateUrl: './dialog-scene-config.component.html',
  styleUrl: './dialog-scene-config.component.css'
})
export class DialogSceneConfigComponent {
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogSceneConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSceneConfigData,
  ){
    this.sceneIds = this.inputSceneId.valueChanges.pipe(
      startWith(''),
      map(value => this.filterSceneId(value)),
    );
  }
  sceneIds: Observable<string[]>;
  private filterSceneId(value: string): string[] {
    value = value.toLowerCase();
    const options = Object.keys(this.data.panoViewer.getConfig().scenes);
    return options.filter(option => option.toLowerCase().includes(value)).reverse();
  }

  public inputSceneId : FormControl = new FormControl('');

  public addScene() {
    try {
      const sceneId = this.inputSceneId.value.trim();
      const scenes = this.data.panoViewer.getConfig().scenes;
      const keyScenes = Object.keys(scenes);
      if (!keyScenes.includes(sceneId)) { // add scene if not fund
        this.data.panoViewer.addScene(sceneId, {
          // "pitch": 0,
          // "yaw": 0,
          // "hfov": 100,
          "type": "equirectangular",
          "panorama": `http://localhost:4200/assets/pano/${this.data.panoViewer.worldId}/images/${sceneId}.png`,
          "compass": true,
          "hotSpots": [],
        });
        this.dialogRef.close(true);
      }
    } catch (e) {
      console.log(e);
    }
  }
  public gotoScene() {
    const sceneId = this.inputSceneId.value.trim();
    this.data.panoViewer.loadScene(sceneId);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
