{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5k~qNLayt8azRjs:ayWCayWAj[WCj[oeWUfkfkj@j@j[axoJjst7f6j]ofa}j[ayazfkayayfQ",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}