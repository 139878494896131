{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5.~qRjt7ofofofoLfQkBayWBj[WBayj[ayj[WBfQj[afWCa|a{kBWBfRjtWBofj[WBa{fQj[af",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}