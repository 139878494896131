<p-toast></p-toast>


<section class="p-3 pt-4" style="background-color: #555; min-height: 100%; width: 30rem; max-width: 100%;">

  <h3 class="mx-3 text-white mb-2">
    Đăng ký ngay để tham gia chương trình <br/>
    <b>Cấp số nhân doanh nghiệp bằng cơ chế vốn</b> <br/>
    Hoàn toàn miễn phí
  </h3>

  <div class="container-fluid h-custom mt-1" style="background-color: #555; height: 100%;">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <form>

          <hr/>

          <!-- <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p class="lead fw-normal mb-0 me-3 text-white">Đăng nhập với: </p>
            <button (click)="btnSignInFacebook()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faFacebookF" />
            </button>
            <button (click)="btnSignInGoogle()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faGoogle" />
            </button>
          </div> -->

          <!-- Phone input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example2">Họ và tên:</label>
            <input type="text" id="form3Example2" class="form-control {{validInputName == '' ? 'is-valid' : validInputName == undefined ? '' : 'is-invalid'}}" (change)="checkValidName($event)" placeholder="Nhập tên bạn" [(ngModel)]="formLogin.inputName" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example2" class="invalid-feedback" [innerText]="validInputName"></div>
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3x">Địa chỉ Email:</label>
            <input type="email" id="form3Example3x" class="form-control {{validInputEmail == '' ? 'is-valid' : validInputEmail == undefined ? '' : 'is-invalid'}}" (change)="checkValidEmail($event)" placeholder="Nhập địa chỉ email" [(ngModel)]="formLogin.inputEmail" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3x" class="invalid-feedback" [innerText]="validInputEmail"></div>
          </div>

          <!-- Phone input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3">Số điện thoại:</label>
            <input type="tel" id="form3Example3" class="form-control {{validInputPhone == '' ? 'is-valid' : validInputPhone == undefined ? '' : 'is-invalid'}}" (change)="checkValidPhone($event)" placeholder="Nhập số điện thoại" [(ngModel)]="formLogin.inputPhone" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3" class="invalid-feedback" [innerText]="validInputPhone"></div>
          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example4">Mật khẩu:</label>
            <input type="password" id="form3Example4" class="form-control {{validInputPassworld == '' ? 'is-valid' : validInputPassworld == undefined ? '' : 'is-invalid'}}" (change)="checkValidPassworld($event)" placeholder="Nhập mật khẩu mới" [(ngModel)]="formLogin.inputPassworld" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example4" class="invalid-feedback" [innerText]="validInputPassworld"></div>
          </div>

          <!-- Re Password input -->
          <div class="form-outline mb-3">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example5">Nhập lại mật khẩu:</label>
            <input type="password" id="form3Example5" class="form-control {{validInputRePassworld == '' ? 'is-valid' : validInputRePassworld == undefined ? '' : 'is-invalid'}}" (change)="checkValidRePassworld($event)" placeholder="Nhập lại mật khẩu mới" [(ngModel)]="formLogin.inputRePasworld" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example5" class="invalid-feedback" [innerText]="validInputRePassworld"></div>
          </div>

          <div class="row text-center text-lg-start mt-3 pt-2 mx-0 px-0">
            <button type="button" class="btn btn-outline-secondary col me-2" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="close()" [disabled]="isLoading">
              Trở lại
            </button>
            <button type="button" class="btn btn-danger col ms-2" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="btnLogin()" [disabled]="isLoading">
              Đăng ký
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>