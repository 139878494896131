<div (click)="back($event)" style="width: 100%; height: 100%; position: relative;">
    <div (click)="disableOut($event)" class="bg-dark p-4 shadow-sm rounded" style="
        max-width: 96%;
        /* width: max-content;  */
        max-height: 95%;
        height: auto;
        position: absolute;
        right: 24px;
        left: 24px;
        bottom: 24px;
        top: 24px;
        /* top: 50%; */
        /* -ms-transform: translateY(-50%);
        transform: translateY(-50%); */
        overflow-y: auto;
        color: white;">

        <div class="row w-100">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mx-0 px-3" style="margin-top: 32px;">
                <div
                    *ngIf="isStatusVideo('unauthen')"
                    class="rounded mb-3"
                    style="transition-duration: 2000ms;
                        width: 100%; 
                        height: fit-content;
                        min-height: 50vh;
                        max-height: 85vh;
                        object-fit: contain;
                        background-color: #222;
                        background-image: url(/assets/images/basic-loading.gif);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 52px 52px;" >
                    <div class="position-relative" role="alert">
                        <img class="w-100" src="/assets/images/clip_im.jpg"/>
                        <h2 class="alert alert-danger position-absolute" style="bottom: 2rem; left: 2rem; right: 2rem;">
                            <i class="bi bi-exclamation-triangle"></i>&nbsp;
                            Để xem nội dung này, bạn cần <button (click)="signIn()" class="btn btn-danger">Đăng nhập</button> vào hệ thống!
                        </h2>
                    </div>
                </div>
                <div
                    *ngIf="isStatusVideo('unpayment') || isStatusVideo('watingpayment')"
                    class="rounded  mb-3"
                    style="transition-duration: 2000ms;
                        width: 100%; 
                        height: fit-content;
                        min-height: 50vh;
                        max-height: 85vh;
                        object-fit: contain;
                        background-color: #222;
                        background-image: url(/assets/images/basic-loading.gif);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 52px 52px;" >
                    <div class="position-relative" role="alert">
                        <img class="w-100" src="/assets/images/clip_im.jpg"/>
                        <h2 class="alert alert-danger position-absolute" style="bottom: 2rem; left: 2rem; right: 2rem;">
                            <p *ngIf="isStatusVideo('unpayment')">
                                <i class="bi bi-exclamation-triangle"></i>&nbsp;
                                Khóa học này được bảo vệ bản quyền, bạn cần <button (click)="payment()" class="btn btn-danger">Mua khóa học</button> để xem các nội dung nâng cao!
                            </p>
                            <p *ngIf="isStatusVideo('watingpayment')">
                                <i class="bi bi-exclamation-triangle"></i>&nbsp;
                                Tài khoản của bạn đang trong quá trình xử lý, Nhân viên Aura Capital đang xác thực tài khoản. Vui lòng chờ hoặc liên hệ với chúng tôi qua hotline: <a href="tel:+84931875933">0931 875 933</a>!
                            </p>
                        </h2>
                    </div>
                </div>
                
                <video
                    *ngIf="!isStatusVideo('unauthen') && !isStatusVideo('unpayment') && !isStatusVideo('watingpayment')"
                    id="fullscreen-video"
                    [controls]="true"
                    [src]="isPlayData.video"
                    controlsList="nodownload"
                    playsinline
                    type="video/mp4"
                    class="rounded m-0 p-0"    
                    style="transition-duration: 2000ms;
                        width: 100%; 
                        /* height: fit-content; */
                        /* min-height: 50vh; */
                        max-height: 85vh;
                        object-fit: contain;
                        background-color: #222;
                        background-image: url(/assets/images/basic-loading.gif);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 52px 52px;" >
                </video>
                <h1 class="mt-2 mb-1 mx-0">{{isPlayData.label}}</h1>
                <hr class="m-0 p-0"/>
            </div>

            <div class="col">
                <div class="my-3 mt-4 mt-xl-0 bg-danger d-xl-none" style="height: 0.5em; width: 5em;"></div>
                <h2 class="mb-0 py-0">Cơ bản</h2>
                <hr class="my-0 py-0"/>
                <div class="mt-4 rounded" style="transition: 300ms; background-color: #fff2;">
                    <button (click)="clickContent($event, '/assets/pano/aura-lms/trailer-aura-lms.mp4', '0')" class="btn-hover btn w-100 text-white p-3 rounded fs-6 border-0" style="text-align: left; display: flex;">
                        <img *ngIf="'0' == isPlayData.id" style="width: 1.4em; height: 1.4em; margin-right: 0.7em;" src="/assets/images/play_video.svg"/>
                        <div [style.fontWeight]="'0' == isPlayData.id ? '500' : ''" style="flex-grow: 1; transition: 300ms;">Cấp số nhân doanh nghiệp</div>
                    </button>
                </div>
                <h2 class="mt-3 mb-0 py-0">Nâng cao</h2>
                <hr class="my-0 py-0"/>
                @for (lesssion of lessionData; track $index) {
                    <div class="mt-4 rounded" style="transition: 300ms; background-color: #fff2;">
                        <button (click)="clickHeader($event, lesssion.label)" class="btn-hover btn w-100 text-white p-3 rounded fs-6 border-0" style="background-color: #0000; text-align: left;">
                            <div [style.fontWeight]="lesssion.id == isPlayData.parentId ? 'bold' : ''" style="transition: 300ms;">{{lesssion.label}}</div>
                        </button>
                        <div *ngIf="idCollapseShow.includes(lesssion.label)" [@enterAnimation] style="transition: 300ms; overflow: hidden;">
                            <hr class="mt-0 mb-1"/>
                            @for (item of lesssion.items; track $index;) {
                                <div class="px-2 py-1">
                                    <button (click)="clickContent($event, item.video, item.id)" class="btn-hover w-100 text-white p-3 rounded fs-6 border-0" style="background-color: #0003; text-align: left; display: flex;">
                                        <img *ngIf="item.id == isPlayData.id" style="width: 1.4em; height: 1.4em; margin-right: 0.7em;" src="/assets/images/play_video.svg"/>
                                        <div [style.fontWeight]="item.id == isPlayData.id ? '500' : ''" style="flex-grow: 1; transition: 300ms;">{{item.label}}</div>
                                    </button>
                                </div>
                            }
                            <div class="pt-1"></div>
                        </div>
                    </div>
                }
            </div>        
        </div>

    </div>
</div>
