<div class="prevent-select" (window:resize)="onResize($event)" style="width: 100vw; height: 100vh;">
    <div class="position-absolute p-3 pb-2 bg-dark shadow-sm" style="z-index: 3; left: 0; right: 0;">
        <app-header/>
    </div>

    <div #containerMapSvg [style]="{opacity: currentStateCode ? 0.2 : 1}" style="overflow-x: hidden;" [@fadeInOut] class="world-size ts300">
        <!-- <img #bgMapSvg class="ts300 position-absolute p-0" style="z-index: 1; overflow: hidden; width: 70vw; margin: 0 15vw; box-sizing: border-box; enable-background:new 0 0 1009.7 666;" src="/assets/images/world-color-new-outline.svg"/> -->
        <svg version="1.1"
            #bgMapSvg class="ts300 position-absolute p-0"
            style="z-index: 1; overflow: hidden; width: 70vw; margin: 0 15vw; box-sizing: border-box; enable-background:new 0 0 1009.7 666;"
            id="Layer_1" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:mapsvg="http://mapsvg.com" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1009.7 666" xml:space="preserve">
            <style type="text/css">
                .fagst0 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_1_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst1 {
                    clip-path: url(#SVGID_3_);
                    fill: #CB001C;
                }

                .fagst2 {
                    clip-path: url(#SVGID_5_);
                    fill: #FFFFFF;
                }

                .fagst3 {
                    clip-path: url(#SVGID_7_);
                    fill: #CB001C;
                }

                .fagst4 {
                    clip-path: url(#SVGID_9_);
                    fill: #FFFFFF;
                }

                .fagst5 {
                    clip-path: url(#SVGID_11_);
                    fill: #CB001C;
                }

                .fagst6 {
                    clip-path: url(#SVGID_13_);
                    fill: #FFFFFF;
                }

                .fagst7 {
                    clip-path: url(#SVGID_15_);
                    fill: #CB001C;
                }

                .fagst8 {
                    clip-path: url(#SVGID_17_);
                    fill: #FFFFFF;
                }

                .fagst9 {
                    clip-path: url(#SVGID_19_);
                    fill: #CB001C;
                }

                .fagst10 {
                    clip-path: url(#SVGID_21_);
                    fill: #FFFFFF;
                }

                .fagst11 {
                    clip-path: url(#SVGID_23_);
                    fill: #CB001C;
                }

                .fagst12 {
                    clip-path: url(#SVGID_25_);
                    fill: #FFFFFF;
                }

                .fagst13 {
                    clip-path: url(#SVGID_27_);
                    fill: #CB001C;
                }

                .fagst14 {
                    clip-path: url(#SVGID_29_);
                    fill: #2A3560;
                }

                .fagst15 {
                    clip-path: url(#SVGID_31_);
                    fill: #FFFFFF;
                }

                .fagst16 {
                    clip-path: url(#SVGID_33_);
                    fill: #FFFFFF;
                }

                .fagst17 {
                    clip-path: url(#SVGID_35_);
                    fill: #FFFFFF;
                }

                .fagst18 {
                    clip-path: url(#SVGID_37_);
                    fill: #FFFFFF;
                }

                .fagst19 {
                    clip-path: url(#SVGID_39_);
                    fill: #FFFFFF;
                }

                .fagst20 {
                    clip-path: url(#SVGID_41_);
                    fill: #FFFFFF;
                }

                .fagst21 {
                    clip-path: url(#SVGID_43_);
                    fill: #FFFFFF;
                }

                .fagst22 {
                    clip-path: url(#SVGID_45_);
                    fill: #FFFFFF;
                }

                .fagst23 {
                    clip-path: url(#SVGID_47_);
                    fill: #FFFFFF;
                }

                .fagst24 {
                    clip-path: url(#SVGID_49_);
                    fill: #FFFFFF;
                }

                .fagst25 {
                    clip-path: url(#SVGID_51_);
                    fill: #FFFFFF;
                }

                .fagst26 {
                    clip-path: url(#SVGID_53_);
                    fill: #FFFFFF;
                }

                .fagst27 {
                    clip-path: url(#SVGID_55_);
                    fill: #FFFFFF;
                }

                .fagst28 {
                    clip-path: url(#SVGID_57_);
                    fill: #FFFFFF;
                }

                .fagst29 {
                    clip-path: url(#SVGID_59_);
                    fill: #FFFFFF;
                }

                .fagst30 {
                    clip-path: url(#SVGID_61_);
                    fill: #FFFFFF;
                }

                .fagst31 {
                    clip-path: url(#SVGID_63_);
                    fill: #FFFFFF;
                }

                .fagst32 {
                    clip-path: url(#SVGID_65_);
                    fill: #FFFFFF;
                }

                .fagst33 {
                    clip-path: url(#SVGID_67_);
                    fill: #FFFFFF;
                }

                .fagst34 {
                    clip-path: url(#SVGID_69_);
                    fill: #FFFFFF;
                }

                .fagst35 {
                    clip-path: url(#SVGID_71_);
                    fill: #FFFFFF;
                }

                .fagst36 {
                    clip-path: url(#SVGID_73_);
                    fill: #FFFFFF;
                }

                .fagst37 {
                    clip-path: url(#SVGID_75_);
                    fill: #FFFFFF;
                }

                .fagst38 {
                    clip-path: url(#SVGID_77_);
                    fill: #FFFFFF;
                }

                .fagst39 {
                    clip-path: url(#SVGID_79_);
                    fill: #FFFFFF;
                }

                .fagst40 {
                    clip-path: url(#SVGID_81_);
                    fill: #FFFFFF;
                }

                .fagst41 {
                    clip-path: url(#SVGID_83_);
                    fill: #FFFFFF;
                }

                .fagst42 {
                    clip-path: url(#SVGID_85_);
                    fill: #FFFFFF;
                }

                .fagst43 {
                    clip-path: url(#SVGID_87_);
                    fill: #FFFFFF;
                }

                .fagst44 {
                    clip-path: url(#SVGID_89_);
                    fill: #FFFFFF;
                }

                .fagst45 {
                    clip-path: url(#SVGID_91_);
                    fill: #FFFFFF;
                }

                .fagst46 {
                    clip-path: url(#SVGID_93_);
                    fill: #FFFFFF;
                }

                .fagst47 {
                    clip-path: url(#SVGID_95_);
                    fill: #FFFFFF;
                }

                .fagst48 {
                    clip-path: url(#SVGID_97_);
                    fill: #FFFFFF;
                }

                .fagst49 {
                    clip-path: url(#SVGID_99_);
                    fill: #FFFFFF;
                }

                .fagst50 {
                    clip-path: url(#SVGID_101_);
                    fill: #FFFFFF;
                }

                .fagst51 {
                    clip-path: url(#SVGID_103_);
                    fill: #FFFFFF;
                }

                .fagst52 {
                    clip-path: url(#SVGID_105_);
                    fill: #FFFFFF;
                }

                .fagst53 {
                    clip-path: url(#SVGID_107_);
                    fill: #FFFFFF;
                }

                .fagst54 {
                    clip-path: url(#SVGID_109_);
                    fill: #FFFFFF;
                }

                .fagst55 {
                    clip-path: url(#SVGID_111_);
                    fill: #FFFFFF;
                }

                .fagst56 {
                    clip-path: url(#SVGID_113_);
                    fill: #FFFFFF;
                }

                .fagst57 {
                    clip-path: url(#SVGID_115_);
                    fill: #FFFFFF;
                }

                .fagst58 {
                    clip-path: url(#SVGID_117_);
                    fill: #FFFFFF;
                }

                .fagst59 {
                    clip-path: url(#SVGID_119_);
                    fill: #FFFFFF;
                }

                .fagst60 {
                    clip-path: url(#SVGID_121_);
                    fill: #FFFFFF;
                }

                .fagst61 {
                    clip-path: url(#SVGID_123_);
                    fill: #FFFFFF;
                }

                .fagst62 {
                    clip-path: url(#SVGID_125_);
                    fill: #FFFFFF;
                }

                .fagst63 {
                    clip-path: url(#SVGID_127_);
                    fill: #FFFFFF;
                }

                .fagst64 {
                    clip-path: url(#SVGID_129_);
                    fill: #FFFFFF;
                }

                .fagst65 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_130_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst66 {
                    clip-path: url(#SVGID_132_);
                    fill: #1A237B;
                }

                .fagst67 {
                    clip-path: url(#SVGID_134_);
                    fill: #1A237B;
                }

                .fagst68 {
                    clip-path: url(#SVGID_136_);
                    fill: #1A237B;
                }

                .fagst69 {
                    clip-path: url(#SVGID_138_);
                    fill: #1A237B;
                }

                .fagst70 {
                    clip-path: url(#SVGID_140_);
                    fill: #1A237B;
                }

                .fagst71 {
                    clip-path: url(#SVGID_142_);
                    fill: #FFFFFF;
                }

                .fagst72 {
                    clip-path: url(#SVGID_144_);
                    fill: #FFFFFF;
                }

                .fagst73 {
                    clip-path: url(#SVGID_146_);
                    fill: #FFFFFF;
                }

                .fagst74 {
                    clip-path: url(#SVGID_148_);
                    fill: #FFFFFF;
                }

                .fagst75 {
                    clip-path: url(#SVGID_150_);
                    fill: #BD0034;
                }

                .fagst76 {
                    clip-path: url(#SVGID_152_);
                    fill: #BD0034;
                }

                .fagst77 {
                    clip-path: url(#SVGID_154_);
                    fill: #BD0034;
                }

                .fagst78 {
                    clip-path: url(#SVGID_156_);
                    fill: #BD0034;
                }

                .fagst79 {
                    clip-path: url(#SVGID_158_);
                    fill: #BD0034;
                }

                .fagst80 {
                    clip-path: url(#SVGID_160_);
                    fill: #FFFFFF;
                }

                .fagst81 {
                    clip-path: url(#SVGID_162_);
                    fill: #BD0034;
                }

                .fagst82 {
                    clip-path: url(#SVGID_164_);
                    fill: #FFFFFF;
                }

                .fagst83 {
                    clip-path: url(#SVGID_166_);
                    fill: #BD0034;
                }

                .fagst84 {
                    clip-path: url(#SVGID_168_);
                    fill: #FFFFFF;
                }

                .fagst85 {
                    clip-path: url(#SVGID_170_);
                    fill: #BD0034;
                }

                .fagst86 {
                    clip-path: url(#SVGID_172_);
                    fill: #FFFFFF;
                }

                .fagst87 {
                    clip-path: url(#SVGID_174_);
                    fill: #BD0034;
                }

                .fagst88 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_175_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst89 {
                    clip-path: url(#SVGID_177_);
                    fill: #FFFFFF;
                }

                .fagst90 {
                    clip-path: url(#SVGID_179_);
                    fill: #D80031;
                }

                .fagst91 {
                    fill: none;
                    stroke: #282828;
                    stroke-width: 0.5;
                    stroke-miterlimit: 10;
                }

                .fagst92 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_180_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst93 {
                    clip-path: url(#SVGID_182_);
                    fill: #FFFFFF;
                }

                .fagst94 {
                    clip-path: url(#SVGID_184_);
                    fill: #D80031;
                }

                .fagst95 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_185_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst96 {
                    clip-path: url(#SVGID_187_);
                    fill: #1C2A7D;
                }

                .fagst97 {
                    clip-path: url(#SVGID_189_);
                    fill: #FFFFFF;
                }

                .fagst98 {
                    clip-path: url(#SVGID_191_);
                    fill: #BC0030;
                }

                .fagst99 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_192_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst100 {
                    fill: #DD0000;
                }

                .fagst101 {
                    fill: #FFCE00;
                }

                .fagst102 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_193_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst103 {
                    clip-path: url(#SVGID_195_);
                    fill: #FFFFFF;
                }

                .fagst104 {
                    clip-path: url(#SVGID_197_);
                    fill: #BD0034;
                }

                .fagst105 {
                    clip-path: url(#SVGID_199_);
                    fill: #BD0034;
                }

                .fagst106 {
                    clip-path: url(#SVGID_201_);
                    fill: #BD0034;
                }

                .fagst107 {
                    clip-path: url(#SVGID_203_);
                    fill: #BD0034;
                }

                .fagst108 {
                    clip-path: url(#SVGID_205_);
                    fill: #1A237B;
                }

                .fagst109 {
                    clip-path: url(#SVGID_207_);
                    fill: #1A237B;
                }

                .fagst110 {
                    clip-path: url(#SVGID_209_);
                    fill: #1A237B;
                }

                .fagst111 {
                    clip-path: url(#SVGID_211_);
                    fill: #1A237B;
                }

                .fagst112 {
                    clip-path: url(#SVGID_213_);
                    fill: #1A237B;
                }

                .fagst113 {
                    clip-path: url(#SVGID_215_);
                    fill: #1A237B;
                }

                .fagst114 {
                    clip-path: url(#SVGID_217_);
                    fill: #1A237B;
                }

                .fagst115 {
                    clip-path: url(#SVGID_219_);
                    fill: #1A237B;
                }

                .fagst116 {
                    clip-path: url(#SVGID_221_);
                    fill: #BD0034;
                }

                .fagst117 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_222_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst118 {
                    clip-path: url(#SVGID_224_);
                    fill: #CB1D23;
                }

                .fagst119 {
                    clip-path: url(#SVGID_226_);
                    fill: #F7DE29;
                }

                .fagst120 {
                    clip-path: url(#SVGID_228_);
                    fill: #F7DE29;
                }

                .fagst121 {
                    clip-path: url(#SVGID_230_);
                    fill: #F7DE29;
                }

                .fagst122 {
                    clip-path: url(#SVGID_232_);
                    fill: #F7DE29;
                }

                .fagst123 {
                    clip-path: url(#SVGID_234_);
                    fill: #F7DE29;
                }

                .fagst124 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_235_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst125 {
                    clip-path: url(#SVGID_237_);
                    fill: #FFFFFF;
                    stroke: #000000;
                    stroke-width: 0.5;
                    stroke-miterlimit: 10;
                }

                .fagst126 {
                    clip-path: url(#SVGID_239_);
                }

                .fagst127 {
                    clip-path: url(#SVGID_241_);
                    fill: #AD0018;
                }

                .fagst128 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_242_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst129 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_243_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst130 {
                    fill: #FFFFFF;
                    stroke: url(#SVGID_244_);
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                }

                .fagst131 {
                    clip-path: url(#SVGID_246_);
                    fill: #C8172B;
                }

                .fagst132 {
                    clip-path: url(#SVGID_248_);
                    fill: #FCFF2C;
                }
            </style>
            <g class="fag-svg-state" id="US">
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="130.8" y1="636.4007" x2="231.6" y2="636.4007" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst0" d="M225,252h-87.6c-3.1,0-5.6,2.5-5.6,5.6v33.6c0,3.1,2.5,5.6,5.6,5.6h33.2c3,0,5.6,1.9,6.5,4.8l4.1,12.9
                l4.1-12.9c0.9-2.8,3.5-4.8,6.5-4.8H225c3.1,0,5.6-2.5,5.6-5.6v-33.6C230.5,254.5,228.1,252,225,252z" />
                <g id="USA_4_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_2_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_3_">
                                                <use xlink:href="#SVGID_2_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="285.9" class="fagst1" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_4_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_5_">
                                                <use xlink:href="#SVGID_4_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="283.9" class="fagst2" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_6_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_7_">
                                                <use xlink:href="#SVGID_6_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="281.8" class="fagst3" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_8_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_9_">
                                                <use xlink:href="#SVGID_8_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="279.7" class="fagst4" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_10_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_11_">
                                                <use xlink:href="#SVGID_10_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="277.7" class="fagst5" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_12_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_13_">
                                                <use xlink:href="#SVGID_12_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="275.6" class="fagst6" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_14_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_15_">
                                                <use xlink:href="#SVGID_14_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="273.6" class="fagst7" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_16_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_17_">
                                                <use xlink:href="#SVGID_16_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="271.5" class="fagst8" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_18_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_19_">
                                                <use xlink:href="#SVGID_18_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="269.4" class="fagst9" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_20_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_21_">
                                                <use xlink:href="#SVGID_20_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="267.4" class="fagst10" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_22_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_23_">
                                                <use xlink:href="#SVGID_22_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="265.3" class="fagst11" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_24_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_25_">
                                                <use xlink:href="#SVGID_24_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="263.2" class="fagst12" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_26_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_27_">
                                                <use xlink:href="#SVGID_26_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="261.2" class="fagst13" width="46" height="2.1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_28_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_29_">
                                                <use xlink:href="#SVGID_28_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="157.7" y="261.2" class="fagst14" width="21" height="14.4" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_30_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_31_">
                                                <use xlink:href="#SVGID_30_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst15" points="159.6,262.8 159.7,263.3 160.3,263.3 159.9,263.7 160,264.2 159.6,263.9 159.1,264.2 
                                            159.3,263.7 158.8,263.3 159.4,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_32_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_33_">
                                                <use xlink:href="#SVGID_32_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst16" points="159.6,265.2 159.7,265.7 160.3,265.7 159.9,266.1 160,266.6 159.6,266.3 159.1,266.6 
                                            159.3,266.1 158.8,265.7 159.4,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_34_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_35_">
                                                <use xlink:href="#SVGID_34_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst17" points="159.6,267.6 159.7,268.1 160.3,268.1 159.9,268.5 160,269 159.6,268.7 159.1,269 159.3,268.5 
                                            158.8,268.1 159.4,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_36_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_37_">
                                                <use xlink:href="#SVGID_36_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst18" points="159.6,270 159.7,270.5 160.3,270.5 159.9,270.9 160,271.4 159.6,271.1 159.1,271.4 
                                            159.3,270.9 158.8,270.5 159.4,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_38_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_39_">
                                                <use xlink:href="#SVGID_38_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst19" points="159.6,272.4 159.7,273 160.3,273 159.9,273.3 160,273.8 159.6,273.5 159.1,273.8 159.3,273.3 
                                            158.8,273 159.4,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_40_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_41_">
                                                <use xlink:href="#SVGID_40_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst20" points="161.2,264 161.4,264.6 162,264.6 161.5,264.9 161.7,265.5 161.2,265.1 160.8,265.5 161,264.9 
                                            160.5,264.6 161.1,264.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_42_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_43_">
                                                <use xlink:href="#SVGID_42_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst21" points="161.2,266.5 161.4,267 162,267 161.5,267.3 161.7,267.9 161.2,267.5 160.8,267.9 161,267.3 
                                            160.5,267 161.1,267 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_44_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_45_">
                                                <use xlink:href="#SVGID_44_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst22" points="161.2,268.9 161.4,269.4 162,269.4 161.5,269.7 161.7,270.3 161.2,270 160.8,270.3 161,269.7 
                                            160.5,269.4 161.1,269.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_46_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_47_">
                                                <use xlink:href="#SVGID_46_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst23" points="161.2,271.3 161.4,271.8 162,271.8 161.5,272.2 161.7,272.7 161.2,272.4 160.8,272.7 
                                            161,272.2 160.5,271.8 161.1,271.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_48_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_49_">
                                                <use xlink:href="#SVGID_48_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst24" points="162.9,262.8 163.1,263.3 163.7,263.3 163.2,263.7 163.4,264.2 162.9,263.9 162.5,264.2 
                                            162.7,263.7 162.2,263.3 162.8,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_50_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_51_">
                                                <use xlink:href="#SVGID_50_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst25" points="162.9,265.2 163.1,265.7 163.7,265.7 163.2,266.1 163.4,266.6 162.9,266.3 162.5,266.6 
                                            162.7,266.1 162.2,265.7 162.8,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_52_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_53_">
                                                <use xlink:href="#SVGID_52_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst26" points="162.9,267.6 163.1,268.1 163.7,268.1 163.2,268.5 163.4,269 162.9,268.7 162.5,269 
                                            162.7,268.5 162.2,268.1 162.8,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_54_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_55_">
                                                <use xlink:href="#SVGID_54_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst27" points="162.9,270 163.1,270.5 163.7,270.5 163.2,270.9 163.4,271.4 162.9,271.1 162.5,271.4 
                                            162.7,270.9 162.2,270.5 162.8,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_56_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_57_">
                                                <use xlink:href="#SVGID_56_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst28" points="162.9,272.4 163.1,273 163.7,273 163.2,273.3 163.4,273.8 162.9,273.5 162.5,273.8 
                                            162.7,273.3 162.2,273 162.8,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_58_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_59_">
                                                <use xlink:href="#SVGID_58_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst29" points="164.6,264 164.8,264.6 165.4,264.6 164.9,264.9 165.1,265.5 164.6,265.1 164.2,265.5 
                                            164.3,264.9 163.9,264.6 164.4,264.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_60_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_61_">
                                                <use xlink:href="#SVGID_60_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst30" points="164.6,266.5 164.8,267 165.4,267 164.9,267.3 165.1,267.9 164.6,267.5 164.2,267.9 
                                            164.3,267.3 163.9,267 164.4,267 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_62_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_63_">
                                                <use xlink:href="#SVGID_62_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst31" points="164.6,268.9 164.8,269.4 165.4,269.4 164.9,269.7 165.1,270.3 164.6,270 164.2,270.3 
                                            164.3,269.7 163.9,269.4 164.4,269.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_64_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_65_">
                                                <use xlink:href="#SVGID_64_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst32" points="164.6,271.3 164.8,271.8 165.4,271.8 164.9,272.2 165.1,272.7 164.6,272.4 164.2,272.7 
                                            164.3,272.2 163.9,271.8 164.4,271.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_66_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_67_">
                                                <use xlink:href="#SVGID_66_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst33" points="166.3,262.8 166.5,263.3 167.1,263.3 166.6,263.7 166.8,264.2 166.3,263.9 165.9,264.2 
                                            166,263.7 165.6,263.3 166.1,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_68_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_69_">
                                                <use xlink:href="#SVGID_68_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst34" points="166.3,265.2 166.5,265.7 167.1,265.7 166.6,266.1 166.8,266.6 166.3,266.3 165.9,266.6 
                                            166,266.1 165.6,265.7 166.1,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_70_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_71_">
                                                <use xlink:href="#SVGID_70_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst35" points="166.3,267.6 166.5,268.1 167.1,268.1 166.6,268.5 166.8,269 166.3,268.7 165.9,269 166,268.5 
                                            165.6,268.1 166.1,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_72_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_73_">
                                                <use xlink:href="#SVGID_72_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst36" points="166.3,270 166.5,270.5 167.1,270.5 166.6,270.9 166.8,271.4 166.3,271.1 165.9,271.4 
                                            166,270.9 165.6,270.5 166.1,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_74_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_75_">
                                                <use xlink:href="#SVGID_74_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst37" points="166.3,272.4 166.5,273 167.1,273 166.6,273.3 166.8,273.8 166.3,273.5 165.9,273.8 166,273.3 
                                            165.6,273 166.1,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_76_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_77_">
                                                <use xlink:href="#SVGID_76_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst38" points="168,264 168.2,264.6 168.8,264.6 168.3,264.9 168.5,265.5 168,265.1 167.5,265.5 167.7,264.9 
                                            167.3,264.6 167.8,264.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_78_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_79_">
                                                <use xlink:href="#SVGID_78_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst39" points="168,266.5 168.2,267 168.8,267 168.3,267.3 168.5,267.9 168,267.5 167.5,267.9 167.7,267.3 
                                            167.3,267 167.8,267 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_80_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_81_">
                                                <use xlink:href="#SVGID_80_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst40" points="168,268.9 168.2,269.4 168.8,269.4 168.3,269.7 168.5,270.3 168,270 167.5,270.3 167.7,269.7 
                                            167.3,269.4 167.8,269.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_82_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_83_">
                                                <use xlink:href="#SVGID_82_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst41" points="168,271.3 168.2,271.8 168.8,271.8 168.3,272.2 168.5,272.7 168,272.4 167.5,272.7 
                                            167.7,272.2 167.3,271.8 167.8,271.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_84_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_85_">
                                                <use xlink:href="#SVGID_84_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst42" points="169.7,262.8 169.9,263.3 170.5,263.3 170,263.7 170.2,264.2 169.7,263.9 169.2,264.2 
                                            169.4,263.7 169,263.3 169.5,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_86_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_87_">
                                                <use xlink:href="#SVGID_86_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst43" points="169.7,265.2 169.9,265.7 170.5,265.7 170,266.1 170.2,266.6 169.7,266.3 169.2,266.6 
                                            169.4,266.1 169,265.7 169.5,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_88_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_89_">
                                                <use xlink:href="#SVGID_88_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst44" points="169.7,267.6 169.9,268.1 170.5,268.1 170,268.5 170.2,269 169.7,268.7 169.2,269 169.4,268.5 
                                            169,268.1 169.5,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_90_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_91_">
                                                <use xlink:href="#SVGID_90_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst45" points="169.7,270 169.9,270.5 170.5,270.5 170,270.9 170.2,271.4 169.7,271.1 169.2,271.4 
                                            169.4,270.9 169,270.5 169.5,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_92_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_93_">
                                                <use xlink:href="#SVGID_92_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst46" points="169.7,272.4 169.9,273 170.5,273 170,273.3 170.2,273.8 169.7,273.5 169.2,273.8 169.4,273.3 
                                            169,273 169.5,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_94_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_95_">
                                                <use xlink:href="#SVGID_94_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst47" points="171.4,264 171.6,264.6 172.1,264.6 171.7,264.9 171.8,265.5 171.4,265.1 170.9,265.5 
                                            171.1,264.9 170.6,264.6 171.2,264.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_96_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_97_">
                                                <use xlink:href="#SVGID_96_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst48" points="171.4,266.5 171.6,267 172.1,267 171.7,267.3 171.8,267.9 171.4,267.5 170.9,267.9 
                                            171.1,267.3 170.6,267 171.2,267 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_98_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_99_">
                                                <use xlink:href="#SVGID_98_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst49" points="171.4,268.9 171.6,269.4 172.1,269.4 171.7,269.7 171.8,270.3 171.4,270 170.9,270.3 
                                            171.1,269.7 170.6,269.4 171.2,269.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_100_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_101_">
                                                <use xlink:href="#SVGID_100_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst50" points="171.4,271.3 171.6,271.8 172.1,271.8 171.7,272.2 171.8,272.7 171.4,272.4 170.9,272.7 
                                            171.1,272.2 170.6,271.8 171.2,271.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_102_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_103_">
                                                <use xlink:href="#SVGID_102_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst51" points="173.1,262.8 173.3,263.3 173.8,263.3 173.4,263.7 173.5,264.2 173.1,263.9 172.6,264.2 
                                            172.8,263.7 172.3,263.3 172.9,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_104_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_105_">
                                                <use xlink:href="#SVGID_104_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst52" points="173.1,265.2 173.3,265.7 173.8,265.7 173.4,266.1 173.5,266.6 173.1,266.3 172.6,266.6 
                                            172.8,266.1 172.3,265.7 172.9,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_106_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_107_">
                                                <use xlink:href="#SVGID_106_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst53" points="173.1,267.6 173.3,268.1 173.8,268.1 173.4,268.5 173.5,269 173.1,268.7 172.6,269 
                                            172.8,268.5 172.3,268.1 172.9,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_108_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_109_">
                                                <use xlink:href="#SVGID_108_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst54" points="173.1,270 173.3,270.5 173.8,270.5 173.4,270.9 173.5,271.4 173.1,271.1 172.6,271.4 
                                            172.8,270.9 172.3,270.5 172.9,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_110_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_111_">
                                                <use xlink:href="#SVGID_110_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst55" points="173.1,272.4 173.3,273 173.8,273 173.4,273.3 173.5,273.8 173.1,273.5 172.6,273.8 
                                            172.8,273.3 172.3,273 172.9,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_112_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_113_">
                                                <use xlink:href="#SVGID_112_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst56" points="174.8,264 174.9,264.6 175.5,264.6 175.1,264.9 175.2,265.5 174.8,265.1 174.3,265.5 
                                            174.5,264.9 174,264.6 174.6,264.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_114_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_115_">
                                                <use xlink:href="#SVGID_114_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst57" points="174.8,266.5 174.9,267 175.5,267 175.1,267.3 175.2,267.9 174.8,267.5 174.3,267.9 
                                            174.5,267.3 174,267 174.6,267 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_116_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_117_">
                                                <use xlink:href="#SVGID_116_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst58" points="174.8,268.9 174.9,269.4 175.5,269.4 175.1,269.7 175.2,270.3 174.8,270 174.3,270.3 
                                            174.5,269.7 174,269.4 174.6,269.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_118_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_119_">
                                                <use xlink:href="#SVGID_118_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst59" points="174.8,271.3 174.9,271.8 175.5,271.8 175.1,272.2 175.2,272.7 174.8,272.4 174.3,272.7 
                                            174.5,272.2 174,271.8 174.6,271.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_120_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_121_">
                                                <use xlink:href="#SVGID_120_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst60" points="176.5,262.8 176.6,263.3 177.2,263.3 176.7,263.7 176.9,264.2 176.5,263.9 176,264.2 
                                            176.2,263.7 175.7,263.3 176.3,263.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_122_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_123_">
                                                <use xlink:href="#SVGID_122_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst61" points="176.5,265.2 176.6,265.7 177.2,265.7 176.7,266.1 176.9,266.6 176.5,266.3 176,266.6 
                                            176.2,266.1 175.7,265.7 176.3,265.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_124_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_125_">
                                                <use xlink:href="#SVGID_124_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst62" points="176.5,267.6 176.6,268.1 177.2,268.1 176.7,268.5 176.9,269 176.5,268.7 176,269 176.2,268.5 
                                            175.7,268.1 176.3,268.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_126_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_127_">
                                                <use xlink:href="#SVGID_126_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst63" points="176.5,270 176.6,270.5 177.2,270.5 176.7,270.9 176.9,271.4 176.5,271.1 176,271.4 
                                            176.2,270.9 175.7,270.5 176.3,270.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_128_" x="157.7" y="261.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_129_">
                                                <use xlink:href="#SVGID_128_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst64" points="176.5,272.4 176.6,273 177.2,273 176.7,273.3 176.9,273.8 176.5,273.5 176,273.8 176.2,273.3 
                                            175.7,273 176.3,273 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="AU">
                <linearGradient id="SVGID_130_" gradientUnits="userSpaceOnUse" x1="2477.5566" y1="306.7011" x2="2566.3594" y2="306.7011" gradientTransform="matrix(-1 0 0 -1 3366.5569 886.2004)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst65" d="M806.8,611.9h75.7c3.1,0,5.6-2.5,5.6-5.6v-33.6c0-3.1-2.5-5.6-5.6-5.6h-27.2c-3,0-5.6-1.9-6.5-4.8l-4.1-12.9
                l-4.1,12.9c-0.9,2.8-3.5,4.8-6.5,4.8h-27.2c-3.1,0-5.6,2.5-5.6,5.6v33.6C801.2,609.4,803.7,611.9,806.8,611.9z" />
                <g id="NEW_ZEALAND_2_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_131_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_132_">
                                                <use xlink:href="#SVGID_131_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst66" points="844.5,576.6 844.5,577.5 844.5,577.8 844.5,577.9 839.4,580.5 844.5,580.5 844.5,581.2 
                                            844.5,582.4 844.5,583.5 844.5,584.3 839.4,584.3 844.5,586.8 844.5,587.3 844.5,588.1 844.5,588.2 842.9,588.2 
                                            842.3,588.2 842.1,588.2 834.9,584.6 834.9,588.2 834.1,588.2 833,588.2 831.8,588.2 831.1,588.2 831.1,584.6 823.9,588.2 
                                            823.1,588.2 821.5,588.2 821.5,603.4 867.5,603.4 867.5,576.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_133_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_134_">
                                                <use xlink:href="#SVGID_133_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst67" points="821.5,584.3 821.5,586.8 826.6,584.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_135_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_136_">
                                                <use xlink:href="#SVGID_135_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst68" points="834.9,576.6 834.9,580.1 841.9,576.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_137_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_138_">
                                                <use xlink:href="#SVGID_137_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst69" points="831.1,576.6 824.1,576.6 831.1,580.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_139_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_140_">
                                                <use xlink:href="#SVGID_139_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst70" points="821.5,580.5 826.6,580.5 821.5,577.9 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_141_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_142_">
                                                <use xlink:href="#SVGID_141_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst71" points="844.5,576.6 836.8,580.5 835.1,580.5 842.8,576.6 841.9,576.6 834.9,580.1 834.9,576.6 
                                            834.1,576.6 834.1,580.5 834.1,580.9 834.1,581.2 835.3,581.2 837,581.2 837.6,581.2 837.9,581.2 844.5,581.2 844.5,580.5 
                                            839.4,580.5 844.5,577.9 844.5,577.8 844.5,577.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_143_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_144_">
                                                <use xlink:href="#SVGID_143_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst72" points="831.1,576.6 831.1,580.1 824.1,576.6 821.5,576.6 829.2,580.5 827.4,580.5 821.5,577.5 
                                            821.5,577.9 826.6,580.5 821.5,580.5 821.5,581.2 828.1,581.2 829,581.2 830.7,581.2 831.8,581.2 831.8,580.9 831.8,580.7 
                                            831.8,580.5 831.8,576.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_145_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_146_">
                                                <use xlink:href="#SVGID_145_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst73" points="837,583.5 835.3,583.5 834.1,583.5 834.1,583.8 834.1,584.1 834.1,584.2 834.1,588.2 
                                            834.9,588.2 834.9,584.6 842.1,588.2 842.3,588.2 842.9,588.2 844.5,588.2 844.5,588.1 836.8,584.3 838.5,584.3 
                                            844.5,587.3 844.5,586.8 839.4,584.3 844.5,584.3 844.5,583.5 837.9,583.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_147_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_148_">
                                                <use xlink:href="#SVGID_147_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst74" points="830.7,583.5 829,583.5 828.4,583.5 828.1,583.5 821.5,583.5 821.5,584.3 826.6,584.3 
                                            821.5,586.8 821.5,588.1 829.2,584.3 830.9,584.3 823.1,588.2 823.9,588.2 831.1,584.6 831.1,588.2 831.8,588.2 
                                            831.8,584.2 831.8,583.8 831.8,583.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_149_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_150_">
                                                <use xlink:href="#SVGID_149_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst75" points="829.2,584.3 821.5,588.1 821.5,588.2 823.1,588.2 830.9,584.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_151_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_152_">
                                                <use xlink:href="#SVGID_151_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst76" points="821.5,577.5 827.4,580.5 829.2,580.5 821.5,576.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_153_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_154_">
                                                <use xlink:href="#SVGID_153_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst77" points="844.5,576.6 842.8,576.6 835.1,580.5 836.8,580.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_155_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_156_">
                                                <use xlink:href="#SVGID_155_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst78" points="836.8,584.3 844.5,588.1 844.5,587.3 838.5,584.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_157_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_158_">
                                                <use xlink:href="#SVGID_157_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst79" points="831.8,576.6 831.8,580.5 831.8,580.7 831.8,580.9 831.8,581.2 830.7,581.2 829,581.2 
                                            828.1,581.2 821.5,581.2 821.5,583.5 828.1,583.5 828.4,583.5 829,583.5 830.7,583.5 831.8,583.5 831.8,583.8 831.8,584.2 
                                            831.8,588.2 833,588.2 834.1,588.2 834.1,584.2 834.1,584.1 834.1,583.8 834.1,583.5 835.3,583.5 837,583.5 837.9,583.5 
                                            844.5,583.5 844.5,582.4 844.5,581.2 837.9,581.2 837.6,581.2 837,581.2 835.3,581.2 834.1,581.2 834.1,580.9 834.1,580.5 
                                            834.1,576.6 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_159_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_160_">
                                                <use xlink:href="#SVGID_159_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst80" points="853.4,584.5 854.7,584.5 855.1,583.3 855.4,584.5 856.7,584.5 855.7,585.2 856.1,586.4 
                                            855.1,585.6 854,586.4 854.4,585.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_161_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_162_">
                                                <use xlink:href="#SVGID_161_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst81" points="854,584.6 854.8,584.6 855.1,583.9 855.3,584.6 856.1,584.6 855.5,585.1 855.7,585.9 
                                            855.1,585.4 854.4,585.9 854.6,585.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_163_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_164_">
                                                <use xlink:href="#SVGID_163_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst82" points="848.4,589.5 849.7,589.5 850.1,588.3 850.4,589.5 851.7,589.5 850.7,590.2 851.1,591.4 
                                            850.1,590.7 849,591.4 849.4,590.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_165_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_166_">
                                                <use xlink:href="#SVGID_165_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst83" points="849,589.7 849.8,589.7 850.1,588.9 850.3,589.7 851.1,589.7 850.5,590.2 850.7,590.9 
                                            850.1,590.4 849.4,590.9 849.6,590.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_167_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_168_">
                                                <use xlink:href="#SVGID_167_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst84" points="857.9,588.2 859,588.2 859.3,587.2 859.7,588.2 860.8,588.2 859.9,588.9 860.2,589.9 
                                            859.3,589.3 858.4,589.9 858.8,588.9 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_169_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_170_">
                                                <use xlink:href="#SVGID_169_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst85" points="858.4,588.4 859.1,588.4 859.3,587.7 859.6,588.4 860.3,588.4 859.7,588.8 859.9,589.5 
                                            859.3,589.1 858.7,589.5 859,588.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_171_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_172_">
                                                <use xlink:href="#SVGID_171_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst86" points="853.2,597.4 854.6,597.4 855.1,596.1 855.5,597.4 856.9,597.4 855.7,598.2 856.2,599.5 
                                            855.1,598.7 853.9,599.5 854.4,598.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_173_" x="821.5" y="576.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_174_">
                                                <use xlink:href="#SVGID_173_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst87" points="853.8,597.6 854.8,597.6 855.1,596.7 855.3,597.6 856.3,597.6 855.5,598.1 855.8,599 
                                            855.1,598.5 854.3,599 854.6,598.1 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="ID">
                <linearGradient id="SVGID_175_" gradientUnits="userSpaceOnUse" x1="688.2" y1="857.6818" x2="777.0029" y2="857.6818" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst88" d="M694.8,535.9h75.7c3.1,0,5.6-2.5,5.6-5.6v-33.6c0-3.1-2.5-5.6-5.6-5.6h-2.6c-3,0,5.8-16,5.8-16
                s-13.2,16-16.2,16h-62.6c-3.1,0-5.6,2.5-5.6,5.6v33.6C689.2,533.5,691.7,535.9,694.8,535.9z" />
                <g id="POLAND_3_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_176_" x="710.5" y="500.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_177_">
                                                <use xlink:href="#SVGID_176_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="710.5" y="514" class="fagst89" width="46" height="13.4" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_178_" x="710.5" y="500.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_179_">
                                                <use xlink:href="#SVGID_178_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="710.5" y="500.6" class="fagst90" width="46" height="13.4" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <rect id="SVGID_00000117674639430129649560000010658967186725131706_" x="710.5" y="500.6" class="fagst91" width="46" height="26.8" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="PL">
                <linearGradient id="SVGID_180_" gradientUnits="userSpaceOnUse" x1="523" y1="678.9818" x2="611.8029" y2="678.9818" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst92" d="M605.2,357.2h-75.7c-3.1,0-5.6-2.5-5.6-5.6V318c0-3.1,2.5-5.6,5.6-5.6h2.6c3,0-5.8-16-5.8-16s13.2,16,16.2,16
                h62.6c3.1,0,5.6,2.5,5.6,5.6v33.6C610.8,354.7,608.3,357.2,605.2,357.2z" />
                <g id="POLAND_00000084510451883594692370000017069969723901174164_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_181_" x="545.8" y="321.3" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_182_">
                                                <use xlink:href="#SVGID_181_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="545.8" y="321.3" class="fagst93" width="46" height="13.4" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_183_" x="545.8" y="321.3" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_184_">
                                                <use xlink:href="#SVGID_183_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="545.8" y="334.7" class="fagst94" width="46" height="13.4" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <rect id="SVGID_00000080898222517000428890000006839660561993542030_" x="545.8" y="321.3" class="fagst91" width="46" height="26.8" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="TH">
                <linearGradient id="SVGID_185_" gradientUnits="userSpaceOnUse" x1="664.5" y1="736.8182" x2="761.7" y2="736.8182" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst95" d="M671.1,354.6h84c3.1,0,5.6,2.5,5.6,5.6v33.6c0,3.1-2.5,5.6-5.6,5.6h-5c-3,0,5.8,16,5.8,16s-13.2-16-16.2-16
                h-68.6c-3.1,0-5.6-2.5-5.6-5.6v-33.6C665.5,357.1,668,354.6,671.1,354.6z" />
                <g id="COSTA_RICA_3_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_186_" x="689.7" y="363.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_187_">
                                                <use xlink:href="#SVGID_186_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="689.7" y="363.6" class="fagst96" width="46" height="26.8" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_188_" x="689.7" y="363.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_189_">
                                                <use xlink:href="#SVGID_188_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="689.7" y="368" class="fagst97" width="46" height="17.9" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_190_" x="689.7" y="363.6" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_191_">
                                                <use xlink:href="#SVGID_190_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="689.7" y="372.5" class="fagst98" width="46" height="8.9" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="DE">
                <linearGradient id="SVGID_192_" gradientUnits="userSpaceOnUse" x1="501.4" y1="612.2181" x2="590.2" y2="612.2181" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst99" d="M583.6,230H508c-3.1,0-5.6,2.5-5.6,5.6v33.6c0,3.1,2.5,5.6,5.6,5.6h2.6c3,0-5.8,16-5.8,16s13.2-16,16.2-16
                h62.6c3.1,0,5.6-2.5,5.6-5.6v-33.6C589.2,232.5,586.7,230,583.6,230z" />
                <path d="M523.4,237.4h45.9v27.5h-45.9V237.4z" />
                <path class="fagst100" d="M523.4,246.5h45.9v18.4h-45.9V246.5z" />
                <path class="fagst101" d="M523.4,255.7h45.9v9.2h-45.9V255.7z" />
            </g>
            <g class="fag-svg-state" id="GB">
                <linearGradient id="SVGID_193_" gradientUnits="userSpaceOnUse" x1="392.9" y1="590.9839" x2="481.7" y2="590.9839" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst102" d="M475.2,206.1h-75.7c-3.1,0-5.6,2.5-5.6,5.6v33.6c0,3.1,2.5,5.6,5.6,5.6H455c3,0,5.6,1.9,6.5,4.8l4.1,12.9
                l2.4-14.5c0.9-2.8,1.1-3.2,4-3.2h3.2c3.1,0,5.6-2.5,5.6-5.6v-33.6C480.7,208.6,478.3,206.1,475.2,206.1z" />
                <g id="UNITED_KINGDOM_2_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_194_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_195_">
                                                <use xlink:href="#SVGID_194_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="414.4" y="214.2" class="fagst103" width="46" height="26.8" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_196_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_197_">
                                                <use xlink:href="#SVGID_196_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst104" points="445.1,223.8 460.3,215.3 460.3,214.2 459.3,214.2 442,223.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_198_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_199_">
                                                <use xlink:href="#SVGID_198_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst105" points="444.4,231.8 460.3,240.7 460.3,238.9 447.6,231.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_200_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_201_">
                                                <use xlink:href="#SVGID_200_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst106" points="414.4,216.2 428,223.8 431.2,223.8 414.4,214.4 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_202_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_203_">
                                                <use xlink:href="#SVGID_202_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst107" points="430.4,231.8 414.4,240.8 414.4,241 417.3,241 433.6,231.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_204_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_205_">
                                                <use xlink:href="#SVGID_204_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst108" points="457.2,214.2 441.1,214.2 441.1,223.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_206_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_207_">
                                                <use xlink:href="#SVGID_206_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst109" points="434,214.2 418,214.2 434,223.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_208_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_209_">
                                                <use xlink:href="#SVGID_208_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst110" points="460.3,223.8 460.3,217.7 449.4,223.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_210_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_211_">
                                                <use xlink:href="#SVGID_210_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst111" points="460.3,237.9 460.3,231.8 449.4,231.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_212_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_213_">
                                                <use xlink:href="#SVGID_212_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst112" points="418.9,241 434,241 434,232.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_214_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_215_">
                                                <use xlink:href="#SVGID_214_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst113" points="441.1,241 456.3,241 441.1,232.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_216_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_217_">
                                                <use xlink:href="#SVGID_216_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst114" points="414.4,231.8 414.4,238.2 425.7,231.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_218_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_219_">
                                                <use xlink:href="#SVGID_218_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst115" points="414.4,223.8 425.7,223.8 414.4,217.5 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_220_" x="414.4" y="214.2" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_221_">
                                                <use xlink:href="#SVGID_220_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst116" points="435.4,214.2 435.4,225.4 414.4,225.4 414.4,230.2 435.4,230.2 435.4,241 439.7,241 
                                            439.7,230.2 460.3,230.2 460.3,225.4 439.7,225.4 439.7,214.2 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="CN">
                <linearGradient id="SVGID_222_" gradientUnits="userSpaceOnUse" x1="657.4" y1="662.2181" x2="746.2" y2="662.2181" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst117" d="M664,280h75.7c3.1,0,5.6,2.5,5.6,5.6v33.6c0,3.1-2.5,5.6-5.6,5.6H737c-3,0,5.8,16,5.8,16s-13.2-16-16.2-16
                H664c-3.1,0-5.6-2.5-5.6-5.6v-33.6C658.4,282.5,660.9,280,664,280z" />
                <g id="CHINA_3_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_223_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_224_">
                                                <use xlink:href="#SVGID_223_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="679.5" y="288.7" class="fagst118" width="46" height="26.8" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_225_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_226_">
                                                <use xlink:href="#SVGID_225_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst119" points="683.9,298.4 684.8,295.8 682.5,294.1 685.3,294.1 686.2,291.4 687.1,294.1 689.9,294.1 
                                            687.6,295.8 688.5,298.4 686.2,296.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_227_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_228_">
                                                <use xlink:href="#SVGID_227_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst120" points="691.8,299.9 692.7,300.3 693.4,299.5 693.3,300.5 694.3,300.9 693.3,301.1 693.2,302.2 
                                            692.7,301.3 691.7,301.5 692.3,300.8 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_229_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_230_">
                                                <use xlink:href="#SVGID_229_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst121" points="696.9,294.7 695.9,294.6 695.4,295.5 695.3,294.5 694.3,294.3 695.2,293.8 695.1,292.8 
                                            695.8,293.6 696.7,293.1 696.2,294 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_231_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_232_">
                                                <use xlink:href="#SVGID_231_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst122" points="694.8,299.3 695.1,298.4 694.3,297.8 695.3,297.8 695.6,296.8 695.9,297.8 697,297.8 
                                            696.1,298.4 696.4,299.3 695.6,298.7 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_233_" x="679.5" y="288.7" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_234_">
                                                <use xlink:href="#SVGID_233_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst123" points="691.8,290.4 692.8,290.9 693.5,290.1 693.3,291.1 694.3,291.6 693.3,291.8 693.1,292.8 
                                            692.6,291.9 691.6,292.1 692.3,291.3 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="JP">
                <linearGradient id="SVGID_235_" gradientUnits="userSpaceOnUse" x1="3036.0532" y1="665.8182" x2="3133.2532" y2="665.8182" gradientTransform="matrix(-1 0 0 1 3996.9531 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst124" d="M954.3,283.6h-84c-3.1,0-5.6,2.5-5.6,5.6v33.6c0,3.1,2.5,5.6,5.6,5.6h5c3,0-5.8,16-5.8,16s13.2-16,16.2-16
                h68.6c3.1,0,5.6-2.5,5.6-5.6v-33.6C959.9,286.1,957.4,283.6,954.3,283.6z" />
                <g id="JAPAN_2_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_236_" x="889.6" y="292.5" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_237_">
                                                <use xlink:href="#SVGID_236_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="889.6" y="292.5" class="fagst125" width="46" height="26.8" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_238_" x="889.6" y="292.5" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_239_">
                                                <use xlink:href="#SVGID_238_" style="overflow:visible;" />
                                            </clipPath>
                                            <g class="fagst126">
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <defs>
                                                                    <rect id="SVGID_240_" x="889.6" y="292.5" width="46" height="26.8" />
                                                                </defs>
                                                                <clipPath id="SVGID_241_">
                                                                    <use xlink:href="#SVGID_240_" style="overflow:visible;" />
                                                                </clipPath>
                                                                <path class="fagst127" d="M912.6,297.9c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S908.2,297.9,912.6,297.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g class="fag-svg-state" id="IN">
                <linearGradient id="SVGID_242_" gradientUnits="userSpaceOnUse" x1="2381.2781" y1="809.1649" x2="2466.5796" y2="809.1649" gradientTransform="matrix(-1 -3.050094e-03 -3.050094e-03 1 3074.3196 -347.446)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst128" d="M611.5,483.2l72.7,0.2c3,0,5.3-2.4,5.3-5.3l0.1-32.3c0-3-2.4-5.4-5.3-5.4h-2.5c-2.9,0,5.6-15.3,5.6-15.3
                s-12.8,15.3-15.6,15.3l-60.2-0.2c-3,0-5.3,2.4-5.3,5.3l-0.1,32.3C606.2,480.8,608.6,483.2,611.5,483.2z" />
                <image style="overflow:visible;enable-background:new    ;" width="250" height="167" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAP4AAACtCAYAAACQjMIIAAAACXBIWXMAADhJAAA4SQFSfeZRAAAA
        GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAFhBJREFUeNrsnfl3VeW9h9+QMA8J
        BIEwyzwqSFtFq4K1g7VSq6V1rdplB9vbwd/u/am/9P4NrbXVTtd129quVW2rxWqrVauiVmaQeQgQ
        hjAECIQQSEj3893scFCsOFUIz7PW8eTss/c++xz8vN/xfXdKIiIiIiIiIiIickFSVvzRfv+sOdnT
        HH8SkU7N/5X91+LaipINiP77/i4inZpns0dtF38HkYsPhS+i8EVE4YuIwhcRhS8iCl9EFL6IKHwR
        UfgiovBFROGLiMIXEYUvIgpfRBS+iCh8EVH4IqLwRRS+iCh8EVH4IqLwRUThi4jCFxGFLyIKX0QU
        vogofBFR+CKi8EVE4YuIwhcRhS8iCl9EFL6IwhcRhS8iCl9EFL6IKHwRUfgiovBFROGLiMIXEYUv
        IgpfRBS+iCh8EVH4IqLwRUThi4jCF7mYqej4q3pCY/bfOn8SkU5NS0qLS4R/9X/7k4hcFDxUIvya
        Wf2y/w73R+m8tLdnw31La2pqOpGOH29LJ0+2py5dylL37uWpV6+u2XNFKivzd+rkdD/T1ZdOx7Fj
        rWnXrqa0ZcvBVFd3OO3ZczQ1NBxLR44cD+G3tbWn8vKy1K1beerTp1uqru6RBg3qnYYP75suvbQy
        DRnSJ/XoUe4P2aljfOkUtLWdTDt2HEkrVuzNHnvS+vUNmfAPdQj/6NETYelfD5a/d++u6ZJLep0S
        flWaOHFAuuyyS+IxdGjfGCRE4ct5RGvryRD4iy/WpZde2pEWL65PGzYcSI2NLed0PIPB4cPH47F5
        88H0j39sT/36dU8TJvRPs2YNSbNnD0vXXDMsjR5dmSoqLAYpfPnA2b27KYT6l79sTs8/vz1t3doY
        A0HHP3Im1IEDe2bxe1nav785xDx9+qCw4Lj7eAYMEtXVPWMAYB+OZ9BYtGh3WrZsT3r66a3pox8d
        nm68cVS6/vqR4RWIwpcPAOJ0RPmHP6xPjz++Ka1Z05BOnGjreB+xY62nTBkYMTti3rTpYJo5c3C6
        8sqhHcm9l1/emZYvr09jxlRlg0GXVFt7KL322r60ZMnutG9fPghs3HggG1AOpVde2Rmf+YUvTEqX
        Xz4o8gOi8OU/xMGDLemZZ7amBx9clZ57bnv2+lhs79mzIo0c2S+sOzH6t789M02aVJ369u0W2ftD
        h1oig98l89YbG4+HO4/1/vSnx6Sqqu7p2LG2cPfXrNmXfvKTZWnduobU3t6etm1rTM3Nrdn2/eFh
        MIDcdde0NGfOyFRZ2d1/EIUv7zdY4Ucf3ZgeeGBZWrq0Piw/bvuoUZXphhtGpeuuG5FZ4i7hun/k
        IzUhcAYGBD50aJ84B4k+tmGxBw3qFaLnPIcONcU2YnpCBD4LL2Lhwp3ZQLMtLP+BA8fSggWb0q5d
        R+L9efPGRVJQFL68T9TXN6WHH14fol+5cm9Hhn78+AHpK1+Znj7+8dHZANAvRF/Q2toex+EF4PID
        pT68Bix+AdUAyn1k9LHiN944OrYj9Jkzh6Rx4/qnn/1seVq7dn8MEv/8567oCeBc8+dPigFEFL68
        x5B0I56/774lafXqfdGQM2BAz7DWN9wwMt1224SI6/fubc4GhJRZ4Vz8iBjxYvnPFP6xM8RKbE85
        cMaM04PB3r1HY4DAW5g3b2zm4h+IJiBCBgaJVav2ph//eGl4B4h/wIAe/kMpfHmvoP7+5JNbMku/
        PBP9/hA9YkTsJPDGjq0KN75Hj4ooxzFI9O3bNV4TBmCZW1ra4kGsX1h8YnrAgu/bdzTeK+r1vEcs
        j6hJ/HXt2iV96UtTIjG4ePHu9Mgj6zMv4XBcDx4IA8y8eeNTr17+b6Xw5V2DO49b/ctfrozSG8k2
        RI6F/fKXp0byjqReAVafMhxxPoMC0JxDUg6hImJEjcVnAAAafPAKeK8Ar4LOvyuuGByDAee45prh
        2esh0dRDBeB3v1sTsf7y5XvTL36xIg0Z0jtyDFQL5PzFTowLAKzuQw+tibIbdXf66T/0oZp0xx2T
        YwBAzEVWH/AEunYtj7IbooSamj5h4bHOpTF+IXzKdwi/SP5xHInDioqyNGxYn45z4+Lv3HkkznfH
        HZOy6xgS10OIsHDhjrhOrlcUvrwLcNGfeGJzPOixx93OO+oGRLxOmY4y26pV+zpEjiWeOnVgCJlS
        H+49giZcQOC8RvCIuHD/KdMdPdoagsbt5ziOpweg6NTDY+B4ronPLdp6+/btHvucvtYt8bcofHmH
        LF++J0pn27cfjtfTp1+S7rlnVrr55rExsYaa/LBhfUP8dO9RbgMy+7jdhAicg8ECV50EHm59qavP
        a9p9eZ/96PMnVOB4zgMc9+KLO7LB50R4AOyL+LmOe+65Iq4LuE6aiTiHKHx5B9BI8/e/bw3xEtcT
        u99005gQ2pw5IzriejLp06YNDDEvWLA5YnNi8quuGhrvP/bYxsjOY82pu/N+qauPFSexx/vs9+ij
        G+LzOB7vYe3ahrDkeAx4EkWpkM+ngYfr4brYznFcL81FXL8ofHmbIMgXXqgLlxs+/OGa9IlPXBqJ
        Myw05bQCRHvttcNTa2tb+u1v18QknREj+kUSjno/vfYMEM3NubuP6IsHAwEeA8JloMFaY8E5ftmy
        +kjgNTW1xiSd0nif8iBtvMB10SxEvN/Q0Jyef74uPkcUvrwN6I9/9dVdkaCLf6hM7AiRrDuddcT7
        JN9I+FG6A9676aaxWYx+MjLszNIjCYinQP2f/XDRESQlPybh8Izw2Y5g//jHDbE/gwyf//OfrwhL
        /8lPXtqR8Sc3QChALz/i53ooJxatwsC1cfyJEyf9x1T4cq7QRYfocb2B5Bn99ZT2aNghsda/f4+w
        uLTvYtER9uTJ1Wn+/IkhOBpriPlnzBiUxd6N4QUgcKoAvEaw9OAT47Od2jznmzFjcAwIP/rRkvAE
        br99YpoypToEz+w/BgcaecgxME0XT4FqQ3GdwHVz/VQAROHLOUKWHUuM0GmqoRX3llvGh+ihT5+u
        McvuqquGxSo5zz23Lf30p8vTU0/VRjnvzjunhPjp8jt8+EQIlC47thGbk7lnP5579uwa23Hx2Q9v
        guPI9t9559Sw5oQctOsywGDUZ82qifo+CT7gum65ZVxM22VA4br5DuvW7fcfU+HLuUBXHhYVqwss
        gXXrreOzOHr0Gb31CJCsO91yTM4h4/6rX72W7r13SSTWmDyDAEnMMYmGUiAMHtwnWnqZ1MPz4MG9
        4jPzefu9Yl4/A8Htt0+ICTr33bc0modoBWZO/uc+NyGNG1d1RpMOXXuEA5/97PioBgDXv3HjwTi3
        nF/YuXcegmhZTGP//jx5h8VFpAjvwIHmjria2jxuNm46i2Pg/lNKQ+hk1nH76bIjQYdrz/69e3eL
        82Ohsfi45Ljp/ft3D8+C/RggsOZLl+6JablUCD72sVGRPxgzpjIEzzG4/tT2yQHQpltV1SOL8ysj
        F0AIQQWB0AEPovAMROHLm7BnT1M049ANByTbBg/uHV10gDVmWi2hAIMAQsXKImxCAoT3pz9tiCYc
        jsU64w0wWJAnyLv82tPLL+d1eTwHLDbnQaCca8mS+hh4Zs8eGt7GxInVIWCy/rTxNjUdjx4CJglR
        LUD4eAxcJ14DcP18DwYXha/w5S0gSYe1LNi8+VBYYkpzuOxk/LG0TI/F6lLaIzFXdNTR0IMAKa9h
        3SnF5YtvdIl9seKIn/3xFrDUbW152EDCjnOSOMTT4Dyc+/HH8zo++/MengaCJ+mH51CsxIOnwaq+
        BXwPHqVzAEThy1mgto74gJ57LGnpyri8Rmw8mBBDBQDrnyfTGqKDD2FjxRHuySyQx41nEFi5cl/m
        hh/qOB/izxfbOBmWvqqqW2zDM2DAefXV3TEY0B5M8w4P2nip559t2S0aeLg+vAfOyfcovosofPk3
        0E1HGQ2wsMTXRRfe60F81M9J0DFhhtwAMT1NOwia+JzkIJl8ttXVNb5heW1es33jxoZYfIOpvJT4
        8jChf8z+mzVrcPzdr1+3uPHGmy21zXVyvWTzGcD4HjQNicKXt4AGnGLRzJ49yzPh9oqEGrPesOR0
        8uVdd/kzlpzB4vSjLcp0ZNMRXrHqLjX10tV3S8E64znkM/JyD4NmHD6DRp3Vq/fGgFA8CClIEBIm
        8Iz7X/yN58A+3KaN6+D7yPmF5TwRLb6cD7CABrE9NDe3pd27j4YFJtl2NiitsRxWUQYsdfWxvFhg
        XH1ek3g7Wxstn0dSkKagYsYe3gJW/O24+mTwSTwW8/wJRfg+ovDlLUCsxcw7Em10y115ZU10xr0x
        LGh7Q3KPGnppcg8RU3ajK4+Bgdp6aZyfzwPom8aP7x+hAyEBCUEGCs5XrLF/Lsm9l17aGddbzMfn
        e9AZKApf3gLi5GKtemJ96uGlXXKl5TyEma+O29xRziPTX8T0eAHE7HgECJBZd2TcGRjyxpu8PEed
        HiGTgedYPpNmHTr1sNj19Uej75+6POU9ynmU/xgESst5XCfHF15F6XcRhS//BhpgyKgXUEpjZh5u
        NA8aa/JVc1s6lsym0YYGHkRGEpAGHmrqpQ08vXvn+1KqI/FHv39hmekMxD1noMCdL9ptZ8/uckYD
        D8IvGniYFsxSXgxCCJzrxhPgMxgcoLr6zO8iCl/eBJp0mF+PpcZ64nKzLn5x0wosdn5zjJqwvLxG
        yLj4Rcsumf3rrx8RlpiYH5eb/YjliduxzMTzZPHJEWDh8S4QN52DtOzSx49HQa9+0bLLnICztewW
        ngjXWTQfsYhHTU1vb7ah8OVcwF0nHicuR4RYcOLyuXNHhpBeD9Z80aJd6amntp7q8BsUOQEEyKQd
        3G7W6MNVzyfrlEcsT+zPc2Vlt8yDaMm8iabMOxgQs/ho2f3qV6fHxKBXXtkVrwkpmH3H8tpcI517
        pevoI3wGn2JyEZYeT4IGIDm/MN16HkLvPC2uxNiwe/eRmAP/17/WntEFh5UnWffYYxuihx7XnWm0
        LIWFMJmnj9X91KfGRIhQxN319UfC3Wcw4Zn4nc/kjjsswcUyWgwO3LGH5+98Z2YMAtygg+m5LOpB
        GFCaIOS6WPefEAMvAKhCEGaUudK2wpdzg5l1JM5wq5kX/7e/1cZaeIXwsfL5Cjw7Trn1I9M3vnF5
        WGQWvMTS49ojWgYBFsucNi1P7FGmIwTA4vNMZx3bmQvAflhojqPNl/PgcZDku/vuy8PlZ8BZvDj3
        Aop19fAcWNsPr4NKA9fNd6AUKApfzhGSZKycU8THuNEIDkEhMurxJPhwpZl3jyCJ9ynp/f73a09Z
        6iui9k6MT3KQWjzJPPIDvEagtPvymu3cfIPzsT8ex3e/e0XkBh5+eF0k8UgcXnvtiEj2jR3bP/IB
        DBRMKirq+kVnINfNbbTJVYjCl3NNvlR0idl1JNkAt5r4nfgZwWKV8xV4hnasest7LKJBg83XvnZZ
        vMe6d8T6LJ7BfngKeBK44QiZZ14jfHIKiJr9OY51977+9cui5IcbX8TuHMe8AOb6UwHgevAKiO/b
        T626wbVx/cXiH6Lw5RyhWQYXu0igIUYESEadkllpYo0yG7PyKirK0xe/ODmsNwMFy2nhwuMRYJmZ
        N8958z77vMbOa2r8vE/WnuQgnX8cz/p7nI/jWFef9foKED0eAlp/8snNp5YBTzHAsOIvA4oofHmb
        YNXnzh2VWc6hUa/HEmPRf/jDRenZZ7d1zODL71q7L9zym28eE3PkKc+xAi985jP5/esZHKi1I3S6
        Aynr8cxrEnc0/LAfoQMhBdl8zjNp0oBI+CF+uviKVX35/Gee2ZbuvXdxdl1bYjvHkfVnoHHxjfPY
        o/QnOL/BWnO3GlpycaWxxJTlyNAjWFxrrDCip2OviKnJ9pNdx90m1iYpyC2y8BToycc1x+IjfGJ8
        thMGsB+fyZp/JAk5D+EA5cXiTryU9fib8GDBgo2xyCfnBnIGXG9xZx3R4ss7gPn4LGKJxcUDIHlG
        Uo12XUpqLL2F6LmTTiF6rDQDBa24ZPvJzmPtsdi437zmUVnZI1boJbM/efLAiOXZj9cMIoQSnKdI
        2DHZh8GGayKbzyCwfv2B+Jt9KCdyrdT+OZcofHkXYHG5My5tudTliaNZGYc75uCeY8Fx2wvq6vJ5
        9yTYioYf9mMfwgDIXf3up+bNp9jOQFF6d12OJ6lYujY+OQFag9nG53NjjWKF3quvHh7X6TJbuvry
        XozOWdyMy06GnRIe9XNaYx95ZF24+iTyGBwQKkLev/9oiBPrXEBbLcJmkDib8HH3WWm39C63eAeE
        FDT/cBy9/PQO4GnQi08jDwMF+QfCCSoJxPelE4pE4cu7ANeZ+9Ph2v/gB4siyUYDzq9//Vr68583
        RndevsJtz7htNSLHnc9d//aOO+EW23Dxi+Qe4N6T+Ctm5uFZsC+xf7HKDyv7/uY3qyPBSC6AQYiu
        vKlTq9M3v3l5rPBbTCcWhS/vEYiZm2cgzvvvXxaJPjL6PMiuk1hjcGC6bFHbh2LVXOr0Bact/uk5
        9STwmN7L/sW+ZPlx4xlkKCXyOTTtFJ4IXsW3vjUj3XrrhDPKi6Lw5T2ERTXnz58Uwn3ggWXh9tNA
        s359Q2TXccOvu254dO5h/Zmsc9rN7/M64Z+2+MD7hA5k6MvLW6IuTwmR81MaRPRUFABvgAaeu+++
        LBYIcQaewpf3GQR92225hX3wwVVR08ftJstOzM2NLXHBmUPf2jojeuYReRGvczed0hgfV5759fli
        mT1inj/NOvffvzSm5eZdg4c7ltPic+fMGZXuumtaTP11oQ2FL/8hEBslPpJ4NNgsWLAprV3bEE01
        WH+goYbXuOPE6oQI1O9nzhwUN9tE0EzHZaIPd7YlG09sX1t78NRyW/UdzTpA+y3Te2kI+vznJ0ZC
        z5bcC5OO9Gvm4v1v9vR9f5ILDxp1aNd94okt6YUXtkcMXrqgJjE6MT9eAPkA1tajLZeJNUzFXbly
        T9qw4UDE9QwGxPmly3Aj7tGjq6J9mBo9NX4GHLkgmVtWVvaswu8kINTa2sa0cGFd9tgR1hrL/27u
        YoNXgYVnVh81+quvHhaJQwYSubCFr6vfWWK2TIwseoFLT3//ihV7IutPjM4UXrLylOOo07/+TjpA
        hp7OO5J01PRx+ydOHBCtt3gHNPS82ZLaYowvHzCIk6WyeXA7LJJ93CgzF35TuPq0/LK4B/V99idD
        Tzsw4QDC51gGEG69VdT9ReHLBQKipTbPo4DMPOU6SnRFow7NO/Txl5b25CIR/kMr/78xe6rzJxHp
        1LScIfzvPf0//iQiF5vFb2w51C97Gu5PItK5I0D+Y11G5CJE4YsofBFR+CKi8EVE4YuIwhcRhS8i
        Cl9EFL6IKHwRUfgiovBFROGLiMIXEYUvIgpfRBS+iCh8EYUvIgpfRBS+iCh8EVH4IqLwRUThi4jC
        FxGFLyIKX0QUvogofBFR+CKi8EVE4YuIwhcRhS+i8EVE4YuIwhcRhS8iCl9EFL6IKHwRUfgiovBF
        ROGLiMIXEYUvIgpfRBS+iCh8EVH4IqLwRS5mKkr+ftafQ6TTU8t//iXAAFB+74xVCMhkAAAAAElF
        TkSuQmCC" transform="matrix(0.1966 0 0 0.1966 623.3276 446.9382)"></image>
            </g>
            <g class="fag-svg-state" id="KR">
                <linearGradient id="SVGID_243_" gradientUnits="userSpaceOnUse" x1="70.2987" y1="733.3162" x2="155.6003" y2="733.3162" gradientTransform="matrix(1 -3.050094e-03 3.050094e-03 1 758.191 -347.446)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst129" d="M909.8,414.4l-72.7,0.2c-3,0-5.3-2.4-5.3-5.3l-0.1-32.3c0-3,2.4-5.4,5.3-5.4h2.5c2.9,0-5.6-15.3-5.6-15.3
                s12.8,15.3,15.6,15.3l60.2-0.2c3,0,5.3,2.4,5.3,5.3l0.1,32.3C915.1,412,912.8,414.4,909.8,414.4z" />
                <image style="overflow:visible;enable-background:new    ;" width="250" height="167" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPsAAACtCAYAAAB2pQlMAAAACXBIWXMAADJxAAAycQEELAXyAAAA
        GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAANrxJREFUeNrsnQd4VFX6/9/0SgIh
        1NB771gBsa2Coq59sa+9110Vy/qzrq5t7YJKsaIIKqjIiiCC0hGR3ptACJCQkJ6c//kcvfMPw71I
        yYSZyfk+zzwh4c7c5L7t+5ZzjoiFhYWFhYWFhYWFhYWFhUUwIkIpVVN/7WYfhYVFWCM7+g9Dn2Kf
        hYVFWGNqpH0GFhbVA9bYLSyssVtYWFhjt7CwsMZuYWFhjd3CwsIau4WFhTV2CwsLa+wWFhbW2C0s
        LKyxW1hYY7ewsLDGbmFhYY3dwsLCGnuwIj8/X9asWSN5eXlWA6oRysvLZefOnbJ27VopKyuzxh7u
        KCoqkp9++kkeeOABGTt2rGzcuNEogUV4o7CwUJYsWSLDhw+Xxx9/XFatWlXtnkHUI4880kx/vbI6
        /LF482XLlsnzzz8vH3/8sUyfPl127dol6enpkpiYKAkJCdYqwgylpaWydetWI+sXX3xRXnvtNRPZ
        S0pK5Oijj65OMl9XrYw9MzNTRowYIW+//baJ5tD5n3/+WX788UeJiIiQOnXqSM2aNc2/LUIfBQUF
        smDBAnnnnXfk6aefllmzZhm58/MtW7ZI3bp1pXPnzhIVFVUtjF2UUv1VNYA2bDVmzBilDVrpP3yf
        lzZydfvttytN85VFeGD58uXqrLPOcpV3ZGSkOuqoo9Qvv/xSXR7HlGqRs+s/VNatWyfvvfeebN++
        3fWa6OhoSU5ONl+d99hcPjRlzQvExMRISkqKaMN2LdaRt5PD79mzxxbowgW7d++W7777TiZOnOj6
        /7GxsdK1a1e57LLLjGKQ523atEnmzp0rOTk51oJCpB5Dbj5//nzzFWRkZMgll1wijRs3dn0Plfmv
        v/5apk6dWi0ce9gbe3FxscybN0+GDh1qKrL+ID9HGQYPHixt27Y1UeG3334zxZzrrrtOPvzwQ1PQ
        wQFYBG9urum4Kb5df/31hsEha5x4jx495KqrrpKkpCTX9yJb3rdhw4ZqQXvCNmfX3lqtXLlSXXnl
        la55G6/U1FR1ww03qLy8PPOe3NxcNWLECJPD8/+1atVSV1xxhZo+fbrSDMEmwkEE7YDVli1bTC3m
        tNNOU1FRUSYX79atm/r++++N/HktWbJEDRgwwPyfmw5Qx3nggQd8OhCuOXtYG3t2drZ65ZVXVGJi
        oquQtedXJ554olq4cKG5vqSkRM2YMUMdc8wxe12n83ilab4aOXKk0hTRWlkQgELqokWL1EMPPaQa
        NGiwl7yQ9wUXXKA0QzPX6iivxo8fr5o2berp9Nu1a6cmTJigdDpgjT0UlWHy5MmqU6dOrsLV9F21
        bNlSvf322z4WoKmcuu222zwVolWrVkrTemtpQQAi+n333WeiuZusGjZsqP7zn/8YQwfbtm1Tjzzy
        iNJ03vX6uLg4NXDgQKVpva3GhxIotpCLjRo1Sn799VfXazR9l9NPP10uuugi8z09d039zHu8qvX0
        4Z1iD/dgGs/m8lVXZedZ88xBfHy8KcDVqFHD9XqKdB999JGZowDI7sILL5T+/fu79tX53Dlz5siw
        YcNMQTccEZZDNVlZWfL++++bwoubMaIoJ5xwgmgKKJoCmkouBR4GL5YvX+5axNORQq699lrR9NB8
        z+SdpvyyefNm4zjs9F3ggAwZgsEYKaQ1adLETDwy+YhRMwbrX03HOdBSo3h3/PHHmwIdckLedFnc
        WrA4fO6DQ6dYG2bDNuE3VMPwzNixY1WjRo1c6Rq0r0uXLiaHc0Bud//99xtq7/YeckDNAJRWEHO9
        ViDzftKA1q1bq2effVatWLFCFRcXW35dyQVWimYzZ85Ud911l8nN+/btqzRb8xXo+L+ePXt6pl7a
        cNWrr77qG5aijvP666+bwquXfhx33HFKMwKbswd7dXbBggUm9/ISPpXXBx980FwLtPdXo0ePVvXq
        1fMUPso0adIkcz0FHApD55xzju+alJQUdf7556tvv/3WKJNF5chSsyb17rvvGgN3njVGesstt/ie
        Mx0S6i61a9f2lB+TcrNnz/Z9Nnk5HRYKr14dmuuvv17t3LnTGnuwwinCeBVtNNU2Rrp+/XqfQmlq
        qE466STPIh7R5PHHH/dVaYnuTz75pIqJidlHqajovvXWW0pTfGuthwGe9Zo1a9SQIUNcjbhFixbq
        vffe8zls5Ilx+svEeem83hi3w8zouvzwww+exVtezZo1U++884651hp7iNF3eqydO3c+aPp+4YUX
        +lo4Dn1HEbzu0bt3bzV//nxrsYcBKujjxo3zpNpE5P79+6vVq1cfFp1/7bXXDCtzuz45OVlddtll
        Zu7CVuODrPq+cuVK0VHVjLm6gdVsp512mqnAO8UY7d3Niij1xyz1XpXLqChp3769XHPNNaaowz20
        cpkVc8zZu4E57GOOOUY6duxoq2qHgbi4OPPsde7sWbBbunSpmZSjio6sOnToIDfffLNoJuD6HqYi
        R44cKQsXLvR1Y0455RQ5+eST91rlyGexGo7/o3ofVoXXcIjsO3bsUM8884wnfYfe9evXzzc8c6D0
        /YknnvDRd6j5iy++6EkViTYUdvhci8phap988onShufJorp3727oOIW8Q6HzMLXPPvvM9OSdNI/h
        qYcfftiX6tmcPYhABXzq1Kmmwu5luE2aNFEvvfSSTymg5Qxk/Bl9Z3DDyfEYl+3Ro4fnPTIyMkxV
        3rmHxeFDszSz7JiBF7fnzoDMxRdf7JtqPBQ676Ry6Aj1HNI0J09Hljk5OUqzCF8qZ439CLZmNKVW
        N910k6dwyb2YjXeKZgdbfeceGzduVHfeeafnPVC6wYMHq6ysLGuhlVyomzdvnmFMXs8eJ/v888/7
        JuWozlNYO9DqvLN+gvy9YjTHcSD3YcOGKZ36qaeeesqwDWvsRwh4XYRBq8Rr9h36jrd3IjT/3h99
        R3motjv0HeUZPny4p/JAGVFGFl5YVD7oszOi7FBtLzo/bdo0n8ww2htvvFHFx8d70vnLL7/cdZ0D
        n0Hx7qefflL33nuvkTt6QeWe1qrTAbDGXsUVW6KvF31HCdq0aWO8vLP6if7qzTff7EnfqcxeffXV
        vpzOma/3ou/cg8GaN954w9L3AIJ0itkIWNr+WqpOdR6DpSPCIicvWVOTefrpp/eK1qSEfAZRnsVQ
        Fd+L42B+g91vrLFXMb1bvHixGWTxondpaWnq7rvv9tE7WigYvpfCUGDr06ePr23GPZYtW6YuueQS
        z3uwDPbWW281qYFFYIEsBg0a5LlMNT093URiZxkyxTfad/Xr1/eUH9EaRuCAVO/ll1/2XCyDTt1x
        xx2GUVpjryKQG+OVvaquFHRY38wIq5N/kfvRm/USPDTxueee892D6akXXnjBkwqSIpx88snG6VhU
        DZP76quvVPPmzT1lyOgyBu7QeXJunLHXpBx0/qqrrvLVc4jsUHU+x+seMDlqPiFI50PP2B2h89C9
        qHX79u3VRx995HtPZmamevTRRz1bcxg0GxMynunQd1IEPsfrHigE69tDqJqpyktKVJn+28r0M+RV
        rv9djtKGSAriTC96sTMcME5+1apVvhoNrbmOHTt6Gi/OAzk6FXjauHRVvIZtCDCkB1ToQ83YQ2rV
        mzM8oyOuWXHmhlq1apllqzfddJNZlsr2ROwx9sQTT0h2drbr8Ey7du1EU37p3bu3b3jmpZdeMvvW
        eS2PPe+88+S2224zmxoG1dxEaZmU5+2R0p27pGR7lhRv3SbFm7dI0YZNUrhmnRSuWqNfa6Vw7Xrz
        s+It28x1pTt2SumubCnLzROt+b+v9tPPL5i21WalGwMv6ACbRSq/YShWL7JnID939oRn0IkhHbYL
        d9uWjFOB2GL8qKOOknr16pn3sByWwSlWQPrfA/1gCSyDPQz98NmhsuotOpQGgFhW+uWXX8rnn3/u
        OXmFwbJ3HP9G+JrKm6k3rz3GdB4mOqrLGWecYb5HWdiE8NNPP3W9nn3NevbsKTfccINRviDwgFKW
        XyCl+vcuy8mVkqwdxpB5Ff/2m5RkZkmJNmQcgPLYVBGjjkqpITHpaRJTJ13imjaW+ObNJK5JI4lJ
        qyVRqSkSzS6tCfFH/M/Vkdg4cvYpYM8Ct+XNn332mVnCPGDAADM5OXDgQJk5c6ZZ3+6/5JnvFy1a
        JK+//ro89dRT5vpmzZoZ+TJth2PxBxtVjh8/Xo499ljj9ENlKWzIRHbGIjnV47HHHjMC9Qe7wmpq
        LXfccYf069fP/ExTMvnggw98h0K4rWvnWta1Y/RsTsnRULAAZ4dS/3ugbDoPNOOUR3DsUcoLCnXU
        zpTC1Wsld9Zc2Tl+omwfPVYyPx4nuT/OkoKly01EL83Wka6o2Lxnfw6DzyvdsUuKNm6W/EVLZPcP
        P0r25KmyZ+FiKd70m5TvyRdVVqpf5RIZGyMRR0jBMSwMkoiLAZf8wUIqOvz69esbgyW6Aza4gBFw
        SITbOnZ0C11hLJpRZ9ga9+BeMAL0wosRYPCsqw+FyB4Sxu7s8c2xTYdC32EEXvT9nnvuMUqB8nDY
        Y1DTd6K4VjLoOAae9ekXkvnuR7Lrm8lSsHK1oeERlbglstJRD4qfv3ip5Gjjz501T0q1gsMQIqKj
        tNHHSURM1ZNDGBXOmYjsRHccMTrARhU4Y7aQds4A4P+Q3cHSee7BcWFu58I5dB72yD1hfNbYKwFQ
        aw5hfPnll10jNA+a/IkIjQeHmi1evFiee+45s7vJPrRV56F440svvdTsPoMyIDjSA7aQdtvdhnuw
        yIV7sGtNVefh5OCFq9dJzrQZsm3E+5L1yWdSoPPv8vz8qnM0Obu14S+T3TNmGQYg5PP62UVqo4pE
        2aswv3fO5nOcP477/PPPN/KBrWHoRH2MGCN3cnF2omERjf8prugVesbONsiZz3deLJhyO0iCawkk
        bdq0kVatWgX7sWHBb+xQKOjXk08+6UqtecAtW7aU22+/3ewvRoRGoFD3d99913VFG1sUsfqNk1zx
        +CgFTuHf//63OdXV7R7QdyJ6VdJ3KHOZpuH5S5bJzi8nSeaoD2XXV5MMfd8vLQ/071VcYmoCu026
        sEJ/XywRcfESqdOiyCqKcDhfVrjhqHHwFFhZoci/kTnOG5lOmzbNRGoKddB59q1jCzK31ZHQeaI7
        OtGlSxdfdOfn7GXnnzIAnAAGT3SH+ltjPwz6Dk179dVXZdKkSZ7U+uyzzza5Ot6chz9hwgTjHPC8
        /uAaDg548MEHTX6GYqxfv94UaCj+uQFFOfPMM+XOO++sGrpGTl5YJIWrVssObdzbho2QnO9nmOh+
        JI3cjeZTzc+du0AKdMSP0Lk8hb6oxASJiAz86mmMl5yZpcudOnUyqRnBgUo6RTqOZqaQRspF4ZYI
        j/Hy8orWMAGWw3LgY9OmTc2RYI0aNTKVedI8/+ABC8TYCQikAEFM54Pb2KFVCA1q7eZVebDk2xgu
        HpsHz/E/GLpbnoVAuA7qDuXj+9zcXPniiy8M5XcrxDiKAgtA+IGP5mWmDUaRbds778mu8ROlNCfI
        dzvVDKQkc7vkzV9ovsbUSjMVfAp5gQTyw3lTaHWi+ezZs01woD1L9GbfAqI16+NbtGhhdAZjx9A5
        4dU/ZXM+hzZt3759jUPB2ZP2USB2Ow6Me1D4C3I6H7zG7lBrqu/s4OomaCqubFgAJUdIXPfmm2+a
        /N4NeGnaMP/85z9NLubcA+dAdHe7BzuZ0oahPRdwQy8plcJ1GyRr7HjZ+tYoU2kPpkj+5/S+2BQP
        839dKtE1U0wbr6radRjhuHHjROuzfPPNN3sFBxw61XZyeQwXPYDaQ+fdWrK8l2iNjvTq1cswAig6
        TBNn4hZ4uActORwEbDMYjT1od6rBW9IjR0hunhKhnXrqqb593/HUVNHZvcQNRABoO2eB4dlxDuTn
        bDkNG/CiiexkwjlwVWEo+UuXmWieOeJ9U1kPRcBMClavkd9eHmqcVvGWrVXisIjesEAq9P6g+o6R
        cmIreoU+EYXp3Hi1zbZt2yYff/yxcQiA4h5sEIfhdios+oSuOtcHI4I2suNNodwUSXjwztRSRfpO
        5ZU9vvk5BRTabG703REWp7RiuAdL3ynOBbQ2UVAoexYslG0jP5Dsyd+HVDT3ZPZ5e7TzWm4KjAzn
        kMsHMo8nChOxodrI1i14+NN5nDk/Y3jGPxfne95D1R4DRx/d6Dy6RCSnG0R6yHBWsE1VBj2N5yEi
        DGgUxRcq8XhOvDT0/ZZbbvHtJ4fAOMmFCSmvyi2FnPvvv9/0YoOJvpdphcqdNUe2vjlccufMl3AC
        wzyF6zeYIl5808YSnVYrYAaPgSFbHDeHQLi1Tx0671BtujIYMMYLBfcHVXhyd/SNQyOcYRuHzuMQ
        KN6de+658q9//cuwwCA19OAv0Dn0m8IYlU7n+B9abFTfMWK+Z+qN01zcZt+hXLTmaJvhoQ+0+j5o
        0CAzcBPI2eeyPfmye8ZMY+hEwXAEdYji37ZI0abNktC8qUTXTguYwWO85OLMWLhtCor+EJHRG1gb
        hT1kjXOnvevG8HAQTGwSuWn1OfP55Powiauvvlr+8Y9/eJ4Bb439EKI8D5oHjtH36dPHUHwoFi0R
        pt68JuvIzzmyCSaA43Aq/FRr3YTrpAhDhgwJHH3XDscx9C3a0FmcEs4gjy/Ztt0M4iQ0b/Z7hI+K
        DIiewAah2kzKeVXOYYJEauSLg8B4cQ6kgP5DW86wDY4C3XMW13Tr1s3oITWjENmBdl1IrXojykKb
        yL8BkXz06NHmTDe3yTqEALUit+c9pAAMWdB/pZfqxgJoncAa6N0GkrozCUdEp3pdHWAMPjNLCjds
        krimTSSmbnpAIjw0GmOn5w6ddw6CrAjqP6SFRHf0AuMleNCKYyDLH8xrUDcitaP/7hzyCeMM8qm5
        vYw9pPeNpwKP0Fj44F8hdfYSv/HGG40Hd+brhw4dasYlvVgAAzowgYAV4wqLJG/Ogt9ba2vXS3VC
        uWZSeQsWypY33vm9rRggYOzs+U6b1evEVnJuUjloOg6ie/fuZqbebd95ggyUne6NCuHiaUif4kqR
        heWmfGWAwqFbeFtytyuuuEIuv/xy8z0sgP47At7ffD2FlkCtYmLGvWD5Ctk6bKSZMa+WKCuX0p07
        zZx9UvcuEhUgCgydx3Bhcm4LoWB56ARMDkrvTNc57TN0hADCz6j1MD2JPoXwya7rQv7IZgomUCsW
        QjDFhADxvvTgybsROg4Aisbsuxt9xxlAyRjQOfHEEwP2u5Zs3yE7Pv1Cdk38VqozKNqV7s6VKB0t
        E9u1DshyWQyVfJwozlJY/4UvDjPkRcGXyO0M28ybN88U5WCEF198sQkABIIQP8J5XViczw4NQzAU
        TBAIQmN4pmvXrsbwMXCOeaIw5waUAudw1113mQptYOh7oeTOnieZI94zffXqDqWfBz34xPZtdP5e
        J2CBgFYZMxhuC5wIAuTvBATWS6BHsEQYAWyAhTUssKKAFwYID2N3ojOCZXkiu5Rg6PwMzz1x4kR5
        5plnPBfGkK/Rg4fOBaY4Va5z1DWSOfx9s+7cQn7vSOTtkTItn+Se3cyKuUDoBMU3DJheutvCF35G
        dR59oX0GnadV65wDFwLr1A/Y2CPDTYeowCM0aJzTU+dAP3IxN2WguEdBDlYQMPqekyM7pswwkd2i
        AtvJz5e8eQvN1KByodmVAVgbOTeTk24zE86+hiyJdoIBjKAqFj1VNSLDWZkwdnI1HIAbFUOoKAKF
        l0C1UMp0frpzySrZ+vnXnnvAVWewSm7nhIlmC61Age2m2KiEYq5bCkgbDSoPpQ9nhA2N96JxCJKo
        TaSHrrFemVwNISP8++67zxT3AsRUJXtLlqz4+EuJmTdLIqxtuz6k8qJiiYyNluSunQJSrHPoPGke
        05a025yf0WunPctshddxz+FC48Pa2Cvm8kw/kZPTnsPgcQKsejrnnHMCFtULikpk1ewlsn3Uh5JS
        tMcathedLyySst25ktixncTWC0wxjNyblipMj51pGc5ipoJaDaPRIbQl9CEbe3R1UShyN7YWphg3
        ZswYs/CBTQkDOQG1dVuOTJ+0QI7bnWkt+k+iO/PzO8ZPlMR2bSQiQEUx6jOsfKTHTkGOzUMD1X0J
        ysDHiTD665TqpVu/5/LO7qOBQFFxqcyavlS+ffR1OWfXQmvQB4CEtq2l6f8NMe24UJZ9kGJqZHVU
        Kmc7o0Bi684C+X7mammQv8Na8QGiZPt2yZn6g9nJNpRlH6yItCpW+SgrV7J+W57MWLBJMkp32wdy
        oM8te7dpxYXqLj3W2KshcvcUyfwVmZK5I0/SygvsAzlQiq0jOmfT5c63aY819hBBZnahTFmwReJV
        qSSqEvtADgJE9by5CwJK5a2xW1QKyjWF36Lz9TkrsiRWWYU9aCq/Z4/kr1gd/NtnW2O3yC8skRUb
        d0lmXolYUz8ULq+kbNcuyV+23D6LSka0fQSVi115xTJ/5Q5t6BFSFBEtZdqfRoW42fO3FOq/JT8i
        RkoioqQ4ItL8DEQYJSrXLKbMpC0J+hWjDm/OnQGb/MXLJeXYo61CWWMPXmRrY1+weqcobQYFkTGS
        HRkntUOwSFemf/9c/bvviEzQr0TZHF1DNkanyk79fY7+eZFE/UENlSSpEvM31i/Nk0Zlu6VBWZ7+
        Pt8UJ6MPIZVhNRyrA81psZGWfFpjD0oGqoyxL9/8+77lRMOtUckhZexE7N3amNdE15KfY+vLzLhG
        sjimjvlbDkyhyrWx50qfwo1yTNEmaV66S+pqwz+YaF9eVGS2n8boo1NqWMWyxh58KCktl6ycAsku
        +F2xob3ro2tKx5Ltwe+o9KtA/77r9O+LgY9PaCMbog/+GKNSHes3RqXKh0mp8lVCazm+aIOcVrBa
        2pZmSXpZgXYl6oANnhVx1titsQclCovLzOScg5yIOBMVBxSsOmAlP1KUHao+Jy5Dxia2k4Wx9Uwa
        criA7n+d0ErmxjaU0/UzOKNwpTQpzTH5/Z86H4x9e5YktGphFcsae/ChuKTMRHYH+TpnXxWTZmhx
        anlwbkVVEvF7JCYKj9OGnh1ZuQtDcBqZUUkyOqmjLItJl0v3/CLdi7f+6fyBKikx21ZZVB5s9aMy
        jV3T+F25RXvlv9sik2RebIOgjOtU1ldG15aRyV1lVFKXSjf0iqAzMTeuobyYcox8H99U8iJj/9TY
        S62xW2MP5px9d/7eZ4ztiEqUH+KaGGUPJpTqiL4qupaM0ob+pY7qZRGBVwWc39romjI0uadMiWu2
        36If225zmIaFNfagRLlSUli6d6uJIt2vsXVlUUzw7FCK0W2OqiFjEjvIt/HNKyU/PxhavzE6Rd7X
        TOKX2Hq+fv0+15WXm00tLKyxB6exlyspLt739FDabxMS2hiafKTpPPenV/5NfCuTp6sjsFkW99yg
        Df6jxE6mf+9+kf5NA7QJpTV2i0oxJLfTgYjuVLonJrQ64H51oEB7bVZcI1MwK444cocekNYsiK1v
        DN41f9cP0m7QaY09aMHGCFEep5Nui0qSTxPby6JKamsdap6+Iqa2aa8Fshh3oKBLMT2+ifwY23hf
        xqOfZURon8BijT2cERUZIfGx3gpKnvyhjmTro1Or3OCZ0V8flWqGZeijBwvoVtCLx/D31sxIiYiN
        sUpljT14jT0hNnK/1HV+XAMZntzNFKnKq8jgiejrtIP5NKm9MXYVRJta0/5bHVNLfoprvHdg1wwp
        shptBmmNPcQQoxU0NWn//eO8iFiZGt9Mhib3MJS6JMB5M3n58uh0+SCps4xLaF8lLbaDRVZkokyL
        a7pXDQEKH5lgjb0yYSfoKhGxMZGSnvLn+49j8N/FN5edWsmZKOtQsl1qVvKEnTKr1mJNy29MUgfT
        61dBekwFjIfUhnUErUt+36AzIiZWolNTrVJZYw9OxMVESe2U+ANW8DlxDU3r6a/5y6Rv4XqzNPRw
        t7Gi0EXFf5NOE2bFNjJFwUNZ0FLVoGCIY/IZu87Xo9PSrFJZYw9eY6+XlijROncvLf/zjjo5+6ao
        FBmmKf10nbMOKlghXYq3SXp5viSXlxzU4hk+ixYfc+hLY9JND31ObMOgpO2ubEezkOXR///4pcg4
        HdnrpFulssYepA8zOlJqp8ZLvZQY2ZxdfMDvKzQ95wayXBspxt6naIO00xEuRVP7RFVqVonFSqlE
        KWUcAIZdro24RCJ9O8jk6Mi4MrqWaWWxDp2fqxA6Xa5A/75bNMuhmBgdoSQqKUli69jIbo09iFEz
        KVa6NKspm38++COfMFrWks+Oy9CGXiTNSrOlcWmO2fyCXV/Y8omdX1iphjFj4EznbdTsAKoeDL3z
        QwWtQdpvTPfVjy2X2IwGxuAtrLEHr7Enx0q3lmny9c+Hfr4bkRvDJULzqi6AqeRGxEpGjWhzFJRF
        5cK23ioZtN66taotMVH2gOaDju4RbNIZJdGpKZLYsb19INbYgxvxcdHSMiNVmqXbHvHBQqfqEhkV
        JTF10yWhVXP7QKyxBz/SU+KlX6d69kEctDIqSaqRIEmdOth83Rp7iBh7arz01cYeF20f78EgVsqk
        Ts0ESe7ZzT4Ma+yhgaSEGOnSKl3aNbDR6UARpaN6SpSSuo3qSFLnDvaBWGMPHTRIS5SBvTMk0tbp
        DggJ5SXSIiVCUo7uKREJCfaBWGM/fJSWlsrWrVtl7dq15lCHQFL5k3tkSL0DmJW3EEmJLJVO9RMk
        pe9xZl+AQKGgoEDWrFkjWVlZ1tjDFeXl5bJr1y758ccf5YUXXpAnnnhCNm3aFLD7MU3XunGqnHlU
        Q7P01cIbTAXWrRErx/ZsKvGNGgbsPsXFxTJv3jx58MEHZejQobJkyRJj/NUF1WKoBiGvW7dOvv76
        a3nnnXfkl19+kbp160qDBg3kH//4h6SkpASGytdOlAtPaCGTF26TNZl2p1TPGkeUSLfmNaXXqb0C
        6uzRgbfeeks+/PBDSUxMlKlTp8r1118vxx9/vNSrVy+gjCIo6iKPPPJIM/31ynD846DpeXl5MmvW
        LHn11VflxRdflM2bN5v/27Nnj/l306ZNpV27dhIZgAMEo/RnJsVH6+hRIjOXZ0mZsoa9b1QXaVEn
        Xm4b2Fo69moTsPvs3LlTRo8eLS+//LJJ5UpKSkwqN2PGDBMM6tevLzVr1pSo8N0Ka11Y03iMfeXK
        lfLQQw/JiBEjpLBw7zXjGzZskDfeeENWrVoVsN+BVtJZxzeVTo1TrGW7IDE2Unq1TpeT+gbO0IuK
        imT27NmGulfUAfQDh08QeOqppyQnJ7wPpYgMRwMv+2MLYqJ1jRo1DF1389h494ULF8qbb74pu3fv
        DswD1vl6m0apcu2A1pKaaPdU2+vZaNrcukGyXHFKC0lMSQwYfV+9erVJ33D8boiLizORHV3x1yFr
        7EGcm0PNZs6cKdnZ2eZnzZo1M3lZixYtPOndF198IRMnTgyYgFOT4+QvvRvLmT3rm7XuFr+jdnKM
        aU/27900YPkyevDVV1/J559/7mnovXv3luuuu07i4+ONDmzZskWmT59uujaB7NhYYz/EaE5kJv96
        /PHHjXFjwORmMTEx0r17dyPMJI8RTOg8FI+vgUKTusly3RltpVW9RGvlGvExkXJM23S5/sx2Eh0d
        mDzZqb4jW1jcvqwrUpo3by5XXXWVtG7d2uf8hw8fLldeeaWp86xYscLoUTgg5At0CAJP/Nlnn8mj
        jz4q33zzjWmxbd++XY466ihTZaXyWqtWLdNqgdK5Ub38/Hxz3dFHH62Vr/KbFND55IRYc/jBrOVZ
        UlRafQ9AgL63aVhD7jinnRzbqUHAAsDGjRvltddek8mTJ7teQxfmrLPOkjvuuMPInHz++++/NwFj
        /fr1Mn/+fMMUSQNTU1MlIbSHfdaFtLEjnF9//VXefvttefbZZ01rxTFeIj2FGdoq0DOiOsaMMPNd
        Dgyk34qT6NGjhzRq1Cgwxaj4aKlXM0E2Z+bKqi15B7R1VbgBtl43JU4G92sq157ZXqKjAkMukfG3
        335rim9uvXSMu2vXrnLvvfeaVA/6vmzZMnnuuedMMc9hBuT5pIWxsbGmXUvQsMZ+BEBO9corr8hL
        L720jwHjCJiSIrK3b9/eeGW8MxVX+uz+1IxIkJuba/7/uOOO8xVrKj1/T4qVFvWTZeWmHPltZ0G1
        MngMPU3//X89tpHce1EXqVkjMMuAoewLFiyQZ555RpYuXerye0RIw4YN5YorrpALLrjA/Awm+P77
        75tCXnmFY6fQC/To559/NgGjT58+odqPD63WG0LASzvtE4yXSFynTh3PXJzWGnkbQsvIyJBrrrnG
        GLNbX52e/JQpU0ybjj58QPImHcm6tKot/3d5d+nXId3krtXF0GtrQ7/g+EbywOCuUj89OWD0ncnI
        d999V6ZNm+Z6DY789NNPl6uvvtroAbKGBaArbrk9LKBx48amkIehcw9YY6B0pNrn7Dxc+uFUSSmi
        QL2opBK5MVIM2r+ajlCg5lzft29fk6NBw6BjFPPc+qp8Fr3XNm3aSKtWrQLixRm2YaFMh8apsmpz
        +Ed4J6Kfe1wjefjS7tK4Xo2A3Yv07dNPPzVpnZvhQsepy/zrX/8yxTkYHrk5eTo03o0FECSuvfZa
        GTx4sPkeBsjYNe9LS0szziMEon3w03iHXjsz7eRgv/32m/Tr188Yb3JystSuXdvkWRTq3Cgd7Rco
        WM+ePY2DYFIKIc+ZM8dVIbjfjh07DGXj2oAUqSIjpG6tRGnfKEXW/pYrW3YVSkkYjtjRaaxTQ0d0
        behDdEQPpKGTYzMtieE6k5L+hkuQuPnmm01kR7fQpWHDhhkH4Qb0a8CAASa3p+aDvsydO9eMWX/y
        yScmOFDjCYHpu+A2dgwSoY0fP14ee+wx+e677wyFJyLTUoNW4akRAnTshx9+MAL3B3QL46U6T67G
        9TCC5cuXmxVQ/r1UGAIOAuUgCnCPgNAqbQn1dYTv1DRVNm/PMwZfVBI+VXr+voy0BFOMu18bekbd
        wBk6MqRASw1n0qRJ7vUSnfadffbZctdddxmZkhL+73//kyeffHKf6cqKRbwhQ4ZI27ZtzT1IDV9/
        /XWZMGGC0SvqP7Tn0CcMHt2yxn4IRRaMkeIblIxWiAOKcRRUKLwxLEPxDXpOWw2q72+8fI9gyfmh
        80R3WAGMwIvOO/cIJJ13Inz92onSq3Vtyc4tkI1ZBZJfFPrTW7Gs+muQLH8/taXcfWFnSa8VWCOA
        vtN+hfntj74zOk3+jS6gX88//7wsWrTI9TOpBV122WV70XfmN6jYO0GFexEw0CMCEKlBoIq7YWvs
        0CO859NPP23+7SZc8nGoNh6bB4xwoPvk6P7AcxOtEQZeGsEEA5136GVaSpz0aFVbykvLZX3mHskt
        KJFQJfUJsVHSs0Utue2stnLdoPaSnBgb8HtSlKMFiwH7d1r86TtAF8aOHWtGpStW3ys6h2OPPVbu
        v/9+E0gc+s7S6IqBxwkmBAz6+h06dDD6ZY39IED0hRItXrzY1z/3p/gIDKFA5+mlY/QIlrzNjc5j
        vLRRqMYT1YOFzjtVrJSkOOnWsrakJUXLlh35xuCLS0PH5BmWSUuOlf6d6sp9F3aSc09oGbA+upu+
        YJQ4emRWsVLuT98p9hKJSQ1hb/uyrUhp2bKl3HbbbaY25E/f3QBTJLdnepPfxRr7QUY7ojXVTirw
        GKoX1XboPEU4Ku0YLsMQ/saLB8cD8xWvfbB0npHKQFddGbzpog2+Z8tasie/WLL3lMiewlIJ5mI9
        TyRRR/N2GTXkoj5N5P6LO0uv9lW3uy5yxogxUFgYxpqZmWkYIf/2p++ke//973/NgJUbYHHnn3++
        3HrrrSZvd6PvFeHUjx544AHDHG3OfgjgIVakUG5UGzqPJ3daaw6dJ7pDwf1B7o4isI4dA0ZJEC5R
        nHt4MQIiBl4+tQqOEaYXn1E3Wfp2rid1k2N0hC+WMm3thcXl5mvwOGSRpLgoaV4vWUfzOmb89Zoz
        20udWlVTpCJCQ6l5wdKo3SBLCrFQafQCeTH77tB35Pjxxx+bKO02804AwGFguKyEQx+YoGMJrD99
        d1gAunTDDTfIoEGDgpl4BX/rjWgN1YbOE7Hd6DwRGWHjXRGWQ9ExeLdRSYyXNh0LZJh7du5BVdVt
        bTuOAKeCY0CRcEJVgcT4GOnWOl3+0qOh1EuJNds3lZZh9GVHtE1HOy0lMVra6kh+cpd6cqvOzW/9
        a0fp3CrdOKqqABEaeUHFR44caSIwgQFnT0pHUfWEE04wOfTAgQONzJwRWvJutz3oaJ117NjRtNUY
        s+Ye6ANFP68KPwHmr3/9q9xzzz2BTfOqg7E7dJ6HCu1ym1pCiAiPNglUDQ+P8RLZaY34F2D4Hg/P
        Z5100knGQdBPJQowdeWVMnCPzp07m2JPVQ1RmL8/KVZ6tasrp/fKkBZ1E83vj7GX6yhfla06jrSq
        kxKn6XqKDOzZUO45v6PceHYH6di8tsTFVu0OZxRnidDQcYpztF2p7cDqnBYYegO1x9Ch+gzN0GZj
        GMbtOaMzsIC///3v5nuCCP13jN2tiIeDIWDQmkMnghyhMRuPsDBEoiszym6Tcs5cO3Qew+WF8Jis
        8x+2gXrhEBiGIJ+DCSA47oFR8x43isc9nA5AoPat2x84WqpD8zQ5VUf6Do1qSGpCtI6yypwLzzMg
        6lcmy8edcdBFeo1YaVY3Sbq3qCXnH9dYbj+ng1w5oI00b5haZZG8IkjnkBGdGtZHOJSeQivdGGQJ
        Y6vYQUFnqONQgUeP/FNCnMOpp55qDBcn4UzWcQ+vAR3ugWM477zz7ARdZcIxXoydFoebAmCICIp5
        ecdB8D4KfA6dh2pRzDv33HMN9WL/uYr3oMDHSjq3/AwF4B7QxF69elUZnd/H6HUUbd24ppzSI0PO
        6J0hbRsmS21tkDW08ddIiDGtr6g/ZrgPJsfnABsKbWn6sxqmJUjL+snSq1WaWbhynTbu2zRVP7V3
        Y2lYJzkge/YdaDEO+TP15l8Z5/9gbMgPB37MMcfs5eDRny5duphcHjni2HkP9B3G9s9//tOwQ35G
        gGBRzLhx4zzTy9NOO8205oJ4kGYvYw+Z3WURFkUXeqXkURTZ/IGAWABB7k7OheGffPLJprLK4hai
        OUbKaie8sbM+Ga9PIYbvqbjfdNNNJh9klNIf27ZtM7uTokj9+/c/oh6dqNqgTg05r38N0+bKLyyR
        FRuy5de1O2Xpxt2ybtse2ZZTIPnF5aaFV1paZowf+48wz1RMa4xtr4ngNbWzwMhbNKgh7ZukSqdm
        taRpg5SAbS5xKKDCzvp0Vqh5sUAq4nRbnJQN2eLkcdIwP2SMniBH2AAMgLwbXQGkd+w8O2rUKHen
        qJlDp06dTFEOpxIy0F6svwoh6PxM3X777Urn2YSsfV7a46qLL75YacM31+torGbNmqUGDBigbrnl
        FrV06VLfZ2kjV9u3b1fff/+9+uqrr5RWCvNzbeRKe3mllcP1HtopKM0MzO8S7CguKlFbtuWopWuy
        1KxfNqspc9arb2evV5P164f5G9SCpVvV6o27VNauPapcP4+g/lu0fHRurrQzd5WLdryqSZMm6qWX
        XlLayM17tHNWn3/+uZozZ47SaZjvszTTU1OmTFE6ECjt/I3MgWaI6qefflI6WHjeIyMjQz377LO+
        e4QIpoScsWOgOl9Txx13nKsweDVs2FA999xzqrCw0LxHe2ql8zWl6dtewl60aJF68sknVcuWLZWO
        BOZ7gBAXLlyodOQ2wnW7R/369c17NRVUFoEHMlm7dq3SrMtT7joNU1deeaXSFN0n408//VQ1btxY
        HXXUUWrkyJFqw4YNRocqOnsnAHAPnSKoO++80/MeBJPBgweb94UYQs/YgaZyStM4pfNrV4Foyq+6
        d+9uooC/93UEPH78eBOdNe0z70lLS1N33323cQwAIx4zZoxxHF4eXud56rvvvgs1Dx+S2L17t3rr
        rbdUamqqqzyQI9F45syZPsNdsmSJkbFzTXp6utJp4D5RvmIAGDt2rNLU3PUemr6ro48+Wk2bNi0U
        H2FoGrtD5/HAiYmJnh74oosu2otqQ+nXrVun/v3vf6tmzZrt85727durL7/80lA5h87fe++9JmJ4
        0Xmd66n169dbawwgioqKlM6hPek7zl3n6erll1/2Od6dO3ea7/31Q+fuJsq/99575pqK4Ps33nhD
        tWvXzuiPW4oAYwxR5x66xs4Dh3affvrpRthuSlCvXj31yCOPmKjgROtPPvlE1alTx/V6FOGUU05R
        v/76617R4ayzzlJRUVGu7+GzhgwZonJycqxVBihtW7Zsmbr88ss9qXWtWrXUrbfe6pMzEXrChAmq
        TZs2rtcjSxwHtRw31vjNN9+ov/3tb4b+O7oFo7jhhht8KYI19iPg8SdOnGi8upcitG7dWo0bN86X
        p61Zs0ZdeumlhpK5Xe8vVIpC3377rfkcr3uQ848ePdowB4vKRVZWlnrmmWd86Zb/i0Ltaaedplas
        WOFjb9RbBg0a5Ckvx0E7BVlqO1u3bvUxOkCq98orr6iuXbuqlJQUEwQWL14cyo9ySkhvOEl/lLaJ
        /kPM/LLb7DytGibpWOnmbCFEL51RWrcVTwxncD0DE4xOOkthuRcDG26z89yDViDtHp0XikXlAFkw
        GcfOM27jrbRjaaOxFTTrFgCy++CDD8xyV7epN9pvXMvCGPQBeTI8o2m9b+UkX+md03tHB7gPW047
        rbkQxbqQjuwOoN0nnXSSpyenMEPu7RTfiNp4bW3EnoWYPn36mAjhgIot7Tuve1Dgo4bgVvixODT6
        Tgp13nnn7feZ33PPPb6uC/T9s88+M7m1F32nqMo1Tpq2fPlydckllxiZd+rUSb3++uuG/TlRnmv4
        fOceoRzZw8LYMbBRo0YZ4XspBsW3SZMm+d5zsHQeR/HRRx8Zx+F1j7Zt25p+vcXhgzYp/XIv+u7U
        Vw6Wvj/wwAM+Q6bOgnFXnKcg/6fvTienYqs2DBAexg6osl9zzTWeykF1laILOSBA4AzTaJrmqRzU
        AujNOspBj5bWDYrmdj2VXwZ6NJW01nqYUX327Nn7HZ6hmzJs2DDfe3QapR577DFP+WPQZ555ptET
        xznQpmO+wo3ZwQCQvZPXh4Oxh82m5SxqYVECOZYbGIFkg4oxY8aY3J6RR+akb7zxRs8tp5wZbI6N
        AmwpzBlg3bp1c72eHJGc0W0prsXBLXRhLJmaitsMPmPN1EcYcQVsOUY9BVm51W2ot5Dbc2YAa88B
        n82GFNR63NZAMJL95Zdfup4eFLIIl8ju0DK8vRedJ2djKGLu3LmHTOdJGfD4Fek87RnuOXDgQNPy
        KQvysdNQAOzozTffNMNRFXveyImIzzDTodJ3UjJasA0aNPDUE+5Lp8fS+BCm87RRNAM4aDo/YsQI
        VzpP64d6AIUiJ3+0qBxQFJs+fbpJjZhHx6ky0chQlONQD4W+M279l7/8xVPezGc8+uij4dZKDT9j
        R0AsZOjZs6fnXDvVWgozVG8PpDqPp6c678xQo2iwAxSGCj2FuzCo1gYlqIbTA3/hhReMDJh9Z3EL
        YEiK2ff9Vd91qrZX9Z2JSnrsXkNS1F0uvPBCtXnz5nB7lOFn7A7VZnaeaOClBD169FCTJ0/2jT46
        dN5LCZzWWsVpPNo2TsSwCCwYoOJZOw4XlkURb38tV+j7Qw895IvQpHlvv/225wQl7IAFViE6+149
        jR2wxJU8jfl1L3pHngclB1RdMf5WrVrtt33HKKXFkQdpGKsOvWRFisVkHU7ckS/z9Th5rwo/6RqM
        L0wxJWyPEGVXEp3rmck5tw0mqOByPhybWlBxZVKOXUo4wM/tRA8mqpiOY4cTi+Co2DP9RnXd/4w1
        KvhsYHH55Zebr+qP3W3Y8MJt/zngbHTC6S9hi3CN7A7VZsmiF53Hm1OYI1o7dB5qzko2h87zlaW0
        THLZ5azBV7EfOnSo6bBQeK04GHPHHXf4ajLQdyr7XqsXoe/9+vXbq0tjaXw1oPPkhkzasfCF97AQ
        4uGHH7bLWIMU0HMdrU2HRUd5I7NTTz3VrJQ7GPrO0tYwR2gvhDkQVNx33usYKXaldQ7+c450ZvNJ
        Bm/uvvtuufrqq82e5BbBuRiKRUvs+Ms20nz/t7/9TU488UTfsU2vvfaaGZBxA7sEc7gDm48G6bFN
        diHMwcDZgYT+qZd3J4LPmDFjr/c4LR6L0InyUHuHvtOVGT58uCd9Z0CH3W3CnL6Hf4HOf1kju8qy
        y6x/MeePuoXZOnr06NG+Pel5D0thLUIHFFlZtorsALsDs0+82ynAACZwzjnnSM+ePavF84msLooA
        1aM6zyGQFUGl3jkwwjlXziJ8KD7GzylBpGT+8/WkbehEtXke4Z6zV2zHkIuzOQEbV7AwBgXg8D6O
        gGIDBBa5OFHBIvSB8+b8AAyeU2B40XJ1FkFxKAQHilQTrKs2xg4owEDNMXTO/WKFHGd7Pfjgg4bK
        +Xt/i9AGrI0CLeexsUrO2YWIohxF10svvTQUjm2qNGOPoECn/zGluvzF5OdU5hmmOeOMM8xJn0fq
        KCOLqgVdF05jXbRokdx+++2hdZrL4WNqtTN2x+B5WSOvfqjGsp9aLXkr1K0a0TcLK3sDG9osLKyx
        W1hYWGO3sLCwxm5hYWGN3cLCwhq7hYWFNXYLCwtr7BYWFtbYLSwsrLFbWFhjt7CwsMZuYWFhjd3C
        wiIkwKq3bP2aah+FhUVY42f7CCwsqgn+nwADAKllif8z9g8vAAAAAElFTkSuQmCC" transform="matrix(0.2194 0 0 0.2194 847.0674 374.8577)"></image>
            </g>
            <g class="fag-svg-state" id="VN">
                <linearGradient id="SVGID_244_" gradientUnits="userSpaceOnUse" x1="775.5" y1="803.5817" x2="864.2999" y2="803.5817" gradientTransform="matrix(1 0 0 1 0 -352)">
                    <stop offset="0" style="stop-color:#023DFA" />
                    <stop offset="0.5155" style="stop-color:#007CFE" />
                    <stop offset="1" style="stop-color:#2BD5FF" />
                </linearGradient>
                <path class="fagst130" d="M857.8,481.8h-75.7c-3.1,0-5.6-2.5-5.6-5.6v-33.6c0-3.1,2.5-5.6,5.6-5.6h2.6c3,0-5.8-16-5.8-16
                s13.2,16,16.2,16h62.6c3.1,0,5.6,2.5,5.6,5.6v33.6C863.3,479.3,860.8,481.8,857.8,481.8z" />
                <g id="VIETNAM_2_">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_245_" x="797.2" y="445.4" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_246_">
                                                <use xlink:href="#SVGID_245_" style="overflow:visible;" />
                                            </clipPath>
                                            <rect x="797.2" y="445.4" class="fagst131" width="46" height="26.8" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <defs>
                                                <rect id="SVGID_247_" x="797.2" y="445.4" width="46" height="26.8" />
                                            </defs>
                                            <clipPath id="SVGID_248_">
                                                <use xlink:href="#SVGID_247_" style="overflow:visible;" />
                                            </clipPath>
                                            <polygon class="fagst132" points="823.4,460.7 825.5,466.9 820.2,463.1 814.9,466.9 817,460.7 811.7,456.9 818.2,456.9 
                                            820.2,450.7 822.2,456.9 828.7,456.9 								" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
          

        <svg version="1.1"
            #mapSvg id="mapsvg" class="ts300 position-absolute p-0" style="overflow: hidden; width: 70vw; margin: 0 15vw; box-sizing: border-box; enable-background:new 0 0 1009.7 666;"
            id="Layer_1_map" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:mapsvg="http://mapsvg.com" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1009.7 794"
            xml:space="preserve">
            <style type="text/css">
                .st0{fill:url(#AD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st1{fill:url(#AE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st2{fill:url(#AF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st3{fill:url(#AG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st4{fill:url(#AI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st5{fill:url(#AL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st6{fill:url(#AM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st7{fill:url(#AO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st8{fill:url(#AR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st9{fill:url(#AS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st10{fill:url(#AT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st11{fill:url(#AU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st12{fill:url(#AW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st13{fill:url(#AX_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st14{fill:url(#AZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st15{fill:url(#BA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st16{fill:url(#BB_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st17{fill:url(#BD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st18{fill:url(#BE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st19{fill:url(#BF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st20{fill:url(#BG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st21{fill:url(#BH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st22{fill:url(#BI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st23{fill:url(#BJ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st24{fill:url(#BL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st25{fill:url(#BN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st26{fill:url(#BO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st27{fill:url(#BM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st28{fill:url(#BQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st29{fill:url(#BR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st30{fill:url(#BS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st31{fill:url(#BT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st32{fill:url(#BV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st33{fill:url(#BW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st34{fill:url(#BY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st35{fill:url(#BZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st36{fill:url(#CA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st37{fill:url(#CC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st38{fill:url(#CD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st39{fill:url(#CF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st40{fill:url(#CG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st41{fill:url(#CH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st42{fill:url(#CI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st43{fill:url(#CK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st44{fill:url(#CL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st45{fill:url(#CM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st46{fill:url(#CN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st47{fill:url(#CO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st48{fill:url(#CR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st49{fill:url(#CU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st50{fill:url(#CV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st51{fill:url(#CW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st52{fill:url(#CX_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st53{fill:url(#CY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st54{fill:url(#CZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st55{fill:url(#DE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st56{fill:url(#DJ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st57{fill:url(#DK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st58{fill:url(#DM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st59{fill:url(#DO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st60{fill:url(#DZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st61{fill:url(#EC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st62{fill:url(#EG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st63{fill:url(#EE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st64{fill:url(#EH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st65{fill:url(#ER_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st66{fill:url(#ES_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st67{fill:url(#ET_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st68{fill:url(#FI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st69{fill:url(#FJ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st70{fill:url(#FK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st71{fill:url(#FM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st72{fill:url(#FO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st73{fill:url(#FR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st74{fill:url(#GA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st75{fill:url(#GB_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st76{fill:url(#GE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st77{fill:url(#GD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st78{fill:url(#GF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st79{fill:url(#GG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st80{fill:url(#GH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st81{fill:url(#GI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st82{fill:url(#GL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st83{fill:url(#GM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st84{fill:url(#GN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st85{fill:url(#GO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st86{fill:url(#GP_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st87{fill:url(#GQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st88{fill:url(#GR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st89{fill:url(#GS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st90{fill:url(#GT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st91{fill:url(#GU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st92{fill:url(#GW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st93{fill:url(#GY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st94{fill:url(#HK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st95{fill:url(#HM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st96{fill:url(#HN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st97{fill:url(#HR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st98{fill:url(#HT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st99{fill:url(#HU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st100{fill:url(#ID_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st101{fill:url(#IE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st102{fill:url(#IL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st103{fill:url(#IM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st104{fill:url(#IN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st105{fill:url(#IO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st106{fill:url(#IQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st107{fill:url(#IR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st108{fill:url(#IS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st109{fill:url(#IT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st110{fill:url(#JE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st111{fill:url(#JM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st112{fill:url(#JO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st113{fill:url(#JP_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st114{fill:url(#JU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st115{fill:url(#KE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st116{fill:url(#KG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st117{fill:url(#KH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st118{fill:url(#KI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st119{fill:url(#KM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st120{fill:url(#KN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st121{fill:url(#KP_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st122{fill:url(#KR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st123{fill:url(#XK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st124{fill:url(#KW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st125{fill:url(#KY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st126{fill:url(#KZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st127{fill:url(#LA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st128{fill:url(#LB_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st129{fill:url(#LC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st130{fill:url(#LI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st131{fill:url(#LK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st132{fill:url(#LR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st133{fill:url(#LS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st134{fill:url(#LT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st135{fill:url(#LU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st136{fill:url(#LV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st137{fill:url(#LY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st138{fill:url(#MA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st139{fill:url(#MC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st140{fill:url(#MD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st141{fill:url(#MG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st142{fill:url(#ME_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st143{fill:url(#MF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st144{fill:url(#MH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st145{fill:url(#MK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st146{fill:url(#ML_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st147{fill:url(#MO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st148{fill:url(#MM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st149{fill:url(#MN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st150{fill:url(#MP_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st151{fill:url(#MQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st152{fill:url(#MR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st153{fill:url(#MS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st154{fill:url(#MT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st155{fill:url(#MU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st156{fill:url(#MV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st157{fill:url(#MW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st158{fill:url(#MX_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st159{fill:url(#MY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st160{fill:url(#MZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st161{fill:url(#NA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st162{fill:url(#NC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st163{fill:url(#NE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st164{fill:url(#NF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st165{fill:url(#NG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st166{fill:url(#NI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st167{fill:url(#NL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st168{fill:url(#NO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st169{fill:url(#NP_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st170{fill:url(#NR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st171{fill:url(#NU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st172{fill:url(#NZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st173{fill:url(#OM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st174{fill:url(#PA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st175{fill:url(#PE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st176{fill:url(#PF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st177{fill:url(#PG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st178{fill:url(#PH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st179{fill:url(#PK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st180{fill:url(#PL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st181{fill:url(#PM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st182{fill:url(#PN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st183{fill:url(#PR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st184{fill:url(#PS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st185{fill:url(#PT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st186{fill:url(#PW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st187{fill:url(#PY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st188{fill:url(#QA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st189{fill:url(#RE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st190{fill:url(#RO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st191{fill:url(#RS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st192{fill:url(#RU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st193{fill:url(#RW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st194{fill:url(#SA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st195{fill:url(#SB_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st196{fill:url(#SC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st197{fill:url(#SD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st198{fill:url(#SE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st199{fill:url(#SG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st200{fill:url(#SH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st201{fill:url(#SI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st202{fill:url(#SJ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st203{fill:url(#SK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st204{fill:url(#SL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st205{fill:url(#SM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st206{fill:url(#SN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st207{fill:url(#SO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st208{fill:url(#SR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st209{fill:url(#SS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st210{fill:url(#ST_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st211{fill:url(#SV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st212{fill:url(#SX_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st213{fill:url(#SY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st214{fill:url(#SZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st215{fill:url(#TC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st216{fill:url(#TD_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st217{fill:url(#TF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st218{fill:url(#TG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st219{fill:url(#TH_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st220{fill:url(#TJ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st221{fill:url(#TK_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st222{fill:url(#TL_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st223{fill:url(#TM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st224{fill:url(#TN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st225{fill:url(#TO_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st226{fill:url(#TR_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st227{fill:url(#TT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st228{fill:url(#TV_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st229{fill:url(#TW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st230{fill:url(#TZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st231{fill:url(#UA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st232{fill:url(#UG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st233{fill:url(#UM-DQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st234{fill:url(#UM-FQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st235{fill:url(#UM-HQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st236{fill:url(#UM-JQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st237{fill:url(#UM-MQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st238{fill:url(#UM-WQ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st239{fill:url(#US_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st240{fill:url(#UY_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st241{fill:url(#UZ_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st242{fill:url(#VA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st243{fill:url(#VC_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st244{fill:url(#VE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st245{fill:url(#VG_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st246{fill:url(#VI_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st247{fill:url(#VN_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st248{fill:url(#VU_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st249{fill:url(#WF_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st250{fill:url(#WS_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st251{fill:url(#YE_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st252{fill:url(#YT_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st253{fill:url(#ZA_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st254{fill:url(#ZM_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
                .st255{fill:url(#ZW_1_);stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
            </style>
            <linearGradient id="AD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="334.4579" x2="0.1864" y2="334.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AD" class="stcustom0 st0" d="M479.9,331.5L479.9,331.5l-0.3,0.2l-0.1,0.1h-0.1h-0.1l-0.1-0.1v-0.1v-0.1v-0.1v-0.2l0.1-0.1l0.1-0.1
                h0.2l0.4,0.1l0.1,0.1v0.1L479.9,331.5L479.9,331.5z"/>
            <linearGradient id="AE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="273.1578" x2="0.1864" y2="273.1578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AE" class="stcustom0 st1" d="M633.1,388.8l0.2,0.3v1.7v0.1h-0.1l-0.1,0.1l-0.1,0.2l-0.2,0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.2-0.2
                l-0.1-0.2l0,0h0.1V391v-0.1h-0.1h-0.1L632,391l-0.1,0.1l-0.1,0.1v0.3v0.3v0.1l-0.1,0.2v0.3l0.1,0.2v0.1v0.1l-0.1,0.3l0.1,0.1h0.4
                l0.1,0.2l0.1,0.2v0.1l-0.3,0.1l-0.3,0.1h-0.2l-0.4,0.1l-0.2,0.2l0.1,0.1l0.1,0.1v0.2l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.2,0.4l-0.2,0.6
                l-0.2,0.5v0.3v0.2v0.4l-0.2,0.2l0,0h-0.2h-0.1h-0.2h-0.3l-0.4-0.1l-0.5-0.1l-0.6-0.1l-0.6-0.1l-0.6-0.1l-0.6-0.1l-0.6-0.1l-0.6-0.1
                l-0.5-0.1l-0.4-0.1h-0.3h-0.2h-0.1h-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2
                l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2v-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.2V393v-0.1l0.1-0.2v0.1l0.1,0.2h0.2h0.1
                l0.1,0.5l0.1,0.2l0.2,0.1h0.6l0.4-0.1l0.7-0.4l0.4-0.1h1.1l0.9,0.1l1.3,0.1h0.3l0.7-0.3l0.4-0.3l0.3-0.1l0.2-0.2l0.1-0.3l0.1-0.2
                l0.1-0.1l0.1-0.2l0.1-0.3l0.2-0.3l1-0.7l0.6-0.6l0.1-0.2l0.3-0.3l0.3-0.3l1.2-0.9l0.2-0.4l0.1-0.4l0,0h0.1l0.1,0.1v0.3l-0.1,0.3v0.3
                v0.2l0.1,0.1l0.2,0.1L633.1,388.8L633.1,388.8L633.1,388.8z M633,390.1V390v-0.1h-0.1v0.1v0.2h0.1V390.1L633,390.1z M628,392.5
                L628,392.5l-0.1,0.1l-0.2-0.1l-0.1-0.1l0.1-0.1h0.1l0.1,0.1L628,392.5L628,392.5z M624.8,393.1h-0.2l-0.2-0.1l0.4-0.2l0.1-0.1
                l0.1-0.1l0.1,0.1l-0.1,0.2l-0.1,0.1L624.8,393.1L624.8,393.1z M622.8,393L622.8,393l-0.1-0.1v-0.1l0.1-0.1l0.1,0.1L622.8,393z
                M626.4,393.3v0.1h-0.3l-0.1,0.1h-0.2l-0.2-0.1l0.2-0.1l0.4-0.1L626.4,393.3L626.4,393.3L626.4,393.3z"/>
            <linearGradient id="AF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="304.3579" x2="0.1864" y2="304.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AF" class="stcustom0 st2" d="M685.3,350.7L685.3,350.7l-0.3-0.1l-0.1-0.1l0,0l-0.2,0.1l-0.3,0.1l-0.5,0.3v0.1l0.3,0.3l0.1,0.1l0,0
                l-0.3,0.1l-0.7,0.3l-0.4,0.2h-0.1l-0.3-0.1l-0.4-0.1H682h-0.9h-0.8L680,352l-0.6,0.1H679l-0.3,0.1l-0.3,0.1l-0.3,0.1h-0.2l-0.3,0.1
                l-0.2,0.2l-0.5,0.3l-0.3,0.2l-0.1,0.2h-0.2H676l-0.2,0.2l-0.2,0.3l-0.4,0.4l-0.2,0.2l-0.1,0.3l0.1,0.1l0.3,0.2l0.2,0.2l0.1,0.2
                l0.2,0.4l0.1,0.4l0.1,0.2v0.3v0.2l-0.1,0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.1v0.1l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.2,0.3l-0.3,0.2l0.1,0.3
                l-0.2,0.3l-0.3,0.3l-0.1,0.3l-0.1,0.2l-0.1,0.1v0.1l0.1,0.2l0.2,0.2v0.3v0.2v0.3l-0.1,0.2l-0.6,0.2l-0.5,0.1h-0.7h-0.3h-0.2
                l-0.7-0.3l-0.3,0.2l-0.1,0.4l0.5,0.6l0.2,0.3l0.2,0.6l0.2,0.3l-0.1,0.3l-0.5,0.3l-0.5,0.3l-0.6,0.1l-0.4,0.1l-0.2,0.1l-0.1,0.6
                l-0.1,0.2v0.3l-0.1,0.3l-0.2,0.2l-0.1,0.3v0.6l0.1,1l-0.3,0.3l-0.3,0.3l-0.3,0.2l-0.3,0.1h-0.2l-0.2-0.2l-0.1-0.2l-0.2-0.1h-0.2
                l-0.2,0.1h-0.3l-0.3-0.1h-0.2l-0.1,0.1l-0.3,0.3l-0.8,0.4h-0.3l-0.1,0.1l0.1,0.2l0.1,0.1l0.2,0.1v0.1l-0.2,0.1l-0.2,0.1l-0.4,0.1
                l-0.5,0.1l-0.5-0.1l-0.2-0.2h-0.3l-0.3,0.1l-0.3,0.2l-0.3,0.5l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.3,0.2l-0.1,0.4l-0.2,0.6v0.3v0.6
                l-0.1,0.4l-0.1,0.3v0.2l0.2,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.1l-0.6,0.2l-0.8,0.2l-0.5,0.2l-0.8,0.2l-0.2,0.1h-0.5h-0.3h-0.3H656
                l-0.4,0.1l-0.4,0.1l-0.3,0.1l-0.2,0.1l-0.1,0.1l-0.4-0.1l-1.1-0.2l-3.1,0.3l-0.3-0.1l-1-0.3l-1.3-0.4l-0.8-0.3l-1.1-0.4l0.7-0.9
                l0.6-0.8l0.6-0.8l0.6-0.8l0.1-0.3v-0.5l-0.2-0.7l-0.3-0.3l-0.9-0.1L646,370l-0.7-0.1h-0.1l-0.1-0.5v-0.2v-0.5v-0.4l0.1-0.6v-0.3
                l-0.3-1.2l-0.2-0.6l-0.2-0.7v-0.2v-0.3l0.4-0.6l0.1-0.1l0.3-0.3l0.2-0.2v-0.1l-0.3-0.1h-0.4l-0.2-0.1l-0.2-0.2l-0.1-0.2l0.1-0.4
                l-0.1-0.9l0.2-0.4l0.2-0.3h0.7l-0.2-0.3l-0.1-0.2l-0.1-0.1v-0.1v-0.1h0.2l0.1-0.1l0.2-0.2l0.1-0.1v-0.2l0.1-0.1l0.1-0.2l0.1-0.2
                v-0.2l0.1-0.3v-0.2l0.1-0.1l-0.1-0.2l-0.1-0.2v-0.2l0.1-0.1l0.1-0.1v-0.2l0.1-0.2l0.1-0.2l0.1-0.1v-0.1l-0.1-0.2h0.2l0.1,0.1
                l0.1,0.2l0.3,0.3l0.2,0.1h0.3h0.3l0.3-0.1h0.1l0.3,0.2l0.3,0.3l0.1,0.1l0.1,0.2l0.1,0.1l0.2-0.2l0.2-0.1h0.2h0.2l0.2-0.1l0.1-0.1
                l0.4-0.3l0.3-0.2l0.2-0.1l0.1-0.4l0.1-0.2l0.1-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.2l0.1-0.1h0.1h0.3l0.6-0.2l0.5-0.2l0.5-0.2h0.2h0.2h0.1
                v-0.1l0.1-0.2l0.3-0.1l0.5-0.3l0.4-0.4l0.2-0.3l0.1-0.4l0.2-0.7l0.2-0.7l0.1-0.3l0.1-0.3l0.4-0.2l0.4-0.2h0.6h0.7l0.1-0.4l0.1-0.4
                l0.1-0.2l0.2-0.1h0.1l0.4,0.2l0.6,0.3l0.7,0.2l0.3,0.1h0.1l0.9-0.1l0.7,0.1l0.4,0.4l0.3,0.1l0.3-0.2h0.2l0.1,0.1h0.2h0.3l0.2,0.1
                v0.1v0.1l0.2,0.3l0.3,0.3l0.3,0.1l0.4-0.3h0.1l0.1-0.1v-0.2l0.2-0.2l0.4-0.2l0.3-0.1l0.1-0.1h0.2h0.2h0.1v-0.1l0,0h0.1h0.1h0.1
                l0.3,0.2l0.4,0.4l0.2,0.2h0.1l0.1-0.1l0.2-0.2v-0.3l-0.1-0.4l0.1-0.3l0.2-0.2l0.4-0.1l0.5-0.1h0.3l0.1,0.1l0.2,0.1h0.2l0.2-0.1
                l0.2-0.3v-0.4l-0.2-0.4v-0.1l0.1-0.1l0.2-0.2l0.3-0.3l0.3-0.4l0.3-0.5l0.3-0.3l0.4-0.1l0.5,0.1l0.6,0.4l0.2,0.5l-0.1,0.6v0.3
                l0.1,0.1h0.2l0.3-0.1h0.2l0.1,0.1v0.2l-0.1,0.2l-0.1,0.7l-0.1,0.6l-0.1,0.6l-0.1,0.5l0.1,0.4l0.2,0.6l0.2,0.4l0.2,0.1h0.2h0.2
                l0.4-0.3l0.6-0.5l0.6-0.3l0.8-0.2l0.3-0.5l0.4-0.3l0.9-0.5l0.5-0.2h0.3l0.3,0.1h0.1h0.1h0.2v0.2v0.2l-0.2,0.1l-0.1,0.1l0.1,0.1h0.3
                l0.6-0.2l0.4-0.1h0.3l0.1-0.2l0.2-0.2h0.3l0.3,0.1h0.2h0.4l0.2,0.1l0.3,0.2L685.3,350.7L685.3,350.7z"/>
            <linearGradient id="AG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="252.2579" x2="0.1864" y2="252.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AG" class="stcustom0 st3" d="M301.9,414.6l-0.1,0.1h-0.3l-0.1-0.1v-0.1l0.2-0.2l0.2,0.1l0.1,0.1h0.1L301.9,414.6L301.9,414.6
                L301.9,414.6L301.9,414.6z M301.8,413L301.8,413l-0.3-0.1l-0.1-0.3v-0.1l0,0h0.1h0.1l0.1,0.1L301.8,413z"/>
            <linearGradient id="AI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.8079" x2="0.1864" y2="254.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AI" class="stcustom0 st4" d="M298.3,411.1l-0.4,0.1v-0.1l0.4-0.2h0.1L298.3,411.1z"/>
            <linearGradient id="AL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="329.2578" x2="0.1864" y2="329.2578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AL" class="stcustom0 st5" d="M531.4,331.4l0.1,0.1l0.2,0.4l0.2,0.3l0.3,0.1l0.2,0.1l0.2,0.2l0.1,0.2l0.1,0.6v0.4v0.2l0,0l-0.1,0.6
                v0.3v0.2l-0.1,0.1l-0.1,0.1l0.1,0.5v0.2v0.2l0.2,0.5l0.1,0.2l0.1,0.1l0.2,0.5l0.1,0.1h0.4l0.2,0.1l0.1,0.1v0.1v0.3l0.1,0.2l0.1,0.2
                v0.1l-0.1,0.2l-0.1,0.3l-0.2,0.1l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1-0.1l-0.1,0.3v0.1l-0.1,0.1l-0.2,0.1h-0.2l-0.1,0.1
                l-0.1,0.1l-0.2,0.2h-0.1v0.1l0.1,0.2l0.1,0.2v0.1h-0.1H532v0.1v0.2v0.1l-0.1,0.1l-0.1,0.1h-0.2l-0.2-0.1h-0.1h-0.1v-0.3l-0.1-0.3
                l-0.3-0.6l-1-0.6l-0.2-0.3l-0.1-0.2l-0.1-0.2h0.1l0.1,0.1l0.1,0.1l0.1-0.1l-0.1-0.2l-0.3-0.6v-0.2l0.1-0.5l0.2-0.5V337l0.1-0.5
                l-0.1-0.3v-0.4l0.2-0.5l0.1-0.1l0.1-0.2v-0.5l-0.3-0.3h-0.4V334v-0.3v-0.1v-0.2l-0.1-0.2l-0.1-0.2l0.1-0.3l0.2-0.4l0.2-0.3l0.2-0.3
                l0.1-0.3l0.2-0.2l0.1-0.1l0.1,0.1v0.1v0.3v0.1l0.1,0.1h0.2l0.2-0.1L531.4,331.4L531.4,331.4z"/>
            <linearGradient id="AM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="325.1079" x2="0.1864" y2="325.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AM" class="stcustom0 st6" d="M605.6,344.8h-0.5l-0.4,0.2h-0.2l-0.1-0.3l-0.1-0.2l-0.3-0.6l0.1-0.2l-0.1-0.1l-0.4-0.2l-0.1-0.1v-0.1
                v-0.3v-0.2l-0.1-0.1h-0.2l-0.2,0.1l-0.4,0.2l-0.3-0.1l-0.2-0.1l-0.1-0.1l-0.2,0.1h-0.1v-0.3l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.5,0.2
                l-0.3,0.1l-0.1-0.2l-0.5-0.5l-0.5-0.4l-0.3-0.2h-0.3l-0.5,0.1h-0.2l-0.4-0.2l-0.4-0.2v-0.1l0.1-0.1l-0.1-0.3l-0.2-0.4v-0.1l-0.1-0.2
                l-0.1-0.1l0.3-0.3l0.1-0.3v-0.3l-0.1-0.3l-0.2-0.5l-0.1-0.1l-0.2-0.1l-0.2-0.2v-0.2h0.1h0.4l0.4-0.1l0.3-0.1l0.5-0.1l0.2-0.1h0.2
                l0.7,0.1l0.3-0.1h0.8l0,0l-0.1-0.1l0,0l0.5-0.1h0.1l0.1,0.2l0.2,0.2l0.2,0.1l0.1,0.1v0.1l-0.3,0.1l0,0l0,0h0.1l0.5,0.2h0.3l0.1,0.1
                l0.1,0.1l0.2,0.2l0.2,0.2v0.1v0.1l-0.5,0.4l-0.1,0.1v0.1l0.2,0.4l0.3,0.4l0.5,0.3l0.6,0.3v0.2l-0.1,0.3l-0.1,0.2v0.1h-0.1h-0.6h-0.1
                l0,0l0,0l0.2,0.1l0.4,0.3l0.2,0.3l0.2,0.1l0.2,0.2l0.2,0.2l0.3,0.3l0.3-0.1l0.4,0.2v0.2v0.1l-0.3,0.1v0.1v0.1v0.1l0.2,0.1l0.2,0.2
                l0.2,0.3l-0.1,0.1h-0.2H605l-0.1,0.1v0.1l0.2,0.2v0.1v0.3L605.6,344.8L605.6,344.8z M601.5,337.1L601.5,337.1v-0.2h-0.1h-0.1v0.1
                v0.1H601.5L601.5,337.1z M602.9,338.6l-0.1,0.1h-0.1v-0.2l0.1-0.1h0.1l0.1,0.1L602.9,338.6L602.9,338.6z"/>
            <linearGradient id="AO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="171.3079" x2="0.1864" y2="171.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AO" class="stcustom0 st7" d="M511.8,475.8L511.8,475.8l-0.4,0.2l-0.3,0.1l-0.4,0.5l-0.2,0.2l-0.1,0.1h-0.2l-0.1,0.1v0.1l0.1,0.1
                l0.1,0.1v0.8v0.8l-0.1,0.1h-0.3l-0.4,0.1h-0.1V479l-0.1-0.3l0.1-0.3l0.1-0.2l-0.1-0.4l-0.2-0.4l-0.2-0.5l-0.1-0.1l0.2-0.1l0.3-0.3
                l0.1-0.2h0.3l0.1-0.1l0.1-0.2v-0.1l0.3-0.1l0.4-0.2l0.2-0.2l0.2-0.1h0.1h0.1l0.3,0.3L511.8,475.8L511.8,475.8L511.8,475.8z
                M542.4,493.3l0.1,0.4l0.1,0.5v0.4v0.2v0.1v0.1v0.2l-0.1,0.2v0.1v0.3v0.4v0.4v0.4l0.1,0.7v0.2l-0.1,0.3l-0.1,0.3l-0.1,0.3v0.2
                l0.2,0.5v0.1h-0.2h-0.2h-0.6h-0.8h-0.8h-0.8h-0.9h-0.7h-0.6v0.4v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.7l0.2,0.9l0.3,1l0.1,0.1l0.3,0.2
                l0.4,0.4l0.2,0.3l0.5,0.5l0.7,0.6l0.6,0.6l0.6,0.5l-0.9,0.2l-1.2,0.2l-0.8,0.2l-1,0.2l-0.7,0.1h-1h-0.1l-0.2-0.1h-0.5l-0.6,0.1h-0.5
                l-0.3-0.1l-0.3-0.1l-0.3-0.2l-0.6-0.1l-0.8,0.1h-0.8l-0.7-0.1H529h-0.3h-0.3l-0.4-0.1l-0.3-0.2l-0.4-0.4l-0.3-0.4l-0.1-0.1l-0.1-0.1
                h-0.1h-0.8h-0.8h-0.4h-1.1h-1.1h-1.1h-1.1h-1.1h-1.1H517h-1.1h-0.6h-0.5h-0.6h-0.1H514l-0.1-0.1l-0.3-0.2l-0.3-0.2l-0.4-0.3
                l-0.2-0.3l-0.2-0.1l-0.4-0.1l-0.3-0.1h-0.2l-0.4,0.1l-0.3,0.1l-0.2,0.1l-0.4,0.2l-0.3,0.2h-0.5h-0.1h-0.3l-0.3-0.1h-0.3l-0.3,0.2
                l-0.4,0.1l0.1-1.1l0.1-0.5v-0.6l-0.1-1.5l-0.1-0.2l-0.1-0.2l0.3-0.2l0.1-0.1l0.2-0.2l0.1-0.3l0.2-0.8l0.6-1.8l0.3-1.7l0.4-0.8
                l0.1-0.9l1-1.2l0.2-0.7l0.5-0.4l0.7-0.4l0.5-0.7l0.2-0.5l0.3-0.9V495l0.2-1.2v-0.4l-0.3-0.5v-0.4l-0.2-0.3l-0.3-0.3l-0.1-0.5
                l-0.5-0.7l-0.1-0.5l-0.2-0.4v-0.4l-0.1-0.5l-0.2-0.5l-0.2-0.5v-0.2l0.1-0.2l0.1-0.1v0.1l-0.1,0.1v0.1l0.9-0.9l0.1-0.2v-0.2v-0.2
                v-0.3l-0.8-1.7l-0.6-1.5l-0.1-0.8l-0.8-1l-0.3-0.7l-0.2-0.5l-0.1-0.2l0.1-0.1h0.2l0.5-0.1l0.7-0.1l0.6-0.3l0.2-0.1h0.3l0.3,0.1
                l0.1-0.1h0.1h0.8h0.3h0.6h0.4h0.2h0.6h0.7h0.3h1h0.9h0.8h0.9h0.7l0.3,0.1l0.3,0.2l0.1,0.2l0.1,0.1l0.1,0.2l0.2,0.1l0.1,0.2v0.3v0.4
                l0.1,0.4l0.2,0.4l0.3,0.5l0.1,0.4v0.3l0.1,0.3l0.2,0.3l0.2,0.2l0.1,0.1l0.3,0.5l0.5,0.7l0.3,0.5l0.1,0.1h0.2l0.4-0.1h0.4l0.3,0.1
                h0.1l0.4-0.2l0.4-0.1l0.4-0.1l0.2-0.1h0.3l0.7,0.2h0.1h0.6l0.6-0.1l0.1-0.7v-0.1l0.1-0.3l0.2-0.2v-0.2v-0.3l0.1-0.4l0.4-0.3l0.6-0.1
                h0.3l0.5-0.1l0.8-0.1h0.3l0,0l-0.2,0.5v0.2l0.1,0.2l0.1,0.1h0.9h0.8h0.9h0.7h0.1h0.1l0.1,0.3v0.5l-0.2,0.7l0.1,0.7l0.3,0.6v1
                l-0.1,0.6l-0.1,0.7v0.8l0.1,0.4l0.3,0.4l0.4,0.4l0.3,0.5l0.2,0.6l0.1,0.4l-0.1,0.2v0.3l0.1,0.4l-0.1,0.3l-0.2,0.1l-0.1,0.2l0.1,0.3
                v0.3l0.1,0.1l0.1,0.1h0.1l0.2-0.1l0.3-0.2l0.2-0.1h0.3l0.4,0.1h0.7h0.2l0.7-0.3h0.2h0.3l0.4,0.1h0.4l0.2-0.1v-0.1l0.1-0.1
                L542.4,493.3L542.4,493.3z"/>
            <linearGradient id="AR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="79.1077" x2="0.1864" y2="79.1077" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AR" class="stcustom0 st8" d="M299.3,526.4L299.3,526.4l0.1,0.2l0.2,0.2l0.5,0.3l0.4,0.5l0.4,0.8l0.4,0.6l0.4,0.4l0.3,0.3l0.3,0.2
                l0.2,0.1v0.1l0.3,0.2l0.5,0.3l0.3,0.3l0.1,0.3l0.5,0.3l0.9,0.3l0.7,0.2h0.4l0.6,0.3l0.8,0.5l0.5,0.4l0.2,0.2l0.5,0.3l1.3,0.7
                l0.6,0.2l0.3,0.2l0.2,0.2l0.2,0.1l0.2-0.1l0.3,0.1l0.5,0.2l0.4,0.3l0.5,0.6l0.2,0.2l0.1,0.2v0.2l-0.2,0.2l-0.3,0.2l-0.1,0.1v0.1
                l-0.1,0.2l-0.2,0.4l-0.1,0.2v0.1l-0.1,0.1l-0.4,0.3l-0.1,0.1v0.1v0.1h-0.1l-0.1,0.1l-0.1,0.2v0.3v0.4v0.1l-0.1,0.1v0.1v0.2l-0.1,0.1
                l-0.1,0.1v0.1v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.1,0.2l0.1,0.4l1.2-0.1l1,0.1l1.2,0.4l0.8,0.1l0.4-0.1
                h0.3l0.2,0.1h0.3l0.3-0.1l0.3,0.1l0.2,0.2l0.2-0.1l0.2-0.3l0.2-0.2l0.2-0.1h0.3l0.3,0.1l0.3,0.1l0.2,0.2h0.2l0.2-0.2l0.1-0.2v-0.3
                l0.1-0.2l0.2-0.1l0.1-0.1l0.1-0.2l0.2-0.1h0.4l0.2-0.1v-0.1l0.1-0.1l0.2-0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.2l0.2-0.4l0.2-0.7l0.1-0.9
                v-1.3h0.2l0.1,0.1l0.2,0.1l0.2-0.1l0.1-0.1h0.2l0.1-0.1h0.1l0.1,0.1l0.1,0.1h0.2l0.2,0.2l0.2,0.1l0.1,0.2l0.1,0.7l0.2,0.4l0.2,0.4
                v0.2l-0.1,0.2v0.3l-0.1,0.7v0.3l0.1,0.2v0.2l-0.1,0.3l-0.2,0.4l-0.2,0.1h-0.1l-0.3,0.3l-0.2-0.7l-0.1-0.1l-0.1,0.1l-0.2,0.3
                l-0.2,0.1l-0.3,0.1h-0.1H322l-0.2,0.1l-0.1,0.2l-0.2,0.1h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.2v0.2l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1
                l-0.1,0.1l-0.4,0.1l-0.3,0.2l-0.2,0.3l-0.2,0.2l-0.3,0.1l-0.4,0.3l-0.1,0.2l0.2,0.2l0.1,0.1v0.1h-0.1l-0.2-0.1h-0.1h-0.1v0.1v0.1
                l-0.1,0.1h-0.2l-0.1,0.1v0.2l-0.2,0.2l-0.3,0.3l-0.3,0.4l-0.2,0.5l-0.2,0.3l-0.3,0.1l-0.2,0.2l-0.1,0.3l-0.3,0.4l-0.5,0.6L315,550
                l-0.4,0.2l-0.2,0.2v0.3l-0.2,0.3l-0.4,0.3l-0.1,0.2l-0.1,0.1v0.2l-0.2,0.3l-0.3,0.4l-0.1,0.3l0.2,0.4v0.5l-0.1,0.2L313,554v0.1
                l0.1,0.2v0.2l-0.1,0.3l-0.2,0.3l-0.2,0.4l-0.1,0.3l0.1,0.1l0.1,0.1v0.1l-0.1,0.2l-0.1,0.3l-0.1,0.2l-0.2,0.1l-0.1,0.2l0.1,0.2v0.2
                l-0.1,0.2v0.2l0.1,0.2v0.2l-0.2,0.5l-0.1,0.3l0.1,1.3l-0.1,0.2l-0.1,0.2h-0.2h-0.2l-0.1,0.1l-0.1,0.6l-0.3,1.3v0.3l0.2,0.5l0.1,0.3
                l0.1,0.2v0.4l-0.1,0.2h-0.1l-0.1,0.1l0.2,0.5l0.1,0.3l0.4,0.5l1.5,0.7l0.6,0.4l0.7,0.6l0.4,0.6v0.5l-0.5,0.7l-0.1,0.6l0.1,0.4
                l0.2,0.4l0.5,0.5l0.4,0.2h0.5l0.1,0.1l0.1,0.1l0.1,1.1v0.4l-0.2,0.4l-1,1.7l-0.9,1.1l-0.3,0.6l-0.1,0.6l-0.3,0.3L312,579l-2.3,0.8
                l-1.9,0.4l-0.4,0.1l-3,0.5l-0.6,0.1H303l-0.6,0.1l-0.7-0.1l-0.6-0.2l-0.3-0.4h-0.4l-0.1,0.2l0.2,0.5l-0.1,0.6l0.1,0.3l0.2,0.1
                l0.2,0.2l0.2,0.2h-0.4l0.1,0.2l0.2,0.1v0.4l-0.1,0.9l-0.3,0.2l-0.1,0.1l-0.1,0.2l-0.2,0.9l-0.1,0.6l0.1,0.4l0.4,0.8l-0.2,0.5
                l-0.3,0.3l-1.1,0.6l-0.5,0.2l-0.7,0.2h-1.1h-0.4l-1-0.5l-0.7-0.3l-0.7-0.3l-0.6-0.2l0.1-0.1v-0.1l-0.2-0.1h-0.1l-0.4,0.3l-0.2,0.3
                l-0.1,0.2v0.6l0.1,0.5l0.3,1.2v0.7l-0.1,0.8l0.2,0.5l0.2,0.2l0.6,0.2l0.2,0.2h0.2l0.1,0.1v0.1l-0.1,0.2v0.2l0.4,0.1h0.4l0.5-0.1
                l0.1-0.2v-0.3l-0.5-0.1l0.1-0.1l0.4-0.1l0.5-0.2h0.3l0.2,0.1l0.1,0.1l0.2,0.4l0.1,0.5v0.6l-0.1,0.5l-0.1,0.2l-0.1,0.2l-1,0.3
                l-0.3-0.1l-0.2-0.4l-0.1-0.4l-0.2-0.3l-0.5-0.2l-0.5,0.1l-0.5,0.4l-0.4,0.1l-0.2,0.4l1.1,0.6l0.5,0.2h0.2l0.2,0.1l-0.2,0.2l-0.2,0.1
                l-0.8,0.3l-0.4,0.2l-0.4,0.4l-0.6,0.9l-0.2,0.2l-0.1,0.2l-0.1,0.6l0.2,1l-0.2,0.4l0.1,0.5l-0.1,0.3l-0.2,0.5l-0.8,0.7l-0.1,0.5
                l0.3,0.3v0.3l-0.1,0.2h-0.3l-1.2-0.2l-0.4,0.3l-0.4,0.3l-0.1,0.2l-0.1,0.1l-0.8,0.2l-0.2,0.1l-0.9,1.3L286,607l-0.5,0.8l-0.1,0.3
                v0.5l0.1,0.4l0.1,0.3l0.2,0.4l0.3,0.5l1.7,1.8l0.4,0.2l1.8,0.2l0.4,0.3l0.2,0.4l0.1,0.4l-0.1,0.9l-0.1,0.3l-0.2,0.3l-0.4,0.3
                l-0.5,0.2l0.1,0.1h0.2l0.5-0.1l0.2,0.1l0.1,0.4l-0.3,0.1l-0.1,0.2l-0.2,0.3l-1.1,1.1l-0.6,0.3l-0.5,0.4l-0.7,0.4l-0.3,0.3l-0.4,0.5
                l-0.6,0.6l-0.6,1.3v0.2l0.1,0.2l-0.3,2.2l-0.1,0.3l-0.2,0.3l-0.6,0.5l-0.3,0.1l-0.4-0.3l-0.2-0.3l-0.2-0.5l-0.3-0.5v0.2l0.1,0.3
                l-0.1,0.3l-0.7,0.1l-0.2,0.1l0.6-0.1l0.4,0.1l0.2,0.1l0.2,0.2l0.1,0.3l-0.1,0.2l-0.3,0.1l-0.5,0.2l-0.5,0.4l-0.3,0.5l-0.1,0.4
                l-0.1,0.7v0.5l-0.2,0.4l-0.3,0.3v0.1l0.2-0.2l0.2-0.1l0.2,0.5l0.2,0.9l0.1,0.6v0.2l-0.1,0.3l-0.4,0.1h-0.4l-0.3,0.1l0.2,0.1h0.3
                l0.4,0.3l0.4-0.1l0.2,0.2l0.1,0.2l0.6,1.4l0.6,0.8l0.3,0.5l-0.1,0.2v-0.3l-0.4-0.1l-0.4-0.1l-0.6-0.2l-0.8-0.3h-0.8l-0.6-0.3
                l-0.7-0.3h-1.5h-1.3h-1.3h-0.8h-0.6l-0.1-0.1V633l-0.2-0.3l-0.3-0.3l-0.4-0.2l-0.2-0.3l-0.2-0.4l0.1-0.3l0.2-0.8v-0.3l-0.2-0.2v-0.3
                v-0.2l0.1-0.1l0.1-0.5l-0.1-0.5l-0.1-0.5l-0.1-0.2l-0.2-0.1H272l-0.3,0.2h-0.5l-0.2,0.1l-0.3,0.2l-0.4,0.3l-0.2-0.1l-0.1-0.3
                l-0.1-0.3l-0.1-0.2l-0.1-0.4l-0.1-0.5l-0.2-0.6l-0.3-0.5V625l-0.1-0.5l0.2-0.5l-0.1-0.4l-0.2-0.5l0.1-0.5l0.2-0.3l0.1-0.4l0.9-0.1
                v-0.5l0.2-0.4l0.2-0.4l0.1-0.2l0.3-0.1l0.4-0.2l0.2-0.2l0.1-0.2l0.1-0.3V619l-0.1-0.6l0.1-0.2l0.2-0.2l0.4-0.2l0.2-0.6l-0.1-0.5
                l-0.2-0.4l-0.3-0.2v-0.4l0.1-0.4l0.2-0.4l0.2-0.5v-0.3l0.2-0.2l0.5-0.4l0.2-0.4l0.2-0.1h0.2v-0.2l-0.2-0.2v-0.3V612v-0.4l0.2-0.2
                l0.3-0.3l0.1-0.2v-0.3l-0.1-0.6l-0.1-0.4v-0.2l-0.2-0.3l-0.1-0.2l0.2-0.2l0.4-0.2l0.1-0.4l-0.1-0.3l-0.2-0.2l-0.1-0.5l0.1-0.6
                l0.1-0.2l0.5-0.1v-0.3l0.4-0.4v-0.4l-0.3-0.2l-0.2-0.4l-0.2-0.4l-0.6-0.2l-0.6-0.1l-0.1-0.3v-0.2l0.3,0.1l0.5-0.1h0.4h0.3h0.3
                l0.3,0.1l0.3-0.1l0.1-0.5l0.2-0.3v-0.3l-0.2-0.2l-0.3-0.1l-1.4-0.2v-0.2v-0.3l0.1-0.4v-0.2l0.1-0.2l0.1-0.3l0.1-0.2l-0.1-0.3
                l-0.2-0.4l0.2-0.2v-0.2v-0.2l-0.2-0.2l-0.2-0.3v-0.4l0.2-0.1l0.2-0.1v-0.2l-0.1-0.3l-0.3-0.1l-0.4-0.2l-0.1-0.1l-0.1-0.3l0.1-0.8
                V595v-0.3l0.1-0.2l0.1-0.2l-0.1-0.4l-0.1-0.2v-0.2l0.1-0.2l0.1-0.2l0.1-0.1l0.1,0.1l0.2-0.1l0.3-0.2v-0.2l-0.1-0.3l-0.2-0.7
                l-0.2-0.5v-0.2l0.1-0.2l-0.1-0.6v-0.4v-1.1v-0.4l-0.2-0.4v-0.4l0.1-0.3l0.1-0.4l0.1-0.3l0.1-0.1l0.2-0.1V586l-0.1-0.1l-0.2-0.2v-0.2
                v-0.2l0.1-0.1h0.2l0.1-0.3v-0.3v-0.2l-0.1-0.2l-0.1-0.5l-0.1-0.3l0.1-0.1h0.1l0.2,0.1h0.1v-0.1v-0.1l0.1-0.1l0.1-0.3l0.1-0.4v-0.3
                v-0.8l0.1-0.2l0.1-0.2l0.2-0.2l0.2-0.1l0.3-0.2l0.4-0.1l0.2-0.2l0.1-0.3v-0.2l-0.1-0.2l-0.2-0.2l-0.1-0.5v-0.4v-0.5l-0.2-0.5
                l-0.2-0.5l-0.1-0.5l0.1-0.3l0.1-0.4l-0.1-0.2l-0.1-0.3l0.1-0.3l0.1-0.4v-0.2l-0.1-0.5l-0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.1v-0.2v-0.2
                l0.2-0.1l0.2-0.2h0.1h0.2l0.1-0.1v-0.2v-0.2l0.3-0.2l0.2-0.2h0.3l0.1-0.2v-0.3v-0.3l0.1-0.4l-0.1-0.6V569l-0.1-0.2v-0.3l-0.1-0.2
                l-0.2-0.1l-0.1-0.2l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.8l0.1-0.2l0.1-0.3v-0.1l0.1-0.2l0.1-0.3l0.2-0.3l0.1-0.2l0.1-0.3v-0.2l0.1-0.1
                h0.2l0.2-0.1l0.1-0.1v-0.1V564l-0.1-0.5v-0.7v-0.4l0.1-0.4l0.1-0.2v-0.2v-0.2l-0.2-0.1l-0.2,0.1h-0.1l-0.2-0.2l-0.1-0.2v-0.3
                l0.1-0.2l0.1-0.3l-0.1-0.1l-0.2-0.2l-0.2-0.6v-0.5l-0.2-0.1l-0.1-0.4l-0.2-0.1l-0.1-0.3l-0.1-0.3v-0.1h0.2l0.1-0.2l-0.1-0.1
                l-0.1-0.1h-0.2L277,557l-0.2-0.6l-0.2-0.3l0.1-0.5v-0.4l0.1-0.3v-0.2l0.1-0.1l0.1,0.1h0.1l0.1-0.2l0.1-0.1v-0.1l-0.1-0.1V554
                l0.1-0.2l0.1-0.5l0.2-0.6l0.1-0.2v-0.2v-0.1l0.1,0.1l0.4-0.1l0.1-0.2l0.1-0.2l0.1-0.1l-0.1-0.2l-0.2-0.1l-0.1-0.1v-0.2l0.1-0.5v-0.3
                l-0.2-0.7l-0.1-0.7l0.1-0.2l0.3-0.3l0.2-0.1v-0.2l0.2-0.8v-0.5l0.1-0.3l0.1-0.5l0.4-0.4l0.1-0.3h0.1l0.1-0.1l0.2-0.3l0.3-0.3
                l0.2-0.2l0.1-0.2l0.1-0.3l0.2-0.5l0.1-0.4l0.2-0.1l0.2-0.5l0.1-0.3l0.2-0.1h0.2l0.2,0.1h0.2l0.2-0.2l0.4-0.1l0.2-0.1l0.1-0.2v-0.3
                l-0.2-0.2l-0.3-0.4l-0.3-0.5V540v-0.2v-0.2l0.1-0.2l0.3-0.4v-0.3l-0.2-1l-0.1-0.3l-0.2-0.5v-0.2l0.1-0.6l0.1-0.2h0.2l0.1-0.1v-0.1
                l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.1-0.2v-0.3l0.2-0.4l0.2-0.1l0.1-0.2l0.2-0.1l0.2-0.1l0.1-0.2l0.6-0.3l0.5-0.2l0.9-0.4
                l0.6-0.3l0.1-0.2V532l0.3-0.9l0.4-1.2l0.2-0.7l-0.5-0.5l0.1-0.1l0.3-0.4l0.1-0.3l0.1-0.1l0.5-0.3l0.1-0.2v-0.2l0.1-0.2h0.2l0.4-0.1
                l0.4-0.1l0.1-0.2l0.1-0.3l0.1-0.4l0.1-0.1h0.1l0.2,0.1l0.1,0.1l0.6,0.4l0.2,0.2h0.2h0.5h0.1h1.2h0.2l0.4,0.1l0.2,0.1h0.2l0.3,0.1
                l0.2,0.4l0.1,0.3l0.1,0.3l0.2,0.5l0.1,0.2v-0.1l0.1-0.6l0.2-0.3l0.2-0.4l0.4-0.9l0.2-0.1l0.2-0.1h0.1l0.1,0.1h0.2l0.1-0.1h1.1h1.2
                l0,0l0.1,0.2l0.2,0.3L299.3,526.4L299.3,526.4L299.3,526.4z M301.4,581.8v0.2h-0.1l-0.3-0.2l-0.1-0.2v-0.1l0.4,0.1l0.2,0.1
                L301.4,581.8L301.4,581.8z M282.4,646.8v-1.1v-1.5v-1.3v-1.3v-1.3V639v-1.4v-1.4l0.2,0.2l0.7,1l0.2,0.4l0.1,0.5l-0.3-0.3l-0.3,0.2
                l-0.1,0.3l-0.1,0.3v0.2l0.1,0.2l0.3,0.2l0.7,0.1v0.1l0.4,1.2l0.2,0.3l0.2,0.2l0.5,0.6l0.5,0.6l0.6,0.6l0.6,0.5l0.6,0.4l0.5,0.4
                l0.6,0.6l0.6,0.4l0.7,0.3l0.7,0.3l1.1-0.1h0.3l0.2,0.2l-0.2,0.5l-0.3,0.4l-0.4,0.2l-0.4,0.1h-0.3l-0.3-0.1H290l-0.3,0.2l-0.3,0.1
                h-0.3l-0.3,0.2l-0.3,0.1l-0.3-0.1l-0.9-0.4l-0.6-0.1l-1.9-0.2l-0.6-0.1l-0.6-0.1h-0.3l-0.4,0.1L282.4,646.8L282.4,646.8L282.4,646.8
                z M293.9,646.2l0.3,0.1l0.6-0.1h0.3h0.1l0.1,0.1l0.4-0.1h0.2v0.2l-0.4,0.2l-0.2-0.1h-0.8l-0.4,0.2h-0.2l-0.4,0.3l-0.3-0.2l-0.1-0.2
                l0.2-0.3h0.2l0.1-0.1L293.9,646.2L293.9,646.2z"/>
            <linearGradient id="AS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="162.7079" x2="0.1864" y2="162.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AS" class="stcustom0 st9" d="M1006.3,503.3L1006.3,503.3l-0.2-0.1l0.3-0.1l0.1-0.1h0.3h-0.2L1006.3,503.3z"/>
            <linearGradient id="AT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="354.8578" x2="0.1864" y2="354.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AT" class="stcustom0 st10" d="M522.7,307.1L522.7,307.1v0.2l-0.1,0.2l-0.1,0.3v0.2l0.3,0.8l0.3,0.5v0.2l0.2,0.1l-0.2,0.2v0.3
                l-0.1,0.1v0.1v0.1v0.2l0.1,0.2l-0.3,0.1h-0.3h-0.1l-0.1,0.1h-0.1l-0.3-0.2h-0.2h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.1l0.6,0.2
                l0.1,0.3l-0.1,0.2v0.1l-0.1,0.1l-0.2,0.1h-0.2v0.1l0.1,0.4l-0.1,0.1l-0.2,0.1l0.1,0.3h0.1v0.1v0.1v0.1v0.1v0.1h-0.1H521l-0.2,0.1
                l-0.4,0.4l-0.2,0.1l-0.2,0.2v0.4l0,0v0.1l-0.5-0.1l0,0h-0.4l-0.2,0.2l-0.3,0.1l-0.6-0.1l-0.6,0.1l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.1
                l-0.2,0.2l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1L516,316h-0.1h-0.1l-0.4-0.1l-0.5-0.1l-0.2-0.1l-0.5,0.2l-0.3-0.1h-0.2h-0.1l-0.6-0.1
                h-0.4l-0.5-0.1l-1-0.2l-0.3-0.1h-0.3l-0.3-0.1l-0.3-0.1l-0.2-0.2l-0.2-0.3l-0.3-0.4l-0.1-0.2l0.1-0.2l0.1-0.1v-0.1h-0.1l-0.6,0.2
                l-0.5,0.2h-0.2h-0.2h-0.3l-0.2-0.1h-0.5l-0.3,0.2l-0.2,0.3l-0.1,0.3l-0.1,0.1h-0.2h-0.3l-0.2-0.1l-0.2-0.2h-0.3h-0.3h-0.1v-0.1
                l-0.1-0.3l-0.2-0.1l-0.5,0.5h-0.1l-0.4-0.1l-0.3-0.2V314l-0.1-0.1l-0.3-0.1l-0.4-0.1h-0.1v-0.1v-0.1v-0.1l-0.1-0.1v-0.1v-0.1V313
                v-0.1v-0.1l0.2-0.5V312l-0.2-0.2l-0.1-0.1h0.1h0.3l0.2-0.1l0.1-0.1l0.3,0.1l0.4,0.2l0.2,0.1l0.1,0.1v0.1v0.1l0.1,0.1h0.2h0.1v0.2
                v0.2h0.2l0.2-0.1l0.2-0.2l0.1-0.2l0.1-0.5l0,0h0.1h0.5l0.2,0.1h0.4v0.1l0.1,0.1l0.2,0.2l0.1,0.1h0.2l0.3-0.1l0.2-0.1h0.1h0.2
                l0.2-0.1l0.1-0.1l0.2-0.1l0.3-0.2l0.4-0.1l1.3-0.1l0.1-0.1V311l0,0l0.2,0.1l0.3,0.1l0.2,0.1l0.1,0.1h0.1l0.2-0.1l0.3-0.1l0.2,0.1
                l0.1,0.1v0.1v0.1l0.1,0.1l0.2,0.1l0.2,0.1h0.1v-0.1v-0.3v-0.3L512,311h-0.1h-0.2h-0.1v-0.1l0.1-0.3v-0.3l-0.3-0.4l-0.3-0.4v-0.1
                l0.2-0.2l0.2-0.2l0.5-0.3l0.2-0.1h0.2l0.3-0.1l0.1-0.1l0.1-0.1l0.1-0.7l0,0l0,0l0.5,0.2l0,0h0.1l0.2-0.2v-0.1v-0.3v-0.3v-0.1h0.1
                l0.2,0.1l0.2,0.2l0.2,0.4l0.4,0.1h0.5l0.2-0.2h0.2l0.2,0.1l0.4,0.1v-0.3l0.2-0.3l0.1-0.1h0.3l0.1-0.2l0.1-0.7l0.1-0.1h0.2l0.2,0.1
                l0.1,0.1h0.1l0.1-0.1h0.2l0.3,0.1l0.6,0.3l0.3,0.1h0.2h0.2l0.7,0.5l0.5,0.1h0.4l0.1-0.1l0.2-0.1h0.2l0.2,0.1l0.3,0.2l0.1,0.1h0.2
                h0.1L522.7,307.1L522.7,307.1z"/>
            <linearGradient id="AU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="97.2079" x2="0.1864" y2="97.2079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AU" class="stcustom0 st11" d="M841.1,494.5l-0.1-0.3v-0.1h0.1h0.1l0.3,0.3l0.1,0.1l0.2,0.2h0.4l0.5-0.2l0.2,0.1h0.1l0.3-0.2l0.2-0.1
                l0.1-0.1l0.1,0.2l0.3,0.2l0.1,0.2l0.1,0.1v0.1l-0.2,0.2v0.2h-0.2l-0.3,0.4l-1,0.6l-0.9-0.5l-0.4-0.4L841,495L841.1,494.5
                L841.1,494.5z M859.5,507.2v0.1l0.2,0.1l0.1-0.1l-0.1-0.1V507l-0.2-0.2l-0.1,0.2h-0.1l-0.1,0.1L859.5,507.2z M865.9,510.1
                L865.9,510.1l0.3-0.1l0.1-0.1h0.1l0.2-0.3l0.1-0.1h0.4l-0.3-0.3l0,0l-0.4,0.1l-0.5,0.1l-0.2,0.2l-0.2,0.3v0.3v0.1L865.9,510.1
                L865.9,510.1z M874.1,491.3l0.1-0.2l-0.1-0.1l-0.1,0.2L874.1,491.3L874.1,491.3L874.1,491.3z M858.5,494.8l0.4-0.5l0.1-0.5h-0.1v0.2
                v0.2l-0.1,0.1l-0.1,0.1L858.5,494.8l-0.1,0.1v0.1L858.5,494.8L858.5,494.8z M846.9,494.1l0.2,0.4h0.1l0.1-0.4l-0.1-0.2v-0.3v-0.1
                l-0.1,0.2h-0.2l0.1,0.2L846.9,494.1L846.9,494.1z M857.8,495.3l0.3-0.2V495l-0.1,0.1l-0.5,0.3l-0.2,0.3l0.4-0.2L857.8,495.3
                L857.8,495.3z M859.2,506.9L859.2,506.9l0.1-0.1v-0.2l-0.1,0.1V506.9z M874,492.7L874,492.7v0.3l0.2,0.1l0.2-0.2l-0.2-0.3L874,492.7
                L874,492.7z M858.2,506.8v0.1l0,0h0.2l0.1-0.2h-0.1h-0.2V506.8L858.2,506.8z M859.3,502.8l-0.3-0.1l-0.1-0.1v-0.1l0.1-0.4l0.1-0.1
                h0.1l0.2-0.2v-0.1l-0.1-0.1h-0.1l-0.1,0.3H859l-0.1-0.1l-0.1-0.2v-0.1h-0.1l-0.2,0.1l-0.1,0.2l-0.3,0.2v0.4v0.3l-0.1,0.1l-0.2,0.1
                h0.1h0.3l0.5,0.1h0.3l0.4,0.1l0.1-0.1l0.1-0.2l0,0L859.3,502.8L859.3,502.8z M899.5,531.1L899.5,531.1l0.2-0.2l-0.1-0.2l-0.1-0.2
                v-0.2h-0.1h-0.1l-0.1-0.1l-0.2-0.1l-0.1,0.2l0.4,0.6L899.5,531.1L899.5,531.1z M904.6,537.3L904.6,537.3l0.1-0.3l0.2-0.5l0.6-1.4
                v-0.3l-0.2-0.2v-0.5h-0.1h-0.1l-0.1,0.1l-0.1,0.2l0.1,0.1l0.2,0.3v0.3l-0.1,0.2l-0.4,0.4l0.1,0.3v0.2l-0.1,0.3l-0.1,0.3l0.1,0.5
                L904.6,537.3L904.6,537.3z M905.7,542.1L905.7,542.1v-0.4l0.1-0.5h-0.1l-0.1,0.1v0.3v0.3L905.7,542.1z M905.6,542.7v0.5l0.1,0.1h0.1
                l0.2-0.9l0,0l-0.2-0.1L905.6,542.7L905.6,542.7z M897.5,526.7L897.5,526.7v-0.3l-0.1-0.1v0.2l-0.1,0.1L897.5,526.7L897.5,526.7
                L897.5,526.7z M895.9,526.3L895.9,526.3v-0.4l-0.1,0.1L895.9,526.3L895.9,526.3L895.9,526.3L895.9,526.3z M885.2,514.7l0.2,0.2
                l0.1,0.3l0.2,0.1l0.1-0.1v-0.1l-0.1-0.2l-0.1-0.3h-0.1h-0.1h-0.1L885.2,514.7L885.2,514.7L885.2,514.7z M874.4,491.6l0.2-0.2
                l-0.1-0.1h-0.2l-0.1,0.1l0.1,0.1L874.4,491.6L874.4,491.6z M893.1,520.2L893.1,520.2l0.1-0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1
                L893.1,520.2L893.1,520.2L893.1,520.2z M893.2,520.7L893.2,520.7h0.2l0,0l-0.1-0.2l-0.1-0.2L893.2,520.7L893.2,520.7L893.2,520.7z
                M866.5,511.1l-0.1,0.1v0.1h0.1l0.3-0.1V511l-0.2-0.1L866.5,511.1L866.5,511.1z M883.4,578.9l-0.2-0.1h-0.4v0.1v0.2l0.2,0.1
                L883.4,578.9L883.4,578.9z M891.3,584.8L891.3,584.8l-0.3-0.2l-0.5-0.7l-0.2-0.1l-0.3,0.4l-0.2,0.1l0.1,0.1h0.2l0.1,0.2v0.2l0.4,0.6
                l0.1,0.1l0.1,0.1l0.1,0.1l0.3-0.1l0.2-0.2l0,0v-0.1l-0.2-0.2l0.1-0.1L891.3,584.8L891.3,584.8z M891.1,586.7L891.1,586.7
                L891.1,586.7l-0.3,0.1v0.1l0.2,0.2L891.1,586.7L891.1,586.7z M882.4,579.5h0.4l0.2,0.1v-0.1l-0.1-0.2l-0.1-0.1h-0.2L882.4,579.5z
                M881.4,586.7v-0.2l0,0l-0.1,0.1l-0.1,0.1l0.1,0.4L881.4,586.7L881.4,586.7z M857.2,501.5L857.2,501.5l-0.1,0.1v0.1l0.3,0.1h0.1
                l0.1-0.1v-0.2l-0.1-0.1L857.2,501.5L857.2,501.5z M921,645.1l-0.2,1v0.2l0.1-0.2l0.2-0.7v-0.5L921,645.1L921,645.1z M891.4,585.9
                l-0.4,0.2h-0.4l-0.1,0.1v0.1l0.2,0.2h0.3l0.3-0.1l0.1,0.2h0.1l0.2-0.2l-0.2-0.2L891.4,585.9z M891.4,592.6L891.4,592.6l-0.1-0.2
                v-0.1V592l-0.1-0.4V591v-0.3v-0.3l0.1-0.4v-0.4v-0.2v-0.2v-0.6l-0.2-0.3l-0.5-0.3h-0.2l-0.3,0.3h-0.2l-0.5-0.1l-0.1,0.1l-0.2,0.3
                l-0.1,0.1l-0.2-0.1l-0.2-0.1h-0.1l-0.1,0.1h-0.3h-0.3l-0.2,0.1l-0.2,0.2h-0.2v0.2l-0.2-0.2l-0.2-0.1l-0.2,0.1l-0.2,0.1l-0.7,0.1
                l-0.5-0.2l-0.8-0.3l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.1l-0.1-0.1h-0.1h-0.2l-0.2-0.1l-0.2-0.2h-0.2l-0.2,0.1h-0.3l-0.6-0.2
                l-0.3-0.2v0.4l-0.2,0.7v0.4l0.1,0.4l0.2,0.6v0.2v0.1l0.4,0.8l0.4,0.7l0.5,0.7l0.1,0.3l-0.1,0.3h0.2l0.1-0.2v0.2l0.1,0.1l0.4,0.5v0.1
                l-0.2,0.4l-0.1-0.3l-0.2-0.3l-0.5-0.4l0.1,0.8l0.1,0.3l0.6,1.5l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.3l0.3,0.6l0.2,0.2l0.3-0.1l0.4,0.1
                h0.1l0.1,0.1l0.1,0.2h-0.3l-0.3,0.1v0.1l0.1,0.1l0.1,0.4l0.4-0.1h0.6h0.4l0.4,0.4l0.4,0.1h0.1l0.2-0.4l0.1-0.5l0.1-0.2l0.1-0.2
                l-0.3-0.2V597v-0.1l0.5,0.4l0.2-0.2v-0.3v-0.2l0.1-0.2l0.1-0.1v-0.2V596l-0.1-0.3v-0.2l0.3,0.4l0.1,0.5l0.2-0.1v-0.1v-0.4l0.1-0.1
                l0.3,0.1l0.3,0.2v0.1v0.1l-0.1,0.1l-0.2-0.1l-0.1,0.2l0.1,0.4l0.2,0.4l0.1-0.1l0.3-0.1l0.1-0.1l-0.1-0.8l-0.1-0.5V595v-0.3l0.1-0.3
                l0.1-0.3v-0.3v-0.3l0.1-0.3l0.2-0.3l0.1-0.1v-0.1l0.1-0.2l0.1-0.2l0.1,0.2l-0.1,0.1l-0.1,0.1l0.2,0.1l0.1,0.1v0.1v0.2v0.1h0.1v-0.2
                v-0.2L891.4,592.6L891.4,592.6z M906.1,545l-0.3-0.6l-0.1-0.5l-0.1-0.4l-0.5-1.1l-0.2-0.7l0.1-0.7l-0.2-2.1l0.2-1.1l-0.1-0.1
                l-0.3-0.2l-0.1-0.2l-0.2-0.4v-0.8l-0.3-0.5l-0.4-0.2l-0.3-0.4l-0.2-0.3v-0.2l-0.1-0.3l-0.3-0.2l-0.2-0.1l-0.4-0.3l-0.2-0.3l-0.4-0.9
                l-0.2-0.2l-0.4-0.3h-0.3l-0.2-0.1l-0.7-0.6l-0.2-0.1l-0.2-0.3l-0.3-0.3l-0.2-0.2l-0.2-0.2l-0.2-0.9v-0.8l-0.1-1l-0.3-0.5l-0.1-0.2
                l-0.2,0.1v0.3v0.2h-0.1l-0.4-0.3l-0.5-0.3l-0.3-0.3l-0.2-0.3H896h-0.1l-0.1,0.4l0.2,0.6l-0.1,0.1l-0.2-0.1l-0.3-0.3l-0.1,0.1h-0.2
                l-0.2-0.3l-0.1-0.2l-0.2-0.7l-0.2-0.8v-0.6L894,524l-0.1-0.5l-0.1-0.1l-0.1-0.4l-0.4-0.5l-0.4-0.3l-0.3-0.3l-0.3-0.5l0.1-0.3
                l0.2,0.1h0.2l-0.2-0.3l-0.2-0.2l-0.4-0.4l-0.2-0.1l-0.4-0.1l-0.5-0.4l-0.3-0.2h-0.2l-0.2-0.1l-0.2-0.2l-0.3-0.1l-0.4-0.4l-0.2-0.4
                l-0.1-0.2l-0.1-0.1l-0.2,0.1h-0.2l-0.4-0.1l-0.1-0.2l-0.3-0.2l-0.5-0.1l-0.4-0.1l-0.3-0.1l-0.3-0.2l-0.3-0.3l-0.2-0.4v-0.5l0.1-0.3
                l-0.3-0.1l-0.5-0.7v-0.3l0.1-0.6l0.1-1l-0.2-0.7l-0.4-0.9v-0.5h-0.2l-0.2-0.1l-0.3-0.4l-0.2-0.3l-0.2-0.3l-0.2-0.4V509v-0.2v-0.5
                l-0.2-0.5l-0.1-0.5l-0.2-0.7l0.1-0.4v-0.4l-0.1-0.3l0.1-0.2v-0.2l-0.3-0.2l-0.3-0.2l-0.4-0.3l-0.8-0.5l-0.2-0.4l-0.3-0.4l-0.4,0.1
                l-0.3,0.1l-0.3,0.3l-0.4,0.2l-0.4-0.2l-0.2-0.1l-0.1-0.5l-0.2-0.6l-0.2-0.3l-0.1-0.4l0.1-0.9l-0.2-0.4v-0.6l-0.2-0.7l-0.2-0.3v-0.3
                l-0.3-0.6l-0.1-0.3l-0.2-0.1l-0.2-0.2v-0.2v-0.2l0.1-0.3l0.1-0.3l-0.3-0.1h-0.2l-0.2-0.1l-0.2-0.2l-0.1-0.5v-0.6v-0.4l-0.1-0.3
                l-0.1-0.3l-0.2-0.3l-0.5-0.4v-0.2l0.1-0.2l-0.2-0.1h-0.2l-0.1,0.3l-0.2,0.2l-0.4,0.2l-0.1,0.9l-0.3,1l-0.2,0.8v0.2v0.2l-0.1-0.1
                l-0.1-0.1l-0.2,0.3l-0.3,0.8v0.4l0.2,0.1l0.1,0.1h0.2l0.1,0.1v0.2l0.1,0.2v0.2l-0.1-0.1H873l-0.1,0.2l-0.3,0.3l0.1,0.9l-0.2,0.5
                l-0.2,0.4l-0.2,0.7v0.4l0.2,0.3l0.2,0.4l-0.1,0.5l-0.1,0.4l0.2,1.1l0.1,0.6l-0.1,0.4l-0.4,1.2l-0.2,0.9l0.1,0.5l-0.2,0.4l-0.2,0.7
                l-0.2,0.5l-0.7,1.1l-0.1,0.5l-0.2,0.6l-0.5,0.4l-0.4,0.2l-0.8,0.2h-0.5l-0.3-0.1l-0.1-0.1l-0.6-0.2l-0.7-0.5l-0.5-0.2l-0.3-0.5V511
                l-0.1-0.3l-0.3-0.3l-0.5-0.1l-0.5-0.2H864l-0.7-0.2l-0.5-0.3l-0.4-0.4l-0.6-0.7l-0.5-0.2l-0.6-0.3l-0.4-0.2l-0.2-0.1l-0.2-0.2h-0.2
                h-0.4l-0.2-0.2v-0.1v-0.1v-0.2H859h-0.1l-0.1,0.1h-0.1l-0.3-0.1l-0.5-0.2l-0.1-0.2l-0.2-0.3l-0.7-0.4l-0.4-0.3l-0.8-0.5l-0.5-0.1
                l-0.1-0.2l-0.1-0.3l0.2-0.3l0.2-0.2l0.6-0.9l0.2-0.1l0.2-0.2l0.2-0.6l0.1-0.4l-0.2-0.5v-0.9l0.7-0.4h0.2l0.2-0.1l0.2,0.1l0.1,0.2
                h0.1l0.4-0.6l-0.1-0.3l-0.1-0.3l0.1-0.1l1.2-1.2l-0.1-0.3l-0.2-0.1h-0.3l-0.3-0.3l-0.2-0.5h-0.3l-0.5,0.5l-0.1,0.1l0.1,0.1l0.1,0.3
                l-0.2,0.4h-0.2H857l-0.1-0.3l-0.1-0.4l-0.2-0.1l-0.2,0.1l-0.2,0.1h-0.1l-0.1-0.1v-0.2l0.3-0.3l0.2-0.2v-0.1l-0.2-0.1v-0.1l0.2-0.2
                h-0.1l-0.3,0.2l-0.3,0.1l-0.4,0.3l-0.6,0.2l-0.4,0.3l-0.5-0.1l-0.5-0.3l-0.1-0.1l-0.2-0.2l-0.5,0.2h-0.3l-0.2-0.1l-0.3-0.1l-0.3-0.2
                l-0.7-0.3l-0.7-0.1h-0.3l-0.3-0.2l-0.2-0.1l-0.5-0.1l-0.2-0.2l-0.3-0.5l-0.2-0.1h-0.3l-0.3,0.2l-0.2,0.1l-0.4-0.4l-0.6-0.4l-0.2-0.1
                L846,494l-0.1,0.2h-0.1l-0.1-0.1l-0.2-0.2h-0.2l-0.4,0.3v0.1l0.3,0.1l0.4,0.4l0.2,0.1l0.4-0.1l0.6,0.1l0.6,0.4l-0.1,0.2v0.3v0.3v0.2
                l0.2,0.3h-0.1l-0.3-0.1l-0.2,0.1l-0.2,0.1l-0.1,0.3l-0.1-0.2l-0.3-0.2l-0.2,0.1l-0.3,0.2l-0.3-0.1l-0.2-0.1l-0.5,0.1h-0.8l-0.3-0.2
                l-0.1-0.3l-0.1-0.1l-0.1,0.1l-0.1,0.2h-0.5l-0.1,0.1v0.2v0.2h-0.2l-0.2,0.1v0.3v0.2l-0.1,0.1l-0.3-0.2l-0.1-0.2l-0.2-0.1l-0.1,0.1
                v0.2v0.4h-0.1H841l-0.2,0.1l-0.2,0.6l-0.4,0.2l-0.1,0.3v0.2l0.3,0.4l-0.2,0.2l-0.2,0.2l-0.2,0.1l-0.4,0.1l-0.3,0.2l-0.1,0.2v0.2
                l-0.1,0.3l-0.1,0.3v0.2l-0.3,0.2l-0.5,0.5l-0.2,0.5l0.3,0.3l0.3,0.2h0.3v0.1l-0.3,0.2l0.2,0.2l0.3,0.2h0.2l0.1,0.1H839h-0.4
                l-0.1,0.2v0.2l0.1,0.2v0.2l-0.1-0.1l-0.1-0.2l-0.3-0.3l-0.2-0.1l-0.3-0.1l-0.1,0.1v0.5l-0.1,0.2l-0.1-0.1v-0.4l-0.3-0.3l-1.2-0.3
                h-0.2h-0.3l-0.2,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.1v0.2l0.2,0.3l0.1,0.1v0.2l-0.1-0.2l-0.1-0.1l-0.1,0.2h-0.1v-0.7
                l0.1-0.5l0.1-0.3l0.1-0.2l-0.1-0.1l-0.8-0.7l-0.3-0.5l-0.3-0.3l-0.4-0.3l-0.2-0.2l-0.5-0.3l-0.5-0.2l-0.3-0.3l-0.3-0.1l-0.4,0.1v0.2
                v0.2l-0.3,0.4l-0.3,0.2l-0.2-0.2l-0.2-0.2l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.1-0.1l-0.1-0.3l-0.2-0.1l-0.2,0.1l0.1,0.3l0.1,0.1v0.2
                l-0.1,0.1v0.2l-0.1,0.3v0.4l-0.2,0.1l-0.2,0.3l-0.1-0.1V503l-0.1-0.2l-0.2-0.1l-0.1,0.2l-0.1,0.1h-0.1l0.1-0.1v-0.3l0.1-0.3
                l-0.2-0.1l-0.1,0.1v0.2l-0.1,0.4l-0.2,0.1l-0.2,0.2h-0.3l-0.1,0.1l-0.1,0.2l-0.2,0.2v0.2l0.2,0.2v0.2l0.4,0.2v0.2l-0.1,0.1h-0.1
                l-0.3-0.2H826l-0.1-0.1v0.1v0.1l-0.1,0.1h-0.2l-0.2,0.1l0.2,0.2v0.1l0.1,0.1h0.3h0.1v0.2v0.2l-0.1,0.1l-0.1-0.2l-0.7,0.3H825v0.1
                v0.1l-0.1,0.2l-0.2,0.2l-0.2-0.1l-0.2,0.1l-0.1,0.4v0.4l0.2,0.3l0.1,0.4l0.3-0.4h0.1v0.2l-0.1,0.2l-0.1,0.2v0.3h-0.2h-0.2l-0.1,0.1
                l-0.1,0.2v0.3l0.1,0.1h0.3h0.3l0.2,0.1v0.1h-0.2h-0.5h-0.2h-0.4l-0.3-0.2l-0.2-0.2H823l-0.2,0.1l-0.1,0.2l-0.2,0.1l-0.4-0.6h-0.2
                l-0.1,0.1l0.1,0.3l-0.1,0.2l-0.1,0.2h-0.2l-0.1,0.1l0.1,0.1l0.2,0.4l0.2,0.2l0.2,0.2l0.1,0.2h0.2l0.1,0.2l-0.1,0.2v0.4h-0.1
                l-0.1-0.1l-0.3-0.2h-0.1l-0.1,0.1v0.2l0.1,0.4l-0.1,0.6v0.2v0.1l-0.1-0.1l-0.1-0.2l-0.3-0.3l-0.3-0.7l-0.3-0.5l-0.2-0.4l-0.3-0.8
                h-0.2l-0.2,0.3l-0.2,0.5l-0.1,0.2l-0.3,0.2l-0.2,0.2l-0.3,0.1l-0.3,0.3l-0.2,0.2l-0.3,0.5v0.3v0.4l0.1,0.5l0.1,0.7l0.2,0.1l0.2,0.1
                v0.2l-0.2,0.1l-0.7,0.7l-0.5,0.2l-0.1,0.2l-0.2,0.4l-0.3,0.5l-0.1,0.3l-0.3,0.6l-0.4,0.6l-0.4,0.5l-0.5,0.4l-0.3,0.2l-1.2,0.5
                l-0.7,0.2l-1.2,0.1l-0.5,0.2l-0.6-0.1l-0.7-0.1l-1,0.8l-0.8,0.2l-0.7,0.1l-0.3,0.1l-0.7,0.5l-0.4,0.2l-0.8,0.2h-0.3l-0.4-0.2h-0.4
                h-0.4h-0.4l-0.3,0.2l-1.7,0.9l-0.3,0.3l-0.3,0.4l-0.5,0.3l-0.4,0.4l-0.8,0.4l-0.8,0.3l-0.4,0.3l-0.3,0.4l-0.5,1l-0.1,0.2l-0.2,0.3
                l-0.3,0.1l-0.2,0.1l0.1-0.5l-0.2-0.4l0.1-0.8l-0.1-0.2l-0.3,0.2l-0.2,0.2l-0.5,1.2l-0.3,0.9l0.2,0.5l0.1,0.3v0.3l-0.1,0.5v0.3v0.4
                l-0.6,1l-0.2,0.4l-0.2,0.8v0.4v0.6l0.2,0.5l0.2,0.3l0.3,0.9l0.3,0.6l0.6,1.2l0.6,1v0.4l-0.1,0.5v0.5l-0.1,0.1l-0.2,0.2l-0.3-0.2
                l-0.1-0.2l-0.2-0.7h-0.1l-0.1,0.3l-0.1,0.1l-0.1-0.1l-0.1-0.1V539v-0.1v-0.5l-0.3-0.3l-0.2-0.3l-0.2-0.1l-0.2,0.1v0.2l0.3,0.6
                l0.2,0.6l0.3,0.4l0.2,0.1l0.2,0.2v0.5l-0.2,0.2l-0.1,0.1l-0.4-0.1l-0.1-0.4l-0.3-0.7l-0.1-0.3l-0.1-0.1v0.1v0.3l-0.1,0.1h-0.1
                l-0.1-0.1l-0.1-0.1h-0.1l0.1,0.2l0.3,0.5l1.1,1.3l0.9,1.6l0.2,0.6l0.1,1.4l0.1,0.3l0.5,0.7l0.5,0.8l0.2,0.4v0.3l0.1,0.3l0.6,0.9
                l0.3,0.9v0.3l-0.1,0.6l0.1,1l0.1,0.6l0.2,1.1l0.3,0.8l0.3,0.5l0.5,1.1l0.7,1.3l0.1,0.6v1.7v0.6l-0.2,0.1l-0.1,0.2l0.1,1.1v0.6
                l-0.2,0.6l-0.2,0.5l-0.4,0.4h-0.5l-0.2-0.2l-0.3-0.2l-0.1,1v0.8l0.1,0.3v0.4l0.3,0.3l0.2-0.1h0.2l0.8,0.4l0.5,0.3l0.7,0.9l0.6,0.2
                l0.8,0.4l1,0.1h0.8l1.2,0.2l0.3-0.1l0.5-0.1l0.4-0.1l0.4-0.1l1.1-0.8l1.1-0.9l0.5-0.1h0.5l0.6-0.3l0.5-0.9l0.3-0.2l0.4-0.2l1-0.1
                l0.6,0.1l0.3-0.1l0.8-0.2l1.7-0.2l0.9,0.1h0.6l0.3,0.1l0.3,0.4l1.8-0.3h0.5l0.3,0.1l0.4,0.3l0.4-0.3h0.4l0.4-0.3l0.6-0.8l0.3-0.5
                l0.4-1.1l0.3-0.4l0.4-0.2l0.4-0.1l0.7-0.2l1.4-0.9l0.6-0.2l0.3-0.2l1-0.7l0.6-0.1l1.8,0.2h0.9l0.7-0.1l1-0.4l1.1-0.3l1.3-0.6
                l1.1-0.6l0.7-0.1l1.1-0.1l1.6-0.2l1.8,0.1l0.5-0.1l0.2-0.1l0.3-0.1l0.4,0.1l0.3,0.1l0.9,0.5l1.4,1h0.3l0.9-0.2h0.3l1.3,0.8h0.5h0.4
                l0.3,0.1l0.3,0.2l0.4,0.5l0.9,0.5v0.4l-0.1,0.2h-0.2l-0.2,0.1l0.2,0.8l0.4,0.6l0.9,0.1l0.3,0.2l0.2,0.2l0.2,0.4l0.1,0.6l0.4,0.5
                l0.4,0.4l0.1,0.2l0.2,0.6l0.1,0.2l0.2,0.6l0.2,0.7l-0.1,0.1h-0.1l-0.2-0.2l-0.2-0.2h-0.1l-0.1,0.1v0.2h0.2h0.1l0.3,0.2l0.2,0.2
                l0.2,0.1l0.5,0.6l0.2-0.1l0.2-0.1l0.4,0.3l0.1,0.1l0.1-0.1l-0.1-0.6L857,567v-0.2l0.2-0.2l0.1-0.2l0.4-0.5l0.9-1.3l0.3-0.2l0.3-0.3
                l0.4-0.2l0.4-0.3l0.3-0.1l0.3-0.1l0.3-0.2l0.3-0.7l0.2-0.8l0.3-0.3l0.4-0.4l0.3-0.5v-0.4v-0.4l0.2,0.3l0.1,0.3l0.2,1.1l-0.2,0.2
                l-0.2,0.1v0.4l0.1,0.5v0.4l-0.4,0.4l-0.4,0.5l-0.4,1v0.3l-0.1,0.4v0.4l0.1,0.4l-0.1,0.6l-0.2,0.5h-0.2h-0.2h-0.3h-0.3l-0.4,1.1
                l0.2,0.1l0.2-0.1h0.3l0.4-0.2l0.5-0.2l0.3,0.1h0.4l0.5-1.4l0.1-0.9l0.3-0.4l0.1-0.3l0.1-0.3l0.3,0.5l0.2,0.5l0.5,0.7l0.2,0.4
                l0.1,0.9l-0.3,1l-0.2,0.3l-0.2,0.3l-0.2,0.4l0.6,0.1h0.4l0.6-0.3h0.1h0.3l0.1-0.2l0.3-0.2l0.2-0.2l0.3-0.1l0.3,0.1l0.1,0.1l0.1,0.1
                l-0.1,0.2v0.4h-0.2l-0.1-0.3l-0.2,0.1l-0.1,0.2l-0.3-0.1v0.1l0.1,0.2l0.6,0.5l0.6,0.6l0.2,0.3l0.5,1l0.4,1v0.3l-0.2,0.5l-0.1,0.5
                v0.3l0.1,0.4l0.3,0.4l0.9,1l0.5,0.9l0.7,0.5l1.1,0.2l0.6,0.3l0.6,0.7l0.2,0.1h0.3l0.4-0.4h0.6l0.7,0.4l0.4-0.1l0.3,0.1l0.4,0.2
                l0.6,0.5l0.7,0.2l0.4,0.3l0.3,0.1l0.6,0.2l0.4-0.2l0.4-0.2l0.8-0.8l0.6-0.4l0.3-0.2l0.3-0.1l0.3-0.3l-0.2-0.2h-0.2l-0.3-0.1l0.2-0.1
                l0.2-0.1l1-0.6l0.3,0.2l0.2,0.2l0.2,0.3l-0.1,0.4l-0.1,0.2l-0.3,0.3h-0.4h-0.2l0.4,0.3l0.3,0.2l0.7-0.4l0.2-0.3l0.1-0.2h0.2l0.3,0.1
                l0.1,0.2l0.1,0.3l-0.2,0.1l-0.1,0.2l-0.1,0.2l0.4,0.3l0.2,0.2h0.2h0.3l0.2,0.4l0.2,0.5l0.2-0.1l0.1-0.1l0.2,0.1l0.3,0.4l0.2,0.4v0.2
                l0.2,0.1l0.2-0.1l0.1-0.2V582v-0.5l-0.1-0.1l-0.3,0.3l-0.1-0.2l-0.2-0.2v-0.2l0.2-0.1h0.2h0.2l1.2-0.2l1.5-1.6l0.7-0.6l0.7-0.4
                l0.7-0.3l0.4-0.1l1.9-0.1h1l0.5-0.1l0.2-0.1l0.4-0.4l0.3-0.2l0.3-0.1l0.1-0.3v-0.3l0.1-0.3l-0.1-0.6v-0.8l0.1-0.4l0.2-0.6l0.1-0.6
                l0.1-0.9l0.1-0.5l0.1-0.5l0.3-0.5l0.2-0.3l0.5-1.3l0.2-0.1h0.2l0.1-0.1v-0.1v-0.1l-0.1-0.1v-0.1l0.2-0.1h0.1h0.1v-0.1l-0.1-0.3
                l0.1-0.5l0.1-0.8l0.2-0.4l0.1-0.3l0.4-0.5l0.4-0.4h-0.1h-0.2l0.1-0.1h0.1l0.1,0.1l0.1-0.2v-0.3l0.1-0.5l-0.1-0.4l0.2-0.1l0.2-0.1
                l0.1-0.4l0.1-0.2l0.1-0.2l0.2-0.3l0.2-0.3l0.4-0.7l0.4-0.3l0.6-0.2l0.1-0.1l-0.2-0.1v-0.1h0.2l0.1-0.2l0.2-0.2l0.4-0.4l0.1-0.4
                l0.1-0.3v-0.7l0.6-0.9l0.4-1.2l0.1-0.7l0.1-0.4l0.1-0.6l-0.1-0.6v-0.5l0.4-1.3l0.1-0.5l0.1-0.3l0.2-1.3v-0.7l0.3-0.8l0.4-0.6v-0.6
                l-0.1-0.4L906.1,545L906.1,545z M840.3,496l0.5-0.2l0.5,0.2l0.3-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.2v-0.3
                l-0.1-0.2h-0.1l-0.1,0.1l-0.3,0.3l0.1,0.2v0.3l-0.2,0.1h-0.2l-0.1,0.3l0.1,0.1L840.3,496L840.3,496z M792.6,537.8v-0.2l-0.1-0.2
                l-0.3-0.9h-0.1l-0.1,0.1l0.2,0.7l0.5,1l0.1-0.1L792.6,537.8L792.6,537.8L792.6,537.8z M798.9,522.3l0.2-0.2v-0.2l-0.1-0.1l-0.2,0.2
                l-0.1,0.2v0.1H798.9z M824.6,506.3L824.6,506.3l0.2-0.1v-0.1l-0.1-0.1v-0.1h-0.1v0.1l-0.1,0.1L824.6,506.3L824.6,506.3L824.6,506.3z
                M826.5,503.9L826.5,503.9v-0.3l-0.1-0.1l-0.1,0.1l-0.1,0.3l0.1,0.1L826.5,503.9L826.5,503.9z M888.6,596.3L888.6,596.3l-0.1,0.2
                l-0.1,0.1l0.1,0.1v0.2h0.1h0.2l-0.1-0.5L888.6,596.3L888.6,596.3z M888.5,597.1L888.5,597.1l-0.2,0.2v0.2h-0.1v0.1l-0.1,0.1
                l-0.2-0.1v0.1l0.1,0.3l0.2-0.1l0.2,0.1l0.1-0.4v-0.2V597.1L888.5,597.1z M890.9,594.5l-0.2-0.1l-0.1,0.2v0.1v0.2h0.1h0.2v-0.1
                l0.2-0.1L890.9,594.5L890.9,594.5z M862.2,569.7l-0.3,0.1l-0.7-0.1v-0.1l0.1-0.2l-0.1-0.1l-0.2-0.1h-0.6l-0.7,0.2l-1.3,0.3l-0.2,0.2
                l-0.1,0.3l0.1,0.2l0.5,0.3h0.4l0.3-0.1l0.3,0.1l0.2-0.2l0.5,0.1l0.2,0.2l0.4-0.2l0.1-0.3l0.1-0.1l0.5-0.1l0.5,0.1h0.2l0.2-0.2
                l-0.2-0.3H862.2L862.2,569.7z M879.6,584l-0.1-0.5l-0.3-0.2h-0.1v0.3l-0.2,0.2v0.1v0.3l-0.1,0.3l0.1,0.3v0.3l0.1,0.2h0.1l0.3-0.1
                l0.2-0.2l0.1-0.2l-0.1-0.3V584L879.6,584z M822,497.8L822,497.8L822,497.8L822,497.8L822,497.8z"/>
            <linearGradient id="AW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="238.2579" x2="0.1864" y2="238.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AW" class="stcustom0 st12" d="M278.9,427.8L278.9,427.8h-0.1l-0.2-0.2l-0.2-0.1v-0.1l0,0l0.2,0.1L278.9,427.8L278.9,427.8z"/>
            <linearGradient id="AX_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="415.0579" x2="0.1864" y2="415.0579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AX" class="stcustom0 st13" d="M531.2,250L531.2,250L531.2,250h0.3l0.2,0.2v0.1l0.2,0.1l0.1,0.1l-0.2,0.4h-0.1h-0.1h-0.1l-0.1,0.1
                v0.2v0.3l-0.7,0.1l-0.2-0.1l-0.2-0.8v-0.2l0.1-0.1h0.1v0.4h0.2l0.1-0.3v-0.2v-0.1l-0.1-0.1l-0.1-0.1l0.1-0.2l0.2-0.1L531.2,250
                L531.2,250L531.2,250z M530.3,250.9v0.1V250.9l-0.2,0.1l-0.1,0.2l-0.1-0.1v-0.2l0.1-0.3h0.2L530.3,250.9L530.3,250.9z M532.9,251.8
                v0.1h-0.2l-0.1-0.1h-0.2v-0.1l0.1-0.1l0.2-0.1h0.2V251.8z"/>
            <linearGradient id="AZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="325.5079" x2="0.1864" y2="325.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="AZ" class="stcustom0 st14" d="M611.4,334l0.3,0.2l0.4,0.6l0.6,1l0.2,0.3l0.1,0.3l0.1,0.4l0.1,0.3l0.6,0.8l0.3,0.3l0.5,0.4l0.2,0.1
                h0.2h0.4l0.4,0.2l0.2,0.1l0.2,0.2l0.2,0.2l0.2,0.5l-0.6-0.2h-0.6l-0.4,0.1l-0.3,0.1l-0.3,0.2l-0.2,0.4l-0.2,0.9l-0.3,0.8v0.4
                l0.1,0.4v0.2l-0.1,0.1l-0.1,0.2l-0.2,0.8l-0.1,0.2l-0.1,0.1v-0.1v-0.2l-0.3-0.2l-0.1,0.2l-0.1,0.4l-0.2,0.4v0.1v1.4h-0.1l-0.6,0.1
                h-0.1l-0.5-0.6l-0.1-0.1h-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.5-0.3l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.3-0.1l0.1-0.1v-0.1
                v-0.1v-0.1l-0.4-0.3v-0.1v-0.1v-0.1l0.1-0.1l0.3-0.2l0.2-0.2l-0.1-0.2l-0.4-0.4l-0.4-0.4h-0.3l-0.3,0.1l-0.5,0.4l-0.3,0.2l-0.4,0.3
                l-0.4,0.3l-0.3,0.3l-0.2,0.3l-0.4,0.1l-0.2,0.2l-0.6,0.7H606v-0.3v-0.3v-0.1l-0.2-0.2v-0.1l0.1-0.1h0.2h0.2l0.1-0.1l-0.2-0.3
                l-0.2-0.2l-0.2-0.1v-0.1v-0.1V343l0.3-0.1v-0.1v-0.2l-0.4-0.2l-0.3,0.1l-0.3-0.3l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.3l-0.4-0.3
                l-0.2-0.1l0,0l0,0h0.1h0.6h0.1v-0.1l0.1-0.2l0.1-0.3v-0.2l-0.6-0.3l-0.5-0.3l-0.3-0.4l-0.2-0.4v-0.1l0.1-0.1l0.5-0.4v-0.1V338
                l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1h-0.3l-0.5-0.2h-0.1l0,0l0,0l0.3-0.1V337l-0.1-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.2l0.6-0.5l0.2-0.1
                l0.4,0.1l0.8,0.3l-0.1,0.2l0.1,0.1l0.2,0.1l0.4,0.1l0.3,0.1l0.2-0.1l0.2-0.1l0.3,0.2l0.3,0.2l0.1,0.1h0.1l0.2-0.1l0.3-0.3l0.1-0.3
                v-0.2l-0.2-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.2l-0.1-0.4h-0.1l0,0v-0.1v-0.2v-0.1l0.1-0.1h0.1l0.1-0.1l0.2-0.2l0.1-0.1l0.3,0.1v0.2h0.1
                h0.1l0.2-0.1l0.2,0.1l0.2,0.3l0.3,0.3l0.2,0.2l0.1,0.1l0.2,0.1l0.2,0.1l0.2,0.2l0.2,0.5l0.2,0.1l0.6,0.2h0.2l0.6,0.1h0.2l0.3-0.5
                l0.3-0.5l0.2-0.1l0.4-0.2l0.3-0.2l0.1-0.2l0.2-0.4L611.4,334L611.4,334z M602.9,338.6v-0.1l-0.1-0.1h-0.1l-0.1,0.1v0.2h0.1
                L602.9,338.6L602.9,338.6z M601.5,337.1L601.5,337.1h-0.2V337v-0.1h0.1h0.1L601.5,337.1L601.5,337.1z M604.5,344.9l-0.5-0.1l-1-0.2
                l-0.3-0.1l-0.3-0.3l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.5-0.7l-0.1-0.1l-0.1-0.1V342l0.3-0.1
                l0.5-0.2l0.1,0.1l0.1,0.2l0.1,0.1v0.3h0.1l0.2-0.1l0.1,0.1l0.2,0.1l0.3,0.1l0.4-0.2l0.2-0.1h0.2l0.1,0.1v0.2v0.3v0.1l0.1,0.1
                l0.4,0.2l0.1,0.1l-0.1,0.2l0.3,0.6l0.1,0.2L604.5,344.9L604.5,344.9z"/>
            <linearGradient id="BA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="339.8078" x2="0.1864" y2="339.8078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BA" class="stcustom0 st15" d="M522.6,320.8l0.6,0.4l0.2,0.1l0.1-0.1h0.2l0.4,0.1l0.1,0.1h0.1l0.3-0.2h0.1l0.3,0.3h0.2l0.2-0.1
                l0.1-0.1l0.4,0.1l0.2-0.1h0.2l0.2,0.1l0.2,0.1l0.2,0.1h0.5l0.2,0.2l0.1,0.2v0.1v0.1l0.1,0.1l0.3,0.1h0.2h0.1l0.3-0.1l0.3-0.1
                l0.2,0.1l0.1,0.1v0.1l-0.1,0.3l-0.1,0.3l-0.2,0.3l-0.2,0.3l-0.1,0.2v0.3v0.2v0.1l0.1,0.1l0.2,0.1l0.3,0.2l0.3,0.3l0.3,0.3l0.1,0.1
                v0.1l-0.1,0.1h-0.3h-0.3h-0.1H529l-0.1,0.1v0.1l0.3,0.4l0.3,0.5v0.2v0.2l-0.1,0.1h-0.1l-0.1-0.1H529h-0.1l-0.2,0.2h-0.1h-0.1h-0.1
                l-0.1-0.1h-0.2l-0.1,0.1v0.1l0.1,0.2l0.2,0.3v0.2h-0.1l-0.1-0.2H528h-0.1l-0.3,0.2l-0.2,0.2v0.1l-0.1,0.2v0.1v0.4l-0.4,0.1l-0.1,0.1
                v0.1v0.5v0.3l0.2,0.4v0.1v0.1l-0.2,0.2l-0.1,0.1l0,0l-0.3-0.1h-0.1l-0.5-0.3l-0.2-0.2l-0.4-0.3l-0.2-0.1l-0.1-0.2h-0.2l-0.2,0.1
                l-0.2-0.2l0.2-0.1v-0.1v-0.1l-0.1-0.1l-0.6-0.6l-0.3-0.4v-0.1v-0.4l-0.1-0.1l-0.5-0.2l-0.5-0.5l-0.5-0.5l-0.1-0.1l-0.3-0.4l-0.3-0.3
                l-0.3-0.2l-0.2-0.3l-0.2-0.4l-0.1-0.5l-0.1-0.5l-0.1-0.2l-0.2-0.1l-0.5-0.6l-0.4-0.3v-0.4l0.1-0.6l0.1-0.7l0.1-0.1l0.2-0.1h0.2
                l0.2,0.1l0.4,0.5l0.2,0.2l0.2,0.1l0.2-0.2l0.2-0.4l0.2-0.2l0.7,0.1L522.6,320.8z"/>
            <linearGradient id="BB_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="240.1579" x2="0.1864" y2="240.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BB" class="stcustom0 st16" d="M308.1,426L308.1,426l-0.3-0.1l-0.1-0.1v-0.4h0.2l0.3,0.3l0.2,0.1L308.1,426z"/>
            <linearGradient id="BD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="271.258" x2="0.1864" y2="271.258" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BD" class="stcustom0 st17" d="M734.9,400v0.1v1v0.4l0.1,0.4v0.1l-0.1,0.1h-0.1l-0.1-0.2l-0.2-0.1l-0.3-0.1l-0.1-0.1H734l-0.2,0.2
                l-0.1,0.2v0.3l0.1,0.3l0.1,0.2v0.2v0.2l0.1,0.2v0.2l0,0l-0.2-0.3l-0.2-0.3l-0.4-0.6l-0.1-1v-0.5l-0.3-0.6l-0.2-0.8l-0.1-0.2l0.1-0.3
                v-0.1l0,0l-0.1,0.1l-0.2-0.3l-0.1-0.3l-0.5-0.6l-0.1-0.3v-0.3l-0.2,0.3l-0.3,0.2l-0.3,0.3l-0.2,0.1l-0.6,0.1l-0.3-0.4l-0.5-0.9
                l-0.1-0.2l0.1-0.5l-0.1-0.5v-0.3v-0.2H729v0.1v0.2v0.2h-0.4l-0.4-0.1l0.3,0.3l0.4,0.1l0.2,0.2v0.2v0.2l-0.2,0.1l-0.2,0.1v0.2
                l0.2,0.2l-0.3,0.1l-0.1,0.2v0.2l0.1,0.2l0.1,0.2v0.1l0.1,0.1l0.2,0.3l0.1,0.2l-0.1,0.3l-0.1,0.1l-0.2,0.1l-0.4,0.4l-0.2,0.4
                l-0.2,0.2h-0.2l-0.1-0.1l-0.2-0.1v-0.2l0.1-0.2l0.3-0.4l-0.2,0.1l-0.2,0.1l-0.3,0.2l-0.1-0.3l-0.1-0.3v-0.3l0.3-0.5l-0.3,0.2
                l-0.1,0.3v0.4v0.2l-0.1,0.3l-0.2,0.2l-0.2,0.1l-0.1,0.2l-0.2,0.1V400l-0.1-0.4l-0.2-0.9v0.2l0.1,0.5v0.4l-0.1,0.3l-0.3,0.3h-0.2
                h-0.1l-0.2-0.2l-0.2-0.3v-0.4l-0.1-0.2V399v-0.3l-0.2-0.7l-0.1-0.4v-0.1l0,0l-0.1-0.5l-0.1-0.3v-0.3l0.2-0.4l-0.1-0.1l-0.3-0.1
                l-0.2-0.1l-0.1-0.1l0.1-0.4l-0.1-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.2l0.2-0.5l0.2-0.5v-0.2v-0.4v-0.1v-0.1l-0.2-0.2l-0.4-0.1
                l-0.3-0.1l-0.2-0.2l-0.1-0.1l-0.2,0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2v-0.1v-0.1l0.3-0.6h0.1l0.3,0.1h0.1l0.2-0.2l0.2-0.7h0.3h0.3h0.2
                h0.2l0.2-0.1l0.1-0.1l0.1-0.1v-0.1l-0.3-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.1H723l-0.3-0.1l-0.1-0.1l-0.2-0.4l-0.3-0.3l-0.3-0.1l-0.1-0.1
                l-0.1-0.1v-0.2l0.1-0.2l0.1-0.2l0.2-0.3l0.3-0.2l0.1-0.2l0.2-0.2v-0.1V386l-0.1-0.1h-0.1v-0.1l0.1-0.2h0.1l0.3,0.2l0.3,0.3l0.2,0.2
                v0.2h0.1h0.1l0.2,0.1h0.2h0.1h0.1v-0.1l-0.1-0.2l-0.1-0.1l0.1-0.1h0.1h0.1l0.1,0.1l0.1,0.2v0.3l0.2,0.3l0.3,0.2l0.2,0.1l0.3,0.1
                l0.2-0.1l0.1-0.2l-0.1-0.2v-0.2l0.1-0.1h0.1l0.1,0.1l0.3,0.7l-0.1,0.3l0.1,0.9l-0.1,0.6v0.1v0.1h0.1h0.1l0.4,0.1l0.3,0.1l0.4,0.1
                l0.5,0.1h0.3h0.2h0.3h0.9h0.7l0.3,0.1h0.2l0.8-0.1h0.8l0.4,0.2l0.5,0.3l0.3,0.2v0.1v0.1l-0.1,0.1h-0.2l-0.4-0.1h-0.1v0.3l0,0
                l-0.1,0.3l-0.2,0.6v0.3v0.1h-0.1h-0.2h-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.2-0.1h-0.1h-0.2l-0.2,0.1L731,393h-0.1h-0.4
                h-0.1v0.1v0.1l-0.3,0.3L730,394l-0.1,0.3v0.2l0.2,0.6l0.2,0.8l0.1,0.1h0.1l0,0v-0.2v-0.2h0.1h0.1l0.1,0.2l0.1,0.3l0.1,0.1h0.2
                l0.2-0.1l0.2-0.1l0.1-0.2v-0.3v-0.2l0.1-0.2l0.4-0.3l0.1-0.1v-0.3v-0.3h0.1h0.2l0.2-0.1h0.1l0.1,0.1h0.2l0.1,0.6l0.1,0.5v0.2v0.5
                l0.1,0.4l0.1,0.1l0.1,0.2l0.1,0.3l0.1,0.1l0.1,0.5l0.1,0.3l0.1,1.1L734.9,400L734.9,400z M729.5,397v0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1
                l0.1-0.1L729.5,397L729.5,397z M729.9,399.6l-0.5,0.1h-0.2l0.5-0.8v-0.4l-0.1-0.3l-0.2-0.2v-0.2l-0.1-0.2l-0.1-0.3l0.3-0.1l0.2,0.2
                v0.1v0.2l0.1,0.2l0.4,0.5v0.3l-0.1,0.7L729.9,399.6L729.9,399.6z M732,398.8l-0.1,0.1l-0.1-0.1l-0.2-0.3l0.1-0.4V398l0.1,0.1
                l0.1,0.3l0.1,0.2L732,398.8L732,398.8z M730.9,399.4l-0.3,0.2l0.1-1.2l0.2,0.5l0.1,0.2L730.9,399.4z M732.9,400.4l-0.1,0.2
                L732.9,400.4l-0.1-0.2v-0.1l0,0l0.1,0.1V400.4L732.9,400.4z M733.1,401.4h-0.2l-0.1-0.1v-0.1v-0.4h0.1h0.1v0.1v0.2L733.1,401.4z"/>
            <linearGradient id="BE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="367.2078" x2="0.1864" y2="367.2078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BE" class="stcustom0 st18" d="M488.6,294.8L488.6,294.8l0.1,0.1h0.3l0.1-0.2l0.1-0.1l0.1,0.1v0.2l0.1,0.3l0.3,0.3l0.3,0.1l0.3-0.1
                l0.1-0.1h0.1l0.1,0.2l0.2,0.2l0.4,0.1l0.1,0.1l0.1,0.1v0.2l-0.2,0.4v0.1l0,0v0.1l-0.2,0.3v0.1l0.1,0.2l0.1,0.1l0,0l0.4-0.1l0.2,0.3
                h0.3v0.1l0.3,0.2l0.1,0.2l0.2,0.2l-0.2,0.2v0.1l0.1,0.1l0.3,0.1l0.1,0.1v0.2l0.1,0.4l-0.5,0.4l-0.2,0.4v0.1l0,0l-0.1-0.1h-0.1
                l-0.2-0.1l-0.3,0.4l-0.1,0.3l-0.1,0.2l-0.1,0.2v0.2v0.1v0.1v0.1l0.2,0.2v0.1l0.2,0.4l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.3
                l-0.2,0.1h-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.1l-0.1-0.1h-0.2l-0.3-0.1l-0.2-0.2l-0.2-0.1h-0.2h-0.2l-0.1-0.3v-0.3l-0.1-0.2l0.2-0.8
                l-0.1-0.1l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.2l-0.3,0.2l-0.5,0.1l-0.5-0.1h-0.1v-0.1v-0.1V301l0.1-0.1v-0.2l-0.1-0.2l-0.1-0.1v-0.2
                l0.1-0.2v-0.1l-0.4-0.3l-0.3-0.1h-0.3h-0.2h-0.1l-0.1,0.1l-0.1,0.1l-0.1-0.1L485,299l-0.1-0.1l-0.3-0.1h-0.5l-0.1-0.1l-0.1-0.3v-0.3
                l-0.1-0.3l-0.1-0.1l-0.1-0.1l-0.2,0.1l-0.3,0.2h-0.2h-0.1l-0.2-0.2l-0.3-0.3l-0.2-0.3v-0.2l0.1-0.2l-0.1-0.1l-0.1-0.3v-0.2l1.2-0.8
                l0.7-0.4l0.4-0.1l0.1,0.4l0.1,0.1l0.1,0.1h0.1l0.1-0.1l0.2-0.1h0.3l0.2,0.1l0.1,0.1l0.1,0.1h0.2l0.4-0.2l0.4-0.3l0.1-0.2v-0.2
                l0.2,0.1h0.2h0.1l-0.1-0.3l0.2-0.1l0.2-0.1l0.1,0.1l0.2,0.1h0.1l0.3-0.3l0.1,0.1L488.6,294.8L488.6,294.8z"/>
            <linearGradient id="BF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="237.4078" x2="0.1864" y2="237.4078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BF" class="stcustom0 st19" d="M475.7,420.8v0.1v0.2v0.4l-0.1,0.4l0.2,0.3l0.3,0.3l0.1,0.1l-0.1,0.3l0.1,0.2l0.2,0.3l0.3,0.4l0.3,0.4
                l0.2,0.1h0.2l0.1,0.1l0.2,0.1h0.2l0.1,0.1l0.1,0.1l0.1,0.2l0.3,0.2l0.2,0.2l-0.1,0.1h-0.3l-0.2-0.1v0.1v0.4v0.4h0.1l0.2,0.1l0.6,0.5
                l0.5,0.5l0.2,0.1h0.3h0.3h0.1l0.3-0.2h0.2h0.2h0.1l0.2,0.2l0.1,0.3v0.2v0.1l0,0l-0.3,0.1h-0.1v0.1v0.1l0.1,0.1l0.3,0.4l0.4,0.5
                l0.1,0.1l-0.1,0.2l-0.2,0.4l-0.2,0.2l-0.7,0.6l-0.3-0.1l-0.7,0.1l-0.1-0.1h-0.2h-0.2l-0.1,0.1v0.1l-0.1,0.1l-0.1,0.2l-0.1,0.1h-0.1
                h-0.2h-0.1v0.1v0.1l-0.1,0.1v0.1v0.1l-0.1,0.1h-0.1h-0.1L478,432l-0.1,0.1h-0.1h-0.7l-0.3,0.1h-0.2v-0.1l0,0l-0.9-0.2l-0.6-0.1
                l-0.6-0.1v0.1l-0.1,0.1h-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.1-0.2h-0.2h-0.4h-0.2h-0.2H472l-0.9-0.1
                H471l0,0h-0.8h-0.9h-0.8h-0.7l0,0h-0.2v0.1l-0.2,0.7v0.4l0.1,0.2l0.1,0.2l0.1,0.1v0.1l-0.1,0.1v0.1l0.1,0.1v0.1l-0.1,0.1v0.3
                l0.1,0.5v0.3l-0.1,0.1v0.2l0.2,0.4v0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.1-0.1l-0.2-0.3
                l-0.2-0.1h-0.2l-0.3-0.1l-0.5-0.1h-0.6l-0.2,0.1l-0.2,0.1l-0.6,0.2l-0.2,0.1l-0.2,0.3h-0.2l-0.2-0.1l-0.1-0.1h-0.3l-0.3-0.1
                l-0.3-0.2l-0.2-0.1l-0.2-0.2l-0.1-0.3l-0.2-0.2l-0.1-0.3l-0.2-0.1l-0.2-0.1h-0.3l-0.2-0.1l-0.2-0.2v-0.2l0.1-0.2v-0.2l0.1-0.4v-0.5
                l-0.1-0.3l0.2-0.1l0.2-0.1l0.1-0.2l0.1-0.5l0.1-0.4v-0.2l-0.1-0.1l-0.1-0.2v-0.2v-0.2l0.2-0.2l0.2-0.1l0.1-0.1l0.4-0.1l0.5-0.1
                l0.3-0.1l0.2-0.1l0.1-0.1l0.1-0.2l0.2-0.2l0.1-0.2v-0.4v-0.3l-0.1-0.1l-0.1-0.1l0.7-0.3v-0.2l-0.1-0.3l-0.1-0.2l-0.1-0.2l0.2-0.2
                l0.2-0.2l0.1-0.1l0.3-0.2l0.3-0.1l0.3,0.1l0.8,0.5h0.1h0.2l0.2-0.1l0.3-0.1l0.1-0.3v-0.5l0.1-0.2h0.1l0.4,0.1h0.1h0.1l0.1-0.1v-0.2
                v-0.1l0.1-0.5l0.3-0.4l0.5-0.4l0.2-0.1h0.2l1,0.3l0.2-0.1l0.2-0.8l0.3-0.1h0.3l0.2-0.1l0.1-0.1l0.5-0.3l0.8-0.4l0.4-0.2l0.1-0.1
                l0.3-0.3l0.4-0.3l0.3-0.1h0.4l0.2,0.1l0.1,0.1h0.1l0.5-0.1l0.7,0.2L475.7,420.8z"/>
            <linearGradient id="BG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="335.2579" x2="0.1864" y2="335.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BG" class="stcustom0 st20" d="M555.3,326.8l-0.1,0.9l-0.3,0.4l-0.4-0.1l-0.5,0.1l-0.3,0.5l-0.2,0.1l-0.1,0.2l-0.1,0.6v1l-0.2,0.1
                h-0.2l-0.8,0.9l0.4,0.3l0.2,0.2l0.3,0.5l0.5,0.6l0.1,0.3l-0.4-0.1h-0.1l-0.1,0.1h-0.2h-0.2l-0.2,0.1h-0.1l-0.2-0.1l-0.3-0.3
                l-0.2-0.2l-0.1-0.1l-0.1,0.1l-0.5,0.1l-0.1,0.1l-0.2,0.1l-0.2,0.1h-0.3h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.2v0.1l-0.4,0.1l-0.1,0.1v0.1
                v0.1l-0.3-0.1l-0.3,0.1l-0.1,0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.3v0.3l-0.1,0.2l-0.2,0.4l-0.4,0.1l-0.4-0.1l-0.2,0.1h-0.3h-0.3
                l-0.4,0.1l-0.4,0.1l-0.3-0.3l-0.4-0.2l-0.4-0.1l-0.1,0.1l-0.1,0.1l-0.3-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.3h-0.1l-0.3,0.1h-0.3h-0.2
                h-0.5l-0.1,0.2h-0.1h-0.1h-0.3l-0.3,0.2l-0.4,0.1h-0.3h-0.3h-0.2h-0.4l-0.2,0.2h-0.4H539v-0.1l0.1-0.9l0.2-0.4v-0.1v-0.1l-0.1-0.1
                l-0.1-0.2l-0.2-0.6l-0.1-0.1l-0.3-0.1l-0.3-0.2l-0.2-0.2l-0.4-0.6l0.2-0.1l0.1-0.1l0.2-0.3v-0.2v-0.1l-0.1-0.1l-0.1-0.3l0.1-0.3
                v-0.2l-0.1-0.2l0.1-0.2l0.2-0.1h0.1h0.4l0.3-0.4l0.2-0.1l0.2-0.2l0.1-0.1l0.1-0.2v-0.2l-0.3-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.1
                l-0.4-0.2l-0.2-0.2l-0.1-0.3l-0.1-0.2L538,327v-0.1v-0.2v-0.3l0.1-0.4l0.1-0.1h0.1l0.4-0.2v-0.3l0.1-0.2l0.1-0.1l0.1-0.1l0.2,0.2
                l0.5,0.3l0.2,0.2v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.2v0.2v0.1l0.1,0.1l0.9-0.2l0.9,0.1l1.2,0.3l0.8,0.1l0.6-0.1l1.1,0.2l1,0.2l0.9,0.1
                l0.5-0.2l0.4-0.2l0.3-0.4l0.8-0.5l0.8-0.3l1-0.2l0.7-0.1l0.1,0.1l0.9,0.5h0.4l0.3,0.1l0.1,0.1h0.1l0.4-0.1l0.2,0.3l0.3,0.4l0.5,0.2
                l0.4,0.1h0.1L555.3,326.8z"/>
            <linearGradient id="BH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="278.3579" x2="0.1864" y2="278.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BH" class="stcustom0 st21" d="M617.1,388l-0.1,0.2l-0.1-0.1l-0.2-0.4l0.1-0.3l-0.1-0.4v-0.1l0.3-0.1h0.1l-0.1,0.1l0.1,0.2v0.4V388z"
                />
            <linearGradient id="BI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="193.5077" x2="0.1864" y2="193.5077" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BI" class="stcustom0 st22" d="M560.8,469.5L560.8,469.5l-0.3,0.6v0.1l0,0l0.1,0.1l-0.1,0.2l0,0v0.2v0.1l0.1,0.1l0.2,0.1h0.2l0.3,0.1
                h0.2v0.1v0.2v0.1v0.2l-0.1,0.2l-0.3,0.1l-0.2,0.1v0.1v0.1v0.1l-0.3,0.2l-0.3,0.3l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.2,0.4
                l-0.1,0.3l-0.6,0.6l-0.5,0.3l-0.1,0.1h-0.9l-0.1-0.4l-0.1-0.6l-0.3-0.5v-0.2v-0.4v-0.6v-0.3v-0.2v-0.4v-0.2l-0.2-0.3l-0.2-0.3
                l-0.1-0.1v-0.1v-0.1v-0.2l0.1-0.2h0.1l0.3,0.1l0.3,0.1l0.1,0.3h0.1h0.2h0.5h0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.1-0.3v-0.6h0.1l0.3,0.2
                h0.1h0.1l0.1-0.1l0.1-0.1h0.1l0.4-0.1l0.2,0.2L560.8,469.5L560.8,469.5z"/>
            <linearGradient id="BJ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="228.7078" x2="0.1864" y2="228.7078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BJ" class="stcustom0 st23" d="M485.2,430l-0.1,0.2l-0.2,0.4v0.3l0.4,0.6v0.1l0.1,0.1l0.1,0.1l0.1,0.3v0.3v0.2l0.2,0.3v0.1l-0.1,0.5
                v0.1l0,0h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.2v0.1l0.2,0.3l-0.1,0.4l-0.1,0.3l-0.2,0.2h-0.2l-0.1,0.1l-0.1,0.1v0.3l-0.3,0.3l-0.2,0.2
                l-0.1,0.1v0.4l-0.1,0.4l-0.2,0.3l-0.4,0.1H483l-0.1,0.8v0.5v0.5l-0.1,0.2v0.3v0.6v0.5l0.1,0.1v0.3v0.3l0.1,0.2l0.1,0.2v0.1l-0.1,0.1
                v0.1v0.7v0.2v0.1l-0.1,0.1v0.4l0.1,0.2l0.1,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.5v0.2l-1.2,0.1l-1.3,0.2l-0.5,0.1v-0.1l0.5-0.1l-0.1-0.4
                l-0.3-0.4l-0.1-0.1l-0.1-0.2l0.1-0.1v-0.1V444l-0.1-0.3h0.3v-1v-1v-0.9v-0.7l-0.1-0.8v-0.6v-0.8l-0.1-0.2l-0.4-0.4l-0.1-0.2v-0.3
                l-0.1-0.3V436v-0.6v-0.1l-0.4-0.3l-0.6-0.4l-0.5-0.3l0,0v-0.1l0.1-0.9l0.1-0.1l0.1-0.4l0.1-0.3h0.1l0.1-0.1l0.1-0.1h0.1h0.1l0.1-0.1
                v-0.1V432l0.1-0.1v-0.1v-0.1h0.1h0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.1v-0.1l0.1-0.1h0.2h0.2l0.1,0.1l0.7-0.1l0.3,0.1l0.7-0.6l0.2-0.2
                l0.2-0.4l0.1-0.2l0.1-0.3l-0.1-0.5v-0.1l0.3-0.1l0.4-0.1h0.1h0.1l0.1-0.1l0.2-0.1h0.1h0.1l0.8,0.7l0.3,0.4l0.1,0.2L485.2,430h0.2
                l0.2,0.2L485.2,430z"/>
            <linearGradient id="BL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="253.8079" x2="203.1555" y2="253.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BL" class="stcustom0 st24" d="M298.8,412.1L298.8,412.1L298.8,412.1l-0.1-0.1l0,0l0,0l0,0L298.8,412.1L298.8,412.1L298.8,412.1
                L298.8,412.1z"/>
            <linearGradient id="BN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="215.6079" x2="0.1864" y2="215.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BN" class="stcustom0 st25" d="M797.9,449.1l-0.2,0.1l-0.2,0.2l-0.2,0.1l-0.1,0.1v0.1l0.1,0.3v0.2l0.1,0.1l0.1,0.1v0.1l-0.3,0.4h0.1
                l-0.1,0.3l-0.1,0.2l-0.2,0.2h-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.1h-0.3l-0.1-0.1v-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.2-0.1
                l-0.1-0.1h0.3h0.3l0.4-0.1l0.3-0.2l0.3-0.2l0.3-0.2l0.3-0.2l0.4-0.2h0.1L797.9,449.1L797.9,449.1L797.9,449.1z M797.9,449.1h0.3
                l0.1,0.1l0.2,0.3l0.1,0.3v0.5l0.1,0.2l0,0h-0.1h-0.1h-0.2l-0.2-0.1l-0.2-0.5l-0.1-0.3v-0.4L797.9,449.1L797.9,449.1z"/>
            <linearGradient id="BO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="156.258" x2="0.1864" y2="156.258" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BO" class="stcustom0 st26" d="M311.9,520.2l0.1-0.5l-0.1-0.4l-0.1-0.1l-0.8-0.5l-0.7-0.5l-1-0.6H308h-1.3l-1.2,0.3l-1.2,0.3
                l-0.6,0.1l-1.2,0.3l-0.7,0.1l-0.2,0.5l-0.3,0.7l-0.3,0.4l-0.3,0.4l-0.4,0.6v0.8v0.7l-0.3,1l-0.3,0.9l-0.2,0.9l-0.2,0.6l-0.1,0.2l0,0
                l-0.2-0.2l-0.2-0.3l-0.1-0.2l0,0h-1.2h-1.1l-0.1,0.1h-0.2l-0.1-0.1h-0.1l-0.2,0.1l-0.2,0.1l-0.4,0.9l-0.2,0.4l-0.2,0.3l-0.1,0.6v0.1
                l-0.1-0.2l-0.2-0.5l-0.1-0.3l-0.1-0.3l-0.2-0.4l-0.3-0.1h-0.2l-0.2-0.1l-0.4-0.1h-0.2h-1.2h-0.1h-0.5h-0.2l-0.2-0.2l-0.6-0.4
                l-0.1-0.1l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.4l-0.1,0.3l-0.1,0.2l-0.4,0.1l-0.4,0.1H288l-0.1,0.2v0.2l-0.1,0.2l-0.5,0.3l-0.1,0.1
                l-0.1,0.3l-0.3,0.4l-0.1,0.1l-0.5,0.1l-0.6,0.1h-0.4H285l-0.1-0.1l-0.2-0.1v-0.1v-0.2v-0.3v-0.4l-0.2-0.5v-0.2v-0.2l-0.1-0.4
                l-0.2-0.2l-0.1-0.4v-0.3l-0.2-0.4v-0.5v-0.4l-0.3-0.5l-0.3-0.5l-0.3-0.1l-0.1-0.1v-0.2v-0.2v-0.1l0.2-0.2l0,0l0,0l-0.5-0.4l-0.1-0.1
                v-0.1v-0.1l0.1-0.1l0.1-0.1l-0.1-0.3v-0.2l-0.1-0.1v-0.1l0.1-0.1l0.4-0.1l0.1-0.2v-0.2l-0.1-0.1l-0.3-0.3v-0.1l0.3-0.5l0.2-0.3
                l0.1-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.3-0.2l-0.2-0.2l-0.1-0.2v-0.2v-0.3l-0.1-0.5v-0.3l-0.1-0.3l-0.4-0.5
                v-0.2l-0.1-0.2l-0.1-0.2l0.1-0.1l0.1-0.1V514l-0.5-0.3l-0.1-0.1l-0.1-0.5l-0.4-0.4v-0.3l0,0v-0.1v-0.2l-0.1-0.2l-0.2-0.1l-0.1-0.1
                l0.1-0.1l0.3-0.3h0.2v-0.1l0.1-0.1l0.3-0.4l0.2-0.3l0.2-0.2l0.2-0.1l0.1-0.1v-0.3v-0.2l0.1-0.1l0.2-0.1l0.2-0.1l0,0v-0.1l-0.2-0.1
                l-0.4-0.1h-0.2l-0.2-0.1l-0.1-0.1l-0.5-1.2l-0.1-0.3v-0.1l0.3-0.6l0.1-0.2l0.2-0.3v-0.1l-0.4-0.5l-0.1-0.2V505v-0.3l0.2-0.1l0.1-0.2
                v-0.2l0.1-0.1l0.1-0.1l0.1-0.2l0.2-0.2l0.1-0.1v-0.3l0.1-0.1l0.3-0.1v-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.1l-0.1-0.6l-0.1-0.3l0.1-0.1
                l0.1-0.1l0.1-0.3v-0.3v-1.2v-0.2l0.1-0.2l0.2-0.2l0.2-0.1l0.1-0.1v-0.2l0.1-0.1l0.1-0.2l-0.4-0.7l-0.3-0.6l-0.3-0.5l-0.4-0.6
                l-0.2-0.4l-0.3-0.5l-0.3-0.5l-0.4-0.6h0.3h0.7l0.6,0.1h0.4l0.2,0.1v0.2l0.1,0.1h0.1h0.2l0.3-0.2l0.3-0.1l0.2-0.1l0.1-0.1l0.3-0.4
                l0.2-0.2l0.2-0.1h0.4l0.1,0.1h0.2l0.2-0.2l0.2-0.3l0.5-0.3l0.2-0.1l0.1-0.1h0.3l0.2-0.1l1.1-0.8l0.4-0.2h0.3l0.2-0.1l0.4-0.1l1-0.1
                h0.6l0.2,0.1h0.2l0.2-0.2l0.2-0.1h0.1l0.2,0.2l0.1,0.2l-0.1,0.2v0.3l0.1,0.3v0.3l-0.2,0.4l-0.1,0.2v0.2v0.2l0.1,0.4l0.2,0.5v0.4
                l-0.1,0.2l-0.1,0.2v0.2v0.1l0.1,0.1v0.1v0.2l0.1,0.2l0.2,0.2l0.1,0.2v0.2v0.1h0.1h0.1h0.1h0.1l0.1,0.3v0.1l0.1,0.2v0.2l0.2,0.1
                l0.2,0.1l0.1,0.1l0.3,0.2l0.2,0.2l0.3,0.1l0.1,0.2l0.2,0.3l0.5,0.1l0.5,0.1l0.3,0.1l0.4-0.2h0.3l0.3,0.1l0.1,0.1l0.2,0.2l0.3,0.2
                l0.3,0.1l0.2-0.1h0.2h0.1l0.1,0.2l0.1,0.2l0.2,0.1l0.3,0.3l0.2,0.1h0.2l0.5,0.2l0.5,0.2h0.2h0.2l0.2,0.1l0.1,0.2l0.4,0.5l0.2,0.2
                l0.2,0.2h0.6h0.2h0.3l0.8-0.1h0.1l0.5,0.2l0.5,0.3l0.4,0.2l0.2,0.1l0.1,0.2l0.1,0.2v0.2l-0.1,0.2l-0.1,0.1v0.1v0.2l0.2,0.2l0.1,0.2
                l0.1,0.4l0.1,0.1l0.1,1.4H305h-0.5l0.1,0.1l0.4,0.5l0.4,0.5l0.1,0.8v0.5l0.1,0.7v0.4h1h1.1h1.3h1.2h0.1l0.2-0.1l0.1-0.1h0.1v0.2v0.2
                v0.2l-0.3,0.5v0.1l0.1,0.6l0.1,0.5l0.1,0.4l0.1,0.1l0.4,0.2l0.6,0.4l0.2,0.1l0.2-0.1l0.1,0.2v0.3l0.3,0.8l0.2,0.5l0.1,0.2l0.2,0.1
                v0.1H313l-0.1,0.1l-0.2,0.6l-0.2,0.8l-0.2,0.5h0.1v0.1v0.2h-0.2l-0.1,0.1l-0.2,0.4l-0.3,0.6l-0.3,0.6l-0.2,0.4l0.3,0.3l0.5,0.4
                l-0.1,0.1l-0.2,0.1h-0.2l-0.1,0.2l-0.1,0.1L311.9,520.2z"/>
            <linearGradient id="BM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="298.4579" x2="0.1864" y2="298.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BM" class="stcustom0 st27" d="M293.4,367.5l-0.3,0.1H293l0,0l0.3-0.1l0.2-0.3h0.1L293.4,367.5z"/>
            <linearGradient id="BQ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="244.9079" x2="0.1864" y2="244.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BQ" class="stcustom0 st28" d="M283.7,428.7l-0.1,0.3l-0.1-0.1v-0.3l-0.1-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.4,0.2L283.7,428.7
                L283.7,428.7z M298.5,413.3L298.5,413.3l-0.1,0.1v-0.1v-0.1h0.1V413.3L298.5,413.3L298.5,413.3L298.5,413.3z M297.6,412.9
                L297.6,412.9L297.6,412.9L297.6,412.9L297.6,412.9L297.6,412.9L297.6,412.9L297.6,412.9L297.6,412.9z"/>
            <linearGradient id="BR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="160.6081" x2="0.1864" y2="160.6081" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BR" class="stcustom0 st29" d="M316.6,457.4L316.6,457.4l0.3,0.1l0.4,0.1l0.6,0.1h0.2l0.1-0.1v-0.2v-0.2l-0.1-0.2l-0.2-0.2l-0.1-0.2
                l-0.2-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.3v-0.1h0.1h0.1l0.1,0.1l0.5,0.2h0.2l0.8-0.1l0.1-0.1h0.2l0.3-0.1h0.1h0.1h0.1l0.2-0.1
                h0.1v0.1l0.1,0.1l0.1,0.1h0.1h0.2h0.1l0.1,0.1v0.1l0.1,0.1h0.1h0.1l0.1,0.1l0.1,0.1l0.2,0.1l0.4,0.1h0.2h0.2h0.1l0.1-0.1l0.4-0.2
                l0.2-0.1l0.1-0.1l0.1-0.1h0.1v0.1v0.1h0.1l0.3,0.1h0.2l0.2-0.1l0.2-0.1h0.1l0.1,0.1l0.1,0.2l0.1,0.1h0.1h0.3l0.2,0.1h0.1l0.2-0.1
                l0.1-0.2l0.2-0.1l0.2-0.1l0.1-0.2l0.2-0.3l0.1-0.1v-0.2l0.3-0.6l0.1-0.1l0.1-0.2l0.1-0.2V454l0.1-0.2l0.2-0.2l0.1-0.1l0.2-0.3
                l0.1-0.2l0.3-0.5v-0.2l0.1-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.2-0.1l0.1-0.1l0.3-0.5v-0.2h0.2l0.4,0.2l0.3,0.4l0.4,1.2
                l0.1,1.1l0.2,0.6l0.5,1.2v0.2l0.1,0.3l0.1,0.3l0.2,0.5v0.1l-0.1,0.1h0.2l0.1,0.1l0.1,0.3l0.1,0.2l0.2,0.3l0.4,0.1h0.3l0.4,0.2
                l0.3,0.2l0.2,0.7l-0.1,0.4v0.3l-0.1,0.1l-0.3,0.2l-0.1,0.1l-0.6,0.5l-0.1,0.2l-0.3,0.3l-0.3,0.6l-0.5,0.6l-0.2,0.1H332l-0.2,0.1
                l-0.4,0.5l-0.5,0.1v0.3l-0.3,0.6l-0.3,0.3l-0.2,0.1l-0.4,0.6l-0.1,0.3v0.5l-0.3,0.3l-0.3,0.2v0.4l-0.1,0.1l-0.1,0.1l-0.6-0.1
                l-0.9,0.4l-0.3,0.1h1l0.3,0.2l0.7-0.1l0.8-0.5l0.3-0.1l0.7-0.4l0.3-0.2l0.5-0.3l0.1-0.1l0.3-0.1l0.1,0.2v0.1h0.1l0.1,0.1l0.1,0.2
                l0.1,0.2v0.2l0.1,0.3l0.3,0.4v0.1v0.2l0.1,0.2l0.1,0.1l0.5,0.5l0.4-0.3l0.2-0.1l0.2-0.1l0.3-0.1l0.3,0.1l0.5,0.2l0.4-0.2l0.8-0.4
                l-0.2,0.7l-0.2,0.6l-0.1,0.2l-0.1,0.7l-0.1,0.2l-0.1,0.2l0.2-0.1l0.1-0.1l0.2-0.3l0.1-0.4l0.6-1.2l0.2-0.1l0.5-0.1l0.8-1h0.3
                l0.2,0.2l0.2,0.1v-0.3l0.3-0.1l-0.3-0.1v-0.1v-0.2l0.2-0.3l-0.1-0.2l0.4-0.3v-0.2l0.1-0.2l0.2-0.2l0.2-0.1v-0.2l0.1-0.1h0.1l0.2,0.2
                l0.2-0.2l0.2-0.1h0.1l0.1,0.1h0.1h0.1l0.3-0.1l0.2,0.2h0.1v-0.1l-0.1-0.1l0.1-0.1l0.1-0.1l0.4,0.1l0.2,0.1l0.2,0.2h0.3h0.2l0.1,0.1
                h0.2l0.1,0.2l0.4,0.2l0.1,0.2l0.3,0.1l0.3,0.1h0.3h0.3v0.2l0.2,0.1h0.3l0.2,0.2l0.5,0.2l0.4,0.3h0.2l0.3,0.1l0.3,0.6l0.1,0.4
                l0.1-0.1l0.1-0.2l0.2-0.3l0.3-0.1l0.1,0.1l0.3,0.2l0.3,0.2l0.1,0.1h0.2l-0.2,0.2h0.2l0.2-0.1l0.2,0.3l0.1,0.3v0.3l-0.1,0.2l-0.1,0.1
                L350,469h-0.1l-0.1,0.1l0.2,0.2l0.1,0.1l0.2-0.4l0.2-0.1l0.2-0.1l0.1,0.3v0.3l-0.4,0.1v0.2l-0.1,0.1l-0.1,0.1l-0.1,0.3l-0.1,0.2
                l-0.2,1.1v0.2l0.3-0.2l0.5-0.5l0.2-0.6l0.2-0.6l0.2-0.2h0.1l0.2,0.1v0.2v0.1l-0.2,0.3l-0.1,0.2l0.1,0.2l0.5-0.5l0.2-0.2h0.2l0.4-0.2
                h0.8l0.1-0.2l0.2-0.1h0.4l0.8,0.2l0.3,0.2l0.4,0.2l0.2,0.2l1,0.4h0.7l0.3-0.2l0.4,0.2l0.2,0.2l0.5,0.1l0.5,0.1l0.3-0.1h0.9l1.1-0.2
                h0.7l0.8,0.1l0.5,0.3l0.5,0.2l0.3,0.2l0.4,0.2l0.9,0.5l0.3,0.3l0.6,0.4l0.6,0.2l0.3,0.4l0.3,0.2l0.6,0.8l0.7,0.5l0.5,0.5l0.9,0.3
                l0.4,0.6l0.6,0.1l0.3,0.1l0.3,0.2l0.4,0.1h0.6h0.6l0.5-0.1l1.2,0.2l0.2,0.1l0.2,0.2l0.4,0.9l0.3,1l0.1,0.8l0.3,0.6l0.2,1.1l0.1,0.3
                v0.3l0.1,0.1l0.1,0.7v0.3l-0.1,0.4v0.2v0.1l-0.1,0.2v0.2l0.1,0.4v0.3l-0.2,0.3l-0.2,0.9l-0.5,1.5l-0.5,0.8l-0.7,0.9l-0.5,0.5h-0.2
                l-0.2-0.1l0.1,0.2l-0.1,0.2l-0.5,0.6l-0.5,0.4l-0.5,0.7l0,0l-0.6,0.3l-0.4,0.2l-0.5,0.4l-0.4,0.7l-0.1,0.1h-0.2v0.3l-0.4,0.5
                l-0.1,0.1v-0.2l0.1-0.1v-0.1v-0.1l-0.1,0.1l-0.2,0.4l0.1,0.3l-0.2,0.4l-0.6,1.3l-0.8,1.1l-0.2,0.3l-0.6,0.7l-0.5,0.3h-0.1H367
                l-0.1-0.6l-0.4-0.3l-0.1-0.1l-0.1,0.4l-0.1,0.1H366l0.2,0.2l0.1,0.2l-0.2,0.4v0.3l-0.3,0.4l-0.2,0.3l-0.1,0.3l-0.1,0.3l0.2-0.1
                l0.1,0.1l0.1,0.1v0.1l-0.1,0.3v0.7v0.2l0.1,0.2l0.1-0.3l0.1,0.1l-0.3,1.8l0.1,0.8v0.9l0.1,0.9l0.2,0.8v0.1l-0.2,0.9l-0.3,0.9
                l-0.2,0.8l-0.1,0.8l-0.1,0.4v0.4l0.1,1v0.2l-0.3,0.4l-0.4,0.2l-0.2,0.2l-0.5,0.8l-0.2,1.1v0.6l0.1,1.3l-0.1,0.5l-0.1,0.3l-0.2,0.2
                l-0.4,0.3l-0.4,0.7l-0.2,0.7l-0.3,0.3l-0.1,0.4l-0.2,0.4l-0.6,0.6l-0.4,0.2l-0.2,0.2l-0.1,0.4l-0.4,0.6l-0.3,0.8l0.1,0.3l0,0
                l0.1,0.9v0.2l-0.3,0.3l-1.3,0.5l-0.3,0.2l-0.8,0.8v0.2v0.3l0.1,0.2l-0.1,0.2l-0.2,0.3h-0.2h-1.3l-0.7,0.1h-0.4l-0.2-0.1l-0.2-0.1
                l-0.1-0.2l0.1-0.2l0.1-0.4H354l-0.2,0.1v0.1v0.1l0.1,0.1v0.2l-0.1,0.2h-0.4l-0.5,0.1l-0.6,0.1l-0.5,0.1l-0.2-0.1l0.2-0.1h0.3
                l0.3-0.1l-0.1-0.1l-0.5-0.2l-0.5,0.1l-0.3,0.2h-0.6l-0.8,0.2l-0.1,0.2v0.3l0.1,0.1l0.1,0.1l-0.1,0.1l-0.1,0.1l-0.8,0.1l-0.7,0.6
                l-0.3,0.1l-0.3,0.3v0.2l-0.1,0.1h-0.2l-0.4-0.1h-0.5l-0.4,0.1l-1.8,1l-0.7,0.4l-0.8,0.8l-1.3,0.9l-0.7,0.5l-0.1,0.1h-0.1l-0.2,0.1
                l0.1,0.1h0.1l-0.1,0.3l-0.3,0.2l-0.5,0.6h-0.1l0.2-0.3h-0.2l-0.4-0.1l-0.2,0.1l0.1,0.3l-0.1,0.1h-0.2h-0.2l-0.2-0.2l0.1,0.4l0.5,0.1
                l0.2,0.1l0.1,0.1l-0.4,0.7l-0.3,0.1v0.1h0.2l0.1,0.2l-0.1,0.8l-0.2,0.1h-0.1l-0.1,0.1l0.1,0.2l0.1,0.2v0.4l-0.1,0.3v0.3l0.2,0.5
                l0.1,0.6l0.1,0.2v0.2l-0.1,0.2l0.1,0.3l-0.2,0.6l0.1,0.8v0.8l-0.1,0.4l-0.1,0.3l-0.3,0.4v0.4l-0.6,0.4l-0.7,0.5l-0.6,0.7l-0.7,0.9
                l-0.8,1.4l-0.7,2l-0.9,1.5l-0.4,0.6l-0.5,0.6l-0.6,0.7l-0.9,0.7l-0.9,0.7l-0.3,0.3l-0.3,0.4l-0.1-0.2l0.1-0.3v-0.2v-0.3l0.2-0.1
                l0.3,0.2l0.1-0.1l0.1-0.1l0.3-0.1l0.7-0.7l0.5-0.3l0.3-0.4v-0.2v-0.5l0.2-0.1h0.4l0.1-0.1v-0.2l0.1-0.3l0.5-0.3l0.2-0.4l-0.1-0.9
                h0.1l0.2,0.1l0.1-0.1l0.1-0.4v-0.2l-0.2-0.1l-0.8,0.4h-0.3v-0.3l-0.4-0.2l-0.2-0.3v-0.2l-0.1-0.1v0.3v0.3l0.4,0.4l-0.1,0.2l-0.2,0.2
                l-0.1,0.4v0.5l-0.1-0.2l-0.1-0.1v0.6l-0.2,0.2l-0.1,0.2l0.1,0.2l-0.1,0.2l-0.6,0.5l-0.6,0.3l-0.1,0.1l-0.1,0.3l-0.1,0.4l-0.3,0.3
                l-0.2,0.6v0.3l0.1,0.4l0.1,0.3l-0.2,0.2l-0.2,0.3l-0.2,0.4l-0.5,1.4l-0.4,0.9l-0.3,0.4l-0.4,0.5l-1.3,1.1h-0.1l-0.2-0.1l-0.2-0.1
                v-0.1v-0.1v-0.4v-1.1l0.1-0.2l0.1-0.1l0.2-0.2l0.2-0.3l0.3-0.4l0.2-0.3l-0.1-0.2l-0.2-0.2l-0.4-0.1l-0.4-0.3l-0.3-0.3l-0.1-0.3
                l-0.1-0.4l-0.1-0.3v-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.3-0.2l-0.4-0.4l-0.8,0.1l-0.1-0.3l-0.2-0.2l-0.9-0.3
                l-0.4-0.4l-0.2,0.1l-0.2-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.3l-0.3-0.3l-0.1-0.1h-0.1l-0.1,0.1v0.1l-0.1,0.1
                l-0.1,0.1l-0.1,0.2l-0.2,0.1h-0.2H318v-0.1v-0.2l0.1-0.5l-0.1-0.2l-0.2-0.2l-0.2-0.3l-0.6-0.6l-0.9-0.8l-0.3-0.3h-0.3h-0.3l-0.2,0.1
                l-0.2,0.4l-0.1,0.1h-0.5l-0.5-0.1l-0.2-0.2l0.1-0.2l0.4-0.3l0.2-0.3v-0.3l0.2-0.2l0.4-0.2l0.4-0.4l0.5-0.6l0.3-0.4l0.1-0.3l0.2-0.2
                l0.3-0.1l0.2-0.3l0.2-0.5l0.3-0.4l0.3-0.3l0.2-0.2v-0.2l0.1-0.1h0.2l0.1-0.1v-0.1v-0.1h0.1h0.1l0.2,0.1h0.1v-0.1l-0.1-0.1l-0.2-0.2
                l0.1-0.2l0.4-0.3l0.3-0.1l0.2-0.2l0.2-0.3l0.3-0.2l0.4-0.1l0.1-0.1v-0.1l0.1-0.1l0.2-0.1l0.1-0.1v-0.2l0.1-0.2l0.1-0.2l0.1-0.1h0.2
                l0.2-0.1l0.1-0.2l0.2-0.1h0.2h0.1l0.3-0.1l0.2-0.1l0.2-0.3l0.1-0.1l0.1,0.1l0.2-0.1l0.3-0.3h0.1l0.2-0.1l0.2-0.4l0.1-0.3V540
                l-0.1-0.2v-0.3l0.1-0.7v-0.3l0.1-0.2v-0.2l-0.2-0.4l-0.2-0.4l-0.1-0.7l-0.1-0.2l-0.2-0.1l-0.2-0.2H323l-0.1-0.1l-0.1-0.1h-0.1
                l-0.1,0.1h-0.2l-0.1,0.1l-0.2,0.1l-0.2-0.1l-0.1-0.1h-0.2v-0.4l0.4-0.7l0.1-0.3v-0.2l0.1-0.6l0.3-1l0.1-0.7l-0.1-0.3v-0.2l0.1-0.2
                l0.1-0.1l-0.4-0.2l-0.2-0.2l-0.2-0.2l-0.3-0.1l-0.1,0.1l-0.1,0.1l-0.3,0.1l-0.3,0.2l-0.2,0.1l-0.3,0.1l-0.3,0.1h-0.3h-0.2l-0.1-0.1
                l-0.1-0.3v-0.2v-0.3l-0.2-0.2l-0.1-0.1V530v-0.2v-0.1v-0.2l-0.1-0.1v-0.2v-0.3l-0.1-0.2l-0.1-0.2v-0.2l-0.1-0.2v-0.2l0.1-0.2v-0.2
                l-0.1-0.2l-0.2-0.1l-0.1-0.2v-0.3l-0.1-0.2l-0.1-0.1h0.1l-0.2-0.1h-0.2h-0.3h-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.3h-0.1l-0.2,0.1
                l-0.1,0.1l-0.1,0.1l-0.1,0.2h-0.2l-0.2,0.1h-0.2h-0.3l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3,0.1H314h-0.2h-0.3l-0.2-0.2l-0.2-0.1H313
                l-0.2,0.1h-0.2l-0.2-0.1l-0.1-0.2v-0.1v-0.1l0.1-0.2v-0.2v-0.2v-0.1v-0.2v-0.1v-0.2v-0.1V524l0.1-0.2l0.1-0.2l-0.1-0.2v-0.1l0.1-0.2
                l0.1-0.2v-0.4l-0.1-0.2l-0.1-0.1v-0.1V522v-0.1v-0.1v-0.1l-0.1-0.2h-0.1l0,0v-0.2v-0.2v-0.2l-0.1-0.1v0.1l-0.1-0.2l-0.1-0.1v-0.2
                l-0.1-0.2h0.2l0.1-0.1l0.1-0.2h0.2l0.2-0.1l0.1-0.1l-0.5-0.4L312,519l0.2-0.4l0.3-0.6l0.3-0.6l0.2-0.4l0.1-0.1h0.2v-0.2v-0.1h-0.1
                l0.2-0.5l0.2-0.8l0.2-0.6l0.1-0.1h0.1v-0.1l-0.2-0.1l-0.1-0.2l-0.2-0.5l-0.3-0.8v-0.3l-0.1-0.2l-0.2,0.1l-0.2-0.1l-0.6-0.4l-0.4-0.2
                l-0.1-0.1l-0.1-0.4l-0.1-0.5l-0.1-0.6v-0.1l0.3-0.5v-0.2v-0.2V509h-0.1l-0.1,0.1l-0.2,0.1h-0.1h-1.2h-1.3h-1.1h-1v-0.4l-0.1-0.7
                v-0.5l-0.1-0.8l-0.4-0.5l-0.4-0.5l-0.1-0.1h0.5h0.4l-0.1-1.4l-0.1-0.1l-0.1-0.4l-0.1-0.2l-0.2-0.2v-0.2v-0.1l0.1-0.1l0.1-0.2v-0.2
                l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.4-0.2l-0.5-0.3l-0.5-0.2H304l-0.8,0.1h-0.3h-0.2h-0.6l-0.2-0.2l-0.2-0.2l-0.4-0.5l-0.1-0.2l-0.2-0.1
                h-0.2h-0.2l-0.5-0.2l-0.5-0.2h-0.2l-0.2-0.1l-0.3-0.3l-0.2-0.1l-0.1-0.2l-0.1-0.2h-0.1h-0.2l-0.2,0.1l-0.3-0.1l-0.3-0.2l-0.2-0.2
                l-0.1-0.1l-0.3-0.1h-0.3l-0.4,0.2l-0.3-0.1l-0.5-0.1l-0.5-0.1l-0.2-0.3l-0.1-0.2l-0.3-0.1l-0.2-0.2l-0.3-0.2l-0.1-0.1l-0.2-0.1
                l-0.2-0.1v-0.2l-0.1-0.2v-0.1l-0.1-0.3h-0.1h-0.1h-0.1h-0.1v-0.1v-0.2l-0.1-0.2l-0.2-0.2l-0.1-0.2v-0.2V495l-0.1-0.1v-0.1v-0.2
                l0.1-0.2l0.1-0.2v-0.4l-0.2-0.5l-0.1-0.4v-0.2v-0.2l0.1-0.2l0.2-0.4v-0.3l-0.1-0.3V491l0.1-0.2l-0.1-0.2l-0.2-0.2h-0.1l-0.2,0.1
                l-0.2,0.2h-0.2l-0.2-0.1h-0.6l-1,0.1l-0.4,0.1l-0.2,0.1h-0.3l-0.4,0.2l-1.1,0.8l-0.2,0.1h-0.3l-0.1,0.1l-0.2,0.1l-0.5,0.3l-0.2,0.3
                l-0.2,0.2h-0.2l-0.1-0.1h-0.4l-0.2,0.1l-0.2,0.2l-0.3,0.4l-0.1,0.1l-0.2,0.1l-0.3,0.1l-0.3,0.2h-0.2h-0.1l-0.1-0.1v-0.2l-0.2-0.1
                h-0.4l-0.6-0.1h-0.7h-0.3h-0.3l-0.5-0.1h-0.3l-0.3,0.1l-0.4,0.2h-0.2h-0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.2,0.1l-0.1,0.1v-0.5v-0.7v-0.6
                v-0.5v-0.6v-0.4l0.1-0.2l0.1-0.2l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l-0.2,0.1h-0.1l-0.1,0.1l-0.2,0.2l-0.2,0.2h-0.9l-0.2,0.3l-0.2,0.1
                l-0.2,0.1l-0.3,0.3l-0.3,0.1h-0.8h-0.8h-0.7h-0.1v-0.3v-0.2l-0.2-0.2v-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.2-0.1l-0.4-0.1l-0.6-0.1
                h-0.6h-0.5l0.3-0.4l0.3-0.4v-0.4l-0.3-0.3l-0.1-0.2l-0.2-0.3l-0.3-0.2l-0.2-0.2v-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.1V486
                l-0.1-0.1l-0.1-0.2v-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1V485l0.1-0.1v-0.1v-0.1l-0.1-0.1h-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1
                l-0.1-0.1l0.1-0.1l0.1-0.1v-0.1v-0.1v-0.1h0.1h0.1l0.1-0.1h0.1h0.2l0.1-0.1v-0.1l-0.1-0.3l-0.1-0.1v-0.2l0.1-0.3l0.1-0.2l0.2-0.2
                l0.5-0.4l0.5-0.3h0.2l0.2-0.1l0.1-0.2v-0.2v-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.2l0.1-0.2l0.1-0.3l0.3-0.4l0.2-0.4v-0.1v-0.3l0.1-0.5
                l0.1-0.3v-0.1l0.1-0.1l0.2-0.1l0.4-0.1l0.3-0.2l0.4-0.3l0.3-0.3l0.3-0.1l0.5-0.3l0.3-0.2l0.1-0.1l0.3-0.1h0.5h0.4l0.2-0.1h0.3
                l0.2-0.1h0.3l0.5-0.1l0.2-0.2l0.1-0.2l0.2-0.2h0.2h0.2h0.3h0.4l0.2,0.1l0.1,0.1l0.2,0.2h0.2h0.2l0.2,0.1h0.1l0.1-0.1v-0.2v-0.1
                l0.1-0.6l0.2-0.9l0.2-0.9l0.2-0.9l0.2-1l0.2-1l0.1-0.8l0.1-0.7l0.1-0.4l0.1-0.6v-0.5v-0.1v-0.1l-0.3-0.2v-0.1v-0.1v-0.1l-0.1-0.1
                l-0.1-0.1l-0.1-0.1v-0.1l-0.1-0.1V465l-0.1-0.1v-0.1l0.1-0.1v-0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.2l-0.3-0.2l-0.3-0.3
                l-0.1-0.2v-0.4v-0.5v-0.7v-0.4h0.2h0.2h0.2h0.2l0.1-0.1l0.1-0.1h0.1h0.1l0.1-0.1l0.1-0.1h0.1h0.2l0.1,0.1l0.1,0.1h0.1h0.1h0.1
                l0.1,0.1h0.1h0.1h0.1l0,0l0,0V461v-0.1v-0.1v-0.1v-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1h-0.1l-0.1,0.1h-0.1l-0.1-0.1h-0.1h-0.1
                H280h-0.3h-0.1h-0.1l-0.2,0.1v-0.7v-0.7v-0.5h0.1l0.2-0.1h0.3l0.2-0.1h0.1h0.2l0.2,0.1h0.2h0.5h0.6h0.7h0.7h0.6h0.2l-0.1-0.2
                l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l0.2,0.1l0.1,0.3l0.1,0.2l0.1,0.1h0.1h0.2l0.2-0.1l0.3-0.4l0.3-0.3l0.1-0.1l0.2-0.1h0.1h0.2l0.1,0.1
                l0.1,0.1l0.3,0.5l0.2,0.4l0.1,0.2v0.6v0.5v0.1l0,0l0.5-0.1l0.7,0.6l0.5,0.5l0.2,0.2h0.1h0.3l0.4-0.1l0.2-0.1l0.2-0.1l0.3-0.2
                l0.3-0.1h0.1h0.1l0.2,0.1l0.1,0.2l-0.1,0.3v0.2h0.2l0.2-0.3l0.1-0.2l0.3-0.2l0.3-0.3l0.2-0.2l0.2-0.1l0.3-0.2l0.3-0.1h0.2l0.2-0.1
                l0.2-0.2l0.2-0.2l0.1-0.1h0.2h0.3l0.3-0.2l0.3-0.2l0.1-0.4l0.1-0.4l0.1-0.1l0.1-0.1h0.1h0.3l0.5-0.2l0.3-0.2h0.3l0.1-0.1l0.1-0.2
                l0.1-0.3V456l-0.5-0.1h-0.4l-0.6-0.1l-0.3-0.1l-0.1-0.1v-0.1l0.1-0.1l0.1-0.3l-0.1-0.4l-0.3-0.6l-0.2-0.6v-0.4v-0.4v-0.3l-0.2-0.2
                l-0.8-0.7l-0.3-0.3l-0.1-0.2l-0.3-0.4l0.1-0.1h0.2l0.2,0.1l0.1,0.2h0.1h0.1h0.8h0.2l0.1,0.1l0.1,0.1l0.1,0.3l0.1,0.1h0.3h0.5h0.3
                l0.2,0.1l0.2,0.1l0.4-0.1h0.1l0.1,0.1l0.4,0.5l0.3,0.2l0.2,0.3h0.3l0.3-0.2l0.1-0.7l0.1-0.2l0.1-0.1h0.2l0.2-0.1l0.2-0.2l0.2-0.1
                l0.7,0.2l0.2-0.1l0.7-0.2l0.7-0.3l0.2-0.3l0.3-0.1l0.2-0.2h0.2h0.3h0.2h0.1l0.1-0.1l0.2-0.3l0.2-0.1l0.3-0.1l0.2-0.1l0.1-0.2
                l0.1-0.2v-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.1H305l0.3-0.1l0.2,0.1h0.3l0.1-0.1h0.2l0.3-0.2l0.2,0.1h0.1l0.1,0.1l0.1,0.1l0.2,0.2v0.3
                v0.2v0.3v0.2l-0.1,0.2l-0.2,0.2v0.1v0.1l0.1,0.1h0.2h0.2l0.2,0.1h0.2l0.2,0.2l0.1,0.1v0.1l-0.1,0.2v0.2l0.1,0.1l0.1,0.1l0.2,0.4
                l0.1,0.1h0.1v0.1l-0.1,0.1l-0.1,0.2l-0.2,0.2v0.1l-0.1,0.1l-0.3,0.2l0.1,0.4v0.2v0.1l-0.1,0.2l-0.2,0.5l-0.1,0.3l-0.1,0.6v0.2
                l0.1,0.3l0.2,0.6l0.1,0.1l0.3,0.1v0.4v0.4v0.2v0.1h0.1l0.1,0.1v0.1v0.1l0.2,0.1h0.2l0.2,0.2l0.3,0.3l0.1,0.1l0.1,0.1l0.2,0.2
                l0.4,0.1l0.4,0.1l0.1,0.2l0.2,0.1h0.1h0.1l0.2-0.1l0.1-0.1h0.2h0.3V459v-0.1l-0.1-0.3l0.1-0.1h0.2v-0.1v-0.1l0.1-0.1h0.1h0.1h0.1
                h0.2l0.1,0.1h0.1h0.2l0.1-0.1v-0.1v-0.2h0.1h0.2l0.2-0.1h0.3h0.3l0.1-0.1l0.1-0.1l0.2-0.4l0.1-0.1l0.1-0.1h0.1l0.2-0.1l0.2-0.1h0.1
                l0.2,0.2h0.1h0.1l0.4,0.1h0.2l0.2-0.1h0.2h0.1L316.6,457.4L316.6,457.4L316.6,457.4z M333.9,457.4l-0.3,0.1h-0.2l-0.1-0.3v-0.3
                l0.2-0.1h0.2h0.1L333.9,457.4L333.9,457.4z M334.3,461.7l-0.3,0.1l-0.1-0.1v-0.4l0.1-0.2l0.4-0.1v-0.1h0.1l0.1,0.1v0.2L334.3,461.7
                L334.3,461.7z M333.6,462.4v0.4l-0.5-0.2v-0.4l0.2-0.1l0.2-0.2l0.1-0.3v-0.4l0.1-0.1h0.1h0.1v0.6v0.3L333.6,462.4L333.6,462.4z
                M335.5,462.1l0.1,0.1l-0.4,0.6l-0.2,0.1h-0.2l-0.3-0.2H334h-0.2v-0.3l0.2-0.3h0.4l0.7-0.2L335.5,462.1L335.5,462.1z M336.3,463.1
                l-0.7,0.1l-0.3-0.1l0.1-0.1l0.3-0.2l0.3-0.1l0.3-0.1l0.3,0.1l0.1,0.2v0.2H336.3L336.3,463.1z M332.9,463.2l-0.8,0.5l-0.3-0.2
                l-0.1-0.1v-0.1l0,0l0.1-0.2l0.4-0.2h0.2h0.3L332.9,463.2L332.9,463.2L332.9,463.2z M335.8,463.5h0.3l0.4-0.1l0.2-0.1h0.3h0.3
                l0.9,0.1h0.6h0.2l0.2,0.1l0.1,0.1v0.2l-0.1,0.2l-0.1,0.3l-0.1,0.4l-0.1,0.1h-0.1l0.1,0.3v0.1l-0.1,0.1l-0.1,0.3l-0.2,0.3l-0.1,0.1
                l-0.2,0.1l-0.1,0.1v0.1l0.1,0.1l-0.1,0.2l-0.3,0.3l-0.2,0.1h-0.1h-0.1l-0.2-0.3v0.2l-0.1,0.2l-0.1,0.1h-0.3l-0.2-0.1l-0.3-0.1
                l-0.1,0.3l-0.2,0.2l-0.2,0.1h-0.3l-0.2,0.1l-0.3-0.1l-0.3-0.2h-0.2l-0.1,0.1h-0.6l-0.3,0.1h-0.2l-0.3-0.3v-0.2l-0.2-0.3l-0.1-0.4
                l-0.1-0.4l0.1-0.3h0.2h0.2l0,0v-0.1v-0.1h-0.3l-0.2-0.2V465v-0.6v-0.1l0.1-0.2v-0.2v-0.2l0.1-0.3l0.1-0.3l0.5-0.3l0.6-0.1
                L335.8,463.5L335.8,463.5z M329.6,466.8l-0.3,0.1l0.4-0.7l0.3-0.3v-0.6l0.4-0.6l0.3-0.2l0.5-0.1l0.3,0.3l-0.3,1h-0.1l-0.4,0.5
                l-0.5,0.4L329.6,466.8z M349.1,466.5l-0.2,0.1l-0.1,0.1l-0.1-0.1v-0.1l0,0v-0.2h0.3L349.1,466.5L349.1,466.5z M350.2,471l-0.3,0.3
                l0.1-0.3v-0.2v-0.2l0.2-0.2h0.1v0.2v0.1L350.2,471L350.2,471z M366.4,499.7l-0.1,0.1v-0.2l0.3-0.2v-0.3l0.1,0.1v0.1v0.1L366.4,499.7
                L366.4,499.7z M365.9,500.7l-0.1,0.2h-0.1v-0.1l-0.1-0.1v-0.1h0.1h0.2V500.7z M351.2,529.2L351.2,529.2L351.2,529.2l-0.3,0.1
                l-0.3,0.1l-0.1-0.1l0.2-0.2l0.1-0.1h0.1l0.1,0.1L351.2,529.2L351.2,529.2z M348.1,531.5v0.2l-0.1-0.1l-0.3,0.1l-0.1-0.1l0.4-0.5
                l0.1,0.1l0.1,0.1v0.1v0.1L348.1,531.5L348.1,531.5z M338.7,539.2L338.7,539.2l-0.2-0.3l0.4-0.4l0.1,0.2l-0.1,0.3L338.7,539.2
                L338.7,539.2L338.7,539.2z M339,543.5l-0.2,0.1v-0.7l0.1-0.2l0.1-0.2l0.1-0.1l0.1,0.2v0.3l-0.2,0.4V543.5L339,543.5z"/>
            <linearGradient id="BS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="271.9579" x2="0.1864" y2="271.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BS" class="stcustom0 st30" d="M258.4,388L258.4,388l-0.3-0.3l-0.2-0.1l0.3-0.2l0.1-0.2v-0.4l0.1-0.2v-0.2l0.1-0.2l-0.1-0.2l-0.3-0.2
                l-0.5-0.7l-0.8-0.2h-0.4l0.2-0.1h0.2l0.3,0.1h0.4l0.2,0.2l0.2,0.3l0.2,0.1l0.1,0.1v0.1v0.1l0.3,0.1l0.3,0.2l0.1,0.6l-0.4,0.3
                l-0.1,0.9L258.4,388L258.4,388z M254.8,385.4l0.3,0.1h0.2l0.1-0.1h0.5l0.4-0.1l0.1,0.2v0.1l-0.9,0.1l-0.8,0.2l-0.4,0.2h-0.2
                l-0.2-0.1l-0.5-0.5h0.1l0.4,0.3l0.2-0.1l0.2-0.2v-0.1v-0.1l0.1-0.2L254.8,385.4L254.8,385.4z M260,389.3l0.5,0.3l0.4,0.1l0.4,0.4
                l0.2,0.2v0.1l-0.1,0.6l-0.1,0.4v0.3l-0.1-0.1l-0.1-0.2l-0.2-0.1l-0.1-0.1h0.3v-0.4l0.1-0.3v-0.3l-0.3-0.3l-0.2-0.3l-0.4-0.1
                L260,389.3l-0.2-0.1h-0.2l0.1-0.2l0.1-0.2l0,0L260,389.3z M256.9,391.7v0.4v0.3v0.1l-0.3,0.2l-0.1,0.1l-0.3,0.1L256,393l-0.1-0.2
                l-0.2-0.1v-0.2l-0.1,0.1l-0.2-0.1l-0.3-0.2l-0.2-0.3h0.3l0.1,0.2l0.2-0.2v-0.1l0,0l-0.1-0.2l0.3-0.5l0.1-0.3l-0.1-0.5h0.1l0.4,0.2
                l0.2,0.2v0.2l0.2,0.2L256.9,391.7L256.9,391.7L256.9,391.7z M258,390.7l-0.3,0.1l-0.2-0.1l-0.1-0.1l0.1-0.1l0.2-0.1h0.3l0.1,0.1l0,0
                L258,390.7L258,390.7z M263.7,393.2v0.2h-0.2l-0.3,0.1h-0.1l0.1-0.1l0.2-0.1v-0.1l-0.2-0.2l-0.3-0.5l-0.1-0.1l-0.1-0.2l-0.2-0.2
                v-0.1l0,0l0.2,0.1l0.4,0.7v0.1L263.7,393.2z M257.1,393.1L257.1,393.1l-0.3,0.3l0.2,0.1l0.2-0.3l0.2,0.2l0.1,0.5v0.1v0.1v0.1v0.1
                l-0.2,0.4h-0.6v-0.3l-0.1-0.1l-0.1-0.5l-0.2-0.2l-0.2-0.4l0.1-0.1h0.2h0.1h0.3L257.1,393.1L257.1,393.1L257.1,393.1z M266.2,393.6
                l-0.2,0.3h-0.1l0.1-0.4l0.2-0.1h0.1L266.2,393.6L266.2,393.6z M262.7,395.5h-0.1l-0.2-0.1l-0.5-0.4h-0.2l0.1-0.2l0.2,0.1l0.4,0.3
                l0.1,0.2L262.7,395.5z M265.1,397.2L265.1,397.2l-0.4-0.5l-0.4-0.1l-0.3-0.1l0.1-0.1h0.2v-0.2l-0.1-0.3l-0.2-0.4l-0.1-0.3l-0.1-0.1
                v-0.2l0.3,0.4l0.1,0.3l0.2,0.3l0.1,0.6l0.4,0.2l0.3,0.3L265.1,397.2L265.1,397.2z M267.3,397.7L267.3,397.7l-0.1,0.2l-0.4-0.1h-0.1
                l-0.1-0.2v-0.1v-0.1l0.2,0.1l0.1,0.2H267.3L267.3,397.7z M266.8,399.3l-0.2,0.1v-0.2l0.4-0.3l0.2-0.2l0.1-0.1v-0.1l0.2-0.1l0.1-0.1
                v-0.1l-0.2-0.2v-0.1l0.1-0.1h0.3l-0.1,0.2l0.1,0.4l-0.4,0.5l-0.3,0.2L266.8,399.3L266.8,399.3z M270.1,398.6h0.2h0.1l0.3,0.1
                l0.2,0.1v0.1l-0.1,0.1l-0.3-0.2h-0.3h-0.4h-0.1l0.1-0.2H270.1L270.1,398.6z M270.5,401.4l-0.4,0.1v-0.2l0.2-0.1L270.5,401.4
                L270.5,401.4z M270.1,402.3l-0.1,0.2l-0.3,0.4L269,403h-0.7l-0.1-0.1v-0.1l0.1-0.2v-0.1v-0.1l0.3-0.1l0.2-0.2h0.3l0.3,0.1h0.2
                l0.3-0.1l0.2-0.3h0.1L270.1,402.3L270.1,402.3z"/>
            <linearGradient id="BT_1_" gradientUnits="userSpaceOnUse" x1="1003.204" y1="282.9579" x2="0.186" y2="282.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BT" class="stcustom0 st31" d="M732.3,382.2L732.3,382.2l-0.1,0.3l-0.1,0.2v0.2l0.2,0.2l0.2,0.2h0.3l0.3-0.1h0.1l0.2,0.3l0.1,0.2
                l-0.1,0.2l-0.1,0.2v0.1v0.1l0.1,0.1l0.1,0.2v0.2l-0.1,0.1l-0.1,0.1h-0.2H733h-0.2l-0.2,0.1l-0.2,0.1H732l-0.2-0.2h-0.1l-0.4,0.2
                h-0.4l-0.8,0.1h-0.3h-0.3l-0.2-0.1L729,385l-0.3-0.1l-0.3,0.1h-0.1l-0.2,0.3l-0.5,0.1l-0.5,0.1H727h-0.3v-0.1v-0.1l-0.1-0.1
                l-0.1-0.1h-0.2L726,385l-0.1-0.1l-0.5,0.1l-0.3-0.2l-0.3-0.2l-0.2-0.1l-0.1-0.3l-0.1-0.1l-0.1-0.1l-0.1-0.1l0.1-0.1l0.3-0.2v-0.1
                l0.2-0.5l0.2-0.2l0.2-0.2l0.2-0.4l0.3-0.4l0.3-0.4l0.2-0.3l0.2-0.1l0.3-0.2l0.3-0.1l0.2-0.2l0.2-0.1l0.2-0.1h0.3l0.3,0.1l0.4,0.1
                v0.1v0.2l-0.1,0.2v0.1h0.1h0.3h0.4h0.2l0.5,0.1l0.2,0.1l0.2,0.1h0.2l0.2-0.2l0.2-0.1h0.1h0.1l0.2,0.1l0.4,0.1l0.3,0.1l0.1,0.1v0.4
                L732.3,382.2z"/>
            <linearGradient id="BV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="21.0079" x2="0.1864" y2="21.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BV" class="stcustom0 st32" d="M484.8,644.9L484.8,644.9h-0.2l0,0h-0.1l0,0l0,0v-0.1v-0.1l0,0h0.1h0.1h0.1l0,0l0.1,0.1v0.1v0.1
                L484.8,644.9L484.8,644.9z"/>
            <linearGradient id="BW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="138.8581" x2="0.1864" y2="138.8581" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BW" class="stcustom0 st33" d="M546,513.3L546,513.3l-0.1,0.3l0.1,0.2l0.1,0.2l0.2,0.2l0.1,0.1l0.1,0.3l0.1,0.3l0.2,0.3l0.6,0.6
                l0.1,0.2l0.1,0.2l0.4,0.4l0.1,0.1v0.3l0.4,0.9l0.2,0.5l0.2,0.1l0.7,0.5l0.6,0.4l0.7,0.3l0.5,0.2l0.2,0.1l0.1,0.1l0.1,0.3l0.1,0.4
                v0.3h0.5h0.4l0.2,0.1l0.1,0.1v0.2v0.3v0.2v0.2v0.3v0.4l0.1,0.1l0.4,0.5l0.2,0.3l0.2,0.4l0.1,0.1l0.1,0.1h0.4l1,0.2l0.6,0.2l0.5,0.2
                h0.2h0.1l0,0l-0.1,0.4v0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.4l0.2,0.2l0.1,0.1l-0.7,0.1l-0.3,0.2l-0.2,0.4l-0.3,0.3l-0.4,0.2l-0.4,0.1
                l-0.5,0.1l-0.5,0.3l-0.5,0.5l-0.3,0.3v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.2l-0.2,0.1h-0.3l-0.2,0.1
                l-0.2,0.2l-0.2,0.1h-0.1l-0.2,0.2l-0.3,0.4v0.2l-0.4,1.5l-0.2,0.2l-0.4,0.3l-0.3,0.4l-0.1,0.2l-0.2,0.1l-0.7,0.2l-0.3,0.1l-0.3,0.1
                l-0.1,0.1l-0.1,0.5l-0.2,0.7l-0.2,0.5l-0.1,0.4l-0.2,0.5l-0.2,0.2L547,537l-0.3,0.1h-0.4H546h-0.3l-0.4,0.2H545l-0.5-0.1l-0.4-0.1
                h-0.2l-0.4-0.3h-0.2h-0.4l-0.2-0.1l-0.2-0.2l-0.4-0.3l-0.4-0.3l-0.4-0.2l-0.3-0.1l-0.3,0.1l-0.3,0.1h-0.1l-0.2,0.1l-0.2,0.3
                l-0.2,0.4l-0.1,0.3l-0.2,0.6l-0.2,0.7l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.7,0.5l-0.4,0.6l-0.2,0.2l-0.3,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.3
                l-0.1,0.1h-0.1h-0.4h-0.1l-1.1,0.1l-0.3-0.1h-0.2l-0.4,0.1l-0.2-0.1l-0.1-0.3l-0.1-0.5v-0.4l0.2-0.3l0.2-0.2l0.2-0.3v-0.1v-0.1v-0.3
                v-0.3l-0.2-0.6l-0.3-0.7l-0.4-0.8l-0.1-0.2l-0.2-0.4l-0.9-0.7l-0.1-0.1v-0.1v-0.7v-0.9v-0.9v-0.9v-0.9V529v-0.9v-0.9v-0.9v-0.7h0.6
                h0.8h0.9h0.4v-0.1V525v-1.2v-1.2v-1.2v-1.2V519v-1.2v-1.2v-1.2v-0.6h0.7l0.8-0.1l1.4-0.2l1.3-0.2l0.8-0.1l1-0.2h0.3h0.1l0.1,0.1
                l0.5,0.6l0.3,0.5l0.1,0.2h0.1h0.1l0.2-0.1l0.5-0.5l0.1-0.1l0.3-0.2l0.4-0.2l0.3-0.2l0.3-0.1h0.1l0.2,0.1l0.2,0.1l0.7-0.6l0.3-0.1
                l0.9-0.1L546,513.3z"/>
            <linearGradient id="BY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="381.9079" x2="0.1864" y2="381.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BY" class="stcustom0 st34" d="M554.1,272.4l0.4,0.4h0.1l0.2-0.2l0,0h0.4l0.2,0.2l0.2,0.3l0.1,0.2l0.2,0.1l0.4-0.3l0.2-0.1h0.2
                l0.5,0.3l0.3,0.1l0.1,0.1v0.2l-0.1,0.2l-0.1,0.3l0.2,0.3l0.2,0.2l0.4-0.3l0.2-0.1h0.2l0.2-0.1l0.2-0.2l0.2-0.1h0.3h0.5l0.6,0.3
                l0.1,0.1l0.3,0.3l0.1,0.2l0.1,0.1l0.2,0.2l0.2,0.1h0.2l0.1,0.1l0.1,0.1v0.2v0.6l-0.1,0.2l-0.1,0.1v0.1v0.1l0.2,0.3l0.2,0.4l0.1,0.2
                v0.2l-0.3,0.5l-0.1,0.1l-0.1,0.3v0.3v0.1l0.5,0.4l0.4,0.2l0.1,0.1v0.1l-0.2,0.5v0.1l0.3,0.2l0.2,0.3l0.2,0.5l0.3,0.5l0.6,0.4
                l0.5,0.3l0.1,0.1v0.1v0.3l-0.1,0.4l-0.1,0.2l0.2,0.1h0.5l0.6,0.1l0.7,0.4v0.2l-0.1,0.2v0.2l0.1,0.2l0.6,0.5l0.1,0.1v0.2v0.2h-0.2
                l-0.2,0.1l-0.3,0.2l-0.1,0.3l-0.5,0.4l-0.3,0.2h-0.2l-0.6-0.1l-0.2-0.2l-0.1-0.2l-0.2-0.1h-0.3h-0.4l-0.1,0.1l-0.1,0.2l-0.2,0.4
                l-0.1,0.2l0.1,0.1l0.2,0.3l0.3,0.3l0.3,0.3l0.1,0.2v0.1l-0.1,0.2v0.3l0.3,0.4l-0.1,0.1v0.5v0.5l0.1,0.1l0.1,0.1l0.1,0.2l0.2,0.4v0.1
                h-0.5h-0.6l-0.4,0.3l-0.1-0.1L563,292l-0.3,0.1l-0.4,0.4l-0.3,0.3l-0.2,0.4l-0.1,0.2l-0.2,0.4l-0.1,0.4l0.1,0.3l0.1,0.3v0.3l0.1,0.2
                l-0.2,0.2l-0.1,0.2h-0.3l-0.3-0.2l-0.6-0.6l-0.3-0.2l-0.2-0.1h-0.3l-0.4,0.1l-0.6,0.1H558l-0.2,0.1l-0.3,0.1l-0.1-0.1l-0.2-0.4
                l-0.2-0.4l-0.1-0.2h-0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.2,0.1l-0.2,0.1l-0.2,0.3h-0.1l-0.1-0.1l-0.1-0.4l-0.2-0.1H555l-0.4-0.1
                l-0.3-0.1h-0.1L554,294h-0.2l-0.4-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2H553l-0.1-0.1v-0.4l-0.2-0.1h-0.4l-0.3,0.1h-0.1l-0.1-0.1l-0.4-0.6
                h-0.2h-0.3l-0.5-0.1l-0.6-0.1l-0.3-0.1h-0.3h-0.4l-1-0.3h-0.4h-0.6l-0.9-0.1h-0.6l-0.3,0.1l-0.3,0.1h-0.5H544h-0.3l-0.4,0.1
                l-0.1,0.1l-0.1,0.3l-0.4,0.5l-0.4,0.3h-0.1l-0.2-0.2l-0.2-0.1h-0.2l-0.2,0.1l-0.1,0.1v0.4l0,0l-0.2-0.5v-0.4l0.1-0.2l0.1-0.2
                l-0.1-0.3l0.1-0.4V292l-0.1-0.1l-0.1-0.2l-0.3-0.2l-0.1-0.1l-0.4-0.2l-0.4-0.2l-0.1-0.1v-0.1l0.1-0.1l0.3-0.4l0.3-0.4l0.2-0.2l1-0.5
                l0.2-0.2v-0.3v-0.2v-0.4l-0.1-0.6l-0.1-0.4l-0.2-0.7l-0.5-1.5l-0.3-1.6l0.2,0.1h0.5l0.4-0.1h0.2h0.2l0.3-0.1h0.2l0.1,0.1l0.2,0.1
                l0.5-0.2l0.4-0.2h0.4l0.1-0.1l0.1-0.6l0.1-0.1l0.5,0.1l0.2-0.1l0.2-0.3l0.3-0.2h0.2l0.3-0.2l0.1,0.1l0.1,0.2l-0.1,0.2v0.1l0.2,0.1
                h0.3l0.2-0.1V282v-0.1v-0.2l-0.1-0.2l-0.2-0.1h-0.2v-0.1l0.1-0.2l0.1-0.4l0.2-0.4l0.1-0.1v-0.1v-0.2v-0.4l0.2-0.6l0.2-0.4l0.3-0.1
                l0.4-0.1l0.2-0.2l0.1-0.2v-0.2l0.1-0.2l0.1-0.1h0.9l0.1-0.4l0.1-0.1l0.2-0.1l0.1-0.1v-0.1l-0.2-0.1l-0.5-0.1l-0.1-0.1v-0.1l0.1-0.4
                l0.1-0.5l0.1-0.4v-0.2l0.1-0.1l0.4-0.1l0.1-0.1l0.4-0.5l0.3-0.1l0.7,0.1h0.3h0.1h0.3v-0.1l0.1-0.5l0.1-0.1l0.6-0.7l0.4-0.3
                L554.1,272.4L554.1,272.4z"/>
            <linearGradient id="BZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="251.6579" x2="0.1864" y2="251.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="BZ" class="stcustom0 st35" d="M227.3,410.4l-0.1,0.3h0.1h0.1h0.3l0.1,0.4v0.3l-0.3,0.8v0.3l-0.1,0.4l0.2,0.3l-0.2,0.4l-0.1,0.2v0.4
                l0.1,0.7l-0.1,1l-0.3,0.4l-0.2,0.2l-0.3,0.4h-0.3l-0.5,0.7l-0.1,0.2l0.1,0.2h-0.1h-0.5h-0.3l0,0v-0.7v-1.1v-0.8v-0.8V414v-0.8v-0.7
                v-0.3l0.1-0.2l0.2-0.1l0.3,0.2l0.1,0.1h0.1l0.1-0.1l0.2-0.3l0.4-0.6l0.2-0.5l0.2-0.1h0.2L227.3,410.4L227.3,410.4z M228.3,412
                l-0.1,0.1l0.1-0.2v-0.1l0.2-0.5h0.1l0,0L228.3,412L228.3,412z M228.5,413.5l-0.2,0.4v-0.1L228.5,413.5v-0.1l0.1-0.1v-0.1l0.1,0.1
                v0.1L228.5,413.5L228.5,413.5z"/>
            <linearGradient id="CA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="492.7079" x2="0.1864" y2="492.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CA" class="stcustom0 st36" d="M280.1,14.2l2.3,0.4l0.7-0.2l0.8,1l0.5,0.1l0.8-0.2l0.6,0.2l2.4,0.4l0.5,0.4v0.5l-0.5,1l-0.7,1
                l-4.3,3.1l-0.3,0.5l0.8,0.2l1.2-0.1l1-0.3l1.1-1.1l0.4-0.1l0.7-0.5l1.4-1.5l1.1-0.8l0.5,0.3l0.4,0.5l0.3,0.1l0.1-0.4l0.2-1.2
                l0.1-0.4l0.4-0.3h0.2l0.4,0.6l0.4,1.3l0.4,0.9h0.2l0.8-1l0.4-0.1l1,0.4l0.4,0.4l0.1,0.5l-0.3,0.5l-0.2,0.4l-0.1,0.4l0.1,0.4l0.6,0.9
                l0.8,1.9V25l-0.5,1.4v0.4l2.2-1.5l2.2,0.7l0.6,0.5l0.2,0.5l0.3,0.9l0.3,1.2l-0.2,1.3l-1,2l-1,1.5l-0.6,1.4l-0.9,0.7l-3.1,3.2l-1.5,1
                l-0.9,1l-0.4,0.2l-1.8-0.2l-0.5,0.5l-0.3,0.9l-0.6,0.4l-0.9,0.3l-1.7,0.3l-0.4,1l-0.1,0.7l-0.2,0.5l-0.1,0.3l-5,3.6l-0.1,0.6
                l0.5,0.2l0.6-0.2l7.2-4.4l1.4-0.3l1.3,0.3l-0.1,1l-1.8,2.9l-2.3,2.5l-1.2,1.9l-2.9,3.2l-2.4,3.2l-0.9,1.6L280,65l-0.4,0.7l-0.5,0.3
                l-0.6-0.1l-0.5-0.4l-0.7-1l-0.7-1.2l-0.2-0.2l0.1,0.6l1.3,3.8l-0.1,0.7l-2.3,0.8l-1,0.7l-0.5,0.2l-0.4-0.1l-0.3,0.1l-0.4,0.3v0.3
                l0.3,0.3L274,71l2.1-0.8l0.3,0.1l0.5,0.4v0.4l-0.6,1.2l-1.7,1.4l0.2,0.1l0.5,0.5v0.4l-0.5,1l-0.2,0.3l-1.6,1l-0.7,0.2l-0.6-0.1
                l-2.8-2.1l-1-0.3l-1-0.5l-0.7,0.1l-0.7,0.6l0.3,0.3l1.4,0.6l1.1,0.1l0.5,0.2l0.2,0.4l0.5,1.4l0.1,0.7l-0.1,0.7l-0.1,0.5l-0.2,0.3
                l-0.3,0.1l-1.1-0.1l-0.4,0.2l-0.5,0.4l-0.6,0.2l-1.1-0.1l-1.3,0.6l-0.7,0.1l-0.8-0.3l-0.8-0.6l-0.9-0.3l-1.5-0.3l0.1,0.4l0.5,0.2
                l1.1,1l0.5,1.3l0.5,0.2l1,1.1l0.7,0.2l0.7,0.4l1-0.5l0.7,0.1l-0.1,2.6l-0.3,0.3h-1.7l-0.8-0.4l-0.3-0.4l-0.8-0.4l-0.7,0.3l-0.6-0.1
                l-0.4,0.3h-0.7l-1.8,0.4h-0.9l-0.7-0.3l-0.8-0.1l-0.9,0.1l0.1,0.3l0.4,0.1l0.6,0.5l0.5,0.8l0.4,0.3l0.5-0.2l0.5-0.4l1.9-0.6l0.8-0.1
                l0.8,0.3l0.5,0.3l0.4,0.4l0.4,1l1.1,0.1l0.8,0.3l1.3,1.5l0.4,0.1l0.1,0.4l-0.3,0.9v0.6l-0.9,1.1l-1.5,0.3l-1.6-0.1l-1.1,0.1
                l-0.1,0.1l0.8,0.3l1.8,1.2l0.7,0.7l0.1,0.4l-1,1.5l-0.9,2.9l-0.3,0.2l-0.3,0.1l-0.8-0.1l-1,0.7l-0.7,0.1l-1.4-0.3h-1.6l-0.1,0.5
                l-0.1,0.9v1.3l0.1,1.7l-0.1,1.3l-0.3,0.8l-0.3,0.6l-0.6,0.6l-0.6,0.3l-0.5,0.1h-0.8l-2.2,0.4h-1.1l-0.8-0.2l-0.8-0.6l-1.4-1.6
                l-0.4-0.4l-0.4-0.2v0.3l0.4,0.9l0.4,0.6l0.3,0.3l-0.1,0.3l-0.6,0.4l-0.7,0.2h-0.8v0.2l0.3,0.5l0.4,0.4l0.2,0.1l0.6-0.1l0.7-0.5
                l0.4-0.2l0.9,0.1l0.4,0.2l1.1,1.2l0.2,0.1l0.8-0.6h1.2l0.4,0.5l0.2,0.9v0.7l-0.1,0.6l0.3,0.6l0.7,0.5l0.5,0.1l0.4-0.3l0.6-0.7
                l0.2-0.2h0.2l0.3,0.5l0.5,1v1.2l-0.4,1.4l-0.5,0.9l-1.8,1.4l-0.6,0.6l-0.4,0.6l-0.6,0.5l-1.2,0.7l-0.7,0.1l-1.4,0.8h-0.3l-0.5-0.1
                l-0.1-0.4l0.1-0.7l0.2-0.6l0.2-0.6v-0.5l-0.4-0.7l-0.3-0.3l-0.3-0.2l-0.5,0.1L246,121h-0.3l-0.4-0.1l-0.3-0.3l-0.6-1.3l-0.2-0.2
                h-0.3l-0.3-0.2l-0.2-0.4l-0.4-0.4l0.1,0.3l0.4,0.8l0.3,0.7l0.1,0.7l-0.1,0.6l-3.2,0.3l-1.4-0.2l-0.3-0.5l-0.7-2.2l-0.1,3.8l-2.4,0.6
                l-0.6-0.1l-0.9-0.4l-1.2-1l-0.5-0.7l-0.2-0.7l-0.1-0.4l-0.1-0.1l-0.3,0.8l-0.3,1.7l-0.8-0.4l-1.1-0.4l-0.4-1.7v2.4l-1.7-0.3
                l-0.8,0.1l-0.2-2.1v-2.3l-0.3,1.5l0.1,1.3v1.5l-0.7-0.4l-1.6-0.2l-0.6-0.2l0.1-2l0.1-2l2-2.1l0.6-1l0.4-0.4l0.7-0.3l0.9-0.2l0.6,0.1
                l0.7-0.1l0.8-0.4l0.6-0.1l0.1-0.1l-0.2-0.2l-0.6-1.4l-0.2-0.3l-0.2-0.1l-0.5-0.1l-0.4-0.6l-0.3-0.5l-0.3-0.7l-0.4-1.4l-0.4-1.6
                l0.2-0.9l0.7-0.7l0.7-0.5l0.7-0.3h0.6l0.7,0.4l1,0.7l0.6,0.8l0.7,1.7l0.5,1.4l0.4,0.6l1.8,1.1l0.6,0.2l0.7-0.1l1.4-0.3l0.7-0.3
                l0.3-0.4l0.2-0.5l0.2-0.4l0.6-0.9l1-1.9l0.5-1.5l0.1-0.5l0.1-0.6l0.1-0.8l-0.3,0.4l-1.7,3.8l-0.3,0.7l-1,1.2l-0.4,0.2l-0.7-0.1
                l-0.9-0.5l-1.1,0.8l-0.6-0.2l-0.6-0.6v-2.7l-0.7-2.2l0.8-1.1l0.7-0.7l1.2-1.8h0.3l0.8,0.3l-0.5-0.4l-0.5-0.6l-1,0.2l0.4-4l-0.7,3
                l-0.7,1.6l-0.4,0.8l-0.5,0.4l-1.8,0.4l0.4-1.4l0.4-2.2l-0.4,0.8l-1,1.2l-0.7,0.6l0.1,0.3l-1.2-0.1l-0.6-0.6l0.2-1.5v-1.8L230,95
                l0.6-1.1l0.6-1.5l0.4-1.6l1.6-0.7l1.5-0.3L236,89l0.6-0.1l0.6,0.4l2.5,0.7l1,0.5l0.4,0.4l0.3,0.1l0.3-0.5l0.4-0.5l1.5,0.1L244,90
                l0.4-0.2l0.5-0.5l0.6-0.8l0.1-0.4h-0.4l-0.4,0.2l-0.6,0.6l-0.6,0.3l-0.4-0.3l-1.2-0.5l-2-0.1l-1-0.2l-0.5-0.2l-0.3-0.3l-0.2-0.5
                l-0.2-0.6l0.1-0.4l0.4-0.3l0.4-0.1l0.6,0.3l0.6,0.6l0.7,0.1l-0.2-0.5l-0.9-1.1l-0.6-0.9l-0.5-1.1L238,82l-0.9-1.8l-0.7-1.8l-0.5-0.8
                l-0.5-0.4l-1.6-0.5l-0.3-0.3l-0.8-1.6l-0.2-2.8l-0.3-1.7l0.3-2.2l0.5-1l3.2,0.8l1.4-0.1l1.7,0.3l0.9,0.5l1.1,1.3l1,1.5l0.9,1
                l0.8,1.4l0.9,2l0.5,0.9l0.5,0.6l0.6,0.5l1.2,0.6l1,0.2l0.5-0.1l0.6-0.5l0.4-0.5l-0.5-0.1l-1.2,0.1l-0.8-0.3l-0.5-0.6l-0.5-0.9
                l-0.8-1.6l-0.6-1.1l-1.3-1.8l-1-1.8l-0.8-1.7l-0.1-0.7l0.6-0.5l0.7-0.4l4.4-1.2l2.6-1.4l1.1-1.7l0.1-0.4l3.5-2.4l2.5-0.9l0.9-0.1
                l0.9-0.4v-0.2l-0.8-0.3l-0.8-0.2h-1.7l-1.5-0.3l-0.5-0.5l0.1-0.9l0.1-0.8l0.5-1.3l0.5-1l2.1-2.8l1.4-1.2l0.4-0.8l-3.1,1.9l-1.1,1.3
                l-1.1,2l-0.6,0.6l-0.4-0.2l-0.4,0.2l-0.3,0.5l-0.3,0.9l-0.2,1.4l-0.2,0.9l-0.2,0.5l-0.1,0.6l-1,1.4l-2.5,1.9l-0.8,0.5l-0.7,0.1
                l-2.3,1.1h-0.7l-0.8-0.3l0.3-0.9l1.2-1.7l0.3-0.8l-0.8,0.2l-0.8,0.5l-1.7,0.4l-0.7,0.7l-0.7,1.2L239,63l-0.4,0.3l-0.6,0.2l-2,0.1
                l-0.5-0.1l-1.2-0.9l-1,0.3l-0.4-0.1l-0.8-0.7l-0.2-0.4v-0.6l0.5-1.1l0.5-1.1l1.7-2.4l1.1-1.1l1.6-1l3.7-1.1l0.2-0.8l-3.8,0.9
                l-3.2,1.1l-0.5,0.4L233,56l-2.4,3.9l-0.7,1l-1.1,0.2l-0.8-0.3l-0.6-0.5l-1.1-1.2l-0.8-0.6l-0.4-0.4l-0.2-0.4l-0.2-0.5l-0.1-0.6
                l0.3-0.5l2.1-1l2.9,0.2l1.3-0.2l1.3-0.6l1.9-1.6l2-2.3l0.4-0.7l-0.7-0.2l-0.6,0.2l-1.3,0.8l-2.1,2.2l-1.8,0.8l-4.5,0.4l-1.4,0.6
                l-0.6-0.1l-0.5-0.6l-0.5-1.1l0.1-0.9l1.1-1l0.8-0.2l0.2-0.2l-1.2-1l-0.1-0.5l0.7-1.1l1.5-1.6l0.8-0.5l1.4-0.3l1.4,0.2l0.1-0.3
                l-1.4-0.6l-1.1-0.1l-1.4,0.4l-3.7,2.7l-0.3-0.1l-0.5-0.5l0.2-0.7l2-2.8l0.1-0.4l-1.4,0.1l-0.4-0.1l-0.4-0.3l-0.6,0.3l-0.8,0.9
                l-0.5,0.4l-0.3-0.1l-0.8-1.1l0.1-0.9l0.6-1.2l0.6-0.9l0.8-0.8l1.3-1l0.9-0.3h1.5l0.7-0.4l0.6-0.8l0.8-1.3l0.9-0.9l1.4-0.7l1.2,0.2
                l0.7,0.6l0.5,1.1l0.6,0.8l0.1-0.7l0.5-0.8l0.6-0.4l0.7,0.1l0.6,0.4l0.8,1l0.6,0.4h0.3l0.4-0.6h1v-0.2l-0.3-0.6l-0.4-0.4l-3.6-2.9
                l-0.1-0.6l1.2-0.6l0.8-0.7l0.4-0.2l0.9-1.6l0.6-0.8l1.1-0.9l0.4,0.3l0.5,0.8l0.5,0.5l1.5,0.5l0.7,0.5l1.2,2.9l0.5,0.9l0.7,1l0.4,0.4
                l0.8,0.2l0.1-0.5l-0.9-1.3l-0.3-0.8l0.1-0.7l0.2-0.4h0.3l0.7,0.6l1.9,2l2.9,2.4l1.1,0.5l0.7,0.8l0.6,1l0.6,0.7l0.1-0.1l-0.6-1.5
                l-1.4-1.9l-3.8-3.4l-1.5-1.8l-0.7-1.2l-0.5-1.2v-0.7l0.5-0.8l0.7-0.6l0.9-0.3l0.1-0.3l-0.8-1l-0.5-0.8V23l0.9-0.5l0.6,0.1l1.1,1.1
                l0.9,0.5l0.1-0.3l-0.9-2.8l-0.1-0.6l0.1-0.4l0.3-0.4l1,0.2l1.6,1.4l2.9,0.7l0.8-0.1l-0.1-0.3l-1.2-0.9l-1.2-1.2l-0.5-0.7l-0.4-1
                l-0.5-0.8v-0.4l0.8-0.6l2,0.1l1.8,1l1.6-0.3l1,0.2l0.4,0.3l0.7,1l2.3,3.7l0.2,0.6l0.3,0.9l0.3,1.2l0.4,0.3l0.7-0.8l0.5-0.8l-0.2-0.8
                l-1.2-1.8l-0.3-0.8l-0.6-1.3l-1.4-2.3l-0.4-1.1l-0.2-0.9l3.9-0.9l3.7,0.8l0.6,0.6l0.4,0.8l0.4,1.2l0.6,1.2l1.2,1.8l0.9,1l-0.3-0.8
                l-1.3-2l-0.6-1.4v-0.9l0.1-0.7l0.2-0.5l1.5-1.9l2.1-0.6l0.2,0.1l1.6,2.9l0.8,1.2l0.5,0.5v-0.2l-0.7-1.3l-0.6-0.7l-0.1-0.5l1-1.4
                l0.6-0.4l2.5-0.4l0.3,0.2l0.2,0.4l0.6,1.6L280.1,14.2L280.1,14.2z M217.2,52.7l0.4,1.5l1.4,3.6l0.6,1.3l1,1.6l0.1,0.6v0.2v0.8
                l0.3,0.3l0.9,0.5l1,0.8h0.2l0.3-0.5l0.4-0.1l0.5,0.1l0.3,0.4l0.2,0.5l0.1,0.4V65l-0.2,0.6v0.2l0.1,0.3v0.3l-0.2,1.2l0.1,0.4l0.5,1.1
                l0.5,0.5l0.9,0.6h0.6l0.4,0.4v-0.2l-0.2-0.7l-0.4-1l-0.6-0.5l-0.1-0.6v-1l0.1-0.6l0.3-0.5l0.3-0.2h0.8l0.5,0.2l0.8,0.7l0.1,0.4v0.8
                V69v0.3l-0.7,0.9l-0.1,0.6l0.2,0.2l0.6,0.1l0.9,0.5l0.4,0.1l0.4,1.2l0.3,1.2l-0.2,1.4l-0.3,2.2l-0.2,0.5l-0.2,0.8l0.1,0.1l0.9-0.3
                l0.2-0.1l0.6-0.8l0.9,0.2l0.3,0.2l0.1,0.3l0.3,0.8l0.2,1.1l0.2-0.1l0.4-1.3l0.1-0.3l0.2-0.3l0.1,0.1l0.4,1.3l0.9,2.2l0.3,0.9
                l0.1,0.7l-0.4,0.8l-0.3,0.4l-2.2,1.6l-1,0.9l-0.5,0.7l-0.3,0.2l-0.5-0.1l-0.1,0.1l-0.2,1.1l-0.2,0.5l-0.5,0.8l-0.7,1.4l-0.4,0.6
                l-0.7-0.4l-0.2-0.6l-0.1-1.4v-0.6v-0.3l0.1-0.5l0.3-1.3v-0.1l-0.1,0.1l-0.5,0.6l-0.2,0.3l-0.2,0.6l-0.1,1l0.1,1.8l-0.1,0.7l-0.2,0.3
                l0.1,0.3l0.5,0.4l0.1,0.2l0.1,0.3v0.4v0.4l-0.2,0.6l-0.3,0.2l-0.4-0.3l-0.8-1.5l-0.4,0.1l-0.1,0.2l0.1,0.5l0.3,1.2v1l-0.1,0.8
                l-0.2,1.3l-0.2,0.7l-0.1,0.1h-0.4l-0.4-0.3l-1.1-2.2l-0.5-1l-0.8-1.9l-0.2-0.4l-0.1-0.1l-0.1,0.8l0.2,0.8l0.7,1.8l0.4,1.3l0.3,1v0.4
                l-0.1,0.2l-0.2-0.1l-0.2-0.4l-0.6-0.6l-0.3-0.3l-0.2-0.4l-0.3-0.3l-0.5-0.2h-0.5l-0.5,0.1l-0.1,0.2l0.5,0.5L221,99l0.1,0.4l0.1,0.5
                l-0.2,0.3l-0.6,0.2l-0.9-0.1l-1.4-0.4l-1.4-0.7l-1.3-1l-0.9-1.1l-0.4-0.6l-0.1-0.4l0.3-0.4l1.2-0.5l1.2-0.3l-0.2-0.3l-2.2-0.6
                l-0.7-0.1l-0.4,0.2l-0.4-0.1l-0.3-0.5l-0.5-0.9l-0.2-0.6v-0.2l0.2-0.1l1,0.1l0.1-0.1l-0.5-0.5l-1.6-0.9l-0.6-0.8l-0.1-0.3v-0.3v-0.3
                l0.6-0.6l1.8-1.5l0.6-0.2h0.6l0.4-0.4l0.4-1.5l1.9-0.5l1.5-0.7l0.1-0.2l-1.1,0.1l-1.6-0.1l-0.7-1l-0.4-0.2h-0.5l-0.6,0.3l-1,0.9
                l-0.5,0.2l-1.1,1l-0.3-0.1l-0.1-0.1v-0.2l0.2-0.6l0.3-0.4l-0.1-0.2l-0.4-0.1h-0.5l-0.3,0.2l-1.2,0.8l-0.6,0.6l-0.2,0.1l-0.6-1
                l-1-0.5l-0.2-0.4l0.2-1.7l0.3-0.3l0.7-0.3l2.2-1.5l0.1-0.3l0.2-0.8l-0.5,0.2l-1.1,0.8l-0.9,0.4h-0.7l-0.5-0.1l-0.3-0.2L205,78
                l-1.3-2.3l-0.4-1.1l-0.1-1l-0.1-0.8l-0.4-1.8l2.8,1.1l1.1,0.2l2.1,0.1l0.1-0.1V72v-0.5v-0.4l0.1-0.3l0.8-0.7l0.1-0.2l-0.9-0.1
                l-1.7,1.1h-0.6l-0.7-1.6l-0.7,0.3l-0.3-0.1l-0.5-0.4l-0.3-0.3l-0.2-0.4l-0.1-0.4v-0.3l0.1-0.3l0.6-0.5h0.3l0.7,0.3h0.6l-0.2-0.5
                l-0.8-1.3l0.1-2.5l0.1-2.4l0.6-0.5l0.6-0.1l0.6,0.6l0.8,0.1l0.6,0.7l0.4,1.3h0.4l0.7,0.2h1.6l-0.3-0.5l-0.5-0.4l-1.1-0.5l-0.5-1.9
                l-1.1-1l-0.9-0.5v-0.4l0.7-2.4l0.8-0.9l1.3,0.3l0.9-0.5l1.1-0.9l1.1,0.4h0.3l0.2-0.3l0.1-0.5v-0.5l-0.1-0.4l-0.3-0.5l-1.4-0.1
                l-0.6-0.2l-0.2-0.3l-0.1-0.4v-0.5v-0.5v-0.3l0.1-0.2l0.3-0.2h1.3l0.8-0.3l0.8,0.3L216,50l0.6,0.6l0.6,1.1L217.2,52.7L217.2,52.7z
                M197.8,72.4l0.1,2.1l-0.1,1l-0.1,0.8l-0.1,0.2h-0.2l-0.8-0.6l-0.2-0.4v-0.3l-0.1-0.6l-0.5-0.8l-1,0.1l-0.4-0.1l-0.2-0.2l-0.1-0.3
                L194,72v-0.5l0.1-0.8l0.1-0.2l0.7-0.8l0.2-0.1l0.9,0.3h0.8l0.4,0.2l0.3,0.5l0.2,0.7C197.7,71.5,197.8,72.4,197.8,72.4z M184.8,82.8
                l0.7,0.3l0.8,1l0.7,2v0.3v0.3v0.4l-0.1,0.5l-0.1,0.3l0.4,0.4v0.2v0.4v0.3l0.2-0.3l0.2-0.5v-0.3v-0.8l0.6-0.4l0.6-0.6l0.3-0.1
                l0.5,0.1l1.1,1.4l0.5,0.2l0.2,0.2l0.1,0.3v0.4l-0.2,0.8l-0.1,0.5l0.1,0.3l0.6,0.3l1.4-0.5l1.2,1.3l0.7,1.6l0.5,0.5l0.1,0.3l-0.1,0.3
                l-0.1,0.7l-0.4,0.5l-0.1,0.2l0.2,0.6v0.4v0.5l0.1,0.3l0.5,0.3l1.2,2.2l0.2,0.6l0.1,0.8v0.3l-0.2,0.4l-0.2,1.2l-0.1,0.3l-0.5,0.2
                l-0.9,0.2l-0.8,0.4l-0.9-0.5l-0.9-0.8l-0.3-0.5l-0.2-0.6l-0.1-0.2l-0.1-1v-0.2l-0.4-0.6l-0.3-0.9l-0.6-0.1l-1.5-0.9l-0.6-0.2
                l-0.6,0.1l-0.9,0.4l-0.2-0.1l-0.2-0.3l-0.1-0.4v-0.3l0.1-0.6l-2.7,0.7l-0.8,0.8l-1.1-0.1l-0.5-0.3l-0.7-0.8l-0.3-0.7l-0.3-0.9v-0.7
                l0.2-0.5l0.3-0.3l0.3-0.1l1.4,0.6l1.3,0.3l0.5-0.3l0.2-0.8l-0.3-0.4l-1.2-0.2l0.3-0.4l1-0.4l0.5-0.6l-0.1-0.2l-0.3-0.3l-1.4,0.1
                l-0.5-0.2v-0.2l0.1-0.3l0.8-1.3v-0.2l-0.3-0.4l-0.3-0.6l-0.1-0.1l-0.7,0.5l-1,1.9l-0.2,0.3h-0.2l-0.2-0.3v-0.4l0.2-0.5l0.4-1.5v-0.5
                l-0.4-0.4l-1.1,0.3h-0.6l-0.1-0.4V86v-0.7l0.2-1.2l0.2-0.9l0.1-0.3l1.5,0.2l2.5-0.6l0.7-0.1L184.8,82.8L184.8,82.8z M205.1,94.3
                l0.7,0.4l1.1-0.2l0.4,0.3l1.1,0.9l0.3,0.6l0.1,0.4v0.2l-0.4,0.5l-0.7,0.7l-0.2,0.5l0.6,0.6l0.3,0.6l0.1,0.4v0.4l-0.4,1.1l-0.3,0.3
                h-0.5l-0.2,0.1l-0.6,0.5l-1,0.5l-1.3,0.2l-0.4,0.3l-0.6,0.5l-0.4,0.1l-0.1-0.3l-0.1-0.4v-0.3l0.1-0.4l-0.2-0.3l-0.9-0.7l-0.5-0.9
                l-0.1-0.3v-0.3l1.2-0.2l0.5-0.2l0.2-0.3v-0.2l-0.2-0.1l-0.8-0.6l-1.4-0.4l-0.1-0.4l-0.6-0.9l-0.1-0.8l-0.1-0.2l-0.4-0.4l-0.1-0.1
                L199,95v-0.3v-0.3l0.7-0.6l-0.1-0.4l-0.5-1.5l-0.1-0.8v-0.3l0.3-0.4h0.5l1.3,0.1l0.6,0.2l0.6,0.4l0.7,0.5l1,0.5l0.3,0.3l0.6,1
                l0.1,0.3V94L205.1,94.3L205.1,94.3z M166.9,92.6l0.5,0.8h0.2l0.2,0.2l0.1,0.2l0.3,1l0.1,0.5V96v0.6l-0.1,0.4l-0.3,0.3l-0.6,0.2
                l-0.9-0.3l-0.8,0.3h-0.4l-0.9-0.2l-0.2-0.2l-0.5-0.6l-0.4-0.3l-0.2,0.1l-0.2,0.6l-0.4,0.7l-0.2,0.2l-0.7-0.1l-1-1.2l-1.2,0.3
                l-1.2,0.7l-0.5,0.1l-0.1-0.2l-0.2-0.5v-0.3l0.4-0.8l0.8-0.8l0.6-0.5l1.2-0.7l1.4-0.4l0.5-0.4l0.3-0.6l0.9-0.9l0.5-0.4l0.7-0.3h0.6
                l0.8,0.8l0.6,0.4L166.9,92.6L166.9,92.6z M186,99.6l-0.3,0.3l-0.4-0.2l-0.1-0.2v-0.2v-0.2l0.4-0.9l0.4-0.3l0.2-0.1l0.2,0.3l0.1,0.4
                L186,99.6L186,99.6z M165.1,100.2l2.2,0.3l0.1,0.1v0.2l-0.2,0.8l-0.3,0.6l-1.2,0.7l-1.5,0.6l-0.3,0.3v0.2l0.1,0.2l0.3,0.3l1.2-0.1
                l0.3,0.1l0.1,0.2l0.1,0.3v0.4v1.2l-0.1,0.7l-0.2,0.6l-0.5,0.4l-0.9,0.6l-0.6,0.3l-0.5-0.1l-0.5,0.1l-2,1.1h-0.6l-0.6-0.3l-0.8-1
                l-0.8-0.4l-0.3-0.5l-0.3-0.3l-0.1-0.4v-0.3l0.1-0.3l0.1-0.2v-0.2l-0.1-0.6l-0.1-0.6l-0.2-0.8v-0.5v-0.3v-0.3l0.2-0.6l0.1-0.1
                l0.5-0.1l0.6-0.3l1.4-0.9l3.1-1.1l0.9,0.1l0.4-0.2L165.1,100.2z M155.6,104.9l-0.8,0.4h-0.5l-0.9-0.6l-1-1.9l-0.1-0.7l0.4-0.1
                l0.3-0.2l0.2-0.3l0.3-0.3l0.8-0.5l0.1,0.2v0.4l0.1,0.4l0.3,0.1l0.3,0.3l0.5,0.8l0.4,0.2l0.1,0.2l0.1,0.3l0.2,0.5v0.3l-0.3,0.5
                L155.6,104.9L155.6,104.9z M189.7,105.7l-0.4,0.1l-0.7-0.1l-0.8-0.5l-0.2-0.6v-0.9v-0.5l0.1-0.1l0.5-0.1l1-0.1l0.8,0.1l0.9,0.5
                l0.4,0.3l0.2,0.2l0.2,0.5l0.1,0.2v0.4l-1.1,0.1l-0.5,0.1L189.7,105.7z M207.1,104.4l0.7,0.5l0.8-0.3h0.8l1.8,0.2l1.2-0.1l0.3,0.1
                l0.5,0.3l0.3,0.4l0.2,0.7l-0.6,0.4l-0.5,2l-0.1,0.1h-0.6l-0.3,0.2l-1.6-0.3l-4.4-0.1l-0.2-0.3l-0.6-1.2v-0.5l0.1-0.6l0.1-0.4
                l0.1-0.2l1.3-0.9L207.1,104.4L207.1,104.4z M181.7,112.8l-0.4,0.2l-0.9-0.5l-0.6-0.2l-0.5-0.9l-0.5-1.3l-0.4-1.4l-0.1-0.8l-0.3-0.5
                l-0.1-0.8l-0.5-1.3l0.1-0.2l0.5-0.2l0.8,0.2l0.4,0.5l0.5,0.8l0.6,1.2l0.2,0.5v0.7l0.1,0.4l0.5,0.1l0.6,1l0.1,0.4l0.1,0.8v0.4
                l-0.1,0.7L181.7,112.8L181.7,112.8z M223,111.2l-0.7,0.7h-0.4l-2.1-1.5l-0.4-0.7l-0.1-0.5v-0.7v-1l0.1-0.7l0.1-0.2l0.3-0.2l0.5-0.1
                l0.5,0.1l0.7,0.3l0.7,0.4l0.9,1.3l0.3,0.6l0.1,0.8v0.5v0.4l-0.3,0.2L223,111.2z M150.8,110l0.2,0.5v0.2l-0.1,0.2l-0.3,0.3l-1.7,1.1
                l-0.3,0.5l0.1,0.4l0.6,0.9l0.6,0.8l0.1,0.4l-0.3,0.4l-0.6-0.1h-0.2l-0.2,0.2l0.1,0.3l0.6,1.1l0.2,0.6v0.3l-0.1,0.3l-0.3,0.4
                l-0.4,0.5l-0.7,0.4l-1.5,0.5v0.4v0.3v0.8l-0.1,0.4l-0.2,0.6l-0.1,0.3l-0.2,0.2l-0.3,0.1h-0.4l-0.7-0.5l-0.3-0.3l-0.4-0.7l-0.1-0.5
                v-0.6l0.1-0.9l0.2-1l0.3-1l0.1-0.6l-0.1-0.2h-0.2l-0.5,0.4l-0.4,0.1l-0.3,0.3l-0.2,0.4l-0.1,0.5l-0.1,0.9l-0.1,0.5l-0.3,0.3h-0.4
                l-0.2,0.2l-0.1,0.3l0.1,0.3l0.4,0.6l0.1,0.6l-0.1,0.4l-0.5,0.7l-0.1,0.2l-0.3,1.1l-0.1,0.3l-0.2,0.2h-0.2l-0.2-0.4l-0.3-0.7
                l-0.2-0.6l-0.1-0.5l-0.1-0.2l-0.2,0.2l-0.2,0.6v0.4v0.6v0.4l-0.3,0.5v0.2l0.5,0.6l0.1,0.2v0.4v0.2h-0.2l-0.2,0.4L139,128l-0.5,0.7
                l-0.7,0.1l-0.6,0.3h-0.1l-0.1-0.5l-0.2-1l-0.1-0.6l-0.2-0.3l-0.3-1.2l-0.1-0.4l-0.1-0.2h-0.1l-0.1,0.2l-0.3,1.7l-0.5,0.4l-0.3,0.1
                h-0.3l-0.7-0.5l-0.6-0.2l-0.4,0.2l-0.7,0.7l-0.3,0.2l-0.4-0.1l-0.2-0.3l-0.1-0.4v-0.3l0.1-0.1l0.2-0.5v-0.2l-0.2-0.3v-0.2l0.1-0.1
                v-0.1l-0.1-0.1l-0.2,0.1l-0.6,0.3l0.1-0.4l0.3-0.7l0.7-1.5l0.3-0.4l0.2-0.1l2.1-0.5l0.1-0.1l1-2l0.3-0.5l0.3-0.4l1.4-1.2l0.1-0.3
                l0.2-0.9l0.1-0.2l0.3-0.3l1-1.8l0.9-1.3l0.5-0.8l0.7-0.8l0.8-0.4l2.3-0.6l1.6,0.8l0.4,0.1l0.2-0.2l0.2-0.4l0.2,0.2l0.6,0.1l0.1-0.1
                l0.3-0.4l-0.2-0.2l-0.7-0.4v-0.2v-0.2l0.3-0.5l0.3-0.3l0.9-0.2l0.4,0.1l0.4,0.3l0.6,0.7L150.8,110L150.8,110z M210.5,115.7l0.5,0.1
                l0.4-0.2h0.4l0.6,0.5l0.5,0.8l0.4,0.3l0.1,0.2l0.1,0.2l0.1,0.6v0.3v0.2l-0.2,0.5l-0.1,0.6l-0.3,0.6l-0.2,0.4l-0.1,0.5l0.3-0.3
                l1.2-1.8l0.8,0.2l1.2-0.2l1.4-0.7l0.7-0.1l0.7,0.1l0.5,0.2l1.1,1l0.4,0.5l0.1,0.3v0.2l-0.2,0.4l-0.7-0.2l-1.1-0.2l-0.2-0.1h-0.2
                l-0.1,0.1l0.1,0.5l0.2,0.1l1.3,0.1l4.4,1.6l0.2,0.5v0.2l-0.2,0.2l-0.3,0.3l-2.5,0.4l-1.4-0.3l-1.2-0.5l-0.4,0.1l0.4,0.7l0.7,0.2
                l0.6,0.4l0.3,0.3l1.3,0.3l0.2,0.3l0.4,0.7l0.3,0.1l0.3,0.5l0.3,0.8l0.1,0.1l0.4-0.1l0.7,0.7l0.2,0.4v0.3l-0.1,0.4l-0.3,0.6l-0.7,0.7
                l-0.1,0.2l0.9-0.1l0.2,0.1l1,1.2h0.1l0.1-0.1l0.1-0.4v-0.4l-0.1-0.6v-0.4l0.2-0.3l0.2-0.1h0.2l0.2,0.2l1,1.5l1.3-0.7l0.2,0.3
                l0.2,0.6l0.1,0.1l0.5-1.2l0.3-0.3l1.2,1.4l0.8,0.3l0.3,0.3l0.6,0.3l0.8,0.1l0.1-0.5l-0.5-0.7l0.3-0.3l1.1-0.6l0.6,0.1l1.1-0.8
                l0.7-0.1h0.4l1.3-1.2l0.3-0.2l0.2-0.4l0.5,0.1l1.4,0.7l0.4-0.1l1.5-0.7l0.6-0.2h0.6l1.4,0.4l1.1,0.4l0.3,0.2l-0.1,0.6l0.1,0.2
                l0.1,0.1l0.3,0.2h1.3l0.6,0.1l0.5,0.5l0.1,0.2v0.2l-0.5,0.6l0.1,0.1l0.5,0.1l1,0.1l0.2,0.1l0.2,0.7l0.2,1v0.4l-0.4,0.7l-1,0.9
                l-1.1,0.7l-0.1,0.2l0.3,0.3l0.4,0.2h0.3l0.9-0.3h0.2l0.4,0.3l0.2,0.3l0.2,0.4l-0.3,0.4l-1.2,0.5l-0.7-0.7l-0.2-0.1l-0.2,0.1l0.1,0.3
                l0.4,0.5l0.1,0.3l-0.1,0.2l-0.1,0.3v0.5v0.5l-0.1,0.8l0,0l-2.7,0.2l-0.3,0.1l-0.7,0.5l-0.6,0.3h-0.4l-0.4-0.1l-1-0.6l-0.9,0.6
                l-0.3-0.1l-0.5-0.4l-0.1-0.2l-0.2-0.5l-0.2-0.7v-0.4l0.1-0.6v-0.3h-0.2l-0.2-0.1l-0.5-0.6l-0.3-0.2l-0.1,0.1l0.1,0.5l0.1,0.2
                l0.3,0.3l0.1,0.4l-0.1,1l-0.1,0.2l-0.3,0.7l-0.3,0.2h-0.7l-1.1,0.5l-0.5,0.1l-0.7-0.1l-0.4-0.2l-0.3-0.3l-0.3-0.4h-0.1l-0.1,0.8
                l-0.1,0.1H236l-0.4-0.3l-0.3-0.6h-0.1v0.4l-0.1,0.2l-0.1,0.1l-0.7,0.4h-0.4l-0.4-0.4h-0.3l-0.4,0.2l-0.9-0.4h-0.2l0.2,0.7l-0.3,0.1
                H231l-1-0.2l-0.6,0.3l-1.2-0.2l-1.2-0.1l-0.2-0.2l-0.2-0.3v-0.3l0.1-0.4l0.2-0.6l0.3-0.8l0.1-0.4l-0.3-0.2l-0.2-0.3h-0.1l-0.4,0.3
                l-0.3,0.9l-0.2,0.3l-0.1-0.2l-0.1-0.6l-0.1-0.3h-0.1l-0.1,0.1l-0.1,0.3l-0.2,0.1l-0.2-0.1l-0.1,0.1l0.1,0.4v0.3v0.3l-0.2,0.3
                l-0.5,0.4l-0.3,0.1l-0.8,0.1l-0.5-0.1l-1-0.5H221l-0.6-0.9l-0.5-0.2v-0.3l0.2-0.6v-0.2l-0.7,0.9l-0.2,0.4l0.1,0.6h-0.1l-0.5-0.2
                l-0.5,0.2l-0.1-0.1l-0.3-0.5l-0.6-0.5l-0.3-0.5l-0.4-1.7l-0.2-1.1v-0.2l0.3-0.3v-0.2l-0.4-0.6l-0.4-0.5l-0.1-0.4l-0.1-0.4l-0.1-0.5
                v-0.4v-0.3l0.2-0.8l0.6-1.5l0.1-0.3v-0.3l-0.1-0.8l-0.1-0.8l-0.1-0.6l-0.3-0.8l-0.5-0.8l-0.7-1.5l-0.5-1.2l-0.6-1.7l-0.3-0.1
                l-0.3,0.1l-0.7,0.6l-0.2,0.3l-0.1,0.2h-0.5l-1.5-0.1l-0.6-0.2h-0.4l-0.7,0.4l-0.8-0.1l-0.5-1.2l-1.1-0.6l-0.3-0.4l-0.2-0.5l0.1-0.3
                l0.6-0.3l0.3-0.3l0.1-0.3l-0.6,0.2h-0.3l-1.9-1.6l-0.6-0.3l-0.1-0.1v-0.2v-0.2l0.1-0.4l0.6,0.5h0.2l0.4-0.1l0.1-0.3l-0.1-0.2
                l-0.6-0.5l-0.3-0.4l-0.1-0.3l0.1-0.4v-0.3l0.2-0.2h0.4l0.5-0.2l0.9-0.6l0.6-0.2h0.6l1.4,0.6l1.4,0.7L210.5,115.7L210.5,115.7z
                M156.4,117.8l-0.4,0.4l-2.9-0.6l-0.2-0.2l-0.1-0.3l0.5-0.7l0.6-0.3l1.5-0.2l0.5,0.3l0.3,0.3l0.2,0.3l0.1,0.5L156.4,117.8
                L156.4,117.8z M223.3,120.6l-0.1,0.2l-0.4-0.1l-0.1,0.2l-0.2-0.1l-0.3-0.3l-0.4-0.7l-0.4-1l-0.3-0.9v-0.2l0.1-0.4l0.3-0.3l0.8-0.3
                h0.5l0.5,0.7l0.1,0.2l0.1,0.3v0.3v0.2l-0.2,0.5l-0.1,0.4v0.3v0.5l0.1,0.3l0.1,0.2L223.3,120.6L223.3,120.6z M171.2,126h0.4l0.4-0.1
                l0.5,0.1l0.2,0.3l0.1,0.5v0.2v0.2l-0.1,0.2l-0.6,1.2L172,129l0.2,0.1l0.1-0.1l0.6-0.9l0.5-0.3l0.3-0.1l0.6,0.2l0.2,0.1l0.2,0.2
                l0.1,0.2l0.2,0.8l0.2,1.1v-0.1v-0.6v-0.5l0.6-0.3v-0.1l-0.2-0.3l-0.2-0.4l-0.1-0.7v-0.2l0.1-0.3l0.4-0.6l0.4-0.3l0.4-0.1l1.4,0.6
                l0.5,0.5l0.2,0.2l0.1,0.2l0.1,0.6l0.2,1.5v0.5l-0.1,0.8l-0.4,1.5l-0.1,1l-0.4,2.4l-0.3,0.7l-0.3,0.5l-1.4,0.8l-1,0.8l-0.3,0.1h-0.3
                l-0.9-0.3l-1-0.5l-0.6,0.1l-0.6,0.4l-0.4,0.1h-0.3l-0.3-0.1l-0.4-0.4l0.2-0.2l0.1-0.2l-0.1-0.2l-0.5-0.3l-0.5,0.6l-1.4,1.4l-1.9,0.5
                l-0.6,0.3l-0.4,0.4l-0.2,0.3l-0.4,0.7l-0.5,0.5l-1,0.6l-1.2,0.9l-2.2,0.9l-1.4,0.2l-1.4-0.3l-0.4-0.2l-0.5-0.4l-0.9-0.9l-0.3-0.3
                l-0.3-0.7l0.2-0.5l0.5-0.5l0.8-0.5l1.5-0.7l1.4-1.1l0.5-0.2l1.3-0.2l0.7,0.1l0.5-0.1l0.3-0.1l0.5-0.4l0.7-0.8l0.5-0.7l0.1-0.3
                l-0.2-0.3h-0.2l-0.8,0.8l-0.4,0.3h-0.4l-0.6,0.3l-0.6,0.1h-0.1l-0.6-0.7l-0.3-0.1l-0.2,0.1l-0.1,0.2l-0.3,0.5l-0.2,0.2l-0.3,0.1
                l-1.1,0.2l-1,0.3l-0.2-0.2l-0.1-0.3v-0.2v-0.6l-0.1-0.3l0.1-0.5l0.1-0.4l0.1-0.3l0.7-0.8l0.1-0.2h-0.3l-0.8,0.4l-0.1-0.1l-0.2-0.5
                h-0.1l-0.1,0.2l-0.1,0.3l-0.1,1.1l-0.1,0.6l-0.3-0.1l-0.4-0.3h-0.2l-0.1,0.1v0.2l0.4,1v0.3l-0.3,0.6l-1.1,1l-0.5,0.3l-0.2-0.1
                l-0.1-0.3l-0.1-0.4l-0.3-0.6l-0.2-0.1h-0.2l-0.2,0.1l-0.2,0.3l-0.1,0.3l-0.1,0.5l-0.2,0.4l-0.1,0.1l-1.2-0.8l-0.9-1.4l-1,0.2
                l-0.5-0.1l-1.4-0.5l-0.2-0.3l-0.1-0.4v-0.2l0.1-0.2l0.2-0.5l0.4-0.7l0.1-0.2l0.2-0.2l0.3-0.2l0.7-0.1h1.9l0.4-0.1l2.1-1.4l0.2-0.2
                l0.3-0.5l0.1-0.2v-0.1l-2.6,1.1l-1.1,0.2l-1.7-0.2l-0.3-0.2l-0.1-0.3l0.4-0.8l0.2-0.3l0.5-0.3l1.2-0.4l1.5-0.4h1l0.9-0.3l0.5-0.3
                H151l-2.1,0.2l-0.3-0.1l-0.6-0.4V128l0.2-0.4l0.1-0.3l-0.2-0.7l0.1-0.3l0.4-0.6l0.7-0.6l0.4-0.1l0.8,0.2l2.3,0.2l0.5-0.1L153,125
                l-0.4-0.2l-1.8-0.3l-0.4-0.2l-0.1-0.2v-0.3v-0.4l0.1-0.4l0.6-0.9l1.6-0.7l0.6-0.1h0.7l0.7,0.3l0.3,0.3l0.1,0.3l0.1,0.3v0.6v0.2
                l0.1,0.4l0.4,0.8l0.3,0.3l1.3-0.5l0.5-0.1l0.5,0.2l0.8,0.5l1,1.5l1.3,1.5v0.3l-0.5,0.5l-0.1,0.2l0.1,0.2l0.5,0.1l0.5-0.1l0.4,0.1
                l0.1,0.1l0.2,0.6l0.2,1l0.3,0.7l0.3,0.5l0.3,0.2h0.5l0.5-0.1h0.7l3.9,0.5l0.2-0.1l0.2-0.3l0.1-0.5v-0.4l-0.1-0.6l-0.1-0.3l-2.4-1.9
                l-0.2-0.8l1.2-1.1l0.1-0.2v-0.3v-0.4l-0.2-0.4l-0.6-0.8l-0.6-0.1l-0.8-0.8l-0.1-0.2l-0.1-0.3l-0.1-0.4v-0.3l0.1-0.2l0.8-0.8l0.3-0.5
                l1-2.1l0.5-0.8l0.3-0.4l0.3-0.2l0.7-0.1l0.8,0.8h0.2l0.1,0.2v0.4l-0.1,0.3l-0.3,0.6l-0.1,0.3v0.3l0.2,0.6l0.1,0.4v0.7v0.1l0.5,0.6
                l0.4,0.7l0.2,1.1l-0.2,0.4l-0.4,0.5l-0.2,0.4l-0.1,0.4l0.1,0.2L171.2,126L171.2,126z M191,119.8l-0.7,0.1l-0.3-0.1v-0.2l0.3-0.3
                l1-0.5l0.6-0.8l0.2-0.1l0.7-0.1h0.4l0.6,0.2l-1.3,1L191,119.8L191,119.8z M200.9,121.1v0.5v0.4l-0.1,0.3v0.3l0.1,0.4l0.4,0.9
                l0.1,0.5v0.5v0.3l-0.2,0.7l-0.1,0.9v0.4l0.1,0.7v0.3l-0.3,0.6l-0.6,0.5l0.1,0.3l1.2,0.6l0.1,0.1v1.4l0.2,1.5h-0.1l-0.3-0.5l-0.5-0.6
                l-0.6,1l0.1,1.7l0.4,0.8l0.1,0.4v0.3l-0.4,0.1l-0.1-0.1l-0.4-0.3l-0.2-0.5h-0.1l-0.1,0.3v0.3l0.2,0.5l0.1,0.6l-0.1,0.2l-0.4,0.1H199
                l-0.8,0.2h-0.4l-0.4-0.1h-0.5l-0.4,0.1l-0.2-0.1l-0.2-0.3l-0.3,0.1l-0.6,0.7l-0.9-0.2l-0.8-0.1l-0.2-0.2l-0.2-0.4l-0.4-1.3l0.1-0.3
                l0.9-0.2v-0.1l-0.6-0.5l-0.7-0.4l-0.3-0.3l0.1-0.5v-0.1l1.2-0.6l0.9-1.2l0.5-0.5V131l0.5-0.2l1.1-0.1v-0.3l-2,0.2l-2.8,0.7l-0.9,0.3
                l-0.7-0.2l-3,1.1h0.2l-0.3-0.3l-0.3-0.6l0.2-0.4l0.9-0.8l0.4-0.7l-0.1-0.4l0.4-0.7l0.6-0.1l1,0.6l0.5,0.6l0.4,0.3l0.4-0.1l0.4-0.4
                h-0.1l-0.7,0.2l-0.1-0.1l-0.4-0.6l-0.2-0.4l-0.1-0.4v-0.3l0.2-0.6l-0.8-0.2l-0.3-0.4l-0.1-0.5V126l0.3-0.6l0.7-0.8l-0.1-0.2h-1
                l-0.2-0.1l-0.4-0.5l0.1-0.5l0.4-0.8l0.3-0.5l0.2-0.1h0.3l0.7,0.3l0.2,0.2l0.6,0.8l0.1,0.4v0.4l0.1,0.3l0.4,0.5l1.9,2.3l0.4,0.5
                l0.2,0.2l0.4,0.2h0.3l0.2-0.2v-0.2l-0.8-0.8l-0.2-0.4l-0.2-0.6l0.1-0.2l0.2-0.1l0.6,0.1l0.7-0.2l-0.8-0.2l-0.5-0.3h-0.5l-0.6-0.5
                v-0.2l0.2-0.2l0.9-0.2l0.2-0.1v-0.1l-0.3-0.4l-0.3-0.2l-1.3-0.4l-0.5-0.5l-0.2-0.2v-0.2l0.2-0.6l0.7-0.7l0.5-0.4l0.9-0.3h0.7
                l0.4,0.1l1,1.2l0.4,0.8l0.8-0.1l-0.2-0.9l-0.1-0.9l0.2-0.3l0.6-0.6l0.5,0.3l0.7,0.8l0.1,0.3l0.8,0.5l0.4,0.2l0.2,0.3L200.9,121.1
                L200.9,121.1z M183.2,119.7l0.1,0.1l0.4-0.2h0.3l0.3,0.5l0.1,0.3l1.1,0.7l0.4,0.3l0.1,0.2l-0.1,0.3l-0.3,0.4l-0.3,0.3l-0.5,0.2h-2.2
                l-0.2-0.1l-0.1-0.4l-0.3-1.4l-0.2-0.7l-0.1-0.5v-0.3l0.2-0.3l0.8-0.4h0.4l0.2,0.1l0.1,0.2v0.3L183.2,119.7L183.2,119.7z
                M188.2,126.4l0.6,0.7v0.2l-0.1,0.1l-0.8,0.4l-0.3,0.3l-0.2,0.7l-0.2,0.3l-1,0.2h-1l0.2-0.7l0.6-1.1l-0.4-0.5l-1.6,0.8l-0.6-0.5
                l0.5-1.2l-0.5-0.1h-0.7l-0.5-0.7l0.2-0.9l0.9-0.5l1.2-0.4l1.3-0.6l1,0.1l0.4,0.3l0.1,1l0.1,1.2l0.2,0.1L188.2,126.4L188.2,126.4z
                M253.2,127.5v0.7H253l-0.6,0.4l-0.5,0.1l-0.3-0.2l-0.2-0.5l0.4-0.9l0.5-0.6l0.6-0.9l0.5-0.6l0.2,0.1l0.2,0.3l-0.3,0.9l-0.3,0.5
                L253.2,127.5L253.2,127.5z M143,131.4l-0.8,0.7l-0.6-0.1l-0.8-0.5l-0.6-0.2l-0.2-0.2v-0.2l0.2-0.5l0.3-0.4l0.6-0.8l1.1-1.6l0.7-0.6
                l0.7-0.4l0.7-0.9l0.4-0.4h0.3l0.3,0.2v0.3l-0.4,1.3l-0.3,0.5l-0.4,1.3l-0.9,2.2L143,131.4L143,131.4z M209.8,129.5h-0.3l-0.4-0.2
                l-0.1-0.2l-0.1-0.3l-0.1-0.4l-0.1-0.3l-0.1-0.3v-0.2l0.1-0.2l0.1-0.1h0.3l0.6-0.5h0.1l0.1,0.2l0.1,0.6l0.1,0.4l0.2,0.7l0.1,0.4v0.1
                l-0.1,0.1L209.8,129.5z M135.3,129.5l-0.2,0.1l-0.2-0.1l0.1-0.3l0.5-0.8v-0.2V128l-0.1-0.2l0.1-0.3l0.2-0.1l0.1,0.1v0.2l-0.1,0.7
                l-0.1,0.3l-0.1,0.3l-0.1,0.3L135.3,129.5L135.3,129.5z M205.5,132.2l-0.2,0.4h-0.2l-0.3-0.3l-0.3,0.1l-0.4,0.7l-0.2,0.4l-0.1,0.1
                l-0.4,0.1l-0.2-0.1l-0.2-0.4l-0.1-0.6l0.1-0.5l0.4-0.3l0.9-0.5l0.3-0.3v-0.3l0.1-0.2l0.1-0.1l0.6,0.5l0.3,0.3l0.2,0.4v0.1
                L205.5,132.2L205.5,132.2z M212.6,137.5l0.2,0.8v0.3v0.7l-0.1,0.9l-0.1,0.2l-0.1,0.1v0.2v0.4l-0.1,0.2l-0.1,0.1l-1,0.2h-0.6
                l-0.9,0.1l-0.5-0.1l-0.3-0.2l-0.4-0.4l-0.9-1h-0.5l-1.2-0.4l-0.6-1.1l-0.2-0.2l-0.2,0.3l-0.1-0.1l-0.1-0.2l-0.1-0.4l-0.1-0.2
                l-0.5,0.1l-0.1-0.1v-0.3v-0.3l0.1-0.4l0.5-1.2l0.3-0.1l0.3-0.2l0.2-0.7v-0.6l0.5-1l0.3-0.3l0.5-0.7l1.7-1l0.5-0.1l0.6,0.1l0.6,0.3
                l0.5,0.6l1,1.4l0.4,0.8l0.2,0.8l0.2,0.5l0.3,1l-0.1,0.4l-0.1,0.5L212.6,137.5L212.6,137.5z M182.9,137.4l-0.5,0.1l-0.9-0.3l-0.5-0.6
                l-0.2-0.3v-0.1l0.1-0.1l0.1-0.4l0.3-1.2l0.1-0.3l0.5-0.7l0.4-0.2l0.8,0.1l0.4,0.4l0.2,0.2l0.1,0.3l0.1,0.6v0.4l0.2,0.5l0.1,0.3v0.3
                l-0.1,0.3L184,137l-0.3,0.4H182.9L182.9,137.4z M207.6,143.1l-0.1,0.1l-0.2-0.1l-0.9-0.5l-0.2-0.2v-0.1l0.2-0.2l0.1-0.2l0.2-0.2h0.4
                l0.4,0.5l0.2,0.5v0.2L207.6,143.1L207.6,143.1z M201.9,142.9l-0.8,0.6l-0.2-0.2l-0.1-0.2l0.7-1l0.3-0.3l0.3,0.3l0.1,0.2v0.2
                L201.9,142.9z M139.1,147.2L139.1,147.2l0.7-0.9l0.4-0.1l0.3,0.1l0.1,0.1l0.1,0.2v0.4v1v0.1l0.1-0.1l0.2-0.2l0.8-1.5l0.3-0.4
                l0.2-0.1l1-0.2h0.7l0.7,0.1l0.5,0.2l0.9,0.6l0.7,0.7l0.6,0.8l2.1,2.8l0.9,0.8l0.3,0.5l0.2,0.3l0.1,0.4v0.4l-0.1,0.4l-0.1,0.3
                l-0.2,0.2l-1.3,0.9l-0.7,0.3l-0.7,0.4l-1.6,1.4l-1.1,0.7l-1.5,1.3l-2.7,2.1l-0.3,0.4l-0.1,0.3l-0.8,2.3l-0.3,0.5l-0.7,0.5l-0.9,0.1
                l-0.3,0.2v0.8l-0.3,1.3l-0.2,0.9l-0.2,2.3l-0.1,0.2l-0.2,0.4l-0.3,0.5l-0.9,0.5l-0.6,0.3l-0.9,0.2l-0.2-0.2l-0.2-0.4h-0.2l-0.1,0.1
                l-1.1,1.6l-1.1,0.6l-0.5,0.6l-0.3,0.3l-0.3,0.1l-0.4-0.1l-0.3-0.3l-0.3-0.4l-0.2-0.4l-0.6-1.8l-0.2-0.6l-0.2-0.3l-0.6-1.1l-0.2-0.2
                l-2.1-1.4l-1-0.8l-0.2-0.3l-0.2-0.2l-1.3,0.1h-0.2v-0.1l0.2-0.4l0.1-0.3v-0.3v-0.4v-0.1l0.5-0.4l-0.1-0.1l-0.1-0.2v-0.2l0.1-0.2
                l0.1-0.1l0.2-0.4l0.2-0.7l0.2-0.4l0.2-0.2l0.4-0.7l0.3-0.3l0.2-0.3v-0.2l-0.1-0.1v-0.3v-0.8v-0.4v-0.4l0.1-0.3l0.1-0.2l1-0.6v-0.2
                v-0.2v-0.2l-0.1-0.2l-0.2-0.1h-0.3l-0.2-0.3l-0.1-0.2l0.1-0.5l0.4-0.8l0.1-0.4l0.5-1.7l0.9-1.1l0.2-1.2l0.7-1.2v-0.2l-0.2-0.4
                l-0.6-0.3l-0.3-0.5l-0.2-0.5l-0.9-3l-0.2-0.2v-0.4l-0.2-0.2v-0.2l3.4-0.9l2.4-0.3l2.5-0.8h0.7l0.5,0.2l0.5,0.4l0.7,0.7l0.9,0.7
                l1.7,1l1.1,0.2l-0.4,0.8l-0.1,0.2L139.1,147.2L139.1,147.2z M213.6,146.7l1.1,0.5l0.5,0.3l0.3,0.2l0.5,0.7l0.3,0.2l1-0.4l0.7-0.2
                l1.5,0.2l1.3,0.6l0.5,0.4l0.3,0.4l-0.1,0.4l-0.2,0.7l-0.3,0.7l-0.6,1.1l-0.5,0.5l-0.1,0.3l-0.1,0.3l-0.2,0.5l-0.5,1.1l-0.1,0.2
                l-0.7,0.5l0.2,0.2l0.1,0.2l-0.1,0.5l-0.5,1.2l-0.5,1.1l-0.3,0.6l-0.6,0.9l-0.3,0.3l-0.4,0.1l-2.7-0.8h-0.7l-1.8,0.4l0.2,0.2l0.6,0.3
                l0.4,0.3l0.6,1l0.1,0.3v0.3v0.6v0.1l-0.9,1.6l-0.3,1.1l-0.2,0.9l-0.3,0.3l-1-0.4h-0.3l-1.1,0.3l-0.5-0.1l0.1-1.4l-0.1-1.5l-0.2-1.5
                l-0.8-2.6l-0.1-0.5l-0.1-0.5v-0.5v-0.6l0.1-1.1v-0.6v-1.5l-0.1-2.3V152v-0.3v-0.2l0.2-0.3l0.3-0.2h0.2l1.1,0.7l0.5,0.2h0.3v-0.1
                l-0.3-0.1l-0.3-0.3l-0.5-0.9l-0.2-0.8v-0.3v-0.3v-0.3l0.1-0.3l0.2-0.4l0.2-0.2l0.7-0.5l0.7-0.3l1.5-0.2l0.4,0.1l0.7-0.5l0.4-0.1
                L213.6,146.7L213.6,146.7z M199.3,149.7l-0.8,0.2l-0.4-0.1l-0.2,0.3l-0.2,0.1l-0.4,0.1l-0.9-0.5l-0.2-0.2l-0.1-0.2v-0.2l0.2-0.1
                l0.7-0.2l0.3-0.2l0.1-0.2l0.2-0.2l0.2-0.1l0.7-0.1l1.5-0.7l0.7-0.1l0.3,0.1l0.1,0.2v0.2v0.2l-0.2,0.5l-0.3,0.4l-0.8,0.8H199.3z
                M194.4,148.9l2.4,2.2l0.3-0.2l0.7-0.1l0.7-0.3l1-0.3l0.6-0.5l0.3-0.1l0.5-0.1h0.2l0.7,0.3l0.3,0.2l0.2,0.2l0.2,0.3l0.3,0.8v0.3v0.1
                l-0.3,0.5l-0.2,0.2l-0.4,0.2l-0.3,0.1l-0.3,0.4L201,153l-0.1,0.3v0.2l0.1,0.1h0.2l0.2-0.1l0.3,0.1l0.2,0.2l0.2,0.4l-0.1,0.3
                l-0.6,0.5l-0.9,0.5l-1.1,1.6l-0.6,0.7l-0.1,0.2l-0.1,0.2v0.4v0.2l0.2,0.1l0.5-0.6l0.3-0.3l0.3-0.1h0.6l0.2,0.1l0.5,0.3l0.4,0.5
                l0.1,0.2v0.2l-0.2,0.2v0.1l0.4,0.3l0.4,0.7v0.4l-0.2,0.4l-0.1,0.3l0.1,0.1l0.2-0.1l0.5-0.5l0.6-0.2h0.2l0.1,0.1l0.1,0.6l0.1,0.7v0.6
                l-0.1,0.5l0.4,0.5l-0.3,0.5l-0.3,0.2h-0.2v0.1l0.4,0.4l0.1,0.3l0.1,0.3v0.3v0.2l-0.4,0.9v0.1l0.1,0.1l0.3,0.5v1.2l-0.9,0.4l-0.2,0.3
                l-0.3,0.4l-0.3,0.3l-0.7,0.4h-0.3l-1.7-0.3l-0.2-0.2l-0.1-0.3l-0.1-0.4v-0.3V169v-0.1l0,0l-0.2,0.3l-0.2,0.5l0.1,0.5l0.5,1.4
                l0.1,0.6v0.3v0.2l-0.6,0.8l-0.3,0.3l-0.4,0.1l-0.3-0.1l-0.3-0.3l-0.2-0.2h-0.5l-0.2-0.2l-0.1-0.3l-0.4-1.2l-0.5-0.8l-0.4-0.9
                l-1.1-1.4l-0.6-0.8l-0.8-1.4l-0.3-0.3l-0.3-0.1l-0.5-0.1l-0.1-0.2l-0.2-0.5l-0.3-0.4H191l-0.2,0.1l-0.5,0.3l-0.6-0.3l-0.1-0.2
                l-0.1-0.4l-0.1-0.2l-0.2-0.2l-0.2-0.5l-1.2-1l-0.7-1.2l-0.1-0.4v-0.2l0.1-0.6l0.2-0.6l0.2-0.6l0.1-0.3l0.5-0.6l0.4-0.1l0.5,0.1
                l0.3,0.1l0.2,0.4l0.1,0.4l0.1,0.3l0.4,0.3l0.2,0.3l0.3,0.6l0.2,0.7l0.2,0.2l0.5,0.1l0.5-0.1l1.2-0.4v-0.1l0.1-0.3l0.1-1.6h0.1
                l0.4,0.8l0.1,0.1l0.2-0.2l0.1-0.4v-0.2l-0.2-0.9l-0.1-0.4l-0.1-0.2l-0.1-0.1l-0.3,0.1l-0.2-0.2l-0.1-0.2v-0.3l0.1-0.4l0.1-0.2
                l0.3-0.1l0.3,0.1l0.4,0.4l0.3,0.1l0.4-0.1l-0.5-0.2l-0.7-1l-0.3-0.2l-0.4,0.4l-0.2,0.2l-0.5,0.2h-0.4l-1.6-1.5l-0.1-0.1l-0.1-0.4
                v-0.2l0.2-0.3l0.4-0.4l0.6-0.2h0.4l0.3,0.3l0.5,0.8l0.4,0.4V154l-0.1-0.4l-0.2-0.6l-0.1-0.2l-0.4-0.2l-0.3-0.4l-0.2-0.3l-0.1-0.4
                v-0.4l0.1-0.3l0.1-0.1l1.2-0.4l0.8,0.4l0.5,0.1l0.2-0.5l-0.1-0.1l-0.3,0.1h-0.3l-0.2-0.3v-0.2l0.2-0.4L194.4,148.9L194.4,148.9z
                M232.1,176.3l0.1,0.2l0.6-0.2l0.5-0.3l0.8-0.7l0.5-0.2h1.5l0.3,0.1l-0.1,0.4l-0.1,0.1l0.1,0.2l0.2,0.2l0.3,0.3l0.1-0.2l0.1-0.6
                l0.2-2.3l0.1-0.7v-0.7v-0.6l-0.1-0.4l-0.4-0.2h-0.5l-0.3-0.1l-0.3-0.1l-0.2-0.2l-0.2-0.3l-0.3-0.8l-0.2-0.4l-0.6-0.8l-0.3-0.3
                l0.1-0.3l0.6-0.4l0.3-0.4l0.4-1l0.2-0.2l0.9,0.1l1.2,0.8l0.7,0.7l0.2,0.1V167l-0.2-0.3l-0.8-0.9l-0.4-0.6l-0.2-0.5l0.1-0.2l0.5-0.2
                l0.1-0.2l-0.6-0.3h-0.3l-0.3,0.2h-0.3l-0.5-0.4l-0.1-0.2l-0.3-0.6l-0.2-0.5l-0.2-0.3l-0.1-0.3v-0.8v-0.5l0.1-0.4l0.1-0.4l0.3-0.7
                l0.2-0.2l0.4-0.1l0.8,0.3l2.1,1.2v-0.4l-2.3-1.6l-0.8-0.4l-0.2-0.6l1.2-2.2l1.1-0.5l0.6-0.7h0.9l0.9,0.4V154l-0.4-0.8V153l0.5-0.5
                l0.9-0.6l1.1-0.4l0.2-0.2l0.3-0.2l0.5-0.1l1.3-0.1l0.7,0.1l1,0.3l0.6,0.6l0.2,0.4l0.3,1.2l0.2,1.6l0.4,0.7l0.6,0.4l0.4,0.4l0.2,0.4
                l0.1,0.5l-0.1,0.7l0.1,0.7l0.3,0.7l0.2,0.4l0.4,0.4v0.2l-0.1,0.3l-0.3,0.4l-0.7,1.1l-0.9,1.2l-0.6,1.1v0.3l1.3-1.7l0.4,0.1v0.2
                l-0.3,0.8l-0.3,0.7l-0.3,0.5l0.1,0.2l0.6,0.8l-0.1,0.1l-0.3-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2v0.3l0.1,0.4v0.3l-0.1,0.2l0.1,0.1h0.2
                l0.2-0.2l0.3-0.5l0.9-1.5l0.6-0.6h0.2l0.5,0.4h0.1l-0.6-1.1l-0.1-0.3l0.1-0.4l0.1-0.1l0.3-0.3l0.3-0.2l0.1,0.1l0.2,0.6l0.1,0.4
                l0.2,0.2l0.4-0.2l0.3-0.5l0.4,0.3l0.5,0.8l-0.1,0.8v0.8v0.6l0.7,1l0.5,0.4h0.1v-0.2l-0.1-0.3l-0.2-0.3l-0.2-0.5l-0.2-0.6l0.1-1.5
                l0.3-0.8l0.3,0.2l0.4,0.5h0.3h0.5l1.1,0.9h0.6l-0.1-0.4l-0.4-0.2l-0.6-0.5l-1-0.6l-0.5-0.7l-0.1-0.3v-0.4l0.1-0.3l0.1-0.3l0.2-0.3
                l1-0.8l0.7-0.4l0.5-0.1h0.9l1,0.1l0.5,0.2l0.6,0.6l0.8,0.6l0.3,0.1h0.3l0.4-0.1h0.4l1.2,0.8l0.3,0.4l0.2,0.5l0.1,0.5l0.1,0.5v0.4
                l-1,1.7l-0.4,0.3l-0.3,0.6l-0.4,1.2l-0.3,0.6v0.1h0.1l0.2-0.3l0.4-0.8l0.3-0.7l0.5-0.6l0.8-0.7l0.7-0.3h0.6l0.5,0.1l0.4,0.2l0.2,0.1
                l0.1,0.1l0.2,0.5v0.4l-0.1,0.4l-0.2,0.5l-0.9,0.5l-1,0.5l-0.3,0.1l-0.9,0.1v0.2l0.6,0.2l0.7-0.1v0.3l-0.3,0.7l-0.1,0.6l0.1,0.5v0.4
                l-0.3,0.8l-0.3,0.7l0.1,0.1l0.7-1l0.2-1.2l0.3-1l0.3-0.6l0.2-0.2l0.7-0.1l0.4-0.6l0.4-0.2h0.1l0.3,0.2v0.2l-0.4,1.1l-0.9,1.7
                l0.4-0.2l0.3-0.4l0.3-0.4l0.4-0.6l0.3,0.6l0.4,0.4l0.2,0.9l0.4,0.4l0.2,0.3v-0.6l-0.3-1.2l0.1-0.5l0.3-0.2l0.8-1l0.6,0.3l0.3,0.3
                l0.2-0.1h0.4l0.7,0.2l0.7,0.3l0.7,0.4l0.5,0.5l0.4,0.5l0.2,0.4l0.1,0.2l0.1,0.5l-0.1,0.3l-0.5,0.8l-0.3,0.4l-0.3,0.2l-0.7-0.2
                l-0.2,0.1l-0.2,0.2l-1,1.3l-0.4,0.5l-0.4,0.3l-0.1,0.2h0.9l0.2-0.3l0.2-0.6l0.4-0.6l0.7-0.3l1,0.6h0.5l0.4-0.6l0.4-0.4l0.2-0.1h0.1
                l0.3,0.4l0.1,0.4v0.9v0.3l-0.3,0.7l-0.7,1l-0.5,0.4l-0.5,0.2l-0.6,0.3l-0.2,0.3l-0.2,0.4l-0.2,0.3l-0.3,0.2l0.3,0.3h0.1l0.1-0.2
                l0.3-0.7l0.2-0.3l0.1-0.1h0.1l0.1,0.1l0.1,0.3v0.6l-0.4,2.5h0.1l0.3-0.7l0.8-2.6l0.2-0.5l0.4-0.5l0.8-0.8l0.6-0.4l0.7-0.4l0.4-0.1
                l0.4,0.1l0.3,0.4l0.4,0.1l0.5-0.1l0.3,0.1l0.3,0.2l0.3,0.3l0.5,0.3l1.1,0.6l0.1,0.1l0.1,0.2l0.1,0.4v0.4l-0.1,0.3l-0.2,0.2l-0.2,0.1
                l-0.2,0.2l-0.4,0.5l-0.1,0.1l-0.7,0.2l-0.6,0.1l-0.4,0.2l-0.7,0.5l-1,1v0.2l0.4,0.1l0.3-0.1l0.5-0.7l0.4-0.3l0.7-0.2l0.9-0.2h0.4
                h0.1l0.1,0.2v0.3l-0.2,0.4l-0.2,0.2l-0.5,0.8l0.3,0.2l0.4,0.1l0.2-0.2l0.2-0.5l0.2-0.3l0.3-0.1l0.2-0.1l0.2-0.2l0.1-0.1l-0.3-0.3
                v-0.2l0.1-0.4l0.2-0.4l0.2-0.3h0.2l0.6,0.3l0.4,0.5l1,1.5l0.1,0.3l0.3,1.1l0.1,0.5l-0.1,0.3L286,187l-0.1,0.1h-0.2l-1.3-0.5
                l-0.6,0.1l-0.3,0.1l-0.2,0.2l-0.2,0.2l-0.1,0.3l-0.2,0.2H282l-0.5,0.2l-0.8,0.4l-0.3,0.2l-0.1,0.3h0.5l0.8-0.4l0.8-0.1l1.3,0.8
                l0.4,0.1l0.2-0.1h0.3l1,0.1l0.3,0.1l0.5,0.3l0.8,0.7l0.2,0.2l0.1,0.2v0.2v0.5l-0.1,0.2l-0.3,0.1l-1.1-0.1l-0.3-0.1l-0.4,0.1H285
                l-0.4-0.2l-0.5-0.4l-0.7,0.4l-0.6-0.2l-0.6,0.2l-1.2,0.9l0.1,0.1l1.6-0.7h0.3l0.5,0.3l0.8,0.5l0.2,0.2v0.8l-0.1,0.5l-0.2,0.6
                l-0.4-0.1l-0.9-0.4l-0.4-0.1l-0.3,0.1l-0.3,0.2h-0.2l-1.4-0.5H280v0.1l0.1,0.1l1.3,0.8l0.9,0.1l0.6,0.1l0.3,0.2l0.2,0.2v0.5l0.3,0.5
                l0.3,0.2h0.2l0.3-0.2h0.3l0.2,0.1l0.3,0.3l0.4,0.1l0.3,0.2h0.3l0.7-0.1l0.3,0.1l0.1,0.1l-0.1,0.2l-0.7,0.4l-0.1,0.4l0.4,0.5l0.2,0.4
                v0.3l-0.2,0.6v0.2h0.1l0.5-0.5l0.1,0.1v0.7h0.1l0.2-0.6l-0.1-0.8l0.3-0.3l0.9-0.2l-0.2,1.2v0.6l-0.4,1l-0.3,0.3v0.1l0.2,0.1h0.1
                l0.1-0.2l0.3-0.8l0.7-0.8h0.1v0.3l-0.1,0.6l0.3,0.3l0.3-0.3l0.2-0.2h0.4l0.2,0.1v0.2l-0.2,1v0.2l0.4,0.7l0,0l-0.1-0.3l-0.1-0.8
                l0.1-0.3l0.3-0.4l0.7-0.6l0.2-0.1l0.2,0.1l0.2,0.3l-0.1,0.2l-0.3,0.2l-0.2,0.4l-0.1,0.5l0.1,0.3h0.5l0.5-0.4l0.3,0.2l0.4,0.5
                l0.7,0.9l0.4-0.2l0.5,0.7l-0.6,0.5l0.2,1.1h-0.8l-0.5,0.1l-0.3-0.1H293l0.3,0.3l0.6,0.1l0.1,0.3h0.5l0.4-0.1h0.6V206l0.4-0.2
                l0.2-0.2l0.2,0.1l0.6,0.2l0.8,0.7l-0.3,0.4l-0.1,0.4l-0.1,0.4l-0.1,0.3l-0.1,0.2l-1.1,1.2h0.2l0.5-0.3l0.9-0.4l0.5-0.2l0.4,0.1h0.2
                l0.2-0.2l0.3,0.2l0.6,0.2l0.7-1l0.4,0.2l0.4,0.6l0.9,1.1l0.5,0.6l0.1,0.3v0.3l-0.4,0.3l-0.2,0.1l-0.6-0.6l-0.5-0.3h-0.3l-0.3,0.2
                l0.1,0.1l1.2,0.9l0.2,0.6v0.3l-0.8,0.4h-0.3l-0.6-0.2l-0.3-0.4l-0.3-0.1h-0.4l-0.1,0.1l0.4,0.6v0.2l-0.2,0.1l-0.1,0.3l0.8,0.5
                l0.6,0.6l0.1,0.2l-0.4,0.2H300l-0.6-0.1l-0.3-0.1L299,215l0.4,0.3l0.1,0.2l0.1,0.3l0.1,0.3v0.2l-0.3,0.2l-0.4,0.6l-0.1,0.6l-0.3,0.1
                l-0.1-0.1l-0.4,0.2l-0.6-0.2l-0.2-0.3l-0.6-1.1v0.1l0.2,0.5v0.3l-0.6,0.2v0.1l0.4,0.2l0.5,0.1l-0.1,0.5v2.1l-0.1,0.7l-0.2,0.6
                l-0.3,0.6l-0.4-0.4l-0.1-0.4l-0.1-0.2l-0.2-0.2l-0.2-0.1h-0.2l-0.3,0.4l-0.3-0.3l-0.3-0.4l0.1-1l0.1-0.5l0,0l-0.2,0.2l-0.2,0.5
                l-0.2,0.9l-0.3-0.3l-0.3-0.4l-0.2-0.4l-0.4-0.5l-0.4-0.6l-0.2-0.7l-0.1-0.1l-0.2-0.6l-0.1-0.2l-0.1-0.1l-0.2-0.4l0.1-0.4l0.3-0.5
                l0.3-0.3l0.4-0.3l0.5-0.2l0.2-0.4l0.3-0.8l0.3-0.6l0.3-0.3l-0.2-0.1l-0.4,0.3l-0.3,0.4l-0.4,0.6l-0.3,0.4l-0.8,0.5l-0.3,0.1h-0.3
                l-0.8-0.1l-0.2-0.2l0.1-0.5l0.6-0.8l-0.1-0.1l-0.2,0.3l-0.3,0.2l-0.2,0.1h-0.3l-0.4-0.5l-0.2-0.2l-0.4-0.2l-0.2-0.2l-0.7-1.3
                l-0.1-0.3l-0.1-0.3l-0.2-0.3l-0.3-0.2h-0.1l0.1,0.3v0.2l-0.3,0.2l-0.3-0.1l-0.3-0.3v0.3l0.4,0.6v0.8l-0.1,0.1h-0.2l-0.2-0.1
                l-0.5-0.6l-0.5-0.4l-0.4-0.2v0.2l0.2,0.7l0.3,0.7l0.4,0.6l0.7,0.7l0.3,0.4l-0.3,0.5l-0.2,0.2l-0.1,0.1h-0.4l-0.8-0.3l-0.4-0.3
                l-0.5-0.8l-0.9-0.8h-0.2l-0.6,0.3l0.1,0.1h0.4l0.3,0.1l0.7,0.7l0.1,0.3l-0.2,0.3v0.4l0.2,0.5l0.2,0.3l0.4,0.2h0.2l0.1,0.1l-0.2,1
                v0.3l0.1,0.1h0.1l0.5-0.4l0.2-0.1h0.2l0.2,0.1l0.3,0.3l0.1,0.3v0.3l0.1,0.2l0.5,0.3v0.1l-0.5,0.4v0.1h0.1l0.3,0.3l0.3,0.4l0.2,0.5
                v0.2v0.2v0.1h0.2l0.1-0.1h0.1l0.1,0.1l0.1,0.4l0.2,1.1l0.1,0.3h0.1v-1.1l0.1-0.2l0.3,0.2l0.5,0.4l0.3,0.4v0.2l-0.3,0.3l0.1,0.1
                l0.2,0.2l0.2-0.1l0.1-0.4l0.2-0.4l0.3-0.3l0.5,0.2l0.4,0.5l0.1,0.2l0.3,0.2l0.2-0.1l0.4,0.6L292,224l-0.5,0.4v0.1h0.1h0.9l0.2,0.2
                l0.1,0.3l-0.4,0.9l-0.4-0.1h-0.5h-0.2v0.1l0.7,0.4l0.2,0.3l0.2,0.3l0.1,0.3v0.1l-0.1,0.2v0.1l0.4,0.1l0.3-0.1h0.3h0.3v0.1v0.3
                l-0.3,0.3l0.1,0.1l0.4-0.1l0.2,0.1l0.2,0.7l0.2,0.5l-0.2,0.1h-0.2v0.7l0.1,0.7v0.7v0.6l-0.2,0.1h-0.2l-0.1-0.1l-0.5-1.8l-0.1-0.3
                l-0.2-0.3l-0.6-0.8v0.1l0.1,0.4l0.1,0.5l0.2,1l0.1,0.7v0.3l-0.1,0.1v0.1l0.1,0.2l0.4,0.7l0.2,0.4l0.1,0.4l0.1,0.3l0.1,0.1v0.1
                l-0.2,0.1h-0.3l-0.2-0.1l-0.6-0.4l-0.1,0.1l0.3,1.4v0.3l-0.2,0.1l-0.2-0.1l-0.2-0.4l-0.4-0.4l-0.5-0.5l-0.5-0.4h-0.1l-0.1,0.1h-0.1
                l-0.1-0.1L290,234l-0.2-0.2l-0.7-0.7H289l0.1,0.2l0.1,0.4l-0.1,0.1h-0.2l-0.3-0.2l-0.2-0.4l-0.3-0.8l-0.2-0.3v0.2l0.1,0.7v0.2
                l-0.2,0.1l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.2-0.2l-0.3-0.2l-0.1-0.2l-0.1-0.3v-0.1l-0.5,0.1l-0.2-0.2l-0.7-0.9l-0.6-1l-0.4-0.5
                l-0.1-0.1l0.2,0.6l0.2,0.9l0.1,0.4h-0.1l-0.2-0.2l-1.1-1.2l-0.7-0.6l-0.4-0.1l-0.6-0.1l-0.1,0.3l0.3,0.7l0.3,0.5l0.3,0.3l0.5,0.7
                l0.5,0.9l0.2,0.3l0.6,0.4l0.3,0.1h0.3v0.1l-0.2,0.2v0.1l0.8,0.4l0.3,0.2l0.3,0.3l0.2,0.1l0.7,0.9l0.2,0.1l0.6,0.3l0.2,0.2l0.3,0.6
                l0.2,0.3l0.3,0.7l0.2,0.3l0.5,0.3l0.2,0.1l0.1,0.1l-0.1,0.3l-0.1,0.1l-0.3,0.2v0.3l0.2,0.5v0.3l-0.2,0.1l-0.4,0.1h-0.2l-0.3-0.1
                l-0.4-0.2l-0.7-0.5l-1.1-0.4l-0.4-0.2l-0.1-0.2l-0.2-0.1l-2.6-0.5l-0.4-0.1l-0.3-0.2l-0.3-0.2l-1-0.5l-0.1-0.1l-0.7-0.9l-0.5-1.1
                l-0.2-0.1l-0.5-0.1l-0.5,0.1l-0.3,0.1h-0.5l-0.3-0.2l-0.6-0.5l-0.6-0.3l-0.6-0.4l-0.3-0.1v-0.1l0.4-0.6h-0.1l-0.7,0.5l-0.3-0.1
                l-0.4-0.4l-0.3-0.4l-0.7-1l-0.4-0.4l0.1-0.1h0.4h0.3l0.2-0.1l0.4-0.4l0.2-0.3v-0.2h-0.4l-0.1-0.1l-0.1-0.2l-0.2-0.2l-0.3-0.3
                l-0.3-0.1l-1.1,0.1l-0.2-0.1v-0.2l0.2-0.5l0.1-0.2v-0.1l0,0h-0.1l-0.6,0.5h-0.1l-0.2-0.5l-0.1-0.6l-0.1-0.2l-0.1-0.1l-0.5-0.6
                l-0.7-1.1l-0.3-0.3l-0.3-0.3l-0.2-0.1v-0.2l0.5-0.9v-0.1l-0.4,0.1l-0.6-0.2l-0.3,0.2h-0.2l-0.2-0.1h-0.1l-0.1,0.7l-0.1,0.2l-0.1,0.1
                h-0.1l-0.1-0.1v-0.2l-0.1-0.9l-0.2-0.1h-0.6l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.1-0.5l-0.1-0.2h-0.1h-0.1l-0.1,0.1l-0.2,0.1h-0.3v0.2
                l0.3,0.3l0.3,0.5l0.3,0.6l-0.2,0.4l-0.6,0.2l-0.5,0.1l-0.4-0.1l-0.3-0.1l-0.4-0.3l-0.6,0.1l-0.1,0.9h-0.1h-0.6l-0.2,0.1l-0.8,0.5
                l-0.2,0.1l-0.2-0.1l-0.2,0.1l-0.3,0.3h-0.4l-0.5-0.2l-0.4-0.1h-0.3l-0.3-0.1l-0.3-0.3l-0.3-0.1h-0.4h-0.1l-0.5-0.6l-0.2-0.2
                l-0.4-0.8l-0.1-0.3v-0.3v-0.2l0.1-0.4l0.1-0.9l0.1-0.3l0.2-0.3l0.3-0.3l1.2-0.6l0.2-0.2v-0.2l-0.3-0.7v-0.2l0.1-0.1l0.2-0.4l0.1-0.1
                l0.2-0.1l0.4,0.2l0.4,0.1h0.5l0.8,0.3l1.2,0.6l0.7,0.4l0.5,0.6l0.4,0.6l0.1,0.3l-0.2,0.4l-0.1,0.1v0.1l0.1,0.2l0.3,0.2l0.1-0.1v-0.3
                l0.1-0.2l0.1-0.2v-0.3l-0.1-0.3l-0.1-0.3l-0.2-0.2l-0.8-0.8l-0.1-0.3l0.3-0.1l1.1,0.3l0.4-0.1l0.2-0.3l0.2-0.2l0.2-0.1l0.4-0.1
                l0.5,0.1h0.3l0.2-0.1l0.3-0.2l0.4-0.6l0.3-0.1l0.4-0.1h0.3l0.6,0.2h0.4v-0.4l-0.2-0.7l-0.3-0.8l-0.2-0.3l-0.6-0.5l-0.7-0.9l-0.4-0.6
                l-0.1-0.3v-0.2l0.1-0.3l1.2-1l1-1l0.4-0.5l0.2-0.4l0.2-0.3l0.2-0.2l0.5-0.2l0.1-0.3v-0.4l0.1-0.4l0.4-1.1l0.3-0.3l0.5-0.2l0.3-0.3
                l0.4-0.9v-0.2l-0.2-0.2l-0.1-0.3l-0.6-2.3l-0.4-1.1l-0.5-1l-0.4-1.2l-0.7-1.2v-0.3l0.1-0.4l-0.1-0.1l-0.8,0.5h-0.2l-0.3-0.2
                l-0.2-0.3l-0.2-0.5v-0.3l0.1-0.3l0.1-0.6v-0.3v-0.3l-0.1-0.2l-0.1-0.1l-0.2-0.1h-0.4l-0.1,0.1l0.4,0.9l-0.1,0.2l-0.5,0.1h-0.2
                l-0.2-0.1L266,195l-0.6-1l-0.1-0.4v-0.3l-0.1-0.1l-0.1,0.1h-0.2l-0.2-0.1v-0.1l0.4-0.5v-0.2l-0.2-0.2h-0.3l-0.1-0.2l0.1-0.2l0.4-0.3
                l0.1-0.2l-0.2-0.1h-0.1l-0.3,0.3l-0.4,0.7l-0.3,0.2l-0.4-0.3l-0.3-0.1l-0.2,0.1l-0.3,0.5L262,193l-1.1,0.9l-0.5,0.3h-0.5l-0.1-0.2
                v-0.3v-0.2l0.1-0.2v-0.2v-1l0.1-0.3l0.2-0.2l0.3-0.2l0.8,0.2h0.4l0.3-0.2l0.3-0.3l0.3-0.4l0.1-0.4l-0.3-0.7l-0.1-0.2l-0.7-0.5
                l-0.4-0.2l-0.4-0.1l-0.3-0.2l-0.2-0.2l-0.2-0.4v-0.3v-0.3l0.1-0.2l0.7-0.3v-0.1l-0.5-0.2h-0.3l-0.2,0.2l-0.3,0.5l-0.2,0.1l-0.2,0.7
                h-0.3l-0.2-0.1l-0.1-0.1l0.1-0.1l0.2-0.1l0.4-0.5v-0.2l-0.3-0.4l-0.2-0.1l-0.6-0.1l-0.7,0.1l-0.3-0.1l-0.1-0.4l-0.1-0.5v-0.6
                l-0.1-1.1l-0.1-0.6l-0.2-0.1l-0.9,0.2h-0.2l-0.1-0.1l-0.6-0.7l-0.2-0.3l-0.1-0.1l-0.4-0.8l-0.2-0.1l-0.2-0.4l-0.2-0.6l-0.2-0.2
                L253,180l-0.3,0.3l-0.3,0.4l-0.1,0.4v0.3l0.2,0.2l0.9,0.4l0.2,0.3l0.2,0.4l0.1,0.5l0.1,0.6v0.5l-0.1,0.3l-0.2,0.3l-0.6,0.4l-0.6,0.2
                l-0.6,0.1l-0.3-0.1l-1.5-0.8h-0.3l-0.4-0.1l-0.8-0.3h-0.4l-0.8-0.3l-1.2-0.1l-0.3,0.1l0.3,0.4l0.3,0.2h0.3l0.4,0.3l0.5,0.7l0.3,0.4
                l0.2,0.5v0.2L248,187l-1.8-1.7l-1.1,0.6l-0.5,0.2h-0.4l-0.5-0.2l-1.2-0.8l-0.5-0.3h-0.2l-1.1,0.4h-0.9l-1.9-0.3l-0.7-0.2l-0.2-0.2
                l-0.2-0.1h-0.4l-1.1-0.3l-1,0.6l-1.1-0.8l-0.3-0.3l-0.1-0.2l-0.3-1v-0.5l0.1-0.5l0.1-0.3l0.1-0.2l-0.6,0.5l-0.2,0.1h-0.3l-0.9-0.2
                l-0.1,0.1v0.2l0.2,0.3v0.2l-0.5,0.1l-0.7-0.1l-0.3,0.1l-0.1,0.1l-0.3,0.4l-0.1,0.1h-0.2l-0.8-1l-0.6-0.6l-0.7-0.2l-0.3-0.2l-0.2-0.2
                l-1-2l-0.1-0.4l-0.3-1.6l-0.1-0.3l-0.1-0.2h0.3l1,0.2h0.9l0.5,0.1l0.6,0.4l0.8,0.3l0.5,0.1l0.9-0.1l1-0.3l0.1-0.2l-0.6-0.4l-0.6-0.5
                l-0.5-0.6l-0.3-0.3l-0.5-0.2l-1.5-0.1l-1.4-0.4l-0.9-0.6l-0.8-0.6l-0.3-0.3l-0.1-0.3l-0.1-0.8l-0.1-1.4l-0.1-0.9l-0.1-0.5v-0.4
                l0.3-0.9l0.8-1v-0.2h-0.1l-0.3-0.3l-0.1-0.4v-0.6v-0.5V164l0.1-0.5l0.3-0.9l0.5-1.1l0.5-1.1l0.1-0.4v-1l0.1-0.7l0.1-0.5l0.1-0.4
                l0.3-0.7l0.4-0.7l0.6-0.6l0.1-0.2v-0.3v-0.2l0.1-0.1l1.5-1.9l0.7-0.8l0.6-0.5l0.7-0.4l2-0.8l1-0.2h1.3l2.4,0.4l0.3,0.3l0.1,0.1
                l0.1,0.4l-0.1,0.3l-0.6,0.9l-0.8,0.8l-0.5,0.7l-0.9,1.5l-0.2,0.5l-1.1,2.9l-0.3,0.5l-0.2,0.4l-0.1,1v0.4l0.2,0.6l0.6,1.3l0.2,0.6
                v0.5l-0.1,1.3v0.7v0.6l0.1,0.9l0.2,1.1l0.5,1.2l0.8,1.2l0.6,0.8l0.6,0.6l0.7,0.8l0.2,0.4l-0.3,0.4l-0.8,0.7l-1,0.3l-0.5,0.3
                l-0.7,0.6l-0.8,0.5L232.1,176.3L232.1,176.3z M251.9,151.8l0.5,0.1l3-0.2l0.6,0.2l1.9,1.1l0.5,0.4l0.2,0.5l0.2,0.8l0.1,0.2l0.7,0.5
                l0.3,0.5l0.1,0.3l0.1,0.6l0.3,0.4l0.4,0.2l0.1,0.2l-0.1,0.8l0.2,0.4l0.3,0.4l0.1,0.3l-0.3,0.4l-0.6,0.2l-1.7-0.2l-2.3-0.5l-1.3,0.1
                l-0.7,0.2l-1.6,0.8l-0.5,0.1h-0.5l-0.9-0.7l-0.3-0.4l-0.1-0.3l-0.2-0.8l-0.2-1l-0.1-0.8L250,156l-0.3-0.2l-0.9-0.2l-0.3-0.3
                l-0.1-0.3l-0.1-0.5V154l0.1-0.4l0.1-0.1h0.1l-0.3-0.5l-0.1-0.6v-0.8v-0.5l0.1-0.2l0.2-0.1l0.4-0.1h0.6l0.8,0.6l0.6,0.1L251.9,151.8
                L251.9,151.8z M179.6,159h-0.1l-0.3-0.2l-0.4-0.5l-0.6-1l-0.8-1l-0.1-0.6l-0.2-0.4l-1-1.1l-0.6-0.4l-0.5-0.2l-0.1-0.3l0.3-0.9
                l0.4-0.7l0.2-0.3l0.7-0.2l2.4-0.5h0.5l0.6,0.2l0.8,1l0.3,0.1l0.2,0.2l0.2,0.4l0.1,0.4v0.7l-0.1,1.1l-0.1,0.4l-0.5,1.4l-0.5,0.8
                l-0.1,0.5l-0.2,0.4l-0.4,0.5L179.6,159L179.6,159z M153.7,162.1l0.2,0.1l0.3-0.1l0.5-0.3l0.6-0.3l0.7-0.2l0.2,0.2l0.1-0.1l0.2-0.4
                v-0.3v-0.3v-0.6l0.1-0.4l0.4-0.8l0.2-0.3l0.4-0.1l0.9,0.1l0.8,0.5l1.1,0.5l1.7,1.2l0.5,0.5l0.1,0.4l-0.3,0.9l-0.7,1.3l-0.6,0.5
                l-0.2,0.3l0.4,0.2l0.2,0.3l0.4-0.5l0.3-0.5l0.4-0.4h0.1v0.1l-0.1,0.2v0.2v0.2v0.1l0.2,0.1l0.1-0.1l0.5-0.6l0.5-0.9l0.8-0.6l0.2-0.3
                l0.7-0.3v-0.2v-0.7l-0.2-0.3l-0.8-0.7l-0.4-0.8l0.1-0.6l0.4,0.1l1.2,0.1l0.2,0.1l1.1,1l0.4,0.6l0.3,0.3l0.7,0.5l0.2,0.4l0.2,0.2
                l0.1,0.2v0.2v0.4l0.1,0.1l0.4,0.1l0.1,0.2l0.2,0.5l0.2,0.8l0.2,0.9l0.3,1.6l0.5,2.2l0.2,1.3l0.1,0.2l0.1,0.2l0.3,0.2l0.3,0.3
                l0.3,0.1h0.1l0.1-0.3l0.2-0.5l1-0.9l0.1-0.1l-0.1-0.2V169v-0.1l0.2-0.1l-0.7-1.2l-0.4-1.1l-0.3-1.4v-0.4v-0.9l-0.1-0.2l-0.2-0.2
                l-0.1-0.3v-0.3v-0.3l-0.2-0.6l-0.7-4.3v-0.4l0.1-0.3l0.2-0.2h0.4l0.1-0.1l-0.1-0.2l-0.3-0.5v-0.2l0.2-0.4l0.9,0.2l0.6,0.4l1.1,0.9
                l0.1-0.1l0.1-0.5l0.2-0.3l0.3,0.1l1,0.7l1.1,1.2l0.8,0.6l0.5,0.8l0.4,0.7l0.2,0.6v0.2l-0.1,0.2l0.1,0.3l0.2,0.4l0.1,0.3l0.1,0.7
                l0.1,0.9v0.5l1,4l0.2,0.7l0.1,0.3l0.7,1.5l0.4,1.1v0.7l0.1,0.2v0.3v0.5l-0.1,0.4l-0.1,0.3l-0.1,0.5l-0.2,1v0.2l0.2,0.3l1,1.2
                l0.6,1.4l0.3,0.2l0.8,0.9l0.8,0.5l0.3,0.2l0.3,0.3h0.1l0.2-0.1v-0.1v-0.1l-0.2-0.7v-0.3h0.1l0.8,1.1l0.5,0.4l0.6,0.5l1.1,1.1
                l0.1,0.1l0.6-0.1l0.2,0.1l0.1,0.1v0.1v0.6l0.2,0.3l0.9-0.1h0.3l0.2,0.1l0.1,0.2l0.2,0.7l0.2,1.3v0.5l-0.1,0.8l-0.1,0.3l-0.2,0.1
                l-0.5-0.1l-0.3-0.2l-0.2-0.3l-0.2-0.7l-0.1-0.1l-0.1,0.1l-0.2,0.6l-0.1,0.3l-0.1,0.2h-0.2l-0.7-0.6l-0.7-0.7l-0.2-0.2h-0.1l-0.3,0.2
                l-0.5,0.4l-0.2,0.3l0.1,0.2l0.1,0.3v0.3v0.2l-0.1,0.1l-0.2,0.2h-0.3l-0.5-0.1l-0.4-0.2l-0.7-0.6l-0.2-0.1l-0.2,0.1l-0.1,0.2l0.1,0.3
                l0.3,0.3l0.4,0.6l0.1,0.1h0.1v0.1v0.3v0.5l-0.2,1v0.2l0.1-0.1l0.6-1l0.3-0.3l0.6-0.5l0.3-0.3l0.8-0.1l0.3,0.2l0.2,0.3v0.1l-0.2,0.4
                v0.2v0.2v0.2l0.1,0.2l0.1,0.2l0.3-0.1h0.1l0.1,0.2l0.1,0.3v0.4l-0.2,0.9l-0.3,0.3l-1.1,0.5l-0.4,0.3l-0.7,0.2l-0.3,0.2l-0.2,0.1
                h-0.8l-0.9,0.2l-1-0.3l-0.7-0.1l-0.8-0.5l-0.3,0.1l-0.3,0.3l-1.5-0.4l-0.2-0.3l0.1-0.2l0.4-0.6V192v-0.1l-0.7-0.1l-0.8-0.3l-0.8-0.2
                h-0.6l-0.4-0.1l-0.4-0.3l-0.2-0.2v-0.2v-0.2v-0.5v-0.3l-0.2-0.3l-0.3-0.2h-0.3l-0.3,0.3l-0.3,0.5l-0.5,1.3l-0.2,0.2l-0.6,0.9
                l-0.2,0.2l-1.2,0.4l-1.4,0.2l-0.5,0.3l-0.5,0.5l-0.6,0.5l-1.5,0.6l-1.4,0.4l-1.4,0.2l-1.1,0.2l-0.3-0.1h-0.5l-0.5,0.4l-0.6,0.1
                l-2.2,0.1l-1,0.2l-0.6,0.1h-0.4l-0.3-0.1l-0.3-0.3l-0.3-0.5l-0.6-1.3l-0.2-0.6l0.1-0.9v-0.6l-0.2-1.2v-0.1l-1.1-0.4l-0.7-0.1h-1.1
                l-1.3,0.1l-1.3-0.1l-0.7-0.2l-0.7-0.3l-1.2-0.7l-0.1-0.1l-0.1-0.2l-0.1-0.4l-0.3-0.5l-0.8-1.2l-0.3-0.7l-0.1-0.2l-0.1-0.5l0.4-0.5
                v-0.5l0.1-0.3l0.1-0.1l1.6-0.6l2.9-0.7l2.6-0.5h1.2l0.7,0.2l0.7,0.1h1.3l1.6,0.3h0.3l0.7-0.2l0.2-0.2h1.1l0.2-0.1l0.2-0.2l-0.3-0.4
                l-1.1-0.8l-2.9-1.4l-0.7-0.3l-1-0.3h-0.6l-0.7,0.2h-0.3l-0.7,0.3l-0.7,0.2l-1.3,0.2l-1.9,0.1h-0.3l-0.4-0.2l-0.3-0.1l-1.9,0.2
                l-1.7-0.2l-1.9-2.2l-0.3-0.7l0.1-0.3l0.2-0.3l0.9-0.9l0.3-0.2l1.4-0.5l1.4-0.6l1.1-0.5l0.5-0.2h0.5l0.4-0.2l-0.1-0.2l-0.3-0.2v-0.3
                l0.2-0.1l0.7-0.1l0.7,0.2l0.4-0.1l0.1-0.2l-0.1-0.2l-0.7-0.3l-3.4,0.9l-1.6,0.1l-1.1,0.4h-0.6l-0.7-0.4l-0.1-0.1v-0.2l0.2-0.5
                l0.8-0.3l0.4-0.9h-0.4l-1.4,0.2l-0.6-0.1l-0.8-0.3l-0.2-0.4l-0.1-0.3v-0.3v-1l0.1-0.5v-0.1l1-1.7l0.6-0.3l0.4-0.5V165l-0.1-0.2
                l-0.4-0.5l-0.2-0.3l-0.1-0.3l0.1-0.4l0.2-0.6l0.7-0.9l1.6-1.9l0.8-0.8l0.8-0.4l1.1-0.9l2.9-1.5l2.6-1.6l0.9,0.4l0.3,0.3l0.1,0.3
                l0.1,0.4l0.1,0.5l0.1,1.1v0.5v0.5l-0.1,0.5l-0.1,0.4l-0.2,0.5l-0.3,0.6l-0.6,1.1L153.7,162.1L153.7,162.1z M203.5,158.8l-0.5,0.1
                l-0.4-0.7v-0.6v-0.3l0.1-0.3l0.2-0.3l0.4-0.3l0.2,0.2l0.1,0.3l0.1,0.1l0.3,0.2l0.1,0.3v0.3l-0.1,0.5l-0.1,0.3l-0.1,0.1L203.5,158.8
                L203.5,158.8z M265.4,321.8h-0.1H265l-0.4,0.1l-0.5,0.3l-0.6,0.5l-1.1,1.1l0,0l0,0h-0.1l-0.3,0.3l-0.3,0.2l-0.8,0.3l-0.2,0.1
                l-0.2,0.1h-0.3l-0.3,0.1l-0.3,0.1l-0.6,0.4h-0.1l-0.1-0.1v-0.1l0.1-0.1l0.4-0.2l0,0h-0.1L259,325h-0.1v-0.2h-0.1l-0.2,0.1h-0.2
                l-0.1-0.1h-0.1l-0.1,0.1H258l-0.2,0.1l-0.4,0.1l-0.1,0.1l0,0l0.4-0.2l0.2-0.1h0.1h0.1v0.1v0.1h0.1h0.1l0,0l0,0l0,0h0.1l0.4-0.1
                l0.1,0.1l-0.1,0.3v0.1h0.2l0.2-0.1l0.2-0.1h0.1l-0.1,0.1l-0.1,0.1l-0.3,0.2l-0.1,0.1l0,0l0.1-0.1h0.3h0.1v0.1l-0.2,0.1l-0.3,0.2
                l-0.3,0.1h-0.2v-0.1l0.1-0.1l0,0l-0.2,0.1h-0.1l-0.1-0.1l0,0h0.1l0,0H258l-0.3,0.1h-0.2l-0.1-0.1v-0.1h0.1l0,0l-0.1-0.1h-0.3H257
                v0.1h-0.3l-0.1,0.1l-0.4-0.1l-0.5,0.1l-0.4,0.1l-0.3,0.1h-0.2h-0.1h-0.2l-0.3,0.1l-0.5,0.1l-0.2,0.1l-0.3,0.2l-0.4,0.3l-0.3,0.2
                h-0.1h-0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1l-0.1,0.2l-0.3,0.5v0.1l0.1,0.2l0.3,0.1l0.3,0.1h0.4h0.2h0.1l0.2-0.1l0.4-0.2l0,0v0.7l0,0
                l0.1,0.1v0.1v0.1l0.1,0.1l0.2,0.1l0.1,0.1v0.1l-0.3,0.2h-0.2l-0.2-0.1h-0.2h-0.3l-0.6,0.1H252h-0.1h-0.1l-0.5,0.1l-0.5,0.1l-0.3,0.1
                l-0.2,0.1l-0.1,0.1l-0.1,0.1H250l-0.2,0.2l0,0h0.2l0.2,0.1l0.6,0.1l0.1,0.1h-0.1l-0.9-0.1h-0.2l-0.4-0.1l-0.6-0.2l-0.6-0.1h-0.6
                l-0.4,0.1l-0.3,0.1l-0.7,0.5l-0.2,0.3l-0.1,0.3l-0.1,0.1h-0.1V332l0.1-0.1l0,0l-0.2,0.1l0,0h-0.3l-0.3,0.1l-0.4,0.2l-0.3,0.2
                l-0.1,0.2l-0.1,0.2v0.2l0,0l-0.2-0.3l-0.1-0.1h-0.1h-0.1l-0.4,0.1h-0.3l-0.4-0.2l-0.1-0.1v-0.2v-0.4l0.1-0.2l0,0v-0.1l0.2-0.1
                l0.4-0.2l0.3-0.4l0.3-0.2l0.3-0.3l0.2-0.4l0.2-1.1l0.3-0.1l0.4-0.2l0.3-0.2l0.2-0.2l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.2l0.1-0.2
                l0.1-0.3v-0.3v-0.3v-1.3V325l0.1-0.3l0.2-0.2l0.1-0.2v-0.2l0.1-0.2l0.1-0.2l0.1-0.1h0.1l0.1-0.1v-0.1l0.1-0.2l0.2-0.2l0.1-0.2v-0.3
                v-0.3l-0.1-0.2l-0.1-0.1v-0.2v-0.3h-0.1h-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.2l-0.1-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1v-0.1h0.3h0.5
                h0.3l0,0l-0.1,0.2v0.1l0.1,0.1l0.1,0.2v0.2l0.1,0.1l0,0v0.1h0.1v0.1v0.1l0,0h0.2l0.1-0.2h0.1h0.1l0,0l-0.1,0.1v0.1v0.1l-0.1,0.1
                l-0.3,0.2v0.1h0.1l0.2-0.1h0.2h0.1l0.1,0.1v0.1v0.2l-0.1,0.3v0.1l0.2-0.2l0.2-0.1l0.3-0.1h0.1l0.2,0.3l0.4,0.2l0.7,0.3l0.4,0.1
                l0.1-0.1l0.1-0.2v-0.3l-0.1-0.2l-0.2-0.1l-0.1-0.1V322l0.1-0.1l0.2-0.1h0.2l0,0v0.1l0,0h0.1l0,0l0.1,0.1h0.3h0.1l-0.1-0.1v-0.1
                l0.1-0.1v-0.1h-0.1v0.1v0.1h-0.1v-0.1v-0.1l-0.1-0.2l-0.2-0.1l-0.1-0.1v-0.1V321h-0.1l-0.1-0.2l-0.2-0.1v-0.1h0.2v-0.1v-0.1
                l-0.1-0.1H251v0.1l0,0v-0.1v-0.1l0.2-0.2v-0.2v-0.1l-0.1-0.1H251h-0.1l0,0l0.1,0.2l0,0l-0.3-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2v-0.2
                v-0.3l-0.1-0.2l0,0v0.1v0.1h-0.1l-0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2v-0.1V318v-0.1l-0.1-0.1v-0.2l-0.1-0.1l-0.2-0.2l-0.1-0.1
                l0,0h-0.2h-0.2h-0.4l-0.2-0.1V317v-0.1l0,0l0,0v0.1l-0.1,0.1H248l-0.4-0.1h-0.1l-0.3,0.1h-0.1l0.1-0.1v-0.1h-0.1l0,0v-0.1l0.3-0.1
                v-0.1l-0.2-0.1h-0.1v0.1l-0.1,0.1h-0.1l-0.1,0.1l0,0v-0.1v-0.1h-0.1l-0.5-0.1h-0.3h-0.2l-0.3-0.1l-0.1-0.1l-0.7,0.1h-0.1h-0.1
                l-0.2-0.1l-0.5,0.1h-0.4l-1.2-0.2l-0.3-0.1l-0.1-0.1h-0.2H241h-0.1l-0.1-0.1h-0.1h-0.3l-0.2-0.1v-0.1v-0.2l0.1-0.2v-0.1l-0.1-0.1
                l-0.1-0.1h-0.2l-0.3,0.1h-0.2l-0.1,0.1v0.1h-0.1l-0.2-0.2V315l0.1-0.1l0.2-0.3l0.1-0.2l-0.1-0.1h-0.1l-0.1,0.1v0.1l0,0l-0.1-0.1
                v-0.3l0.1-0.1l0.1-0.1h0.2h0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.1h-0.2H239l-0.1,0.1h-0.1l-0.1-0.1l-1.5,0.4l-0.1-0.1v-0.1l0.2-0.3v-0.1
                l0.1-0.2l0.2-0.3l0.1-0.2l-0.1-0.1l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.1v-0.3l-0.2-0.1l-0.2-0.2l-0.2-0.2v-0.2l0.1-0.1V311v-0.1l0.1-0.1
                l0.1-0.2v-0.1v-0.1l0.1-0.2l0.1-0.1V310h-0.3h-0.6h-0.5l-0.5-0.1h-0.4l-0.5-0.1l-0.4-0.2l-0.3-0.4l-0.2-0.4l-0.2-0.4l-0.1-0.3V308
                l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.1-0.2l-0.1-0.1V307l-0.1-0.2l-0.1-0.1v-0.1l-0.1-0.1h-0.1h-0.3l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.2
                l-0.2,0.1H231l-0.3-0.1h-0.2h-0.4l-0.2-0.1l-0.3-0.1h-0.2h-0.1h-0.1v-0.1h-0.1l-0.3-0.1l-0.2-0.1l-0.6-0.2l-0.2-0.2h-0.1h-0.2
                l-0.1,0.1h-0.1h-0.1v0.1v0.1v0.1l0.1,0.2l0.2,0.2l0.1,0.2v0.2l-0.1,0.2l-0.3,0.4l0,0H227l-0.1,0.1h-0.1h-0.1h-0.1l-0.1,0.1v0.1v0.1
                v0.1l-0.1,0.1h-0.1v-0.1v-0.1l0.1-0.1l0.5-0.5l0.2-0.2v-0.1v-0.2l-0.1-0.2l-0.1-0.1h-0.2l-0.1,0.1l-0.1,0.2v0.1v0.3v0.1v0.1h-0.1
                l-0.1,0.1v0.2l-0.1,0.1h-0.1l-0.1,0.1v0.3v0.1l-0.1,0.1l-0.3,0.1h-0.1V308l0.1-0.1h0.1l0.2-0.5l0.2-0.3l0,0l-0.9,0.4l-0.3,0.2
                l-0.1,0.1v0.1v0.2v0.1l-0.1,0.1l-0.1,0.3l-0.1,0.2l-0.1,0.2l-0.3,0.1l0,0l-0.3,0.3h-0.1l-0.6-0.1l-0.4,0.1l-0.3-0.1l-0.1-0.3
                l-0.1-0.2l-0.6,0.1l-0.8-0.1h-0.4l-0.1-0.1l-0.1-0.3H220l-0.4,0.1l-0.5,0.4l-0.5,0.2h-0.4l-0.4-0.2l-0.6-0.4l-0.4-0.4l-0.5-0.2h-0.4
                l-0.1,0.2h-0.2l-0.1-0.4l-0.1-0.3l-0.2-0.1l-0.4-0.3l-0.3-0.2l-0.4-0.2h-0.2h-0.3h-0.3l-0.3,0.1l0,0l0,0l0,0l-0.2,0.2l-0.3,0.1h-0.4
                l-0.3-0.1l-0.1-0.2l-0.6-0.2l-1-0.2l-0.6-0.2l-0.2-0.1l-0.1-0.1v-0.2l0,0l-0.3-0.6l-0.1-0.5l-0.1-0.6v-0.2l-0.1-0.1l-0.2-0.1
                l-0.6-0.1v0.7v0.9h-0.7h-0.9H205h-1.2h-1.2h-1.2h-1.2H199h-1.2h-1.2h-1.2h-1.2H193h-1.2h-1.2h-1.2h-1.2H187h-1.2h-1.2h-1.2h-1.2H181
                h-1.2h-1.2h-1.2h-1.2H175h-1.2h-1.2h-1.2h-1.2H169h-1.2h-1.2h-1.2h-1.2H163h-1.2h-1.2h-1.2h-1.2H157h-1.2h-1.2h-1.2h-1.2H151h-1.2
                h-1.2h-1.2h-1.2H145h-1.2h-1.2h-1.2h-1.2h-1H138h-1.2h-1.2h-1.2h-1.2H132h-1.2h-0.3l-0.1-0.2l-0.3-0.2H130l-0.1,0.1l-0.1,0.1
                l-0.1,0.2v0.1l0,0v-0.1l-0.1-0.3v-0.1l0.1-0.1v-0.1h-0.2h-0.1v-0.1v-0.3l-0.1-0.2l0.1-0.1l0.3-0.1h0.3l0.1-0.1l0.1-0.3l-0.2,0.3
                h-0.1l-0.4-0.1h-0.3v-0.1v-0.1l0.1-0.1V303l0.1-0.6l0.1-0.2v-0.1l0,0l-0.4,0.4v0.1v0.1v0.2l-0.2,0.1h-0.1l-0.2,0.2h-0.1l-0.9-0.4
                l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.3l0.1-0.2l0.1-0.1h0.1l0.1,0.1l0.2,0.4l0.1,0.2h0.2l0.4-0.3l0.1-0.1L128,302h-0.2l-0.2-0.1
                l-0.2-0.2l-0.1-0.3v-0.8l0.1-0.2l0.2-0.1l0.1-0.2V300l-0.1-0.2l-0.2-0.1l-0.2-0.1l0,0l0.2,0.4v0.1l-0.3,0.3v0.1v0.3v0.1l-0.2,0.1
                l-0.2,0.3l-0.4,0.1h-0.4l-0.2-0.1l-0.6-0.6l-0.2-0.3v-0.2l-0.4-0.8v-0.2l-0.1-0.3h-0.2v-0.2l0.3-0.5l0.2-0.4v-0.1v-0.2l-0.1-0.5
                v-0.2l-0.2,0.3v0.2l0.1,0.2v0.2l-0.1,0.3l-0.2,0.3l-0.4,0.2l-0.7-0.1H123l-0.1-0.1v-0.5v0.1l-0.1,0.3l-0.1,0.4l-0.1,0.1h-0.2
                l-0.1-0.1l-0.1-0.1H122l-0.4,0.1h-0.2h-0.2l-0.4-0.1h-0.5l-0.1-0.1v-0.2l0.1-0.1l0.5-0.1l0.5-0.2l0.5-0.1v-0.1h-0.2l-1.1,0.2h-0.3
                h-0.1v-0.2l0.1-0.2l0.2-0.2l0.1-0.1l-0.1-0.1h-0.2l-0.1-0.1l0.1-0.4l-0.1-0.4l-0.1,0.4l-0.2,0.2l-0.9,0.1l-0.2,0.1h-0.1l-0.6-0.2
                l-0.3-0.1l-0.2-0.2l-0.4-0.4l-0.3-0.3v-0.5l0.1-0.3l0.2-0.4l0.6-0.8l0.2-0.2l0.2-0.1l0.9-0.1l0.7-0.1h0.1l-1-0.1h-0.9l-0.3,0.1
                l-0.4,0.5l-0.1,0.2l-0.1,0.2h-0.1l-0.1-0.1v-0.1l-0.1-0.2l-0.2-0.3l-0.1-0.3l-0.1-0.5v-0.2l0.1-0.3l0.3-0.5h-0.4v-0.4l0.1-0.5
                l0.3-0.3l0.3-0.2l0.3-0.3l0.5-0.2l0.2,0.4l0.5,0.1l0.1,0.2l0.2,0.3l0.2,0.3l0.2,0.3h0.1l-0.1-0.2l-0.4-0.5v-0.2l-0.1-0.2l-0.5-0.3
                l-0.1-0.1l-0.1-0.3v-0.2l0.1-0.2l0.5-0.6l0.1-0.3v-0.1v-0.2l-0.1-0.3l0,0v0.4v0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1l-1.2,1.3L117,290
                l-0.4,0.1l-0.2,0.1l-0.1,0.2l-0.2,0.5l-0.3,0.9l-0.3,0.7l-0.3-1l-0.5-0.7l0.9-0.7V290l-0.1-0.4v-0.1l0.1-0.2l0.2-0.3l0,0l-0.3,0.1
                l-0.4,0.6l-0.2,0.2h-0.1v-0.3l0.2-0.9l0.2-0.8l0.1-0.2l0.2-0.2h-0.1l-0.7,0.4l-0.2-0.2l-0.2-1.2l-0.4-0.5l-0.6-0.4l-0.6-0.2
                l-0.1-0.4l-0.1-0.4l0.2-0.5l0.3-0.2l0.2-0.1h0.2v0.2l-0.2,0.5h0.2l0.8,0.6h0.2l0.3-0.2h0.2l0.4,0.2l0.2,0.2l0.4,0.4l-0.1-0.3
                l-0.5-0.5l-0.3-0.2h-0.5l-0.3,0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.5v-0.2v-0.2l0.1-0.2l0.1-0.1l0.2-0.1l0.3-0.1l0.1-0.1l-0.3-0.3
                h-0.2l-0.5,0.4h-0.1V283l0,0l-0.2,0.2l-0.1,0.1l-0.4,0.6l-0.1,0.3v0.5v0.3l-0.1,0.1l-0.5,0.2l-0.3,0.4l-0.3-0.4l-0.4-0.4l-0.3-0.7
                l-0.5-0.1l-0.5-0.4l-0.2-0.3l0.3-0.7l0.4-0.5l0.1-0.6l0.1-0.1l0.7-0.2l0.5-0.3h-0.5h-0.3l-0.5,0.2l-0.6-0.4l-0.3-0.4l-0.1-0.3
                l0.1-0.3v-0.3l0.1-0.4l0.1-0.2l0.1-0.2l0.2-0.1l0.2-0.4l0.1-0.3l0.4-0.9l0.2-0.4l0.3-0.6l0.6-0.9l-0.2,0.1l-0.1,0.1h-0.1l-0.1-0.1
                l-0.1-0.2l-0.1-0.3v0.1v0.6l-0.1,0.5l-0.1,0.3l-0.3,0.7l-0.2,0.3L110,277l0.1-0.4l0.2-0.3v-0.5l-0.2-0.6l-0.1-0.5v-0.3v-0.3v-0.3
                l0.1-0.3l0.1-0.3l0,0v-0.3v-0.3l-0.1-0.3l-0.1-0.2l-0.3,0.1l-0.6-0.2l-0.2-0.5l-0.5-0.2l-0.3-0.4l-0.5-0.2l-0.4-0.1l-0.3-0.2
                l-0.4-0.3l-0.4-0.3l-0.3-0.2h-0.2h-0.5v-0.5l-0.1-0.3l0.1-0.3l-0.3-0.1l-0.4-0.2l0.1-0.5l0.1-0.4l-0.4-0.1l-0.5-0.2l0.2-0.3l0.1-0.3
                l-0.2-0.4l-0.4-0.7l-0.3-0.5l-0.4-0.8l-0.3-0.7l-0.3-0.5l-0.2-0.4l-0.3-0.7l-0.4-0.8l-0.4-0.6l0.1-0.4l-0.4-0.5l-0.4-0.5l-0.4-0.6
                l-0.4-0.3l-0.3-0.2l-0.4-0.3l-0.2-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.2v-0.3l-0.1-0.2l-0.5-0.4l-0.2-0.2l-0.4-0.3l-0.3-0.1l-0.1-0.1
                l-0.4-0.8l0.1-0.3v-0.3v-0.2l-0.6-0.6l-0.3-0.3l-0.3-0.3l-0.6,0.4l-0.7,0.4l-0.5,0.1l-0.6,0.2l0.2,0.4l-0.1,0.3l-0.2,0.1h-0.3v1
                l-0.3,0.7h-0.7l-0.4,0.2l-0.5,0.4l-0.4,0.3l-0.5,0.5l-0.2-0.1l0.1-0.4l-0.2-0.7l-0.1-0.6l-0.3-0.3l-0.5-0.5l-0.4-0.4l-0.5-0.5
                l-0.4-0.4l-0.4-0.4l-0.5-0.5l-0.2-0.7l-0.5-0.2l-0.5-0.3l-0.4-0.5l0.1-0.5l0.2-0.6v-0.4h-0.4h-0.7H83l-0.4,0.4l-0.4,0.4l-0.6-0.3
                l-0.7-0.4l-0.2,0.5l-0.7-0.2l-0.7-0.2v-1.7V246v-1.7v-1.7v-1.7v-1.7v-1.8v-1.8v-1.8V232v-1.8v-1.8v-1.9v-1.9v-1.9v-1.9v-1.9v-2v-2
                v-2v-2v-2.1v-2.1v-2.1v-2.1v-2.2v-2.2v-2.2v-2.2v-2.3v-2.3v-2.3l0.4,0.1l1.3,0.3l1.2-0.2l2.2,0.9l1.4,1.6l1.1,0.8l0.5,0.5l0.7,0.5
                l1.7,1l0.5,0.1l1,0.5l0.6-0.1l1.1,0.1l0.7,0.4l1.4,1.1l0.3,0.1l0.1-0.1l-0.5-1l-0.1-0.1l-0.6-0.4l-0.7-0.2l-0.1-0.1l-0.1-0.4v-0.1
                l0.1-0.1H94l0.3-0.1v-0.1h-0.2l-0.3-0.1l-0.3-0.2l-0.2-0.2l0.6-1.6l0.2,0.2l0.3-0.4l0.6,0.2l0.1-0.1l0.1-0.8l0.1-0.2l0.2-0.1
                l0.8-0.1l1,0.1l0.1-0.1l-0.1-0.5v-0.2l0.1-0.5l0.1-0.3l0.1-0.1l0.5,0.1l0.1,0.2l0.2,0.4l0.2,0.2l0.5,0.2l0.1,0.2l-0.2,0.6l-0.2,0.3
                l-0.4,0.9v0.2l0.6-0.4l0.7-0.5l0.6-0.3l0.5-0.1l0.4-0.2l0.2-0.3l0.2-0.3l0.3-1l0.2-0.2l0.9,0.1h0.2l0.1-0.1v-0.1l-0.2-0.2h-0.3v-0.1
                l0.1-0.2l0.1-0.1l0.4-0.1l0.3,0.4h0.2l0.6-0.4l1-1l0.4-0.3l0.3-0.1l0.3,0.2l0.2-0.1l0.3-0.6l0.1-0.3l0.2-0.3l0.7-0.6l0.5-0.1
                l0.3,0.1l0.3,0.3l0.3,0.1l0.4-0.1h0.3l0.1-0.1l0.5-0.7h0.2l0.1,0.2l0.2,0.5v0.3l-0.3,0.6l-2.3,1.8l-0.7,0.8l-0.3,0.3l-0.4,0.2
                l-0.7,0.1l-0.3,0.2l-0.5,0.1l-1.1,0.2l-0.2,0.1l-0.1,0.1l-0.4,0.9l-0.2,0.3l-0.4,0.5l-0.4,0.3l-0.6,0.1l-0.4,0.4l-0.4,0.8l-0.3,0.6
                l-0.4,0.5l-0.4,0.6l-0.1,0.3l0.1,0.4l0.1,0.1l0.4,0.2l0.2-0.1l-0.2-0.2l-0.4-0.3v-0.1l0.1-0.1l1.7,0.3l0.4-0.3l0.1-0.2v-0.1h-0.5
                l-0.1-0.2l-0.1-0.4v-0.3v-0.2l0.1-0.3l0.5-0.5l0.5-0.2l0.4-0.3l0.2-0.3l0.6-0.4l0.2-0.4l0.1-0.3v-0.1l-0.1-0.1l0.1-0.2l0.4-0.2h0.2
                l0.6,0.2l0.1,0.2l-0.1,0.4h0.1l0.2-0.5l0.1-0.2h0.1l0.1,0.1l0.1,0.2l0.1,0.5v0.9v0.4l0.2-0.6l0.1-0.3l0.6-1.3l0.4-0.7l0.5-0.7
                l0.7-0.5l1.5-0.9l0.9-0.2l0.4-0.2l0.2-0.2l0.1-0.2l0.2-0.3l0,0l-0.1,0.6l-0.1,0.2l-0.6,0.3l-0.1,0.3l0.1,0.4l0.1,0.3l0.1,0.1
                l0.2-0.1l0.3-0.3l0.4-0.5l0.9-1.2l0.1-0.2l0.2-1l0.5-0.4l0.9-0.5l0.2-0.3l-0.8-0.3l-0.2-0.2v-0.1l0.2-0.3l-0.4-0.3l-0.1-0.2v-0.5
                l0.1-0.4l0.2-0.4l0.1-0.1l0.4,0.2l0.3,0.3l1.1,1.2l0.4,0.6l0.2,0.5l0.6,1.5l0.3,0.8l0.2,0.9l0.2,0.6l0.2,0.4l1,1.5l0.5,0.6l0.4,0.4
                l0.5,0.3l0.6,0.2h0.4l0.6-0.6v-0.4l-0.3-0.7l-0.3-0.5v-0.3l0.4-0.6v-0.2l0.1-0.6l0.2,0.1h0.1l0.1-0.2l0.2-0.4l0.2-0.3l0.3-0.3
                l0.1-0.2l-0.3-0.1h-0.2l-0.1-0.1l-0.1-0.1l0.1-0.1l0.6-0.3l0.1-0.3l0.2-0.2l0.2-0.1l0.1,0.1l0.2,0.3v0.4l-0.1,0.7v0.5l0.2,1.2
                l0.2,0.3l0.6,0.4v0.3l-0.7,1.3l-0.2,0.3l-0.1,0.2v0.2l0.1,0.2l0.2,0.1h0.6l0.2-0.1h1.2l0.2-0.1l0.2-0.2l0.3-0.6l0.3-0.2l0.1-0.2
                l0.2-0.7l0.1-0.9l0.1-0.4l0.1-0.2l0.2-0.1l0.5,0.1l0.2-0.1l0.9,0.1l0.9-0.1l0.9,0.2l0.6,0.2l0.5,0.3l1,0.7l0.4,0.4l1.5,1.6l0.4,0.3
                l0.8,0.3l2.8,0.7l0.3,0.2l0.7,0.7l0.5,0.4l0.6,0.4l0.7,0.3l1.5,0.5l0.2,0.2h0.3l0.3-0.1l1.3,0.3h0.4l0.3,0.1l0.3,0.2l0.5,0.1v-0.1
                l-0.5-0.8v-0.1h0.2l0.6,0.1l0.1-0.2h0.2l0.5,0.1l0.5,0.2l0.6,0.4l0.7,0.3l1,0.8l0.6,0.7l0.5,0.8l0.3,0.6l0.1,0.3l0.1,0.2h0.2
                l0.1,0.1l-0.2,0.7l-0.1,0.2l-0.1,0.1l-0.5,0.1l-1.4-0.2l-0.2,0.6l-0.8,0.5l-0.1,0.2v0.2l0.1,0.5l-0.1,0.1l-0.6,0.6v0.2l0.4,0.2
                l0.4,0.4l0.3,0.1l0.4-0.1l0.5,0.1l0.7,0.3l0.5,0.1h0.3l0.4,0.1l0.4,0.1l0.6,0.1h1.3l0.4,0.1l0.5,0.1h1.1h0.2l0.3-0.3l0.2-0.1h0.4
                l1.1-0.2h0.4l0.4-0.1l0.4-0.3l0.3-0.1l0.1,0.2l0.2,0.1h0.3l0.5-0.3l1.2-0.9h0.4l0.3-0.3h0.1l0.1,0.1l0.3,0.7l0.1,0.1l0.2,0.1
                l0.2,0.3l0.2,0.5l0.2,0.1h1.1l0.4,0.1l0.1,0.1l0.1,0.4l0.1,0.8l0.1,0.3l0.2,0.4l0.1,0.1l0.1-0.1l0.3-1.1l0.1-0.2l0.2,0.1l0.1,0.1
                l0.3,0.8l0.4,0.6l1,1.1l0.2,0.4l0.1,0.3l1.3,0.6l-0.2,0.2l-0.3,0.2l-0.4,0.1l-0.3-0.1l-0.3-0.2h-0.1l0.1,0.2l0.7,0.9l0.2,0.3
                l0.2,0.2l0.1,0.1l0.1,0.2l0.1,0.3l0.6,0.7l0.2,0.3l0.6,1l0.3,0.4l0.2,0.2h0.1l-0.1-0.2l-0.8-1.4l-0.4-0.9l-0.1-0.2v-0.3v-1.1
                l0.1-0.2l0.3-0.1l0.4,0.5l0.1,0.1h0.1V208l0.2,0.1l0.4,0.4h0.1l-0.3-0.7l-0.2-0.3l-0.1-0.2l0.2-0.3l-0.1-0.2l-0.5-0.5l-0.2-0.5
                L173,205v-0.3v-0.3v-0.3l-0.3-0.6l-0.3-0.4l-0.2-0.4l-0.1-0.2v-0.6l0.2-0.3l0.4-0.4l0.1-0.4l-0.2-0.4v-0.2h0.1l0.7-0.2l0.2,0.1
                l0.3-0.1l0.4-0.2l0.3-0.1h0.4l0.2-0.1l0.2-0.1l0.1-0.1l0.2-0.5l0.1-0.1l0.4,0.1l0.2,0.1h0.1v-0.7l0.1-0.2l0.4-0.5l0.4-0.1l0.3-0.1
                l0.3-0.3l0.2-0.2l0.2-0.4l0.1-0.5l-0.1-0.1l-0.4-0.2l-0.3,0.1l-0.6,0.4l-0.6,0.5l-0.2,0.4l-0.1,0.5l-0.1,0.2l-0.5-0.2h-0.2l-0.3,0.1
                l-0.3,0.2l-0.3,0.3l-0.4,0.1l-0.6-0.2l-0.3-0.1l-0.3,0.3v0.3l0.2,0.4l-0.2,0.2l-0.8,0.1h-0.2l-0.4,0.1h-0.2l-0.1-0.2l-0.9-0.8
                l-0.1-0.2l0.2-0.6l0.8-1.7l0.1-0.1l1.5-0.3l0.9-0.3l1.7-0.9l0.3-0.1l1.1-0.6l0.4-0.2l0.4,0.1l0.6,0.3l0.3,0.3l0.2,0.4l0.2,0.5
                l0.2,1.1l0.1,0.9l0.1,0.3l0.5,0.6l0.3,0.2l0.2,0.1l0.1-0.1h0.1l0.1,0.1l0.1,0.4h0.1l0.3-0.1l0.3,0.2v0.1l-0.1,0.5l0.1,0.2l0.4,0.4
                l0.4,0.2l0.4,0.1l0.8-0.1l0.7-0.2l0.5-0.3l0.4,0.4l0.8,0.9l0.5,0.7l0.4,0.3l0.9,0.4l0.2,0.2h0.3l0.4-0.2l0.5,0.1l0.5,0.3l0.4,0.1
                l1.3-0.5h0.2l0.5-0.2l0.3-0.1h0.4l0.3-0.1l0.2-0.2h0.7l1.2,0.2l0.8,0.2l0.5,0.3l0.4,0.2h0.3l0.3-0.1l0.3-0.1l0.3-0.3l0.7-0.1
                l0.1-0.1v-0.1l-0.1-0.2l-0.4-0.4l-0.3-0.4l-0.1-0.3v-0.3l0.1-0.2h0.2l0.3,0.2l0.4,0.4l1,1.6l0.2,0.2l0.1,0.2l0.9,0.6l0.4,0.1
                l0.5-0.4l0.2-0.2l0.1-0.2v-0.2V202l-0.1-0.2l-0.1-0.2l-0.4-0.3l-0.6-0.4l-0.5-0.1L200,201l-0.6,0.4l-0.2-0.1l-0.7-1.1l-0.2-0.4v-0.1
                l0.3,0.1v-0.1l-0.2-0.5l-0.1-0.2l-0.4-0.8V198l0.2-0.1l0.1-0.1h0.1l0.7,0.5l0.4-0.2l0.8-0.3l-0.3-0.5l-0.1-0.5v-0.1l0.3-0.1l0.5,0.4
                l0.3,0.1l0.2-0.2h0.2l0.2,0.1l0.2,0.2l0.4,0.6l0.2,0.3l0.2,0.5l0.1,0.1h1l0.6-0.5v0.2l-0.1,0.4l-0.7,1.4v0.2l0.4-0.1l0.2-0.1
                l0.1-0.2l0.1-0.4l0.1-0.1l1-0.6l0.3-0.1l-0.2,0.7l-0.4,2.4l-0.1,0.8l-0.1,0.3l-0.4,0.9v0.3l0.4,0.8l0.1,0.2v0.6l0.1,0.1h0.4l0.4-0.2
                l0.4-0.1l0.1,0.1l-0.2,0.8v0.1l0.4-0.2h0.2h0.1l0.3,0.4v0.3v0.4v0.3L207,208l-0.1,0.1l-0.2,0.1h-0.1l-0.4,0.1l-0.3-0.1l-0.3-0.2
                l-0.2-0.1l-0.2,0.2h-0.3l-0.4-0.5h-0.2l-0.1,0.1v0.1l0.2,0.3l1.3,1.8l0.2,0.4v0.5l0,0l0.1-0.5l-0.1-0.3l-0.7-1l-0.1-0.4v-0.1
                l0.2-0.1l1,0.3l0.4-0.1l0.3-0.2l0.1-0.2l0.1-1.2l0.2-0.8l-0.1-0.7l-0.3-1.1l-0.2-0.7l-0.5-0.7v-0.2l0.5-2.1l0.1-0.2l0.1-0.1h0.4
                l0.3-0.2l0.5,0.2l0.3,0.1l0.3-0.2l0.7-0.9l0.3-0.3l0.4-0.5l0.4-0.8l0.5-0.6l0.8-0.5l0.5-0.4l0.1-0.2h-0.4L212,196l-0.1-0.4v-0.8
                v-0.4l-0.1-0.4l-0.1-0.3l-0.1-0.3l-0.1-0.2l-0.1-0.1h-0.1v0.1l-0.1,0.7l-0.1,0.5l-0.2,0.3l-0.4,0.2l-0.7,0.1l-0.3-0.3v-0.2l0.1-0.8
                l0.2-0.4l0.7-0.7l0.4-0.6v-0.1h-0.4l-0.1-0.1l-0.1-0.7v-0.3l0.1-0.3l0.3-0.2l0.8-0.3l0.7-0.3v0.1l-0.5,1l-0.1,0.2l0.2,0.2l0.5-0.6
                l0.3-0.5l0.1-0.2l-0.3-0.1v-0.2l0.1-0.4V189l-0.3-0.3l-0.4,0.2l-0.3,0.3l-0.3,0.1h-0.4l-0.3-0.1l-0.2-0.2l-0.2-0.3l-0.3-0.5
                l-0.3-0.5l-0.1-0.1l-0.1,0.1l-0.2,0.5l-0.1,0.1l-1.3-0.7l-0.6-0.4l-0.3-0.3l-0.3-0.2l-0.4-0.1l-0.3-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.4
                l-0.3-0.5l-0.6-1.1l-0.2-0.7V183v-0.7l0.6-1.2l0.1-0.3l0.2-0.3l0.3-0.2l0.2-0.1l0.5,0.2l-0.3-0.4v-0.2l0.3-0.7H206l-0.8,0.5H205
                l-0.3-0.3l-0.5-1.1v-0.7l0.2-1l0.1-0.6l-0.2-0.5l0.1-0.1l0.2-0.1l0.1-0.1l-0.1-0.6l0.1-0.3l0.4-0.6l0.4-0.5l0.2-0.2h0.2l0.2,0.2
                l0.2,0.3l0.4,0.3l0.3,0.1l0.2-0.2l0.3-1.1l0.1-0.3l-0.1-0.1h-0.6l-0.3-0.1l-0.2-0.1l-0.1-0.4l0.1-0.2l0.6-0.8l0.3-0.8l0.9-1.1
                l0.9-0.5l0.4-0.2h0.3l0.2,0.1l0.2,0.6v0.6l0.5,0.8l0.4,0.1l0.2-0.1h0.8l0.2,0.2v0.2l-0.1,0.4l0.1,0.3l0.5,0.6l0.5,0.4l0.4,0.5
                l0.6,1.1l0.1,0.3l0.1,0.3l0.2,1.2v0.5l-0.1,1.3l-0.1,0.2l-0.2,0.3l0.1,0.1l0.5,0.3l0.4,0.7l0.2,0.2l0.5,0.4l0.1,0.1l0.1,0.2l0.3,1
                l0.5,0.9v0.2l-0.1,0.4l0.1,0.1l0.2,0.1l0.2-0.1l0.1-0.3l0.2-0.1l0.2,0.1l0.1,0.2l0.2,0.6l0.3,0.5v0.1l-0.1,0.1l-0.7,0.1l-0.4-0.1
                l-0.4-0.2l-0.2-0.2l-0.2-0.4h-0.1l-0.1,0.3l-0.3,0.4l-0.2,0.4l0.2,0.2h0.9l0.2,0.1l0.2,0.3l-0.3,0.4l-0.6,0.8l-1.3,1.4l-0.4,0.4
                l0.1,0.1h0.1l0.4-0.1l0.4-0.2l0.5,0.1l0.2,0.2l-0.1,0.2l0.1,0.2l0.8,0.6l0.5-0.1l0.5-0.6l0.4-0.3h0.5l0.1,0.1v0.2l-0.4,0.3l-0.3,0.3
                v0.1l0.4-0.1l0.9,0.2l0.5,0.1l0.3-0.1l0.3,0.1l0.3,0.2l0.1,0.1l-0.3,0.1h-0.3l-0.2,0.1l-0.2,0.3l-0.1,0.4l-0.1,0.4l-0.2,0.2
                l-0.3-0.1l-0.1-0.1l0.1-0.2h-0.1l-0.3,0.1H219l-0.1,0.1l1.4,1.4l0.4,1.2l0.3,0.5v0.1l-0.2,0.3v0.3l0.1,0.8v0.6l-0.1,1l0.1,0.3
                l0.3,0.3l0.2,0.4l0.1,0.1l0.1,0.3l0.1,0.2l0.1,0.1l0.1-0.1l0.1-0.3l0.1-0.3l0.3-0.5l0.3-0.7l0.1-0.2v-0.6v-0.2l0.3-0.9l0.1-0.6
                l0.1-0.9l0.1-0.6l0.3-0.6l0.6-1.1l0.2-0.2l0.2-0.1h0.4l0.3,0.4l0.4,0.7l0.5,0.6l0.9,0.8l0.3,0.3l0.5,0.8l0.2,0.8l0.1,1.1l0.1,0.7
                l0.1,0.2v0.3v0.4v0.3l-0.1,0.2l-0.1,0.1H228l-0.3-0.1l-0.1-0.1l-0.2-0.5h-0.1l-0.3,0.4v0.2l0.1,0.7v1.3v0.3l0.3,1.4l0.6,1l1.4,2
                l0.1,0.2l0.1,0.8l0.1,0.2l0.1,0.1h0.1l0.2-0.1l0.5-0.6l0.4-0.6l0.3-0.3h0.2l0.2-0.1l0.2-0.2l0.1-0.2l0.1-0.2l0.1-1l0.1-0.5l0.2-0.6
                l0.1-0.2l1.1-1.6l0.1-0.2l0.5-1.9l0.2-0.9v-0.5l-0.1-0.5v-0.4l0.1-0.3l0.1-0.2l0.2-0.2l0.1-0.3h0.1h0.2l0.2,0.2h0.2l1.1-0.2v-0.1
                l-0.7-0.4v-0.2l0.1-0.3l0.2-0.3l0.3-0.1l0.1-0.2v-0.3l0.1-0.4l-0.1-0.1l-0.6-0.6h-0.4l-0.1-0.1l-0.3-0.5l-0.1-0.7v-0.3v-0.5v-0.1
                V189l-0.1-0.3v-0.3l0.1-0.2v-0.3l0,0l-0.1-0.3l0.2-0.8v-0.2l-0.1-0.3l-0.1-0.1l0.1-0.1h0.3l0.3,0.1l0.4,0.3h0.4l0.5-0.2l0.5-0.1h0.9
                l0.2,0.1l0.9,0.7l0.7,0.4h0.3l1.6,0.1l0.7-0.1h0.4l0.7,0.4v0.3l-0.3,0.6l-0.4,0.1L243,189l0.3,0.3l0.9,0.4l0.2,0.6l0.1,0.3l-0.1,0.3
                l0.1,0.1h0.2l0.6-0.2l0.6,0.1l0.9,0.5l0.1,0.1l0.2,0.4v0.1l-0.8,0.9l-0.4,0.4l-0.5,0.4v0.2h0.8l0.6,0.1l0.3,0.1l0.1,0.2l0.2,0.4v0.3
                v0.4l-0.1,0.3l-0.7,0.8l-0.3,0.2l-0.5,0.3l-0.2,0.2h-0.3l-0.3-0.3l-0.3-0.1l-0.5,0.2h-0.3l-0.1,0.1v0.2l0.2,0.5l0.1,0.2l0.1,0.1
                l-0.1,0.2v0.1l0.1,0.1l0.5,1.1l0.1,0.1h0.1l0.2-0.3l0.1-0.1h0.1v0.2l-0.2,0.9v0.2v0.2l0.1,0.5l0.2,0.5l0.3,0.4l0.5,0.6l0.6,0.6
                l0.2,0.3l0.3,0.7l0.1,0.3l-0.1,0.8l-0.2,1.2l-0.2,0.7l-0.1,0.2l-0.5,0.5l-0.3,0.1h-0.4l-0.4-0.3l-0.2,0.4l-0.3,0.7l-0.2,0.4
                l-0.2,0.2l-0.3,0.2l-0.5,0.6l-0.2,0.2l-0.9,0.3l-0.7,0.8l-0.3,0.3l-0.3,0.1h-0.3l-0.2-0.1l-0.1-0.5l-0.1-0.2l-0.2-0.4l-0.5-1
                l-0.2-0.4l-0.1-0.1l-0.3,0.1H239l-0.3-0.3l-0.1-0.2v-0.1l0.2-0.1l-0.1-0.2l-0.4-0.5l-0.2-0.2v-0.1l-0.4-0.3l-0.4-0.1l-0.5,0.5
                l-0.2,0.4v0.1l0.3,0.1l0.1-0.1l0.2-0.3l0.1-0.1l0.3,0.1l0.3,0.2l0.1,0.2v0.1l0.8,1l0.3,0.2l0.1,0.2l0.1,0.4l0.2,0.5l0.4,0.7l0.4,0.9
                l0.1,0.4l-0.2,0.2h-0.1l-0.3-0.1l-0.8-0.4h-0.1l-0.2,0.2l-0.2,0.5h-0.1l-0.4-0.2l-0.8-0.4l-0.5-0.4l-0.3-0.3l-0.3-0.5l-0.4-0.7
                l-0.5-0.2l-0.5,0.2l-0.8,0.1l-1.6-0.1l-0.2,0.1L232,211l0.1,0.4v0.1l-0.1,0.1v0.1l0.2,0.4l0.3,0.3l0.8,0.4l0.5,0.3l0.3,0.3l0.1,0.2
                v0.2l-0.1,0.5l-0.1,0.2l-1.9,2.4l-0.7,1l-0.4,0.6l-0.3,0.4l-0.3,0.2l-0.5,0.1h-0.6l-0.8-0.1l-0.4-0.3l-0.8-0.8l-0.5-0.5l-0.2-0.2
                l-0.2-0.5l-0.2-0.1l-0.4-0.1l-0.4-0.2l-0.9-0.8l-0.5-0.3l-0.4-0.2h-0.4l-0.1,0.1l0.3,0.4h-0.1l-0.3-0.1h-0.3l-0.6-0.3h-0.6l-0.4-0.1
                l-0.5-0.2l-0.5-0.1h-0.8H219v0.1l0.4,0.4l0.7,0.4l-0.1-0.4v-0.1l0.2-0.1l1.1,0.2l1.2,0.5h0.3l0.3,0.2l0.4,0.3l0.5,0.6l1,1.4l0.3,0.3
                l0.4,0.3l2.2,0.5h0.8l1.5,0.1l0.8,0.2l0.2,0.2l0.1,0.6l-0.1,0.3l-0.4,0.9l-0.3,0.7l-1.7,2.8l-0.2,0.6l-0.1,0.4l-0.3,0.4l-0.8,0.6
                l-0.8,0.5l-0.5,0.1l-0.4-0.1l-0.3-0.1l-0.4-0.5v0.1l0.3,0.8l-0.1,0.1L225,228l-0.5-0.4l-0.2,0.1l-0.1,0.1h-0.1l-0.2-0.1l-0.3-0.3
                l-0.1-0.1l-0.1-0.4l-0.1-0.1l-0.6,0.3l-0.1,0.1l0.3,0.2l0.1,0.1l0.3,0.7v0.1l-0.2,0.1l-0.6-0.2h-0.1l0.3,0.6l0.1,0.3v0.2l-0.4,0.7
                l-0.3,0.3l-0.3,0.1l-0.2-0.1l-0.2-0.2h-0.2l-0.1,0.2l-0.2,0.2l-0.3,0.1h-0.4l-0.5-0.2l-1.2-0.7l-0.4-0.1l-0.7-0.1l-0.1-0.1v-0.1
                l0.1-0.1v-0.1l-0.1-0.1l-0.2,0.1l-0.2,0.2l-0.3,0.1l-0.4-0.1l-0.6-0.3l-1.2-0.7l-1.3-0.6l-0.7-0.8l0.3,0.7v0.2l-0.1,0.2v0.2l0.3,0.5
                l0.4,0.2h0.4v-0.1l-0.5,0.1l-0.1-0.2l-0.1-0.3h0.1l0.4,0.1l0.2,0.2l1.8,0.9l0.5,0.2l0.4,0.2l0.1,0.1l-0.1,0.2l-0.7,0.6v0.1l0.5-0.1
                l0.6-0.5l0.3-0.2l0.3-0.1l0.4,0.2l0.6,0.6l0.4,0.3l0.6,0.2l0.4,0.2l0.6,0.6l0.1,0.3l0.1,1.2v0.3l-0.1,0.3l-0.1,0.3l-0.3,0.2h-0.4
                l-0.3,0.1l-0.7,0.6l-0.3,0.1l-1.2-0.2l-0.5-0.2h-0.2l-0.1,0.1l-0.1,0.1l-0.5,0.1l-0.1,0.1v0.2l0.1,0.2l0.1,0.1l0.2,0.2l0.3,0.1
                l0.5,0.1l0.1,0.3v0.1l-0.2,0.2h-0.2l-0.4-0.3H216l-0.2,0.1h-0.2h-0.3l-0.2,0.1v0.3l-0.1,0.2l-0.3,0.3l-0.2,0.2v0.2l0.2,0.1l0.2,0.3
                l0.2,0.5v0.2l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.4-0.3l-0.8-0.4h-0.1l0.1,0.1l0.5,0.4l0.2,0.2v0.2l-0.4,0.4v0.1l0.1,0.1v0.1l-0.2,0.2
                l-0.3,0.2h-0.5v0.1l0.2,0.2l0.1,0.1l-0.2,0.2h-0.1l-0.6-0.1l0.2,0.4l0.1,0.1l0.2,0.2l0.3,0.2v0.1l-0.1,0.2l-0.2,0.2l-0.8,0.6
                l-0.6,0.7l-0.1,0.2l0.1,0.5v0.1l-0.1,0.2l-0.3-0.1l-0.1,0.1l0.1,0.2v0.3l-0.1,0.4l-0.2,0.7l-0.4,0.9l-0.3,0.8l-0.2,0.7l-0.2,0.4
                h-0.3l-0.2,0.2l0.2,0.1l0.1,0.1l0.1,0.2l-0.1,0.7l-0.2,1.1l-0.1,0.9v2.6v1.2l-0.1,0.6l-0.1,0.3l-0.2,0.1l0.3,0.1l0.2,0.2l0.1,0.2
                l0.1,0.4l0.1,0.2h0.1h0.1l0.1,0.1l0.3,0.6l0.4,0.2v0.3l-0.1,1.7v0.2l0.2-0.4l0.2-1.3l0.2-0.6l0.2-0.1l0.8-0.1l0.8,0.2h0.3l0.3-0.1
                l0.3,0.2l0.1,0.2l0.1,0.7l0.1,0.4l0.5,1.4l0.2,0.8l0.3,1.2l0.1,0.3l0.6,1.6l0.1,0.4v0.3v0.2l-0.1,0.4l-0.2,0.5l-0.2,0.8l-0.2,0.3
                l-0.2,0.2l-0.2,0.1l0,0l0.2-0.1l0.2-0.1l0.4-0.3l0.2-0.1h0.4v0.1l-0.2,0.2l0,0l0.3-0.2l0.6-0.3l2.5-0.9l0.6-0.1l0.9,0.2l0.7,0.4
                l0.8,0.5l0.8,0.4l1.3,0.3l0.4,0.2l0.7,0.2l0.3,0.2l0.4,0.5l0.7,0.6l0.5,0.4l0.6,0.3l0.6,0.6l0.9,1.4l0.2,0.2l0.5,0.2l1,0.3l1.5,0.7
                l0.7,0.3l0.4,0.1l0.4,0.2l0.4,0.3l0.3,0.3l0.2,0.3l0.2,0.2l0.4,0.2l0.2,0.2v0.3l-0.4,1v0.1l0.4-0.7l0.2-0.2l0.2-0.1h0.4l0.6,0.1h0.5
                l0.5-0.1l0.4-0.1h0.3h0.2l0.1-0.1h0.2l0.7,0.2h0.3l1,0.2l0.6-0.1h0.1l0.2,0.3h0.2h0.3l0.3,0.1l0.5,0.4l0.2,0.3l0.2,0.7v0.2l-0.4,1.6
                l-0.1,0.6v0.5l0.1,0.3l0.4,0.5l0.1,0.1l0.2,0.8l0.1,0.3v0.4l-0.1,0.6v0.5l0.1,0.7v0.5l-0.2,0.2v0.4l-0.1,0.4v0.2l0.1,0.3l0.2,0.2
                l0.3,0.2l0.2,0.3l0.5,0.7l0.3,0.4l0.4,0.6l0.1,0.3l-0.1,0.2l-0.1,0.1l-0.3,0.2l-0.1,0.1l0,0l0.5-0.1h0.3l0.2,0.1l0.2,0.3l0.3,0.2
                l0.4,0.2l0.4,0.3l0.7,0.8l0.1,0.2l0.2,0.4l0.3,0.6l0.1,0.4v0.2l-0.1,0.2l-0.5,0.4l-0.5,0.6h0.2l0.3-0.3l0.6-0.5l0.3-0.1l0.3,0.1
                l0.5,0.1l0.4,0.2l0.3,0.3l0.5,0.7l0.5,0.6l0.3,0.5l-0.1-0.3l-0.2-0.4l-0.5-0.6l-0.2-0.3v-0.1v-0.2v-0.3l0.1-0.4l0.1-0.3l0.2-0.2
                l0.1-0.2l0.1-0.2l0.1-0.1l0.4-0.3h0.1l0.1,0.3l0.1,0.1h0.2l0.2,0.1l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.6l0.1,0.3v-0.3l0.1-0.5l0.1-0.2
                l0.3-0.3v-0.1l-0.1-0.2l-0.6-0.8V293l0.2-0.1l0.1-0.2l0.1-0.3l0.1-0.2l0.4-0.3l0.3-0.5l0.2-0.3l0.1-0.2h0.1l-0.2-0.1v-0.1v-0.4
                l-0.1-0.4l-0.1-0.2l-0.3-0.4l-0.1-0.1l-0.1-0.5v-0.2l0.1-0.2l-0.1-0.2l-0.3-0.4l-0.1-0.3l-0.1-0.8l-0.1-1l-0.1-0.7l-0.2-0.5v-0.3
                l0.1-0.1l0.1-0.4l0.1-0.1h0.2l0,0l-0.2-0.2l-0.1-0.2V283l0.2-0.2v-0.1l-0.2-0.1l-0.5-0.2l0.2-0.1l0.1-0.2V282l-0.2-0.1l-0.2-0.1
                l-0.2-0.2l-0.2-0.4l-0.1-0.3l-0.1-0.5l-0.2-0.5l-0.1-0.1l-0.1-0.1h-0.1v-0.1l0.1-0.1l2.1-0.9l0.2-0.1l1-0.5l0.5-0.3l0.5-0.4l0.7-0.4
                l0.3-0.3l0.2-0.3l1.1-1l0.4-0.5l0.3-0.5l0.4-0.6l0.5-0.6l0.3-0.6l0.1-0.5l0.2-0.8v-0.7v-1.1v-0.9l-0.1-1.5l-0.1-0.5l-0.2-0.6
                l-0.4-1.1l-0.1-0.3l-0.2-0.5l-0.7-1.4l-0.9-0.9l-0.2-0.2l-0.4-0.3l-0.6-0.3l-0.4-0.3l-0.9-1l-0.3-0.1l-0.1-0.3V259v-0.5l0.1-0.3
                l0.1-0.2l0.1-0.2l0.5-0.7l0.3-0.6l0.2-0.3l0.2-0.2l0.4-0.3l0.2-0.4l-0.1-0.2l-0.2-0.2v-0.2l0.3-0.5v-0.1v-0.4l0.1-0.1h0.4l0.5,0.6
                l0.1-0.1l-0.2-0.2l-0.2-0.4v-0.2l0.4-0.4v-0.2l-0.1-0.3v-0.2l0.2-0.5l-0.1-0.1l-0.7-0.1l-0.1-0.2v-0.1l0.3-0.2v-0.1l-0.6-1l-0.1-0.4
                l0.2-0.5l0.3-0.2v-0.1h-0.3l-0.2-0.1l-0.2-0.4l0.1-0.2l0.1-0.1l0.2-0.5l0.2-0.1v-0.1l-0.8,0.2l-0.4-0.2H256l-0.2-0.1l0.1-0.2
                l0.6-0.9l0.3-0.5l0.2-0.4l0.1-0.3v-0.1l-0.1-0.9v-0.3l0.2-0.2l0.4-0.5l-0.5-0.4l-0.3-0.4l-0.2-0.2l-0.2-0.2l-0.2-0.4l-0.2-0.5
                l-0.2-1.1V239v-0.4l0.1-0.2l0.1-0.2l0.5-0.4l0.8-0.6l0.6-0.2l0.5,0.1l0.9,0.1l0.7,0.4l2.2,0.9l0.4,0.4l-0.4,0.3l0.1,0.1l0.8-0.6
                l0.2-0.1h0.2l0.6,0.2h0.3l0.3,0.2l0.8,0.7l0.1-0.1l-0.2-0.4l0.1-0.2l0.6-0.4l0.6-0.3l0.4-0.3l0.5-0.4l0.3-0.2h0.2l0.2,0.1l0.6,0.5
                l0.4,0.3l0.3,0.3l0.4,0.5l0.2,0.1l0.3,0.3h0.4h0.1v0.1l0.1,0.2v0.1v0.2l-0.1,0.4l-0.3,0.7h0.1l0.2-0.1l0.2-0.3l0.2-0.1l0.4,0.2
                l0.4,0.3l0.1,0.2l0.1,0.3l0.1,0.1l0.1,0.3v0.1l-0.1,0.1l-0.5,0.2l0.1,0.1l0.5-0.1l0.2-0.1l0.1-0.2l0.2-0.1l0.6,0.4l0.1,0.1v0.1
                l-0.1,0.1l-0.3,0.1l-0.2,0.4v0.2l0.2,0.1h0.4v0.1l-0.2,0.2v0.2l0.5,0.7l0.4,0.3l0.2,0.1h0.5l0.4,0.1l0.9,0.4l0.5,0.1l0.4-0.1h0.3
                l0.3,0.2l0.1,0.1v0.2v0.3l0.1,0.2l0.3,0.1h0.2l0.3-0.3h0.3l0.1-0.2l0.1-0.4l0.1-0.2l0.2-0.1l0.1,0.1l0.1,0.2l0.2,0.5v0.2v0.2
                l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.2,0.3l-0.2,0.7l-0.1,0.5v0.3v0.3v0.3l0.1,0.3l0.2,0.4v0.1v0.3v0.1l-0.1,0.3l-0.3,0.3l-0.5,0.1h-1.5
                l-0.4-0.1l0.1,0.2l0.4,0.1h0.4l1.5,0.1l0.2,0.1l0.2,0.3l0.1,0.3l0.1,0.6v0.3l-0.1,0.3l-0.2,0.3l-0.1,0.4l-0.1,0.5l0.1,0.3h0.8
                l0.2,0.2v0.1l-0.3,0.5v0.2l0.1,0.4v0.1L280,257l-0.1,0.3l-0.1,0.4l-0.1,0.3l-0.2,0.2h-0.1l-0.1-0.1l-0.2-0.6l-0.1-0.1l-0.1,0.1v0.1
                v0.1v0.1l-0.1,0.1l-0.3,0.2l-0.5,0.1v0.2l0.3,0.1l0.4,0.3h0.2l0.4-0.2l0.8-0.7l0.3-0.2l0.3-0.1h0.3l0.3,0.1l0.7-0.1l0.2,0.1l0.2,0.1
                l0.2,0.2l0.2,0.2l0.1,0.2l0.2,0.8l0.2,0.2v0.2v0.2v0.5l-0.2,0.9l-0.1,0.3l-0.3,0.5l-0.4,0.2l-0.6,0.2l-0.3,0.2l-0.3,0.2v0.1l0.7-0.4
                l0.8-0.2l0.2-0.2l0.2-0.2l0.2-0.5l0.3-1.2l0.2-0.4l0.3-0.1l0.1,0.1l0.3,0.7v0.2l-0.1,0.2l-0.4,0.7l0.2-0.1l0.4-0.6l0.1-0.2l0.1-0.3
                l0.1-0.2l0.1,0.1l0.1,0.8v0.5v0.2v0.5l0.1,0.1l0.1-0.5v-0.3l0.1-0.2l0.1-0.1l0.5-0.5l0.6-0.4l0.4-0.3l0.3-0.2l0.5-0.2l0.3-0.3
                l0.1-0.5l0.1-0.3l0.1-0.2l0.3-0.3h0.2l0.2,0.1l0.2,0.2l0.2,0.4l0.1,0.3v0.2v0.7l0,0l0.2-0.6v-0.2V259l-0.1-0.2l-0.2-0.5l-0.1-0.3
                v-0.2l0.2-0.1h0.3l0.1-0.1l-0.2-0.2v-0.1l0.2-0.3h0.1l0.3,0.1l-0.1-0.2v-0.1h0.1l0.4,0.1v-0.1h0.4v-0.1l-0.3-0.2l-0.3-0.1l-0.1-0.1
                l-0.1-0.1l-0.1-0.3v-0.1h0.1l0.2,0.1l0.1-0.2l0.1-0.4l0.1-0.2l0.3,0.2v-0.1l-0.2-0.6v-0.1h0.4l0.2,0.1l0.6,0.5l0.1-0.1l-0.1-0.1
                l-0.3-0.3l-0.3-0.2l-0.2-0.1l-0.2-0.2l-0.2-0.4v-0.2v-0.2l0.1-0.5l0.1-0.1l0.1-0.1l0.2-0.1h0.2l0.4,0.3l0.1-0.1l-0.2-0.2l0.3,0.4
                l-0.1-0.2v-0.2l0.2-0.5l0.1-0.4l0.4-1.1l0.1-0.2l0.1-0.1l0.1-0.1h0.3l0.6,0.4l0.2,0.2v0.3l-0.3,0.4l-0.5,0.3l-0.2,0.1l0.1,0.1
                l0.5-0.3l0.4-0.1h0.3l0.3,0.5v0.7l-0.2,0.6l0.2-0.3l0.3-0.2l0.2,0.4v0.3l0.1,0.3l0.2,0.4l0.3,0.3l-0.3,0.4l-0.3,0.2l0.1,0.2l0.5,0.2
                l0.1,0.2v0.2h0.1l0.3-0.4h0.3l0.4,0.8l-0.3,0.4l-0.4,0.2l-0.3,0.1h-0.4l-0.2,0.1l0.1,0.1h0.4l0.6-0.1l0.5-0.2h0.2l0.2,0.1l0.1,0.1
                l-0.2,0.1l0,0l0.1,0.1l0.2,0.4v0.1l-0.2,0.2l0.3,0.1l0.4-0.1l0.1,0.1l0.2,0.5l0.1,0.5l-0.6,0.7l-0.3,0.1l-0.5,0.4l-0.1,0.3l-0.3,0.4
                h0.2l0.5-0.6l0.2-0.1h0.2l0.1,0.1v0.2h0.2l0.7-0.3l0.3-0.1h0.4v0.1l-0.2,0.8l-0.4,0.6l-0.7,0.4l-0.2,0.2l-0.3,0.4l0.1,0.1l0.7-0.5
                l0.5-0.2l0.6-0.2h0.3l0.5,1l0.3,0.1h0.2l0.4,0.3l0.2,0.3v0.2l-0.4,0.1l-0.1,0.2l0.2,0.5l-0.1,0.3l-0.3,0.3l-0.2,0.1h-0.2l-0.2,0.2
                h-0.1l-0.3-0.1l0.1,0.1l0.2,0.1h0.3l0.3-0.1h0.3l0.5,0.2l0.2,0.2v0.1l-0.1,0.1l-0.1,0.4l-0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.1l0.2-0.1
                l0.2,0.1l0.8,0.9v0.5l-0.1,0.4v0.4v0.5l-0.4,0.1l-1.5-0.2l-0.9-0.3v-0.1l0.2-0.2h-0.2l-0.2,0.1l-0.1,0.1l0.3,0.4l0.8,0.3l0.4,0.4
                h0.4l0.1,0.1l0.2,0.2l-0.1,0.1h-0.4l-0.3,0.3l0.2,0.2l0.7,0.1h0.5l0.2,0.1l-0.2,0.2l-0.6,0.2v0.3l0.4,0.1l0.4-0.1h0.2l0.1,0.7
                l0.1,0.1L303,273v0.1l0.3,0.1l0.5,0.1l0.2,0.1v0.2l0.1,0.1h0.3l0.3-0.3l0.2-0.2l0.2,0.1v0.3l0.3,0.3l0.1,0.1l0.1,0.4l0.2-0.4
                l0.2,0.1h0.2l-0.1,0.4l-0.1,0.3l0.2,0.2l0.1,0.3l0.3,0.4l-0.1,0.2l-0.4,0.4l-0.2,0.6v0.2l-0.2,0.3l-0.3,0.3h0.2l0.6-0.6l0.4-0.2
                l0.8-0.1l0.2-0.2l0.3-0.1l0.2,0.2v0.4l0.2,0.1l0.2-0.1l0.2,0.1l-0.1,0.2l-0.7,0.9l-0.2,0.4l-0.1,0.3l0.2-0.4l0.9-0.8l0.1-0.1
                l0.2-0.4l0.2-0.2l0.5,0.1l0.2,0.2l0.1,0.5l0.2,0.5l0.3,0.6l0.8,0.3h0.3l0.5-0.2l0.1-0.3l0.4-0.1h0.3l0.1,0.5l0.3,0.3l0.3,0.2
                l0.3,0.1h0.4l0.2,0.2v0.1l-0.2,0.3l-0.2,0.4l-0.4,0.3h-0.5l-0.7,0.2v0.1l-0.2,0.2l-0.4,0.2l-0.2,0.1l-0.3,0.6l-0.2,0.3H311h-0.3
                l-0.2,0.1l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.5,0.2l-0.8,0.5h-0.4l-0.3-0.1h-0.2l-0.1,0.1l-0.4,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.3v0.1
                L307,285l-0.3-0.1l-0.4-0.2l0.1,0.2l0.6,0.4l0.2,0.2l-0.2,0.2l-0.4,0.3v0.1l0.2,0.1l-0.1,0.1l-0.2,0.1v0.1v0.1l0.5-0.2l0.5-0.4
                l0.3-0.4l0.1-0.1l0.6-0.2l0.3-0.2l0.5-0.3l0.5-0.5l0.6-0.6l0.8-0.5l0.9-0.4l0.7-0.2h0.4v-0.1l-0.4-0.1h-0.3l-0.4,0.1l-0.1-0.2v-0.1
                l0.1-0.1l0.3-0.1l1.6,0.2l0.6,0.1l0.6,1.1l0.1,0.4v0.3l-0.1,0.2l-0.2,0.2l-0.7,0.4l-0.1,0.1v0.1l0.3,0.1l0.1,0.1l0.2,0.4l0.3-0.3
                l0.6-0.7l0.5-0.3l0.4-0.1h0.5h0.2l0.1,0.2l0.3,0.4l0.2,0.1l0.4,0.1l0.4,0.5l0.2,0.4l0.1,0.2v0.1v0.1l0.1,0.2l0.1,0.2v0.4l-0.2,0.6
                l0.2,0.6l-0.1,0.3v0.4l0.2,0.3v0.2l-0.1,0.1l-0.9,0.2h-0.4l-0.1,0.1h0.3h0.5l0.6,0.1l0.3,0.2l0.1,0.2v0.2l-0.2,0.1h-0.3l-0.3-0.1
                v0.1l0.5,0.3l0.1,0.1l0.3,0.2l0.1,0.2l-0.1,0.2l-0.9,0.9l-0.7,0.6l-0.7,0.5l-1.2,1h-0.1h-0.2l-0.6-0.2h-0.5l-0.9,0.2l-0.2,0.1
                l-0.5,0.3H313l-0.5,0.1H312h-0.2l-0.2,0.2l-0.1,0.1l-0.1,0.3l-1.2,1.3l-0.3,0.4l-0.6,0.5l-0.7,0.8l-0.6,0.3l-0.2,0.4l-0.5,0.3
                l-1,0.1l-0.5,0.1l-0.6-0.1l-0.4,0.2l-0.6,0.1h-0.3l-1.2,0.4l-0.3-0.4l-0.2-0.2h-0.7l-0.6-0.2h-0.5l-0.5-0.1h-0.3h-0.3h-0.5l-0.3,0.2
                l-1-0.1l-0.4-0.2h-0.3h-0.5l-0.4,0.2l-1-0.2l-1,0.1l-0.9-0.1l-0.2-0.1l-1.4,0.3l-0.5-0.2l-0.5,0.4l-0.3-0.1l-0.4,0.1l-0.1-0.1
                l-0.2,0.1l-0.2,0.2H289l-0.3,0.4l-0.6,0.3l-0.8,1.7l-0.1,0.6l-0.3,0.4l-0.3,0.1h-0.2l-1.4,0.3l-0.6,0.3l0.2,0.2l-0.2,0.2l-0.3,0.1
                l-0.4,0.2l-0.2,0.2l-0.1,0.3l-0.7,0.5l-0.8,1.1l-0.4,0.8l-0.5,0.6l-0.3,0.2h-0.2l-0.3-0.1l-0.4-0.3h-0.3l-0.8-0.4l-1.8-0.4l0.3,0.1
                l0.2,0.2l0.5,0.1h0.5l1,0.5l0.5,0.2l0.3,0.1l0.3,0.3l-0.2,0.6l-0.2,0.5l-0.2,0.4l-0.9,1l-0.4,0.3l-0.7,1.2l-0.7,0.5l-0.4,0.3
                l-0.4,0.5l-1,0.4l-0.4,0.1l-0.3-0.1l-0.4,0.3l-0.5,0.2l-0.1,0.3l-1.2,0.8l-0.5,0.1l-0.4,0.2l-0.1,0.4l-0.3,0.2l-0.1,0.2l-0.3,0.5
                l-0.5,0.6l-0.7,0.1l-0.2,0.2l-0.3,0.4l-0.4,0.2l-0.8-0.1l0.2,0.2l0.7,0.2l0.1,0.4l-0.3,0.1l-0.7,0.5L265.4,321.8L265.4,321.8z
                M193.6,180.7L193.6,180.7l-0.6-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.3v-0.4l0.1-0.2h0.3l0.3,0.2l0.4,0.5l-0.1,0.1v0.3L193.6,180.7
                L193.6,180.7L193.6,180.7z M231.2,183.8l0.3,0.1l0.3-0.1l0.2,0.1l0.1,0.2l0.1,0.2v0.3l-0.1,0.2l-0.5,0.2l-0.3-0.1l-0.4-0.2l-0.2,0.1
                l-0.4-0.2l-0.2-0.2l-0.2-0.3v-0.2l0.4-0.2l0.2-0.2L231.2,183.8L231.2,183.8z M201.6,187.7L201.6,187.7h0.2l0.1-0.3l0.1-0.1l0.1,0.1
                l0.4,0.5l0.3,0.5l0.3,0.4l0.5,0.3l1.1,1l0.3,0.7l0.3,1.1l0.3,0.8l0.3,0.5l0.3,0.4l0.5,0.5l0.4-0.4l0.2-0.1l0.2,0.1l0.1,0.4l-0.1,0.2
                l-0.2,0.2l-0.3,0.2h-0.4l-0.2,0.1l-0.3,0.4l-0.3,0.5l-0.4,0.2l-0.7,0.8l-0.4,0.3l-0.6,0.1l-1.1-0.6l-0.7,0.1l-0.6-0.1l-0.7-0.6
                l-0.5-0.4l-1-0.5l-0.1-0.1v-0.2v-0.3v-0.2l-0.1-0.1h-0.2l-0.2,0.2l-0.3,0.2h-0.5l-0.2-0.1l-0.2-0.2l-0.1-0.2v-0.2v-0.2l-0.1-0.1
                h-0.2l-0.3,0.1l-0.1,0.1l0.1,0.2v0.1h-0.5l-0.2-0.1l-0.3-0.3l-0.2-0.3l-0.2-0.6v-0.2l0.1-0.4l0.2-0.2l1-0.1l0.5-0.1l0.5-0.4l0.6-0.7
                l0.1-0.2v-0.2V190l-0.2-0.4l-0.1-0.3l0.1-0.1h0.2l-0.1-0.2l-0.1-0.2v-0.1v-0.2h0.2l0.2,0.1l0.5,0.6l0.2,0.1l0.3,0.1l-0.3-0.4
                l-0.4-0.9v-0.3v-0.2l0.1-0.5l0.1-0.2l0.1-0.1l0.3-0.3l0.5-0.2h0.3l0.3,0.2l0.2,0.3l0.5,0.5l0.1,0.2v0.1l-0.2,0.1v0.1L201.6,187.7
                L201.6,187.7z M252.2,186.5l0.1,0.5l0.1,0.1l-0.1,0.2l-0.4,0.4l-0.9,0.2l-0.5-0.2l0.2,0.6v0.3l-0.1,0.1h-0.2l-0.3-0.2l-0.2-0.2v-0.2
                l-0.1-0.1l-0.1,0.1h-0.1l-0.2-0.4l-0.1-0.1l-0.9-0.2v-0.1v-0.2l0.1-0.2l0.2-0.1l0.5,0.1v-0.1v-0.3v-0.1h0.4l0.2-0.1l0.1,0.2l0.1,0.4
                l0.2-0.1l0.3,0.1l0.3-0.1l0.4-0.3l0.3-0.1L252.2,186.5L252.2,186.5z M256.1,187.1l0.1,0.4v0.2l-0.2,0.2l-0.8,0.5l-0.5,0.4l-0.2,0.1
                l-0.3-0.1l-0.4,0.2h-0.1l0.2-0.3l0.6-0.9l0.5-0.1l0.2-0.2l0.1,0.1l0.1-0.2v-0.2l0.2-0.2h0.2L256.1,187.1L256.1,187.1z M284.5,188.5
                L284.5,188.5l-0.8-0.3l-0.1-0.3l0.4-0.3l0.3-0.2h0.2l0.2,0.1l0.2,0.4l-0.3,0.3L284.5,188.5L284.5,188.5z M84.9,188.2l-0.2,0.3
                l-0.4-0.3l-0.1-0.2l0.4-0.4h0.2l0.4,0.3l0.1,0.2L84.9,188.2L84.9,188.2z M207,188.2l0.4,0.5v0.3v0.4l-0.1,0.3l-0.2,0.2l-0.2,0.1
                h-0.3l-0.1-0.1l0.1-0.3l0.1-0.1v-0.3v-0.5l-0.1-0.3l-0.2-0.2h-0.1v0.2l0.1,0.3v0.4l-0.1,0.5l-0.1,0.2l-0.2-0.1l-0.1-0.2v-0.3v-0.3
                v-0.3l0.1-0.5l0.2-0.3l0.2-0.2h0.3l0.3,0.2L207,188.2z M191.2,189.7l-0.2,0.1l0,0v-0.2v-0.1l-0.1-0.1l0.2-0.2v-0.1L191,189l-0.3-0.2
                l-0.1-0.2v-0.2l0.1-0.1h0.2l0.4,0.3l0.2,0.4l0.1,0.3h-0.1l-0.1,0.1l-0.1,0.2L191.2,189.7z M259,191.7l-0.4,0.1h-0.3l-0.2-0.2
                l-0.1-0.3l-0.2-0.6l0.1-0.3v-0.5v-0.2l0.1-0.1l0.4-0.2h0.2l0.3,0.2l0.7,0.1l0.2,0.2v0.1v0.1v0.2l-0.3,0.5l-0.2,0.3l-0.1,0.4
                L259,191.7L259,191.7z M222,189.5l0.1,0.5l-0.3,0.7l-0.1,0.1h-0.1l-0.1-0.1l-0.3-0.5l-0.1-0.3l0.1-0.2l0.2-0.2l0.2-0.1l0.2,0.1
                l0.1-0.1L222,189.5L222,189.5z M252.8,194l-0.2,0.1l-0.2-0.1l-0.1-0.3v-0.3v-0.1l0.1-0.1l0.1-0.3l0.2-0.4l0.3-0.3l0.6-0.3l0.1-0.1
                l0.3-0.8l0.1-0.1l0.3-0.1v-0.1l-0.1-0.2v-0.2l0.1-0.2l0.1-0.2l0.4-0.2h0.4l0.1,0.1l0.1,0.1l0.1,0.4v0.1l-0.2,0.3l-0.4,0.5l-0.3,0.4
                l-0.1,0.1v0.2l-0.1,0.2l-0.3,0.5l-0.2,0.5l-0.2,0.3l-0.6,0.3L252.8,194L252.8,194z M221.1,191.1l-0.2,0.1l-0.1-0.3l-0.1-0.1
                l-0.1-0.2h-0.2v-0.3l0.1-0.2l0.2-0.1l0.2,0.1l0.2,0.3l0.1,0.3l0.1,0.3L221.1,191.1L221.1,191.1z M194.5,192.7l-0.1,0.5l-0.3-0.1
                l-0.2-0.2l-0.1-0.4v-0.1v-0.1l0.1-0.3l0.1-0.1l0.2,0.1l0.1,0.2l0.1,0.3L194.5,192.7L194.5,192.7z M193.8,194.3l-0.1,0.2l-0.1,0.1
                l-0.2,0.3h-0.1l-0.1-0.2l-0.1-0.3l0,0H193l-0.1,0.2h-0.1l-0.1-0.1v-0.3v-0.4l0.1-0.6V193v-0.2v-0.1l0.1-0.1l0.1-0.1l0.3,0.1l0.2,0.2
                l0.1,0.3l0.2,0.2l0.1,0.2L193.8,194.3z M189.3,196h-0.1l-0.2-0.1l-0.9-0.5l-0.1-0.1l0.1-0.2l0.3-0.3l0.2-0.3l0.2-0.4l0.5,0.2
                l0.2,0.2l0.1,0.2v0.2v0.6l-0.2,0.1L189.3,196L189.3,196z M264.9,197.8h-0.2l-0.3-0.5l-0.7-0.5l-0.3-0.4v-0.2v-0.3l0.1-0.4l0.2-0.4
                l0.2-0.1l0.4,0.1l0.3,0.3l0.3,0.8l0.2,0.4l0.1,0.3l-0.1,0.1v0.1v0.1v0.1l-0.1,0.2L264.9,197.8L264.9,197.8z M181.7,197.4h-0.2
                l-0.3-0.1l-0.4-0.3l-0.3-0.3l-0.2-0.3v-0.2l0.2-0.1l0.2-0.1h0.6l0.3,0.1l0.4,0.4l0.1,0.3v0.2v0.1L181.7,197.4z M253.4,199l-0.2,0.1
                l-0.3-0.4v-0.2l0.1-0.5l0.6-0.1l0.2,0.3l0.1,0.3L253.4,199L253.4,199z M262.7,198l1.5,0.7l0.1,0.2l0.1,0.2v0.2v0.5v0.2l-0.1,0.5v0.1
                l0.1,1.6v0.9l-0.1,0.7l-0.2,0.6l-0.3,0.4l-0.2,0.2l-1.1,0.6l-0.8,0.2h-0.8l-1,0.2h-0.5l-0.2-0.1l-0.2-0.1l-0.2-0.4l-0.2-0.6
                l-0.2-0.7l-0.2-1.3v-0.2l0.2-1.1l0.3-0.7l0.5-1.1l0.6-1.1l0.1-0.2l0.3-0.2l0.6-0.3l0.5,0.1h0.2l0.3-0.1h0.3L262.7,198L262.7,198z
                M232.1,202.4H232l-0.2-0.1l-0.4-0.4l-0.1-0.2v-0.2v-0.3v-0.3l0.1-0.5l-0.3-0.4l-0.1-0.2v-0.1l0.1-0.3v-0.2l0.1-0.3l0.4-0.6l0.4,0.1
                l0.3,0.5l0.1,0.3v0.3v0.5l0.1,0.6v0.5v0.3l-0.1,0.5l-0.1,0.2L232.1,202.4L232.1,202.4z M168.3,200.5h-0.1l-0.4-0.4l0.1-0.4l0.4,0.4
                l0.1,0.2L168.3,200.5z M268.5,202.1h-1.4l-0.7-0.1l-0.3-0.1l-0.3-0.2l-0.3-0.6l-0.2-0.6v-0.3V200l0.1-0.1l0.9-0.2l0.8,0.2l0.6,0.3
                h0.8l0.3,0.1l0.1,0.1l0.1,0.1l0.1,0.7v0.4v0.3L268.5,202.1L268.5,202.1z M168.7,200.6l0.3,0.1l0.2-0.1l0.2,0.3l0.1,0.3v0.1h-0.1
                l-0.5-0.3l-0.2-0.2V200.6L168.7,200.6L168.7,200.6z M172.3,204.9l-0.1,0.6l-0.1-0.1l-0.1-0.3l-0.2-0.1l-0.2-0.3V204l0.1-0.3v-0.4
                l0.2-0.3l0.2,0.3v0.5v0.3l0.2,0.3l0.1,0.1v0.2L172.3,204.9L172.3,204.9z M299.2,207.4l-0.3,0.2l-0.2-0.2l0.1-0.1l0.2-0.3l0.3-0.3
                l0.1-0.2l0.4-0.1h0.1l0.1,0.1l-0.2,0.3L299.2,207.4L299.2,207.4z M171.7,207.7l0.4,0.1h0.5l-0.1,0.6l-0.2,0.4l-0.1,0.1l-0.1-0.2
                l-0.3-0.4L171.7,207.7L171.7,207.7z M241.8,212.8l0.3,0.1h0.2v0.1l-0.2,0.3l-0.1,0.1l-0.2-0.2l-0.2-0.3l-0.1-0.2v-0.2l0,0
                L241.8,212.8z M240.1,216.2l0.4,0.3l0.4,0.2l0.6,0.1l0.1,0.1v0.1l-0.1,0.2l-0.2,0.2h-0.1l-0.3-0.2l-0.1-0.1l-0.1-0.2h-0.1l-0.1,0.1
                v0.1v0.1h-0.1l-0.4-0.1l-0.1-0.1v-0.2l0.3-0.3l-0.2-0.1l-0.1-0.1l-0.4,0.2h-0.2l-0.3-0.1v-0.7v-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.1
                l-0.3-0.1l-0.2-0.4v-0.2v-0.1l0.1-0.2l0.8,0.4l0.5,0.3l0.5,0.4l0.2,0.3v0.2l-0.1,0.2l-0.1,0.2L240.1,216.2L240.1,216.2z
                M237.5,217.7l-0.1,0.1h-0.2l-0.1-0.2l-0.1-0.4l-0.2-0.3l-0.4-0.3l-0.1-0.1l-0.1-0.4v-0.4l-0.1-0.4v-0.2l0.1-0.3l0.3-0.1l0.3,0.1
                l0.1,0.1l0.1,0.2l0.1,0.2l0.3,0.6l0.2,0.4l0.2,0.9v0.2l-0.1,0.2L237.5,217.7L237.5,217.7z M236.8,219.8L236.8,219.8h0.2l0.2-0.3
                l0.4-1l0.1-0.1h0.2l0.7,0.6l0.2,0.3l0.1,0.5l0.1,0.2l0.5,0.2l0.5,0.1l0.6,0.2l0.2,0.2l0.5,0.9l0.1,0.1l0.6,0.4l0.9,0.8l0.2,0.1
                l0.9,0.3l0.3,0.2l0.3,0.3l0.3,0.6l0.4,0.9l0.3,1.4v0.3v0.2l-0.1,0.2l-0.3,0.5v0.1h0.5l1.1-0.3l0.6,0.2h0.2h0.1l0.2-0.4l0.3,0.1
                l0.4,0.4l0.2,0.3l0.1,0.3v0.1l-0.2,0.1l0.6,0.2l0.5,0.4l-0.1,0.3l-0.6,0.6l-0.6,0.5l-0.7,0.7l-0.2,0.1h-0.1l-0.4-0.1l-0.5-0.3
                l-1.7-0.8l-0.5-0.2l-0.7-0.1l-0.1-0.2l-0.2-1.2l-0.3-0.2l-1-0.3l-0.3-0.1v-0.2l0.1-0.4l-0.1-0.2h-0.3l-0.3,0.1l-0.5,0.3l-0.3,0.3
                l-0.1,0.3l-0.1,0.6l-0.1,0.3l-0.2,0.4l-0.8,1l-0.3,0.3l-0.3,0.1l-0.1,0.1l-0.2,0.4l-0.3,0.9l-0.1,0.2l-0.2,0.3l-0.5,0.4l-0.5,0.3
                l-0.8,0.4l-0.4,0.1l-0.3-0.1l-0.2-0.8l-0.4-2.4l-0.1-0.2l-0.1-0.1h-0.1l-1.4,0.3H232l-0.8,0.5H231h-0.4l-0.3-0.1l-0.1-0.1v-0.2v-0.2
                l0.1-0.3l0.3-0.7l0.3-0.5l0.1-0.1l1.3-0.8l0.3-0.3l0.2-0.3v-0.8l-0.1-0.4l-0.2-0.9l-0.1-0.8v-0.4l0.1-0.6l0.3-1.5l0.1-0.8l0.2-2.7
                l0.1-0.8l0.2-0.7l0.2-0.4l0.4-0.9l0.3-0.4l0.4-0.2h0.1l0.1,0.1l0.1,0.4l0.6,0.3l0.2,0.3l0.1,0.4l0.1,0.5l-0.1,0.2l-0.3,0.3v0.1v0.1
                l0.5,0.5L236.8,219.8L236.8,219.8z M257.2,228.1l-0.2,0.3l-0.6-0.1l-0.1-0.1v-0.1l0.1-0.1l0.6-0.1h0.3h0.1v0.1L257.2,228.1
                L257.2,228.1z M259.9,231.9l-0.3,0.1l-0.4-0.1l-0.4-0.3l-0.9-0.9l0.6-0.6l1,0.7l0.3,0.4L259.9,231.9L259.9,231.9z M256.5,231.4
                l0.2,0.3h0.2l0.1,0.2l0.3,0.7v0.1v0.2l-0.2,0.3l-0.2,0.1l-0.4,0.2l-0.4,0.1l-0.2-0.2l-0.6-0.6l-0.6-0.7l-0.2-0.4l0.1-0.2l0.3-0.1
                l0.5-0.1l0.8,0.1L256.5,231.4L256.5,231.4z M245,234.6l0.1,0.2v0.3v0.3l-0.1,0.3l-0.1,0.3l-0.2,0.5l-0.8,0.8l-0.3,0.4l-0.2,0.3
                l-1.3,1.2l-0.2,0.1h-0.2l-0.3-0.2h-0.3l-0.9,0.5v-0.1V239l-0.1-0.3l-0.4-0.6V238v-0.2v-0.1l0.4-0.6l1-2.1l0.2-0.1l0.5,0.2l0.2,0.1
                h0.2l0.7-0.4l0.7,0.1l0.6-0.3h0.3h0.2L245,234.6L245,234.6z M277.7,237.1h-0.2h-0.4l-0.4-0.1l-0.2-0.1l-0.2-0.3v-0.3l-0.4-0.5
                l-0.4-0.2l-0.2-0.4h0.2l0.3,0.1l0.5,0.2l0.5,0.2l0.6,0.4l0.2,0.4l0.2,0.3l0.1,0.2l-0.1,0.1L277.7,237.1L277.7,237.1z M267.4,236.7
                l-0.1,0.1l-0.6-0.1l-0.7-0.3l-0.4-0.3V236h0.2h0.2l0.3,0.2l0.8,0.1l0.3,0.1l0.1,0.2L267.4,236.7L267.4,236.7z M293.2,237.1l0.5,0.1
                h0.3l0.1,0.1l0.1,0.3L294,238l-0.2,0.2h-0.3l-0.5-0.1l-0.2-0.1l-0.2-0.2l0.1-0.2l0.2-0.1v-0.1l-0.1-0.1L293.2,237.1L293.2,237.1
                L293.2,237.1z M251.9,238l0.2,0.2l0.4,0.5l0.1,0.3v0.4l-0.1,0.5v0.5l-0.1,0.3l-0.3,0.4l-0.2,0.5l-0.2,0.6l-0.2,0.4l-0.1,0.2
                l-0.1,0.1h-0.1l-0.2-0.2l-0.3-0.4l-0.2-0.3l-0.3-0.2l-0.2-0.2v-0.2v-0.8v-0.4v-0.3l0.1-0.3l0.2-0.4l0.4-0.8l0.3-0.3l0.2-0.1l0.4,0.1
                h0.2L251.9,238L251.9,238L251.9,238z M292.6,241.1L292.6,241.1h0.1l0.1,0.1v0.2l0.1,0.1l0.2,0.2l0.1,0.1v0.1l-0.1,0.2H293l-0.7-0.4
                l-0.2-0.4v-0.2l0.1-0.2l0.1-0.1h0.1l0.2,0.1L292.6,241.1L292.6,241.1z M214,241.4h-0.1l-0.3-0.3l-0.1-0.2l0.3-0.1l0.2,0.3v0.1
                L214,241.4L214,241.4z M293.1,244.2L293.1,244.2l-0.1,0.1l-0.2-0.3l-0.3-0.1l-0.1-0.1l-0.8-0.6l-0.1-0.3v-0.2l0.3-0.1l0.6-0.1h0.5
                l0.5,0.1l0.1,0.1l0.2,0.3l-0.1,0.3v0.4l-0.1,0.2l-0.2,0.1L293.1,244.2L293.1,244.2z M254.7,247.9l-0.4,0.1v-0.1l0.2-0.2l0.6-0.2
                l0.4-0.1l-0.1,0.2l-0.3,0.2L254.7,247.9L254.7,247.9z M283.6,250.6h-0.3l-0.1-0.1v-0.3l0.1-0.3l0.3-0.5l0.3-0.6l0.2-0.1l0.3,0.1
                l0.2,0.2l0.2,0.3l0.1,0.2l-0.1,0.3l-0.2,0.3l-0.3,0.2L283.6,250.6L283.6,250.6z M294.3,249.9l-0.1,0.4l-0.3-0.1l-0.5-0.3l-0.2-0.2
                l-0.1-0.2v-0.3h0.2h0.4l0.3,0.4l0.1,0.1L294.3,249.9L294.3,249.9z M250.4,253.3h-0.3l0.1-0.3l0.1-0.2l0.1-0.1h0.2l0.2,0.1l-0.1,0.2
                L250.4,253.3L250.4,253.3z M249.8,254.1L249.8,254.1l-0.1-0.1l0.1-0.2h0.1l0.1-0.3l0.1,0.1v0.1l-0.2,0.2L249.8,254.1z M281,257.3
                l-0.2,0.4h-0.2l-0.1,0.1h-0.1l0.1-0.4v-0.2l-0.1-0.3l0.1-0.1h0.3v0.3v0.1h0.1L281,257.3L281,257.3z M251.4,265.4L251.4,265.4h-0.2
                v0.3l0,0l-0.1-0.2l0.1-0.2v-0.1v-0.1l0.1-0.2h0.1l0,0v0.3L251.4,265.4z M301.8,265.2l0.2,0.2l0.1,0.6h-0.4l-0.5-0.4l-0.1-0.3l0,0
                h0.1l0.1,0.1l0.1-0.1h0.1L301.8,265.2L301.8,265.2z M250.9,269.2L250.9,269.2h-0.2v-0.3l0.1-0.2h0.1l0.1,0.1v0.1L250.9,269.2z
                M251.9,269.8L251.9,269.8l-0.2,0.1l0.1-0.5l-0.1-0.2v-0.1V269l0,0l0.1,0.1l0.1,0.1v0.1v0.1L251.9,269.8L251.9,269.8L251.9,269.8z
                M253.6,271.8l-0.2,0.5l-0.2,0.5l-0.3,0.9h-0.1l-0.1-0.2l0.4-1.1v-0.1v-0.1L253,272l-0.1,0.2l-0.5,1.2l-0.1,0.2l-0.1,0.1h-0.1h-0.2
                l-0.4,0.3l0.8-1.4v-0.1l-0.1-0.1h-0.1l-0.6,0.9l-0.3,0.3l-0.2-0.1l-0.1-0.1v-0.1l0.6-0.9l0.5-0.7l0.2-0.4l0.1-0.4v-0.3v-0.3v-0.1
                l0,0v0.1v0.3l-0.1,0.7l-0.1,0.3l-0.1,0.3l0.1,0.1l0.2-0.2l0.2-0.3l0.1-0.5l0.1-0.4l0.1-0.7l0,0v0.1l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.2
                l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.3v0.1v0.1h0.1L253.6,271.8z M250.6,272.1h-0.1l-0.2-0.1l0.1-0.4l0.1-0.2l0.4-0.2l0.1-0.1h0.1h0.2
                l0.2-0.1l0.2-0.3h0.1l-0.1,0.4l-0.2,0.3L250.6,272.1L250.6,272.1z M253.9,272.4l-0.1,0.1h-0.1v-0.2l0.1-0.3l0.1-0.3v-0.3l0.1-0.2
                l0.1-0.2l0.1-0.1h0.2v0.6v0.3l-0.1,0.2l-0.1,0.2L253.9,272.4L253.9,272.4z M303.9,273v0.1l-0.4-0.1l-0.2-0.1v-0.1v-0.1l0.1-0.1
                l0.2-0.1l0.1,0.1l0.2,0.2v0.1L303.9,273L303.9,273z M108.6,279.2l0.2-0.3l0.5-0.5l0.3-0.4v0.4l-0.4,0.6l-0.5,0.3L108.6,279.2z
                M107.6,280.6L107.6,280.6L107.6,280.6l-0.1-0.3l0.1-0.4v-0.1l0.4,0.1h0.1v0.1v0.1l-0.1,0.2L107.6,280.6L107.6,280.6z M102.8,282.3
                l0.3,0.3l0.6-0.2h0.1l0.1,0.1l0.1,0.2l0.1,0.3v0.4l-0.1,0.1l-0.1,0.1l-1,0.6v0.1v0.1l0.1,0.1h0.2l0.8-0.1v-0.1l0.1-0.5l0.1-0.3v-0.2
                l-0.1-0.5v-0.2h0.5l0.3-0.2l0.3-0.3h0.1l-0.1,0.6v0.2l-0.3,0.7l-0.2,0.6l-0.1,0.6v1l-0.1,0.3l-0.2,0.2l-0.9,0.4h-0.5l-0.4-0.3
                l-0.2-0.2l0.2-0.3h0.1l0.3,0.1l0.2,0.1h0.1V286l-0.7-0.5l-0.5-0.2l-0.1-0.3v-0.2v-0.1l-0.4-0.7l-0.1-0.3l-0.1-0.4v-0.4l0.1-0.7v-0.1
                h0.2l0.3,0.1L102.8,282.3L102.8,282.3z M109.6,283.1l-0.1,0.2l-0.2,0.3l-0.1,0.1l-0.1-0.1h-0.2l-0.2-0.3l-0.1-0.1h-0.1v0.1v0.1v0.2
                v0.1h-0.1l-0.1-0.1l-0.1-0.1v-0.1v-0.1l0.1-0.2l0.4-0.4l0.1-0.1h0.1l0.2,0.2v0.1L109.6,283.1L109.6,283.1z M110.7,286.9L110.7,286.9
                h-0.2l-0.6-0.8l-0.4-0.3l-0.3-0.4l-0.3-0.3l0.2-0.4l0.2,0.1l0.6,0.3l0.4,0.3l0.3,0.2l0.5,0.9v0.1L110.7,286.9L110.7,286.9z
                M250.7,286.2v0.1h-0.2l-0.1-0.1l-0.1-0.1V286l0.1-0.1l0.2,0.1L250.7,286.2L250.7,286.2z M112.6,287.1L112.6,287.1h-0.3h-0.1
                l-0.1-0.1v-0.1v-0.2l0.2-0.3v-0.1v-0.1l0.1,0.2l0.1,0.2C112.6,286.4,112.6,287.1,112.6,287.1z M114.3,287.9l0.1,1.5v0.5l-0.2,0.3
                l-0.1,0.5l-0.2,0.2l-0.1-0.3v-0.5v-0.4v-0.2l0.1-0.8l-0.1,0.1l-0.2,0.3h-0.2l-0.3-0.4l-0.2-0.3v-0.3l-0.2-0.3v-0.1v-0.1l0.2-0.3
                l0.1-0.2l0.1-0.5l0.1-0.2h0.2l0.3,0.2l0.3,0.2l0.3,0.3L114.3,287.9L114.3,287.9z M105.3,286.7l0.3,0.4l0.1,0.4v0.5l-0.5,0.2
                l-0.2-0.1h-0.1l-0.2,0.1l0.2,0.1l0.3,0.2l0.2,0.3l0.3,0.1l0.4,0.2l-0.3,0.4l-0.1,0.2l0.4,0.6v0.1h0.1h0.3l0,0v0.1l-0.2,0.3v0.1
                l0.2,0.1h0.3l0.1,0.3l-0.3,0.3l-0.6-0.4l-0.2-0.4l-0.1-0.4l-0.2-0.2l-0.5-0.4l-0.8-1l-0.2-0.1l-0.2-0.4l-0.1-0.2V288l0.1-0.1h0.2
                v-0.2l-0.9-0.3l-0.1-0.1l-0.1-0.2h0.1h0.5l0.5-0.1l0.3-0.1l0.1-0.1l0.3-0.1h0.1L105.3,286.7L105.3,286.7z M248.5,288.8l-0.2,0.1
                l-0.6-0.1l-0.2-0.1l-0.7-0.3l-1.4-0.5l-0.5-0.2l-0.1-0.2l0.2-0.4l0.1-0.2l0.2-0.1l1.4-0.2l0.6,0.1l0.7,0.8l0.4,0.5l0.2,0.4v0.2
                L248.5,288.8L248.5,288.8z M112.2,287.7L112.2,287.7H112l-0.1-0.1l-0.2-0.3l-0.1-0.1V287l0,0h0.1h0.1l0.3,0.6L112.2,287.7
                L112.2,287.7z M113.2,289.9l-0.1,0.2l-0.4-0.5l-0.1-0.1l-0.3-0.5v-0.2v-0.1l0,0h0.1l0.1,0.1l0.5,0.6l0.2,0.3L113.2,289.9
                L113.2,289.9z M114.8,290.4l-0.2,0.1l0.1-0.2v-0.1v-0.1v-0.3v-0.6l0.2-0.4h0.3v0.2l-0.1,0.9l-0.1,0.4l-0.1,0.2L114.8,290.4
                L114.8,290.4z M107.4,292.4L107.4,292.4l-0.1-0.1l-0.1-0.2v-0.4v-0.2v-0.1l0.2,0.3V292.4L107.4,292.4z M252.3,292.5h-0.1h-0.3
                l-0.2-0.1l-0.1-0.1l0.9-0.4l0.2,0.1v0.1l-0.1,0.2v0.1L252.3,292.5L252.3,292.5L252.3,292.5z M319.7,292.7h-0.1v-0.1l0.1-0.2l0.1-0.2
                l0.2-0.1l-0.1,0.3L319.7,292.7L319.7,292.7z M116.1,294.6L116.1,294.6l-0.2,0.1l-0.2-0.1l-0.1-0.2l-0.2-0.5v-0.1l0.1-0.1l0.3-0.2
                h0.1v0.1l0.2,0.3v0.1v0.4L116.1,294.6L116.1,294.6z M319.5,294.3l-0.2,0.4l-0.1,0.2l-0.1,0.1l-0.3,0.1l-0.6,0.1l-0.3,0.1v0.3v0.2
                l0.1,0.1h0.1l0.2-0.1h0.1l0.1,0.1l0.1,0.1v0.2v0.2v0.2l-0.2,0.6l-0.3,0.3l-0.3,0.3l-0.1,0.1v0.1v0.4l-0.2,0.3l-0.5,0.7l-0.2,0.2v0.1
                l-0.1,0.3l-0.2,0.3l-0.4,0.6l-0.1,0.2v0.2v0.3v0.1l-0.1,0.2l-0.1,0.2v0.1l0.1,0.2l0.1,0.1v0.2v0.3l0.2-0.2l0.4-0.6l0.3-0.4l0.2-0.1
                l0.1-0.2l0.2-0.3l0.2-0.3l0.2-0.1l0.1,0.1l0.1,0.2v0.2l-0.1,0.3v0.1l0.2-0.2l0.4-0.2h0.1l0.3,0.2h0.3l0.4-0.1l0.1,0.1l-0.1,0.2
                l-0.2,0.2l-0.4,0.3l-0.9,0.5l-0.3,0.4h0.1l0.2-0.2l0.2-0.1h0.2h0.1v0.1v0.3l-0.5,0.6h0.1l0.6-0.3l0.4,0.3l0.5-0.1l0.3-0.1v0.1
                l0.1,0.2v0.2l0,0l0.2-0.1v-0.1v-0.4l0,0l0.1,0.1l0.1,0.1v0.3l-0.1,0.3l-0.1,0.3l-0.2,0.4v0.2l-0.1,0.2h0.1l0.2-0.2v-0.1v-0.2v-0.1
                l0.2-0.2l0.3-0.2h0.1v0.1v0.1h0.1l0.2-0.2l0.2-0.1h0.2l0.2-0.1l0.2-0.2l0.2-0.2l0.2-0.2h0.1v0.3v0.3v0.3v0.1l0.2-0.3l0.1-0.1h0.1
                h0.1l0.9-0.1l0.3,0.1l0.3,0.2l0.4,0.3l0.1,0.2v0.3v0.2l-0.3,0.3l-0.2,0.2l-0.1,0.2v0.2l-0.1,0.1l-0.2,0.2l-0.7,0.4h0.2l0.4-0.1h0.3
                v0.1l-0.1,0.1l-0.2,0.1v0.1v0.1l0.2,0.1h0.3l0.2,0.1v0.1l-0.2,0.3l-0.1,0.2l-0.3,0.2l-0.5,0.3l-0.1,0.1l0,0l0.5-0.2l0.2-0.1h0.1
                l0.2-0.2l0.3-0.1l0.3,0.1l0.4-0.3h0.1h0.2l0.2-0.1l0.3-0.2l0.2-0.1l0,0v0.1v0.3v0.2l-0.1,0.1l-0.2,0.3l-0.1,0.1H326h-0.2l-0.1,0.1
                l-0.2,0.3l-0.4,0.3l-0.2,0.1l0.1,0.1l0.1,0.3L325,310l-0.4,0.1l0,0l-0.1,0.1l-0.3,0.1h0.2l0.4-0.1l0,0l-0.1,0.2l-0.1,0.2l-0.5,0.5
                v0.1l0.1,0.2l0.1,0.2l0.1,0.1h0.3l0.2-0.1l0.3-0.3l0.6-1.1l0.6-0.3l0.5-0.3l0.1,0.1v0.1v0.1l-0.2,0.3l-0.1,0.2l-0.3,0.7l-0.1,0.3
                l-0.1,0.3v0.6v0.1l0.1,0.1l0.2-0.1l0.3-0.3l0.2-0.3l0.2-0.5l0.1-0.2h0.1l0.1,0.1v0.2l0.1,0.3l0.1,0.3v0.3v0.2l-0.6,1.3l0.1,0.2v0.1
                v0.2l-0.2,0.6l-0.2,0.4l-0.1,0.2l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1h-0.1h-0.2l-0.4,0.3h-0.1h-0.1l-0.1-0.2l0.1-0.9v-0.3
                l-0.1-0.2l0.1-0.4v-0.1l-0.1-0.1h-0.1l-0.2,0.2l-0.2,0.3l-0.2,0.3l-0.4,0.4l-0.2,0.1h-0.1l-0.1-0.1l-0.1-0.2v-0.2v-0.2l0.2-0.5
                l0.3-0.7l0.3-0.5l0.1-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.6l-0.1-0.5l-0.2-0.2l-0.4-0.2l-0.1,0.1v0.3l-0.5,0.9l-0.1,0.4l-0.1,0.1l-0.1,0.1
                l-0.2,0.1l0.1-0.2l0.2-0.5l0,0l-0.3,0.4l-0.2,0.2l-0.3,0.1h-0.2l-0.2,0.1l-0.7,0.9v0.3l-0.1,0.2l-0.3,0.4l-0.2,0.1H320l-0.2-0.1
                h-0.1l-0.3,0.1l-0.4,0.1h-0.2l-0.1-0.1l-0.2-0.2v-0.1v-0.1l0.1-0.2l0.2-0.2l0.2-0.1l0.5-0.1l0.3-0.2l0.3-0.2l0.1-0.2l0.5-0.8
                l0.6-0.3l0.3-0.2l0.2-0.3v-0.1l-0.3,0.1h-0.2l-0.2-0.1l-0.1-0.1h-0.3h-0.5l-0.1,0.1l-0.1,0.4l-0.1,0.2l-0.1,0.1h-0.1h-0.2l-0.6-0.1
                l-0.1-0.1l-0.1-0.1l-0.6,0.1H318l0.1-0.1l0.6-0.3l0.1-0.8v-0.1l-0.2,0.1l-0.3,0.1h-0.2l-0.1-0.1l-0.1,0.1l-0.2,0.4l-0.1,0.1h-0.2
                L317,311l-0.7,0.1l-0.1,0.1h-0.5l-1.5-0.2h-0.5l-0.6-0.1l-0.1-0.1h-0.9h-0.3v-0.2l0,0l-0.3,0.2l-0.2,0.1L311,311l-0.9,0.2h-0.5
                l-0.3-0.1l-0.1-0.1l-0.2-0.4l-0.1-0.5V310l0.1-0.2l0.2-0.3l0.9-0.7l0.7-0.7l0.3-0.4l0.3-0.1l0.5-0.3l0,0h-0.5l-0.3-0.1h-0.3
                l-0.6,0.1h-0.6v-0.2l0.3-0.3l0.6-0.5h0.1l-0.2,0.2l-0.1,0.2l0.1,0.1l0.1,0.1h0.4l0.1-0.1l0.1-0.5l0.3-0.6l0.1-0.5l0.3-0.3l0.1-0.1
                l0.1,0.1l0.4,0.1l0.4,0.3h0.1l0,0l-0.1-0.1l-0.1-0.1V305l0.1-0.4l0.2-0.1v-0.1h-0.3l-0.3-0.1l-0.1-0.2v-0.4l0.1-0.2l0.2-0.3l0.3-0.2
                h0.2l0.3,0.2l0.2-0.1V303l-0.3-0.4l-0.1-0.3v-0.1l0.6-1.4l0.3-0.8l0.4-1.2l0.1-0.2l0.2-0.3l0.1-0.1h0.3h0.2l-0.2-0.2l-0.1-0.1v-0.1
                l0.1-0.1l0.1-0.1l0.3-0.2l0.2-0.3l0.1-0.4v-0.1l-0.1-0.1v-0.1l0.2-0.1l0.4-0.4l0.1-0.1l0.2-0.6l0.2-0.3l0.2-0.1l0.3-0.2l0.9-0.4
                l0.5-0.4h0.3l0.1,0.2l0.5,0.2l0.1-0.2l-0.1-0.2l0.1-0.1l0.4-0.1h0.1l0.1,0.1L319.5,294.3L319.5,294.3z M118.1,298.3l1.4,0.5l1.4,0.3
                l1,0.3l0.6,0.1l0.2,0.1l0.1,0.1l0.2,0.3l0.3,0.6l0.2,0.4l0.5,0.7l0.4,0.5l0.1,0.2l-0.1,0.1v0.1l0.3,0.5l0.5,0.4l0.4,0.2l0.9,0.3
                l0.5,0.3l0.2,0.2l0.2,0.2l0.1,0.2l0.2,0.6l0.4,0.5l0.4,1l0.1-0.1v-0.3v-0.1h0.1l0.1,0.1l0.1,0.3l0.2,0.6l-0.1,0.2h-0.1l-0.3-0.1
                l-0.1,0.1l-0.1,0.2l-0.1,0.1h-0.1l-0.9-0.2l-0.6-0.2l-0.7-0.3l-0.9-0.3l-0.5-0.2l-0.4-0.2l-0.3-0.2l-0.1-0.2v-0.1l0.6-0.6l0.2-0.3
                l0.1-0.2v-0.2v-0.3l0,0l-0.1,0.3l-0.1,0.3l-0.1,0.2l-0.1,0.1l-0.7,0.1h-0.5l-0.3,0.2h-0.1l-0.2-0.1l-0.3-0.3l-0.5-0.3v-0.1l0.3-0.1
                l0.2-0.2l0,0h-0.1H122l-0.2-0.3l-0.1-0.1l-0.2,0.1h-0.1l-0.1-0.2l0.1-0.4v-0.1l-0.2,0.1h-0.1l-0.1-0.1l-0.1-0.1h-0.2l-0.2-0.1h-0.1
                v0.2h-0.1l-0.3-0.3h-0.1l-0.2,0.2h-0.1l-0.1-0.1v-0.5v-0.2v-0.1l0.3-0.1l0.8-0.1l0.1-0.1h-0.6l-0.2-0.1l-0.2-0.2h-0.2l-0.1-0.1
                l-0.1-0.1l-0.3-0.5l-0.2-0.1l-0.3-0.1l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.2l-0.1-0.1l-0.1-0.2v-0.2l0.1-0.3v-0.1v-0.1
                l-0.1-0.1l-0.1-0.1h-0.1v0.1l-0.1,0.1l-0.2,0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1H116l-0.3,0.2h-0.1h-0.1V300l0.1-0.3v-0.4v-0.1l-0.3-0.1
                V299l0.2-0.4l0.1-0.1h0.1h0.5l0.2,0.1l0.3,0.3v-0.1l-0.1-0.3v-0.2l0.2-0.2l-0.2-0.1H116v0.1l0.1,0.2l0.1,0.6h-0.3h-0.3l-0.2-0.1
                l-0.4-0.4l-0.2-0.4v-0.2l0.1-0.2l0.2-0.1l0.4-0.1h0.5l0.6,0.2L118.1,298.3L118.1,298.3z M319.2,298L319.2,298H319l-0.1-0.1v-0.1
                l0.1-0.2l0.2-0.1h0.2v0.1L319.2,298L319.2,298z M123.8,300.7v0.2l-0.2-0.4l-0.3-0.8v-0.2l0.1-0.3h0.1l0.2,0.1l0.2,0.2v0.1l0.1,0.2
                v0.2l-0.1,0.2C124,300.4,123.8,300.7,123.8,300.7z M124.3,301L124.3,301l-0.1-0.5l0.1-0.2v-0.1v-0.1l0.1,0.2l0.1,0.1v0.2v0.1
                L124.3,301L124.3,301z M301.7,305l-1.2,0.1l-0.9-0.3l-0.7-0.1l-0.7-0.2l-1.5-0.7l-0.2-0.3l-0.1-0.3l-0.3-0.3l-0.3-0.2l-1.6-0.8
                l-0.1-0.3l0.3-0.2l0.4-0.1h0.3l1,0.3l1.3,0.3l0.6,0.2l0.6,0.3l0.6,0.4l1.4,0.9l0.3,0.1l0.6,0.5l0.2,0.3l0.1,0.3L301.7,305L301.7,305
                L301.7,305z M119.7,302.8L119.7,302.8l-0.3-0.1l-0.2-0.1l-0.3-0.3v-0.1v-0.1l0.1-0.1v-0.1l-0.1-0.2l0.3-0.1l0.2,0.1l0.1,0.2l0.1,0.3
                l0.1,0.3L119.7,302.8L119.7,302.8z M126.7,303.2L126.7,303.2l-0.6-0.2l-0.3-0.2l-0.1-0.1l-0.1-0.1l-0.3-0.2l-0.1-0.1l0.1-0.1h0.2
                l0.4,0.2l0.3,0.3C126.2,302.6,126.7,303.2,126.7,303.2z M323.3,302.2l0.2,0.3l0.1,0.1l-0.7,0.3h-0.1l0,0v-0.3v-0.2h0.1l0.2,0.1
                L323.3,302.2L323.3,302.2L323.3,302.2z M322,302.9l-0.4,0.3l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.3v-0.1h0.1h0.1v0.1h0.1h0.1l0.3-0.3h0.2
                h0.1L322,302.9z M121.2,303.9l0.1,0.4l-0.3-0.1l-0.1-0.1v-0.2l0.1-0.2L121.2,303.9L121.2,303.9L121.2,303.9z M286.7,321.2h-0.1h-0.1
                h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1V321l0.1-0.1v-0.1l-0.1-0.2l-0.1-0.1v-0.1v-0.1l0.1-0.1
                l0.1-0.1V320l-0.1-0.1l-0.2-0.1h-0.1h-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.1h-0.1l-0.1-0.1v-0.1v-0.2V319v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1
                v-0.1v-0.4v-0.7v-0.5V316v-0.5v-0.7v-0.6v-0.6l-0.4-0.3l-0.5-0.4l-0.4-0.3h-0.2H283l-0.1,0.1l-0.3,0.1l-0.5,0.1l-0.5,0.2h-0.2h-0.1
                l-0.2-0.1l-0.1-0.2v-0.3v-0.4l-0.3-0.1l-0.3-0.1l-0.2,0.3l-0.2,0.2l-0.3,0.5l-0.4,0.6l-0.2,0.4l-0.4,0.6l-0.4,0.5l-0.1,0.6l-0.1,0.5
                l-0.3,0.4l-0.2,0.4l-0.1,0.4l-0.1,0.4v0.3l0.1,0.2v0.1l-0.1,0.2l-0.2,0.3v0.3l-0.1,0.1l-0.4,0.3l-0.3,0.4v0.2v0.2v0.1l-0.1,0.1h-0.1
                h-0.1l-0.1,0.2v0.2l-0.1,0.2h-0.1l-0.1-0.1l-0.1-0.2h-0.1l-0.2,0.1l-0.2,0.2h-0.2l-0.4-0.1l-0.3,0.4l-0.3,0.8h-1.2h-1.2h-1.2h-1.2
                h-1.2h-1.2h-1.2h-0.7h-0.1l0.4-0.1l0.8-0.6l0.6-0.2l0.8-0.6l0.6-0.1l0.1-0.1l0.1-0.5l0.1-0.2l0.3-0.5l0.3-0.4l0.3-0.6l0.5-0.4
                l0.7-0.3l0.7-0.7l0.4-0.2l0.4-0.2l0.1-0.3l0.2-0.2l0.6-0.3l0.6-0.1l0.7-0.3l0.5-0.1l0.3-0.3l0.4-0.1l1.3-0.7l0.4-0.3l0.5-0.7
                l0.4-0.4l0.1-0.4l0.6-0.6l0.6-0.8l0.3-0.6l0.5-0.3l0.9-1l0.5-0.4h0.2l0.5-0.3l0.3-0.4l0.5-0.4l1-0.4l0.9-0.5l1.2-0.5l1.5-0.7
                l1.2-0.4l0.8-0.1l1-0.2h0.4l1.6,0.3l0.8,0.4l0.9,0.8l0.1,0.2v0.3l-0.5-0.1h-0.4l0.3,0.2l0.5,0.5v0.6l-0.3,0.5l-0.8,0.3l-0.2,0.2
                l-0.2,0.3l-0.2,0.1l-0.4,0.2l-0.2,0.2l-0.6,0.4h-0.3l-0.3-0.1l-0.8-0.3l-0.5-0.3l-0.2,0.2l-0.2,0.2l-0.5-0.1l-0.2,0.1l-0.4-0.1
                l-0.7,0.4h0.2l0.6-0.2h0.2l0.4,0.3l1,0.3l0.3,0.2l0.3,0.7l0.2,0.1l0.3-0.1l0.4-0.3l0.3-0.2l0.6-0.1l-0.1,0.2h0.5l0.5,0.3l-0.2,0.2
                l-0.2,0.4l-0.2,0.8l-0.5,0.6l-0.7,0.5l0.2,0.1l0.2,0.1l0.4-0.2h0.3l0.3,0.1l-0.1,0.4l-0.1,0.3l0.1,0.3l0.2,0.5l0.3,0.1l0.1,0.6
                l0.1,0.4v0.3l0.3,0.2v0.3l0.9,0.1l0.2,0.1l0.6,0.1l0.1,0.1l0.1,0.2l-0.6,0.3l0.5,0.2l0.5,0.4l0.4-0.1h0.2l0.4,0.3l0.1,0.1l0.1,0.1
                h0.2l0.3-0.1h0.6l0.6,0.1v0.2l-0.1,0.2h0.5l0.3,0.2l0.1-0.1l0.1-0.1l0.6-0.3l0.7-0.6l0.1,0.1v0.2l0.1,0.3l0.3,0.2l0.3,0.1l0.5-0.2
                l0.2,0.2l0.2,0.3l0.2,0.4v0.1l-0.3,0.1l-0.2,0.2l1,0.1l0.1,0.1l0.1,0.2l-0.1,0.2l-0.1,0.1l-0.2-0.1l-0.3,0.1l-0.3,0.2l-0.3,0.1h-0.2
                l-0.2,0.1l-0.2,0.1h-0.2l-0.7,0.4l-0.7,0.2l-0.7,0.4l-0.7,0.2l-0.7,0.3h-0.2h-0.2l-0.4,0.3h-0.2h-0.2l-0.2-0.1l-0.2-0.1l0.1,0.3v0.3
                l-0.1,0.1l-0.1,0.1h-0.4l-0.2-0.1l-0.2-0.1l-0.1-0.2l-0.2-0.2l-0.1,0.2v0.2l0.4-0.2l-0.2-0.4l-0.3,0.1l-0.1,0.4l0.1,0.1l0.1,0.3
                l-0.2,0.2h-0.1l-0.3,0.5l-0.3,0.2l-0.3,0.5l-0.4,0.4l-0.1,0.2l-0.6,0.5h-0.2h-0.2l-0.3,0.2v0.5l-0.1-0.1h-0.1l-0.1,0.1h-0.1
                l-0.2-0.1l-0.3,0.1l-0.2-0.1l-0.3-0.7l-0.1-0.2l-0.3-0.1l-0.1,0.1l-0.1,0.1l-0.2-0.3l-0.2-1v-0.2l0.3-0.9l0.6-0.8h-0.2l-0.6,0.5
                l0.1-0.1l0.1-0.1l0.2-0.2l0.3-0.2l0.4-0.1h0.3l0.2-0.1l0.3-0.2v-0.1l-0.2,0.1l-0.4,0.1l0.1-0.2l0.1-0.1l2.1-1.4l0.4-0.2l0.8-0.3
                l0.1-0.2l-0.1-0.1l0.3,0.1v0.2l-0.1,0.1v0.2v0.2l0.3,0.1l0.3,0.4l-0.1-0.5l0.3-0.3l1-0.4h0.8l0.3-0.2l-0.7-0.1l-0.8,0.1l-0.5-0.1
                l-0.7,0.1l-0.7-0.1l-0.2,0.1l-0.2,0.2l-0.2-0.1h-0.1l-0.1-0.1l0.2-0.4l0.8-0.6l0.5-0.5l0.1-0.1l0.1-0.2h-0.3l-0.2,0.1l-0.2-0.2
                l-0.3-0.3v0.1l0.1,0.4l-0.5,0.7h-0.3l-0.4,0.3l-0.6,0.3l-0.7,0.5l-1,0.5h-0.2l-0.4-0.4l0.1-0.2l0.1-0.2l-0.1,0.1l-0.1,0.1l-0.3,0.2
                l0.2,0.3l-0.1,0.1l-0.3,0.2l-0.3,0.2l-0.2,0.1l-0.2-0.2l-0.6,0.2l-0.5,0.1l-0.1-0.1v-0.2h-0.2l-0.3,0.1L286.7,321.2L286.7,321.2z
                M128.9,305.9L128.9,305.9l-0.5-0.3l-0.3-0.4L128,305l0.6,0.6L128.9,305.9L128.9,305.9L128.9,305.9z M128.7,306.5l-0.1,0.1h-0.1
                l-0.1-0.1l-0.2-0.7h0.1l0.2,0.2l-0.1,0.1l0.2,0.2L128.7,306.5L128.7,306.5L128.7,306.5z M228,306.6L228,306.6l-0.1-0.1v-0.3l0.1-0.1
                l0.2-0.1h0.2h0.3l0.1,0.1v0.1v0.1h-0.1v0.1h-0.1h-0.2l-0.2,0.1L228,306.6L228,306.6z M229.2,306.5H229h-0.1l0,0v-0.1v-0.1l-0.1-0.1
                l0,0l0,0h0.1l0.1,0.1L229.2,306.5L229.2,306.5L229.2,306.5z M294.1,309.8l-0.3,0.2l0.1-0.3l0.1-0.1h0.1h0.1L294.1,309.8L294.1,309.8
                z M294.1,310.1l-0.1,0.3l-0.2,0.3h-0.1h-0.1l0.1-0.2v-0.3h0.2h0.1L294.1,310.1L294.1,310.1z M234.7,310.8l-0.5,0.1l-0.2-0.1v-0.1
                l0.1-0.1l0.1-0.1l0.2-0.1h0.2l0.3,0.1l0.1,0.1v0.1L234.7,310.8L234.7,310.8z M322.9,312l-0.1,0.1h-0.1V312l0.2-0.2l0.1-0.2v-0.1
                v-0.1l0.1-0.1l0.1-0.2l-0.1,0.3L322.9,312L322.9,312z M301.3,312.6L301.3,312.6l0.3,0.1h0.1l-0.2,0.2h-0.3l-0.2-0.1l0.2-0.8l0.3-0.2
                l0.6-0.5l0.2-0.2l0.2-0.1h0.2l-0.2,0.3h-0.3l-0.3,0.3l-0.2,0.3l-0.2,0.2l-0.2,0.2L301.3,312.6L301.3,312.6z M296,316L296,316h0.2
                l0.2-0.3l0.4,0.1l0.2,0.1h0.1h0.1l0.2,0.2l0.4,0.2h0.5l0.7-0.1h0.1l0.7-0.1h0.7l0.2,0.1l0.1,0.1v0.1l-0.4,0.3L300,317l-0.6,0.3
                l-0.1,0.1v0.3v0.3h0.1l0.1,0.1l-0.1,0.1h-0.6h-0.2l-0.2-0.1l-0.1-0.3H298h-0.1l0.4-0.2l-0.2-0.3h-0.2l-0.1-0.1V317l0.2-0.1v-0.1
                l-0.2,0.1l-0.2,0.2l-0.2,0.1l-0.2,0.1h0.3l-0.2,0.1h-0.2l-0.8-0.2l-0.2-0.1l-0.3-0.2l-0.2-0.3h0.1v-0.1v-0.1h-0.3H295l-0.3-0.1v-0.6
                l-0.1-0.2l-0.3-0.1h-0.4v-0.2l0.1-0.3l0.2-0.3l0.2-0.3l0.2-0.2l0.5-0.4v0.3v0.3l-0.3,0.6l0.5,0.6l0.1,0.1v0.1v0.1l-0.1,0.1l0.2,0.1
                H296L296,316z M303.6,318.1L303.6,318.1l0.5-0.2h0.2v0.1l-0.3,0.2l-0.1,0.1l0.2,0.1v0.1l-0.2,0.2l-0.1,0.2l0.1,0.2l0.4-0.2h0.1h0.2
                l0.2-0.1l0.1-0.1l0.6-0.7v-0.1l-0.7,0.1l-0.1-0.1l0.4-0.4v-0.2l0.2-0.4l0.2-0.2l0.2-0.1l0.2-0.1l0.2,0.2v0.3h0.4l0.4,0.1l0.3,0.1
                v0.1v0.1l-0.1,0.2l-0.1,0.2l0.3,0.2v0.1l-0.5,0.2l-0.3,0.2l-0.3,0.3l-0.5,0.4l-0.8,0.3h-0.3l-0.3-0.1H304l-0.3,0.1h-0.3h-0.1h-0.1
                l-0.1-0.1l-0.2-0.3l-0.1-0.2l-0.1-0.9v-0.5l0.2-0.4l0.3-0.3l0.2-0.2l0.7-1.4l0.1-0.3l0.2-0.3l0.3-0.3l0.4-0.5l0.1-0.1h0.2h0.2
                l-0.1,0.2v0.2l0.3,0.6v0.1l-0.1,0.5l-0.3,0.8l-0.1,0.4v0.1l-0.1,0.2l-0.1,0.2l-0.5,0.3l-0.2,0.1l-0.2,0.1L303.6,318.1L303.6,318.1z
                M275.8,314.3h-0.3l0.1-0.1l0.3-0.3l0.3-0.1h0.2l-0.2,0.3L275.8,314.3z M239.9,317.2L239.9,317.2h-0.1h-0.1v0.1l-0.1,0.1h-0.1
                l-0.1-0.1l-0.2-0.3l-0.1-0.2l-0.1-0.2l0.1-0.1h0.3l0.2,0.1l0.2,0.1l0.1,0.2L239.9,317.2L239.9,317.2z M241.2,318.3H241l-0.2-0.1
                l-0.1-0.1V318l0.1-0.1l0.1-0.1h0.1l0.1,0.1l0.1,0.1v0.1L241.2,318.3L241.2,318.3z M242.7,318v0.2v0.1h0.1l0.1,0.1v0.1h0.1h0.1h0.1
                v0.1l0,0h0.1h0.1l0.1,0.1h0.1v-0.1v-0.1h-0.1l0,0l-0.1-0.1v-0.1l0.1-0.1h0.1v0.1h0.1l0.1-0.1l0.1-0.1h0.2h0.1v0.1h0.1h0.1l0.1,0.1
                l0.1,0.2h0.1l0.1-0.2l0.1-0.1l0.2-0.1h0.1l0.1,0.1v0.1v0.1l0.1,0.1h0.1v0.1v0.2v0.1l0.1,0.2l0,0l0.1-0.3l0.1-0.2l0.1-0.1h0.1v0.1
                v0.1l-0.1,0.1v0.1h0.2l0,0l-0.3,0.5l-0.2,0.3l-0.2,0.2h-0.1l-0.1-0.1h-0.1H245l0,0l0.2-0.1h0.1h0.1l0.3-0.3l0,0h-0.2l-0.6,0.5h-0.1
                h-0.1v-0.1l-0.3-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.3-0.2L243,319l-0.6-0.1l-0.1-0.1l-0.6-0.2l-0.2-0.1v-0.1v-0.1l0.1-0.1
                h0.1l0.1,0.1h0.1l0.1-0.1h0.1l0.1,0.1h0.1l0.1-0.1l0.1-0.1L242.7,318L242.7,318L242.7,318z M268.6,320l-0.2,0.1L268,320l-0.4,0.1
                h-0.1l0.3-0.3l0.5-0.2l0.5-0.6h0.1l-0.2,0.6L268.6,320L268.6,320z M268.3,319.5l-0.3,0.1h-0.1l0.4-0.4l0.4-0.1L268.3,319.5
                L268.3,319.5z M304,319.9h-0.1v-0.1l-0.2-0.2v-0.1l0.2-0.1h0.3l-0.1,0.2L304,319.9L304,319.9z M245.6,320L245.6,320h-0.1l0,0v-0.1
                l0.3-0.1h0.1v0.1L245.6,320z M250.1,322.6L250.1,322.6l-0.1-0.1v-0.1v-0.1h0.1h0.1v0.1L250.1,322.6L250.1,322.6z M287.7,323.1
                l-0.4,0.2l0.1-0.5l0.1-0.2l0.2,0.1v0.3L287.7,323.1L287.7,323.1z M289.1,324.6l-0.1,0.1v-0.1l0.2-0.3l0.1-0.1L289.1,324.6z
                M260.8,325.2l-0.2,0.1h-0.1l0,0v-0.1l0,0l0,0v-0.1l0.1-0.1l0.1-0.1h0.3h0.1h0.1v0.1l-0.3,0.1L260.8,325.2L260.8,325.2z
                M259.9,325.2h-0.1h-0.2h-0.1v-0.1l0.1-0.1l0.3-0.1v0.1L259.9,325.2L259.9,325.2z M307.3,326l-0.4,0.1h-0.3l-0.2-0.1v-0.1l0.5,0.1
                h0.2l0.4-0.2L307.3,326z M243.2,334.4h-0.1v-0.1v-0.2l0.1-0.1l0,0V334.4L243.2,334.4L243.2,334.4z"/>
            <linearGradient id="CC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="168.9079" x2="0.1864" y2="168.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CC" class="stcustom0 st37" d="M746.9,497L746.9,497L746.9,497L746.9,497L746.9,497L746.9,497L746.9,497v-0.2l0,0l0,0l0,0V497
                L746.9,497L746.9,497L746.9,497z M747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1
                L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1L747.1,497.1z"/>
            <linearGradient id="CD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="191.5579" x2="0.1864" y2="191.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CD" class="stcustom0 st38" d="M552,448.6l0.1,0.2l0.1,0.2l0.5,0.3l0.2,0.2l0.1,0.2l0.1,0.2l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.2,0.2
                l0.3,0.2h0.2h0.2l0.2,0.1h0.2l0.3-0.1l0.3-0.2l0.2-0.1h0.6l0.3,0.1l0.3,0.2h0.2l0.4-0.3l0.2-0.3l0.2-0.1l0.3,0.1l0.3,0.3l0.3,0.4
                l0.2,0.2l0.2,0.3l0.5,0.5l0.6,0.3l0.2,0.1l0.1,0.1v0.2v0.2l0.1,0.1h0.2h0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.1v0.2l-0.1,0.2
                l-0.1,0.2l-0.1,0.3l-0.1,0.3l0.1,0.1l0.1,0.1l0.1,0.1v0.1v0.1l-0.2,0.5l-0.1,0.4v0.2l0.3,0.2h0.4l0.1,0.1l0.1,0.1l0.1,0.1h0.2
                l0.1,0.1v0.1l0.1,0.1l0.1,0.1l-0.1,0.2v0.1l-0.3,0.3l-0.6,0.7l-1.3,1.2l0,0l-0.4,0.1l-0.2,0.2l-0.2,0.4l-0.4,0.3l-0.3,0.1v0.1v0.3
                v0.5l-0.1,0.2l-0.2,0.4l-0.1,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.4v0.1l-0.1,0.9v0.3l-0.1,0.4v0.3v0.3l-0.1,0.3v0.4v0.7v0.1l-0.1,0.1
                l-0.2,0.2l-0.2,0.1h-0.1l-0.2,0.3l-0.2,0.3l-0.2,0.3v0.1v0.3v0.4v0.2l-0.1,0.1l-0.3,0.2l-0.2,0.2l-0.1,0.1l-0.1,0.1l0.1,0.3v0.2
                l0.1,0.1l0.3,0.1v0.1v0.1l0.1,0.1l0.2,0.3l0.2,0.3v0.2v0.4v0.2v0.3v0.6v0.4v0.2l0.3,0.5l0.1,0.6l0.1,0.4v0.1l-0.1,0.5l-0.1,0.5v0.2
                l0.1,0.2l0.2,0.5l0.2,0.4l0.1,0.2l0.1,0.3l0.1,0.4v0.2v0.1l-0.3,0.5v0.2l0.1,0.4l0.1,0.4l0.1,0.2l0.3,0.6l0.2,0.2l0.5,0.3l0.4,0.3
                l0.2,0.2l0.1,0.2l0.3,0.5l0.2,0.4l0.1,0.3l0.2,0.5l0.2,0.4l0.3,0.5l0.2,0.4l0.1,0.3l-0.5,0.1l-0.9,0.2l-0.8,0.1l-0.8,0.1l-0.8,0.1
                l-0.8,0.1l-0.7,0.1l-0.2,0.1l0.1,0.3v0.3l-0.1,0.2l-0.2,0.3l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.4,0.3l-0.2,0.2v0.1l0.4,0.7l0.2,0.5
                l0.1,0.4v0.2v0.5v0.6v0.2l0.1,0.4v0.3l-0.3,0.4l-0.1,0.4l-0.1,0.5l-0.2,0.7l-0.1,0.4l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.3l0.2,0.2
                l0.1,0.1l0.5,0.4l0.2,0.2l0.3,0.4l0.3,0.3l0.4,0.1l0.4,0.1l0.2,0.1h0.2l0.1-0.1v-0.2v-0.1v-0.1l0.1-0.1h0.4l0.2-0.1h0.1v0.4v0.4v0.5
                v0.6v0.5v0.5v0.6v0.1l-0.1,0.1h-0.1l-0.2-0.1v-0.1v-0.2l-0.1-0.1h-0.1l-0.2,0.1l-0.3,0.2l-0.4,0.1l-0.1,0.1H557l-0.3-0.1l-0.2-0.2
                l-0.1-0.3l-0.2-0.3l-0.2-0.4l-0.1-0.2l-0.2-0.2h-0.2l-0.3-0.1l-0.1-0.3l-0.1-0.3l-0.1-0.1l-0.1-0.2l-0.2-0.1l-0.3-0.1l-0.5-0.2
                l-0.6-0.2h-0.3h-0.3l-0.2-0.1l-0.1-0.1l-0.1-0.3l-0.2-0.4l-0.5-0.5l-0.1-0.5l-0.1-0.1h-0.2l-0.1,0.1l-0.1,0.1l-0.1,0.5l-0.1,0.2
                l-0.1,0.1l-0.1,0.1l-0.2,0.1H550h-0.4l-0.5-0.1l-0.3-0.1l-0.7-0.1h-0.2l-0.3-0.1l-0.2-0.1l-0.7-0.2h-0.3l-0.1-0.2l-0.1-0.1l-0.2-0.1
                l-0.1-0.2l-0.1-0.4v-0.2l0.1-0.3l-0.1-0.1h-0.1l-0.2,0.1h-0.3l-0.6,0.1l-0.2,0.1h-0.2H544l-0.4,0.2h-0.1l-0.2-0.1l-0.1-0.1l0.1-0.1
                l0.1-0.2l-0.1-0.4l-0.1-0.2l-0.4-0.1h-0.1l-0.1-0.2l-0.1-0.2h-0.2h-0.1l-0.1,0.1l-0.1,0.1v0.1l-0.2,0.1h-0.4l-0.4-0.1h-0.3h-0.2
                l-0.7,0.3h-0.2h-0.7l-0.4-0.1h-0.3v0.2l-0.3,0.2l-0.2,0.1h-0.1l-0.1-0.1l-0.1-0.1v-0.3l-0.1-0.3l0.1-0.2l0.2-0.1l0.1-0.3l-0.1-0.4
                v-0.3l0.1-0.2l-0.1-0.4l-0.2-0.6l-0.3-0.5l-0.4-0.4l-0.3-0.4l-0.1-0.4v-0.8l0.1-0.7l0.1-0.6v-1l-0.3-0.6l-0.1-0.7l0.2-0.7v-0.5
                l-0.1-0.3h-0.1H536h-0.7h-0.9h-0.8h-0.9l-0.1-0.1l-0.1-0.2v-0.2l0.2-0.5l0,0h-0.3l-0.8,0.1l-0.5,0.1h-0.3l-0.6,0.1l-0.4,0.3
                l-0.1,0.4v0.3v0.2l-0.2,0.2l-0.1,0.3v0.1l-0.1,0.7l-0.6,0.1h-0.6H528l-0.7-0.2H527l-0.2,0.1l-0.4,0.1l-0.4,0.1l-0.4,0.2h-0.1
                l-0.3-0.1h-0.4l-0.4,0.1h-0.2l-0.1-0.1l-0.3-0.5l-0.5-0.7l-0.3-0.5l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.3v-0.3l-0.1-0.4l-0.3-0.5
                l-0.2-0.4l-0.1-0.4v-0.4v-0.3l-0.1-0.2l-0.2-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.2l-0.3-0.2l-0.3-0.1h-0.7h-0.9h-0.8h-0.9h-1h-0.3h-0.7
                h-0.6h-0.2H514h-0.6h-0.3h-0.8h-0.1l-0.1,0.1l-0.3-0.1h-0.3l-0.2-0.1h-0.4l-0.2,0.1l-0.3,0.2l-0.5,0.1h-0.2h-0.1l-0.3-0.3l-0.2-0.2
                l-0.1-0.1h0.1l0.4-0.1h0.3l0.1-0.1v-0.8v-0.8l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.1l0.2-0.1l0.1-0.1l0.2-0.2l0.4-0.5l0.3-0.1l0.3-0.1l0,0
                v-0.1h0.1l0,0l0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.2h0.1l0.2-0.1l0.2-0.1l0.3-0.1l0.1-0.1v-0.2v-0.2v-0.2h0.1h0.1l0.2,0.1l0.1,0.1h0.2
                l0.1-0.1l0.2-0.1h0.2l0.3-0.1l0.3-0.1h0.1l0.1,0.2l0.1,0.1v0.1l-0.1,0.2l-0.1,0.2l0.1,0.3v0.3v0.2l0.1,0.1h0.1h0.1h0.2l0.2,0.1h0.2
                l0.2-0.1l0.4-0.4l0.5-0.7l0.5-0.4l0.4-0.2l0.2-0.2l0.1-0.2l0.2-0.2l0.4-0.1l0.3-0.1l0.3-0.5l0.4-0.8l0.1-0.8l0.1-0.5v-1.6v-0.5
                l0.1-0.3l0.2-0.2l0.4-0.4l0.3-0.3l0.2-0.4l0.4-0.9l0.2-0.3l0.1-0.1l0.3-0.2l0.4-0.2l0.5-0.2l0.7-0.6l0.6-0.6l-0.1-0.8l0.1-0.6
                l0.3-0.8l0.1-0.8l-0.1-0.9v-0.7l0.3-0.8l0.1-0.3v-0.5v-0.8l0.4-1.1l0.4-0.7l0.4-0.8l0.2-0.5l0.2-0.6v-0.5v-0.6l0.1-0.8v-0.5l0.5-0.5
                l0.1-0.2l0.3-0.1l0.4-0.4l0.7-1l0.7-0.5l0.5-0.2h0.5l0.3,0.1l0.2,0.2l0.4,0.2l0.6,0.3l0.5,0.4l0.3,0.4l0.2,0.2l0.2,0.1h0.4l0.5,0.1
                l0.5,0.2l0.3,0.1l0.1-0.1h0.2l0.5,0.2l0.4-0.1l0.6,0.1l1.4,0.3l0.1-0.1l0,0l0.1-0.1l0.3-0.7l0.3-0.4l0.1-0.2l0.3-0.2l0.4-0.1h0.3
                l0.3,0.1l0.3,0.1h0.3l0.3-0.1l0.4-0.2l0.5-0.1l0.4-0.1l0.7-0.2l0.3-0.1h0.3l0.9,0.2l0.6-0.1h0.2l0.5-0.2l0.1-0.1l0.3-0.5l0.3-0.2
                l0.5,0.1l0.3,0.1l1,0.2l1.3,0.2h0.4h0.2h0.1l0.4-0.3h0.1h0.1L552,448.6z"/>
            <linearGradient id="CF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="221.408" x2="0.1864" y2="221.408" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CF" class="stcustom0 st39" d="M539.3,432.2l0.2,0.4l0.1,0.1l0.8,0.8l0.2,0.2l0.4,0.6l0.2,0.4l0.3,0.6v0.3v0.3l-0.1,0.8l-0.1,0.2
                l-0.4,0.4v0.2l0.1,0.2l0.1,0.1l0.1,0.1v0.4l0.1,0.1l0.3,0.1l0.7,0.1l0.4,0.1l0.3,0.1h0.1l0.1,0.1l-0.1,0.4l0.1,0.3l0.2,0.2l0.2,0.1
                l0.2,0.1l0.8,0.1l0.3,0.2l0.4,0.5l0.5,0.4l0.1,0.2v0.2l-0.2,0.3v0.1l0.2,0.3l0.3,0.3l0.5,0.3l0.9,0.5l0.4,0.3l0.1,0.2l0.2,0.3
                l0.3,0.2l0.2,0.2l-0.2,0.5v0.2l0.1,0.1l0.2,0.2l0.1,0.3l0.2,0.3l0.2,0.1l0.4,0.1l0.2,0.1l0.4,0.3l0.4,0.2l0.2,0.1l0.1,0.1l0.1,0.2
                v0.2v0.3l0.1,0.4l0.2,0.3l0.2,0.2l-0.8-0.2h-0.1h-0.1l-0.4,0.3h-0.1H550h-0.4l-1.3-0.2l-1-0.2l-0.3-0.1l-0.5-0.1l-0.3,0.2l-0.3,0.5
                l-0.1,0.1l-0.5,0.2h-0.2l-0.6,0.1l-0.9-0.2h-0.3l-0.3,0.1l-0.7,0.2l-0.4,0.1l-0.5,0.1l-0.4,0.2l-0.3,0.1h-0.3l-0.3-0.1l-0.3-0.1
                h-0.3l-0.4,0.1l-0.3,0.2l-0.1,0.2l-0.3,0.4l-0.3,0.7l-0.1,0.1l0,0l-0.1,0.1l-1.4-0.3l-0.6-0.1l-0.4,0.1l-0.5-0.2h-0.2l-0.1,0.1
                l-0.3-0.1l-0.5-0.2l-0.5-0.1H533l-0.2-0.1l-0.2-0.2l-0.3-0.4l-0.5-0.4l-0.6-0.3l-0.4-0.2l-0.2-0.2l-0.3-0.1h-0.5l-0.5,0.2l-0.7,0.5
                l-0.7,1l-0.4,0.4l-0.3,0.1v-0.2l0.1,0.4v0.5l-0.1,0.8v0.6l-0.2-0.1l-0.2-0.3l-0.1-0.1l-0.4,0.1l-0.2,0.1L526,453h-0.1l-0.1-0.1h-0.1
                h-0.2h-0.2h-0.1h-0.1l-0.2-0.1l-0.8-0.2l-0.1-0.1h-0.2l-0.4,0.2l-0.2,0.1l-0.6,0.1l-0.7,0.1h-0.3l-0.2,0.1l-0.1,0.1l-0.1,0.3
                l-0.1,0.5l-0.1,0.1v0.2v0.3v0.3v0.2l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.1-0.3l-0.1-0.3v-0.4v-0.1l-0.1-0.1l0,0l-0.1-0.3
                l0.1-0.2l-0.1-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.2l-0.2-0.1l-0.3-0.3l-0.3-0.3l-0.3-0.4l0.2-0.3l-0.3-0.4l-0.3-0.3
                l-0.2-0.3l-0.1-0.2h0.1h0.1h0.1v-0.1l-0.1-0.3l-0.1-0.3l-0.1-0.2l-0.4-0.3l-0.3-0.2l-0.1-0.1l-0.1-0.2l-0.1-1.1l-0.1-0.3l-0.1-0.1
                l-0.1-0.1v-0.1v-0.2v-0.2v-0.1l0.1-0.2v-1l-0.1-0.1l-0.1-0.1h-0.1h-0.1l-0.1-0.2l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.2
                l0.1-0.1l0.1-0.1v-0.1l0.2-0.5l0.3-0.5l0.1-0.1l0.1-0.4l0.2-0.4l0.1-0.2l0.1-0.2l0.1-0.2l0.4-0.3l0.3-0.5h0.3l0.3,0.1h0.4l0.3-0.1
                l0.2-0.2l0.5-0.1l0.5-0.2l0.1-0.2l0.2-0.1l0.2-0.1h0.1v0.1l0.1,0.3l0.2,0.3l0.3,0.3h0.1l0.2-0.2l0.5-0.1l0.1-0.1l0.4-0.3l0.4-0.2
                h0.1l0.2-0.1l0.4-0.2h0.3h0.5l0.8-0.1h0.6h0.3h0.1l0.1-0.3l0.1-0.1l0.2-0.1l0.4-0.5l0.3-0.4l0.1-0.1l0,0h0.1l0.1-0.2l-0.1-0.2
                l-0.5-0.3l0,0v-0.1l0,0l0.2-0.1l0.3-0.2l0.3-0.1h0.7h0.6h0.1l0.5-0.1l0.3-0.1l0.3-0.2h0.8l0.6-0.4l0.2-0.1l0.1-0.1v-0.1l0.3-0.2
                l0.3-0.3l0.3-0.3l0.1-0.2l0.7-0.7h0.2l0.1-0.1l0.3-0.5l0.1-0.1h0.1l0.2-0.1l0.1-0.1l0.1-0.2v-0.3l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1
                l0.5-0.3l0.1-0.1l0.1-0.1h0.2h0.2l0.1-0.1l0.1-0.1l0.4-0.2l0.3-0.1l0.4,0.1l0.3,0.1l0.2,0.1L539.3,432.2z"/>
            <linearGradient id="CG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="201.5078" x2="0.1864" y2="201.5078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CG" class="stcustom0 st40" d="M527.3,453.1v0.5l-0.2,0.6l-0.2,0.5l-0.4,0.8l-0.4,0.7l-0.4,1.1v0.8v0.5l-0.1,0.3l-0.3,0.8v0.7
                l0.1,0.9l-0.1,0.8l-0.3,0.8l-0.1,0.6l0.1,0.8l-0.6,0.6l-0.7,0.6l-0.5,0.2l-0.4,0.2l-0.3,0.2l-0.1,0.1l-0.2,0.3l-0.4,0.9l-0.2,0.4
                l-0.3,0.3l-0.4,0.4l-0.2,0.2l-0.1,0.3v0.5v1.6l-0.1,0.5l-0.1,0.8l-0.4,0.8l-0.3,0.5l-0.3,0.1l-0.4,0.1l-0.2,0.2l-0.1,0.2l-0.2,0.2
                l-0.4,0.2l-0.5,0.4l-0.5,0.7l-0.4,0.4l-0.2,0.1h-0.2l-0.2-0.1h-0.2h-0.1h-0.1l-0.1-0.1v-0.2v-0.3l-0.1-0.3l0.1-0.2l0.1-0.2v-0.1
                l-0.1-0.1l-0.1-0.2h-0.1l-0.3,0.1l-0.3,0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.2l-0.1-0.1l-0.2-0.1h-0.1h-0.1v0.2v0.2v0.2l-0.1,0.1l-0.3,0.1
                l-0.2,0.1l-0.2,0.1h-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1l0,0H512v0.1h-0.1l-0.2-0.2l-0.3-0.3h-0.1h-0.1l-0.2,0.1l-0.2,0.2
                l-0.4,0.2l-0.3,0.1v0.1l-0.1,0.2l-0.1,0.1h-0.3l-0.1,0.2l-0.3,0.3l-0.2,0.1v-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.1l-0.2,0.4v-0.3
                l-0.3-0.4l-0.8-0.7l-0.1-0.2l-0.7-0.6l0.2-0.4l0.1-0.2l0.2-0.1l0.6-0.3h0.1l0.4,0.4h0.1h0.1h0.2l0.1-0.1v-0.1l-0.1-0.1v-0.1l0.1-0.1
                v-0.2l0.1-0.2v-0.1l-0.1-0.1l-0.3-0.2l-0.2-0.1l-0.1-0.1l0.1-0.2l0.2-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.1l0.1-0.2l0.1-0.3
                v-0.2l-0.1-0.6v-0.1l0.1-0.1l0.2,0.1l0.2,0.1l0.5-0.1h0.2l0.1,0.1l0.2,0.1l1.1-0.2v-0.2l0.1-0.2v-0.2v-0.1l-0.1-0.1v-0.2v-0.2
                l0.1-0.1l0.3-0.2h0.1l0.2,0.1l0.2,0.2l0.2,0.4l0.1,0.3l0.2,0.4l0.5,0.2l0.6,0.1h0.3l0.4-0.3l0.2-0.3l0.1-0.1l0.1,0.1l0.2,0.3
                l0.1,0.1v0.1l-0.1,0.2l0.1,0.1l0.3,0.1l0.3-0.1l0.1-0.1l0.2-0.2v-0.1l-0.1-0.1v-0.1l0.1-0.1l0.1-0.3v-0.2l0.1-0.1l0.2-0.1l0.1-0.1
                l0.1-0.5l-0.1-0.2v-0.1l0.1-0.2V467l-0.1-0.5v-0.4v-0.4l0.1-0.5l0.1-0.5v-0.1l-0.1-0.2l-0.2-0.1l-0.4-0.1l-0.2-0.2l-0.1-0.2
                l-0.1-0.1l-0.5-0.1l-0.1-0.1v-0.3v-0.5v-0.3l0.1-0.3l0.1-0.2l0.2-0.2l0.1-0.2l0.1-0.1h0.4l0.1-0.1l0.1-0.1v-0.1l0.1-0.2l0.1-0.2
                v-0.1v-0.1l-0.1-0.3l-0.1-0.2l-0.1-0.1l-0.2-0.6l-0.2-0.1l-0.3-0.1l-0.6-0.1l-0.4,0.1l-0.6,0.2l-0.4,0.1l-0.3,0.1h-0.2l-0.1-0.1
                l0.1-0.1l0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.3V458l0.1-0.4l0.2-0.5v-0.2h0.7h0.7h0.7h0.7h0.6l0.3-0.1l0.3,0.2h0.1l0,0l0.1,0.1h0.3
                l0,0v0.2h0.3h0.1h0.1l0.2-0.1h0.1l0.2,0.1l0.2,0.1h0.2h0.5l0.4,0.1l0.4,0.3l0.3,0.2l0.2,0.2h0.1l0.1-0.1l0,0v-0.2l-0.1-0.3l-0.1-0.3
                v-0.2l0.1-0.2l0.2-0.1v-0.2l0,0l0.2-0.4l0.2-0.4l0.2-0.4l0.2-0.4v-0.2v-0.3v-0.3V454l0.1-0.1l0.1-0.5l0.1-0.3l0.1-0.1l0.2-0.1h0.3
                l0.7-0.1l0.6-0.1l0.2-0.1l0.4-0.2h0.2l0.1,0.1l0.8,0.2l0.2,0.1h0.1h0.1h0.2h0.2h0.1l0.1,0.1h0.1l0.1-0.1l0.2-0.1l0.4-0.1l0.1,0.1
                l0.2,0.3L527.3,453.1z"/>
            <linearGradient id="CH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="351.2079" x2="0.1864" y2="351.2079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CH" class="stcustom0 st41" d="M501.8,311.6L501.8,311.6l0.3,0.2v0.3l-0.2,0.5l-0.1,0.4v0.3v0.1l0,0h0.2h0.1l0.4,0.1l0.3,0.1l0.1,0.1
                v0.2l0.3,0.2l0.4,0.1h0.1l0.5-0.5l0.2,0.1l0.1,0.3v0.1l-0.1,0.5v0.3l0.1,0.2v0.1v0.1h-0.2l-0.3-0.1l-0.2-0.2h-0.2l-0.1,0.1l-0.1,0.2
                l-0.1,0.3v0.1l0.1,0.1l0.1,0.2l0.1,0.3v0.1v0.1h-0.1h-0.1l-0.2-0.4l-0.1-0.1h-0.2l-0.3,0.1l-0.4,0.2H502h-0.1l-0.1-0.2l-0.1-0.3
                v-0.2h-0.1l-0.4,0.2l-0.1,0.1v0.3v0.4L501,317l-0.4,0.5l-0.1,0.2l-0.1,0.1v0.1l0.1,0.2l0.1,0.2l-0.1,0.1l-0.2,0.1l-0.1-0.1l-0.1-0.2
                l-0.3-0.3l0.1-0.3v-0.1l-0.5-0.1l-0.2-0.2l-0.3-0.3l-0.1-0.1v-0.5v-0.1v-0.1h-0.1l-0.2,0.2l-0.2,0.3l-0.4,0.3v0.1l0.1,0.3v0.1
                l-0.3,0.4l-0.1,0.1l-0.4,0.3l-0.2,0.1l-0.5-0.2h-0.2l-0.2,0.1l-0.3,0.1l-0.6,0.1l-0.2-0.1l-0.1-0.1v-0.1l-0.1-0.2l-0.2-0.1l-0.1-0.2
                l-0.1-0.2l-0.1-0.1l0.1-0.4l-0.1-0.2v-0.2v-0.2l0,0l-0.5-0.1h-0.4l-0.3,0.1l-0.2,0.2v0.1l0,0l0.1,0.2l-0.2,0.2l-0.3,0.2h-0.2h-0.1
                v-0.3l0.2-0.1l0.2-0.2l0.1-0.2v-0.2l-0.2-0.2v-0.1l0.1-0.2l0.1-0.2l0.1-0.2l0.3-0.3l0.4-0.3l0.1-0.3V314v-0.1l0.5-0.2l0.1-0.1
                l0.1-0.1l0.4-0.4l0.4-0.4l0.1-0.1l0.1-0.1v-0.1v-0.1h-0.2l-0.1-0.1l0.2-0.2l0.2-0.1h0.2l0.1,0.1v0.1l0.1,0.1h0.2h0.2l0.2-0.1
                l0.1-0.2l0.1-0.2l0.3-0.2l0.2,0.1h0.6l0.5-0.1l0.3-0.1h0.4l0.2,0.1l0,0h0.1l0.1-0.1h0.2v-0.1v-0.1l0,0h-0.3h-0.1v-0.1l0.1-0.2
                l0.2-0.1h0.2h0.1l0.3,0.3h0.1l0,0h0.1l0.1,0.1l0.1,0.2l0,0l0.7-0.1h0.2L501.8,311.6L501.8,311.6z"/>
            <linearGradient id="CI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="223.7079" x2="0.1864" y2="223.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CI" class="stcustom0 st42" d="M459.6,433.6l0.2,0.2l0.2,0.1h0.3l0.2,0.1l0.2,0.1l0.1,0.3l0.2,0.2l0.1,0.3l0.2,0.2l0.2,0.1l0.3,0.2
                l0.3,0.1h0.3l0.1,0.1l0.2,0.1h0.2l0.2-0.3l0.2-0.1l0.6-0.2l0.2-0.1l0.2-0.1h0.6l0.5,0.1l0.3,0.1h0.2l0.2,0.1l0.2,0.3l0.1,0.1
                l0.2,0.1l0.1,0.2l0.1,0.2l0.1,0.1l0.2,0.2h0.1l0.1-0.1l0.1-0.1v0.1l-0.1,0.2v0.1l0.1,0.1v0.2l-0.2,0.3v0.2l0.2,0.1l0.1,0.2l0.1,0.3
                l0.1,0.1v0.1l0.1,0.8l0.1,0.8l-0.1,0.1h-0.1h-0.1v0.1l0.1,0.1v0.1l-0.2,0.1l-0.3,0.3v0.1l-0.1,0.2L467,441l-0.1,0.2l-0.2,0.6
                l-0.1,0.5v0.2l-0.1,0.1l-0.1,0.2l-0.4,0.5l-0.2,0.4v0.2v0.2l-0.1,0.1v0.3v0.3l0.1,0.3l0.3,0.7l0.1,0.4l0.1,0.4l0.1,0.2l0.1,0.1v0.1
                l0.4,0.1l0.1,0.1l0.1,0.5v0.2L467,448v0.2v0.2l-0.1,0.1h-0.2l-0.2,0.1h-0.2v-0.1h-0.1l-0.3-0.1V448h-0.1l-0.1,0.1l-0.2,0.5l-0.1,0.1
                l-1.5-0.3l-0.3-0.2h-0.4h-0.7l-0.5,0.1l-0.2,0.1l1.4-0.1h0.1l0.1,0.1l-1.8,0.2l-0.7,0.1h-0.2l-0.1-0.2h-0.7l-0.1,0.1l-0.1,0.1h0.3
                h0.5l0.1,0.1l-1.4,0.1l-1,0.2l-0.4,0.2l-1.4,0.5l-0.8,0.3l-0.2,0.1l-0.4,0.3l-0.5,0.2L454,451l-0.3,0.1l-0.1-0.1v-0.5v-0.7v-0.3
                v-0.3V449l0.2-0.1v-0.1v-0.3l0.2-0.2v-0.4v-0.1v-0.1l-0.1-0.3l-0.1-0.5l0,0l0,0h-0.1l-0.3-0.2h-0.3l-0.2-0.2v-0.2l-0.1-0.1l-0.1-0.2
                l-0.1-0.2l-0.3-0.1h-0.2h-0.2h-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.1h-0.1h-0.1l-0.1-0.1l0,0l0.6-0.6l0.2-0.3V444v-0.2l0.1-0.2
                v-0.3l-0.3-1l-0.1-0.3l-0.1-0.1H451l0.2-0.1h0.2l0.3,0.1l0.1-0.1l0.3-0.5v-0.2V441l0.1-0.3l0.1-0.1l0.1-0.1v-0.2l-0.1-0.1h-0.1h-0.1
                l-0.2-0.1l-0.1-0.1v-0.4v-0.1l0.1-0.1h0.1h0.3h0.3h0.2h0.1l0.1,0.1l0.1,0.1h0.1v-0.1v-0.4l-0.1-0.2l-0.2-0.2l-0.5-0.2v-0.3v-0.3
                l0.1-0.1l0.3-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1l0.1-0.3v-0.3h-0.2h-0.2l-0.2-0.1l-0.1-0.2v-0.5v-0.6V435l0.1-0.1l0.2-0.1l0.2-0.2
                l0.1-0.1h0.1l0.2-0.1l0.2-0.1l0.2-0.3l0.2-0.2h0.3h0.1h0.1l0.1,0.2l0.1,0.1h0.1l0.1,0.2l0.5,0.1l0.2,0.1l0.2,0.2h0.1h0.1l0.1-0.1
                v-0.1l-0.1-0.1v-0.1l0.1-0.1h0.1h0.2h0.2h0.2l0.1-0.1l-0.1-0.3v-0.2v-0.2l0.1-0.1l0.3,0.2l0.2,0.1h0.2l0,0l-0.1-0.2v-0.1h0.1h0.1
                l0.3-0.1l0,0l0.1,0.3v0.1l0.1,0.2l0.1,0.2v0.1l-0.1,0.1l-0.1,0.1l0,0l0.1,0.1l0.2,0.1h0.2l0.1-0.1l0.1-0.1l0.1-0.1v-0.1l0.1-0.1
                l0.4-0.1L459.6,433.6L459.6,433.6L459.6,433.6z M466.4,448.5l-0.1,0.1l-0.4-0.1l0.1-0.1L466.4,448.5z"/>
            <linearGradient id="CK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="142.4079" x2="0.1864" y2="142.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CK" class="stcustom0 st43" d="M26.8,523.5L26.8,523.5h-0.2h-0.1l0,0v-0.1h0.1h0.1L26.8,523.5L26.8,523.5L26.8,523.5z"/>
            <linearGradient id="CL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="83.6078" x2="0.1864" y2="83.6078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CL" class="stcustom0 st44" d="M286.5,528.2l0.5,0.5l-0.2,0.7l-0.4,1.2l-0.3,0.9v0.1l-0.1,0.2l-0.6,0.3l-0.9,0.4l-0.5,0.2l-0.6,0.3
                l-0.1,0.2l-0.2,0.1l-0.2,0.1l-0.1,0.2l-0.2,0.1l-0.2,0.4v0.3l0.1,0.2l0.2,0.1l0.1,0.2l0.1,0.2l0.1,0.1v0.1l-0.1,0.1h-0.2l-0.1,0.2
                l-0.1,0.6v0.2l0.2,0.5l0.1,0.3l0.2,1v0.3l-0.3,0.4l-0.1,0.2v0.2v0.2v0.1l0.3,0.5l0.3,0.4l0.2,0.2v0.3l-0.1,0.2l-0.2,0.1l-0.4,0.1
                l-0.2,0.2h-0.2l-0.2-0.1h-0.2l-0.2,0.1l-0.1,0.3l-0.2,0.5l-0.2,0.1l-0.1,0.4l-0.2,0.5l-0.1,0.3l-0.1,0.2l-0.2,0.2l-0.3,0.3l-0.2,0.3
                l-0.1,0.1h-0.1l-0.1,0.3l-0.4,0.4l-0.1,0.5l-0.1,0.3v0.5l-0.2,0.8v0.2l-0.2,0.1l-0.3,0.3l-0.1,0.2l0.1,0.7l0.2,0.7v0.3l-0.1,0.5v0.2
                l0.1,0.1l0.2,0.1l0.1,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.4,0.1l-0.1-0.1v0.1v0.2l-0.1,0.2l-0.2,0.6l-0.1,0.5l-0.1,0.2v0.2l0.1,0.1
                v0.1l-0.1,0.1l-0.1,0.2h-0.1l-0.1-0.1l-0.1,0.1v0.2l-0.1,0.3v0.4l-0.1,0.5l0.2,0.3l0.2,0.6l0.2,0.1h0.2l0.1,0.1l0.1,0.1l-0.1,0.2
                h-0.2v0.1l0.1,0.3l0.1,0.3l0.2,0.1l0.1,0.4l0.2,0.1v0.5l0.2,0.6l0.2,0.2l0.1,0.1l-0.1,0.3l-0.1,0.2v0.3l0.1,0.2l0.2,0.2h0.1l0.2-0.1
                l0.2,0.1v0.2v0.2l-0.1,0.2l-0.1,0.4v0.4v0.7l0.1,0.5v0.3v0.1l-0.1,0.1l-0.2,0.1h-0.2l-0.1,0.1v0.2l-0.1,0.3l-0.1,0.2l-0.2,0.3
                l-0.1,0.3l-0.1,0.2v0.1l-0.1,0.3l-0.1,0.2l-0.2,0.8l-0.2,0.2l-0.4,0.6l-0.1,0.1l0.1,0.2l0.2,0.1l0.1,0.2v0.3l0.1,0.2v0.3l0.1,0.6
                l-0.1,0.4v0.3v0.3l-0.1,0.2h-0.3l-0.2,0.2l-0.3,0.2v0.2v0.2l-0.1,0.1h-0.4h-0.1l-0.2,0.2l-0.2,0.1v0.2v0.2l-0.1,0.1l-0.1,0.3
                l-0.1,0.3l0.1,0.3l0.1,0.5v0.2l-0.1,0.4l-0.1,0.3l0.1,0.3l0.1,0.2l-0.1,0.4l-0.1,0.3l0.1,0.5l0.2,0.5l0.2,0.5v0.5v0.4l0.1,0.5
                l0.2,0.2l0.1,0.2v0.2l-0.1,0.3l-0.2,0.2l-0.4,0.1l-0.3,0.2l-0.2,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.2v0.8v0.3l-0.1,0.4l-0.1,0.3l-0.1,0.1
                v0.1v0.1h-0.1l-0.2-0.1H274l-0.1,0.1l0.1,0.3l0.1,0.5l0.1,0.2v0.2v0.3l-0.1,0.3h-0.2l-0.1,0.1v0.2v0.2l0.2,0.2l0.1,0.1v0.2l-0.2,0.1
                l-0.1,0.1l-0.1,0.3l-0.1,0.4l-0.1,0.3v0.4l0.2,0.4v0.4v1.1v0.4l0.1,0.6l-0.1,0.2v0.2l0.2,0.5l0.2,0.7l0.1,0.3v0.2l-0.3,0.2l-0.2,0.1
                l-0.1-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2v0.2l0.1,0.2l0.1,0.4l-0.1,0.2l-0.1,0.2v0.3v0.5l-0.1,0.8l0.1,0.3l0.1,0.1l0.4,0.2l0.3,0.1
                l0.1,0.3v0.2l-0.2,0.1l-0.2,0.1v0.4l0.2,0.3l0.2,0.2v0.2v0.2l-0.2,0.2l0.2,0.4l0.1,0.3l-0.1,0.2l-0.1,0.3l-0.1,0.2v0.2l-0.1,0.4v0.3
                v0.2l1.4,0.2l0.3,0.1l0.2,0.2v0.3l-0.2,0.3l-0.1,0.5l-0.3,0.1l-0.3-0.1h-0.3h-0.3h-0.4l-0.5,0.1l-0.3-0.1v0.2l0.1,0.3l0.6,0.1
                l0.6,0.2l0.2,0.4l0.2,0.4l0.3,0.2v0.4l-0.4,0.4v0.3l-0.5,0.1l-0.1,0.2l-0.1,0.6l0.1,0.5l0.2,0.2l0.1,0.3l-0.1,0.4l-0.4,0.2l-0.2,0.2
                l0.1,0.2l0.2,0.3v0.2l0.1,0.4l0.1,0.6v0.3l-0.1,0.2l-0.3,0.3l-0.2,0.2v0.4v0.3v0.3l0.2,0.2v0.2h-0.2l-0.2,0.1l-0.2,0.4l-0.5,0.4
                l-0.2,0.2v0.3l-0.2,0.5l-0.2,0.4l-0.1,0.4v0.4l0.3,0.2l0.2,0.4l0.1,0.5l-0.2,0.6L272,618l-0.2,0.2l-0.1,0.2l0.1,0.6v0.3l-0.1,0.3
                l-0.1,0.2l-0.2,0.2l-0.4,0.2l-0.3,0.1l-0.1,0.2l-0.2,0.4l-0.2,0.4v0.5l-0.9,0.1l-0.1,0.4l-0.2,0.3l-0.1,0.5l0.2,0.5l0.1,0.4
                l-0.2,0.5l0.1,0.5v0.4l0.3,0.5l0.2,0.6l0.1,0.5l0.1,0.4l0.1,0.2l0.1,0.3l0.1,0.3l0.2,0.1l0.4-0.3l0.3-0.2l0.2-0.1h0.5l0.3-0.2h0.1
                l0.2,0.1l0.1,0.2l0.1,0.5l0.1,0.5l-0.1,0.5l-0.1,0.1v0.2v0.3l0.2,0.2v0.3l-0.2,0.8l-0.1,0.3l0.2,0.4l0.2,0.3l0.4,0.2l0.3,0.3
                l0.2,0.3v0.4l0.1,0.1h0.6h0.8h1.3h1.3h1.5l0.7,0.3l0.6,0.3h0.8l0.8,0.3l0.6,0.2l0.4,0.1l0.4,0.1v0.3l-1.6-0.4l-0.4-0.2h-0.3
                l-0.6,0.3l-0.3,0.7l-0.2,0.2l-0.4,0.2h-0.4l-1.4,0.7l-0.5,0.1l-0.3,0.2l-0.3,0.3l-0.1,0.6v0.3l-0.4,1.2l-0.1,0.7v0.4l0.1,0.6l-0.1,1
                l-0.2,0.2l-0.6,0.3l-0.4-0.2l-0.7-0.2l-0.5-0.4l-0.6-0.3l-0.2-0.2l-0.6-0.8l-0.1-0.3v-0.3l0.3-0.5h0.2h0.5l0.4-0.1l0.2,0.2l0.1,0.5
                l-0.1,0.3l-0.1,0.2v0.1l0.3-0.2l0.1-1.2l1-0.6l0.3-0.3l0.3-0.5v-0.2v-0.2l-0.2-0.2l-0.5-0.2l-1.4,1.1l-0.6,0.3l-0.4,0.3l-0.5,0.6
                l-0.1,0.2l-0.1,0.4l-0.1,0.4l-0.5-0.2l-0.8-0.6l-0.2-0.2l0.2-0.3l0.2-0.3v-0.9l0.1-0.3l0.2-0.2l0.3-0.3l0.1-0.1l0.1,0.1v0.2h0.5
                l0.9-0.8h0.4l0.5,0.2l0.6-0.1l0.1-0.1l0.1-0.2l-0.4-0.2l-0.4-0.1l-1.2-0.1l-0.3,0.1l-0.3,0.4l-0.1-0.1l-0.1-0.2l-0.4-0.1h-0.2
                l-0.2,0.2v0.3l-0.1,0.3l-0.4,0.3l-0.2,0.5v0.4v0.2l-0.1,0.1l-0.2,0.1l-0.6-0.1l-0.3-0.4l-0.1-0.3l-0.4-0.3l0.8-0.4l0.3-0.2l0.3-0.5
                l0.2-0.3l-0.1-0.2H270v0.3l-0.2,0.3l-0.4-0.1l-0.6,0.4l-0.4-0.1l-0.6,0.1l-0.3-0.2l-0.1-0.3l0.1-0.3l-0.1-0.5l-0.2-0.1H267l-0.1-0.3
                l-0.2-0.5l-0.1-0.1l-0.1-0.2l0.1-0.1l0.2,0.1l0.2,0.2h0.3l0.6,0.3l0.2-0.1l0.1-0.1v-0.3v-0.3h0.1l0.3,0.3h0.2l0.4,0.1h0.2l0.3-0.1
                l0.5-0.4l0.3-0.4l0.1-0.1h0.2l0.1,0.1v0.3l0.1,0.3l0.2,0.2v0.3v0.2l-0.3,0.3l-0.1,0.1l0.1,0.1l0.1-0.1l0.2-0.2l0.1-0.2v-0.1v-0.2
                v-0.2l-0.3-0.6v-0.1v-0.3l0.3-0.3l0.1-0.2V632l-0.1-0.3l-0.6-0.6l-1-0.6l-0.1,0.1l-0.1,0.1l0.1,0.1h0.1l0.9,0.5l0.2,0.3l0.2,0.1
                l0.2,0.2v0.3l-0.9,0.3l-0.7,0.6l-0.5,0.4l-0.4-0.1l-0.2-0.4l-0.2-0.5l-0.3-0.3h-0.2l-0.1-0.1l-0.1-0.1l-0.2,0.1l-0.5-0.3l-0.1-0.1
                l0.4-0.5l0.4,0.2l0.1-1.3l-0.1-0.3l-0.5-0.3h-0.2h-0.3l-0.2-0.1l-0.3-0.1l-0.2-0.1l-0.3-0.2l-0.4-0.1l-0.5-0.8l-0.2-0.4l-0.1-0.5
                h0.7l0.4-0.1l0.1-0.2l-0.1-0.4l-0.2-0.3l0.2-0.3l0.2-0.2l0.2,0.1l0.6,0.5l0.1,0.3l0.4,1l0.1,0.1v0.1l0.8,0.5h0.1l-0.1-0.5l0.2-0.6
                l0.2-0.2h0.1v-0.1l-0.2-0.3l0.1-0.3h-0.1l-0.2,0.2l-0.4,1.1l-0.2,0.2l-0.3-0.5l-0.2-0.4l-0.1-0.1v-0.5l0.7,0.1l-0.2-0.2l-0.8-0.3
                l-0.2-0.2l-0.1-0.1l-0.3-0.4l-0.3-0.3l0.5-0.6l0.3-0.4l0.9,0.2l0.2-0.1l-0.1-0.3l-0.2,0.1l-0.3-0.2l-0.4-0.5v-0.3l0.1-0.5l0.2-0.1
                l0.4-0.1l0.4,0.2l0.2,0.1l0.2-0.1l-0.2-0.4l-0.3-0.1l-0.3-0.3v-0.3l0.1-0.2l0.1-0.3v-0.4V620l0.1-0.1l0.1-0.1v-0.1h-0.3l-0.1,0.4
                v0.3l-0.2,0.3l-0.1,0.3v0.4l-0.1,0.4l-0.2-0.2l-0.1-0.2l-0.1-0.1v-0.2l-0.1-1.3v-1.1l0.1-0.8l0.3-0.3l0.1-0.1h0.1h0.2l0.1-0.1
                l-0.5-0.2l-0.3,0.1l-0.2,0.2l-0.4-0.1l-0.1-0.4l-0.2-0.4v-0.5v-0.7l0.5,0.1l0.4,0.1h1.1l0.9,0.7l0.4-0.1v-0.1l-0.3-0.2l-0.2-0.4
                l-0.1-0.1l-0.1-0.2v-0.3l-0.2-0.9h-0.1l-0.1,0.3l-0.2,0.5l-0.3,0.3l-0.4,0.1l-0.4,0.1l-0.3-0.1L266,615v-0.3l-0.1-0.1l-0.4-0.1
                l-0.1-0.1l-0.1-0.2l0.2-0.4l0.2-0.2h0.2l0.2,0.1l0.2,0.3l0.2,0.1l0.3-0.2v-0.1l-0.2-0.1h-0.1l-0.2-0.1l-0.4-0.4l0.2-0.4l0.5-0.5
                l0.2-0.1l-0.1-0.4l0.2-0.4l-0.2-0.4l-0.3-0.4l-0.4-0.1l-0.1,0.1v0.2l0.1,0.1v0.1h-0.1l-0.5-0.1l-0.3-0.3l-0.5-0.2l-0.1-0.2l-0.1-0.3
                l0.2-0.5h-0.1l-0.4,0.4l-0.5,0.2l-0.4,0.1l-0.2,0.1l-0.1,0.1l0.1,0.1h0.2l0.2,0.5v0.2l-0.1,0.1h-0.2l-0.4-0.3l-0.2-0.4v-0.3l0.1-0.4
                l0.6-0.5l0.2-0.2l0.4-0.2l0.5-0.5l0.4-0.3l-0.2-0.2l-0.2-0.4V607l0.9-0.2l0.4,0.1h0.5l0.3-0.1h0.2l0.4-0.1l0.2-0.2v-0.2v-0.1V606
                l-0.1-0.4l0.1-0.1l0.2-0.2l0.2-0.1h0.1l0.3,0.2l-0.1,0.2l-0.1,0.3l-0.2,1.1l-0.1,0.2l-0.2,0.2l0.1,0.4l-0.2,0.3l-0.8,0.3h-0.1
                l0.1,0.1h0.4l0.3-0.1l0.3-0.3l0.1-0.4l0.1-0.8l0.2-0.1h0.2l0.1,0.2v0.4v0.4l-0.3,1.2l-0.3,0.5v0.1v0.2h0.3l0.2-0.3l0.2-0.3l0.2-0.5
                v-0.3v-0.2l0.1-0.7l0.1-0.4v-0.5l-0.2-0.2l-0.3-0.1L268,606l0.1-0.6h0.5l0.5-0.4l0.3-0.1h0.2l0.6,0.4h0.1v-0.1l-0.1-0.1L270,605
                l-0.5-0.4l-0.6-0.1l0.1-0.5l0.1-0.5l0.3-0.1l0.5-0.2l1-0.7l0.2-0.6v-0.6l-0.5-0.2l-0.5-0.4l-0.4-0.2l-0.3-0.3l0.1-0.4v-0.7l0.4-0.1
                l0.2-0.9l-0.3-0.7l0.1-0.5l0.4-0.4l0.1-0.3l0.1-0.3h0.3v-0.2v-0.3l-0.2-0.4v-0.5l0.2-0.6h0.3h0.1l-0.2-0.4l-0.2-0.4v-0.2l0.2-0.1
                l0.2-0.1l0.2,0.2l0.3,0.7v-0.2l-0.1-0.7l-0.1-0.9l-0.4,0.1l-0.3-0.1l-0.1-0.1l-0.1-0.2l0.1-0.2l0.1-0.2l0.2-0.2l0.5-0.1l0.4-0.3
                l0.1-0.6l-0.1,0.1l-0.2,0.5l-0.3,0.2h-0.2l-0.2-0.1l-0.4-0.4l-0.2-0.1h-0.2l-0.2,0.1l-0.4,0.8l-0.2,0.1l-0.8,0.1l-0.3-0.1L268,591
                v-0.2l0.1-0.2l0.2-0.1v-0.1h-0.2l-0.3-0.2l-0.1-0.3l-0.1-0.5l-0.3-0.7v-0.5l0.2-0.4l0.4-1.5l0.1-0.8l0.2-0.7v-0.4l0.5-0.4l0.2-0.2
                l0.5-1.3l0.1-0.7l-0.7-2.2l-0.1-0.4v-0.5l0.2-0.8v-0.3l-0.1-0.5l-0.4-0.8v-0.4l0.2-0.4l-0.2-0.5l0.1-0.3l0.1-0.2l0.6,0.1l0.3-0.1
                l0.2-0.1l0.1-0.4l0.1-0.6v-0.3l0.1-0.4l0.3-0.2l0.1-0.4l0.3-0.5l0.3-1.4l0.3-0.4l0.3-0.4l-0.1-0.6l0.2-0.3l0.2-0.2l0.1-0.4l0.2-0.3
                l0.5-0.5l0.1-0.6l0.4-1l0.1-0.7l0.1-0.4V564l0.2-0.5l0.2-0.4l0.1-0.2l0.5-0.6l0.1-0.4l-0.2-0.3v-0.5l-0.1-0.6l0.3-0.2l0.1-0.2l0.4-1
                v-0.4l0.1-0.5l-0.3-0.6v-1.3l-0.1-1l-0.2-1.1v-0.6l-0.1-0.7v-0.4l0.1-1l0.8-0.6l0.1-0.7l0.1-0.9v-0.7l-0.1-0.3l-0.4-0.5l-0.1-0.9
                l0.1-0.2l0.3-0.2l0.2-0.3l0.1-0.5l0.2-0.4l0.1-1l0.2-0.8l0.1-0.3l0.3-0.3l0.1-0.1v-0.3v-0.6v-0.4l0.2-0.7v-0.3l0.3-0.8l0.1-0.5
                l0.1-0.3v-0.3l0.1-0.7l-0.2-0.4v-0.2l0.2-0.7l0.2-0.2l0.2-0.3l0.1-0.4v-0.2l-0.3-1.2v-0.4l0.1-1l0.1-0.6v-0.5v-0.3l0.1-0.3l0.2-0.4
                v-0.3l-0.1-0.1l-0.3-0.1l-0.2-0.3v-0.3l0.1-0.2v-0.4l0.3-0.1l0.2-0.2l0.2-0.4l0.2-0.9l0.1-1.1l0.1-0.7l0.1-0.3l0.1-0.7l0.1-0.4v-0.4
                v-0.3l-0.3-1.6v-0.6l0.1-0.9v-1.3v-0.3l-0.1-0.3v-0.4l-0.2-0.6l-0.2-1.3v-0.7l-0.1-0.6l-0.2-0.2h0.1h0.3h0.3l0.4-0.1l0.4-0.2
                l0.2-0.3l0.1-0.3v-0.3l-0.1-0.3v-0.2l0.1-0.1h0.3l0.3-0.2l0.2-0.2v0.3l0.4,0.4l0.1,0.5l0.1,0.1l0.5,0.3v0.1l-0.1,0.1l-0.1,0.1
                l0.1,0.2l0.1,0.2v0.2l0.1,0.2l0.1,0.3v0.3l0.1,0.5v0.3v0.2l0.1,0.2l0.2,0.2l0.3,0.2l0.2,0.2l0.2,0.2l0.2,0.1l0.2,0.1l0.1,0.1v0.1
                l-0.1,0.1l-0.2,0.3l-0.3,0.5v0.1l0.3,0.3l0.1,0.1v0.2l-0.1,0.2l-0.4,0.1l-0.1,0.1v0.1l0.1,0.1v0.2l0.1,0.3l-0.1,0.1l-0.1,0.1v0.1
                v0.1l0.1,0.1l0.5,0.4l0,0l0,0l-0.2,0.2v0.1v0.2v0.2l0.1,0.1l0.3,0.1l0.3,0.5l0.3,0.5v0.4v0.5l0.2,0.4v0.3l0.1,0.4l0.2,0.2l0.1,0.4
                v0.2v0.2l0.2,0.5v0.4v0.3v0.2v0.1l0.2,0.1l0.1,0.1h0.2h0.4l0.6-0.1L286.5,528.2L286.5,528.2z M168.4,541.5l-0.4,0.1v-0.2l0.1-0.2
                l0.3,0.1h0.1L168.4,541.5z M253.9,562.6l-0.5,0.1l0,0v-0.1l0.1-0.1l0.1-0.1l0,0h0.1h0.1L253.9,562.6L253.9,562.6L253.9,562.6
                L253.9,562.6L253.9,562.6z M268.1,597.3l-0.2,0.1h-0.2l-0.2-0.1h-0.4l-0.3-0.2l-0.3-0.2l-0.1-0.1v-0.2l0.2-0.4l0.2-0.8l0.1-1.1
                l-0.1-0.4v-0.2l0.1-0.2v-0.2v-0.2v-0.2l0.2-0.4v-0.2v-0.2l0.1-0.4v-0.1l-0.1-0.1l0.1-0.1l0.9,0.3l0.6,0.1v0.3l0.1,0.2l0.1,0.4
                l0.1,0.1v0.3l-0.3,0.1v0.3l0.2,0.3l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2l0.1,0.1l0.3,0.3l0.3,0.2l0.2,0.3l0.2,0.3l-0.1,0.2
                l-0.2,0.3l-0.3,0.2l-0.3,0.1v0.5L268.1,597.3L268.1,597.3z M265.5,598.3l-0.4,0.1l-0.1-0.1v-0.1l0.1-0.1l0.2-0.1l0.1,0.1
                L265.5,598.3L265.5,598.3L265.5,598.3L265.5,598.3z M267.9,599.2l0.1,0.2h-0.1h-0.2l-0.1,0.2l0,0l-0.5-0.1l-0.1-0.1v-0.2h0.5
                l0.3-0.1l0,0L267.9,599.2L267.9,599.2z M268.2,601.4l-0.1,0.2h-0.2l-0.3,0.2v0.2v0.2l0.2,0.3l0.1,0.3l0.1,0.4l0.1,0.4v0.1v0.2
                l0.2,0.3v0.2v0.2l-0.1,0.3h-0.1h-0.2v0.2v0.1h-0.5l-0.2-0.1v-0.5l-0.3-0.2l-0.2-0.3l-0.2-0.6l-0.2-0.2l-0.2-0.5l-0.3-0.4l0.4-0.2
                l-0.1-0.4l0.2-0.1l0.3-0.2l0.2,0.1h0.2l0.1-0.1v-0.4l0.1-0.3l0.2-0.2h0.3l0.1,0.2l0.1,0.2l0.3,0.2L268.2,601.4L268.2,601.4
                L268.2,601.4z M270.3,602.9l-0.7,0.3l-0.3-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.3l0.1-0.2l0.2-0.3l0.1-0.2v-0.2v-0.2v-0.2l0.1-0.1l0.5,0.2
                l0.5,0.3l0.2,0.2v0.2l-0.2,0.4l-0.1,0.3L270.3,602.9L270.3,602.9z M268.4,603.1L268.4,603.1h-0.2l-0.1-0.1v-0.2l-0.2-0.3l-0.1-0.1
                v-0.2l0.1-0.2l0.2-0.1h0.1l0.1,0.3v0.3v0.3L268.4,603.1L268.4,603.1z M264.5,603.4L264.5,603.4h-0.2l-0.1-0.1v-0.2l0.1-0.1h0.1
                l0.1,0.1V603.4L264.5,603.4L264.5,603.4z M266.5,606.5l-0.2,0.2l-0.3,0.1l-0.3-0.1l-0.3,0.1v-0.3l0.1-0.2l0.2-0.3l0.2-0.4V605
                l0.1-0.1l0.1-0.2l0.3-0.1l0.1,0.4l-0.1,0.7l0.2,0.4v0.1v0.2L266.5,606.5L266.5,606.5z M264.3,615.3l-0.2,0.1v-0.1l-0.2-0.2l0.2-0.1
                l0.3-0.2h0.2l0.2,0.1v0.1l-0.4,0.2L264.3,615.3L264.3,615.3L264.3,615.3z M265.8,618.5l-0.1,0.1l-0.3-0.1l-0.6,0.1l-0.3-0.4
                l-0.1-0.6l-0.1-0.1l-0.1-0.3v-0.3l-0.2-0.4l-0.1-0.4V616l0.1-0.2l0.6-0.2l0.2-0.3h0.2l-0.1,0.7l0.1,0.2l0.2,0.2v0.1v0.3l0.1,0.4
                l0.1,0.2v0.1v0.1v0.1L265.8,618.5L265.8,618.5z M263.2,619.2h-0.3l-0.1-0.8l0.4-1.1v-0.4l-0.1-0.3l-0.1-0.3v-0.1l0.5-0.2l0.1,0.2
                l0.2,0.6l0.3,0.9v0.8l-0.2,0.2l-0.6,0.2L263.2,619.2z M266.1,620.8v0.6v0.6l-0.1,0.8v0.2h0.1v0.1v0.4l-0.1,0.3l-0.1,0.3l-0.1,0.3
                l-0.1,0.1l-0.3,0.1h-0.2l-0.1-0.4V624v-0.3l-0.2-0.4v-0.1l0.1-0.3l0.1-0.1v-0.4l0.1-0.1l0.2-0.2V622v-0.1h-0.1l-0.6,0.5l-0.1,0.1
                v0.2v0.6l-0.1,0.4l-0.1,0.1h-0.3h-0.4l-0.4-0.3l-0.3,0.1l-0.1-0.4l0.1-0.3h0.5l0.1-0.6l-0.2-0.1l-0.2-0.2l-0.1-0.2l0.1-0.2l0.3-0.2
                h0.2l0.1,0.1l0.4-0.1V621l-0.3-0.2l0.1-0.3l0.4-0.3l0.2-0.3v-0.3l-0.1-0.3v-0.1l0.2-0.2l0.3-0.1h0.1l0.3,0.2h0.2l0.1,0.1v0.2
                L266.1,620.8L266.1,620.8z M264.3,619.5v0.3l-0.4,0.7l-0.4,0.4l-0.3,0.3H263l-0.2-0.1l0.2-0.2l0.2-0.2l-0.1-0.3l-0.1-0.1h-0.1
                l-0.2-0.2l0.1-0.2l0.1-0.1l0.1-0.1l0.1,0.1l0.5-0.2l0.2-0.1h0.3L264.3,619.5L264.3,619.5z M264.5,625.8l-0.5,0.4l-0.2-0.1h-0.4
                l0.1-0.4v-0.3l0.1-0.1v-0.2l0.1-0.4l0.3,0.1h0.2l0.3,0.1l0.4,0.1l0.1,0.4l-0.4,0.2L264.5,625.8L264.5,625.8z M263.8,627.5l-0.1,0.4
                h-0.2l-0.1-0.1v-0.3l-0.1-0.1l0.1-0.3l0.1-0.3v-0.2h0.3l0.4,0.1l0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1L263.8,627.5L263.8,627.5z
                M265.8,630.2v0.4l-0.1,0.1h-0.1l-0.2-0.1h-0.1l-0.2,0.2l-0.2,0.1h-0.2l-0.3-0.1l-0.3,0.6l-0.1,0.2l-0.3,0.3v-0.3l0.2-0.5l0.1-0.3
                l0.2-0.5l0.3,0.2l0.4-0.2l0.4-0.3h0.4L265.8,630.2L265.8,630.2L265.8,630.2z M265.1,631.7l0.1,0.9l0.1,0.1l0.3,0.1l0.3,0.4v0.1
                l-0.4,0.8l-0.1,0.5H265l-0.2-0.5l-0.3-0.5l-0.1-0.6l-0.2-0.5l0.3-0.3l0.3,0.1v-0.4L265.1,631.7L265.1,631.7z M267,633.1L267,633.1
                l-0.4-0.1l-0.2-0.1l-0.2-0.2v-0.2l-0.1-0.3h0.1l0.2,0.1l0.1,0.1l0.1,0.2l0.4,0.3l0.1,0.1L267,633.1L267,633.1L267,633.1z
                M282.5,636.4v1.4v1.4v1.3v1.3v1.3v1.3v1.5v1.1h-0.4l-0.1,0.1l-0.7,0.2l-1.1-0.2l-0.3-0.2l-0.4-0.5l-0.1,0.1l-0.4,0.2l-0.4,0.2h-0.3
                l-0.3-0.2l-0.1-0.1h-0.1l-0.6,0.3l-0.7-0.3l-0.5-0.2l-0.9-0.1l-0.6-0.4h-1.1l-0.2-0.1l-0.1-0.4l0.1-0.2l0.2-0.1l0.1-0.2h0.2l0.3,0.3
                h0.1l0.2-0.2l0.3-0.2h0.1l0.6,0.3h0.2l0.3-0.1l0.1-0.1l0.1-0.2l0.1-0.1l0.3-0.1l0.3,0.1v0.3v0.3l0.4,0.1h0.4l0.3,0.1v-0.2l-0.5-0.5
                l-0.2-0.3l-0.3-0.2l-0.3-0.1l-0.3-0.6V643v-0.5l0.6-0.3l-0.1-0.5l0.2-0.3l0.2-0.1l0.2,1.3l0.2,0.4l-0.2,0.1h-0.5l0.3,0.6l0.4,0.2
                l0.4,0.5v0.3l0.2,0.2h0.5l0.3-0.1l0.2-0.2l0.2-0.1l0.3,0.3l0.6,0.2l0.2,0.1l0.1,0.2v0.3v0.1l0.2-0.1l0.2-0.4l0.1-0.1l0.1-0.1
                l0.1-0.1v-0.1l-0.4-0.3l-2.2-1.2l-0.3-0.5l-0.2-0.6v-0.6l0.2-0.2l0.4-0.2l0.7-0.3l0.8-0.5l0.1-0.1V640l-0.1-0.2l-0.3-0.1h-0.3h-0.3
                l-0.3,0.1l-0.6,0.3h-0.3l-0.3-0.2l-0.2-0.3l-0.1-0.5v-0.3v-0.3l0.1-0.3l0.2-0.1h0.2l0.2-0.1l0.1-0.1l0.1-0.1v-0.1l-0.1-0.1l-0.3-0.2
                l-0.1-0.2l-0.2-0.3l0.1-0.1h0.4l0.3,0.2l0.3,0.2h0.2l0.1-0.1l0.3-0.3l0.3-0.4l0.3-0.4l0.2-0.3h0.2l0.7,0.8h0.2l0.8-0.5h0.1
                L282.5,636.4L282.5,636.4L282.5,636.4z M266.3,637.6L266.3,637.6h0.2l0.2,0.1l0.6,0.1l0.5,0.2l0.3,0.2l0.4,0.1l0.3,0.3l0.1,0.1h0.2
                l0.4,0.5v0.1l0.4,0.4v0.1h-0.3l-0.5-0.2h-0.3l-0.2-0.1l0,0v-0.2l-0.1-0.1l-0.5-0.5l-0.2-0.1l-0.4-0.1l-0.4,0.1l-0.3-0.1h-0.1
                l-0.4-0.4l-0.4-0.3l-0.2-0.4l-0.3-0.3V637l0.1-0.1l0.3,0.2l0.3,0.3l0.1,0.1L266.3,637.6L266.3,637.6z M270.4,640.3l0.1,0.4v0.1
                l0.2-0.1h0.3l0.6,0.1l0.1,0.1l0.2,0.4l0.2,0.2l0.3,0.4l-0.3,0.3l-0.2,0.4v0.2l-0.1,0.1l-0.2,0.1l-0.3,0.2H271l-0.3,0.1l-0.1,0.1
                h-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.2-0.1l0.3-0.4v-0.4l-0.3-0.1l-0.2,0.1h-0.1l-0.2-0.1l-0.1,0.2v0.2v0.3v0.1l-0.1,0.1l-0.3-0.1
                l-0.3-0.2v-0.1l0.1-0.4V642l-0.1-0.3l0,0h-0.1l-0.3,0.1l-0.3-0.4l-0.2-0.4l-0.6-0.1l0.4-0.6l0.7-0.1l0.2,0.3l0.7,0.2v-0.3v-0.1
                l0.1-0.1h0.1l0.1,0.1h0.1l0.1,0.1L270.4,640.3L270.4,640.3z M274.7,642.9l0.6,0.4h0.4v0.2v0.4l-0.1,0.2l-0.2,0.2l-0.1,0.2h-0.1
                l-0.5-0.3l-0.5-0.4l-0.2,0.1l-0.3-0.1h-0.3l-0.2,0.2l-0.4,0.1l-0.1-0.4l-0.3-0.4l-0.3-0.3l0.2-0.5l0.2-0.1l0.2-0.2l0.8,0.2l0.4,0.2
                L274.7,642.9z M275.9,646.9l0.1,0.3v0.1l0.3,0.1l0.2-0.1h0.4l0.2-0.1l0.3-0.1l0.4,0.8v0.2l-0.3,0.3l-0.2,0.1l-0.2-0.1v-0.1v-0.1
                l-0.1-0.1h-0.1l-0.2,0.1h-0.1l-0.2-0.1l-0.3-0.1l-0.1-0.1v-0.2l-0.1-0.1l-0.4-0.3l-0.2-0.2h-0.2H275l-0.1,0.1l-0.2,0.1h-0.1
                l-0.1-0.1v-0.1l0.1-0.2h0.1h0.5l0.3,0.1L275.9,646.9L275.9,646.9z M279.5,647.1l2.3,0.5l0.7-0.3h0.5l0.2,0.4l-0.6,0.4v0.1l0.1,0.1
                l0.6,0.1l0.1,0.1l0.1,0.2l-0.1,0.3v0.1l0.1,0.1l0.4,0.4l0.2,0.2l0.1,0.2v0.3v0.3h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.4l-0.1-0.1l-0.4-0.1
                l-0.4-0.2h-0.3l-0.3-0.1l-0.2,0.1l-0.1-0.1l-0.1-0.3V649l0.1-0.4v-0.1h-0.1l-0.3,0.1l-0.1-0.1l-0.3-0.3l-0.1-0.1h-0.3l-0.2,0.5v0.1
                l0.2,0.3l0.3,0.5h-0.2l-0.5-0.2l-0.1-0.1l-0.1-0.3l-0.3-0.2l-0.1-0.1v-0.1v-0.3h-0.1l-0.4,0.1l-0.1-0.1v-0.1l-0.1-0.1l-0.3-0.1v-0.1
                l0.1-0.1l0.1-0.2l0.1-0.9L279.5,647.1L279.5,647.1z M286.8,648.3l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.2,0.1l-0.2-0.1l-0.1-0.2v-0.2
                l-0.1-0.1h-0.1h-0.1l-0.1,0.1l-0.3,0.3l-0.1,0.1h-0.1l-0.8-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.5l-0.4-0.4l0.5-0.2h0.7l1.3,0.2h0.5
                l0.4,0.4l0.1,0.3L286.8,648.3L286.8,648.3z M288.5,648.7l-0.2,0.2h-0.2l-0.1-0.1v-0.1v-0.1l0.1-0.1l0.2-0.1h0.1l0.2,0.1L288.5,648.7
                z M286.2,651.4h-0.1l-0.1-0.1l-0.1-0.1l-0.5-0.1V651v-0.1l0.1-0.1l0.2-0.1l0.1-0.3h0.1l0.1,0.1l0.1,0.4l0.1,0.3L286.2,651.4
                L286.2,651.4L286.2,651.4z M285.4,651.9h-0.1l-0.2-0.1h-0.4l0,0v-0.1v-0.1l0.2-0.1h0.6h0.1v0.1L285.4,651.9L285.4,651.9L285.4,651.9
                z"/>
            <linearGradient id="CM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="223.8579" x2="0.1864" y2="223.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CM" class="stcustom0 st45" d="M518.5,441.8l-0.3,0.5l-0.4,0.3l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.4l-0.1,0.4l-0.1,0.1l-0.3,0.5
                l-0.2,0.5v0.1l-0.1,0.1l-0.1,0.1l-0.4,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.1l0.1,0.2l0.1,0.2h0.1h0.1l0.1,0.1l0.1,0.1v1l-0.1,0.2v0.1
                v0.2v0.2v0.1l0.1,0.1l0.1,0.1l0.1,0.3l0.1,1.1l0.1,0.2l0.1,0.1l0.3,0.2l0.4,0.3l0.1,0.2l0.1,0.3l0.1,0.3v0.1h-0.1h-0.1h-0.1l0.1,0.2
                l0.2,0.3l0.3,0.3l0.3,0.4l0.3,0.3l0.3,0.4l0.3,0.3l0.3,0.3l0.2,0.1h0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.2l0.1,0.2l-0.1,0.2l0.1,0.3
                l0,0l0.1,0.1v0.1v0.4l0.1,0.3l0.1,0.3l0,0v0.2l-0.2,0.1l-0.5-0.1v0.2l0.1,0.3l0.1,0.3v0.2l0,0l-0.1,0.1h-0.1l-0.2-0.2l-0.3-0.2
                l-0.4-0.3l-0.4-0.1h-0.5h-0.2l-0.2-0.1l-0.2-0.1h-0.1l-0.2,0.1h-0.1h-0.1H517v-0.2l0,0h-0.3l-0.1-0.1l0,0h-0.1l-0.3-0.2l-0.3,0.1
                h-0.6h-0.7h-0.7h-0.7h-0.7l-0.1-0.2l-0.1-0.1H512h-0.7h-0.6h-0.2h-0.2h-0.5h-0.6h-0.1h-0.5h-1.1h-0.6v0.1v0.1v0.2h-0.7h-0.9h-0.8
                H504h-0.9l-0.3-0.1l-0.1-0.1v-0.1v-0.1h-0.1l0.1-0.7l0.1-0.5v-0.5l0.2-0.5l-0.1-0.4l-0.1-0.2l-0.6-0.6l0.3-0.2h-0.3l-0.1-0.2
                l-0.2-0.3h0.1l0.1-0.2h0.3V452l-0.3-0.2v-0.1l0.1-0.1l-0.1-0.1l-0.2,0.1h-0.1l-0.1-0.1h-0.1v0.2l-0.1,0.2l-0.1,0.1h-0.2h-0.1v-0.1
                l-0.1-0.1l-0.4-0.1l-0.3-0.1l-0.1-0.4v-0.3l-0.1-0.2v-0.2v-0.3l-0.1-0.1h-0.1h-0.1h-0.1l-0.2-0.2l-0.1-0.1l0.1,0.3l-0.1,0.1h-0.2
                l-0.1-0.1V450l0.1-0.4l0,0l0.1-0.2l0.2-0.3l0.2-0.3l0.2-0.4l0.2-0.7l0.1-0.5l0.1-0.4l0.2-0.4l0.2-0.3l0.5-0.5l0.4-0.4l0.2-0.2
                l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.3l0.2-0.3l0.1-0.1l0.2-0.1l0.5-0.3l0.3-0.2l0.1,0.1v0.1l0.1,0.1h0.2h0.3h0.2l0.1-0.1l0.1-0.3
                l0.1-0.1h0.1l0.4,0.2l0.3,0.3l0.3,0.3l0.2,0.1l0.1,0.1l0.1,0.5l0.1,0.1l0.1,0.1h0.2l0.2-0.1l0.2-0.1l0.2-0.2l0.1-0.2l0.1-0.1v-0.4
                v-0.1l0.2-0.2l0.4-0.3l0.2-0.2v-0.1l-0.1-0.2l-0.1-0.2l0.1-0.2l0.1-0.2l0.5-0.5v-0.2v-0.2l0.4-0.6l0.2-0.8v-0.2l0.2-0.4l0.3-0.5
                l0.5-0.1l0.2-0.1l0.2-0.2l0.1-0.2l0.1-0.2v-0.4l0.1-0.4l0.1-0.4l0.2-0.3l0.3-0.2l0.4-0.1l0.1-0.1l0.1-0.2v-0.5v-0.3v-0.1l0.1-0.2
                l0.4-0.4l0.2-0.6l0.2-0.6l0.5-0.8l0.5-0.8l0.2-0.2l0.2-0.1h0.2l0.2-0.1l0.6-0.4l0.2-0.1l0.2-0.1v-0.1v-0.2l-0.1-0.4l0.1-0.3l0.1-0.4
                v-0.3v-0.1l-0.1-0.2l0,0l-0.2-0.2l-0.3-0.1h-0.4l-0.2-0.1v-0.2v-0.1v-0.1v-0.3l-0.3-1.3h0.5l0.6,0.2l0.2,0.1l0.1,0.5l0.2,0.3
                l0.4,0.2l0.2,0.4l0.1,0.7l0.2,0.4v0.1l0.2,0.6l0.1,0.2v0.3v0.2l0.1,0.3l-0.2,0.5l-0.1,0.3v0.4l0.1,0.7l0.2,0.6l0.2,0.5l0.2,0.4
                l0.3,0.4l0.4,0.4l0.3,0.2l-0.3,0.1h-0.6l-0.4-0.1h-0.2h-0.2l-0.7,0.1H516l-0.6-0.1H515l-0.3,0.2l-0.2,0.3l-0.2,0.3l0.1,0.3l0.2,0.2
                l0.3,0.4l0.3,0.3l0.1,0.2l0.6,0.5l0.6,0.5l0.1,0.1l0.2,0.1h0.1l0.3,0.3l0.4,0.4l0.4,0.7l0.3,0.7l0.3,0.7l0.1,0.1l0.2,0.1v0.1v0.2
                l-0.1,0.2L518.5,441.8z"/>
            <linearGradient id="CN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="317.8579" x2="0.1864" y2="317.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CN" class="stcustom0 st46" d="M786.3,405.9l0.1,0.1l0.1,0.2l0.1,0.4v0.3l-0.3,0.2l-0.3,0.1l-0.5,0.8l-0.1,0.2l-0.1,0.1v0.1v0.1
                l-0.1,0.4l-0.1,0.5l-0.1,0.2l-0.1,0.1l-0.2,0.1h-0.1h-0.1l-0.3,0.3l-0.3,0.2l0.1,0.1v0.1l-0.1,0.1h-0.1l-0.4,0.1l-0.2,0.1l-0.2,0.3
                h-0.1l-0.3,0.1h-0.2l-0.3-0.2l-0.2-0.1l-0.4-0.1l-0.4-0.1l-0.3-0.1l-0.6-0.4l-0.1-0.6l-0.1-0.3v-0.1v-1.1V408l0.1-0.1l0.3-0.2
                l0.3-0.2l0.4-0.4l0.3-0.2l0.3-0.3h-0.2h-0.1l0.1-0.2l0.1-0.1l0.1-0.1h0.3h0.3l0.2-0.2h0.2l0.7,0.1l0.5-0.1l0.2-0.2h0.1l0.4,0.1
                l0.1,0.2V406v-0.1h0.1l0.5,0.2v-0.3v-0.1l0.1-0.1h0.1l0.2,0.2l0.2,0.1L786.3,405.9L786.3,405.9z M784.9,402.6l0.1,0.1l0.3-0.1
                l0.1,0.1v0.1l-0.1,0.2l-0.2-0.1h-0.2h-0.2v-0.1l0.1-0.1L784.9,402.6L784.9,402.6z M791.2,401l-0.3,0.1h-0.1l0.1-0.2l0.2-0.1
                L791.2,401z M791.6,401.1L791.6,401.1L791.6,401.1l-0.1-0.2l-0.1-0.1l0.1-0.1l0.1-0.1h0.2h0.1l-0.1,0.1v0.1L791.6,401.1L791.6,401.1
                z M793.8,397.5v0.1l-0.2-0.2h-0.1l-0.1-0.1l-0.1-0.1h0.2l0.2,0.2L793.8,397.5L793.8,397.5z M806.8,392.3l-0.1,0.2h-0.2v-0.1l0,0
                v-0.1l0,0H806.8L806.8,392.3L806.8,392.3z M811.4,389.4l-0.2,0.1l-0.1-0.1v-0.2l0.1-0.2l-0.1-0.1l0.1-0.1h0.2l0.1,0.1h0.1v0.1v0.1
                l-0.1,0.2L811.4,389.4L811.4,389.4L811.4,389.4z M815.4,381.1l-0.2,0.1h-0.1V381l0.2-0.2l0.1,0.1v0.1V381.1L815.4,381.1z M818,376.1
                L818,376.1l-0.2-0.1h-0.1l0.1-0.1h0.2V376.1L818,376.1z M818.6,375.4v0.1h-0.1l-0.1-0.3l0.1-0.1h0.1V375.4L818.6,375.4z
                M818.3,375.2L818.3,375.2l-0.4-0.1h-0.4l-0.1-0.2v-0.3h0.4l0.5,0.2l0.1,0.1L818.3,375.2L818.3,375.2z M817.1,370.2l-0.2,0.1
                l-0.7-0.3l-0.5-0.3l-0.3-0.4v-0.2h0.4l0.4,0.1l0.1,0.2l0.1,0.1l0.1,0.1l0.7,0.3l0.1,0.1L817.1,370.2L817.1,370.2z M821.7,285.2
                L821.7,285.2h0.2l0.1-0.1l0.4,0.2l0.7,0.5l0.4,0.2l0.2-0.1l0.2,0.1l0.2,0.3l0.3,0.2l0.5,0.1l0.5,0.4h0.2l0.1-0.2l0.2-0.1h0.3
                l0.4,0.2l0.6,0.4l0.3,0.2h0.1l0.1,0.1l0.1,0.2v0.2v0.1l0.1,0.2h0.2l0.2,0.1l0.2,0.3l0.2,0.2l0.1,0.1v0.1v0.1v0.1l-0.1,0.1v0.2
                l0.1,0.2l0.3,0.1l0.1,0.1v0.2l0.1,0.2l0.2,0.2l0.1,0.2v0.1l0.1,0.1l0.1,0.1v0.3v0.2l0.2,0.2v0.4l0.1,0.5l0.4,0.7l0.1,0.4v0.4
                l0.1,0.2l0.2,0.1l0.1,0.2v0.3l0.1,0.2l0.1,0.2v0.3l0.1,0.2l0.1,0.1l0.1,0.3v0.3l0.3,0.5l0.5,0.7l0.3,0.5l0.1,0.4v0.3l-0.1,0.2v0.2
                l0.1,0.1v0.2v0.2l0.2,0.2l0.5,0.4l-0.2,0.6l-0.1,0.4v0.4l0.1,0.3l0.2,0.2l0.2,0.2l0.1,0.2l0.3,0.2l0.5,0.2h0.7l0.8-0.2h0.5l0.2,0.1
                l0.1,0.2l-0.1,0.2l0.1,0.1l0.3,0.1l0.2,0.1l0.1,0.2l0.2,0.1l0.2-0.1h0.2l0.2,0.1h0.1l0.1-0.1h0.2h0.2l0.1,0.3l0.2,0.2h0.2l0.3,0.3
                l0.7,1l0.4,0.3l0.4,0.1h0.6l0.2,0.4l-0.1,0.4v0.3l0.1,0.1l0.2,0.4l0.2,0.2v0.2l0.1,0.2v0.4l-0.2,0.5l0.1,0.5l0.3,0.4l0.2,0.4v0.3
                l0.1,0.2l0.1,0.1h0.3l0.6-0.1h0.4l0.3,0.2h0.6l1-0.2h0.6l0.3,0.1l0.2-0.2l0.2-0.5l0.2-0.2h0.2l0.3-0.2l0.4-0.4l0.3-0.2h0.4h0.5
                l0.3-0.1l0.3-0.3l0.5-0.3l1-0.4l0.2-0.1h0.1l0.3,0.1l0.3,0.1l0.3,0.3v0.2v0.2l-0.1,0.1l-0.1,0.2l-0.1,0.3l0.1,0.2l0.2,0.4l0.1,0.3
                l0.2,0.4l-0.1,0.1l-0.1,0.2l-0.3,0.4l-0.2,0.2l-0.2,0.2h-0.3h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.2v0.2l0.1,0.3v0.3l-0.2,0.2
                l-0.1,0.4v0.1l-0.1,0.3v0.1v0.6l-0.2,0.4l-0.3,0.5l0.1,0.3v0.3l-0.1,0.1v0.1v0.2l-0.1,0.1l-0.2,0.2l-0.1,0.2v0.3l-0.1,0.2l-0.1,0.2
                l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.3v0.2l-0.1,0.2v0.2l-0.1,0.2l-0.2,0.1l-0.1,0.1h-0.1l-0.2,0.2l-0.2,0.7v0.4v0.4l-0.3,0.2
                l-0.2,0.2l-0.1-0.1l-0.1-0.1l-0.3-0.1l-0.2-0.1l-0.3-0.1l-0.5-0.1l-0.5-0.2l-0.3-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.2l-0.2,0.1l-0.1,0.2
                l-0.2,0.1l-0.1,0.3l-0.1,0.2l-0.3,0.3l-0.1,0.1l-0.5,0.2l-0.1,0.1h-0.4l-0.1,0.1l-0.1,0.2v0.2l0.1,0.2l0.2,0.4l0.1,0.3l0.1,0.5
                l0.4,1.6l-0.1,0.3l-0.5,0.8v0.2v0.3v0.2l0.1,0.1l0.1,0.1v0.1v0.2v0.2l-0.1,0.3l-0.1,0.4l-0.1,0.2l-0.1,0.1l-0.1,0.1v0.3v0.2
                l-0.2,0.1l-0.2,0.1h-0.2h-0.2l-0.2,0.1l-0.4,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1v0.1l0.1,0.1h0.2l0.2,0.2v0.2l-0.2,0.1l-0.1-0.1h-0.1
                l-0.3-0.2l-0.2-0.2l-0.1-0.2v-0.5V330l-0.2-0.1l-0.1-0.1l-0.1-0.1H840h-0.1l-0.1-0.1h-0.1l-0.1,0.1l-0.1,0.3l-0.2,0.4v0.3l-0.1,0.4
                l-0.1,0.5l-0.1,0.1h-0.2h-0.1l-0.1,0.2h-0.1l-0.1-0.1l-0.2-0.1H838l-0.1,0.1l-0.2,0.2l-0.1,0.2v0.2v0.2l-0.2,0.2l-0.2,0.1l-0.3,0.3
                l-0.1,0.1h-0.2h-0.3l-0.3,0.1h-0.6l-0.3-0.1l-0.4,0.1l-0.3,0.1v0.1v0.2l0.1,0.1l0.1,0.1l0.1,0.3l0.1,0.3l0.2,0.2l0.1,0.2v0.2
                l-0.1,0.2l-0.2,0.3l-0.1,0.2h-0.1l-0.2-0.1l-0.1-0.1H834l-0.6,0.1l-0.3-0.1l-0.2-0.1h-0.3l-0.4-0.1h-0.3l-0.1-0.1v-0.2l-0.1-0.1
                l-0.1-0.2l-0.2-0.2l-0.1-0.1h-0.1l-0.2,0.1l-0.2,0.1h-0.1h-0.1l-0.1,0.1l-0.3,0.2l-0.1,0.2l-0.1,0.4l-0.1,0.4l-0.1,0.1h-0.1
                l-0.1,0.1l-0.2,0.4l-0.2,0.3l-0.3,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.3h-0.3l-0.3,0.1h-0.2l-0.1,0.1l-0.1,0.2v0.1h-0.1l-0.1,0.1l-0.4,0.3
                l-0.3,0.1l-0.4,0.2l-0.3,0.2l-0.1,0.1v0.1v0.1h-0.2h-0.1l-0.3,0.3l-0.2,0.2l-0.6,0.5l-0.3,0.3l-0.1,0.4l0,0l-0.2,0.3l-0.5,0.3
                l-1,0.1l-0.3-0.2l-0.1,0.1l-0.1,0.2l-0.3,0.1h-0.4l-0.2,0.1l-0.1,0.1h-0.5l-0.2,0.2l-0.3,0.1l-1.4,0.9l-0.3,0.4l-0.3,0.4l-0.2,0.2
                l-0.2,0.1l-0.2,0.1l-0.2,0.1h-0.2l-0.2-0.1h-0.2l-0.1,0.2l0.1,0.2l-0.1,0.1l-0.4,0.1l-0.6,0.1l-0.2,0.1l-0.1,0.1h-0.1l-0.1-0.3V345
                l0.2-0.1h0.2l1.2-0.5l-0.1-0.4l0.1-0.2l0.3-0.3l0.2-0.1l-0.1-0.1l-0.8,0.1h-0.4h-0.2l0.1-0.2V343l-0.1-0.1l0.4-0.3l0.2-0.1h0.1v-0.2
                L816,342l0.1-0.3l0.8-0.4l0.2-0.4l0.3-0.3l0.6-0.8v-0.1l0.2-0.4v-0.2l-0.3-0.2l-0.1-0.3L817,338l-0.1-0.5h-0.1l-0.1,0.3l-0.1,0.1
                h-0.4l-0.2-0.1l-1-0.1l-0.2,0.2l-0.2,0.3l-0.2,0.2l-0.2,0.1l-0.2,0.2l-0.8,1.3l-0.3,0.1l-1.5,0.8l-0.7,0.3l-0.6,0.5l-0.2,0.3
                l-0.2,0.4l-0.1,0.6l-0.5,0.7l-0.2,0.1l-0.2,0.1h-0.2h-0.2l-0.4-0.1l-0.4,0.2l-0.5,0.2l-0.4-0.5l-0.3-0.1l-0.5,0.1l-0.2,0.2l-0.5,1
                l-0.2,0.6v0.2l0.3,0.7l0.3,0.4l0.7,0.5l1.5,0.3l0.3-0.1h0.4l0.4,0.3l0.2,0.5v0.3v0.1l0.1,0.1l0.1,0.2l-0.2,0.1l-0.1,0.1l-0.1,0.5
                v0.6l0.1,0.2l0.3,0.3l0.5,0.2h0.5l0.9-0.1l0.4-0.3v-0.1v-0.2l0.8-0.5l0.4-0.5l-0.1-0.1l-0.1-0.1h0.1h0.2l1.1-0.5l0.8,0.4l0.5,0.4
                l0.5,0.1l0.3,0.2l0.4,0.2h0.5h0.4l0.1-0.2l0.1-0.1h0.1l0.2,0.2l0.5,0.2h0.4l0.3-0.1l0.2,0.1l-0.3,0.3v0.5l-0.2,0.2l-0.2,0.2l0.1,0.2
                l0.1,0.1v0.2l-0.2,0.1l-0.3,0.3H818l-0.1-0.1l-0.1-0.1v-0.2l-0.1-0.1h-0.3h-0.3l-0.7,0.4l-0.7,0.3l-0.8,0.3l-0.3,0.2h-0.2l-0.3-0.1
                h-0.2v0.1l0.2,0.2l0.1,0.2v0.1l-0.1,0.1l-0.2-0.1l-0.2,0.1l-0.1,0.3v0.6l-0.1,0.1l-0.3,0.1l-0.4,0.2l-0.1-0.1v-0.1v-0.3v-0.1h-0.2
                l-0.2,0.1l-0.2,0.2l-0.1,0.1l0.2,0.4h0.2l0.1,0.1l-0.2,0.2l-0.5,0.3l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.1,0.2l-0.2,0.1l-0.3,0.1
                l-0.3,0.4l-0.2,0.4l-0.3,0.2l-0.2,0.6l-0.4,0.3l-0.1,0.6l0.1,0.3h0.4l0.2,0.1l0.4,0.4l0.5,0.3l0.5,0.2l0.7,0.4l0.2,0.2l0.2,0.4
                l0.3,1l0.2,0.5v0.3l0.3,0.5l0.3,0.9l0.4,0.7l0.1,0.6l-0.1,0.3v0.3l0.4,0.3l0.9,0.4l0.1,0.1l0.2,0.2v0.5l0.1,0.2l0.1,0.1l0.5,0.2
                l0.2,0.2l0.2,0.3l0.1,0.3v0.4h-0.3h-0.2l-0.9-0.5h-0.2l-0.3,0.1l-0.5-0.1l-0.5-0.5l-0.4-0.2l-0.4-0.1l-0.9,0.5H812l-0.1,0.1
                l-0.1,0.1l0.4,0.1l0.4-0.2l0.4-0.2l0.6,0.1l0.1,0.2l0.1,0.3l0.4,0.2l0.3,0.1l0.4,0.3l0.4,0.5l0.9,0.5l0.4,0.5l0.1,0.3l0.1,0.5
                l-0.3,0.2h-0.3l-0.4,0.1l-0.3,0.2l-0.3,0.3l-0.9,0.5l-0.2,0.3L814,374l0.2-0.1l-0.5-0.1h-0.5l-0.6,0.3l-0.1,0.1h0.1h0.1l0.3,0.1
                l0.4-0.2l0.4,0.5l0.8-0.1l0.7-0.5h0.3l0.2,0.1l0.3,0.2l0.7,0.8l0.4,0.1l0.4,0.2h0.2l0.2,0.1l-0.5,0.3l-0.6,0.6l-0.3,0.1l-0.2,0.2
                l0.5-0.1l0.4-0.3l0.2-0.1l0.1,0.1l0.1,0.4l-0.1,1.1h-0.2l-0.2-0.3l-0.2-0.1l-0.2,0.1h-0.3l-0.1,0.1l-0.1,0.2h0.2l0.4,0.3v0.2
                l-0.1,0.1l-0.3-0.1l0.3,0.3l-0.1,0.3l-0.1,0.1l-0.2,0.1l-0.1,0.2l0.2,0.4l0.2,0.5v0.2l-0.3-0.1l-0.4,0.3h-0.2l-0.2-0.4l-0.2,0.1
                l-0.1,0.1l-0.2,0.4l-0.2,0.4l-0.2,0.1h-0.2h-0.2v0.1l0.2,0.1v0.1l-0.4,0.5l-0.1,0.2v0.2l-0.2,0.2l0.1,0.3l-0.1,0.2l-0.2,0.3
                l-0.2,0.2l-0.2,0.3l-0.3,0.2l-0.4,0.7l-0.1,0.3v0.3l-0.1,0.1l-0.2,0.1l-0.2-0.1v-0.2h-0.1l-0.1-0.1v-0.2v-0.2h-0.1l-0.1,0.2
                l-0.2,0.1l-0.2-0.1l-0.2-0.1v0.2l0.1,0.2v0.2h0.2l0.2,0.2l0.1,0.3v0.1l0.1,0.1v0.1l-0.2,0.1l-0.3,0.2l-0.4,0.3l-0.3,0.2h-0.3h-0.2
                l-0.2-0.1l-0.3-0.1l0.3,0.5l0.2,0.1h0.2l0.2-0.2h0.3l0.1,0.3l-0.1,0.3l-0.2,0.4v0.3l0.2,0.5v0.1l-0.1,0.1l-0.3-0.1l-0.2-0.2h-0.2
                l-0.2-0.1l-0.2,0.1l-0.1,0.1l0.1,0.2l0.2,0.1l0.1,0.2l-0.1,0.1l-0.6-0.1h-0.1l-0.2,0.3l0.1,0.4l-0.1,0.2l-0.2,0.1l-0.3,0.2H808v0.1
                l0.1,0.1l0.1,0.1l-0.2,0.4l-0.3,0.1l-0.4-0.1l-0.3,0.1l-0.3-0.2h-0.3l-0.2,0.2v0.2H806h-0.1h-0.2v0.1l0.1,0.1h0.4l0.1,0.2v0.2
                l-0.4,0.4l-0.2,0.3h-0.3l-0.2,0.2l-0.1,0.3l-0.1-0.1h-0.3l-0.1,0.1l0.1,0.1v0.1l-0.1,0.4l-0.1,0.1l-0.1-0.1v-0.2h-0.1l-0.2,0.2
                l-0.2,0.1l-0.2,0.1l-0.1-0.1l-0.4,0.5l-0.1,0.6l-0.3,0.2l-0.1,0.1h-0.2l0.1,0.1v0.2l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.5L802,397h-0.4
                l-0.3-0.1l-0.1,0.1v0.1l-0.1,0.1h-0.3l-0.6,0.2l-0.3-0.1l-0.3-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.1h-0.3l-0.3-0.2l-0.3-0.1l-0.3,0.1
                l-0.2,0.2l-0.3,0.1l-0.1,0.1l-0.1,0.1H797l-0.1-0.3h-0.2l-0.2,0.2l-0.1,0.1l-0.1,0.1v0.3h-0.2l-0.2-0.2h-0.2l-0.2,0.2h-0.1h-0.1
                h-0.2H795h-0.1l-0.1,0.1l0,0l-0.2-0.1l-0.3-0.2l-0.2-0.4l-0.3-0.2l-0.1-0.2V397v-0.2v-0.2l0.1-0.2l-0.3,0.1l-0.2,0.1v0.2l-0.1,0.2
                l-0.3,0.1v0.1v0.1l0.2,0.2v0.2l0.1,0.1l0.2,0.3v0.6l0.1,0.2v0.2l-0.1,0.2l0,0l-0.1-0.1h-0.1v0.1v0.1l0,0H793h-0.1l-0.1,0.1l-0.2,0.2
                h-0.3l-0.2-0.4l-0.2,0.3l-0.1,0.5l-0.1,0.1l-0.1,0.1l-0.3-0.2l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.3-0.1l-0.2-0.2l0.1-0.1V400
                l-0.1-0.1h-0.1l0.1,0.2v0.4l-0.1,0.1l-0.1,0.1l-0.3-0.1l-0.2-0.1l-0.3-0.1H789v0.2l-0.1,0.2h-0.1h-0.1l-0.2,0.2l-0.1,0.1l-0.2,0.1
                l-0.6,0.1l-0.2,0.1h-0.3h-0.2h-0.1l-0.1-0.1h-0.1l-0.1,0.2l-0.3,0.1h-0.3l-0.3,0.3l-0.2,0.2h-0.2l-0.1-0.1l-0.1-0.3h-0.1v0.3
                l-0.1,0.2l-0.1,0.1l-0.4,0.3l-0.1,0.3l0.1,0.3l0.5,0.1l0.1,0.1v0.1l-0.1,0.1v0.1l0.6,0.5v0.2l-0.1,0.1l-0.1,0.2l-0.3,0.2l-0.6,0.1
                l-0.5-0.1l-0.2-0.2v-0.1h0.1h0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.3v-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.1l-0.1-0.1l0.1-0.4l-0.1-0.2
                l0.2-0.3l0.1-0.3l0.4-0.1v-0.3h-0.3l-0.2-0.2v0.1h-0.2l-0.3-0.4l-0.1-0.1h-0.1l0.1,0.5l-0.3,0.2l-0.2,0.1h-0.4l-0.2,0.1h-0.2v-0.1
                l0.1-0.2l-0.1-0.1l-0.2-0.1h-0.3h-0.2h-0.2l-0.1-0.1L780,401l-0.2-0.1l-0.1-0.1l0.1-0.2l-0.1-0.1h-0.3v0.2v0.3l0.1,0.2l-0.1,0.1
                l-0.2,0.1l-0.2-0.2l-0.1-0.1h-0.1l-0.1,0.2l-0.2,0.2h-0.3l-0.2,0.1l-0.3,0.1l-0.2-0.2l-0.3-0.3h-0.1l-0.3,0.1h-0.5l-0.1-0.1
                l-0.2,0.1l-0.2-0.3h-0.3l-0.3-0.2l-0.1-0.1v-0.2l-0.1-0.1h-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1h-0.1h-0.1V400v-0.4v-0.3v-0.1v-0.3
                l-0.1-0.1v-0.2v-0.3l0.1-0.2h0.1l0.2-0.2l0.1-0.2l0.1-0.2l-0.4-0.3l-0.2-0.1h-0.3l-0.3,0.1h-0.2h-0.1l-0.2-0.3h-0.1h-0.2h-0.2
                l-0.1,0.1h-0.2H772l-0.2-0.1l-0.3-0.2l-0.4-0.1v-0.1l-0.1-0.2l-0.2-0.2l-0.3-0.2l-0.2-0.1l-0.1,0.1l-0.1,0.1l-0.5,0.3l-0.2,0.1
                l-0.1,0.1l-0.1,0.1v0.3l-0.1,0.3l-0.2,0.2l-0.1,0.1h-0.2h-0.2h-0.1l-0.4,0.3h-0.2l-0.2-0.2l-0.1-0.1h-0.2l-0.2,0.1l-0.1,0.3
                l-0.1,0.2l-0.1,0.1h-0.1h-0.1l-0.8-0.7l0,0l-0.1,0.1l-0.1,0.4l-0.1,0.1h-0.1l-0.3-0.5h-0.1h-0.1l-0.1,0.2l-0.2,0.2l-0.2,0.1v0.2
                l-0.2,0.1l-0.2,0.1h-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.2,0.3l-0.2,0.2
                l-0.2,0.2l-0.1,0.1l-0.1-0.1l-0.2-0.1l0,0l-0.3,0.2l-0.2-0.3h-0.1h-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1v0.1l0.1,0.1
                v0.1v0.2l0.1,0.2l0.3,0.3l0.1,0.2v0.1v0.5v0.2l-0.1,0.4v0.2l0.1,0.1l0.1,0.1v0.1l0,0l-0.2,0.1h-0.1l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.1
                h-0.3l-0.5,0.1h-0.1l-0.1-0.1v-0.2v-0.2v-0.1l-0.1-0.1l0.1-0.3l-0.2-0.1l0,0l-0.1-0.4v-0.1l0,0h-0.1l-0.2,0.1l-0.5,0.2l-0.4,0.5
                l-0.2,0.1h-0.2l-0.2-0.1l-0.3-0.1l-0.4,0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1v-0.2v-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.2V400v-0.2v-0.3
                l-0.1-0.1l-0.3-0.1l-0.7-0.1l-0.6-0.1h-0.2h-0.2h-0.1L753,399v-0.1l0.1-0.3l0.1-0.3l0.3-0.4v-0.3v-0.3l0.1-0.4l0.2-0.3l0.1-0.1v-0.1
                l-0.1-0.1l-0.1-0.1l-0.2-0.1h-0.3l-0.5-0.1l-0.5-0.2l0.1-0.4v-0.2l-0.1-0.2l-0.1-0.1l-0.1-0.1l0.1-0.3l-0.1-0.3l-0.1-0.1l-0.2-0.2
                v-0.2l0.1-0.2l0.4-0.4V393h-0.1H752l-0.5,0.1l-0.1-0.1l-0.2-0.1h-0.4h-0.4l-0.5,0.1l-0.5,0.2l-0.2,0.2l-0.2,0.1h-0.2l-0.2-0.1v-0.2
                l0.3-0.4v-0.3l0.3,0.4v-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.1V392l0.2-0.4l0.2-0.1l0.1-0.1v-0.1l-0.1-0.3v-0.2l0.1-0.4l0.1-0.3h0.3
                l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.5l0.1-0.1l0.3,0.1l0.1-0.1l0.2-0.3l0.2-0.3l0.3-0.1h0.2l0.1-0.1v-0.2l-0.2-0.3l-0.1-0.2
                v-0.1l0.3-0.1l0.1-0.2v-0.3l0.1-0.4l0.1-0.5v-0.4v-0.3v-0.3v-0.5l-0.1-0.5v-0.2V383l-0.1-0.5l-0.1-0.1l-0.3-0.2h-0.1l-0.1,0.1v0.2
                l-0.1,0.2H751l-0.1-0.2l-0.1-0.2l-0.3-1V381l-0.1-0.3l-0.1-0.1l-0.1-0.1l-0.2-0.3l-0.1-0.1h-0.1h-0.1h-0.1l-0.1-0.2l-0.1-0.2
                l-0.1-0.1l-0.2-0.1h-0.2l-0.1,0.2l-0.1,0.1l-0.1,0.2l-0.2,0.3l-0.1,0.1l-0.1-0.1l-0.4-0.3l-0.2-0.1l-0.3,0.1l-0.4-0.1h-0.2l-0.3-0.3
                h-0.1l-0.5,0.2l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.1v-0.1v-0.1l0.2-0.3l0.5-0.5l-0.1-0.2l-0.2-0.4v-0.2l-0.1-0.1l-0.2,0.1
                l-0.5,0.4H745V378l-0.1-0.4l0.2-0.1l0.3-0.1l0.2-0.2v-0.1h-0.1h-0.3l-0.1-0.1l-0.2-0.3l-0.1-0.1l-0.1-0.1l-0.4,0.2l-0.5,0.2
                l-0.5,0.3v0.2h-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.1h-0.1h-0.2l-0.4-0.2l-0.4-0.1h-0.1l-0.6-0.1v-0.1l-0.1-0.2l-0.2-0.1h-0.2l-0.4,0.3
                l-0.5,0.2l-0.3,0.3l-0.2,0.3l-0.3,0.1v0.2l-0.1,0.1l-0.2,0.2l-0.4,0.2l-0.3,0.1l-0.9,0.1l-0.3,0.1l-0.1,0.1l-0.1,0.3l-0.1,0.3
                l-0.2,0.2l-0.4,0.3l-0.5,0.3l-0.1,0.2v0.1h0.1l0.1,0.1v0.1l-0.1,0.1l-0.3,0.2l-0.2,0.1l-0.2,0.1h-0.2h-0.2h-0.1H734h-0.2h-0.2
                l-0.3,0.2h-0.2l-0.2-0.1h-0.3h-0.2v-0.1v-0.4l-0.1-0.1l-0.3-0.1l-0.4-0.1l-0.2-0.1h-0.1h-0.1L731,381l-0.2,0.2h-0.2l-0.2-0.1
                l-0.2-0.1l-0.5-0.1h-0.2h-0.4h-0.3h-0.1v-0.1l0.1-0.2v-0.2v-0.1l-0.4-0.1l-0.3-0.1h-0.3l-0.2,0.1l-0.2,0.1l-0.2,0.2l-0.3,0.1
                l-0.3,0.2l-0.2,0.1l-0.2,0.3l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.2,0.2l-0.2,0.2l-0.2,0.5l-0.2-0.1l-0.2-0.2v-0.3l0.2-0.8l0.1-0.3
                l-0.1-0.1l-0.1-0.3l-0.1-0.1l-0.4-0.2h-0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.4,0.1l-0.4,0.1h-0.1v0.1v0.1h-0.2h-0.3h-0.2l-0.5,0.2
                h-0.2H721h-0.3h-0.5l-0.4-0.1l-0.3-0.3l-0.2-0.1l-0.3-0.1l-0.3-0.1l-0.1-0.2l-0.1-0.1h-0.2l-0.2,0.1l-0.1,0.4l-0.1,0.1h-0.2
                l-0.2-0.1l-0.3-0.2l-0.1-0.2l-0.1-0.1l-0.2,0.1v0.3v0.2l-0.2,0.1l-0.1-0.1l-0.1-0.2l-0.2-0.5l-0.2-0.3l-0.2-0.2H715l-0.6-0.1
                l-0.3-0.1l-0.1-0.2l0.1-0.4l0.1-0.3V379h-0.1h-0.2l-0.6,0.2h-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.5-0.3l-0.2-0.2l-0.3-0.2
                l-0.2-0.1l-0.1-0.4l-0.1-0.4l-0.1-0.2l-0.2-0.1l-0.2-0.1l-0.4,0.2l-0.3,0.1h-0.2l-0.4-0.4l-0.3-0.4l-0.3-0.4l-0.2-0.2h-0.4l-0.4-0.2
                l-0.6-0.5l-0.4-0.4l-0.7-0.4L706,374l-0.1-0.1l-0.1-0.3l-0.2-0.3l-0.5-0.1l-0.6-0.1l-0.6,0.2l-0.5,0.8l-0.2,0.2H703l-0.2-0.2
                l-0.1-0.2l-0.1-0.2l-0.3-0.2l-0.4-0.2l-0.2-0.2l-0.2-0.1h-0.2l-0.4-0.1l-0.4-0.2h-0.2v-0.1l0.1-0.3v-0.2l-0.1-0.1h-0.2l-0.4-0.3l0,0
                l-0.1-0.1l-0.2-0.1H699l-0.3,0.1l-0.2-0.1l-0.3-0.2l-0.1-0.1l-0.1-0.1l-0.3-0.4l-0.4-0.5l-0.2-0.1H697l-0.1,0.3h-0.1h-0.1l-0.2,0.1
                h-0.1h-0.1v-0.1V370l-0.1-0.1l0.1-0.3l0.1-0.2l-0.1-0.2l-0.2-0.2v-0.2l0.1-0.3v-0.2v-0.1l-0.1-0.1l-0.5-0.6v-0.1l-0.1-0.2v-0.3
                l-0.1-0.2l-0.1-0.2v-0.1h0.1h0.3h0.3l0.2-0.1l0.1,0.1v0.2l0.1,0.1l0.2,0.2l0.2,0.2h0.2l0.2-0.1l0.2-0.3l0.1-0.1h0.1l0,0v-0.2v-0.5
                v-0.2l-0.1-0.2v-0.2V365l-0.2-0.2l-0.1-0.1v-0.1l0.1-0.2v-0.2l-0.1-0.2l0,0l-0.1-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.2
                v-0.5v-0.5l-0.1-0.3l-0.1-0.4v-0.1l0.1-0.1l0.5-0.3l0.1-0.1v-0.1v-0.1l-0.1-0.2l-0.2-0.1l-0.3-0.2l-0.3-0.2l-0.4-0.1l-0.5-0.2
                l-0.1-0.2l-0.1-0.4l-0.2-0.6l-0.2-0.6l-0.2-0.4v-0.2l0.1-0.5v-0.1h-0.1l-0.2,0.1l-0.1,0.1h-0.1l-0.1-0.1l0,0l0,0l-0.2,0.1h-0.4H693
                h-0.2l-0.4-0.1l-0.6-0.2l-0.6-0.2l-0.3-0.2l-0.1-0.1l-0.3-0.2l-0.2-0.1v-0.4h-0.1l-0.3,0.1l-0.4,0.1h-0.2l-0.1-0.1l-0.1-0.4L689,355
                h-0.2l-0.2-0.1l-0.1-0.1v-0.1l0.1-0.2l0.1-0.1v-0.7l-0.1-0.3l-0.1-0.2l-0.1-0.3l-0.1-0.2l-0.2-0.2l-0.3-0.2l-0.3-0.1l-0.3,0.1h-0.1
                l-0.1-0.5l-0.1-0.1l-0.6-0.2H686l-0.3,0.1l-0.2,0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1h-0.3h-0.2l0,0l-0.1-0.1l-0.3-0.3v-0.1l0.5-0.3
                l0.3-0.1l0.2-0.1l0,0l0.1,0.1l0.2,0.1h0.1l0.1-0.1l0.3-0.2l0.2-0.2l0.1-0.1l-0.1-0.2l-0.3-0.3l-0.2-0.1l-0.1-0.1l0.1-0.3l0.1-0.3
                v-0.1l-0.1-0.1v-0.3l-0.1-0.4l-0.1-0.2v-0.3V347l0.2-0.5l-0.1-0.2l-0.2-0.2l-1.1-0.2l-0.7-0.2h-0.3H683l-0.2,0.2l-0.1,0.2h-0.2
                l-0.3-0.1v-0.1l-0.1-0.3l-0.1-0.4l-0.1-0.1V345l0.1-0.1l0.2-0.1v-0.1v-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.4v-0.2v-0.4V343h0.2l0.3-0.1
                l0.1-0.2l0.1-0.2v-0.1l-0.1-0.4l-0.1-0.2v-0.1l0.1-0.1l0.1-0.2l0.2-0.4l0.1-0.2l0.1-0.1l0.2-0.1l0.4-0.1l0.5-0.2l0.6-0.5l0.2-0.1
                l0.2-0.1h0.2v-0.1l-0.1-0.3v-0.1l0.1-0.1h0.1l0.4,0.2h0.3l0.4-0.1l0.8-0.5h0.1l0.1,0.1l0.1,0.3l0.1,0.7l0.1,0.1h0.5l0.4-0.2l0.2-0.1
                h0.3l0.1-0.1l0.1-0.1l0.2,0.3l0.2-0.1l0.2-0.2l0.1-0.2l0.2-0.2l0.1-0.3v-0.3l0.1-0.1l0.1-0.1l0.3-0.6l0.2-0.2l0.2-0.1l0.5,0.1h0.3
                l0.8,0.1l0.4-0.1l0.3-0.1h0.4l0.5-0.1l0.6-0.8v-0.2v-0.2l0.2-0.2l0.3-0.2l0.6-0.4l1.1-0.6l0.4-0.2h0.2l0.4-0.1l0.7-0.3l0.2-0.4
                l0.2-0.1l0.9-0.1h0.1v-0.1v-0.3l-0.1-0.2l0.1-0.1l0.1-0.1v-0.1l-0.2-0.5l-0.1-0.5V331v-0.2l0.1-0.3l0.1-0.2l0.5-0.2l0.3-0.1v-0.1
                l-0.3-0.1l-0.2-0.1v-0.1v-0.1v-0.1l0.3-0.2l0.3-0.2l0.4,0.1l0.1-0.1v-0.2l-0.1-0.2l-0.1-0.3l-0.2-0.1V328l0.1-0.3l-0.1-0.5l-0.2-0.5
                l-0.3-0.8l-0.2-0.2l-0.1-0.4l-0.1-0.2v-0.3v-0.2v-0.4l-0.1-0.4l0.1-0.4l0.1-0.3V323l0.1-0.1h0.2l0.1-0.1l-0.1-0.1l-0.3-0.1l-0.3-0.1
                h-0.4h-0.4l-0.2-0.1l-0.2-0.1v-0.1l0.2-0.2l0.3-0.2l0.5-0.1l0.5-0.2l0.3-0.1l0.4-0.1h0.4h0.2l0.5-0.2l0.8-0.3l0.8-0.3l0.3-0.2
                l0.2,0.2l0.1,0.3l0.2,0.2l0.2,0.1h0.1l0.4-0.1l0.4-0.1l0.2,0.1l0.2,0.2l0.2,0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.3v-0.3v-0.2l-0.1-0.1
                l-0.4-0.1l-0.4-0.2v-0.2v-0.1l0.1-0.3l0.2-0.6l0.2-0.8l0.1-0.6l0.4-0.9l0.3-1l0.5-1.4l0.1-0.3v-0.4v-0.2l0.2-0.1l0.3,0.1l0.7,0.3
                l0.5,0.3l0.2,0.1l0.3,0.1l0.5,0.1h0.3l0.3-0.1h0.3l0.5,0.1h0.2h0.2l0.1,0.1l0.1,0.3l0.1,0.1l0.2-0.1l0.4-0.3l0.3-0.2l0.3-0.3h0.3
                l0.4-0.1l0.1-0.2l0.1-0.4l0.2-0.3v-0.3l-0.1-0.2l-0.2-0.4v-0.3l-0.1-0.8l-0.1-0.7l0.1-0.6l0.2-0.6l0.1-0.2l0.1-0.3l0.2-0.3l0.2-0.1
                l0.6-0.1l0.6-0.1l0.3-0.1l0.3-0.1l0.2-0.1l0.3-0.5l0.2-0.3l0.1-0.7l-0.1-0.3l0.1-0.3l0.2-0.2l0.2-0.2h0.1l0.3-0.1h0.5l0.3,0.1h0.3
                l0.2-0.1l0.1-0.1h0.2l0.3-0.1l0.3-0.1h0.1v0.2v0.2l0.1,0.2l0.1,0.1v0.1l-0.1,0.1l-0.1,0.1v0.2l0.2,0.2l0.1,0.2l0.3,0.1l0.2,0.1
                l0.1,0.1v0.1l-0.1,0.1l-0.1,0.2v0.1v0.1l0.2,0.1l0.3,0.1l0.4,0.2l0.3,0.3l0.3,0.1l0.1,0.3v0.4l0.3,0.2l0.4,0.3l0.2,0.1l0.1,0.2
                l0.2,0.2l0.2,0.1h0.2l0.4-0.2h0.4l0.2,0.1l0.2,0.4l0.2,0.1l0.1,0.1l0.1,0.1h0.1l0.2-0.1l0.1-0.2h0.2l0.1,0.1v0.2l0.1,0.2l0.2,0.2
                l0.3,0.1v0.1v0.2l0.1,0.2l0.1,0.2l0.1,0.4v0.3l0.1,0.2l0.2,0.3l0.3,0.5l0.2,0.4l0.2,0.1l0.2,0.1l0.1,0.3l0.2,0.5v0.4v0.3l0.1,0.1
                v0.2l-0.2,0.6l-0.1,0.3v0.2l0.1,0.4l0.1,0.3v0.3l-0.1,0.2l-0.2,0.3l-0.1,0.1l-0.2,0.1l-0.2,0.5l-0.1,0.5v0.3l0.1,0.2l0.2,0.2v0.2
                l0.3,0.4l0.1,0.3h0.1l0.1-0.1h0.3l0.2,0.1l0.2,0.2l0.3,0.1h0.4l0.2,0.1l0.2,0.1h0.4h0.3h0.5l0.4,0.1l0.7,0.1h0.4l0.6-0.1l0.4,0.1
                l1.1,0.1l0.6,0.2l0.4,0.2l0.3,0.3l0.3,0.4l0.3,0.2l0.7,0.1l0.5,0.5l0.4,0.2l0.6,0.5l0.4,0.2l0.5,0.2l0.8-0.1v0.1l-0.1,0.3l-0.1,0.4
                v0.3l0.1,0.1l0.3,0.1l0.2,0.1l0.1,0.2l0.1,0.1l0.3,0.7l0.4,1.1v0.4l0.1,0.3l0.5,0.4l0.2,0.3l0.4,0.3l0.1,0.3v0.4l0.1,0.1l0.7-0.1
                l0.6-0.1l1-0.1l1.4,0.2l1.5,0.2l1.3,0.2l0.6,0.1l1.5,0.2l0.8-0.2l0.6-0.2h0.3l1.2,0.2l0.7,0.1l0.9,0.1l0.6,0.1h0.5l0.2,0.1l0.2,0.1
                l0.2,0.1l0.5,0.7l0.3,0.3l0.5,0.2l1.2,0.2l0.7,0.2l0.7,0.2l0.5,0.3l0.6,0.3l0.7,0.4l0.8-0.2l0.9-0.2l0.5-0.1v0.8l0.8,0.1h0.2
                l0.3,0.2l0.2-0.1l0.2-0.2l0.2-0.3l0.3-0.1l0.6-0.3l0.1-0.1l0.8-0.4l1.3-0.6l0.6-0.3l0.2-0.1l0.3-0.1l0.2-0.1l0.4-0.1h0.5l0.6-0.1
                l1.3-0.2h0.2l0.7-0.1l0.3-0.1h0.5h0.6h0.4h0.5l0.7-0.1h0.6l0.3-0.1l0.4-0.2l0.3-0.2l0.5-0.2l0.6-0.2l0.4-0.2l0.3-0.1l0.3-0.1
                l0.1-0.2l0.1-0.1l0.2-0.2l0.3-0.4l0.2-0.3l0.1-0.1l0.3-0.3l0.2-0.2l0.3-0.3l0.2-0.1l0.3-0.1l0.7-0.3l0.1-0.1h0.1l0.3-0.3l0.2-0.2
                l0.1-0.2l0.3-0.1l0.2-0.1v-0.2v-0.2l-0.1-0.2l-0.1-0.2l-0.4-0.4l-0.2-0.3l-0.2-0.3l-0.1-0.3l-0.2-0.2l-0.1-0.2v-0.2l0.2-0.4l0.1-0.2
                l0.1-0.4l0.2-0.6l0.2-0.3l0.2-0.3l0.4-0.4l0.4-0.1l0.2,0.1h0.5h0.3l0.2,0.2l0.3,0.4l0.3,0.1l1,0.3l0.4,0.1h0.3l0.4,0.1h0.1l0.2,0.1
                l0.2-0.1l0.3-0.2l0.4-0.3l0.2-0.1l0.3-0.1l0.1-0.1l0.2-0.3l0.3-0.2l0.4-0.4l0.2-0.3v-0.2v-0.2l0.1-0.1l0.2-0.1h0.3l0.5,0.2h0.7h0.2
                l0.6-0.1l0.3-0.1l0.4-0.1l0.3-0.3l0.4-0.4l0.3-0.2h0.2l0.2-0.2l0.1-0.2v-0.2v-0.2l0.1-0.3l0.3-0.5l0.2-0.3l0.2-0.2l0.1-0.3l0.2-0.1
                h0.2l0.3-0.2h0.2l0.3,0.1h0.5h0.3h0.2l0.1-0.1V316l0.1-0.4v-0.1l0.1-0.1h0.3l0.2,0.1l0.1,0.1h0.2l0.2-0.1l0.3-0.3l0.5-0.2h0.2
                l0.4-0.2l0.3,0.1h0.5h0.2h0.2l0.2-0.2l0.2-0.1l0.3,0.1l0.2,0.2l0.4,0.2l0.5,0.1l0.4-0.1l0.4,0.1h0.2l0.1-0.1l0.3-0.2l0.1-0.2v-0.2
                v-0.3l-0.1-0.2l-0.2-0.3l-0.1-0.2v-0.3l-0.1-0.2l-0.3-0.3l-0.2-0.1l-0.4-0.5l-0.1-0.1v-0.1v-0.2l-0.2-0.1l-0.2-0.1l-0.1-0.1
                l-0.1-0.2V311l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.2-0.3l-0.3-0.5l-0.2-0.2l-0.7-0.1l-0.3-0.1h-0.3l-0.2,0.1h-0.4h-0.2l-0.3,0.3
                l-0.3,0.4l-0.3,0.3l-0.2,0.3l-0.1,0.1l-0.2-0.1l-0.2-0.3l-0.4-0.3l-0.3-0.1l-0.1-0.1l-0.4-0.1h-0.3l-0.4,0.1H801l-0.2-0.1h-0.2
                l-0.4,0.3l-0.2,0.3l-0.3,0.1l-0.2-0.2l-0.3-0.3l-0.3-0.3l-0.2-0.3l-0.1-0.8l0.3-0.2l0.4-0.3v-0.4v-0.5l0.1-0.5l0.4-0.5l0.2-0.4v-0.2
                l0.2-0.4l0.2-0.4l0.2-0.6l0.4-1l0.5-1.2l0.3-0.6l0.6,0.4l0.4,0.2l0.6,0.3l0.6,0.1l0.6,0.3l0.3,0.1h0.2l0.9-0.8l0.7-0.7l0.9-0.5
                l0.6-0.1l0.5-0.1l0.3-0.2l0.2-0.4l0.1-0.5l-0.1-0.3l-0.3-0.1l-0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.3l0.2-0.3l0.3-0.3l0.2-0.3v-0.4
                l0.2-0.4l0.3-0.4l0.2-0.3v-0.3l0.2-0.4l0.4-0.7l0.3-0.8l0.5-0.6l0.8-0.6l0.5-0.6l0.2-0.6V291l-0.2-0.4v-0.4l0.1-0.4l-0.1-0.3
                l-0.4-0.2l-0.5-0.1l-0.5,0.1l-0.3-0.1l-0.1-0.4l0.1-0.3l0.3-0.2l0.6-0.6l0.8-1l0.8-0.5l1.2-0.2l1-0.3l1-0.3l0.7-0.2l0.1,0.1h0.4
                l0.6-0.1l0.6-0.1l0.6-0.2l0.4-0.1l0.3,0.1L821.7,285.2L821.7,285.2z"/>
            <linearGradient id="CO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="214.3579" x2="0.1864" y2="214.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CO" class="stcustom0 st47" d="M274.9,429.5h-0.1l-0.1,0.1l-0.4,0.1l-0.5,0.1l-0.7,0.2l-0.2,0.2l-0.7,1.2l-0.6,0.2l-0.2,0.2l-0.2,0.2
                l-0.3,0.4l-0.1,0.3l-0.4,0.7l-0.2,0.8l-0.1,0.5l-0.1,0.7l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3v0.1l0.1,0.1l0.4-0.1l0.2-0.1
                l0.2-0.1l0.1,0.1l0.1,0.3h0.2h0.1l0.2,0.1l0.2,0.7l0.2,0.6l0.4,0.4l0.3,0.3l0.1,0.3l0.1,0.4v0.2l-0.1,0.1l-0.2,0.2v0.4v0.1v0.4v0.2
                l0.1,0.2l0.1,0.1l0.3,0.1l0.2,0.1l0.1,0.3l0.2,0.4l0.2,0.2l0.3,0.1h0.2l0.5-0.1h0.5l0.7,0.1h0.2h0.3l0.6-0.2h0.2h0.2l0.3,0.1
                l0.2,0.1l0.2,0.1l0.3,0.1h0.2h0.2h0.1l0.5,0.7l0.5,0.6l0.4,0.5l0.4,0.5l0,0l0.2-0.1h0.1l0.1,0.1h0.2l0.3-0.2h0.4l0.6,0.1h0.7
                l0.9-0.1l0.6-0.1l0.2-0.1h0.4l0.4,0.1l0.2,0.2v0.2l0.1,0.3l-0.1,0.3l-0.3,0.3l-0.2,0.3v0.4l-0.1,0.3l-0.3,0.2l-0.1,0.3l0.1,0.4v0.6
                l-0.1,0.7v0.4l0.1,0.1l0.1,0.2v0.3v0.2l0.1,0.3l0.2,0.6l0.2,0.3l0.1,0.1l0.1,0.1l0.4,0.6l0.1,0.1v0.1v0.1v0.1l-0.5,0.4l-0.9,0.8
                l-0.1,0.1v0.2l0.3-0.1l0.3,0.1h0.1l0.1,0.1l0.1,0.2h0.1l0.1,0.1l0.3,0.2l0.2,0.2l0.2,0.1l0.1,0.1v0.2v0.2l0.1,0.4l0.1,0.1v0.1v0.1
                l0.1,0.2l0.1,0.3l0.2,0.4v0.2l0.1,0.1l0.1,0.3l0.1,0.3v0.2l0.1,0.2l-0.5,0.1l0,0v-0.1v-0.5v-0.6l-0.1-0.2l-0.2-0.4l-0.3-0.5
                l-0.1-0.1l-0.1-0.1h-0.2h-0.1l-0.2,0.1L285,457l-0.3,0.3l-0.3,0.4l-0.2,0.1H284h-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.2-0.1l-0.1,0.1
                l-0.1,0.2v0.2l0.1,0.2l0.1,0.2h-0.2h-0.6h-0.7h-0.7h-0.6h-0.5h-0.2l-0.2-0.1h-0.2h-0.1l-0.2,0.1h-0.3l-0.2,0.1h-0.1v0.5v0.7v0.7
                l0.2-0.1h0.1h0.1h0.3h0.1h0.1h0.1l0.1,0.1h0.1l0.1-0.1h0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.1v0.2v0.1v0.1v0.1v0.1l0,0l0,0h-0.1h-0.1
                h-0.1l-0.1-0.1h-0.1h-0.1h-0.1l-0.1-0.1l-0.1-0.1h-0.2h-0.1l-0.1,0.1l-0.1,0.1h-0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.2H278h-0.2v0.4
                v0.7v0.5v0.4l0.1,0.2l0.3,0.3l0.3,0.2l0.2,0.2l0.2,0.1l0.1,0.1l0.1,0.1v0.1v0.1v0.1l-0.1,0.1v0.1l0.1,0.1v0.1l0.1,0.1v0.1l0.1,0.1
                l0.1,0.1l0.1,0.1v0.1v0.1v0.1l0.1,0.2v0.1v0.1v0.5l-0.1,0.6l-0.1,0.4l-0.1,0.7l-0.1,0.8l-0.2,1l-0.2,1l-0.2,0.9l-0.2,0.9l-0.2,0.9
                l-0.1,0.6v0.1l-0.1-0.2l-0.2-0.2l-0.2-0.1l-0.1-0.2l-0.1-0.3l-0.2-0.1l-0.1-0.1H277l-0.1,0.1l-0.2,0.1h-0.1l-0.5-0.2H276l0.3-0.5
                l0.6-0.9l0.4-0.6l0.4-0.6l0.2-0.3v-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.2l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.2-0.1h-0.2
                l-0.2-0.2l-0.6-0.3h-0.2L275,469l-0.2,0.1l-0.2,0.2l-0.3,0.1H274l-0.1-0.1h-0.1l-0.2-0.2l-0.3-0.1l-0.2-0.1H273l-0.2,0.2l-0.2,0.2
                l-0.1,0.1h-0.2l-0.2,0.2l-0.2,0.1h-0.2l-0.3,0.1l-0.3-0.1l-0.2-0.1h-0.1h-0.1l-0.2,0.1h-0.3H270h-0.2l-0.1-0.2l-0.2-0.1l-0.2-0.1
                l-0.1-0.2V469l0.1-0.2l-0.1-0.2l-0.1-0.3v-0.1l-0.1-0.1h-0.1h-0.2l-0.3-0.1l-0.2-0.1l-0.1-0.2l0.1-0.3l-0.1-0.2l-0.2-0.1l-0.1-0.2
                l-0.2-0.2l-0.2-0.1h-0.2l-0.2-0.1l-0.2-0.2l-0.2-0.1l-0.2-0.2l-0.4-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2v-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.3
                l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.1h-0.1H264h-0.2l-0.2-0.1l-0.1-0.1l-0.2-0.2h-0.1h-0.1
                l-0.2,0.2l-0.5-0.2L262,463l-0.4-0.1l-0.3-0.2l-0.3-0.3l-0.1-0.2l-0.1-0.1l-0.6-0.3h-0.1l-0.2,0.1l-0.1,0.1v0.2v0.1l-0.2,0.1h-0.3
                l-0.2-0.1H259v0.1h-0.1h-0.2l-0.3-0.1l-0.2-0.1l-0.3-0.2h-0.1h-0.4l-0.3-0.1l-0.1-0.1l-0.1-0.6l0,0h-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2
                l-0.1-0.2h-0.4l-0.6-0.2l-0.4-0.2l-0.4-0.2l-0.6-0.4l-0.2-0.1l-0.3-0.1l-0.2-0.2l-0.3-0.2l-0.1-0.1l-0.1-0.2l-0.4-0.3l0.2-0.4
                l0.5-0.3l0.6,0.2l0.1-0.4l-0.2-0.4v-0.7l0.1-0.1l0.2-0.2l0.2-0.1h0.1l0.2,0.1l0.1-0.1l0.5,0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.2
                l0.1-0.2l0.1-0.1h0.2v-0.1l0.1-0.1l0.3-0.3v-0.1l-0.1-0.2v-0.1h0.2l0.2-0.1l0.1-0.2l0.1-0.2l0.2-0.3h0.2l0.1-0.4l0.2-0.3l0.5-0.9
                h-0.1l-0.1,0.1h-0.1l-0.1-0.1v-0.4h-0.1l-0.2,0.3l-0.2-0.3v-0.2l0.1-0.2v-0.1l-0.3,0.1v-0.1l0.2-0.1l0.1-0.1l0.2-0.1l0.1-0.2v-0.3
                l0.1-0.4l-0.1-0.2l-0.1-0.1l-0.1-0.7v-0.4v-0.3l-0.1-0.3l-0.4-0.3l0.6-0.4l0.2-0.3l-0.3-0.6l-0.4-0.5v-0.3h0.1h0.1l0.1-0.6v-0.2
                l-0.2-0.3h-0.2l-0.2-0.4l-0.1-0.1l-0.1-0.3l-0.3-0.5l-0.3-0.3l0.2-0.6l0.2-0.1l0.1-0.1l-0.1-0.4v-0.1l0,0l0,0l0.1,0.1l0.1,0.2
                l0.1,0.2l0.1,0.1l0.1-0.1l0.5-0.4v-0.1v-0.2l0.2-0.2l0.2-0.1l0.1-0.1v-0.2l0.6-0.8l-0.2-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.2l0.1-0.2
                l0.2-0.2h0.1l0.1,0.1l0.2,0.4l0.4,0.3l0.4,0.4l0.2,0.3l0.1,0.1l0.1,0.1v0.1l-0.1,0.1v0.2l0.1,0.1l0.1,0.1h0.2l0.1-0.2l-0.1-0.9
                l-0.1-0.4l-0.2-0.1l-0.1-0.2l0.1-0.1l0.2-0.1l0.3-0.2l1.2-0.8l0.4-0.8l0.3-0.3l0.3-0.2h0.4l0.3-0.1l0.1-0.2l-0.1-0.3l-0.1-0.2
                l0.1-0.3l0.1-0.4v-0.4l0.2-0.2l-0.1-0.1l-0.2,0.2l-0.2,0.1l0.1-0.1l0.3-0.4l0.2-0.6l0.1-0.2l0.5-0.3l0.1-0.2l0.4-0.2l0.6-0.5
                l0.2-0.1l1.1,0.3h0.3l-0.1,0.1h-0.2l-0.2,0.1l-0.1,0.2l0.2,0.2l0.2,0.1l0.1-0.1l0.1-0.4l0.2-0.4l0.1-0.5l0.2-0.2l0.2-0.1l0.4,0.1
                l0.3,0.1h0.3l1-0.1l1.7-1.2l0.8-0.3l0.5-0.2l0.3-0.5l0.1-0.4l0.2-0.1h0.2l0.1-0.1v-0.1l0.6-0.3h0.3h0.3l0.6,0.3l0.3,0.5v0.3
                l-0.4,0.4L274.9,429.5L274.9,429.5z M255.9,455.7L255.9,455.7h-0.2v-0.1l0.1-0.1h0.1l0.1,0.1L255.9,455.7z"/>
            <linearGradient id="CR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="229.7578" x2="0.1864" y2="229.7578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CR" class="stcustom0 st48" d="M240.4,432.2l0.1,0.1l0.1,0.2v0.2l0.4,0.8l0.3,0.4l0.6,0.8l0.3,0.1l0.5,0.6l0.2,0.1l0.1,0.2l0.5,0.2
                l0.1,0.1v0.1v0.1l-0.1,0.1h-0.1l-0.2-0.1l-0.2-0.1l-0.1,0.1v0.2l-0.1,0.1h-0.1v0.1v0.6v0.5h0.2l0.3,0.2l0.1,0.1v0.1l0,0l-0.2,0.1
                l-0.2,0.1l-0.1,0.2l0.2,0.3v0.2v0.2v0.1l-0.4,0.2l-0.1,0.1v0.1l0.2,0.2l0.1,0.2l0.1,0.2v0.2l-0.2-0.3l-0.3-0.3l-0.2-0.2v-0.4
                l-0.1-0.2l-0.3-0.2l-0.3-0.2h-0.2l0.1,0.2l0.3,0.3v0.1v0.2h-0.2l-0.2-0.1H241l-0.2-0.1l-0.4-0.4l0.3-0.3l0.1-0.2V438l-0.1-0.2
                l-0.3-0.3l-0.4-0.4l-0.6-0.3l-0.3-0.2l-0.7-0.2l-0.3-0.1l-0.2-0.2v-0.2l0.1-0.2l-0.2-0.3l-0.9-0.6l-0.5-0.2l-0.1-0.1h-0.1l0.1,0.4
                l0.2,0.3l0.6,0.2l0.2,0.1l0.1,0.2l-0.3,0.3l-0.2,0.1v0.2l-0.1,0.1l-0.1-0.1l-0.4-0.5l-0.9-0.3l-0.2-0.2l-0.3-0.5l-0.1-0.5l0.1-0.3
                l0.4-0.5l0.1-0.2v-0.1V433l-0.1-0.1l-0.3-0.2l-0.2-0.1l0.1-0.1l0.4-0.2v-0.2V432h0.1h0.1l0,0l0.1-0.2l0.1-0.1h0.1l0.1,0.1l0.5,0.2
                l0.5,0.2l0.8,0.3l0.3-0.2l0.3-0.1h0.2l0.4,0.2h0.2h0.1l0.3,0.2l0.1,0.2v0.1l0.1,0.1h0.2l0.5,0.1h0.3l0.3-0.1l0.2-0.1L240.4,432.2z"
                />
            <linearGradient id="CU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="264.5079" x2="0.1864" y2="264.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CU" class="stcustom0 st49" d="M245.4,396.4l0.7,0.1h0.6l0.3-0.1v0.1l0.3,0.2h0.1l0.4-0.1h1l0.1,0.1l0.2,0.2l0.3,0.1l0.3,0.1h0.3h0.3
                h0.3l0.3,0.2h0.1l0.3-0.1l-0.1,0.2l0.5,0.3l0.4,0.5l0.3,0.2l0.3,0.2l0.2,0.1l0.3,0.1h0.8h0.2l0.2,0.1h0.2h0.1l1.5,0.8l0.5,0.4
                l0.3,0.2l0.6,0.3l0.3,0.1h0.1v-0.1l-0.2-0.2v-0.1l0.2,0.1l0.4,0.4l0.1,0.1l0.2,0.1l0.2,0.1l-0.1,0.1h-0.2l-0.3-0.1l0.3,0.2v0.2h0.1
                l0.2-0.2l0.1-0.2l0.5,0.4l0.3,0.2l-0.1,0.1v0.1l0.3-0.1h0.1l0.1,0.1l0.1,0.2h0.3h0.3l0.5,0.1l0.5,0.3l0.5,0.1h0.5l0.2,0.1l0.1,0.2
                l-0.1,0.1l-0.1,0.1l0.2,0.2l-0.4,0.1l-0.1,0.1v0.1l0.1,0.1l0.2-0.1h0.3h0.5h0.4l0.7,0.1l0.2,0.1l0.4,0.2l0.2,0.2l0.4,0.4l0.4,0.2
                h0.3h0.1h0.1l0.1,0.1l0.1,0.2v0.2l-0.2,0.2l-0.1,0.1h-0.4l-0.6,0.1l-0.6,0.2L265,406l-0.1,0.1l-0.3,0.1v-0.1V406l-0.4-0.1l-0.1,0.1
                l-0.1,0.1l-0.2,0.1h-0.7l-0.3-0.1l-0.3-0.1l-1.1-0.1h-0.3l-0.7,0.1h-0.7l-0.3,0.1l-0.3,0.1h-0.6l-0.7,0.1h-0.7l0.5-0.7l1-0.6
                l0.2-0.1l0.1-0.2v-0.1v-0.1l-0.2-0.2v-0.2l-0.1-0.1l-0.3-0.1l-0.3-0.1h-0.4l-0.7-0.1h-0.4l-0.3-0.1l-0.6-0.5l-0.3-0.1l-0.1-0.1
                l-0.1-0.1l-0.1-0.7l-0.1-0.4l-0.2-0.3l-0.3-0.2l-0.3-0.1l-1,0.2h-0.2l-0.2-0.1l-1.6-0.5l-0.6-0.3l-0.3-0.1l-0.2-0.2l-0.2-0.3
                l-0.3-0.3v0.1v0.1h-1.3l-0.2-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2v0.2l-0.1,0.2l-0.2,0.1h-0.2l-0.2-0.2h-1.1h-0.1l-0.3-0.2
                l-0.3-0.3l0.3-0.1l0.6-0.1l0.1-0.1l0.1-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1h-0.1h-0.2h-2.3l-0.1,0.1l-0.2,0.2l-0.4,0.2l-0.3,0.3
                l-0.1,0.1l-0.1,0.1l-0.3,0.2l-0.2,0.2l-0.3,0.1l-0.2-0.1h-0.2l-0.1,0.1h-0.1h-0.6l-0.1,0.1l-0.1,0.2l-0.1,0.3l-0.1,0.1h-0.3
                l-0.3,0.1l-0.6,0.3h-0.2v-0.2v-0.2h-0.2h-0.2l-0.2,0.1l-0.3,0.2h-0.1l-0.1-0.1v-0.1l1-0.4h0.1h0.2h0.2l0.1-0.1l-0.2-0.5l0.1-0.4
                l0.2-0.3l0.4-0.4l0.2-0.1l2.2-0.9h0.2l1.4-0.2l0.2-0.1l0.7-0.3l0.7-0.1L245.4,396.4L245.4,396.4z M252.4,397.9L252.4,397.9l-0.5-0.1
                l-0.2-0.2l-0.1-0.1h0.1L252.4,397.9L252.4,397.9z M254.4,398.2l0.4,0.1h0.1h0.1h0.1l0.2,0.3h-0.2H255h-0.1h-0.3l-0.2-0.1l-0.1-0.1
                l-0.1-0.1L254.4,398.2L254.4,398.2z M256.1,399L256.1,399l-0.2-0.1h-0.2l-0.1-0.2l-0.1-0.1v-0.1h0.2h0.1l0.2,0.1L256.1,399
                L256.1,399L256.1,399z M256.5,399.5L256.5,399.5l-0.4-0.1l-0.1-0.1l0.1-0.1V399l0,0v0.2l0.2,0.1l0,0L256.5,399.5L256.5,399.5
                L256.5,399.5z M257.1,400.1l-0.1,0.1l-0.1-0.1h-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.2h0.2h0.2l0.4,0.1v0.2L257.1,400.1z M243.4,401.2
                l-0.3,0.2l-0.6,0.2h-0.3l-0.3-0.1l-0.2-0.2l-0.1-0.2V401l0.2,0.1l0.2,0.1h0.1l0.1-0.1l-0.3-0.6v-0.1l0.2-0.3l0.7,0.1l0.1,0.1
                l0.1,0.2l0.1,0.2L243.4,401.2L243.4,401.2z"/>
            <linearGradient id="CV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="248.3079" x2="0.1864" y2="248.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CV" class="stcustom0 st50" d="M404.5,414.9h-0.3h-0.1v-0.2l-0.1-0.2v-0.1l0.6-0.3h0.2l0.2,0.2l-0.1,0.1L404.5,414.9L404.5,414.9z
                M405.2,415.2l-0.2,0.1h-0.1l-0.2-0.1l0.1-0.1l0.2-0.1h0.2V415.2L405.2,415.2L405.2,415.2z M410.9,415.7l-0.1,0.1l-0.1-0.2l-0.1-0.1
                v-0.3l0.2-0.1h0.1v0.3V415.7L410.9,415.7z M407.5,415.8l0.1,0.1v0.1h-0.2l-0.4-0.1l-0.1,0.1l-0.1,0.2l-0.2-0.4v-0.1l0,0l0.3,0.1
                L407.5,415.8L407.5,415.8z M410.8,416.9l0.2,0.1h0.1h0.1l0.2,0.2v0.2l-0.1,0.2l-0.3,0.2h-0.2l-0.2-0.2l0.1-0.3L410.8,416.9z
                M409.3,420.5l-0.2,0.3h-0.4l-0.2-0.1l-0.2-0.3v-0.3l0.1-0.2v-0.2v-0.1h0.1v0.1l0.3,0.3l0.1,0.1L409.3,420.5L409.3,420.5z
                M410,420.1L410,420.1l-0.2-0.1v-0.2l0,0l0.1-0.2h0.2l0.1,0.1v0.3L410,420.1L410,420.1z M406.9,420.9l-0.2,0.1h-0.2l-0.1-0.1
                l-0.1-0.2l0.1-0.1l0.3-0.2l0.2,0.1l0.1,0.3L406.9,420.9L406.9,420.9z"/>
            <linearGradient id="CW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="237.4079" x2="0.1864" y2="237.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CW" class="stcustom0 st51" d="M282.1,428.9H282l-0.5-0.3l-0.4-0.4V428h0.1l0.1,0.1l0.2,0.3l0.5,0.2L282.1,428.9z"/>
            <linearGradient id="CX_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="173.6079" x2="0.1864" y2="173.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CX" class="stcustom0 st52" d="M771.8,492.2l-0.1,0.2l-0.1-0.1h-0.2v-0.1h0.1h0.1l0.1-0.1L771.8,492.2z"/>
            <linearGradient id="CY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="307.7578" x2="0.1864" y2="307.7578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CY" class="stcustom0 st53" d="M571.8,356.4l-1.5,1l-0.1,0.3l0.1,0.2l0.2,0.3l0.1,0.1l0.1,0.2l-0.3,0.1h-0.3h-0.2h-0.2l-0.5,0.6
                l-0.3,0.2l-0.3,0.1l-0.3,0.1h-0.2l-0.2,0.1l-0.1,0.1v0.1v0.1h-0.2l-0.1-0.2l-0.1-0.1h-0.3h-0.2l-0.5-0.2l-0.2-0.1l-0.1-0.2l-0.3-0.6
                v-0.4l0.3,0.1l0.2-0.1l0.2-0.2l0.3-0.1h0.2h0.2l0.3-0.1l0.1-0.3v-0.4l0.5,0.1l0.5,0.1h0.4l0.4-0.1l1.3-0.4l0.4-0.2l0.2-0.1l0.4-0.2
                l0.4-0.1L571.8,356.4z"/>
            <linearGradient id="CZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="364.208" x2="0.1864" y2="364.208" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="CZ" class="stcustom0 st54" d="M516.7,297.4h0.2l0.2-0.1v-0.2v-0.3l0,0l0.4,0.1l0.4,0.2l0.1,0.3l0.1,0.2l0.1,0.2l0.1,0.1h0.2l0.5,0.2
                h0.2l0.2,0.1l0.2,0.1h0.2l0.1,0.2l0.1,0.1l0.2-0.1l0.6-0.1l0.2,0.2l0.1,0.2l0,0l-0.1,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1
                l0.1,0.1l0.2,0.1h0.1v0.1l0.4,0.4l0.3,0.6l0.1,0.1h0.1l0.1-0.1l0.2-0.2l0.2-0.1l0.2-0.1l0.3-0.2V300l-0.2-0.4l-0.1-0.3v-0.1h0.3
                l0.5,0.2l0.7,0.5h0.1h0.3l0.3-0.1l0.1-0.1l0,0v0.3l-0.1,0.2l-0.3,0.2v0.1l0.1,0.1l0.2,0.1l0.2,0.2l0.1,0.2l0.1,0.1h0.1l0.3-0.1
                l0.1-0.1v-0.1h0.1l0.1,0.1v0.1l0.3,0.1l0.2,0.1l0.1,0.1l0.1-0.1l0.5,0.1l0.1,0.1v0.2v0.1l0.1,0.3l0.6,0.6l0.1,0.3v0.1h-0.1l-0.2,0.1
                h-0.2h-0.2l-0.2,0.1l-0.2,0.2l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.6,0.3l-0.1,0.1l-0.1,0.2v0.3v0.2l-0.1,0.1l-0.3,0.1l-0.1,0.1l-0.1,0.1
                l-0.2,0.2l-0.2,0.2l-0.4,0.2l-0.4,0.1l-0.5-0.1l-0.3-0.1l-0.1,0.1l-0.2,0.3l-0.2,0.4l-0.1,0.3l-0.1-0.1l-0.1-0.4h-0.1h-0.2l-0.1-0.1
                l-0.3-0.2l-0.2-0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.4l-0.5-0.1l-0.7-0.5H519h-0.2l-0.3-0.1l-0.6-0.3l-0.3-0.1h-0.2l-0.1,0.1h-0.1
                l-0.1-0.1l-0.2-0.1h-0.2l-0.1,0.1l-0.1,0.7l-0.1,0.2h-0.3l-0.1,0.1l-0.2,0.3v0.3l-0.4-0.1l-0.2-0.1H515l-0.2,0.2h-0.5l-0.4-0.1
                l-0.2-0.4l-0.2-0.2l-0.2-0.1h-0.1l-0.1-0.2l-0.2-0.3l-0.4-0.4h-0.3l-0.1-0.1v-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.2-0.2l-0.3-0.4
                l-0.3-0.3h-0.3l-0.2-0.2l-0.2-0.2l-0.1-0.2l-0.2-0.5l-0.2-0.3l-0.1-0.2l-0.1-0.1v-0.1l0.2-0.3l0.1-0.1l0.1-0.1V301v-0.1l-0.2-0.3
                l-0.2-0.2l-0.3-0.2L509,300l-0.1-0.2v-0.1l-0.1-0.2l-0.1-0.2v-0.1l0,0h0.1l0.1,0.1l0.2,0.2l0.1,0.3l0.1-0.1l0.1-0.3l0.3-0.3l0.3-0.2
                h0.2l0.2-0.1l0.2-0.1h0.3l0.2,0.1h0.1l0.1-0.2l0.1-0.2l0.5-0.1l0.2-0.3h0.1h0.1l0.1-0.1h0.1l0.1,0.1h0.1l0.1-0.1l0.2-0.3l0.1-0.1
                l0.4-0.1l0.6-0.2l0.3-0.2l0.3-0.1l0.3-0.2l0.5-0.2v-0.1l-0.3,0.5l-0.1-0.1v-0.1l0.1-0.1h0.1l0.1,0.1l0.4,0.1l0.1,0.1v0.2l0.1,0.2
                h0.1v0.3l0.1,0.1l0.2,0.1h0.1L516.7,297.4L516.7,297.4z"/>
            <linearGradient id="DE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="371.058" x2="0.1864" y2="371.058" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DE" class="stcustom0 st55" d="M498.4,279.1L498.4,279.1v-0.6l0.3-0.7h0.1l-0.1,0.2v0.1l-0.1,0.3v0.1h0.7l-0.1,0.1l-0.7,0.1
                L498.4,279.1L498.4,279.1z M502.4,279L502.4,279l0.4,0.2l0.2,0.2l0.2,0.3v0.4l-0.2,0.3l-0.2,0.2l0.8-0.1l0.1,0.2l0.1,0.2l0.4-0.1
                l1,0.6l0.6-0.3h0.2l0.1,0.5l-0.2,0.5l-0.6,0.5l0.1,0.3l0.2,0.1l0.5-0.1l0.8,0.3l0.2-0.1l0.7-0.7l0.3-0.2l0.9-0.1l0.2-0.3l0.4-0.3
                l0.2-0.3l0.6-0.6l0.6,0.1l0.3,0.1l0.4,0.1l0.3,0.6l0.8,0.7l0.8-0.1l0.3,0.6l0.1,0.8l0.2,0.2l0.2,0.2l0.6,0.2l0,0v0.1v0.4l0.1,0.3
                l0.3,1.3v0.3v0.1l-0.1,0.4l-0.2,0.4l-0.3,0.2l-0.2,0.2v0.3l0.4,0.4l0.7,0.6l0.3,0.5l-0.1,0.4v0.3l0.1,0.2l0.1,0.2l0.2,0.1l0.1,0.2
                v0.3v0.2l0.1,0.1l0,0l-0.1,0.2l-0.1,0.3l-0.1,0.2l-0.2,0.3l0.1,0.3l0.2,0.3l0.1,0.2v0.2l-0.1,0.4v0.1l0.5,0.3l0.1,0.1v0.3l0.2,0.6
                l-0.1,0.7l-0.1,0.4l-0.3,0.6v0.1v0.1l-0.1,0.1h-0.1l-0.2-0.1l-0.1-0.1v-0.3H516l-0.1-0.2v-0.2l-0.1-0.1l-0.4-0.1l-0.1-0.1h-0.1
                l-0.1,0.1v0.1l0.1,0.1l0.2,0.2v0.1l-0.5,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.2l-0.6,0.2l-0.4,0.1l-0.1,0.1l-0.2,0.3l-0.1,0.1h-0.1
                l-0.1-0.1h-0.1l-0.1,0.1h-0.1H512l-0.2,0.3l-0.5,0.1l-0.1,0.2l-0.1,0.2H511l-0.2-0.1h-0.3l-0.2,0.1l-0.2,0.1h-0.2l-0.3,0.2l-0.3,0.3
                l-0.1,0.3l-0.1,0.1l-0.1-0.3l-0.2-0.2l-0.1-0.1h-0.1l0,0v0.1l0.1,0.2l0.1,0.2v0.1l0.1,0.2l0.2,0.2l0.3,0.2l0.2,0.2l0.2,0.3v0.1v0.1
                l-0.1,0.1l-0.1,0.1l-0.2,0.3v0.1l0.1,0.1l0.1,0.2l0.2,0.3l0.2,0.5l0.1,0.2l0.2,0.2l0.2,0.2h0.3l0.3,0.3l0.3,0.4l0.2,0.2l0.2,0.1
                l0.1,0.2l0.1,0.2v0.1l0.1,0.1h0.3l0.4,0.4l0.2,0.3l0.1,0.2v0.1v0.3v0.3v0.1l-0.2,0.2h-0.1l0,0l-0.5-0.2l0,0l0,0l-0.1,0.7l-0.1,0.1
                l-0.1,0.1l-0.3,0.1h-0.2l-0.2,0.1l-0.5,0.3l-0.2,0.2l-0.2,0.2v0.1l0.3,0.4l0.3,0.4v0.3l-0.1,0.3v0.1h0.1h0.2h0.1l0.1,0.2v0.3v0.3
                v0.1h-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.1v-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.3,0.1l-0.2,0.1h-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.2-0.1l0,0
                v0.3l-0.1,0.1l-1.3,0.1l-0.4,0.1l-0.3,0.2l-0.2,0.1l-0.1,0.1l-0.2,0.1h-0.2h-0.1l-0.2,0.1l-0.3,0.1h-0.2l-0.1-0.1l-0.2-0.2l-0.1-0.1
                v-0.1h-0.4l-0.2-0.1H504h-0.1l0,0l-0.1,0.5l-0.1,0.2l-0.2,0.2l-0.2,0.1h-0.2v-0.2v-0.2H503h-0.2l-0.1-0.1v-0.1v-0.1l-0.1-0.1
                l-0.2-0.1L502,311l-0.3-0.1l-0.1,0.1l-0.2,0.1h-0.3H501l-0.5-0.3l-0.5-0.3h-0.2l-0.7,0.1l0,0l-0.1-0.2l-0.1-0.1h-0.1l0,0h-0.1
                l-0.3-0.3h-0.1h-0.2l-0.2,0.1l-0.1,0.2v0.1h0.1h0.3l0,0v0.1v0.1H498l-0.1,0.1h-0.1l0,0l-0.2-0.1h-0.4l-0.3,0.1l-0.5,0.1h-0.6
                l-0.2-0.1l-0.1-0.1l-0.1-0.3v-0.4l0.2-0.6v-0.4l-0.1-0.3l0.1-0.4l0.2-0.5l0.2-0.5l0.1-0.6l0.1-0.4l0.2-0.3l0.6-0.7v-0.1v-0.4
                l-0.2-0.1l-0.2-0.1l-0.6-0.1l-0.5-0.1l-0.2-0.1l-0.2-0.3H495l-0.3,0.1l-0.3,0.1l-0.2-0.1h-0.1l-0.1,0.1l0,0l-0.1-0.2l-0.1-0.1
                l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.4-0.6l-0.1-0.1v-0.1l-0.1-0.2l-0.2-0.2l-0.2-0.1h-0.1v-0.3l0.1-0.4l0.1-0.2l0.1-0.2
                l0.1-0.1v-0.2V301h-0.1l-0.3-0.1l-0.2-0.1l-0.1-0.2l-0.2-0.3l-0.1-0.3v-0.3v-0.1v-0.1l0.2-0.4l0.5-0.4l-0.1-0.4v-0.2L492,298
                l-0.3-0.1l-0.1-0.1v-0.1l0.2-0.2l-0.2-0.2l-0.1-0.2l-0.3-0.2v-0.1l0.2-0.7l-0.1-0.2l-0.1-0.1H491l-0.1-0.1v-0.1v-0.1h0.2l0.1-0.1
                l0.5-0.4v-0.1h-0.1h-0.1v-0.1v-0.1l0.3-0.6l0.1-0.2v-0.2v-0.2l-0.1-0.3l-0.1-0.2v-0.2l-0.1-0.1l-0.3-0.5V292l0.2-0.1l0.2-0.1
                l0.1-0.1h0.1l0.4,0.1l0.2,0.1l0,0l0.1-0.1h0.3l0.6-0.3l0.1-0.1l0.1-0.1v-0.1l-0.2-0.3v-0.1v-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.3-0.3
                l0.1-0.3v-0.3v-0.2l-0.1-0.2l-0.1-0.1H494h-0.3l-0.2-0.1l-0.1-0.2v-0.1l0.1-0.1v-0.1v-0.1v-0.1l0.1-0.1h0.7l0.1-0.1v-0.4l0.2-0.7
                l0.2-0.4v-0.2v-0.9V285l-0.1-0.2l-0.3-0.2l0.1-0.5l0.1-0.4l0.3-0.5l0.2-0.1l1-0.1h1.1l0.4,0.7l-0.2,0.4l0.3,0.2l0.1-0.1l0.1-0.3
                l0.1-0.3l0.1-0.1l0.3,0.3l0.1,0.2v0.6l0.1-0.8l-0.1-0.5l0.1-0.5l0.1-0.3l0.1-0.2l0.8,0.2l0.9-0.1l0.3,0.2l0.7,1l0.2,0.2l0.3,0.1
                l-0.4-0.2l-0.9-1.2l-0.3-0.2H500l-0.3-0.1l-0.2-0.2v-0.2v-1.2l-0.2-0.2l-0.2-0.1l-0.1,0.1h-0.3l-0.1-0.3l0.1-0.2l0.5-0.1l0.3-0.2
                v-0.3l-0.2-0.3l-0.3-0.5l-0.3-0.5v-0.5l0,0h0.5h0.1l0.8,0.3l0.2,0.2h0.2l0.4-0.2l0.3-0.1l0.1,0.1L502.4,279L502.4,279L502.4,279z
                M499.2,279.5l-0.1,0.1h-0.3l-0.1-0.1v-0.1l0.1-0.1h0.1l0.2,0.1L499.2,279.5L499.2,279.5z M513.6,281.1l0.1,0.3l-0.1,0.2l-0.3-0.3
                H513l-0.2,0.4h-0.1l-0.5-0.4l-0.1-0.2v-0.2l0.1-0.5v-0.2l0.2-0.2v-0.3l0.3-0.3h0.2l0.1,0.2l0.1,0.2l0.4,0.2l0.1,0.1v0.1l-0.2,0.2
                l-0.1,0.1l0.1,0.2L513.6,281.1L513.6,281.1z M506.8,280.9h-0.4l-0.2-0.2H506l0.1-0.2l0.1-0.1l0.4,0.2L506.8,280.9L506.8,280.9
                L506.8,280.9z M515,283.2v0.1v0.2h-0.1l-0.3,0.1l-0.3-0.1l-0.1-0.3l0.1-0.3l-0.1-0.2l-0.1-0.1v-0.2v-0.2l0.6,0.4L515,283.2z"/>
            <linearGradient id="DJ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="236.1579" x2="0.1864" y2="236.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DJ" class="stcustom0 st56" d="M596.5,430.5l-0.3,0.4l-0.3,0.5l-0.4,0.6h-0.2h-0.2l-0.1-0.1l-0.2-0.1h-0.3l-0.3,0.1l-0.4,0.1h-0.4
                l-0.3,0.1l-0.3,0.1h-0.2l-0.2-0.1v-0.6v-0.6v-0.5l0.1-0.3l0.1-0.1l0.4-0.4l0.1-0.2l0.4-0.6l0.4-0.5l0.3-0.4l0.1-0.1l0.1-0.1h0.1
                l0.5,0.4h0.1l0.2-0.1l0.2-0.4l0.1-0.2l0,0l0.3-0.1l0.3-0.1v0.1l0.5,0.6l0.2,0.3l0.2,0.5L597,429l-0.1,0.2l-0.2,0.2l-0.6,0.4
                l-0.9,0.1l-0.4,0.5h-0.3l0.1,0.2h0.1h0.2l0.4-0.1l0.3-0.1h0.4l0.3,0.1H596.5z"/>
            <linearGradient id="DK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="393.6579" x2="0.1864" y2="393.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DK" class="stcustom0 st57" d="M502.4,279L502.4,279h-0.2l-0.1-0.1l-0.3,0.1l-0.4,0.2h-0.2L501,279l-0.8-0.3h-0.1h-0.5l0,0v-0.4
                l-0.1-0.3l-0.2-0.4l0.3-0.1l-0.1-0.9l-0.1-0.4l-0.8-0.5l-0.6-0.4l0.1-1.5l0.1-0.4l-0.2-0.8v-0.9l0.1-1.4l0.2-0.1h0.1l0.5,0.3h0.2
                l0.2,0.2l0.2,0.1l0.1-0.2l0.1-0.4l0.4-0.5l0.3-0.2l0.2-0.1l0.2,0.2l0.2,0.2v-0.5l0.1-1l-0.4-0.2l-0.3,0.1l-0.3,0.7l-0.3,0.8
                l-0.5,0.1l-0.4,0.2l-0.3-0.2l-0.2-0.2v-0.3v-0.2l0.4-0.7l0.5-0.7h0.5l0.4-0.2h0.2h0.7l0.4-0.1l0.3-0.3l0.7-1.3l0.4-0.5l0.8-0.2
                l0.8-0.6h0.2l-0.4,0.5l-0.1,0.2v0.3l0.3,0.6l-0.1,0.4v0.7l-0.2,0.4l-0.3,0.8l-0.1,0.1v0.9v0.2v0.8l0.3,0.3l0.3,0.2h1l0.1,0.1
                l0.1,0.3l-0.1,0.4l-0.1,0.3l-0.3,0.3l-0.4,0.2h-0.2l-0.3-0.4v-0.2l-0.2,0.2l-0.3,1l-0.1,0.7l-0.1,0.1l-0.1-0.1h-0.3l-0.3,0.2
                l0.2,0.1l0.2,0.3l-0.1,0.1l-0.3,0.1l-0.2,0.3l-0.1,0.2l-0.3,0.3l-0.2,0.3l0.1,0.4v0.3l0.1,0.4l-0.1,0.3l-0.4,0.4l-0.1,0.4h0.3
                l0.2,0.1l0.1,0.1l0.1,0.2l-0.1,0.2L502.4,279L502.4,279z M506.1,266.8l-0.1,0.1l-0.4-0.2l0.2-0.2l0.4-0.1h0.2l-0.3,0.2L506.1,266.8
                L506.1,266.8z M510.4,274.2v0.5l-0.1,0.1l-0.1,0.1l-0.3,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.4l0.2,0.3l0.3,0.1l0.1,0.5l-0.3,0.2L509,277
                l-0.1,0.6v0.5v0.3l-0.1,0.5l-0.5,0.2l-0.3-0.7v-0.3l-0.1-0.3v-0.3l-0.1-0.4l-0.5-0.1h-0.2l-0.3,0.1h-0.1l-0.3-0.6l0.1-0.7l-0.2-0.3
                v-0.2v-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.4l0.2-0.1h0.5h0.1l0.1-0.1l0.4-0.6v-0.1v-0.2l0.4-0.1l0.2,0.2v0.4v0.5l0.3,0.1h0.1l0.1-0.4
                l0.1-0.2l0.1-0.1v-0.3l-0.1-0.2l-0.1-0.2l0.5-0.4l0.5-0.3h0.3l0.3,0.1l0.3,0.1l0.1,0.1l0.1,0.2l-0.2,0.4l-0.1,0.2L510.4,274.2
                L510.4,274.2z M504.9,274.2L504.9,274.2h-0.2v-0.3l0.1-0.3l-0.1-0.3l0.1-0.2l0.2,0.4l0.1,0.2l-0.1,0.2L504.9,274.2z M510.6,275.2
                l-0.3,0.2h-0.1l-0.1-0.3l0.1-0.2l0.1-0.1h0.1l0.1,0.2L510.6,275.2L510.6,275.2z M505,275.1l0.1,0.3l0.1,0.5l0.2,0.6l-0.1,0.3
                l0.1,0.3l-0.1,0.3l-0.5,0.4h-0.5l-0.5-0.2l-0.7-0.4L503,277l-0.1-0.1l-0.2-0.6v-0.8l0.4-0.1l0.8-0.4l0.2,0.1l0.2,0.2h0.2L505,275.1
                L505,275.1L505,275.1z M517.4,278l-0.1,0.1l-0.5-0.1l-0.6-0.3l0.1-0.7l0.1-0.3l1,0.7v0.3V278L517.4,278z M505.2,279.3h-0.1l-0.2-0.4
                v-0.1l0.2-0.3l0.1-0.3l0.3-0.4l0.2-0.5h0.1l-0.1,0.5l-0.4,1.3L505.2,279.3L505.2,279.3z M503.3,278.7l-0.3,0.1l-0.1-0.1h-0.3
                l-0.1-0.8l0,0l0.1,0.1l0.5,0.4l0.2,0.4L503.3,278.7L503.3,278.7z M510.3,278.3l-0.1,0.1l-0.4-0.1l-0.5,0.3l-0.2-0.1l0.1-0.2l0.1-0.1
                l0.2-0.1l0.1-0.1v-0.2l0.1,0.1h0.3l0.1,0.1l0.1,0.1L510.3,278.3L510.3,278.3z M504.5,278.8l-0.2,0.1l-0.2-0.1l-0.4-0.4v-0.1l0.2,0.1
                l0.2,0.2h0.2L504.5,278.8L504.5,278.8L504.5,278.8z M507,278.6l0.5,0.3h0.3l0.2,0.1l0.1,0.2v0.4l-0.2,0.1h-0.3l-0.4,0.2l-1.2-0.7
                v-0.6v-0.2l0.6-0.1L507,278.6z"/>
            <linearGradient id="DM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="246.5579" x2="0.1864" y2="246.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DM" class="stcustom0 st58" d="M303.1,419.8l-0.3,0.1l-0.1-0.5l-0.2-0.4v-0.2v-0.1l0.4,0.1l0.1,0.2l0.1,0.4V419.8z"/>
            <linearGradient id="DO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="256.6079" x2="0.1864" y2="256.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DO" class="stcustom0 st59" d="M273.7,411.7v-0.5l0.1-0.2l-0.1-0.2l-0.3-0.2l-0.2-0.3L273,410l0,0h0.3l0.1-0.1l0.2-0.3v-0.2v-0.2
                l-0.1-0.2l-0.1-0.2l0.2-0.2l0.2-0.3v-0.1v-0.1l-0.3-0.3v-0.1l0.1-0.3v-0.2l-0.1-0.6l-0.1-0.1h0.1l0.1-0.2l0.1-0.2l0.1-0.1l0.2-0.1
                h0.3l0.5,0.1h0.1l0.4-0.1l0.4-0.1l0.3,0.1l0.1,0.1l0.3,0.2l0.1,0.1h0.4h0.1l0.4,0.3l0.3,0.1h0.2l0.3-0.1h0.2l0.2,0.2v0.3l0.2,0.3
                l0.2,0.2l1.2-0.1l0.3,0.2l-0.1,0.1l-0.2,0.1h-0.6h-0.2v0.1v0.1h0.3l0.3,0.1l0.3,0.1l0.3,0.1h0.4l0.4,0.1l0.6,0.2l0.7,0.6l0.2,0.1
                l0.1,0.2l-0.1,0.2l-0.2,0.4l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.2h-0.1H282l-0.2-0.1l-0.1-0.2l-0.3-0.2H281l-0.6-0.1l-0.3,0.1h-0.3
                l-0.4-0.1H279l-0.4,0.1l-0.3,0.1l-0.1,0.1l-0.2,0.2l-0.1,0.1l-0.8,0.1l-0.2-0.1l-0.2-0.2h-0.3l-0.5,0.2l-0.3,0.1l-0.1,0.1v0.1v0.3
                l-0.1,0.2l-0.5,0.6l-0.3,0.5l-0.1,0.1h-0.1l-0.2-0.3l-0.1-0.1h-0.2l-0.1-0.1V412v-0.2L273.7,411.7L273.7,411.7z"/>
            <linearGradient id="DZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="286.2578" x2="0.1864" y2="286.2578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="DZ" class="stcustom0 st60" d="M499.2,351.8l0.1,0.2v0.2l-0.3,0.2l-0.2,0.1l-0.2,0.4l-0.4,0.3l-0.1,0.1v0.1l0.3,0.1l0.1,0.1v0.2
                l-0.1,0.6l-0.1,0.5l-0.1,0.6v0.2l0.1,0.3l0.1,0.2v0.2v0.6l0.1,0.4l0.1,0.3l-0.2,0.4l-0.1,0.4l-0.1,0.5v0.3l-0.1,0.3l-0.2,0.3
                l-0.2,0.2l-0.3,0.1l-0.3,0.2l-0.3,0.5l-0.5,0.4l-0.1,0.1l-0.1,0.4v0.5l0.1,0.4l0.3,0.6l0.2,0.6l0.1,0.3l0.1,0.1l0.3,0.2l0.6,0.3
                l0.1,0.1l0.3,0.4l0.3,0.8l0.1,0.5l0.5,0.4l0.5,0.4l0.5,0.3l0.5,0.4l0.1,0.1l0.2,0.7l0.2,0.7l0.2,0.8l0.2,0.8l0.2,1l0.1,0.5l0.1,0.7
                l0.2,0.8l-0.3,0.2l-0.3,0.2l0.2,0.4l0.4,0.6l0.3,0.5l0.1,0.2l0.2,0.6l0.2,0.6v0.2l0.1,0.5l-0.1,1.3l0.1,1.6l0.2,0.8l-0.3,0.7
                l-0.2,0.7v0.4l0.1,0.5l0.1,0.4l0.2,0.2v0.7l-0.1,0.2l-0.5,0.4l-0.5,0.3l-0.1,0.3v0.3l0.1,0.2l0.4,0.5l0.6,0.8l0.6,0.9l0.1,0.2v0.6
                l0.3,0.8l0.3,0.4l0.1,0.3l0.2,0.2l0.2,0.1h0.1l0.7-0.2l1.2,0.4l1.1,0.4l0.1,0.1l0.2,0.5l0.4,0.8l0.3,0.6l0.3,0.5l-1.5,0.9l-1.5,0.9
                l-1.5,0.9l-1.5,0.9l-1.5,0.9l-1.5,0.9l-1.5,0.9l-1.5,0.9l-1,0.6l-0.6,0.5l-0.8,0.7l-0.7,0.7l-0.6,0.5l-0.7,0.7l-0.4,0.3l-0.8,0.7
                l-0.2,0.1l-1.1,0.2l-1,0.2l-0.9,0.2l-0.6,0.1l-0.6,0.1l-0.9,0.2l-0.6,0.1l-0.7,0.1h-0.1h-0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.1v-0.1
                l0.1-0.2l0.1-0.2v-0.1l0.1-0.1l0.1-0.1V407l-0.1-0.2l-0.1-0.3V406v-0.2v-0.1l-0.2-0.2l-0.4-0.2l-0.4-0.1h-0.2l-0.4-0.1l-0.5-0.1
                l-0.2-0.1l-0.4-0.4l-0.2-0.1l-0.8-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.2v-0.2l-0.1-0.1l-0.9-0.5l-0.2-0.2l-0.1-0.1v-0.2v-0.3V402
                v-0.1l-0.4-0.3l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.6l-0.9-0.7l-0.9-0.7
                l-0.9-0.7l-0.9-0.7l-0.9-0.7l-1.4-0.2l-0.8-0.6l-0.9-0.6l-0.6-0.4l-0.6-0.4l-0.7-0.5l-0.4-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3
                l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3v-0.6v-0.5v-0.8
                v-0.7V381v-1.1v-0.5v-0.2l0.1-0.1l0.3-0.2l0.4-0.4l0.2-0.2l0.2-0.2l0.7-0.5l0.2-0.1l0.7-0.6l0.2-0.1l0.4-0.1l0.2-0.1l0.2-0.2
                l0.3-0.3l0.2-0.1l0,0h0.1l0.7,0.1l0.3,0.1h0.3h0.1l0.1-0.1l0.1-0.2v-0.2v-0.2v-0.1h0.1h0.1h0.2h0.4h0.1h0.5l0.6-0.1l0.5-0.2l0.4-0.1
                l0.4-0.3l0.3-0.3l0.3-0.5l0.3-0.5l0.5-0.3l0.4-0.2l0.3-0.1l0.6-0.2l0.5-0.4l0.5-0.3h0.4l0.4-0.1l0.1-0.1l0.1-0.1v-0.2l-0.1-0.1
                l-0.2-0.1l-0.1-0.1h-0.1l-0.1-0.1v-0.2v-0.2l0.1-0.2v-0.2l-0.1-0.2v-0.2v-0.2l0.1-0.1l0.2-0.1h0.2h0.3l0.5-0.1l1.2-0.4l0.1-0.1
                l0.1-0.3l0.1-0.3l0.1-0.1h0.1l0.4-0.1l0.6-0.1h0.2h0.6h0.4h0.7h0.5h0.4h0.6l0.1-0.1v-0.2l-0.1-0.4l0.1-0.2l0.2-0.2l0.3-0.2l-0.1-0.3
                l-0.2-0.2l-0.3-0.2l-0.2-0.1l-0.3-0.3l-0.2-0.3l-0.1-0.7l-0.2-0.4l-0.2-0.5l0.1-0.8l-0.2-0.5v-0.2v-0.3l0.1-0.4v-0.6l-0.2-0.6
                l0.1-0.2l0.1-0.1v-0.1l-0.2-0.2l-0.1-0.2v-0.2l0.1-0.2v-0.1l-0.4-0.3l-0.6-0.5l-0.2-0.2l-0.1-0.3l0.6,0.1h0.3l0.7-0.3l0.5-0.4
                l0.4-0.2l0.4-0.5l0.3-0.3l0.5-0.3l1.4-0.7h0.2l0.5,0.2h0.4l0.3-0.2l0.3-0.6l0.5-0.3l0.6-0.3l0.8-0.3l0.5-0.3l0.8-0.3l2-0.2l1-0.1
                h0.7l0.7-0.5l0.4-0.2h1.5l0.7-0.4h2.7l0.3,0.1l0.3,0.2l0.6,0.5l0.3,0.1l0.4-0.1l0.8-0.4l1-0.2l0.5-0.3l0.2-0.4l0.4-0.1l0.3,0.3
                l1,0.3l0.6-0.1l0.3-0.1l-0.1-0.4l0.6,0.1l0.5,0.2l0.5,0.4l0.3,0.1l0.6-0.2L499.2,351.8z"/>
            <linearGradient id="EC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="197.9579" x2="0.1864" y2="197.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="EC" class="stcustom0 st61" d="M263.8,463.1l-0.2,0.1h-0.2h-0.2l-0.3-0.1h-0.1v0.1l0.2,0.1l0.2,0.1l0.1,0.2l0.1,0.2l0.3,0.3l0.2,0.1
                v0.1l-0.1,0.2v0.1l0.1,0.7h-0.1h-0.1h-0.1l-0.1-0.1h-0.1v0.1l-0.1,0.3l-0.2,0.7l-0.2,0.6l-0.2,0.2l-0.3,0.3l-0.4,0.5l-0.6,0.7
                l-0.4,0.3l-0.3,0.2l-0.4,0.3l-0.5,0.4l-0.6,0.2l-0.8,0.3l-0.6,0.2l-0.4,0.1l-0.4,0.1l-0.6,0.2l-0.2,0.2l-0.4,0.4l-0.2,0.2l-0.2,0.2
                v0.1v0.1l0.1,0.1v0.1l-0.1,0.1h-0.1l0,0v-0.1l-0.1-0.1h-0.1H255v0.1l-0.1,0.5v0.2l-0.1,0.1v0.2l-0.1,0.2l-0.1,0.2v0.1l-0.1,0.1v0.2
                l-0.1,0.3l-0.1,0.3l-0.1,0.2v0.2l0.1,0.1v0.1l-0.1,0.2v0.1l-0.2,0.1l-0.3,0.2l-0.1,0.1l-0.1,0.2v0.1v0.1h-0.2l-0.1,0.1l-0.1,0.2
                l-0.1,0.1l-0.3-0.1h-0.2l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.3v-0.4l-0.2-0.1l-0.2-0.1h-0.2H251l-0.1-0.1l-0.3-0.2l-0.3-0.2l-0.2-0.1
                h-0.2l-0.1,0.1l-0.2,0.2l-0.3,0.1h-0.1L249,475v-0.1l0.1-0.2l0.3-0.4h-0.3l-0.1-0.1v-0.1V474l0.1-0.2l0.1-0.1l0.2,0.1h0.2l0.1-0.2
                l0.1-0.1l0.1-0.1v-0.1l-0.1-0.3v-0.1v-0.1v-0.2v-0.1l-0.1-0.1v-0.2l-0.1-0.1V472v-0.1l-0.1-0.1l-0.1-0.1h0.1l0.4-0.1l0.2-0.1
                l0.2-0.1l0.2-0.2l0.1-0.2l0.3-0.9l0.3-0.6v-0.3l-0.2-0.4v-0.5v-0.2V468l-0.1,0.2v0.8l-0.1,0.3l-0.2,0.1l-0.1-0.1l0.1-0.6l-0.1,0.1
                l-0.3,1.1l-0.3,0.3v0.1l-0.1,0.1l-0.3-0.1l-0.2-0.1l-0.7-0.6l-0.4-0.1l-0.3-0.2l-0.1-0.1v-0.1l0.3-0.1l0.3-0.2v-0.4v-0.3l-0.2-0.5
                l0.1-0.7l-0.1-0.3L248,466l0.2-0.3l0.6-0.2l0.2-0.1l0.1-0.5l0.1-0.3l0.3,0.1h0.2l-0.3-0.1l-0.2-0.4V464l0.5-0.6l0.2-0.1l0.3-0.3
                l0.2-0.5l0.1-0.7l-0.1-0.5l-0.1-0.5l0.1-0.1l0.4-0.1l0.3-0.2l0.2-0.2h0.4l0.4-0.2l0.7-0.1l0.9-0.3l0.2-0.2l-0.1-0.4l0.1,0.1l0.3,0.2
                l0.2,0.2l0.3,0.1l0.2,0.1l0.6,0.4l0.4,0.2l0.4,0.2l0.6,0.2h0.4l0.1,0.2l0.1,0.2l0.1,0.1l0.2,0.1h0.1l0,0l0.1,0.6l0.1,0.1l0.3,0.1
                h0.4h0.1l0.3,0.2l0.2,0.1l0.3,0.1h0.2h0.1v-0.1h0.1l0.2,0.1h0.3l0.2-0.1v-0.1v-0.2l0.1-0.1l0.2-0.1h0.1l0.6,0.3l0.1,0.1l0.1,0.2
                l0.3,0.3l0.3,0.2l0.4,0.1l0.4,0.3L263.8,463.1L263.8,463.1z M253.6,459.3h-0.2l-0.1-0.1l0.2-0.2h0.1V459.3z M218.9,462.8l0.2,0.2
                l0.1,0.5l0.6,0.5l0.1,0.3l-0.1,0.1v0.1l0.3,0.2l0.2,0.2l-0.3,0.5l-0.6,0.2h-0.7l-0.1-0.1l-0.2-0.2v-0.2l0.1-0.2l0.3-0.3l0.5-0.2
                l0.1-0.2l-0.2-0.2l-0.1-0.3l-0.3-0.2l-0.2-0.7h-0.1l-0.2,0.1l-0.1-0.1l0,0l0.2-0.2l0.1-0.1l0.4-0.1l0.2,0.1L218.9,462.8L218.9,462.8
                z M220.9,463.8l-0.1,0.1l-0.5-0.1l-0.2-0.2l0.1-0.2l0.1-0.1l0.3,0.1l0.3,0.2L220.9,463.8L220.9,463.8z M218.5,464.1h-0.3L218,464
                l-0.1-0.1v-0.2v-0.1l0.5-0.1l0.2,0.2v0.3L218.5,464.1z M221.6,465h-0.1l-0.4-0.3v-0.3l0.2-0.2l0.6-0.1l0.2,0.2v0.3l-0.2,0.2
                L221.6,465L221.6,465L221.6,465z M224.1,465.4l-0.3,0.1l-0.1-0.1l-0.1-0.1v-0.1l0.2-0.2l0.2-0.1l0.2-0.2l0.3-0.1h0.1h0.1v0.1
                l-0.1,0.2l-0.2,0.1L224.1,465.4z M221.3,466.6h-0.1l-0.2-0.1l0.1-0.2l0.1,0.1l0.1,0.1l0.1,0.1H221.3L221.3,466.6z M250.2,471.1
                l-0.1,0.1h-0.3h-0.1v-0.1l0.1-0.4l0.1-0.2l0.2-0.2l0.2-0.1h0.2l0.2,0.1l-0.3,0.3h-0.2h-0.1L250.2,471.1L250.2,471.1z"/>
            <linearGradient id="EG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="281.2579" x2="0.1864" y2="281.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="EG" class="stcustom0 st62" d="M571.1,370.7L571.1,370.7l0.1,0.4l0.2,0.7l0.2,0.5l0.2,0.8l0.1,0.3v0.2l0.4,0.8l0.2,0.7l0.2,0.5
                l0.2,0.8l0.1,0.3l-0.2,0.1l-0.3,0.5l-0.3,1.6l-0.5,1.3l-0.1,0.8l-0.1,0.3l-0.2,0.4l-0.3,0.4l-0.5-0.2l-0.8-0.7l-0.5-0.7l-0.5-0.4
                l-0.5-0.6l-0.1-0.4v-0.3l-0.2-0.6l-0.2-0.3l-0.6-0.7l-0.2-0.4l-0.1-0.2l-0.1-0.2l-0.2-0.9l-0.2-0.6l-0.3,0.2v0.2l-0.2,0.3l-0.1,0.4
                l0.1,0.3l0.5,0.5l0.1,0.2l0.1,0.4v0.6l0.1,0.2l0.4,0.5l0.1,0.3l0.1,0.2l0.1,0.2l0.3,0.4l0.5,0.7l0.5,0.5l0.3,0.2l0.1,0.2v0.6v0.3
                l0.3,0.6l0.1,0.3l0.3,0.2l0.1,0.3l0.1,0.4l0.2,1.3l0.3,0.3l0.8,1.6l0.7,1l0.3,0.8l0.5,0.9l1,2l0.6,0.6l0.2,0.4l0.4,0.3l0.4,0.4h-0.4
                h-0.1l-0.1,0.1l-0.1,0.2v0.2l0.1,1l0.1,0.5l0.4,1l0.3,0.3l0.1,0.2l0.2,0.1l0.9,0.3l0.5,0.7l1.2,0.9l0.1,0.2v0.1h-0.9h-0.9h-0.9h-0.9
                H574h-0.9h-0.9h-1h-0.9h-0.9h-0.9h-0.9h-0.9h-0.9h-0.9H564h-0.5l0.1-0.3l0.1-0.2l-0.1-0.1h-0.2h-0.1l-0.3,0.6h-0.1h-0.3h-1.1h-1.3
                h-1.1H558h-1.1h-1.1h-1.1h-1.1h-1.1h-1.1h-1.1h-1.1h-1.1H547h-1.1h-0.7v-0.7v-0.7v-0.2v-0.7v-0.7v-0.7v-0.7v-0.7v-0.7V393v-0.7v-0.7
                v-0.7v-0.7v-0.7v-0.7v-0.7v-0.7v-0.5v-0.7v-0.7v-0.7v-0.7v-0.7v-0.7v-0.8v-0.7v-0.7v-0.7v-0.7v-0.7v-0.7v-0.1l-0.2-0.5l-0.1-0.6
                l-0.2-0.8v-0.2l-0.3-0.8v-0.2l0.1-0.2l0.4-0.7l0.1-0.3l0.1-0.4v-0.3l-0.1-0.5l-0.1-0.4v-0.5v-0.4l0.2-0.3l0.3-0.3l0.1-0.2l0.2-0.2
                l0.1-0.1l0.2,0.4l0.4,0.1l1.4-0.4l1.6,0.4l0.9,0.1l1.3,0.3l0.8,0.5l0.2,0.1h0.6l0.4,0.3l1.5,0.2l0.8,0.4l0.5,0.3l0.3,0.1h0.2
                l0.3-0.1l0.4-0.2l0.5-0.3l0.9-0.7l0.3-0.1h0.2h0.3l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.2h0.5l1-0.3l-0.1,0.1l-0.9,0.3h0.4l0.4-0.1
                l0.5-0.1l0.1-0.1l0.1-0.3h0.1l0.3,0.1l0.9,0.4h0.2l0.7-0.2h0.1l0.2,0.1l0.5,0.5H565l-0.5-0.5v0.2l-0.3,0.4l0.4,0.2l0.3,0.1l0.2,0.2
                l0.1,0.2l0.3-0.1l0.2-0.3l-0.1-0.1l-0.1-0.2h0.1l0.2,0.1l0.6,0.5l0.2,0.1h0.2l0.5-0.1h0.1l0.6-0.2l0.1,0.1l0.1,0.1l0.5-0.2h0.8
                l0.7-0.2L571.1,370.7L571.1,370.7z"/>
            <linearGradient id="EE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="405.908" x2="0.1864" y2="405.908" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="EE" class="stcustom0 st63" d="M553.7,254.9l0.1,0.2l0.2,0.3l0.1,0.2l-0.1,0.1l-0.2,0.1v0.1l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.5
                l-0.3,0.8l-0.4,0.6l-0.3,0.3l-0.1,0.2l-0.1,0.3v0.3l0.3,1.6v0.3l-0.1,0.3v0.3v0.3l0.2,0.4l0.2,0.7l0.1,0.4l0.1,0.2l0.1,0.1v0.1v0.1
                l-0.1,0.1l-0.6,0.2l-0.1,0.2l-0.1,0.2l-0.3,0.3l-0.1,0.3v0.3v0.1h-0.1l-0.4-0.1l-0.4-0.2l-0.2-0.2h-0.2l-0.2,0.1l-0.8,0.3l-0.2-0.1
                l-0.5-0.3l-0.2-0.3l-0.5-0.6V264l-0.1-0.1l-0.6-0.2l-0.2-0.2h-0.2l-0.2-0.1l-0.6-0.5h-0.2v0.1v0.1v0.1h-0.1l-0.2-0.2l-0.2-0.2
                l-0.6,0.3l-0.2,0.1h-0.2l-0.9,0.4l-0.3,0.2h-0.1v-0.2l0.4-1l0.1-0.8l0.1-0.1v-0.1l-0.1-0.3l-0.4-0.2H543l-0.1,0.3l-0.1,0.2l-0.3,0.1
                l-0.3-0.2l-0.7-0.3l-0.2-0.4v-0.4l-0.4-0.4l-0.2-0.4l0.1-0.3l0.3-0.2l0.1-0.2h-0.4h-0.1v-0.2l-0.2-0.5l0.2-0.2l0.1-0.2l-0.1-0.2
                v-0.2l0.1-0.2l-0.1-0.5l0.4-0.3l0.4-0.2l0.8-0.1l-0.1-0.4h0.3l0.6-0.5l0.6,0.1l0.8-0.4h1.6l0.2-0.2v-0.2v-0.2l0.3,0.1h0.5l1.9,0.4
                h0.5l0.6,0.5l0.3,0.1h1l1.6,0.2L553.7,254.9L553.7,254.9L553.7,254.9z M539.4,258.4l-0.2,0.3l-0.1-0.1l-0.1-0.1l-0.3,0.6l-0.3,0.1
                l-0.2-0.1v-0.2l-0.2-0.6l-0.3-0.2h-0.4l-0.3-0.2l1.1-0.2l0.1-0.3l0.2-0.3h0.2l0.1,0.1v0.2v0.1l0.5,0.1l0.2,0.4V258.4L539.4,258.4
                L539.4,258.4z M540.6,259.9l-0.2,0.1l-0.6-0.4l0.1-0.3l0.2-0.1l0.5,0.2V259.9L540.6,259.9L540.6,259.9z M538.6,259.6l0.2,0.1h0.2
                l0.2-0.1l0.4,0.1l0.9,0.7l0.1,0.2l-0.6,0.1l-0.1,0.2l-0.1,0.1h-0.2l-0.3,0.3l-0.4,0.3l-0.1,0.2h-0.7l-0.4,0.1l-0.3,0.3l-0.1,0.6
                l-0.2,0.5l-0.2,0.2h-0.2l-0.1-0.2v-0.2l0.5-0.6l0.1-0.2l-0.2-0.1l-0.2-0.2l-0.4-0.3l-0.1-0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.2l-0.3-0.6
                l0.2-0.1h0.2l0.2,0.2l0.2-0.2h0.1l0.2,0.1l0.2-0.4l0.4-0.1l0.2-0.1L538.6,259.6L538.6,259.6z"/>
            <linearGradient id="EH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="273.008" x2="0.1864" y2="273.008" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="EH" class="stcustom0 st64" d="M450.7,383.7v0.5v0.6v0.6v0.7v0.7v0.5v0.4h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5
                h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.3l-0.2,0.1
                l-0.4,0.2l-0.4,0.2l-0.5,0.1l-0.2,0.1l-0.3,0.2l-0.4,0.3l-0.4,0.3l-0.3,0.4l-0.1,0.2v0.2v0.2l0.1,0.4v0.2v0.3v0.4v0.4v0.4v0.4v0.4
                v0.4v0.3v0.4h-0.4h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.6h-0.4l-0.1,0.6l-0.1,0.4
                L428,403v0.3l-0.1-0.2l0.2-1.6v-0.1l0.2-1.4l0.4-0.8l0.3-0.3l0.5-0.2l0.4-0.8l0.2-0.7l0.3-0.3l0.1-0.3l-0.1-0.2l0.3-0.4l0.3-0.6
                l0.2-0.4l0.4-0.6v-0.1v-0.1l-0.2,0.1l-0.2,0.2l-0.2,0.2l0.1-0.2l0.1-0.3l0.3-0.3l0.5-0.4l1.1-1.2l0.4-0.2l0.4-0.5l0.1-0.5V390
                l0.1-0.6l0.2-0.4l0.3-0.8l0.2-0.4l0.1-0.7l0.2-0.3l0.3-0.1l0.4-0.4l0.6-0.2l0.7-0.5l0.3-0.3l0.2-0.4l0.2-0.9l0.4-0.9l0.2-0.7h12.2
                l0,0l0,0h0.4v0.5L450.7,383.7z"/>
            <linearGradient id="ER_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="246.0079" x2="0.1864" y2="246.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ER" class="stcustom0 st65" d="M596.1,427.1l-0.3,0.1l-0.3,0.1l0,0l-0.1,0.2l-0.2,0.4L595,428h-0.1l-0.5-0.4h-0.1l-0.1,0.1l-0.1,0.1
                l-0.2-0.3l-0.2-0.3l-0.3-0.3l-0.2-0.1l-0.3-0.2l-0.3-0.4l-0.3-0.5l-0.4-0.4l-0.7-0.5l-0.7-0.7l-0.5-0.7l-0.3-0.4l-0.1-0.1l-0.7-0.2
                l-0.5-0.3l-0.4-0.3l-0.2-0.1h-0.2l-0.5,0.1l-0.4-0.2h-0.2h-0.3l-0.2-0.1l-0.2,0.1l-0.5,0.1h-0.2l-0.1-0.2l-0.1-0.1l-0.2-0.1h-0.1
                l-0.1,0.1L584,422l-0.9,0.2h-0.2l-0.2-0.1l-0.4-0.5l-0.1-0.1h-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.1l-0.2,0.4l-0.3,0.7l-0.2,0.4
                l-0.2,0.5h-0.1h-0.1l-0.4-0.6l-0.3-0.2h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.1h-0.1h-0.2l-0.4-0.1h-0.4l-0.4,0.1h-0.1l-0.1-0.8l-0.1-0.6
                l-0.1-0.6L577,420l0.3-0.3l0.1-0.3l0.3-1.1l0.1-0.2l0.2-0.6v-0.2l0.2-0.7v-0.5v-0.5l0.1-0.3l0.1-0.2v-0.2l0.1-0.4v-0.1h0.1l0.3,0.1
                h0.2h0.3h0.2l0.1-0.1l0.2-0.5l0.1-0.1h0.1l0.2-0.1l0.2-0.2l0.2-0.1h0.1h0.2l0.2-0.1l0.1-0.1l0.2-0.1h0.2l0.1-0.1h0.1h0.1l0.1-0.1
                v-0.1l0.1-0.1l0.2-0.1l0.1-0.1v-0.1v-0.1l0.1-0.1l0.3-0.3l0.2-0.2l0.8,1.7l0.3,1l0.3,1l0.2,1.6l0.2,0.8l0.3,0.4l0.2,0.7h0.2l0.1,0.2
                l0.3,0.7l0.2,0.3l0.1-0.2v-0.1l-0.1-0.2l0.1-0.3l0.1-0.2l0.3,0.2l0.2,0.2v0.3l0.1,0.2l0.3,0.4l0.3,0.1h0.4l0.3,0.1l0.2,0.1l0.5,0.4
                l1.1,0.4l0.9,1.1l0.5,0.8l1.6,1.1l0.3,0.5l0.1,0.5h0.3l0.6,0.6l0.2,0.4l0.5,0.2l0.1-0.3l0.2,0.2L596.1,427.1L596.1,427.1z
                M587.6,417.4l0.1,0.3l-0.3-0.1v-0.1l0.1-0.1L587.6,417.4L587.6,417.4z M587.8,418.5l0.1,0.2h0.1l0.1-0.1v-0.1l0.4,0.2v0.1h-0.3
                l-0.3-0.1h-0.3l-0.3-0.1l-0.1-0.2l0.2,0.1h0.1l0,0l-0.2-0.2h-0.2v-0.1l0.1-0.1l0.1-0.1l-0.1-0.1h0.2l0.2,0.1l0.1,0.1L587.8,418.5
                L587.8,418.5z"/>
            <linearGradient id="ES_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="311.3079" x2="0.1864" y2="311.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ES" class="stcustom0 st66" d="M470.1,328.1v0.1l0.1,0.2l0.1,0.1l0.2,0.1h0.2h0.3l0.2,0.1v0.2v0.2l-0.1,0.2l-0.1,0.1l0.1,0.1l0.1,0.1
                h0.1h0.1v-0.1l0.1-0.1h0.1v0.1v0.1l0.3,0.1l0.7,0.3h0.3h0.2l0.1,0.1l0.4,0.4h0.1h0.2h0.2l0.2-0.1h0.1l0.1,0.1l0.1,0.1l0.2,0.1
                l0.2,0.2l0.1,0.1l0.7-0.1l0.2,0.1h0.2h0.2l0.4,0.1h0.3l0,0v-0.4l0.1-0.1h0.1h0.2l0.7,0.2l0.3,0.1l0.3,0.1h0.2l0.2,0.1l0.2,0.4v0.2
                v0.1v0.1v0.1l0.1,0.1h0.1h0.1l0.1-0.1l0.3-0.2h0.1l0.4,0.2l0.2,0.1l0.1,0.1l0.1,0.1h0.1l0.2-0.1l0.3-0.1l0.5,0.1l0.5,0.2h0.2v-0.1
                v-0.1l0.1-0.1h0.1l0.2-0.1l0.2-0.1h0.2l0.2,0.1l0.3,0.1h0.2l0.1,0.2l0.1,0.1l0.1,0.2l-0.2,0.1h-0.1v0.4l0.1,0.1l0.1,0.1v0.1v0.5
                l-0.3,0.3l-0.4,0.4l-1.9,1.1l-0.5,0.5l-0.2,0.1l-1.4,0.3l-1,0.4l-0.5,0.1l-0.6,0.6l-0.3,0.3l0.2,0.1l0.3,0.3l-0.1,0.1l-0.4,0.2
                l-0.2,0.1h-0.1H477l-0.7,1.1l-0.6,0.8l-0.3,0.3l-0.3,0.5l-0.7,1.3v0.4l0.3,1.3l0.2,0.3l0.3,0.3l0.5,0.2l0.1,0.2l-0.2,0.2l-0.5,0.4
                l-0.9,0.5l-0.4,0.4l-0.1,0.4l-0.3,0.2l-0.1,0.6l-0.2,0.4v0.1l-0.2,0.3v0.2l0.3,0.3l-0.1,0.1l-0.1,0.1h-0.3h-1.1l-0.9,0.6l-0.4,0.5
                l-0.4,1l-0.5,0.6l-0.2,0.1l-0.3-0.3h-0.4l-0.4,0.1l-0.2,0.2l-0.3,0.1l-0.3-0.1l-0.7-0.1h-0.3l-0.5,0.2l-0.4-0.1l-0.7-0.1l-1.5,0.1
                l-0.2,0.1l-0.2,0.2l-0.5,0.4h-0.7l-0.7,0.3l-0.2,0.2l-0.3,0.5l-0.1,0.3h-0.1l-0.1-0.1H460l-0.1,0.3l-0.2,0.1h-0.2l-0.5-0.2l-0.4-0.3
                h-0.2l-0.4-0.5l-0.2-0.3l-0.1-0.3v-0.1V353l-0.3-0.1l-0.1-0.3l0.2-0.4l0.2-0.2l0.1-0.1h-0.3l-0.2,0.3l-0.3-0.4l-1.1-0.8l0.1-0.2
                v-0.1l-0.2,0.2l-0.1,0.1h-0.6l-0.6,0.1l-0.2-0.9l-0.1-0.3v-0.2l0.2-0.5l0.2-0.2l0.2-0.4l0.3-0.4l0.3-0.1l0.1-0.1l0.1-0.3l0.1-0.2
                l0,0h-0.4l-0.7-1v-0.2l0.1-0.2l0.1-0.3v-0.2l0.2-0.2l0.3-0.2l0.2-0.3l0.1-0.3v-0.3l-0.1-0.2l-0.4-0.1l-0.4-0.7l-0.1-0.5h-0.1
                l-0.2-0.2l-0.2-0.4v-0.1l0.2-0.1h0.9l0.2-0.1l0,0l0.2-0.3l0.2-0.5v-0.3l-0.1-0.1l-0.3-0.3v-0.1v-0.1l0.2-0.2l0.3-0.2l0.1-0.2v-0.1
                l-0.1-0.1v-0.1v-0.1v-0.5v-0.1v-0.5l-0.1-0.4l-0.2-0.5v-0.1l0.1-0.1l0.3-0.2l0.2-0.4l0.3-0.3l0.5-0.3l0.3-0.3l0.1-0.2l0.1-0.1v-0.1
                l-0.1-0.2l-0.2-0.2l-0.2-0.1h-0.3h-0.2v-0.1v-0.3v-0.3v-0.1l-0.1-0.1h-0.2l-0.2-0.1h-0.2l-0.1,0.1h-0.5l-0.2-0.1l-0.1-0.1h-0.1v0.1
                v0.1v0.1l-0.2,0.1l-0.4,0.1h-0.3l-0.3-0.1l-0.1-0.1l-0.1-0.1l-0.6,0.1h-0.1l-0.2,0.1l-0.3,0.1H452h-0.1v-0.1l-0.1-0.2v-0.1l0.2-0.3
                v-0.1l-0.1-0.1l-0.1-0.2v-0.1h-0.1l-0.2,0.1l-0.6,0.2l-0.1,0.1l-0.3,0.2l-0.3,0.3l-0.2,0.1l-0.1-0.1v-0.6l0.3-0.4l0.2-0.2h-0.1h-0.2
                v-0.2l0.1-0.1l0.1-0.2l-0.1-0.1l-0.1-0.1v-0.4v-0.1v-0.2l-0.5,0.2h-0.1V331l0.3-0.4v-0.1l-0.3-0.1l-0.2-0.2l-0.1-0.2l-0.2-0.3v-0.2
                l0.2-0.5l0.2-0.2l0.2-0.1l0.4-0.4l0.6,0.1l0.4-0.1l0.3-0.2h0.2l0.3-0.2v-0.2l-0.1-0.2l0.1-0.2l0.3-0.2l0.4-0.3h0.4l0.4-0.2l0.3,0.1
                h0.3l0.3,0.2l0.4,0.4l0.6,0.2l0.5-0.1h0.8l0.4,0.1l0.7-0.1h0.4l0.7-0.2l0.5,0.2l1,0.1l0.6,0.2l1.6,0.3h0.6l0.8-0.2l0.4-0.1l0.3,0.1
                l0.5-0.2h0.2l0.3,0.2l1,0.3l0.3-0.3l0.2-0.1l0.8,0.2l0.8,0.3h0.4l0.6-0.1L470.1,328.1L470.1,328.1L470.1,328.1z M487.1,341.4
                L487.1,341.4l-0.9-0.4h-0.3l-0.1-0.1v-0.2v-0.1h0.6l0.5,0.2l0.3,0.4v0.1L487.1,341.4L487.1,341.4z M483.9,341.6l0.3,0.1l0.3-0.1h0.2
                l0.1,0.1v0.2l-0.1,0.3l-0.2,0.3l-0.2,0.3l-0.1,0.3l-0.3,0.2l-0.2,0.1l-0.5-0.2l-0.3-0.1l-0.1-0.1l-0.1-0.4l-0.1-0.1l-0.2-0.1
                l-0.2,0.1l-0.2,0.2l-0.1-0.2h-0.2l-0.1-0.1v-0.1l1.2-0.9l0.3-0.2l0.7-0.2h0.1l-0.1,0.1v0.1l0.1,0.1v0.1l-0.1,0.1L483.9,341.6z
                M479.2,344.8l-0.1,0.2l-0.4-0.1l-0.1-0.1l0.1-0.2h0.1v-0.2l0.1-0.2l0.6-0.1l0.1,0.1v0.2L479.2,344.8L479.2,344.8L479.2,344.8z
                M479.6,345.6L479.6,345.6h-0.2h-0.3v-0.1v-0.1l0.1-0.1l0.2,0.2h0.3L479.6,345.6L479.6,345.6z M436.6,378.5l-0.2,0.2l-0.2-0.1
                l0.1-0.5l0.1-0.1l0.4-0.2l0.3-0.1l0.1-0.2l0.1-0.1l0.1,0.1l-0.1,0.1l-0.1,0.5l-0.2,0.1L436.6,378.5L436.6,378.5z M425,379.9
                L425,379.9l-0.1-0.2l-0.3-0.6l0.2-0.3h0.4l0.1,0.2v0.2l-0.1,0.1v0.2v0.1L425,379.9L425,379.9z M435.3,380.9l-0.4,0.4l-0.4-0.1
                l-0.1-0.1l0.4-0.1l0.3-0.3l0.2-0.6l0.3-0.7l0.1-0.3l0.1-0.1h0.2h0.1l0.1,0.1v0.3l-0.1,0.6l-0.2,0.5L435.3,380.9L435.3,380.9z
                M429.3,380.2l-0.2,0.7l-0.2,0.3l-0.1,0.1l-0.3,0.1l-0.4-0.4l-0.2-0.5l-0.1-0.1l0.2-0.1h0.3l0.5-0.1h0.1l0.6-0.5l0.5-0.1v0.2
                L429.3,380.2L429.3,380.2z M426.9,381.3h-0.1l-0.1-0.1l-0.1-0.3l0.1-0.2l0.1-0.1h0.1l0.2,0.1l0.1,0.1v0.1L426.9,381.3L426.9,381.3z
                M431.9,381v0.2l0.1,0.2v0.4l-0.1,0.2l-0.3,0.2h-0.3l-0.2-0.1l-0.3-0.3v-0.3l0.2-0.2l0.1-0.3l0.6,0.1L431.9,381L431.9,381L431.9,381
                L431.9,381z M424.9,382l-0.3,0.5l-0.3-0.2l-0.1-0.1l-0.1-0.1h0.3l0.3-0.3L424.9,382L424.9,382z"/>
            <linearGradient id="ET_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="228.8579" x2="0.1864" y2="228.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ET" class="stcustom0 st67" d="M583,422.2h0.2l0.9-0.2l0.5-0.3l0.1-0.1h0.1l0.2,0.1l0.1,0.1l0.1,0.2h0.2l0.5-0.1l0.2-0.1l0.2,0.1h0.3
                h0.2l0.4,0.2l0.5-0.1h0.2l0.2,0.1l0.4,0.3l0.5,0.3l0.7,0.2l0.1,0.1l0.3,0.4l0.5,0.7l0.7,0.7l0.7,0.5l0.4,0.4l0.3,0.5l0.3,0.4
                l0.3,0.2l0.2,0.1l0.3,0.3l0.2,0.3l0.2,0.3l-0.3,0.4l-0.4,0.5l-0.4,0.6l-0.1,0.2l-0.4,0.4l-0.1,0.1l-0.1,0.3v0.5v0.6v0.6l0.2,0.1h0.2
                l0.3-0.1l0.3-0.1h0.4l0.4-0.1l0.3-0.1h0.3l0.2,0.1l0.1,0.1h0.2h0.2v0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.3,0.5v0.1v0.1l0.2,0.2
                l0.2,0.3l0.1,0.3l0.1,0.2l0.2,0.2l0.3,0.4l0.2,0.2l0.3,0.1l0.1,0.3l0.2,0.5l0.3,0.4l0.2,0.3l0.3,0.1h0.1l0.6,0.5l0.4,0.4l0.1,0.1
                l0.8,0.3l0.9,0.3l0.7,0.2l0.9,0.3l0.9,0.3l0.9,0.3l1.2,0.4l1,0.3l0.8,0.3l0.2,0.1h0.9h0.9h1l-0.7,0.7l-0.8,0.8l-0.8,0.8l-0.5,0.5
                l-0.8,0.8l-0.7,0.7l-0.7,0.7l-0.7,0.7l-0.8,0.9l-0.5,0.6l-0.9,0.9l-0.5,0.6h-0.1h-0.8h-0.8l-1-0.1h-0.1l-0.3,0.1l-0.2,0.1l-0.7,0.2
                h-0.1l-0.6,0.3l-0.6,0.3l-0.3,0.2l-0.2,0.3l-0.1,0.2l-0.1,0.1l-0.2,0.1l-1.2,0.2h-0.4l-0.6,0.2l-0.3,0.3l-0.1,0.1h-0.4h-0.7h-0.3
                h-0.2h-0.3l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.4-0.4l-0.3-0.2l-0.7,0.3l-0.6,0.3l-0.8,0.4l-0.5,0.3l-0.1,0.3l-0.4,0.5l-0.3,0.3H586
                l-0.8-0.1l-0.3-0.1l-0.5-0.1l-0.6-0.1l-0.4-0.1H583h-0.6l-0.4-0.1l-0.4-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.6-0.4l-0.7-0.4h-0.2h-0.1
                h-0.8h-0.8h-0.5l-0.2-0.1l-0.1-0.1l-0.2-0.3l-0.2-0.2l-0.2-0.3v-0.4l0.1-0.4l0.1-0.1v-0.1v-0.2l-0.1-0.2l-0.8-0.2h-0.1l-0.1,0.1
                l-0.1,0.1l-0.1-0.1l-0.1-0.1V448v-0.1v-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.3l-0.1-0.2l-0.5-0.9l-0.2-0.3l-0.2-0.4l-0.2-0.8l-0.1-0.3
                l-0.2-0.2l-0.2-0.2l-0.2-0.3l-0.6-0.3l-0.2-0.2l-0.4-0.4l-0.1-0.2v-0.2l-0.1-0.2l-0.2-0.2l-0.7-0.5l-0.2-0.1h-0.2h-0.3l-0.5-0.1
                l-0.4-0.2l-0.2-0.1v-0.1v-0.1l0.1-0.3l0.3-0.6l0.2-0.4l0.1-0.1h0.4h0.4h0.3h0.4h0.5l0.2-0.1l0.1-0.1l0.1-0.1v-0.3v-0.2v-0.8V437
                v-0.6v-0.1v-0.1l0.1-0.6l0.1-0.4l0.1-0.2l0.3-0.6l0.1-0.2V434l-0.1-0.8l0.2-0.4l0.2-0.4l0.2-0.2l0.2-0.1h0.1l0.2,0.2l0.3,0.2h0.1
                l0.2-0.1l0.1-0.2v-0.3l0.1-0.6V431l0.1-0.4l0.1-0.6l0.1-0.4l0.1-0.2l0.4-0.4l0.3-0.6l0.2-0.4l0.4-0.7l0.2-0.2l0.2-0.1l0.3-0.1
                l0.5-0.1l0.3-0.1v-0.1v-0.1v-0.3l0.1-0.5l0.1-0.5l0.2-0.4l0.1-0.2l0.1-0.2l0.1-0.3l0.2-0.6v-0.4l0.2-0.8l0,0l0.4-0.1h0.4l0.4,0.1
                h0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.2l0.3,0.2l0.4,0.6h0.1h0.1l0.2-0.5l0.2-0.4l0.3-0.7l0.2-0.4l0.2,0.1l0.2,0.2l0.2,0.1l0.2,0.1
                h0.1l0.1,0.1l0.4,0.5L583,422.2z"/>
            <linearGradient id="FI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="447.1079" x2="0.1864" y2="447.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FI" class="stcustom0 st68" d="M556.4,192.6l-0.2,0.1l-0.6,0.4l-0.4,0.3l-0.4,0.2l0.1,0.2l0.7,0.1l0.1,0.1l0.1,0.1v0.2l-0.1,0.3
                l-0.8,1.8v0.4l0.2,1l0.4,1.2l1.1,0.5l0.8,0.4l0.5,1l0.8,1.3l0.4,0.5v0.1l-0.1,0.9l-0.5,0.9l-0.5,0.7l-0.5,0.9l-0.4,0.8l-0.4,0.9
                l-0.1,0.3v0.3l0.1,0.3l0.6,1.1l0.2,0.6l0.3,0.6l0.2,0.6l0.1,0.6l0.2,0.6l0.1,0.3l0.2,0.4l0.3,0.6l0.1,0.5l0.4,1.6v0.4v0.3l-0.2,0.1
                h-0.4l-0.4,0.2v0.1l0.3,0.4l-0.3,0.6v0.9l-0.3,0.5v0.1v0.1v0.1l0.5,0.1v0.1v0.3v0.2l-0.3,0.2l-0.3,0.3l-0.1,0.2v0.2l0.1,0.4l0.2,0.4
                l0.2,0.3l0.8,0.3l0.1,0.2v0.3v0.3l-0.4,0.6v0.2l0.2,0.5l0.2,0.5l0.8,0.5l0.3,0.3l0.1,0.2v0.4v0.4l-0.1,0.4l-0.2,0.5l-0.6,0.9
                l-0.6,0.4v0.1l0.2,0.3l1,1.2l0.7,0.5l0.9,0.7l0.6,0.6l0.2,0.4l0.2,0.5l0.3,0.4l0.2,0.3l0.1,0.2v0.2l-0.3,0.7l-0.2,0.5l-0.3,0.8
                l-0.3,0.5l-0.7,1l-1,1.2l-0.2,0.4l-0.5,0.6l-0.8,1.2l-0.2,0.3l-0.7,1l-0.3,0.3l-0.2,0.3l-0.7,0.9l-0.7,0.7l-0.7,0.6l-0.2,0.3
                l-0.3,0.2l-0.3,0.2l-0.1,0.1l-0.7,0.9l-1,1.2h-0.1l-0.3,0.2h-0.4l-0.2,0.1l-0.6-0.4h-0.1l-0.4,0.1l-0.3,0.3l-0.6,0.1l-0.3,0.1
                l-0.2,0.1v-0.3l0.1-0.4l0.1-0.3v-0.2h-0.1l-0.2,0.4l-0.1,0.5l-0.2,0.2l-0.5,0.1l-0.5-0.4h-0.2l0.1,0.3l0.1,0.3v0.2h-0.3l-0.3,0.2
                l-0.2,0.3h-0.1l-0.2-0.4l-0.3,0.2l-0.3,0.2l-0.5,0.1l-0.3,0.3l-0.6,0.2h-0.3l-0.7,0.2l-0.2,0.4l-0.2,0.1l-0.3-0.1l-0.9,0.2l-0.9,0.2
                h-0.4l-0.4-0.1l-0.4,0.3l-0.1,0.6l-0.4,0.2l-0.2-0.1l0.1-0.2l0.3-0.2l0.2-0.3V252l-0.1-0.1h-0.2l-0.2-0.3l-0.2-0.6h-0.1l-0.2-0.1
                l-0.1,0.5l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.5l-0.1-0.2v-0.1l0.1-0.3l-0.1-0.1l0.1-0.2h0.1h0.1l0.1-0.1v-0.2h-0.2v-0.1
                l0.2-0.4V250h-0.1h-0.1l-0.7-0.1l-0.9-0.6h-0.2l-0.1-0.5l-0.2,0.1l-0.3,0.3l-0.2-0.2l-0.3-0.1l-0.1-0.2v-0.3V248l-0.1-0.5l-0.1-0.7
                v-0.5l0.2-0.4l0.1-0.3l0.1-0.6v-0.8l-0.1-0.3v-0.2h0.2v-0.1l-0.1-0.1l-0.1-0.2l0.1-0.1h0.2v-0.1V243l-0.2-0.4v-0.2l-0.2-0.6
                l-0.2-0.6l-0.4-0.4l0.1-0.7l0.1-0.7v-0.3l-0.1-0.4l-0.4-0.4l-0.1-0.6l-0.1-0.7v-0.4l0.1-0.3l0.1-0.3l0.7-1v-0.5h0.5l-0.2-0.5
                l-0.1-0.3v-0.3l0.7-0.2l0.3,0.2l0.6-0.2l0.6-0.4v-0.2l-0.1-0.2l-0.1-0.4l0.1-0.1l0.2,0.1l-0.1-0.2v-0.2l0.2,0.1l0.4-0.6v-0.4
                l0.6-0.2l0.7-0.9l0.3-0.3l0.3-0.2l0.7-0.9h0.3l0.2-0.6l0.6-0.8l0.2-0.1l0.3-0.7l0.7-0.8l0.5-1.1l0.3-0.4l0.1-0.4h0.3l0.3-0.3
                l0.5-0.2l0.5,0.1l0.2,0.1h0.2v-0.4l-0.1-0.2l0.1-0.2l0.3-0.2v-0.4v-0.7l-0.2-0.3l0.1-0.7v-0.7l0.1-0.9l-0.3-0.5l-1.1-0.8h-0.2
                l-0.3-0.1l-0.3-0.6l0.1-0.5v-0.2H544l-0.2,0.2l-0.4,0.3l-0.5-0.2h-0.2l-0.3-1.3l-0.2-0.5l-0.2-0.6l-0.4-0.3l-0.1-0.2l-0.1-0.3v-0.4
                l-0.1-0.5v-0.4l0.1-0.3l0.2-0.2l0.3-0.5l0.1-0.4v-0.6l0.1-0.5l0.1-0.2v-0.2l-0.1-0.3l-0.2-0.4l-0.3-0.5l-0.2-0.5l-0.1-0.4l-0.1-0.4
                v-0.4l0.1-0.2l0.3-0.3V205l-0.1-0.7l-0.2-0.1l-0.3-0.1h-0.2V204v-0.1v-0.3l0.1-0.3l0.1-0.2v-0.2l-0.1-0.6v-0.7V201l0.4-0.4v-0.2
                l-0.5-0.5l-0.3-0.5l-0.1-0.3H540l-0.2-0.9l-0.3-0.4l-0.3-0.4l-0.2-0.2l-1.2-0.6l-0.5-0.1l-0.6-0.3l-0.4-0.4l-0.4-0.3l-0.3-0.3
                l-0.4-0.3l0,0l-0.5-0.5l-0.2-0.3l-0.7-0.6v-0.2v-0.2V193l-0.8-0.4l0.2-0.3h0.6l0.5,0.2l0.1-0.1l0.1-0.2l-0.2-0.8v-0.2l0.2-0.3
                l0.3-0.2h0.5h0.4h0.1l0.6,0.9l0.5,0.9l0.3,0.4l0.6,1.1l0.2,0.6l0.1,0.4h0.3l0.9,0.2l0.7,0.2l0.2,0.2h0.5l0.4-0.2l0.7-0.3l0.2-0.3
                l0.2-0.4l0.4,0.1l0.4,0.3l0.5,0.4l0.4,0.2l0.6,0.3l0.3,0.4l0.4,0.1l0.4-0.4l0.2-1l0.2-0.4l0.3-0.3l0.3-0.1l0.3-0.1l0.2-0.2l0.3-0.6
                l0.1-0.7l-0.1-1.2l0.1-0.4l0.2-0.7l0.3-1.8l0.1-0.5l0.2-0.3l0.2-0.2l0.4-0.5l0.6-1.1l0.2-0.1l0.4-0.1h0.5l0.5,0.2h0.1l0.2-0.1
                l0.4-0.3l0.7-0.7l0.4-0.2h0.4l0.4,0.7l0.6,0.8l0.4,0.4l1.1,0.7l1,0.5l0.5,1.6l-0.3,0.6l-0.1,0.2l-0.5,0.6l-0.5,0.9v0.5l0.2,0.5
                L556.4,192.6L556.4,192.6z M544.8,221.6l-0.4,0.2l-0.3-0.1v-0.4l0.2-0.2l0.4-0.1l0.5,0.2l0.1,0.1l-0.3,0.1L544.8,221.6z
                M534.6,232.9v0.1h0.2l0.2-0.2l0.2,0.1v0.3h-0.1v-0.1l-0.1,0.2v0.1l-0.2,0.1l-0.3-0.3l-0.2-0.5h0.4v0.1L534.6,232.9L534.6,232.9z
                M535.3,249v0.3h-0.2h-0.2l-0.2-0.3l-0.1-0.4v-0.1l0.1-0.1l0.1,0.2L535.3,249L535.3,249z M536.8,250.1h-0.2l-0.3-0.3v-0.1l0.1-0.1
                l-0.1-0.2v-0.1l0.2,0.2l0.1,0.2h-0.1l0.2,0.2L536.8,250.1L536.8,250.1z M537.3,249.9l0.4,0.1h0.1l0.2,0.3l-0.3,0.2v0.2l0.1,0.1v0.2
                h-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.1l0.1-0.2v-0.2H537.3L537.3,249.9z M536.4,251.2l-0.3,0.2H536v-0.3l0.2-0.1h0.3
                L536.4,251.2L536.4,251.2z M535.8,251.4l-0.2,0.1l-0.2-0.2l0.1-0.1l0.2-0.1h0.2v0.2L535.8,251.4L535.8,251.4z"/>
            <linearGradient id="FJ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="154.4579" x2="0.1864" y2="154.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FJ" class="stcustom0 st69" d="M972.2,498L972.2,498h-0.3l0,0h0.1h0.1H972.2L972.2,498L972.2,498L972.2,498z M980.4,508.5l-0.1,0.1
                l0,0l0,0L980.4,508.5L980.4,508.5L980.4,508.5L980.4,508.5z M980.5,509.5l-0.2,0.1v-0.1l0.2-0.1h0.1L980.5,509.5L980.5,509.5
                L980.5,509.5z M983.2,511.7l-0.1,0.1H983l0.1-0.2v-0.1l-0.1-0.1v-0.1l0,0l0.1,0.1l0.1,0.1l0,0V511.7L983.2,511.7z M985.2,513.7H985
                l-0.1-0.1l0.1-0.1h0.1L985.2,513.7L985.2,513.7z M983.1,513.8L983.1,513.8L983.1,513.8h-0.2v-0.1l0.1-0.1L983.1,513.8L983.1,513.8
                L983.1,513.8z M983.7,514.5L983.7,514.5h-0.2l-0.1-0.1L983.7,514.5L983.7,514.5L983.7,514.5L983.7,514.5z M980.8,516.6L980.8,516.6
                L980.8,516.6L980.8,516.6L980.8,516.6L980.8,516.6l-0.1,0.2l0,0l0,0L980.8,516.6L980.8,516.6L980.8,516.6L980.8,516.6L980.8,516.6
                L980.8,516.6z M984.4,517.3L984.4,517.3L984.4,517.3h-0.2l0,0v-0.1L984.4,517.3L984.4,517.3L984.4,517.3L984.4,517.3L984.4,517.3
                L984.4,517.3L984.4,517.3L984.4,517.3L984.4,517.3z M983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7
                L983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7L983.9,521.7z M980.3,508.5l-0.4,0.4l-0.2,0.2l-0.1,0.2l-0.4,0.2
                l-0.2,0.3v0.3l0.4-0.3l0.4-0.3l0.1-0.1h0.1v0.1l-0.1,0.1v0.2l0.1,0.2h-0.3h-0.3l-0.4,0.1l-0.3,0.1h-0.1h-0.1l-0.1-0.1l-0.1-0.1h-0.1
                h-0.3l-0.4,0.3l-0.1,0.3h-0.2l-0.2-0.1l-0.2,0.2l-0.3,0.1l-0.1-0.2l-0.1-0.2l-0.1-0.1h-0.3v-0.2l0.1-0.1l0.1-0.1v-0.1l0.1,0.1h0.1
                l0.2-0.1h0.2l0.2-0.3l0.3-0.2l0.4-0.1l0.4-0.1h0.2l0.2-0.1l0.3-0.2l0.2-0.1l0.2-0.1h0.2h0.2h0.2L980.3,508.5L980.3,508.5
                L980.3,508.5z M980.3,509.6L980.3,509.6L980.3,509.6v-0.1V509.6z M980.7,510L980.7,510l-0.4,0.3l0,0l-0.2,0.3l-0.1,0.3l0.1,0.1
                l0.2-0.1l0.1-0.1l0.3-0.2l0.1-0.2L980.7,510L980.7,510z M972.5,511.4L972.5,511.4v-0.2l0.1-0.1h0.1h0.1v0.2L972.5,511.4z M978.6,512
                L978.6,512l-0.1-0.3h0.1h0.1v0.1L978.6,512L978.6,512z M975.4,512L975.4,512l0.1,0.2h0.1l0.2,0.2l0.3,0.2l0.2,0.2v0.1l-0.1,0.1
                l0.1,0.3v0.3l0.1,0.4l-0.2,0.1h-0.3l-0.1,0.1h-0.1h-0.3l-0.2,0.1l-0.2,0.2h-0.3h-0.3h-0.3l-0.2-0.1l-0.4-0.1l-0.5-0.1l-0.2-0.1
                l-0.2-0.1l-0.2-0.3v-0.2v-0.2l0.1-0.1l0.1-0.1V513l0.1-0.1h0.1l0,0v-0.2v-0.1l0.3-0.3l0.3-0.2l0.6-0.2h0.3l0.5-0.2l0.2-0.1h0.2
                L975.4,512L975.4,512z M977,513.1L977,513.1l-0.2-0.1l0.1-0.2h0.1L977,513.1L977,513.1L977,513.1z M978.4,514.2L978.4,514.2
                l-0.2-0.3v-0.1v-0.1l0.1-0.1l0.1,0.1l0.1,0.2L978.4,514.2L978.4,514.2z M976,516.7L976,516.7l-0.4,0.2l-0.1-0.1h-0.1l-0.2,0.2
                l-0.1,0.1v0.1H975l-0.4,0.1l-0.2-0.1l0.1-0.1l0.1-0.1h0.1l0.1-0.1l0.1-0.2h0.2l0.1-0.1h0.2L976,516.7L976,516.7z M965.2,524.8
                L965.2,524.8L965.2,524.8L965.2,524.8L965.2,524.8L965.2,524.8L965.2,524.8z"/>
            <linearGradient id="FK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="33.4581" x2="0.1864" y2="33.4581" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FK" class="stcustom0 st70" d="M309.9,630.1l0.4,0.3l0.5-0.1l0.2,0.1l0.1,0.2l-0.1,0.2h-0.2l-0.1,0.1v0.3l0.1,0.1l0.6,0.3h0.1v-0.1
                l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l0.6-0.1l0.2,0.1l0.3,0.5l-0.3,0.1l-0.1,0.2l0.3,0.1l0.2,0.1l-0.1,0.2v0.1l-0.5,0.2l-0.4,0.1
                l-0.2,0.3l-0.3,0.2l-1,0.3l0.1,0.3v0.1v0.3l-1.3-0.4h-0.2l0.4,0.7l-0.3,0.1l-0.3-0.1l-0.2,0.1l-0.2,0.5l-0.4-0.3l-0.3-0.5v-0.3
                l0.3-0.5l-0.1-0.2l0.7-0.7l0.1-0.2l0.2-0.1h0.2l0.1-0.1v-0.2l-0.1-0.3V631l0.6-0.6l-0.1-0.4L309.9,630.1L309.9,630.1z M306.4,630.7
                H306l-0.1-0.1v-0.4h0.3l0.3,0.2L306.4,630.7L306.4,630.7L306.4,630.7z M305.9,631l0.4,0.1l0.4-0.3l0.3-0.1l0.2,0.1l0.1,0.2h0.2
                l0.6-0.2l0.1,0.1l0.2-0.2l0.2,0.1l0.1,0.2l-0.1,0.2l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.4,0.6l-0.6,0.7l-0.2,0.1h-0.4
                l-0.2-0.1h-0.1l-0.1,0.4l-0.2,0.3l-0.1,0.1h-0.2h-0.1l-0.1,0.1h-0.5l-0.4-0.2l-0.4-0.4l0.6-0.5h0.5l0.4-0.3l0.3-0.2l0.1-0.2l0.1-0.1
                v-0.2l-0.1-0.1h-0.1l-0.1,0.1l-0.3,0.1l-0.2-0.2l0.2-0.1h0.2l0.5-0.2l0.1-0.1l-0.2-0.3l-0.3-0.2l-0.3-0.3v-0.1v-0.2l-0.1-0.3h0.1
                l0.2,0.2L305.9,631L305.9,631z M303.8,632.4l0.2,0.1h0.2l-0.1,0.5l-0.1,0.2h-0.2l-0.2-0.3l-0.1-0.2l0.3-0.1L303.8,632.4z
                M311.1,633.5v0.4l-0.2-0.1l-0.1-0.2l0.1-0.1L311.1,633.5L311.1,633.5L311.1,633.5z M307.6,634.5l-0.2,0.1l0,0l-0.1-0.2v-0.2v-0.1
                h0.1l0.2,0.2V634.5L307.6,634.5z"/>
            <linearGradient id="FM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="223.8579" x2="0.1864" y2="223.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FM" class="stcustom0 st71" d="M862.8,436.2l-0.2,0.2v-0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.1l0.1,0.1l-0.1,0.1L862.8,436.2L862.8,436.2z
                M901.3,442L901.3,442L901.3,442L901.3,442L901.3,442L901.3,442L901.3,442L901.3,442L901.3,442z M900.7,442.3L900.7,442.3h-0.2l0,0
                l0,0h0.1v-0.1l0,0l0,0h0.1L900.7,442.3L900.7,442.3L900.7,442.3L900.7,442.3z M919.4,443.8l-0.2,0.1H919l-0.1-0.2l-0.1-0.1v-0.1
                l0.1-0.1l0.3,0.1l0.1,0.2l-0.1,0.1H919.4L919.4,443.8z M932.5,448v0.1l-0.2-0.1l0,0H932.5L932.5,448L932.5,448z"/>
            <linearGradient id="FO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="424.7579" x2="0.1864" y2="424.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FO" class="stcustom0 st72" d="M457.1,238.9l-0.1,0.4l-0.2-0.1h-0.1v-0.1v-0.3v-0.5l0.2,0.4L457.1,238.9z M456.5,239.1l-0.1,0.8h-0.1
                l-0.2-0.2h-0.2v0.1v0.1l0.1,0.1l0.2,0.5v0.2v0.1l-0.2-0.2l-0.6-0.7l-0.4-1.2l0.6-0.2l0.4,0.3L456.5,239.1L456.5,239.1z M454.9,239.6
                l0.3,0.2l0.1,0.2l-0.1,0.2H455h-0.2l-0.3-0.2l-0.1-0.4h0.2l0.3-0.1L454.9,239.6z M456.5,241.6l-0.1,0.2h-0.1l-0.3-0.3l-0.2-0.2
                l-0.1-0.1l-0.1-0.2h0.1l0.1,0.1l0.4,0.2l0.1,0.2L456.5,241.6z M456.3,243.7l0.1,0.2h-0.1l-0.2-0.2l-0.3-0.5l-0.1-0.4v-0.2h0.1
                l0.1,0.2l0.3,0.1l0.1,0.1v0.2l0.1,0.2L456.3,243.7L456.3,243.7z"/>
            <linearGradient id="FR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="349.858" x2="0.1864" y2="349.858" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="FR" class="stcustom0 st73" d="M483.1,298h0.2l0.3-0.2l0.2-0.1l0.1,0.1l0.1,0.1l0.1,0.3v0.3l0.1,0.3l0.1,0.1h0.5l0.3,0.1l0.1,0.1
                l0.1,0.6l0.1,0.1l0.1-0.1l0.1-0.1h0.1h0.2h0.3l0.3,0.1l0.4,0.3v0.1l-0.1,0.2v0.2l0.1,0.1l0.1,0.2v0.2l-0.1,0.1v0.1v0.1v0.1h0.1
                l0.5,0.1l0.5-0.1l0.3-0.2l0.1-0.2l0.1-0.2l0.2-0.2l0.1-0.1l0.1,0.1l-0.2,0.8l0.1,0.2v0.3l0.1,0.3h0.2h0.2l0.2,0.1l0.2,0.2l0.3,0.1
                h0.2l0.1,0.1l0.1,0.1l0.2,0.3l0.2,0.2h0.1l0.2-0.1h0.3h0.2l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.1h0.1h0.2l0.1-0.1l0.2-0.1h0.2l0.1,0.1
                l0.2,0.1h0.1l0.2,0.1l0.2,0.2l0.1,0.2v0.1l0.1,0.1l0.4,0.6h0.1l0.1-0.1l0.1-0.1h0.1l0.2,0.1l0.1,0.1l0.1,0.2l0,0l0.1-0.1h0.1
                l0.2,0.1l0.3-0.1l0.3-0.1h0.1l0.2,0.3l0.2,0.1l0.5,0.1l0.6,0.1l0.2,0.1l0.2,0.1v0.4v0.1l-0.6,0.7l-0.2,0.3l-0.1,0.4l-0.1,0.6
                l-0.2,0.5l-0.2,0.5l-0.1,0.4l0.1,0.3v0.4l-0.2,0.6v0.4l0.1,0.3l0.1,0.1l-0.3,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.1h-0.2h-0.2l-0.4-0.5
                v-0.1l-0.1-0.1h-0.2l-0.2,0.1l-0.2,0.2l0.1,0.1h0.2v0.1v0.1l-0.1,0.1l-0.1,0.1l-0.4,0.4l-0.4,0.4l-0.1,0.1l-0.1,0.1l-0.5,0.2v0.1
                v0.4l-0.1,0.3l-0.4,0.3l-0.3,0.3l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.1l0.2,0.2v0.2l-0.1,0.2l-0.2,0.2l-0.2,0.1v0.3h0.1h0.2l0.3-0.2
                l0.2-0.2l-0.1-0.2l0,0v-0.1l0.2-0.2l0.3-0.1h0.4l0.5,0.1l0,0v0.2v0.2l0.1,0.2l-0.1,0.4l0.1,0.1l0.1,0.2l0.1,0.2l0.2,0.1l0.1,0.2v0.1
                l-0.2,0.2l-0.4,0.2v0.1v0.2v0.1l0.2,0.2l0.2,0.4l0.1,0.3l0.3,0.3l0.1,0.1v0.1l-0.1,0.1l-0.1,0.4l-0.1,0.1h-0.1l-0.4,0.3h-0.2h-0.2
                l-0.2,0.1v0.2l0.2,0.2l0.1,0.2v0.2l0.2,0.2l0.2,0.1h0.1l0.1,0.1l0.1,0.1l0.1,0.4l-0.1,0.1h-0.1l-0.1,0.2l-0.2,0.3l-0.1,0.2l0.1,0.2
                v0.1l-0.1,0.1l0.1,0.2l0.2,0.2l0.5,0.3l0.5,0.2h0.1l0.6-0.2h0.1l0.1,0.2v0.1l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.1,0.2v0.2v0.2l-0.2,0.1
                l0,0h-0.1H495l0,0v0.1l-0.3,0.1l-0.2,0.1l-0.9,0.9l-0.4,0.2l-0.1,0.1l-0.1,0.3l-0.2,0.2l-0.2,0.1l-0.5,0.1l-0.5,0.3l-0.2-0.1h-0.6
                l-0.4-0.3l-0.7-0.2l-0.2-0.4h-0.3H489l-0.1-0.1v-0.2v-0.1l-0.2,0.1h-0.2l-0.1,0.1l-0.1,0.1h-0.1h-0.1v0.1h-0.2l-0.2-0.1l-0.6-0.2
                H487l-0.4-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.4,0.1l-0.1,0.2l-0.2,0.2l-1.5,1l-0.3,0.4l-0.3,0.6v0.3l0.1,0.9l0.3,0.5v0.1h-0.2
                l-0.3-0.1l-0.2-0.1h-0.2l-0.2,0.1l-0.2,0.1h-0.1l-0.1,0.1v0.1v0.1H482l-0.5-0.2l-0.5-0.1l-0.3,0.1l-0.2,0.1h-0.1l-0.1-0.1l-0.1-0.1
                l-0.2-0.1l-0.4-0.2v-0.1l0.1-0.1v-0.1l-0.1-0.1l-0.4-0.1H479l-0.1,0.1l-0.1,0.1l-0.2-0.4l-0.2-0.1h-0.2l-0.3-0.1l-0.3-0.1l-0.7-0.2
                h-0.2h-0.1l-0.1,0.1v0.4l0,0h-0.3l-0.4-0.1h-0.2h-0.2l-0.2-0.1l-0.7,0.1l-0.1-0.1l-0.2-0.2L474,330l-0.1-0.1l-0.1-0.1h-0.1l-0.2,0.1
                h-0.2h-0.2H473l-0.4-0.4l-0.1-0.1h-0.2H472l-0.7-0.3L471,329v-0.1v-0.1h-0.1l-0.1,0.1v0.1h-0.1h-0.1l-0.1-0.1l-0.1-0.1l0.1-0.1
                l0.1-0.2v-0.2v-0.2l-0.2-0.1h-0.3h-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.2v-0.1l0.5-0.1l0.4-0.5l0.4-1.8l0.3-2.1l0.2-0.4l0.3-0.1l-0.2-0.3
                l-0.1,0.1l-0.1,0.2l-0.1,0.1l0.2-2l0.1-0.7l0.2-0.8l0.4,0.3l0.3,0.3l0.2,0.3l0.2,0.9l0.2,0.2l0.2,0.2l-0.1-0.2l-0.2-0.2l-0.3-1.2
                l-0.2-0.3l-0.3-0.3l-0.8-0.6l-0.1-0.1v-0.2h0.3l0.2,0.1v-0.1l-0.1-0.1l-0.1-0.5l-0.1-1.1v-0.2v-0.2L471,316h-0.2l-0.2-0.1l-1.1-0.7
                l-0.4-0.7l-0.4-0.5l-0.1-0.2v-0.2l0.2-0.5l-0.2-0.3l-0.2-0.1l-0.1-0.2l0.1-0.3l0.1-0.2h0.2l0.3,0.1l0.3,0.1h0.2l-0.7-0.4l-1.1,0.1
                h-0.2l-0.2-0.1l-0.1-0.3l0.2-0.1l0.1-0.2l-0.2-0.2l-0.2-0.1h-0.3l-0.3,0.1l-0.1-0.1l0.2-0.3l-0.2-0.1h-0.2l-0.3,0.1l-0.3-0.1
                l0.6,0.4H466h-0.1l-0.2-0.1h-0.2h-0.1l-0.2-0.2l-1.1-0.4h-0.5l-0.4,0.2l-0.2-0.1l-0.2-0.2l-0.1-0.4l-0.7-0.3l0.1-0.2h0.3l0.4-0.1
                l0.1-0.2l-0.3-0.2h-0.2l-0.1-0.1l-0.1-0.2l0.1-0.1h0.1h0.3h0.5l-0.2-0.2h-0.2h-0.1h-0.4l-0.2,0.1h-0.4l-0.1-0.2v-0.2l0.1-0.4
                l0.5-0.3l1.3-0.4l0.6,0.1l0.4-0.1l0.5-0.2l0.2-0.2l0.7-0.1l0.6,0.2l0.6,0.8l0.3,0.3l0.7-0.5h1l0.2,0.3l0.1-0.2l0.2-0.3l0.2,0.1
                l0.1,0.2h1.1h0.2l-0.3-0.2l-0.2-0.5v-1.7l-0.3-0.5l-0.3-0.8l-0.2-0.5v-0.2l0.1-0.2h0.4l0.3,0.1l0.6-0.2l0.3,0.1v0.4l0.1,0.4l0.1,0.2
                l0.2,0.2h0.5l0.5,0.1h0.7l1,0.2l0.4-0.1l0.4-0.3l0.8-0.2l0.1-0.1h-0.5l-0.4-0.2l-0.1-0.2v-0.2l0.2-0.4l1.2-0.7l0.9-0.2l0.9-0.4
                l0.5-0.4l0.3-0.5l0.1-0.1l0.1-0.1l-0.1-0.2l0.1-2l0.1-0.4l0.2-0.3l0.3-0.2l0.4-0.2l1.5-0.3l0.2-0.1v0.2l0.1,0.3l0.1,0.1l-0.1,0.2
                v0.2l0.2,0.3l0.3,0.3L483.1,298L483.1,298L483.1,298z M471.8,318.3l-0.1,0.4l-0.2-0.3l-0.2-0.3l-0.1-0.3v-0.1l0.3,0.2L471.8,318.3
                L471.8,318.3z M501.7,330.4l-0.1,0.6l0.1,0.2l0.1,0.1v0.1l0.1,1.5v0.1l-0.3,0.6l-0.1,0.2v0.7l-0.1,0.2l-0.1,0.2l-0.2,0.6l-0.2,0.3
                l-0.5-0.3l-0.3-0.1l-0.1-0.2l-0.1-0.1l0.1-0.1l0.1-0.2v-0.1l-0.3-0.1l-0.1-0.1v-0.2l0.1-0.2l-0.1-0.2h-0.2h-0.1v-0.1l0.1-0.1
                l0.1-0.2v-0.2l-0.2-0.1l-0.2-0.2l-0.1-0.2l0.1-0.1l0.2-0.1l-0.1-0.2h-0.1h-0.1l0.1-0.1l0.1-0.2l0.2-0.5l0.3-0.2l0.5-0.1l0.1-0.1
                l0.1-0.2l0.1-0.1h0.2l0.2,0.1l0.1,0.1l0.1-0.1l0.1-0.2v-0.2v-0.5l0.1-0.3h0.1l0.1,0.2v0.1L501.7,330.4L501.7,330.4z"/>
            <linearGradient id="GA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="200.7579" x2="0.1864" y2="200.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GA" class="stcustom0 st74" d="M512.4,456.8v0.2l-0.2,0.5l-0.1,0.4v0.4l0.1,0.3l0.1,0.2l0.1,0.2l-0.1,0.2l-0.1,0.1l0.1,0.1h0.2
                l0.3-0.1l0.4-0.1l0.6-0.2l0.4-0.1l0.6,0.1l0.3,0.1l0.2,0.1l0.2,0.6l0.1,0.1l0.1,0.2l0.1,0.3v0.1v0.1l-0.1,0.2l-0.1,0.2v0.1l-0.1,0.1
                l-0.1,0.1H515l-0.1,0.1l-0.1,0.2l-0.2,0.2l-0.1,0.2l-0.1,0.3v0.3v0.5v0.3l0.1,0.1l0.5,0.1l0.1,0.1l0.1,0.2l0.2,0.2l0.4,0.1l0.2,0.1
                l0.1,0.2v0.1l-0.1,0.5l-0.1,0.5v0.4v0.4l0.1,0.5v0.3l-0.1,0.2v0.1l0.1,0.2l-0.1,0.5l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.2l-0.1,0.3
                l-0.1,0.1v0.1l0.1,0.1v0.1l-0.2,0.2l-0.1,0.1l-0.3,0.1l-0.3-0.1l-0.1-0.1l0.1-0.2v-0.1l-0.1-0.1l-0.2-0.3l-0.1-0.1l-0.1,0.1
                l-0.2,0.3l-0.4,0.3h-0.3l-0.6-0.1l-0.5-0.2l-0.2-0.4l-0.1-0.3l-0.2-0.4l-0.2-0.2l-0.2-0.1H511l-0.3,0.2l-0.1,0.1v0.2v0.2l0.1,0.1
                v0.1v0.2l-0.1,0.2v0.2l-1.1,0.2l-0.2-0.1l-0.1-0.1H509l-0.5,0.1l-0.2-0.1l-0.2-0.1l-0.1,0.1v0.1l0.1,0.6v0.2l-0.1,0.3l-0.1,0.2
                l0.3,0.1l0.1,0.1l0.1,0.1l0.1,0.1v0.1l-0.2,0.1l-0.1,0.2l0.1,0.1l0.2,0.1l0.3,0.2l0.1,0.1v0.1l-0.1,0.2v0.2l-0.1,0.1v0.1l0.1,0.1
                v0.1l-0.1,0.1h-0.2h-0.1h-0.1l-0.4-0.4h-0.1l-0.6,0.3L507,473l-0.1,0.2l-0.2,0.4l-0.3-0.3l-0.2-0.5l-0.3-0.3l-0.6-0.5l-0.2-0.3
                l-0.7-0.7l-1-0.7l-0.7-0.6l-0.1-0.1h0.1l0.7,0.3h0.1l0.1-0.1l-0.3-0.2l-0.3-0.1l-0.3-0.1h-0.3l-0.1-0.1l-0.1-0.2v-0.2l-0.1-0.2
                l-0.4-0.4l-0.1-0.1l-0.2-0.2h0.1l0.4,0.2v-0.1V468l-0.4-0.2h-0.2v-0.1v-0.1L501,467l-0.3-0.4v-0.2l0.8,0.9h0.1h0.1l0.3-0.1l-0.1-0.1
                l-0.1-0.1l-0.1,0.1h-0.2l-0.1-0.1v-0.1l0.2-0.4h-0.1l-0.1,0.1h-0.1h-0.2l-0.4-0.2l-0.3-0.6l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.4-0.9
                l0.2,0.1l0.2,0.3l0.4-0.1l0.1-0.1h0.1h0.1l0.2-0.1l0.5-0.6l0.1-0.8v-0.5l-0.1-0.5l0.1-0.2l0.1,0.1v0.2l0.1,0.1l0.2,0.1h0.3l0.5,0.2
                l0.2,0.1v-0.2l0.5-0.2l-0.2-0.1l-0.5,0.1l-0.6-0.3l-0.2-0.2l-0.2-0.3l-0.2-0.2v-0.2l0.5-0.2h0.1v0.2l0.1,0.1l0,0v-0.2v-0.4l-0.1-0.6
                V460h0.1l0.1-0.1h0.1h0.2l0.1,0.1v0.1h0.2l0.1,0.1h0.1l0.1-0.1h0.1h0.4h0.4h0.8h0.8h0.8h0.6v-0.3v-0.5v-0.6v-0.6v-0.5V457v-0.2v-0.1
                v-0.1h0.6h1.1h0.5h0.1h0.6h0.5h0.2h0.2h0.6h0.7h0.3l0.1,0.1L512.4,456.8z"/>
            <linearGradient id="GB_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="391.5079" x2="0.1864" y2="391.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GB" class="stcustom0 st75" d="M472.1,298.1l-0.2,0.2l-0.1,0.2l-0.1,0.1h-0.2h-0.2l-0.6-0.4h-0.1l0.1-0.2l0.4-0.1l0.2-0.2l0.5,0.2
                L472.1,298.1L472.1,298.1z M463.3,286.1l0.1,0.1h0.3l-0.1,0.2l-0.3,0.2l-0.2,0.2l-0.3,0.2l-0.1-0.2h-0.1l-0.2-0.4v-0.6l0.3-0.2h0.4
                L463.3,286.1L463.3,286.1z M457.2,276.9l0.4,0.1h0.3l0.3,0.4l0.1,0.6l0.3,0.6l0.4,0.5v0.3l-0.2,0.2l-0.3,0.2v0.2l0.2-0.1h0.2h0.4
                l0.2,0.2l0.1,0.3l0.1,0.3v0.3l-0.1-0.1l-0.1-0.3l-0.1-0.1l-0.2-0.1l0.1,0.3v0.5h0.1h0.2l-0.1,0.5l-0.3,0.1h-0.3l-0.1,0.2l-0.1,0.2
                l-0.2,0.3l-0.2,0.2H458l-0.3-0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.1h-0.3h-0.3v-0.1v-0.4l-0.1-0.1l-0.3-0.1L456,282l-0.2-0.3v-0.1v-0.2
                l-0.2-0.2l-0.2-0.2h-0.1l-0.2,0.3l-0.2,0.3l0.1,0.1l0.1,0.2L455,282l-0.3,0.3v0.1l-0.1,0.1l-0.2-0.1H454h-0.2l-0.2-0.2l-0.5-0.1
                l-0.1-0.3l-0.1-0.1l-0.6-0.6l-0.1-0.2l0.1-0.1l0.2-0.2l0.7-0.3l0.1-0.1v-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.1v-0.1l0.1-0.1h0.2h0.2
                l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.3l0.1-0.3v-0.1l0.1-0.5l0.1-0.1l0.4-0.3l0.1,0.2h0.2l0.2-0.2l0.2-0.5h0.2h0.2l0.4-0.1l0.6-0.2
                L457.2,276.9L457.2,276.9z M460.8,275.9h-0.4h-0.1l-0.2-0.1l-0.2-0.7l0.1-0.2l0.1-0.1l0.1-0.1h0.2l0.2,0.1l0.1,0.1l0.2,0.5v0.4
                L460.8,275.9L460.8,275.9z M457.9,273.5l0.1,0.6l0.1,0.4v0.1l-0.1,0.2l-0.5,0.2h-0.1v-0.1l0.1-0.3l-0.1-0.3v-0.2l0,0h-0.1l-0.3,0.3
                h-0.1v-0.1l0.1-0.3v-0.2v-0.1l0.1-0.1l0.1-0.1h0.1l0.1,0.1l0.3-0.2L457.9,273.5L457.9,273.5z M458.3,274.1L458.3,274.1h-0.2L458,274
                v-0.1v-0.2l0.1-0.2l0.4-0.2l-0.2-0.1v-0.1l0.1-0.2l0.4-0.3l0.1-0.1h0.1l-0.2,0.6L458.3,274.1L458.3,274.1z M458.9,271.4l-1.1,0.3
                h-0.4v-0.1l0.1-0.1l0.3-0.1l0.1-0.7l-0.5-0.3v-0.1v-0.1l0.1-0.1l0.3-0.2h0.1h0.1l0.2,0.2l0.2,0.4l0.3,0.1l0.2,0.2L458.9,271.4
                L458.9,271.4z M456.6,270.2h-0.2v-0.1l0.3-0.3l0.2-0.1h0.1l-0.1,0.2L456.6,270.2L456.6,270.2z M454.3,268.3l-0.2,0.1H454v-0.1
                l0.1-0.2l0.2-0.1l0.1,0.1L454.3,268.3L454.3,268.3L454.3,268.3z M457.5,268.3L457.5,268.3l-0.2,0.1l-0.1-0.1l-0.1-0.2l0.3-0.2
                l0.1,0.1v0.1v0.1L457.5,268.3L457.5,268.3z M454.7,267.5h-0.1h-0.2l-0.1-0.1l-0.1-0.3v-0.2v-0.4v-0.4h0.3l0.1,0.1L454.7,267.5
                L454.7,267.5L454.7,267.5z M457.9,265.5v0.2v0.3l0.1,0.3v0.2l0.1,0.1l0.1,0.1l0.5,0.1h0.5l0.1,0.1v0.1l-0.1,0.1l-0.3,0.3l-0.3,0.4
                l-0.1,0.1h-0.1h-0.1l-0.1-0.8l-0.4,0.1h-0.3l-0.2-0.1l-0.1-0.2l-0.2-0.5l-0.7-0.2l-0.2-0.3l-0.1-0.2v-0.1l0.1-0.2l0.2,0.1h0.1
                l0.1-0.1v-0.1l-0.1-0.2V265l0.7-0.2l0.1-0.3h0.1l0.2,0.1l0.2,0.3L457.9,265.5L457.9,265.5z M454.9,264.5l0.3,0.3l-0.3,0.5h-0.4
                l-0.5-0.4v-0.1v-0.1l0.1-0.1h0.1l0.1,0.1l0.2-0.1h0.1L454.9,264.5L454.9,264.5z M457.7,260.9l-0.4,0.9h-0.1l-0.1,0.2l-0.4,0.3h0.3
                l0.1,0.1v0.2l-0.1,0.1l-0.4,0.4l-0.3,0.2l-0.3,0.4h-0.2l-0.2,0.3l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.1l0.4-0.3v-0.2l0.2-0.2l0,0
                l-0.4-0.2l-0.2-0.1v-0.1l0.2-0.2h-0.1l-0.1-0.1h-0.1v-0.1v-0.2V262l0.1-0.1v-0.1l0,0l0.2,0.1l0.2,0.2l0.2-0.1h0.2l0,0l-0.2-0.5v-0.1
                l0.1-0.1l0.6-0.3l0.7-0.6l0.2-0.1l0.1,0.1l0.1,0.3L457.7,260.9L457.7,260.9z M466.4,260.1v0.4v0.1l-0.1,0.2l-0.2,0.3l-0.6,0.4l-1,1
                l-0.6,0.5l-0.1,0.2v0.3l0.4,0.1l0.1,0.1l-0.1,0.2l-0.5,0.6l-0.2,0.5h0.4l0.3-0.1l0.7-0.3l0.6-0.2h0.3l0.6,0.2h0.1l0.3-0.1h0.3
                l1.7,0.1l0.5-0.1l0.3,0.1l0.3,0.3l0.3,0.6v0.1l-0.2,0.3l-0.3,0.4l-0.2,0.5l-0.1,0.3v0.3l-0.1,0.3l-0.5,1.2l-0.5,0.7l-0.2,0.5
                l-0.3,0.4l-0.2,0.2l-0.3,0.2l-0.8,0.2l-0.2,0.1l-0.3,0.2l-0.3,0.1h0.3l0.3-0.1h0.6l0.7,0.4l-0.1,0.3l-0.3,0.3h-0.6L466,273l-0.3,0.2
                l-0.3,0.1h-0.3l-0.6-0.2l-0.3-0.2l0.2,0.3l0.3,0.1l1.6,0.3h0.1l0.5-0.3h0.7l1.3,0.6l0.4,0.5l0.5,0.7l0.3,0.3l0.2,0.2l0.1,0.4
                l0.2,1.2l0.3,1.1l0.4,1.2l0.2,0.3l0.2,0.2l1.1,0.5l0.2,0.2l0.4,0.5l0.4,0.6l0.4,0.4l0.4,0.3l-0.2,0.2l-0.1,0.3l0.1,0.4l0.2,0.4
                l0.3,0.6l0.3,0.6l-0.1-0.1l-0.1-0.1h-0.2h-0.2l-0.3-0.2l-0.3-0.2l-0.5,0.1h-0.3h-0.3l0.5,0.1h0.5l1.2,1.1l0.4,0.6l0.2,0.8l-0.2,0.4
                l-0.3,0.2L475,288l-0.2,0.3l0.7,0.4h0.1l0.1-0.1l0.1-0.2l0.2-0.4l0.1-0.1h0.4h0.3l0.3,0.1h0.3l0.6,0.2l0.3,0.1l0.8,0.6l0.2,0.4
                l0.1,0.5v0.5l-0.1,0.5l-0.1,0.4l-0.1,0.5l-0.1,0.2l-0.1,0.1l-0.4,0.4l-0.3,0.2l-0.1-0.1H478v0.1l0.1,0.2v0.3l-0.2,0.2l-0.2,0.1
                l-0.4-0.1l-0.6,0.4l0.4,0.2l0.1,0.2l-0.1,0.3l-0.3,0.2l-0.3,0.1h-0.3l-0.2,0.1l-0.2,0.2l0.3-0.1l0.2,0.1l0.1,0.3l0.1,0.1l0.6,0.1
                h0.4l0.7-0.1h0.3l0.1,0.1v0.2v0.6l-0.1,0.1l-0.9,0.5l-0.2,0.3l-0.1,0.2h-0.5l-0.2,0.2l-0.4,0.1l-0.3,0.2l-0.3,0.2l-0.3,0.1l-1.1-0.2
                h-0.7l-0.9,0.2h-0.2L472,298l-0.4-0.1l-0.4-0.1l-0.4-0.2l0.2,0.3l-0.5,0.3l-0.2,0.1h-0.2l-0.5,0.1h-0.5l0.1,0.2l0.1,0.2l-0.1,0.1
                h-0.1l-0.9-0.2h-0.1l-0.1,0.1l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.3-0.1h-0.3l-1.1,0.4l-0.2,0.4l-0.1,0.5l-0.2,0.5l-0.3,0.4h-0.3
                l-0.3-0.2l-0.6-0.3l-0.2-0.2h-0.1l-0.1,0.1l-0.2,0.1h-0.2l-0.4,0.1l-0.6,0.2l-0.3,0.2l-0.5,0.4l-0.1,0.1l-0.2,0.4l-0.3,0.1l-0.3-0.3
                l-0.3-0.1l-0.3,0.1l-0.2,0.1l-0.1-0.1v-0.2l0.2-0.3l0.6-0.2l0.6-0.6l0.3-0.3l0.1-0.2l0.1-0.1h0.2l0.1-0.2l0.8-0.9l0.1-0.2v-0.4
                l0.1-0.3l0.6-0.2l0.3-0.7l0.1-0.1l0.9-0.1h0.7l0.7,0.1h0.3h0.3l0.3-0.2l0.5-0.7l0.3-0.3l0.3-0.3l0.3-0.3l0.4-0.6l-0.3,0.2l-0.4,0.3
                l-0.2,0.2l-0.7,0.2l-0.3,0.2l-0.5,0.4h-0.1l-0.8-0.1l-0.6-0.6l-0.4-0.2h-0.1l-0.2,0.1l-0.3,0.1H463l0.2-0.3l0.2-0.1l-0.5-0.1
                l-0.1-0.1l-0.2-0.2h-0.4H462l-0.3,0.2l-0.5,0.3l-0.6-0.4l-0.1-0.2v-0.3l-0.1-0.2l-0.2-0.1l0.2-0.3l0.3-0.2l0.6-0.2l0.9-0.5l0.5-0.2
                l0.5-0.4l0.2-0.2l0.1-0.3l0.1-0.4l0.2-0.3l-0.2-0.1l-0.1-0.2v-0.2l0.1-0.2l-0.1-0.3l-0.1-0.3v-0.2v-0.2H463l-0.4,0.1l-0.3,0.2
                l-0.3,0.2h-0.3v-0.2l0.1-0.2l0.3-0.3l0.3-0.3l0.1-0.2l0.1-0.2l0.2-0.2l0.4-0.3l0.8-0.4h0.1h0.3l0.3-0.1l0.3-0.1h0.3l0.6,0.4
                l-0.2-0.6l0.3-0.2l0.4,0.6l0.2,0.1l0.3-0.1l-0.1-0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.3-0.6v-0.3l0.2-0.4l0.2-0.3l-0.2-0.1l-0.1-0.1v-0.3
                l0.1-0.3l0.4-0.3l0.1-0.4v-0.4l-0.1-0.2h-0.4l-0.2,0.1l-0.2,0.1h-0.2l-0.4-0.5l-0.2-0.4l-0.4-0.8l-0.1-0.5l0.4-1l0.6-0.6l0.6-0.2
                H466h-1l-0.3,0.1l-0.3,0.3l-0.2,0.1H464l-0.2,0.1l-0.2,0.2l-0.2,0.1h-0.3h-0.2l-0.1-0.1l-0.1-0.2h-0.1l-0.1,0.1l-0.3,0.2l-0.3,0.1
                l-0.4-0.1l-0.5-0.3l-0.1,0.1l-0.1,0.3l-0.1,0.4l-0.3-0.3l-0.3-0.5L460,279v-0.3l0.2-0.1l0.2,0.1l0.3-0.8l0.5-1l0.2-0.3l0.1-0.4v-0.3
                l-0.1-0.2l-0.5-0.5v-0.4v-0.5l0.1-0.3l0.1-0.1h0.6l-0.2-0.1l-0.5-0.4v-0.1l0.1-0.4H461l-0.1,0.2l-0.2,0.4l-0.1,0.1l-0.3,0.1
                l-0.1,0.2l-0.1,0.1h-0.2l-0.1,0.2l0,0V274v-0.4l0.1-0.3l0.1-0.3l0.5-0.6l-0.2,0.2l-0.6,0.5l-0.3,0.4l-0.1,0.1v0.1v0.1l0.1,0.6v0.3
                l-0.5,1.9l-0.1,0.2l-0.1,0.1h-0.1h-0.2l-0.1-0.1v-0.2v-0.2l0.2-0.9l0.1-0.2l0.1-0.2l0.3-0.4l0,0l-0.2,0.1h-0.1l-0.1-0.1v-1.2
                l0.2-0.4l0.1-0.6l0.1-0.5l0.2-0.4l0.1-0.5l0.2-0.2v-0.3l0.2-0.3l0.2-0.4h-0.1l-1,0.9l-0.2,0.2h-0.3l-0.3-0.1l-0.2-0.2l-0.1-0.4h-0.2
                l-0.2-0.1v-0.1l0.3-0.2l0.4-0.1l0.4-0.4l-0.4-0.3V269l0.3-0.2l0.4-0.7l0.1-0.7l-0.2-0.3l-0.1-0.2l-0.4-0.2l-0.1-0.3v-0.2l0.1-0.2
                l0.2-0.1l0.3-0.1l-0.3-0.1l-0.1-0.2l-0.1-0.2v-0.1l0.1-0.6l0.1-0.2l0.2-0.3h0.7l0.1-0.1h0.1l0.4,0.1l-0.1-0.1l-0.6-0.7l-0.1-0.1
                l0.2-0.4v-0.2v-0.2v-0.1l0.2-0.1h0.6l0.1-0.1l-0.1-0.2l-0.1-0.2v-0.2v-0.2v-0.4v-0.2l0.1-0.2l0.1-0.1h0.1l0.3,0.1l0.1,0.1l0.1,0.2
                h0.1l0.4-0.3h0.1l0.2,0.3l0.7-0.2l0.9-0.1l0.6-0.2l0.6-0.1l0.5-0.2l0.6,0.1v0.1v0.1L466.4,260.1L466.4,260.1z M466.9,258.9
                L466.9,258.9l-0.1-0.1l-0.2-0.4l0.3-0.1h0.1v0.2L466.9,258.9L466.9,258.9z M466.2,258.6l-0.2,0.1h-0.2l-0.2-0.3l-0.1-0.2V258
                l0.1-0.1l0.2,0.1l0.1,0.3v0.2v0.1H466.2L466.2,258.6L466.2,258.6z M466.5,257.3v0.1h0.2l0.3,0.1h0.2l0.2,0.1l-0.1,0.3l-0.1,0.1h-0.1
                l-0.4-0.3l-0.5,0.2h-0.1l-0.1-0.1v-0.1v-0.2v-0.1l-0.2,0.2h-0.1v-0.1v-0.2V257l0.1-0.4l0.2-0.1h0.3l0.3,0.2l0.1,0.1v0.1L466.5,257.3
                L466.5,257.3z M467.9,256.2h-0.3l0.2-0.3l0.2-0.1h0.4l-0.1,0.1L467.9,256.2L467.9,256.2z M467.4,256.5l-0.2,0.1l-0.1-0.1v-0.4
                l-0.3-0.2l-0.1-0.1l-0.1-0.2v-0.1l0.2-0.1l0.3,0.3l0.1,0.3l0.2,0.1l0,0V256.5L467.4,256.5z M471.4,249l0.1,0.4l0.1-0.1l0.2,0.4h0.1
                l0.2-0.2v0.4l-0.2,1l-0.1,0.2v0.3v0.1l-0.1,0.6l-0.1,0.2l-0.1,0.5l0,0l-0.2-0.2l0.2-0.7l0.1-0.4v-0.2l-0.1-0.2h-0.2l-0.2,0.1v-0.1
                v-0.2v-0.1h-0.3h-0.1l-0.1-0.1v-0.1l0.2-0.1h0.2l0.3-0.2l-0.2-0.8l-0.3-0.1l-0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.4l0.1-0.1h0.2L471.4,249
                L471.4,249z M472.2,249.1L472.2,249.1l-0.3-0.5l0.2-0.7h0.2v0.2v0.2h-0.1l0,0v0.3V249.1L472.2,249.1L472.2,249.1z M472.9,247.4
                L472.9,247.4l-0.1,0.5v0.2h-0.2V248v-0.3v-0.3v-0.1h0.1l0.1,0.1l0.1-0.1h0.1L472.9,247.4L472.9,247.4z"/>
            <linearGradient id="GE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="333.8578" x2="0.1864" y2="333.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GE" class="stcustom0 st76" d="M605.4,333.8l-0.1,0.1l-0.2,0.2l-0.1,0.1h-0.1l-0.1,0.1v0.1v0.2v0.1l0,0h0.1l0.1,0.4l0.2,0.2l0.3,0.2
                l0.3,0.2l0.2,0.2v0.2l-0.1,0.3l-0.3,0.3l-0.2,0.1h-0.1l-0.1-0.1l-0.3-0.2l-0.3-0.2l-0.2,0.1l-0.2,0.1l-0.3-0.1l-0.4-0.1l-0.2-0.1
                l-0.1-0.1l0.1-0.2l-0.8-0.3l-0.4-0.1l-0.2,0.1l-0.6,0.5h-0.1l-0.5,0.1l0,0l0.1,0.1l0,0H600l-0.3,0.1l-0.7-0.1h-0.2l-0.2,0.1
                l-0.5,0.1l-0.3,0.1l-0.4,0.1H597h-0.1v-0.1v-0.1l-0.1-0.1h-0.1h-0.2l-0.2-0.1l-0.1-0.1v-0.1l0.1-0.1l-0.1-0.1l-0.3-0.2l-0.4-0.4
                l-0.2-0.1l-0.1-0.3l-0.1-0.1h-0.2h-0.2l0,0h-0.1l-0.2,0.3l-0.1,0.1l-0.3-0.1l-0.2-0.1h-0.2h-0.4h-0.4l-0.3,0.2h-0.1l-0.2-0.1
                l-0.4-0.1l-0.2-0.1l0.5-0.7l0.2-0.4v-0.3v-0.3l-0.3-0.7l-0.2-0.9l-0.2-1l-0.2-0.3l-0.8-0.3l-0.2-0.4l-0.6-0.5l-0.9-0.2l-0.2-0.1
                l-0.8-0.6l-0.6-0.4l0.1-0.2l0.2-0.3l0.2-0.1l0.5,0.1l0.5,0.1l0.4-0.1l0.4,0.2l0.4,0.2l0.4,0.2l0.8,0.2l0.3,0.2l0.3,0.2l1.3,0.1h0.1
                h0.1l0.4-0.1h0.4l0.4,0.3h0.3h0.3l0.4,0.1l0.3,0.2v0.2l0.2,0.2l0.7,0.4l0.6,0.2l0.2,0.1l0.4,0.2v0.1v0.1l-0.1,0.2v0.2l0.1,0.1
                l0.2,0.1h0.4l0.1-0.1l0.3-0.1l0.3-0.1l0.4-0.2l0.5-0.2h0.2l0.2,0.1l0.1,0.1l0.2,0.4l0.2-0.5h0.1l0.2,0.1l0.4,0.1l0.2,0.1l0.1,0.1
                l0.4,0.5h0.6l0.3,0.1l0.1,0.1l0.1,0.1l-0.1,0.4l-0.2,0.5v0.1l0.2,0.2l0.3,0.2l0.2,0.1l0.1,0.1l0.3,0.1l0.3,0.1h0.1l0.2,0.1
                L605.4,333.8L605.4,333.8z"/>
            <linearGradient id="GD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="237.0579" x2="0.1864" y2="237.0579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GD" class="stcustom0 st77" d="M301.9,429.1h-0.2l0.1-0.1v-0.2l0.1-0.2l0.2-0.1h0.1l-0.1,0.5L301.9,429.1z"/>
            <linearGradient id="GF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="214.0079" x2="0.1864" y2="214.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GF" class="stcustom0 st78" d="M330.1,451.5L330.1,451.5l-0.3,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1v0.1l-0.1,0.1v0.2l-0.3,0.5
                l-0.1,0.2l-0.2,0.3l-0.1,0.1l-0.4,0.1l-0.1,0.2v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.3,0.6v0.2l-0.1,0.1l-0.2,0.3l-0.1,0.2l-0.2,0.1
                l-0.2,0.1l-0.1,0.2l-0.2,0.1h-0.1l-0.2-0.1h-0.3h-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.2,0.1l-0.2,0.1h-0.2l-0.3-0.1h-0.1v-0.1
                v-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.4,0.2l-0.2,0.3h-0.1h-0.2h-0.2l-0.4-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1h-0.1l0,0h0.1h0.1
                l0.1-0.2l0.2-0.1l0.4-0.7l0.2-0.3V455v-0.3l-0.1-0.4v-0.1l0.4-0.5l0.2-0.3v-0.2V453v-0.1h-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.2
                l-0.3-0.4v-0.2l-0.1-0.1l-0.1-0.1v-0.1l-0.1-0.3l-0.1-0.3v-0.2l0.1-0.3v-0.3l-0.1-0.2v-0.2v-0.2l0.1-0.1v-0.2l0.3-0.5l0.3-0.3
                l0.2-0.2l0.2-0.1l0.3-0.7l0.2-0.3h0.2l1.1,0.6l0.5,0.1l1,0.3l0.4,0.4l0.9,0.7l0.5,0.2v0.2l-0.1,0.3l0.3-0.3l0.5,0.4l0.1,0.2l0.1,0.4
                v0.2l-0.1,0.1v0.1l0.1-0.1l0.1-0.1v-0.2l0.1-0.3h0.1l0.1,0.2l0.2,0.8l0.1,0.2v0.3v0.1L330.1,451.5z"/>
            <linearGradient id="GG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="362.4079" x2="0.1864" y2="362.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GG" class="stcustom0 st79" d="M468,303.3l-0.1,0.3l-0.3-0.1v-0.1L468,303.3L468,303.3L468,303.3z"/>
            <linearGradient id="GH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="225.3579" x2="0.1864" y2="225.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GH" class="stcustom0 st80" d="M474.9,431.6l0.2,0.2v0.1l-0.1,0.4l-0.1,0.3l-0.1,0.2v0.1l0.1,0.1l0.3,0.2l0.1,0.1l0.2,0.2l0.2,0.2
                l0.3,0.2h0.1v0.1v0.1v0.9v0.2v0.1v0.3l0,0H476h-0.1v0.1v0.1h0.2l0,0H476l-0.1,0.1v0.1l-0.1,0.1v0.1h0.1h0.1l0.2-0.2h0.1h0.1l0.2,0.2
                v0.1l-0.1,0.4l-0.1,0.3v0.4l0.1,0.2v0.1l-0.1,0.1l-0.2,0.2v0.1l0.1,0.2l0.2,0.2l0.4,0.3l0.2,0.4v0.1l-0.1,0.1l-0.1,0.1v0.2l0.1,1.2
                l-0.3,0.5v0.1v0.2l0.1,0.1h0.1l0.1,0.1v0.4l-0.1,0.4v0.2v0.1l-0.1,0.1v0.1v0.1v0.1l0.1,0.1l0.1,0.2l0.2,0.4h0.1v0.1v0.1l0.1,0.2
                l0.2,0.2l0.3,0.2h0.2v0.1l0.1,0.2l0.1,0.1l0.2,0.1h0.1v0.2l-0.4,0.3l-0.2,0.2l-0.1,0.2l-0.2,0.3l-0.6,0.1h-0.2h-1.2l-1.1,0.5
                l-0.6,0.2l-0.4,0.3l-0.5,0.2l-0.4,0.3l-0.7,0.1l-1.2,0.4l-0.4,0.2l-0.4,0.3l-0.6,0.3h-0.2l-0.5-0.3l-0.4-0.2l-0.9-0.2l-0.7-0.1
                l-0.3-0.1h-0.1l0.1-0.1h0.2h0.2l0.2-0.1h0.2l0.1-0.1v-0.2v-0.2l0.1-0.1v-0.2l-0.1-0.5l-0.1-0.1l-0.4-0.1v-0.1l-0.1-0.1l-0.1-0.2
                l-0.1-0.4l-0.1-0.4l-0.3-0.7l-0.1-0.3v-0.3v-0.3l0.1-0.1v-0.2v-0.2l0.2-0.4l0.4-0.5l0.1-0.2l0.1-0.1v-0.2l0.1-0.5l0.2-0.6l0.1-0.2
                l0.1-0.1l0.1-0.2v-0.1l0.3-0.3l0.2-0.1V440l-0.1-0.1v-0.1h0.1h0.1l0.1-0.1l-0.1-0.8l-0.1-0.8V438l-0.1-0.1l-0.1-0.3l-0.1-0.2
                l-0.2-0.1v-0.2l0.2-0.3v-0.2l-0.1-0.1v-0.1l0.1-0.2v-0.1V436l-0.2-0.4v-0.2l0.1-0.1V435l-0.1-0.5v-0.3l0.1-0.1V434l-0.4-0.1v-0.1
                l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2v-0.4l0.2-0.7v-0.1h0.2l0,0h0.7h0.8h0.9h0.8l0,0h0.1l0.9,0.1h0.5h0.2h0.2h0.4h0.2l0.1,0.2
                h0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1h0.1l0.1-0.1v-0.1L474.9,431.6z"/>
            <linearGradient id="GI_1_" gradientUnits="userSpaceOnUse" x1="460.1043" y1="311.2579" x2="460.1043" y2="311.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GI" class="stcustom0 st81" d="M460.1,354.5L460.1,354.5L460.1,354.5v0.2l0,0l0,0V354.5L460.1,354.5L460.1,354.5L460.1,354.5z"/>
            <linearGradient id="GL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="538.908" x2="0.1864" y2="538.908" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GL" class="stcustom0 st82" d="M391,1l2.7,1.5l1.4,1.7l0.3-0.1l1.9,0.7l1.8,0.8l3.1,2.1l0.4,0.7L402.3,9l-0.8,0.4l-3.9,0.7l-7.1,0.8
                l-4,1.6l-1.3,0.1l-0.1,2.4l0.5,0.1l0.9-0.3l3.2-2.4l1.2-0.4l2.2,0.2l2.9,0.9l1.2-0.3l2.1,0.2l2.4-0.7l2.9-1.5l0.8,3.3l1.1,3.2
                l0.8-0.4l0.7,0.2l0.2,1l0.4,0.4l0.8-0.2l2.5,0.9l1.7,1.6l0.6,0.7l0.3,1.1l0.2,0.8l-0.3,1l-1.1,1.8l-1.3,1.7l-1.8,1.3l-2.1,0.8
                l-16,2.6l-0.5,0.6l-0.3,1.6l0.2,2l0.8,0.3l1.8-1.1l3-1.1l2.2,0.1l5.3,0.9l1.6,2.4l0.8,3.6l1.8-0.8l0.4-0.6l0.3-1.1l0.2-1.2l0.2-1.2
                l0.2-0.8l0.2-0.5l0.5-0.4l1.1-0.5l2.8-0.4l0.7,0.1l0.5,1.7l0.1,1v1.3v1.6l-0.1,1.9l-0.2,1.8l-0.8,3.1l-0.6,1.7l-0.7,1.2l-1.3,2.7
                l-0.4,0.7l-1.4,3.1l-0.3,1.4v1l0.2,0.2l0.4-1l0.2-0.7l0.3-0.7l2.1-1.9l0.4-0.5l1.4-2.3l0.9-0.9l0.7-0.9l0.4-0.7l2.1-4.8l1.1-1.5h1.1
                l0.2,2.4l1.4,0.4l0.6-0.1l1,0.6l0.4,0.5l0.7,0.2l0.7,0.6l0.6-0.6l0.2-0.4l0.6-1.8l0.8-1.6l0.8-2l0.3-0.5l0.4-0.4l0.4-0.2l1.2-0.9
                l0.3-0.1l0.6,0.3l2.8,0.2l1.5,0.5l2.1,1.3l1.5,0.7l0.7,0.6l1,1.4l0.8,1.4l0.4,0.4v0.5l-0.3,0.6l-2,2.1l-0.6,1.4l-1.9,2.6l-0.9,0.9
                l-1,0.4l-1.6,0.5l-0.7,0.4l-0.1,0.4l0.5,1.1l0.2,0.8v0.7l-0.5,1l-0.2,0.2l-1.9,0.7l-1,1.2l-1.3,0.1l-0.9-0.1l-1.2,1.3l0.5,1.1
                l0.4,0.5l1.4,1v0.5l-0.7,1.1l-0.9,1.3l-1.1,0.9l-0.4,0.1l-0.5-0.2h-0.5l-1,0.4l-1,0.1l-1.7-0.6l-0.9-0.7l-0.5-0.3l-0.6,0.1l-0.2,0.3
                l-1,1.6l-0.5,1.1l-0.3,1.1l-0.1,1.2v1.3l0.1,0.9l0.2,0.5l0.2,0.3l0.4,0.1l0.9-0.1l0.4,0.1l0.1,0.3l0.2,0.8v0.8l-0.2,1.1l-0.1,1.2
                l-0.1,1.4v0.8L421,83l0.2-0.1l0.2,0.2l0.2,0.5l0.2,0.6l0.1,0.6l-0.1,0.5l-0.2,0.4l-0.5,0.4l-1.3,0.9l-0.1,0.3l-0.1,0.5l-0.1,0.8
                l-0.2,0.7l-0.2,0.6l-0.2,0.4l-0.4,0.2H418l-0.6,0.4l-1.5,2.1v0.2l0.5,0.7v0.6l-0.7,2.5l-0.2,1.2l-0.1,1.6l-0.2,1.4l-0.3,1.1
                l-0.3,1.3l-0.3,1.4v1.1l0.4,0.8l0.6-0.6l0.7-2l0.8-0.9l0.8,0.3l0.7,0.4l0.9,0.8l0.8,0.5l0.7,0.6l0.3,0.5l0.3,0.7v0.5l-0.5,0.3
                l-0.2-0.1l-1.2-1.2l-0.6-0.3l-0.8,0.4l-0.7,0.6l0.6,2.2l0.7,1l1.2,0.5l0.6,0.5l0.5,0.6l0.4,0.3l0.5-0.1l0.6-0.6h0.9l0.4,0.3l0.3,0.6
                l0.1,1v1.5l-0.1,1.1l-0.2,0.8l-0.3,1l-0.3,0.2l-0.4-0.1l-0.4-0.2l-0.3-0.4l-0.5-0.3l-1-0.3l-1-0.7l-0.6-0.2l-1.2,0.1l-1.3,0.4v0.5
                l-1.8,1.9h-0.4l-0.5-0.6l-0.7-0.6h-0.4l-0.6,0.8l-0.2,0.3v0.3l0.5,0.7l0.2,0.2l0.2,0.1l0.8,0.2l0.4,0.2l0.3,2.1l0.5,1.3l0.2,0.3
                l0.2,0.1h0.6l0.8-0.4l0.3,0.3l0.6,0.4l0.4,0.1h0.4l0.5,0.2l0.7,1.2l-0.3,1.4l0.4,1.1l0.7,1.2l0.2,0.4l0.1,0.8v0.5l0.1,0.5l0.1,0.4
                l0.1,0.8l0.1,1.2v1l-0.1,0.8l-0.3,0.5l-0.4,0.3l-0.3-0.1l-0.3-0.5l-0.4-0.6l-0.5-0.6l-0.8-0.1l-1.2,1.7l-0.5,0.1l-0.4,0.2l-0.5,0.7
                l-0.7,0.4l-0.6-0.2l-1-0.9l0.4,0.6l0.5,0.7l0.3,0.4l0.2,0.1l0.3-0.1l0.4-0.3l0.9-0.8l0.2-0.1l0.2,0.1l0.2,0.2l0.2,0.7l0.2,1.1v0.9
                l-0.3,0.8l-0.2,0.5l-0.2,0.2v0.2l0.5,0.2l0.7-1l0.2-1.2l0.3-1.4l0.6-0.5l0.6,0.5l0.5,1.3l0.7,2.4l0.3,0.2l0.4,0.6l0.2,0.7v0.8
                l-0.1,0.6l-0.1,0.4l-0.2,0.2l-0.3,0.1l-0.5,0.1l-1.1-0.3h-0.6l0.1,0.8l-1.2,0.7l-1.3,0.3l-1.3-0.5l-1.1-0.8l0.3-1.2l0.2-1.3
                l-0.5-0.9h-0.1l0.2,1.3l-0.1,0.5l-0.6,0.6l-0.4,0.3v0.2l0.2,0.1l0.1,0.3l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.1,0.3v0.3l0.2,0.3l0.4,0.4
                l0.4,0.2l1.9,0.1l0.8,0.2l1.8,0.9l0.1,0.3l-0.3,1.7l-0.2,1.6l-0.4,0.3l-1.9,0.1l-0.6,0.2l-0.9,0.7l-0.9,0.9h-0.5l-1.8-0.8l-0.7-0.5
                l-1.5-1.4l-1.1-2.2l-0.5,0.9l-0.3,0.4l-0.3,0.2H406l-0.3-0.2l-0.3-0.3l-0.6-0.8l-0.7-0.8l-0.5-0.4v0.1l0.3,0.5l0.4,0.5l1,1.6
                l0.4,0.4v0.3L405,154l-0.8,0.5l-0.4,0.4l-0.6,1L403,156l-0.9,0.2l-0.3-0.1l-0.7-0.5l-1-0.3l-0.6-0.3l-0.8-0.6l0.3,0.6l1.6,0.9
                l0.2,0.3l-0.3,0.6l-0.2,0.2h-0.4l-0.6-0.1h-0.6l-0.6,0.2l-0.2,0.2l0.1,0.3l0.1,0.2l0.2,0.2h0.2l0.4-0.6h0.3l0.9,0.2l0.9-0.5l0.6-0.2
                l0.5-0.1l1.8-0.7l0.4-0.8l0.6-0.3l1.3-0.3l1.3,0.1l0.7,0.1l0.5,0.8l0.7,0.6l0.6,0.6l0.7,0.2l0.4,0.8l1.1,0.9l0.7,0.2l0.4,0.4v1.9
                v0.8l-0.2,2.2l-0.6,0.5l0.1,1.1l-0.2,0.9l-0.6-0.3l-0.6-0.6l-1.4-0.9l-1.3-0.6l-0.5-0.6l-0.6-0.4l-0.8-1.8l-0.5-2.2l-0.2-1.1
                l-0.4-0.1l-0.6,0.3l-0.5,0.3l-0.2,0.1l-1.7,0.7l-0.6,0.5h-0.4l-1.3,0.8l0.5,0.4h0.2l0.5-0.2l0.3-0.3l1.2-0.7l0.9-0.1l0.3-0.3
                l0.7-0.4l0.5-0.1l0.1,0.1l0.1,0.2l0.3,2.7l-0.1,0.7l-0.4,0.3l-0.8,0.5l-0.2,0.2l0.2,0.4l0.8-0.4l0.5-0.4l0.3,0.2l0.4,0.7l0.5,0.3
                l1.2,1l0.6,0.6l0.8,0.5l0.9,0.7l0.2,0.3l0.9,0.4l0.2,0.1l0.4,1.3l0.3,0.1l0.9,0.1l-0.1,0.5l-0.8,1.1l-0.4,0.4L412,172v0.4v0.6
                l0.2,1.2l0.2-1.1l0.1-0.5l0.2-0.2h0.2l0.6-0.5l0.6,0.3l0.2,1.2l0.1,1.1l-0.1,1l0.1,1.5v0.5l0.1,0.4l0.1,1.8l0.1,0.5l-0.3,0.5
                l-0.9,0.2h-0.3l-0.9,0.1v-0.4v-0.5v-0.3l-0.1-0.3v-1.8l-0.2,0.4v0.4l-0.1,0.4l-0.2,1.9l-0.2,0.5l-0.7-0.1l-0.7,0.1l-0.4-0.1
                l-1.3-0.9l-0.5-0.8l-0.4-1.2l-0.3-1.1l-0.1-1.1l-0.3-0.9l-0.5-0.7l-0.6-0.5l-0.7-0.4l-0.8-0.7l-0.5-0.7l-0.6-0.5l-0.6-0.4l-0.9-0.2
                l-1.3,0.1l-0.9-0.4h-0.2L399,171l0.2,0.6l1,0.3l0.8,0.1h1.1l0.6,0.2l0.2,0.4l0.2,0.6l0.1,0.9l-0.2,0.7l-0.8,0.8l-0.4,0.4l-1.2,0.7
                l-0.4,0.2h-1l-0.8-0.1l-1-0.4l-0.6-0.1h-1.2l-0.3,0.1l0.3,0.4l0.5,0.2l0.3,0.3v0.5l-0.1,0.7l-0.1,0.5l-0.2,0.4l-0.8,0.7l-0.3,0.2
                l-1.4,0.7l-0.1,0.1h0.3l0.9-0.3h0.3l1.5,0.6h1.2l2.4-0.6h0.2l0.2,0.1l0.2,0.2l0.2,0.3l-0.2,0.3l-0.5,0.3l-0.8,0.3l-0.4,0.2l-0.4,0.3
                l-0.7,0.8l-0.2,0.8l0.7,0.3l0.3-0.4l0.4-0.8l0.3-0.5l0.8-0.3l0.9,0.2l0.7-0.2l1.5-0.8l0.3-0.1l2.2,0.5l2,0.9l1,0.3l1.4,0.2l2.5-0.1
                l0.2,0.1l-0.1,0.3l-0.1,0.3l-0.4,0.4l-0.5,0.3l-0.3,0.1l-0.3,0.2l-0.6,0.2l-0.2,0.1l0.2,0.6l-0.1,0.1h-0.5l-0.9,0.4h-0.7l-0.2,0.1
                l0.1,0.2l0.1,0.3l0.2,0.4l-0.1,0.3l-0.3,0.2l-0.2,0.1L407,188h-0.1l0.1,0.2l0.1,0.3v0.4l-0.2,0.3l-0.4,0.3l-0.8,0.7l-0.4,0.2
                l-0.7,0.2l-0.2,0.1l0.3,0.5v0.2l-0.5,0.6l-0.8,0.4l-0.1,0.2l-0.1,0.5l-0.1,0.2l-0.2,0.3l-0.7,0.6l-0.5,0.3l-0.3,0.2l-0.3,0.4
                l-0.4,0.2h-0.5l-0.5,0.1l-0.7,0.4l-0.5,0.1l-1.6,0.7l-0.8,0.1l-0.7,0.2l-1.4,0.7l-0.7,0.2l-0.5,0.3l-0.5,0.1l-0.8-0.2h-0.4l-0.3,0.1
                l-0.2,0.2l-0.4,0.6h-0.3l-1.1-0.4v0.2l0.3,0.5v0.3l-0.7,0.3l-0.4,0.1l-0.5-0.1l-0.7-0.4l-0.9-0.8l-1.1-1.2l-0.5-0.4v0.4l0.1,0.4
                l0.2,0.4v0.3l-0.1,0.2l-0.2,0.1h-0.2v0.1l0.3,0.6l0.3,0.6v0.5l-0.3,0.5l-0.3,0.3l-0.2,0.1l-1.3,1.3l-0.4,0.2l-0.2,0.2l-0.1,0.2
                l-0.4,1l-0.2,0.3l-0.3,0.4l-0.1,0.1v0.2l0.1,0.3l-0.1,0.4l-0.2,0.6l-0.8,1.7l-0.6,1.5l-0.3,0.5l-0.2,0.2l-0.1-0.1h-0.3l-0.1,0.3
                l-0.1,0.5l-0.1,0.4l-0.2,0.3l-1.2,1.1l-0.3,0.2l-0.3-0.1h-0.3l-0.7-0.6l-0.1-0.2l-0.5-0.5v0.2l0.1,0.1l0.1,0.2l0.2,0.2l0.4,1.3
                l-0.3,0.3l-0.2,0.3l-0.6,0.5l-0.7,0.8l-0.3,0.2l-0.1-0.7v-0.2l-0.4-0.3v0.2v0.2l-0.3,0.9l-0.1,0.2h-0.1l-0.3,0.2l-0.3-0.1l-0.4-0.5
                l-0.1-0.2l-0.5,0.6h-0.2v0.5l-0.2,0.4l-0.3,0.2h-0.4l-0.3,0.3l-0.6-0.3l-0.1-0.5l0.4-0.7l0.1-0.3l-0.1-0.4l0.1-0.5l0.9-1.5l0.6-0.8
                v-0.1l-0.8-0.2l-0.7-0.3l-0.7-0.1l-0.3,0.1l0.5,0.4l0.7,0.4l-0.3,0.4l-0.3,0.4l-0.3,1.2l-0.2,0.5l-0.7-0.5l-0.3-0.2l0.2,0.6l0.6,0.6
                v0.2v0.7l-1.2,0.6l-1.3,0.1l-0.9,0.2l-1.5,0.2h-0.6l-0.1,0.2l1.5,1l0.2,0.2l-0.2,0.4l-0.3,0.2l-0.5,0.8l-0.3,0.3l-0.6,0.4l-1.2-0.4
                l-0.6,0.2l-0.6-0.1v0.4l0.2,0.3l0.2,0.8l0.4-0.1l0.5-0.2l0.4,0.6l0.2,1l0.4,0.5l0.2,0.4l0.1,0.4l-0.3,0.4l-0.6,0.5l-0.6,0.1v0.4
                l-0.3,0.3l-0.6-0.1l-0.3-0.2l-0.3-0.1l-1.1-0.1l1.1,0.8l0.4,0.4l0.2-0.2l0.4-0.1l0.6,0.2l-0.1,1.3l0.3,1.1v0.2l-0.6,0.6v0.6
                l-0.4,0.2h-0.4v0.6l-0.3,0.4v0.2l0.1,0.2l-0.2,0.4l-0.2,0.5l-0.3,0.4h-0.2h-0.5h-0.6l-0.5-0.6l-0.2-0.2l-0.2-0.1l0.1,0.4l0.1,0.2
                l0.5,0.4l0.8,0.5v0.3l-0.2,0.2l-0.5,0.9h-0.2l-0.2,0.2l-0.7-0.1l-0.3-0.1l-0.9,0.1l-0.3-0.1h-0.3l0.2,0.3l0.5,0.2l0.6,0.2l0.9,0.2
                v0.3l-0.2,0.3l0.1,0.4l-0.1,0.3v0.4l-0.2,0.8l0.2,0.6l0.3,0.3v0.4l0.1,0.6l-0.4,0.5h-0.3l-0.5,0.1l-0.2,0.2l0.7,0.2l-0.1,0.4
                l-0.2,0.5l-0.2,1l-0.4,1.7l-0.2,1.7l-0.9,1.4h-0.3l-0.1,0.1l-0.4-0.1l-0.7-0.3l-0.5-0.1h-0.4v0.2l0.3,0.3l0.5,0.1l0.4,0.2l0.7,0.2
                l0.2,0.3l0.2,0.3v0.2v0.2l0.1,1.1l-0.3,0.4l-0.2,0.4h-0.8l-0.1-0.1l-0.8-0.4v0.2l0.6,0.5l0.2,0.3l-0.1,0.1h-0.2l-0.3,0.2l-0.6-0.1
                v-0.3l0.1-0.3h-0.3l-0.3,0.1h-0.2h-0.1l-0.1-0.1l-0.1-0.5l0.1-0.3l0.5-0.7l0.2-0.4l-0.1-0.2l-0.3,0.4l-0.4,0.6l-0.2,0.4l-0.2,0.1
                l-0.6-0.2l-1.6-0.9v-0.5v-0.4l0.5-0.1l0.3-0.2l0.3-0.2l0.3-0.4l0.3-0.7v-0.1l-0.9,0.9l-0.6,0.3l-0.3,0.1l-0.1-0.1l-0.5-0.3l-0.3-0.1
                l-0.7-0.2l-0.1-0.1l-0.2-0.1l-0.3-0.9l0.3-1.1l0.2-0.3l0.2-0.4l0.1-0.5l-0.1-0.2l-0.2,0.1l-0.1,0.2v0.3l-0.1,0.2l-0.8,0.4l-0.8,0.3
                l-0.4,0.3l-0.2,0.3l-0.2,0.3h-0.3l-0.4,0.1l-0.3,0.2l-0.4-0.1l-0.3-0.2h-0.3l-0.4,0.1H341v0.2l0.2,0.4h-0.3h-0.5l-0.3-0.1l-0.2-0.2
                l-0.2-0.2l0.1-0.3l0.8-0.5l0.4-0.3l-0.2-0.1L340,247l-0.1-0.1h-0.5v-0.8l-0.1-0.2v-0.1l-0.2-0.2l-0.2-0.1l-0.1-0.1l-0.9-0.2
                l-0.1-0.4l-0.1-0.4l-0.2-0.6l-0.4-0.1l-0.2-0.2l0.2-0.2l0.1-0.3l-0.2-0.1l-0.1-0.2v-0.1l-0.2-0.4v-0.3l0.2-0.3l0.5-0.3l0.2-0.1
                l0.1-0.1l0.6-0.2l-0.5-0.2h-0.3l-0.2,0.1l-0.2,0.3l-0.2,0.2l-0.8,0.1l-0.1-0.1l-0.1-0.5v-0.3l0.3-0.5l-0.4-0.2l-0.3-0.1l-0.4-0.2
                l-0.4-0.2l-0.3-0.3l-0.3-0.3H334l0.1-0.3l0.1-0.3v-0.6l-0.1-0.3l0.3-0.5l0.4-0.6l0.8-0.9l-0.8,0.4l-0.7,0.9H334l-0.1-0.2l-0.3-0.6
                l-0.2-0.2l-0.1-0.2l-0.4-0.3l-0.2-0.2l-0.2-0.5l-0.3-0.6l-0.5-1.1l-0.8-1.3l-0.2-0.7l0.2-0.9l-0.3-0.6l0.8-0.3l1.1-0.3l0.6-0.3
                l0.3-0.1l0.7-0.1l0.2-0.3l-0.4,0.1h-0.3v-0.1l0.1-0.2l0.1-0.3l-0.1,0.1l-0.7,0.5l-1,0.4l-0.8,0.2h-0.1l-0.3,0.1h-0.2h-0.1l-0.3-0.4
                l-0.1-0.3l0.5-0.7l0.4-1l0.5-0.6l0.3-0.1h0.6l0.2,0.1l-0.1-0.4v-0.2l0.5-0.2l0.5-0.1l0.4,0.1l0.2,0.4l0.3,0.8l0.4,0.3v-0.4l-0.2-0.5
                l-0.1-0.8l-0.3-0.3L334,224l-0.6,0.1l-0.4-0.6l-0.1-0.2V223l-0.4-0.8l-0.1-0.4l-0.2-0.6h-0.1l0.1,0.7l0.2,0.5l0.2,1.1l0.1,0.4
                l-0.2,0.3l-0.3,0.3l-0.3,0.2l-0.6,0.2l0.1-0.5l0.1-0.5l-0.3,0.2l-0.3,0.4l-0.1,0.5l-0.2,0.5l-0.6,1.1l-0.2,0.6l-0.2,0.3l-0.2,0.1
                l-0.2-0.2l-0.2-0.6l-0.1-0.5v-1.2v-0.6l-0.1-0.7l-0.3-1.8l-0.1-0.6l-0.5-0.3V221l-0.1-0.4l-0.1-0.4l0.1-0.1l0.1-0.1l0.8-0.5l0.6-0.6
                l0.7-0.9l0.3-0.3l1-0.2h0.5v-0.2l-0.2-0.1h-0.7l-0.9,0.4l-0.2,0.1l-0.4,0.6l-0.3,0.3l-0.9,0.7h-0.6l-0.6-0.9l-0.7,0.2l-0.4-0.1
                l-0.1-0.1l-0.1-1.2l0.4-1.4h-0.4l-0.1-0.1l-0.2-0.3l-0.1-0.1l0.1-0.2l1-0.7l1.4-1.3l0.6-0.5l0.4-0.2l0.3-0.3l0.3-0.6l0.1-0.2
                l0.2-0.2l0.4-0.2l0.4-0.3l0.7-0.8l0.1-0.3l-0.2-0.1l-0.3,0.3l-0.7,0.7l-0.5,0.4l-1.7,1.8l-0.7,0.6l-0.4,0.4l-0.3,0.4l-0.3,0.3
                l-0.3,0.2l-0.7,0.1l-0.4,0.1l-0.2-0.1l-0.1-0.8l0.1-0.5v-0.5l0.2-0.7l0.3-0.5l0.1-0.3v-0.2l0.5-0.5l0.3-0.2l0.2-0.5l1.2-0.2h0.3
                l0.2-0.1l0.1-0.2l-0.1-0.1l-0.4-0.1h-1l-0.9-0.1l-0.4-0.1l-0.2,0.1l-0.3-0.2l-0.4-0.3l-0.6-1.1l0.2-1.4v-0.7l0.7-0.6l0.4-0.2
                l0.5-0.4l0.7-0.8l0.9-0.5l0.4-0.1l0.4,0.1l1.3,0.7l0.7,0.1l0.6-0.2l0.8,0.2l1.3,0.9l0.3-0.1l-0.1-0.2l-1.5-1V203l0.4-0.1l0.4-0.3
                l-0.2-0.2l-1,0.1l-0.3,0.2l-1,0.1l-0.5-0.2l-0.5-0.1l-0.7-0.4l-0.6,0.1l-0.4,0.2l-0.6,0.2l-0.2,0.1l-1.2,1.4l-0.5,0.3l-0.4-0.1
                l0.3-0.9l0.1-0.3v-0.4l0.1-0.5l0.6-1l0.4-1.1l0.2-0.7l0.3-0.1l0.4,0.1l1.3,0.4l1.1,0.5l0.8,0.1h0.5l0.2-0.2l0.2-0.3l0.1-0.2v-0.4
                l0.1-0.1l0.2-0.2l0.4-0.6l0.1-0.5l-0.1-0.3h-0.2l-0.5,0.2l-0.1,0.1v0.1l-0.4,0.7l-0.5,0.2l-1.1,0.2H328l-0.9-0.3l-0.1-0.1l-0.1-0.2
                l-1.1,0.1l-0.3,0.1h-0.3l0.1-0.4l0.3-0.5l0.5-1.5l0.4-0.4l0.8-0.4l0.8,0.1l1.5,1.2l0.4,0.1l0.4-0.1l1-0.4l0.2-0.2l0.3-0.5l0.4-0.8
                v-0.2l-0.6,0.5l-0.3,0.1h-0.3l0.3-1.5l0.1-1.2l0.1-0.3l0.8,0.1l1.1-0.2l0.3-0.3v-0.1l-0.5-0.2l-0.2-0.3l-0.4,0.1l-0.5,0.2h-0.6
                l0.1-0.5l0.5-1.1v-0.5l0.2-1V188l0.2-0.5l0.7-0.4l0.3-0.2v-0.2l-0.4-0.9l0.1-0.2l0.3-0.2l0.1-0.2l-0.1-0.1l-0.3-0.1l-0.5,0.2
                l-0.5,0.1l-0.5-0.3l-0.4-0.1h-0.2l-0.6,0.5h-0.2l-0.3-0.1l-1.8-0.4l-0.2-0.2l-0.7-0.8l-0.5-0.5l-0.7-0.6l-0.9-0.4l-1.2-0.3l-0.7-0.3
                l-0.2-0.9l-0.6-0.9l-0.4-0.7l-0.1-0.4l0.3-0.4l0.3-0.3l0.5-0.3l0.9,0.1l0.5,0.1l0.5,0.3h0.4l0.8-0.1l0.8,0.2l0.5,0.2l0.6,0.4
                l1.7,1.5l0.7,0.5l0.3,0.1l1.3,0.6h0.2l0.5-0.3l0.1-0.2l-0.2-0.2l-0.6-0.1l-0.7-0.6l-0.4-0.5v-0.8l0.1-0.5l0.1-0.2l0.1-0.7l-0.4-0.4
                l-0.3-0.1l-0.7-0.6l-0.1-0.2l0.3-0.1h0.3l0.7,0.3h0.4l0.3-0.1v-0.1l-0.4-0.4l-0.6-0.7l-1.2-0.1l-0.8,0.1l-0.5-0.2l-0.5-0.4l-0.3-0.1
                l-0.7,0.2h-0.3l-0.3-0.1l-0.3-1.2l0.1-0.3l0.2-0.2l0.2-0.4l0.1-0.4l0.4-0.4l2.2-0.9l0.6-0.6v-0.1l-0.4,0.1l-0.5,0.3l-0.3,0.1
                l-1.3-0.4l-0.2,0.1l-0.5,0.5l-0.7,0.6h-0.3l-0.4-0.3l-0.1-0.2v-0.3l0.5-0.4l0.2-0.2l0.3-0.6v-0.3l-0.5,0.2l-0.1-0.3v-0.5l-0.1-0.6
                l-0.1-0.6l-0.4-0.9l-0.2-0.2l-0.1-0.3l-0.3-1.2l0.1-0.3l0.3-0.2l0.1-0.1l-0.7,0.2l-0.1,0.2l0.1,0.3l0.1,0.4l0.1,0.5l0.1,0.5l0.3,0.5
                l0.2,0.3l0.3,0.7l0.1,0.7v0.4l-0.3,0.4l-0.4,0.4l-0.1,0.4l-0.1,0.4l-0.3,0.3l-0.2-0.1h-0.2l0.2,0.6l0.1,0.6l-0.1,0.6l-0.4,0.4h-0.2
                l-0.4,0.3l-1,0.2l-0.4-0.1l-0.7-0.3l-0.8-0.2l-0.3-0.4l-0.4-0.7l-0.2-0.6v-0.6l0.1-0.4l0.2-0.3l0.3-1.7l0.4-1.4l1-1.4l0.3-0.5
                l0.1-0.3v-0.2l-0.2-0.1l-1.2,1.8l-0.7,0.2l-0.2-0.4l0.1-0.7l0.1-0.2l0.6,0.1l0.2-0.4l-0.4-0.6l-0.4-0.2l-0.1-0.2l0.4-0.5h0.9
                l0.2-0.3l0.3-0.4l0.4-0.7l0.1-0.6v-0.5l-0.1-0.4v-0.4l0.1-0.4l-0.1-0.4l-0.3-0.5l-0.6-0.5l-0.2,0.5l-0.2,0.2h-0.3l-0.2-0.2l-0.2-0.1
                l-0.2-0.2l-0.2-0.1l-0.1-0.2l-0.1-0.4v-0.6l0.3-0.3l0.4-0.2l0.3-0.4l0.2-0.6v-0.6l-0.1-0.7l-0.3-0.6l-0.6,0.6l-0.2,0.1l-0.1-0.4
                l-0.1-0.3l-0.2-0.4l-0.3-0.3l-0.3-0.2v-0.3l0.1-0.4l0.1-0.4l0.2-0.9l0.2,0.1l0.2-0.1l-0.1-0.7l-0.2-0.6l-0.2-0.4v-0.2v-0.2l-0.1-0.4
                l-0.2-0.3l-0.3-0.9l-0.2-0.4l-0.3-0.2h-0.3l-0.5,0.2l-0.8,0.3l-0.7,0.1l-0.1-0.1l0.3-0.4l0.5-0.4l0.6-0.2l0.2-0.6l-0.1-0.5v-0.5
                l-0.2-0.5l0.2-0.3l0.6-0.3h0.3l0.3-0.4l-0.7-0.9l-0.8-0.6l-0.2-0.2l-0.2-0.4l-0.1-0.6l-0.2-0.6l-0.3-0.6l-0.5-0.6l-1.3-1l-0.4-0.7
                l-0.4-1.1l-0.2-0.5l-0.2-0.3l-0.9-0.8l-0.1-0.4l0.9-1l0.1-0.4l-0.4-1.2l-0.4-0.9l-0.4-0.3l-0.6-0.2l-0.6-0.4l-0.5-0.6l-0.5-0.5
                l-0.8-0.4l-1.3-1.1l-2-1.2l-0.9-0.7l-0.5-0.3l-0.7-0.1l-1.3-0.7l-1.1-0.2l-0.7,0.1l-0.2-0.1l-0.5-0.7l-0.8-0.4l-0.4,0.1l-0.5,0.7
                l-0.6,0.7l-0.3,0.1l-0.5-0.7l-0.2-0.5l-0.2-0.2l-0.2,0.1l-0.4,0.6l-0.4,0.4l-0.6,0.5l-0.5,0.2l-0.6,0.1l-0.2,0.2h-0.2l-0.3-0.2
                l-0.3-0.3l-0.3-0.5l-0.3-0.3h-0.2l-0.5,0.3l-0.6,0.8l-0.3,0.2l-0.2-0.1l-0.3-0.2l-0.6-0.6l-0.3,0.1l-0.2,0.2l0.1,0.5l0.6,1.2
                l0.5,0.8l-0.4,0.1l-3.7-1.1l-0.5-0.3l-0.7-0.7l-0.6-0.4l-1-1.1l-0.7-0.6l-0.2-0.5l-0.1-0.3l0.2-0.4l1.5-1.5l0.6-0.3l1.2-0.4l0.3-0.2
                l0.1-0.2l-0.3-0.3l-1.5,0.1l-1.4-0.2l-1.2-0.6l-0.2-0.2l-0.2-0.4l-0.2-0.5v-0.6l0.3-0.7l0.2-0.5v-0.3l-1.5,1.7l-0.6,0.6l-0.5-0.2
                l-0.3-0.3l-0.2-0.3v-0.3l0.1-0.2l0.1-0.2l-0.8-0.7l-0.4-0.5v-0.6l0.3-0.6l0.3-0.4l0.3-0.3l0.7-0.2l2.7-0.4l1.9,0.4l0.6-1.4l0.4-0.5
                l1.3-0.5l2-0.1l1.4,0.3l0.7,0.3l0.9,0.7l0.1-0.2l-0.2-0.7V109l0.4-1l0.2-0.6l-0.1-0.6l-0.4-0.7l-0.7-0.9l-0.4-0.1l-0.4,0.2l-0.5,0.5
                l-1,1.2l-0.5,0.2l-0.8,0.1l-0.4-0.2l-0.4-0.2l-0.7-0.6l-0.2-0.1l-0.3,0.3l-0.4,0.7l-0.3,0.5l-0.3,0.3l-0.3,0.1l-0.4-0.1l-1.8-1
                l-0.4-0.5v-0.7l-0.5-0.7l-0.6-0.1l-0.1-0.2l0.8-1.1l0.6-0.4l-0.1-0.1h-0.8l-0.6,0.6l-0.3,0.1h-0.7l-0.8-0.3l-0.3-0.3l-0.3-0.6
                l-0.4-0.3l-1.2-0.5l-0.3-0.3l-0.2-0.5l-1-1.3l-0.6-0.9l-0.1-0.5l0.7-1.2v-0.3l-0.3-0.5l-0.1-0.4l0.1-0.5l0.6-1.2l0.2-0.3l1-0.7l1-1
                l0.4-0.2l0.3-0.1l1.4,0.1l0.4-0.2l0.4-0.5l0.6-0.5l1.2-0.8l2.8-1.2l0.2-0.1v-0.2l-0.2-0.6l-0.1-0.3l0.6-0.5l1.3-0.9l0.9-0.4h0.6
                l0.5-0.2l0.6-0.5l0.4-0.1l2.2-0.2l1,0.3h0.5l0.3-0.2l0.4-0.6l0.7-1.5l0.4-1l0.4-1.5l0.5-2.3l0.4-2.3l0.2-2.3l0.2-1.4l0.1-0.5
                l0.4-0.6l0.5-0.5l0.8-0.4l-0.1-0.2l-0.3-0.4l-0.3-0.1l-0.3,0.1l-0.5,0.5l-0.7,0.4l-0.7-0.1l-0.5,0.1l-0.4,0.5l-0.7,0.4l-0.5-0.1
                l-0.9-0.7l-0.4-0.1l-1.1,0.1l-0.3-0.3l-0.3-0.5l-0.2-0.7l-0.2-0.9v-1l0.4-1.7l0.2-0.5l1.1-2l0.7-0.9l0.7-0.7l0.5-0.4l0.5-0.2
                l0.4-0.4l0.8-1.4l0.8-1.2l1-2.3l0.5-0.6l1.8-1h0.5l0.4,0.3l0.4,0.5l1.1,2.3l0.1-0.1l-0.2-0.9l-0.4-2.6l0.1-1.1L300,51l0.3-0.2
                l0.6,0.1l1,0.4l0.7,0.4l0.5,0.6l0.6,0.4h0.3l0.2-0.3l0.3-1l0.4-1.7l0.2-2.1L305,45l-0.1-1.9l-0.2-1.2l0.1-1l0.5-1.2l0.5-0.9l1.2-1.3
                l0.9-0.3l0.6,0.1l0.7,0.6l1,0.4l0.9,1.2l1.5,2.6l1,1.3l0.8,0.6l0.8,1l1.2,2.1L317,48l0.4,0.3l0.3,0.1l-0.1-0.6l-0.6-1.2l-0.9-1.4
                l-1.9-2.5l-1.1-1.8l-0.9-2.1l-0.7-1.2l-1.3-1.2v-0.5l1.5-1.8l2.8-1.5l3.2-1.2l1.1,0.1l1.9-0.5l0.2-0.8l0.4-0.3l1.7-1.1h0.5l0.8,0.5
                l0.8,1l0.4,0.9l0.5,1.5l0.2,2.1v6.4v1.2l0.1,0.5l0.4-0.7l0.4-1.3l0.4-0.9l0.3-1.4l0.2-1.9l0.1-1.4l-0.5-1.6V31l0.2-1.5h0.7l2.9,5
                l1.1,1.1l1.3,2.6l1.5-0.3l1.4,0.3l0.6-0.1l0.3-0.4l-0.4-1.1l-2-3l-0.9-2.4l-0.6-3.1l-0.2-1.6l0.5-0.3h2.2l3.3,1.4l4.2,4.8l2.1,1.6
                l3.7,5.4l1.1,0.8l0.5,0.2l0.3-0.7l0.2-0.7v-1l-0.2-1.2l-0.1-1.4v-1.6l0.3-3.2l0.6-1.1l0.3-1.2l-0.2-2.2l-0.7-1.5l-2.7-4v-0.5
                l0.6-0.5l0.8-0.3l6.8,1l1.2,0.4l0.5,0.3l0.2,0.5l0.3,0.2l1.5-0.5v-1l-0.2-0.6l-7.9-1.8l-1.5-0.6h-0.7l-0.8,0.5l-1.6,0.2l-0.7-0.1
                l-1-2.1l0.9-2.6l0.7,0.1l1.4,1l0.8-1.4l1.3-1.2l1.3-0.4l2.8-2.6l0.5-0.2l0.7,0.1l1.5,0.7l0.6,0.6l0.7,1.4l0.4,0.4l0.5,0.1l0.6,0.6
                l0.9-2l0.8-2.1l0.9-1.4l1.3,0.8l0.8,1l0.8,1.2l1.1,0.7l1.8,4.1h0.3l0.2-0.3l0.2-0.8l0.1-1.1l0.2-1.5l-0.2-0.6l-1.5-1.7l-2.2-0.7
                l-0.3-1.1V5.9l0.3-0.7l0.3-0.3l1,0.3l0.3-0.2l0.3-0.6l0.4-1.2l0.3-0.3h0.7l1,0.8l0.9,0.1l0.3-0.3V2.8l0.1-0.5l0.1-0.2h3.2l0.8-0.2
                l0.6-0.6l0.8-0.1l0.7,0.3l0.8,0.7h0.8l1.2-1.2l1.2-0.6l6.4,0.2L391,1L391,1z M349.2,34.4l-0.6,0.4l-1.2-2.2l-1.9-2.2l-1.7-1.5
                l-1.6-4l-0.1-1.4l0.2-1.3l1.4-0.2l1.1-0.6l2.8,2.5L349,26l0.5,1.7l-0.1,3.3L349.2,34.4z M422.7,39.2l-0.3,0.6l-0.7-0.3l-0.9-1.8
                l-0.6-1.5v-1.8l0.3-0.8l0.5-0.1l0.7,1.5l0.7,2L422.7,39.2L422.7,39.2z M425.7,74.9l-1.2,1.8l-1.8-0.1l-1-0.8l-0.3-1.3l0.4-1.4
                l1.3-1.1l1.6-0.7l1.4,0.4l0.2,1.4L425.7,74.9L425.7,74.9z M421.8,102l-0.4,0.5l-0.2-1.4l-0.2-1.9V97l0.6-1.1l0.3-0.2l0.2,0.2v1v1.9
                l0.2,1.3C422.1,100.1,421.8,102,421.8,102z M424.7,106.4h-0.5l-0.2-0.3l0.1-0.6l0.8-2l0.3-0.2l0.4,0.2l0.1,1l-0.2,1L424.7,106.4
                L424.7,106.4z M274,110.5l-1,0.1l-1-0.5l-0.3-0.4v-0.6l0.1-0.2l0.5-0.2h0.4l0.3,0.1l0.7,0.4l0.5,0.4l0.3,0.1l-0.1,0.5
                C274.5,110.1,274,110.5,274,110.5z M422.9,126.1l-0.3,0.3l-1.1-4.9v-1.8l0.1-1.4l0.5-0.1l0.4,0.8l0.2,2.9
                C422.7,121.9,422.9,126.1,422.9,126.1z M424.6,133.4l0.2,1.2l0.1,1.1l0.3,0.7l0.7-0.1l0.3,1.2l-0.5,0.5l-2.2-0.2l-0.9,0.1l-0.6-0.8
                v-1.4l0.1-1.4l0.6-0.8l0.5,0.7l0.6-0.5L424.6,133.4L424.6,133.4z M320.7,160.2l-0.4,0.6l-0.3,0.4l-0.7,1.1h-0.1l-0.2-0.1l-0.1-0.3
                l-0.3-0.1l-0.1-0.2v-0.1l-0.1-0.1l-0.2-0.1l-0.3,0.1l-0.3-0.1l-0.2-0.5l0.4-0.3l0.2-0.3l0.9-0.1l0.3,0.1h0.2l0.2-0.1l0.5-0.3
                l0.1-0.2l0.5,0.2V160.2L320.7,160.2z M324.8,176l-0.3,0.1l-0.8-0.4l-0.1-0.2v-0.2v-0.2l0.2-0.4l0.5-0.7l0.3-0.1l0.2,0.4l0.2,0.6v0.3
                v0.3l-0.1,0.3L324.8,176L324.8,176L324.8,176z M403.7,177.1l0.1,0.5v0.2v0.2l-0.1,0.1l-0.1,0.1l0.2,0.3l0.1,0.2v0.2l-0.2,0.3
                l-1.1,0.5l-0.3,0.2l-0.4,0.5l-0.5,0.5h-0.2l-0.2-0.5l-0.7-0.4l-1.4,0.2l-1.6,0.4l-0.6,0.2l-0.3-0.1l-0.1-0.2v-0.2l0.2-0.7l0.1-0.2
                l0.4-0.2l0.3-0.6l-0.1-0.7l0.1-0.9l0.3-0.1l0.6,0.3l0.4,0.1h0.7l1-0.1l0.8-0.4l1.5-1.1h0.3l0.2,0.4l0.1,0.2l0.4,0.3L403.7,177.1
                L403.7,177.1z M330.1,177.6h-0.4l-0.9-0.2l-0.1-0.1v-0.2l0.1-0.5l0.4-0.1l0.5,0.3l0.5,0.4l0.1,0.2L330.1,177.6L330.1,177.6z
                M327.1,185.2l0.9,0.7l1,0.5l0.1,0.2l0.1,0.3v0.2v0.2l-0.1,0.1l0.1,0.2l0.2,0.2v0.3l-0.2,0.4l-0.3,0.5l-1.8,1l-0.7,0.2l-1.6,0.7
                h-0.5h-0.1l-0.3-0.3l-0.4-0.3l-0.3-0.4l-0.2-0.3l0.1-0.2l0.3-0.1h0.4l0.6-0.2l-0.2-0.2l-0.2-0.1l-0.1-0.3l-0.3,0.1l-0.2-0.2
                l-0.4-0.1l-1-0.1l-0.7-0.3l-0.2-0.2l-0.2-0.3l-0.1-0.4l0.2-1.5l0.1-0.4l0.3-0.1l0.8,0.3l0.1-0.1l-0.9-0.6l-0.3-0.3L321,184l-0.1-0.4
                v-0.2v-0.2l0.1-0.2l0.2-0.3l0.9-0.5l1,0.2l1.8,0.6l0.2,0.1l0.5,0.5L327.1,185.2L327.1,185.2z M331.9,188.4l-0.4,0.3l-0.1-0.1
                l-0.1-0.2l-0.2-1l-0.1-0.5l0.1-0.6l-0.1-0.4l0.4-0.5l0.3-0.1l0.4,0.1l0.7,0.5l-0.1,0.2l-0.2,0.3l-0.4,0.3l-0.2,0.7v0.4v0.3
                L331.9,188.4L331.9,188.4z M371.2,218h-0.4l-0.1-0.5v-0.6l0.5-0.2l0.3,0.4l-0.1,0.6L371.2,218L371.2,218z M345.2,247.6l-0.3,0.7
                l-0.3-0.2l-0.2-0.3l-0.3-0.1h-0.3v-0.1l1.1-0.7l0.5-0.2v0.3l-0.1,0.3L345.2,247.6z"/>
            <linearGradient id="GM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="240.8079" x2="0.1864" y2="240.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GM" class="stcustom0 st83" d="M428,426.1v-0.2l-0.2-0.6l0.2-0.2l0.2-0.1l0.2,0.1v0.2l0.1,0.2l0.4,0.1l0.4-0.1h0.2v-0.1l0.1-0.2
                l0.5-0.1h0.5l0.5-0.1h0.4h0.1l0,0h-0.4l-0.8,0.1h-0.8l-0.6,0.3h-0.2l-0.3-0.3l-0.1-0.4h0.7h0.9h0.9h0.4l0.2-0.4l0.4-0.2l0.5-0.1h0.2
                l0.2,0.1l0.5,0.3l0.3,0.1l0.3,0.1l0.2,0.2l0.3,0.2h0.2h0.1l0.2-0.1h0.1h0.5l0.4,0.2l0.1,0.2l-0.1,0.2l-0.5,0.1l-0.7,0.2l-0.5-0.1
                l-0.7-0.2l-0.4-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1h-0.2L432,425l-0.1,0.1l-0.1,0.2l-0.1,0.1l-0.5,0.1l-0.5,0.1h-0.3h-0.2
                l-0.1,0.5h-0.6H429h-0.6h-0.6l0.4-0.2L428,426.1z"/>
            <linearGradient id="GN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="230.7078" x2="0.1864" y2="230.7078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GN" class="stcustom0 st84" d="M443.1,428L443.1,428l-0.2,0.2l-0.1,0.2l-0.1,0.1v0.1l0.2,0.2l0.3,0.3h0.1l0.1-0.1l0.2-0.2l0.2-0.2
                l0.2-0.1h0.2l0.2,0.2l0.2,0.3l0.2,0.3l0,0h0.1h0.1l0.1-0.1h0.1l0.1-0.1l0.3-0.4l0.3-0.1h0.1l0.2-0.1l0.3,0.1l0.4,0.2l0.5,0.2h0.2
                h0.1l0.2-0.3l0.2-0.1l0.3-0.1l0.2-0.1h0.1v-0.1v-0.1v-0.1l-0.1-0.2v-0.1h0.1h0.2h0.2l0.3,0.1l0.2,0.1l0.1,0.2l0.1,0.3l0.1,0.3
                l0.3,0.5v0.3v0.4l0.1,0.1h0.1l0.1,0.1l0.1,0.3l0.1,0.1h0.1l0.3,0.2l0.2,0.1v0.1v0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.1,0.2l-0.3,0.4
                v0.1l0.1,0.1h0.1h0.1l0.2-0.1l0.2,0.1l0.2,0.1l0.1,0.1v0.2v0.2v0.2l0.1,0.4l0.1,0.4l0.1,0.1l0.6,0.3l0.1,0.1v0.1v0.2l-0.1,0.1
                l-0.2,0.2L452,435l0.2-0.1v0.3v0.6v0.5l0.1,0.2l0.2,0.1h0.2h0.2v0.3v0.3l0.2,0.1l0.1,0.1l0.1,0.1l-0.3,0.2l-0.1,0.1v0.3v0.3l0.5,0.2
                l0.2,0.2l0.1,0.2v0.4v0.1h-0.1l-0.1-0.1l-0.1-0.1h-0.1h-0.2h-0.3h-0.3h-0.1l-0.1,0.1v0.1v0.4l0.1,0.1l0.2,0.1h0.1h0.1l0.1,0.1v0.2
                l-0.1,0.1l-0.1,0.1l-0.1,0.3v0.1v0.2l-0.3,0.5l-0.1,0.1l-0.3-0.1h-0.2l-0.2,0.1l-0.1-0.1l-0.1-0.1v-0.1h-0.1h-0.1l-0.1,0.1l-0.1,0.2
                v0.2v0.1l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.2h-0.1h-0.1l0,0l-0.2,0.1h-0.2v-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.3-0.1h-0.1H449h-0.1
                v-0.1l0.1-0.2l0.1-0.2v-0.2v-0.2l-0.1-0.3l-0.1-0.2v-0.1v-0.2v-0.2v-0.1v-0.2V440l-0.1-0.1v-0.2v-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.1
                l-0.1-0.1h-0.1l0,0v0.1h-0.1L448,439l0,0l-0.1,0.1l-0.7,0.3v-0.1l-0.1-0.1H447l-0.2,0.1h-0.1h-0.2h-0.1l-0.3,0.3l-0.2,0.1h-0.1h-0.1
                h-0.1h-0.1v-0.1l0.1-0.1l0.1-0.4l0.4-0.4v-0.1l-0.1-0.2l-0.2-0.3v-0.3v-0.2l-0.3-0.1h-0.1v-0.1l0.1-0.2l0.1-0.2v-0.1v-0.1l-0.2-0.2
                l-0.3-0.4l-0.3-0.4l-0.2-0.4l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.2-0.1h-0.6h-0.7H442v0.2l-0.6,0.1l-0.4-0.2l-0.4,0.1l-0.2,0.1l-0.1,0.2
                l-0.1,0.2l-0.1,0.1v0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.4,0.1l-0.1,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.1h-0.2
                l-0.2,0.1v-0.1l0.1-0.3l-0.1-0.1l-0.3-0.3v-0.1l-0.1-0.2l-0.4-0.3H437l0.1-0.3v-0.4l-0.1-0.2v-0.2h-0.1l-0.1,0.1h-0.2l-0.4-0.2
                l-0.2-0.2v-0.2v-0.1h-0.1h-0.2L435,434l-0.5-0.9v-0.2l0.1-0.3v-0.1l-0.2,0.2v-0.1l-0.2-0.3l-0.1-0.2l-0.2-0.1h-0.1l-0.1,0.1
                l-0.1,0.4h-0.1l-0.1-0.1v-0.3l0.1-0.1l0.2-0.2l0.5-0.9l0.2-0.2l0.1-0.1h0.2l0.4-0.1l0.4-0.2l0.2-0.1h0.4h0.5l0.6-0.2v-0.3v-0.4v-0.1
                l-0.2-0.1l-0.8-0.2l-0.1-0.1l-0.1-0.1v-0.1l0.2-0.1h0.1h0.3l0.1-0.1l0.1-0.1l0.1-0.2v-0.2l-0.2-0.3v-0.2h0.9h0.1h0.4h0.3h0.2
                l0.1,0.1v0.1v0.1l0.1,0.1h0.1h0.1l0.1-0.1l0.1-0.1h0.1l0.3,0.2l0.2,0.1l0.3,0.1l0.2,0.1h0.2l0.2,0.1h0.3l0.4-0.1l0.3-0.1h0.4h0.2
                L443.1,428l0.2-0.1L443.1,428z"/>
            <linearGradient id="GO_1_" gradientUnits="userSpaceOnUse" x1="607.9044" y1="170.5579" x2="607.9044" y2="170.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GO" class="stcustom0 st85" d="M607.9,495.3L607.9,495.3L607.9,495.3L607.9,495.3L607.9,495.3L607.9,495.3z"/>
            <linearGradient id="GP_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="248.8079" x2="0.1864" y2="248.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GP" class="stcustom0 st86" d="M303,417h-0.3h-0.2v-0.2l0.1-0.2l-0.1-0.2l0.1-0.1l0.1-0.1l0.2,0.1v0.2l0.1,0.1l0.5,0.3L303,417
                L303,417z M302.2,417.6l-0.2,0.1h-0.1l-0.1-0.2l-0.1-0.7l0.1-0.1h0.1l0.3,0.1l0.1,0.1l0.1,0.1l-0.1,0.1L302.2,417.6L302.2,417.6
                L302.2,417.6z M303.3,417.9h-0.2H303v-0.2l0.1-0.1h0.1l0.1,0.1V417.9L303.3,417.9L303.3,417.9z"/>
            <linearGradient id="GQ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="209.7079" x2="0.1864" y2="209.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GQ" class="stcustom0 st87" d="M499.6,452.3L499.6,452.3h0.5l0.1,0.1v0.2l-0.4,0.6l-0.1,0.3l-0.2,0.2h-0.1l-0.5-0.1l-0.1-0.1v-0.1
                v-0.3V453h0.2h0.1l0.1-0.3v-0.2l0.1-0.2H499.6L499.6,452.3z M506.9,456.8v0.6v0.5v0.6v0.6v0.5v0.3h-0.6h-0.8h-0.8h-0.8h-0.4h-0.4
                H503l-0.1,0.1h-0.1l-0.1-0.1h-0.2v-0.1l-0.1-0.1h-0.2h-0.1l-0.1,0.1h-0.1v-0.1l-0.3-0.2h-0.2l-0.2-0.1l0.1-0.4l0.2-0.4l0.3-0.3
                l0.1-0.1v-0.1l0.2-0.5l0.2-0.4l-0.1-0.4l0.1-0.7h0.1v0.1v0.1l0.1,0.1l0.3,0.1h0.9h0.5h0.8h0.9L506.9,456.8L506.9,456.8z"/>
            <linearGradient id="GR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="319.1079" x2="0.1864" y2="319.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GR" class="stcustom0 st88" d="M549,334.5l0.3,0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.1l0.1,0.3v0.4l-0.1,0.2h-0.2l-0.6,0.4v0.4v0.2v0.1
                l0.1,0.1v0.1l-0.1,0.2l-0.3,0.3l-0.2,0.2l-0.2,0.3h-0.1H548l-0.1-0.2l-0.4-0.3l-1-0.2l-0.5-0.2h-0.2l-0.4-0.2l-0.3,0.1l-0.6,0.4
                h-0.3l-0.3-0.2h-0.2l-0.3,0.1l-0.4,0.5l-0.4,0.2l-0.4-0.1h-0.5l-0.1,0.3l0.1,0.2l0.3,0.3l-0.1,0.2l0.1,0.2h0.2h0.3l0.5,0.3l0.2,0.3
                l0.1,0.3l-0.3-0.2l-0.2-0.2l-0.3-0.1l-0.4-0.2h-0.3l-0.3,0.1v0.2l0.3,0.3l0.3,0.2l0.1,0.1l0.1,0.3l-0.1,0.1l-0.1,0.1l-0.3-0.2
                l-0.5-0.7l-0.7-0.1l-0.1,0.2l0.1,0.4l0.1,0.2l0.6,0.4v0.1h-0.1l-0.7-0.2l-0.2-0.4V340l-0.6-0.3l-0.6-0.4L539,339l0.1-0.1l0.1-0.2
                h-0.3l-0.2,0.2l-0.3,0.1v0.2v0.2l-0.1,0.3l-0.1,0.6l0.1,0.3l0.7,0.9l0.2,0.6l0.2,0.2l0.3,0.3l0.4,0.5l0.2,0.3l0.1,0.4l-0.3,0.3h-0.2
                l-0.1-0.1l0.1-0.3v-0.2l-0.5-0.3l-0.2,0.1l-0.2,0.2l0.1,0.3l0.1,0.2l0.1,0.3h0.3l-0.4,0.3l-0.4,0.2h-0.4h-0.2l-0.1,0.1l0.2,0.1h0.1
                l0.2,0.2l0.7,0.2l0.3,0.3h0.3l0.3,0.5l0.6,0.1l0.3,0.5l0.4,0.1l0.4,0.2l0.1,0.2l0.1,0.3v0.7l0.1,0.5v0.2v0.2l-0.1,0.1H542l-0.3-0.4
                l-0.4-0.4l-0.4-0.4l0.3-0.2h-0.1l-0.2,0.2l-0.6,0.1l-0.3,0.2h-0.1v0.1l0.1,0.1l0.2,0.2v0.3l0.1,0.3l0.2,0.1h0.2l0.1,0.1v0.1l0.1,0.2
                l0.1,0.1v0.1l-0.7,0.2l-0.1,0.1l-0.1,0.1l-0.2-0.1v-0.3l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.2-0.2l-0.1,0.2l0.1,0.5l0.2,0.4l0.4,1
                l0.2,0.6v0.3l-0.1,0.5l0.2,0.3l0.1,0.3h-0.1l-0.1-0.1l-0.2-0.1l-0.4-0.6l-0.1-0.3h-0.2h-0.3l-0.3,0.7v0.4l-0.2-0.1l-0.1-0.1V353
                v-0.2l-0.4-0.6l-0.2-0.1l-0.1-0.2l-0.2-0.2h-0.2l-0.2,0.1v0.3v0.3l-0.1,0.2l-0.4-0.4l-0.4-0.8V351l0.3-0.4v-0.3l-0.3-0.5l-0.4-0.3
                l-0.2-0.1l-0.1-0.4l-0.2-0.2l-0.2-0.1v-0.1l0.1-0.1l0.5-0.4l0.3-0.6h0.1l0.3,0.1h0.3l0.3-0.4l0.2-0.2h0.4l0.8,0.5l0.9,0.3l0.4,0.2
                l0.2,0.2h0.1h0.2V348l-0.1-0.2l0.2-0.1h0.5l0.1-0.1l0.1-0.1l-0.1-0.2l-0.2-0.1h-0.2H540h-0.2l-0.3-0.1l-0.1-0.1l-0.1-0.1l-0.5-0.2
                l-0.5-0.3l-0.1,0.2l-0.2,0.1h-0.3l-0.7-0.2l-0.5,0.2h-0.2h-0.2l-0.2,0.1h-0.3l-0.2-0.3l-0.1-0.2h-0.1v0.2l-0.1,0.2l-0.3,0.1
                l-0.2-0.1l-0.2-0.4l-0.2-0.5l-0.3-0.4l-0.3-0.1v-0.2v-0.2h0.3l0.5,0.2h0.1l0.1-0.1v-0.2l-0.1-0.2h-0.1h-0.1H534l-0.4,0.1l-0.2-0.1
                l-0.1-0.1l-0.3-0.3l-0.3-0.4l-0.5-0.3l-0.3-0.8l-0.3-0.3l-0.4-0.5h0.1h0.1l0.2,0.1h0.2l0.1-0.1l0.1-0.1v-0.1v-0.2v-0.1h0.2h0.1v-0.1
                l-0.1-0.2l-0.1-0.2l0,0h0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.2l0.2-0.1l0.1-0.1v-0.1l0.1-0.3l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.2-0.1
                l0.2-0.1l0.1-0.3l0.1-0.2v-0.1l-0.1-0.2l-0.1-0.2v-0.3h0.4h0.1h0.5l0.2-0.1h0.2l0.3,0.1l0.1-0.1l0.4-0.2l0.4-0.6l0.2-0.1h0.4
                l0.1-0.1h0.1l0.5,0.1h0.3l0.3-0.1l0.3-0.1l0.1-0.5l0.1-0.1h0.2h0.2h0.3h0.4l0.2-0.2h0.4h0.2h0.3h0.3l0.4-0.1l0.3-0.2h0.3h0.1h0.1
                l0.1-0.2h0.5h0.2h0.3l0.3-0.1h0.1l0.1,0.3l0.1,0.1l0.2,0.1l0.3,0.2l0.1-0.1l0.1-0.1l0.4,0.1l0.4,0.2l0.3,0.3l0.4-0.1l0.4-0.1h0.3
                h0.3l0.2-0.1l0.4,0.1l0.4-0.1l0.2-0.1l0.1-0.2v-0.3l-0.1-0.3l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1L549,334.5L549,334.5L549,334.5z
                M544.6,338.6l-0.4,0.1l-0.4-0.2v-0.1l0.2-0.3l0.1-0.1h0.3l0.2,0.2v0.1L544.6,338.6L544.6,338.6z M547.2,339.3l-0.3,0.1l-0.3-0.3
                l0.3-0.1l0.2,0.1L547.2,339.3L547.2,339.3z M546.5,340.9l-0.1,0.1l-0.1,0.2v0.3h-0.2l-0.1-0.1v-0.1v-0.1h-0.1l-0.1,0.2l-0.1,0.1
                h-0.2l-0.2-0.1v-0.2V341v-0.1h0.5l0.1,0.2l0.2-0.1l0.1-0.1l0.2-0.1L546.5,340.9L546.5,340.9z M531.4,342.9l0.1,0.2l-0.3-0.1
                l-0.3-0.2l-0.2-0.4l-0.4-0.5v-0.1l0.2-0.1l0.4-0.1l0.1,0.1l0.1,0.1v0.1l-0.2,0.2v0.1l0.2,0.2v0.1l0.1,0.3l0.1,0.1l0.2,0.1
                L531.4,342.9L531.4,342.9z M549.2,343.3v0.2l0.4,0.4l0.1,0.2v0.2v0.1l-0.1-0.1h-0.1v0.2l0.1,0.1l-0.2,0.1h-0.2l-0.6-0.2l-0.1-0.2
                l0.4-0.3l0.1-0.1h-0.3l-0.3,0.4l-0.5-0.2l-0.1-0.1v-0.1l0.2-0.3h0.3l0.2-0.1l0.2-0.1v-0.1H549.2L549.2,343.3L549.2,343.3z
                M542.1,343.9L542.1,343.9v-0.3l0.2-0.1l-0.1,0.2L542.1,343.9L542.1,343.9z M541.8,344l-0.1,0.1l-0.2-0.1l-0.2-0.4L541.8,344z
                M540.8,344.6l0.2,0.4l0.1,0.1l0.3,0.2h0.1l0.5,0.3l0.6,0.1l0.1,0.1l0.1,0.2l0.1,0.2v0.1l-0.1,0.1l0.1,0.4l0.2,0.4l0.2,0.2l0.3,0.1
                h0.3l0.1,0.1v0.4l-0.1,0.1h-0.1h-0.1l-0.1-0.1h-0.1h-0.2l-0.1-0.1l-0.3-0.2l-0.1-0.1v-0.2l-0.1-0.1l-0.1-0.3l-0.1-0.1l-0.1-0.1v-0.1
                H542h-0.4l-0.3-0.1l-0.1-0.4l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.3-0.3l-0.3-0.2l-0.3-0.2l-0.3-0.1l-0.3,0.1h-0.2V345l0.3-0.2l0.4-0.3
                l0.3-0.1h0.2L540.8,344.6L540.8,344.6z M544.3,345.1l-0.3,0.1h-0.1l0.1-0.1l0,0l-0.3-0.2v-0.3v-0.1l0.2,0.1v0.2L544.3,345.1
                L544.3,345.1z M533.2,345.9L533.2,345.9H533h-0.1l-0.1,0.1v-0.3l0.1-0.4l0.1-0.2l0.2-0.1l0.1,0.2L533.2,345.9L533.2,345.9
                L533.2,345.9z M548.3,347.3l-0.3,0.2l-0.3-0.3v-0.1l0.2-0.1l0.1-0.2l-0.1-0.2l-0.3-0.3v-0.2l0.5-0.1l0.3,0.2h0.1l-0.1,0.2v0.1v0.6
                L548.3,347.3L548.3,347.3L548.3,347.3L548.3,347.3z M533.4,346.9L533.4,346.9l-0.3-0.3l-0.1-0.2h0.1h0.1l0.1,0.1v0.1v0.1
                L533.4,346.9L533.4,346.9z M532.9,346.7v0.4l0.2,0.1l0.3,0.4v0.2l-0.1,0.1l-0.4-0.2l-0.1,0.1h-0.1l-0.1-0.2v-0.1l-0.1-0.1l-0.1-0.1
                l-0.2,0.2h-0.1v-0.1l0.2-0.4l0.1-0.1l0.1,0.1h0.1l0.1-0.2v-0.2v-0.1L532.9,346.7L532.9,346.7z M541.2,348.3l-0.1,0.1H541h-0.1v-0.1
                h0.1l0.1-0.1h0.1h0.1l0.1,0.1H541.2L541.2,348.3z M545.2,348.9l-0.1,0.2l-0.2-0.3l-0.2-0.2l-0.1-0.2l-0.1-0.1v-0.2l0.2-0.1h0.1
                l0.2,0.3h0.3v0.2l0.1,0.2L545.2,348.9L545.2,348.9z M533.7,348.7l0.3,0.3l-0.2-0.1l-0.3,0.2l-0.3-0.2l-0.2-0.3v-0.1l0.2-0.3l0.2,0.3
                h0.2L533.7,348.7L533.7,348.7z M550.4,348.7l0.3,0.1h0.1h0.2v0.2h-0.2l-0.4,0.2h-0.2L550,349h-0.3h-0.1l0.2-0.2l0.3-0.1H550.4z
                M543.4,349.5l-0.2,0.2v-0.3l0.1-0.3h0.2l0.1,0.1L543.4,349.5L543.4,349.5z M546,349.4l-0.1,0.2h-0.2l-0.3-0.2l-0.1-0.1l-0.1-0.1
                h0.1l0.1,0.1l0.4,0.1H546z M548.1,349.7L548.1,349.7l-0.1-0.1l0.3-0.2h0.4l0.3-0.1h0.1l-0.2,0.2l-0.3,0.2H548.1L548.1,349.7z
                M546.4,350.1h-0.3v-0.3l0.1-0.1l0.3,0.1v0.1L546.4,350.1z M545.1,349.8v0.4H545v-0.1V350L545.1,349.8L545.1,349.8L545.1,349.8z
                M543.7,350.3l-0.2,0.1l0.1-0.2l-0.1-0.1l0.1-0.1l0.1-0.1v0.1l0.1,0.1L543.7,350.3L543.7,350.3z M543.9,351.1l-0.1,0.1l-0.2-0.1
                v-0.2l0.1-0.1l0.1,0.1v0.1L543.9,351.1L543.9,351.1z M546.8,351.7l-0.2,0.1l-0.2-0.2l-0.1-0.3l0.5-0.4h0.1l0.1,0.1v0.4L546.8,351.7
                L546.8,351.7z M546,351.3l-0.2,0.3h-0.2l-0.1-0.1l0.1-0.3l0.2-0.1h0.1L546,351.3L546,351.3L546,351.3z M550.9,351.7h-0.3v-0.3
                l-0.1-0.2l0.2,0.1l0.1,0.1h0.1V351.7L550.9,351.7L550.9,351.7z M544.5,351.8L544.5,351.8l-0.1-0.1v-0.1v-0.1h0.1V351.8L544.5,351.8z
                M547.7,352.3h-0.2h-0.1l0.2-0.1l0.1-0.1l0.1-0.1l0.3-0.1l0.2-0.2l0.2,0.1l-0.2,0.1L547.7,352.3z M550.7,352.5L550.7,352.5v-0.2
                l0.3-0.2l0.4-0.2h0.1l0.2,0.1l-0.4,0.2l-0.1,0.1h-0.3L550.7,352.5L550.7,352.5z M546.3,352.7L546.3,352.7l-0.3-0.2l-0.1-0.1l0.1-0.1
                l0.3,0.2V352.7L546.3,352.7z M544,352.4v0.2v0.1l-0.6,0.1l0.1-0.2v-0.1l0.2,0.1l0.1-0.1v-0.1H544z M553.3,353.1L553.3,353.1
                l-0.2-0.1v-0.1l0.1-0.1h0.1v0.1v0.1L553.3,353.1z M549.4,353l-0.2,0.1l-0.1,0.2l-0.2-0.1V353l0.2,0.1l0.1-0.1v-0.1h0.1L549.4,353
                L549.4,353z M546.6,353.7l-0.1,0.2l-0.2-0.1l0.1-0.1v-0.1v-0.1v-0.1l0,0l0.2,0.1V353.7L546.6,353.7z M553.2,355.3l-0.2,0.1h-0.1
                l-0.1-0.2l0.1-0.4l-0.1-0.2v-0.1l0.2-0.1l0.1-0.2l0.3-0.2l0.7-0.3h0.2v0.2l-0.2,0.6l-0.2,0.3l0.1,0.2l-0.3,0.1L553.2,355.3
                L553.2,355.3z M539.8,354.4v0.1l-0.3-0.1l-0.1-0.2v-0.3l0.1-0.2v-0.1l0.1,0.2l0.3,0.3L539.8,354.4L539.8,354.4z M551.4,356.9
                l-0.1,0.2l-0.1-0.2v-0.2l-0.1-0.3l0.2-0.4v-0.2l0.2-0.1v0.4l-0.1,0.3l0.1,0.2l0.1,0.3L551.4,356.9L551.4,356.9z M542,356.6h0.2h0.3
                h0.1l0.2-0.2h0.2l0.1,0.2l-0.2,0.1v0.1l0,0l0.2,0.1h0.2v0.2v0.1l0.1,0.1h0.1h0.3h0.3l0.3-0.1l0.3-0.1l0.8,0.1l0.3,0.2h0.5l0.5,0.1
                l0.3-0.1l0.5-0.1l0.1,0.1l-0.1,0.5v0.1l0.1,0.1h0.1l0.2-0.2l0.4-0.1h0.4l0.3-0.3h0.1l-0.1,0.2v0.4l-0.1,0.2v0.2l-0.2,0.1h-0.3h-0.6
                l-0.6,0.1l-1.1,0.2l-1.1,0.1l-0.2-0.1v-0.2v-0.1l-0.1-0.1l-0.4-0.1l-0.3-0.2l-1.3-0.2l-0.3-0.1H542h-0.2l-0.1-0.1l-0.1-0.1v-0.4
                l0.1-0.4l0.1-0.1v0.1l0.1,0.1l0.1-0.1V356.6L542,356.6h0.2l0.1,0.3L542,356.6L542,356.6z"/>
            <linearGradient id="GS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="11.9078" x2="0.1864" y2="11.9078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GS" class="stcustom0 st89" d="M371,643l0.3,0.2l0.2-0.2h0.2l0.1,0.1h0.1h0.2l0.3,0.4l-0.1,0.3l0.3-0.1l0.3,0.3h0.1l0.1-0.1l0.2-0.1
                l0.1,0.2l0.1,0.3l0.2,0.1l0.2,0.4l0.1,0.5l0.1,0.1h0.2l0.2-0.1l-0.1,0.4v0.4l0.3,0.3l-0.2,0.1l-0.2,0.2l-0.4,0.2l-0.1-0.1l-0.4-0.4
                l-0.2-0.4l-0.4-0.6l-0.1-0.2l-0.1-0.1l-0.3-0.1l-0.3-0.1l-0.2-0.3l-0.1-0.2l-0.1-0.1h-0.3l-0.2-0.1l-0.2-0.2l-1-0.6l-0.4,0.1
                l-0.2-0.2V643l0.2-0.2l-0.8-0.1l-0.3-0.1l0.2-0.1h1.1h0.4v0.1l0.4,0.2L371,643L371,643z M401.4,665.1v0.3l-0.4-0.3l-0.1-0.1l0.1-0.2
                h0.3l0.1,0.1V665.1L401.4,665.1z"/>
            <linearGradient id="GT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="247.8079" x2="0.1864" y2="247.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GT" class="stcustom0 st90" d="M224.9,412.3v0.7v0.8v0.6v0.8v0.8v1.1v0.7l0,0h0.3h0.5h0.1l0.2,0.1h0.1l0.3,0.2l0.3,0.1l0.2-0.2
                l-0.1-0.1l-0.1-0.1v-0.1l1,0.6l-0.1,0.1l-0.3,0.2l-0.5,0.4l-0.4,0.3l-0.4,0.3l-0.4,0.3l0,0l-0.5,0.2l-0.1,0.1l-0.1,0.4v0.1l0.1,0.2
                l0.1,0.3v0.2l-0.3,0.2l-0.1,0.2l-0.1,0.1h-0.1h-0.1h-0.2h-0.1L224,422v0.1l0.1,0.2v0.1H224l-0.3,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.1
                h-0.1h-0.1L223,423l-0.3,0.3l-0.5,0.6v0.1v0.1l-1.1-0.5l-0.4-0.1h-1.5l-0.6-0.2l-0.7-0.4l-0.5-0.3l-1.2-0.9l0.1-0.1l0.1-0.2l0.1-0.2
                V421v-0.2l0.1-0.2v-0.2v-0.1l0.1-0.1l0.1-0.1l-0.4-0.5v-0.1v-0.1l0.3-0.5l0.4-0.6l0.4-0.7l0.2-0.4h0.8h0.6h0.7h0.8h0.5h0.2v-0.3
                v-0.3l0.1-0.3v-0.1l-0.2-0.1l-0.3-0.1l-0.2-0.1v-0.2l-0.1-0.2l-0.1-0.2l-0.3-0.2l-0.4-0.2l-0.4-0.3l-0.3-0.4l-0.3-0.3l-0.2-0.1v-0.1
                h0.6h0.6v-0.6v-0.5V412h1h1.2h1.3h1L224.9,412.3z"/>
            <linearGradient id="GU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="240.9079" x2="0.1864" y2="240.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GU" class="stcustom0 st91" d="M881.3,425.5L881.3,425.5l-0.2-0.1v-0.1V425l0.4-0.3l0.1-0.3h0.1h0.1l0.1,0.1l-0.5,0.5L881.3,425.5z"
                />
            <linearGradient id="GW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="236.7578" x2="0.1864" y2="236.7578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GW" class="stcustom0 st92" d="M436.6,427.2v0.2l0.2,0.3v0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1h-0.3h-0.1l-0.2,0.1v0.1l0.1,0.1l0.1,0.1
                l0.1,0.1l0.2,0.1v0.1v0.4v0.3l-0.6,0.2h-0.5h-0.4l-0.2,0.1l-0.4,0.2l-0.4,0.1h-0.2l-0.1,0.1l-0.2,0.2l-0.5,0.9l-0.2,0.2l-0.1,0.1
                l-0.1-0.2l0.1-0.4h-0.1l-0.2,0.3h-0.1v-0.4h-0.1h-0.2l-0.2-0.2v-0.1v-0.2l0.1-0.1v-0.1h-0.1h-0.1l-0.1-0.1l0.1-0.3l0.5-0.2h0.2h0.3
                l-0.1-0.2l-0.3-0.1H432l-0.1,0.1h-0.1l-0.2-0.3v-0.2l0.1-0.2l0.1-0.1h0.6l0.2-0.1h0.1l0.1-0.1v-0.1h-0.1l-0.2,0.1h-0.7l-0.2,0.1
                l-0.4,0.3l-0.5,0.2l-0.3-0.1l0.1-0.4v-0.1l-0.1-0.1l-0.5,0.1l-0.4-0.2l-0.2-0.2v-0.3l0.2-0.2v-0.1h-0.2l-0.4,0.1l-0.8-0.4h0.2h0.4
                l0.3-0.1l0.2-0.1l0.3-0.1h0.3l0.9,0.1l0.7-0.1l0.6-0.3l0.5-0.3h0.7h0.7h1h0.8L436.6,427.2L436.6,427.2z M430.2,429.5l-0.1,0.3h-0.2
                l-0.1-0.2v-0.1L430.2,429.5L430.2,429.5L430.2,429.5z M431.4,430.4L431.4,430.4h-0.2l0.2-0.2h0.2v0.2H431.4L431.4,430.4L431.4,430.4
                z M430.5,430.6l-0.1,0.1h-0.1l-0.1-0.1v-0.1l0.2-0.2h0.1V430.6z M431,431.4v0.1h-0.1l0,0l0.1-0.2l0.1-0.1h0.1l0,0v0.1l-0.1,0.1H431
                L431,431.4z M429.9,431.8h-0.2l-0.1-0.1v-0.1h0.1h0.1l0.1-0.1l0.1-0.1l0,0h0.1v0.2l-0.1,0.1L429.9,431.8L429.9,431.8z M430.5,431.7
                L430.5,431.7l-0.2,0.1v-0.1l0,0v-0.2l0,0l0.1,0.1l0,0L430.5,431.7L430.5,431.7z"/>
            <linearGradient id="GY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="216.3579" x2="0.1864" y2="216.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="GY" class="stcustom0 st93" d="M314.6,447.3l-0.1,0.2v0.1l-0.1,0.2l-0.2,0.1l0.1,0.2h0.1l0,0l0,0v0.1l0,0l-0.1,0.1l-0.1,0.1v0.2
                l-0.1,0.1h-0.2h-0.4h-0.2h-0.2l-0.1,0.1l-0.1,0.1h-0.1l-0.1,0.1l-0.1,0.2v0.1l0.1,0.2l0.1,0.2l-0.1,0.3l-0.1,0.2l-0.1,0.1l-0.1,0.3
                l-0.2,0.3l-0.1,0.2v0.2l0.1,0.3l0.3,0.4l0.1,0.2l0.1,0.3l0.3,0.2l0.2,0.2v0.3v0.1l0.1,0.1h0.1h0.2h0.1l0,0h0.3v0.1v0.4v0.2l0.1,0.1
                v0.1v0.1v0.2l0.1,0.1v0.2v0.1l0.1,0.1l0.1,0.2l0,0v0.1l0.1,0.2l0.1,0.1l0,0v0.1l0.1,0.2v0.1l0.1,0.2v0.2l0.1,0.2l0.1,0.1l0.1,0.1
                l0.2,0.3l0.2,0.2l0.2,0.1h0.2l0.1,0.1l0.1,0.1H316l-0.1,0.1h-0.1h-0.2l-0.2,0.1h-0.2l-0.4-0.1h-0.1h-0.1l-0.2-0.2h-0.1l-0.2,0.1
                l-0.2,0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.2,0.4l-0.1,0.1l-0.1,0.1h-0.3h-0.3l-0.2,0.1h-0.2h-0.1v0.2v0.1l-0.1,0.1h-0.2h-0.1l-0.1-0.1
                h-0.2h-0.1h-0.1h-0.1l-0.1,0.1v0.1v0.1h-0.2l-0.1,0.1l0.1,0.3v0.1v0.1h-0.3h-0.2l-0.1,0.1l-0.2,0.1h-0.1h-0.1l-0.2-0.1l-0.1-0.2
                l-0.4-0.1l-0.4-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.3-0.3l-0.2-0.2l-0.2-0.1l-0.2-0.1v-0.1v-0.1l-0.1-0.1h-0.1v-0.1v-0.2v-0.4v-0.4
                l-0.3-0.1l-0.1-0.1l-0.2-0.6l-0.1-0.3v-0.2l0.1-0.6l0.1-0.3l0.2-0.5l0.1-0.2v-0.1v-0.2l-0.1-0.4l0.3-0.2l0.1-0.1v-0.1l0.2-0.2
                l0.1-0.2l0.1-0.1v-0.1h-0.1l-0.1-0.1l-0.2-0.4l-0.1-0.1l-0.1-0.1v-0.2l0.1-0.2v-0.1l-0.1-0.1l-0.2-0.2h-0.2l-0.2-0.1h-0.2h-0.2
                l-0.1-0.1v-0.1v-0.1l0.2-0.2l0.1-0.2V450v-0.3v-0.2v-0.3l-0.2-0.2l-0.1-0.1l-0.1-0.1h0.3l-0.2-0.1l-0.3,0.2h-0.2l-0.1,0.1h-0.3
                l-0.2-0.1l-0.3,0.1l-0.6-0.7l-0.6-0.7l-0.6-0.7v-0.1l0.2-0.3l0.2-0.2l0.2-0.1l0.1-0.1l-0.1-0.5v-0.2l-0.1-0.2l-0.1-0.2l0.1-0.2
                l0.1-0.1h0.1h0.3h0.2l0.1-0.1l0.1-0.1h0.1l0.3,0.1l0.1-0.1l0.2-0.1l0.5-0.2l0.1-0.2l0.1-0.2v-0.1h-0.1h-0.1h-0.2v-0.8H305l-0.1-0.2
                v-0.1l0.1-0.2v-0.1l-0.3-0.4v-0.1l0.2-0.2l0.1-0.1l0.2-0.3l0.1-0.1h0.4l0.1-0.1l0.2-0.2l0.3-0.2l0.4-0.2l0.1-0.3l0.1-0.1l0.3-0.2
                l0.1-0.1v-0.1l-0.5-0.7h0.1l0.4,0.4l0.2,0.1l0,0v-0.1h0.2l0.5,0.3l0.8,0.5l1.1,1l0.3,0.4l0.2,0.2l0.3,0.4l0.1,0.2v0.8l-0.3,0.5
                l-0.1,0.4v0.5l-0.2,0.3l0.2-0.2l0.1-0.5l0.2-0.3l0.2-0.3l0.3-0.1l0.4,0.1h0.3l0.2,0.1l0.5,0.5l0.5,0.4l0.2,0.3l0.6,0.2l0.3,0.3
                l0.1,0.2l0.1,0.6L314.6,447.3L314.6,447.3z"/>
            <linearGradient id="HK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="267.1579" x2="0.1864" y2="267.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HK" class="stcustom0 st94" d="M795.8,398.3L795.8,398.3l0.1,0.1v0.1l0.1,0.1v0.1l-0.1,0.1v0.1l-0.1,0.1l-0.4-0.2l-0.3-0.1h-0.3
                l-0.1-0.1v-0.1l0.3-0.2v-0.1l0,0l0.1-0.1h0.1h0.1h0.2h0.1L795.8,398.3L795.8,398.3z M795,399.3h-0.3h-0.1v-0.1l0.1-0.1l0.5-0.2
                l-0.1,0.2L795,399.3L795,399.3z M795.7,399.3L795.7,399.3l-0.3-0.2V399h0.1l0.2,0.1V399.3L795.7,399.3z"/>
            <linearGradient id="HM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="27.5578" x2="0.1864" y2="27.5578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HM" class="stcustom0 st95" d="M682,638.6l-0.3,0.2h-0.3l-0.1-0.2l-0.2-0.5H681l-0.1-0.2v-0.1h0.2l0.2,0.2l0.6,0.1l0.4,0.3l0.3,0.1
                l-0.1,0.1L682,638.6z"/>
            <linearGradient id="HN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="244.9078" x2="0.1864" y2="244.9078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HN" class="stcustom0 st96" d="M234.1,416.3l-0.2,0.2v-0.1l0.1-0.2l0.1-0.1h0.1L234.1,416.3L234.1,416.3L234.1,416.3z M232.6,416.5
                l-0.4,0.2h-0.1l0.2-0.2l0.3-0.1l0.3-0.1h0.2L232.6,416.5L232.6,416.5z M241.7,420.5H241l-0.3,0.1l-0.1,0.2l-0.1,0.1h-0.1l-0.2,0.1
                l-0.3,0.2l-0.3,0.1h-0.3h-0.1v0.1v0.1h-0.1H239l-0.1-0.1h-0.1v0.1h-0.1l-0.1-0.1h-0.2l-0.2,0.1H238l-0.3-0.1l-0.2-0.2l-0.2-0.2
                l-0.2-0.1l-0.4,0.2l-0.1,0.2v0.1v0.1l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.2v0.2l0.1,0.1l-0.1,0.1l-0.2,0.1l-0.3,0.2l-0.3,0.3l-0.3,0.2
                l-0.3,0.1l-0.1,0.1v0.2l0,0h-0.1h-0.1l-0.6-0.3l-0.2-0.2h-0.1l-0.2,0.1l-0.2,0.3l-0.3,0.4h-0.1l-0.7-0.1h-0.3h-0.1v0.1v0.2l0.1,0.7
                l0.1,0.3l-0.1,0.1h-0.2h-0.2l-0.1,0.1v0.1v0.2l-0.1,0.2l-0.1,0.1h-0.1h-0.8v-0.3l-0.2-0.1l-0.1-0.3l-0.1-0.2v-0.1v-0.1l-0.3-0.1
                l-0.3,0.1h-0.2l-0.1-0.1l0.2-0.1v-0.1l-0.1-0.1h-0.1v-0.2v-0.2l0.1-0.5v-0.1l-0.2-0.1h-0.3h-0.3l-0.1-0.1l-0.1-0.2l-0.2-0.1
                l-0.4,0.1l-0.4,0.2l-0.1,0.1h-0.1v-0.1v-0.2l0,0l-0.2-0.1h-0.2h-0.1l-0.1-0.1l-0.3-0.2l-0.1-0.1l-0.4-0.3l-0.1-0.1l-0.1-0.1
                l-0.2-0.1h-0.1l-0.5-0.1h-0.1l0.1-0.1l0.1-0.2l0.3-0.2V421l-0.1-0.3l-0.1-0.2v-0.1l0.1-0.4l0.1-0.1l0.5-0.2l0,0l0.4-0.3l0.4-0.3
                l0.4-0.3l0.5-0.4l0.3-0.2l0.1-0.1l0.3,0.1l0.2-0.2l0.1-0.1l0.3-0.2h0.1l0.5-0.1h0.2l0.2,0.2l0.2,0.1l0.3-0.1h0.3l1.1,0.2l0.4-0.1
                h0.8l0.3,0.1l0.5-0.3l0.3-0.1l0.4-0.1v-0.1l-0.1-0.1l0.6,0.1l0.8,0.3l0.9-0.1l0.3-0.2h0.2l0.9,0.3l0.2,0.2h0.2l0.1-0.1l0,0l-0.2-0.1
                l-0.1-0.1l0.7,0.1l1.4,1.1v0.1l-0.6-0.3h-0.3l-0.1,0.1v0.2v0.1h0.1h0.1l0.2,0.1l0.2,0.1l0.2,0.2l0.1,0.2h0.1l0.1-0.1h0.2l0.1,0.1
                h0.1l-0.1-0.2l-0.4-0.2h0.1l0.8,0.4l0.2,0.5l0.2,0.1L241.7,420.5L241.7,420.5z"/>
            <linearGradient id="HR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="342.1579" x2="0.1864" y2="342.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HR" class="stcustom0 st97" d="M521.4,315.8l0.2,0.1l0.5,0.3l0.3,0.3l0.2,0.3l0.3,0.3l0.3,0.2l0.3,0.3l0.2,0.3l0.3,0.2h0.3l0.2,0.1
                l0.1,0.2l0.2,0.2l0.3,0.1l0.4,0.1h0.8h0.1h0.2l0.2-0.1l0.3-0.1l0.1-0.1l0.3-0.4h0.2h0.3l0.2-0.1l0,0v0.1v0.2l-0.2,0.1l0.2,0.3
                l0.1,0.4l-0.1,0.2l0.1,0.2l0.3,0.1l0,0l-0.1,0.1l-0.1,0.1v0.3l0.2,0.2l0.5,0.2h0.2l0.1,0.1l0.1,0.1v0.1v0.1v0.1h-0.2H529l-0.2-0.1
                v0.1v0.1l-0.2,0.1l0.1,0.6v0.2l-0.1,0.1h-0.1h-0.1v0.1v0.1h-0.2l-0.3-0.1l-0.1-0.1V322v-0.1l-0.1-0.2l-0.2-0.2H527l-0.2-0.1
                l-0.2-0.1l-0.2-0.1h-0.2l-0.2,0.1l-0.4-0.1l-0.1,0.1l-0.2,0.1h-0.2l-0.3-0.3h-0.1l-0.3,0.2h-0.1l-0.1-0.1l-0.4-0.1h-0.2l-0.1,0.1
                l-0.2-0.1l-0.6-0.4l-0.4,0.3l-0.7-0.1l-0.2,0.2l-0.2,0.4l-0.2,0.2l-0.2-0.1l-0.2-0.2l-0.4-0.5l-0.2-0.1h-0.2l-0.2,0.1l-0.1,0.1
                l-0.1,0.7l-0.1,0.6v0.4l0.4,0.3l0.5,0.6l0.2,0.1l0.1,0.2l0.1,0.5l0.1,0.5l0.2,0.4l0.2,0.3l0.3,0.2l0.3,0.3l0.3,0.4l0.1,0.1l0.5,0.5
                l0.5,0.5l0.5,0.2l0.1,0.1v0.4v0.1l0.3,0.4l0.6,0.6l0.1,0.1v0.1v0.1l-0.2,0.1l-0.1-0.1l-0.6-0.6l-0.6-0.4l-0.6-0.7l-0.8-0.3l-0.6-0.3
                h-0.4l-0.4,0.1h-0.2l-0.2-0.1l-0.1-0.2v-0.1v-0.2l-0.3-0.3l-0.5-0.3l-0.4-0.4l-0.9-1l-0.2-0.3l0.2-0.1h0.1l0.1-0.1h0.2l0.3,0.1
                l-0.3-0.2l-0.3-0.2l-0.8-0.9l-0.2-0.4v-0.4l0.1-0.6l-0.1-0.4l-0.6-0.6l-0.2-0.3l-0.5-0.2h-0.2l-0.1,0.2l-0.1,0.5l-0.4,0.6l-0.1,0.3
                l-0.2,0.4h-0.2h-0.1l-0.3-0.6l-0.3-0.5V321v-0.3l-0.2-1l0.2-0.1l0.1,0.2l0.7,0.2l0.2-0.1l0.1-0.1v0.1h0.1l0.3,0.1h0.2h0.3h0.2
                l0.2-0.1l0.2-0.4l0.1-0.2h0.1h0.1v0.2l0.1,0.2l0.2,0.3l0.2,0.1h0.2l0.1-0.1h0.2l0.4,0.2h0.4l0.3-0.1V320l-0.1-0.2v-0.2v-0.1l0.2-0.1
                v-0.1l-0.2-0.2V319l0.5-0.3l0.5-0.1l0.1-0.1v-0.2V318v-0.3l-0.2-0.2v-0.1v-0.1l0.1-0.1h0.2l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.3
                h0.1h0.3l0.1-0.1V316l0.1-0.1L521.4,315.8l0.1-0.1h0.3L521.4,315.8L521.4,315.8z M516.7,321.9l-0.3,0.1l-0.2-0.2v-0.1h-0.3l-0.2-0.2
                v-0.1l0.2-0.2l0.1-0.3l0.2,0.2l0.2,0.3l0.1,0.1L516.7,321.9L516.7,321.9z M515.8,323.2v0.2l-0.2-0.2l-0.1-0.3l-0.2-0.6v-0.2l0.1-0.2
                v-0.2l-0.2-0.5l0.1-0.1h0.1v0.3l0.1,0.2l0.2,0.2v0.4v0.6v0.1L515.8,323.2L515.8,323.2z M516.7,322.8l0.1,0.2l-0.3-0.2l-0.2-0.1v-0.1
                v-0.1l0.1-0.1h0.2v0.1L516.7,322.8L516.7,322.8z M517.7,324.5l-0.1,0.1l-0.2-0.2l-0.2-0.1l-0.1-0.2l-0.2-0.2l-0.1-0.2l-0.3-0.5
                l-0.1-0.1l0.2,0.2l0.1,0.1h0.1l0.3,0.3l0.3,0.4l0.4,0.3L517.7,324.5L517.7,324.5L517.7,324.5z M517.7,326.1L517.7,326.1L517.7,326.1
                L517.7,326.1L517.7,326.1l-0.8-0.8l-0.1-0.2l0.2,0.2L517.7,326.1L517.7,326.1z M517.8,325.5v0.1l-0.3-0.3l-0.1-0.2V325L517.8,325.5
                L517.8,325.5z M518.2,325.9l0.2,0.3l-0.2-0.1l-0.2-0.2l-0.1-0.2L518.2,325.9z M522.2,328.6h-0.4l-0.4-0.1l-0.2-0.1v-0.1l0.1-0.2h0.4
                l0.7,0.1l0.2,0.1v0.1L522.2,328.6L522.2,328.6z M523.3,329.2h-0.2h-1.2l-0.4-0.1l-0.4-0.2l-0.1-0.1l0.4-0.1l0.4,0.1l0.1,0.1l1,0.1
                L523.3,329.2L523.3,329.2z M524.7,330l0.2-0.1l0.2,0.1l0.1,0.2l0.2,0.1l0.4,0.3l0.2,0.2l0.5,0.3h0.1l0.3,0.1v0.1l0.1,0.2l0.1,0.2
                l-0.5-0.4l-0.5-0.4l-0.9-0.6l-0.7-0.2l-0.9-0.5l-0.6-0.2h0.2h0.3l1.4,0.7L524.7,330L524.7,330z M521.8,329.6l0.5,0.1h0.4l0.3,0.1
                l0.2,0.1l0.1,0.1H523h-0.3l-0.4,0.1L522,330l-0.1-0.1l-0.1-0.1L521.8,329.6L521.8,329.6z M524.5,330.5l0.4,0.3l-1.1-0.3h0.1h0.1
                H524.5L524.5,330.5z"/>
            <linearGradient id="HT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="257.1078" x2="0.1864" y2="257.1078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HT" class="stcustom0 st98" d="M271.2,405.8l0.1,0.1v0.1l-0.3-0.1l-0.3-0.1h-0.1h-0.1l-0.2-0.1l0.1-0.1h0.2h0.2L271.2,405.8
                L271.2,405.8z M273.6,406.7L273.6,406.7l0.2,0.7v0.2l-0.1,0.3v0.1l0.3,0.3v0.1v0.1l-0.2,0.3l-0.2,0.2l0.1,0.2l0.1,0.2v0.2v0.2
                l-0.2,0.3l-0.1,0.1h-0.3l0,0l0.2,0.3l0.2,0.3l0.3,0.2l0.1,0.2l-0.1,0.2v0.5l-0.2-0.2l-0.3-0.2l-0.2-0.1H273h-1.2h-0.1l-0.1,0.1h-0.1
                l-0.3,0.1h-0.3l-0.8-0.2l-0.3-0.1l-0.3-0.1h-0.4h-0.4l-0.3,0.1l-0.2,0.2v0.2h-0.1l-0.3-0.3l-0.3-0.2l-0.3-0.2l-0.6-0.2l-0.1-0.1
                l-0.1-0.2l0.3-0.5l0.3-0.1h0.2l0.4,0.1l0.3,0.1l0.3,0.1h0.5l0.3,0.1l1.9,0.2l0.4,0.1h0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.6h0.1l0.1-0.1
                V410l-0.3-0.2l-0.5-0.4l-0.5-0.5l0.2-0.2l-0.1-0.3l0.1-0.3l0.1-0.3l-0.5-0.3l-0.5-0.3l-0.7-0.1l-0.2-0.1l-0.1-0.2l0.1-0.3l0.2-0.1
                l0.3-0.1l0.3-0.1l0.7-0.1l0.7,0.1l0.6,0.3l0.6,0.2l0.7,0.1l0.3,0.1L273.6,406.7L273.6,406.7z M270.8,409.5v0.2l-0.7-0.2l-0.6-0.3
                V409h0.3l0.3,0.1l0.4,0.2L270.8,409.5z"/>
            <linearGradient id="HU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="352.7579" x2="0.1864" y2="352.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="HU" class="stcustom0 st99" d="M537.2,308h0.3l0,0h0.1v0.2l0,0l0.1,0.1l0.1,0.2l0.1,0.1l0.2,0.1l0.3,0.2l0.2,0.3l0.3,0.1l0,0h0.1h0.2
                v0.1l0.2,0.1l0.1,0.1v0.1v0.2l0.1,0.1l-0.1,0.1l-0.5,0.5l-0.2,0.1h-0.1l-0.2-0.1h-0.2l-0.2,0.1h-0.2l-0.1,0.1l-0.2,0.3l-0.2,0.2
                l-0.2,0.1l-0.1,0.1v0.5l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.2,0.7l-0.2,0.2l-0.2,0.2v0.2v0.2l-0.2,0.4l-0.3,0.4v0.1l0.1,0.2l-0.2,0.2
                l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.4v0.2v0.1l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.6-0.1l-0.2,0.1l-0.1,0.1v0.1
                l-0.1,0.1l-0.1,0.1h-0.1l-0.3-0.1l-0.6,0.1l-0.1,0.1l-0.1-0.1l-0.1-0.1l-0.6-0.1l-0.3,0.1h-0.3l-0.3-0.1l-0.2,0.1l-0.2,0.3l-0.1,0.1
                l-0.1,0.1l-0.2,0.1l-0.5,0.1L529,318h-0.2l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1H528l0,0l-0.2,0.1h-0.3h-0.2l-0.3,0.4l-0.1,0.1
                l-0.3,0.1l-0.2,0.1h-0.2h-0.1h-0.8l-0.4-0.1l-0.3-0.1l-0.2-0.2l-0.1-0.2l-0.2-0.1h-0.3l-0.3-0.2l-0.2-0.3l-0.3-0.3l-0.3-0.2
                l-0.3-0.3l-0.2-0.3l-0.3-0.3l-0.5-0.3l-0.2-0.1v-0.1l-0.2-0.3l-0.1-0.1v-0.2v-0.1l-0.1-0.1v-0.2v-0.2l-0.1-0.1h-0.5l0.4-0.4l0.2-0.1
                h0.3h0.1V314v-0.1v-0.1v-0.1v-0.1h-0.1l0.1-0.7l0.1-0.1l0.1-0.1l-0.1-0.4v-0.1h0.2l0.2-0.1l0.1-0.1v-0.1l0.1-0.2l-0.1-0.3l-0.6-0.2
                v-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1h0.2l0.3,0.2h0.1l0.1-0.1h0.1h0.3l0.3-0.1l-0.1-0.2v-0.2v-0.1v-0.1l0.1-0.1v-0.3l0.2-0.2h0.1h0.3
                h0.1l0,0l0.5,0.4l0.4,0.3l0.4,0.2h0.5h0.6l0.9-0.1h0.7v-0.1l0.1-0.2l-0.1-0.2v-0.2l0.1-0.3l0.3-0.2l1-0.1l0.6-0.2l0.1-0.2l0.2-0.2
                h0.2l0.2,0.1l0.3,0.2l0.2,0.1l0.1-0.1l0.5-0.3l0.6-0.3l0.4-0.8v-0.1l0.4-0.1h0.6l0.3,0.1l0.2,0.1h0.4l0.5-0.2h0.2l0.2,0.1l0.2,0.1
                l0.1,0.1l0.1,0.2v0.1l0.1,0.1l0.1,0.1h0.1L537.2,308L537.2,308z"/>
            <linearGradient id="ID_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="196.0079" x2="0.1864" y2="196.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ID" class="stcustom0 st100" d="M820.1,493.4h-0.3h-0.1v-0.2l0.1-0.1l0.6-0.2l0.2-0.2l0.3-0.3l0.2-0.1h0.1l0,0v0.3l0.1,0.2v0.1
                L821,493l-0.3,0.3L820.1,493.4L820.1,493.4L820.1,493.4z M817.2,492.5L817.2,492.5l-0.4-0.1h-0.1l0.3-0.1l0.2-0.2h0.2h0.1v0.2
                L817.2,492.5z M821.5,491.7l-0.3,0.1v-0.2l0.2-0.3l0.2-0.1l0.1,0.1l0,0l-0.3,0.1L821.5,491.7z M811.9,489.1l0.1,0.1l0.5,0.2l0.1,0.1
                v0.2l0.1,0.1h0.2h0.2l0.2,0.1l0.1,0.1l0.2,0.2l0.2,0.3l0.2,0.2l0.1,0.2l-0.1,0.2l-0.3,0.3l-0.2,0.1h-0.2l-0.3,0.2l-0.1-0.1l-0.4-0.1
                l-0.3-0.1l-0.3-0.2l-0.2-0.2l-0.2-0.2l-0.3-0.1l-0.6-0.4h-0.4H810h-0.2l-0.8-0.2l0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.2l0.1-0.1
                l0.1-0.1l0.4-0.2h0.3h0.4h0.5l0.5,0.1l0.2-0.1l0.3-0.2l0.1,0.1L811.9,489.1L811.9,489.1z M825.7,488.2L825.7,488.2l0.5-0.2h0.1
                l0.1,0.1v0.2l-0.1,0.2H826v0.1v0.1v0.1l0.1,0.1l0.1,0.2l0.1,0.4l-0.2,0.2L826,490l-0.3,0.3l-0.4,0.4l-0.3,0.2l-0.3,0.3l-0.2,0.2
                l-0.3,0.1h-0.4l-0.6,0.3l-0.3,0.1h-0.3l-0.3-0.1l-0.1-0.1v-0.2l0.1-0.1l0.1-0.1l0.1-0.1l-0.3-0.2v-0.1l0.1-0.4l0.1-0.4l0.1-0.3
                l0.5-0.5l0.3-0.2l0.2-0.1v0.1l0.1,0.1h0.1h0.1h0.4h0.1l0.2-0.2l0.1-0.1l0.1-0.2v-0.1l0.4-0.1l0.2-0.1l0.2-0.2l0.5-0.3l0.1-0.1
                L825.7,488.2L825.7,488.2L825.7,488.2z M799.6,487.4L799.6,487.4l-0.3-0.1l-0.1-0.1l0.2-0.1h0.1l0.1,0.1V487.4z M820.2,486.7
                l-0.1,0.2h-0.2v-0.2l0.1-0.1l0.2-0.1h0.2h0.1v0.1L820.2,486.7L820.2,486.7L820.2,486.7z M810.4,487.3L810.4,487.3h-0.2V487l-0.1-0.2
                l0.1-0.1v-0.2v-0.1l0.1,0.1l0,0h0.1h0.1v0.1v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1L810.4,487.3L810.4,487.3z M821.2,486.2l-0.1,0.1h-0.8
                v-0.2l0.2-0.2l0.1-0.1l0.2-0.1l0.3,0.1L821.2,486.2L821.2,486.2z M802.4,486.9l-0.4,0.6l0.1,0.1l0.1,0.1l-0.6,0.1l-0.2-0.1h-0.1
                l-0.6-0.1l-0.4-0.1v-0.1v-0.1h0.1h0.3l0.1-0.1v-0.4v-0.5l0.4-0.4l0.2-0.2l0.3-0.1l0.7,0.2l0.1,0.1l0.1,0.1v0.1L802.4,486.9
                L802.4,486.9z M823.9,486.1l-0.2,0.2l-0.1,0.3l-0.1,0.1l-0.2,0.1l-0.1-0.3h-0.3l0.1-0.3l0.1-0.1h0.2l0.1,0.1l0,0l0.4-0.5h0.1h0.1
                v0.1L823.9,486.1L823.9,486.1z M822.9,486l-0.4,0.1l-0.2,0.4h-0.2l-0.1,0.2v0.1v0.1v0.1l-0.1,0.1l-0.2-0.1l-0.2,0.1h-0.1l-0.2-0.1
                H821h-0.1l0.3-0.3l0.4-0.2l0.1-0.1h-0.1h-0.1v-0.1h0.2h0.2l0.1,0.1h0.1l0.5-0.3l0.2,0.1L822.9,486L822.9,486L822.9,486z
                M864.9,486.3L864.9,486.3l-0.8-0.1l-0.1-0.1V486l0.2-0.1l0.2-0.2l0.2-0.1h0.1l0.3,0.5L864.9,486.3L864.9,486.3z M805,486.2
                L805,486.2l-0.2-0.1V486l-0.1-0.2v-0.2l0.2-0.1h0.3v0.1L805,486.2L805,486.2z M809.3,485.9L809.3,485.9l-0.1-0.1v-0.1h0.1h0.1
                l0.1,0.1v0.1H809.3L809.3,485.9z M824.7,485.6l0.1,0.2l0.2-0.1h0.2h0.5h0.4l0.2,0.1v0.3l-0.1,0.1l-1.8,0.3l-0.2-0.1l-0.1-0.1
                l0.2-0.3l-0.1-0.1l0.1-0.2l0.2-0.1L824.7,485.6L824.7,485.6z M833.8,485.5l0.5,0.1h0.3l0.1,0.1l-0.3,0.2l-0.6-0.2V485.5L833.8,485.5
                L833.8,485.5z M819.7,486.9l-0.4,0.1l-0.2,0.1l-0.2,0.1h-0.1h-0.3h-0.4h-0.3l-0.7,0.3h-0.3l-0.2,0.1l-0.1-0.1l-0.1-0.1h-0.2H816
                l-0.2,0.3l-0.4-0.1h-0.1l-0.1,0.1h-0.1h-0.2l-0.6-0.2l-0.6-0.1l-0.6,0.1l-0.6-0.1l-0.3,0.1l-0.3,0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2V487
                v-0.1l0.1-0.1l0.1-0.1v-0.1l0.1,0.1h0.1l0.4-0.2l0.4-0.2l0.3-0.1h0.2h0.2h0.2l0.2-0.1l0.3,0.2h0.1h0.4l0.3,0.1l0.3,0.2l0.4,0.2
                l0.3,0.2l0.2,0.1h0.2h0.1l0.2-0.1l0.2-0.1h0.2h0.3h0.1l0.2-0.1h0.2l0.1,0.1l0.5,0.4h0.2l0.3-0.1l0.1-0.1V487l0.1-0.1l0.1-0.1
                l0.1-0.1l0.4-0.1l0.3-0.1l0.2-0.2l-0.5-0.1l0.1-0.2l0.2-0.1h0.2l0.2,0.1l0.1,0.5l-0.1,0.1l-0.1,0.1l-0.1,0.1L820,487l0.1,0.2
                l-0.1,0.1L819.7,486.9L819.7,486.9z M842.4,486.1l-0.2,0.1l0.2-0.2l0.5-0.5l0.2,0.1h0.2l-0.4,0.2l-0.4,0.1L842.4,486.1z
                M806.9,486.1l0.1,0.1h0.1l0.3-0.2l0.2-0.1h0.2h0.2l0.2,0.1l0.1,0.2l0.1,0.1l0.1-0.2l0.1-0.1h0.1h0.2l0.2,0.1l0.2,0.3v0.3l0.1,0.1
                l0.1,0.1l0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1h-0.1l-0.1-0.1h-0.1h-0.2l-0.2,0.1v0.1h0.2l0.1,0.1v0.1h-0.1l-0.2-0.1h-0.2l-0.5,0.1
                h-0.1l-0.1-0.1v-0.3l-0.1-0.1l-0.4,0.4l-0.1,0.1h-0.2h-0.2l-0.6,0.2h-0.2h-0.2L805,488l-0.3,0.1h-0.2h-0.2h-0.2l-0.1,0.1l-0.3,0.1
                l-0.3-0.1l-0.2-0.1L803,488l-0.1-0.1v-0.2l0.1-0.2l-0.1-0.4l0.1-0.2l0.1-0.2l0.1-0.1h0.2l0.3-0.2l0.3-0.2h0.2l0.4,0.2h0.2h0.4
                l0.2,0.1l0.1,0.2l0.1,0.1l0.1,0.1l0.3,0.4h0.2l0.2,0.1l0.4-0.2h0.3l0.1-0.2l-0.2-0.2l-0.2-0.1h-0.1h-0.1h-0.1l-0.5-0.3l-0.1-0.2
                l-0.1-0.2v-0.2l0.3-0.1h0.1l0.6,0.1l0.1,0.1L806.9,486.1L806.9,486.1L806.9,486.1z M799.1,485.6l0.3,0.1l0.4,0.4v0.1l-0.1,0.1
                l-0.3,0.2l-0.6,0.3l-0.1,0.1l-0.1,0.3v0.1v0.1h-0.1h-0.1l-0.2-0.1l0.1-0.2v-0.2l-0.1-0.2l-0.1-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.1h-0.3
                l-0.1-0.1l-0.2-0.2l-0.1-0.1v-0.1v-0.1h0.1h0.3l0.6,0.2h0.3h0.2l0.4-0.3h0.1l0.4,0.1L799.1,485.6L799.1,485.6z M839.5,485.1
                l-0.2,0.3h-0.2l-0.3-0.3v-0.2v-0.1h0.1l0.4,0.1l0.1,0.2H839.5L839.5,485.1z M831,484.3v0.1v0.1h-0.3l-0.3,0.2l-0.2,0.2l-0.1,0.2
                l-0.4-0.1h-0.4l-0.2-0.1h-0.2l-0.2,0.1l-0.4,0.2h-0.1v-0.3l0.1-0.2l0.4-0.4l0.3,0.1h0.4l0.4-0.1l0.3-0.2l0.4-0.1l0.3,0.3L831,484.3
                L831,484.3z M832.7,484.1l-0.2,0.1l0.1-0.2v-0.2l0.3,0.1v0.1v0.1H832.7z M863.9,486l-0.7,0.4l-0.9-0.1H862l-0.5,0.1l-0.1-0.1
                l0.1-0.3l0.4-0.9l0.5-0.8l0.2-0.2l0.3-0.2l0.3-0.2l0.7-0.2h0.6l0.1,0.1l0.3,0.3l0.2,0.2l0.1,0.3l-0.3,0.5l-0.3,0.5l-0.5,0.4
                L863.9,486z M843.7,485.2L843.7,485.2h-0.4h-0.2V485l-0.1-0.2l0.1-0.2v-0.3h0.1v-0.2l0.2-0.4l0.1-0.1h0.1l0.2-0.2l0.1-0.1l0.1-0.2
                l0.1-0.1v-0.2l0.1-0.1l0.2-0.1l0.2,0.1l0.1,0.2l-0.3,0.2l0.1,0.5l-0.2,0.5l-0.1,0.2l-0.2,0.1l-0.1,0.1l-0.3,0.3l-0.1,0.2
                L843.7,485.2L843.7,485.2L843.7,485.2z M845.5,483v0.1V483l-0.3-0.1H845h-0.1l-0.1-0.1h0.5L845.5,483L845.5,483z M796.2,482.8v0.1
                h-0.1l-0.1-0.2h0.1h0.1h0.1L796.2,482.8z M836.2,482.9l-0.1,0.1l-0.2-0.1l-0.1-0.1l0.1-0.1h0.1l0.1,0.1v0.1v0.1L836.2,482.9z
                M814,482.7h-0.3h-0.1v-0.3l0.3,0.1h0.1V482.7L814,482.7z M794.6,482.7L794.6,482.7h-0.5l-0.3,0.2l-0.2,0.1h-0.8h-0.1h-0.1l0,0h-0.2
                l-0.8-0.2l-0.1-0.2l0.1-0.2l0.3-0.3l0.6-0.1h2.5l0.3,0.2v0.1l-0.6,0.2L794.6,482.7z M798.9,482.3h-0.2l-0.2-0.1v-0.1l0.1-0.1
                l0.3-0.1h0.2l0.2,0.1l0.1,0.1l0.1,0.1h-0.3L798.9,482.3z M853.1,481.7L853.1,481.7L853.1,481.7l-0.1-0.1l0.1-0.2l0.1-0.1h0.1v0.1
                L853.1,481.7L853.1,481.7z M770.5,481.4l-0.2,0.1l-0.1-0.1l-0.1-0.1l0.2-0.2h0.1h0.1v0.1V481.4L770.5,481.4z M853.2,481.1H853
                l-0.1-0.2l0.1-0.1l0.1,0.1L853.2,481.1L853.2,481.1z M853.5,480.8L853.5,480.8l-0.1-0.1l0.1-0.1l0.1-0.1h0.1L853.5,480.8z
                M852.7,480.8v0.2v0.2l-0.3,0.2l-0.2,0.5l-0.1,0.1l-0.3,0.2l-0.3-0.2l-0.1-0.2l0.1-0.8h0.1h0.1v-0.1l-0.2-0.1v-0.5v-0.2h0.1l0.2,0.1
                l0.2,0.3l0.3,0.2L852.7,480.8L852.7,480.8z M776.4,479.6l0.3,0.3l0.2,0.2l0.3,0.1h0.3h0.3l0.4,0.1l0.4,0.1h0.2l0.2-0.1h0.1l0.1,0.1
                l0.2,0.3l0.3,0.2l0.1,0.1l0.2,0.6l0.2,0.2h0.3h0.3h0.3l0.8,0.1h0.3l0.3-0.1l0.2,0.1l0.7,0.2h0.3h0.4h0.4h0.2l0.2,0.1h0.1h0.2
                l0.3-0.1l0.2-0.3l0.1-0.3l0.1-0.3l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.4l0.1,0.1l0.4,0.6h0.1h0.5h0.1l0.3-0.1h0.2l0.3,0.1l0.1,0.1
                l0.1,0.1l0.7,0.1l0.3,0.2h0.1h0.5h0.3l0.3,0.1l0.1,0.3l0.1,0.4l0.1,0.1l0.3,0.1l0.1,0.1v0.4v0.3l0.6,0.3l0.7,0.2h0.7l0.7-0.1
                l0.4-0.1l0.5-0.1h0.1l0.9,0.4l0.1,0.1l0.1,0.3v0.3l-0.2,0.7v0.2v0.2l0.2,0.4l0.1,0.1l0.3,0.2v0.1v0.1l-0.3-0.1l-0.2-0.1l-0.1-0.2
                l-0.2-0.1h-0.3l-0.6-0.2l-0.7-0.3l-1.2-0.5h-0.3l-0.3,0.1l-0.3,0.1l-0.4,0.1h-0.3h-0.3l-0.7-0.1l-0.7-0.1l-1.7-0.1l-0.5-0.1
                l-0.8-0.1l-0.6-0.1l-0.6-0.1l-1.6-0.7l-0.5-0.2l-1.6-0.3H782h-0.6l-0.4-0.1h-0.3l-0.5,0.1l-0.1,0.1l-0.2,0.2h-0.3h-0.3l-0.9-0.2
                l-0.3-0.1l-0.3-0.1l-0.3-0.2l-0.1-0.1l-0.7-0.2l-0.6-0.1l-1.2-0.1l-0.3-0.1l-0.2-0.1l-0.1-0.2v-0.2l0.1-0.2l0.1-0.2l0.1-0.2
                l-0.9-0.4l-0.7-0.2h-0.3h-0.3h-0.3h-0.3l-0.2-0.1h-0.2h-0.2h-0.1v-0.2l0.1-0.1l0.2-0.2h0.1v0.2l0,0l0.2,0.1h0.1l0.3-0.3l0.1-0.2
                l0.1-0.4l0.1,0.1h0.1l0.1-0.1l0.2-1l0.2-0.3l0.3-0.2l0.1-0.1l0.3,0.1l0.5,0.1l0.3,0.1h0.3h0.3l0.4,0.2h0.1l0.2-0.1l0.2-0.2l0.1-0.3
                l0.3,0.1l0.5,0.1L776.4,479.6L776.4,479.6z M823.2,479.5v0.1l-0.1-0.2l-0.1-0.1v-0.2l0.1,0.1L823.2,479.5L823.2,479.5z M813.4,480.4
                l-0.2,0.5l-0.1-0.2v-0.4l-0.1-0.2v-0.2v-0.6l0.1-0.3l0.2,0.4v0.2L813.4,480.4L813.4,480.4z M791.4,479l-0.1,0.1H791V479l0.2-0.2h0.1
                l0.1,0.1V479L791.4,479z M847.8,479.2l-0.2,0.3l-0.1-0.1h-0.1v-0.2v-0.3l-0.1-0.4h0.2l0.1,0.1h0.1l0.2,0.4L847.8,479.2L847.8,479.2z
                M853.3,478.8v0.1v0.2v0.2l-0.1,0.2l0.1,0.3v0.1v0.2v0.1l-0.1,0.3l-0.1,0.1H853l-0.1,0.1l-0.6-0.1l-0.2-0.2l-0.2-0.2v-0.1l-0.2-0.2
                l-0.1-0.1v-0.1h0.2h0.2v-0.1l0.1-0.4l-0.3-0.2l-0.1-0.1l0.1-0.1l0.3,0.1l0.3-0.4l0.1-0.1v-0.2h0.2h0.1l0.1,0.2v0.1v0.1l0.2,0.1
                L853.3,478.8L853.3,478.8z M848.2,479.3l-0.2,0.2l0.2-0.6v-0.3l0.2-0.2l0.3-0.9h0.1l0.1,0.1l-0.1,0.6l-0.4,0.4L848.2,479.3z
                M762.4,478.1h-0.2l-0.4-0.3l-0.1-0.1l0.1-0.1h0.1l0.5,0.2l0.1,0.1L762.4,478.1L762.4,478.1z M822.1,477.5v0.3H822l-0.1-0.1v-0.1
                v-0.1v-0.1L822.1,477.5L822.1,477.5z M817.6,478l-0.2,0.1l-0.3-0.3l-0.1-0.3v-0.1l0.1-0.1l0,0v-0.1l0.1-0.1h0.1l0.1,0.2h0.1l0.1,0.2
                L817.6,478z M819.3,477.5l-0.1,0.2l-0.2,0.1h-0.1h-0.1l-0.2-0.1l-0.1,0.1h-0.2l-0.1-0.2l0.1-0.5l0.2-0.2v-0.2l-0.2-0.4l0.1-0.2
                l0.4-0.2l0.4-0.2h0.1l0.1,0.2l0.1,0.7l-0.4,0.6L819.3,477.5L819.3,477.5z M820.8,475.5l0.1,0.6v0.2l-0.2-0.2h-0.1l0,0h-0.1h-0.1
                l-0.1,0.2v0.2l-0.1,0.1v0.5v0.1l0.1-0.1h0.1l0.3,0.2l0.1,0.1v0.2l-0.2,0.2l-0.2,0.1l-0.2-0.1l0,0l-0.1,0.1L820,478v0.1l-0.2,0.3
                l-0.1,0.1l-0.2-0.1l-0.1,0.1h-0.1l-0.2-0.3V478l0.2-0.2v-0.1l0.1-0.1l0.2-0.2l0.1-0.1v-0.1l0.1-0.4v-0.1v-0.2l0.1-0.3v-0.6l0.3-0.5
                l0.3-0.1h0.1v0.1L820.8,475.5L820.8,475.5z M850,474.7l0.1,0.1l-0.3-0.1l-0.5-0.2v-0.2l0.4,0.2L850,474.7L850,474.7z M821,474.3
                l-0.3,0.3h-0.2l-0.2-0.2l-0.1-0.2v-0.1l0.2-0.1h0.5l0.1,0.1V474.3L821,474.3z M835.9,472.8l-0.5,0.1l0.1-0.3l0.1-0.1l0.2,0.1
                L835.9,472.8z M835.1,473.1l-0.1,0.1l-0.2,0.1h-0.1v-0.1v-0.1h-0.1l-0.2,0.1l-0.2,0.2l-0.1-0.1v-0.1v-0.1l0.2-0.2h0.3l0.4-0.2h0.2
                v0.1l-0.1,0.1v0.1L835.1,473.1L835.1,473.1z M836.4,472.7v0.1l0,0h-0.2h-0.1l-0.1-0.2l0,0l0.2,0.1H836.4L836.4,472.7L836.4,472.7z
                M801.8,472.5l-0.1,0.5l-0.2-0.3L801.8,472.5v-0.2V472.5L801.8,472.5z M833.2,472.1l0.1,0.1l-0.3-0.1l-0.1-0.1l0.1-0.1h0.1
                L833.2,472.1z M801.5,473.6l-0.6,0.5l-0.1-0.1l0.1-0.5l-0.2-0.3V473l0.1-0.4l0.2-0.3l0.3-0.2h0.1v0.4l0.1,0.2v0.1v0.1l0.1,0.5
                L801.5,473.6v0.1V473.6L801.5,473.6z M831.1,471.4l0.5,0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.1l0.2,0.1v0.2v0.5H832l-0.2,0.1l-0.4,0.3
                l-0.2,0.1H831l-0.2,0.1h-0.2l-0.4-0.1l-0.4-0.2l-0.6-0.3l-0.1-0.1l-0.1-0.2l-0.3-0.3l-0.1-0.2v-0.5l0.1-0.1l0.1-0.1l0.4,0.1l0.2-0.1
                l0.7-0.1h0.7L831.1,471.4z M834.3,471l-0.1,0.2H834l0,0l0.3-0.1V471L834.3,471z M775.1,471.2L775.1,471.2h-0.2l-0.1-0.1l-0.1-0.1
                v-0.1l0.2-0.1l0.3,0.1L775.1,471.2L775.1,471.2z M776.7,470.9l-0.1,0.1l-0.1-0.1v-0.1v-0.1h0.2h0.1L776.7,470.9z M839.2,470.8
                l0.6,0.3h0.3h0.6h0.2l0.5,0.4l0.2,0.3v0.2l0.1,0.2l0.1,0.1h0.2l0.2,0.3v0.1l-0.2,0.8l-0.6-0.3l-0.6-0.3l-0.3-0.1l-0.7-0.3l-0.1-0.1
                l-0.1-0.1l-0.3-0.2h-0.6h-0.2h-0.1v0.1v0.2l-0.2,0.1l-0.4-0.1h-0.3l-0.3-0.1l-0.4-0.1v-0.1V472v-0.1h-0.1h-0.2l-0.2,0.1l-0.1,0.1
                l-0.2,0.3l-0.1,0.1h-0.3h-0.1l-0.1-0.1l-0.4-0.5l-0.1-0.1l-0.1-0.1h-0.1l-0.1,0.1l-0.1,0.2v0.2v0.1l-0.2,0.3l-0.1,0.2l0,0l0.1-0.3
                v-0.2l-0.1-0.2l-0.1-0.2l0.7-0.8l0.2-0.2l1-0.1h0.6h0.3l0.2-0.1h0.2v0.2l0.1,0.1h0.2l0.3-0.1l0.3-0.2l0.2-0.1h0.2h0.2h0.2
                L839.2,470.8L839.2,470.8z M756.9,471.7l0.1,0.4l-0.3-0.3v-0.2v-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.5l0.1-0.1h0.1l0.6,0.6v0.1v0.2
                l-0.1,0.1L756.9,471.7L756.9,471.7z M778.8,471.2v0.3l-0.1,0.1l-0.2,0.1l-0.1,0.1h-0.2v-0.2l-0.1-0.1l-0.2-0.1h-0.1v0.2l-0.5,0.1
                h-0.1l0.1-0.2l-0.1-0.2v-0.2V471l-0.1-0.1l0.1-0.2v-0.2l0.1-0.2l0.1-0.5l0.5-0.1l0.1,0.1l0.6,0.1l0.4,0.3l0.2,0.4L778.8,471.2
                L778.8,471.2z M756.3,470.5l-0.2,0.2h-0.3l-0.1-0.1v-0.3l-0.1-0.1l0,0l0.1-0.2h0.1L756.3,470.5L756.3,470.5z M852.2,468.7
                L852.2,468.7l-0.1-0.1v-0.2h0.1h0.1l0.1,0.1L852.2,468.7L852.2,468.7z M755.3,469.4L755.3,469.4l-0.4-0.2l-0.2-0.1l-0.2-0.3l0.1-0.1
                v-0.2v-0.1h0.1l0.2,0.1l0.1,0.3l0.2,0.3L755.3,469.4L755.3,469.4z M828.9,469.7L828.9,469.7l-0.2-0.1l-0.1-0.4l-0.1-0.1l-0.1-0.4
                v-0.1l0.1-0.2h0.1l0.1,0.1v0.4l0.3,0.6L828.9,469.7L828.9,469.7z M822.7,468.3v0.1h-0.2l-0.1-0.2v-0.1L822.7,468.3z M828.8,467.8
                l0.9,0.1l-0.1,0.1l-0.9,0.2h-0.3l-1,0.1h-0.1v-0.2l-0.1-0.1l0.2-0.1h0.2h0.6L828.8,467.8z M820.7,467.9l-0.2,0.2V468v-0.1l0.1-0.1
                h0.1L820.7,467.9z M840.9,467.5v0.2l0.2,0.2l-0.1,0.2h-0.1v0.1l0.1,0.1l-0.1,0.1h-0.1l-0.2,0.1l-0.1,0.1h-0.3l-0.1-0.1l-0.6-0.1
                l-0.4-0.3V468l0.7-0.3l0.3-0.1h0.3L840.9,467.5L840.9,467.5L840.9,467.5z M825.8,467.6l0.3,0.1h0.1l0.1-0.1h0.1l0.1,0.1v0.2h0.2
                l0.1,0.1l0,0v0.2h-0.5l-0.4,0.2l-0.5-0.1l-0.5,0.2l-0.3,0.1h-0.3l-0.2-0.4l0.1-0.5l0.1-0.1h0.2h0.5L825.8,467.6L825.8,467.6z
                M855.3,467.3l1.1,0.1h0.3l0.6,0.1l0.5,0.2h0.9l0.3,0.1l0.2,0.1l-0.5,0.1l-0.2,0.1H858h-0.4l-0.3,0.1l-0.1-0.1l-0.4-0.1l-0.5-0.2
                l-1.1-0.2L855.3,467.3L855.3,467.3L855.3,467.3z M780.9,467.3l-0.3,0.1l-0.1-0.3l0.2-0.1l0.2,0.1L780.9,467.3z M772.7,467.5l0.1,0.2
                l0.1,0.2l0.1,0.2l0.1,0.9l0.4,0.8l1.3,0.3l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.5v0.1l0.1,0.2v0.2h-0.2h-0.2l-0.1-0.1l-0.1-0.1
                l-0.1-0.1h-0.2l-0.3-0.2l-0.3-0.1l-0.4-0.1l-0.2-0.2l-0.1-0.3l0.1-0.4l-0.1-0.1l-0.1-0.1l-0.2-0.3l-0.1-0.4l-0.2-0.1l-0.3-0.1
                l-0.1-0.1l-0.6,0.1h-0.1l-0.1-0.1l-0.3-0.1v-0.2l0.1-0.2l0.4-0.2l0.2-0.1v-0.2l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1l0.4-0.1l0.2,0.2
                l0.1,0.2l0.1,0.1l0.2-0.2l-0.1-0.3l0.3-0.1h0.3l0.2,0.1l0.1,0.1L772.7,467.5L772.7,467.5z M822,467.6h-0.2l-0.1-0.1v-0.4l0.1-0.1
                h0.1v0.1l0.1,0.1l0.1,0.1L822,467.6L822,467.6z M834.8,467.5l-0.2,0.1h-0.1l-0.4-0.1h-0.5l-0.5,0.1l-0.3-0.1l-0.2-0.1v-0.2l0.2-0.4
                l0.4-0.3l0.2-0.1l0.3,0.1l0.5,0.2l0.4,0.3l0.3,0.2L834.8,467.5L834.8,467.5z M820.9,466.1l0.1,0.2l-0.1,0.2l0.1,0.3l0.3-0.4h0.3
                l0.2,0.1l0.1,0.1l0.1,0.1l-0.1,0.3l-0.2,0.1h-0.2l-0.1-0.2h-0.2l-0.1,0.4v0.1H821l-0.1-0.1v-0.1l0.2-0.2l-0.1-0.5l-0.1,0.1l-0.3,0.5
                l-0.3,0.2l-0.1-0.1l-0.1-0.3l0.1-0.4l0.2-0.3h0.2l0.5-0.1L820.9,466.1L820.9,466.1z M783,466.1l-0.6,0.3h-0.1l-0.1-0.1l0.1-0.5
                l0.1-0.2h0.4l0.2,0.1l0.1,0.1v0.2v0.1H783L783,466.1z M853.9,465.9l-0.1,0.1l-0.2-0.1l-0.1-0.2l0.1-0.2l0.2-0.1l0.1,0.1v0.1h0.1
                L853.9,465.9L853.9,465.9z M753.4,467.8h-0.3l-0.6-0.3l-0.1-0.1v-0.2l-0.5-0.8l-0.1-0.2l0.2-0.6l0.5-0.2l0.2,0.1l0.1,0.3l0.3,0.5
                l0.1,0.3l0.1,0.1v0.1v0.1l0.2,0.3l0.2,0.2v0.3L753.4,467.8L753.4,467.8z M842.7,466.5L842.7,466.5h-0.4l-0.2-0.2l-0.1-0.2l-0.1-0.2
                v-0.2l-0.1-0.2v-0.1l0.6-0.2l0.1,0.1h0.3l0.1,0.1l-0.1,0.6L842.7,466.5L842.7,466.5z M842.5,465l-0.1,0.1l-0.1,0.1l-1.2,0.2l0.1-0.1
                l0.1-0.1l0.1-0.1h0.1h0.1h0.1h0.1h0.1h0.2h0.3V465H842.5L842.5,465z M855,464.6l0.6,0.1h0.2l0.2,0.1l0.3-0.1h0.1l0.5,0.4l0.2,0.3
                l0.4,0.2l0.3,0.1l-0.2,0.2l-0.4,0.1H857l-0.3-0.1h-0.2l-0.2-0.2v-0.3l-0.2-0.6l-0.3,0.2l-0.3-0.3h-0.1l0,0l-0.1-0.1l-0.1-0.2
                L855,464.6L855,464.6z M832.4,465L832.4,465h-0.3H832l0.1-0.4h0.1L832.4,465L832.4,465L832.4,465z M841.7,464.3h-0.2l-0.3-0.1
                l0.2-0.1h0.1l0.1-0.1h0.1l0.1,0.1l0.1,0.1L841.7,464.3L841.7,464.3z M817.1,464l0.1,0.1l-0.1,0.1H817h-0.3h-0.1l-0.1,0.1h-0.1v-0.1
                l0.2-0.2h0.1H817.1L817.1,464z M849.7,464.8h0.7h0.4h0.3l0.1,0.1l0.2,0.2l-0.1,0.1v0.3l0.1,0.3l0.2,0.3l0.2,0.3v0.1l-0.1,0.3
                l-0.1,0.2l-0.2,0.3l-0.1,0.3l0.1,0.3v0.3v0.3v0.3l0.1,0.3l0.5,0.9l0.3,0.6l0.1-0.3v-0.4l0.1-0.1l0.1-0.1l0.2,0.1v0.1v0.3l0.1,0.6
                h0.2l0.2-0.1v0.2v0.4l0.1,0.3l0.1,0.1l0.3,0.2h0.2l0.4,0.1h0.3l0.3-0.1l0.2-0.2l0.2-0.2l0.6-0.5l0.2-0.3l0.2-0.4l0.1-0.1l0.6-0.4
                l0.1-0.1l0.1-0.3l0.1-0.3l0.1-0.1l0.6-0.1l0.6-0.1l0.6-0.3l0.3-0.2v-0.1l-0.1-0.3v-0.1l0.1-0.1l0.6-0.3l0.7-0.3l0.5-0.2h0.3l0.3,0.2
                l0.3,0.2l1.5,0.5l0.2,0.2l0.2,0.2l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.2l0.6,0.3l0.9,0.4h0.2h0.8l0.1,0.1l0.1,0.1h0.1l0.9,0.1l0.1,0.1
                l0.1,0.1l0.1,0.3h0.6v0.5v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.2l-0.1,0.3l-0.2,0.4v0.4l0.2,0.3
                l0.2,0.2v0.5v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6v0.6l0,0l-0.1-0.1l0.2-1.2l-0.4-0.4l-0.2-0.3l-0.3-0.3l-1.1-0.9l-0.3-0.3l-0.1-0.1
                v-0.1l0.1-0.2l0.2-0.5l-0.2,0.3l-0.3,0.2h-0.4l-0.4,0.1l-0.4,0.1H866l-0.2-0.1l-0.1-0.2l-0.1-0.2v-0.2l-0.2,0.3l-0.3,0.2l-0.4,0.3
                l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.4l0.2-0.2l0.1-0.4l0.1-0.1v-0.1l-0.1-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.1
                l-0.2-0.1l-0.1-0.1l0.1-0.1h0.2h0.2l0.3,0.1h0.1l0.3-0.1h-0.2h-0.2l-0.6-0.2l-0.4-0.2l-0.3-0.4v-0.1l0.2-0.1l0.5-0.1l-0.2-0.2
                l-0.2-0.2L864,481l-0.2-0.2l-0.3-0.3l-0.2-0.3l-0.2-0.6l-0.2-0.5v-0.2l0.2-0.1h-0.3l-0.2-0.1l0.1-0.2l0.3-0.1h-0.2l-0.2,0.1h-0.1
                h-0.1l-0.1-0.1v-0.1v-0.2v-0.2l-0.2-0.1l-0.2-0.2l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.2l-1.3-0.7l-0.1-0.2l-0.1,0.1h-0.1l-0.1-0.1
                l-0.2-0.1h-0.2l-0.2-0.1H859h-0.2l-0.7-0.2l-0.6-0.3l-0.5-0.1l-0.3-0.2l-0.3-0.1l-0.7-0.1L855,475h-0.3h-0.2h-0.2l-1.2-0.7l-0.2-0.3
                v-0.2l0.1-0.2h0.5h-0.4H853l-0.3,0.1h-0.2l-0.2-0.1l-0.2-0.1l-0.4,0.1l-0.2-0.2l-0.1-0.2l-0.1-0.1h-0.1l-0.2,0.1h-0.2l-0.1-0.1
                l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.3v-0.5v-0.2l0.2-0.3l0.2-0.3h-0.2l-0.2,0.1l-0.1,0.1v0.1v0.5l-0.2,0.1h-0.2l0.1,0.3
                l-0.1,0.3l-0.3,0.3v0.1v0.2l-0.1,0.2l-0.4,0.5h-0.1h-0.3l-0.3,0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.1-0.3l0.3-0.4l-0.1-0.4
                l-0.2-0.3l-0.6-0.5l-0.6-0.4l-0.3-0.1h-0.4h-0.1l-0.1-0.2l-0.1-0.2l0.3-0.1l0.5-0.2h0.3l0.7,0.1l0.2,0.1l0.2,0.1l0.5-0.4l0.4-0.5
                l0.2-0.1h0.2h0.2l0.4,0.2l0.3,0.1h0.2l0.1,0.1l0.1,0.1v-0.2l0.1-0.3l0.2-0.1h0.1l0.1-0.1v-0.2h-0.3l0.2-0.2l0.1-0.1l0.1-0.1v-0.1
                l-0.6,0.2l-0.6,0.1h-0.4h-0.4l-0.7,0.2h-0.3l-0.7-0.1l-0.4-0.1l-0.3,0.1H846l-0.3-0.2l-0.2-0.2l-0.1-0.2l-0.2-0.1l-0.1-0.2l-0.2-0.6
                v-0.4h-0.3h-0.3l-1.2-0.4l-0.1,0.1l0.2,0.3H843h-0.2l-0.2-0.1v-0.1l0.1-0.3l0.1-0.1l0.2-0.1l0.1-0.1l0.2-0.4v-0.2v-0.2v-0.1l0.1-0.1
                l0.5-0.1l1-0.2l0.2-0.1l0.2-0.2l0.2-0.1l0.1-0.1l0.1-0.1l0.7-0.3h0.3h0.3l0.6,0.2l0.6,0.3l0.5,0.3L849.7,464.8L849.7,464.8z
                M768.3,463.8l0.3,0.3l0.1,0.1l-0.1,0.1l-0.1,0.2h-0.1l-0.2-0.1l-0.1,0.2l-0.1-0.3l-0.2-0.2l0.1-0.2l0,0h0.1l0.1,0.1L768.3,463.8
                L768.3,463.8z M833.1,463.7l0.3,0.4l-0.2,0.4l0.2,0.2h0.4l0.1,0.1l0.1,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.3-0.1l-0.1-0.1h-0.1l-0.4,0.1
                h-0.1l-0.1-0.2l0.1-0.3l-0.2-0.1l-0.2-0.3v-0.1l0.1-0.2v-0.2h0.1l0.2,0.2l0.1-0.2l0.1-0.1L833.1,463.7z M832.2,464.2l-0.2,0.1h-0.2
                V464l0.1-0.4l0.2-0.1l0.3,0.1l-0.1,0.1l0.1,0.2L832.2,464.2L832.2,464.2z M766.2,463.8l-0.4,0.1l-0.4-0.1v-0.2l0.2-0.2h0.2l0.3,0.1
                L766.2,463.8L766.2,463.8L766.2,463.8z M842.2,462.8l0.5,0.1h0.1l0.7,0.3l0.1,0.2v0.1l0.1,0.1l-0.2,0.2h-0.1l-0.1-0.1h-0.2l-0.3,0.1
                l-0.2-0.1l-0.1-0.2l-0.2-0.1l-0.4-0.4h-0.2v0.2l0.1,0.1l0.2,0.3h0.1h0.1l0.2,0.1v0.2L842,464l-0.1-0.1v-0.3l-0.2,0.1l-0.1,0.1h-0.1
                l-0.2-0.3h-0.4l-0.3-0.1l0.1-0.2v-0.1l0.2-0.1l0.2,0.1l0.2-0.1h0.1l0.1-0.1h0.4L842.2,462.8z M751.4,464.3l-0.2,0.1l-0.3-0.1
                l0.1-0.2v-0.2l0.2-0.2l0.1-0.2l-0.3-0.6h0.1l0.1,0.1l0.2,0.4l0.2,0.3l-0.1,0.3L751.4,464.3L751.4,464.3z M769.2,463.3L769.2,463.3
                l0.2-0.1l0.2,0.2l0.1,0.1l0.2,0.1h-0.2l-0.1,0.1l0,0l-0.6-0.3l-0.4,0.1l-0.3-0.1l-0.1-0.1l0.1-0.2l0.1-0.4l0.3,0.1l0.1,0.1l0,0
                l0.2,0.1L769.2,463.3L769.2,463.3z M838.7,463.3L838.7,463.3l-0.2-0.2l-0.3-0.2l-0.2-0.3l0.7,0.5V463.3z M832.8,462.8L832.8,462.8
                L832.8,462.8h-0.2l0.1-0.3v-0.1l0.1,0.2L832.8,462.8L832.8,462.8z M768.9,462.7L768.9,462.7L768.9,462.7h-0.2h-0.1l-0.3-0.4h0.1
                l0.3,0.3L768.9,462.7L768.9,462.7z M765,461.3h-0.3h-0.1V461l0.1-0.2h0.1l0.2,0.2V461.3L765,461.3z M832.7,461L832.7,461L832.7,461
                l-0.1-0.1l0.1-0.2h0.1l0,0v0.1L832.7,461L832.7,461z M832.6,460.6L832.6,460.6l-0.2,0.1l-0.1-0.1v-0.1l0.1-0.1h0.1L832.6,460.6
                L832.6,460.6z M765.4,461L765.4,461l-0.3-0.3l0.1-0.2l0.1-0.1l0.1,0.1l0.1,0.1l0.1,0.2L765.4,461L765.4,461z M766.5,460.6
                L766.5,460.6l-0.2-0.1v-0.1v-0.1l0.2,0.1V460.6z M767.6,460.5l-0.2,0.1l-0.2-0.3v-0.1l0,0l0,0l0.1,0.1h0.2L767.6,460.5L767.6,460.5z
                M764.2,460.7l-0.1,0.1l-0.1-0.1l-0.5-0.1h-0.2l-0.5-0.1l-0.1-0.1l-0.1-0.1v-0.2l0.1-0.1v-0.3l0.1-0.1l0.2,0.2l0.3,0.2l0.2,0.1
                l0.5,0.2l0.2,0.1L764.2,460.7z M765.4,459.9v0.2h-0.2l-0.1-0.2l0.1-0.1h0.1L765.4,459.9L765.4,459.9L765.4,459.9z M764.6,460.4
                L764.6,460.4l-0.2,0.1l-0.1-0.1l-0.2-0.2l-0.2-0.2l-0.3-0.1h-0.2h-0.1l0.1-0.3h0.2l0.6,0.3l0.2,0.1L764.6,460.4L764.6,460.4z
                M767,459.5l0.2,0.1l0.1-0.1v0.1v0.1l-0.2,0.3l-0.3-0.1l-0.1-0.1v-0.1l0,0v-0.1h0.1L767,459.5L767,459.5z M768.6,459.4v0.2l0.2,0.1
                v0.2v0.2l-0.2,0.3l-0.1,0.1l-0.2-0.1l-0.1-0.1v-0.1v-0.1l-0.1-0.1l0.1-0.1l0,0l-0.4,0.1h-0.1v-0.2v-0.1l0.3-0.2h0.2h0.2L768.6,459.4
                L768.6,459.4z M762.6,460.1l-0.1,0.1l-0.2-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.3v-0.2l0.1-0.1h0.1l0.2,0.1l0.2,0.2l0.1,0.1v0.2l-0.1,0.2
                V460.1L762.6,460.1z M748.7,458.7l0.6,0.8l0.2,0.1l0.3,0.4l0.1,0.1l-0.1,0.2l-0.1,0.7l-0.2,0.2l-0.4-0.1V461l-0.2-0.5l-0.4-0.3h-0.2
                l-0.1-0.3l-0.2-0.4l-0.6-0.7h0.5l0.2-0.2l0.1-0.1l0,0L748.7,458.7L748.7,458.7z M762.7,458.8v0.4l-0.2-0.1l-0.2-0.1l-0.3-0.1h-0.3
                l-0.2-0.1l-0.2-0.2v-0.1v-0.1h0.1l1.2,0.3L762.7,458.8z M825.6,460l-0.5,0.5l-0.2,0.2l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.1,0.1l-0.3,0.1
                h-0.2h-0.3l-1,0.2h-0.3h-0.3h-0.6h-0.1l-0.2-0.2l-0.3-0.2h-0.2h-0.2h-1.8h-0.6l-0.6,0.1H817l-0.3-0.1h-0.2h-0.3l-1.2,0.1h-0.3
                l-0.6-0.2h-0.3l-0.3,0.1l-0.3,0.2l-0.1,0.1l-0.3,0.4l-0.2,0.3l-0.1,0.4l-0.1,0.4l-0.1,0.3v0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.4,0.6
                l0.1,0.1l0.4,0.2l0.3,0.2l0.2,0.6l0.2,0.3h0.2h0.2h0.3l0.3,0.1l0.3-0.2l0.2-0.4l0.2-0.3l0.4-0.5l0.2-0.2l0.2-0.1h0.2l0.1,0.1
                l0.2,0.1l0.3,0.1h0.3l0.3-0.2l0.1-0.1l0.1-0.1l0.3-0.1h0.7h0.4h0.6v-0.1l-0.1-0.1v-0.1l0.1-0.1l0.4-0.1l0.4-0.1l0.3,0.1l0.3,0.2
                l0.1,0.2v0.2l-0.1,0.5l-0.1,0.1l-0.2,0.1l-0.2-0.1l-0.2-0.3l-0.3-0.1l-0.4,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.3l-0.2,0.3l-0.4,0.5
                l-0.5,0.4l-0.2,0.2l-0.3,0.1l-0.8,0.3l-0.2,0.2l-0.2,0.3l-0.2,0.1h-0.2h-0.2l-0.3-0.2l-0.1,0.1v0.2l0.2,0.1l0.3,0.2l0.2,0.3l0.1,0.1
                l0.3,0.1l0.1,0.1l0.2,0.3l0.3,0.6l0.1,0.3l0.2,0.3l0.6,0.4v0.1v0.1v0.1l0.2,0.3v0.2l-0.2,0.2v0.3l-0.1,0.4v0.1v0.1l0.1,0.1l0.1,0.1
                h0.2l0.1,0.1l0.3,0.3l0.1,0.1l0.1,0.1v0.2l0.1,0.1l0.1,0.2h0.2l0.1-0.1l0.1-0.1h0.1l0.1,0.1v0.2v0.2v0.3l-0.1,0.1h-0.2l-0.3-0.1v0.1
                v0.1h-0.1h-0.2h-0.4l-0.7,0.2l-0.3,0.1l-0.2,0.2v0.2l0.1,0.3l-0.1,0.1h-0.3l-0.5-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.3l0.2-0.8
                v-0.1l0.1-0.1l0.1-0.2V474l-0.2-0.2l-0.3-0.1l-0.3-0.2l-1.1-1l-0.1-0.1v-0.2V472l0.4-0.6v-0.1v-0.4v-0.4v-0.4l-0.2-0.2l-0.3-0.1
                h-0.3l-0.3,0.1l-0.3,0.2l-0.6,0.4l-0.2,0.2v0.3l0.1,0.3l0.2,0.3l0.1,0.3l0.1,1v0.1l-0.1,0.3l-0.1,0.7l0.1,0.9l0.1,0.6v0.3l-0.3,0.7
                l-0.1,0.4v0.1l0.3,0.7l0.1,0.3v0.3l-0.3-0.1h-0.2h-0.2h-0.3H812l-0.1,0.1l-0.2,0.2l-0.2,0.1h-0.1l-0.4-0.2l-0.3-0.2l-0.2-0.3v-0.3
                l0.1-0.3l0.1-0.3l0.2-0.6V476l0.1-0.3l0.1-0.3v-0.3V474v-0.1l-0.3-0.6v-0.1V473v-0.2v-0.1l-0.1-0.1l-0.1-0.1h-0.2h-0.3l-0.7,0.2
                l-0.2-0.2l-0.2-0.2l-0.1-0.3l-0.1-0.3v-0.3l0.1-0.3l-0.1-0.2l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.1-0.1l0.4-0.3l0.1-0.3v-0.3
                l0.1-0.3l0.2-0.3l0.2-0.3l0.1-0.3l-0.1-0.5v-0.2v-0.2V467l0.1-0.5l0.4-0.9l0.4-0.5l0.2-0.1l0.2,0.2l0.2,0.3v-0.2v-0.2l-0.2-0.6
                l-0.1-1.1v-0.1h0.1l0.1-0.1l0.1-0.2l-0.2-0.4v-0.1l0.3-0.6l0.2-0.2l0.1-0.1l0.1-0.4l0.1-0.1l0.2-0.1l0.2-0.2l0.1-0.3h0.1h0.1
                l0.1,0.3l0.1,0.1l0.3,0.1l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.3l0.2-0.3l0.1-0.1l0.1-0.1h0.2h0.2l0.4,0.2h0.2h0.2h0.1
                l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.1h0.1l0.8-0.1l0.7,0.2h0.9l0.3,0.1l0.3,0.1l0.4,0.2h0.1h0.2l0.2-0.2h0.1h0.2h0.6l1.6,0.2h0.2l1-0.5
                l0.4-0.5l0.3-0.1l0.1-0.2l0.1-0.2l0.1-0.1l0.3-0.1l0.1-0.1l0.2-0.3l0.2-0.3l0.1-0.1h0.3l0.2,0.1l0.2,0.4v0.1l-0.2,0.2l-0.1,0.1
                l-0.3,0.6l-0.2,0.3L825.6,460L825.6,460z M760.5,457l0.2,0.2v0.1l-0.1,0.2v0.3l-0.3,0.2H760l-0.1-0.1l-0.2-0.4v-0.3l0.1-0.1h0.3
                L760.5,457L760.5,457L760.5,457z M833.6,460.5l0.2,0.1h0.2l0.1-0.1v-0.2l0.1-0.3l0.2-0.2h0.2l0.1-0.1v-0.2v-0.2l0.2-0.2l0.6-0.3
                l0.3-0.1h0.4v0.1v0.2l0.1,0.3v0.7l-0.1,0.1l-0.4,0.3l-0.5,0.2l-0.1,0.1l-0.1,0.2v0.2l0.4,0.3l0.6,0.2l0.1,0.1l0.1,0.2v0.2l0.1,0.1
                h0.2l0.1,0.1l0.1,0.1l-1-0.3l-0.3-0.1h-0.3h-0.3l-0.3-0.1h-0.3l-0.2,0.1l-0.1,0.2v0.2l0.1,0.3v0.2l-0.1,0.3l0.2,0.8l0.3,0.7l0.5,0.7
                l0.2,0.2l0.3,0.2l-0.4-0.1l-0.1-0.2l-0.5-0.2l-0.1-0.1l-0.3-0.7l-0.1-0.1l-0.3-0.2l-0.1-0.2v-0.2v-0.2v-0.3V463l0.1-0.4l-0.1-0.1
                l-0.1-0.1l-0.2-0.3l-0.1-0.3v-0.2l0.1-0.2l0.1-0.1v-0.1l-0.2-0.2l-0.3-0.6v-0.3l0.3-0.6v-0.3l0.1-0.2v-0.2l0.2-0.4l0.3-0.3l0.5-0.5
                l0.2-0.1l0.2-0.1v0.1v0.1l-0.3,0.5v0.1v0.2l0.2,0.1l0.2,0.2v0.3v0.4v0.4l-0.1,0.1l-0.3,0.4l-0.7,0.4l-0.1,0.1v0.1l0.1,0.1
                L833.6,460.5L833.6,460.5z M748.3,457L748.3,457l-0.3-0.2l-0.3-0.2h0.1h0.3h0.1l0.1,0.1L748.3,457z M835.6,457.1h-0.4l-0.1-0.1
                l-0.1-0.6l0.3-0.5l0.4-0.3l0.3-0.1h0.1l0.2,0.3l-0.2,0.7l-0.2,0.4L835.6,457.1L835.6,457.1z M827,455.4L827,455.4l-0.1-0.3l0.1-0.2
                l0.1,0.1v0.1L827,455.4L827,455.4L827,455.4z M745.8,456.2h-0.2l-0.2-0.1l-0.1-0.2l-0.8-0.5h-0.2l-0.2-0.1h-0.2l-0.2-0.3v-0.2
                l0.2-0.1l0.1-0.2l0.3,0.1l0.3,0.3l0.3,0.1l0.1,0.1l0.1,0.2l0.7,0.4l0.1,0.1v0.1L745.8,456.2L745.8,456.2z M780.7,454.7l-0.1,0.1
                l-0.1-0.1l0.2-0.3h0.1L780.7,454.7z M771.9,454.8L771.9,454.8l-0.1-0.1v-0.1v-0.1v-0.1v-0.1l0.1,0.1l0.1,0.1h0.1h0.1l0,0l-0.1,0.2
                L771.9,454.8L771.9,454.8z M773.4,454v0.2l-0.2-0.1v-0.2l0.1-0.1h0.1L773.4,454L773.4,454z M805.3,453.7L805.3,453.7l-0.3-0.1
                l-0.1-0.2v-0.1h0.2l0.1,0.1L805.3,453.7L805.3,453.7z M827.8,453.2L827.8,453.2l-0.4-0.1v-0.2v-0.1l-0.1-0.1v-0.1v-0.1l0.2,0.2
                l0.1,0.3L827.8,453.2L827.8,453.2L827.8,453.2z M831.1,452.3L831.1,452.3L831.1,452.3l-0.2-0.1v-0.1h0.1l0.1,0.1L831.1,452.3
                L831.1,452.3z M830.7,452v0.1l-0.2-0.3l-0.1-0.3l0.1,0.1l0.2,0.2V452L830.7,452z M805.9,451.1l0.1,0.3v0.1l-0.5,0.1l-0.3-0.3
                l0.1-0.1h0.3L805.9,451.1L805.9,451.1z M779.1,452.5l-0.4,0.1l-0.2-0.1l0.2-0.2h0.1h0.1v-0.1l-0.4-0.1l-0.1-0.1l-0.1-0.3v-0.2
                l0.6-0.4h0.1v0.2l0.4,0.5v0.3v0.1L779.1,452.5L779.1,452.5z M802.2,450.7l0.1,0.1h0.1h0.2h0.4h0.7l0.5,0.1l0.5,0.3l0.2,0.1h0.1l0,0
                l-0.2,0.1l-0.1,0.2l0.3,0.2v0.2l0.2,0.1l0.3,0.2v0.1l0.1,0.1v0.1l-0.2,0.1h-0.2l-0.2-0.1l-0.2-0.1v0.2l-0.1,0.1h-0.5h-0.3h-0.3h0.2
                l0.1,0.1l0.5,0.5l0.1,0.2l-0.2,0.3l0.1,0.1l0.2,0.1l0.2,0.2h0.2l0.1,0.1v0.2l0.1,0.1l-0.2,0.1h0.2l0.2,0.1l-0.1,0.1l-0.1-0.1
                l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.2l0.2,0.4l0.4,0.5l0.1,0.2v0.2l-0.1,0.1l-0.2,0.2l-0.2,0.2v0.1l-0.3,0.1l0.1,0.1l0.1,0.1l0.2,0.3
                l0.4,0.5l0.2,0.2l0.9,0.6l0.5,0.3l0.6,0.6l0.3,0.1l0.1,0.2l-0.3,0.3l-0.4,0.1l-0.6,0.1l-0.6-0.1l-0.3-0.1l-0.3-0.2l-0.2-0.3L806,460
                l0.1,0.2l0.1,0.2v0.2l-0.1,0.2l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.5,1.1l-0.1,0.3l-0.2,1.2v0.3l0.2,0.6v0.3v0.1v0.1l-0.1,0.1l-0.5,0.2
                l-0.3,0.2l-0.3,0.2l-0.2,0.3l-0.2,0.2l-0.2,0.1H803l-0.1-0.1l-0.1-0.2l-0.1-0.2v0.1v0.1l0.1,0.2v0.2v0.2l-0.1,0.1l-0.3,0.1h-0.2v0.2
                l-0.1,0.1l-0.1,0.1l-0.4,0.2l-0.1,0.1l-0.1,0.1h0.2h0.2v0.2l0.1,0.2l-0.1,0.4l-0.3,0.2l0.2,0.1l0.1,0.1l0.3,0.1l0.1,0.3v0.3
                l-0.1,0.3l-0.2,0.1l-0.1-0.1h-0.1l-0.1,0.1v0.1l0.2-0.1v0.4l-0.1,0.4l-0.1,0.2l-0.1,0.2l-0.2,0.1l-0.2-0.1v0.1l0.1,0.1l0.2,0.3
                l-0.1,0.1l-0.1,0.1v0.1l-0.3,0.3l-0.1,0.2v0.3l-0.1,0.2l-2,0.9l-1.6,0.7H797l-0.1-0.1l-0.1-1.1l-0.2-0.6v-0.3l-0.2,0.3h-0.1
                l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.4l-0.1,0.2l-0.2,0.2h-0.2l-0.1-0.1l-0.1-0.1h-0.1l-0.3,0.3l-0.5,0.2h-0.3l-0.2-0.1v-0.2v-0.2
                l-0.1-0.1l-0.1-0.1h-0.1l-0.3,0.1h-0.1l-0.2-0.3l-0.9-0.9l-0.2,0.7l-0.6,0.4l-0.4,0.2l-0.4-0.1l-0.4-0.1l-0.4,0.2l-0.5,0.4l-0.1,0.1
                h-0.1l-0.1-0.1v-0.3v-0.3v-0.7v-0.1l-0.1-0.2l-0.2-0.1l-0.1,0.1l-0.1,0.1h-0.4l-0.4-0.1l-0.3,0.1l-0.6,0.3h-0.3l-0.2-0.1l-0.1-0.1
                l0.1-0.1l0.1-0.1l-0.2,0.1l-0.2,0.1l-0.1,0.1h-0.1l-0.3-0.3l-0.6,0.1h-0.1l-0.1,0.1l-0.1-0.1l-0.1-0.1v-0.7l-0.3-1.3l-0.1-0.6
                l-0.1-0.2l-0.3-0.2v-0.3l0.2-0.3v-0.3v-0.4l-0.1-0.3l-0.1-0.3l-0.2-0.2l-0.2-0.2l-0.3-0.2l-0.6-0.2h-0.3l-0.1-0.1l-0.1-0.1V465
                l0.1-0.1h0.2v-0.1l-0.3-0.2l-0.3-0.2l-0.1-0.1v-0.2v-0.4l0.1-0.2v-0.1l0.1-0.4l0.2-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.2
                l-0.4-0.3l-0.1-0.5v-0.7v-0.3l0.1-0.6l0.2-0.2l0.2-0.1l0.1-0.1h-0.1h-0.1h-0.2l0.1-0.6l0.1-0.2l0.3-0.3l0.3-0.3l0.1-0.3l0.2-0.3
                l0.7-0.3l-0.3,0.4v0.1l0.1,0.1l0.2,0.1l0.1,0.5l0.2,0.3l0.2,0.2l0.2,0.1l0.2,0.2l0.1,0.2l0.1,0.1l0.2,0.1l0.6,0.5l0.2,0.2l0.2,0.2
                l0.1,0.1h0.3l0.9-0.4h0.2l0.3-0.1h0.5l0.6,0.1h0.2l0.2-0.1h0.2h0.2h0.1l0.3-0.3l0.4-0.1l0.1-0.3l0.1-0.3l0.1-0.3l0.2-0.1l0.3-0.1
                l0.4-0.1h1.3l0.1,0.1v0.1v0.1l0.1,0.1h0.2l0.2,0.1l0.7,0.2l0.3,0.1h0.2l0.3,0.2l0.2-0.1l0.2-0.1l0.2-0.2l0.2-0.2l0.3-0.1h0.4
                l0.4-0.1l0.3-0.1l0.4,0.1h0.1l0.1-0.1l0.2-0.3l0.1-0.2l0.1-0.4v-0.1l0.1-0.1l0.1-0.1v-0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.3v-0.1l0.1-0.1
                l0.1-0.1l0.4-0.2l0.3-0.3l0.2-0.1l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1v-0.1v-0.1v-0.1V455v-0.1l0.1-0.1l0.2-0.2l0.2-0.1l0.2,0.1h0.2
                l0.2-0.1l0.1-0.3v-0.1V454l0.1-0.4v-0.1l0.1-0.2v-0.2l-0.1-0.4v-0.3v-0.6l0.1-0.1l0.1-0.3l0.1-0.3l0.3-0.2l0.2-0.2h0.1h0.1l0.4,0.2
                l0.3-0.2h0.3L802.2,450.7L802.2,450.7l0.1,0.2l0.3-0.2H802.2L802.2,450.7z M831,451.6l-0.1,0.1h-0.2v-0.1l0.2-0.3l0.1-0.3l-0.1-0.1
                l-0.1-0.2v-0.2l0.1-0.4h0.2l0.1,0.2l0.1,0.3l0.1,0.2l-0.2,0.3L831,451.6L831,451.6z M745.9,448.2h0.3l0.6-0.2h0.4l0.3,0.1l0.3,0.1
                l0.7-0.1h0.1l0.1,0.1l0.1,0.1l0.3,0.4l0.6,0.4l0.2,0.3l0.1,0.3l0.1,0.1l0.6,0.6l0.1,0.3l-0.1,0.4l0.2,0.3l0.6,0.3l0.4,0.2l0.1,0.1
                l0.1,0.1l0.2,0.2l0.3,0.1l0.8,0.4l1,0.8l0.6,0.4l0.5,0.5l0.2,0.3l0.1,0.3l0.3,0.4l0.5,0.5l0.1,0.2l0.1,0.2l0.2,0.2l0.2,0.2l0.2,0.1
                h0.2l0.4,0.4l0.2,0.1v-0.3l-0.2-0.2v-0.1v-0.1l0.1-0.1h0.2l0.3,0.1l0.5,0.4l0.2,0.3l0.2,0.3l0.1,0.4l0.2,0.2l0.3,0.1h0.3l0.3,0.1
                l0.7,0.5l0.2,0.2l0.2,0.3l0.1,0.3l0.1,0.3v0.1l0.4,0.4l0.2,0.2l0.3,0.1l0.8,0.1l0.3,0.1l0.2,0.2l0.1,0.2l-0.2,0.2l-0.6,0.3l-0.7,0.2
                l0.6-0.1l0.3-0.1l0.3-0.1l0.3-0.2l0.5-0.3l0.2-0.1h0.2l0.2,0.1l0.3,0.3l0.3,0.3l0.2,0.3l0.1,0.4l-0.2,0.2l-0.3,0.1l-0.5,0.3v0.1
                l0.1,0.1l-0.1,0.3l0.3,0.2v0.1l-0.2,0.2v0.1l0.3,0.5l0.1,0.1l0.4,0.3l0.6,0.3l0.3,0.1l0.4,0.1h0.2h0.3l0.1,0.1l0.1,0.5l0.1,0.3
                l0.1,0.7l0.1,0.3v0.3l0.1,0.3l0.3,0.2l0.3,0.1l0.2,0.1v0.2l-0.1,0.2l-0.1,0.1l-0.3,0.3l-0.1,0.1v0.3l0.1,0.1h0.1l0.1-0.1l0.4-0.4
                l0.1-0.1l0.2-0.1h0.2h0.7l0.3,0.1l0.3,0.1l0.2,0.2l0.9,1.1l0.4,0.6v0.2v0.2l-0.1,0.1l-0.4,0.4v0.1l-0.1,0.5v0.3l0.1,0.1l0.1,0.1v0.1
                l-0.2,0.7v0.1l0.2,1.1v0.3v0.4v0.6l-0.2,1.9v0.1L772,479h-0.2h-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.5-0.3l-0.1,0.1l-0.5,0.4l-0.1,0.1
                l-0.2-0.1l-0.3-0.1l-0.8-0.5v0.1v0.2l0.2,0.5v0.2h-0.1h-0.1l-0.3-0.3l-0.3-0.3l-0.4-0.4l-0.3-0.2l-0.3-0.2l-0.7-0.9l-0.2-0.1l-1-0.6
                l-0.2-0.1l-0.3-0.2l-0.3-0.2l-0.6-0.4l-1.1-0.9l-0.5-0.5l-0.5-0.8l-0.2-0.2l-0.9-0.6l-0.5-0.4l-0.2-0.2l-0.5-0.7l-0.1-0.3l-0.2-0.2
                l-0.3-0.2l-0.2-0.2l-0.5-0.7l-0.2-0.3l-0.1-0.3v-0.6l-1-1.8l-0.3-0.6l-0.2-0.8l-0.1-0.1l-0.6-0.7l-0.2-0.2l-0.2-0.2l-0.2-0.2
                l-0.4-0.8l-0.1-0.2l-0.2-0.2l-0.7-0.3l-0.3-0.2l-0.2-0.2l-0.1-0.3l-0.1-0.6l-0.3-1l-0.4-1.3L752,458l-0.3-0.5l-0.1-0.1l-1.3-0.8
                l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.3-0.2l-0.1-0.2l-0.1-0.5l-0.1-0.3l-0.1-0.2l-0.6-0.4l-0.2-0.3l-0.2-0.3l-0.2-0.2l-0.6-0.8l-0.2-0.2
                l-0.3-0.2l-0.8-0.2l-0.2-0.1l-0.4-0.5l-0.2-0.2l-0.7-0.5l-1.1-1.1l-0.2-0.3l-0.2-0.3l-0.1-0.3l-0.5-0.9v-0.2l0.1-0.2v-0.1l-0.1-0.1
                v-0.1l0.1-0.1l0.3-0.1h0.3h0.3l0.3,0.1l0.3,0.2l0.5,0.5l0.3,0.2l0.3,0.1L745.9,448.2L745.9,448.2z M742.7,446.6L742.7,446.6h-0.2
                l-0.2-0.3h0.1h0.1l0.2,0.1L742.7,446.6L742.7,446.6L742.7,446.6z"/>
            <linearGradient id="IE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="380.4078" x2="0.1864" y2="380.4078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IE" class="stcustom0 st101" d="M454.8,277.7l-0.4,0.3l-0.1,0.1l-0.1,0.5v0.1l-0.1,0.3l-0.1,0.3l-0.2,0.1l-0.2,0.1l-0.1,0.1h-0.2
                h-0.2l-0.1,0.1v0.1l0.1,0.1l0.2,0.1l0.2,0.1v0.1l-0.1,0.1l-0.7,0.3l-0.2,0.2l-0.1,0.1l0.1,0.2l0.6,0.6l0.1,0.1l0.1,0.3l0.5,0.1
                l0.2,0.2h0.2h0.4l0.2,0.1l0.1-0.1v-0.1l0.3-0.3l0.1-0.1l-0.1-0.2l-0.1-0.1l0.2-0.3l0.2-0.3h0.1l0.2,0.2l0.2,0.2v0.2v0.1l0.2,0.3
                l0.1,0.1l0.3,0.1l0.1,0.1v0.4v0.1h0.3h0.3h0.1l0.1-0.1l0.2-0.1h0.2l0.1,0.2l0.1,0.2l-0.2,0.1h-0.2l-0.1,0.1v0.2l0.1,0.3l0.1,0.2
                l0.1,0.4l0.1,0.5l0.1,0.3v0.4v0.2v0.3l-0.1,0.1v0.3l0.2,0.6l0.1,0.4v0.8l-0.1,0.3l-0.2,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.6l-0.4,0.6
                l-0.2,0.2l-0.2,0.1l0.4,0.5l-0.3,0.2l-0.3,0.1l-0.4-0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.1l-0.1-0.4l-0.1,0.4l-0.2,0.1h-0.4l-0.6,0.1
                l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.5,0.1l-0.1,0.1l-0.2,0.3l-0.3,0.2l-0.2,0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1h-0.3
                l0.1,0.1l0.1,0.1v0.2v0.2l-0.2,0.1h-0.2l-0.3,0.3l-0.4,0.1l-0.2,0.2l-1.4,0.4h-0.1l-0.2-0.1h-0.2h-0.2l-0.6,0.2h-0.3l0.4-0.5
                l0.5-0.3V294h-0.2l-0.9,0.2l-0.3,0.2h-0.3l0.1-0.2l0.4-0.3l0.2-0.2l0.1-0.1l0.1-0.2l0.4-0.2l-1.4,0.5l-0.4-0.1l-0.1-0.1l-0.3,0.1
                l-0.1-0.3l0.4-0.5l0.2-0.2l0.3-0.1l0.3-0.2l0.1-0.2L447,292h-0.8h-0.4v-0.2l0.1-0.2l0.4-0.3h0.2h0.2l0.2,0.1l0.2,0.1l0.5-0.1
                l-0.2-0.2v-0.4l-0.1-0.1l0.2-0.2l0.2-0.1l0.4-0.4l0.1-0.1l0.7-0.1l0.8-0.2l0.8-0.3l-0.4-0.2l-0.2-0.2l-0.3,0.4l-0.2,0.2l-0.6,0.1
                h-0.2l-0.3-0.1h-0.1l-0.1,0.1l-0.4,0.2h-0.4l0.5-0.4l0.6-0.6l0.1-0.2l0.2-0.3l-0.1-0.2l-0.1-0.1l0.5-0.7l0.2-0.1h0.3l0.2-0.1h0.1
                h0.1l0.2-0.2l-0.3-0.1l-0.3-0.1l-0.9,0.1h-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1h-0.2l-0.2,0.1h-0.1l-0.1-0.1l0.2-0.2l-0.3-0.1
                h-0.3l-0.2-0.1v-0.2l0.1-0.2l-0.1-0.1v-0.2l0.2-0.1h0.2l0.3-0.1l0.4-0.1l-0.4-0.1l-0.2-0.1v-0.2v-0.2l0.4-0.3l0.5-0.1v-0.2v-0.2
                l-0.5-0.1l-0.5,0.1v-0.4l0.1-0.3v-0.2v-0.2l-0.2,0.1v-0.3l-0.1-0.2l-0.3,0.1v-0.3l0.1-0.2l0.2-0.1h0.2h0.3l0.3-0.2h0.4h0.7l0.5,0.4
                l0.1-0.1l0.2-0.3h0.1l0.7,0.1l0.4,0.2h0.1l-0.1-0.3l-0.2-0.2l0.2-0.3l0.2-0.2l0.2-0.1l0.4-0.1l0.2-0.1l0.1-0.4l0.2-0.3l-0.9,0.2
                l-0.9-0.3l0.1-0.2l0.2-0.1l0.3-0.1V279l0.2-0.1l0.3-0.3l-0.1-0.4l0.1-0.3l0.2-0.2l0.1-0.3l0.1-0.2l0.4-0.1l0.4-0.2h0.1h0.4l0.1,0.1
                v-0.3h0.3l0.1,0.1v0.2l0.1,0.1v0.2l-0.1,0.2l-0.1,0.1l0.1,0.1l-0.2,0.3l0.2-0.1l0.3-0.3v-0.2v-0.3l-0.1-0.2v-0.3l0.2-0.2l0.4-0.1
                l-0.2-0.3h0.2l0.2,0.1l0.3,0.2l0.3,0.2l0.3,0.1l-0.3,0.3l-0.3,0.2L454.8,277.7L454.8,277.7z M447.2,283.3v0.1l-0.2-0.2l-0.1-0.2
                l-0.6-0.1l0.2-0.2l0.1,0.1h0.4l0.1,0.1L447.2,283.3L447.2,283.3z"/>
            <linearGradient id="IL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="295.4578" x2="0.1864" y2="295.4578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IL" class="stcustom0 st102" d="M575.5,366.1L575.5,366.1l-0.4,0.2l0,0l-0.1,0.1v0.1v0.7h-0.2l-0.2-0.2v-0.1h-0.1h-0.2l-0.3-0.1
                l-0.4,0.2l-0.2,0.4v0.2l-0.1,0.4v0.2v0.3v0.3v0.2l-0.1,0.1v0.1h0.1l0.2-0.1l0.2,0.1l0.2,0.1v0.1l-0.1,0.1l-0.3,0.2l-0.2,0.2
                l-0.1,0.2l-0.2,0.5v0.1l0.1,0.1h0.5l0.5-0.2l0.4-0.2h0.1l-0.1,0.5l0,0l-0.1,0.3v0.1l0.1,0.3l-0.2,0.5l-0.2,0.4l-0.1,0.2l-0.2,0.4
                l-0.2,0.5l-0.1,0.3v0.1v0.6v0.2l-0.2,0.5v0.3l-0.1,0.4l-0.1,0.7l-0.2,0.2l-0.1-0.3l-0.2-0.8l-0.2-0.5l-0.2-0.7l-0.4-0.8v-0.2
                l-0.1-0.3l-0.2-0.8l-0.2-0.5l-0.2-0.7l0.3-0.3v-0.2l0.5-0.5v-0.1l-0.1-0.1l0,0l0.5-1l0.4-1l0.3-1.4l0.2-0.7l0.2-0.5l0.1-0.4h0.3h0.2
                h0.3l0.2-0.1l0.1-0.4l0.1-0.1l0.1,0.1l0.1-0.1l0.3-0.2l0.1-0.1l0.1-0.2l0.1-0.1v0.2v0.1v0.2l0.1,0.1l0.1,0.2l0.1,0.2l-0.1,0.2v0.2
                v0.1l0.1,0.2l-0.2,0.3l-0.2,0.3L575.5,366.1z"/>
            <linearGradient id="IM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="384.0079" x2="0.1864" y2="384.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IM" class="stcustom0 st103" d="M462.7,282l-0.6,0.6l-0.2-0.1l-0.2,0.1h-0.1l0.1-0.2l0.1-0.5l0.2-0.2l0.3-0.5l0.2-0.1h0.1h0.1
                l0.1,0.6l-0.2,0.2L462.7,282z"/>
            <linearGradient id="IN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="265.408" x2="0.1864" y2="265.408" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IN" class="stcustom0 st104" d="M738.6,443.7l-0.2,0.2l-0.3-0.7H738v-0.3l0.1-0.1l0.4-0.1l0.1,0.1l0.2,0.7L738.6,443.7z M738.2,442.3
                l-0.3,0.3l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1L738.2,442.3L738.2,442.3z M737.3,440.8h-0.2l-0.1-0.1l-0.1-0.1l0.1-0.1h0.1l0.2,0.2V440.8
                L737.3,440.8z M737.6,440.3l-0.1,0.1l0,0L737.6,440.3l-0.1-0.1v-0.1v-0.2l0.1-0.1h0.1l-0.1,0.2V440.3L737.6,440.3z M680.2,439.7
                L680.2,439.7L680.2,439.7L680.2,439.7L680.2,439.7L680.2,439.7L680.2,439.7L680.2,439.7v-0.1l0,0l0,0V439.7L680.2,439.7z
                M736.5,439.7l0.1,0.1L736.5,439.7l-0.2-0.1v-0.1l0.1-0.1L736.5,439.7L736.5,439.7z M735.5,437.2L735.5,437.2l-0.2-0.1V437l0.1-0.1
                h0.1h0.1l0.1,0.2L735.5,437.2z M734.7,433.2L734.7,433.2h-0.4v-0.3l-0.1-0.3v-0.1l0.2-0.2l0.2-0.1l0.1,0.3l0.1,0.3L734.7,433.2
                L734.7,433.2z M679.4,431.4L679.4,431.4L679.4,431.4v-0.1v-0.1l0,0v0.1V431.4L679.4,431.4z M735.2,430.9l-0.1,0.1l-0.1-0.1l0.1-0.1
                v-0.2l0.1,0.1V430.9L735.2,430.9z M736.1,429l0.1,0.4l-0.2-0.2l-0.1-0.1L736.1,429L736.1,429L736.1,429z M735.3,426.6l-0.1,0.2v-0.4
                l0,0l0,0h0.1V426.6z M735.3,430.4L735.3,430.4h-0.2l-0.3-0.5v-0.3l-0.1-0.1l0.1-0.2l0.1-0.1l0.1-0.2v-0.3l0.1-0.2l0.1-0.1h0.2h0.1
                v-0.2l-0.1-0.1V428v-0.5v-0.2l0.1-0.2l-0.1-0.3v-0.1l0.2-0.2l0.1-0.4l-0.1-0.1l0.1-0.5v-0.4l0.2-0.4l0.3-0.2h0.1v0.3v0.1l-0.2,0.2
                l0.2,0.2v0.1l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.1l0.2,0.3l0.1,0.9l-0.2,0.2h-0.2v0.6v0.1l-0.2,0.3v0.1l-0.1,0.1v0.1
                l0.1,0.1v0.1l-0.1,0.3v0.4L735.3,430.4L735.3,430.4z M696,368.9L696,368.9l-0.2-0.3L696,368.9l-0.1,0.3v0.2l0.2,0.2l0.1,0.2
                l-0.1,0.2l-0.1,0.3l0.1,0.1v0.4v0.1h0.1h0.1l0.1,0.2l-0.1-0.2l0,0l0.2-0.1h0.1h0.1l0.1-0.3h0.1l0.2,0.1l0.4,0.5l0.3,0.4l0.1,0.1l0,0
                l0.1,0.1l0.3,0.2l0.2,0.1l0.3-0.1h0.4l0.2,0.1l0.1,0.1l0,0l0,0l0,0l0.4,0.3h0.2l0.1,0.1v0.2l-0.1,0.3v0.1h0.2l0.4,0.2l0.4,0.1h0.2
                l0.2,0.1l0.2,0.2l0.4,0.2l0.3,0.2l0.1,0.2h-0.1h-0.2l-0.2,0.1l-0.1,0.1l-0.4,0.4l-0.2,0.1l-0.2,0.2l-0.4,0.5l-0.2,0.5l-0.2,0.5v0.3
                l-0.1,0.4l-0.2,0.2l-0.1,0.1l-0.1,0.3l-0.1,0.4l0.1,0.1l0.2,0.2l0.2,0.2l0.3,0.2l0.3,0.2h0.2v-0.1l0.1-0.1h0.2l0.2,0.2l0.2,0.1h0.1
                l0.4,0.2l0.3,0.2l0.4,0.2l0.1,0.1l0.1,0.2l0.2,0.2l0.5,0.4l0.4,0.3l0.3,0.2l0.3,0.1h0.1l0.1-0.1h0.1h0.1l0.2,0.1l0.5,0.3l0.5,0.3
                h0.5h0.1l0.1,0.2l0.1,0.2l0.6,0.2l0.4,0.1l0.4,0.1l0.2,0.1l0.2-0.1v-0.1l0.2-0.1h0.3l0.5,0.2l0.2,0.1l0.2-0.2l0.4-0.1l0.2-0.1
                l0.4,0.2l0.7,0.2l0.4,0.2l0.1,0.2v0.1v0.4l0.1,0.2l0.7,0.4l0.2,0.2h0.2h0.1h0.1l0.1,0.2l0,0h0.1h0.1l0.5-0.2l0.3-0.1h0.2l0.1,0.1
                v0.2l0.1,0.2l0.2,0.1h0.2l0.4-0.2l0.3,0.1h0.3l0.3,0.1l0.1,0.1l0.4,0.2l0.4,0.2h0.2l0.7-0.4h0.1l0.1,0.3l0.2,0.1l0.3,0.1l0.4-0.2
                l0.3,0.1h0.3l0.3-0.1h0.3l0.4,0.2h0.1l0.1-0.1l0.2-0.5l0.1-0.4v-0.3l-0.1-0.4l-0.3-0.5v-0.1l0.1-0.9l0.1-0.5l0.1-0.2l0.1-0.3v-0.2
                v-0.1l-0.1-0.1v-0.1V381h0.1l0.4-0.1l0.4-0.1l0.2-0.1l0.1-0.1l0.1-0.1h0.1l0.4,0.2l0.1,0.1l0.1,0.3l0.1,0.1v0.3v1.4v0.3l0.2,0.2
                l0.2,0.1v0.1l-0.4,0.3l-0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.3l0.2,0.1l0.3,0.2l0.3,0.2l0.5-0.1l0.1,0.1l0.3,0.1h0.2l0.1,0.1
                l0.1,0.1v0.1v0.1h0.3h0.1l0.5-0.1l0.5-0.1l0.2-0.3h0.1l0.3-0.1l0.3,0.1l0.3,0.2l0.2,0.1h0.3h0.3l0.8-0.1h0.4l0.4-0.2h0.1l0.2,0.2
                h0.4l0.2-0.1l0.2-0.1h0.2h0.1h0.2l0.1-0.1l0.1-0.1v-0.2l-0.1-0.2l-0.1-0.1v-0.1v-0.1l0.1-0.2l0.1-0.2l-0.1-0.2l-0.2-0.3H733
                l-0.3,0.1h-0.3l-0.2-0.2L732,383v-0.2l0.1-0.2l0.1-0.2v-0.1h0.2h0.3l0.2,0.1h0.2l0.3-0.2h0.2h0.2h0.1h0.1h0.2h0.2l0.2-0.1l0.2-0.1
                l0.3-0.2l0.1-0.1v-0.1l-0.1-0.1H735v-0.1l0.1-0.2l0.5-0.3l0.4-0.3l0.2-0.2l0.1-0.3l0.1-0.3l0.1-0.1l0.3-0.1l0.9-0.1l0.3-0.1l0.4-0.2
                l0.2-0.2l0.1-0.1v-0.2l0.3-0.1l0.2-0.3l0.3-0.3l0.5-0.2l0.4-0.3h0.2l0.2,0.1l0.1,0.2v0.1l0.6,0.1h0.1l0.4,0.1l0.4,0.2h0.2h0.1
                l0.1-0.1l0.1-0.2l0.1-0.1h0.1v-0.2l0.5-0.3l0.5-0.2l0.4-0.2l0.1,0.1l0.1,0.1l0.2,0.3l0.1,0.1h0.3h0.1v0.1l-0.2,0.2l-0.3,0.1
                l-0.2,0.1l0.1,0.4v0.1h0.1l0.5-0.4l0.2-0.1l0.1,0.1v0.2l0.2,0.4l0.1,0.2l-0.5,0.5l-0.2,0.3v0.1v0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.1
                h0.1l0.1-0.1l0.5-0.2h0.1l0.3,0.3h0.2l0.4,0.1l0.3-0.1l0.2,0.1l0.4,0.3l0.1,0.1v0.2v0.2l0.1,0.2v0.2v0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2
                l-0.3,0.2l-0.2,0.2l-0.2,0.2l-0.1,0.2v0.2l0.1,0.2l0.6,0.9v0.1h-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.4l-0.2-0.1h-0.2l-1.1,0.2l-0.2,0.1
                l-0.4,0.1l-0.3,0.3l-0.2,0.3l-0.2,0.1l-0.3,0.2l-0.8,0.6l-0.4,0.3l-0.3,0.1l-0.2,0.1l-0.1,0.2l-0.1,0.2v0.4l0.1,0.5l0.1,0.3l0.1,0.1
                v0.1l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.4l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.3,0.2l-0.1,0.2l-0.1,0.3l-0.1,0.2v0.1v0.1l0.1,0.1l0.2,0.1
                l0.1,0.1v0.2l-0.1,0.4l-0.2,0.5l-0.3,0.4l-0.3,0.4l-0.1,0.1l-0.2,0.5l-0.2,0.6l-0.1,0.4l-0.1,0.3h-0.1l-0.2-0.1l-0.4-0.1l-0.5-0.5
                l-0.2-0.1h-0.1l-0.2,0.1h-0.2h-0.1l-0.3-0.3H737l-0.1,0.1l0.2,0.8l0.1,0.3v0.5v0.6l-0.1,0.6v0.1l-0.1,0.2h-0.2l-0.1-0.1h-0.1v0.1
                v0.3l-0.1,0.3l-0.1,0.3v0.3v0.3l0.2,0.6v0.2v0.2l-0.1,0.1h-0.1l-0.1,0.1l-0.1,0.1L736,400h-0.2l-0.2-0.1l-0.2-0.3l-0.1-0.1h-0.1v0.1
                l-0.1,0.2l-0.1,0.1l-0.2,0.1v-0.2l-0.1-1.1l-0.1-0.3l-0.1-0.5l-0.1-0.1l-0.1-0.3l-0.1-0.2l-0.1-0.1l-0.1-0.4v-0.5v-0.2l-0.1-0.5
                l-0.1-0.6h-0.2l-0.1-0.1h-0.1l-0.2,0.1H733h-0.1v0.3v0.3l-0.1,0.1l-0.4,0.3l-0.1,0.2v0.2v0.3l-0.1,0.2L732,397l0.2-0.1H732l-0.1-0.1
                l-0.1-0.3l-0.1-0.2h-0.1h-0.1v0.2v0.2l0,0h-0.1l-0.1-0.1l-0.2-0.8l-0.2-0.6V395l0.1-0.3l0.1-0.5l0.3-0.3v-0.1v-0.1h0.1h0.4h0.1
                l0.1-0.1l0.2-0.1h0.2h0.1l0.2,0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.1h0.1h0.2h0.1v-0.1v-0.3l0.2-0.6l0.1-0.3l0,0v-0.3h0.1l0.4,0.1h0.2
                l0.1-0.1v-0.1v-0.1l-0.3-0.2l-0.5-0.3l-0.4-0.2h-0.8l-0.8,0.1h-0.2l-0.3-0.1h-0.7h-0.9h-0.3h-0.2H729l-0.5-0.1l-0.4-0.1l-0.3-0.1
                l-0.4-0.1h-0.1h-0.1V390v-0.1l0.1-0.6l-0.1-0.9l0.1-0.3l-0.3-0.7l-0.1-0.1h-0.1l-0.1,0.1v0.2l0.1,0.2l-0.1,0.2l-0.2,0.1l-0.3-0.1
                l-0.2-0.1l-0.3-0.2l-0.2-0.3v-0.3l-0.1-0.2l-0.1-0.1h-0.1h-0.1l-0.1,0.1l0.1,0.1l0.1,0.2v0.1h-0.1H725h-0.2l-0.2-0.1h-0.1h-0.1V387
                l-0.2-0.2l-0.3-0.3l-0.7-0.6h-0.1l-0.1,0.2v0.1h0.1l0.1,0.1v0.1v0.1l-0.2,0.2l-0.1,0.2l-0.3,0.2l-0.2,0.3l-0.1,0.2l-0.1,0.2v0.2
                l0.1,0.1l0.1,0.1l0.3,0.1l0.3,0.3l0.2,0.4l0.1,0.1l0.3,0.1h0.5l0.1,0.1l0.1,0.3l0.1,0.1l0.3,0.1v0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1
                h-0.2h-0.2h-0.3h-0.3l-0.2,0.7l-0.2,0.2h-0.1l-0.3-0.1h-0.1l-0.3,0.6v0.1v0.1l0.2,0.2l0.2,0.2l0.2,0.1l0.2-0.1l0.1,0.1l0.2,0.2
                l0.3,0.1l0.4,0.1l0.2,0.2v0.1v0.1v0.4v0.2l-0.2,0.5l-0.2,0.5l0.1,0.2l0.1,0.1l0.1,0.1l0.2,0.2l0.1,0.2l-0.1,0.4l0.1,0.1l0.2,0.1
                l0.3,0.1l0.1,0.1l-0.2,0.4v0.3l0.1,0.3l0.1,0.5l0,0v0.1l0.1,0.4l0.2,0.7v0.3v0.3l-0.1,0.5h-0.2l0.2,0.3l0.1,0.2v0.3H725h-0.2
                l-0.1-0.3l-0.1,0.2l-0.2,0.2l-0.1-0.1l-0.1-0.1v-0.2l0.1-0.8v-0.1l-0.1-0.1h-0.1l-0.1-0.2l-0.2,0.9l0.1,0.4v0.2l-0.4,0.1l-0.4-0.3
                v-0.1v0.2L723,401h-0.4l-0.2-0.2l0.1-0.3l0.2-0.7v-0.3l-0.3-0.2l-0.3-0.1l-0.1-0.3l0.1,0.4l0.1,0.1l0.2,0.1l0.2,0.2l-0.2,0.2h-0.2
                l-0.3,0.5l-0.3,0.3l-0.4,0.2l-1.3,0.3l-0.3,0.1l-0.4,0.4l-0.3,0.4v0.4l0.2,0.4l0.1,0.7l0.1,0.1l-0.1,0.2l-0.2,0.3l-0.2,0.3v0.2
                l-0.1,0.1l-0.7,0.4l-0.1,0.2l-0.2,0.2l-0.2-0.1h-0.1l0.2,0.2v0.1l-0.1,0.1l-0.2,0.1l-1,0.3l-0.8,0.3h-0.2v-0.1l0.1-0.1v-0.3
                l-0.1-0.1h-0.1l-0.6,0.4l-0.2,0.4v0.1h0.1l0.4-0.2l0.2,0.1v0.1l-0.6,0.4l-1.3,1.1l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.4,0.6l-0.8,0.9
                l-0.2,0.3l-1.3,0.7l-0.2,0.2l-0.5,0.6l-0.5,0.5l-0.6,0.4l-1.1,0.5l-0.7,0.5l-0.2,0.3v0.1l0.1,0.2l0.1,0.2v0.1l-0.1,0.2v0.1l-0.2,0.3
                l-0.3,0.2l-1.1,0.5h-0.1l-0.9-0.1l-0.3,0.1l-0.1,0.2l-0.3,0.9l-0.3,0.2l-0.1,0.2v0.1h-0.2l-0.1-0.1h-0.1l-0.1-0.3l-0.2-0.1h-0.2
                l-0.7,0.3l-0.3,0.2l-0.5,1.1l-0.1,0.7l0.1,0.8l0.2,0.6v0.3v0.4l-0.1,0.2l-0.1,0.2l0.1,0.4l0.2,0.6l0.1,0.2v0.3l0.2,0.6l-0.1-0.1
                l-0.1-0.2l-0.2-0.3l-0.3,0.3l0.1,0.2l0.5,0.3l0.1,0.2l-0.3,1.9l-0.2,0.7l-0.3,0.4l-0.2,0.2l-0.4,0.7l-0.2,0.9v0.3l0.1,0.4l-0.1,0.2
                l-0.2,0.2l0.3-0.1l0.1,0.2v0.2v1.2v1.3l-0.2,0.1h-0.3h-0.2h-0.2l-0.4,0.1l-0.2,0.1l-0.2,0.2v0.4l-0.7,1l-0.2,0.3l-0.1,0.3l0.1,0.2
                l0.2,0.2l0.2,0.1l0.5,0.1l0.2,0.1l0.2,0.2l-0.6-0.2h-0.7l-1.6,0.5l-0.4,0.3l-0.2,0.3l-0.2,0.6v0.4l-0.2,0.4l-0.8,0.5l-0.5,0.2
                l-0.2,0.1l-0.6-0.2l-0.7-0.5l-0.3-0.3l-1-1.2l-0.2-0.2l-0.2-0.5V437l-0.1-0.1l-0.1-0.1v-0.1l-0.2-0.6l-0.1-0.6l-0.1-0.7h0.1l0.2,0.2
                l0.1,0.3v0.5l0.1,0.1h0.1l-0.3-1.1l-0.3-0.3H689l-0.1-0.2v-0.2V434l-0.2-0.4l-0.1-0.2l-0.5-1.1l-0.2-0.8l-0.3-0.9l-0.2-0.3l-0.3-0.7
                l-0.3-0.3l-0.3-0.4l-0.2-0.2l-0.1-0.1l-0.7-1.4l-0.2-0.8l-0.2-0.4l-0.1-0.3l-0.2-1.2v-0.2v-0.2l-0.2-0.5l-0.3-0.6l-0.1-0.4v-0.1
                L684,422v-0.3l-0.1-0.2l-0.2-0.2l-0.2-0.2l-0.4-0.6l-0.1-0.1l-0.3-0.4l-0.2-0.7l-0.2-0.3h0.4l-0.2-0.2l-0.1-0.2l-0.1-0.1l0.2-0.2
                h-0.3l-0.1-0.1l-0.2-0.5l-0.4-0.5l-0.1-0.3l-0.3-0.9l-0.3-2.2l-0.3-1v-0.3l-0.3-0.8l-0.1-0.6l-0.1-0.5l-0.1-0.3l-0.1-0.6l-0.1-0.2
                v-0.1l0.1-0.3l0.2-0.4l0.1-0.3L680,408l-0.2,0.4l-0.2,0.1l-0.1-0.3v-0.4v-0.1v-0.1l0.5,0.1l-0.6-0.3l-0.1-0.1v-0.1l0.1-0.2l-0.2-0.2
                l-0.1-0.5l-0.1-0.1v-0.1l0.1-0.7l0.5-1.4v-0.3v-0.5l-0.1-0.4v-0.4v-0.1h-0.2l-0.2-0.1l-0.2-0.6l0.2-0.2l0.1-0.1H679h-0.2l0.3-0.3
                l0.3-0.2l0.6-0.2l0.3-0.2l-0.4,0.1l-0.4,0.1h-0.8l0.1-0.5l0.1-0.2l0.2-0.1h-0.2h-0.3l0.1-0.6l0.2-0.1h0.2l0.3-0.1l-0.3-0.1h-0.3
                l-0.4,0.1l-0.3-0.1h-0.4l0.2,0.1l0.2,0.2l-0.1,0.3l-0.1,0.2l-0.2,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.1,0.2
                v0.4l-0.5,0.9l-0.2,0.2l-1.2,0.6l-0.5,0.3l-1,0.4l-0.4,0.1l-0.5-0.1l-0.7-0.3l-1-0.8l-0.3-0.3l-0.8-1l-0.6-0.5l-0.4-0.5l-0.5-0.5
                l-0.5-0.6l-0.1-0.3v-0.3l0.2-0.2l0.2,0.1l0.2,0.2l0.1,0.1h0.1l0.8-0.4h0.3l0.2-0.2h0.3l0.5-0.3h0.2l0.3-0.1l0.4-0.7l0.3-0.5l0.2-0.1
                v-0.1l-0.1-0.1h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.1l-0.2-0.1H672l-0.2,0.1l-0.8,0.3l-0.3,0.2h-0.2l-1.2-0.3l-1.2-0.6l-0.5-0.4l-0.3-0.5
                l-0.3-0.6l0.1-0.2l0.5-0.4l0.4-0.3l-0.4,0.1l-0.4,0.2l-0.2,0.1l-0.2,0.3l-0.3,0.1l-0.1-0.4l-0.1-0.4l0.2-0.1l0.1-0.1l0.3-0.1h0.3
                h0.3h0.4v-0.9v-0.1h0.1h0.1h0.1l0.1,0.1h0.1l0.1-0.1l0.2,0.1h0.2l0.2,0.1h0.3h0.6h0.3l0.2,0.1l0.2,0.2h0.2h0.4l0.2-0.1l0.1-0.1
                l0.1-0.1l0.5-0.2l0.6-0.2h0.2l0.1,0.1v0.2l0.1,0.2l0.2,0.1h0.2h0.1l0.1-0.1l0.2-0.2l0.1-0.1h0.2l0.2-0.1v-0.1h-0.1l-0.1-0.1v-0.1
                v-0.2v-0.1l0.1-0.1l0.1-0.1l-0.1-0.2l-0.2-0.4l-0.2-0.5l-0.2-0.4l-0.3-0.4l-0.1-0.3v-0.6v-0.1l-0.1-0.1h-0.1l-0.2,0.1h-0.2h-0.3
                l-0.2-0.1l-0.5-0.6l-0.1-0.2v-0.3l0.2-0.4v-0.4v-0.4v-0.1l-0.1-0.1l-0.2-0.1h-0.4l-0.5-0.1l-0.4-0.2l-0.3-0.1l-0.1-0.1v-0.1l0.1-0.5
                l0.1-0.5l0.1-0.2l0.2-0.2l0.1-0.1l0.2-0.2l0.5-0.5l0.4-0.7l0.3-0.5l0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.1h0.2l0.2,0.1l0.2,0.1l0.1,0.3
                l0.1,0.2l0.1,0.1l0.2,0.1h0.2l0.9-0.4l0.3-0.1h0.7l0.5-0.1l0.4-0.1l0.1-0.3l0.2-0.4l0.5-0.5l0.1-0.2l0.2-0.5l0.2-0.4l0.1-0.2
                l0.8-0.5l0.8-0.4l0.1-0.2l0.5-0.9l0.3-0.6l0.1-0.2l0.2-0.5l0.2-0.5l0.2-0.1l0.5-0.2l0.4-0.2l0.2-0.2l0.1-0.2v-0.2l-0.1-0.2v-0.1V374
                l0.3-0.3l0.6-0.8l0.3-0.4h0.1l0.4-0.2l0.3-0.2V372v-0.1l-0.2-0.1l-0.1-0.2v-0.2l0.2-0.7v-0.2l-0.2-0.6v-0.2l0.1-0.2l0.2-0.2l0.3-0.2
                l0.9-0.5l0.2-0.1l0.3-0.1l0.2-0.2V368l-0.1-0.1l-0.2-0.2l-0.4-0.2l-0.3-0.1h-0.6l-0.3-0.1l-0.1-0.1v-0.3l0.1-0.5h-0.1l-0.1,0.1
                l-0.3-0.1h-0.4l-0.1-0.1l0.1-0.2v-0.2l-0.1-0.2h-0.1l-0.2-0.1l-0.3-0.2l-0.2-0.2l-0.1-0.2v-0.1v-0.1l0.2-0.2l0.2-0.3l0.1-0.2v-0.2
                l-0.1-0.1l-0.2-0.2l-0.2-0.1l-0.1-0.1v-0.2l0.1-0.2l0.2-0.2l0.4-0.2l0.1-0.2v-0.1H683h-0.3l-0.5-0.1l-0.1-0.1L682,362v-0.1l0.1-0.1
                l0.1-0.2v-0.2l-0.1-0.2l-0.3-0.1v-0.2v-0.1l0.1-0.2l0.1-0.1l0.2-0.4l0.3-0.1l0.3-0.1l0.4-0.2l0.6,0.1l0.3,0.1l0.5,0.1l0.5,0.1h0.5
                h0.2l0.2,0.1l0.5,0.2l0.4,0.1h0.3l0.4-0.2l0.2-0.2l0.3-0.2h0.4l0.8-0.3l0.1,0.1h0.2l0.3-0.2l0.1-0.2v-0.1l0.1-0.1l0.3-0.1l0.3-0.2
                l0.1-0.2l0.1-0.2l0.3-0.2l0.3-0.2l0.4-0.2l0.4-0.3l0.4-0.2l0.3-0.2l0,0l0.1,0.1h0.1l0.1-0.1l0.2-0.1h0.1v0.1l-0.1,0.5v0.2l0.2,0.4
                l0.2,0.6l0.2,0.6l0.1,0.4l0.1,0.2l0.5,0.2l0.4,0.1l0.3,0.2l0.3,0.2l0.2,0.1l0.1,0.2v0.1v0.1l-0.1,0.1l-0.5,0.3l-0.1,0.1v0.1l0.1,0.4
                l0.1,0.3v0.5v0.5l0.2,0.2l0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.1l0.1,0.1l0.1,0.2v0.2l-0.1,0.2v0.1l0.1,0.1l0.2,0.2v0.3v0.2l0.1,0.2v0.2
                v0.5v0.2h-0.1h0.4l-0.2,0.3l-0.2,0.1h-0.2l-0.2-0.2l-0.2-0.2l-0.1-0.1v-0.2l-0.1-0.1l-0.2,0.1h-0.3h-0.3h-0.1v0.1l0.1,0.2l0.1,0.2
                v0.3l0.1,0.2v0.1l0.5,0.6l0.1,0.1v0.1L696,368.9L696,368.9z"/>
            <linearGradient id="IO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="182.5579" x2="0.1864" y2="182.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IO" class="stcustom0 st105" d="M678.5,483.4l-0.1,0.1l-0.1,0.1l-0.1-0.3l-0.2-0.2h0.1l0.2,0.1l0.1,0.3L678.5,483.4l-0.1-0.1v-0.1
                v-0.1l-0.1-0.1l0,0l0.1,0.1v0.1L678.5,483.4z"/>
            <linearGradient id="IQ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="301.8079" x2="0.1864" y2="301.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IQ" class="stcustom0 st106" d="M600.7,351.1L600.7,351.1l0.1,0.3l0.2,0.9l0.1,0.1l0.2,0.1l0.1,0.1v0.1v0.2v0.2l0.1,0.2l0.1,0.1
                l0.1,0.1h0.1h0.1l0.1,0.1l0.3,1v0.1h0.1h0.2l0.2,0.1l0.2,0.2l0.2,0.3l0.1,0.1h0.5l0.6,0.1l0.3,0.2v0.1l-0.2,0.1l-0.4,0.1l-0.1,0.2
                l-0.1,0.3v0.2l0.1,0.2l0.3,0.4v0.1l0.1,0.2l0.1,0.1l-0.1,0.2l-0.3,0.2l-0.3,0.2l-0.7,0.8l-0.1,0.2v0.5l0.2,0.1H603h-0.2v0.2
                l-0.1,0.2l-0.1,0.2l0.2,0.4v0.2v0.2l-0.2,0.4l-0.1,0.2v0.1l0.2,0.1l0.6,0.8l0.2,0.3l0.2-0.1h0.1h0.1v0.1v0.1l-0.1,0.2l0.3,0.1
                l0.1,0.2l0.4,0.6v0.2l-0.2,0.3v0.2v0.2l0.1,0.1h0.5l0.2,0.1l0.5,0.3l0.6,0.5l0.5,0.4l0.4,0.3h0.5l0.1,0.1l0.1,0.1l0.1,0.3l0.3,0.6
                l0.2,0.2l0.3,0.5l0.3,0.5l-0.2,0.6l-0.2,0.7v0.8v0.5h0.4h0.5v0.5v0.5v0.6h0.1l0.2,0.1l0.1,0.2l0.1,0.1h0.1l0.1,0.1l0.1,0.2l0.1,0.1
                v0.1v0.2l0.1,0.2l0.1,0.1l0.2,0.1l-0.3,0.1l-0.3-0.1l-0.1-0.1H610l-0.3,0.1v0.1l-0.6-0.3l-0.2-0.1h-0.1h-0.4l-0.5,0.1l-0.3,0.1
                l-0.2,0.1l-0.1,0.1v0.1l-0.2,0.4l-0.2,0.5l-0.2,0.4l-0.4,0.6l-0.2,0.3l-0.5,0.5l-0.5,0.1l-1.1-0.1l-1.3-0.1l-1.3-0.1l-0.9-0.1h-0.1
                l-0.9-0.7l-0.7-0.6l-0.9-0.7l-0.9-0.8l-0.9-0.8l-0.7-0.6l-0.8-0.7l-0.8-0.7l-0.6-0.5l-0.8-0.5l-0.6-0.4l-0.9-0.5l-0.7-0.4l-0.6-0.4
                l-0.9-0.6l-0.3-0.1l-1-0.2l-0.9-0.2l-0.9-0.2l-0.6-0.1l0.4-0.4l-0.1-0.4l-0.3,0.1l-0.3,0.1l-0.2-0.6l0.2-0.1l-0.2-0.7l-0.2-0.7
                l-0.2-0.7l-0.2-0.7l0.8-0.5l0.6-0.4l0.8-0.5l0.8-0.5l0.8-0.5l0.8-0.5l0.8-0.5l0.7-0.2l0.1-0.1l0.3-0.6l0.3-0.5v-0.1v-0.8v-0.9
                l0.1-0.5l0.2-0.4l0.1-0.3v-0.3v-0.3L591,355l-0.2-0.5v-0.4v-0.2l0.1-0.4l0.2-0.3l0.2-0.2l0.7-0.2l0.4-0.1l0.5-0.5l0.3-0.3l0.4-0.5
                l0.3-0.3V351l0,0l0.3-0.1l0.5-0.4l0.3-0.4h0.1l0.3,0.1h0.2l0.4-0.1l0.3,0.1l0.2,0.1h0.1l0.6,0.2h0.1h0.3h0.5l0.3-0.2l0.2-0.2h0.1
                h0.1l0.1,0.1l0.1,0.1v0.2v0.5v0.1l0.1,0.1h0.1l0.1-0.1l0.2-0.2l0.3-0.2l0.2-0.2l0.1-0.1h0.2L600.7,351.1L600.7,351.1z"/>
            <linearGradient id="IR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="299.7574" x2="0.1864" y2="299.7574" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IR" class="stcustom0 st107" d="M600.9,342.1L600.9,342.1l0.5,0.8l0.2,0.2l0.1,0.2l0.1,0.2l0.1,0.1l0.2,0.1l0.2,0.2l0.2,0.2l0.3,0.3
                l0.3,0.1l1,0.2l0.5,0.1h0.2l0.4-0.2h0.5h0.2l0.6-0.7l0.2-0.2l0.4-0.1l0.2-0.3l0.3-0.3l0.4-0.3l0.4-0.3l0.3-0.2l0.5-0.4l0.3-0.1h0.3
                l0.4,0.4l0.4,0.4l0.1,0.2l-0.2,0.2l-0.3,0.2l-0.1,0.1v0.1v0.1v0.1l0.4,0.3v0.1v0.1v0.1l-0.1,0.1l-0.3,0.1l-0.2,0.1l-0.1,0.1
                l-0.1,0.1v0.1l0.1,0.1l0.5,0.3l0.1,0.1l0.1,0.2l0.1,0.1h0.2l0.1,0.1l0.5,0.6h0.1l0.6-0.1h0.1v0.2l0.1,0.9l0.1,0.5l0.1,0.4l0.2,0.4
                l0.2,0.4l0.3,0.2l0.6,0.3l0.3,0.1l0.7,0.1l0.7,0.1l0.4,0.1l0.1,0.1l0.1,0.1l0.3,0.7l0.5,0.5l1.1,0.7l0.5,0.2l1.8,0.4h1.2l3.3-0.9
                l1.1-0.2h0.4l-0.2,0.2l-0.4,0.1l0.2,0.1h0.4h0.2l0.1-0.1v-0.2v-0.2l-0.2-0.8l-0.1-0.6h0.8l0.3-0.1l0.4-0.2l0.3-0.1h0.2l0.2-0.1
                l0.1-0.1l0.3-0.8l0.1-0.2l0.5-0.4l0.4-0.3l0.4-0.2l0.6-0.2h0.7l0.6,0.1h0.3h0.2h0.1l0.1-0.1l0.1-0.3l0.1-0.1l0.2-0.1h0.3h0.4h0.3
                l0.4,0.1h0.5h0.3l0.2,0.1l0.1,0.2l0.1,0.1v0.2v0.2l0.1,0.1l0.2,0.1l0.3,0.1l0.5,0.1l0.5,0.2l0.3,0.1l0.4,0.2l0.4,0.4l0.2,0.1h0.2
                h0.1l0.3-0.2l0.3,0.1h0.1l0.3-0.1l0.3,0.1l0.9,0.5h0.1h0.1l0.1,0.1v0.1l0.1,0.4l0.2,0.3l0.3,0.3l0.4,0.1l0.7,0.3l0.3,0.3l0.3,0.5
                l0.4,0.6l0.1,0.1h1h1.2l0.1,0.2v0.5v0.5l0.1,0.3v0.3l-0.1,0.2l-0.1,0.2v0.1l0.1,0.1l0.1,0.3v0.4l-0.1,0.2v0.2l0.1,0.1l0.1,0.2v0.1
                l-0.1,0.1l-0.1,0.2l-0.1,0.2v0.2l-0.1,0.1l-0.1,0.1v0.2l0.1,0.2l0.1,0.2l-0.1,0.1v0.2l-0.1,0.3v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1v0.2
                l-0.1,0.1l-0.2,0.2l-0.1,0.1h-0.2v0.1v0.1l0.1,0.1l0.1,0.2l0.2,0.3h-0.7l-0.2,0.3l-0.2,0.4l0.1,0.9l-0.1,0.4l0.1,0.2l0.2,0.2
                l0.2,0.1h0.4l0.3,0.1v0.1l-0.2,0.2l-0.3,0.3l-0.1,0.1l-0.4,0.6v0.3v0.2l0.2,0.7l0.2,0.6l0.3,1.2v0.3l-0.1,0.6v0.4v0.5v0.2l0.1,0.5
                h0.1l0.7,0.1l0.7,0.1l0.9,0.1l0.3,0.3l0.2,0.7v0.5l-0.1,0.3l-0.6,0.8l-0.7,1l-0.6,0.8l-0.7,0.8l-0.1,0.1l0.5,0.6l0.3,0.4l0.5,0.5
                l0.1,0.1v0.2l0.5,0.8l0.2,0.4l0.2,0.3l0.4,0.4l0.4,0.4l0.4,0.2h0.3l0.6,0.2l0.2,0.2l0.4,0.4l0.4-0.1h0.1l0,0v0.1l-0.1,0.6l0.1,0.6
                l0.1,1v0.2l-0.1,0.3v0.2v0.1l0,0l0.1,0.1h0.3l0.7-0.1h0.1l0.2,0.1l0.1,0.2v0.1l-0.2,0.1v0.2l0.1,0.4l0,0l-0.2,0.1l-0.1,0.5l0,0
                l-0.2,0.1h-0.9h-0.1l-0.3,0.1l-0.6,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.2v0.2l0,0h-0.3l-0.1,0.2l-0.6,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.5
                l-0.1,0.5v0.1l-0.2,0.2v0.1v0.2l-0.1,0.3l-0.1,0.9l-0.1,0.3h-0.2l-0.1,0.1l-0.2,0.2l-0.5-0.1l-0.4-0.1l-1.2-0.3l-0.1-0.1l-0.1-0.3
                l-0.2-0.1l-0.3,0.4l-1.1-0.2l-0.4,0.1l-0.2-0.1h-0.6l-0.4-0.2l-0.6,0.2h-0.5l-0.7-0.4l-0.7-0.1h-0.6h-0.3L638,389l-0.2-0.2l-0.4,0.1
                l-0.2-0.2l-1.1-0.2l-0.2-0.4l-0.2-0.4v-0.4l-0.3-0.7l-0.1-1l-0.1-0.4l-0.2-0.3l-0.2-0.3l-0.3-0.3l-0.2-0.1l-1-0.2h-0.2l-0.5,0.1
                l-0.5,0.3l-0.8,0.2l-0.2,0.1l-0.2,0.3l-0.3,0.2h-0.4l-0.4,0.2l-0.6,0.5l-0.4,0.2h-0.3l-0.3-0.2l-0.8-0.3l-0.5-0.1l-0.7,0.1l-0.3-0.1
                l-0.6-0.4l-0.1-0.3l-0.3-0.2l-1-0.4l-0.8-0.6l-0.2-0.2l-0.1-0.3l-0.4-0.4l-0.8-0.3l-0.5-0.3l-0.5-0.1h-0.5l-0.2-0.1l-0.2-0.1
                l-0.7-0.7v-0.3l-0.4-0.7l-0.1-0.2l-0.1-0.7l-0.1-0.2l-0.4-0.3l-0.1-0.2l0.1-0.2v-0.2l-0.2-0.2l-0.3-0.1l-0.1-0.2l0.1-0.4l-0.1-0.3
                l-0.3-0.4l-0.4-0.4l-0.4-0.6l-0.2-0.2l-0.1-0.4l-0.2-0.5h-0.2l-1.3,0.5l-0.4-0.3l-1.1-0.6l-0.1-0.1l-0.1-0.1l0.1-0.1h0.1l0.3,0.1
                l0.2-0.1l-0.1-0.2l-0.3-0.1h-0.4l0.1,0.2l-0.3,0.2l-0.1,0.2v0.3v0.4l-0.1,0.2h0.2h-0.5l-0.2,0.2h-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2
                v-0.2v-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.2l-0.2-0.1h-0.1V373v-0.5V372h-0.5h-0.4v-0.5v-0.8l0.2-0.7l0.2-0.6
                l-0.3-0.5l-0.3-0.5l-0.2-0.2l-0.3-0.6l-0.1-0.3l-0.1-0.1l-0.1-0.1h-0.5l-0.4-0.3l-0.5-0.4l-0.6-0.5l-0.5-0.3l-0.2-0.1H605l-0.1-0.1
                v-0.2V365l0.2-0.3v-0.2l-0.4-0.6l-0.1-0.2l-0.3-0.1l0.1-0.2v-0.1v-0.1h-0.1h-0.1l-0.2,0.1l-0.2-0.3l-0.6-0.8l-0.2-0.1V362l0.1-0.2
                l0.2-0.4v-0.2V361l-0.2-0.4l0.1-0.2l0.1-0.2V360h0.2h0.2l0.1-0.1v-0.5l0.1-0.2l0.7-0.8l0.3-0.2l0.3-0.2l0.1-0.2l-0.1-0.1l-0.1-0.2
                v-0.1l-0.3-0.4l-0.1-0.2v-0.2l0.1-0.3l0.1-0.2l0.4-0.1l0.2-0.1v-0.1l-0.3-0.2l-0.6-0.1h-0.5l-0.1-0.1l-0.2-0.3l-0.2-0.2l-0.2-0.1
                h-0.2h-0.1v-0.1l-0.3-1l-0.1-0.1h-0.1h-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2V353v-0.2v-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1l-0.2-0.9l-0.1-0.2
                l0,0l0,0v-0.2l0.1-0.2v-0.1l-0.2-0.2l-0.3-0.2H601v-0.2V350v-0.2l-0.1-0.1v-0.1l0.1-0.2l-0.8-0.5l-0.5-0.3l-0.2-0.1h-0.3v-0.1v-0.2
                l0.1-0.3l0.2-0.2l0.1-0.1l0.1-0.2v-0.2l0.2-0.2v-0.1l-0.1-0.1h-0.2h-0.2h-0.1v-0.1v-0.5v-0.3l-0.1-0.2v-0.5v-0.3l-0.2-0.3l-0.1-0.2
                v-0.1v-0.1V344v-0.1l-0.2-0.1l-0.1-0.1v-0.1v-0.2l-0.1-0.1v-0.1l0.1-0.1h0.2h0.3h0.3l0.2-0.1l0.2-0.9l0.2-0.2l0.2-0.1l0.4,0.3
                L600.9,342.1L600.9,342.1L600.9,342.1z M632.8,384.8l-0.3,0.4l-0.4,0.3l-0.2-0.1h-0.1l-0.3,0.1h-0.2l-0.4,0.2l-0.3,0.1h-0.2h-0.1
                v-0.1v-0.1h0.1l0.5-0.2l0.6-0.3l0.1-0.1l-0.1-0.2v-0.1l0.4,0.1l0.5-0.2l0.4-0.1l0.2,0.2L632.8,384.8L632.8,384.8z"/>
            <linearGradient id="IS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="444.2078" x2="0.1864" y2="444.2078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IS" class="stcustom0 st108" d="M431.5,213.2h0.3l0.5-0.2l0.2-0.2l0.5-0.5l0.3-0.2h0.5h0.2v0.1l-0.3,0.2l-0.2,0.1l-0.3,0.3l-0.3,0.7
                l-0.2,0.4v0.2l0.3,0.3l0.3,0.2l0.3-0.1l0.1,0.1l0.1,0.2l0.1,0.2v0.2l-0.1,0.4l-0.2,0.4l-0.2,0.4v0.1l0.2,0.1l0.9-0.2h0.1l0.1,0.1
                v0.2l0.1,0.2l0.1,0.2v0.2l-0.4,0.6l0.5-0.4l0.4-0.1l0.6,0.2l0.3,0.2l0.2,0.4l0.2-0.1h0.1l0.1,0.2v0.2l-0.1,0.3v0.3l-0.1,0.1
                l-0.2,0.1l-0.1,0.1l0.1,0.2l0.1,0.2h0.2v0.1v0.1v0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l0.5,0.3l0.1,0.1v0.2v0.2l-0.1,0.2l-0.1,0.1h-0.4
                l-0.2,0.1l0.1,0.2v0.3l-0.1,0.3L436,223l-0.3,0.3l-0.3,0.2l-0.5-0.1l-0.2-0.1v0.4l-0.3,0.3v0.2l0.1,0.1v0.3l-0.1,0.3l-0.2,0.3
                l-0.2,0.2l-0.5,0.2l-0.4,0.4l-0.3,0.2h-0.7l-0.7,0.2l-0.9,0.5l-0.6,0.4l-0.5,0.5l-0.7,0.8l-0.5,0.3l-0.3,0.1l-0.5,0.1l-0.5,0.2
                l-1.5,0.4l-0.5,0.2l-0.1,0.2l-0.2,0.3v0.1l0.1,0.1v0.1l-0.2,0.3l-0.4,0.2h-0.2l-0.2-0.2h-0.1l0,0v0.1l0.1,0.3l-0.2,0.1l-1,0.3
                l-1.7-0.2l-0.7-0.2l-0.8-0.4l-0.5-0.1h-0.7l-0.6-0.5l-0.3-0.3V230v-0.2l0.1-0.1l0.1-0.1h0.2l0,0l-0.1-0.3l-0.1,0.1l-0.4,0.4h-0.2
                l-0.2-0.2v-0.2l-0.4-0.1l-0.4-0.2l-0.4-0.3l-0.1-0.1l0.2-0.2l0,0h-0.1l-0.3,0.1l-0.4,0.4l-0.2,0.1l-2.6,0.1h-0.7l-0.1,0.1L411,229
                l-0.1-0.6V228v-0.2l0.1-0.2h0.1l0.1,0.2l0.1,0.3l0.1,0.1l0.9-0.3l0.4-0.2l0.2-0.2l0.2-0.3l0.2-0.2l0.1-0.2l0.2-0.5l0.1-0.2l0.2-0.2
                l0.2-0.1l0.4-0.1l-0.3-0.1h-0.3l-0.9,0.5h0.2V226l0.1-0.2l0.3-0.3h-0.2l-0.1-0.1v-0.3l0.1-0.4l0.7-0.5l0.2-0.1l0.1-0.1l-0.1-0.1
                l-0.1-0.1l-0.7,0.6l-0.5,0.2h-0.2l-0.3-0.2l-0.1-0.1l-0.1-0.2V224l0.2-0.4v-0.1h-0.2l-0.4-0.4h-0.7l-1.8-0.2L409,223l-0.6,0.3
                l-0.4,0.1l-0.2-0.1l-0.2-0.2l-0.1-0.2l-0.1-0.3l0.1-0.2l0.2-0.1l0.2-0.1l0.5,0.1l0.6-0.2h0.4h0.1l0.2-0.2l0.1-0.1l0.2,0.1l0.1,0.2
                l0.6-0.2l0.2-0.1v-0.1l0.1-0.1l0.3,0.1h0.2l0.3-0.1h0.5h1.2l0.2-0.2l0.1-0.2l0.1-0.5v-0.1l-0.7,0.4H413l-0.8-0.2l-0.3-0.2l0.1-0.2
                l0.4-0.4l0.5-0.3l0.7-0.4l0.2-0.1V219l-0.5-0.3l-0.9,0.1l-0.2-0.4l-0.7-0.2l-0.5,0.1l-0.3-0.2l-0.6,0.3l-1.4,0.4l-0.5,0.3l-0.3,0.1
                l-0.3-0.3l-0.6-0.3l-0.6-0.1l-0.1-0.2l0.4-0.5l0.3-0.1h0.3l0.5,0.4l0.3,0.1l-0.4-0.5v-0.2v-0.3l-0.1-0.1l-0.1-0.3l0.1-0.1h0.2
                l0.3,0.1l0.8,0.6l0.4-0.1l0.2-0.2l0.3-0.2l-0.1-0.1H409l-0.4-0.1l-0.2-0.2l-0.2-0.3l0.1-0.1l0.2-0.1h0.6l-0.4-0.5l-0.3-0.3v-0.1
                v-0.2v-0.1l0.1-0.1l0.7,0.3h0.2l-0.1-0.2l-0.3-0.3v-0.1l0.1-0.1l0.1-0.2V214l0.2-0.1h0.2l0.2,0.1l0.7,0.6l0.1,0.2v0.2v0.3v0.1
                l0.3-0.1l0.2,0.1h0.1l0.3-0.4l0.2,0.1l0.1,0.2v0.2v0.2v0.5v0.1l0.2-0.3h0.3v-0.1v-0.5V215v-0.1l-1-0.6l-0.2-0.1l-0.2-0.3v-0.1
                l0.2-0.1l0.3-0.1h0.7l0.1-0.1l-0.1-0.1l-0.3-0.1l-0.1-0.1V213l-0.4,0.1h-0.4l-0.4-0.1v-0.1l0.2-0.2l0.3-0.3l0.2-0.1l0.5,0.1l0.5-0.1
                l0.4,0.1l0.3,0.3l0.4,0.5l0.6,0.3l0.1,0.1l0.3,0.3l0.6,0.8l0.6,0.4v0.1l-0.1,0.1l-0.2,0.2l0.1,0.1l0.3,0.1l0.2,0.3v0.1l-0.2,0.9
                l-0.1,0.2l-0.1,0.1l-0.6-0.2l0.1,0.3l0.4,0.3l0.1,0.2l-0.1,0.2l0,0l0.1-0.1l0.1,0.1v0.3l-0.1,0.2l-0.1,0.2v0.1h0.2h0.1l0.2,0.3
                l0.2,0.8l0.1,0.3l0.1-0.2l0.1-0.6l0.1-0.3h0.1l0.1-0.1l0.1-0.2l0.1-0.6l0.4-0.5l0.2-0.1h0.2l0.1,0.1l0.3,0.5l0.2,0.1h0.1l0.1-0.3
                l0.2-0.7v-0.7l-0.1-0.8v-0.6l0.2-0.4l0.2-0.1l0.3,0.1l0.2,0.2l0.4,0.8l0.3,0.4l0.3,0.5l0.2,0.1l0.3,0.1h0.1l0.1-0.1v-0.2l-0.1-1.2
                l0.1-0.4l0.1-0.3l0.5-0.2l0.3-0.2l0.3-0.3l0.2-0.1h0.2l0.2,0.1l0.2,0.2l0.3,0.4l0.4,0.7l0.5,0.5l0.3,0.9l0.1,0.1h0.1l0.1-0.1v-0.2
                v-0.4l-0.1-0.5l-0.5-1.3v-0.2l0.1-0.2h0.3l0.8,0.1l0.2,0.2l0.5,0.8l0.1,0.2h0.1v-0.1l0.2-0.1l0.1-0.2l0.2-0.4l0.5-0.8h0.1l0.1,0.1
                l0.3,0.2l0.1,0.2l0.2,0.1h0.3l0.3-0.3l0.4-0.2l0.1-0.4v-0.2L429,212l0.1-0.2l0.7-0.3h0.6l0.1,0.1l0.4,0.6l0.3,0.3l0.1,0.2v0.5
                l0.2,0.2L431.5,213.2z"/>
            <linearGradient id="IT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="332.8578" x2="0.1864" y2="332.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="IT" class="stcustom0 st109" d="M513.5,315.8l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.1,0.2l0.1,0.1l0.1,0.1h0.1h0.1
                l0.1,0.1l0.2,0.1v0.1l-0.1,0.1l-0.2,0.2l-0.2,0.2v0.1v0.1l0.1,0.1h0.3v0.1l-0.1,0.5v0.1l0.2,0.1l0.2,0.1l0.3,0.3l0.1,0.3l-0.1,0.1
                h-0.2h-0.2l0.2-0.2l-0.4-0.6H513l-0.3,0.2l-0.7-0.2l-0.1,0.1l-0.1,0.2l-0.3,0.2l-0.4,0.1l-0.4,0.3l-0.4,0.2l-0.3,0.1h-0.2l0.3-0.3
                H510l-0.4,0.2l-0.2,0.2l-0.1,0.3l-0.1,0.5l0.2,0.1l0.3,0.7l0.4,0.3l-0.1,0.3l-0.1,0.2l-0.2,0.2l-0.2-0.1h-0.1l-0.1,0.4l0.2,1.2
                l0.3,0.8l0.3,0.4l0.6,0.5l0.6,0.3l1.1,0.9l0.6,0.3l0.2,0.2l0.4,0.7l0.3,0.8l0.3,1.3l0.2,0.6l0.5,0.7l1,1l0.9,0.7l0.8,0.4l0.7,0.1
                l1.6-0.1h0.3l0.3,0.1l0.1,0.3l-0.1,0.2l-0.3,0.2l-0.3,0.3v0.4l0.3,0.3l1.5,0.8l1.5,0.6l0.5,0.3l0.6,0.5l1.3,0.7l0.2,0.3l0.8,0.7
                l0.4,0.5l0.1,0.4l-0.2,0.4l-0.1,0.3l-0.1,0.3l-0.3-0.1l-0.4-0.3l-0.6-1.3l-1.1-0.1l-0.2-0.1l-0.4-0.2v-0.1l-0.1-0.2l-0.1-0.1h-0.4
                l-0.3,0.2l-0.3,0.5l-0.4,0.7l-0.4,1v0.4l0.2,0.4l0.6,0.2l0.5,0.4l0.3,0.4v0.9l0.1,0.5l-0.2,0.3l-0.4-0.1l-0.5,0.2l-0.4,0.3l-0.2,0.3
                v0.8l-0.1,0.3l-0.7,0.6l-0.4,0.6l-0.1,0.2l-0.1,0.3h-0.9l-0.2-0.3v-0.5l0.2-0.3l0.3-0.1l0.2-0.6l-0.1-0.5l0.1-0.2l0.1-0.1l0.3-0.1
                l0.4-0.1v-0.7l-0.3-0.3l-0.1-0.4l-0.1-0.8l-0.5-1l-0.3-0.9l-0.2-0.4l-0.3-0.2h-0.5l-0.3-0.1l-1-0.6l-0.1-0.1v-0.2l0.2-0.2l-0.1-0.3
                l-0.1-0.3l-0.2-0.3l-0.2-0.1l-0.4,0.1l-0.2,0.1h-0.3l-0.2,0.1h-0.1l0.3-0.5l-0.1-0.1l-0.3-0.2h-0.5h-0.1l-0.1,0.1l-0.1-0.1V338
                l-0.5-1l-0.4-0.4l-0.2-0.1l-0.3,0.1l-0.5-0.2h-0.3h-0.2l-0.3,0.1l-0.1-0.1v-0.1l-0.5-0.4l-0.6-0.2l-1.2-1.3l-0.4-0.5l-0.8-0.5
                l-0.5-0.8l-0.4-0.3l-0.6-0.2h-0.1l-0.2,0.1h-0.1l-0.1-0.1l0.1-0.1h0.1v-0.3l-0.6-0.8l-0.4-0.2l-0.1-0.2l-0.1-0.2v-0.4l-0.2-0.1h-0.2
                l-0.2-0.1v-0.4V329v-0.2l-0.2-0.6l-0.4-0.5l-0.2-1.3l-0.2-0.4l-0.4-0.3l-0.9-0.3l-1.2-0.9h-0.3l-0.7-0.3l-0.5-0.1l-0.6,0.3l-0.7,0.8
                l-0.6,0.8l-0.2,0.2l-0.8,0.3l-0.7,0.1v-0.2v-0.2l0.1-0.2l0.2-0.2l0.2-0.3l0.1-0.2v-0.1l-0.1-0.2h-0.1l-0.6,0.2h-0.1l-0.5-0.2
                l-0.5-0.3l-0.2-0.2l-0.1-0.2l0.1-0.1v-0.1l-0.1-0.2l0.1-0.2l0.2-0.3l0.1-0.2h0.1l0.1-0.1l-0.1-0.4l-0.1-0.1l-0.1-0.1h-0.1l-0.2-0.1
                l-0.2-0.2V322l-0.1-0.2l-0.2-0.2v-0.2l0.2-0.1h0.2h0.2l0.4-0.3h0.1l0.1-0.1l0.1-0.4l0.1-0.1v-0.1l-0.1-0.1l-0.3-0.3l-0.1-0.3
                l-0.2-0.4l-0.2-0.2v-0.1v-0.2v-0.1l0.4-0.2l0.2-0.2l0.1,0.1l0.2,0.1l0.6-0.1l0.3-0.1l0.2-0.1h0.2l0.5,0.2l0.2-0.1l0.4-0.3l0.1-0.1
                l0.3-0.4v-0.1L498,317v-0.1l0.4-0.3l0.2-0.3l0.2-0.2h0.1v0.1v0.1v0.5l0.1,0.1l0.3,0.3l0.2,0.2l0.5,0.1v0.1l-0.1,0.3l0.3,0.3l0.1,0.2
                l0.1,0.1l0.2-0.1l0.1-0.1l-0.1-0.2l-0.1-0.2v-0.1l0.1-0.1l0.1-0.2l0.4-0.5l0.1-0.3v-0.4V316l0.1-0.1l0.3,0.1h0.1v0.2l0.1,0.3
                l0.1,0.2h0.1h0.2l0.4-0.2l0.3-0.1h0.2l0.1,0.1l0.2,0.4h0.1h0.1v-0.1v-0.1l-0.1-0.3l-0.1-0.2l-0.1-0.1V316l0.1-0.3l0.1-0.2l0.1-0.1
                h0.2l0.2,0.2l0.3,0.1h0.2v-0.1v-0.1l-0.1-0.2V315l0.1-0.5h0.1h0.3h0.3l0.2,0.2l0.2,0.1h0.3h0.2l0.1-0.1l0.1-0.3l0.2-0.3l0.3-0.2h0.5
                l0.3-0.1h0.3h0.2h0.2l0.5-0.2l0.6-0.2h0.1v0.1l-0.1,0.1l-0.1,0.2l0.1,0.2l0.3,0.4l0.2,0.3l0.2,0.2l0.3,0.1l0.3,0.1h0.3l0.3,0.1
                l1,0.2l0.5,0.1h0.4L513.5,315.8L513.5,315.8z M510.1,326.2l0.1-0.2v-0.1H510l-0.1,0.2l0.1,0.2L510.1,326.2L510.1,326.2z M510,333.8
                L510,333.8L510,333.8L510,333.8L510,333.8L510,333.8z M504.3,330.2l0.1,0.1v0.1l-0.1,0.1v0.2l-0.2-0.2l-0.4,0.1h-0.2l-0.1-0.2v-0.1
                h0.3h0.1h0.2L504.3,330.2z M502.1,337.6l0.1,0.2l0.3,1v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.5v0.4l0.1,0.2v0.3l-0.1,0.3l-0.2,2.1l-0.1,0.4
                l-0.1,0.3l-0.2,0.1l-0.3-0.1l-0.3-0.2h-0.2l-0.2,0.1l-0.1-0.1l-0.1-0.1l-0.1,0.7l-0.2,0.3l-0.2,0.2h-0.2l-0.2-0.1h-0.2l-0.1-0.1
                l-0.1-0.2l-0.2-0.3l-0.2-0.3v-0.3v-0.7l0.1-0.1l0.1-0.1v-0.3V342l0.1-0.1l0.1,0.1h0.1v-0.1v-0.3l-0.1-0.2l-0.2-0.1v-0.2v-0.2
                l0.1-0.1v-0.2V340l-0.2-0.2l-0.1-0.3l-0.1-0.2l-0.2-0.2l-0.2-0.2l-0.1-0.2v-0.4l0.1-0.4l0.1-0.2h0.1l0.2,0.2h0.1h0.3l0.3-0.1
                l0.4-0.2l0.3-0.2l0.5-0.6l0.3-0.1l0.2-0.2l0.1-0.2l0.1-0.1l0.2,0.2h0.2l0.3,0.2l0.1,0.2l0.1,0.2l0.1,0.1h0.1l0,0h-0.1l-0.1,0.2
                l0.1,0.1L502.1,337.6L502.1,337.6z M498.3,337l-0.1,0.2h-0.1l0.1-0.1l0.1-0.3l0.1-0.1l0.1,0.1L498.3,337L498.3,337L498.3,337z
                M514.2,338.3L514.2,338.3H514v-0.1v-0.1l0.3,0.1L514.2,338.3L514.2,338.3L514.2,338.3z M498.9,344.2l-0.2,0.4l-0.2-0.3v-0.2V344
                l0.2,0.1L498.9,344.2L498.9,344.2z M518.8,347.3l-0.2,0.4l-0.1,0.2l-0.7,1l-0.1,0.2v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.3v0.3v0.1l0.1,0.1
                l0.1,0.1l0.1,0.1l-0.2,0.1l0.2,0.2l0.1,0.1v0.1v0.1l-0.3,0.3l-0.1,0.2l-0.1,0.2v0.2v0.2v0.2H517l-0.3-0.1h-0.3l-0.5-0.2h-0.2
                l-0.2-0.1l-0.4-0.6l-0.3-0.3l-0.3-0.2h-0.3h-0.3l-0.3-0.1l-0.6-0.4l-0.6-0.3l-0.3-0.2L512,350l-0.1-0.1l-0.4-0.1l-0.3-0.2h-0.1h-0.3
                h-0.2l-0.2-0.1l-0.3-0.3l-0.2-0.4l-0.1-0.2l0.1-0.4l0.2-0.4l0.2-0.1l0.2-0.1l0.1-0.1l0.1-0.1l0.3,0.4l0.1,0.1h0.1l0.3-0.2v-0.2
                l0.3-0.2h0.4h0.2l0.1,0.2l0.1,0.1h0.2l0.5,0.4l0.2,0.1h0.2l0.4-0.2l0.3-0.1l0.7,0.1l0.4-0.1h0.3l0.4-0.1l0.3-0.2l0.1-0.1h0.2h0.4
                l0.4,0.1l0.2-0.1l0.1-0.2l0.2-0.1h0.2l0.4-0.3h0.2l0.2,0.1L518.8,347.3L518.8,347.3z M508.9,352.4h-0.1l-0.2-0.1v-0.2l0,0l0.2,0.1
                L508.9,352.4L508.9,352.4L508.9,352.4z"/>
            <linearGradient id="JE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="361.4079" x2="0.1864" y2="361.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="JE" class="stcustom0 st110" d="M469.4,304.5v0.2h-0.1l-0.1-0.1H469h-0.2v-0.4h0.4L469.4,304.5z"/>
            <linearGradient id="JM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.4579" x2="0.1864" y2="254.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="JM" class="stcustom0 st111" d="M258.3,410.4l0.3,0.1l0.4,0.1h0.2h0.1l0.3,0.3l0.3,0.1l1,0.3l0.3,0.5l0.1,0.2l-0.3,0.1h-0.3h-0.3
                l-0.3-0.1l-0.1-0.1h-0.3l0.1-0.1h-0.1h-0.2l-0.1,0.2l-0.1,0.2H259l-0.1-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.4l-0.2-0.2l-0.2-0.2l-0.3-0.1
                h-0.6l-0.3-0.1l-0.2-0.3l-0.1-0.1l-0.2-0.1l-0.2-0.4l-0.1-0.1l-0.6-0.1l-0.4-0.1v-0.2l0.2-0.2l0.1-0.1h0.3l0.3-0.1l0.1-0.1l0.1-0.1
                l1.2,0.2h0.3L258.3,410.4z"/>
            <linearGradient id="JO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="295.2078" x2="0.1864" y2="295.2078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="JO" class="stcustom0 st112" d="M585,368.1l-0.4,0.4h-0.1l-0.5,0.2l-1.1,0.3l-0.7,0.2l-0.9,0.3l-0.8,0.2l-0.8,0.2l-0.7,0.2l0.4,0.4
                l0.6,0.7l0.4,0.5l0.5,0.6l0.4,0.5l0.5,0.5l-0.3,0.2l-0.5,0.3l-0.1,0.1v0.1l-0.2,0.5l-0.2,0.4l-0.1,0.1l-0.8,0.2l-0.8,0.2l-0.5,0.1
                l-0.1,0.1l-0.3,0.5l-0.3,0.5l-0.5,0.4l-0.6,0.5h-0.1l-0.4-0.1l-0.7-0.1l-0.7-0.1l-0.5-0.1l-0.6-0.1l0.1-0.4v-0.2l0.1-0.7l0.1-0.4
                V375l0.2-0.5v-0.2v-0.6v-0.1l0.1-0.3l0.2-0.5l0.2-0.4l0.1-0.2l0.2-0.4l0.2-0.5l-0.1-0.3v-0.1l0.1-0.3l0,0l0.1-0.5v-0.3l0.1-0.4
                l0.2-0.3l-0.1-0.7V368l0.1-0.4l-0.1-0.5v-0.7v-0.1l0.1-0.1l0,0l0.3-0.2h0.1l0.3,0.1l0.2,0.2l0.3,0.4l0.5,0.1l0.2,0.1l0.2,0.2
                l0.3,0.1l1,0.1l0.8-0.5l0.6-0.4l0.7-0.5l0.5-0.3l0.8-0.5l0.6-0.3l0.7-0.5l0.7-0.5l0.2,0.7l0.2,0.7l0.2,0.7l0.2,0.7l-0.2,0.1l0.2,0.6
                l0.3-0.1l0.3-0.1l0.1,0.4L585,368.1z"/>
            <linearGradient id="JP_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="309.3579" x2="0.1864" y2="309.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="JP" class="stcustom0 st113" d="M878.7,325.3h0.4h0.2l0.3,0.1l1.1,0.6l0.3,0.1h0.3h0.2l0.2-0.2l0.6-0.7l0.7-0.7h0.1v0.2v0.2l-0.3,0.6
                l-0.3,0.8l-0.1,0.4l0.1,0.4l0.2,0.3l0.2,0.4l0.2,0.6l0.3,0.1h0.1l0.3-0.2l0.3-0.2h0.2h0.2l-0.3,0.2l-0.3,0.2l-0.2,0.4l-0.1,0.1h-0.3
                h-0.2l-0.3,0.2l-0.3,0.2l-0.3,0.2l-0.3,0.1h-0.3l-0.5,0.2h-0.3l-0.6-0.2h-0.3l-0.6,0.4l-0.6,0.5l-0.5,0.6l-0.4,0.7l-0.2,0.4
                l-0.1,0.4v0.3v0.3l-0.1,0.2l-0.1,0.1l-0.4-0.1l-0.6-0.4l-1.1-0.5l-1.2-0.8l-0.7-0.4l-1.2,0.1l-1.2,0.8l-0.1-0.1l-0.5-0.5l-0.2-0.2
                l-0.3-0.1h-0.2h-0.2l-0.3,0.3l-0.1,0.2l-0.1,0.2v0.2v0.2l0.3,0.4l0.3,0.3h0.1h0.3h0.1l0.5,0.5l0.5,0.5l0.1,0.2l-0.2,0.2l-0.2,0.1
                h-0.3l-0.3-0.1l-0.4-0.2l-0.2,0.2l-0.3,0.4l-0.2,0.4l-0.1,0.2l-0.3,0.2l-0.3,0.1h-0.2l-0.1-0.1l-0.1-0.2v-0.2l0.1-0.4l0.2-0.4
                l0.1-0.4l-0.1-0.6l-0.1-0.1l-0.4-0.3l-0.2-0.3v-0.4v-0.2l0.1-0.5l0.1-0.3l0.2-0.1l0.2-0.1l0.3-0.2l0.3-0.2l0.3-0.3l0.3-0.3l0.2-0.4
                l-0.2-0.5l-0.1-0.3v-0.3l0.3-0.1l0.3,0.1l0.6,0.4h0.1h0.4l0.5,0.1h0.3l0.1-0.1l0.2-0.3l0.1-0.4V328v-0.5l0.1-0.4l0.4-0.7l0.1-0.4v-1
                l0.2-0.4l0.1-0.4l0.1-0.9l-0.2-0.9l-0.2-0.4l-0.2-0.4v-0.4l0.2-0.4v-0.1v-0.1l0.3-0.1l0.1-0.1l0.1-0.2l0.2-0.1l0.1,0.1l0.1,0.2
                l0.4,0.4l0.7,0.8l0.8,1.2l0.5,0.6l0.5,0.5l0.6,0.5l0.6,0.5l0.4,0.2l0.3,0.4L878.7,325.3L878.7,325.3z M871,320.5l-0.1,0.3l-0.1-0.4
                V320h0.1l0.2,0.1v0.2L871,320.5L871,320.5z M871.6,321.4h-0.2l-0.2-0.2V321l0.2-0.2l0.2,0.1l0.2,0.2l0.1,0.1L871.6,321.4
                L871.6,321.4z M866.5,333.1L866.5,333.1L866.5,333.1l-0.2-0.3l0.1-0.1l0.2-0.1h0.2L866.5,333.1L866.5,333.1L866.5,333.1z
                M871.5,335.8l0.1,0.1l0.5-0.2l-0.1,0.6l-0.1,0.6v1v0.4l0.1,0.4l0.2,0.3l0.3,0.2l0.4,0.7l0.2,0.8l0.2,0.4l0.1,0.4v0.2v0.2v0.3v0.2
                v0.7l-0.2,0.8v0.4l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.1,0.1h-0.1l-0.1,0.1v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.5v0.5L872,347
                l-0.3,0.1h-0.3l-0.4,0.2l-0.1,0.1l-0.3,0.6l-0.1,0.3v0.4l0.1,0.5l0.1,0.4l0.1,0.8l-0.1,1.2l-0.1,0.4l-0.2,0.3l-0.2,0.1l-0.1,0.2
                l-0.2,0.4l-0.3,0.8v0.2v0.2l-0.1,0.3v0.3v0.3l0.1,0.3l0.4,0.7l0.2,0.2l0.2,0.2l-0.7,0.2l-0.1,0.1l-0.4,0.4l-0.1,0.4v0.4l-0.1,0.2
                l-0.1,0.1l-0.1,0.1l-0.4,0.2l-0.3,0.2l-0.3,0.3l-0.1,0.2l-0.2-0.1l-0.1-0.1l0.1-0.2v-0.2l0.1-0.5l-0.1-0.2l0.2-0.2l0.1-0.3l0.2-0.2
                l0.2-0.2v-0.2l-0.1-0.2l-0.2-0.1h-0.2h-0.2l-0.1,0.2v0.2v0.1v0.1l-0.3,0.3v0.3l0.1,0.2l0.1,0.1v0.1l-0.2,0.2H867l-0.2-0.3l-0.3-0.2
                h-0.3l-0.3,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.2v0.5l-0.1,0.4l-0.2,0.4l-0.1,0.1l-0.2,0.2h-0.2l-0.1-0.1l-0.1-0.2l0.1-0.6V359l0.3-0.2
                l-0.2-0.2l-0.3-0.1l-0.4,0.1l-0.1,0.1l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.3,0.4l-0.2,0.5l-0.6-0.2H862h-0.3l-0.6-0.1l-0.6,0.1l-0.7,0.2
                v-0.1l0.6-0.3v-0.1l-0.1-0.2h-0.1l-0.4,0.1h-0.2l-0.1-0.2l-0.1-0.1l-0.1,0.1v0.3h-0.1l-0.1-0.1v-0.2L859,359v-0.2l0.1-0.2l-0.1-0.1
                h-0.1l-0.2,0.1l-0.2,0.1l-0.3,0.7l-0.1,0.4l0.2,0.3l0.6,0.4l0.1,0.1v0.2l-0.1,0.2l-0.2,0.1l-0.7,0.1l-0.6,0.3l-0.2,0.3l-0.5,1.1
                l-0.4,0.7l-0.6,0.2l-0.7-0.2l-0.2-0.3l-0.1-0.3l-0.3-0.3l-0.2-0.3l-0.1-0.4v-0.6L854,361l0.1-0.1l0.4-0.2l0.1-0.1l0.2-0.3l0.1-0.2
                v-0.2l-0.2-0.1h-0.4l-0.4,0.1l-0.3-0.1l-0.4-0.3l-0.1-0.1h-0.4l-0.3,0.1l-0.3,0.1h-0.3l-0.1,0.1l-0.4,0.4l-0.3,0.2l-0.3,0.1h-0.6
                l-0.3,0.1l-0.3,0.1h-0.1l-0.3,0.2l-0.4,0.1l-0.2,0.1l-0.3-0.1l-0.7,0.3h-0.3l-0.3-0.1l-0.3-0.2l-0.3,0.1l-0.2,0.3L846,362l-0.1,0.3
                v0.4l-0.2-0.1l-0.9-0.6h-0.1l-0.7,0.1l-0.2,0.1l-0.2,0.1l-0.3,0.1l-0.1-0.5l-0.2-0.2h-0.2l-0.2,0.1l-0.1-1v-0.1l0.1-0.2l0.1-0.1h0.4
                h0.4l0.3-0.1l0.2-0.2l0.2-0.3l0.3-0.2l0.4-0.2l0.3-0.2l0.3-0.3l0.3-0.3l0.3-0.2l0.3-0.2l0.4-0.5l0.6-0.5l0.2-0.4l0.1-0.1l0.5-0.2
                l0.7-0.2h0.3l0.3,0.3h0.2l0.2-0.1h0.3l0.3,0.1h0.3h0.3l0.7-0.1l0.3-0.1l0.3-0.2l1.2-0.1l0.8-0.3h0.1l0.1,0.1v0.2l-0.1,0.2l0.1,0.1
                l0.2,0.1h0.8h0.2l0.3-0.2l0.3-0.2l0.3-0.3l0.2-0.3l-0.2-0.4v-0.4l0.2-0.4l0.2-0.4l0.3-0.2l0.3-0.3l0.6-0.7l0.4-0.6l0.1-0.7l-0.1-0.9
                l0.3-0.6l0.3-0.1l0.7-0.3l0.3-0.1l0.1,0.1v0.2l-0.5,0.5l-0.3,0.2l-0.2,0.1l-0.2,0.1l-0.1,0.2l0.3,0.3l0.1,0.2v0.2v0.2l0.3,0.2
                l0.3,0.1h0.1l0.1-0.1l0.4-0.5l0.1-0.1l1.1-0.4l0.6-0.3l0.3-0.1l0.3-0.2l0.6-0.6l0.2-0.3l0.2-0.3l0.2-0.4l0.1-0.4l0.2-0.2l1-0.6
                l0.3-0.3l0.1-0.2l0.1-0.4l0.1-0.5l0.1-0.4l0.2-0.3l0.2-0.4l0.3-0.3l0.1-0.3l0.2-0.8l0.1-0.4l0.1-0.2l0.1-0.1l0.1-0.2l0.1-0.2v-0.2
                v-0.6v-0.5l-0.2-0.4l-0.1-0.1h-0.2h-0.2l-0.2-0.2v-0.1h0.2l0.1-0.1l0.1-0.1l0.2-0.4l0.1-0.5v-0.2l-0.1-0.4l-0.1-0.4v-0.2l0.1-0.3
                l0.2-0.2l0.2-0.1h0.2l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.4v-0.2l-0.1-0.6l0.1-0.2l0.1-0.1l0.2,0.1h0.2h0.2l0.2,0.1v0.1l0.1,1l0.1,0.1
                l0.1,0.1h0.1l0.1-0.2l0.1-0.2h0.2l0.5,0.2l0.2-0.2l0.1-0.2l0.1-0.4v-0.4l-0.1-0.1H871l-0.1,0.1l-0.1,0.1l-0.8,0.2v-0.4l0.2-0.6
                l0.1-0.2l0.1-0.1l0.3,0.1l0.2,0.1L871.5,335.8L871.5,335.8z M863.4,348.7h-0.3H863l0.2-0.1v-0.1l0.1-0.3v-0.1h-0.2v-0.3l0.2-0.3
                l0.4-0.5l0.1-0.1v0.2l-0.1,0.5v0.2h0.3l-0.2,0.6L863.4,348.7L863.4,348.7z M849.4,354.3l-0.1,0.1h-0.2l-0.1-0.2V354l0.3-0.2l0.2,0.3
                L849.4,354.3L849.4,354.3z M866.5,359.4v0.2h-0.1l-0.1-0.1v-0.2h0.2L866.5,359.4L866.5,359.4z M838.2,360.7l-0.1,0.2l-0.2-0.1
                l-0.1-0.1l0.2-0.5V360v-0.1l0.4-0.3l0.1,0.1v0.1v0.1v0.3L838.2,360.7L838.2,360.7z M853.8,360.9l-0.3,0.3h-0.3l-0.1-0.1v-0.2
                l0.3-0.2l0.2-0.4l0.2-0.2l0.2-0.1h0.1l-0.3,0.5L853.8,360.9L853.8,360.9z M852.2,360.2L852.2,360.2L852.2,360.2l-0.3,0.2v-0.1
                l-0.1-0.1v-0.1l0.4-0.1h0.1L852.2,360.2L852.2,360.2z M852.2,361l0.4,0.1h0.4v0.6l0.1,0.2l0.1,0.2l-0.1,0.3l0.2,0.1l-0.5,0.3
                l-0.5,0.4l-0.2,0.3l-0.2,0.3l-0.1,0.3l-0.1,0.3l-0.2-0.1l-0.5-0.5l-0.3-0.1l-0.5-0.1H850l-1,0.5l-0.1,0.4l-0.3,0.6l-0.1,0.2
                l-0.1,0.1l-0.1,0.1l-0.1,0.5l-0.3,0.3h-0.2l-0.3-0.1h-0.1l0.2-0.5l-0.3-0.1h-0.3v-0.3l-0.2-0.2l0.1-0.2v-0.2l0.1-0.1v-0.1v-0.1h-0.2
                l-0.1-0.1V364h-0.1l-0.3,0.1l-0.5,0.3h-0.1l0.2-0.2l0.5-0.2l0.2-0.1l0.5-0.4l0.3-0.2l0.2-0.3v-0.2l0.1-0.2l0.1-0.3l0.2-0.1l0.3-0.2
                h0.2l0.2,0.3l0.2,0.2h0.2l0.3-0.1h0.1h0.3l0.3-0.1l0.1-0.2v-0.2l-0.1-0.4h0.1h0.1l0.3-0.2l0.3-0.1h0.4l0.4,0.1L852.2,361L852.2,361z
                M837.9,361.4l-0.2,0.1l-0.1-0.2l0.1-0.6l0.3,0.1v0.2L837.9,361.4L837.9,361.4z M847.2,361.5l-0.1,0.1h-0.2l0.1-0.1l0.1-0.1h0.1
                L847.2,361.5L847.2,361.5L847.2,361.5z M846.3,362l0.1,0.1h0.3l0,0l-0.1,0.1l-0.1,0.1l-0.3-0.1H846V362v-0.1L846.3,362L846.3,362z
                M843.2,363.2l0.4,0.1h0.2h0.1l0.2-0.1l0.2-0.1h0.2l0.1,0.1l0.1,0.2v0.2l-0.3,0.4l-0.2,0.4l0.5,0.1h0.5l-0.1,0.2v0.2l0.2,0.1
                l0.1,0.1v0.1l-0.1,0.1l0.3,0.2v0.1l-0.1,0.1l-0.7,0.8l-0.2,0.4l-0.1,0.5l-0.1,0.3l-0.1,0.4l-0.1,0.4l-0.1,0.4v0.3v0.4l-0.3,0.9h-0.2
                l-0.3-0.1h-0.2l-0.1,0.2l0.2,0.4l-0.5,0.5l-0.6,0.3v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.4v-0.4l-0.2-0.5v-0.2l0.1-0.1h0.1v-0.1v-0.2
                l-0.1-0.1h-0.2H842l-0.1,0.2l-0.2,0.3l-0.1,0.3v0.2l0.1,0.2l0.2,0.3l-0.1,0.2l-0.1,0.1l-0.8-0.3h-0.2l-0.1-0.1l-0.2-0.4l0.3-0.1h0.1
                v-0.1v-0.4l-0.1-0.3l-0.1-0.1l-0.1-0.1l0.1-0.3v-0.3v-0.5l0.1-0.1l0.3-0.1l0.2-0.2l0.2-0.3l0.3-0.5l0.2-0.5h-0.2l-0.2-0.1l0.2-0.3
                l-0.1-0.3l-0.3-0.4l-0.2-0.5l-0.3-0.2l-0.1-0.1l-0.2,0.1l-0.1,0.1l0.1,0.3v0.3v0.3h0.1l0.2-0.1h0.1l0.1,0.1v0.2l-0.1,0.2l-0.1,0.1
                h-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.1l-0.3,0.2l-0.3,0.3l-0.2,0.2l0.1-0.2l0.1-0.3l-0.1-0.2l-0.3-0.3l-0.1-0.2v-0.2V365l0.3,0.2l0.1,0.3
                l0.2,0.1h0.3l-0.2-0.5l-0.1-0.1l-0.3-0.2l-0.4-0.3l-0.2-0.2l0.1-0.4l0.1-0.1h0.1l0.4,0.1v-0.2l-0.1-0.1v-0.1l0.3-0.2l0.4-0.1
                l0.1-0.1l0.1-0.1l0.1-0.1h0.3l0.3-0.1l0.2-0.3v-0.2l0.1-0.2l0.5-0.3h0.1h0.3l0.3,0.2l0.2,0.3l0.1,0.3L843.2,363.2L843.2,363.2z
                M839.4,362.7l-0.2,0.1l-0.1-0.1l0.1-0.3v-0.1l0.2,0.1V362.7z M838.5,364.5l-0.2,0.2h-0.1l0.1-0.2v-0.1l0.1-0.2l0.2-0.1h0.1
                l-0.2,0.3L838.5,364.5L838.5,364.5z M837.3,365.7L837.3,365.7l-0.2-0.3l-0.1-0.1l0.1-0.1l0.2-0.5v0.2l0.1,0.2h0.1l-0.1,0.2l-0.1,0.1
                V365.7L837.3,365.7z M867.6,365L867.6,365l-0.2-0.1v-0.1v-0.1h0.1l0.2,0.1L867.6,365L867.6,365z M836.2,365.9l0.1,0.1l0.2-0.1h0.1
                h0.1l0.1,0.2v0.1h-0.2h-0.1l-0.1,0.2l-0.2-0.1l-0.1-0.1v-0.1L836.2,365.9z M840.2,367.8l-0.2,0.1v-0.1l-0.1-0.1l0.2-0.2v-0.1
                l-0.1-0.1l0.1-0.4v-0.2l0.4-0.1l0.1,0.2v0.5L840.2,367.8L840.2,367.8z M841,367.1h-0.2h-0.1V367l0.3-0.2h0.3l-0.1,0.2L841,367.1
                L841,367.1z M839.1,369.6L839.1,369.6v-0.2l0.2-0.2v0.1L839.1,369.6z M842.6,373.7h-0.2v-0.2l0.2-0.4v-0.3l0.2-0.4l0.1-0.1h0.1
                l0.1,0.1l-0.1,0.5l-0.2,0.4L842.6,373.7L842.6,373.7z M841.7,374.2l-0.3,0.1l-0.2-0.1l-0.2-0.4l0.3-0.2l0.4,0.2l0.1,0.1L841.7,374.2
                L841.7,374.2z M838.4,380.8l-0.2,0.3l-0.3-0.2l-0.3-0.2h0.1l0.1-0.1v-0.1l0.2-0.1l0.4-0.1h0.1l0.1-0.1v-0.1h0.1l0.3-0.1l0.1,0.2v0.1
                l-0.2,0.1l-0.2,0.2L838.4,380.8l-0.1,0.1v0.1L838.4,380.8L838.4,380.8z M838,381.1L838,381.1h-0.3l-0.1-0.3l0.2,0.1L838,381.1
                L838,381.1z M837.1,382.3l-0.1,0.1l-0.2-0.1v-0.4l0.1-0.2h0.1l0.1,0.3l0.1,0.1L837.1,382.3L837.1,382.3z M835.1,385.7l-0.3,0.1
                l-0.1,0.2l-0.2,0.1l-0.2,0.2h-0.2v0.2l0.1,0.2H834l-0.2,0.2v0.1l0.1,0.1v0.1l-0.2,0.2h-0.2v-0.2v-0.1l0.2-0.3v-0.4h0.2l0.1-0.1
                l0.3-0.3l0.1-0.1l-0.2-0.1v-0.1v-0.1h0.2l0.1,0.1l0,0l0.1-0.1l0.1-0.1l0.3-0.3l0.1-0.3l0.2,0.2l-0.1,0.3L835.1,385.7L835.1,385.7z
                M874.1,385.8L874.1,385.8l-0.2-0.3h0.1l0.1,0.1L874.1,385.8L874.1,385.8L874.1,385.8z M827.2,391.6l-0.2,0.1h-0.3v-0.4l0.1,0.1
                l0.1,0.1l0.2,0.1L827.2,391.6z M823.9,392.3l-0.2,0.5l-0.1,0.1h-0.1l-0.1-0.3l0.1-0.1l0.1,0.1h0.1L823.9,392.3h0.2H823.9
                L823.9,392.3z M822.8,393h-0.2l-0.2-0.1h-0.2v-0.1l0.2-0.1v-0.1v-0.1l0.5,0.2v0.1L822.8,393L822.8,393z"/>
            <linearGradient id="JU_1_" gradientUnits="userSpaceOnUse" x1="595.1044" y1="154.6579" x2="595.1044" y2="154.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="JU" class="stcustom0 st114" d="M595.1,511.2L595.1,511.2L595.1,511.2L595.1,511.2L595.1,511.2L595.1,511.2L595.1,511.2z"/>
            <linearGradient id="KE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="203.7579" x2="0.1864" y2="203.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KE" class="stcustom0 st115" d="M592.6,451.7l-0.3,0.5l-0.4,0.6l-0.8,1.1l-0.6,0.6l-0.4,0.4v0.1v0.5v1.2v2.4v2.4v2.4v1.2v0.4l0.4,0.5
                l0.4,0.5l0.5,0.6l0.3,0.3v0.1v0.2l-0.4,0.5l-0.3,0.2l-0.5,0.1h-0.1l-0.2-0.1l-0.1,0.1l-0.1,0.2h-0.1l-0.1-0.1v0.3v0.2l-0.1,0.2
                l-0.2,0.2v0.2l-0.3,0.2h-0.7l-0.4,0.2l-0.2,0.2l-0.1,0.4v0.6l-0.2,0.4v0.2l-0.3,0.3l-0.2,0.3l-0.1,0.3l-0.1,0.1l-0.1,0.6l-0.2,0.4
                v0.1v0.1l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.4,0.9l-0.3,0.4h-0.3l-0.2,0.2v0.1H585l-0.2-0.2l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3
                l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.3-0.2l-0.1-0.1l-0.1-0.2v-0.1l-0.1-0.1h-0.1l0,0v-0.1v-0.1l0.2-0.3v-0.2v-0.2v-0.3v-0.1
                l-0.3-0.2l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3
                l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.2-0.1l-0.2-0.1h-0.6v-0.5l0.1-1.2v-1.1l0.1-0.5l0.3-0.3l0.1-0.2l0.1-0.3l0.1-0.3l0.3-0.2l0.1-0.1
                l0.3-0.4l0.2-0.5l0.2-0.2l0.2-0.2l0.1-0.1l0.2-0.1h0.2l0,0V460l-0.1-0.3l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.1l0.1-0.1v-0.2v-0.2v-0.2
                v-0.6l-0.1-0.5l-0.1-0.5l0.1-0.2l-0.1-0.3h-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.3l-0.1-0.1l-0.4-0.2l-0.2-0.5l-0.2-0.1l-0.1-0.5V454
                l0.1-0.5v-0.1l-0.1-0.1l-0.4-0.1l-0.3-0.2v-0.1v-0.1l-0.2-0.1l-0.4-0.9l0.6-0.6l0.6-0.6l0.7-0.7l0.7-0.7l0.6-0.6l0.5-0.5v0.1v0.1
                l0.1,0.1l0.1,0.1l0.1-0.1l0.1-0.1h0.1l0.8,0.2l0.1,0.2v0.2v0.1l-0.1,0.1l-0.1,0.4v0.4l0.2,0.3l0.2,0.2l0.2,0.3l0.1,0.1l0.2,0.1h0.5
                h0.8h0.8h0.1h0.2l0.7,0.4l0.6,0.4l0.5,0.3l0.5,0.3l0.5,0.3l0.4,0.3l0.4,0.1h0.6h0.4l0.4,0.1l0.6,0.1l0.5,0.1l0.3,0.1l0.8,0.1h0.1
                l0.3-0.3l0.4-0.5l0.1-0.3l0.5-0.3l0.8-0.4l0.6-0.3l0.7-0.3l0.3,0.2l0.4,0.4l0.2,0.2l0.1,0.1l0.2,0.1h0.3h0.2h0.3h0.7L592.6,451.7
                L592.6,451.7z M590.1,468.8L590.1,468.8v-0.2l0.3-0.2h0.1l0,0l0,0h-0.1L590.1,468.8L590.1,468.8z"/>
            <linearGradient id="KG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="329.5078" x2="0.1864" y2="329.5078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KG" class="stcustom0 st116" d="M700.2,332.7l0.1,0.2v0.3v0.1h-0.1l-0.9,0.1l-0.2,0.1l-0.2,0.4l-0.7,0.3l-0.4,0.1h-0.2l-0.4,0.2
                l-1.1,0.6l-0.6,0.4l-0.3,0.2l-0.2,0.2v0.2v0.2l-0.6,0.8l-0.5,0.1h-0.4l-0.3,0.1l-0.4,0.1l-0.8-0.1h-0.3l-0.5-0.1l-0.2,0.1l-0.2,0.2
                l-0.3,0.6l-0.1,0.1l-0.1,0.1v0.3l-0.1,0.3l-0.2,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.1l-0.2-0.3l-0.1,0.1l-0.1,0.1h-0.3l-0.2,0.1l-0.4,0.2
                h-0.5l-0.1-0.1l-0.1-0.7l-0.1-0.3l-0.1-0.1h-0.1l-0.8,0.5l-0.4,0.1h-0.3l-0.4-0.2h-0.1l-0.1,0.1v0.1l0.1,0.3v0.1H685l-0.2,0.1
                l-0.2,0.1l-0.6,0.5l-0.5,0.2l-0.4,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.2l-0.2,0.4l-0.1,0.2l-0.1,0.1v0.1l0.1,0.2l0.1,0.4v0.1l-0.1,0.2
                l-0.1,0.2L682,343h-0.2h-0.2h-0.3l-0.2,0.1l-0.1,0.1l-0.3,0.1h-0.4h-0.4h-0.2l-0.7-0.1h-0.2l-0.2,0.1l-0.4,0.1l-0.2,0.2l-0.1,0.2
                h-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.1h-0.1l-0.5,0.3h-0.1l-0.1-0.1v-0.3V343l-0.3-0.1h-0.4l-0.1-0.1v-0.1v-0.1v-0.1l-0.1-0.1h-0.2
                l-0.2,0.1l-0.2,0.1l-0.2,0.1h-0.2l-0.1,0.1l-0.2,0.3l-0.6,0.1l-0.2-0.1l-0.2-0.2l-0.2-0.3H673h-0.2h-0.3l-0.4,0.1l-0.1-0.1h-0.1
                l-0.1,0.1h-0.1h-0.4h-0.5l-0.3-0.1h-0.2l-0.4,0.2h-0.2h-0.3v-0.5l-0.1-0.3v-0.2l0.1-0.3l0.1-0.2l0.2,0.1l0.2,0.1h0.1v-0.1v-0.1v-0.1
                l0.1-0.1l0.1-0.1l0.7-0.2l0.6-0.2l0.3,0.1l0.6,0.2l0.3,0.1l0.2,0.1l0.2,0.4h0.1l0.1-0.1l0.1-0.1l0.1-0.3l0.3-0.2l0.6-0.2v-0.1v-0.1
                l0.1-0.1l0.3-0.1l0.6-0.1h0.2l0.2,0.1l0.2,0.1h0.2h0.1h0.1v0.1l0.1,0.1l0.2-0.1l0.2-0.2h0.2l0.1-0.1V340l0.1-0.2l0.3-0.4l0.2-0.1
                h0.1v0.1l0.1,0.1l0.3,0.1l0.1-0.1v-0.1l-0.2-0.5v-0.1v-0.1h0.1l0.5,0.2h0.1l0.2-0.1l0.2-0.2l0.1-0.2l1-0.5l0.1-0.1v-0.1l-0.4-0.1
                l-0.2,0.1h-0.2l-0.1-0.1h-0.5l-0.1-0.1l-0.3-0.4l-0.2-0.1l-0.2-0.1h-0.2l-0.2,0.1h-0.1v-0.2v-0.2v-0.2h-0.1l-0.2,0.1l-0.3-0.1H677
                l-0.1-0.4l-0.1-0.2l-0.1-0.2l-0.1-0.1l-0.2-0.1v-0.2v-0.1h-0.1h-0.1l-0.1,0.1v0.3v0.3l-0.1,0.1l-0.1,0.1h-0.1l-0.2-0.1v0.8l0,0
                l-0.3-0.1h-0.2h-0.3l-0.2-0.1h-0.2l-0.3-0.1l-0.2-0.1l-0.1-0.5l-0.1-0.2h-0.1l-0.5,0.2l-0.2-0.1l-0.3-0.2l-0.2-0.1l-0.1-0.1v-0.1
                l0.8-0.6l0.3-0.4l0.2-0.2l0.3-0.1l0.2-0.1l0.1-0.4l0,0h0.2l0.3-0.1l0.6-0.3v-0.1l-0.1-0.1l-0.2-0.2l-0.3-0.1l-0.2,0.1l-0.1,0.1
                l-0.2-0.2v-0.2l0.2-0.3l0.1-0.1l0.1-0.3l0.2-0.2l0.2-0.3l0.3-0.3l0.5-0.2l0.3,0.1h0.2l0.4-0.2h0.1h0.2l1,0.2h0.3l0.8,0.3l0.3,0.1
                l0.2,0.1l0.1,0.1l0.2,0.2l0.9,0.1l0.3,0.1l0.1,0.1l0.3,0.2h0.2l-0.2-0.7l0.1-0.4l0.3-1.1l0.2-0.2l0.3-0.2l0.5-0.2l0.2-0.2h0.4h0.2
                h0.1l0.1-0.1l0.4,0.2l0.7,0.5l0.5,0.3l0.6,0.3l0.9,0.3l0.8,0.1l0.1-0.1l0.3-0.4h0.1h0.3h0.8h0.8h0.4l0.8-0.2h0.1h0.2l0.5,0.2h0.4
                h0.3h0.1h0.3h0.5l0.6,0.1h0.7h0.2h0.4l0.3,0.1l0.4,0.1l0.3,0.1h0.2h0.3h0.2l0.1,0.1l0.1,0.4l0.3,0.2l0.2,0.2l0.2,0.2l0.2,0.1h0.3
                l0.6,0.1l0.3,0.1l0.4,0.4L700.2,332.7L700.2,332.7z M674.9,341.2L674.9,341.2l-0.1-0.3l0.1-0.2h-0.3l-0.1-0.1l-0.2-0.3h-0.1
                l-0.1,0.1v0.2v0.2l0.1,0.1h0.1l0,0v0.1l-0.1,0.3h0.1h0.3L674.9,341.2L674.9,341.2z M676.5,341v-0.2h-0.1h-0.1h-0.2v0.1l0.1,0.1h0.1
                H676.5z M673.5,341.5L673.5,341.5l-0.1-0.1h-0.3l-0.2-0.1l0,0v0.1l0.1,0.1l0.1,0.1h0.1L673.5,341.5L673.5,341.5z"/>
            <linearGradient id="KH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="238.4079" x2="0.1864" y2="238.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KH" class="stcustom0 st117" d="M768.2,433.6l-0.5-0.4l-0.9-0.1l-0.1-0.2h-0.1l-0.1,0.2l-0.5,0.2l-0.2-0.1l-0.2-0.1v-0.2l0.1-0.1
                l0.2-0.1l0.1-0.4l-0.2-0.5l-0.2-0.1l-0.2-0.1l-0.2,0.2l-0.2,0.3l-0.2,0.2h-0.2h-0.3l-0.1-0.5v-0.4V431l0.1-0.3l-0.3-0.4V430
                l-0.2-0.2v0.1v0.1v-0.1l-0.5-1l-0.1-0.5l0.1-0.4l0.1-0.1l-0.1-0.2l-0.2-0.2l-0.4-0.3v-0.5l-0.1-0.5l-0.1-0.2l-0.2-0.3l-0.1-0.3v-0.7
                v-0.1h0.3l0.3-0.1l0.1-0.1l-0.1-0.1l0.2-0.2l0.3-0.4l0.2-0.4l0.2-0.2l0.1-0.2l0.3-0.3l0.5-0.2l0.3-0.1l0.3-0.1l0.3-0.1h0.2l0.4,0.1
                h0.2h0.2h0.2h0.2l0.5-0.1l0.5,0.1l0.5-0.1l0.6-0.1l0.3,0.1l0.3,0.1v0.2l0.1,0.1l0.1,0.1h0.1l0.1-0.2l0.1-0.2l0,0v0.1l0.1,0.2
                l0.1,0.2l0.1,0.1l0.2,0.1h0.1l0.4-0.1l0.6,0.2l0.1,0.1l0.2,0.2l0.2,0.1h0.5l0.2-0.4l-0.1-0.2l-0.3-0.4l-0.1-0.2h0.1h0.4h0.1l0.1-0.3
                h0.1h0.2l0.3-0.2l0.2-0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.1l0.2,0.2l0.1,0.2l0.1,0.1h0.3h0.1l0.2-0.2l0.1-0.1h0.1h0.1l0.3-0.2
                l0.2-0.2l0.1-0.1l0.2,0.1h0.1l0.1-0.3l0.2-0.1v0.2l-0.1,0.3l-0.1,0.3l-0.2,0.2v0.2l-0.1,0.5v0.2l0.1,0.1l0.1,0.1l0.2,0.5l0.2,0.5
                l0.2,0.4v0.2l-0.2,0.6l-0.2,0.6v0.3l0.1,0.3l0.1,0.4v0.5v0.3l-0.1,0.2l-0.2,0.2l-0.1,0.1l-0.2-0.2h-0.1h-0.2l-0.1,0.1l-0.3,0.3
                l-0.5,0.2l-0.5,0.1l-0.2,0.2h-0.2h-0.4h-0.2v0.1v0.5v0.1l0,0h-0.2l-0.3-0.1l-0.4-0.1h-0.3l-0.1,0.2l-0.1,0.1h-0.1h-0.1v0.1v0.1
                l0.1,0.2v0.3v0.2l0.1,0.1l0.6,0.5l0.2,0.1v0.1L773,432l0.1,0.4h-0.2l-0.3-0.2l-0.1-0.1l-0.2,0.1h-0.1l-0.1-0.2l-0.2-0.2h-0.2
                l-0.3,0.1h-0.3H771h-0.1l-0.2,0.3h-0.1l-0.4-0.1h-0.3l-0.1,0.1v0.2l0.1,0.2v0.1l-0.2,0.1l-0.2,0.2l-0.1,0.2h-0.1h-0.4h-0.4l-0.1,0.1
                l-0.1,0.1L768.2,433.6L768.2,433.6z M764.3,431.2L764.3,431.2L764.3,431.2L764.3,431.2l-0.1-0.2v-0.1h0.1V431.2z M765.1,432.8
                l-0.1,0.1l-0.2-0.2v-0.1L765.1,432.8L765.1,432.8z"/>
            <linearGradient id="KI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="192.4579" x2="0.1864" y2="192.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KI" class="stcustom0 st118" d="M1003.6,470.5L1003.6,470.5l0.2,0.1v0.1l0,0h-0.1h-0.1l0,0l0,0l0,0h0.1h0.1l0,0l0,0l-0.1-0.1
                L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5L1003.6,470.5
                L1003.6,470.5z M1005.3,471.6L1005.3,471.6L1005.3,471.6L1005.3,471.6L1005.3,471.6L1005.3,471.6L1005.3,471.6L1005.3,471.6z
                M1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3L1004.9,475.3
                L1004.9,475.3z M1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4
                L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4L1002.1,475.4z M995.7,475.9L995.7,475.9
                L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9L995.7,475.9
                L995.7,475.9L995.7,475.9L995.7,475.9z M27.9,451.9l0.2,0.2v0.1h-0.2h-0.1h0.1l0,0l-0.1-0.1h-0.1h-0.1v0.1l-0.1-0.1l0.1-0.1
                L27.9,451.9L27.9,451.9L27.9,451.9z M960.5,454.1l-0.2,0.1h-0.1L960.5,454.1L960.5,454.1L960.5,454.1L960.5,454.1z M960.2,454.3
                l-0.2,0.1l-0.1-0.1h0.1H960.2l0.1-0.1L960.2,454.3L960.2,454.3z M33.5,457.6l0.5,0.3h-0.2l-0.5-0.2l-0.4-0.3l0.1-0.1l0.1,0.1
                l0.2,0.1l0.1-0.2l0.1-0.1l-0.4-0.2h0.1l0.3,0.2V457.6L33.5,457.6z M960.7,457.7L960.7,457.7l-0.1-0.1l-0.1-0.1l0,0l0,0l0.1,0.1l0,0
                L960.7,457.7z M960.7,458L960.7,458L960.7,458L960.7,458v-0.3l0,0v0.1V458L960.7,458L960.7,458z M960.7,459.1L960.7,459.1
                L960.7,459.1l0.3-0.1l0,0h0.1l0,0l0,0h-0.2L960.7,459.1L960.7,459.1z M960.7,460l0.2,0.1v0.1l-0.1,0.1l-0.2,0.2h-0.1l0.2-0.2
                l0.1-0.1L960.7,460L960.7,460L960.7,460L960.7,460L960.7,460L960.7,460L960.7,460z M964.9,465.1L964.9,465.1L964.9,465.1
                L964.9,465.1l-0.2-0.4h-0.1l-0.1-0.1l0,0l0.1,0.1h0.1l0.1,0.2L964.9,465.1L964.9,465.1z M950.9,465.3L950.9,465.3L950.9,465.3
                L950.9,465.3L950.9,465.3L950.9,465.3L950.9,465.3L950.9,465.3z M965.6,466.2v0.1h-0.1L965.6,466.2L965.6,466.2l-0.2-0.2h0.1
                L965.6,466.2L965.6,466.2z M40.2,474.2L40.2,474.2L40.2,474.2L40.2,474.2H40l0,0H40.2L40.2,474.2L40.2,474.2L40.2,474.2L40.2,474.2
                L40.2,474.2z M37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5L37.7,478.5
                L37.7,478.5z M49.1,494.9L49.1,494.9L49.1,494.9L49.1,494.9l-0.1-0.1l0,0l0,0L49.1,494.9L49.1,494.9L49.1,494.9z"/>
            <linearGradient id="KM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="169.6579" x2="0.1864" y2="169.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KM" class="stcustom0 st119" d="M597.1,496.2L597.1,496.2l-0.3-0.1h-0.1l-0.2-0.3l0.1-0.9l0.1-0.1h0.1h0.1l0.1,0.1v0.6l0.2,0.4
                l0.1,0.3H597.1z M599.9,496.8l0.1,0.4v0.3l-0.1,0.1l-0.1-0.1l-0.2-0.2l-0.4-0.2h0.2h0.1h0.1l0.1-0.1v-0.1L599.9,496.8L599.9,496.8
                L599.9,496.8z M598,497.4l0.2,0.2l-0.5-0.1l-0.1-0.2v-0.1h0.2L598,497.4L598,497.4z"/>
            <linearGradient id="KN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="251.8079" x2="0.1864" y2="251.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KN" class="stcustom0 st120" d="M299.3,414L299.3,414l-0.2-0.1h-0.2l-0.2-0.1l0,0v-0.1v-0.1h0.1l0.2,0.1l0.1,0.2L299.3,414L299.3,414
                L299.3,414z M299.6,414.4l-0.1,0.1l-0.1-0.1v-0.2h0.1l0.1,0.1V414.4L299.6,414.4z"/>
            <linearGradient id="KP_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="326.5079" x2="0.1864" y2="326.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KP" class="stcustom0 st121" d="M841.4,331.4l0.1,0.2l0.2,0.2l0.1,0.2v0.2l0.1,0.1l-0.1,0.1l-0.2-0.1h-0.3l-0.4,0.3l-0.2,0.1
                l-0.2,0.3l-0.3,0.2l-0.2,0.2l-0.2,0.4l-0.1,0.3l-0.3,0.4l-0.2,0.4v0.4l0.2,0.4v0.3l-0.2,0.7l0.1,0.7l-0.1,0.3l-1,0.5l-0.3,0.2
                l-0.4,0.6l-0.5,0.2l-0.3,0.3l-0.4,0.1L836,340l-0.3,0.2l-0.3,0.2l-0.2,0.2h-0.6l-0.4,0.3l-0.3,0.4l-0.8,0.4L833,342l0.1,0.5v0.4
                l-0.1,0.3l-0.2-0.1l-0.1,0.1l-0.1,0.3v0.3l0.3,0.1l0.2,0.1l0.3,0.1l0.2,0.1l0.5,0.7l0.4,0.3l0.1,0.1l0.2,0.1l0.2,0.2l0.1,0.2
                l-0.1,0.1l-0.2,0.3l-0.2,0.4l-0.2,0.2l-0.2,0.1L834,347h-0.4h-0.3h-0.1h-0.5h-0.1h-0.7h-0.3l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.2
                l-0.3,0.5l-0.2,0.2v0.3v0.1l-0.1,0.1l0,0h-0.1l-0.6-0.3l-0.5,0.2l0.2,0.1l-0.1,0.1l-0.2-0.4h-0.3l-0.5-0.4l-0.2,0.1l-0.1,0.2
                l-0.3,0.4l-0.4,0.3H827h-0.1v-0.1l-0.2-0.3l-0.6-0.1l-0.2-0.1h-0.1l0.6-0.4l0.2-0.1l-0.1-0.1h-0.1l-0.5,0.1l-0.2-0.1h-0.4l-0.2-0.1
                l0.5-0.4v-0.2v-0.2l0.3-0.5l0.3-0.3l0.7-0.4l0.3-0.1h0.2h0.2l-0.2-0.1l-0.2-0.1h-0.4l-0.4-0.2v-0.2l0.7-1.5v-0.1l-0.1-0.4v-0.4
                l-0.5-0.2h-0.2l-0.7-0.4l-0.3-0.2l-0.1,0.1v0.3l-0.1,0.1l-0.2,0.1l-0.1-0.4l-0.1-0.3l-0.4-0.3l-0.2-0.1l0.1-0.3l0,0l0.1-0.4l0.3-0.3
                l0.6-0.5l0.2-0.2l0.3-0.3h0.1h0.2v-0.1v-0.1l0.1-0.1l0.3-0.2l0.4-0.2l0.3-0.1l0.4-0.3l0.1-0.1h0.1v-0.1l0.1-0.2l0.1-0.1h0.2l0.3-0.1
                h0.3l0.2-0.3l0.1-0.2l0.1-0.2l0.3-0.2l0.2-0.3l0.2-0.4l0.1-0.1h0.1l0.1-0.1l0.1-0.4l0.1-0.4l0.1-0.2l0.3-0.2l0.1-0.1h0.1h0.1
                l0.2-0.1l0.2-0.1h0.1l0.1,0.1l0.2,0.2l0.1,0.2l0.1,0.1v0.2l0.1,0.1h0.3l0.4,0.1h0.3l0.2,0.1l0.3,0.1l0.6-0.1h0.3l0.1,0.1l0.2,0.1
                h0.1l0.1-0.2l0.2-0.3l0.1-0.2v-0.2l-0.1-0.2l-0.2-0.2l-0.1-0.3l-0.1-0.3l-0.1-0.1l-0.1-0.1v-0.2v-0.1l0.3-0.1l0.4-0.1l0.3,0.1h0.6
                l0.3-0.1h0.3h0.2l0.1-0.1l0.3-0.3l0.2-0.1l0.2-0.2v-0.2v-0.2l0.1-0.2l0.2-0.2l0.1-0.1h0.2l0.2,0.1l0.1,0.1h0.1l0.1-0.2h0.1h0.2
                l0.1-0.1l0.1-0.5l0.1-0.4v-0.3l0.2-0.4l0.1-0.3l0.1-0.1h0.1l0.1,0.1h0.1h0.2l0.1,0.1l0.1,0.1l0.2,0.1v0.1v0.5l0.1,0.2l0.2,0.2
                l0.3,0.2h0.1L841.4,331.4L841.4,331.4z M825.6,342.5l-0.2,0.1v-0.2l0.1-0.2h0.1L825.6,342.5L825.6,342.5z"/>
            <linearGradient id="KR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="310.7079" x2="0.1864" y2="310.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KR" class="stcustom0 st122" d="M830.5,348.8L830.5,348.8l0.1-0.2v-0.3l0.2-0.2l0.3-0.5l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.1h0.3h0.7h0.1
                h0.5h0.1h0.3h0.4l0.2-0.1l0.2-0.1l0.2-0.2l0.2-0.4l0.2-0.3l0.1-0.1l0.7,1.6l0.7,1l0.6,0.7l0.8,1.4l0.2,0.8v0.5l0.1,0.6l-0.1,0.4v0.6
                v0.3l-0.2,0.3v0.4v0.2v0.3l0.1,0.1h0.1l0.1-0.1h0.2v0.4l-0.2,0.9l-0.2,0.7l-0.3,0.6l-0.3,0.5l-0.4,0.2l-0.3,0.1h-0.5l-0.4-0.1
                l-0.4,0.1l-0.1,0.1l-0.1,0.2l0.1,0.3v0.2h-0.2l-0.3-0.1h-0.3l-0.2-0.1l-0.2-0.3h-0.2l-0.3,0.2h-0.4l-0.2,0.1l-0.1,0.1l0.1,0.2
                l0.2,0.2l-0.1,0.2l-0.2,0.1l-0.2-0.3l-0.1-0.3h-0.1l-0.2,0.1v0.3l0.1,0.2l0.2,0.2l-0.2,0.2l-0.1,0.2l-0.2,0.1L832,360l0.1-0.2
                l0.2-0.2v-0.2l-0.1-0.1l-0.6,0.5l-0.4,0.6H831l-0.1-0.1l-0.1-0.1l-0.4,0.4l-0.1,0.3h-0.1l-0.1-0.1v-0.3l-0.1-0.2l-0.4-0.3l-0.2-0.3
                l0.1-0.2l0.3,0.1h0.3l-0.1-0.1l-0.1-0.1l0.2-0.1l0.2-0.2h-0.1l-0.2,0.1h-0.2l-0.1-0.4l-0.2-0.4l-0.1-0.4l0.2-0.2l0.1-0.3l0.2-0.5
                l0.1-0.2l0.3-0.1l0.1-0.1l-0.1-0.1l-0.2-0.1v-0.1l0.2-0.1l0.2-0.2l0.3-0.2l0.1-0.4l-0.1-0.1l-0.2-0.1V355l0.1-0.1v-0.1l-0.2-0.2
                l-0.2-0.2v-0.2v-0.4v-0.3v-0.2l-0.1-0.4l-0.1-0.4l-0.2,0.1l-0.1,0.1l-0.4-0.1h-0.1l-0.1-0.3l0.2-0.3l0.4-0.3h0.2l0.2-0.1h0.3
                l0.3,0.2h0.3l0.2,0.4l0.1,0.1v-0.1l0.2-0.2l0.1-0.1v-0.1l-0.3-0.1l-0.2-0.4v-0.2l-0.1-0.1l0.1-0.4l-0.3-0.4l-0.1-0.1v-0.4l-0.1-0.2
                l-0.1-0.1v-0.2v-0.1L830.5,348.8L830.5,348.8L830.5,348.8z M830.2,349v0.5H830h-0.1v-0.1l-0.1-0.4l0.1-0.2l0.2,0.1L830.2,349
                L830.2,349z M842.5,349.9l-0.1,0.1l-0.2-0.1v-0.1l0.1-0.1l0.2-0.1l0.1,0.1L842.5,349.9z M829.9,353.3v0.2l-0.2-0.1l-0.1-0.5l0.2,0.1
                L829.9,353.3L829.9,353.3z M836.4,359.2l-0.3,0.2l-0.4-0.3l-0.1-0.2l0.3-0.2l0.2-0.3h0.2L836.4,359.2L836.4,359.2z M834.5,359.1v0.3
                h-0.2l-0.1-0.2l-0.1,0.1H834l-0.1-0.3v-0.2l0.2-0.2l0.1,0.1h0.2L834.5,359.1L834.5,359.1z M829.2,359.4L829.2,359.4l-0.2,0.1
                l-0.1-0.2l-0.1-0.2l-0.1-0.1l0.2-0.2l0.3,0.3L829.2,359.4z M833.8,359.8v0.1l-0.1-0.2l0.1-0.2V359.8z M829.4,360.6l-0.2,0.1
                l-0.1-0.1H829v-0.2l0.3-0.3l0.1-0.1l0.3,0.1l0.1,0.2l-0.1,0.2L829.4,360.6L829.4,360.6z M830.8,360.7v0.2h-0.2l-0.1-0.2v-0.1h0.1
                L830.8,360.7z M829.6,364.5l-0.1,0.1h-0.1l0,0l-0.1-0.2v-0.1l0.1-0.2l0.4-0.3l1-0.3h0.2l0.4,0.1l0.1,0.2l-0.1,0.2l-0.1,0.1l-0.5,0.2
                l-0.4,0.1L829.6,364.5L829.6,364.5z"/>
            <linearGradient id="XK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="334.6079" x2="0.1864" y2="334.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="XK" class="stcustom0 st123" d="M535.6,332.5L535.6,332.5l-0.5,0.1l-0.2,0.1l-0.1,0.2v0.1h-0.1l-0.1-0.1l-0.2-0.2h-0.2l-0.8,0.4
                l-0.1,0.2v0.4l-0.1,0.1l-0.1,0.1h-0.3l0,0v-0.2v-0.4l-0.1-0.6l-0.1-0.2l-0.2-0.2l-0.2-0.1l-0.3-0.1l-0.2-0.3l-0.2-0.4l-0.1-0.1l0,0
                l0.1-0.3l-0.1-0.2l-0.1-0.2l0.1-0.1h0.2h0.2l0.1-0.2l0.4-0.1l0.3-0.1l0.1-0.1l-0.1-0.2v-0.1l0.4-0.3l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.2
                v-0.1l0.2-0.1l0.2-0.1h0.1l0.1,0.1v0.1l0.1,0.2l0.1,0.1l0.2,0.1l0.3,0.1l0.2,0.2l0.3,0.3v0.2l0.2,0.1l0.2,0.2v0.3l0.8,0.3h0.2h0.1
                v0.1l-0.1,0.2l-0.3,0.7v0.1l-0.2,0.1v0.1l0.1,0.2L535.6,332.5z"/>
            <linearGradient id="KW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="288.8579" x2="0.1864" y2="288.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KW" class="stcustom0 st124" d="M609.7,375.1v0.1l0.1,0.4l0.2,0.4l0.2,0.3v0.1H610l-0.1-0.1l-0.2-0.1l-0.4,0.4l-0.3,0.2v0.1l0.3,0.1
                h0.3l0.2-0.1l0.1,0.1l0.1,0.3v0.2l0.2,0.7l0.2,0.2l0.2,0.4l0.1,0.2l0.1,0.2l0.1,0.3h-0.5h-0.6h-0.5h-0.6l-0.2-0.3l-0.1-0.3l-0.1-0.3
                l-0.2-0.5l-0.8-0.1l-0.4-0.1l-0.7-0.1l-0.5-0.1l0.5-0.5l0.2-0.3l0.4-0.6l0.2-0.4l0.2-0.5l0.2-0.4v-0.1l0.1-0.1l0.2-0.1l0.3-0.1
                l0.5-0.1h0.4h0.1l0.2,0.1L609.7,375.1L609.7,375.1z M610.6,376.2l-0.2,0.1h-0.1l-0.1-0.2l-0.2-0.4l0.1-0.2v-0.1v-0.1h0.1l0.1-0.2
                l0.1-0.1l0.1,0.1l0.3,0.5v0.2v0.1L610.6,376.2L610.6,376.2z"/>
            <linearGradient id="KY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="258.6079" x2="0.1864" y2="258.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KY" class="stcustom0 st125" d="M251.1,406.7H251h-0.1H251.1l0.1-0.1h0.1h0.1h0.1l0,0L251.1,406.7L251.1,406.7z M250.6,406.8
                L250.6,406.8h-0.1l-0.2,0.1h-0.1l0,0l0,0l0,0l0,0h0.1L250.6,406.8L250.6,406.8L250.6,406.8L250.6,406.8z M246.7,407.8L246.7,407.8
                h0.2v-0.1h0.4l0.1,0.1h-0.3l-0.1,0.1h-0.1h-0.3v-0.3h0.1L246.7,407.8L246.7,407.8z"/>
            <linearGradient id="KZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="358.4579" x2="0.1864" y2="358.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="KZ" class="stcustom0 st126" d="M720.2,305.1l-0.3-0.1h-0.5l-0.3,0.1H719l-0.2,0.2l-0.2,0.2l-0.1,0.3l0.1,0.3l-0.1,0.7l-0.2,0.3
                l-0.3,0.5l-0.2,0.1l-0.3,0.1l-0.3,0.1l-0.6,0.1l-0.6,0.1l-0.2,0.1l-0.2,0.3l-0.1,0.3l-0.1,0.2l-0.2,0.6l-0.1,0.6l0.1,0.7l0.1,0.8
                v0.3l0.2,0.4l0.1,0.2v0.3l-0.2,0.3l-0.1,0.4l-0.1,0.2l-0.4,0.1h-0.3l-0.3,0.3l-0.3,0.2l-0.4,0.3l-0.2,0.1l-0.1-0.1l-0.1-0.3
                l-0.1-0.1h-0.2h-0.2l-0.5-0.1h-0.3l-0.6-0.3h-0.3l-0.5-0.1l-0.3-0.1l-0.2-0.1l-0.5-0.3l-0.7-0.3l-0.3-0.1l-0.2,0.1v0.2v0.4l-0.1,0.3
                l-0.5,1.4l-0.3,1l-0.4,0.9l-0.1,0.6l-0.2,0.8l-0.2,0.6l-0.1,0.3v0.1v0.2l0.4,0.2l0.4,0.1l0.1,0.1v0.2v0.3l-0.1,0.3l-0.1,0.2
                l-0.1,0.1h-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.4,0.1l-0.4,0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.3l-0.2-0.2l-0.3,0.2l-0.8,0.3l-0.8,0.3
                l-0.5,0.2H702h-0.4l-0.4,0.1l-0.3,0.1l-0.5,0.2l-0.5,0.1l-0.3,0.2l-0.2,0.2v0.1l0.2,0.1l0.2,0.1h0.4h0.4l0.3,0.1l0.3,0.1l0.1,0.1
                l-0.1,0.1H701l-0.1,0.1v0.1l-0.1,0.3l-0.1,0.4l0.1,0.4v0.4v0.2v0.3l0.1,0.2l0.1,0.4l0.2,0.2l0.3,0.8l0.2,0.5l0.1,0.5l-0.1,0.3v0.2
                l0.2,0.1l0.1,0.3l0.1,0.2v0.2L702,329l-0.4-0.1l-0.3,0.2l-0.3,0.2v0.1v0.1v0.1l0.2,0.1l0.3,0.1v0.1l-0.3,0.1l-0.5,0.2l-0.1,0.2
                l-0.1,0.3v0.2v0.4l0.1,0.5l0.1,0.5v0.1l-0.1,0.1l-0.1,0.1l-0.4-0.4l-0.4-0.4l-0.3-0.1l-0.6-0.1h-0.3l-0.2-0.1l-0.2-0.2l-0.2-0.2
                l-0.3-0.2l-0.1-0.4l-0.1-0.1h-0.2h-0.3h-0.2l-0.3-0.1l-0.4-0.1l-0.3-0.1h-0.4h-0.2h-0.7l-0.6-0.1h-0.5H693h-0.1h-0.3h-0.4l-0.5-0.2
                h-0.2h-0.1l-1.2,0.1h-0.4H689h-0.8h-0.3h-0.1l-0.3,0.4l-0.1,0.1l-0.8-0.1l-0.9-0.3l-0.6-0.3l-0.5-0.3l-0.7-0.5l-0.4-0.2l-0.1,0.1
                h-0.1h-0.2h-0.4l-0.2,0.2l-0.5,0.2l-0.3,0.2l-0.2,0.2l-0.3,1.1l-0.1,0.4l0.2,0.7h-0.2l-0.3-0.2l-0.1-0.1l-0.3-0.1l-0.9-0.1l-0.2-0.2
                l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.8-0.3h-0.3l-1-0.2h-0.2h-0.1l-0.4,0.2h-0.2l-0.3-0.1l-0.5,0.2l-0.3,0.3l-0.2,0.3l-0.2,0.2l-0.1,0.3
                l-0.1,0.1l-0.2,0.3v0.2l0.2,0.2l-0.2,0.2h-0.3l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.1H673l-0.1-0.2h-0.2l-0.2,0.2l-0.3,0.3l-0.4,0.5
                l-0.4,0.2l-0.5,0.2l-0.3,0.1l-0.3,0.2l-0.5,0.3l-0.1,0.2l-0.3,0.1l-0.3,0.1l-0.2,0.2l-0.1,0.4l-0.2,0.2l-0.4,0.3l-0.3,0.3l-0.2,0.3
                l-0.2,0.3l-0.1,0.2v0.2l0.1,0.2v0.2l-0.1,0.1l-0.2,0.1H667l-0.3-0.1l-0.4-0.2l-0.1-0.1l-0.2-0.2v-0.2l0.1-0.4l0.1-0.3l-0.2-0.1
                l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.2,0.1l-0.2,0.1l-0.2-0.1h-0.6H664h-0.4h-0.5h-0.6l-0.2-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.3l-0.1-0.5
                l-0.1-0.4l-0.1-0.5l-0.1-0.6v-0.4h-0.5h-0.4h-0.5v-0.3v-0.4v-0.5l0.1-0.6v-0.5v-0.6v-0.4v-0.4l-0.3,0.1l-0.3,0.2l-0.3,0.1l-0.2-0.4
                l-0.2-0.4l-0.3-0.5l-0.2-0.4l-0.4-0.2l-0.3-0.2l-0.3-0.3l-0.2-0.3l-0.2-0.3l0.1-0.2l-0.3,0.1l-0.3,0.2l-0.3,0.2l-0.3,0.2l-0.1,0.1
                h-0.4h-0.3h-0.5h-0.5h-0.5l-0.7-0.1l-0.7-0.1l-0.4,0.1l-0.6,0.1l-0.6,0.1l-0.5,0.1l-0.6,0.1l-0.5,0.1h-0.2l-0.3-0.3l-0.5-0.5
                l-0.3-0.3l-0.3-0.3l-0.4-0.5l-0.3-0.3l-0.3-0.3l-0.2-0.3l-0.1-0.4l-0.2-0.2l-0.4-0.2l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3
                l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.4-0.3l-0.3-0.2h-0.3l-0.4,0.1
                l-0.5,0.1l-0.5,0.1l-0.8,0.2l-0.5,0.2l-0.4,0.1l-0.4,0.1l-0.6,0.2l-0.5,0.2l-0.6,0.2l-0.5,0.2l-0.4,0.1l-0.4,0.1l0.1,0.5v0.9v0.9
                v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9h-0.1h-0.3l-0.5,0.1l-0.4,0.1h-0.2l-0.1-0.1l-0.1-0.2l-0.2-0.2l-0.2-0.2
                l-0.2-0.4l-0.2-0.3l-0.4-0.6l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.5-0.4l-0.6-0.4l-0.6-0.4l-0.2-0.1l-0.3-0.1h-0.3h-0.2l-0.7,0.1l-0.5,0.1
                l-0.7,0.2l-0.5,0.2l-0.1,0.1l-0.4,0.3l-0.5,0.4l-0.6,0.6l-0.1-0.4v-0.6v-0.2l0.2-0.5l0.2-0.4l0.1-0.4l0.1-0.5l-0.1-0.8l-0.1-0.2
                l-0.2-0.1h-0.2h-0.3l-0.1,0.1l-0.3-0.3h-0.3l-0.2,0.1h-0.2l-0.2-0.1l-0.2-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.4l-0.2-0.2h-0.3H619h-0.1
                v-0.2l0.1-0.5V328v-0.2l-0.1-0.2l-0.1-0.2l-0.3-0.3l-0.2-0.4l-0.4-0.8l-0.3-0.9l-0.1-0.1l-0.3-0.1l-0.6-0.1l-0.4-0.1l-0.2-0.1
                l-0.1-0.2v-0.2v-0.3l0.1-0.2l0.3-0.2h0.7h0.6l0.5,0.4l0.2,0.1h0.2l0.4-0.1h0.2h0.5l-0.1-0.2l-0.2-0.1h-0.2l-0.2-0.1l-0.3-0.4
                l-0.4-0.4l-0.1-0.2v-0.3l0.1-0.2l0.3-0.2l0.3-0.3l0.1-0.4l0.1-0.2l0.2-0.3l0.3,0.1l0.5-0.2h0.9l1.1-0.1h0.3l0.7,0.2l0.4,0.1h0.5
                l0.3-0.1l-0.3-0.3l-0.7-0.4l-0.2-0.3l0.3-0.8l0.4-0.8l0.3-0.9l-0.1-0.9l-0.1-0.2v-0.3l0.2-0.2l0.1-0.2l0.1-0.3l-0.2-0.5l-0.1-0.2
                l-0.3-0.3h-0.7l-0.5-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.4,0.2h-0.1h-0.1l-0.2-0.3H621l-0.6-0.2l-0.3-0.3H620l-0.9-0.3l-0.3-0.1
                l-0.7,0.3l-0.5,0.4l-0.1,0.1l-0.3,0.2H617h-0.2h-0.1l-0.3,0.3l-0.6,0.4l-0.3,0.3l-0.3,0.2l-0.4,0.1h-0.4l-0.1,0.1h-0.4l-0.3,0.1v0.1
                v0.3l-0.2-0.1l-0.2,0.2l0.1,0.2h-0.1l-0.6-0.4l-0.5-0.3l-0.5-0.2h-0.1l-0.1-0.1l-0.1-0.2V315v-0.1l0.1-0.1h0.1h0.1l0.1,0.1l0.2,0.1
                h0.3l0.2-0.1v-0.2L612,314l-0.3-0.6l-0.3-0.7l-0.1-0.2l-0.4-0.6l-0.4-0.6l-0.3-0.5l-0.2-0.2l-0.5-0.1l-0.9-0.1l-0.3-0.1l-0.3,0.1
                l-0.3,0.1l-0.3-0.2l-0.2-0.4l-0.1-0.3l0.1-0.3v-0.4l-0.2-0.4l-0.2-0.2l-0.4-0.2l-0.5-0.4l-0.1-0.7l0.3-1l0.4-0.7l0.3-0.3l0.1-0.3
                v-0.2v-0.2l-0.2-0.2l-0.3-0.2l-0.1-0.3l0.1-0.6l0.2-0.8l0.3-0.7l0.4-0.4l0.3-0.3l0.1-0.3v-0.4v-0.3l0.1-0.2l0.1-0.2l0.1-0.2l0.2-0.2
                h0.3l0.3,0.2l0.4,0.4l0.6,0.8l0.3,0.5l0.1,0.2l0.3,0.3l0.3,0.1l0.5-0.2l0.4-0.2l0.1-0.1l0.1-0.2l-0.1-0.4l-0.1-0.2l-0.1-0.3
                l-0.1-0.5l-0.1-0.9l-0.1-0.3h0.1l0.2,0.1h0.2l0.3-0.2l0.4-0.4l0.7-0.6l0.2-0.4l0.1-0.4l0.2-0.3l0.5-0.1l0.4-0.1l0.3-0.3l0.5-0.3
                l0.4-0.2l0.2-0.1l0.1-0.2l0.5-0.6l0.4-0.4l0.3-0.4l0.1-0.2h0.2l0.4,0.2l0.4,0.2l0.3,0.2l0.1,0.2v0.2l0.1,0.1h0.1h0.2h0.4l0.5-0.3
                l0.7-0.5l0.6-0.2l0.3,0.1l0.3,0.4l0.2,0.4l0.2,0.1h0.1l0,0l0.3-0.1l0,0h0.2l0.2,0.1h0.4l0.5-0.1h0.1l0.3,0.1l0.3,0.2l0.2,0.2
                l0.4,0.7l0.2,0.2l0.5,0.2l0.2,0.2l0.3,0.3l0.1,0.2l0.3,0.4l0.3,0.6l0.1,0.5l0.1,0.4l0.1,0.2h0.1l0.1-0.1l0.1-0.2v-0.3v-0.5l-0.1-0.4
                l-0.1-0.1l-0.1-0.2l0.1-0.2l0.2-0.1l0.2,0.1l0.4,0.3l0.4,0.3l0.5,0.6l0.5,0.4l0.5,0.3l0.4,0.1l0.3-0.1l0.4-0.2l0.3-0.3l0.2-0.3
                l0.1-0.3l0.5-0.4l0.5-0.4l0.2,0.1l0.1,0.1l0.5-0.2l0.2-0.1l0.5-0.1l0.5,0.1l0.4,0.4l0.4,0.3h0.3l0.3-0.1l0.2-0.2l0.1-0.3l0.2-0.2
                l0,0l0.6,0.1h0.4l0,0l0.5,0.1l0.5,0.4l0.3,0.5l0.4,0.6l0.2,0.2l0.3,0.1h0.2l0.3,0.1l0.8,0.1l0.1,0.1l0.1,0.1l-0.1,0.3l0.1,0.1
                l0.6-0.2l0.2-0.2l0.2-0.5l0.2-0.5l0.1-0.2h0.1l0.2,0.1l0.2,0.3l0.3,0.3l0.4,0.1h0.2h0.4l0.9-0.1l0.8-0.4l0.5-0.4l0.2-0.6l0.1-0.7
                l0.2-0.4l-0.1-0.4l-0.4-0.4l-0.1-0.1l-1-0.2l-0.1-0.2h-0.1l-1-0.4l-0.5-0.2l-0.1-0.2l-0.1-0.3l-0.3-0.3l-0.6-0.3l-0.1-0.2l0.1-0.2
                l0.5-0.2l0.5-0.5l0.2-0.1h0.5l0.4-0.4l0.3-0.2l0.2-0.3v-0.3l-0.4-0.8l-0.1-0.5l0.1-0.3l0.3-0.3l0.1-0.2l0.2-0.3l0.1-0.2l0.4-0.1h0.5
                l0.4,0.1h0.5l0.5,0.1l0.2,0.1l0.2-0.1l0.1-0.2v-0.2l-0.2-0.2l-0.7-0.3l-0.3-0.3h-0.2l-0.4-0.1l-0.4-0.2l-0.3-0.1l-0.1-0.2l0.1-0.3
                l0.1-0.2l0.2-0.1h0.3l0.3-0.1l0.1-0.1v-0.1V285l-0.1-0.1H647l-0.2,0.1l-0.2,0.1l-0.4-0.2l-0.3-0.2v-0.2l0.2-0.3l0.1-0.2v-0.3v-0.3
                l0.1-0.4l0.2-0.3l0.3-0.1l0.7,0.3l0.9,0.2h0.2V283l0.1-0.1h1.3l0.2-0.1l0.1-0.1l1.2-0.2l0.1-0.2l0.2-0.2h0.3l0.3-0.1l0.5-0.2
                l0.3-0.1h0.1h0.4l0.4-0.1l0.1-0.1l0.1-0.1l0.4-0.2l0.7-0.2l0.2,0.1h0.4l0.4-0.1l0.3-0.1l0.2,0.1l0.3,0.1l0.2-0.1l0.1-0.4l0.1-0.4
                l0.2-0.2l0.2-0.1l0.2-0.1l0.1-0.1h0.6l0.6-0.4l0.1,0.2h0.8l0.9-0.2l0.6-0.1l1-0.2l0.4-0.2l0.6-0.1l0.6-0.1l0.4-0.3h0.3h0.4l0.2-0.1
                l0.2-0.1l0.1-0.2l-0.1-0.3l-0.1-0.2l0.3-0.1h0.4l0.2-0.1l0.5-0.5l0.4-0.2l0.4-0.2l0.8,0.1l0.7,0.1l0.7,0.2l0.4,0.3l0.3,0.2l0.3,0.1
                l0.3,0.1l0.3-0.1l0.2-0.1l0.1-0.2l0.2-0.1l0.7-0.1l0.1,0.2l0.3,0.7l0.2,0.9l0.4,1.1l0.2,0.6l-0.1,0.3v0.4v0.4l-0.3,0.5l0.1,0.2
                l0.7,0.3l1-0.1l0.6-0.2l0.3,0.1l0.2-0.1l0.1-0.4l0.2-0.1l0.2,0.3l0.2,0.4l0.2,0.3v0.3l0.1,0.4l0.1,0.1l0.2-0.2l0.2-0.1V283l-0.1-0.2
                v-0.2l0.1-0.1l0.1-0.1h0.3l0.5,0.1l0.6,0.6l0.3,0.1h0.1h0.3l0.4-0.2l0.2-0.2l0.1-0.1h0.1l0.1,0.1v0.2l-0.1,0.3l-0.3,0.3l-0.4,0.3
                l-0.3,0.5l-0.1,0.5l0.1,0.3l0.1,0.2v0.2h0.1l0.2-0.1l0.5-0.5l0.2-0.1l0.4-0.1h0.6l0.4,0.2l0.2,0.2l0.2,0.2l0.1-0.1l0.1-0.2v-0.2
                l0.1-0.2l0.6-0.5l0.4-0.3h0.1l0.3,0.1h0.2l0.5-0.3l0.4-0.4v-0.2v-0.2l0.1-0.1l0.6-0.1h0.1l0.5-0.3l0.7-0.4l0.4-0.3l0.6-0.1l0.1-0.1
                l0.2-0.2l0.4-0.2h0.2l-0.1,0.6l-0.2,0.7l-0.1,0.2h-0.7v0.2l0.1,0.3l0.1,0.2l0.1,0.1l0.2,0.2l0.7,0.6l0.9,0.7l0.9,0.8l0.7,0.6
                l0.3,0.3l0.2,0.2l0.5,0.8l0.5,0.8l0.8,1.3l0.7,1.3l0.8,1.4l0.4,0.8l0.9,1.7l0.2,0.5l0.5,1l0.4,0.9l0.4,0.8l0.2,0.1V298v-0.1l0.1-0.1
                l0.3-0.2l0.1-0.1h0.2l0.2-0.1l0.1-0.2l-0.1-0.4v-0.2l0.1-0.2l0.1-0.1l0.2-0.1h0.2l0.1-0.2l0.2-0.1h0.2h0.2l0.2,0.2l0.1,0.2h0.2h0.3
                v0.2l-0.1,0.3l-0.1,0.3v0.2l0.1,0.1l0.5-0.1h0.2l0.1,0.2l0.1,0.2v0.2l-0.1,0.2l0.1,0.1h0.5l0.3-0.1h0.5l0.5,0.2h0.3l0.3-0.1l0.5,0.1
                l0.3-0.2l0.2-0.2l0.1-0.2l0.1-0.1h0.4h0.3l0.2-0.3l0.2-0.1h0.3h0.2l0.6,0.3l0.4,0.2l0.4,0.3l0.2,0.2l0.2,0.4l0.3,0.3l0.2,0.4
                l0.1,0.4l0.2,0.7l0.2,0.2h0.2l0.3,0.1l0.3,0.1l0.7,0.5h0.2l0.2,0.1v0.2l-0.1,0.3l0.1,0.2l0.2,0.3l0.2,0.3l0.2,0.4l0.1,0.2l0.2,0.1
                l0.2-0.1l0.4,0.1l1.1,0.2h0.1l0.1,0.2h0.2h0.2h0.2l0.2-0.2l0.1-0.2l0.4-0.2l0.3-0.3l0.2-0.3h0.2l0.1,0.1v0.2l-0.2,0.2l-0.1,0.2v0.2
                h0.2l0.3,0.3l0.4,0.7l0.1,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.3L720.2,305.1L720.2,305.1z M616.3,322L616.3,322l-0.2-0.2l0.1-0.2h0.2
                l-0.1,0.3V322L616.3,322z M615.9,322.4l-0.1,0.1h-0.2l-0.3-0.4l0.1-0.4l0.1-0.1l0.1-0.1v0.1l-0.2,0.2v0.2l0.2,0.3L615.9,322.4z"/>
            <linearGradient id="LA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.8576" x2="0.1864" y2="254.8576" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LA" class="stcustom0 st127" d="M761.7,398.8l0.2,0.3l0.3,0.3l0.4,0.5l0.1,0.2l0.3,0.2l0.1,0.2l0.1,0.2v0.2l0.1,0.1h0.1h0.1l0.1-0.1
                l0.1-0.3l0,0l0.1,0.2h0.1h0.1l0.1,0.1v0.2v0.2l-0.1,0.2l-0.1,0.2v0.3l-0.1,0.2l0.1,0.2l0.6,0.9l0.3,0.2l0.7,0.2l0.3,0.1l0.2,0.1
                l0.2-0.1l0.2-0.3l0.3-0.2l0.5-0.2h0.1l0.3,0.1l0.4,0.3l0.3,0.3l0.2,0.1l0.1,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.2,0.1l-0.1,0.1h0.1h0.3
                l0.3,0.1l0.1,0.1v0.1v0.2l0.1,0.1h0.3h0.1l0.1,0.1l0.1,0.3v0.2l-0.2,0.2l-0.1,0.1v0.2l-0.2,0.2l-0.4,0.4h-0.1l-0.8-0.2h-0.4h-0.2
                H767l-0.1,0.1l0.1,0.2v0.2l-0.1,0.2l-0.3,0.2l-0.1,0.1v0.1l0.1,0.1l0.2,0.1l0.3,0.1l0.9,0.6l0.2,0.1l0.3,0.2l0.3,0.2l0.8,0.2
                l0.3,0.1l0.1,0.1v0.1l-0.1,0.1l-0.1,0.2v0.1l0.1,0.1l0.1,0.2l0.3,0.3l0.2,0.1h0.2l0.2,0.1l0.2,0.2l0.2,0.3v0.2l0.1,0.2l0.2,0.3
                l0.2,0.3l0.3,0.3l0.2,0.2l0.1,0.1l0.7,0.6l0.2,0.2l0.3,0.4l0.1,0.1l0.1,0.1l0.1,0.2v0.2v0.5l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.1h0.1
                l0.1-0.1l0.1-0.1h0.1l0.1,0.3l0.1,0.1l0.2,0.1l0.2,0.1l0.4,0.4l0.2,0.2h0.2l0.1,0.1v0.1l-0.1,0.1l-0.1,0.1l-0.5,0.2l-0.1,0.1
                l0.1,0.2l0.1,0.2l0.1,0.2l0.2,0.2l0.3,0.3l0.3,0.2l0.2,0.2l0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.1v0.1l0.1,0.2l0.1,0.1v0.2
                v0.3l-0.2,0.1l-0.1,0.3h-0.1l-0.2-0.1l-0.1,0.1l-0.2,0.2l-0.3,0.2h-0.1h-0.1l-0.1,0.1l-0.2,0.2h-0.1h-0.3l-0.1-0.1l-0.1-0.2
                l-0.2-0.2l-0.2-0.1l0.4,0.7l-0.1-0.1l-0.1-0.1l-0.2,0.2l-0.3,0.2h-0.2h-0.1l-0.1,0.3H773h-0.4h-0.1l0.1,0.2l0.3,0.4l0.1,0.2
                l-0.2,0.4h-0.5l-0.2-0.1l-0.2-0.2l-0.1-0.1l-0.6-0.2l-0.4,0.1h-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2v-0.1l0.2-0.1l0.3-0.1
                l0.2-0.2l0.1-0.2l0.1-0.2v-0.2l0.1-0.5l0.1-0.3v-0.3l-0.1-0.2v-0.4v-0.2v-0.1l0.2-0.2l0.1-0.2l0.1-0.3v-0.2l-0.1-0.1l-0.2-0.1
                l-0.3-0.1l-0.2-0.1l-0.1-0.2v-0.2l0.1-0.1l-0.2-0.1l-0.5-0.2l-0.3-0.2l-0.1-0.2l-0.2-0.3l-0.4-0.4l-0.2-0.5V415v-0.6l0.2-0.7
                l-0.2-0.5l-0.2-0.2l-0.3-0.2l-0.3-0.3l-0.3-0.3l-0.4-0.5L767,411l-0.3-0.3l-0.1,0.1l-0.3-0.1l-0.5-0.2l-0.4-0.1h-0.3h-0.2l-0.1,0.1
                v0.1l0.1,0.1v0.1l-0.2,0.1l-0.1,0.1l-0.2,0.2l0,0l0,0l0,0l-0.1,0.3l-0.2,0.1h-0.3l-0.3,0.1l-0.3,0.2l-0.1,0.1v0.1H763h-0.1l-0.1-0.1
                v-0.2l-0.1-0.1l-0.3-0.1l-0.3-0.2l-0.3-0.3l-0.2-0.2h-0.1l-0.2,0.1l-0.2,0.3l-0.2,0.1l-0.2-0.1l-0.1,0.1l-0.1,0.2l-0.2,0.2L760,412
                l0,0l-0.4,0.3l-0.3,0.3l-0.4,0.4l-0.2,0.1l-0.2-0.1l-0.3-0.1l-0.1-0.1l0.3-0.6l0.3-0.7l0.1-0.3V411v-0.2l-0.1-0.2l-0.1-0.2v-0.1
                v-0.1l0.1-0.2l0.2-0.2l0.2-0.5l0.2-0.5v-0.3l-0.1-0.4l-0.1-0.3l0.1-0.5v-0.2L759,407l-0.5-0.1h-0.2l-0.1,0.1l-0.1,0.1l-0.2,0.1h-0.3
                l-0.3-0.2l-0.3-0.3l-0.1-0.3l0.2-0.4l0.1-0.3l0.1-0.3v-0.1l-0.1-0.1h-0.1l-0.2-0.2l-0.2-0.3l-0.2-0.1h-0.1l-0.1,0.1l-0.1,0.2
                l-0.1,0.1H756v-0.2v-0.2l0.2-0.6l0.2-0.4l0.2-0.2l0.2-0.1h0.2h0.2l0.2-0.1v-0.1h-0.2l-0.1-0.1v-0.2l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.4
                l0.2-0.2h0.2l0.3-0.2l0.4-0.3l0.2-0.3l0.2,0.1l-0.1,0.3l0.1,0.1v0.1v0.2v0.2l0.1,0.1h0.1l0.5-0.1h0.3l0.1,0.1l0.1,0.1h0.1l0.1,0.1
                h0.1l0.2-0.1l0,0v-0.1l-0.1-0.1l-0.1-0.1v-0.2l0.1-0.4v-0.2v-0.5v-0.1l-0.1-0.2l-0.3-0.4l-0.1-0.2v-0.2v-0.1l-0.1-0.1v-0.1l0.1-0.1
                l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.1h0.1h0.1l0.2,0.3l0.3-0.2h0.2l0.2,0.1L761.7,398.8z"/>
            <linearGradient id="LB_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="303.6578" x2="0.1864" y2="303.6578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LB" class="stcustom0 st128" d="M575.8,363.8L575.8,363.8l-0.2,0.2l-0.1,0.1l-0.3,0.2l-0.1,0.1l-0.1-0.1l-0.1,0.1l-0.1,0.4l-0.2,0.1
                h-0.3h-0.2h-0.3l0.1-0.3l0.1-0.3l0.1-0.4l0.2-0.4l0.5-1.3l0.3-0.5l0.1-0.7l0.4-0.6l0.3-0.2l0.2-0.2v-0.3h0.5h0.3l0.1-0.2l0.2,0.1
                l0.1,0.1l-0.1,0.2l-0.2,0.2l0,0h0.1l0.2,0.1l0.1,0.1l0.2,0.7l-0.1,0.3l-0.2,0.3h-0.1l-0.2,0.1l-0.2,0.2l-0.1,0.1v0.1l0.2,0.1v0.1
                l0,0h-0.2h-0.2h-0.1h-0.2l-0.2,0.2l-0.1,0.1v0.1l-0.1,0.2l0.1,0.1l0.2,0.1l0,0v0.1l-0.2,0.1l-0.1,0.1v0.1L575.8,363.8z"/>
            <linearGradient id="LC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="242.2079" x2="0.1864" y2="242.2079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LC" class="stcustom0 st129" d="M304.2,423.9l-0.2,0.3l-0.3-0.2v-0.2v-0.1l0.2-0.3l0.1-0.2l0.1-0.1l0.1,0.2L304.2,423.9z"/>
            <linearGradient id="LI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="352.6579" x2="0.1864" y2="352.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LI" class="stcustom0 st130" d="M502,313.6h-0.2l0,0v-0.1v-0.3l0.1-0.4v0.1v0.1v0.1v0.1v0.1l0.1,0.1V313.6L502,313.6L502,313.6z"/>
            <linearGradient id="LK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="225.1079" x2="0.1864" y2="225.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LK" class="stcustom0 st131" d="M699.6,435.3h0.3h0.3h0.2l0.3,0.4l0.9,0.8l0.5,0.8v0.2l0.1,0.1h0.1l0.1,0.1l0.5,0.8l0.1,0.2v0.2v0.1
                l0.1,0.1h0.2l0.1,0.1l0.1,0.6v0.2v0.1l0.6,0.9v0.1v0.1v0.1l0.1,0.2l0.2,0.5l0.1,0.1l0.1,0.4v0.8v0.3l-0.1,0.4l-0.1,0.4l-0.2,0.3v0.2
                l-0.7,0.5l-0.2,0.1l-0.9,0.3l-0.7,0.3l-0.6,0.1l-0.6-0.2l-0.5-0.4l-0.2-0.6l-0.2-0.6l-0.2-0.7l-0.2-2.1l-0.1-0.6l-0.1-0.8v-0.3
                l0.1-0.3v0.7l0.1,0.1l0.1-0.1l0.1-0.7l0.1-0.3l0.3-0.8v-0.1v-0.3V438l0.4-0.6l0.1-0.3l0.1-0.3v-0.4l-0.1-0.4l0.3,0.1l0.2,0.1
                l0.2,0.1h0.1h0.2l-0.1-0.2l-0.4-0.2l-0.6-0.1l-0.2-0.1l-0.1-0.1L699.6,435.3L699.6,435.3z M699.5,435.8h-0.2l-0.1-0.2v-0.1v-0.1l0,0
                l0,0l0.1,0.2L699.5,435.8L699.5,435.8z M699.3,437.5l0.1,0.2l-0.2-0.1l-0.2-0.1l-0.1-0.1L699.3,437.5L699.3,437.5z"/>
            <linearGradient id="LR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="221.0078" x2="0.1864" y2="221.0078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LR" class="stcustom0 st132" d="M451.3,441.7L451.3,441.7l0.1,0.1l0.1,0.3l0.3,0.9v0.3l-0.1,0.2v0.2v0.2l-0.2,0.3l-0.6,0.6l0,0
                l0.1,0.1h0.1h0.1l0.1,0.1l0.2,0.2l0.2,0.1l0.2,0.1h0.2h0.2h0.2l0.3,0.1l0.1,0.2l0.1,0.2l0.1,0.1v0.2l0.2,0.2h0.3l0.3,0.2h0.1l0,0
                l0,0l0.1,0.5l0.1,0.3v0.1v0.1v0.4l-0.2,0.2v0.3v0.1l-0.2,0.1v0.2v0.3v0.3v0.7v0.5l0.1,0.1h-0.3l-1-0.4l-0.7-0.2l-2.5-1.3l-0.7-0.5
                l-0.8-0.8l-1.7-1.6l-0.4-0.3l-0.5-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.4l-0.4-0.3l-0.8-0.4l-0.6-0.6l0.1-0.1l0.2-0.4l0.3-0.4l0.3-0.2
                l0.2-0.2l0.2-0.2l0.3-0.2l0.5-0.6l0.1-0.1l0.1-0.4l0.1-0.5l0.2-0.2l0.4-0.1l0.1-0.1l0.1-0.3l0.1-0.4v-0.1h0.1l0.2-0.1h0.1l0.1,0.1
                v0.1l0.7-0.3l0.1-0.1l0,0l0.1,0.2h0.1V439l0,0h0.1l0.1,0.1l0.1,0.1l0.2,0.1l0.1,0.1v0.2v0.2l0.1,0.1v0.1v0.2v0.1v0.2v0.2v0.1
                l0.1,0.2l0.1,0.3v0.2v0.2l-0.1,0.2l-0.1,0.2v0.1h0.1h0.1h0.1l0.3,0.1l0.1,0.1l0.1,0.2l0.1,0.1v0.1h0.2l0.2-0.1l0,0h0.1h0.1l0.1-0.2
                l0.1-0.2l0.2-0.2l0.1-0.1v-0.1v-0.2l0.1-0.2l0.1-0.1h0.1h0.1v0.1L451.3,441.7L451.3,441.7z"/>
            <linearGradient id="LS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="116.5076" x2="0.1864" y2="116.5076" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LS" class="stcustom0 st133" d="M555.7,550.9l-0.3,0.1l0,0h-0.2H555h-0.2h-0.1l-0.2,0.2l-0.3,0.7l-0.1,0.1v0.2l-0.1,0.2l-0.1,0.2
                h-0.1l-0.3-0.1l-0.4-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.2l-0.1-0.1v-0.1l-0.2-0.1h-0.1h-0.1l-0.1-0.1v-0.1V551l-0.1-0.2l-0.2-0.3
                l-0.1-0.2l-0.2-0.3l-0.1-0.3l-0.1-0.3v-0.1l0.1-0.1l0.3-0.1l0.2-0.1l0.2-0.2l0.2-0.3l0.1-0.2l0.1-0.1l0.1-0.1l0.2-0.3l0.2-0.3
                l0.2-0.3l0.3-0.1l0.4-0.1l0.3-0.3l0.4-0.2l0.7-0.3l0.3-0.1h0.1l0.1,0.1l0.1,0.2l0.1,0.1l0.3,0.2l0.1,0.1l0.3,0.3l0.3,0.2l0.3,0.3
                l0.2,0.1h0.1l0.1,0.2l0.1,0.2l0.1,0.2v0.2l-0.1,0.4l-0.2,0.4l-0.1,0.2l-0.2,0.1l-0.1,0.2l-0.1,0.3l-0.1,0.4l-0.2,0.2l-0.2,0.1
                l-0.2,0.1L555.7,550.9z"/>
            <linearGradient id="LT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="388.2078" x2="0.1864" y2="388.2078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LT" class="stcustom0 st134" d="M549.7,274.8v0.2l-0.1,0.4l-0.1,0.5l-0.1,0.4v0.1l0.1,0.1l0.5,0.1l0.2,0.1v0.1l-0.1,0.1l-0.2,0.1
                l-0.1,0.1l-0.1,0.4h-0.9l-0.1,0.1l-0.1,0.2v0.2l-0.1,0.2l-0.2,0.2l-0.4,0.1l-0.3,0.1l-0.2,0.4l-0.2,0.6v0.4v0.2v0.1l-0.1,0.1
                l-0.2,0.4l-0.1,0.4l-0.1,0.2v0.1h0.2l0.2,0.1l0.1,0.2v0.2v0.2v0.1l-0.2,0.1h-0.3l-0.2-0.1v-0.1l0.1-0.2l-0.1-0.2l-0.1-0.1l-0.3,0.2
                h-0.2l-0.3,0.2l-0.2,0.3l-0.2,0.1l-0.5-0.1l-0.1,0.1l-0.1,0.6l-0.1,0.1H544l-0.4,0.2l-0.5,0.2l-0.2-0.1l-0.1-0.1h-0.2l-0.3,0.1h-0.2
                h-0.2l-0.4,0.1H541l-0.2-0.1v-0.1v-0.2v-0.3l-0.1-0.3l-0.2-0.3l-0.2-0.2l-0.3-0.2l-0.2-0.1h-0.1v-0.1v-0.1l-0.1-0.1l-0.2-0.1h-0.2
                l-0.2,0.2l-0.1-0.2l-0.1-0.4v-0.3l0.1-0.3l0.3-1v-0.2l-0.2-0.3l-0.3-0.2l-0.2-0.4h-0.6h-0.6h-0.2l-0.6-0.2l-0.5-0.3l-0.4-0.2
                l-0.3-0.2l-0.2-0.2l-0.3,0.1h-0.2l0,0l-0.1-0.4l0.1-0.5l-0.2-0.8l-0.3-1v-1v-0.2l0.8-0.6l1-0.6l0.2-0.1l0.9-0.4h0.1l0.8,0.1l0.6,0.1
                h0.5l0.3-0.1l0.3,0.1l0.2,0.3h0.2l0.2-0.2l1.2,0.2h0.3h0.3l0.5,0.2l0.3,0.2l0.7-0.1h0.3l0.2-0.1l0.5-0.4l0.2-0.1l0.2-0.1l0.2,0.1
                l0.1,0.4l0.4,0.6l0.4,0.1l1.1,0.2l0.2,0.1l0.6,0.6l0.4,0.3l0.2,0.2l0.3,0.4l0.2,0.3l0.3,0.2l0.4,0.2L549.7,274.8L549.7,274.8z
                M533.9,276.7h-0.2l0.3-0.6l0.1-0.4l0.1-0.5l0.1-0.2v0.2v0.4l-0.2,0.7L533.9,276.7L533.9,276.7z"/>
            <linearGradient id="LU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="363.8079" x2="0.1864" y2="363.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LU" class="stcustom0 st135" d="M492.3,300.6v0.1v0.3l0.1,0.3l0.2,0.3l0.1,0.2l0.2,0.1l0.3,0.1h0.1v0.2v0.2l-0.1,0.1l-0.1,0.2
                l-0.1,0.2l-0.1,0.4v0.3l-0.2-0.1l-0.1-0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.2h-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1l0.1-0.1l0.1-0.1
                l0.1-0.1l0.1-0.1l-0.2-0.4v-0.1l-0.2-0.2V302v-0.1v-0.1v-0.2l0.1-0.2l0.1-0.2l0.1-0.3l0.3-0.4l0.2,0.1h0.1L492.3,300.6L492.3,300.6z
                "/>
            <linearGradient id="LV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="397.0078" x2="0.1864" y2="397.0078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LV" class="stcustom0 st136" d="M551.9,265.3h0.3l0.1,0.1l0.1,0.4l0.4,0.3l0.3,0.3l0.1,0.1v0.2v0.3v0.1l-0.1,0.2l-0.1,0.4v0.4
                l-0.2,0.7l0,0l0.4-0.1l0.1,0.1l0.1,0.1v0.4l0.1,0.2l0.1,0.3v0.2l0.3,0.3v0.2l0.2,0.6l0.1,0.4v0.3l-0.1,0.4l-0.1,0.2h-0.1l-0.2,0.1
                l-0.4,0.3l-0.6,0.7l-0.1,0.1l-0.1,0.5v0.1h-0.3h-0.1h-0.3l-0.7-0.1l-0.3,0.1l-0.4,0.5l-0.1,0.1l-0.4,0.1l-0.1,0.1h-0.1l-0.4-0.2
                l-0.3-0.2l-0.2-0.3l-0.3-0.4l-0.2-0.2l-0.4-0.3l-0.6-0.6l-0.2-0.1l-1.1-0.2l-0.4-0.1l-0.4-0.6l-0.1-0.4l-0.2-0.1l-0.2,0.1l-0.2,0.1
                l-0.5,0.4l-0.2,0.1h-0.3l-0.7,0.1l-0.3-0.2l-0.5-0.2h-0.3h-0.3l-1.2-0.2l-0.2,0.2h-0.2l-0.2-0.3l-0.3-0.1l-0.3,0.1h-0.5l-0.6-0.1
                l-0.8-0.1H537l-0.9,0.4l-0.2,0.1l-1,0.6l-0.8,0.6l-0.1-1V270l0.1-1l0.5-0.6l0.3-0.4l0.2-0.6v-0.5l0.1-0.4l0.8-1.3l0.6-0.1l0.8-0.4
                l0.9-0.3l0.2,0.4l0.1,0.3l1.1,1.1l0.3,0.4l0.4,1.2l1,0.6l0.8-0.2l0.3-0.3l0.6-0.5l0.3-0.4l0.1-0.4l-0.1-1.7l-0.2-0.7l0.1-0.5h0.1
                l0.3-0.2l0.9-0.4h0.2l0.2-0.1l0.6-0.3l0.2,0.2l0.2,0.2h0.1V263v-0.1v-0.1h0.2l0.6,0.5l0.2,0.1h0.2l0.2,0.2l0.6,0.2l0.1,0.1v0.2
                l0.5,0.6l0.2,0.3l0.5,0.3l0.2,0.1l0.8-0.3l0.2-0.1h0.2l0.2,0.2l0.4,0.2L551.9,265.3L551.9,265.3z"/>
            <linearGradient id="LY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="279.7577" x2="0.1864" y2="279.7577" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="LY" class="stcustom0 st137" d="M545.7,369.6l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.3,0.3l-0.2,0.3v0.4v0.5l0.1,0.4l0.1,0.5v0.3l-0.1,0.4
                l-0.1,0.3l-0.4,0.7l-0.1,0.2v0.2l0.3,0.8v0.2l0.2,0.8l0.1,0.6l0.2,0.5v0.1v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7
                v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v0.7v1.5v1.5v1.5v1.5l0,0l0,0l0,0l0,0h-0.7h-0.7H543h-0.7v0.4
                v0.4v0.4v0.4l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7
                l-1.3-0.7l-1.3-0.7l-1.3-0.7l-1.3-0.7l-0.9-0.5l-1,0.5l-0.8,0.4l-1,0.5l0,0l-1.2,0.6l-0.9,0.5l0,0l0,0l-0.9-0.8l-0.7-0.7l-0.3-0.2
                l-1.4-0.3l-1.4-0.3l-1.5-0.4l-0.3-0.5l-0.3-0.6l-0.4-0.8l-0.2-0.5l-0.1-0.1l-1.1-0.4l-1.2-0.4l-0.7,0.2h-0.1l-0.2-0.1l-0.2-0.2
                l-0.1-0.3l-0.3-0.4l-0.3-0.8v-0.6l-0.1-0.2l-0.6-0.9l-0.6-0.8l-0.4-0.5l-0.1-0.2v-0.3l0.1-0.3l0.5-0.3l0.5-0.4l0.1-0.2v-0.7
                l-0.2-0.2l-0.1-0.4l-0.1-0.5v-0.4l0.2-0.7l0.3-0.7l-0.2-0.8l-0.1-1.6l0.1-1.3l-0.1-0.5v-0.2l-0.2-0.6l-0.2-0.6l-0.1-0.2l-0.3-0.5
                l-0.4-0.6l-0.2-0.4l0.3-0.2l0.3-0.2l0.3-0.2l0.5-0.2l0.2-0.1l0.1-0.1l0.4-0.5l0.2-0.3l0.3-0.4l0.1-0.3v-0.2v-0.3l-0.2-0.7l-0.2-0.7
                l0.1-0.3l0.1-0.1l0.2-0.3l0.1-0.1l0.5-0.1l0.2-0.2l0.1-0.3v-0.1l0.2-0.2l0.2-0.2l0.2-0.2l0.5-0.3l0.5-0.3l0.5-0.3l0.4-0.2l0.1-0.2
                v-0.2l-0.2-0.4v-0.9v-0.4v-0.2l0.1-0.6v-0.1l0.4,0.2l0.4,0.1l1.3,0.8l0.4,0.1l0.9,0.1l1.1-0.3l0.4-0.1l0.7,0.3l0.3,0.1h0.5l0.9,0.3
                l0.2,0.1l0.5,0.4l0.3,0.1l1.9,0.4l0.3,0.3l0.3,0.5v0.6l0.1,0.5l0.2,0.6l0.3,0.4l0.3,0.3l0.4,0.2l0.8,0.3l0.9,0.1h0.9l1.6,0.4
                l1.4,0.5l0.3,0.2l0.7,0.2l1.3,1.2l0.7,0.4l0.5,0.1l0.5-0.1l0.8-0.4l0.3-0.2l0.8-1l0.3-0.5l0.1-0.4V371l-0.1-0.3l-0.2-0.4l-0.2-0.5
                l-0.1-0.9l0.1-0.6l0.2-0.4l0.3-0.4l0.7-0.7l0.7-0.5l1.2-0.6h0.7l0.3-0.1l0.6-0.5h0.2l0.3,0.1h1l0.4,0.1l0.5,0.3l0.6,0.2l0.5,0.2
                l0.5,0.2l0.1,0.6l-0.1,0.2v0.2l0.5,0.4l1.4,0.2l0.3,0.1l0.4,0.3l0.3,0.1h1l0.6-0.1l0.5,0.1l0.2,0.1l0.2,0.2l0.3,0.6L545.7,369.6z"/>
            <linearGradient id="MA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="296.5578" x2="0.1864" y2="296.5578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MA" class="stcustom0 st138" d="M468.9,358.1l0.1,0.3l0.2,0.2l0.6,0.5l0.4,0.3v0.1l-0.1,0.2v0.2l0.1,0.2l0.2,0.2v0.1l-0.1,0.1
                l-0.1,0.2l0.2,0.6v0.6l-0.1,0.4v0.3v0.2l0.2,0.5l-0.1,0.8l0.2,0.5l0.2,0.4l0.1,0.7l0.2,0.3l0.3,0.3l0.2,0.1l0.3,0.2l0.2,0.2l0.1,0.3
                l-0.3,0.2l-0.2,0.2l-0.1,0.2l0.1,0.4v0.2l-0.1,0.1H471h-0.4h-0.5h-0.7H469h-0.6h-0.2l-0.6,0.1l-0.4,0.1h-0.1l-0.1,0.1l-0.1,0.3
                l-0.1,0.3l-0.1,0.1l-1.2,0.4l-0.5,0.1h-0.3h-0.2l-0.2,0.1l-0.1,0.1v0.2v0.2l0.1,0.2v0.2l-0.1,0.2v0.2v0.2l0.1,0.1h0.1l0.1,0.1
                l0.2,0.1l0.1,0.1v0.2l-0.1,0.1l-0.1,0.1l-0.4,0.1h-0.4l-0.5,0.3l-0.5,0.4l-0.6,0.2l-0.3,0.1l-0.4,0.2l-0.5,0.3l-0.3,0.5l-0.3,0.5
                l-0.3,0.3l-0.4,0.3l-0.4,0.1l-0.5,0.2l-0.6,0.1h-0.5h-0.1h-0.4H457h-0.1h-0.1v0.1v0.2v0.2l-0.1,0.2l-0.1,0.1h-0.1h-0.3l-0.3-0.1
                l-0.7-0.1h-0.1l0,0l-0.2,0.1l-0.3,0.3h-0.4L454,377l-0.4,0.1l-0.2,0.1l-0.7,0.6l-0.2,0.1l-0.7,0.5l-0.2,0.2l-0.2,0.2l-0.4,0.4
                l-0.3,0.2l-0.1,0.1v0.2v0.5v0.5v0.7v0.7v0.8h-0.4l0,0l0,0H438l0,0l0.4-0.4l0.3-0.5l0.4-0.2l0.9-0.1l1.4-0.4l1.2-0.6l0.3-0.2l0.4-0.5
                l0.6-0.6l1.1-0.7l0.5-0.4l0.8-1l0.5-0.8l0.4-0.5l0.3-0.5l0.2-0.5l0.1-0.8l-0.1-0.3l-0.3-0.5l-0.2-0.1l-0.1-0.2l0.1-0.4V372l0.1-1.2
                l0.4-0.9l0.9-1.2l0.2-0.5l0.1-0.8v-0.3l1.1-1.2l0.7-0.9l0.2-0.2l0.6-0.4l2.1-0.9l1.2-0.6l0.7-0.5l0.4-0.6l1.1-2.2l1.1-3.1l0.1-0.4
                l0.5-0.1h0.4l0.3-0.1l0.4-0.2l0.3,0.1l-0.2,0.2v0.4l0.2,0.4l0.4,0.5l0.8,0.6l0.6,0.3l0.8,0.2l1-0.3h0.5l0.3-0.1l0.3,0.2l0.5,0.1
                l0.5-0.1l0.4-0.3l0.3-0.3v0.2v0.2l0.1,0.1l0.2,0.4l0.1,0.2h0.3l0.3,0.1h0.6L468.9,358.1z"/>
            <linearGradient id="MC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="339.1079" x2="0.1864" y2="339.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MC" class="stcustom0 st139" d="M496,326.7l-0.2,0.1v-0.1l0,0H496L496,326.7L496,326.7z"/>
            <linearGradient id="MD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="352.0079" x2="0.1864" y2="352.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MD" class="stcustom0 st140" d="M554.3,320.1l-0.1-0.3l-0.1-0.2l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1v-0.3l-0.1-0.3l-0.1-0.1v-0.2v-0.4
                l0.1-0.7l0.2-0.8l0.1-0.4l-0.1-0.2v-0.5l-0.1-0.3l-0.2-0.4l-0.2-0.8l-0.3-0.3l-0.3-0.3l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.2
                l-0.3-0.6l-0.1-0.3v-0.1l-0.3-0.4l-0.2-0.3l-0.1-0.3v-0.2l-0.2-0.5l-0.2-0.4L551,309l-0.1-0.2l-0.2-0.2l-0.3-0.2h-0.2h-0.3l0.1-0.1
                l0.6-0.4l0.2,0.1h0.3h0.6l0.3-0.3l0.2,0.1l0.2-0.1l0.3-0.1l0,0l0,0l0.4,0.1l0.3,0.1l0.2,0.2l0.2,0.1l0.2,0.1l0.1,0.1v0.2l0.2,0.1
                h0.4l0.2,0.1l-0.1,0.2v0.1l0.1-0.1l0.1,0.1l0.1,0.2l0.1,0.1l0.2-0.3h0.2l0.5,0.1l0.3,0.5l0.2,0.2l0.1,0.1l0.2-0.1l0.2-0.1h0.1
                l0.2,0.3v0.4v0.2l-0.1,0.3l-0.1,0.3l-0.1,0.2v0.2l0.1,0.1h0.1l0.4,0.3l0.1,0.2l0.2,0.1h0.2l0.1,0.1v0.1v0.3l-0.1,0.2v0.2l0.1,0.2
                v0.2v0.1l0.1,0.1l0.3,0.2l0.4,0.2l0.1,0.2l0.1,0.2v0.4v0.4l0.6,0.5l-0.1,0.1l-0.1,0.1l-0.6,0.1H559l-0.2-0.4h-0.1l-0.1,0.1l-0.1,0.1
                h-0.2l-0.2-0.1l-0.1-0.1h-0.1l-0.1,0.1h-0.1l-0.1-0.1l-0.1,0.3l-0.1,0.1h-0.1v-0.5v-0.1h-0.1l-0.3,0.1l-0.3,0.2l-0.1,0.1v0.3v0.3
                l0.2,0.5l-0.1,0.2l-0.1,0.3l-0.3,0.3l-0.3,0.2v0.3l-0.2,0.2l-0.3,0.2l-0.2,0.3v0.2v0.2v0.1v0.1h-0.1h-0.5l-0.1,0.1L554.3,320.1z"/>
            <linearGradient id="MG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="149.2578" x2="0.1864" y2="149.2578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MG" class="stcustom0 st141" d="M614.1,497.8l0.1,0.3l0.2,0.3l0.5,0.7l0.2,0.3l0.2,0.3l0.1,0.6l0.3,0.9l0.3,1.3l0.1,1.4l0.1,0.6
                l0.2,0.6l0.4,0.6l0.1,0.7l-0.2,0.7l-0.3,0.7l-0.1,0.1l-0.1,0.2h-0.1l-0.3-0.2l-0.2-0.3l-0.3-0.7l-0.1-0.3l-0.1-0.1h-0.3l-0.2,0.2
                v0.1v0.4l0.1,0.3v0.3v0.4l0.1,0.1l0.1,0.1l0.1,0.3v0.7l-0.1,0.3l-0.2,0.3v0.2l0.1,0.2l-0.1,0.1l-0.3,0.1l-0.1,0.1l-0.2,0.3l-0.3,0.6
                v0.3l0.2,0.9v0.7l-0.3,1.3l-0.2,0.6l-0.3,0.7l-0.4,1l-0.4,1.2l-0.3,1.3l-0.2,0.8l-0.3,0.7l-0.4,1.3l-0.3,1.3l-0.5,1.5l-0.7,1.7
                l-0.1,0.2l-0.1,0.9l-0.2,0.7l-0.2,0.7l-0.4,1.2v0.4l-0.1,0.4l-0.4,0.8l-0.2,0.3l-0.1,0.3l-0.1,0.4l-0.1,0.4l-0.3,0.7l-0.4,0.6
                l-0.3,0.2l-0.6,0.3l-0.3,0.1h-0.7l-0.6,0.2l-0.7,0.3l-0.6,0.4l-0.2,0.2l-0.3,0.1h-0.8l-0.3-0.1l-0.8-0.6l-0.3-0.1l-0.6-0.1l-0.2-0.1
                l-0.2-0.1l-0.3-0.3l-0.5-0.3l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.4l-0.2-0.3l-0.5-0.6v-0.2v-0.6v-0.4v-0.7l0.1-0.3l0.2-0.3
                l-0.1-0.3l-0.2-0.3l-0.1-0.4l-0.1-0.3l-0.5-0.6l-0.1-0.3l-0.1-0.3l-0.2-0.9v-0.3v-0.7l0.1-0.4l0.1-0.2v-0.2l0.1-0.2l0.1-0.1l0.1-0.1
                l0.2-0.9l0.2-0.2l0.3-0.1l0.3-0.2l0.2-0.3l0.2-0.6l0.4-0.6l0.2-0.3l0.3-0.5l0.3-0.7l0.1-0.3l0.1-0.3l0.1-0.7l0.1-0.4v-0.4l-0.2-0.4
                l-0.4-0.7v-0.1v-0.5v-0.4l-0.2-0.4l-0.2-0.3l-0.2-0.6l-0.1-1v-0.4l-0.1-0.3l-0.1-0.3l0.1-0.6l1.2-2v-0.2v-0.6v-0.4v-0.1l0.1-0.1h0.2
                l1-0.1l0.1-0.1l0.2-0.2l0.3-0.3l0.2-0.1h0.1l0.1,0.1l0.1,0.1l0.4-0.1h0.2h0.2l0.1-0.1V508l0.1-0.1l0.1-0.1h0.5l0.3-0.1l0.4-0.1h0.1
                l0.3,0.5h0.1h0.1l0.1-0.1l-0.3-0.2v-0.1v-0.2l0.2-0.3l0.3-0.3l0.6-0.4l0.6-0.4h0.2l0.1,0.1l0.1,0.5v0.1h0.1l0.1-0.1l0.1-0.2v-0.2
                l-0.1-0.2V506v-0.1l0.3-0.3l0.2-0.3l0.1-0.3l0.1-0.2l0.2-0.2h0.1l0.1,0.1v0.2l-0.1,0.2l0.1-0.2v0.2h0.1h0.1l0.2-0.4l0.2-0.3l0.1-0.2
                l0.2-0.1h0.3l0.3,0.1l-0.4-0.4l-0.1-0.5l0.5-0.9v-0.2l0.1-0.1V502l-0.3-0.3v-0.1v-0.2l0.1-0.2l0.1-0.1l0.2-0.1l0.1,0.1l0.3,0.2h0.2
                l0.2-0.2l0.2-0.3l0.3-0.2l0.3-0.1l0.5-0.5l0.3-1v-0.3l-0.1-0.3l-0.1-0.3l-0.2-0.4v-0.1l0.3,0.1l0.1-0.1l0.3-0.4l0.5-0.7h0.2l0.1,0.1
                l0.1,0.2l0.1,0.1l0.3,0.3L614.1,497.8L614.1,497.8z M610.8,500.4v0.1h-0.4l-0.1-0.4h0.2V500h0.1l0.1,0.3L610.8,500.4L610.8,500.4z
                M615.2,510.7l-0.3,0.5l0.1-0.4l0.4-0.6h0.1L615.2,510.7z"/>
            <linearGradient id="ME_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="335.0079" x2="0.1864" y2="335.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ME" class="stcustom0 st142" d="M529,327.6L529,327.6v0.2l0.1,0.1l0.2,0.1l0.3,0.3l0.4,0.5l0.2,0.1h0.2l0.3,0.2l0.2,0.1l0.2,0.1
                l0.6,0.4l0.3,0.1l0.2,0.2v0.2v0.1l-0.4,0.1l-0.1,0.2h-0.2h-0.2l-0.1,0.1l0.1,0.2l0.1,0.2l-0.1,0.3l0,0h-0.1l-0.3,0.2l-0.2,0.1h-0.2
                l-0.1-0.1v-0.1v-0.3v-0.1l-0.1-0.1l-0.1,0.1l-0.2,0.2l-0.1,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.4l-0.1,0.3l0.1,0.2l0.1,0.2v0.2v0.1v0.3
                v0.2l-0.4-0.3l-0.2-0.4l-0.6-0.7l-0.7-0.5v-0.1V332v-0.1h-0.2l-0.1,0.1h-0.1l-0.1-0.2l-0.1-0.2v-0.1l0,0l0.1-0.1l0.2-0.2v-0.1V331
                l-0.2-0.4v-0.3v-0.5v-0.1l0.1-0.1l0.4-0.1v-0.4V329l0.1-0.2v-0.1l0.2-0.2l0.3-0.2h0.1h0.1l0.1,0.2h0.1v-0.2l-0.2-0.3l-0.1-0.2v-0.1
                l0.1-0.1h0.2l0.1,0.1h0.1L529,327.6L529,327.6z"/>
            <linearGradient id="MF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.3579" x2="0.1864" y2="254.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MF" class="stcustom0 st143" d="M298.3,411.6H298v-0.1l0.1-0.1h0.1l0,0L298.3,411.6z"/>
            <linearGradient id="MH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="226.8079" x2="0.1864" y2="226.8079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MH" class="stcustom0 st144" d="M943.5,431.5L943.5,431.5L943.5,431.5L943.5,431.5L943.5,431.5L943.5,431.5L943.5,431.5L943.5,431.5z
                M948.9,442.4L948.9,442.4h-0.3l-0.1-0.1l0,0h0.2L948.9,442.4L948.9,442.4z M955.3,442.9l0.4,0.1l0.5-0.1h-0.1h-0.2h-0.1h-0.1h-0.1
                H955.3l-0.2-0.1l0,0L955.3,442.9L955.3,442.9z M956.6,443.1l0.1,0.1h0.2l0.2,0.2H957l-0.1-0.1h-0.1h-0.1L956.6,443.1L956.6,443.1
                L956.6,443.1z M951.2,446.5L951.2,446.5l-0.1,0.1L951.2,446.5L951.2,446.5v-0.3l0.1-0.1l0.1-0.1v0.1l-0.2,0.1L951.2,446.5
                L951.2,446.5z"/>
            <linearGradient id="MK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="331.0078" x2="0.1864" y2="331.0078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MK" class="stcustom0 st145" d="M537.8,332.2l0.4,0.6l0.2,0.2l0.3,0.2l0.3,0.1l0.1,0.1l0.2,0.6l0.1,0.2l0.1,0.1v0.1v0.1l-0.2,0.4
                l-0.1,0.9v0.1H539h-0.2l-0.1,0.1l-0.1,0.5l-0.3,0.1l-0.3,0.1h-0.3l-0.5-0.1h-0.1l-0.1,0.1h-0.4l-0.2,0.1l-0.4,0.6l-0.4,0.2l-0.1,0.1
                l-0.3-0.1H535l-0.2,0.1h-0.5h-0.1h-0.4v-0.1l-0.1-0.1l-0.2-0.1h-0.4l-0.1-0.1l-0.2-0.5l-0.1-0.1l-0.1-0.2l-0.2-0.5v-0.2v-0.2
                l-0.1-0.5l0.1-0.1l0.1-0.1v-0.2v-0.3l0.1-0.6l0,0l0,0h0.3l0.1-0.1l0.1-0.1v-0.4l0.1-0.2l0.8-0.4h0.2l0.2,0.2l0.1,0.1h0.1v-0.1
                l0.1-0.2l0.2-0.1l0.5-0.1l0,0h0.2l0.3-0.1l0.2-0.1h0.1l0.1-0.1h0.2l0.2,0.1l0.3-0.1l0.3-0.1h0.1L537.8,332.2L537.8,332.2z"/>
            <linearGradient id="ML_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="253.658" x2="0.1864" y2="253.658" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ML" class="stcustom0 st146" d="M487,408.4v0.5v0.8v0.9v0.8v0.9v0.7v0.9v0.9l-0.1,0.1v0.5v0.6l-0.2,0.7l-0.3,0.5l-0.1,0.5l-0.1,0.3
                l-0.1,0.1v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.3,0.1l-0.5,0.5v0.4l-0.6-0.1l-0.6-0.1h-0.1h-0.1v0.2h-0.9h-0.8H481h-0.6h-0.8h-0.8
                l-0.5,0.4l-0.5,0.4l0,0l-0.6,0.1l-0.8-0.1H476h-0.2v0.2l-0.6-0.2l-0.7-0.2l-0.5,0.1h-0.1l-0.1-0.1l-0.2-0.1h-0.4l-0.3,0.1l-0.4,0.3
                l-0.3,0.3l-0.1,0.1l-0.4,0.2l-0.8,0.4l-0.5,0.3l-0.1,0.1l-0.2,0.1h-0.3l-0.3,0.1l-0.2,0.8l-0.1-0.2l-1-0.3H468l-0.2,0.1l-0.5,0.4
                l-0.3,0.4l-0.1,0.5v0.1v0.2l-0.1,0.1h-0.1h-0.1l-0.4-0.1h-0.1l-0.1,0.2v0.5l-0.1,0.3l-0.3,0.1l-0.2,0.1h-0.2h-0.1l-0.8-0.5l-0.3-0.1
                l-0.3,0.1l-0.3,0.2l-0.1,0.1l-0.2,0.2l-0.2,0.2l0.1,0.2l0.1,0.2l0.1,0.3v0.2l-0.7,0.3l0.1,0.1l0.1,0.1v0.3v0.4l-0.1,0.2l-0.2,0.2
                l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.3,0.1l-0.5,0.1l-0.4,0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2v0.2v0.2l0.1,0.2l0.1,0.1v0.2l-0.1,0.4l-0.1,0.5
                l-0.1,0.2l-0.2,0.1l-0.2,0.1l0.1,0.3v0.5l-0.1,0.4v0.2l-0.1,0.2v0.2h-0.1h-0.4l-0.4,0.1l-0.1,0.1v0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
                h-0.2l-0.2-0.1l-0.1-0.1l0,0l0.1-0.1l0.1-0.1v-0.1l-0.1-0.2l-0.1-0.2v-0.1l-0.1-0.3l0,0l-0.3,0.1h-0.1h-0.1v0.1l0.1,0.2l0,0h-0.2
                l-0.2-0.1l-0.3-0.2l-0.1,0.1v0.2v0.2l0.1,0.3l-0.1,0.1h-0.2h-0.2H456h-0.1l-0.1,0.1v0.1l0.1,0.1v0.1l-0.1,0.1h-0.1h-0.1l-0.2-0.2
                l-0.2-0.1l-0.5-0.1l-0.1-0.2h-0.1l-0.1-0.1l-0.1-0.2h-0.1h-0.1h-0.3l-0.2,0.2l-0.2,0.3l-0.2,0.1l-0.2,0.1h-0.1v-0.2v-0.1l-0.1-0.1
                l-0.6-0.3l-0.1-0.1l-0.1-0.4l-0.1-0.4v-0.2v-0.2v-0.2l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.2,0.1h-0.1H451l-0.1-0.1v-0.1l0.3-0.4l0.1-0.2
                l0.2-0.1l0.1-0.1l0.1-0.1v-0.1v-0.1l-0.2-0.1l-0.3-0.2h-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.1h-0.1l-0.1-0.1v-0.4v-0.3l-0.3-0.5l-0.1-0.3
                l-0.1-0.3l-0.1-0.2l-0.2-0.1l-0.3-0.1h-0.2h-0.2H449v0.1l0.1,0.2v0.1v0.1v0.1H449l-0.2,0.1l-0.3,0.1l-0.2,0.1l-0.2,0.3H448h-0.2
                l-0.5-0.2l-0.4-0.2l-0.3-0.1l-0.2,0.1h-0.1L446,428l-0.3,0.4l-0.1,0.1h-0.1l-0.1,0.1h-0.1h-0.1l0,0l-0.2-0.3l-0.2-0.3l-0.2-0.2h-0.2
                l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.1,0.1h-0.1l-0.3-0.3l-0.2-0.2v-0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.1V427l-0.2-0.2v-0.1v-0.2l0.1-0.4
                v-0.2l0.1-0.3l-0.1-0.1v-0.1l-0.1-0.2l-0.2-0.2v-0.2l-0.1-0.2l-0.2-0.2h-0.1h-0.2v0.1l-0.1,0.1h-0.1l-0.1-0.1v-0.1v-0.1l-0.2-0.2
                l-0.3-0.4v-0.3l0.2-0.2v-0.1V423l-0.1-0.2l-0.1-0.1v-0.3V422l-0.1-0.2l-0.1-0.1l-0.2-0.2l-0.1-0.2l0.1-0.3l0.1-0.2l-0.3-0.5l0.5,0.2
                l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.3l0.1-0.4v-0.3l0.1-0.3l0.1-0.2l0.2-0.2l0.2-0.2l0.3-0.2h0.1l0.3,0.3l0.6,0.5l0.5,0.4l0.2,0.2h0.1
                l0.2-0.4l0.2-0.3l0.1-0.1h0.3h0.3h0.2l0.4,0.1l0.2,0.1h0.2h0.5l0.5-0.1l0.5-0.1l0.4-0.1v-0.2v-0.2l0.1-0.1l0.1-0.1h0.1v0.4l0.1,0.1
                h0.3h0.5h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6l0.2-0.8l0.1-0.8l0.1-0.7l-0.4-0.5l-0.3-0.4l-0.1-0.7
                l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7
                l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.7l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.8
                l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.8l-0.1-0.7h0.9h0.9h0.9h1.3h1l0.9,0.6l0.8,0.6l0.9,0.7l0.9,0.7l0.9,0.7l0.9,0.7l0.9,0.7l0.9,0.7
                l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.9,0.6l0.4,0.3v0.1v0.2v0.3v0.2l0.1,0.1l0.2,0.2
                l0.9,0.5l0.1,0.1v0.2l0.1,0.2l0.2,0.1l0.2,0.1l0.3,0.1l0.8,0.1l0.2,0.1l0.4,0.4l0.2,0.1l0.5,0.1l0.4,0.1h0.2l0.4,0.1l0.4,0.2
                l0.2,0.2v0.1v0.2v0.5l0.1,0.3l0.1,0.2v0.1l-0.1,0.1l-0.1,0.1v0.1l-0.1,0.2l-0.1,0.2v0.1l0.2,0.1l0.2,0.2l0.2,0.1h0.1h0.1h0.1
                l0.7-0.1l0.6-0.1L487,408.4z"/>
            <linearGradient id="MO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="266.6079" x2="0.1864" y2="266.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MO" class="stcustom0 st147" d="M793.6,399.3L793.6,399.3v-0.1h0.1l0.1,0.1l0,0H793.6L793.6,399.3L793.6,399.3z"/>
            <linearGradient id="MM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="258.3579" x2="0.1864" y2="258.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MM" class="stcustom0 st148" d="M758.9,401.2l-0.2,0.3l-0.4,0.3L758,402h-0.2l-0.2,0.2l-0.1,0.4l-0.1,0.2l-0.1,0.1h-0.1v0.2l0.1,0.1
                h0.2v0.1l-0.2,0.1h-0.2h-0.2l-0.2,0.1l-0.2,0.2l-0.2,0.4l-0.2,0.6v0.2l-0.3-0.2l-0.1-0.1h-0.2l-0.2,0.1l-0.1,0.1h-0.1h-0.2l-0.3-0.1
                l-0.2-0.1l0,0l0.1,0.3v0.2v0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.2l-0.2-0.1h-0.2l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.5l-0.1,0.2l-0.1,0.1
                h-0.1h-0.2h-0.2l-0.7,0.2h-0.1h-0.2h-0.2h-0.2l-0.4-0.2h-0.2l-0.1,0.1l-0.1,0.3l-0.2,0.2l-0.3,0.4l-0.1,0.6v0.4l-0.3,0.4v0.2
                l0.1,0.9v0.1h-0.1H749l-0.3,0.1l-0.2,0.1h-0.1l-0.2-0.1h-0.1v0.1l0.2,0.4l0.2,0.2h0.2h0.1v0.1l0.1,0.2l0.2,0.4l0.1,0.3l-0.1,0.3v0.1
                l0.1,0.1l0.2,0.3l0.4,0.4l0.4,0.5l0.3,0.4l0.2,0.3l0.5,0.5l0.1,0.2v0.5l0.1,0.3l0.1,0.2l0.1,0.2l0.2,0.5l0.1,0.1l0.4-0.3l0.1,0.1
                l0.1,0.1v0.2l-0.1,0.2l-0.1,0.2l-0.6,0.4l-0.1,0.3v0.5v0.6v0.5v0.1h-0.1h-0.2l-0.3,0.2h-0.1l-0.2,0.1l-0.1,0.1v0.2l0.1,0.5l0.1,0.5
                l0.2,0.3l0.2,0.3l0.3,0.4l0.2,0.3l0.4,0.4l0.6,0.5l0.2,0.3l0.2,0.4l0.1,0.3l0.1,0.4v0.2v0.8l-0.1,0.2l-0.1,0.2v0.2l0.1,0.2v0.2
                l0.1,0.4l0.2,0.2l0.2,0.2l0.1,0.1v0.2l0.1,0.2v0.2l0.1,0.3l0.2,0.3l0.3,0.9v0.1l-0.1,0.2l-0.2,0.2l-0.1,0.1l-0.1,0.2l-0.2,0.5
                l-0.5,0.8l-0.5,0.5l-0.4,0.4l-0.3,0.2l-0.1,0.1v0.1l0.1,0.2v0.4L752,434l-0.1,0.2v0.2h-0.1l-0.3,0.4l-0.1-0.2l-0.1-0.2l0.1-0.5
                l-0.2-0.9l0.1-0.1l0.1-0.1l0.2-0.4l0.2-0.3v-0.4l0.2-0.3v-0.3v-0.3v-0.2v-0.2l0.1-0.2l0.2-0.2l-0.1-0.1l-0.1-0.1l-0.3,0.2l-0.2-0.1
                v-0.2v-0.2v-0.1l0.1-0.1V429l-0.1-0.2l0.1-0.3h-0.2l-0.1-0.1l0.1-0.2l0.2-0.1l-0.2-0.3l0.1-0.3v-0.4l-0.1-0.3v-0.2l-0.1-0.4
                l-0.1-0.5l-0.2-0.4l-0.2-0.5l-0.5-0.7v-0.3v-0.3l-0.1-0.1l-0.1,1l-0.1-0.2v-0.5l-0.1-0.3l0.1-0.5l-0.3-0.5l-0.1-0.4l-0.2-0.6
                l0.1-0.1l0.3,0.1l-0.2-0.3l-0.2,0.1l-0.2-0.3V420l-0.2-0.4l0.1-0.4l-0.2-1.3l-0.4-0.4l0.1-0.4l0.1-0.3v-0.6l0.1-0.2l0.2-0.1h-0.2
                h-0.1h-0.3h-0.4l-0.1-0.4l-0.2-0.2l-0.2-0.4l-0.1-0.5l0.1-0.1l-0.3-0.2l-0.1-0.1l-0.3-0.3l-0.3-0.2l0.1,0.2l0.1,0.1l-0.2,0.3
                l0.2,0.5l-0.1,0.3l-0.1,0.4l-0.1,0.2l-0.4,0.4l-0.3,0.1h-0.2h-0.2l-0.2-0.2l-0.1-0.2v-0.3H745h-0.1l0.1,0.4v0.2l0.2,0.4l-0.1,0.1
                l-0.4,0.2h-0.2h-0.1v0.2l-0.1,0.1l-0.7,0.2l-0.1,0.3l-0.1,0.3l-0.3,0.4l-0.5,0.3h-0.1l-0.1-0.1v-0.4l0.2-0.3l-0.1-0.3v0.2l-0.3,0.5
                l-0.1,0.1h-0.3l-0.4,0.1l-0.1-0.5v-0.2v-0.1v-0.2v-0.1l-0.1,0.2v0.2l-0.1,0.2l-0.4,0.2v-0.2v-0.2l0.1-0.2V417l0.1-0.5v-0.2v-0.2
                l-0.1,0.3v0.3l-0.1,0.1l-0.1,0.1l-0.3,0.3l-0.2,0.3l-0.4,0.3h-0.2v-0.3l0.2-1.1l0.2-0.2l0.1-0.2l0.1-0.7l0.1-0.3l0.1-0.5l0.1-0.1
                l0.2-0.4l0.1-0.8l-0.1-0.4l-0.2-0.4l-0.2-1.1l-0.5-0.9v-0.3l-0.2-0.4h0.2l-0.4-0.3l-0.1-0.1l-0.1-0.7v-0.4l-0.1,0.1l-0.1,0.3
                l-0.2,0.1l0.1,0.4v0.1l-0.1,0.2l-0.4-0.2L738,409l-0.3-0.5l-0.3-0.5l0.1-0.1h0.1l0.4,0.4l0.3,0.1l0.2-0.1l0.2-0.2l0.1-0.3l-0.1-0.1
                l-0.2-0.1l-0.1-0.1l-0.2-0.2v-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1l0.1-0.2l0.1-0.2h-0.4l-0.4-0.3l-0.1-0.1l-0.1-0.1h-0.3l-0.3,0.1
                l0.1,0.4v0.1h-0.2l-0.4-0.6l0.1-0.2l0.2-0.2H736h-0.1l0.1-0.6l-0.1-0.1v0.2l-0.1,0.2l-0.3,0.4l-0.2-0.1l-0.1-0.1l0.1-0.2l0.1-0.1
                l0.1-0.1l-0.1-0.2l-0.2-0.2l-0.1-0.3H735l0.1,0.3v0.5l-0.3-0.5l-0.6-0.7l-0.2-0.2v-0.2l-0.1-0.2v-0.2V403l-0.1-0.2l-0.1-0.3v-0.3
                l0.1-0.2l0.2-0.2h0.1l0.1,0.1l0.3,0.1l0.2,0.1l0.1,0.2h0.1l0.1-0.1v-0.1l-0.1-0.4v-0.4v-1v-0.1l0.2-0.1l0.1-0.1l0.1-0.2v-0.1h0.1
                l0.1,0.1l0.2,0.3l0.2,0.1h0.2l0.2-0.4l0.1-0.1l0.1-0.1h0.1l0.1-0.1v-0.2v-0.2l-0.2-0.6v-0.3v-0.3l0.1-0.3l0.1-0.3V397v-0.1h0.1
                l0.1,0.1h0.2l0.1-0.2v-0.1l0.1-0.6v-0.6V395l-0.1-0.3l-0.2-0.8l0.1-0.1h0.1l0.3,0.3h0.1h0.2l0.2-0.1h0.1l0.2,0.1l0.3,0.1l0.4,0.1
                l0.2,0.1h0.1l0.1-0.3l0.1-0.4l0.2-0.6l0.2-0.5l0.1-0.1l0.3-0.4l0.3-0.4l0.2-0.5l0.1-0.4v-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.1v-0.1v-0.1
                l0.1-0.2l0.1-0.3l0.1-0.2l0.3-0.2l0.2-0.2l0.2-0.3l0.1-0.2l0.1-0.4l0.1-0.1l0.1-0.1l0.1-0.2v-0.1l-0.1-0.1l-0.1-0.3l-0.1-0.5v-0.4
                l0.1-0.2l0.1-0.2l0.2-0.1l0.3-0.1l0.4-0.3l0.8-0.6l0.3-0.2l0.2-0.1l0.2-0.3l0.3-0.3l0.4-0.1l0.2-0.1l1.1-0.2h0.2l0.2,0.1l0.2,0.4
                l0.2,0.1l0.2,0.1h0.2v-0.1l-0.6-0.9l-0.1-0.2v-0.2l0.1-0.2l0.2-0.2l0.2-0.2l0.3-0.2l0.2-0.2l0.2-0.1l0.1-0.1v-0.1v-0.2l-0.1-0.2
                v-0.2v-0.2l0.1-0.1l0.2-0.3l0.1-0.2l0.1-0.1l0.1-0.2h0.2l0.2,0.1l0.1,0.1l0.1,0.2l0.1,0.2h0.1h0.1h0.1l0.1,0.1l0.2,0.3l0.1,0.1
                l0.1,0.1l0.1,0.3v0.3l0.3,1l0.1,0.2l0.1,0.2h0.1l0.1-0.2v-0.2l0.1-0.1h0.1l0.3,0.2l0.1,0.1l0.1,0.5v0.6v0.2l0.1,0.5v0.5v0.3v0.3v0.4
                l-0.1,0.5l-0.1,0.4v0.3l-0.1,0.2l-0.3,0.1v0.1l0.1,0.2l0.2,0.3v0.2l-0.1,0.1h-0.2l-0.3,0.1l-0.2,0.3l-0.2,0.3l-0.1,0.1l-0.3-0.1
                l-0.1,0.1l-0.1,0.5l-0.1,0.2l0.5-0.1l-0.1,0.1l-0.1,0.1h-0.3l-0.1,0.3l-0.1,0.4v0.2l0.1,0.3v0.1l-0.1,0.1l-0.2,0.1l-0.2,0.4v0.4
                l0.1,0.1l0.2,0.1l0.1,0.1v0.2l0.1,0.3v0.3l-0.3,0.4v0.2l0.2,0.1h0.2l0.2-0.1l0.2-0.2l0.5-0.2l0.5-0.1h0.4h0.4l0.2,0.1l0.1,0.1
                l0.5-0.1h0.1h0.1v0.1l-0.4,0.4l-0.1,0.2v0.2l0.2,0.2l0.1,0.1l0.1,0.3l-0.1,0.3l0.1,0.1l0.1,0.1l0.1,0.2v0.2l-0.1,0.4l0.5,0.2
                l0.5,0.1h0.3l0.2,0.1l0.1,0.1l0.1,0.1v0.1l-0.1,0.1l-0.2,0.3l-0.1,0.4v0.3v0.3l-0.3,0.4l-0.1,0.3l-0.1,0.3v0.1l0.1,0.1h0.1h0.2h0.2
                l0.6,0.1l0.7,0.1l0.3,0.1l0.1,0.1v0.3v0.2v0.2l0.1,0.2l0.2,0.1l0.2,0.1v0.1v0.2l0.1,0.1l0.1,0.1l0.2,0.1l0.4-0.1l0.3,0.1l0.2,0.1
                h0.2l0.2-0.1l0.4-0.5l0.5-0.2l0.2-0.1h0.1l0,0v0.1L758.9,401.2L758.9,401.2L758.9,401.2z M736.1,406.1v0.3l-0.1-0.1l-0.2-0.4v-0.3
                l0.1,0.1L736.1,406.1L736.1,406.1z M737.5,406.2l0.1,0.4l-0.2-0.2l-0.1-0.2V406L737.5,406.2L737.5,406.2z M738.1,407.2l0.3,0.2h0.1
                l0.2,0.2v0.1v0.1l-0.1,0.1l-0.2,0.1l-0.2-0.1l-0.1-0.3l-0.2-0.1l-0.1-0.1L738.1,407.2L738.1,407.2L738.1,407.2z M738,409.8
                L738,409.8l-0.3-0.2l-0.2-0.3l0.4-0.1l0.4,0.1v0.2L738,409.8L738,409.8z M748.9,416.9L748.9,416.9l-0.3-0.2v-0.5l0.1-0.1h0.1
                l0.1,0.1v0.1v0.1v0.1L748.9,416.9L748.9,416.9z M740.2,417.8l-0.2,0.3l-0.1-0.4l0.3-0.2l0.1-0.2l0.2-0.2v0.2l-0.1,0.4L740.2,417.8
                L740.2,417.8z M741.2,418.1L741.2,418.1H741l0,0l0.3-0.3v0.1L741.2,418.1L741.2,418.1z M751,426v0.5l-0.1-0.2L751,426l-0.1-0.2l0,0
                l0,0l0.1,0.1V426L751,426z M751.3,427.4L751.3,427.4l0.2,0.1v0.3l-0.2,0.3h-0.1h-0.1v-0.5l-0.1-0.3v-0.2l0.2,0.1L751.3,427.4
                L751.3,427.4z M750.3,428v0.1l-0.2,0.2l-0.1-0.1v-0.1l0.1-0.1H750.3L750.3,428L750.3,428L750.3,428z M750.5,428.7L750.5,428.7
                L750.5,428.7h-0.2l-0.1-0.2l0.1-0.1l0,0h0.1l0,0l0,0v0.1v0.1L750.5,428.7z M751.6,429.4L751.6,429.4l-0.2-0.5v-0.1l0.2,0.2l0.2,0.1
                l-0.1,0.1L751.6,429.4z M750.3,430v0.2l-0.2-0.2v-0.5l0.1,0.3l0.1,0.1L750.3,430L750.3,430z M751.7,429.8l-0.1,0.6l-0.2-0.1h-0.1
                l-0.1-0.3v-0.1l-0.1-0.2h0.4L751.7,429.8L751.7,429.8z M750.8,430.6L750.8,430.6L750.8,430.6L750.8,430.6l-0.1-0.3l0.1-0.2l0.1-0.3
                l0.1-0.1v0.2l-0.1,0.4L750.8,430.6L750.8,430.6L750.8,430.6z M751.7,432.1L751.7,432.1L751.7,432.1h-0.2l0.1-0.3L751.7,432.1z
                M750.7,432.1l0.2,0.5v0.1l0,0h-0.1l-0.1-0.3l-0.2-0.2h-0.2l0.2-0.2h0.1L750.7,432.1L750.7,432.1z M750.6,435l-0.1,0.2l0,0
                L750.6,435l0.2-0.3h0.2v0.1l-0.1,0.2L750.6,435L750.6,435z"/>
            <linearGradient id="MN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="352.2078" x2="0.1864" y2="352.2078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MN" class="stcustom0 st149" d="M802.6,301.9l-0.3,0.6l-0.5,1.2l-0.4,1l-0.2,0.6l-0.2,0.4l-0.2,0.4v0.2l-0.2,0.4l-0.4,0.5l-0.1,0.5
                v0.5v0.4l-0.4,0.3l-0.3,0.2l0.1,0.8l0.2,0.3l0.3,0.3l0.3,0.3l0.2,0.2l0.3-0.1l0.2-0.3l0.4-0.3h0.2l0.2,0.1h0.4l0.4-0.1h0.3l0.4,0.1
                l0.1,0.1l0.3,0.1l0.4,0.3l0.2,0.3l0.2,0.1l0.1-0.1l0.2-0.3l0.3-0.3l0.3-0.4l0.3-0.3l0.2-0.1h0.4l0.2-0.1h0.3l0.3,0.1l0.7,0.1
                l0.2,0.2l0.3,0.5l0.2,0.3l0.3,0.1l0.2,0.1l0.2,0.1l0.2,0.1v0.2l0.1,0.2l0.1,0.1l0.2,0.1l0.2,0.1v0.2v0.1l0.1,0.1l0.4,0.5l0.2,0.1
                l0.3,0.3l0.1,0.2v0.3l0.1,0.2l0.2,0.3l0.1,0.2v0.3v0.2l-0.1,0.2l-0.3,0.2l-0.1,0.1h-0.2l-0.4-0.1l-0.4,0.1l-0.5-0.1l-0.4-0.2
                l-0.2-0.2l-0.3-0.1l-0.2,0.1l-0.2,0.2H808h-0.2h-0.5l-0.3-0.1l-0.4,0.2h-0.2l-0.5,0.2l-0.3,0.3l-0.2,0.1h-0.2l-0.1-0.1l-0.2-0.1
                h-0.3l-0.1,0.1v0.1l-0.1,0.4v0.2l-0.1,0.1h-0.2h-0.3h-0.5l-0.3-0.1h-0.2l-0.3,0.2h-0.2l-0.2,0.1l-0.1,0.3l-0.2,0.2l-0.2,0.3
                l-0.3,0.5l-0.1,0.3v0.2v0.2v0.1l-0.2,0.2h-0.2l-0.3,0.2l-0.4,0.4l-0.3,0.3l-0.4,0.1l-0.3,0.1l-0.6,0.1h-0.2h-0.7l-0.5-0.2h-0.3
                l-0.2,0.1l-0.1,0.1v0.2v0.2l-0.2,0.3l-0.4,0.4l-0.3,0.2l-0.2,0.3l-0.1,0.1l-0.3,0.1l-0.2,0.1l-0.4,0.3l-0.3,0.2l-0.2,0.1l-0.2-0.1
                h-0.1l-0.4-0.1h-0.3l-0.4-0.1l-1-0.3l-0.3-0.1l-0.3-0.4l-0.2-0.2h-0.3h-0.5l-0.2-0.1l-0.4,0.1l-0.4,0.4l-0.2,0.3l-0.2,0.3l-0.2,0.6
                l-0.1,0.4l-0.1,0.2l-0.2,0.4v0.2l0.1,0.2l0.2,0.2l0.1,0.3l0.2,0.3l0.2,0.3l0.4,0.4l0.1,0.2l0.1,0.2v0.2v0.2l-0.2,0.1l-0.3,0.1
                l-0.1,0.2l-0.2,0.2l-0.3,0.3h-0.1l-0.1,0.1l-0.7,0.3l-0.3,0.1l-0.2,0.1l-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.1,0.1l-0.2,0.3l-0.3,0.4
                l-0.2,0.2l-0.1,0.1l-0.1,0.2l-0.3,0.1l-0.3,0.1l-0.4,0.2l-0.6,0.2l-0.5,0.2l-0.3,0.2l-0.4,0.2l-0.3,0.1h-0.6l-0.7,0.1H780h-0.4H779
                h-0.5l-0.3,0.1l-0.7,0.1h-0.2l-1.3,0.2l-0.6,0.1h-0.5l-0.4,0.1l-0.2,0.1l-0.3,0.1l-0.2,0.1l-0.6,0.3l-1.3,0.6l-0.8,0.4l-0.1,0.1
                l-0.6,0.3l-0.3,0.1l-0.2,0.3l-0.2,0.2l-0.2,0.1l-0.3-0.2H769l-0.8-0.1v-1l-0.5,0.1l-0.9,0.2l-0.8,0.2l-0.7-0.4l-0.6-0.3l-0.5-0.3
                l-0.7-0.2l-0.7-0.2l-1.2-0.2l-0.5-0.2l-0.3-0.3l-0.5-0.7l-0.2-0.1l-0.2-0.1l-0.2-0.1h-0.5l-0.6-0.1l-0.9-0.1L757,331l-1.2-0.2h-0.3
                l-0.6,0.2l-0.8,0.2l-1.5-0.2l-0.6-0.1l-1.3-0.2l-1.5-0.2l-1.4-0.2l-1,0.1l-0.6,0.1l-0.7,0.1l-0.1-0.1v-0.4l-0.1-0.3l-0.4-0.3
                l-0.2-0.3l-0.5-0.4l-0.1-0.3v-0.4l-0.4-1.1l-0.3-0.7l-0.1-0.1l-0.1-0.2l-0.2-0.1l-0.3-0.1l-0.1-0.1v-0.3l0.1-0.4l0.1-0.3v-0.1
                l-0.8,0.1l-0.5-0.2l-0.4-0.2l-0.6-0.5l-0.4-0.2l-0.5-0.5l-0.7-0.1l-0.3-0.2l-0.3-0.4l-0.3-0.3l-0.4-0.2l-0.6-0.2l-1.1-0.1l-0.4-0.1
                l-0.6,0.1h-0.4l-0.7-0.1l-0.4-0.1h-0.5h-0.3h-0.4l-0.2-0.1l-0.2-0.1h-0.4l-0.3-0.1l-0.2-0.2l-0.2-0.1h-0.3l-0.1,0.1h-0.1l-0.1-0.3
                l-0.3-0.4V320l-0.2-0.2l-0.1-0.2v-0.3l0.1-0.5l0.2-0.5l0.2-0.1l0.1-0.1l0.2-0.3l0.1-0.2v-0.3l-0.1-0.3l-0.1-0.4v-0.2l0.2-0.3
                l0.2-0.6v-0.2l-0.1-0.1v-0.3v-0.4l-0.2-0.5l-0.1-0.3l-0.2-0.1l-0.2-0.1l-0.2-0.4l-0.4-0.2l-0.2-0.3l-0.1-0.2v-0.3l-0.1-0.4l-0.1-0.2
                l-0.1-0.2v-0.2V311l-0.4-0.1l-0.2-0.2l-0.1-0.2v-0.2l-0.1-0.1h-0.2l-0.1,0.2l-0.2,0.1h-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.4
                l-0.2-0.1h-0.4l-0.4,0.2h-0.2l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.4-0.3l-0.3-0.2v-0.4l-0.1-0.3l-0.3-0.1l-0.3-0.3l-0.4-0.2
                l-0.3-0.1l-0.2-0.1v-0.1v-0.1l0.1-0.2l0.1-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.1-0.2l-0.2-0.2v-0.2l0.1-0.1l0.1-0.1v-0.1
                l-0.1-0.1l-0.1-0.2V305v-0.2l0,0h0.3l0.2-0.1l0.1-0.1l0.2-0.2l0.1-0.2v-0.4l0.2-0.3l0.4-0.1h0.2h0.2h0.3h0.2l0.1,0.1l0.2,0.1h0.2
                l0.1-0.1v-0.2l0.1-0.1l0.1,0.1l0.1,0.1h0.1l0.3-0.1l0.2-0.1l0.1-0.3l0.1-0.1l0.2,0.1h0.3l0.2-0.2l0.3-0.2l0.2-0.1v-0.1l-0.1-0.3
                v-0.3l0.3-0.2h0.4l0.3-0.1l0.1-0.4l0.1-0.1h0.1l0.3-0.1l0.2-0.2l0.2-0.1l0.4-0.2h0.4l0.2-0.2l0.1-0.2l0.2-0.1l0.2-0.2l0.3-0.2h0.1
                l0.5-0.1h0.2h0.1h0.2l0.1-0.2l0.2-0.2l0.2-0.1l0.1-0.2l0.2-0.2l0.3-0.1h0.4h0.4h0.2l0.2-0.3v-0.2v-0.2l0.2-0.1l0.2,0.3l0.2,0.2
                l0.3,0.2l0.1,0.2h0.2l0.2-0.1l0.1-0.3h0.2h0.2l0.1,0.1v0.1l0.1,0.3l0.3,0.2h0.3h0.1h0.3h0.3l0.3,0.1h0.1h0.4h0.5h0.2l0.5,0.1
                l0.1,0.2l0.1,0.5l0.1,0.4v0.4l0.1,0.2l0.2,0.1l0.1,0.1l0.2,0.2l0.1,0.3h0.2l0.1-0.1h0.3h0.3l0.2,0.2l0.1,0.2l0.2,0.1h0.2h0.5h0.2
                l0.2,0.1h0.2l0.1-0.1l0.4-0.1l0.2-0.2h0.2l0.1,0.1l0.1,0.1l0.2-0.1l0.1-0.1h0.1l0.1,0.1l0.3,0.1l0.2,0.2h0.2l0.2-0.1h0.1l0.1,0.1
                l0.2,0.1l0.1-0.1l0.2-0.1l0.8,0.1l0.2,0.2l0.1,0.1l0.1,0.2l0.2,0.1h0.4l0.2-0.1l0.3-0.3l0.1-0.3l0.2-0.1h0.2h0.2h0.2l0.2-0.2
                l0.2-0.1l0.3-0.3l0.1-0.1l0.1-0.3l0.1-0.2l0.1-0.3l0.1-0.5v-0.3v-0.2l-0.2-0.1h-0.2l-0.2-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.3v-0.2v-0.2
                l-0.1-0.1l-0.2-0.2l-0.1-0.2v-0.3l0.2-0.5v-0.2V296v-0.1l0.1-0.3l0.1-0.1l0.1-0.3l0.2-0.2h0.2l0.1-0.1v-0.3l0.1-0.3l0.1-0.2l0.1-0.2
                l0.8-0.4l0.3-0.5l0.1-0.2l0.1-0.5l0.1-0.2l0.2,0.1l0.2,0.3h0.2l0.2,0.2l0.6,0.3l0.4,0.1h0.2l0.3,0.1l0.2,0.2l0.4,0.3l0.3,0.1h0.5
                h0.7l0.2,0.1l0.5,0.2l0.5,0.3l0.5,0.2l0.4,0.2l0.2,0.2l0.2,0.1l0.2-0.1h0.3l0.7,0.2l0.4,0.2l0.4,0.1l0.1,0.2v0.2v0.2v0.5l0.1,0.3
                v0.3v0.3v0.3l0.1,0.2l0.1,0.1l0.1,0.2v0.2v0.1v0.2l0.1,0.2h0.2h0.2l0.2,0.3l0.4,0.3l0.2,0.1l0.3,0.1l0.5,0.1h0.3l0.2,0.1l0.2,0.3
                l0.3,0.1l0.2,0.1l0.4,0.1l0.3-0.1l0.2-0.1h0.1l0.3,0.1h0.3l0.3-0.1l0.2-0.2l0.3-0.3l0.3-0.1h0.4l0.2-0.1l0.8-0.2h0.3l0.3-0.2
                l0.2-0.1l0.3-0.1l0.4,0.1l0.4,0.1h0.5l0.3,0.2l0.2,0.2l0.4,0.1l0.4-0.1h0.6l0.4,0.1l0.4,0.3l0.2,0.2l0.3,0.5l0.3,0.2l0.3,0.2h0.3
                h0.8l0.4,0.1l0.4,0.1l0.1,0.1v0.3v0.5v0.2l0.1,0.2h0.1l0.1,0.2l0.2,0.1l0.3,0.2l0.5,0.6l0.3,0.2l0.3,0.1l0.3-0.1h0.5h0.9l0.6,0.2
                l0.2,0.1l0.6,0.1l0.7,0.1l0.6,0.2l0.3-0.2h0.3l0.3,0.1l0.3,0.2h0.2l0.3-0.1l1.1-0.6l0.4-0.2l0.3,0.1l0.2-0.1l0.2-0.1l0.5-0.1h0.3
                l0.3-0.1h0.4l0.8-0.4l0.3-0.1l0.6,0.1l0.3-0.1l0.3-0.2l0.4-0.2l0.1-0.3l0.2-0.5l0.4-0.3l0.4-0.3l0.4-0.3l0.4-0.2l0.4-0.2l0.5-0.5
                l0.4-0.2l0.2-0.1l0.3,0.1l0.5,0.1h0.3l0.2,0.1l0.4,0.2l0.3,0.2l0.3,0.3l0.5,0.5l0.3,0.2l0.2,0.1h0.4h0.4l0.2-0.1l0.4-0.2l0.6-0.3
                h0.2l0.4,0.1l0.6,0.3l0.2,0.2L802.6,301.9z"/>
            <linearGradient id="MP_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="249.7079" x2="0.1864" y2="249.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MP" class="stcustom0 st150" d="M884,409.5l-0.1,0.1l-0.1-0.1v-0.2h0.1L884,409.5L884,409.5z M884.2,411.6l-0.1,0.1l0.2-0.3h0.1
                l0.1,0.1L884.2,411.6L884.2,411.6z M884,416.6L884,416.6h-0.2h-0.1v-0.1h0.2L884,416.6L884,416.6L884,416.6z M884.1,420.1
                L884.1,420.1l-0.2,0.1l0,0l0.1-0.3l0.2-0.1h0.1l-0.1,0.1v0.1L884.1,420.1L884.1,420.1z M883.9,420.6l-0.1,0.1l-0.1-0.2v-0.1l0.1-0.1
                h0.1V420.6L883.9,420.6z M882.8,422.9l-0.1,0.1h-0.1h-0.1v-0.1H882.8L882.8,422.9L882.8,422.9z"/>
            <linearGradient id="MQ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="244.3079" x2="0.1864" y2="244.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MQ" class="stcustom0 st151" d="M304.4,422v0.2h-0.1l-0.1-0.1h-0.5l-0.1-0.1v-0.1l0.2-0.2l-0.3-0.1l-0.1-0.1l-0.2-0.4V421l0.1-0.1
                h0.1l0.3,0.1l0.2,0.2h0.1v0.1v0.1l0.1,0.1l0.1,0.1L304.4,422z"/>
            <linearGradient id="MR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="263.4078" x2="0.1864" y2="263.4078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MR" class="stcustom0 st152" d="M461.6,390.8h-1h-1.3h-0.9h-0.9h-0.9l0.1,0.7l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.8
                l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.8l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7
                l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.1,0.7l0.3,0.4l0.4,0.5l-0.1,0.7
                l-0.1,0.8l-0.2,0.8h-0.6h-0.6h-0.6h-0.6h-0.6h-1.1h-0.6H455h-0.6h-0.6h-0.6h-0.6h-0.4h-0.6H451h-0.6h-0.6h-0.5H449l-0.1-0.1v-0.4
                h-0.1l-0.1,0.1l-0.1,0.1v0.2v0.2l-0.4,0.1l-0.5,0.1l-0.5,0.1h-0.5h-0.2l-0.2-0.1l-0.4-0.1h-0.2h-0.3h-0.3l-0.1,0.1l-0.2,0.3
                l-0.2,0.4h-0.1h-0.3l-0.5-0.4l-0.6-0.5l-0.3-0.3h-0.1l-0.3,0.2l-0.2,0.2l-0.2,0.2l-0.1,0.2l-0.1,0.3v0.3l-0.1,0.4l-0.2,0.3l-0.2,0.2
                l-0.2,0.1l-0.1,0.1l-0.5-0.2h-0.1l-0.3-0.2l-0.1-0.2l-0.2-0.2l-0.3-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1v-0.1v-0.1v-0.1
                l-0.2-0.3l-0.2-0.1h-0.2h-0.1l-0.1-0.1v-0.1l-0.1-0.1h-0.2l-0.1-0.2l-0.1-0.4l-0.1-0.3l-0.2-0.2l-0.1-0.1h-0.1l0,0v-0.1h-0.1
                l-0.2,0.1h-0.2l-0.1-0.1h-0.1l-0.1,0.1h-0.2l-0.2-0.2l-0.1-0.2V417l-0.3-0.3l-0.6-0.5l-0.7-0.2h-0.7h-0.4l-0.1-0.1H433l-0.1,0.1
                h-0.1h-0.1h-0.1v0.1l-0.2,0.1h-0.5l-0.4,0.1l-0.3,0.1l-0.4,0.1h-0.5l-0.3-0.1l-0.1-0.1h-0.2h-0.2l-0.2,0.2l-0.2,0.4l-0.1,0.2
                l-0.1,0.1l-0.1,0.3l-0.1,0.5l-0.1,0.2v-1.3l0.2-0.5V416l0.3-0.9l0.4-0.8l0.4-1l0.1-1v-1l-0.1-0.9l-0.2-0.6l-0.2-0.8l-0.3-0.4
                l-0.5-0.4l-0.1-0.2l0.1-0.1l0.3-0.1l0.2-0.3l-0.4,0.1l0.5-0.9l0.1-0.6v-0.4l0.1-0.3l-0.3-0.6l-0.3-0.7l-0.1-0.1l-0.1-0.1v0.2
                l-0.1,0.1l-0.2-0.1l-0.3-0.5l-0.4-0.8l-0.1-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.7v-0.3l0.1-0.3l0.1-0.4l0.1-0.6h0.4h0.6h0.6h0.6h0.6h0.6
                h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.4v-0.4v-0.3v-0.4v-0.4v-0.4v-0.4v-0.4v-0.4v-0.3v-0.2l-0.1-0.4v-0.2v-0.2l0.1-0.2
                l0.3-0.4l0.4-0.3l0.4-0.3l0.3-0.2l0.2-0.1l0.5-0.1l0.4-0.2l0.4-0.2l0.2-0.1v-0.3v-0.4v-0.4v-0.5v-0.4v-0.4v-0.4v-0.4v-0.4v-0.4v-0.4
                v-0.4v-0.4v-0.4v-0.4v-0.6v-0.4v-0.4v-0.4h0.4h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.6v-0.4v-0.5v-0.7
                v-0.7v-0.6v-0.6v-0.5l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3l0.5,0.3
                l0.5,0.3l0.5,0.3l0.5,0.3l0.4,0.3l0.7,0.5l0.6,0.4L461.6,390.8L461.6,390.8z M429.1,406.8l-0.2,0.3l-0.1-0.1v-0.2l0.2-0.3l0.1-0.1
                l0.1-0.1L429.1,406.8z"/>
            <linearGradient id="MS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="250.4079" x2="0.1864" y2="250.4079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MS" class="stcustom0 st153" d="M300.7,415.5v0.2l-0.2-0.1v-0.2l0.1-0.2l0,0L300.7,415.5z"/>
            <linearGradient id="MT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="310.7079" x2="0.1864" y2="310.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MT" class="stcustom0 st154" d="M515.3,354.9l-0.2,0.1l-0.2-0.1v-0.1l0.2-0.1h0.1L515.3,354.9L515.3,354.9L515.3,354.9z M516,355.5
                l-0.1,0.1h-0.3l-0.2-0.2V355l0.3,0.1l0.2,0.2L516,355.5L516,355.5z"/>
            <linearGradient id="MU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="145.3579" x2="0.1864" y2="145.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MU" class="stcustom0 st155" d="M636.9,521.2l-0.4,0.1h-0.4l-0.2-0.2V521l0.1-0.1v-0.2l0.1-0.3l0.1-0.1l0.2-0.1l0.1-0.3l0.2-0.2h0.2
                l0.2,0.3l0.2,0.3v0.3l-0.2,0.1l-0.1,0.2L636.9,521.2z"/>
            <linearGradient id="MV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="213.4579" x2="0.1864" y2="213.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MV" class="stcustom0 st156" d="M681.4,451.2L681.4,451.2L681.4,451.2L681.4,451.2L681.4,451.2v-0.1V451l0,0l0,0l0,0v0.1V451.2
                L681.4,451.2L681.4,451.2z M681.1,453.8L681.1,453.8L681.1,453.8l-0.1-0.1l0,0h0.1l0,0V453.8L681.1,453.8L681.1,453.8z"/>
            <linearGradient id="MW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="165.608" x2="0.1864" y2="165.608" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MW" class="stcustom0 st157" d="M573.2,495.3h-0.4h-0.5l-0.1,0.1v0.2l-0.1,0.4l-0.1,0.2l-0.2,0.3l-0.2,0.4l-0.1,0.1v0.1l0.1,0.5
                l0.2,0.6v0.2l0.1,0.7l0.1,0.5v0.3l0.1,0.4l0.1,0.2l0.1,0.1l0.5,0.1l0.2,0.1l0.3,0.3l0.7,0.7l0.4,0.5l0.3,0.4l0.6,0.8l0.4,0.6
                l0.1,0.6l0.1,0.1l-0.1,0.4l-0.1,0.7l0.1,0.4v0.8l-0.1,0.8l-0.1,0.3l-0.1,0.1l-0.3,0.1l-0.7,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.4l-0.2,0.4
                l-0.1,0.2l0,0l0.1,0.2l0.1,0.5v0.8v0.1h-0.2h-0.2h-0.1l-0.1-0.1l-0.1-0.2l0.1-0.1v-0.2l-0.1-0.2h-0.2l-0.2-0.2l-0.5-0.6l-0.4-0.4
                l-0.2-0.3l-0.2-0.1l-0.1-0.1l-0.1-0.1v-0.2v-0.1l-0.1-0.2l-0.2-0.3l-0.1-0.1v-0.2l0.1-0.2l0.2-0.2l0.2-0.4l0.1-0.3l0.3-0.5v-0.5
                v-0.4v-0.3l-0.1-0.6l-0.1-0.4l-0.4-0.5h-0.1H571l-0.3,0.1l-0.1,0.1h-0.2l-0.6,0.1h-0.2l-0.1,0.1h-0.1l-0.4-0.4l-0.3-0.4l-0.4-0.7
                l-0.1-0.1L568,502l-0.1,0.1l-0.2,0.1h-0.1l0,0l0,0v-0.1l-0.1-0.3l-0.1-0.2l-0.2-0.1l-0.1-0.1l0.1-0.1l0.1-0.1v-0.1l-0.1-0.1
                l-0.3-0.1v-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.1-0.3l0.1-0.3l0.1-0.1v-0.2v-0.2l0.1-0.3v-0.3l-0.1-0.1l-0.1-0.2l0.1-0.3l0.1-0.2l0.6-0.2
                l0.4-0.2l0.1-0.1l0.1-0.2l0.1-0.2H569h-0.3l-0.1-0.1l-0.2-0.6l0.1-0.6v-0.3v-0.3V495l-0.1-0.1l-0.1-0.1v-0.3h0.1l0.2-0.4l0.1-0.3
                l-0.1-0.2l-0.1-0.3l-0.1-0.2V493l0.1-0.1l0.1-0.1h0.2l0.2-0.1l0.5-0.5v-0.1l-0.1-0.2l-0.2-0.3v-0.1v-0.3l-0.1-0.1l-0.3-0.2l-0.2-0.2
                l0.1-0.2v-0.3l-0.1-0.1l-0.2-0.1l-0.1-0.2v-0.2l-0.1-0.1h-0.1l-0.1,0.1h-0.1h-0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.1v-0.1l0,0h0.1l0.4,0.3
                h0.3l0.3,0.1l0.3,0.3h0.1h0.2h0.5h0.2l0.2,0.1h0.1h0.2l0,0v-0.1v-0.2v-0.1l0.1-0.1l0.3,0.1l0.7,0.5v0.1l0.4,0.5l0.1,0.2v0.1l0.1,0.5
                v0.2v0.2v0.1v0.2v0.1l0.1,0.3l0.1,0.2v0.2v0.2l-0.1,0.3v0.1v0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.2l0.1,0.1h0.1h0.1l0.1,0.1l0.1,0.2
                V495.3L573.2,495.3L573.2,495.3z M572.3,496.6l-0.1,0.2h-0.1l-0.1-0.1v-0.1h0.1H572.3L572.3,496.6L572.3,496.6z M572.5,496.8
                L572.5,496.8h-0.2v-0.2v-0.1h0.1h0.1l0,0v0.1L572.5,496.8L572.5,496.8z"/>
            <linearGradient id="MX_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="271.7579" x2="0.1864" y2="271.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MX" class="stcustom0 st158" d="M202.5,387.8l-0.1,0.6l-0.2,0.5l-0.6,1.1l-0.2,0.7l-0.5,1.9l-0.1,1.3v0.6v0.1v0.1l-0.1,1.3l0.1,1.1
                v0.2l-0.2,0.3l-0.1,0.5v0.2v0.1l0.2,0.7l0.1,0.5l0.5,0.9l0.3,0.3l0.3,0.3l0.1,0.2l-0.1,0.4l-0.1,0.2l-0.1,0.3l-0.1-0.3v-0.3l0.1-0.2
                v-0.1l-0.2-0.1l-0.4-0.4l-0.5-0.8l0.3,1.3l0.1,0.2l0.1,0.1l0.1,0.1v0.1v0.1l0.4,0.9l0.5,0.9v0.2l0.2,0.3l1.2,1.3l0.7,0.9l0.2,0.9
                l0.1,0.3l0.1,0.4l0.5,0.4l0.1,0.3l0.2,0.2l0.2,0.5l0.4,0.3h-0.1l-0.3-0.1v0.1l0.3,0.2l0.5,0.2h0.1l-0.2-0.1l-0.2-0.1h0.1l0.4,0.2
                l1.1,0.1l0.5,0.4l0.6,0.2l0.3,0.5l0.4,0.5h0.2h0.2l0.6-0.1l0.9-0.3l0.3-0.2l0.6-0.2h0.9l0.3,0.1l0.7-0.1l0.3-0.2l0.1-0.1l0.1-0.1
                l0.6-0.2h0.1h0.6l0.3-0.1h0.4l0.3,0.2v0.1l-0.2,0.1l0.1,0.1l0.3,0.2l0.6,0.1h0.2l0.3-0.3l0.5-0.2v-0.3l-0.1-0.2h-0.1V410l0.1-0.2
                v-0.1l-0.3,0.2h-0.1v-0.1l0.1-0.1l0.8-0.4l0.2-0.2l0.3-0.2l0.6-0.6l0.1-1.1l0.1-0.2l0.4-0.3v-0.1v-0.2v-0.6v-0.5v-0.5l0.1-0.5
                l0.1-0.1l0.2-0.8l0.5-0.3l0.8-0.4l0.2-0.1l2.6-0.4l0.4-0.1l0.5-0.3l0.3-0.1h0.6h0.2l0,0l0,0h0.1h0.4l0.7,0.2h0.2l0.6,0.2l0.6,0.1
                h0.1l0.1-0.1l0.1-0.1l-0.1-0.1h-0.1L230,402h-0.1l-0.3-0.1l0.1-0.1h0.3h0.2l0.2-0.1l0.3,0.1l0.3,0.4l0.2,0.1v0.6v0.1l0.1,0.1
                l-0.1,0.4l-0.1,0.4l-0.2,0.3l-0.4,0.5l-0.5,0.4l-0.6,0.8l-0.1,0.4v0.3l0.1,0.3v0.1l-0.1,0.1h-0.1l-0.2,0.1l-0.3,0.4v0.1l0.1,0.1
                l0.2-0.1h0.2h0.1h0.1v0.2l-0.1,0.2l-0.1,0.1H229l-0.2,0.1l-0.1,0.1v0.3h0.1l0.2-0.2h0.1v0.1l-0.3,0.7l-0.2,0.7l-0.2,0.4l-0.1,0.6
                l-0.1,0.3l-0.1,0.3H228l-0.2-0.5l-0.2-0.1v-0.1l0.1-0.6l-0.1-0.3h-0.1l-0.1,0.2l-0.2,0.2v0.2L227,411l-0.1,0.1h-0.2h-0.2l-0.2,0.1
                l-0.2,0.5l-0.4,0.6l-0.2,0.3l-0.1,0.1h-0.1l-0.1-0.1l-0.3-0.2l-0.2,0.1l-0.1,0.2v0.3H224h-1h-1.3h-1.2h-1v0.6v0.5v0.6h-0.6h-0.6v0.1
                l0.2,0.1l0.3,0.3l0.3,0.4l0.4,0.3l0.4,0.2l0.3,0.2l0.1,0.2l0.1,0.2v0.2l0.2,0.1l0.3,0.1l0.2,0.1v0.1l0.1-0.4v0.3v0.3H221h-0.5h-0.8
                H219h-0.6h-0.8l-0.2,0.4l-0.4,0.7l-0.4,0.6l-0.3,0.5v0.1v0.1l0.4,0.5l-0.1,0.1l-0.1,0.1v0.1v0.2l-0.1,0.2v0.2v0.2l-0.1,0.2l-0.1,0.2
                l-0.1,0.1l-0.1-0.1l-0.7-0.8l-0.8-0.9l-0.3-0.3l-0.3-0.2l-0.4-0.4l-1.1-0.9l-0.5-0.4l-0.5-0.5l-0.5-0.3l-0.5-0.2l-0.2-0.1l-0.2-0.1
                h-0.1v0.2l0.2,0.1l0.2,0.1h0.1l0.2,0.1l0.5,0.2l0.1,0.1l-1.3-0.5h-0.5l-0.1-0.1l0.3-0.3l-0.1-0.1h-0.1l-0.3,0.2H209v-0.1v-0.1
                l-0.2-0.2h-0.1l-0.1,0.1l-0.2,0.2v0.1l0.5,0.1l0.2,0.1v0.1h-0.4l-0.5,0.1l-0.9,0.6l-0.9,0.3l-1.2,0.6h-0.5l-0.3,0.1l-0.8-0.2
                l-1.1-0.5l-1.6-0.2l-1.1-0.7l-1.1-0.3l-0.7-0.7h-0.4l-0.4-0.3l-1-0.2l-1-0.2l-0.9-0.6l-0.6-0.2l-0.5-0.2l-1.2-0.4l-0.4-0.2l-0.4-0.3
                l-0.7-0.4l-0.3-0.3l-0.3-0.1l-0.5-0.6l-0.2-0.2l-0.2-0.1h-0.2h-0.6l-0.9-0.2l-0.4-0.1l-0.9-0.4l-1.2-0.4l-0.4-0.5l-0.3-0.4l-0.6-0.6
                l-0.4-0.2l-0.6-0.3l-0.4-0.2l-0.6-0.2l-0.9-0.5l-0.3-0.4l-0.2-0.4l-0.5-0.4l-0.5-0.8l-0.1-0.3l-0.1-0.5l-0.1-0.3l-0.1-0.2l0.1-0.1
                l0.3-0.2h0.5l0.3-0.2v-0.2v-0.1l-0.2-0.3l-0.3-0.1h-0.2l-0.1-0.1l0.2-0.1l0.2-0.2l0.3-0.3l0.2-0.3V402v-0.4l0.1-0.3l-0.6-0.4
                l-0.1-0.2l-0.2-0.4l-0.3-0.5v-1l-0.4-0.9l-0.4-0.5l-0.2-0.2l-0.6-0.7l-0.5-0.4l-0.5-0.8l-0.5-0.5l-0.6-0.8l-0.4-0.4l-1.9-1.4h0.1
                l0.6,0.3h0.1v-0.2l-0.1-0.2h-0.1h-0.1h-0.2l-0.1-0.1h-0.3l-0.4-0.2l-0.2-0.2v-0.3l-0.5-0.6l-0.2-0.3h0.1l0.1,0.1h0.1h0.2l0.1-0.1
                v-0.1l-0.1-0.1l-0.8-0.3l-0.3-0.2l-0.6-0.4l-0.2-0.1l-0.1-0.4h-0.2l-0.1,0.1l-0.4,0.1l-0.1-0.1v-0.1l0.3-0.1l0.2-0.3v-0.1l-0.1,0.1
                l-0.2,0.1l-0.2,0.1l-0.3,0.1h-0.2l-0.1-0.1l-0.2-0.3l-0.1-0.9l0.2-0.3l0.2-0.3l0.2-0.2l0.1,0.1h0.1l-0.1-0.2l-0.2-0.2l-0.1-0.2V386
                l-0.1-0.3l-0.6-0.6h-0.5h-0.2l-0.2-0.2l-0.2-0.4l-0.1-0.3V384l-0.1-0.2l-0.9-0.3l-0.3-0.2l-0.3-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.3
                l-0.1-0.3l0.1-0.4l0.1-0.2l-0.6-0.2h-0.3l-0.2,0.1l-0.2-0.1l-0.4-0.1l-0.5-0.5l-0.5-0.9l-0.6-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3v-0.1
                l-0.1-0.1l-0.3-0.2l-0.3-0.4l-0.2,0.2l-0.1-0.5l-0.2-0.2l-0.2-0.1v-0.2v-0.1l-0.1-0.2l-0.4-0.7l-0.2-0.5l-0.1-0.2l-0.1-0.2l-0.1-0.5
                l-0.2-0.6l-0.4-0.7l-0.3-0.5l-0.1-0.5l0.1-0.5l-0.1-0.3v-0.1v-0.1l0.1,0.1l0.1-0.1v-0.3l-0.1-0.1l-0.3-0.1l-0.1-0.1l-0.7-0.1
                l-0.4-0.2v-0.4l-0.2-0.2l-0.2-0.1l-0.5-0.2l-0.2,0.5l-0.1,0.2h-0.2h-0.2l-0.3-0.2l-0.8-0.6l-0.2-0.1h-0.2l-0.1-0.1l-0.5-0.3l0.1,0.2
                l0.2,0.2l0.1,0.5l-0.2,0.4l-0.1,1.2l0.1,0.2l0.2,0.4l0.2,0.6l0.1,0.5l0.1,0.4v0.9l0.1,0.3l0.2,0.4l0.4,0.4l0.1,0.2l0.5,0.3l0.3,0.4
                l0.7,0.5l0.2,0.2l0.6,0.8v0.3l0.1,0.3l0.3-0.1l0.1,0.2v0.1v0.1h0.2l0.1,0.1l0.3,0.9l0.2,0.1l0.2,0.1l0.2,0.1v0.2v0.2l0.2,0.3v0.4
                l0.2,0.3v0.3l0.1,0.2l0.5,0.5l0.6,0.4l0.1,0.6l0.3,0.5l0.3,0.1l0.3,0.2v0.2v0.1l0.4,0.4l0.1,0.5l0.3,0.3h0.1l0.1-0.1l-0.2-0.3
                l-0.1-0.2v-0.3h0.1l0.6,0.5l0.1,0.4l0.2,0.2v0.3l0.1,0.2v0.3l0.2,0.4v0.6l0.1,0.4l0.4,0.7l0.3,0.1l0.1,0.3l0.3,0.9l0.4,0.5l0.2,0.4
                v0.2l-0.1,0.4v0.2l0.2,0.8l0.3,0.4l0.4,0.1l0.1,0.1v0.1l0.1,0.1l0.1-0.1l0.1-0.2l-0.1-0.2v-0.1l0.1-0.1h0.1l0.7,0.5l0.1,0.2l0.2,0.2
                l0.2,0.3l0.1,0.2l0.2,0.2l0.1,0.4l0.5,0.2l0.2,0.4v0.2l-0.1,0.6l-0.1,0.2l-0.4,0.2l-0.3,0.3L167,397l-0.3,0.1h-0.2l-0.2-0.3l-0.3-1
                l-0.2-0.2l-0.1-0.3l-0.2-0.3l-0.8-0.4l-0.4-0.4l-0.4-0.3l-0.4-0.4l-1.1-0.7l-0.4-0.3l-0.3-0.3h-0.2h-0.1l-0.1-0.1v-0.2l-0.1-0.1
                l-0.6-0.5l-0.1-0.3v-0.3l0.1-0.9l0.1-0.5V389h-0.1l-0.1-0.1v-0.4l-0.2-0.5l-0.6-0.9l-0.4-0.2l-0.4-0.1l-1-0.8l-0.3-0.4l-0.1-0.2
                v-0.5l-0.1,0.3l-0.2,0.2h-0.4l-0.5,0.2l-0.3-0.2l-0.2-0.2l-0.2-0.3l-0.3-0.1h-0.2l-0.3-0.4l-0.3-0.1h-0.4l-0.3-0.2l-0.1-0.2
                l-0.1-0.3l-0.1-0.2l-0.5-0.3l-0.4-0.4l-0.4-0.2l-0.1-0.2v-0.1h0.6l0.7,0.1h0.3l0.2-0.1l0.2-0.1v0.1v0.2l0.2,0.2l0.3,0.1h0.2
                l-0.2-0.2l-0.1-0.3l0.1-0.1v-0.2h-0.3v-0.1l0.2-0.2l0.3-0.7l0.1-0.6l-0.3-0.6l-0.5-0.4l-1-1.2l-0.6-0.6l-0.2-0.2l-0.2-0.1l-0.5-0.1
                l-0.4-0.3l-0.7-0.5l-0.3-0.2l-0.2-0.6l-0.2-0.1l0.1-0.4l-0.1-0.7l-0.1-0.2l-0.4-0.2l-0.1-0.5v-0.5l-0.1-0.3l-0.7-0.5v-0.3v-0.2
                l-0.1-0.2l-0.4-0.5l-0.4-0.5l-0.1-0.2v-0.4l-0.1-0.1h0.1h0.1l0.1-0.1v-0.3l-0.6-0.5l-0.2-0.7l-0.3-0.4l-0.1-0.1l-0.2-0.6l0.8-0.1
                l0.8-0.1l0.8-0.1l0.8-0.1l0.8-0.1l0.8-0.1l0.8-0.1l0.8-0.1h0.3l-0.2,0.5l-0.1,0.2l1.3,0.5l1.3,0.5l1.3,0.5l1.3,0.5l1.3,0.5l1.3,0.5
                l1.3,0.5l1.3,0.5h1h1h1h1h1h1h1h1v-0.4v-0.4v-0.4v-0.4h0.6h0.6h0.6h0.6h0.6h0.6h0.6h0.6l0,0l0,0l0.2,0.3l0.3,0.4l0.3,0.3l0.3,0.2
                l0.6,0.5l0.8,0.8l0.7,0.6l0.5,0.3l0.3,0.2l0.2,0.2l0.2,0.4l0.4,1v0.5l0.2,0.4l0.3,0.6l0.3,0.3l0.2,0.1l0.3,0.2l0.3,0.3l0.3,0.2
                l0.4,0.1l0.5,0.3l0.7,0.4l0.5,0.2h0.3l0.2-0.1l0.2-0.3l0.2-0.2l0.2-0.1l0.1-0.1v-0.2l0.1-0.4l0.3-0.6l0.3-0.3l0.4-0.1l0.3-0.1
                l0.1-0.2h0.2l0.3,0.1l0.5,0.1h0.7h0.4l0.1-0.1h0.1v0.1h0.1h0.2l0.2,0.1l0.2,0.3l0.7,0.6l0,0l0.1,0.2l0.3,0.3l0.5,0.4l0.3,0.4
                l0.1,0.3l0.2,0.5l0.4,0.7l0.2,0.4v0.1v0.2l0.1,0.3l0.2,0.3l0.3,0.2l0.3,0.4l0.3,0.6l0.4,0.4l0.4,0.3l0.3,0.3l0.1,0.3v0.2l-0.1,0.2
                v0.2l0.1,0.2v0.2v0.3v0.1l0,0v0.1l0.4,0.5l0.2,0.4l0.2,0.6l0,0l0.2,0.4l0.3,0.1l0.4,0.1l0.3,0.1l0.2,0.2l0.3,0.1h0.3l0.3,0.1
                l0.3,0.2l0.5,0.1l0.8,0.1l0.6,0.2l0.4,0.3l0.2,0.1l0,0l0,0v-0.1l0.2-0.1L202.5,387.8L202.5,387.8z M153.2,369.5L153.2,369.5
                l-0.3-0.1v-0.1l0,0l0.2,0.1L153.2,369.5L153.2,369.5z M157.5,378.1L157.5,378.1l-0.3-0.2l-0.6-0.7l-0.2-0.3v-0.2v-0.4h0.2l0.3,0.2
                l0.1,0.2v0.3l0.5,0.1l0.1,0.5L157.5,378.1L157.5,378.1z M160.2,378.2L160,379h-0.2l-0.4-0.2v-0.1l0.2-0.9l0.1-0.1l0.4-0.1l0.1,0.1
                v0.3L160.2,378.2L160.2,378.2z M143.2,378.4l-0.1,0.1l-0.3-0.7v-0.2l0.1-0.1h0.2v0.2l0.1,0.1l0.1,0.1L143.2,378.4L143.2,378.4z
                M151.9,381.2v0.1l-0.5-0.2l0.3-0.4v-0.4l0.1-0.1l0.1,0.1l0.1,0.5L151.9,381.2L151.9,381.2z M163.3,387.6v0.1l0,0V387.6l-0.3,0.5
                h-0.1l0.1-0.6l0.1-0.1h0.1L163.3,387.6L163.3,387.6z M160.6,392.2L160.6,392.2l-0.3-0.3v-0.2l-0.1-0.1l-0.2-0.1l0.2-0.5l0.2-1
                l0.1,0.2l-0.2,1v0.1l0.1,0.1l0.1,0.2v0.2l0.2,0.2L160.6,392.2L160.6,392.2z M164.8,390.8l0.1,0.3l-0.1-0.1l-0.2-0.2l-0.1-0.2v-0.1
                l0,0l0.3,0.1V390.8L164.8,390.8z M161.6,392.6v0.1l-0.8-0.6l0.2-0.1h0.2L161.6,392.6z M166.9,393.4L166.9,393.4l-0.2-0.2l-0.1-0.4
                l0,0L166.9,393.4L166.9,393.4L166.9,393.4L166.9,393.4z M176.2,401.1l-0.1,0.2l-0.2-0.1l-0.1-0.2v-0.3h0.1l0.2,0.1v0.1L176.2,401.1z
                M231.7,402.2l0.1,0.1l0,0L231.7,402.2l-0.1-0.1l0,0L231.7,402.2L231.7,402.2z M231.1,405l-0.1,0.1l-0.1-0.3l0.1-0.3l0.1-0.2h0.3
                l0.2-0.1v0.1l-0.2,0.2L231.1,405L231.1,405z M163.8,409.6l-0.2,0.1l-0.2-0.2l0.1-0.1l0.1-0.1l0.1,0.2L163.8,409.6L163.8,409.6z
                M217.8,409.8l-0.3,0.1l-0.1-0.1l0.6-0.3h0.1l0,0l-0.3,0.1V409.8L217.8,409.8z"/>
            <linearGradient id="MY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="214.4578" x2="0.1864" y2="214.4578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MY" class="stcustom0 st159" d="M803.9,442.8l-0.2,0.1l-0.1-0.2v-0.2l0.2-0.2h0.3v0.2v0.2l-0.1,0.1H803.9L803.9,442.8z M805.1,451.2
                L805.1,451.2l-0.3-0.1l-0.5-0.3l-0.5-0.1h-0.7h-0.4h-0.2h-0.1l-0.1-0.1h-0.1l-0.3,0.2l-0.1-0.1l-0.1-0.1h-0.2h-0.3l-0.3,0.2
                l-0.4-0.2h-0.1h-0.1l-0.2,0.2l-0.3,0.2l-0.1,0.3l-0.1,0.3l-0.1,0.1v0.6v0.3l0.1,0.4v0.2l-0.1,0.2v0.1l-0.3,0.4v0.1v0.1l-0.1,0.3
                l-0.2,0.1h-0.2l-0.2-0.1l-0.2,0.1l-0.2,0.2l-0.1,0.1v0.1v0.1v0.1v0.1v0.1l0.1,0.1l0.1,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.3,0.3l-0.4,0.2
                l-0.1,0.1l-0.1,0.1v0.1l0.1,0.3l0.1,0.1v0.1v0.1v0.1l-0.1,0.1l-0.1,0.1v0.1l-0.1,0.4l-0.1,0.2l-0.2,0.3l-0.1,0.1h-0.1l-0.4-0.1
                l-0.3,0.1l-0.4,0.1h-0.4l-0.3,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.1l-0.2,0.1l-0.3-0.2h-0.2l-0.3-0.1l-0.7-0.2l-0.2-0.1h-0.2l-0.1-0.1
                v-0.1v-0.1l-0.1-0.1h-1.3l-0.4,0.1l-0.3,0.1l-0.2,0.1l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.4,0.1l-0.3,0.3h-0.1h-0.2H788l-0.2,0.1h-0.2
                L787,460h-0.5l-0.3,0.1H786l-0.9,0.4h-0.3l-0.1-0.1l-0.2-0.2l-0.2-0.2l-0.6-0.5l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.2-0.2l-0.2-0.1
                l-0.2-0.2l-0.2-0.3l-0.1-0.5l-0.2-0.1l-0.1-0.1v-0.1l0.3-0.4l0.2,0.4l0.1,0.1l0.4,0.3l0.3,0.1l0.4,0.1h0.4h0.1l0.1-0.1l0.1,0.1
                l0.8,0.4l0.3,0.1h0.3h0.1l0.4,0.3h0.1h0.2l-0.3-0.1l-0.2-0.1l-0.1-0.2l0.1-0.2l0.2-0.1l0.1-0.2v-0.5l0.1-0.2l0.1-0.2v-0.2l-0.2-0.2
                v-0.3v-0.2l0.1-0.2l0.2,0.1l0.2,0.1h0.2h0.1v-0.1v-0.2v-0.4l0.2-0.3l0.3-0.2l0.3-0.1l1.1-0.2l1.7-0.4l0.5-0.2l0.2-0.1l0.2-0.1
                l0.3-0.4l0.5-0.6l0.4-0.5l0.7-0.7l0.6-0.7l0.1-0.1l0.1-0.4v-0.2v-0.2l0.1-0.1h0.1l0,0l0.1,0.1l0.2,0.1l0.2,0.1l0.1,0.2l0.1,0.2v0.1
                l0.1,0.1h0.3l0.1,0.1l0.2,0.3l0.2,0.2l0.1,0.1h0.1l0.2-0.2l0.1-0.2l0.1-0.3h-0.1l0.1-0.2v-0.1l-0.1-0.1l-0.1-0.1v-0.2l-0.1-0.3v-0.1
                l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.1v0.2v0.4l0.1,0.3l0.2,0.5l0.2,0.1h0.2h0.1h0.1l0,0l-0.1-0.2v-0.5l-0.1-0.3l-0.2-0.3l-0.1-0.1
                l0.7-0.1l0.1-0.1l0.3-0.2l0.1-0.1l0.1-0.3l-0.3-0.2l-0.1-0.2V448l0.4-0.4l0.1-0.1l0.1,0.2h0.2h0.2h0.2l0.2-0.2l0.1-0.3l0.4-0.4
                l0.1-0.3l0.1-0.4l1-1.1l0.1-0.2l0.6-1.1h0.1l0.2,0.1v0.4v0.2l-0.1,0.2l-0.1,0.2h0.1l0.3-0.1l0.3-0.4l0.2-0.3l0.1-0.1l0.3,0.1
                l0.1,0.1v0.2v0.1l0.1,0.3l0.2,0.2l0.3,0.1l0.3,0.2l0.1,0.1l0.1,0.1l0.1,0.2v0.2l-0.2,0.2l0.1,0.3v0.2l-0.1,0.2l-0.3,0.2l0.9-0.2
                l0.2-0.1l0.3-0.2l0.2,0.2l0.2,0.3l-0.1,0.1l-0.4,0.1v0.1l0.1,0.2h0.2l0.3-0.1l0.3-0.2h0.1h0.2l0.3,0.1l0.2,0.1l0.1,0.1l0.1,0.3
                l0.3,0.1l0.7,0.4h0.1h0.1h0.4l0.1,0.1l0.1,0.1v0.2v0.2v0.1l-0.1,0.1l-0.2,0.2l-0.6,0.2l-0.7,0.2h-0.3l-0.5-0.1H807l-0.2,0.1
                l-0.2,0.4l0.4,0.4l0.7,0.5l0.1,0.1v0.1l-0.1,0.1h-0.1l-0.4,0.1l-0.4,0.1l-0.3,0.1l-0.3,0.1h-0.3l-0.4-0.2h-0.1l-0.1,0.1L805.1,451.2
                L805.1,451.2L805.1,451.2z M761.6,445.4l0.5,0.1l0.2,0.1l0.5,0.9l0.7,0.6l0.3,0.2l0.2,0.1l0.3,0.3l0.3,0.4l0.6,1.2l0.1,0.5v0.8
                l-0.1,1.2l-0.2,0.6v0.3l0.2,0.4l-0.1,0.4v0.3v0.9l0.1,0.3l0.1,0.2l0.8,0.5l0.1,0.2l0.4,0.7l0.7,1.5l0.2,0.7v0.2l-0.1,0.1l-0.2,0.1
                l-0.2-0.1l-0.1-0.1v-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1v0.2v0.3h-0.2L766,459l-0.3,0.1l-0.4,0.3h-0.2l-0.1-0.3l-0.1-0.2l-0.1-0.1
                l-1.3-0.7l-0.5-0.2l-0.5-0.5l-1.1-0.6l-0.7-0.6l-0.3-0.3l-0.7-0.3l-0.3-0.4l-0.2-0.1L759,455l0.2-0.4l-0.1-0.4l-0.1-0.3l-0.5-0.6
                l-0.3-0.4l-0.5-0.4l-0.2-0.2l-0.2-0.3l0.1-0.1l0.1-0.1l-0.1-0.2l-0.3-0.4l-0.1-0.4V450l-0.4-1.1l-0.3-1.5l0.1-0.5l-0.1-0.6l-0.2-0.6
                l-0.3-0.4l-0.1-0.3l0.1-0.1l0.1-0.4v-0.1h0.1h0.1l0.2,0.4l0.6,0.2l0.2,0.1l0.2-0.1l0.1,0.1l0.1,0.1l0.1,0.3l0.2,0.2h0.3h0.1h0.1
                l0.1,0.2v0.4v0.2l-0.2,0.3v0.2l0.1,0.1l0.2,0.1l0.1,0.1h0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.4-0.2l0.4-0.2h0.1l0.1,0.1l0.1,0.2h0.1h0.1
                h0.2l0.2-0.1l0.1-0.2l0.1-0.2l0.3-0.3v-0.2L761.6,445.4L761.6,445.4z M755.3,444.8l0.2,0.3l-0.1,0.1l0,0h-0.1l-0.1,0.1h-0.1
                l-0.1-0.2l-0.1-0.1v-0.1h0.2L755.3,444.8L755.3,444.8L755.3,444.8L755.3,444.8z M756.6,448L756.6,448h-0.3v-0.5l0.1-0.1l0.2,0.1
                l0.1,0.1L756.6,448L756.6,448z M805.9,451.1l-0.4,0.1h-0.3v-0.1V451h0.1h0.1L805.9,451.1z M759.4,454.5l-0.1,0.1L759.4,454.5
                l-0.1-0.1l0.1-0.1v0.1V454.5z M767.6,455.2L767.6,455.2h-0.2v-0.1l0.1-0.2l0,0L767.6,455.2L767.6,455.2L767.6,455.2z M787.7,456.1
                L787.7,456.1l-0.2-0.1v-0.8l0.1-0.1h0.1l0.1,0.2v0.4v0.3L787.7,456.1L787.7,456.1z"/>
            <linearGradient id="MZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="149.3579" x2="0.1864" y2="149.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="MZ" class="stcustom0 st160" d="M567.4,540.6h-0.3h-0.5h-0.3H566l-0.4-0.1h-0.2v-1l-0.1-0.2l-0.1-0.3v-0.2l0.1-0.2v-0.3V538l-0.3-0.1
                h-0.1l-0.1-0.2v-0.3l0.2-0.4v-0.8v-0.3v-0.6v-0.7V534v-0.5l-0.1-0.3v-0.1l-0.1-0.3l-0.1-0.6l-0.2-0.5l-0.2-0.3l-0.1-0.2l-0.1-0.2
                l-0.2-0.4l-0.2-0.2v-0.2v-0.4l-0.2-0.8l-0.1-0.6l-0.2-0.6l-0.1-0.4v-0.1v-0.2l0.4-0.3l0.4-0.4l0.5-0.5l0.4-0.5l0.4-0.4l0.5-0.5
                l0.5-0.5l0.1-0.1l0,0l-0.2-0.5l0.3-0.6v-0.4v-0.3v-0.2l0.1-0.1l0.4-0.3l0.3-0.5l0.2-0.4l0.3-0.7v-0.2v-0.2l-0.1-0.2l-0.2-0.4
                l-0.2-0.3l-0.1-0.5l0.2-0.4l0.1-0.3v-0.1l-0.1-0.1l-0.2-0.1L567,517v-0.2v-0.2l0.1-0.1l0.4-0.2l0.1-0.1v-0.1v-0.2l0.1-0.4l0.1-0.4
                V515v-0.1v-0.2v-0.3v-0.9l0.1-1V512l-0.2-0.6v-0.5l0.2-0.3v-0.2h-0.1h-0.3l-0.2-0.1L567,510l-0.5-0.2l-0.6-0.2l-0.9-0.1l-0.7-0.6
                l-0.6-0.1l-0.2-0.1l-0.5-0.4h-0.8h-0.9h-0.5h-0.1v-0.5v-0.5v-0.4l-0.1-0.5l-0.1-0.2l-0.2-0.3l-0.1-0.3v-0.2v-0.1l0.6-0.2l0.3-0.1
                l0.4-0.1l0.7-0.2l0.6-0.2l0.6-0.2l0.6-0.2l0.2-0.1l0.3-0.1l0.7-0.2l0.2-0.1l0.4-0.1l0.2-0.1l0.8-0.3l0.9-0.3l0.3-0.1l0.6-0.2
                l0.1,0.1l0.4,0.7l0.3,0.4l0.4,0.4h0.1l0.1-0.1h0.2l0.6-0.1h0.2l0.1-0.1l0.3-0.1h0.3h0.1l0.4,0.5l0.1,0.4l0.1,0.6v0.3v0.4v0.5
                l-0.3,0.5l-0.1,0.3l-0.2,0.4l-0.2,0.2l-0.1,0.2v0.2l0.1,0.1l0.2,0.3l0.1,0.2v0.1v0.2l0.1,0.1l0.1,0.1l0.2,0.1l0.2,0.3l0.4,0.4
                l0.5,0.6l0.2,0.2h0.2l0.1,0.2v0.2l-0.1,0.1l0.1,0.2l0.1,0.1h0.1h0.2h0.2v-0.1v-0.8l-0.1-0.5l-0.1-0.2l0,0l0.1-0.2l0.2-0.4l0.1-0.4
                l0.1-0.2l0.1-0.1l0.7-0.1l0.3-0.1l0.1-0.1l0.1-0.3l0.1-0.8v-0.8l-0.1-0.4l0.1-0.7l0.1-0.4l-0.1-0.1l-0.1-0.6l-0.4-0.6l-0.6-0.8
                l-0.3-0.4l-0.4-0.5l-0.7-0.7l-0.3-0.3l-0.2-0.1l-0.5-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.4v-0.3l-0.1-0.5l-0.1-0.7v-0.2l-0.2-0.6l-0.1-0.5
                v-0.1l0.1-0.1l0.2-0.4l0.2-0.3l0.1-0.2l0.1-0.4v-0.2l0.1-0.1h0.5h0.4h0.6h0.7h0.1h0.1h0.2l0.2-0.1l0.2-0.1l0.2-0.2h0.4l0.5,0.2
                l0.3,0.2v0.2l0.3,0.1h0.6l0.4-0.1l0.3-0.2l0.3-0.1h0.3l0.2,0.1l0.2,0.2l0.3,0.1l0.4,0.1l0.5-0.1l0.5-0.3l0.3-0.3l0.1-0.3l0.1-0.2
                l0.1-0.1h0.3h0.4l0.4,0.1l0.5,0.3l0.3-0.2l0.5-0.3l0.5-0.2h0.5l0.4-0.1l0.3-0.2l0.3-0.2l0.4-0.1l0.3-0.1l0.5-0.3l0.5-0.4l0.5-0.4
                l0.3-0.2l0.1,0.3l0.3,0.3l-0.2,0.2l-0.2,0.1l0.3,0.2l-0.2,0.3v0.2l0.1,0.1v0.1l-0.1,0.3l-0.2,0.2l-0.1,0.2l0.2,0.3l-0.1,0.6l0.2,0.5
                v0.3l0.1,0.2l-0.1,0.3v0.6v0.2l-0.1,0.3l0.2,0.1l0.1,0.3v0.4l-0.1,0.2l-0.3,0.2v0.1v0.1h0.4v0.2v0.2v0.3v0.2l0.1,0.2l-0.1,0.3v0.2
                v0.3l0.1,0.6v0.8v0.1l0.1,0.1h0.2v0.2l-0.2,0.3v0.2v0.2l0.2-0.3h0.1l0.1,0.1v0.2v0.1v0.2l0.1,0.2v0.2l-0.2,0.1l-0.2,0.3v0.2v0.2
                h-0.1l-0.1,0.1l0.1,0.2v0.2l-0.3,0.6l-0.7,0.8l-0.3,0.3l-0.3,0.3v0.1v0.1l-0.3,0.5l-0.3,0.1l-0.2,0.1l0.2,0.4l-0.2,0.1l-0.4,0.3
                l-1.1,0.6l-0.2,0.1l-0.3,0.4l-0.4,0.1l-0.2,0.1H584h-0.1h-0.1l-0.1,0.1l-0.7,0.3l-0.7,0.2l-0.2,0.1l-0.1,0.1l-0.6,0.2l-0.9,0.5
                l-0.8,0.5l-0.5,0.5l-0.1,0.1l-0.2,0.2l-0.1,0.3l-0.1,0.1l-0.4,0.5l-0.6,0.6l-0.1,0.2l-0.2,0.3v0.2l-0.2,0.1l-0.2-0.2l-0.1,0.4h-0.1
                l-0.2-0.1l-0.4,0.2l-0.4,0.2l-0.6,0.5l-0.8,1l-1.2,0.9H573h-0.1l-0.4-0.3h-0.2l0.2,0.2l0.1,0.2v0.3v0.5l-0.1,0.9v0.2l0.2,0.3
                l0.3,0.3l0.3,0.4l0.4,1.2v0.6l0.4,0.8v0.3l0.2,0.8v0.7v0.4l0.2,0.2l0.1-0.2v-0.3v-0.4l0.1-0.2h0.1v0.2l0.1,0.2v0.2v0.2l-0.1,0.8v0.3
                l0.2,0.6l-0.2,0.7l-0.3,1.6v0.3l0.1,0.1h0.2l0.1-0.2h0.1l0.1,0.1l-0.1,0.7l-0.1,0.3l-0.5,0.8l-0.3,0.3l-0.5,0.3l-1.1,0.5l-2.2,0.8
                l-0.9,0.4l-0.5,0.2l-1.1,0.7l-0.5,0.5l-0.2,0.5l-0.2,0.2l-0.2,0.3l0.2,0.3l0.2,0.2l0.2,0.1l0.1,0.1l0.1,0.1l0.1-0.4l0.1-0.1h0.1
                l-0.1,0.5L567.4,540.6L567.4,540.6L567.4,540.6z M572.5,496.8L572.5,496.8l0.1-0.1v-0.1l0,0h-0.1h-0.1v0.1v0.2L572.5,496.8
                L572.5,496.8L572.5,496.8z M572.3,496.6L572.3,496.6l-0.1-0.1h-0.1v0.1l0.1,0.1h0.1V496.6L572.3,496.6z"/>
            <linearGradient id="NA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="136.7576" x2="0.1864" y2="136.7576" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NA" class="stcustom0 st161" d="M540.7,512.8l0.6-0.1l0.6-0.1l0.7-0.1l0.5-0.1h0.1l1.3,0.1l0.6,0.1l0.2,0.1l0.3,0.2l0.5,0.5H546
                l-0.9,0.1l-0.3,0.1l-0.7,0.6l-0.2-0.1l-0.2-0.1h-0.1l-0.3,0.1l-0.3,0.2l-0.4,0.2l-0.3,0.2l-0.1,0.1l-0.5,0.5l-0.2,0.1h-0.1h-0.1
                l-0.1-0.2l-0.3-0.5l-0.5-0.6l-0.1-0.1h-0.1h-0.3l-1,0.2l-0.8,0.1l-1.3,0.2l-1.4,0.2l-0.8,0.1h-0.7v0.6v1.2v1.2v1.2v1.2v1.2v1.2v1.2
                v1.2v0.5v0.1h-0.4h-0.9h-0.8h-0.6v0.7v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.9v0.7v0.1v1.3v1.4v1.4v1.4v1.4v1.4v1.4v1.4v0.4h-0.3l-0.6,0.2
                l-0.4,0.2l-0.2,0.3l-0.2,0.2l-0.3,0.1l-0.1,0.1v0.2l-0.1,0.2l-0.2,0.1h-0.4l-0.5-0.2h-0.7l-0.8,0.1h-0.6l-0.4-0.2L525,547h-0.4
                l-0.2-0.1l-0.5-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.2V546l0.1-0.1v-0.2l-0.1-0.3l-0.1-0.1h-0.2l-0.1-0.1V545l-0.1-0.2l-0.3-0.2l-0.3,0.1
                l-0.2,0.2l-0.1,0.3l-0.1,0.1v0.2v0.2l-0.1,0.2l-0.1,0.1h-0.1l-0.2,0.1l-0.4,0.3l-0.1,0.1l-0.3-0.3l-0.9-1l-0.3-0.3l-0.5-0.6
                l-1.1-1.8l-0.2-0.4l-0.2-0.9l-0.2-0.6v-0.4l0.1-0.2l-0.1-0.3l-0.1-0.3l-0.4-0.3l-0.1-1.1l-0.2-0.7l0.1-0.6l-0.1-0.5v-0.3l0.1-0.7
                l-0.2-0.8l-0.4-0.7l-0.4-1.1l-0.1-0.5v-1.2l-0.1-0.5v-0.6l-0.1-0.6l-0.1-0.3l0.1-0.3l0.1,0.1h0.1l0.1-0.4V528l-0.2-0.8l-0.4-0.8
                l-1-1.3l-0.2-0.5l-0.1-0.4l-1.1-1.7l-0.5-1.2l-0.3-1l-0.4-0.5l-1.6-3.3l-0.4-0.5l-0.7-0.6l-0.2-0.2l-0.3-0.6l-0.5-0.8l-0.1-0.7v-0.8
                l0.1-0.6l0.4-0.1l0.3-0.2h0.3l0.3,0.1h0.3h0.1h0.5l0.3-0.2l0.4-0.2l0.2-0.1l0.3-0.1l0.4-0.1h0.2l0.3,0.1l0.4,0.1l0.2,0.1l0.2,0.3
                l0.4,0.3l0.3,0.2l0.3,0.2l0.1,0.1h0.1h0.1h0.6h0.5h0.6h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h0.4h0.8h0.8h0.1l0.1,0.1l0.1,0.1l0.3,0.4
                l0.4,0.4l0.3,0.2l0.4,0.1h0.3h0.3h0.5l0.7,0.1h0.8l0.8-0.1l0.6,0.1l0.3,0.2l0.3,0.1l0.3,0.1h0.5l0.6-0.1h0.5l0.2,0.1h0.1l0.8-0.2
                l0.7-0.1l1-0.2l0.8-0.2l1.2-0.2L540.7,512.8z"/>
            <linearGradient id="NC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="143.4579" x2="0.1864" y2="143.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NC" class="stcustom0 st162" d="M924,517.7L924,517.7l-0.1-0.4l0.1-0.2v0.4V517.7L924,517.7z M935.9,520.5l0.3,0.2l0.3-0.1l0.4,0.3
                l1.1,0.9l0.4,0.2l0.2,0.1l0.2,0.1l0.2,0.2l0.2,0.1l0.1,0.1v0.2l0.1,0.1l0.4,0.3l0.2,0.3l0.3,0.1l0.1,0.2l0.2,0.1l0.2,0.2l0.3,0.1
                l0.7,0.5l0.5,0.4l0.3,0.3l0.3,0.2l0.4,0.2l0.3,0.2l0.2,0.5l-0.1,0.2l-0.2,0.1h-0.2l-0.2,0.1l-0.6-0.3h-0.1h-0.2l-0.1-0.1l-0.1-0.1
                l-0.3-0.1l-0.3-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.5-0.1l-0.3-0.2l-0.2-0.2l-0.3-0.2l-0.5-0.3l-0.3-0.1l-0.2-0.2l-0.7-0.6l-0.2-0.1
                l-0.2-0.3l-0.6-0.6l-0.3-0.3l-0.3-0.2l-0.2-0.3l-0.2-0.3l-0.4-0.5V521v-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.1v-0.2l0.1-0.1L935.9,520.5
                L935.9,520.5z M942.5,521.8L942.5,521.8l0.1-0.3v-0.2l0.1-0.3v-0.1h0.1l0.1,0.1l-0.1,0.1v0.1v0.2h0.1l-0.1,0.2L942.5,521.8
                L942.5,521.8z M944.9,523.2h-0.2l-0.2-0.2l-0.3-0.1l-0.2-0.2l-0.1-0.2l0.2-0.1l0.2-0.3l-0.1-0.1h-0.3v-0.1l0.4-0.1l0.2,0.1l0.1,0.1
                v0.5l0.2,0.2l0.2,0.3v0.1L944.9,523.2L944.9,523.2z M946.6,524L946.6,524l0.3,0.1l-0.1,0.5l-0.3,0.1h-0.1l-0.1-0.1l-0.2-0.1v-0.2
                l-0.2-0.4l0.3-0.1l0.2-0.1v0.1L946.6,524L946.6,524L946.6,524z M945.3,527.6L945.3,527.6l-0.2,0.1H945l-0.1-0.1l0.1-0.2l0.2,0.1
                L945.3,527.6L945.3,527.6z"/>
            <linearGradient id="NE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="253.0078" x2="0.1864" y2="253.0078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NE" class="stcustom0 st163" d="M517.1,396.9l0.3,1.7l0.2,1.5v1v0.2l0.1,0.2l0.2,0.2l0.9,1.4l-0.2,0.2l0.1,0.4l0.2,0.2l0.7,0.8
                l0.1,0.2v0.1l-0.5,1l-0.1,0.2l-0.1,1.2l-0.1,0.9l-0.1,1.2l-0.1,1.4l-0.1,1.2l-0.1,1.5l-0.1,1.5l-0.7,0.8l-1.3,1.4l-1.1,1.1l-0.5,0.8
                l-1,1.5l-0.5,1l-0.4,0.5l-0.2,0.2l0.2,0.7l0.3,1.2h-0.5l-0.3,0.1l-0.4,0.3l-0.4,0.1l-0.5,0.2l-0.3,0.2l-0.3,0.2l-0.4,0.4l-0.1,0.3
                l-0.4,0.1H509l-0.4-0.3l-0.8-0.3l-0.5-0.1h-0.2l-1.3-0.1l-1.4,0.1l-0.7,0.1h-0.1l-0.4,0.2l-0.3,0.2l-0.9,0.9h-1.2l-0.7-0.1l-0.6-0.1
                l-0.8-0.4l-1-0.7l-0.4-0.1l-0.4-0.1h-0.1l-1.2,0.7h-0.2l-0.3,0.1l-0.2,0.2l-0.1,0.1h-0.1h-0.2l-0.2-0.1l-0.2-0.2l-0.5-0.7l-0.1-0.1
                l-0.2-0.2l-0.4-0.3l-0.2-0.2h-0.1h-0.2l-1-0.3l-1-0.3h-0.2l-0.2,0.1l-0.3,0.2h-0.4h-0.5h-0.3l-0.4,0.1l-0.3,0.1l-0.4,0.2l-0.5,0.4
                l-0.1,0.1l-0.1,0.1l-0.2,1.2l-0.1,0.3h-0.2l-0.5,0.4l-0.3,0.3v0.4v0.6v0.4v0.3l-0.1,0.1v0.1v0.2l0.1,0.1v0.1v0.1l-0.2,0.1l-0.2-0.3
                l-0.2-0.2l-0.3-0.1l-0.2-0.1l-0.1-0.2l-0.3-0.4l-0.8-0.7H483h-0.1l-0.2,0.1l-0.1,0.1h-0.1h-0.1l-0.4,0.1l-0.3,0.1v0.1l0.1,0.5
                l-0.1,0.3l-0.1-0.1l-0.4-0.5l-0.3-0.4l-0.1-0.1v-0.1v-0.1h0.1l0.3-0.1l0,0v-0.1v-0.2l-0.1-0.3l-0.2-0.2h-0.1h-0.2h-0.2l-0.3,0.2H480
                h-0.3h-0.3l-0.2-0.1l-0.5-0.5l-0.6-0.5l-0.2-0.1h-0.1v-0.4v-0.4v-0.1l0.2,0.1h0.3l0.1-0.1l-0.2-0.2l-0.3-0.2l-0.1-0.2l-0.1-0.1
                l-0.1-0.1h-0.2l-0.2-0.1l-0.1-0.1h-0.2l-0.2-0.1l-0.3-0.4l-0.3-0.4l-0.2-0.3l-0.1-0.2l0.1-0.3l-0.1-0.1l-0.3-0.3l-0.2-0.3l0.1-0.4
                v-0.4v-0.2v-0.1v-0.2h0.2h0.4l0.8,0.1l0.6-0.1l0,0l0.5-0.4l0.5-0.4h0.8h0.8h0.6h0.9h0.8h0.9v-0.2h0.1h0.1l0.6,0.1l0.6,0.1v-0.4
                l0.5-0.5l0.3-0.1l0.1-0.1l0.1-0.2l0.1-0.2v-0.2l0.1-0.1l0.1-0.3l0.1-0.5l0.3-0.5l0.2-0.7v-0.6v-0.5l0.1-0.1v-0.9V413v-0.7v-0.9v-0.8
                v-0.9v-0.8v-0.5l0.6-0.1l0.6-0.1l0.9-0.2l1-0.2l1.1-0.2l0.2-0.1l0.8-0.7l0.4-0.3l0.7-0.7l0.6-0.5l0.7-0.7l0.8-0.7l0.6-0.5l1-0.6
                l1.5-0.9l1.5-0.9l1.5-0.9l1.5-0.9l1.5-0.9l1.5-0.9l1.5-0.9l1.5-0.9l1.5,0.4l1.4,0.3l1.4,0.3l0.3,0.2l0.7,0.7l0.9,0.8l0,0l0,0
                l0.9-0.5L517.1,396.9L517.1,396.9z"/>
            <linearGradient id="NF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="118.2079" x2="203.3996" y2="118.2079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NF" class="stcustom0 st164" d="M946.4,547.5L946.4,547.5L946.4,547.5L946.4,547.5l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                l0,0l0,0l0,0l0,0L946.4,547.5L946.4,547.5L946.4,547.5L946.4,547.5L946.4,547.5L946.4,547.5L946.4,547.5z"/>
            <linearGradient id="NG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="228.9578" x2="0.1864" y2="228.9578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NG" class="stcustom0 st165" d="M513.3,424.2l0.4,0.6l0.5,0.7l0.4,0.5l0.3,1.3v0.3v0.1v0.1v0.2l0.2,0.1h0.4l0.3,0.1l0.2,0.2l0,0
                l0.1,0.2v0.1v0.3l-0.1,0.4l-0.1,0.3l0.1,0.4v0.2v0.1l-0.2,0.1l-0.2,0.1L515,431l-0.2,0.1h-0.2l-0.2,0.1l-0.2,0.2l-0.5,0.8l-0.5,0.8
                l-0.2,0.6l-0.2,0.6l-0.4,0.4l-0.1,0.2v0.1v0.3v0.5l-0.1,0.2l-0.1,0.1l-0.4,0.1l-0.3,0.2l-0.2,0.3l-0.1,0.4l-0.1,0.4v0.4l-0.1,0.2
                l-0.1,0.2l-0.2,0.2l-0.2,0.1l-0.5,0.1l-0.3,0.5l-0.2,0.4v0.2l-0.2,0.8l-0.4,0.6v0.2v0.2l-0.5,0.5l-0.1,0.2l-0.1,0.2l0.1,0.2l0.1,0.2
                v0.1l-0.2,0.2l-0.4,0.3l-0.2,0.2v0.1v0.4l-0.1,0.1l-0.1,0.2l-0.2,0.2l-0.2,0.1l-0.2,0.1h-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.5l-0.1-0.1
                l-0.2-0.1l-0.3-0.3l-0.3-0.3l-0.4-0.2h-0.1l-0.1,0.1l-0.1,0.3l-0.1,0.1h-0.2H504h-0.2l-0.1-0.1v-0.1l-0.1-0.1l-0.3,0.2l-0.5,0.3
                l-0.1,0.1l-0.1,0.1l-0.2,0.3l-0.2,0.3l-0.2,0.2l-0.2,0.1l-0.1,0.1l-0.2,0.1l-0.4,0.4l-0.5,0.5l-0.2,0.3l-0.2,0.4l-0.1,0.4l-0.1,0.5
                l-0.2,0.7l-0.2,0.4l-0.2,0.3l-0.2,0.3l-0.1,0.2l0,0l-0.1,0.1l-0.2-0.1l-0.1-0.2h-0.1l-0.2-0.3h-0.1l0.3,0.7l-0.1,0.3h-0.7l-0.6,0.1
                h-0.4l-0.2-0.1l-0.1-0.3l0,0v0.1l-0.1,0.1h-0.5l-0.2-0.2l-0.2-0.2l-0.2-0.1v0.1l0.2,0.2v0.3l-0.4,0.3h-0.3l-0.2-0.1l-0.1-0.2v-0.3
                l-0.1-0.2h-0.1l0.1,0.2v0.2v0.3l0.2,0.3l-0.3,0.1h-0.1H494v-0.1v-0.2l-0.1-0.1l-0.1,0.4h-0.2h-0.1l-0.5,0.1h-0.1v-0.1l0.1-0.1v-0.2
                l-0.2,0.1v0.3h-0.1h-0.3l-0.3-0.1l-0.2-0.1l-0.3-0.2l-0.6-0.5l-0.1-0.2l-0.2-0.3l-0.1-0.3l-0.2-0.5h0.1h0.1l0.1-0.1l-0.2-0.1
                l-0.1-0.1V448v-0.2l0.2-0.1h0.2l0.1-0.1v-0.1l-0.5,0.2l-0.4-0.2l-0.1-0.1v-0.1h0.2h0.3l0.2-0.1h-0.1h-0.2l-0.1-0.1v-0.2h-0.1
                L490,447l-0.3,0.1l-0.2-0.1v-0.2v-0.1l-0.1-0.1l-0.5-0.6l-0.6-0.5l-0.6-0.4l-0.9-0.2H485l-0.1-0.1l0.1-0.1l0.2-0.1l0.6-0.3h-0.1
                l-0.6,0.2h-0.2l-0.3,0.4L483,445h-0.2v-0.2l0.1-0.5l0.1-0.2l0.1-0.1l-0.1-0.2l-0.1-0.2v-0.4l0.1-0.1V443v-0.2v-0.7V442l0.1-0.1v-0.1
                l-0.1-0.2l-0.1-0.2v-0.3v-0.3l-0.1-0.1v-0.5v-0.6v-0.3l0.1-0.2v-0.5v-0.5l0.1-0.8h0.3l0.4-0.1l0.2-0.3l0.1-0.4v-0.4l0.1-0.1l0.2-0.2
                l0.3-0.3v-0.3l0.1-0.1l0.1-0.1h0.2l0.2-0.2l0.1-0.3l0.1-0.4l-0.2-0.3v-0.1l0.1-0.2l0.1-0.2l0.1-0.1h0.2l0,0v-0.1l0.1-0.5v-0.1
                l-0.2-0.3V432v-0.3v-0.3l-0.1-0.1l-0.1-0.1v-0.1l-0.4-0.6v-0.3l0.2-0.4l0.1-0.2l0.2-0.1v-0.1v-0.1l-0.1-0.1V429v-0.1l0.1-0.1v-0.3
                v-0.4v-0.6v-0.4l0.3-0.3l0.5-0.4l0.3-0.5l0.1-0.3l0.2-1.2l0.1-0.1l0.1-0.1l0.5-0.4l0.4-0.2l0.3-0.1l0.4-0.1h0.3h0.5h0.4l0.3-0.2
                l0.2-0.1h0.2l1,0.3l1,0.3h0.2h0.1l0.2,0.2l0.4,0.3l0.2,0.2l0.1,0.1l0.5,0.7l0.2,0.2l0.2,0.1h0.2h0.1l0.1-0.1l0.2-0.2l0.3-0.1h0.2
                l1.2-0.7h0.1l0.4,0.1l0.4,0.1l1,0.7l0.8,0.4l0.6,0.1l0.7,0.1h1.2l0.9-0.9l0.3-0.2l0.4-0.2h0.1l0.7-0.1l1.4-0.1l1.3,0.1h0.2l0.5,0.1
                l0.8,0.3l0.4,0.3h0.6l0.4-0.1l0.1-0.3l0.4-0.4l0.3-0.2l0.3-0.2l0.5-0.2l0.4-0.1l0.4-0.3l0.3-0.1L513.3,424.2L513.3,424.2z
                M495.6,450.5l-0.3,0.1h-0.2l0.2-0.4l0.1,0.1h0.2L495.6,450.5L495.6,450.5z"/>
            <linearGradient id="NI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="239.6079" x2="0.1864" y2="239.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NI" class="stcustom0 st166" d="M241.7,420.5l-0.1,0.1l-0.1,0.1l-0.2,0.3h-0.1v-0.3h-0.1l-0.1,0.1l-0.1,0.1l0.1,0.2h0.1h0.1l0.3,1.2
                l-0.1,0.2l-0.2,0.3l-0.2,0.3l-0.2,0.2l-0.2,0.7l-0.2,1.2l0.1,1.1l-0.1,1l0.1,0.2v0.3l-0.2,0.1h-0.1l-0.1-0.2v-0.2l0.1-0.2V427v-0.1
                l-0.1,0.3l-0.2,0.1h-0.1l-0.1,0.1l0.1,0.3l0.1,0.2v0.1l-0.1,0.2v0.6l0,0l-0.1-0.1h-0.1v0.2v0.1l-0.1,0.1v0.1l0.1,0.1h0.1h0.1
                l0.1,0.3v0.2l-0.3,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.2l0.1,0.5l0.2,0.3l0.2,0.2h0.2v0.2l-0.2,0.1l-0.3,0.1h-0.3l-0.5-0.1h-0.2
                l-0.1-0.1v-0.1l-0.1-0.2l-0.3-0.2h-0.1h-0.2l-0.4-0.2h-0.2l-0.3,0.1l-0.3,0.2l-0.8-0.3l-0.5-0.2l-0.5-0.2l-0.1-0.1h-0.1l-0.1,0.1
                l-0.1,0.2l0,0h-0.1h-0.1V431l-0.2-0.3l-0.4-0.4l-1.4-1.2l-0.5-0.7l-0.3-0.5l-0.3-0.3l-0.8-0.5l-0.2-0.2l-0.8-0.7l-0.6-0.4v-0.2
                l0.2-0.2h0.1l0.1,0.2l0.2,0.2h0.1l0.1-0.1v-0.1h0.8h0.1l0.1-0.1l0.1-0.2v-0.2V425l0.1-0.1h0.2h0.2l0.1-0.1l-0.1-0.3l-0.1-0.7v-0.2
                v-0.1h0.1h0.3l0.7,0.1h0.1l0.3-0.4l0.2-0.3l0.2-0.1h0.1l0.2,0.2l0.6,0.3h0.1h0.1l0,0v-0.2l0.1-0.1l0.3-0.1l0.3-0.2l0.3-0.3l0.3-0.2
                l0.2-0.1l0.1-0.1l-0.1-0.1v-0.2l0.1-0.2l0.1-0.1h0.2l0.1-0.1v-0.1v-0.1l0.1-0.2l0.4-0.2l0.2,0.1l0.2,0.2l0.2,0.2l0.3,0.1h0.2
                l0.2-0.1h0.2l0.1,0.1h0.1v-0.1h0.1l0.1,0.1h0.1h0.1v-0.1v-0.1h0.1h0.3l0.3-0.1l0.3-0.2l0.2-0.1h0.1l0.1-0.1l0.1-0.2l0.3-0.1
                L241.7,420.5z"/>
            <linearGradient id="NL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="374.7578" x2="0.1864" y2="374.7578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NL" class="stcustom0 st167" d="M489.8,285.9l-0.1-0.1l0.6-0.2l0.4-0.1h0.1l-0.7,0.2L489.8,285.9L489.8,285.9z M491.2,285.6l0.6-0.1
                l0,0h-0.1h-0.5H491v0.1H491.2L491.2,285.6z M492.9,285.2h-0.1l-0.3,0.1l0,0h0.1L492.9,285.2L492.9,285.2z M489.4,286.2h-0.2
                l-0.3,0.3v0.1L489.4,286.2z M494.2,284.7h-0.1l-0.2,0.1l-0.1,0.1h0.3L494.2,284.7z M486.4,295l-0.4,0.2l-0.5-0.1l-0.3-0.2h-0.5
                l-0.2,0.1l0.1,0.4l0.1,0.1l0.1,0.1h0.1l0.1-0.1l0.2-0.1h0.3l0.2,0.1l0.1,0.1l0.1,0.1h0.2l0.4-0.2l0.4-0.3l0.1-0.2v-0.2l-0.3,0.1
                L486.4,295L486.4,295z M495.3,286.3l-0.4-0.1l-0.3-0.1l-0.2-0.2l-0.3-0.3h-0.7l-0.6,0.1H492l-0.5,0.1l-1,0.5l-0.2,0.3l-0.2,0.5
                l-0.8,0.6l-0.5,0.2l-0.1-0.1l-0.2-0.1l-0.2,0.3l-0.1,0.3l-0.3,1.7l-0.2,0.6l-0.3,0.5l-0.5,0.6l-0.2,0.2l-0.1,0.1l-0.2,0.3l-0.1,0.4
                l-0.1,0.2l0.5,0.6l0.1,0.2l0.1,0.1l-0.5,0.1l0.2,0.2l0.3,0.1l0.2,0.1l0.1,0.1l-0.2,0.1h-0.2l-0.7-0.5l-0.4-0.1h-0.5l-0.1,0.1
                l-0.1,0.2l0.2,0.2l0.2,0.1h0.3l0.4,0.2l0.5-0.2l0.4,0.2l0.2,0.1l0.2,0.1h0.2h0.1l-0.1-0.3l0.2-0.1l0.2-0.1l0.1,0.1l0.2,0.1h0.1
                l0.3-0.3l0.1,0.1l0.1,0.1v0.1v0.1h0.1h0.3l0.1-0.2l0.1-0.1l0.1,0.1v0.2l0.1,0.3l0.3,0.3l0.3,0.1l0.3-0.1l0.1-0.1h0.1l0.1,0.2
                l0.2,0.2l0.4,0.1l0.1,0.1l0.1,0.1v0.2l-0.2,0.4v0.1l0,0v0.1l-0.2,0.3v0.1l0.1,0.2l0.1,0.1l0,0l0.1,0.1h0.1h0.3h0.3l0.2-0.7l-0.1-0.2
                l-0.1-0.1h-0.2l-0.1-0.1v-0.1v-0.1h0.2l0.1-0.1l0.5-0.4v-0.1h-0.1h-0.1v-0.1v-0.1l0.3-0.6l0.1-0.2v-0.2v-0.2l-0.1-0.3l-0.1-0.2v-0.2
                l-0.1-0.1l-0.3-0.5v-0.2l0.2-0.1l0.2-0.1l0.1-0.1h0.1l0.4,0.1l0.2,0.1l0,0l0.1-0.1h0.3l0.6-0.3l0.1-0.1l0.1-0.1v-0.1l-0.2-0.3v-0.1
                v-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.3-0.3l0.1-0.3v-0.3V290l-0.1-0.2l-0.1-0.1h-0.1h-0.3l-0.2-0.1l-0.1-0.2v-0.1l0.1-0.1v-0.1V289v-0.1
                l0.1-0.1h0.7l0.1-0.1v-0.4l0.2-0.7l0.2-0.4V287v-0.9L495.3,286.3L495.3,286.3z M485.6,293.7l0.2-0.1l0.4,0.3l0.3-0.1v-0.1l-0.1-0.1
                l-0.3-0.2h-0.4l-0.3,0.1v0.1L485.6,293.7L485.6,293.7z M488.5,287.6l0.3-0.3v-0.5l-0.4,0.4l-0.1,0.3v0.1H488.5L488.5,287.6z"/>
            <linearGradient id="NO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="446.9579" x2="0.1864" y2="446.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NO" class="stcustom0 st168" d="M546.9,175.1l0.8,0.3h0.3l0.4,0.6l0.2-0.1v0.4l-0.4,0.2l-0.6,0.1l-0.1,0.1l-0.5-0.1l-0.3-0.5
                l-0.5-0.1v-0.2l0.3-0.4L546.9,175.1L546.9,175.1z M561.7,186.5l0.2,1.1v0.4l-0.1,0.4l-0.1,0.2l-0.2,0.1H561l-0.7-0.4l-0.4-0.4h-0.1
                h-0.1l0.1,0.4v0.3l-0.1,0.3l-0.1,0.3l-0.1,0.2l-0.3,0.3l-0.5,0.3l-1.2,0.5l-0.1,0.2l-0.4,1.4l-0.1,0.2l-0.1,0.2l-0.4,0.2l-0.2-0.3
                l-0.2-0.5v-0.5l0.5-0.9l0.5-0.6l0.1-0.2l0.3-0.6l-0.5-1.6l-1-0.5l-1.1-0.7l-0.4-0.4l-0.6-0.8l-0.4-0.7H553l-0.4,0.2l-0.7,0.7
                l-0.4,0.3l-0.2,0.1h-0.1l-0.5-0.2h-0.5l-0.4,0.1l-0.2,0.1l-0.6,1.1l-0.4,0.5l-0.2,0.2l-0.2,0.3l-0.1,0.5l-0.3,1.8l-0.2,0.7l-0.1,0.4
                l0.1,1.2l-0.1,0.7l-0.3,0.6l-0.2,0.2l-0.3,0.1l-0.3,0.1l-0.3,0.3l-0.2,0.4l-0.2,1l-0.4,0.4l-0.4-0.1l-0.3-0.4l-0.6-0.3l-0.4-0.2
                l-0.5-0.4l-0.4-0.3l-0.4-0.1l-0.2,0.4l-0.2,0.3l-0.7,0.3l-0.4,0.2h-0.5l-0.2-0.2l-0.7-0.2l-0.9-0.2h-0.3l-0.1-0.4l-0.2-0.6l-0.6-1.1
                l-0.3-0.4l-0.5-0.9l-0.6-0.9h-0.1h-0.4h-0.5l-0.3,0.2l-0.2,0.3v0.2l0.2,0.8l-0.1,0.2l-0.1,0.1l-0.5-0.2h-0.6l-0.2,0.3h-0.4l-1.1,0.1
                l0.5,0.7l0.2,0.3v0.4l-0.1,0.7l-0.2,0.6l-0.3,0.5l-0.5,0.5l0.8,0.5l-0.5,0.7l-0.2,0.3H531l-0.5-0.2l-1.2-0.6l-0.6-0.2l-0.5-0.1h-0.3
                l-1.1-0.5l-0.2,0.1l-0.4,0.2v0.5v1.1l0.1,0.9l-0.1,0.5l-0.1,0.3l-0.4,0.9l-1-0.6l-0.7-0.4l-0.4,0.6l-1.1,1l-0.6,2v0.1l-0.3,0.5
                l-0.4,0.2l-0.3,0.1l-0.2,0.6l0.4,0.8l0.2,0.4l0.2,0.7v0.4v0.3l-0.5,0.6l-1,1.5l-0.9,1.5l-0.4,0.4l0.2,1.3l-0.3,0.4l-0.6,0.4
                l-0.3,0.2l-0.3,0.1l-1.1,0.2l0.2,1.4l0.1,0.6v0.4l-0.1,0.3l-0.1,0.7l-0.2,2.3L516,220l-0.2,0.6l-0.7,1.5l-0.5,1l-0.8,1.4l0.6,0.4
                l0.6,0.3l0.1,0.5l0.1,0.8v0.6l-0.2,0.5l-0.2,0.4l-0.1,0.2l-0.8-0.2l-1-0.2h-0.3L512,228l-0.5,0.3l-0.3,0.3l-0.1,0.1l-0.4,0.6
                l-0.6,1.1l-0.4,0.5l0.1,0.7l-0.6,1.3l0.4,1.3l0,0l0.2,0.5l-0.2,0.3l-0.1,0.2v0.6l0.1,0.6v0.4v0.4l0.5,1.9v0.4v0.3l-0.2,1.1l-0.2,1.5
                l0.4,0.4l0.5,0.5l0.3,0.2l0.5,0.6l0.3,0.5v0.4l-0.1,0.4l-0.1,0.3l-0.1,0.4l-0.1,0.3l-0.1,0.1h-0.6l-0.3,0.1l-0.2,0.1l0.1,0.6
                l0.4,1.2l0.3,0.8l0.1,0.5l-0.1,0.5l-0.1,0.3v0.4l-0.1,0.7l-0.2,0.4l-0.3,0.4l-0.3,0.3l-0.3,0.1h-0.2l-0.2,0.2l-0.1,0.5l-0.1,0.5
                l-0.4,0.6v0.2l0.2,0.7l0.2,0.8l-0.1,0.7l-0.1,0.8l-0.2,0.5l-0.3,0.2l-0.2-0.1l-0.2-0.7v-0.2l-0.1-0.2l-0.7-0.2H507l-0.3-0.1h-0.2
                l-0.3-0.1l-1-1.1l-0.3-0.5v-0.2v-1l-0.1-0.4v-0.5l-0.2,0.4l0.1,0.6l-0.2,0.3l-0.3,0.1v0.4l0.1,0.1v0.4l-0.1,0.5l-0.5,1.2l-0.1,0.1
                l-0.1,0.2l-0.3-0.1l-0.3,0.3l-0.3,0.1l-0.1-0.4L502,257h-0.2l0.2,0.2l0.2,0.3l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.7,0.4l0.2,0.3l-0.2,0.3
                h-0.2l-0.1,0.1v0.2l-0.7,0.6l-1.1,1.4l-0.6,0.4L498,262h-0.4l-0.5,0.4l-1.1,0.3l-0.8-0.1l-0.5,0.1l-0.3-0.2v-0.2v-0.1v-0.1h-0.1
                h-0.2l-0.1,0.1v0.3l-0.1,0.1l-0.4-0.2l-0.1-0.1l0.1-0.3l0.2-0.3v-0.1v-0.2h-0.1h-0.4h-0.3l-0.9-0.6l-0.2-0.3L491,260l-0.3-0.5
                l-0.2-0.6v-0.5l0.1-0.8l0.2-0.2l0.7,0.3l0.7,0.5h0.1l0.2-0.4l0.4-0.3l-0.1-0.1l-0.6,0.4l-0.2-0.2l-0.4-0.4V257l0.2-0.2l0.1-0.3
                l-0.1-0.3v-0.4l0.3-0.4l0.4-0.4l0.3-0.4l0.3-0.2v-0.1l-0.4,0.1l-0.3,0.2L492,255l-0.5,0.3l-0.4,0.1l-0.2,0.1l-0.3,0.1l-0.3,0.5
                l-0.3,0.2h-0.5l-0.1-0.4l0.1-1.2l0.2-0.6l0.2-0.4l0.3-0.1l0.2-0.3h0.2l0.1,0.1l0.5,0.1l0.3-0.4l0.3-0.1l0.6-0.4v-0.1l-0.4,0.1h-0.3
                l-0.4,0.1l-0.2-0.1l-0.1-0.3l0.1-0.3l0.6-0.7l0.2-0.3l0.1-0.3v-0.2l0.1-0.4l0.6-0.7l0.5-0.3l0.2,0.3l-0.1,0.8v0.3l0.4-1.2l0.2-0.3
                l0.2-0.2l0.5-0.1l0.1-0.2l-0.5,0.1l-1.3,0.5l-0.5,0.4l-0.1,0.3l-0.4,0.5l-0.2,0.3l-0.1,0.5l-0.2,0.2l-0.3,0.1l-0.4,0.6l-0.2,0.5
                l-0.4,0.4l-0.3,0.3l-0.1,0.1l-0.1,0.3h-0.1l-0.1-0.2V253v-0.6l0.2-0.4l0.1-0.4l-0.1-0.4l0.1-0.2h0.2l0.3,0.1h0.3l0.5-0.3l-0.1-0.2
                h-0.2h-0.4l-0.4-0.3l-0.3-0.6l-0.1-0.8l0.1-0.2l1.1-0.8l0.3-0.4h-0.2l-0.4,0.4l-0.6,0.3l-0.4-0.4l-0.2-0.4l-0.1-0.9v-0.4v-0.6
                l0.2-0.2l0.3,0.1h0.3l0.6-0.1l1.3-0.4l0.9,0.2h0.4l0.5-0.3h0.5l0.4,0.2l0.2,0.3v0.4l0.2,0.2l0.1-0.1l-0.1-0.3v-0.4l1.4-0.5l0.2-0.2
                l-0.6-0.1l-0.2-0.5l0.3-0.7V243l-0.3,0.4l-0.2,0.5l0.1,0.4l-0.1,0.2l-0.3,0.1h-0.6l-0.4-0.2l-0.4-0.1l-0.1-0.1v-0.3l-0.1-0.1
                l-0.2,0.3l-0.1,0.5l-0.3,0.1l-0.8-0.2l-1.2,0.1l-0.5,0.3h-0.4l-0.6-0.5l-0.2-0.4l-0.1-0.7V243l0.5-0.1h0.2l0.2-0.2l-0.2-0.1
                l-0.3-0.2l-0.2-0.5l-0.3-0.1l-0.2-0.4v-0.6l0.1-0.4l0.2-0.1l0.4,0.1l1-0.1l0.9,0.4l0.6,0.2l1.3-0.1l0.7-0.4l-0.1-0.1l-0.8,0.2h-0.7
                l-1.3-0.4l-0.5-0.1l-0.6,0.1l-0.3-0.1l-0.2-0.4l0.1-0.8l0.3-0.2l0.1,0.2h0.2l0.2-0.3l0.2-0.2l0.1-0.4l0.5-0.4h0.2l0.3-0.2l0.2,0.1
                l0.1,0.2l0.2,0.2h0.4l1-0.3l0.1-0.1l0.2-0.3l-0.7,0.1l-0.5,0.2l-0.4,0.1v-0.2l0.1-0.2l0.2-0.2l0.1-0.4l0.2-0.2h0.2l0.5-0.1l0.4-0.1
                l0.6,0.1l0.9,0.1l0.6,0.4h0.2l0.2-0.1l0.1-0.1l-0.5-0.1v-0.2l0.1-0.2l0.7-0.3l0.8-0.1l-0.1-0.2l-1.8,0.4l-0.5-0.2H495l-0.2,0.1
                l-0.7,0.2l-0.1-0.1l0.1-0.4l0.4-0.7v-0.2l0.2-0.2l1.1-0.4l0.5-0.5l0.2-0.1h0.2l0.3-0.1l0.7,0.1l0.3,0.6l0.3,0.2l0.9,0.7v-0.2l-0.8-1
                l-0.3-0.2l-0.2-0.5l0.1-0.5l0.2-0.3l0.9-0.2l0.2-0.2v-0.3l-0.1-0.2h-0.3l-0.3-0.1l-0.1-0.3l0.1-0.2l0.5-0.4l0.3-0.1l0.5-0.1l0.8,0.3
                l0.1,0.2l-0.2,0.4v0.2h0.2l0.5-0.7l0.6-0.1l0.2-0.1l0.3-0.1l0.4,0.6l0.2,0.2l0.1,0.1l0.1,0.5h0.1l0.2-0.3l0.3-0.1l0.4-0.1l0.7,0.1
                l0.3-0.1h0.2l-0.1-0.5l-0.1-0.1l0.1-0.4l0.2-0.2l0.5-0.3l0.5-0.1l0.3-0.3l0.4-0.3l-0.1-0.2l-0.1-0.2h-0.3l-0.1-0.1l0.3-0.3l0.5-0.4
                l-0.2,0.3l-0.3-0.2l-0.3,0.1l-0.4,0.3l-0.5,0.4l0.1,0.1l0.2,0.4l-0.3,0.5l-1.7,1.3l-0.8,0.4l-0.4-0.1l-0.1-0.3l-0.2-0.3l-0.2-0.5
                H502l-0.2,0.1l-0.1-0.2l0.1-0.6l0.3-0.4l0.4-0.3l0.2-0.4l0.2-0.6l0.6-0.6l0.9-1.5l0.8-0.5l0.3-0.5l0.4-0.2l0.4-0.4h0.3l0.5-0.4
                l0.3-0.5h-0.2l-0.5,0.3l-0.3,0.1v-0.5l0.1-0.5l0.4-0.5l1.9-1.4l0.2,0.2l0.2,0.4l0.6-0.1l0.6-0.8l0.5-0.8l-0.3,0.1l-0.3,0.3l-0.6,0.5
                l-0.3,0.1l-0.2-0.1l-0.1-0.3l-0.2-0.1l-0.2,0.1l-0.2-0.2v-0.6l0.2-0.8l0.2-0.6l0.2-0.4l0.8-1.2l0.2-0.7l0.4-0.3l0.5,0.1l0.1-0.1
                l-0.2-0.4l-0.5-0.3v-0.2l1.7-0.6h0.8l0.2-0.3l0.4-0.2l0.3-0.4l-0.2-0.2l-0.8,0.3l-0.5,0.2h-0.2l-0.2,0.1h-0.7l-0.1-1.4l0.1-0.8h0.2
                l0.1-0.7l0.3-0.4l0.4-0.1l0.2-0.2l0.3-0.4l0.5,0.1l0.5-0.1l-0.1-0.2l-0.6-0.2l-0.1-0.4l0.2-0.2l0.2-0.2h0.2l0.4-0.8l0.2-0.3l0.3,0.1
                l0.4-0.3l0.4,0.1l0.4-0.2l0.5-0.1l1.8-0.1l0.1-0.3l-0.4-0.1l-1.3-0.1h-0.7l-0.3,0.1l-0.1-0.1v-0.2l0.2-0.3l0.1-0.3l0.5-0.8l0.6-0.6
                l0.4,0.1l0.5,0.5l0.3,0.1l0.2,0.2l0.2,0.7h0.1l-0.1-0.7l0.3-0.6l-0.1-0.2l-0.5,0.2l-0.4-0.2l-0.3-0.4l-0.1-0.4l0.2-0.4l0.2-0.2
                l-0.1-0.2l-0.7,0.6l-0.5,0.1l-0.2-0.1l0.1-0.6l-0.1-0.4l0.7-1.1l0.2-0.1l0.4,0.1l0.4,0.3h0.3l0.3-0.2v-0.3l-0.7-0.1l-0.2-0.2
                l0.1-0.2l0.5-0.3l0.5-0.5l0.5-0.1l0.4-0.4l0.1,0.1l0.1,0.1l0.2,1.3l0.4,1.1h0.1l-0.2-0.9l0.1-0.3l0.2-0.2l0.1-0.2l-0.2-0.1l-0.2-0.3
                l-0.2-1l0.1-0.3l0.5-0.6l0.6-0.1l0.7,0.4h0.2l0.4-0.1l0.7-0.3l0.4-0.1h0.2l0.1-0.2l0.4-0.1l-0.1-0.1l-0.2-0.1l-0.6,0.2l-1.7-0.1
                l-0.2-0.2v-0.3l0.2-0.5l0.2-0.3l0.7-0.5l0.7-0.1l0.7-0.8l0.3-0.6l0.2-1l0.4-0.8l1.1-0.4v-0.2l-0.1-0.4V190l0.3-0.9l0.2-0.3h0.1
                l0.2,0.3l0.3,0.6l0.5,0.3l0.6,0.1l0.2-0.2l-0.5-0.3l-0.4-0.4v-0.4l0.2-0.2h0.3l0.3-0.1l0.3-0.3V188v-0.3l0.1-0.3l0.4-0.7l1.4-0.5
                l0.1,0.2l-0.1,1.4l-0.2,0.9v0.6l0.3-0.6l0.4-1.8l0.3-0.8l0.3-0.5l0.2-0.1l0.2-0.2l0.3-0.1l0.1,0.2l0.1,0.4l-0.2,1.5v0.5l-0.2,0.6
                l-0.7,1.4v0.2l0.1-0.1l0.3-0.2l0.8-1.4l0.7,0.2v-0.1l-0.2-0.4l-0.3-0.4l-0.1-0.5v-1.3l0.2-0.5l0.6,0.1l0.4-0.1l0.2,0.2h0.4l0.3-0.9
                l0.5-0.1l0.4,0.6l0.5,0.4l0.4,0.6l0.1-0.2l-0.2-1.4L536,184l-0.5-0.3l-0.6-0.6l-0.2-0.3v-0.2l0.5-0.2l0.7,0.2l0.6-0.5l0.2,0.1
                l0.5-0.3l0.3,0.4l0.2-0.1l0.1-0.5l0.7-0.3l0.5,0.3l0.3,0.3l0.1,0.6l0.2,1.1l0.4,0.6l0.2,0.3l0.3,0.1l0.1-0.3l-0.3-0.4l-0.1-0.3
                l0.1-0.8l0.1-0.3l0.8-1.3l0.7-0.7l0.4-0.1l0.7-1.5l0.2-0.3l0.2-0.1v-0.4L543,178v-0.5l0.5-0.6l0.6-0.9l0.3-0.1l0.2,0.3l0.6,0.4
                l0.4,0.5l0.3,0.2l0.2-0.1l0.1-0.4l0.2-0.2l0.4,0.1l0.2,0.2h0.2l0.2,0.1v0.3l-0.3,0.3l-0.6,0.9l-0.5,1l-0.2,0.5l-0.2,1.4l-0.4,0.9
                v0.6l0.2,0.3l0.5-0.2l0.6-0.8l0.1-0.9l1.5-2.4l0.7-1.3l0.8-1.1l0.4-0.2l0.2,0.7l-0.2,1l-0.3,0.6l0.2,0.3v0.7l-0.1,0.4v0.4v0.4
                l0.2-0.1l0.9-0.8l0.2-0.8l0.2-0.6l0.1-0.5l0.4-0.5h0.7v-0.2l-0.8-0.7l-0.1-0.3l0.3-0.4l0.7-0.8l0.4,0.1l0.2,0.2l0.9,0.1l0.7,0.6v0.9
                l-0.2,0.4l-0.2,0.2l-0.9,0.7l-0.1,0.3l0.3,0.1l0.6-0.3l0.2,0.3l-0.2,0.8v1.1l-0.1,0.7v0.6l0.1,0.3l0.2-1.3l0.1-0.3l0.4-0.5l0.1-1
                l0.3-1.2l0.4-0.7l0.2-0.2h0.8l0.3,0.3l0.3,0.6l0.2,0.2l0.7,0.2l0.2,0.3v0.2h0.2l0.5-0.4l0.3-0.1l0.5,0.7l-0.1,0.5v0.2h0.6l0.5,0.2
                l0.9,1l0.1,0.5v0.6l-1.3,0.6l-0.6,0.6l-0.9,0.2L556,183l0.1,0.4l2.2,1l0.1,0.3l-0.1,0.6v0.5v0.3l0.2,0.3l0.3,0.1l0.6-0.1l0.3,0.2
                l0.2-0.2l0.1-0.8l0.2-0.2l0.3,0.2l0.1,0.8l0.1,0.1l0.2-0.6h0.3h0.3L561.7,186.5L561.7,186.5z M540.9,178l-0.1,0.3l-0.1,0.3l-0.2,0.3
                l-0.7,1.1l-0.4,0.2l-0.1,0.2l-0.2,0.1l-0.5-0.1l-0.1,0.2l-0.1,0.1l-0.4,0.1h-0.2l-0.5-0.4L537,180l-0.2-0.4h0.5l0.2-0.1l0.3,0.1
                l0.2-0.4h0.4l0.8-0.3l0.3,0.1l0.7-0.9h0.2l0.3-0.3L540.9,178L540.9,178z M542.5,180.1l-0.5,0.3l-0.3-0.3l-0.1-0.3v-0.7l0.1-0.4
                l0.3-0.2l0.2,0.2v0.1l0.3,0.1l0.3,0.4L542.5,180.1L542.5,180.1z M541.4,180.2l0.1,0.4v0.3l-0.3,0.5l-0.6,0.6v0.2l-0.2,0.2l-0.3,0.1
                l-0.2-0.1v-0.5v-0.2l-0.2,0.2l-0.2-0.3v-0.3l0.1-0.2l0.2-0.4l0.4-0.2l0.3,0.1l0.8-0.9l0.1,0.2L541.4,180.2z M530.6,183l0.1,0.1
                l0.1-0.1l0.1,0.1l0.2,0.4l0.3,0.2v0.2l-0.2,0.2l-0.3,0.1l-0.3-0.1l-0.1-0.3l-0.1-0.5l-0.3-0.4v-0.4l0.2-0.1L530.6,183L530.6,183z
                M529.1,184.2l0.2,0.4h0.2l0.1-0.2l0.2-0.1l0.3,0.2v0.4l-0.4,0.5l-0.3,0.7l-0.4,0.2l-0.2-0.1l-0.3,0.4l-0.3,0.4l-0.3,0.5v0.3v0.2
                l-1.1,0.2l-0.4,0.1l-0.4-0.2l-0.2-0.4l0.1-0.2l0.4-0.1v-0.3l0.1-0.2l0.1-0.1l0.1-0.4l0.2-0.1l0.3,0.1l0.2-0.3l0.1-0.1l0.1,0.3
                l0.1-0.4l-0.1-0.3v-0.2l0.4-0.6l0.2-0.4l0.2-0.3h0.2l0.1-0.4l-0.1-0.4V183l0.2-0.6h0.2l0.1,0.6L529.1,184.2L529.1,184.2z M533.4,184
                l-0.2,0.2l-0.2,0.1l-0.1-0.1l-0.2-0.1h-0.2l-0.2-0.4v-0.3l0.2-0.4l0.5-0.2l0.4,0.1l0.1,0.1L533.4,184L533.4,184z M559.2,186.4
                l-0.5,0.2l-0.1-0.2l0.1-0.3l0.1-0.6h0.2l0.2,0.2l0.2,0.3L559.2,186.4z M524.2,188.1l0.3,0.5l0.2-0.1l0.3-0.1l0.2,0.2l0.2,0.3h0.2
                l0.1,0.4l0.1,0.5l-0.2,0.4l-0.2,0.2l-0.1,0.4l0.1,0.6l-0.5,0.2l-0.6,0.1l-0.2-0.3l-0.5,0.5l-0.5,0.8l-0.2,0.1v-0.3l-0.3-0.2h-0.4
                v-0.2l0.1-0.1l0.4-0.2l0.1-0.4l-0.1-0.7l0.1-0.4v-0.2l0.2-0.3l0.8,0.1l0.1-0.3l-0.1-0.2l-0.4-0.3l0.1-0.2l0.3-0.2h0.3l0.1-0.3
                L524.2,188.1L524.2,188.1L524.2,188.1z M519.3,196.2L519.3,196.2l0.4-0.7l0.4-0.2V195l0.2-0.2v-0.4l0.1-0.3l0.2-0.1l0.1-0.1l0.1-0.1
                l0.3,0.3l0.2,0.3l0.2,0.7l-0.1,0.6l-0.5,0.5l-0.4,0.2l-0.4,0.6l-0.2,0.5l-0.2,0.1h-0.1l-0.1-0.1h-0.2l-0.2,0.4l-0.7,0.3l-0.3-0.1
                v-0.4h-0.2l-0.3,0.5l-0.3,0.2h-0.2l-0.3-0.2L516,199l-0.8,0.2l-0.3-0.1v-0.5l0.5-0.6l0.4-0.4l1.4-0.3l0.9-1.3l0.2-1.5l0.2-0.5
                l-0.1-0.3l-0.2-0.1v-0.5l0.1-0.5l0.5-0.7l0.3-0.3l0.4-0.8l0.2-0.2h0.2l0.2,0.2v0.5l-0.3,0.8l-0.5,0.7l0.1,0.5l0.2,0.4v0.7v0.7
                l-0.4,0.9L519.3,196.2z M517.8,193.2l0.4,0.8l0.2,0.5l-0.1,0.9l-0.4,0.4l-0.5,0.1H517l-0.2-0.2v-0.2l-0.1-0.1l-0.4,0.3H516l-0.3-0.2
                l-0.1-0.4l0.3-0.5l0.2-0.4h0.4l0.1,0.1l0.2,0.1l0.1-0.4v-0.3l0.1-0.2l0.5,0.2v-0.8l0.2-0.1h0.1l0.1,0.2L517.8,193.2L517.8,193.2z
                M514,198.4l0.2,0.1h0.4h0.1l-0.1,0.2l-0.2,0.2L514,199l-0.2,0.4l-0.1,0.1h-0.3l-0.2,0.1l-0.2,0.3l-0.2-0.2l-0.1,0.2v0.3l-0.1,0.1
                l-0.3,0.1l-0.1-0.7l0.2-0.2l0.1-0.3h0.2h0.2l0.3-0.6l0.4-0.2L514,198.4L514,198.4L514,198.4z M511.5,201.4l-0.4,0.4l0.2-0.7l0.2-0.7
                l0.3-0.4l0.2,0.2l-0.1,0.4v0.3l-0.1,0.2L511.5,201.4L511.5,201.4z M510,214.5h-0.3v-0.3l0.2-0.3l0.1-0.2v-0.2l0.2-0.2l0.3,0.2v0.4
                l-0.1,0.4L510,214.5L510,214.5z M510.2,215.5H510v-0.3l0.1-0.3l0.2-0.2h0.3h0.3l0.1,0.1l-0.2,0.2L510.2,215.5L510.2,215.5z
                M508.7,217.4l-0.2,0.2l-0.3-0.1v-0.2l0.1-0.4l0.2-0.1h0.3l0.1,0.1L508.7,217.4L508.7,217.4z M506.6,222.4l-0.1,0.2l-0.3-0.1
                l-0.6,0.1l-0.3-0.2l0.2-0.4l0.6-0.4h0.3l0.3,0.5L506.6,222.4L506.6,222.4z M498.9,230.2h-0.3l-0.2-0.1l0.5-0.3l0.7-0.3l0.1-0.2h0.1
                l0.1,0.2v0.3l-0.1,0.1L498.9,230.2z M497.8,232.3h-0.3l-0.3-0.1L497,232v-0.2l0.4-0.2l0.4-0.1l0.2,0.2v0.4L497.8,232.3L497.8,232.3z
                M489,245.8l-0.2,0.1l-0.2-0.1l0.1-0.6l0.1-0.1h0.2l0.2,0.3L489,245.8z M489.4,250.3v0.7l-0.3-0.1l-0.1-0.3v-0.2V250l-0.1-0.4
                l0.1-0.2h0.1l0.2,0.4L489.4,250.3L489.4,250.3z"/>
            <linearGradient id="NP_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="285.508" x2="0.1864" y2="285.508" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NP" class="stcustom0 st169" d="M722.4,381.8l0.1,0.1v0.1v0.2l-0.1,0.3l-0.1,0.2l-0.1,0.5l-0.1,0.9v0.2l0.3,0.5l0.1,0.4v0.3l-0.1,0.4
                l-0.2,0.5l-0.1,0.1H722l-0.4-0.2h-0.3l-0.3,0.1h-0.3l-0.3-0.1l-0.4,0.2l-0.3-0.1l-0.2-0.1l-0.1-0.3h-0.1l-0.7,0.4h-0.2l-0.4-0.2
                l-0.4-0.2l-0.1-0.1l-0.3-0.1h-0.3l-0.3-0.1l-0.4,0.2H716l-0.2-0.1l-0.1-0.2v-0.2l-0.1-0.1h-0.2l-0.3,0.1l-0.5,0.2h-0.1h-0.1l0,0
                l-0.1-0.2h-0.1h-0.1h-0.2l-0.2-0.2l-0.7-0.4l-0.1-0.2v-0.4v-0.1l-0.1-0.2l-0.4-0.2l-0.7-0.2l-0.4-0.2l-0.2,0.1l-0.4,0.1l-0.2,0.2
                l-0.2-0.1l-0.5-0.2h-0.3l-0.2,0.1v0.1l-0.2,0.1l-0.2-0.1l-0.4-0.1l-0.4-0.1l-0.6-0.2l-0.1-0.2l-0.1-0.2h-0.1h-0.5l-0.5-0.3l-0.5-0.3
                l-0.2-0.1h-0.1h-0.1l-0.1,0.1h-0.1l-0.3-0.1l-0.3-0.2l-0.4-0.3l-0.5-0.4l-0.2-0.2l-0.1-0.2l-0.1-0.1l-0.4-0.2l-0.3-0.2l-0.4-0.2H702
                l-0.2-0.1l-0.2-0.2h-0.2l-0.1,0.1v0.1h-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.2l-0.2-0.2l-0.1-0.1l0.1-0.4l0.1-0.3l0.1-0.1l0.2-0.2l0.1-0.4
                v-0.3l0.2-0.5l0.2-0.5l0.4-0.5l0.2-0.2l0.2-0.1l0.4-0.4l0.1-0.1l0.2-0.1h0.2h0.1l0.1,0.2l0.2,0.2h0.2l0.2-0.2l0.5-0.8l0.6-0.2
                l0.6,0.1l0.5,0.1l0.2,0.3l0.1,0.3l0.1,0.1l0.2,0.2l0.7,0.4l0.4,0.4l0.6,0.5l0.4,0.2h0.4l0.2,0.2l0.3,0.4l0.3,0.4l0.4,0.4h0.2
                l0.3-0.1l0.4-0.2l0.2,0.1l0.2,0.1l0.1,0.2l0.1,0.4l0.1,0.4l0.2,0.1l0.3,0.2l0.2,0.2l0.5,0.3l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.2
                l0.6-0.2h0.2h0.1v0.1l-0.1,0.3l-0.1,0.4l0.1,0.2l0.3,0.1l0.6,0.1h0.8l0.2,0.2l0.2,0.3l0.2,0.5l0.1,0.2l0.1,0.1l0.2-0.1v-0.2V382
                l0.2-0.1l0.1,0.1l0.1,0.2l0.3,0.2l0.2,0.1h0.2l0.1-0.1l0.1-0.4l0.2-0.1h0.2l0.1,0.1l0.1,0.2l0.3,0.1l0.3,0.1l0.2,0.1l0.3,0.3
                l0.4,0.1h0.5h0.3h0.2h0.2l0.5-0.2h0.2h0.3L722.4,381.8z"/>
            <linearGradient id="NR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="201.5579" x2="203.3996" y2="201.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NR" class="stcustom0 st170" d="M943.7,464.3L943.7,464.3l-0.1,0.1L943.7,464.3l-0.1-0.1L943.7,464.3L943.7,464.3L943.7,464.3z"/>
            <linearGradient id="NU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="148.9079" x2="0.1864" y2="148.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NU" class="stcustom0 st171" d="M1008.9,517l-0.3,0.2l-0.1-0.2l0.1-0.2l0.1-0.1h0.1L1008.9,517L1008.9,517z"/>
            <linearGradient id="NZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="65.2579" x2="0.1864" y2="65.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="NZ" class="stcustom0 st172" d="M991,598.9L991,598.9l-0.3,0.1l-0.3,0.4v-0.3l-0.1-0.1H990v0.1l0.2,0.1l0,0l-0.2,0.2l0.2,0.4h0.2
                l0.1,0.3v0.1l-0.3,0.1l-0.2,0.2h-0.2h-0.1l-0.1-0.3v-0.1l0.2-0.2l0.1-0.2l-0.1-0.2l-0.2-0.1l-0.5,0.1H989l0.2-0.3h0.3l0.3-0.2
                L991,598.9L991,598.9z M991,601.1L991,601.1l-0.1-0.2v-0.1h0.2l0.1,0.2L991,601.1L991,601.1z M961.4,567v0.2l0.2-0.1l0.1-0.2
                l0.2-0.2v0.3l0.1,0.1l0.6,0.2l0.1,0.2h0.1l0.1-0.1l0.1-0.1l0.2,0.1l0.5,0.3v0.1v0.2v0.2l0.1,0.1h0.2l0.2-0.2h0.1l0.1,0.3l0.1,0.2
                l0,0l0.1,0.2l0.1,0.1l0.2,0.4v0.2l-0.1,0.1l0.2,0.4h-0.1l-0.4-0.1v0.1l0.2,0.3l0.2,0.4l0.2,0.3l0.6,0.8l-0.1,0.3v0.2l-0.1,0.2
                l0.2,0.4l-0.1,0.1l-0.1,0.4l-0.1,0.1v0.2h0.2l0.1,0.1l0.1,0.1l0.1-0.2h0.1l0.3,0.2l0.6,0.2l0.2,0.1l0.1,0.2l0.1,0.4l0.1,0.2h0.2
                l0.2-0.1l0.1-0.1v-0.4l-0.2-0.6v-0.2v-0.2v-0.2l-0.1-0.2l-0.1-0.1l-0.1-0.1v-0.2l0.2-0.1l0.1,0.2l0.1,0.2l0.4,0.6h0.3v0.2l0.2,0.2
                l0.1,0.3l0.1,0.9l0.2,0.8l0.3,0.4v0.2l-0.2-0.1l-0.1,0.1v0.1l0.2,0.2l0.2,0.1h0.1l0.1,0.1l0.9,0.5l0.4,0.2l1.1,0.3h0.3h0.2l0.3-0.1
                l0.3-0.2l0.3-0.3l0.2-0.4l0.2-0.2l0.3-0.1l0.1-0.1l0.1-0.1h0.7l0.2,0.2l0.3,0.1l0.2,0.1l-0.1,0.2l-0.2,0.3l-0.1,0.4l-0.1,0.9
                l-0.1,0.9l-0.1,0.4l0.1-0.1l-0.3,0.2l-0.3,0.1l-0.1,0.5l-0.1,0.6v0.1l0.1,0.1v0.2l-0.2,0.3l-0.1-0.1l-0.1-0.3l-0.1-0.1l-0.4-0.1
                h-0.4h-0.3l-0.3,0.1l-0.4,0.4l-0.1,0.1l-0.1,0.2l-0.2,0.4l-0.1,0.4v0.2l0.1,0.2l0.4,0.2l-0.4,0.9l-0.4,0.9l-0.2,0.3l-0.2,0.2
                l-0.2,0.5l-0.4,0.5l-0.3,0.4l-0.2,0.4l-0.2,0.4l-0.4,0.6l-0.2,0.4l-0.2,0.3l-0.4,0.4l-0.4,0.3l-0.7,0.5l-0.2,0.2l-0.2,0.1l-0.2-0.1
                v-0.1l-0.1-0.3l-0.1-0.1l-0.3-0.1l-0.4,0.2h-0.1v-0.1v-0.5l0.1-0.1l-0.1-0.1h-0.1v0.1l0.1,0.1l-0.2,0.1h-0.2l-0.1-0.1v-0.1l0.1-0.1
                l0.1-0.1l0.5-0.6l0.5-0.8l0.4-0.8l0.1-0.4l0.2-0.8l-0.1-0.3l-0.2-0.3l-0.4-0.6l-0.5-0.3h-0.4l-0.3-0.1l-0.3-0.3l-0.3-0.3l-0.6-0.3
                l-0.6-0.2l-0.3-0.3l-0.1-0.2l-0.1-0.2v-0.2v-0.2l0.1-0.2l0.1-0.1l0.6-0.4l0.7-0.2h0.1h0.1l0.2-0.1l0.3-0.3l0.1-0.2l0.1-0.6l0.1-0.6
                l0.2-0.7l0.3-0.4l0.1-0.3l-0.1-0.5l0.1-0.2l0.1-0.1l0.1-0.1l-0.2-0.4l-0.3-0.6l-0.1-0.2v-0.2l0.1-0.2h-0.2l-0.1-0.2l-0.2-0.6
                l0.1-0.1l0.1,0.1l0.2,0.4v-0.2l0.2-0.1l0.2-0.1h0.2l-0.4-0.5h-0.1l-0.2,0.1h-0.2h-0.2l-0.2-0.1l-0.1-0.2l-0.1-0.4l-0.1-0.1l-0.5-0.8
                h0.2l0.4,0.4l0.1-0.1l0.1-0.2v-0.2l-0.1-0.2l-0.2-0.1v-0.2l0.1-0.2V572l-0.3-0.2h-0.1v0.1l0.1,0.2h-0.1l-0.6-0.4l-0.2-0.4l-0.2-0.4
                v0.2v0.2l0.2,0.4l0.4,0.5l0.1,0.1l-0.1,0.2h-0.1l-0.1-0.1l-0.2-0.4l-0.1-0.2l-1.5-2.2l0.2-0.3l0.3-0.2l0.1-0.1v-0.1h-0.1l-0.1,0.1
                l-0.1,0.1l-0.1,0.1l-0.1,0.3l-0.1,0.1l-0.2-0.2l-0.1-0.1v-0.1v-0.1h-0.1l-0.2-0.3l-0.1-0.1l0.2-0.3V568l-0.2-0.4l-0.2-0.4l-0.9-1.3
                l-0.4-0.6l0.5-0.1h0.5l-0.2,0.4l0.1,0.2l0.2,0.2l0.3,0.5v0.2L961.4,567L961.4,567L961.4,567z M967.8,571.6v0.2l-0.2-0.1l-0.1-0.1
                l-0.2-0.1l0,0v-0.3l0.1-0.1V571h0.1l0.1,0.2l0.2,0.2L967.8,571.6z M960.9,589.5h0.3l0.3-0.3l0.3-0.2l0.3-0.2l0.5-0.4l0.1-0.1
                l0.3-0.1l0.1-0.1h0.2l-0.1,0.2l-0.2,0.1v0.1l0.1,0.1l-0.2,0.2v0.3l-0.2,0.3l0.3-0.1l0.1-0.2L963,589l0.1-0.2l0.2-0.1l-0.1-0.2v-0.1
                h0.2h0.1l0.1-0.1h0.2v0.1h0.2l-0.1,0.2l-0.2,0.2v0.1l-0.3,0.2l-0.2,0.1h0.3l0.4-0.3l0.2-0.3v0.3l-0.2,0.3l-0.2,0.2l-0.2,0.1
                l-0.2,0.1l-0.1,0.2v0.2l0.1,0.1l0.2,0.2l-0.2,0.4l0.2-0.1l0.1,0.1l0.2,0.2l-0.1,0.3l-0.1,0.1l-0.5,0.6l-0.2,0.3l-0.2,0.2v0.3
                l-0.1,0.2l-0.7,0.8l-0.1,0.2l-0.6,1.2v0.8l-0.2,0.2l-0.2,0.1l-0.5,0.2l-0.2,0.3l-0.3,0.2l-0.3,0.1v0.1l0.2,0.1l0.1,0.2l-0.1,0.2
                l-0.2,0.1h-0.2l-0.1,0.1l0.5-0.1l0.1,0.1v0.2v0.2l0.1,0.2l0.4,0.1l0.4,0.1l0.1,0.1l0.1,0.4l-0.1,0.2l-0.1,0.1h-0.1h-0.3l-0.3-0.1
                l-0.2-0.2l-0.5,0.1h-0.1h-0.1l0.3-0.2l-0.2-0.1h-0.1L959,599l-0.1,0.1v0.2l-0.1,0.1h-0.2l-0.2-0.2l-0.2-0.3l-0.3-0.2v0.1l0.2,0.4
                l0.1,0.2l-0.3,0.2l-0.3,0.2l-0.2,0.1l-0.2,0.1l-0.3,0.2l-0.1,0.1h-0.4l-0.2,0.1l-0.1,0.3l-0.1,0.2h-0.3l0.1,0.1l0.1,0.1l-0.2,0.9
                v0.4v0.6l-0.1,0.6h-0.4l0.1,0.1l0.3,0.2l-0.1,0.2l-0.3,0.4l-0.1,0.3l-0.1,0.6l-0.2,0.6l-0.3,0.7v0.1l0.1,0.2l0.1,0.1v0.2v0.1h-0.2
                l-0.1,0.1l-0.7,0.2l-0.2,0.2l-0.2,0.4l-0.2,0.3l-0.8,0.7l-0.4,0.6l-0.1,0.2l-0.1,0.1l-1,0.3H950l-0.4-0.1l-0.4-0.1H949l-0.4,0.1
                l-0.2,0.1l-0.3-0.1l-0.2,0.1l-0.1-0.1l-0.1-0.2V610l-0.1-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.1h-0.3l-0.5,0.1h-0.2l-0.3-0.6l-0.1-0.1
                l-0.4-0.2h-0.1l-0.2,0.3l-0.1,0.1h-0.8l-0.8-0.1l-0.3-0.1l-0.1-0.3l0.6-0.7l-0.2,0.1l-0.4,0.3h-0.2l0.2-0.3v-0.1v-0.2l-0.3,0.3h-0.3
                v-0.2v-0.3l0.1-0.1l0.9-0.1l0.3-0.1l0.1-0.2l-0.5-0.1v-0.2l0.1-0.2l0.5-0.3l-0.3,0.1h-0.4V606l0.1-0.2h0.4l-0.1-0.2v-0.2h0.1
                l0.4,0.3l0.3,0.1l-0.1-0.2v-0.1l0.1-0.1h0.2h-0.1l-0.2-0.1l-0.3-0.2v-0.2v-0.2l0.3-0.3l0.2,0.2h0.2l-0.2-0.1l-0.1-0.2l0.1-0.1
                l0.6-0.5l0.2,0.5V604v-0.2l-0.1-0.1v-0.1l0.1-0.1l0.3-0.1l0.3-0.4l0.2-0.2l0.2,0.1l0.1,0.2v-0.2l-0.1-0.1v-0.4l0.5-0.6l0.5-0.5
                l0.5-0.6l0.3-0.2l0.5-0.2l0.3,0.1h0.1l0.5-0.4l0.2-0.1l0.2,0.1l0.1,0.1l-0.1-0.4l0.1-0.2l0.4-0.3l0.5-0.3l0.4-0.1l0.3-0.2h0.2v-0.2
                v-0.1h0.2v-0.1l-0.1-0.1l0.4-0.3l0.2-0.3l0.1-0.1l0.1-0.1l0.1-0.2l0.2-0.1h0.2l0.1,0.1l-0.1-0.2l-0.2-0.1l0.2-0.2l0.2-0.1l0.2,0.1
                l0.2,0.1l-0.2-0.2v-0.1l0.3-0.1h0.1l0.2,0.3v-0.2v-0.2l0.3-0.3l0.4-0.5l0.1,0.2v0.2v0.3l0.1-0.1v-0.2l-0.1-0.4l0.5-0.7l0.1-0.1h0.1
                h0.2v-0.1l-0.1-0.1l0.1-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.7l0.1-0.1h0.4l0.2-0.1l0.3-0.2l0.3-0.4l0.2-0.4l0.2-0.9l0.1-0.9l0.4-0.7
                l0.5-0.5l0.5-0.4l0.2-0.1h0.3l0.3,0.1l-0.6,0.1l-0.1,0.2v0.2l0.1,0.2l0.1,0.2l0.3,0.2l0.3,0.1l0.2,0.4v0.5v0.4L960.9,589.5
                L960.9,589.5z M963.2,588l-0.4,0.2v-0.1l0.1-0.3l0.2-0.2h0.1l0.2-0.1v0.3L963.2,588L963.2,588z M943.7,604.5l0.1,0.5l-0.3-0.1
                l-0.1-0.1L943.7,604.5L943.7,604.5L943.7,604.5z M943.1,606.4v0.2v0.1H943h-0.1l-0.1-0.1h-0.1h-0.1l0.1-0.2H943.1L943.1,606.4
                L943.1,606.4z M947,611.3v0.2l-0.3-0.1v0.2l0.2,0.1l0.1,0.1h0.2l0.1,0.2l-0.1,0.2l-0.2,0.1l-0.5,0.1l-0.3,0.3h-0.3h-0.1l-0.3,0.3
                l-0.3,0.1h-0.1v-0.2l0.3-0.2v-0.2l0.1-0.2l0.2-0.1V612l0.2-0.2l-0.1-0.4l0.1-0.4h0.5L947,611.3L947,611.3z M941.6,627.9l0.1,0.4
                h-0.2l-0.3-0.1l-0.1-0.2H941l-0.1,0.2h-0.2v-0.1l0.1-0.2l0.4-0.4l0.1-0.5v-0.2h0.3l0.1,0.1v0.1v0.1l-0.1,0.2v0.2v0.2l-0.1,0.1
                L941.6,627.9L941.6,627.9L941.6,627.9z M949.9,635.7l0.2,0.2l-0.3,0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2h0.2l0.1-0.1L949.9,635.7z"/>
            <linearGradient id="OM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="264.6579" x2="0.1864" y2="264.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="OM" class="stcustom0 st173" d="M633.1,388.8L633.1,388.8l-0.1,0.1l-0.2-0.1l-0.1-0.1v-0.2v-0.3l0.1-0.3v-0.3l-0.1-0.1h-0.1l0.2-0.4
                l0.1-0.1h0.1h0.2l0.1-0.2l0.1-0.1h0.1v0.1v0.4v0.3l-0.1,0.9l-0.1,0.2l-0.1,0.1L633.1,388.8L633.1,388.8z M633,390.1l-0.1,0.1h-0.1
                V390v-0.1h0.1v0.1L633,390.1L633,390.1z M624.1,415.7l-0.2-0.4l-0.2-0.4l-0.2-0.4l-0.2-0.4l-0.1-0.3l-0.2-0.1l-0.1-0.3l-0.1-0.3
                l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
                l-0.1-0.3l0.4-0.1l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2l0.5-0.2
                l0.5-0.2l0.5-0.2l0.5-0.2l0.3-0.1l0.1-0.4l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3
                l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.3l-0.2-0.3l-0.2-0.4l-0.2-0.4l-0.2-0.4l-0.2-0.3l-0.2-0.3v-0.4V397v-0.3
                l0.2-0.5l0.2-0.6l0.2-0.4l0.1-0.3l0.1-0.3l0.1-0.3v-0.2l-0.1-0.1l-0.1-0.1l0.2-0.2l0.4-0.1h0.2l0.3-0.1l0.3-0.1v-0.1l-0.1-0.2
                l-0.1-0.2h-0.4l-0.1-0.1l0.1-0.3v-0.1v-0.1l-0.1-0.2v-0.3l0.1-0.2v-0.1v-0.3v-0.3l0.1-0.1l0.1-0.1l0.1-0.1h0.1l0.1,0.1v0.1v0.1h-0.1
                l0,0l0.1,0.2l0.2,0.2h0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.1-0.2l0.1-0.1h0.1l0.3,0.8l0.4,0.8l0.4,0.4l0.4,0.6l0.6,0.5l0.3,0.2l1.1,0.4
                l0.6,0.1l0.8,0.1l0.6,0.3h0.2l0.3-0.1h0.2l0.5,0.4l0.2,0.4l0.2,0.2l0.2,0.3l0.1,0.3l0.5,0.5l0.3,0.5l0.3,0.4l0.3,0.2l0.5,0.1
                l0.4,0.1v0.3v0.3l-0.1,0.3l-0.3,0.5l-0.1,0.3l-0.4,0.5l-0.4,0.9l-0.2,0.2l-0.7,0.4l-0.5,0.5l-0.6,0.9l-0.4,0.9l-0.2,0.3l-0.4,0.1
                h-0.2l-0.2-0.1l0.1-0.2V404h-0.2l-0.2,0.1l-0.4,0.7l-0.2,0.3l-0.1,0.4l-0.1,0.5l-0.2,0.4l-0.1,0.4v0.2l0.1,0.5v0.5l0.1,0.3l0.1,0.4
                l-0.2,0.1l-0.2,0.1h-0.7l-0.7,0.1l-0.6,0.2l-0.4,0.2l-0.5,0.5l-0.3,1.2l-0.5,0.5l-0.3,0.1h-0.8l-1.1,0.1l-0.4,0.1l-0.6,0.8v0.2
                l0.1,0.2v0.2l-0.1,0.2l-0.3,0.5l-0.3,0.3l-0.8,0.2l-0.3-0.1l-0.3-0.1h-0.5l-0.9,0.1l-0.3,0.3l-0.5,0.2l-0.5,0.3l-0.9,0.1
                L624.1,415.7L624.1,415.7z M639.9,405.2h-0.2h-0.1v-0.4l0.4-0.5l0.3-0.5l0.2,0.5l-0.3,0.3l-0.2,0.5L639.9,405.2L639.9,405.2z"/>
            <linearGradient id="PA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="226.8579" x2="0.1864" y2="226.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PA" class="stcustom0 st174" d="M257.9,438.6L257.9,438.6l-0.2,0.3l-0.1,0.2l0.2,0.2l0.1,0.2l0.1,0.2l0.2,0.2l0.2,0.4v0.2l-0.1,0.1
                l-0.2,0.1l-0.2,0.2v0.2v0.1l-0.5,0.4l-0.1,0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.1l0,0l0,0v0.1l0.1,0.4l-0.1,0.1l-0.2,0.1l-0.2,0.6
                l-0.1-0.1l-0.7-0.8l-0.6-1L255,440h0.2h0.1l0.1-0.1l0.1-0.1l-0.1-0.3l0.3-0.2l0.1-0.2h0.1l0.2,0.3l0.3,0.2l0.3,0.2l0.2,0.1l-0.3-0.2
                l-0.4-0.3l-0.1-0.2l-0.1-0.3l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1-0.1V439h-0.3l-0.1-0.1H255v0.2l0.1,0.1v0.1H255l-0.1-0.1l-0.1-0.1
                l-0.1-0.5l-0.3-0.2l-0.1-0.1h-0.1l-0.2-0.2l-0.2-0.1l-0.3-0.3l-0.4-0.2l-0.4-0.1h-0.5l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.3,0.1l-0.1,0.2
                l-0.1,0.2l-0.2,0.2l0.2,0.1l-1.1,0.7l-0.2,0.1l-0.5,0.1l-0.1,0.1l-0.1,0.1v0.2v0.2l0.1,0.1l0.1,0.1l0.3,0.4l0.5,0.5l0.1,0.2l0.1,0.3
                l-0.2,0.1l-0.1,0.1h-0.5l-0.2,0.1l-0.1,0.2l-0.2,0.1l-0.6,0.1h-0.5l-0.2-0.2v-0.5l-0.3-0.8l-0.1-0.5l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.4
                l-0.1,0.1h-0.1l-0.3-0.1l-0.4-0.1l-0.5-0.8l-0.1-0.2l-0.1-0.2l-0.4-0.1l-0.3-0.1h-0.3l-0.2,0.1l-0.2-0.1v-0.2l-0.4,0.1h-0.5
                l-0.4-0.1l-0.3,0.1l-0.2,0.2v0.4l-0.1,0.1v-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.2v-0.1l0.1-0.1l0.4-0.2v-0.1v-0.2v-0.2l-0.2-0.3l0.1-0.2
                l0.2-0.1l0.2-0.1l0,0v-0.1l-0.1-0.1l-0.3-0.2H242v-0.5v-0.6v-0.1h0.1l0.1-0.1v-0.2l0.1-0.1l0.2,0.1l0.2,0.1h0.1l0.1-0.1v-0.1v-0.1
                l0.2,0.2l0.4,0.3v0.1v0.1l0.1,0.4l0.2,0.1l0.2-0.1v0.1v0.1l-0.1,0.1v0.3l0.3,0.1l0.2,0.1l0.5-0.1h0.2h0.1l-0.1-0.2l-0.2-0.2v-0.1
                l0.1,0.1l0.1,0.1l0.3,0.2l0.5,0.5l0.5,0.1h0.4l0.4-0.1l0.6-0.2l0.5-0.4l0.4-0.2l1.2-0.4l0.4-0.4h0.2h0.2l0.4-0.3l0.2-0.2l0.2-0.1
                l0.6,0.1l0.4,0.1h0.3l0.3,0.1l0.1,0.2l0.1,0.1h0.7l0.5,0.1l1.2,0.5l0.7,0.5l0.4,0.5L257.9,438.6L257.9,438.6z M244.3,436.6v0.1
                l-0.2-0.2h0.1l0,0L244.3,436.6z M253.7,439.7l-0.1,0.1l-0.1-0.3v-0.1v-0.2l0.1-0.1h0.1h0.1v0.3v0.1L253.7,439.7z M253.2,439.7
                l-0.1,0.1v-0.1l0.1-0.1l0,0V439.7L253.2,439.7z M246.1,442.3h-0.2l-0.3-0.1l-0.2-0.2v-0.1v-0.1l0.1-0.2l0.2-0.1h0.1l0.2,0.3
                l-0.1,0.1v0.2l0.2,0.1V442.3L246.1,442.3z"/>
            <linearGradient id="PE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="176.9078" x2="0.1864" y2="176.9078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PE" class="stcustom0 st175" d="M278.7,474.6v0.2l-0.1,0.1h-0.1l-0.2-0.1h-0.2h-0.2l-0.2-0.2l-0.1-0.1l-0.2-0.1H277h-0.3h-0.2h-0.2
                l-0.2,0.2l-0.1,0.2l-0.2,0.2l-0.5,0.1H275l-0.2,0.1h-0.3l-0.2,0.1h-0.4h-0.5l-0.3,0.1l-0.1,0.1l-0.3,0.2l-0.5,0.3l-0.3,0.1l-0.3,0.3
                l-0.4,0.3l-0.3,0.2l-0.4,0.1l-0.2,0.1l-0.1,0.1v0.1l-0.1,0.3l-0.1,0.5v0.3v0.1l-0.2,0.4l-0.3,0.4l-0.1,0.3l-0.1,0.2l0.1,0.2l0.1,0.3
                l0.1,0.2v0.2v0.2l-0.1,0.2l-0.2,0.1h-0.2l-0.5,0.3l-0.5,0.4l-0.2,0.2l-0.1,0.2l-0.1,0.3v0.2l0.1,0.1l0.1,0.3v0.1l-0.1,0.1h-0.2h-0.1
                l-0.1,0.1h-0.1h-0.1v0.1v0.1v0.1l-0.1,0.1L267,484l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.2h0.2l0.1,0.1v0.1v0.1l-0.1,0.1v0.1l0.2,0.1
                l0.1,0.1l0.1,0.1l0.1,0.2v0.1l0.1,0.2l0.1,0.1v0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.1v0.1v0.1l0.2,0.2l0.3,0.2l0.2,0.3l0.1,0.2
                l0.3,0.3v0.4l-0.3,0.4l-0.3,0.4h0.5h0.6l0.6,0.1l0.4,0.1l0.2,0.1l0.2,0.1l0.1,0.2l0.1,0.2v0.2l0.2,0.2v0.2v0.3h0.1h0.7h0.8h0.8
                l0.3-0.1l0.3-0.3l0.2-0.1l0.2-0.1l0.2-0.3l0.2-0.1l0.2-0.2l0.2-0.2l0.1-0.1h0.1l0.2-0.1l-0.1,0.1l-0.1,0.2v0.2l0.1,0.2l-0.1,0.2
                l-0.1,0.2v0.4v0.6v0.5v0.6v0.7v0.5l0.1-0.1l0.2-0.1l0.2,0.2l0.2,0.1h0.1h0.1h0.2l0.4-0.2l0.3-0.1h0.3l0.5,0.1h0.3l0.4,0.6l0.3,0.5
                l0.3,0.5l0.2,0.4l0.4,0.6l0.3,0.5l0.3,0.6l0.4,0.7L282,498l-0.1,0.1v0.2l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.2v0.2v1.2v0.3l-0.1,0.3
                l-0.1,0.1l-0.1,0.1l0.1,0.3l0.1,0.6l0.1,0.1l0.1,0.2l0.1,0.2v0.1l-0.3,0.1l-0.1,0.1v0.3l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.1
                l-0.1,0.1v0.2l-0.1,0.2l-0.2,0.1v0.3v0.2l0.1,0.2l0.4,0.5v0.1l-0.2,0.3l-0.1,0.2l-0.3,0.6v0.1l0.1,0.3l0.5,1.2l0.1,0.1l0.2,0.1h0.2
                l0.4,0.1l0.2,0.1v0.1l0,0l-0.2,0.1l-0.2,0.1l-0.1,0.1v0.2v0.3l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.3l-0.3,0.4l-0.1,0.1v0.1H280
                l-0.3,0.3l-0.1,0.1l0.1,0.1l0.2,0.1l0.1,0.2v0.2v0.1l0,0l-0.2,0.2l-0.3,0.2h-0.3l-0.1,0.1v0.2l0.1,0.3v0.3l-0.1,0.3l-0.2,0.3
                l-0.4,0.2l-0.4,0.1h-0.3h-0.3h-0.1l-0.2-0.2l-0.9-0.7l-0.3-0.4l-0.3-0.2l-0.8-0.6l-0.1-0.2l-0.1-0.6l-0.1-0.2l-0.3-0.2l-0.7-0.3
                l-0.3-0.1l-0.3-0.3l-0.4-0.2l-0.4-0.4l-0.3-0.3l-0.3-0.2l-0.9-0.3l-0.5-0.3l-0.9-0.4l-0.4-0.2l-0.9-0.3l-0.3-0.1l-0.9-0.7l-0.6-0.2
                l-0.5-0.4l-1.5-0.8l-0.2-0.3l-0.2-0.4l-0.3-0.2l-0.4-0.6l-0.6-0.3l-0.6-0.4l-0.2-0.4l-0.4-0.5l-0.1-0.3l-0.3-0.3v-0.5l-0.2-0.2
                l0.2-0.1l0.2-0.1l0.2-0.8l-0.1-0.4l-0.6-0.7l-0.2-0.4l-0.2,0.4l-0.2-0.3l-0.3-0.6l-0.2-0.5l-0.5-0.4l-0.1-0.1l-0.1-0.2l-0.3-0.1
                v-0.4l-0.2-0.7l-0.3-0.4l-0.9-0.7v-0.3l-0.1-0.5l-0.2-0.5l-1-1.6l-0.3-0.5l-0.3-0.8v-0.4l-0.2-0.8l-0.4-0.6l-0.2-0.5l-0.3-0.7V487
                l-0.5-0.6l-0.2-0.5l-0.4-0.5l-0.4-0.3l-0.2-0.2l-0.6-1.2l-0.1-0.3l-0.4-0.6l-0.4-0.5l-0.3-0.4l-0.3-0.3l-2-1l-0.7-0.4l-0.2-0.2
                l-0.1-0.3v-0.2l0.2-0.2l0.3,0.1l0.2-0.1l0.1-0.2v-0.3l-0.2-0.4l-0.6-0.9v-0.2l0.1-0.2l-0.2-0.4l-0.3-0.3l-0.1-0.3l0.1-1l0.1-0.2l1-1
                l0.3-0.4l0.4-0.3l0.4-0.4l0.5-0.3l0.1,0.1l0.1,0.1v0.1v0.1l0.1,0.1v0.2l0.1,0.1v0.1v0.2v0.1v0.1l0.1,0.3v0.1l-0.1,0.1l-0.1,0.1
                l-0.1,0.2h-0.2l-0.2-0.1l-0.1,0.1l-0.1,0.2v0.1v0.1l0.1,0.1h0.3l-0.3,0.4l-0.1,0.2v0.1l0.2,0.1h0.1l0.3-0.1l0.2-0.2l0.1-0.1h0.2
                l0.2,0.1l0.3,0.2l0.3,0.2l0.1,0.1h0.2h0.2l0.2,0.1l0.2,0.1v0.4l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.1h0.2l0.3,0.1l0.1-0.1l0.1-0.2
                l0.1-0.1h0.2v-0.1V477l0.1-0.2l0.1-0.1l0.3-0.2l0.2-0.1v-0.1l0.1-0.2V476l-0.1-0.1v-0.2l0.1-0.2l0.1-0.3l0.1-0.3v-0.2l0.1-0.1v-0.1
                l0.1-0.2l0.1-0.2v-0.2l0.1-0.1v-0.2l0.1-0.5V473h0.1h0.1l0.1,0.1v0.1l0,0h0.1l0.1-0.1V473l-0.1-0.1v-0.1v-0.1l0.2-0.2l0.2-0.2
                l0.4-0.4l0.2-0.2l0.6-0.2l0.4-0.1l0.4-0.1l0.6-0.2l0.8-0.3l0.6-0.2l0.5-0.4l0.4-0.3l0.3-0.2l0.4-0.3l0.6-0.7l0.4-0.5l0.3-0.3
                l0.2-0.2l0.2-0.6l0.2-0.7l0.1-0.3V466h0.1l0.1,0.1h0.1h0.1h0.1l-0.1-0.7v-0.1l0.1-0.2V465l-0.2-0.1l-0.3-0.3l-0.1-0.2l-0.1-0.2
                l-0.2-0.1L263,464v-0.1h0.1l0.3,0.1h0.2h0.2l0.2-0.1l0.2-0.2h0.1h0.1l0.2,0.2l0.1,0.1l0.2,0.1h0.2h0.1h0.1l0.1,0.1l0.1,0.2l0.2,0.1
                l0.2,0.1l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.3l0.1,0.2l0.1,0.1v0.1l0.1,0.2l0.1,0.1l0.2,0.1l0.4,0.1l0.2,0.2l0.2,0.1l0.2,0.2
                l0.2,0.1h0.2l0.2,0.1l0.2,0.2l0.1,0.2l0.2,0.1l0.1,0.2l-0.1,0.3l0.1,0.2l0.2,0.1l0.3,0.1h0.2h0.1l0.1,0.1v0.1l0.1,0.3l0.1,0.2
                l-0.1,0.2v0.1l0.1,0.2l0.2,0.1l0.2,0.1l0.1,0.2h0.2h0.2h0.3l0.2-0.1h0.1h0.1l0.2,0.1l0.3,0.1l0.3-0.1h0.2l0.2-0.1l0.2-0.2h0.2
                l0.1-0.1l0.2-0.2l0.2-0.2h0.1l0.2,0.1l0.3,0.1l0.2,0.2h0.1l0.1,0.1h0.3l0.3-0.1l0.2-0.2l0.2-0.1l0.2-0.1h0.2l0.6,0.3l0.2,0.2h0.2
                l0.2,0.1l0.3,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.2,0.1l0.2,0.1l0.1,0.1v0.1v0.1l-0.2,0.3l-0.4,0.6l-0.4,0.6l-0.6,0.9l-0.3,0.5h0.1
                l0.5,0.2h0.1l0.2-0.1l0.1-0.1h0.1l0.1,0.1l0.2,0.1l0.1,0.3l0.1,0.2l0.2,0.1l0.2,0.2L278.7,474.6z"/>
            <linearGradient id="PF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="160.9079" x2="0.1864" y2="160.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PF" class="stcustom0 st176" d="M82,487.8l-0.3,0.1h-0.1l-0.1-0.2v-0.1l0,0l0.5,0.1V487.8v0.1C82.1,487.7,82,487.8,82,487.8z
                M83.4,487.8h-0.2v-0.1l0.1-0.1h0.1h0.1l0.1,0.1v0.1C83.6,487.8,83.4,487.8,83.4,487.8z M82,489.2L82,489.2l-0.2-0.1V489l0.2-0.1
                h0.1L82,489.2L82,489.2z M84.9,490l0.4,0.1h0.1l-0.1,0.1l-0.4,0.1l-0.1,0.1h-0.2l-0.1-0.2L84.9,490z M84.8,490.6l-0.2,0.2v-0.2H84.8
                L84.8,490.6L84.8,490.6L84.8,490.6z M86,492.3L86,492.3l-0.1-0.3l0.1,0.1h0.1L86,492.3L86,492.3L86,492.3z M68,507.6v0.1V507.6
                l-0.2-0.2l-0.1-0.1h0.1L68,507.6L68,507.6z M75.1,508.3L75.1,508.3v-0.2h0.1L75.1,508.3z M66.8,509L66.8,509L66.8,509l-0.1-0.1v-0.1
                l-0.1-0.1l-0.1-0.1v-0.1v-0.2l0.1,0.2l0.1,0.2l0.1,0.2V509L66.8,509z M50,509.9L50,509.9L50,509.9l-0.1-0.1v-0.1l0.1,0.1V509.9
                C50.1,509.8,50,509.9,50,509.9z M72.2,509.9L72.2,509.9l-0.4-0.2h0.1l0.2,0.1C72,509.8,72.2,509.9,72.2,509.9z M72.5,509.8l0.2,0.1
                L72.5,509.8h-0.3h0.1H72.5L72.5,509.8L72.5,509.8z M50.2,510.6L50.2,510.6H50v-0.3v-0.1l0.2,0.1V510.6L50.2,510.6z M54.6,512.5
                L54.6,512.5h-0.2l-0.1-0.1v-0.1v-0.1h0.3h0.1L54.6,512.5z M56,512.9l0.4,0.1l0.1,0.2l-0.1,0.1h-0.2l-0.1-0.1L56,512.9l-0.4,0.1
                l-0.3-0.1l-0.2-0.3v-0.2l0.1-0.1l0.3-0.1l0.4,0.1l0.1,0.2C56,512.7,56,512.9,56,512.9z M79.9,513.4L79.9,513.4L79.9,513.4
                L79.9,513.4L79.9,513.4v-0.2l0.1-0.1l0.1-0.1l0.2-0.1h0.1l0,0l0,0l-0.3,0.2l-0.1,0.1L79.9,513.4L79.9,513.4L79.9,513.4L79.9,513.4z
                M79.9,514.4L79.9,514.4L79.9,514.4l-0.2-0.1l-0.2-0.2V514l0.1,0.1l0.1,0.1L79.9,514.4z M90.7,514.9L90.7,514.9l-0.3-0.2h0.1
                L90.7,514.9L90.7,514.9z M80.3,515L80.3,515L80.3,515h-0.2v-0.1L80.3,515z M92.6,515.5L92.6,515.5L92.6,515.5L92.6,515.5l-0.2-0.1
                l-0.1-0.1h-0.1l0,0h0.1h0.1L92.6,515.5L92.6,515.5L92.6,515.5L92.6,515.5z M86.4,522.3L86.4,522.3L86.4,522.3l-0.1-0.2h-0.1h0.1
                l0.1,0.1V522.3L86.4,522.3z"/>
            <linearGradient id="PG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="184.8579" x2="0.1864" y2="184.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PG" class="stcustom0 st177" d="M906,495l0.5,0.1l0.2,0.2l-0.2,0.1l-0.4,0.1l-0.1-0.1l-0.4-0.1l-0.1-0.2l-0.2,0.1l0.1-0.1l-0.2-0.1
                l-0.1-0.2v-0.1l0.3,0.1L906,495L906,495z M908.1,494.7v0.2l-0.1-0.1l-0.3,0.1h-0.2l-0.1-0.1v-0.1h0.3v-0.2l0.4,0.1L908.1,494.7
                L908.1,494.7z M898.6,492.4v0.2l-0.2-0.1l0,0v-0.1H898.6L898.6,492.4L898.6,492.4z M899.1,490.9L899.1,490.9l0.3-0.2l0.2-0.1
                l0.1,0.1l-0.2,0.7l-0.2-0.1l-0.6-0.2v-0.3l-0.2-0.1l-0.1-0.3l-0.2-0.3v-0.2l0.1,0.1l0.1,0.2l0.5,0.4v0.1L899.1,490.9L899.1,490.9z
                M897.6,489l0.4,0.2l0.2-0.1h0.1l0.3,0.3v0.2v0.2v0.1l-0.1,0.1v-0.1h-0.1H898l-0.3-0.1h-0.4l0.2-0.2v-0.1l-0.2-0.3v-0.1V489
                L897.6,489L897.6,489L897.6,489z M897,489.4L897,489.4h-0.2l-0.5-0.4l0.1-0.3l0.2-0.1l0.3,0.2l0.1,0.2v0.1L897,489.4L897,489.4z
                M903.5,487.9h0.2h0.3l0.1,0.2l0.2,0.1l0.1,0.1l0.1,0.1v0.1v0.1l-0.1,0.1v0.1h-0.1l-0.2,0.1l-0.3-0.1h-0.1v-0.1l-0.2-0.2l-0.3-0.1
                l0.2-0.1L903.5,487.9L903.5,487.9z M878.1,487l0.1,0.1l-0.4-0.2l-0.4-0.3l-0.2-0.1l-0.1-0.2l0.2,0.1l0.4,0.1L878.1,487L878.1,487z
                M899.2,487.3v0.2l-0.2-0.2l0.1-0.2V487v-0.1l-0.2-0.1l0.1-0.2l0.1-0.1h0.1v0.3l0.1,0.1L899.2,487.3L899.2,487.3z M878.1,486.6
                L878.1,486.6l-0.6-0.2l-0.1-0.1h0.6h0.1V486.6L878.1,486.6z M890.5,479.1L890.5,479.1l-0.1-0.1l-0.3-0.1l-0.3-0.3v-0.3v-0.1h0.1
                l0.6,0.3l0.1,0.1v0.3L890.5,479.1L890.5,479.1z M912.8,481.5l-0.1,0.3h-0.1l-0.1-0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1h-0.3l-0.3-0.1
                l-0.3-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.3l0.1-0.3l-0.1-0.3l-0.4-0.2l-0.1-0.1l-0.2-0.3l-0.2-0.1l-0.2-0.3l-0.1-0.1l-0.1-0.3v-0.2
                l0.1-0.6v-0.3h0.1l0.1,0.1l0.1,0.1l0.4,0.1l0.3,0.2l0.3,0.4v0.1l0.1,0.1l0.3,0.2l0.1,0.1l0.3,0.5l0.1,0.1h0.2l0.2,0.1l0.3,0.2
                l0.2,0.2l0.2,0.2l0.1,0.3L912.8,481.5L912.8,481.5z M888.1,478h-0.2l-0.3-0.3l-0.1-0.1v-0.1l0.3-0.2l0.2,0.2v0.4L888.1,478
                L888.1,478z M909.1,478L909.1,478l-0.2-0.3v-0.3v-0.2l-0.1-0.1l0.2-0.2l0.1-0.1l0.1,0.1v0.2l0.1,0.2l-0.1,0.5L909.1,478L909.1,478z
                M884.9,476l-0.2,0.1l-0.1-0.1l-0.1-0.2v-0.2l0.2-0.1h0.1l0.1,0.1v0.2V476z M901.4,474.8l0.1,0.1l0.4-0.3l0.2,0.2l0.3,0.1l0.3,0.1
                l-0.1,0.4v0.2l0.1,0.2v0.3l-0.1,0.3l-0.3,0.4l-0.1,0.1h-0.1h-0.4l-0.1,0.2v0.2l0.2,0.3l0.2,0.3l-0.2,0.3l-0.3,0.2l-0.3,0.1l-0.5-0.1
                h-0.5l-0.1,0.1v0.2l-0.1,0.1l-0.1,0.1l-0.3,0.3l-0.3,0.2l-0.4,0.2l-0.1,0.1h-0.3l-0.3,0.1v0.1L898,480l-0.3,0.1l-0.3,0.2h-0.1h-0.7
                h-1h-0.3H895l-0.2-0.1l-0.3-0.4l-0.3-0.1h-0.3l-0.4,0.1h-0.1l-0.8-0.6l-0.2-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.2-0.3v-0.3l0.2-0.2
                l0.4,0.1h0.1l0.1-0.1h0.2l0.2,0.1l0.6-0.1l0.3,0.1l0.3,0.1h0.3h0.3l0.4-0.2h0.1h0.4l0.4-0.2l0.1-0.9l0.1-0.3l0.1-0.1h0.1l0.1,0.1
                l-0.2,0.2l-0.1,0.1v0.3l0.1,0.3l0.2,0.3h0.3l0.3-0.2h0.3l0.3,0.2h0.3l0.1-0.1h0.2h0.2l0.1-0.1l0.2-0.3l0.1-0.3l0.2-0.3l0.5-0.4
                l0.2-0.1h0.2h0.4l0.3-0.2v-0.3v-0.3l-0.3-0.8v-0.1v-0.1l0.1-0.1h0.3h0.3l0.1,0.1L901.4,474.8z M906.3,474.3L906.3,474.3h-0.2
                l0.1-0.1h0.1L906.3,474.3z M903.6,471.6l-0.1,0.2l-0.2-0.1l-0.1-0.2l0.1-0.1l0.2-0.1L903.6,471.6L903.6,471.6z M902,471v0.1h-0.1
                l-0.2-0.3v-0.1l0.1-0.1L902,471L902,471L902,471z M901.6,470.7L901.6,470.7l-0.1-0.2v-0.1l0.2,0.1v0.2H901.6z M879.6,473.5h0.4h0.4
                h0.1h0.1l0.1,0.1l0.1,0.2l0.2,0.2l0.3,0.1l0.3,0.2l0.3,0.2l0.2,0.2l0.2,0.2l0.3,0.1h0.4l1.2,1.2l0.1,0.2v0.8l-0.1,0.6l0.3,0.2
                l0.4,0.1l0.6,0.1l0.6,0.2l1.8,0.8l0.2,0.1h0.4h0.4h0.1l0.3,0.2l0.1,0.1l0.2,0.3l0.2,0.3l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.5v0.3
                l-0.1,0.1l-0.3,0.1l-1,0.1l-0.7-0.1l-0.5,0.3v0.1v0.1l0.4,0.7l0.2,0.6l0.2,0.2l0.3,0.2l0.3,0.2l0.2,0.3l0.5,0.5l0.3,0.2l0.3,0.1
                l0.5,0.4l0.1,0.2l0.2,0.5l0.1,0.3v0.1v0.1l0.5,0.3l0.1,0.1l0.2,0.7l0.2,0.3l0.3,0.1h0.3l0.9-0.2h0.1h0.2l0.1,0.1v0.3l-0.1,0.3v0.3
                l0.2,0.3l0.4,0.2l0.2,0.1l0.8,0.1l0.3,0.1l0.3,0.1l0.1,0.1l-0.1,0.1l-0.2,0.1h-0.2l-0.3,0.1v0.2l0.2,0.2l0.1,0.2l0.1,0.1l0.1,0.1
                l0.3,0.1l0.3,0.1l0.2,0.1l0.2,0.1h0.5l0.4,0.1l0.5-0.1l-0.4,0.2l-0.2,0.1l-0.5-0.1l-0.1,0.1l0.2,0.2l0.3,0.2l0.1,0.1l-0.1,0.1
                l-0.4,0.2h-0.2h-0.3l-0.5-0.1l-0.4-0.1l-0.1-0.2l-0.1-0.1l-0.3-0.2l-0.2-0.1h-0.3h-0.3l-0.5-0.1l-1.2-0.1l-0.3-0.1l-0.3-0.2h-0.2
                l-0.2,0.1h-0.5h-0.1l-0.3-0.2l-0.3-0.1h-0.1h-0.1L890,491h-0.3l-0.3-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.3l-0.2-0.3l-0.3-0.3l-0.7-0.4
                l-0.1-0.1l-0.3-0.4v-0.3l0.1-0.3l-0.1,0.1H887l-0.4-0.2l-0.2-0.2l-0.3-0.6l-0.2-0.3l-0.4-0.5l-0.1-0.3l-0.2-0.3l-0.1-0.1l-0.1-0.1
                l-0.1-0.2l-0.1-0.1l-0.6-0.2l-0.1-0.1H884h-0.5h-0.3l-0.5-0.2l-0.3-0.1h-0.3l-0.3-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.3h-0.3H881l-0.2-0.1
                l-0.2-0.1l-0.2,0.1l-0.1,0.2h-0.1h-0.1H880l-0.2,0.1l-0.1,0.1h-0.2l-0.5-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.1l0.2,0.3
                l0.6,1.1h-0.1h-0.1l0.1,0.2h-0.2h-0.2l-0.3-0.1h-0.3h-0.1l0.1,0.1l0.1,0.2l0.1,0.2l-0.5,0.1l-0.2,0.2l-0.5,0.1h-0.5l-0.3-0.1h-0.3
                h-0.3L875,486h-0.2l-0.1-0.2l-0.1-0.1l-0.1-0.1h-0.2l-0.2,0.1h0.3l0.1,0.1l0.1,0.2l0.2,0.2l0.3-0.1h0.6l0.6,0.3h0.1l0.1,0.1l0.3,0.3
                l0.2,0.2l0.2,0.3v0.1v0.3l-0.1,0.1l-0.4,0.2l-0.4,0.2l-0.6,0.3l-0.6,0.4l-0.3-0.1l-0.3-0.2l-0.1-0.1l-0.3-0.1h-0.2l-0.7,0.1h-0.7
                h-0.3l-0.3-0.1l-0.3-0.1h-0.3l-0.2,0.1h-0.2l-0.4-0.3l0,0v-0.6v-0.6v-0.6v-0.6v-0.6v-0.2v-0.6v-0.6v-0.6v-0.6V482l-0.2-0.2l-0.2-0.3
                v-0.4l0.2-0.4l0.1-0.3v-0.2v-0.6V479v-0.6v-0.6v-0.6v-0.3v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6v-0.6V470v-0.2l0,0h0.1
                h0.3h0.2l1.4,0.6l0.4,0.2l0.1,0.1h0.1h0.1l0.6,0.3l0.9,0.3l1,0.3l0.3,0.1h0.3l0.7,0.1l0.4,0.1l0.5,0.4l0.3,0.1l0.3,0.2l0.4,0.2
                l0.1,0.1L879.6,473.5L879.6,473.5z M904.4,476.1l-0.2,0.2l-0.1-0.2l-0.2-0.2l-0.1-0.2l-0.2-0.4v-0.2v-0.2v-0.2l-0.1-0.4l-0.2-0.4
                l-0.7-0.9l-0.2-0.2l-0.2-0.2l-0.2-0.1l-0.3-0.1h-0.1l-0.3-0.1l-0.2-0.2l-0.6-0.5l-0.3-0.1l-0.2-0.2l-0.9-0.6l-0.3-0.1h-0.3l-0.3-0.1
                l0.2-0.1v-0.2V470l0.5,0.3l0.5,0.3l0.1,0.2h0.2l0.5,0.2l0.3,0.2l0.3,0.2l0.3,0.3l0.6,0.2l0.1,0.1l0.3,0.4l0.4,0.3l0.1,0.2l1.8,1.5
                l0.3,0.4v0.3l-0.1,0.1l-0.2,0.2v0.3l-0.1,0.3L904.4,476.1L904.4,476.1z M897.3,470.2h-0.6h-0.2l-0.2-0.2l-0.2-0.3l-0.2-0.1h-0.1
                l0.4-0.2l0.4-0.1l0.6,0.2l0.1,0.1v0.1V470.2L897.3,470.2L897.3,470.2z M890.1,469.2l-0.1,0.1h-0.2h-0.1h0.2L890.1,469.2L890.1,469.2
                L890.1,469.2L890.1,469.2z M887.8,468.3l0.9,0.2h0.1v-0.1l0,0h0.1v0.1h-0.1h-0.1l-0.1,0.1l-0.4,0.3H888l-0.2,0.1h-0.4l-0.5-0.1
                l-0.1,0.1h-0.2l-0.2,0.1h-0.1v-0.2v-0.1l0.2-0.1v-0.2l0.2-0.1h0.3l0.3-0.1L887.8,468.3L887.8,468.3z M895.4,467.2v0.1l-0.2-0.1h-0.1
                l-0.4-0.3v-0.2l0.1-0.2h0.1l0.3,0.2L895.4,467.2L895.4,467.2z"/>
            <linearGradient id="PH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="239.8078" x2="0.1864" y2="239.8078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PH" class="stcustom0 st178" d="M817.1,403.6l-0.1,0.2h-0.1v-0.1l0.1-0.3h0.1V403.6L817.1,403.6z M817.4,404.8L817.4,404.8
                L817.4,404.8v-0.3l0.1-0.1h0.1L817.4,404.8L817.4,404.8z M816.1,407.8v0.3h-0.2l-0.2-0.2v-0.1v-0.1v-0.1L816.1,407.8z M815.4,408.6
                v0.2l-0.1-0.1L815.4,408.6l-0.1-0.1l0.1-0.1l0.1,0.1L815.4,408.6L815.4,408.6z M817.3,409.2l-0.2,0.2l-0.1-0.1l0.1-0.2V409l0.1-0.2
                l0.2-0.1l0.1,0.2L817.3,409.2L817.3,409.2z M815,410l0.4,0.2l1,0.5l0.3,0.1l0.4,0.1h0.3l0.3-0.1l0.1-0.1l0.2-0.3h0.2l0.1,0.1
                l0.1,0.2v0.2l-0.1,0.3l-0.2,0.2l-0.1,0.3l-0.1,0.9v0.3l0.1,0.3l0.2,0.4l0.1,0.1l0.3,0.1l0.1,0.1v0.2v0.2l0.2,0.1l0.1,0.1l-0.1,0.2
                l-0.1,0.2l-0.1,0.5l-0.6,1.1v0.2l-0.2,0.5l-0.5,0.1l-0.5,0.2l-0.3,0.2l-0.3,0.2l-0.1,0.3l0.1,0.1l0.1,0.2v0.2l-0.1,0.1l-0.3,0.3
                l-0.1,0.3l-0.1,0.1l-0.1,0.1v0.2l0.1,0.1l0.3,0.6l0.3,0.6l0.1,0.1v0.1l-0.2,0.2v0.3l0.1,0.3l0.3,0.7v0.2l0.1,0.2l0.1,0.1l0.2,0.1
                l0.5,0.2h0.2h0.2v-0.1h0.2v-0.1l-0.2-0.2v-0.1l0.1-0.1h0.1l0.3-0.2l0.3-0.2h0.4l0.4,0.1l0.3,0.1l0.2,0.2l0.2,0.3l0.2,0.3v0.2v0.2
                v0.2l0.1,0.1h0.4l0.2-0.3v-0.3l-0.1-0.1l0.1-0.1l0.1-0.1l0.2,0.1l0.2,0.2l0.6,0.2h0.1h0.1l0.3,0.1l0.1,0.1l-0.1,0.2h-0.6l-0.2,0.2
                l0.2,0.3l0.3,0.3l0.2,0.2l0.2,0.2v0.2l-0.1,0.2h0.2h0.2l0.3,0.2h0.1h0.1v0.7l-0.2,0.6l-0.3-0.1L823,427v-0.3l0.2-0.3l-0.1-0.1h-0.2
                l-0.2,0.1h-0.2h-0.3l-0.6-0.3h-0.3l-0.1-0.2v-0.3l-0.3-0.4v-0.1l-0.1-0.1l-0.7-0.4l-0.1-0.1l-0.2-0.3l-0.5-0.5h-0.1H819l-0.1,0.1
                l0.1,0.2v0.2v0.2v0.1l0.2,0.3v0.1l0.2,0.3v0.4l-0.2,0.2l-0.2-0.2v-0.2v-0.1l-0.3-0.4l-0.1-0.1l-0.5-0.4l-0.4-0.4l-0.8-0.4h-0.1h-0.1
                l-0.1,0.1l-0.4,0.2l-0.1,0.1v0.2l-0.3,0.2h-0.4l-0.3-0.1l-0.3-0.2h-0.2l-0.3-0.4H814l-0.3,0.3l-0.1-0.5v-0.6l0.1-0.2l0.1-0.1
                l0.7-0.6l0.1-0.2v-0.2l-0.1-0.2l-0.2-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.2l-0.1,0.3l0.1,0.5v0.3l-0.1,0.1h-0.2h-0.2L813,422l-0.1-0.3
                l-0.2-0.2l-0.1-0.3h-0.1h-0.2l-0.2-0.1l-0.1-0.4v-0.4l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-1.2v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2v-0.1
                V417v-0.1l0.1-0.1l0.2,0.1l0.1,0.1l0.2,0.1l0.1,0.1l0.3,0.3l0.1,0.1h0.3h0.2l0.1-0.1l0.1-0.1v-0.2l-0.2-0.5l-0.1-0.4V416v-0.3
                l0.2-0.6v-0.4v-0.5v-0.3v-0.2l-0.1-0.3v-0.3l0.4-1.5l0.1-0.3l0.1-0.3v-0.4l0.3-0.1l0.3-0.2h0.2h0.2l0.4-0.1L815,410L815,410z
                M817.6,420.5l0.1,0.1L817.6,420.5v0.1l-0.1,0.2l0.1,0.4l-0.1,0.3h-0.2h-0.1v-0.1v-0.1v-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l0.1-0.2h0.4
                L817.6,420.5L817.6,420.5z M818,423.3v0.1l-0.6-0.4v-0.1v-0.1v-0.1L818,423.3L818,423.3z M824.1,424.5l-0.1,0.2l-0.1-0.1h-0.1
                l-0.2,0.2l-0.3-0.2l-0.1-0.2l0.2-0.4v-0.5l0.1-0.1l0.1-0.1l0.1-0.1l0.2,0.4h0.1l0.2,0.2l-0.1,0.3v0.2L824.1,424.5L824.1,424.5z
                M812.6,424.1v0.2l-0.5-0.3v-0.1v-0.1l0,0l0.3,0.1L812.6,424.1L812.6,424.1z M817.2,424.7h0.2h0.1l0.3,0.2v0.1v0.2l-0.2,0.3v0.1
                l-0.1,0.1l-0.4-0.2l-0.1-0.1v-0.3l0.1-0.4L817.2,424.7L817.2,424.7z M813.9,424.9L813.9,424.9l0.5-0.1h0.2l0.1,0.2l0.2,0.1l0.1,0.1
                l0.2-0.1l0.2,0.2l0.2,0.3l0.2,0.2l0.2,0.2v0.1l-0.1,0.2v0.3v0.3l0.2,0.6l-0.1,0.2l-0.2,0.2l-0.1,0.2v0.1l-0.1,0.1v0.2h-0.1h-0.1
                l-0.1,0.1l-0.1,0.2H815h-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.2v-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.1-0.1v-0.1v-0.4
                l-0.2-0.5l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2l0,0h-0.1h-0.1v-0.2l0.1-0.1h0.2l0.5,0.1L813.9,424.9L813.9,424.9z
                M821.1,426.7l0.2,0.4l-0.3-0.3l-0.3-0.2l-0.3-0.3l-0.2-0.1l-0.1-0.1V426h0.2h0.1l0.5,0.6L821.1,426.7z M822.5,427.8v0.2l-0.1-0.1
                l-0.3-0.5l-0.1-0.2l0.1-0.1l0.2,0.2L822.5,427.8L822.5,427.8z M817.8,428.1l-0.2,0.7l-0.2-0.2l0.1-0.2l-0.1-0.1v-0.1V428l0.1-0.1
                v-0.5l0.3-0.1h0.1v0.1v0.2L817.8,428.1L817.8,428.1z M818.4,427.6L818.4,427.6l-0.1,0.1v-0.2l0.1-0.1l0,0V427.6L818.4,427.6z
                M822.3,428.3l0.5,0.3l0.4,0.6v0.4v0.2l-0.2-0.2l-0.4-0.3l-0.3-0.1h-0.1v-0.1L822,429v-0.1l-0.2-0.1l-0.2-0.3l-0.2-0.1l-0.2,0.1
                l-0.4,0.5l-0.4,0.3v-0.1l0.2-0.4l0.1-0.6l0.1-0.2l-0.1-0.3v-0.3l0.3,0.1l0.4,0.1l0.3,0.2v0.1L822.3,428.3L822.3,428.3z M826.6,427.6
                l0.2,0.2v0.2v0.2l0.1,0.1h0.2l0.2,0.1l0.2,0.2l-0.1,0.2v0.2l-0.2,0.3v0.5l0.1,0.2v0.2v0.2v0.1l0.2,0.5v0.2l-0.1,0.1v0.1h0.2l0.2,0.2
                l0.1,0.3v0.1l-0.2-0.2h-0.1l-0.6,0.1l-0.3-0.1h-0.2l-0.2-0.3l-0.2-0.1l-0.2-0.2l-0.2-0.4l-0.1-0.2l0.2-0.2l0.1-0.2V430h-0.2
                l-0.1-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.3-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.2-0.5l-0.1-0.5l0.8,0.1h0.8l0.9-0.1
                L826.6,427.6L826.6,427.6z M819.3,428.2l-0.1,0.1l-0.3-0.3l-0.2-0.1v-0.1l0.1-0.1h0.4l0.2,0.2v0.1L819.3,428.2L819.3,428.2z
                M812.2,428.6h0.2h0.1l0.1-0.2l0.1,0.2l0.2,0.2l-0.1,0.2h-0.2h-0.2l-0.2,0.1h-0.3l-0.2-0.2l-0.2-0.3l-0.1-0.1v-0.1l0.1-0.1l0,0v-0.1
                l0,0h0.1l0.1,0.1L812.2,428.6L812.2,428.6L812.2,428.6z M812,430l-0.2,0.1l-0.1-0.1v-0.1v-0.1l-0.2-0.5l0.2-0.1l0.1,0.1l0.1,0.1h0.1
                l0.1,0.2v0.1v0.1L812,430L812,430z M818.9,430.2l0.3,0.1l0.3-0.1h0.3l0.3,0.2l-0.1,0.1v0.1l0.6-0.3h0.2v0.3v0.2l-0.1,0.2l-0.1,0.3
                l-0.2,0.2l-0.2,0.2l-0.3,0.1l-0.1,0.1v0.1v0.2l-0.1,0.2l-0.3,0.1l-0.4,0.3l-0.9,0.2l-0.2,0.1l-0.2,0.2l-0.2,0.2h-0.1v-0.1v-0.1
                l0.1-0.4v-0.2l-0.1-0.2v-0.3l0.2-0.3l0.1-0.3V431l0.1-0.9V430l-0.1-0.1l-0.4-0.1l-0.1-0.1l0.1-0.2l0.1-0.1h0.2l0.2,0.1l0.6,0.2
                l0.3,0.2L818.9,430.2L818.9,430.2z M825.5,430.3l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.2v-0.2l0.2,0.1L825.5,430.3L825.5,430.3L825.5,430.3
                z M824.8,430.6h-0.4l-0.2-0.1l-0.2-0.4l0.2-0.1h0.2l0.2,0.1l0.2,0.3V430.6L824.8,430.6z M824.7,431l0.2,0.1h0.2l0.3-0.2l0.3,0.1
                l0.2,0.3l0.1,0.1v0.2v0.5l-0.1,0.5l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1v0.4l0.2,0.3v0.1v0.1h-0.3v0.1v0.1l-0.1-0.1l-0.2-0.3
                l-0.2-0.1v0.5l0.1,0.2v0.2l-0.3-0.2l-0.3-0.1l-0.1-0.1v-0.3v-0.1l-0.1-0.3l0.2-0.7v-0.1v-0.1l-0.1-0.3l-0.2-0.2h-0.1l-0.3,0.2
                l-0.2-0.1l-0.1-0.6l-0.1-0.6l-0.1-0.2l-0.1-0.2l0.1-0.1l0.1,0.1l0.2,0.2L824.7,431L824.7,431L824.7,431L824.7,431z M811.5,430.5
                l0.1,0.1l-0.1,0.2h-0.1l-0.1-0.1l-0.2-0.1v-0.1h0.1H811.5L811.5,430.5L811.5,430.5z M804.3,439.2l-0.3,0.2v-0.3l0.1-0.2l0.3-0.5
                l0.2-0.1l0.3-0.4l0.2-0.2l0.4-0.4l0.4-0.4h0.1h0.1h0.1l0.3-0.2l0.6-0.7l0.5-0.5l0.5-0.7l0.3-0.2l0.1-0.1l0.5-0.6l0.2-0.1l0.2-0.1
                l0.1-0.1l0.1-0.1l0.2-0.3l0.1-0.3v-0.2l-0.1-0.3l0.1-0.4l0.1-0.2l0.3-0.7l0.1-0.1l0.1,0.1v0.1l-0.1,0.3v0.2l0.1,0.2l-0.1,0.3
                l0.3,0.7l0.2,0.4v0.1l-0.3,0.3l-0.2,0.1l-0.3,0.1L810,434l-0.2,0.2l-0.1,0.3v0.1l-0.1,0.1l-0.7,0.2l-0.3,0.1l-0.1,0.1l-0.1,0.2
                l0.1,0.3l-0.6,1l-0.2,0.3l-0.2,0.2l-0.2,0.2l-0.3,0.1l-0.3,0.2l-0.2,0.3l-0.2,0.3l-0.3,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.1l-0.1,0.1
                l-0.1,0.2l-0.1,0.1h-0.2L804.3,439.2L804.3,439.2z M822.4,431.2l0.2,0.4h-0.2l-0.1-0.3L822.4,431.2L822.4,431.2L822.4,431.2z
                M821.3,436.4l-0.1,0.1v-0.2V436l0.2-0.9v-0.2l0.4-0.5l0.2-0.5l0.3-0.5v-0.3l0.3-0.5l0.3-0.7v-0.2l0.1-0.1v-0.2v-0.1l0.2-0.2
                l0.1,0.2l-0.1,0.3v0.2v0.1v0.3l-0.1,0.4l0.1,0.5l-0.1,0.5l-0.1,0.2l-0.2,0.2l-0.2,0.1l-0.2,0.3l-0.2,0.3v0.3l-0.4,0.9L821.3,436.4
                L821.3,436.4z M820.7,437.4h-0.2h-0.2l-0.1-0.1l-0.2-0.6l-0.3-0.1l-0.3-0.1l-0.2-0.1l-0.1-0.1l-0.4-0.6v-0.4l0.1-0.2l0.1-0.2h0.1
                h0.4h0.2l0.4-0.3v-0.1V434v-0.3l-0.1-0.3l0.1-0.1l0.1-0.1l0.1-0.3v-0.2v-0.2v-0.1l0.1-0.1l0.6-0.2h0.1l0.7,0.2l0.1,0.3v0.1l-0.1,0.3
                l-0.1,0.2l-0.2,0.4l-0.2,0.4l-0.1,0.6l-0.1,0.2l-0.2,0.4l-0.1,0.2v0.4v0.2v0.1l0.4,0.7v0.1v0.1l-0.1,0.2L820.7,437.4v0.2V437.4
                L820.7,437.4z M819.3,433.5L819.3,433.5L819.3,433.5l-0.3,0.1l-0.1-0.2l0.1-0.3l0.2-0.2l0.1-0.1h0.1h0.1l0.1,0.1v0.1L819.3,433.5
                L819.3,433.5L819.3,433.5z M824,433.1L824,433.1l0.1-0.3h0.1l0,0v0.1L824,433.1L824,433.1z M811.6,433.4l-0.3,0.1l-0.1-0.3l0.2-0.3
                l0.3,0.1l0.2,0.1l-0.1,0.1L811.6,433.4L811.6,433.4z M827.8,435L827.8,435l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.1l0.1-0.2v-0.3
                l0.2-0.2h0.1l0.1-0.2h0.1v0.1l-0.1,0.4l0.2,0.5l-0.1,0.3V435L827.8,435L827.8,435z M824.8,435.4v0.1h-0.2h-0.1l-0.2,0.3l-0.1,0.1
                l-0.7,0.1l-0.5-0.1l-0.2-0.1l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l0.4-0.2l0.1-0.2l0.2-0.2l0.5-0.1v0.1h0.1h0.1l0.2,0.2l0.3,0.1l-0.1,0.4
                l0.1,0.1L824.8,435.4L824.8,435.4z M826.7,434.9v0.1l-0.4-0.4l-0.1-0.3h0.1l0.2,0.1L826.7,434.9L826.7,434.9z M828.9,435.5
                L828.9,435.5l-0.2-0.2v-0.2l0.2-0.4l0.2,0.3v0.1v0.1l0.1,0.2l-0.1,0.1H828.9L828.9,435.5z M828.7,436.7l0.2,0.2h0.3v0.4v0.1l0.3,0.4
                v0.3l-0.2,0.3l-0.1,0.1l-0.2,0.2v0.1l0.1,0.1l0.3,0.1l0.2,0.2v0.4l0.2,0.3v0.2l-0.1,0.6v0.3l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.4v0.6
                v0.2l-0.1,0.2l-0.3,0.5l-0.4,0.4h-0.2l-0.1,0.1l0.1,0.3l-0.1,0.7l-0.1,0.5l-0.1-0.2l-0.1-0.3l-0.1-0.7l-0.1-0.3l-0.2-0.3l-0.1-0.3
                l-0.2-0.2l-0.2-0.6h-0.1l-0.2,0.2l-0.1,0.1v0.2l-0.1,0.2l-0.3,0.2l-0.2,0.3l-0.2,0.3l-0.1,0.3l0.1,0.2l0.2,0.1l0.2,0.2l0.1,0.1
                l0.2,0.7v0.7l-0.2,0.3l-0.4,0.6l-0.3,0.2l-0.2-0.1l-0.1-0.4v-0.1l0.1-0.3v-0.3l-0.1-0.1h-0.1l0,0l-0.3,0.4l-0.1,0.1h-0.2h-0.1
                l-0.8-0.3l-0.7-0.3l-0.5-0.3l-0.4-0.5l-0.1-0.4v-0.4l-0.2-0.6v-0.2v-0.2l0.2-0.3l0.2-0.2l0.1-0.1l0.1-0.1l0.1-0.2v-0.2l-0.1-0.1
                l-0.3-0.4l-0.3-0.2l-0.6-0.2l-0.1-0.1l-0.1-0.1h-0.2h-0.2l-0.2,0.1v0.1v0.1v0.1l-0.2,0.7l-0.3-0.2l-0.3-0.2l-0.1-0.1v-0.2v-0.1
                l-0.1-0.1l-0.1,0.2l-0.2,0.2h-0.2h-0.2l-0.1-0.1l-0.1-0.5l-0.2-0.1h-0.3l-0.3,0.3l-0.1,0.1l-0.1,0.2l-0.4,0.6l-0.2,0.5l-0.2,0.5
                l-0.1,0.2l-0.1,0.1h-0.2l-0.2-0.1l-0.2-0.3l0.1-0.3l0.2-0.2l0.2-0.2l0.2-0.8v-0.3v-0.1l0.3-0.4l0.3-0.2h0.1l0.6-0.1l0.2-0.1h0.4
                l0.3-0.1l0.2-0.2v-0.2v-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.1l0.1-0.1l0.1-0.1l0.2-0.2l0.2,0.1l0.2,0.1l0.3,0.1l0.3,0.2
                l0.2,0.3v0.2v0.5l-0.1,0.1l-0.3,0.2h0.1l0.4-0.2l0.2-0.1l0.5-0.1l0.1-0.1l0.1-0.1l0.2-0.3l0.1-0.3l0.1-0.1l0.1-0.1h0.1l0.5,0.2
                l0.3-0.1l0.1-0.4l0.1-0.5l0.1-0.1l0.2-0.1h0.2l0.3,0.2l0.3,0.1l0.1-0.1l0.1-0.3h0.1l0.4,0.1l0.3-0.1l0.1-0.4l-0.1-0.4l-0.3-1.1
                l0.2-0.2h0.1l0.3,0.3l0.7,0.4l0.2,0.2L828.7,436.7L828.7,436.7z M828.6,436L828.6,436l-0.1-0.1l0.1-0.3l0.1-0.1l0.1,0.2L828.6,436
                L828.6,436z M822.3,437v0.3l-0.3,0.1l-0.2-0.1l-0.1-0.2V437h0.1l0.3-0.2h0.1L822.3,437z M825.4,437.2l-0.1,0.2l-0.3-0.1l-0.1-0.1
                v-0.1h0.2h0.1L825.4,437.2L825.4,437.2L825.4,437.2z M804.5,439.8l-0.2,0.1v-0.2v-0.2h0.1l0.1,0.1L804.5,439.8L804.5,439.8z
                M803.7,440.8l-0.1,0.2l-0.2-0.2v-0.3l0.1-0.1l0.2-0.1V440.8z M820.1,442.1L820.1,442.1v0.1l-0.3,0.1h-0.1v-0.2l0.1-0.1l0.1,0.1
                H820.1L820.1,442.1L820.1,442.1z M828.1,443.4v0.2l-0.2-0.4l-0.1-0.1l0.1-0.3l0.2,0.2V443.4L828.1,443.4z M817.7,444.9h-0.3h-0.1
                l-0.2-0.3v-0.1l-0.2-0.1l0.1-0.1h0.2l0.4-0.2l0.6,0.3l0.1,0.1l-0.2,0.1l-0.1,0.3L817.7,444.9L817.7,444.9z M815.1,445.9l0.2,0.2
                l0.2-0.1l0.3,0.1l0.1,0.1v0.1l-0.3,0.2l-0.2-0.2l-0.4,0.1l-0.2-0.1l-0.2,0.1l-0.2-0.2l0.1-0.2l0.4-0.2L815.1,445.9z M812.6,448.1
                l-0.1,0.2l-0.1,0.1h-0.1l-0.1-0.1v0.1H812l-0.2,0.2l-0.2,0.1h-0.2v-0.2l0.4-0.3l0.3-0.1l0.2-0.2h0.1L812.6,448.1L812.6,448.1
                L812.6,448.1z"/>
            <linearGradient id="PK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="292.8578" x2="0.1864" y2="292.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PK" class="stcustom0 st179" d="M685.1,351.6l0.1,0.1l0.2-0.1l0.3-0.1h0.3l0.6,0.2l0.1,0.1l0.1,0.5h0.1l0.3-0.1l0.3,0.1l0.3,0.2
                l0.2,0.2l0.1,0.2l0.1,0.3l0.1,0.2l0.1,0.3v0.7l-0.1,0.1l-0.1,0.2v0.1l0.1,0.1l0.2,0.1h0.2l0.1,0.1l0.1,0.4l0.1,0.1h0.2l0.4-0.1
                l0.3-0.1h0.1v0.4l0.2,0.1l0.3,0.2l0.1,0.1l0.3,0.2l0.6,0.2l0.6,0.2l0.4,0.1h0.2h0.1h0.4l0.2-0.1l-0.2,0.1l-0.4,0.2l-0.4,0.3
                l-0.4,0.2l-0.3,0.2l-0.3,0.2l-0.1,0.2l-0.1,0.2l-0.3,0.2l-0.3,0.1l-0.1,0.1v0.1l-0.1,0.2l-0.3,0.2h-0.2l-0.2-0.1l-0.8,0.3h-0.4
                l-0.3,0.2l-0.2,0.2l-0.4,0.2h-0.3l-0.4-0.1l-0.5-0.2l-0.2-0.1H686h-0.5l-0.5-0.1l-0.5-0.1l-0.3-0.1l-0.6-0.1l-0.4,0.2l-0.3,0.1
                l-0.3,0.1l-0.2,0.4l-0.1,0.1l-0.1,0.2v0.1v0.2l0.3,0.1l0.1,0.2v0.2l-0.1,0.2l-0.1,0.1v0.1l0.1,0.1l0.1,0.1l0.5,0.1h0.3h0.1v0.1
                l-0.1,0.2l-0.4,0.2l-0.2,0.2l-0.1,0.2v0.2l0.1,0.1l0.2,0.1l0.2,0.2l0.1,0.1v0.2l-0.1,0.2l-0.2,0.3l-0.2,0.2v0.1v0.1l0.1,0.2l0.2,0.2
                l0.3,0.2l0.2,0.1h0.1l0.1,0.2v0.2l-0.1,0.2l0.1,0.1h0.4l0.3,0.1l0.1-0.1h0.1l-0.1,0.5v0.3l0.1,0.1l0.3,0.1h0.6l0.3,0.1l0.4,0.2
                l0.2,0.2l0.1,0.1v0.2l-0.2,0.2l-0.3,0.1l-0.2,0.1l-0.9,0.5l-0.3,0.2l-0.2,0.2l-0.1,0.2v0.2l0.2,0.6v0.2l-0.2,0.7v0.2l0.1,0.2
                l0.2,0.1v0.1v0.1l-0.5,0.2l-0.4,0.2h-0.1l-0.3,0.4l-0.6,0.8l-0.3,0.3v0.1v0.1l0.1,0.2v0.2l-0.1,0.2l-0.2,0.2l-0.4,0.2l-0.5,0.2
                l-0.2,0.1l-0.2,0.5l-0.2,0.5l-0.1,0.2l-0.3,0.6l-0.5,0.9l-0.1,0.2l-0.8,0.4l-0.8,0.5l-0.1,0.2l-0.2,0.4l-0.2,0.5l-0.1,0.2l-0.5,0.5
                l-0.2,0.4l-0.1,0.3l-0.4,0.1l-0.5,0.1h-0.7l-0.3,0.1l-0.9,0.4h-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.3l-0.2-0.1l-0.2-0.1h-0.2
                l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.3,0.5l-0.4,0.7l-0.5,0.5l-0.2,0.2l-0.1,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.5l-0.1,0.5v0.1
                l0.1,0.1l0.3,0.1l0.4,0.2l0.5,0.1h0.4l0.2,0.1l0.1,0.1v0.1v0.4v0.4l-0.2,0.4v0.3l0.1,0.2l0.5,0.6l0.2,0.1h0.3h0.2l0.2-0.1h0.1
                l0.1,0.1v0.1v0.6l0.1,0.3l0.3,0.4l0.2,0.4l0.2,0.5l0.2,0.4l0.1,0.2l-0.1,0.1l-0.1,0.1v0.1v0.2v0.1l0.1,0.1h0.1v0.1l-0.2,0.1h-0.2
                l-0.1,0.1l-0.2,0.2h-0.1h-0.1h-0.2l-0.2-0.1l-0.1-0.2v-0.2l-0.1-0.1H673l-0.6,0.2l-0.5,0.2l-0.1,0.1l-0.1,0.1l-0.2,0.1h-0.4h-0.2
                l-0.2-0.2l-0.2-0.1h-0.3h-0.6h-0.3l-0.2-0.1h-0.2l-0.2-0.1l-0.1,0.1h-0.1l-0.3,0.8h-0.1H668h-0.1v0.1v0.9h-0.4h-0.3h-0.3l-0.3,0.1
                l-0.1,0.1l-0.2,0.1v0.2l-0.1,0.1l-0.1-0.2l-0.1-0.1l-0.1,0.1h-0.1l-0.3-0.2l-0.1,0.2l-0.4,0.1l-0.1-0.2V394l-0.2,0.1l-0.2-0.2
                l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.3v-0.3v-0.3l-0.3-1.2l-0.2-0.1l-1.1-0.2l-0.1-0.2l0.1-0.6v-0.4l-0.4-0.5l-0.1-0.3
                l-0.3-0.3l-0.2,0.2h-0.3l-0.2,0.1l-0.1,0.2h0.6l0.1,0.1l0.2,0.1h-0.2l-0.2-0.1h-0.3l-1,0.1l-0.6,0.2l-0.8-0.1l-1,0.2h-0.8l-0.3,0.4
                l-0.2-0.1l-0.1-0.1l-1.1-0.3l-0.1-0.1l-0.2-0.1l-0.2,0.2h-0.1l-0.6-0.1l-0.5,0.1l-0.2,0.2v0.3l-0.6-0.1l-0.3-0.1l-0.4,0.1l-1-0.1
                h-0.3l-0.4,0.2l-0.2,0.1l-0.2,0.1l-0.2-0.2l-0.1-0.1l-0.1,0.1l-0.2,0.2l-0.5,0.1H648l-0.5-0.1h0.1l0.1-0.3l0.1-0.9l0.1-0.3v-0.2
                v-0.1l0.2-0.2v-0.1l0.1-0.5l0.1-0.5l0.1-0.2l0.1-0.1l0.6-0.2l0.1-0.2h0.3l0,0v-0.2l0.1-0.2l0.2-0.2l0.2-0.1l0.6-0.1l0.3-0.1h0.1h0.9
                l0.2-0.1l0,0l0.1-0.5l0.2-0.1l0,0l-0.1-0.4v-0.2l0.2-0.1v-0.1l-0.1-0.2l-0.2-0.1h-0.1l-0.7,0.1h-0.3l-0.1-0.1l0,0v-0.1v-0.2l0.1-0.3
                V383l-0.1-1l-0.1-0.6l0.1-0.6v-0.1l0,0h-0.1l-0.4,0.1l-0.4-0.4l-0.2-0.2l-0.6-0.2H649l-0.4-0.2l-0.4-0.4l-0.4-0.4l-0.2-0.3l-0.2-0.4
                l-0.5-0.8v-0.2l-0.1-0.1l-0.5-0.5l-0.3-0.4l-0.5-0.6l1.1,0.4l0.8,0.3l1.3,0.4l1,0.3l0.3,0.1l3.1-0.3l1.1,0.2l0.4,0.1l0.1-0.1
                l0.2-0.1l0.3-0.1l0.4-0.1l0.4-0.1h0.5h0.3h0.3h0.5l0.2-0.1l0.8-0.2l0.5-0.2l0.8-0.2l0.6-0.2l0.2-0.1l0.2-0.2l0.1-0.2l-0.2-0.2v-0.2
                l0.1-0.3l0.1-0.4v-0.6v-0.3l0.2-0.6l0.1-0.4l0.3-0.2l0.2-0.1l0.1-0.1l0.1-0.1l0.3-0.5l0.3-0.2l0.3-0.1h0.3l0.2,0.2l0.5,0.1l0.5-0.1
                l0.4-0.1l0.2-0.1l0.2-0.1v-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2l0.1-0.1h0.3l0.8-0.4l0.3-0.3l0.1-0.1h0.2l0.3,0.1h0.3l0.2-0.1h0.2
                l0.2,0.1l0.1,0.2l0.2,0.2h0.2l0.3-0.1l0.3-0.2l0.3-0.3l0.3-0.3l-0.1-1v-0.6l0.1-0.3l0.2-0.2l0.1-0.3v-0.3l0.1-0.2l0.1-0.6l0.2-0.1
                l0.4-0.1l0.6-0.1l0.5-0.3l0.5-0.3l0.1-0.3l-0.2-0.3l-0.2-0.6l-0.2-0.3l-0.5-0.6l0.1-0.4l0.3-0.2l0.7,0.3h0.2h0.3h0.7l0.5-0.1
                l0.6-0.2l0.1-0.2v-0.3v-0.2V361l-0.2-0.2l-0.1-0.2v-0.1l0.1-0.1l0.1-0.2l0.1-0.3l0.3-0.3l0.2-0.3l0.2-0.1l0.3-0.2l0.2-0.3l0.1-0.2
                l0.2-0.2l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.1l0.1-0.1v-0.2v-0.3l-0.1-0.2l-0.1-0.4l-0.2-0.4l-0.1-0.2l-0.2-0.2l-0.3-0.2
                l-0.1-0.1l0.1-0.3l0.2-0.2l0.4-0.4l0.2-0.3l0.2-0.2h0.3h0.2l0.1-0.2l0.3-0.2l0.5-0.3l0.2-0.2l0.3-0.1h0.2l0.3-0.1l0.3-0.1l0.3-0.1
                h0.4l0.6-0.1l0.3-0.1h0.8h0.9h0.1l0.4,0.1l0.3,0.1h0.1l0.4-0.2l0.7-0.3l0.3-0.1h0.2h0.3l0.2,0.1L685.1,351.6z"/>
            <linearGradient id="PL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="373.9077" x2="0.1864" y2="373.9077" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PL" class="stcustom0 st180" d="M530.1,280.7l0.1,0.1l0.8,0.1l0.8,0.1l1.3,0.1l1.3,0.1l1.4,0.1l1.5,0.1h1.6h0.1l0.2-0.2h0.2l0.2,0.1
                l0.1,0.1v0.1v0.1h0.1l0.2,0.1l0.3,0.2l0.2,0.2l0.2,0.3l0.1,0.3v0.3v0.2v0.1l0.3,1.6l0.5,1.5l0.2,0.7l0.1,0.4l0.1,0.6v0.4v0.2v0.3
                l-0.2,0.2l-1,0.5l-0.2,0.2l-0.3,0.4l-0.3,0.4l-0.1,0.1v0.1l0.1,0.1l0.4,0.2l0.4,0.2l0.1,0.1l0.3,0.2l0.1,0.2l0.1,0.1v0.3l-0.1,0.4
                l0.1,0.3l-0.1,0.2l-0.1,0.2v0.4l0.2,0.5l0.2,0.3l0.1,0.2l-0.1,0.2v0.2l0.1,0.2l0.4,0.6l0.2,0.6l0.1,0.2l0.3,0.3v0.1l-0.1,0.1h-0.1
                h-0.1l-0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.5v0.4l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.7,0.1l-0.2,0.2l-0.4,0.4l-0.3,0.2l-0.4,0.4l-0.6,0.8
                l-0.2,0.3l-0.2,0.3l-0.5,0.7l-0.2,0.3v0.2l0.2,0.6v0.3v0.2l-0.1,0.2v0.1l0.2,0.1l0.2,0.2v0.1v0.1L539,305l-0.3-0.1l-0.3-0.2h-0.1
                h-0.2l-0.8-0.3l-0.5-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.5-0.2l-0.2-0.1l-0.8-0.1h-0.4l-0.2,0.1h-0.2l-0.2,0.3l-0.2,0.1h-0.2
                l-0.2-0.1l-0.2-0.2l-0.3-0.1h-0.2h-0.2h-0.1h-0.1h-0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.4l-0.4-0.2l-0.1,0.1h-0.2
                l-0.1-0.1v-0.1l0.1-0.1v-0.2v-0.2l-0.1-0.1h-0.2h-0.1v-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.1l-0.2,0.1l-0.2,0.2l-0.1,0.1
                l-0.3,0.5h-0.5v-0.2l-0.1-0.2l-0.3-0.1v-0.1l-0.1-0.3l-0.6-0.6h0.1v-0.1v-0.2l-0.1-0.1l-0.5-0.1l-0.1,0.1l-0.1-0.1l-0.2-0.1
                l-0.3-0.1v-0.1l-0.1-0.1h-0.1v0.1l-0.1,0.1l-0.3,0.1h-0.1l-0.1-0.1L525,301l-0.2-0.2l-0.2-0.1l-0.1-0.1v-0.1l0.3-0.2l0.1-0.2v-0.3
                l0,0l-0.1,0.1l-0.3,0.1h-0.3h-0.1l-0.7-0.5l-0.5-0.2h-0.3v0.1l0.1,0.3l0.2,0.4v0.1l-0.3,0.2l-0.2,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.1
                h-0.1l-0.1-0.1l-0.3-0.6l-0.4-0.4v-0.1h-0.1l-0.2-0.1l-0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.1-0.1V299l0.1-0.1l0,0l-0.1-0.2l-0.2-0.2
                l-0.6,0.1l-0.2,0.1l-0.1-0.1l-0.1-0.2h-0.2l-0.2-0.1l-0.3-0.4H519l-0.5-0.2h-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.2l-0.1-0.3l-0.4-0.2
                l-0.4-0.1l0,0v0.3v0.2l-0.2,0.1h-0.2v-0.1l0.3-0.6l0.1-0.4l0.1-0.7l-0.2-0.6v-0.3l-0.1-0.1l-0.5-0.3V294l0.1-0.4v-0.2l-0.1-0.2
                l-0.2-0.3l-0.1-0.3l0.2-0.3l0.1-0.2l0.1-0.3l0.1-0.2l0,0l-0.1-0.1v-0.2V291l-0.1-0.2l-0.2-0.1l-0.1-0.2l-0.1-0.2V290l0.1-0.4
                l-0.3-0.5l-0.7-0.6l-0.3-0.4v-0.3l0.2-0.2l0.3-0.2l0.2-0.4l0.1-0.4v-0.1v-0.3l-0.3-1.3l-0.1-0.3v-0.4v-0.1l0.6,0.3l0.3,0.2v-0.2
                l-0.1-0.1v-0.2v-0.3l-0.6-0.2l-0.4-0.1v-0.2v-0.1l0.1,0.1h0.4l0.9-0.4l1.6-0.6l1.7-0.5l0.4-0.1l0.4-0.1l0.1-0.2l0.1-0.1l0.2-0.4
                l0.5-0.6l0.9-0.2l0.3-0.3l0.7-0.4l1.6-0.4l0.7-0.1h0.7l0.6,0.3l0.6,0.4l0.1,0.2l-0.3-0.2l-0.5-0.4h-0.2l0.4,1.1l0.2,0.4l0.5,0.3
                l0.4,0.1l1.2-0.2l0.4-0.2L530.1,280.7z"/>
            <linearGradient id="PM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="351.7079" x2="0.1864" y2="351.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PM" class="stcustom0 st181" d="M317.2,314.5l-0.2,0.2l-0.1-0.1v-0.1l0.1-0.3v-0.1l-0.1-0.5v-0.1l0,0l0.2,0.1v0.1L317,314l0.1,0.2
                L317.2,314.5L317.2,314.5L317.2,314.5z M317.5,314.8L317.5,314.8h-0.3l0.1-0.1h0.1h0.1l0,0L317.5,314.8L317.5,314.8L317.5,314.8z"/>
            <linearGradient id="PN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="133.0079" x2="0.1864" y2="133.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PN" class="stcustom0 st182" d="M115.1,533L115.1,533L115.1,533L115.1,533l-0.1-0.2l0.1-0.1h0.1L115.1,533L115.1,533z"/>
            <linearGradient id="PR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.5578" x2="0.1864" y2="254.5578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PR" class="stcustom0 st183" d="M289.5,410.5L289.5,410.5l0.2,0.1l-0.1-0.1h0.1l0.5,0.1l0.3,0.1l0.4,0.1v0.4l-0.3,0.2l-0.2,0.2
                l-0.1,0.2l-0.4,0.2l-0.5,0.1h-0.3H289h-0.1h-0.2l-0.3-0.1h-0.2h-0.5l-0.2,0.1h-0.2h-0.2H287h-0.4l-0.2-0.1l0.1-0.5v-0.2l-0.1-0.2
                l-0.1-0.1l-0.1-0.1l0.1-0.1l0.1-0.1v-0.2h0.1h0.1l0.7,0.1h1.8L289.5,410.5L289.5,410.5L289.5,410.5z M291.5,411.5h-0.2h-0.1v-0.1
                l0.3-0.1h0.3h0.2l0,0L291.5,411.5z M284.6,411.6L284.6,411.6L284.6,411.6L284.6,411.6L284.6,411.6l-0.2-0.1l0,0l0,0l0,0h0.2l0,0l0,0
                l0,0l0,0V411.6L284.6,411.6L284.6,411.6L284.6,411.6L284.6,411.6L284.6,411.6z"/>
            <linearGradient id="PS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="296.9579" x2="0.1864" y2="296.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PS" class="stcustom0 st184" d="M571.5,370.8l-0.3,0.3l-0.1-0.3v-0.1l0.5-0.5l0.3-0.3l0.1,0.1v0.1l-0.5,0.5V370.8z M574.8,368.6
                l0.1,0.7l-0.2,0.3l-0.1,0.4v0.3h-0.1l-0.4,0.2l-0.5,0.2h-0.5l-0.1-0.1v-0.1l0.2-0.5l0.1-0.2l0.2-0.2l0.3-0.2l0.1-0.1v-0.1l-0.2-0.1
                l-0.2-0.1l-0.2,0.1h-0.1V369l0.1-0.1v-0.2v-0.3v-0.3v-0.2l0.1-0.4v-0.2l0.2-0.4l0.4-0.2l0.3,0.1h0.2h0.1v0.1l0.2,0.2h0.2l0.1,0.5
                L575,368L574.8,368.6L574.8,368.6z"/>
            <linearGradient id="PT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="316.1579" x2="0.1864" y2="316.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PT" class="stcustom0 st185" d="M426.8,365.6l0.4,0.2l0.4-0.1l0.4,0.2l0.2,0.1l-0.2,0.2l-0.2,0.2h-0.5l-0.4-0.2l-0.2-0.1V366
                L426.8,365.6L426.8,365.6z M404.9,351.7L404.9,351.7h-0.2h-0.2l-0.1-0.2l0.1-0.1h0.2l0.1,0.1L404.9,351.7L404.9,351.7z M403.1,348.6
                h0.2l0.9-0.1h0.2v0.3l-0.2,0.1l-0.5,0.1l-0.8-0.2l-0.3-0.2v-0.2v-0.1l0.2-0.1L403.1,348.6L403.1,348.6z M396.1,346.4l0.2,0.1h-0.4
                l-0.1,0.1l-0.3-0.1h-0.3l-0.2-0.2v-0.2l0.1-0.1h0.3L396.1,346.4L396.1,346.4z M394.7,346.2h-0.3l-0.2-0.3l0.4-0.1l0.1,0.1l0.1,0.1
                l0.1,0.1L394.7,346.2z M397.1,346.1H397l-0.7-0.3l-0.3-0.1l-0.3-0.3l1,0.4L397.1,346.1L397.1,346.1z M399.1,345.7L399.1,345.7
                l-0.6-0.1l-0.2-0.1l-0.1-0.2l0.1-0.1h0.3h0.4l0.2,0.2v0.2L399.1,345.7L399.1,345.7z M387.7,343l-0.1,0.2l-0.2-0.1l-0.1-0.1l0.1-0.4
                l0.2-0.1l0.2,0.1L387.7,343L387.7,343z M452.2,334.1L452.2,334.1h0.2l0.3-0.1l0.2-0.1h0.1l0.6-0.1l0.1,0.1l0.1,0.1l0.3,0.1h0.3
                l0.4-0.1l0.2-0.1v-0.1v-0.1v-0.1h0.1l0.1,0.1l0.2,0.1h0.5l0.1-0.1h0.2l0.2,0.1h0.2l0.1,0.1v0.1v0.3v0.3v0.1h0.2h0.3l0.2,0.1l0.2,0.2
                l0.1,0.2v0.1l-0.1,0.1l-0.1,0.2l-0.3,0.3l-0.5,0.3l-0.3,0.3l-0.2,0.4l-0.3,0.2l-0.1,0.1v0.1l0.2,0.5l0.1,0.4v0.5v0.1v0.5v0.1v0.1
                l0.1,0.1v0.1l-0.1,0.2l-0.3,0.2l-0.2,0.2v0.1v0.1l0.3,0.3l0.1,0.1v0.3l-0.2,0.5l-0.2,0.3l0,0l-0.2,0.1h-0.9l-0.2,0.1v0.1l0.2,0.4
                l0.2,0.2h0.1l0.1,0.5l0.4,0.7l0.4,0.1l0.1,0.2v0.3l-0.1,0.3l-0.2,0.3l-0.3,0.2l-0.2,0.2v0.2l-0.1,0.3l-0.1,0.2v0.2l0.7,1h0.4l0,0
                l-0.1,0.2l-0.1,0.3l-0.5-0.2l-0.3,0.1l-0.3,0.4l-0.2,0.4l-0.2,0.2l-0.2,0.5v0.2l0.1,0.3l0.2,0.9h-0.2l-1,0.6h-0.3l-0.6-0.2l-1-0.1
                l-0.3-0.1l-0.4,0.2h-0.3l-0.2,0.2l-0.2-0.1l0.2-0.5l0.3-0.9v-0.6l0.1-0.5l-0.1-0.5l-0.2-0.3l0.2-0.8v-0.4l-0.2-0.5l0.6,0.1l-0.2-0.2
                l-0.2-0.1h-0.2H450l-0.5,0.2l-0.3,0.1h-0.1v-0.3l-0.1-0.4l0.2-0.1h0.2l0.2-0.2l0.1-0.2l-0.1-0.4l0.2-0.3l0.4-0.3H450l-0.2,0.2
                l-0.4,0.7l-0.1,0.3l-0.3,0.1l-0.3,0.1h-0.1l-0.2-0.1v-0.2v-0.2l0.1-0.4v-0.5l0.2-0.5v-0.1v-0.2l0.2-0.2l0.2-0.1l0.3-0.4l0.4-1
                l0.5-1.1v-0.1l-0.1-0.1v-0.3l0.3-1.3l0.1-0.2l0.1-0.4v-0.6l0.1-0.4v-0.2v-0.3l-0.2-0.5l-0.2-1V335l0.2-0.2h-0.3l-0.1-0.2v-0.3
                l0.3-0.4l0.3-0.3l0.3-0.2l0.1-0.1l0.6-0.2l0.2-0.1h0.1v0.1l0.1,0.2l0.1,0.1v0.1l-0.2,0.3v0.1L452.2,334.1L452.2,334.1L452.2,334.1z"
                />
            <linearGradient id="PW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="217.9579" x2="0.1864" y2="217.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PW" class="stcustom0 st186" d="M852.8,442.2l-0.2,0.1l-0.1-0.2v-0.2l0.1-0.2l0.1-0.1l0,0l0.1-0.2v0.1l-0.1,0.5l-0.1,0.2H852.8
                L852.8,442.2z M843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4L843.2,454.4z"/>
            <linearGradient id="PY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="136.5079" x2="0.1864" y2="136.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="PY" class="stcustom0 st187" d="M311.9,520.2l0.1,0.2v0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.1v0.2v0.2v0.2l0,0h0.1l0.1,0.2v0.1v0.1v0.1
                v0.1v0.1l0.1,0.1l0.1,0.2v0.4l-0.1,0.2l-0.1,0.2v0.1l0.1,0.2l-0.1,0.2l-0.1,0.2v0.2v0.1v0.2v0.1v0.2v0.1v0.2v0.2l-0.1,0.2v0.1v0.1
                l0.1,0.2l0.2,0.1h0.2l0.2-0.1h0.1l0.2,0.1l0.2,0.2h0.3h0.2h0.2l0.3-0.1l0.3,0.1l0.3,0.1l0.3,0.1h0.3h0.2l0.2-0.1h0.2l0.1-0.2
                l0.1-0.1l0.1-0.1l0.2-0.1h0.1l0.1,0.3l0.2,0.1l0.1,0.1l0.2,0.1h0.3h0.2l0.2,0.1h0.2l0.1,0.1l0.1,0.2v0.3l0.1,0.2l0.2,0.1l0.1,0.2
                v0.2l-0.1,0.2v0.2l0.1,0.2v0.2l0.1,0.2l0.1,0.2v0.3v0.2l0.1,0.1v0.2v0.1v0.2v0.2l0.1,0.1l0.2,0.2v0.3v0.2l0.1,0.3l0.1,0.1h0.2h0.3
                l0.3-0.1l0.3-0.1l0.2-0.1l0.3-0.2l0.3-0.1l0.1-0.1l0.1-0.1l0.3,0.1l0.3,0.2l0.2,0.2l0.4,0.2l-0.1,0.1l-0.1,0.2v0.2l0.1,0.3l-0.1,0.7
                l-0.3,1l-0.1,0.6v0.2l-0.1,0.3l-0.4,0.7v0.4v1.3l-0.1,0.9l-0.2,0.7l-0.2,0.4h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1
                l-0.2,0.1h-0.4l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.1,0.2v0.3l-0.1,0.2l-0.2,0.2H319l-0.2-0.2l-0.3-0.1l-0.3-0.1h-0.3l-0.2,0.1
                l-0.2,0.2l-0.2,0.3l-0.2,0.1l-0.2-0.2l-0.3-0.1l-0.3,0.1H316l-0.2-0.1h-0.3l-0.4,0.1l-0.8-0.1l-1.2-0.4l-1-0.1l-1.2,0.1l-0.1-0.4
                l0.1-0.2l0.2-0.2l0.1-0.2l0.1-0.2l0.1-0.1l0.2-0.1l0.1-0.1v-0.1v-0.1l0.1-0.1l0.1-0.1v-0.2v-0.1l0.1-0.1V539v-0.4v-0.3l0.1-0.2
                l0.1-0.1h0.1v-0.1v-0.1l0.1-0.1l0.4-0.3l0.1-0.1v-0.1l0.1-0.2l0.2-0.4l0.1-0.2v-0.1l0.1-0.1l0.3-0.2l0.2-0.2v-0.2l-0.1-0.2l-0.2-0.2
                l-0.5-0.6l-0.4-0.3l-0.5-0.2l-0.3-0.1l-0.2,0.1l-0.2-0.1l-0.2-0.2l-0.3-0.2l-0.6-0.2l-1.3-0.7l-0.5-0.3l-0.2-0.2l-0.5-0.4l-0.8-0.5
                l-0.6-0.3h-0.4l-0.7-0.2l-0.9-0.3l-0.5-0.3l-0.1-0.3l-0.3-0.3l-0.5-0.3l-0.3-0.2V529l-0.2-0.1l-0.3-0.2l-0.3-0.3l-0.4-0.4l-0.4-0.6
                l-0.4-0.8l-0.4-0.5l-0.5-0.3l-0.2-0.2v-0.1l-0.1-0.1l0.1-0.2l0.2-0.6l0.2-0.9l0.3-0.9l0.3-1v-0.7v-0.8l0.4-0.6l0.3-0.4l0.3-0.4
                l0.3-0.7l0.2-0.5l0.7-0.1l1.2-0.3l0.6-0.1l1.2-0.3l1.2-0.3h1.3h1.3l1,0.6l0.7,0.5l0.8,0.5l0.1,0.1l0.1,0.4L311.9,520.2z"/>
            <linearGradient id="QA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="276.1078" x2="0.1864" y2="276.1078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="QA" class="stcustom0 st188" d="M619,392l-0.3,0.1l-0.2,0.1h-0.2h-0.2l-0.1-0.1l-0.2-0.3l-0.1-0.3l0.1-0.2v-0.1l-0.2-0.9l-0.1-0.7
                v-0.1l0.1-0.2l0.2-0.4l0.1-0.3l0.3-0.8l0.3-0.3l0.4-0.2l0.4,0.4l0.4,0.3l0.1,0.4l-0.1,0.3l-0.1,0.5l0.1,0.2v0.2l0.1,0.3l0.1,0.4v0.3
                l-0.1,0.3l-0.2,0.2l-0.3,0.7l-0.1,0.1L619,392z"/>
            <linearGradient id="RE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="142.7579" x2="0.1864" y2="142.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="RE" class="stcustom0 st189" d="M631.7,523.8l-0.4,0.1H631l-0.5-0.3l-0.1-0.2l-0.2-0.5v-0.2l0.2-0.3l0.4-0.1h0.4l0.2,0.1l0.2,0.3
                l0.3,0.3v0.4L631.7,523.8z"/>
            <linearGradient id="RO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="347.8579" x2="0.1864" y2="347.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="RO" class="stcustom0 st190" d="M554.3,320.1l0.3,0.4l0.4,0.2l0.9,0.2h0.1l0,0l-0.1-0.1v-0.1v-0.1h0.1l0.2,0.1l0.4-0.1l0.5-0.3
                l0.5-0.1l0.5,0.2l0.2,0.2l0.2,0.2v0.3v0.2l-0.1,0.7l-0.1,0.3l-0.1,0.3l-1.4,0.3l0.1-0.2v-0.3l-0.1-0.2l0.1-0.2l-0.3-0.1l-0.1,0.1
                l-0.1,0.2l0.1,0.4l-0.2,0.2l-0.1,0.1v0.3l-0.1,0.1v0.1h0.2l-0.1,0.3l-0.4,0.5l-0.2,0.3v1.2l-0.2,0.7v0.2h-0.5h-0.1l-0.4-0.1
                l-0.5-0.2l-0.3-0.4l-0.2-0.3l-0.4,0.1h-0.1l-0.1-0.1l-0.3-0.1h-0.4l-0.9-0.5l-0.1-0.1l-0.7,0.1l-1,0.2l-0.8,0.3l-0.8,0.5l-0.3,0.4
                l-0.3,0.5l-0.5,0.2l-0.9-0.1l-1-0.2l-1.1-0.2l-0.6,0.1l-0.8-0.1l-1.2-0.3l-0.9-0.1l-0.9,0.2l-0.1-0.1v-0.1V326l0.1-0.2l0.2-0.1
                l0.1-0.1v-0.1l-0.2-0.2l-0.5-0.3l-0.2-0.2l0,0v-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.2v-0.2l0.1-0.2l0.2-0.1h0.2l0.1-0.1v-0.1
                l-0.2-0.2l-0.4-0.2l-0.4,0.1l-0.4,0.5l-0.3,0.1l-0.2-0.3l-0.3-0.2l-0.5-0.1l-0.3-0.1l-0.1-0.2l-0.2-0.1l-0.5-0.1v-0.1h0.1h0.2h0.2
                v-0.1v-0.1l-0.2-0.1l-0.2-0.1L535,322l-0.1-0.1v-0.1h0.1h0.1h0.1v-0.2l0.1-0.1h0.1v-0.1l-0.1-0.1l-0.1-0.1h-0.1l-0.4-0.1l-0.2-0.2
                h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.1-0.1V320v-0.1v-0.1l-0.1-0.2v-0.3v-0.2V319l0,0l0,0h-0.1h-0.1l-0.2-0.2l-0.2-0.4
                l-0.1-0.1l-0.3-0.2l-0.2-0.1l-0.2-0.3l-0.2-0.2l0.1-0.1l0.6-0.1l0.3,0.1h0.1l0.1-0.1l0.1-0.1v-0.1l0.1-0.1l0.2-0.1l0.6,0.1l0.2-0.2
                l0.1-0.1l0.1-0.2l0.1-0.2l0.2-0.1v-0.1v-0.2l0.1-0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.2l-0.1-0.2v-0.1l0.3-0.4l0.2-0.4v-0.2v-0.2
                l0.2-0.2l0.2-0.2l0.2-0.7l0.1-0.1l0.2-0.1l0.1-0.1v-0.5l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.3l0.1-0.1h0.2l0.2-0.1h0.2l0.2,0.1h0.1
                l0.2-0.1l0.5-0.5l0.1-0.1l0.1-0.1l0.4-0.2l0.1-0.2l0.1-0.2h0.2l0.6,0.4h0.6h0.1l0,0h0.1l0.8,0.2h0.1l0,0l0.3,0.2h0.3l0.3-0.1h0.3
                l0.3,0.1l0.2,0.2l0.5,0.5l0.2,0.2h0.2l0.3-0.1l0.3-0.3l0.8-0.4l0.6-0.1l0.6-0.1l0.7-0.1l0.2-0.3l0.1-0.2l0.1-0.4l0.4-0.1l0.4-0.1
                h0.1h0.3h0.2l0.3,0.2l0.2,0.2l0.1,0.2l0.2,0.3l0.2,0.4l0.2,0.5v0.2l0.1,0.3l0.2,0.3l0.3,0.4v0.1l0.1,0.3l0.3,0.6l0.2,0.2l0.2,0.2
                l0.1,0.2l0.1,0.2l0.3,0.3l0.3,0.3l0.2,0.8l0.2,0.4l0.1,0.3v0.5l0.1,0.2l-0.1,0.4l-0.2,0.8l-0.1,0.7v0.4v0.2l0.1,0.1l0.1,0.3v0.3
                l-0.1,0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.2L554.3,320.1z"/>
            <linearGradient id="RS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="341.2578" x2="0.1864" y2="341.2578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="RS" class="stcustom0 st191" d="M530.5,317.3h0.3l0.3-0.1l0.6,0.1l0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.3l0.2,0.1l0.3,0.2l0.1,0.1l0.2,0.4
                l0.2,0.2h0.1h0.1l0,0l0,0v0.1v0.2v0.3l0.1,0.2v0.1v0.1v0.1l0.1,0.1l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.1h0.1l0.2,0.2l0.4,0.1h0.1
                l0.1,0.1l0.1,0.1v0.1h-0.1l-0.1,0.1v0.2h-0.1h-0.1H535v0.1l0.1,0.1l0.1,0.1l0.2,0.1l0.2,0.1v0.1v0.1h-0.2h-0.2h-0.1v0.1l0.5,0.1
                l0.2,0.1l0.1,0.2l0.3,0.1l0.5,0.1l0.3,0.2l0.2,0.3l0.3-0.1l0.4-0.5l0.4-0.1l0.4,0.2l0.2,0.2v0.1l-0.1,0.1h-0.2l-0.2,0.1l-0.2,0.2
                v0.2l0.1,0.2l0.1,0.2l0.2,0.1l0.1,0.1v0.1l0,0l-0.1,0.1l-0.1,0.1l-0.1,0.2v0.3l-0.4,0.2h-0.1l-0.1,0.1l-0.1,0.4v0.3v0.2v0.1l0.1,0.2
                l0.1,0.2l0.1,0.3l0.2,0.2l0.4,0.2l0.2,0.1l0.1,0.2l0.1,0.2l0.3,0.2v0.2l-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.2,0.1l-0.3,0.4H538h-0.1
                l-0.2,0.1l-0.1,0.2l0.1,0.2v0.2l-0.1,0.3l0.1,0.3l0.1,0.1v0.1v0.2l-0.2,0.3l-0.1,0.1l-0.2,0.1h-0.1l-0.1-0.1H537l-0.3,0.1l-0.3,0.1
                l-0.2-0.1H536l-0.1,0.1h-0.1l-0.2,0.1l-0.3,0.1h-0.2l-0.1-0.1l-0.1-0.2v-0.1l0.2-0.1v-0.1l0.3-0.7l0.1-0.2v-0.1h-0.1h-0.2l-0.8-0.3
                v-0.3l-0.2-0.2l-0.2-0.1v-0.2l-0.3-0.3l-0.2-0.2l-0.3-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.2v-0.1l-0.1-0.1h-0.1l-0.2,0.1l-0.2,0.1v0.1
                l0.1,0.2l0.1,0.1v0.1l-0.1,0.1l-0.4,0.3v0.1l0.1,0.2l-0.1,0.1l-0.3,0.1v-0.1v-0.2l-0.2-0.2l-0.3-0.1l-0.6-0.4l-0.2-0.1l-0.2-0.1
                l-0.3-0.2h-0.2l-0.2-0.1L529,328l-0.3-0.3l-0.2-0.1l-0.1-0.1v-0.1l0,0l0.2-0.2h0.1h0.2l0.1,0.1h0.1l0.1-0.1V327v-0.2l-0.3-0.5
                l-0.3-0.4v-0.1l0.1-0.1h0.1h0.1h0.3h0.3l0.1-0.1v-0.1l-0.1-0.1l-0.3-0.3l-0.3-0.3l-0.3-0.2l-0.2-0.1l-0.1-0.1v-0.1v-0.2v-0.3
                l0.1-0.2l0.2-0.3l0.2-0.3l0.1-0.3l0.1-0.3v-0.1l-0.1-0.1l-0.2-0.1l-0.3,0.1l-0.3,0.1H528v-0.1v-0.1h0.1h0.1l0.1-0.1v-0.2l-0.1-0.6
                l0.2-0.1V321v-0.1l0.2,0.1h0.3h0.2v-0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.1h-0.2l-0.5-0.2l-0.2-0.2v-0.3l0.1-0.1l0.1-0.1l0,0l-0.3-0.1
                l-0.1-0.2l0.1-0.2l-0.1-0.4l-0.2-0.3l0.2-0.1v-0.2V318h0.1l0.2-0.1l0.1-0.1l0.1-0.1h0.1l0.2,0.1h0.2l0.2-0.1l0.1-0.1l0.2-0.1
                l0.1-0.1l0.1-0.1l0.2-0.3l0.2-0.1L530.5,317.3z"/>
            <linearGradient id="RU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="477.7074" x2="0.1864" y2="477.7074" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="RU" class="stcustom0 st192" d="M627.4,65.2l-1.3-0.9l0.1-0.5l0.1-0.2v-0.5l-0.1-0.4l0.1-0.7l0.8,0.5l0.1,0.2l0.6,0.4l0.1,0.7
                l-0.1,0.4L627.4,65.2L627.4,65.2z M619.9,166.9l-0.3,0.8l-0.1,0.4v1v0.4l0.2,1.1l0.2,0.7l0.3,0.4l0.3,0.3l0.4,0.1l0.7-0.1l0.7-0.4
                l0.7,0.3l0.6,0.1l1.3-0.4l0.1,0.1l-0.2,0.5v0.7l0.3,0.5h0.3l0.3,0.1l-0.1,0.3l0.8,0.9l0.8,0.6l-0.2,0.2l-0.5-0.3l-0.2,0.1l0.1,0.5
                l-0.5-0.1l-0.2,0.3v0.4l0.1,0.4v0.3l-0.4,0.1l-0.2,0.2l1,0.5l1.3,0.4l0.4,0.2l0.5,0.4l0.2,0.1v-0.3l0.1-0.2l0.6,0.5l0.5,0.1h0.5
                l1.3-0.2l0.1,0.1v0.3l0.3,0.2l0.3-0.1l0.1-0.2l0.4,0.3l0.1-0.2l0.1-0.1l0.8,0.8h0.2l0.2-0.2l-0.4-0.6l-0.2-0.1l-0.2-0.3l0.4-0.2h0.3
                l0.2,0.3l0.1,0.4l0.1,0.1l1.4,0.5l0.3-0.1v-0.3l0.5-0.2l0.5-0.6l-0.2-0.3l-0.2-0.2l-1.2-0.7l-0.5-0.4l-1.2-1.6l-1.2-2.1l-0.6-1.4
                l-0.6-1.6l-0.2-0.8l-0.2-0.8l-0.5-0.6l0.2-0.7l0.1-0.8l0.3-0.7l0.1-0.4l-0.3-0.9v-0.6l-0.1-0.3v-0.5l0.2-0.3v-0.4l0.1-0.2l0.5-0.2
                l0.2-0.7V161v-0.4l0.3-0.2h0.7l0.1-0.2l0.1-0.4l0.1-0.5l-0.1-0.7l0.1-0.5l0.4-0.8l0.2-0.2l0.1-0.6l-0.2-0.2l-0.6-0.3l-1-0.1
                l-1.3-0.4l-0.6-0.5l-0.5-0.3h-0.4l-0.4,0.2l-1,0.7l-0.4,0.2h-0.3l-0.6-0.2l-0.3,0.1L626,156l-0.3,0.2l-0.4,0.1l-0.3,0.4l-0.1,0.3
                v0.4l0.1,0.9v0.4l0.3,0.5l-0.3,0.1l-0.3-0.1h-0.3l-0.3,0.1l-0.3,0.2l-0.7,0.8l-0.1,0.2l-0.1,0.3l0.2,0.3l0.2,0.2l0.7,0.1l-0.2,0.5
                v0.3l0.1,0.4l-0.3,0.6l-0.1,0.4v0.4l-0.1,0.5l-0.1,0.3l-0.1,0.2l-0.3,0.3l-0.2,0.5l-0.2,0.4l-0.2,0.2h-0.5l-0.5-0.2l-0.2,0.1
                l-0.4,0.4L619.9,166.9L619.9,166.9z M622.6,68.8l0.2,0.2l0.1,0.2l0.6,0.1l1.9-0.2l0.4-0.6l0.4-0.1l0.2-0.7l-1-0.9l-0.4-0.7v-0.6
                l-0.4-0.2l-0.9,0.2l-0.4,0.9l-0.1,0.5l-0.3,0.4l-0.9,0.3l-0.2,0.2l0.4,0.8L622.6,68.8L622.6,68.8z M631.5,51.7l1.3-0.7l0.5,0.8
                l0.4,0.1l0.4-0.4l0.4-0.7l0.9,1.1l0.8,0.8l0.9-0.6l0.4-0.5l0.3-1.1l-0.3-0.9l-0.1-1.2l1.4-0.4l0.5-0.6l-0.4-0.9l-1.2-0.4l-0.4-0.4
                l-0.1-0.8l-0.3-0.4l-0.7,0.4l-0.3,0.1l-0.8-0.1l-0.3,0.6l-0.7,1.6l-0.9,0.7l-0.7,1.6l-1.1-0.5l-0.9,0.3l0.3,1.5L631.5,51.7
                L631.5,51.7z M627.7,58.9l0.4,0.1l0.3,0.3l0.1,0.5l1.3-0.2l1.2,0.8l0.5,1.1l0.5,0.2l1.2-0.1l1.4-0.5l2.1-1.6l0.3-0.6l-0.4-0.5
                l-1.8-1.6l-1.2-1.5l-0.8-0.6l-2,0.2l-2.1-1.7h-0.2l-0.6,2.3l-0.1,1.5h-0.4l-0.5,0.5l0.1,1C626.8,58.4,627.7,58.9,627.7,58.9z
                M637.8,42.1l0.9-0.4l3-0.9v-0.4l0.1-0.9l-0.4-0.6l-3.2,0.5l-0.4,0.6l-0.1,1l-0.1,0.5v0.2L637.8,42.1z M612.9,63.6l-1.3,0.1
                l-0.5-0.8l-0.2-0.2l-0.3,0.1l-0.3,0.3l-0.9,0.3l-0.7,0.5l-0.7,0.9l-2.1,2.4l0.3,0.7l0.3,0.3l0.4,0.9h0.7l0.3-0.1l0.3-0.7l0.6-0.3
                l0.7,0.1l0.2,0.4l-0.7,1l-0.3,0.6l0.3,0.5l0.6-0.1l0.2-0.2l0.2-0.4l0.2-0.2l0.6,0.6l0.2-0.2l0.2-0.8l0.1-0.4l0.1-0.2l0.6,0.6
                l0.3,0.1l0.2-0.1l0.1-0.7l-0.1-0.6l-0.1-0.4l-0.1-0.2l-0.7-0.2V67l0.4-0.9l0.2-0.3l1.9-0.1l0.6-0.8l-0.1-0.8l0.3-0.4l1.2-0.5L618,63
                l0.5-1.1l1.6-1.4l-0.3-0.9l-0.4-0.1l-1.5-2.6l-0.3-0.4l-1,0.1l-0.4-0.3l-0.4,0.1l-1.7,1l-0.7,0.8l-0.3,1.9l0.1,1v1.7L612.9,63.6
                L612.9,63.6z M616.6,53.6l0.3,0.7l0.3,0.1l0.4-0.1l0.1-0.4l0.4-0.7l-0.2-0.8l-0.5-0.4h-0.3l-0.2,0.2v0.3l-0.1,0.3l-0.3,0.1v0.4
                L616.6,53.6L616.6,53.6z M612.5,188.8l-0.3,0.1l-0.6,0.5l-0.6,0.7l-0.3,0.6l-0.1,0.7v0.7v0.4v0.4l0.1,0.3l0.3,1.1l0.6,0.6l0.7-0.1
                l0.8-0.3l1.3-0.6l0.6-0.9l0.2-0.6l0.5-0.6l0.1,0.2l0.1,0.5l0.2-0.1l0.2-0.3v-0.8l-0.3-0.6l-0.5-0.4l-2.2-1.6L612.5,188.8
                L612.5,188.8z M602.5,62.8l0.7,0.4l0.9-0.6l0.2,0.5l0.1,1l0.2,0.6l0.7-0.2l0.4-0.3l0.3-1.1l0.2-0.4h0.9l0.4-0.8l0.1-0.1l0.3,0.2
                l0.3-1.1l0.3-0.2l0.2-0.9l0.3-0.4l0.2,0.2l0.7,1.5l1.6,0.7l0.2-0.1v-1.5l-0.4-1.1l-0.3-0.5l-0.3-0.2l-0.3-0.1l-1,0.2l-1.3-0.7h-0.3
                l-0.9,0.7l-0.6,1l-1.3,0.3l-3.4,1.4l-0.6,0.7l0.7,0.2C601.8,62.2,602.5,62.8,602.5,62.8z M618.4,73.2l1,0.2v-0.2l-0.1-0.2l-0.2-0.5
                l-0.2-0.3v-0.3v-0.6l-0.9-0.7l-0.7,0.7l-0.6,0.2l-1.1,0.9l1,0.9L618.4,73.2L618.4,73.2z M615.6,70.9l0.1-0.6l0.7-1v-0.2l-0.2-0.6
                l-1-0.2l-0.9,1.2l0.1,0.4l1.1,1.2C615.3,71.2,615.6,70.9,615.6,70.9z M652.2,55.7l-0.8,1.3l-0.6,0.7l-0.2,0.5l0.7,1l0.7,0.9l0.5,0.3
                h0.5l3.3-1l1.3-1.1l1.2-2l-0.2-0.7v-0.7l0.1-0.9l-0.2-0.7l-0.4-0.9l-0.4-0.5l-0.6-0.5h-0.6l-0.7,0.4l-0.2,0.6l-0.1,0.7l-0.1,1.3
                l-0.2,0.7l-0.7,0.3h-0.7L652.2,55.7L652.2,55.7z M631.5,70.4l0.2,0.3l1.1,0.2L635,71l0.3-0.4v-0.7l0.1-0.9v-2l-0.1-0.6l-0.4-0.3
                h-0.7l-0.1,0.6l-1.8-0.2l-0.1,0.4l0.1,1.9l-0.2,0.7L631.5,70.4L631.5,70.4z M630,68.4l0.8-0.8l-0.1-0.5l-0.2-0.3l-0.3-0.1l-0.4,0.5
                l-0.3,0.7l0.2,0.5L630,68.4z M641,49.8l0.6-0.2l0.2-0.4l-0.3-0.8l-0.6-0.6l-0.5,0.1l-0.7,0.6l-0.1,0.4l0.3,0.4
                C639.9,49.4,641,49.8,641,49.8z M638.4,53.4l-0.2-0.4l-0.1-0.1l-1.1,1.6l-0.7-0.3l-0.6,0.5l0.5,1.8l1,0.5l0.5,1.7l1,0.5l1-0.1
                l0.6-0.2l0.2-0.9l-0.1-1.2l-0.2-0.6l-0.2-1l-0.4-0.9l-0.3-0.4L638.4,53.4z M641.5,61.8l-0.1,0.8v0.9l0.1,0.3l0.9,1.3l0.7-0.2
                l1.1-0.8l1.2,1l0.9,0.3l0.7-1.5l0.9-0.5l0.2-0.9l0.2-0.3l0.9-0.3l0.1-1.2l0.2-0.8l0.1-1.1l-0.2-0.7l-0.2-0.6l-0.7-0.3l-0.7-0.1
                l-0.8,0.5l-1.4,0.6l-1,0.4H644l-0.1-0.6l-0.4-0.2l-1.1,0.2l-0.3,0.3l-0.1,0.6l-0.2,0.3l-0.3,0.9V61.8L641.5,61.8z M642.5,187.1
                l0.2,0.1l0.2,0.1h0.3l0.3-0.2l0.5,0.2l0.3-0.2l0.3-0.1l0.1-0.5v-0.7l-0.1-0.4l-0.2-0.2l-0.6-0.5l-0.6-0.7l-0.9-0.7l-0.3-0.4
                l-0.3-0.5l-0.3-0.4l-0.6-0.6l-0.1-0.2h-0.1l-0.6,0.3l-0.3,0.6l-0.2,0.1l-0.3,0.3l-0.1,0.4l0.3,0.9l0.1,0.2l0.1,0.3l0.1,0.3l0.8,1.3
                l0.1,0.1l0.2-0.2l0.2-0.1l0.3,0.2l0.3,0.1l0.3,0.2l0.2,0.6v0.4L642.5,187.1L642.5,187.1z M658.1,120.7h-0.3l-0.3,0.1l-0.7,0.7
                l-0.7,0.6l-2.6,0.8l-1.6,0.9l-0.5-0.1l-0.9,0.2l-0.7-0.1l-1.3-0.6l-0.6-0.1l-1,0.2l-0.1,0.1l-0.3,0.5v0.8v0.6l-0.2,0.4l-0.2,0.2
                h-0.4l-0.2-0.4l-0.3-0.1l-0.9,0.1l-0.5,0.3l-0.2,1l-0.7,0.4l-1.2,0.4L641,128h-0.3l-0.3,0.2l-0.6,0.9l-0.7,0.6l-1,0.6v0.5l0.1,0.3
                l-0.9,1l-0.2,0.6l-0.2,1.1l-0.1,0.2l-0.9-0.4l-0.6-0.1l-0.3,0.1l-0.4,0.3l-0.1,0.3l0.1,0.6l0.1,0.4l-0.9,1.6h-0.2l-0.3-0.5L633,136
                l-0.4-0.2l-0.4-0.1l-0.3,0.3l-0.3,0.5v0.4l0.1,0.3l0.4,0.6l1-0.1l0.2,0.4l0.2,0.2l-0.8,0.6l-0.9,1.1l-0.7,1.5l-0.2,0.3l0.1,0.4
                l0.1,0.4l0.3,0.2h0.5l0.5,0.5l-0.2,0.2l-1.9,0.5l-0.2,0.2l-0.9,2.4l-0.3,0.6l-0.2,0.3l-0.3,0.6l-0.3,0.8L628,149l-0.6,0.2l-0.6,0.5
                l-0.6,0.6l-0.3,0.2l-0.2,0.3l0.2,0.7l1,1.5l-0.2,0.6l0.5,1.3l0.7-0.7l0.6-0.3h0.7l0.8,0.6l0.8,0.3l1.4,0.1l0.5,0.3l0.6,0.2l0.6-0.1
                l0.9-0.6l0.5-1.4l0.9-1l0.2-0.5l-0.2-0.9l-0.5-0.7l0.1-0.2l0.4,0.1l0.4,0.5l0.2,0.1h0.3l0.2-0.4l0.1-0.5l-0.1-0.5l-0.2-0.8v-0.4
                l1.9-1.2l0.5-1l0.1-0.6l-0.1-0.4l-0.2-1l-0.2-0.4l0.1-0.4l1.1,0.4l0.3-0.2l0.2-0.2l0.1-0.5V142l0.1-0.6l0.2-0.3l1,0.8h0.2l0.2-0.3
                l0.1-0.3l-0.1-0.3v-0.5h0.7l0.3-0.1l0.4-0.4l0.2-0.4l0.4-0.4l0.2-0.3l-0.1-0.4l-0.6-0.3l0.1-0.4l0.6-0.5h0.2h0.3l0.2-0.1l0.3-0.5
                l0.3-0.6l0.6-0.6l0.3-0.6l0.3-0.4h0.4l0.4-0.1l1.3-1.2l2.8-1.7l0.8-0.3l1-0.7h0.3l1.4-0.5l1.4-0.8l1.3-0.6l1.2-0.7l1.9-0.9l1.1-0.7
                l0.7-0.3l0.7-0.4l0.7-0.6l1.1-0.9l1.1-0.6l0.2-0.3l0.9-1.6l1-1.5l-0.1-0.5l0.1-0.6l0.1-0.6l-0.1-0.6l-0.1-0.4l-0.5-1l-0.6-0.8
                l-1.3-0.7l-1-0.3h-0.3l-0.8,0.5l-1.2,0.5l-1.4,1.3l-0.8,0.9l-0.3,0.7l-0.3,0.9l-0.3,0.4h-0.3l-0.3,0.1l-0.6,0.6L658.1,120.7
                L658.1,120.7z M635.3,64l-0.2,0.4l0.2,0.4l0.3,0.8l0.1,0.5v0.7l0.2,2.2l0.1,0.6l0.2,0.3l1.1,0.6l0.4-0.3l0.6-1.2l0.3-0.1l0.1-0.8
                v-0.8L639,67l2.4-0.4l-0.4-0.8l-0.4-0.5l-1.4-0.8l-2.7-0.2L635.3,64L635.3,64z M645.8,56.4l0.9-0.4l0.9-1.1l0.3-0.7l-0.3-1l-2.4,0.3
                l-1.2,1.3l-0.2,0.3l-0.1,0.3l0.7,0.5C644.4,55.9,645.8,56.4,645.8,56.4z M650.6,43.1l0.1,0.3l0.9,0.5l1.8,0.2l0.3-0.2l0.4-0.8v-0.3
                l-0.2-0.4l-2.6-0.6l-1.4,0.2l-0.5,0.5l1.1,0.4L650.6,43.1z M640.5,72.4l0.5,0.3l0.2,0.3l0.1,0.2l0.4,0.2l1-0.5l0.6-0.6l-0.3-1.4
                l-0.7-0.6l-1.5,1.1l-0.2,0.2L640.5,72.4L640.5,72.4z M538.8,279.9l0.3-1v-0.2l-0.2-0.3l-0.3-0.2l-0.2-0.4h-0.6h-0.6H537l-0.6-0.2
                l-0.5-0.3l-0.4-0.2l-0.3-0.2l-0.2-0.2l-0.3,0.1h-0.2v0.8l-0.1,0.8l-0.5,0.2h-0.3l-0.3-0.2H533l-0.2-0.1l0.7-1l0.3-0.5h-0.2l-0.2,0.3
                l-0.5,0.6l-0.4,0.5l-0.3,0.2h-0.8l-0.4,0.2l-0.1,0.4v0.4l-0.2,0.6l-0.3,0.4l-0.4,0.4l0.1,0.1l0.8,0.1l0.8,0.1l1.3,0.1l1.3,0.1
                l1.4,0.1l1.5,0.1h1.6h0.1l-0.1-0.2l-0.1-0.4v-0.3L538.8,279.9L538.8,279.9z M624.4,175v-0.5l-0.1-0.2h-0.2l-0.2-0.5l-0.3-0.6
                l-0.4-0.3h-0.1v0.1v0.3l-0.3-0.2h-0.3l-0.6,0.2l-0.2,0.3v0.4l0.1,0.1l0.4,0.3l0.3-0.1l0.5,0.6l0.1,0.6l0.1,0.3l0.3,0.3l0.2,0.4
                l0.1,0.4l0.3-0.1v-0.1l-0.2-0.3l0.1-0.3L624.4,175L624.4,175z M749,69.4l-1.3,0.2l-2,0.8l-0.4-0.1l-0.3,0.2l-0.9-0.2l-1,0.1
                l-0.3,0.5l-0.1,0.5l-0.2,0.2l-0.8-1.1l-0.1,0.1l-0.4,0.9l-0.3,0.4l-1,1.1l-0.3,1.8L739,76l-0.5,0.9l-1.2,1.1l-0.9,2.1l0.6,0.6
                l0.6-0.1l0.8,0.2l1.3,0.8L740,83l0.5,1.3l0.4,0.4v0.8l0.1,0.2l0.4,0.6l0.6,0.5h0.3l0.9-0.7h0.3l0.5,1.3l0.3,0.2l1.5-0.2l0.2,0.2
                l1.1,0.3l0.2,0.3l0.2,0.4l0.9,1l0.9,0.6h0.4l0.6,0.2l0.4-0.2l0.6,0.4l0.4,0.1l1.1-0.4l1.7-0.2l0.3-0.3l1.1-1.6l-0.1-0.7l-0.2-1.3
                l-0.2-0.2l-0.7-0.3l-0.6-1.4l-0.8-1l0.2-0.2h0.2l0.6,0.5h0.4l0.4-0.7l0.1-0.9v-1.2v-0.4l0.1-0.4l0.1-0.8v-0.9l0.1-0.4l0.1-0.2
                l0.2-1.1l0.4-0.6l-0.3-1.1l-0.4-0.8l-0.3-0.3l-0.5-0.3l-0.2-0.5l-0.3-0.3l-0.2-0.5l-1.2-0.5l-0.8-0.1l-0.2,0.1l-0.2,0.6l0.1,0.9
                l-0.4,1.1l-0.2,0.2l-0.6-0.4l-0.5,0.8l-0.4,1.1l-0.2,0.3l-0.2-0.2l0.1-1.2l0.4-0.7l0.1-1l0.3-0.8l0.3-0.3l-0.3-1.2l-0.6-1L749,69.4
                L749,69.4z M746.2,124l0.1-0.5l-0.2-0.2l-0.7-0.3l-0.3,0.4l-1-0.3l-0.5,0.5l-0.6-0.5l-0.2,0.3v0.6l1,0.2l0.5,0.4l0.7,0.1l0.5,0.4
                l0.1-0.7V124l0.4-0.3C745.9,123.9,746.2,124,746.2,124z M747,122.4L747,122.4l-0.1,0.3l-0.3,0.8v0.6v0.1l0.1,0.1l0.2-0.1l0.3-0.4
                l0.2-0.8v-0.2l-0.2-0.3L747,122.4z M755.6,77.4l-0.1,1.1l0.6-0.2l0.5-0.9l-0.4-0.2l-0.2-0.3l-0.3,0.2
                C755.6,77.1,755.6,77.4,755.6,77.4z M748.2,118.4l0.1,0.7l0.2,0.5l0.3,0.1l0.1-0.2l-0.6-1.3C748.4,118.2,748.2,118.4,748.2,118.4z
                M777.1,110.4l-0.3-0.2h-0.3l-0.3,0.7l0.1,0.6h0.3l0.7-0.3v-0.4L777.1,110.4L777.1,110.4z M756.5,92.9v0.8l-0.1,0.5l-0.1,0.4
                l-0.1,0.5l-0.2,1.3l-0.1,0.6l-1,1.5l-0.4,0.8l-0.3,1.3l-0.4,0.6l0.3,0.5l0.3,0.3l1,0.3l0.7-0.2l1.3-1l1.4-1.3l0.5-0.7h1.4l1.4-0.2
                l1.2-0.3l-0.1,0.3l0.2,0.2h0.2l0.6-0.9h2l1.6-1.1l0.6-0.2L769,97l0.3-0.2l1.3-2.1l0.1-1.3l-0.1-1l-0.2-1l-0.1-0.7l-0.2-0.6l-0.4-0.4
                l-0.4-0.2l-0.7,0.3l-0.5-0.7l0.1-0.6l-0.1-0.6l-0.1-0.2l-0.9-0.5l-0.2-0.7l-0.2-0.9l-0.3-0.4H766l-0.7,0.4l-0.7,0.8l-0.3,0.2h-0.4
                l-0.3,0.6l-0.3,1l-0.5,1.1l-0.5,0.5l0.1-0.6l0.8-3.4l0.1-1l0.3-1.2l0.2-0.3l0.3-0.2l0.1-0.3l-0.2-0.5l-0.7-0.9l-1.1-0.6h-0.3
                l-0.2,0.3l-0.1,0.6v0.9l0.2,0.9l-0.3,0.1l-0.3-0.2l-0.3-0.7l-0.2-0.9h-0.2l-0.3,0.2l-0.1,0.2l-0.1,0.6v0.9l-0.7,0.3l-0.3,0.4
                l-0.1,0.7l-0.3,0.5V86l-0.1,1.1l-0.2,0.2l-0.2,0.4l-0.1,0.8v0.4l0.1,1.2l0.2,0.3l-0.2,0.1l-0.7-0.2l-0.3,0.1l-0.3,0.5l-0.4,1.1
                L756.5,92.9L756.5,92.9z M773.3,98.8l0.6-0.5l0.1-0.2h0.5h0.1v-0.4l-0.1-0.3l-0.1-0.3l-0.5-0.1l-0.3,0.9l-0.8,0.1l-0.1,0.6l0.4,0.3
                L773.3,98.8z M737.8,75.1l0.6-1.4l-0.9-0.6l-2.5-0.9l-1.2-0.8l-1.2-0.1l-0.9,0.1l-0.6,0.3l-0.4,0.8l0.2,1.2l0.7,1.1l0.9,0.7l1.3,1.7
                l0.8,0.1l0.7-0.2l0.7-0.3l0.6-0.5l0.6-0.7L737.8,75.1L737.8,75.1z M718.4,139.5l0.7,0.2l0.2-0.3l0.3-0.8l-0.2-0.5l-0.1-0.1l-0.7,0.3
                l-0.2-0.2l-0.2-0.2l-0.3,0.3l-0.5,0.3l-0.2,0.5l0.4,0.5H718.4L718.4,139.5z M881.2,306l-0.2-0.3l-0.5-0.5l-0.3-0.6L880,304l-0.1-0.5
                l-0.1-0.5l-0.2-0.5l-0.3-1l-0.7-1.7l-0.2-1l-0.6-3.3l-0.1-0.1v-0.1v-0.5v-0.3l-0.1-0.2l-0.3-0.3l-0.1-0.2v-0.5l-0.1-0.5l-0.2-0.4
                l-0.1-0.6v-1.2l0.1-0.4l0.2-0.2l0.1-0.2l0.1-0.4V289v-1.2l-0.1-0.8l-0.1-0.4l-0.1-0.4l-0.4-0.9l-0.4-1.4l0.1-0.1l-0.1-0.3v-0.4
                l0.1-0.3l0.1-0.3v-0.3l-0.6-1.2l-0.2-0.1l-0.1,0.3l-0.1,0.3l-0.2,0.1h-0.6l0.4,0.6l0.6,0.9l0.1,0.3l-0.1,0.4l-0.1,0.4h0.1v0.3
                l-0.4,0.1l-0.1,0.3l0.2,0.2l-0.2,0.4l-0.3,0.2h-0.2h-0.1l-0.4-0.4l-0.1-0.1l-0.5,0.2l-0.3,0.3l-0.1,0.2v0.9l0.1,0.5l-0.1,1.1
                l-0.1,1.1l-0.2,0.5l-0.2,0.4l-0.1,0.4v1.5l0.1,0.4l0.2,0.3l-0.1,0.2l-0.1,0.1l0.1,0.2l0.3,0.3l0.4,0.5l0.2,0.4l0.3,0.9v1l-0.2,0.5
                l-0.1,0.5l-0.1,0.6v0.5l0.2,0.9v0.4v2.8l-0.1,0.6l-0.1,0.5l-0.1,1l-0.1,0.5l-0.3,0.9v0.2l0.1,0.2l0.4,0.8l0.3,0.8l0.1,1.2l-0.1,0.5
                l-0.2,0.4l-0.2,0.4l-0.1,0.5v0.2l0.1,0.8l0.1,0.4l0.1,0.4l-0.1,0.5l-0.4,1.4l-0.1,1l0.2,1.1v0.3l0.1,0.3l0.2,0.2l0.2,0.2l0.2-0.3
                l0.2-0.4l0.3-1.1l0.1-0.4l0.2-0.4l0.2-0.4l0.3-0.2l0.2-0.1h0.2l0.2,0.2l0.1,0.2l0.1,0.1l0.6,0.1l0.7,0.1l0.2,0.3l0.1,0.5l0.1,0.6
                v0.8l0.1-0.2l0.1-0.2v-0.2l0.1-0.2l0.2-0.5v-0.2l-0.1-0.7l-0.2-0.7l-0.1-0.2l-0.2-0.1h-0.2l-0.3,0.1l-0.1-0.2l-0.2-0.6l-0.2-0.9
                l-0.2-0.4l-0.1-0.2l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.4l-0.3-0.6l-0.1-0.2v-0.6l0.1-0.8l0.2-0.7l0.9-2.8l0.2-0.8l0.2-0.4l0.4-0.3
                l0.4-0.1l1-0.1h0.2l0.4,0.1l0.2,0.1l0.2,0.2l0.4,0.6l0.4,0.4l0.3,0.4l0.2,0.3l0.1,0.6l0.1,0.2v-0.8L881.2,306L881.2,306z
                M712.8,144.2l0.1-0.6l0.3-0.6h-0.5l-0.4,0.2l-0.4,0.4l0.1,0.3L712.8,144.2z M709.2,147.9l0.1-0.1h0.1l0.1-0.2l0.2-0.2l-0.3-0.3
                l-1-0.3l-0.7,0.2l-0.2,0.4l1,0.2L709.2,147.9L709.2,147.9z M732.3,67.7L733,68l0.6,0.4l0.3,0.7l2.2,1.3l1.8,1.5h0.6l1.3-1.1l0.7-0.8
                l0.3,0.1l0.8-0.5l2.5-0.4l3.7-1.1l0.3-0.5l0.3-0.8l-0.4-0.3l-0.1-0.3l-0.4-2.3l-0.1-0.4l-0.1-0.3l0.2-1.3l0.3-0.7l1.2-0.4l0.2-0.4
                h0.3v-1.1l-0.1-0.6l-0.4-0.5l-0.8-0.3l-1.8-2.1l-0.2-0.7l-0.4-0.6l-0.1-0.8l-0.2-0.4l-0.8-1.5l-0.3-0.2l-0.3-0.3l-0.2-0.9l-0.3-0.4
                l-1.8,0.2l-0.3,1.5l-0.6,0.9l-0.6,0.5l-0.7,0.1l-0.7,0.3l-0.7,0.6l-0.7,0.4h-0.4l-0.4,0.1l-0.8,0.8l-0.4,1.1l-0.5,0.6l-0.2,0.4
                l-0.3,1.1v0.5l0.5,0.2l1.4-0.4l-0.8,1.5l-0.4,1.5l-0.7,1.5l-0.9,0.6l-1,0.4l-0.6,1l-0.5,1L732.3,67.7L732.3,67.7z M773.7,99.7
                l1.6,0.6h1l0.4,0.6l0.3-0.3l0.2-0.7l-0.3-0.7l-0.2-0.1l-2.6,0.3L773.7,99.7z M743.6,114.3l0.2,0.4l0.2,0.2l0.7-0.3l0.3,0.1l0.1-0.2
                l0.1-0.2l0.4-0.6l0.4-0.7v-0.3l-0.1-0.7l-1.9,1.4l-1.2,0.5l-0.4,0.5l0.3,0.1L743.6,114.3L743.6,114.3z M727.6,53.1l3.5,0.9l1-1.4
                l-0.3-0.8l-1-0.3l-2.9-0.3l-0.5,0.8l0.1,0.4L727.6,53.1L727.6,53.1z M856.6,133.3l0.2-0.3l-0.2-0.5l0.1-0.4l0.5-0.9l-0.1-0.2
                l-0.6-0.8l-0.2-0.4l-0.1-0.9l-0.3-0.5l-0.2,0.9l-0.1,0.6v0.8l0.1,0.7l-0.2,0.9l-0.1,0.4l-0.1,0.8l0.8,0.1L856.6,133.3L856.6,133.3z
                M859.6,135.3l0.6,1.2l0.6,0.8l0.3,0.1l0.3,0.3l0.7,1.5l0.2,0.5l0.3,0.3l2.2,1l0.3,0.3l0.3,0.2h0.3l0.3-0.3l0.3-0.7l0.2-0.9l0.1-0.7
                l0.2-0.4l0.2-0.2h0.2l0.7,0.7l0.7,0.5l0.6-0.1l0.6-0.3l1.8-0.4l0.6-0.3l0.6-0.4l0.7-0.1l0.3,0.4l0.2,0.6l0.3,0.5l0.3,0.2l0.3,0.1
                l0.4-0.2l0.4-0.3l1-1.1l-0.6-1l-0.7-0.4l-0.2-0.3l-1-2.3l-0.2-0.5l-0.1-0.7l-0.2-2.3l0.6-0.4l0.7-0.3l1.1,0.1l0.2,0.6v0.3l-0.3,0.7
                l-0.4,0.3l-0.1,1.1l0.1,1.2l0.3,0.8l0.3,0.6l0.7,1.1l0.6,0.4h0.6l1.1,0.4l0.6-0.2l0.5-0.5l1.3-1.8l-0.2-0.6l-0.2-0.5l0.2-0.6
                l0.6-0.8l0.9-0.5l-0.1-0.4l-0.2-0.2l-4.4-3.2h-0.4l-0.7,0.4l-0.4,0.1l-0.7-0.2l-0.7-0.4l-0.6-0.5l-1.3-1.6l-0.7-0.8l-0.7-0.3
                l-0.5,0.9l-0.7,0.9l-0.1,0.3l-0.1,0.4l-0.1,0.7v0.8v0.6v0.6l-0.2,0.5l-0.2,0.2h-0.4l-0.2-0.1l-0.2-0.2l-0.1-0.3l-0.3-1.2l-0.3-0.3
                l-0.3,0.1l-0.3-0.2l-0.9-1.4l-0.6-0.7l-0.9-0.8l-0.3-0.3l-0.3-0.6l-0.3-0.4h-0.3l-1.1,0.8l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.2,0.2
                l-0.6,0.1l-0.4,0.3l-0.2,0.4l-0.2,0.5l0.3,1l0.3,0.7l-1-0.3l-0.3,0.4l-0.2,2.2l0.2,2.3h-0.3l-0.5-0.2l-0.1,0.4v0.6L859.6,135.3
                L859.6,135.3z M926,178l0.2-0.1l-0.2-0.5l-0.2-0.3l-0.3-0.1v0.1v0.6l0.2,0.3H926L926,178z M893.8,118.6l0.2-0.9l0.4-0.4l-1.9,0.4
                l-0.8,0.8l-0.1,0.3l2.1-0.1L893.8,118.6L893.8,118.6z M887.4,137.1l0.6,0.7l0.3,0.2l1,0.3l0.3,0.3l0.6,0.8l0.3,0.3l0.6,0.3l2.1,0.3
                h1.5l0.7-0.2l1.4-0.8l0.7-0.6l0.2-0.3l0.5-2.3L898,136l-0.2,0.1l-0.2,0.4l-0.2,0.2l-0.3-0.4l-0.4-0.3l-0.5-0.6l-1.3-0.3l-1.6-0.2
                l-0.5,0.4l-0.8-0.1l-0.3-0.4v-0.4l0.1-0.3v-0.6l-0.2-0.3l-2.6-0.3h-0.2l-1.1,0.8l-0.7-0.1l-0.1-0.6v-0.9l-0.6-0.8l-0.3,0.3l-0.3,0.9
                l-0.2,1l-0.2,1.1l-0.1,1.1l1.6,0.9L887.4,137.1L887.4,137.1z M904.2,125.2l-0.1-0.5l-0.1-0.3l-0.1-0.1l-0.4,0.2l-0.3,0.4l0.6,0.7
                L904.2,125.2L904.2,125.2z M976.9,177.9h0.2l0.8-0.5l1.4-0.2l0.7-0.7l0.3-0.1l0.7,0.2l0.6,0.4h0.3l0.7-0.2l1.8-0.6l1.3-0.2l0.7-0.2
                l0.8-0.9l0.1-0.5l-0.1-0.4l-0.1-0.2l-0.7-0.5l-0.4-0.4l-0.2-0.4l-0.2-0.2l-0.2-0.1l-0.4-0.4l-0.2-0.1l-1.2-0.3l-0.3-0.1H983
                l-0.4,0.2h-0.4l-0.4-0.1h-0.4l-0.4,0.2l-0.4,0.1l-0.3,0.1l-0.5,0.5l-0.5,0.2l-0.9,1.1l-1,0.8l-0.2,0.5l-0.4,0.6l-0.2,0.5l0.1,0.4
                L976.9,177.9L976.9,177.9z M927.3,191.2v0.7l0.1,0.2l0.5,0.3l0.3,0.4v0.2l-0.1,0.5h0.1l0.1-0.2V193l-0.1-0.4l-0.3-0.7v-0.7v-0.8
                l-0.1-0.6l0.1-0.4l0.5-0.2l0.2-0.5V188l-0.3-0.3l0,0l-0.3,0.4l-0.2,0.4l-0.6,0.6l-0.1,0.5l0.2,0.6L927.3,191.2L927.3,191.2z
                M946.6,186.6l0.4,0.5l0.6,0.4l1.6,0.7l0.8-0.1l0.1-0.2v-0.2l0.1-0.9l0.1-0.3l0.2-0.1V186v-0.2l-0.1-0.2l-2.9-1.1l-0.5,0.1l-0.4,0.3
                l-0.5,0.6l-0.1,0.2l-0.1,0.3l0.1,0.1L946.6,186.6z M789.9,120.1l0.4,0.1l0.3,0.7l0.4,0.4h0.1v-0.4l0.1-0.2l0.1-0.1l-0.1-0.5
                l-0.4-0.8l-0.2-0.3l-0.3,0.3l-0.8-0.2l-0.1,0.1l0.1,0.3L789.9,120.1L789.9,120.1z M855.2,146.5l0.5,0.6l0.2,0.6l0.9,1.3l0.2,0.4
                l0.2-0.3l0.2-0.7l-0.6-1.1l-1.1-1.3l-0.7-0.3v0.5L855.2,146.5z M824.5,149.9h0.2l0.3-0.4v-0.1l-0.2-0.3l-0.3-0.1l-0.3,0.1v0.2
                l0.1,0.4L824.5,149.9z M867.1,154.1l0.3,0.7h0.4l0.3-0.1l1-0.7l0.8-0.2l0.3,0.1l1.2,0.6l1.2,0.8l1.5,0.3l0.6,0.3h0.7l0.7,0.1l1,0.2
                l0.7-0.1l0.1-0.1l-0.1-2.1l-0.1-0.6l-0.2-0.5l-2-2.3l-0.6-0.5l-0.7-0.4l-0.7-0.2l-0.7,0.1l-1,0.3h-0.3l-0.3,0.1l-0.3,0.3l-0.3,0.5
                l-0.5,1.5l-0.3,0.6l-0.6,0.8l-0.6,0.3l-0.7,0.1L867.1,154.1L867.1,154.1z M811.9,157.8l0.7-0.4l-0.1-0.2l-0.4-0.5l-0.3-0.1l-0.6,0.1
                l-0.3,0.3l0.4,0.8L811.9,157.8L811.9,157.8z M869.2,149.1h0.3l1.4-0.8l0.1-0.5l0.1-1.2v-0.4l-0.1-0.3l-0.3-0.2l-0.3-0.1l-1.2,0.1
                l-0.3,0.1l-0.3,0.2l-0.3,0.5l0.2,1.8L869.2,149.1z M706.6,147.6l0.4,0.2l0.3-0.4l-0.1-0.2l-0.2-0.4l-0.2-0.1l-0.4,0.1l-0.1,0.2
                l0.1,0.3L706.6,147.6L706.6,147.6z M861.9,171.2l-1.5,0.1l-0.3,0.2H860l-0.1,0.1v0.1v0.2l0.5,0.4h0.2l0.2-0.1l0.2-0.1l0.2,0.1
                l0.3,0.4l0.3,0.1l0.7-0.7l-0.1-0.3l-0.2-0.4L861.9,171.2L861.9,171.2z M725.7,112.3l0.6-0.1l0.4-0.8v-0.3l-0.2-0.4l-0.9,0.1
                l-0.2,0.4l-0.2,0.6l0.1,0.2L725.7,112.3L725.7,112.3z M942.3,278.3l-0.2-0.3l-0.4-0.8l-0.1-0.4l0.1-0.3l-0.2-0.1l-0.8-0.1l-0.3,0.2
                l-0.2,0.1l0.7,0.5l0.2,0.3v0.3l0.1,0.2l0.3,0.5l0.3,0.4l0.2,0.1l0.2,0.1l0.2,0.3l0.3,0.4V279l-0.2-0.3L942.3,278.3L942.3,278.3z
                M909,303.8v0.4l0.3,0.1l0.3-0.2l0.1-0.2l-0.1-0.5l0.2-0.4v-0.3l-0.2-0.1L909,303.8L909,303.8z M904.6,310.5l-0.2,0.3l0.1,0.1h0.1
                l0.1-0.2L904.6,310.5L904.6,310.5z M912.9,297.8l-0.3,0.3l-0.3,0.9l-0.3,0.4l-0.7,0.1l-0.3,0.3h-0.3l-0.1,0.1l0.1,0.2v0.5l0.1,0.1
                l0.3,0.1l0.1-0.2l0.2-0.3l0.3-0.1l0.5-0.1l0.4-0.4l0.3-0.7l0.2-0.5l0.1-0.5l-0.1-0.4L912.9,297.8L912.9,297.8z M914,297.3l-0.5,0.3
                l-0.1,0.2l0.1,0.1l0.4,0.3h0.1l0.1-0.1l0.2-0.4v-0.4l-0.1-0.1L914,297.3L914,297.3z M704,134.2l0.2,0.5l0.1,0.1l0.5-0.4l0.1,0.6
                l0.2,0.2l0.2-1h0.4l0.1-0.1v-0.4l-0.1-0.4v-1.1h-0.4l-0.3,0.2v0.4v0.6h-0.2l-0.4-0.5l-0.6,0.9l0.1,0.3L704,134.2L704,134.2z
                M911.7,297.6h0.3v-0.1v-0.3l-0.2-0.1l-0.3,0.1v0.2l0.1,0.1L911.7,297.6L911.7,297.6z M902.1,313.4l-0.4,0.4l-0.5,0.6l-0.4,0.1v0.1
                l0.1,0.2h0.2l0.5-0.5l0.8-1L902.1,313.4L902.1,313.4L902.1,313.4z M907.6,305.8l-0.1,0.3l-0.3,0.3v0.1l0.1,0.1l0.1-0.2l0.3-0.3
                l0.1-0.1L907.6,305.8L907.6,305.8L907.6,305.8z M885,328L885,328v0.1V328L885,328L885,328L885,328L885,328L885,328L885,328z
                M885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1L885.8,327.1z M885.4,323.8
                h-0.3l-0.5,0.9l-0.1,0.1l-0.1,0.2l-0.2,0.2l-0.1,0.2l-0.2,0.3l-0.6,0.5l-0.1,0.2v0.3l0.3,0.3l0.1-0.7l0.5-0.4l0.3-0.4l0.1-0.2
                l0.6-0.6l0.2-0.1h0.3l0.4-0.4h0.2l0.1-0.3l-0.6,0.1L885.4,323.8L885.4,323.8z M884.6,327.9L884.6,327.9L884.6,327.9L884.6,327.9
                L884.6,327.9L884.6,327.9L884.6,327.9L884.6,327.9l-0.1,0.1l0,0h0.1l0,0V327.9L884.6,327.9L884.6,327.9z M892.8,320v-0.1v-0.1
                l-0.1-0.1h-0.2l-0.3,0.2l-0.8,0.8l-0.5,0.1h-0.2l-0.3-0.5h-0.1l-0.1,0.3v0.3l-0.3,0.1l-0.3,0.4l-0.3,0.1l-0.4,0.5l-0.5,0.4l-0.3,0.4
                v0.4l-0.5,0.4l-0.1,0.2l-0.1,0.4l0.6-0.5l0.3-0.1l0.3-0.5l0.7-0.6l0.1-0.2v-0.2l0.1-0.1l0.4,0.1l0.4-0.1l0.3-0.3l0.7-0.6l0.4-0.1
                l0.5-0.3h0.5l0.1-0.2l-0.1-0.2L892.8,320L892.8,320z M896.9,317.1l-1,0.7l-0.5,0.6l-0.4,0.1l-0.6,1h0.3l0.4-0.2l0.5-0.6l0.2-0.2
                l0.3-0.1l0.4-0.3l0.1-0.3l0.9-0.8L896.9,317.1L896.9,317.1L896.9,317.1z M887.2,326.3l-0.6,0.2v0.1v0.2l0.2,0.1l0.1-0.1l0.5-0.2
                v-0.1L887.2,326.3z M897.9,256.6l-0.2,0.3l-0.3,0.2v0.1l0.1,0.1l0.2-0.1l0.4-0.4v-0.1L897.9,256.6L897.9,256.6z M937,256.3h-0.2
                l-0.9,0.7l-1.2,0.4v0.2v0.2l-0.2,0.7l-0.4,0.9l-0.4,0.3v0.2v0.1l0.1,0.1l0.5-0.5l0.9-0.8l0.9-0.5l0.9-0.3l0.1-0.5l-0.1-0.8
                L937,256.3L937,256.3z M688.8,78.8l0.9,0.5h0.5v0.8l0.5,0.1l2.2-0.2l-0.1-0.3l-0.5-0.5l-2.5-1.3l-0.3,0.4l-0.3-0.6l-0.3-0.2
                l-0.3,0.3l0.1,0.3L688.8,78.8L688.8,78.8z M688.8,152.8l-0.2,0.3l0.4,0.5l1.2,0.4l0.3-0.1l-1.4-1.1L688.8,152.8L688.8,152.8z
                M687.9,153.7l0.2-0.2l0.4-0.7v-0.1l-0.3-0.1l-1,0.3l-0.5,0.6l-0.1,0.4l0.4-0.2H687.9L687.9,153.7z M684,159.1l0.4,0.3l0.1,0.2
                l0.1-0.1l0.1-0.3l-0.1-0.2v-0.4l0.3-0.6l0.6-0.3l-0.7-0.4l-0.4-0.1l-0.5-0.1l-0.6,0.2l-0.2,0.3l-0.1,0.5l0.2,0.4L684,159.1
                L684,159.1z M697.1,58.5l2.6-0.6l0.9-0.3l0.1-0.3l0.2-0.8l-0.4-0.4l-1.3-0.5l-1.7,0.3l-0.3,0.7l-0.4,1.3l0.1,0.2L697.1,58.5
                L697.1,58.5z M671.9,157.7l0.5,0.1h0.2l0.8-0.5l0.6-0.2l1.3-0.4l0.8-0.1v-0.5l-0.1-0.6l-0.4-0.6l-0.3-0.3l-0.3-0.7l-0.3-0.3
                l-0.3-0.3l-0.2-0.1l-1.7,0.4l-0.6,0.3l-0.4,0.8l0.1,1.3l-0.1,0.5l-0.2,0.4v0.4l0.3,0.5L671.9,157.7L671.9,157.7z M696.5,160.6
                l1.5,0.4l0.2-0.1l0.1-0.4v-1.4l-0.4-0.6l-0.2-0.5l-0.5-0.5l-1.4,1.9l-0.1,0.4l0.2,0.4L696.5,160.6L696.5,160.6z M946.3,279.6
                l-0.5-0.4l-0.3-0.1l-0.2-0.3h-0.2l0.1,0.3l0.5,0.5l1.1,0.9l-0.1-0.3L946.3,279.6L946.3,279.6z M692.3,162.3L692,163l-0.3,0.4
                l-0.7,0.7l-0.1,0.4l0.1,0.2l0.7,0.1l1.4-0.1l0.4-0.2l0.6-0.8l0.4-0.2l0.6-0.6v-0.2l-0.2-0.5l-1.5-0.7h-0.5L692.3,162.3L692.3,162.3z
                M859.6,277.7l-0.6,0.4l-0.2,0.3h0.1l0.4-0.1l0.2,0.2h0.2l0.3-0.8h-0.1l-0.2,0.1L859.6,277.7L859.6,277.7z M862.5,277.8l-0.3-0.2
                l-0.1-0.1l-0.9-0.4l-0.1,0.2l-0.3,0.7l-0.1,0.1l-0.3,0.5l-0.1,0.5h0.1l0.5-0.4l0.2,0.2l0.4,0.8h0.2l0.2-0.2l0.2-0.3l0.3-0.2l0.1-0.2
                l0.1-0.4l0.2-0.4l0.3-0.2l-0.1-0.1L862.5,277.8L862.5,277.8z M1009,213.8l-0.3-0.1h-1l0.1-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.2h-0.2
                l-0.3,0.1l-0.3,0.2l0.2-0.3l0.2-0.2l-0.1-0.2l-0.1-0.1l-1-1.2l-0.6-0.4l-0.6-0.6l-0.6-1l-0.6-0.8l-0.7-0.3l-0.7,0.1l-0.5-0.2
                l-0.5-0.3l-1.1-0.3v0.2l1.2,0.4l0.2,0.2l-0.1,0.2h-0.3l-0.9-0.1l-0.3-0.1l-0.2-0.3h-0.1l-0.1,0.2v0.4l0.1,0.3l-0.2,0.1l-0.2-0.1
                v-0.4l0.1-0.3l0.3-0.6l0.2-0.1v-0.1l-0.9-0.3l-0.3-0.2l-0.3-0.1l-0.6,0.3h-1.6l-0.3,0.1v0.2l0.1,0.1l0.2,0.1h0.2l0.2,0.3l0.4,0.1
                l0.2,0.3l0.1,0.5l0.1,0.3v0.3l-0.2,0.6l-0.2,0.3l-0.3,0.1l0.1,0.4l0.3,0.3l0.5,0.1l0.2,0.2l0.2,0.4l-0.2,0.5l-0.2,0.4l-0.2,0.2
                l-0.1,0.3l-0.1,0.1h-0.1l0.1-1.1l-0.2-0.6l-0.3,0.1l-0.1,0.2l-0.3,0.6h-0.1l-0.1-0.2l-0.1-0.4l-0.1-0.4l-0.1-0.3l-0.3-0.3l-0.2-0.1
                l-0.5-0.1l-0.2-0.1l0.2-0.7l0.3-0.4v-0.9l-0.1-0.3l-0.2-0.3l-0.1-0.7v-0.8l0.2-0.5l0.1-0.6l-0.2-0.4l-0.2-0.2l-0.2,0.2l-0.2,0.3
                l-0.1,0.1l-0.6,0.1l0.4-0.7v-0.5l-0.1-0.3l-0.1-0.3l-0.1-0.6l-4.4-3.3l-0.7-0.4l-0.4-0.4l-0.3-0.2l-0.3,0.1l-0.2,0.1l-0.1-0.1
                l0.1-0.3l0.2-0.1l-0.4-0.5l-1.6-1.4l-0.3-0.2l0.2,0.4l1.2,1.1l0.2,0.3l-0.4,0.4l-0.4,0.2l0.1-0.4l-0.1-0.5l-0.1-0.3l-0.4-0.6
                l-0.6-0.3l-0.6-0.3l-0.1-0.4v-0.5l0.4,0.4l0.2,0.1l-0.4-0.7l-0.5-0.6l-1.1-0.5l-0.2-0.2l-0.3-0.5h-0.1l-0.2,0.1l-0.6-0.3l-0.6-0.3
                l-0.4-0.2l-1.7-1.9l-0.9-0.3l-0.1-0.2l-0.2-0.4l-0.2-0.2l-1.1-0.5l-1.4-0.3L973,188l-1.3-0.3l-1.4-1l-0.8-0.7l-0.5-0.3l-0.5-0.1
                l-1.3,0.4h-1.4l-1.3-0.2l-1,0.1l-0.6-0.1l-0.8-0.5l-0.2,0.2l-0.2,0.8l-0.6-0.3l-0.5-0.4l-0.9-0.4l-1.7-0.3l-2-0.6l-1.1-0.2l-1.1-0.1
                l0.1,1.4l-0.1,0.7l-0.4,0.9l-0.4,0.5l-0.1,0.5l1.2,0.3l0.4,1.6l0.5,1l0.3,1v0.7l-1.3,1.7l-1.3,0.2l-1.3,0.1l-0.5-1l-0.3-1.3l-1-0.7
                l-1-0.5l-0.5-0.1l-0.3-0.3l-0.2-1.4l-0.2-1l-0.3-0.4l-0.3-0.6l-0.3-0.2l-0.6-0.1l-1.6,1.5l-0.5,0.4h-0.2l-2.4-0.4l-0.6-0.3l-3.5-0.2
                l-1-0.9l-0.9-0.2l-0.7,0.3l-0.6,0.1l-0.8-0.2l-0.7,0.3l-1.6,0.3l-0.6,0.2l-0.6,0.5l-1.1,1.3l-0.2,0.6v0.8l0.2,1.1v1.2l-0.2,0.4
                l-0.4,0.2l-0.4,1.3l-0.4,0.7l-0.7,0.2l0.4-0.5l0.4-0.4l0.6-1.9l-0.1-0.6l-0.5-0.4l-0.3-0.5l-0.2-1.9l-0.1-1l-0.1-1.2l-0.5-0.4
                l-1.7-0.6l-0.8-0.4l-0.3-0.7l0.3-1v-0.8l0.2-0.6l0.3-0.5l0.1-0.7l-0.1-1l-0.1-0.7l-0.3-0.8l-0.2-0.4l-1.1-1.2l-1.8-1.2l-1.9-0.9
                l-1.7-0.3l-2.1-0.2h-2.2l-0.8,0.5h-1.6l-1.7,0.5l-1.7,0.8h-0.9l-1.9,0.4h-0.8l-2.1-1.3l0.7-0.2l0.3-0.2l-0.9-1.7l-0.5-0.6l-1.2-0.8
                l-0.5-0.1l-0.4,0.2l-1.6,0.8l-0.4,0.4l0.8-1l0.4-0.4l0.4-0.6l-0.1-0.4l-0.1-0.2l-1.8,0.4l-0.1-0.1l-0.3-0.5l-0.2-0.2l-1-0.6
                l-0.7-0.2h-0.8l-0.1-0.2l0.1-0.3v-0.2l0.2-0.3l0.6-0.3l1.7-0.2l0.4-0.5l-0.1-0.5l-0.1-0.4l-0.6-0.9l-0.7-0.7L893,165l-1.6-0.5
                l-2.7-0.3l-0.5,0.1l-0.4,0.3l-0.7,0.9l-1.5,2.5l-0.8,1l-0.8,0.6l-1.7,0.5L882,170l-0.2-0.4l0.1-0.4l0.2-0.3v-0.2l-0.1-0.4v-0.2h0.2
                l0.4,0.3h0.4l1-0.5v-0.7l-0.3-0.4l0.1-1v-0.3l0.1-0.2h0.1l0.7,0.7h0.2l0.3,0.1l-0.6,1.7h0.3l0.8-0.8l0.6-0.8l0.6-1l0.1-0.5l-0.7-0.1
                l-4.4,0.4l-1.6,0.8l-0.5-0.2l-0.4-0.6l0.5-0.1l0.6-0.4l0.5-0.7l0.3-0.1h0.9l0.7,0.3l2.2,0.1l0.1-0.9l-0.5-0.3l-1-0.2l-0.6-0.4
                l-0.8-0.3l-1.8-0.4l-0.7-0.3l-1.7-0.3l-0.5-0.2l-4.1-0.2L872,160l-0.6-0.7L870,159h-0.3l-0.1,0.2l-0.1,0.3l0.9,1.2v0.8l0.3,0.4
                l-1.1,0.6l-0.7,0.2h-2.4l-0.4,0.3l-0.9,1.3v0.6l0.1,0.9h0.7l0.2-0.4l0.3-0.2l1.5,0.1l0.1,0.2l-0.5,0.3l-0.5,0.1l-0.6,1.4l-0.8,0.4
                l0.5,0.2l0.5,0.4l-0.1,1.7l0.7,1.3l0.1,0.6h-1l-0.9,0.4h-0.3l-0.6-1l-0.6-0.6l-0.3-0.1l-0.4,0.6l-0.6-0.4l-0.2,0.1l-0.3,0.3
                l-0.2,0.4l-0.1,0.6l-0.2,0.3v0.4l0.3,0.2h0.2l0.6,0.3l-0.6,0.2l-0.2,0.2l-0.1,0.2l-0.5,0.3l0.2,0.3l0.2,0.2l0.1,0.2l-0.1,0.1
                l-0.4-0.3l-0.4-0.4l-0.7-0.8l-0.3-0.5l-0.6-0.5l-2-1.4l-0.9-0.4l-0.6-0.1l-0.9,0.2l-0.6,0.6l-0.9,0.3l-0.6,0.5l-0.3,0.6l-1.7,0.1
                l-1.2-0.5L849,172l-0.8-1l-0.8-1.3l-0.1-0.1l-0.1-0.3l-0.1-0.7l-0.2-0.5l-0.3,0.3l-0.7,1.5l-0.3,0.7l-0.4,1.4l-0.3,1.2v0.5l0.1,0.4
                l-0.4,0.4l-0.4,0.9l-0.6,1.7l-0.4,0.6l-0.5,0.5l-0.3,0.2H842l-0.3-0.5l-0.2-1.1l-0.2-0.2l-0.2,0.6h-0.4l-0.7-0.5l-0.7-1l-0.7-0.5
                l-1-2.5l-0.5-0.9l-0.3-0.7l-0.6-0.1l-0.2-0.5l0.3-0.4l0.8-0.3l0.6,0.1l-0.5-1l-0.2-0.6l-0.2-0.3l0.1,0.7l-0.1,0.5l-0.2,0.4l-0.4,0.2
                l-1.6-3l-0.9-1.5l-0.5-0.5l-0.3-1l0.2-0.2l1.1,1.2l0.8,0.6l1.3,1.5l1-0.1l0.4-0.7v-1.4l-0.4-1.1l-0.5-0.4l-1.6-0.1l-0.4-0.4l0.3-0.1
                h0.4l0.5-0.3l0.8-0.9l0.4-0.3l-0.1-0.7l-0.6-0.9l-1-0.1l-0.2-0.1l0.4-0.5l0.3-0.3l0.6-0.7l0.1-0.6h-0.1h-0.4l-0.1-0.3v-0.5l-0.4-0.4
                l-0.4-0.3h-0.9v-0.3v-0.3l-0.4-0.2l-0.3-0.4l-0.1-0.3l0.6-0.3l-0.6-0.4l-2-0.7l-0.2,0.2l-0.3,0.9l-0.8,1l-0.6-0.5l-0.1-0.1l-0.1-0.3
                l0.1-0.4l0.1-0.4l-0.1-0.3l-0.1-0.1l-0.4,0.3l-0.6,0.2l-0.3,0.3l-0.5,0.2l0.1-0.7l-2.3-1.9l-0.7-0.4h-0.4l-1,0.4l-0.2,0.2l-0.4,0.6
                l-0.9-0.4l-0.2,0.3l-0.3,1v1l0.1,0.3l0.1,0.5l0.3,0.8l0.3,0.7l-0.1,0.2H822l-0.2,0.3l-0.2,0.6l-0.3,0.8l-0.4,0.4l-0.5-0.1l-1.2-0.5
                L819,158l-0.1,0.4l0.6,0.4l0.1,0.2l-0.2,0.1l-0.4,0.1h-0.8l-0.6-0.2l-0.4-0.6l-0.4-0.1h-1.1l-1,0.3l-1.1-0.4l-1.9,0.1l-0.5-0.1
                l-0.9-0.8l-1.3-0.5l-1.5-1.3l-0.2-1.2l0.2-1h0.8l0.5-0.2l-0.1-0.4l-0.1-0.2l-1.2-0.5l-3.2-0.1l-2.3-0.8l-3.2-0.3l-1.5,1l-2.1,0.2
                l-0.6,0.5l-1,0.3l0.1,0.7l0.5,0.5l0.2,0.1l0.3,0.2l-0.2,0.2l-0.2,0.1l-0.4,0.4l-0.2,0.4l0.1,0.9l-0.1,0.8l-0.4,1.2l-0.3,0.5
                l-0.3,0.7l0.2,0.6l0.3,0.3l0.7,0.3l0.2,0.2l-0.1,0.2l-1-0.2l-0.4-0.7l-0.1-0.4l-0.1-0.6l0.3-0.6l0.3-0.4l0.3-1v-0.9v-2l-0.6-0.4
                l-0.3-0.7l0.6-1.2l0.1-0.6l-0.3-0.6l-0.4-1.3l-0.4-0.8l-0.3-0.3l-0.3-0.2l0.3,1.3l-0.2,0.6l-0.2,0.3l-1.1,0.4h-0.7l-1-0.4l-1.1-0.8
                l-0.3-0.6l-0.2-0.8l0.7-0.4l0.3-0.2l-0.6-0.2l-0.6-0.1l-0.2,1.2l-0.4-0.1l-1.8-0.7l-0.5,0.2l-0.6,0.6l-0.3,0.5l-0.3,0.8l0.1,0.6
                l0.1,0.2l1,0.1l0.8-0.2l0.9-0.5l0.2,0.2l0.2,0.3l-0.3,0.4l-1,0.6l-1.6,1.6l-0.6,0.2l-0.9-0.3l-0.5,0.9l-0.2,0.2l-1.4,0.6l-0.6,0.1
                l-0.2,0.4l-0.4,0.1l0.1,0.5l-0.7,0.6l-1.1,0.1l-0.7-0.1l-1.8,0.4l-0.5,0.3l-0.3,0.4l-0.1,0.6l-0.3,0.5l-1,1.1l-0.9,0.4l-0.7,0.1
                l0.7-0.6l0.8-1.1l1.4-3.4l1.4-0.2l0.3-0.5l1-2.1l0.3-0.3h1.4l1.2-0.7l2.5-3.4l1.2-0.6l0.8-0.8l0.2-0.4l0.1-0.5l-0.1-0.3l-0.1-0.3
                l1.1-0.6l0.4-0.9l1.5-0.9l1.1-1.2l1.6-0.9l0.9-1.2l2.1-1.7l1.9-3l0.3-1.8l-0.5-0.6l-0.4-0.4l-0.3-0.9l-0.2-0.1l-0.6,0.5l-0.6-1.9
                l-0.8-1.1v-0.2l0.1-0.1l0.4,0.2l1.4,1.6l0.7,0.2l0.2,0.2l0.1,0.4l-0.1,0.7l0.2-0.1l0.1-0.3l0.4-1.3l0.3-1.7v-0.8l-0.8,0.3l-0.4-2.6
                l-0.2-0.8l-0.3-0.9l-0.5-0.1l-0.3,0.2l0.2,0.3l0.2,0.5l-0.2,0.5l-0.1,0.2l-0.6,0.6l-0.5,0.1l0.2-0.3l0.2-0.6l-0.2-0.7l-0.2-0.4
                l-0.2-2l-0.6-0.3l-0.3-0.3l-0.4,0.1l-0.6,0.5l0.2-0.5l0.2-0.7l-0.4-0.9l-0.4-0.6l-0.5-0.2l-0.6-0.8l-0.8-0.4l-1.8-0.4l-1.4,0.6
                l-1.7-0.5l-2.1,0.4h-0.8l-0.5-0.2l-0.4,0.2l-0.2,0.7l-0.1,1.1l-0.5,0.6l-0.3,0.1l-1.3-0.2l-0.9,0.5l-0.4-0.4h-0.8l-0.1-0.9h0.5
                l0.3,0.2l0.8-1.9l0.7-1.1l0.7-1.3l-0.4-0.8l-0.9-0.5h-0.4l-0.2,0.2l-1-0.4h-0.5L772,114h-0.3l-0.2-1.2l-0.9,0.1l-3.1-0.1l0.3-0.4
                l1.7-0.5l1.3-0.8l1-1.5l0.9-0.5l-0.2-0.7l-0.2-0.5l-0.5-0.5l-1.1-0.3l-1-0.6l-0.4-0.8l-1.8-1H767l-1.3,1.3l-0.6-0.1l-0.6,0.2
                l-1.5,1.5l-3.1,4l-0.6,1.2l-0.3,0.9l-0.5,0.5l-0.2,1.1v1l0.2,0.5l0.3,0.9l-0.3,1.1l-0.2,0.7l0.2,0.3l0.6-0.1l1.3,0.6l-0.2,0.6
                l-0.8-0.5h-0.7l-0.6-0.6l-1.5,0.6l-1.1-0.1l-1,0.2l-2-0.5l-0.2,0.3l0.5,0.6l0.3,0.6l1,1.3l0.4,0.4l0.6,1.2l0.1,2.4l-0.3,0.6
                l-0.4,0.8l-0.2,0.1l-0.1,0.6l0.5-0.6l0.2-1.2l0.2-0.8l-0.3-0.6l-0.1-0.1l-0.1-0.3l-1.1-0.8l-0.6-0.4l-0.6-0.2l-0.3-0.2l-0.9,0.7
                l-0.4,0.2l-0.5,0.4l-0.3,0.5l-0.7,0.1l-0.1,0.6l-0.4,0.6l-0.4-0.6l-0.4,0.2l-0.9,1l-1.1,0.5l0.1-0.3l0.2-0.8l-0.3-0.2l-1.6,0.9
                l-0.5,0.6l-0.3-0.2l0.9-1.4l0.3-0.8l-0.4-0.4l-1-0.3h-0.6l-0.9,0.3l-1.3-0.4l-0.2,0.5l-0.3,0.1l-0.8-0.2l-0.7,0.3l-0.5,0.5l-0.8-0.5
                h-0.3l-0.4,0.8l-0.4-0.6l-0.3,1.1l0.1,0.8l0.3,0.1l0.2-0.1l0.1-0.4l0.2-0.2l0.6,0.7l0.2-0.4l0.3-0.3l0.3,0.4l1.3-0.4l-0.2,0.5
                l-1.5,0.7l-2.7,0.9l-0.5,0.3l-1.6,0.3l-1,0.8h-1.3l-2.3,0.7l-1.7,1.5l-0.8-0.1l-1.6,1.1l-0.6,0.9l-2.3,1.8l-1.4-0.7l-0.5,0.2
                l-0.2,0.6v0.5h0.6l0.4,0.2l0.5,0.4l-0.1,0.8l-1.1,1.7l-0.5,0.6l-0.6,0.4l-1.3-1.4l-0.2,0.6l-0.2,0.3l-0.5-0.1l-0.3,1l0.9,0.2
                l0.9,0.5l0.8,0.7l0.5,0.8l0.6,0.5l0.3,0.4l-0.9,0.4l-0.7-0.9l-0.4-0.3l-0.4-0.1l-0.6,0.3l-0.5,1.1l0.5,0.4l1.1,0.4l0.4,0.5l1.4,3.2
                l0.4,0.3l0.5,0.1l0.2,0.2l-0.6,0.6l-0.2,0.5l-0.5,0.9l-2.1,0.5l-0.6,0.3l-0.2,0.2l-0.3,0.3l-0.2,0.3l-0.1,0.6l0.2,0.4l0.4,0.4
                l1.7,1.8l-0.1,0.2l-0.5-0.3l-0.6-0.5l-0.6-0.7l-0.8-0.5v-0.4v-0.7V154l0.1-0.3l0.7-0.8l0.8-0.4l0.9-1l0.9-1.1L719,150l-0.2-0.3
                l-0.8-0.1l-1.7,0.4l-1,0.4l-0.5,0.9l-0.7,0.1h-0.3l-1-0.4l-0.9,0.4l-2.1,0.4l-0.3,0.2l-4.8,0.1l-1,0.2l-2.5,0.7l-0.1,0.5l0.1,0.4
                l-0.4,0.6l-0.2,0.6l0.1,0.7v0.6l0.1,0.7l0.2,0.7l0.4,0.4l0.3,0.2l0.2,0.7l-0.2,0.8l-0.3,1l-0.1,0.4l0.2,0.6l0.2,1.2l0.1,0.3l0.8,0.9
                l0.5,0.3l0.9,0.1l0.6,0.2l0.8,0.6l0.3,0.3l0.1,0.2l0.2,1l0.1,0.3l0.9,1.3l0.3,0.2l1.2,0.3l0.4,0.4l0.5,1.3l0.1,0.3v0.5l-0.1,0.4
                l-0.1,0.3l-0.2,0.4l-0.5,1.7l-0.3,1.5l0.5,1l0.7,1.9l0.2,0.8l0.2,1.1l-0.1,0.7l-0.1,0.4l-0.5,0.6l-0.6,0.2L708,183l0.1,0.5l0.1,0.5
                l-0.1,0.4l-0.1,0.1h-0.3l-0.3-0.1l-0.3-0.4l-0.2-0.5l0.2-0.6l0.5-1l0.3-1.5l0.1-1v-1l-0.1-0.7l-0.4-0.5l-0.4,0.1l-0.4,0.4l-0.4,1.7
                l-0.5,1.2l-0.1,0.5v0.4v0.3l-0.1-1l-0.1-0.7l0.3-0.9l0.2-0.9l-0.1-0.6l-0.2-1v-0.5l0.1-0.3l0.1-1.4l0.5-0.3l1.2-1.1l0.2-0.3l0.4-1
                l0.2-0.4l0.2-0.6l-0.4-0.5l-0.3-0.3l-0.6-0.1h-0.6l-1.3,0.5l-1.2-0.1l-0.4-0.3l-1.8-2l-0.1-0.8l-0.1-0.3l-0.2-0.1l-0.6-0.5l-1.5-0.6
                l-1.5-1.5l-2.6-0.1l-0.7,0.2l-0.7,0.5l-0.7,0.9L693,166l-0.4,0.1l-0.1,0.3l-0.1,0.4l0.2,0.3l0.8-0.4l0.7,0.2l0.3,0.4l0.3,0.8
                l-0.1,0.4l-1.1,0.6l-0.6-0.1l-1.4-1.5l-0.5-0.3l-1.3,0.2l-0.8,0.7l-0.3,0.1l0.2,0.7l0.3,1.3l0.3,0.8l0.3,0.4l1.2,0.9l0.7,0.3l1,0.9
                l0.6,0.1l0.6-0.2l0.9,0.5l0.5,1.6l0.3,0.5l0.3,0.3l0.4,0.2h0.2l0.2-0.2h0.5l-0.2,0.4l-0.2,0.1l-1.2,0.2l-0.6-0.2l-0.7-0.5l-1.3-1.6
                l-1.7-0.1l-0.2,0.5l-0.5-0.6l-1.8-0.1l-1.1-0.4l-1.1-0.7l-0.1-0.3v-0.5l0.4-0.6l0.1-0.4l0.1-1.1l-0.7-1.4l0.1-1.3l0.3-0.2l0.4-1.7
                l0.3-0.6l0.1-0.2l0.1-0.4l-0.1-0.5l-0.1-0.3l-0.1-0.7v-0.5V162l-0.4-1l-0.3-1.1l-0.6-0.5h-0.6l-0.2,0.1l-0.2,0.3l0.3,1l0.3,0.9
                l0.1,0.7l0.1,1.2v1.5l-0.1,0.6l-0.2,0.5l-0.5,0.6l-0.9,0.7l-0.5,0.4l-1,0.4l-0.8,0.6l-1.6,3.6l0.2,0.5l0.6,0.6l0.4,0.5l0.2,0.4
                l0.4,1.3l1.6,3.6l0.1,0.6l-0.4,1.1l-0.8,1.4l-0.3,0.8l-0.7,3.1v0.8l0.3,0.7l0.5,0.9l0.2,0.7l-0.3,1.7l0.2,0.4l0.4,0.2l1.1-0.2
                l1.3,0.4l0.7-0.2l1-1l0.4-0.1l1.2,0.1l1.8,0.9l1.9,1.2l0.4,0.4h0.5l0.4,2.1l0.3,1.1l0.1,0.8l0.1,0.9l-0.4,0.2l-0.3,0.1l-0.3,0.2
                l-0.4,1.4v0.7l0.2,1.2l1.6,0.5l1.1,0.3l0.8,0.1l0.1,0.1l0.1,0.2l-0.9,0.1l-1.7,0.1l-0.6-0.1l-0.3-0.1l-0.3-0.4l-0.5-0.4l-0.2-0.3
                l-0.4-0.3l0.2-1.2v-2.8l-0.1-1.2l-0.4-1l-1.1-1.4l-0.4-0.9l-0.4-0.6l-0.4-0.1l-0.6,0.1l-1.5,0.6l-1.3,0.3l-1.5,0.9l-0.3,0.7
                l-0.3,1.8l0.3,0.9l0.3,0.6l0.3,1.1l0.1,0.7v0.7v1l-0.3,0.5l-1.7,2.1l-0.2,0.6L682,207l-0.3,0.6l-0.8,1l-0.5,0.4l-2.6,1.7l-0.1,0.4
                l-0.2,1.2l-0.7,0.5h-0.4l-1-0.6l-0.6-0.2l-0.6-0.1l-2.3,0.2l-1-0.4l-0.8-0.6l-0.8-0.2l-0.6-0.5l-0.1-0.4l-0.1-0.6l-0.1-0.3l-0.1-0.2
                l0.2-0.2l0.4-0.1l1.5,0.1l0.4-0.2l0.2,0.1l0.4,0.5l0.5,0.5l0.5-0.1l0.4-0.4h0.1l0.2,0.1l0.1,0.7l-0.4,0.2l-0.1,0.1l-0.1,0.3l0.5,0.4
                l0.5,0.2l0.6-0.2l0.4-0.4l0.8-0.6h0.6v-0.5l-0.3-0.8l-0.2-0.6l0.8,0.2l0.5-0.5l2.1-4.2l1-0.8l0.3-0.5l0.2-0.7l0.1-0.8l-0.1-0.9v-0.7
                l0.4-0.9l0.6-1l0.4-0.4l-0.1-0.4l-0.1-0.3l-1-1l-1.1-0.8l-0.4-0.5l-0.3-0.7l-0.1-0.9v-0.6l0.1-1.8l0.2-0.8v-2.5l-0.2-3.1l-0.2-0.8
                l0.3-0.6l0.3-0.5l0.1-0.5l0.1-3.1l-0.1-1.2l-0.3-1.6l-1.4-1.4l-0.6-1.3v-0.5l0.1-0.3l0.6-0.5l0.4-0.8l0.3-1.1l0.6-1.7l0.1-0.6
                l0.4-2.4l0.1-1.3l0.1-2l-0.5-0.5l-0.5-0.4l-1-0.4l-0.5,0.1l-0.9-0.8l-0.3-0.1l-2.4,0.2l-1.4-0.1l-0.8,0.2H671l-0.3-0.1V159l0.1-0.2
                v-0.2h-0.2l-0.6,0.3l-1,2.7l-0.6,2.6l-0.6,3.5l-0.4,1.4l-0.6,1.5l-0.9,1.2l-1.2,1.2l-0.8,0.6l-1,0.6l-0.4,1.2l-0.4,0.5l0.1,0.3
                l0.4-0.2l-0.2,0.9l-0.3,0.5v0.3l0.1,0.4l0.3,0.2l0.9-0.3l0.2,0.3l0.2,0.5l-0.1,2l-0.3,1.7v0.6l0.1,0.4l0.1,0.5l-0.3,0.6l-0.2,0.2
                l-0.4-0.1l-0.3,1.4l-0.1,0.8v0.7l0.1,0.4l0.2,0.4l0.1-0.3l0.1-0.5l0.3-0.3l1.6,0.9l0.4,0.4l0.6,0.4l0.2,0.5l0.1,1.5l0.7,1.3l0.5,0.8
                l0.3,0.3l0.3,0.1l0.5-0.3h0.6l-0.3,1l-0.5,1.9l-0.9,1.7l-0.4,0.3l-0.6-0.7l-0.8-0.6l-0.4-0.3l-0.3-0.5l-1.4-1.3l-0.4-0.9l-0.7-0.1
                l-1-0.4l-0.1-0.1l-0.8-0.6l-0.8-0.3l-0.2-0.4l-0.6-0.3l-0.6-0.2l-0.8-0.5l-0.4,0.2l0.1-0.6l-0.9-0.9l-1.1-0.8l-2.3-1.1l-2-0.5
                l-2.4-0.2l-2.1-0.7h-0.3l-0.3,0.2l-0.7,1l-0.8,0.3l-0.3,0.5l0.5,1.1l0.9,2.8l0.5-0.3l0.2,1.2l-0.3,0.7l-0.5,0.8l-0.4,0.4l-0.9,0.2
                l-0.7-0.1l-0.1,0.8l0.2,0.7l-0.1,0.3l-0.1,0.4l-0.1,0.2l-0.1,0.1l-0.3,0.2h-0.3l-0.8-0.4l-0.6-0.3v-1.3l0.3-0.6l0.2-0.1l0.2-0.2
                l-0.4-0.9l-0.3-0.4l-0.1-0.6v-0.3h-0.4l-1.6,0.7l-0.3,0.6l-0.2-0.4l-2,1.9l-0.9,0.7l-0.6-0.1l-0.8-0.4h-1l-0.7-0.2l-0.3,0.1
                l-0.7,0.5l-0.7,0.1l-0.8,0.7l-0.6,0.8l-0.2,1.3l-0.4,0.1l-0.4-0.7l-0.2-0.2l-0.2,0.1l-0.5,0.1l-0.4,0.1l-0.4,0.2h-0.2l-1.1-0.2h-0.3
                l-0.4-0.1l0.1-0.3l0.1-0.2l0.6-0.2l0.3-0.3l0.4,0.2l0.3-0.4v-0.8l-0.4-0.7l0.2-0.6l0.2-0.7l0.1-0.1l0.1-0.3l-0.1-0.2l-0.3-0.1v-0.2
                l0.2-0.1l1.4-0.3l0.3-0.2l-0.9-0.1l-1.1,0.1l-1.1,0.7l-2,1.4l-1,0.9h0.3l0.3,0.1l0.3,0.7l0.2,0.2l-0.1,0.4l-0.5,0.3l-0.2,0.2
                l-0.2,0.1l-0.2-0.1l-0.2-0.2l0.1-0.3l0.2-0.3l-0.4-0.6l-0.2-0.1H621l-1.1,0.5l-0.8,0.6l-0.7,0.3l-0.7,0.1l-0.4,0.2l-0.8,0.8
                l-0.5,0.3l-0.8,0.8l-2.2,1.5l-0.6,0.1l-0.3-0.1l-0.2-0.2l-0.2,0.2l0.2,0.4l0.3,0.7l-0.1,0.4l-0.5-0.1l-1.1,0.3l-1.1,0.5v0.5l0.1,0.4
                l-0.2,0.7l-0.2,0.6l-0.2,1.7l-0.1,0.5l-0.4,0.3l-2.3,0.7h-0.4l-0.2,0.1l-0.1-0.1l-0.4-0.2h-0.6l-0.3-0.1l-0.3-0.3l-0.4-0.7l-0.5-1.4
                l-1.2-0.7l-0.6-0.5l-0.1-0.5l0.1-0.5l1.2-1.6l0.4-0.5l1.8-0.4h0.7l0.7-0.2v-0.9l-0.7-1.1l-0.8-1.3l-0.7-1.4l-1-0.5l-1.2-0.2
                l-2.5,0.3l-0.4-0.1l-1.6-1h-0.2l-0.2,0.1l0.1,0.3l0.1,0.2l2.1,2.1l0.1,0.6l0.1,0.8v0.3v0.3v0.6l-0.5,2.4l-0.5,1.7l-0.2,0.8v0.5
                l0.2,0.5l0.6,0.2l0.6,0.5l0.3,0.7l0.1,0.5v1l0.1,0.9l-0.5,1.3L599,212l-0.3,1.2l0.1,0.8v0.3l-0.1,0.4l-0.2-0.3l-0.2-0.3l-0.3-0.3
                l-0.3-0.1l-0.3,0.1l-0.2,0.2v-0.3l0.3-0.5l-0.1-0.3l-0.1-0.2l-0.9-0.7h-0.6l-0.6,0.1l-0.6-0.1l-0.4-0.4l-0.4-0.2h-0.3l-0.4,0.4
                l-0.8,1.4l-0.9,0.9l-1.1,0.7l-0.8,0.2l-0.2,0.2l-0.5,0.8l-0.5,0.6l-1.4,1l-0.1,0.4l-0.1,0.6l0.1,0.7l0.3,0.6l0.7,1.3l0.4,0.4
                l0.3,0.7l0.2,0.8l-0.1,0.2l-0.6-0.2l-0.4,0.1l-0.6,0.5v0.2l-0.2,0.5h-0.5l-1.4-0.9l-1.2-0.5h-0.2l-0.3-0.2l-0.1-0.2h-0.5h-0.3
                l-0.3-0.2l-1.4-1.5l-1.1-0.6h-0.3l-0.5,0.2l-0.3,1.2L578,222h-0.3v0.6l0.1,0.4l0.1,0.3l0.4,0.4l0.8,1.3l0.4,0.5l0.3,0.2h0.4l0.9-0.2
                l0.3,0.2l0.3,0.3l0.1,0.7l0.2,0.7v0.4l-0.3,0.5l-0.9,0.4l-0.5,0.5h-0.2l-1.1-0.6l-0.7-0.2l-1-0.4l-0.2-0.2l-0.4-1l-1-0.9l-0.4-0.3
                l-0.6,0.2l-0.4-0.1l-0.7-0.5l-0.5-0.8v-1l0.1-0.2l0.1-0.1l-0.3-0.3v-0.7l-0.1-0.5l-0.4-1.2l-0.4-0.7l-0.4-0.8l0.4-0.4l0.2-0.4l0.3-1
                l0.1-0.4l0.1-0.4V216v-0.3l-0.3-0.6l-0.8-1.2l-0.8-0.7l-1.5-0.7H569l-0.2-0.1l0.3-0.1l0.3-0.3l0.2-0.4l-0.4-0.2l-0.3-0.1l-0.5-0.3
                l-0.1-0.3l0.1-0.2l-0.1-0.5l-0.7-0.2l-0.2-0.1v-0.2l-0.5-0.6l-0.6-0.6l0.1-0.6l-0.5-0.5l-0.4-0.3l-0.6-0.1l-0.2-0.2l1.4,0.1l1.5,0.5
                l-0.1,0.2l-0.1,0.3l0.4,0.8l0.4,0.5l0.9,0.6h0.1l0.2-0.1l0.5,0.2l0.4,0.3h0.7l0.9,0.4l-0.1,0.2l-0.1,0.1l0.2,0.4l0.4-0.1l0.6-0.4
                l1.5,1.3l0.4,0.2l2.4,0.7l1.1,0.1l0.6,0.1l0.9,0.3l0.9,0.7l0.8,0.2l1.4,0.2h0.7l1.8-0.4l2.3-1.2l1.2-1l1.9-2.7l0.2-0.6l0.2-1.5v-0.6
                l-0.3-0.1l-0.4-0.4V205l-0.2-0.4l-0.3-2l-0.6-0.2l-0.3-0.2l-0.4-0.1l-0.4-0.3l-0.5-0.8l-0.5-0.6l-0.4-0.7l-0.2-0.3l-0.2-0.1l0.1,0.4
                l0.1,0.4l-0.5-0.2l-2.1-1.9l-0.4-0.1l-0.1,0.2l-0.6-0.3l-0.2-0.5l-1.8-2.1l-3.1-2.4l-2.1-1.5l-1.6-0.7l-0.2,0.1l-0.2,0.3l-0.5,0.1
                l-0.4-0.1l-1.4-0.6l-0.3-0.1h-1.5l-0.2,0.2l-0.5,1.3l-0.3,0.3l-0.5,0.2l0.2-0.4l0.4-0.3l0.2-0.9v-0.4l0.1-0.5v-0.4l-0.2-0.1
                l-0.4,0.1l-0.8,0.5l-0.1-0.1l0.2-0.5l-0.1-0.2l-0.3,0.1l-0.7-0.2l-0.7,0.1l-0.1-0.6l-0.5-0.3l-0.2-0.3l0.2-0.3l1.6,0.6h0.5l0.2-0.2
                l0.1-0.4v-0.4l-0.2-0.2l-1.1-0.4l-0.5-0.5l-1.1-0.7v0.3l0.2,0.6l-0.1,0.2l-0.3-0.2l-0.3,0.1l-0.3,0.8l-0.3,0.2l-0.3,0.1l-1.1-0.6
                l-0.5-0.1l0.2,1.1v0.4l-0.1,0.4l-0.1,0.2l-0.2,0.1h-0.5l-0.7-0.4l-0.4-0.4H560h-0.1l0.1,0.4v0.3l-0.1,0.3l-0.1,0.3l-0.1,0.2
                l-0.3,0.3l-0.5,0.3l-1.2,0.5l-0.1,0.2l-0.4,1.4l-0.1,0.2l-0.1,0.2l-0.4,0.2l-0.2,0.1l-0.6,0.4l-0.4,0.3L555,194l0.1,0.2l0.7,0.1
                l0.1,0.1l0.1,0.1v0.2l-0.1,0.3l-0.8,1.8v0.4l0.2,1l0.4,1.2l1.1,0.5l0.8,0.4l0.5,1l0.8,1.3l0.4,0.5v0.1l-0.1,0.9l-0.5,0.9l-0.5,0.7
                l-0.5,0.9l-0.4,0.8l-0.4,0.9l-0.1,0.3v0.3l0.1,0.3l0.6,1.1l0.2,0.6l0.3,0.6l0.2,0.6l0.1,0.6l0.2,0.6l0.1,0.3l0.2,0.4l0.3,0.6
                l0.1,0.5l0.4,1.6v0.4v0.3l-0.2,0.1H559l-0.4,0.2v0.1l0.3,0.4l-0.3,0.6v0.9l-0.3,0.5v0.1v0.1v0.1l0.5,0.1v0.1v0.3v0.2l-0.3,0.2
                l-0.3,0.3l-0.1,0.2v0.2l0.1,0.4l0.2,0.4l0.2,0.3l0.8,0.3l0.1,0.2v0.3v0.3l-0.4,0.6v0.2l0.2,0.5l0.2,0.5l0.8,0.5l0.3,0.3l0.1,0.2v0.4
                v0.4l-0.1,0.4l-0.2,0.5l-0.6,0.9l-0.6,0.4v0.1l0.2,0.3l1,1.2l0.7,0.5l0.9,0.7l0.6,0.6l0.2,0.4l0.2,0.5l0.3,0.4l0.2,0.3l0.1,0.2v0.2
                l-0.3,0.7l-0.2,0.5l-0.3,0.8l-0.3,0.5l-0.7,1l-1,1.2l-0.2,0.4l-0.5,0.6l-0.8,1.2l-0.2,0.3l-0.7,1l-0.3,0.3l-0.2,0.3l-0.7,0.9
                l-0.7,0.7l-0.7,0.6l-0.2,0.3l-0.3,0.2l-0.3,0.2l-0.1,0.1l-0.7,0.9l-1,1.2l1.1-0.2l0.9-0.6l0.2,0.1l0.2,0.2v0.4v0.3l-0.4-0.3l0.1,0.3
                l0.3,0.6l0.5,0.2l0.7,0.8l0.8,0.1l0.6-0.1h0.4l0.4,0.4l0.3,0.5l0.2,0.1l0.3,0.3v0.3l-0.1,0.2l-1.3-0.5l-1.5-0.2l-0.2,0.2l-0.2,0.3
                l-0.1,0.3l-0.1,0.1l-0.2,0.1h-0.3l-0.4-0.1l-0.2-0.2l-0.2,0.2l-0.1,0.4l-0.2,0.2l-0.3-0.2l-0.2-0.3H554l-0.1,0.3l0.1,0.4v0.5
                l-0.1,0.4l0.1,0.2l0.2,0.3l0.1,0.2l-0.1,0.1l-0.2,0.1v0.1l-0.1,0.1h-0.2l-0.1,0.1l-0.1,0.5l-0.3,0.8l-0.4,0.6l-0.3,0.3l-0.1,0.2
                l-0.1,0.3v0.3l0.3,1.6v0.3l-0.1,0.3v0.3v0.3l0.2,0.4l0.2,0.7l0.1,0.4l0.1,0.2l0.1,0.1v0.1v0.1l-0.1,0.1l-0.6,0.2l-0.1,0.2l-0.1,0.2
                l-0.3,0.3l-0.1,0.3v0.3v0.1h0.3l0.1,0.1l0.1,0.4l0.4,0.3l0.3,0.3l0.1,0.1v0.2v0.3v0.1l-0.1,0.2L553,268v0.4l-0.2,0.7l0,0l0.4-0.1
                l0.1,0.1l0.1,0.1v0.4l0.1,0.2l0.1,0.3v0.2l0.3,0.3v0.2l0.2,0.6l0.1,0.4v0.3l-0.1,0.4l-0.1,0.2l0.4,0.4h0.1l0.2-0.2l0,0h0.4l0.2,0.2
                l0.2,0.3l0.1,0.2l0.2,0.1l0.4-0.3l0.2-0.1h0.2l0.5,0.3l0.3,0.1l0.1,0.1v0.2l-0.1,0.2l-0.1,0.3l0.2,0.3l0.2,0.2l0.4-0.3l0.2-0.1h0.2
                l0.2-0.1l0.2-0.2l0.2-0.1h0.3h0.5l0.6,0.3l0.1,0.1l0.3,0.3l0.1,0.2l0.1,0.1l0.2,0.2l0.2,0.1h0.2l0.1,0.1l0.1,0.1v0.2v0.6l-0.1,0.2
                l-0.1,0.1v0.1v0.1l0.2,0.3l0.2,0.4l0.1,0.2v0.2l-0.3,0.5l-0.1,0.1l-0.1,0.3v0.3v0.1l0.5,0.4l0.4,0.2l0.1,0.1v0.1l-0.2,0.5v0.1
                l0.3,0.2l0.2,0.3l0.2,0.5l0.3,0.5l0.6,0.4l0.5,0.3l0.1,0.1v0.1v0.3l-0.1,0.4l-0.1,0.2l0.2,0.1h0.5l0.6,0.1l0.7,0.4v0.2l-0.1,0.2v0.2
                l0.1,0.2l0.6,0.5l0.1,0.1v0.2v0.2H567l-0.2,0.1l-0.3,0.2l-0.1,0.3l-0.5,0.4l-0.3,0.2h-0.2l-0.6-0.1l-0.2-0.2l-0.1-0.2l-0.2-0.1H564
                h-0.4l-0.1,0.1l-0.1,0.2l-0.2,0.4l-0.1,0.2l0.1,0.1l0.2,0.3l0.3,0.3l0.3,0.3l0.1,0.2v0.1l-0.1,0.2v0.3l0.3,0.4l-0.1,0.1v0.5v0.5
                l0.1,0.1l0.1,0.1l0.1,0.2l0.2,0.4v0.1h0.1l0.3,0.1l0.3,0.1h0.2h0.2l0.3-0.1l0.2-0.1l0.2-0.7l0.1-0.1l0.1-0.1h0.2l0.4,0.1l0.5,0.1
                h0.3l0.7-0.4l0.4-0.1l0.5,0.1h0.5l0.3-0.1l0.2,0.1l0.3,0.3l0.3,0.4l0.3,0.8l0.8,0.9v0.2l-0.1,0.1l-0.4,0.1l-0.3,0.1v0.2l0.1,0.2
                l0.2,0.2v0.3v0.3l0.1,0.3l0.1,0.1v0.1l-0.1,0.2v0.1l0.1,0.1h0.7l0.4,0.2l0.3,0.1h0.1l0.3-0.1l0.3-0.1h0.2l0.1,0.1l0.1,0.3l0.1,0.3
                l0.1,0.1h0.2h0.1l0.1,0.1l-0.1,0.2v0.2l0.1,0.2l0.1,0.5l0.1,0.1l0.1,0.2v0.2l-0.1,0.2l-0.1,0.1l0.1,0.3l0.2,0.4l0.2,0.1l0.1,0.3
                l0.2,0.1l0.3-0.3l0.3-0.1l0.3,0.1h0.3l0.2,0.2l0.2,0.2l0.2,0.1l0.2-0.1l0.4,0.1l0.2,0.2l0.2,0.1l0.2-0.2l0.2-0.2l0.6-0.2l0.4-0.1
                h0.1l0.2-0.1l0.2-0.1h0.2l0.2,0.3l0.2,0.2l0.1,0.3l0.3,0.5l0.7,0.6l0.3,0.2h0.2h0.1v-0.1v-0.3l0.1-0.1h0.1l0.5,0.4h0.3h0.3l0.4,0.3
                l0.4,0.3h0.3l0.2-0.1l0.2-0.1l0.1,0.1l0.1,0.2l0.2,0.2h0.2h0.3l0.5,0.3l0.4,0.3h0.3l0.2-0.1h0.2l0.1,0.1v0.1l-0.1,0.2v0.3l0.2,0.3
                v0.3l-0.1,0.2l-0.1,0.2l-0.3,0.3l-0.2,0.3l-0.4,0.1l-0.2,0.1l0.1,0.2l0.1,0.2l0.3,0.2l0.4,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.3-0.1
                l-0.1,0.1l-0.1,0.2l-0.1,0.3l-0.1,0.3l0.3,0.1l0.2,0.1l0.1,0.2l0.1,0.3v0.2l-0.1,0.1v0.1l0.1,0.1h0.1l0.1,0.1v0.1l-0.2,0.3l-0.2,0.6
                l-0.1,0.3v0.3l-0.1,0.2h-0.2h-0.8h-0.7h-0.3h-0.4l-0.2,0.1l-0.3,0.5l-0.2,0.2l-0.4,0.2l-0.4,0.1l-0.2,0.2l-0.1,0.3v0.3v0.1l-0.1,0.1
                v0.1v0.1h0.1h0.1v0.1v0.1l-0.1,0.1l-0.1,0.2v0.2v0.2l0.8-0.3l0.3-0.3l0.5-0.1l-0.1,0.1l-0.3,0.1l-0.3,0.3h0.3l0.7-0.1l0.3-0.4h0.5
                l0.1,0.3l0.1,0.4v0.1L586,314l-0.4,0.1l-0.9,0.5l-0.5,0.1l-0.5,0.2l0.1,0.3v0.3l-0.4-0.1l-0.3-0.1h-0.2l-0.5,0.3l-0.3-0.1h-0.3
                l0.1,0.4l0.3,0.5l0.2,0.1h0.3l0.7,0.6l0.5,0.6h-0.3l-0.2-0.1H583l-0.1,0.4l-0.1,0.3v-0.1V318l-0.2-0.1l-0.2,0.2l-0.3,0.8l-0.5,0.6
                l-0.2,0.4v0.3l0.1,0.1h0.1v0.2l-0.1,0.2l-1.1,0.3l-0.1,0.2l-0.3-0.1l-0.4-0.3l-0.3-0.2l-0.2,0.1l-0.2,0.2l0.1,0.1h0.1l0.4,0.2
                l-0.2,0.2l-0.7,0.3v0.1l0.1,0.1l0.8,0.2l0.7,0.4l0.2,0.3l0.2,0.5l0.2,0.2l0.2,0.2l0.2,0.1h0.4l0.4-0.1l0.9,1.1l0.4,0.2l0.9,0.2
                l0.2,0.1l1.7,1.5l0.5,0.7l1,1l0.3,0.2l0.1-0.2l0.2-0.3l0.2-0.1l0.5,0.1l0.5,0.1l0.4-0.1l0.4,0.2l0.4,0.2l0.4,0.2l0.8,0.2l0.3,0.2
                l0.3,0.2l1.3,0.1h0.1h0.1l0.4-0.1h0.4l0.4,0.3h0.3h0.3l0.4,0.1l0.3,0.2v0.2l0.2,0.2l0.7,0.4l0.6,0.2l0.2,0.1l0.4,0.2v0.1v0.1
                l-0.1,0.2v0.2l0.1,0.1l0.2,0.1h0.4l0.1-0.1l0.3-0.1l0.3-0.1l0.4-0.2l0.5-0.2h0.2l0.2,0.1l0.1,0.1l0.2,0.4l0.2-0.5h0.1l0.2,0.1
                l0.4,0.1l0.2,0.1l0.1,0.1l0.4,0.5h0.6l0.3,0.1l0.1,0.1l0.1,0.1l-0.1,0.4l-0.2,0.5v0.1l0.2,0.2l0.3,0.2l0.2,0.1l0.1,0.1l0.3,0.1
                l0.3,0.1h0.1l0.2,0.1l0.4,0.2l0.1,0.1l0.3,0.1v0.2h0.1h0.1l0.2-0.1l0.2,0.1l0.2,0.3l0.3,0.3l0.2,0.2l0.1,0.1l0.2,0.1l0.2,0.1
                l0.2,0.2l0.2,0.5l0.2,0.1l0.6,0.2h0.2l0.6,0.1h0.2l0.3-0.5l0.3-0.5l0.2-0.1l0.4-0.2l0.3-0.2l0.1-0.2l0.2-0.4l0.2-0.2l-0.3-0.2
                l-0.1-0.1l-0.1-0.1l-0.2-0.5l-0.2-0.4l-0.4-0.7l-0.7-1l-0.1-0.1l-0.1-0.1l-0.1-0.5l-0.2-0.4l-0.3-0.2l-0.1-0.1l-0.1-0.1l0.1-0.7
                v-0.2l-0.1-0.4l0.1-0.5l0.2-0.7l0.2-0.5l0.1-0.3l-0.2,0.2l-0.3,1.1l-0.1-0.9l-0.2-0.8l-0.2-0.4l-0.2-0.3l-0.3-0.3l-0.3-0.3l-0.3-0.2
                l-0.5-0.1l-0.1-0.1V325v-0.2l0.1-0.4l0.2-0.2l0.3-0.3l0.1-0.2l0.1-0.2l0.1,0.2l0.1,0.1l0.1-0.4l0.1-0.3l0.2-0.2l0.2-0.5l0.2-0.3
                l0.1-0.3l0.1-0.5h0.1l0.1-0.1l0.1-0.1v-0.2v-0.3l-0.2-0.3l0,0l0.1,0.1l0.2,0.2l0.2,0.2v-0.3l0.1-0.1l0.2,0.1h0.2l0.6-0.2l0.3-0.1
                l0.3-0.2l0.6-0.6h0.1h0.1l0.1,0.1l0.1,0.1h0.1l0.1-0.1l-0.1-0.2v-0.2v-0.2l0.2-0.1h0.2l0.8-0.4l0.1-0.2V318h0.3v-0.2h-0.1l-0.6-0.4
                l-0.5-0.3l-0.5-0.2h-0.1l-0.1-0.1l-0.1-0.2v-0.2v-0.1l0.1-0.1h0.1h0.1l0.1,0.1l0.2,0.1h0.3l0.2-0.1v-0.2l-0.4-0.7l-0.3-0.6l-0.3-0.7
                l-0.1-0.2l-0.4-0.6l-0.4-0.6l-0.3-0.5l-0.2-0.2l-0.5-0.1l-0.9-0.1l-0.3-0.1l-0.3,0.1l-0.3,0.1l-0.3-0.2l-0.2-0.4L608,311l0.1-0.3
                v-0.4l-0.2-0.4l-0.2-0.2l-0.4-0.2l-0.5-0.4l-0.1-0.7l0.3-1l0.4-0.7l0.3-0.3l0.1-0.3v-0.2v-0.2l-0.2-0.2l-0.3-0.2l-0.1-0.3l0.1-0.6
                l0.2-0.8l0.3-0.7l0.4-0.4l0.3-0.3l0.1-0.3v-0.4v-0.3l0.1-0.2l0.1-0.2l0.1-0.2l0.2-0.2h0.3l0.3,0.2l0.4,0.4l0.6,0.8l0.3,0.5l0.1,0.2
                l0.3,0.3l0.3,0.1l0.5-0.2l0.4-0.2l0.1-0.1l0.1-0.2l-0.1-0.4l-0.1-0.2l-0.1-0.3l-0.1-0.5l-0.1-0.9l-0.1-0.3h0.1l0.2,0.1h0.2l0.3-0.2
                l0.4-0.4l0.7-0.6l0.2-0.4l0.1-0.4l0.2-0.3l0.5-0.1l0.4-0.1l0.3-0.3l0.5-0.3l0.4-0.2l0.2-0.1l0.1-0.2l0.5-0.6l0.4-0.4l0.3-0.4
                l0.1-0.2h0.2l0.4,0.2l0.4,0.2l0.3,0.2l0.1,0.2v0.2l0.1,0.1h0.1h0.2h0.4l0.5-0.3l0.7-0.5l0.6-0.2l0.3,0.1l0.3,0.4l0.2,0.4l0.2,0.1
                h0.1l0,0l0.3-0.1l0,0h0.2l0.2,0.1h0.4l0.5-0.1h0.1l0.3,0.1l0.3,0.2l0.2,0.2l0.4,0.7l0.2,0.2l0.5,0.2l0.2,0.2l0.3,0.3l0.1,0.2
                l0.3,0.4l0.3,0.6l0.1,0.5l0.1,0.4l0.1,0.2h0.1l0.1-0.1l0.1-0.2v-0.3v-0.5l-0.1-0.4l-0.1-0.1l-0.1-0.2l0.1-0.2l0.2-0.1l0.2,0.1
                l0.4,0.3l0.4,0.3l0.5,0.6l0.5,0.4l0.5,0.3l0.4,0.1l0.3-0.1l0.4-0.2l0.3-0.3l0.2-0.3l0.1-0.3l0.5-0.4l0.5-0.4l0.2,0.1l0.1,0.1
                l0.5-0.2l0.2-0.1l0.5-0.1l0.5,0.1l0.4,0.4l0.4,0.3h0.3l0.3-0.1l0.2-0.2l0.1-0.3l0.2-0.2l0,0l0.6,0.1h0.4l0,0l0.5,0.1l0.5,0.4
                l0.3,0.5l0.4,0.6l0.2,0.2l0.3,0.1h0.2l0.3,0.1l0.8,0.1l0.1,0.1l0.1,0.1l-0.1,0.3l0.1,0.1l0.6-0.2l0.2-0.2l0.2-0.5l0.2-0.5l0.1-0.2
                h0.1l0.2,0.1l0.2,0.3l0.3,0.3l0.4,0.1h0.2h0.4l0.9-0.1l0.8-0.4l0.5-0.4l0.2-0.6l0.1-0.7l0.2-0.4l-0.1-0.4l-0.4-0.4l-0.1-0.1l-1-0.2
                l-0.1-0.2h-0.1l-1-0.4l-0.5-0.2L645,295l-0.1-0.3l-0.3-0.3l-0.6-0.3l-0.1-0.2l0.1-0.2l0.3-0.1l0.2-0.1l0.5-0.5l0.2-0.1h0.5l0.4-0.4
                l0.3-0.2l0.2-0.3v-0.3l-0.4-0.8l-0.1-0.5l0.1-0.3l0.3-0.3l0.1-0.2l0.2-0.3l0.1-0.2l0.4-0.1h0.5l0.4,0.1h0.5l0.5,0.1l0.2,0.1l0.2-0.1
                l0.1-0.2v-0.2l-0.2-0.2l-0.7-0.3l-0.3-0.3h-0.2l-0.4-0.1l-0.4-0.2l-0.3-0.1l-0.1-0.2l0.1-0.3l0.1-0.2l0.2-0.1h0.3l0.3-0.1l0.1-0.1
                v-0.1v-0.1l-0.1-0.1h-0.2l-0.2,0.1l-0.2,0.1l-0.4-0.2l-0.3-0.2v-0.2l0.2-0.3l0.1-0.2v-0.3v-0.3l0.1-0.4l0.2-0.3l0.3-0.1l0.7,0.3
                l0.9,0.2h0.2v-0.1l0.1-0.1h1.3l0.2-0.1l0.1-0.1l1.2-0.2l0.1-0.2l0.2-0.2h0.3l0.3-0.1l0.5-0.2l0.3-0.1h0.1h0.4l0.4-0.1l0.1-0.1
                l0.1-0.1l0.4-0.2l0.7-0.2l0.2,0.1h0.4l0.4-0.1l0.3-0.1l0.2,0.1l0.3,0.1l0.2-0.1l0.1-0.4l0.1-0.4l0.2-0.2l0.2-0.1l0.2-0.1l0.1-0.1
                h0.6l0.6-0.4l0.1,0.2h0.8l0.9-0.2l0.6-0.1l1-0.2l0.4-0.2l0.6-0.1l0.6-0.1l0.4-0.3h0.3h0.4l0.2-0.1l0.2-0.1l0.1-0.2l-0.1-0.3
                l-0.1-0.2l0.3-0.1h0.4l0.2-0.1l0.5-0.5l0.4-0.2l0.4-0.2l0.8,0.1l0.7,0.1l0.7,0.2l0.4,0.3l0.3,0.2l0.3,0.1l0.3,0.1l0.3-0.1l0.2-0.1
                l0.1-0.2l0.2-0.1l0.7-0.1l0.1,0.2l0.3,0.7l0.2,0.9l0.4,1.1l0.2,0.6l-0.1,0.3v0.4v0.4l-0.3,0.5l0.1,0.2l0.7,0.3l1-0.1l0.6-0.2
                l0.3,0.1l0.2-0.1l0.1-0.4l0.2-0.1l0.2,0.3l0.2,0.4l0.2,0.3v0.3l0.1,0.4l0.1,0.1l0.2-0.2l0.2-0.1v-0.1l-0.1-0.2V284l0.1-0.1l0.1-0.1
                h0.3l0.5,0.1l0.6,0.6l0.3,0.1h0.1h0.3l0.4-0.2l0.2-0.2l0.1-0.1h0.1l0.1,0.1v0.2l-0.1,0.3l-0.3,0.3l-0.4,0.3l-0.3,0.5l-0.1,0.5
                l0.1,0.3l0.1,0.2v0.2h0.1l0.2-0.1l0.5-0.5l0.2-0.1l0.4-0.1h0.6l0.4,0.2l0.2,0.2l0.2,0.2l0.1-0.1l0.1-0.2v-0.2l0.1-0.2l0.6-0.5
                l0.4-0.3h0.1l0.3,0.1h0.2l0.5-0.3l0.4-0.4v-0.2v-0.2l0.1-0.1l0.6-0.1h0.1l0.5-0.3l0.7-0.4l0.4-0.3l0.6-0.1l0.1-0.1l0.2-0.2l0.4-0.2
                h0.2l-0.1,0.6l-0.2,0.7l-0.1,0.2h-0.7v0.2l0.1,0.3l0.1,0.2l0.1,0.1l0.2,0.2l0.7,0.6l0.9,0.7l0.9,0.8l0.7,0.6l0.3,0.3l0.2,0.2
                l0.5,0.8l0.5,0.8l0.8,1.3l0.7,1.3l0.8,1.4l0.4,0.8l0.9,1.7l0.2,0.5l0.5,1l0.4,0.9l0.4,0.8l0.2,0.1v-0.2v-0.1l0.1-0.1l0.3-0.2
                l0.1-0.1h0.2l0.2-0.1l0.1-0.2l-0.1-0.4V298l0.1-0.2l0.1-0.1l0.2-0.1h0.2l0.1-0.2l0.2-0.1h0.2h0.2l0.2,0.2l0.1,0.2h0.2h0.3v0.2
                l-0.1,0.3l-0.1,0.3v0.2l0.1,0.1l0.5-0.1h0.2l0.1,0.2l0.1,0.2v0.2l-0.1,0.2l0.1,0.1h0.5l0.3-0.1h0.5l0.5,0.2h0.3l0.3-0.1l0.5,0.1
                l0.3-0.2l0.2-0.2l0.1-0.2l0.1-0.1h0.4h0.3l0.2-0.3l0.2-0.1h0.3h0.2l0.6,0.3l0.4,0.2l0.4,0.3l0.2,0.2l0.2,0.4l0.3,0.3l0.2,0.4
                l0.1,0.4l0.2,0.7l0.2,0.2h0.2l0.3,0.1l0.3,0.1l0.7,0.5h0.2l0.2,0.1v0.2l-0.1,0.3l0.1,0.2l0.2,0.3l0.2,0.3l0.2,0.4l0.1,0.2l0.2,0.1
                l0.2-0.1l0.4,0.1l1.1,0.2h0.1l0.1,0.2h0.2h0.2h0.2l0.2-0.2l0.1-0.2l0.4-0.2l0.3-0.3l0.2-0.3h0.2l0.1,0.1v0.2l-0.2,0.2l-0.1,0.2v0.2
                h0.2l0.3,0.3l0.4,0.7l0.1,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.3l0.1,0.3h0.3l0.2-0.1l0.1-0.1h0.2l0.3-0.1l0.3-0.1h0.1l0,0h0.3
                l0.1-0.1l0.1-0.1l0.2-0.2l0.1-0.2v-0.4l0.2-0.3l0.4-0.1h0.2h0.2h0.3h0.2l0.1,0.1l0.2,0.1h0.2l0.1-0.1V305l0.1-0.1l0.1,0.1l0.1,0.1
                h0.1l0.3-0.1l0.2-0.1l0.1-0.3l0.1-0.1l0.2,0.1h0.3l0.2-0.2l0.3-0.2l0.2-0.1V304l-0.1-0.3v-0.3l0.3-0.2h0.4l0.3-0.1l0.1-0.4l0.1-0.1
                h0.1l0.3-0.1l0.2-0.1l0.2-0.1l0.4-0.2h0.4l0.2-0.2l0.1-0.2l0.2-0.1l0.2-0.2l0.3-0.2h0.1l0.5-0.1h0.2h0.1h0.2l0.1-0.2l0.2-0.2
                l0.2-0.1l0.1-0.2l0.2-0.2l0.3-0.1h0.4h0.4h0.2l0.2-0.3v-0.2v-0.2l0.2-0.1l0.2,0.3l0.2,0.2l0.3,0.2l0.1,0.2h0.2l0.2-0.1l0.1-0.3h0.2
                h0.2l0.1,0.1v0.1l0.1,0.3l0.3,0.2h0.3h0.1h0.3h0.3l0.3,0.1h0.1h0.4h0.5h0.2l0.5,0.1l0.1,0.2l0.1,0.5l0.1,0.4v0.4l0.1,0.2l0.2,0.1
                l0.1,0.1l0.2,0.2l0.1,0.3h0.2l0.1-0.1h0.3h0.3l0.2,0.2l0.1,0.2l0.2,0.1h0.2h0.5h0.2l0.2,0.1h0.2l0.1-0.1l0.4-0.1l0.2-0.2h0.2
                l0.1,0.1l0.1,0.1l0.2-0.1l0.1-0.1h0.1l0.1,0.1l0.3,0.1l0.2,0.2h0.2l0.2-0.1h0.1l0.1,0.1l0.2,0.1l0.1-0.1l0.2-0.1l0.8,0.1l0.2,0.2
                l0.1,0.1l0.1,0.2l0.2,0.1h0.4l0.2-0.1l0.3-0.3l0.1-0.3l0.2-0.1h0.2h0.2h0.2l0.2-0.2l0.2-0.1l0.3-0.3l0.1-0.1l0.1-0.3l0.1-0.2
                l0.1-0.3l0.1-0.5v-0.3v-0.2l-0.2-0.1h-0.2l-0.2-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.3v-0.2v-0.2l-0.1-0.1l-0.2-0.2l-0.1-0.2v-0.3l0.2-0.5
                v-0.2v-0.1v-0.1l0.1-0.3l0.1-0.1l0.1-0.3l0.2-0.2h0.2l0.1-0.1v-0.3l0.1-0.3l0.1-0.2l0.1-0.2l0.8-0.4l0.3-0.5l0.1-0.2l0.1-0.5
                l0.1-0.2l0.2,0.1l0.2,0.3h0.2l0.2,0.2l0.6,0.3l0.4,0.1h0.2l0.3,0.1l0.2,0.2l0.4,0.3l0.3,0.1h0.5h0.7l0.2,0.1l0.5,0.2l0.5,0.3
                l0.5,0.2l0.4,0.2l0.2,0.2l0.2,0.1l0.2-0.1h0.3l0.7,0.2l0.4,0.2l0.4,0.1l0.1,0.2v0.2v0.2v0.5l0.1,0.3v0.3v0.3v0.3l0.1,0.2l0.1,0.1
                l0.1,0.2v0.2v0.1v0.2l0.1,0.2h0.2h0.2l0.2,0.3l0.4,0.3l0.2,0.1l0.3,0.1l0.5,0.1h0.3l0.2,0.1l0.2,0.3l0.3,0.1l0.2,0.1l0.4,0.1
                l0.3-0.1l0.2-0.1h0.1l0.3,0.1h0.3l0.3-0.1l0.2-0.2l0.3-0.3l0.3-0.1h0.4l0.2-0.1l0.8-0.2h0.3l0.3-0.2l0.2-0.1l0.3-0.1l0.4,0.1
                l0.4,0.1h0.5l0.3,0.2l0.2,0.2l0.4,0.1l0.4-0.1h0.6l0.4,0.1l0.4,0.3l0.2,0.2l0.3,0.5l0.3,0.2l0.3,0.2h0.3h0.8l0.4,0.1l0.4,0.1
                l0.1,0.1v0.3v0.5v0.2l0.1,0.2h0.1l0.1,0.2l0.2,0.1l0.3,0.2l0.5,0.6l0.3,0.2l0.3,0.1l0.3-0.1h0.5h0.9l0.6,0.2l0.2,0.1l0.6,0.1
                l0.7,0.1l0.6,0.2l0.3-0.2h0.3l0.3,0.1l0.3,0.2h0.2l0.3-0.1l1.1-0.6l0.4-0.2l0.3,0.1l0.2-0.1l0.2-0.1l0.5-0.1h0.3l0.3-0.1h0.4
                l0.8-0.4l0.3-0.1l0.6,0.1l0.3-0.1l0.3-0.2l0.4-0.2l0.1-0.3l0.2-0.5l0.4-0.3l0.4-0.3l0.4-0.3l0.4-0.2l0.4-0.2l0.5-0.5l0.4-0.2
                l0.2-0.1l0.3,0.1l0.5,0.1h0.3l0.2,0.1l0.4,0.2l0.3,0.2l0.3,0.3l0.5,0.5l0.3,0.2l0.2,0.1h0.4h0.4l0.2-0.1l0.4-0.2l0.6-0.3h0.2
                l0.4,0.1l0.6,0.3l0.2,0.2l0.1,0.2l0.6,0.4l0.4,0.2l0.6,0.3l0.6,0.1l0.6,0.3l0.3,0.1h0.2l0.9-0.8l0.7-0.7l0.9-0.5l0.6-0.1l0.5-0.1
                l0.3-0.2l0.2-0.4l0.1-0.5l-0.1-0.3l-0.3-0.1l-0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.3l0.2-0.3l0.3-0.3l0.2-0.3v-0.4l0.2-0.4l0.3-0.4
                l0.2-0.3v-0.3l0.2-0.4l0.4-0.7l0.3-0.8l0.5-0.6l0.8-0.6l0.5-0.6l0.2-0.6v-0.5l-0.2-0.4v-0.4l0.1-0.4l-0.1-0.3l-0.4-0.2l-0.5-0.1
                l-0.5,0.1l-0.3-0.1l-0.1-0.4l0.1-0.3l0.3-0.2l0.6-0.6l0.8-1l0.8-0.5l1.2-0.2l1-0.3l1-0.3l0.7-0.2l0.1,0.1h0.4l0.6-0.1l0.6-0.1
                l0.6-0.2l0.4-0.1l0.3,0.1h0.2h0.1h0.1l0.1-0.1l0.4,0.2l0.7,0.5l0.4,0.2l0.2-0.1l0.2,0.1l0.2,0.3l0.3,0.2l0.5,0.1l0.5,0.4h0.2
                l0.1-0.2l0.2-0.1h0.3l0.4,0.2l0.6,0.4l0.3,0.2h0.1l0.1,0.1l0.1,0.2v0.2v0.1l0.1,0.2h0.2l0.2,0.1l0.2,0.3l0.2,0.2l0.1,0.1v0.1v0.1
                v0.1l-0.1,0.1v0.2l0.1,0.2l0.3,0.1l0.1,0.1v0.2l0.1,0.2l0.2,0.2l0.1,0.2v0.1l0.1,0.1l0.1,0.1v0.3v0.2l0.2,0.2v0.4l0.1,0.5l0.4,0.7
                l0.1,0.4v0.4l0.1,0.2l0.2,0.1l0.1,0.2v0.3l0.1,0.2l0.1,0.2v0.3l0.1,0.2l0.1,0.1l0.1,0.3v0.3l0.3,0.5l0.5,0.7l0.3,0.5l0.1,0.4v0.3
                l-0.1,0.2v0.2l0.1,0.1v0.2v0.2l0.2,0.2l0.5,0.4l-0.2,0.6l-0.1,0.4v0.4l0.1,0.3l0.2,0.2l0.2,0.2l0.1,0.2l0.3,0.2l0.5,0.2h0.7l0.8-0.2
                h0.5l0.2,0.1l0.1,0.2l-0.1,0.2l0.1,0.1l0.3,0.1l0.2,0.1l0.1,0.2l0.2,0.1l0.2-0.1h0.2l0.2,0.1h0.1l0.1-0.1h0.2h0.2l0.1,0.3l0.2,0.2
                h0.2l0.3,0.3l0.7,1l0.4,0.3l0.4,0.1h0.6l0.2,0.4l-0.1,0.4v0.3l0.1,0.1l0.2,0.4l0.2,0.2v0.2l0.1,0.2v0.4l-0.2,0.5l0.1,0.5l0.3,0.4
                l0.2,0.4v0.3l0.1,0.2l0.1,0.1h0.3l0.6-0.1h0.4l0.3,0.2h0.6l1-0.2h0.6l0.3,0.1l0.2-0.2l0.2-0.5l0.2-0.2h0.2l0.3-0.2l0.4-0.4l0.3-0.2
                h0.4h0.5l0.3-0.1l0.3-0.3l0.5-0.3l1-0.4l0.2-0.1h0.1l0.3,0.1l0.3,0.1l0.3,0.3v0.2v0.2l-0.1,0.1l-0.1,0.2l-0.1,0.3l0.1,0.2l0.2,0.4
                l0.1,0.3l0.2,0.4l-0.1,0.1l-0.1,0.2l-0.3,0.4l-0.2,0.2L852,314h-0.3h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.2v0.2l0.1,0.3v0.3
                l-0.2,0.2l-0.1,0.4v0.1l-0.1,0.3v0.1v0.6l-0.2,0.4l-0.3,0.5l0.1,0.3v0.3l-0.1,0.1v0.1v0.2l-0.1,0.1l-0.2,0.2l-0.1,0.2v0.3l-0.1,0.2
                l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.3v0.2l-0.1,0.2v0.2l-0.1,0.2l-0.2,0.1l-0.1,0.1h-0.1l-0.2,0.2l-0.2,0.7v0.4v0.4
                l-0.3,0.2l-0.2,0.2l-0.1-0.1l-0.1-0.1l-0.3-0.1l-0.2-0.1l-0.3-0.1l-0.5-0.1l-0.5-0.2l-0.3-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.2l-0.2,0.1
                l-0.1,0.2l-0.2,0.1l-0.1,0.3l-0.1,0.2l-0.3,0.3l-0.1,0.1l-0.5,0.2l-0.1,0.1h-0.4l-0.1,0.1l-0.1,0.2v0.2l0.1,0.2l0.2,0.4l0.1,0.3
                l0.1,0.5l0.4,1.6l-0.1,0.3v-1v0.2v0.3v0.2l0.1,0.1l0.1,0.1v0.1v0.2v0.2l-0.1,0.3l-0.1,0.4l-0.1,0.2l-0.1,0.1l-0.1,0.1v0.3v0.2
                l-0.2,0.1l-0.2,0.1h-0.2h-0.2l-0.2,0.1l-0.4,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.1v0.1l0.1,0.1h0.2l0.2,0.2v0.2l-0.2,0.1l0.1,0.2l0.2,0.2
                l0.1,0.2v0.2l0.1,0.1l0.1-0.1l0.3-0.7l-0.3-0.5l0.1-0.1l0.5,0.1h0.2l0.4,0.1l0.2-0.3l0.1-0.3l0.3-0.2l0.3-0.7l0.6-0.8l0.2-0.2
                l0.4-0.2h0.1l0.1,0.1l-0.3,0.4l-0.1,0.3h0.2l0.2-0.1l0.6-0.5l0.2-0.3l0.1,0.3l-0.1,1.4l0.5-0.1l0.3,0.1h0.4l0.4,0.3h0.2h0.2l0.2,0.3
                l0.3,0.1l0.5-0.3l0.7-0.2h0.3l0.8-0.4l0.4-0.4l1.5-1l0.6-0.5l0.6-0.4l0.4-0.6l0.6-0.6V326l0.1-0.3l1-1.6l0.3-0.3l0.4-0.2l0.2-0.3
                l0.1-0.4l0.6-0.6l0.4-0.6l0.4-0.4l0.2-0.4l1-0.9l0.8-1l0.7-0.7l0.2-0.4l0.9-1.3l0.3-0.9l0.4-0.3l0.2-0.8l0.3-0.6l0.1-0.4l0.2-0.3
                l1.2-1.3l0.5-1l0.6-1.1l0.4-0.4l0.4-0.5l0.2-0.4l0.7-0.6l0.3-0.4l0.2-0.4l0.2-1.1l0.4-0.8l-0.1-0.1l-0.1-0.3v-0.3l0.1-0.2v-0.3
                l0.1-0.3l0.1-0.2l0.2-1.1v-0.7l-0.1-0.3v-0.4l0.3-0.5l0.1-0.1v-0.1l-0.2-0.1l-0.1-0.1l-0.2-1.8l0.1-1.1l0.4-0.8l0.1-0.3v-0.8
                l0.4-0.8l0.3-0.9l0.6-0.5l0.4-0.6l0.3-0.3l0.1-0.6l0.3-0.6l-0.2-0.3l-0.2-0.2l-0.4-0.4l-0.1-0.3l0.3-0.5l0.1-0.5V288l-0.5-0.3
                l-0.6-0.7l-0.1-0.2h0.1l0.8,0.4l0.4-0.4l0.2-0.4l-0.1-0.5l-0.4-0.2l-0.6-0.6v-0.2l-0.9-0.5l-1-1l-0.3-0.9l-0.2-0.2l-0.9-0.7
                l-0.2-0.2l-0.2-0.1l-1.1,0.4l-0.6-0.1l-1.1-0.5l-0.1,0.1l0.2,0.4v0.4v0.5l-0.1,0.8l-0.1,0.6l-0.4,0.8l-0.2,0.2l-0.4,0.1h-0.2
                l0.1-0.3l0.3-0.4l0.5-0.7V283l-0.1-0.1h-0.1l-0.3,0.2l-0.4,0.9l-0.8,0.6l-0.6,0.2l-1.2,0.1h-0.2h-0.1l0.3-0.2l0.6-0.4l0.4-0.8
                l0.4-0.2l0.1-0.2l-0.6-0.1l-0.4-0.3l-0.4-0.3l0.2-0.1h0.1l0.2-0.1l0.4-0.6h-0.4h-0.4l-0.7,0.5l-0.1,0.3l0.2,0.3l0.2,0.2l-0.3,1
                l-0.4-0.1h-0.4l-0.2,0.3l-0.2-0.1l-0.1-0.6l0.1-0.6l0.1-1.4l0.1-0.5v-0.5l-0.1-0.3h-0.2l-0.4,0.1h-1l-1.1,0.1l-1.2-0.5l-0.3-0.1
                l-0.2-0.1l-0.1-0.5l0.1-0.3l0.1-0.2l0.8-0.8l0.6-0.2l1.2-0.9l0.5-0.8l0.3-0.3l0.9-0.6l0.6-0.5l0.5-0.5l0.5-0.4l0.5-0.7l0.3-0.1
                l1.1-1.5l0.2-0.3l0.1-0.5l0.1-0.2l1.2-1.7l0.9-0.6l0.6-0.9l0.7-0.4l0.2-0.1l0.3-0.5l0.5-0.3l0.2-0.2l0.4-0.7l1.2-0.7l0.1-0.3
                l0.5-1.8l0.3-0.5l0.6-0.6l1-0.6l0.7-0.6l0.4-0.5l0.8-1.4l0.9-0.8l0.7-0.5l1.7-0.7l0.9,0.1l1-0.4h0.7l1,0.2l3-0.2l0.6,0.2l0.2,0.2
                l0.3,0.7l0.3,0.2l0.6-0.3l0.5-1.1l0.3-0.1l0.7,0.5h0.7l1.3,0.5l0.5-0.1l0.5-0.5l1.1-0.1l0.7,0.8h0.7l0.5-0.1l0.1-0.5l-0.2-0.2
                l-0.4,0.1l-0.1-0.4l0.2-0.5l0.4,0.3h0.6h0.2l-0.1-0.2l-0.1-0.2l-0.2-0.4l0.6-0.5l0.4-0.2l0.6-0.1l1.6,0.7l0.3,0.1l0.4,0.3l0.6,0.2
                l-0.4,0.2l-0.2,0.2l0.4-0.1l0.3,0.2h0.3l0.1-0.1l0.1-0.3l0.2-0.3l0.1-0.1h0.4l0.5,0.1l0.4,0.2l0.9,1.1l0.4,0.2h0.5l0.2,0.1l0.3,0.3
                l-0.8,0.3l-0.7,0.1l-0.6-0.1l-1.1,0.4l0.6,1.1h1.1l1.1-0.2l0.2-0.5l0.4-0.2h0.2l0.5,0.4l0.7,0.2l0.2-0.1l0.5-0.8l0.3-0.1h0.2
                l0.2-0.7l0.9-0.1l0.6,0.6l0.3,0.2l0.7-0.2l0.4-0.4l0.2-0.2l0.7,0.4l0.3-0.3h0.5h0.4V256v-0.5l-0.5-0.5l-1.1-0.5l-0.6,0.3h-0.2h-0.2
                l-0.2-0.2l0.2-0.4l0.2-0.3v-0.4l0.1-0.6l0.3-0.2h0.1l0.4-1.2l1.1-1.6l1.3-1l0.8-0.8l0.4-0.5l0.6-1.3l0.8-0.9l0.4-0.3l0.4-0.4
                l0.1-1.2l0.3-0.3l0.3-0.2l0.5-0.7l0.8-0.4l0.3-0.3h0.9l0.8,0.2l0.2-0.1l0.5-0.4l0.6,0.1l0.8-0.2l0.7-0.4h0.3l0.3,0.1l0.4,0.6
                l0.2,0.2l0.2,0.4l0.5-0.2l1.3-0.9h0.2l0.2,0.1v0.3v0.3l-0.2,0.9l-0.2,0.6l-0.7,1.3l-0.1,0.2l0.1,0.3l0.1,0.6l-0.3,0.7l-0.1,0.3
                l0.3,0.1l0.3-0.4l0.5-0.2h0.3l0.3,0.1l-0.4,1.1l-0.1,0.6l-0.1,0.2v0.3l0.3-0.2l0.2-0.2l0.3-0.2l0.8-0.1l0.4-0.8l0.3-0.4l3.2-3.4
                l0.6-0.7h0.3l0.3,0.1h0.3l0.1-0.3l0.1-0.1h0.3l0.2,0.6l0.2,0.3l0.1-0.1l0.1-0.1l0.2-0.2l0.2-0.2l0.2-0.3l-0.1-0.2l-0.6-0.3V241
                l0.3-0.9v-0.6l0.1-0.6l0.1-0.3l0.1-0.1l0.1-0.2l-0.2-0.5l0.1-0.3l0.1-0.2l2.6-0.9h0.5l0.4,0.2l0.6,0.6l0.7,0.3l1,0.1l0.1,0.3
                l-0.4-0.1l-0.2,0.1v0.3v0.2l-0.2-0.2l-0.7-0.1l-0.6-0.3h-0.2l-0.9,0.8l-0.2,0.3l-0.4,1.5v1l-0.1,1l-0.4,0.4l-0.1,0.5l-0.1,0.6
                l0.5,0.2l0.1,0.2v0.3l-0.3,0.6l-0.8,0.8l-0.1,0.2l-0.1,0.3l0.4,0.6l-0.4,0.2l-0.3,0.2l-0.3,0.3l-1.1,0.1l-0.7,0.7l-1.3,0.7l-0.6,0.4
                l-0.2,0.3l-0.4,1.1l-0.3,0.5l-0.9,0.7l-0.6,1l-1,1.2l-0.4,0.1l-0.5,0.3l-0.6,0.8l-1.4,1.5l-0.7,1.8l-0.4,0.6l-0.4,0.5l-0.3,0.5
                l-0.5,0.5l-1,0.8l-0.7,0.6l-0.4,0.4l-0.1,0.4l-0.2-0.1l-0.7,0.2l-0.9-0.2l-0.6,1.2l-0.7,0.1l-0.6-0.3l-0.3,0.1l-0.1,0.1l-0.1,0.2
                l0.3,0.4l0.1,0.3v0.3v0.5l-0.4,0.9l-0.3,0.7l-0.6,0.7l-1.3,1.2l-0.1,0.2l-0.1,0.3l-0.7,3.2l-0.2,1.4l-0.2,2.2v0.7l0.2,1.6l0.2,1.7
                l0.6,2.8l0.1,0.9l0.4,3.5v0.7l0.1,0.5l0.2,0.6l0.4,0.5v0.7l0.3,2.1v2l0.1,0.4l0.1,0.3l0.4,0.4l0.1,0.5v0.3l0.1,0.3l0.3-0.2l1-0.9
                l0.8-0.9l0.1-0.3l0.3-0.2l0.5-0.3l0.8-0.9l0.6-1.3l0.4-1l0.1-0.4v-0.4l-0.1-0.8l0.1-0.3l0.2-0.9l-0.1-0.2l-0.4-0.2l0.1-0.3l0.3-0.1
                l0.2,0.2l0.1,0.4l0.2,0.1l0.6-0.6l0.5-0.3l1.3-0.6h0.5l0.5,0.5h0.2l-0.1-0.7l-0.3-0.5V286l0.2-0.5l-0.1-0.3l-0.1-0.2l-0.1-0.5
                l0.2-1.1l0.2-0.6l0.2-0.3l0.6-0.5l0.6-0.7l0.7-0.5l0.5-0.2l0.5-0.1l0.5,0.4h0.9l0.3-0.1l0.7-0.8l0.4-0.3l-0.1-0.7l-0.2-0.5l-0.5-0.7
                l-0.1-0.3l-0.2-0.8V276l0.1-0.8l0.4-0.9l0.5-1.2l0.2-0.2l0.5-0.3l0.4-0.2l0.2-0.1l-0.1-0.7l0.3-0.3l0.2-0.2l0.6,0.1l0.2-0.2l0.3-0.1
                l-0.2,0.4l-0.2,0.3l-0.5,0.3l-0.2,0.5l0.6,0.8l0.4,0.2l0.2-0.1l0.4-0.5l0.2-0.2l0.2-0.3l-0.1-1.1l-0.1-0.6V270l-0.3-0.2l-0.3-0.1
                l-0.3,0.1l-0.2-0.2l-0.1-0.3V269l0.1-0.8v-0.4l-0.1-0.7v-0.2v-0.4l0.5-0.6l0.4-0.5l0.2-0.4l0.1-0.3v-0.5l-0.2-0.2l-1.2-0.6h-0.2
                l-0.4,0.2l-0.2,0.7l-0.2,0.3v-0.1l0.1-0.2l-0.6-0.3l-0.3-0.2l-0.2-0.2l-0.1-0.3l-0.1-0.5l0.2-1l0.3-0.9l0.9-1.4l0.5-0.5l0.6-0.8
                l0.2-0.1l0.1-0.1l0.1-0.2l-0.2-0.5l0.1-0.1l0.1-0.1l0.2,0.1l0.5-0.9v-1.2l0.1-1l0.1-0.4l0.1-0.3l0.2-0.3l0.2-0.2l0.3-0.4l0.2-0.3
                l0.1-0.1h0.4l0.3,0.1l0.3,0.2l-0.1,0.5l0.4-0.4l0.4-0.5l0.2-0.1l0.2,0.1l0.4,0.4l0.3,0.7l0.2,0.2h0.3l0.2-0.1l0.2-0.5v-0.9l0.3-0.1
                l0.3-0.1l0.4-0.4l0.5-0.2l1-0.7l0.7-0.7h0.5l-0.1,0.4v0.4l-0.2,0.9l-0.2,0.5l-0.1,0.6v0.3l0.1,0.4h0.2l0.2-0.1l0.3-0.4l1.4-2
                l0.7-0.6l1.1-0.4l0.3-0.2l1.1-0.4l0.9-0.1l0.6,0.2h0.3l1.2-0.2l0.1,0.2l1,0.7l0.6,1l0.1,0.1l0.1,0.2v0.4l0.1,0.2l0.2,0.2l0.5,0.5
                l0.6,0.1l0.1-0.2l0.1-0.2l0.2-1.2l0.2-0.5v-0.3l0.1-0.2l0.5-0.4l0.4-0.1l1.5-1.2l0.7-0.7h0.3l0.2-0.2l0.2-0.2l0.2-0.1l0.4-0.5
                l0.5-0.4l-0.1-0.3l0.1-0.3l0.3-0.1h0.2l0.3-0.3v-0.3l0.1-0.1h0.2l0.2,0.1l0.1-0.1l-0.2-0.4l-0.1-0.4l0.1-0.2l0.6,0.4h0.2l0.7-0.9
                l0.7-0.9l0.6,0.2l0.9-0.7l0.4-0.1h0.6l0.3-0.3l0.3-0.5l0.2,0.1l1.1-0.6l0.2-0.4l0.3-0.1h0.2l0.5-0.3l2-1l0.3-0.4l0.7-0.6l0.6-0.2
                l0.7-0.2l0.1-0.2l-0.5-0.2l-0.1-0.2v-0.2l0.1-0.2l0.1-0.3l0.2-0.1l0.2,0.2h0.2l0.1-0.2h0.1l0.1,0.3l-0.1,0.3l-0.1,0.2v0.3l0.2,0.1
                h0.9l1,0.2l2.7,1.2l0.2,0.2h0.2v-0.5l0.1-0.4l0.3-0.2l0.5-0.6l0.3-0.5v-0.5l-0.2-0.5l-0.4-0.1l-0.2-0.3l-0.1-0.4l0.2-0.3l0.2-0.1
                v-0.4l-0.2-0.3l-0.9-0.6l-0.3-0.4v-0.3l-0.4-0.9v0.2v0.4l-0.1,0.3h-0.2V232l0.1-0.5l-0.1-0.2l-0.5-0.1l-0.1-0.2l0.5-0.3h0.2l0.1-0.1
                l-0.1-1.1l-0.1-0.8l-0.3-0.1l-0.2-0.2l0.1-0.5v-0.2l-0.3-0.9l-0.2-0.3l-0.2-0.3l-0.2,0.4l-0.1,0.5l-0.2,0.1h-0.3l-0.7-0.5l-0.7-0.9
                l-0.1-0.8l0.2-1.1l-0.1-0.2l-0.1-0.1l-0.9,0.4l-0.6,0.6l-0.3,0.1l-0.6-0.4l-1,0.6l0.2-0.4l0.2-0.1l0.2-0.3l-0.1-0.5l-0.2-0.4
                l-0.2-0.3l-0.3-0.1l-0.3,0.3l-0.2,0.3l-0.5,0.3l-1,0.2H966l-1.1,0.4h-0.4l1.5-0.6h0.8l1.1-0.4l0.8-0.8l1,0.7l0.4,0.1l0.8-0.1
                l0.7,0.4l0.2-0.1l0.2-0.4l0.2-1.2l-0.2-0.3l-0.3-0.1l-0.8,0.1l-0.5-0.1l-0.3-0.1l0.2-0.2l0.6,0.2l0.7-0.3l0.8,0.4l0.2,0.4l0.2,0.1
                l0.7,1l0.5,0.4l1.5,0.3l0.7,0.5l0.5-0.2l1.3-1l0.8-0.3l0.6-0.7l0.5-0.8l0.5-0.2l0.8-0.8l0.2-0.4l0.3-1l0.2-0.4l0.3-0.5v-0.4
                l-0.1-0.4l-0.2-0.3l-0.5-0.5l-0.2-0.3l-0.2-0.7v-0.8l0.1-0.6l0.2-0.5l0.2,0.4l0.5-0.1l0.3-0.1v-0.4l-0.1-0.5l0.1-0.1l0.3-0.1v-0.3
                l0.1-0.2l0.1,0.2v0.8l0.2,0.2l0.3,0.2h0.1l0.1-0.1l0.2-0.2l0.4-0.8l0.2-0.3v0.6l-0.1,0.8l-0.1,0.2l-0.2,0.3l-0.1,0.6v0.2l-0.3-0.2
                h-0.2l0.2,0.7l0.2,0.5l0.3,0.5l0.3,0.3h0.1l0.1,0.1v0.3l-0.1,0.7l0.1,0.4l0.3,0.3l0.3,0.1h1.7l0.6-0.1l0.9-0.7l0.3-0.1l0.4,0.1
                l1.1,0.4l1.3,0.5l0.5,0.8l0.2,0.8l0.1,0.9l-0.1,0.3v0.3l0.4,0.4l0.5,0.5l0.1,0.1l0.1,0.2l0.1,0.1l0.4,0.1l0.3-0.1h0.3l0.6,0.2
                l0.7,0.4l0.7,0.5l0.3,0.4l0.6,0.8l0.3,0.3l0.5,0.3h0.2l0.2-0.1l0.4-0.4l0.2-0.3l0.2-0.4l0.1,0.3v0.3l-0.2,0.2v0.4l0.3,0.4l0.3,0.1
                l0.4-0.1l0.1-0.2l0.1-0.3l-0.1-0.9l0.1-0.1l0.3,0.2l0.1,0.3v0.3h0.1h0.8l0.1-0.1v-0.3l-0.1-0.3l-0.1-0.2l-0.4-0.2l-0.4-0.2h-0.3
                l-0.1-0.1v-0.2l-0.1-0.3l0.1-0.2l0.3-0.2V223l-0.3-0.2l-0.3-0.1l-0.2,0.1l0.1-0.2l0.2-0.2l0.3-0.1h0.3l0.6-0.2l0.5-0.4l0.3-0.2
                l0.3-0.3v-0.5l-0.2-0.5l-0.3-0.1h-0.3h-0.3l-0.2-0.1l1-0.2l0.1-0.2l0.2-0.8l-0.1-0.2l-0.2,0.1h-0.3l0.1-0.2l0.1-0.1l-0.6-0.8
                l-0.6-0.5l0.2-0.1h0.3l0.5,0.1l0.3,0.4l0.1,0.2l0.1,0.1h0.3l0.5,0.2v0.2l0.1,0.1l0.3-0.1l0.9-0.2h0.3l0.4,0.2h0.1l0.2-0.1l0.1-0.3
                l-0.2-0.5l-0.5-0.5l-0.3-0.4l-0.1-0.3l0.1-0.1h0.1l0.4,0.5l0.3,0.3l0.3,0.2l0.3,0.2l0.6,0.1l0.3-0.2l0.1-0.4l-0.1-0.8l0.1-0.3
                l0.4-0.4l0.7-0.5l0.4-0.2h0.2l0.2,0.2h0.2l0.3-0.4L1009,213.8L1009,213.8z M789.7,142.6l-0.2,0.2l-0.1,0.3v0.4l-0.1,0.7l-0.2,0.3
                l-0.7-0.1l-0.2,0.1l-0.2,0.2l0.4,0.8l0.8,0.6l0.5,0.6l0.3,0.2l1.6,0.5l0.1-0.1l0.5-1l0.6-0.4l0.2-0.3l0.1-0.5l0.2-0.4l0.1-0.5
                l-0.3-0.4l-0.9-0.4L789.7,142.6L789.7,142.6z M661.6,178.3l-0.1,0.1l-0.1,0.3v0.9l0.1,0.5l0.2,0.4l0.1,0.1v-0.3l-0.3-1.3
                L661.6,178.3L661.6,178.3L661.6,178.3z M671.8,209.9l-0.4-0.1l-0.3-0.2h-0.5l-0.3-0.1l-0.1,0.2l0.5,1.1l0.5,0.5l0.5-0.1l0.1-0.1
                l0.1-0.4l-0.1-0.2v-0.2V209.9L671.8,209.9z M663.9,189.3l0.2-0.7v-0.3h-0.3l-0.5,0.7l0.1,0.1l0.1,0.2H663.9L663.9,189.3z"/>
            <linearGradient id="RW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="197.7079" x2="0.1864" y2="197.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="RW" class="stcustom0 st193" d="M560.7,465.8L560.7,465.8l-0.1,0.2l0.1,0.2l0.3,0.4l0.2,0.1l0.1,0.2l0.1,0.3v0.4l-0.1,0.4v0.3
                l0.1,0.2v0.3l-0.1,0.3l-0.1,0.2l-0.1,0.1H561h-0.1h-0.2l-0.2,0.1h-0.1h-0.1l-0.1-0.1l-0.2-0.2l-0.4,0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.1
                h-0.1l-0.3-0.2h-0.1v0.6l-0.1,0.3l-0.1,0.1l-0.2,0.1l-0.2,0.1H558h-0.5h-0.2h-0.1l-0.1-0.3l-0.3-0.1l-0.3-0.1h-0.1l-0.1,0.2v0.2
                l-0.3-0.1l-0.1-0.1V470l-0.1-0.3l0.1-0.1l0.1-0.1l0.2-0.2l0.3-0.2l0.1-0.1v-0.2v-0.4v-0.3V468l0.2-0.3l0.2-0.3l0.2-0.3h0.1l0.2-0.1
                l0.2-0.2l0.1-0.1h0.1l0.6-0.1h0.1l0.1,0.3h0.1h0.1l0.2-0.1l0.3-0.2l0.1-0.1l0.2-0.2l0.2-0.2l0.1-0.2l0.1-0.1h0.1h0.2L560.7,465.8z"
                />
            <linearGradient id="SA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="273.5579" x2="0.1864" y2="273.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SA" class="stcustom0 st194" d="M605.7,377.9l0.5,0.1l0.7,0.1l0.4,0.1l0.8,0.1l0.2,0.5l0.1,0.3l0.1,0.3l0.2,0.3h0.6h0.5h0.6h0.5
                l0.2,0.3l0.1,0.3l0.3,0.7l0.4,0.5l0.1,0.2l0.1,0.3l-0.1,0.1v0.1l0.3,0.3l0.5,0.3l0.2,0.1l0.2,0.1l-0.2,0.2l0.3,0.4l0.3,0.4l0.4,0.1
                l0.5,0.6l0.8,0.4l0.5,0.5l0,0l-0.1-0.1l-0.2-0.1l-0.1,0.1v0.2v0.3l0.2,0.2l0.2,0.2l0.1,0.3l-0.2,0.6h-0.1l-0.1-0.1h-0.1h-0.1
                l0.1,0.5l0.1,0.4l0.2,0.3l0.1,0.4l0.1,0.2l0.5,0.4l0.2,0.4l0.1,0.7l0.3,0.4l0.2,0.3l0.2,0.2l0.1,0.3l0.2,0.3l0.1,0.1h0.2h0.2
                l0.2-0.1l0.3-0.1l0.2,0.1h0.2v0.1l-0.1,0.2l-0.2,0.4l0.2,0.1h0.2l0.2,0.1h0.1v0.1v0.4l0.1,0.2l0.1,0.1l0.2,0.2l0.2,0.2l0.2,0.2
                l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.1,0.2h0.2h0.1h0.2
                h0.3l0.4,0.1l0.5,0.1l0.6,0.1l0.6,0.1l0.6,0.1l0.6,0.1l0.6,0.1l0.6,0.1l0.5,0.1l0.4,0.1h0.3h0.2h0.1h0.2l0,0l0.2-0.2l0.2,0.3
                l0.2,0.3l0.2,0.4l0.2,0.4l0.2,0.4l0.2,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3
                l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.4l-0.3,0.1l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2
                l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.5,0.2l-0.4,0.1l-0.7,0.1
                l-0.6,0.1l-0.7,0.1l-0.9,0.1l-0.7,0.1l-1,0.1l-0.9,0.1l-0.8,0.1l-0.8,0.1l-0.7,0.1l-0.4,0.1l-0.5,0.3l-0.8,0.4l-0.8,0.4l-0.4,0.2
                l-0.4,0.5l-0.2,0.3l-0.4,0.5l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.2,0.6l-0.2,0.1l-0.3,0.2l-0.3,0.1h-0.5l-0.3-0.4l-0.3-0.4l-0.1-0.1h-0.1
                h-0.5L605,414l-0.7-0.1l-0.8-0.1l-0.7-0.1l-0.4-0.1l-0.5-0.3h-0.1h-0.1h-0.6h-0.6l-0.6,0.1h-0.5h-0.6l-0.2,0.1h-0.2l-0.1,0.1h-0.1
                l-0.1-0.1h-0.2l-0.3-0.1l-0.2-0.2l-0.2-0.1L597,413h-0.2h-0.2l-0.2,0.1l-0.1,0.1l-0.3,0.3v0.1l0.1,0.2v0.1l-0.2,0.1l-0.1,0.3v0.1
                v0.4l0.1,0.3l0.1,0.1v0.1l-0.1,0.2l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.5,0.4v-0.2l-0.2-0.3v-0.2l-0.1-0.2l-0.1-0.2l-0.3-0.2
                v-0.3l-0.2-0.3l-0.3-0.2l-0.1-0.4l-0.1-0.5l-0.7-0.7l-0.8-0.6l-0.3-0.4l-0.4-0.7l-0.2-0.6l-0.6-0.7v-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
                l-0.6-1.2l-0.2-0.2l-0.2-0.3v-0.2v-0.1l-0.4-0.2l-0.4-0.5l-1.1-0.8l-0.6-0.1l-0.4-0.3l-0.3-0.4l-0.3-0.7l-0.6-0.7l-0.5-1l0.2-0.4
                v-0.3l-0.2-0.4l-0.2-0.3l-0.1-0.3l0.1-0.5v-0.5l0.1-0.3l0.1-0.3l-0.1-0.6l-0.2-0.3v-0.2l-0.2-0.1l-0.2-0.2h0.2l-0.3-0.3l-0.1-0.2
                l-0.1-0.4l-0.1-0.3l-0.5-0.8l-0.2-0.5l-0.5-0.6l-0.5-0.4l-0.3-0.2l-0.2-0.2H581l-0.3-0.3h-0.2h-0.3l-0.3-0.5l-0.3-0.5l-0.4-0.6
                l0.1-0.2l0.1-0.3l-0.1-0.4l-0.1-0.2l-0.2-0.4l-0.6-1.1l-0.2-0.2l-0.3-0.2l-0.2-0.5l-0.1-0.4l-0.4-0.2l-0.8-1.5l-0.4-0.5l0.2-0.3
                l-0.5-0.6l-0.2-0.6l-0.5-0.5l-0.4-0.9l-0.7-1l-0.3-0.2l-0.7-0.1l-0.3-0.1l-0.3,0.2v-0.3l0.2-0.4l0.3-0.8l0.1-0.7l0.4-2l0.6,0.1
                l0.5,0.1l0.7,0.1l0.7,0.1l0.4,0.1h0.1l0.6-0.5l0.5-0.4l0.3-0.5l0.3-0.5l0.1-0.1l0.5-0.1l0.8-0.2l0.8-0.2l0.1-0.1l0.2-0.4l0.2-0.5
                v-0.1l0.1-0.1l0.5-0.3l0.3-0.2l-0.4-0.3l-0.4-0.5l-0.5-0.6l-0.4-0.5l-0.6-0.7l-0.4-0.4l0.7-0.2l0.8-0.2l0.8-0.2l0.9-0.3l0.7-0.2
                l1.1-0.3l0.5-0.2h0.1l0.4-0.4l0.6,0.1l0.9,0.2l0.9,0.2l1,0.2l0.3,0.1l0.9,0.6l0.6,0.4l0.7,0.4l0.9,0.5l0.6,0.4l0.8,0.5l0.6,0.5
                l0.8,0.7l0.8,0.7l0.7,0.6l0.9,0.8l0.9,0.8l0.9,0.7l0.7,0.6l0.9,0.7h0.1l0.9,0.1l1.3,0.1l1.3,0.1l1.1,0.1L605.7,377.9L605.7,377.9z
                M577.8,388.6L577.8,388.6l-0.1-0.1v-0.2l0.1-0.1v0.2V388.6L577.8,388.6L577.8,388.6z M578.7,389.6L578.7,389.6l-0.3-0.2l-0.1-0.2
                l-0.1-0.1l-0.5-0.2l-0.1-0.1l0.1-0.1l0.1,0.1l0.1,0.1l0.4,0.2l0.5,0.4h0.1L578.7,389.6L578.7,389.6z M592.9,415.5h0.2l-0.1-0.1l0,0
                l0.1-0.1l0.3,0.3v0.3v0.1l-0.1-0.1l-0.1-0.1v-0.1l-0.1-0.1l-0.3,0.1l-0.2-0.1l-0.3-0.3l-0.1-0.2h0.1l0.1-0.1l0.1-0.2l-0.1-0.2h0.2
                l0.1,0.2v0.4v0.1v0.1L592.9,415.5L592.9,415.5z"/>
            <linearGradient id="SB_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="177.2079" x2="0.1864" y2="177.2079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SB" class="stcustom0 st195" d="M917.1,483.3L917.1,483.3l-0.1,0.3l-0.3-0.1l-0.1-0.1l0,0h-0.2l-0.4-0.1l-0.6-0.4l-0.6-0.8l-0.6-0.4
                l-0.1-0.1v-0.2l0.1-0.1l0.3,0.1l0.5,0.3l0.7,0.4l0.2,0.2l0.1,0.4l0.1,0.1l0.4,0.3l0.2,0.1L917.1,483.3L917.1,483.3L917.1,483.3z
                M912.5,482.7l-0.3,0.1l-0.2-0.1l0.1-0.2l0.1-0.1l0.4,0.2L912.5,482.7L912.5,482.7z M923.8,486.7L923.8,486.7l-0.4-0.2l-0.3-0.3
                l-0.8-0.3l-0.2-0.2H922l-0.4-0.3l-0.4-0.2L921,485l-0.1-0.1l-0.2-0.1l-0.3-0.2l-0.3-0.2l-0.1-0.3l-0.2-0.2l-0.1-0.1l0.8,0.2l0.4,0.3
                l0.3,0.2l0.1,0.1l0.3,0.2h0.2l0.2,0.2h0.2l0.2,0.1l1.2,0.8l-0.1,0.2l0.2,0.2L923.8,486.7L923.8,486.7z M914.8,485L914.8,485
                l-0.1-0.1h-0.1v-0.2l-0.3-0.3v-0.2l0.2-0.2l0.2,0.1l0.2,0.2l0.3,0.1l-0.1,0.2l-0.2,0.3L914.8,485L914.8,485z M916.2,485.5
                L916.2,485.5h-0.4l-0.2-0.3V485l0.2-0.2h0.2l0.1,0.1l0.1,0.2v0.2L916.2,485.5L916.2,485.5L916.2,485.5z M914.6,485.7L914.6,485.7
                l-0.2-0.3v-0.2v-0.1h0.1l0.1,0.4L914.6,485.7L914.6,485.7z M917.9,485.9l0.2,0.2l0.2,0.5v0.2h-0.1v0.1l-0.2-0.2l-0.3-0.1l-0.2-0.2
                l-0.1-0.3v-0.2h-0.2h-0.4l-0.1,0.2l-0.2-0.1v-0.1v-0.1l0.3-0.1l0.1-0.2l0.3-0.3l0.2-0.1l0.3,0.1v0.4l0.1,0.1L917.9,485.9
                L917.9,485.9z M926.2,486.1l0.7,0.8v0.1l-0.1,0.1v0.3l0.1,0.1h0.2l0.3,0.3l0.1,0.4v0.1l0.1,0.2v0.4l0.3,0.5v0.2v0.1l-0.1-0.1
                l-0.4-0.6l-0.4-0.2v-0.1l-0.4-0.3l-0.3-0.5l-0.3-1l0.1-0.2l-0.3-0.5v-0.1h0.1h0.1V486L926.2,486.1L926.2,486.1z M923.3,486.6
                L923.3,486.6l-0.3-0.1l-0.1-0.1v-0.1l0.1-0.1l0.1,0.1v0.1L923.3,486.6L923.3,486.6z M916.8,487.2L916.8,487.2h-0.2l-0.3-0.4l0.1-0.1
                l0.3-0.2h0.1l0.1,0.2l-0.1,0.2l-0.1,0.1v0.2L916.8,487.2L916.8,487.2z M918.8,487.1l-0.3,0.2l-0.2-0.1l-0.2-0.2l0.1-0.2v-0.1h0.1
                h0.1l0.1-0.1l0.3,0.1l0.1,0.1l-0.2,0.1h0.1v0.1L918.8,487.1L918.8,487.1z M919.1,487.5L919.1,487.5l-0.1-0.1l0.2-0.3l0.1,0.2v0.1
                L919.1,487.5z M917.5,487.3L917.5,487.3l-0.2,0.1l-0.4-0.1v-0.1h0.2h0.1L917.5,487.3L917.5,487.3L917.5,487.3z M921.9,488.4
                L921.9,488.4h-0.2h-0.2l-0.1-0.1l0.1-0.1l0.1-0.1h0.1l0.1,0.1h0.1L921.9,488.4L921.9,488.4z M924.6,488h0.2h0.1l0.2,0.2l0.2,0.2
                l-0.1,0.1l-0.2-0.1h-0.1l0,0v-0.1l-0.2-0.1h-0.2v-0.1L924.6,488L924.6,488z M923.4,488.8l0.6,0.5h0.3h0.8l0.5,0.3l0.3,0.1l0.2,0.3
                l0.2,0.1l0.1,0.1l0.1,0.3l0,0l-0.2,0.1h-0.2l-0.5-0.1l-0.5-0.2h-0.9l-0.4-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.3l-0.2-0.3v-0.2v-0.3
                l0.1-0.1l0.2-0.1L923.4,488.8L923.4,488.8z M928.5,489.8v0.3v0.1l-0.2-0.2l-0.1,0.1l-0.1-0.1v-0.2v-0.2v-0.2l-0.1-0.3h0.1
                L928.5,489.8L928.5,489.8z M928.9,491.9l0.4,0.2h0.2l0.3,0.1l0.2-0.1l0.1,0.1l0.4,0.6v0.2l0.2,0.1h-0.2l-0.3-0.1H930l-0.2-0.1
                l-0.4-0.1l-0.3-0.1l-0.7-0.4v-0.2l-0.1-0.1v-0.3l-0.2-0.1h-0.3v-0.1l0.1-0.2h0.2l0.3,0.1l0.5,0.3V491.9L928.9,491.9L928.9,491.9z
                M941.3,493h-0.2H941h-0.2l-0.2,0.2h-0.1h-0.1l-0.1-0.2v-0.1h0.1l0.1-0.2l0.1-0.1h0.3l0.3,0.1h0.1L941.3,493L941.3,493L941.3,493z
                M925.7,495.9l-0.2,0.1h-0.2l-0.1-0.1l-0.1-0.2l-0.2-0.1l-0.3-0.1l-0.1-0.1l0,0h-0.2l-0.1-0.1v-0.1v-0.1l0.2,0.1l1,0.6L925.7,495.9
                L925.7,495.9L925.7,495.9z M943.6,495.6h-0.2h-0.1l-0.2-0.2l0.1-0.1h0.2l0.1,0.1L943.6,495.6z"/>
            <linearGradient id="SC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="189.9579" x2="0.1864" y2="189.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SC" class="stcustom0 st196" d="M631,475.9v0.3l-0.1-0.1L631,475.9l-0.3-0.1l-0.1-0.1l0.2-0.1L631,475.9z"/>
            <linearGradient id="SD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="246.4578" x2="0.1864" y2="246.4578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SD" class="stcustom0 st197" d="M563.4,399.7l-0.1,0.3h0.5h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9h0.9v0.7
                l0.1,0.5l0.4,0.8l0.4,0.4l0.1,0.2v0.1v0.1l-0.1-0.1l-0.2-0.1v0.4v0.3v0.5l0.2,0.5l-0.1,0.5v0.8l0.2,1v0.6l0.3,1.4l0.3,0.8l0.2,0.2
                l0.2,0.1l0.4,0.1l0.5,0.4l0.4,0.4l0.2,0.2l0.2,0.2h0.1l0.1-0.1l0.1,0.2l0.7,0.4l0.1,0.2l-0.2,0.2l-0.3,0.3l-0.1,0.1v0.1v0.1
                l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1l-0.1,0.1h-0.1h-0.1l-0.1,0.1h-0.2l-0.2,0.1l-0.1,0.1l-0.2,0.1h-0.2h-0.1l-0.2,0.1l-0.2,0.2
                l-0.2,0.1h-0.1l-0.1,0.1l-0.2,0.5l-0.1,0.1h-0.2H579h-0.2l-0.3-0.1h-0.1v0.1l-0.1,0.4v0.2l-0.1,0.2l-0.1,0.3v0.5v0.5l-0.2,0.7v0.2
                l-0.2,0.6l-0.1,0.2l-0.3,1.1l-0.1,0.3l-0.3,0.3l0.1,0.6l0.1,0.6l0.1,0.6l0.1,0.8l-0.2,0.8v0.4l-0.2,0.6l-0.1,0.3l-0.1,0.2l-0.1,0.2
                l-0.2,0.4l-0.1,0.5l-0.1,0.5v0.3v0.1v0.1l-0.3,0.1l-0.5,0.1l-0.3,0.1l-0.2,0.1l-0.2,0.2l-0.4,0.7l-0.2,0.4l-0.3,0.6l-0.4,0.4
                l-0.1,0.2l-0.1,0.4l-0.1,0.6l-0.1,0.4v0.3l-0.1,0.6v0.3l-0.1,0.2l-0.2,0.1h-0.1l-0.3-0.2l-0.2-0.2h-0.1l-0.2,0.1l-0.2,0.2l-0.2,0.4
                l-0.2,0.4l0.1,0.8v0.2l-0.1,0.2l-0.3,0.6l-0.1,0.2l-0.1,0.4l-0.1,0.6v0.1l0,0l0,0H570l0,0l0,0l0,0l0,0v-0.1v-0.1v-0.2l0.1-0.3
                l0.2-0.4l0,0l0,0l0,0v-0.1v-0.1v-0.1V435v-0.1v-0.1l0,0l-0.1-0.3l0,0l-1.2-1l-0.2-0.3l0,0l0,0l-0.6-0.2l0,0l0,0l0,0l0.1-0.1l0,0
                v-0.1l-0.3-2.1l0,0l0,0l0,0l0,0l0,0v-0.1l0,0v-0.4v-0.3l0.2-0.6v-0.2h-1.3l0,0l0,0l0,0l0,0l0,0v0.1l0,0l0,0v0.1v0.1l0,0l0,0v0.1H565
                l0.7,0.8l0,0l0,0l0,0v0.3v0.5v0.3v0.2l0.2,0.4v0.1v0.1l-1.3,1.1v0.1l-0.2,0.5l-0.2,0.3l-0.1,0.1l-0.3,0.4l-1.2,1.2l-0.2,0.1h-0.6
                h-0.3l0,0h-0.1l0,0l0,0l0,0l0,0l-0.7-0.7l-1.3-0.8l-0.1,0.1l-0.7,0.4l-0.2,0.1l-0.1,0.1v0.4l-0.1,0.2L558,436l-0.6,0.1l-0.3,0.1
                l-0.3,0.2h-0.1l0,0l-0.1,0.1l-0.3,0.3v0.2v0.2h-2.2l-0.1-0.1l-0.3-0.6l0,0h-0.2l-2-0.1l-0.3,0.1l-0.6,0.3h-0.3l-0.3-0.1l-1.1-1.2
                l-0.2-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.1l-0.1-0.1v-0.1v-0.3l-0.1-0.2h-0.2l-1.4,0.3h-0.2l-0.3,0.1l-0.1,0.1l-0.1,0.2v0.2v0.2v0.2
                l-0.1,0.2l-0.4,0.4l-0.1,0.2v0.5v0.2l-0.1,0.1l-0.2,0.2l-0.1,0.1v0.1v0.3v0.1l-0.2,0.4l-0.1,0.1v0.3v0.1l-0.7,0.2l-0.2,0.1l-0.1,0.2
                v0.1l0,0l-0.3-0.1l-0.4-0.1l-0.7-0.1l-0.3-0.1l-0.1-0.1v-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.2V438l0.4-0.4l0.1-0.2l0.1-0.8v-0.3V436
                l-0.3-0.6l-0.2-0.4l-0.4-0.6l-0.2-0.2l-0.8-0.8l-0.1-0.1l-0.2-0.4l0.1-0.3l0.1-0.5v-0.2l-0.1-0.2l-0.2-0.2h-0.2l-0.1-0.1l-0.2-0.1
                l-0.2-0.1l-0.1-0.2l-0.1-0.3l0.1-0.9v-0.1h-0.2v-0.1v-0.2l-0.1-0.5l-0.1-0.4l0.1-0.2l-0.2-0.3l-0.3-0.1h-0.3l-0.3,0.1h-0.2l-0.1-0.1
                l-0.1-0.1v-0.1v-0.2l0.2-0.4l0.2-0.3l0.5-0.3l0.1-0.2l0.1-0.2v-0.2v-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.3v-0.2l0.1-0.1l0.1-0.2l0.2-0.2
                h0.1l0.2-0.1l0.1-0.1l0.3-0.2l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1v-0.2v-0.3l-0.1-0.2l-0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.3-0.1l0.1-0.1
                v-0.2v-0.2l0.1-0.1l0.1-0.3l0.1-0.1l0.2-0.2l0.2-0.2l0.1-0.2v-0.2l-0.1-0.6l0.2-0.3l0.3-0.2h0.4h0.6l0.4-0.1h0.3l0.7,0.1h0.1l0,0
                v-0.2v-0.4v-1.3v-1.3v-1.3v-1.3v-1.3v-1.3v-1.3v-1.3v-0.4v-0.4v-0.4v-1.2h0.7h0.7h0.7h0.7l0,0l0,0l0,0l0,0v-1.5v-1.5v-1.5v-1.5h1.1
                h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1h0.3h0.1l0.3-0.6h0.1h0.2l0.1,0.1L563.4,399.7z"/>
            <linearGradient id="SE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="431.5579" x2="0.1864" y2="431.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SE" class="stcustom0 st198" d="M542.9,216.1l-0.7,0.2l-0.6-0.3l-0.3,0.2h-0.5l-0.6,0.1l-0.2,0.2l-0.1,0.1l-0.5-0.3l-0.5-0.6
                l-0.4,0.4l-0.2,0.1l-0.2-0.4l-0.2-0.1l-0.1,0.1l-0.1,0.4l-0.1,0.3v0.2v0.7v0.2l-0.5-0.1v0.2l0.1,0.1v0.1l-0.2,0.2h-0.5v0.2l0.1,0.3
                l-0.1,0.2l-0.1,0.1l-0.6,0.1h-0.3l-0.1,0.1v0.2l0.1,0.2l0.1,0.1v0.1v0.3h-0.1l-0.3-0.4h-0.1l0.1,0.2l0.2,0.3l0.1,0.3l0.1,0.3v0.2
                l-0.4,0.8l-0.4,0.5l-0.3,0.4l-0.2,0.4l0.2,0.2l0.2,0.3l0.1,0.6l0.2,0.6l0.4,0.5l-0.1,0.3l-0.1,0.2l-0.6,0.5l-0.7,0.8l-0.7,2
                l-0.2,0.3l-0.6,0.3l-0.2,0.3l-0.5,0.4l-0.8,0.3l-0.4,0.5l-0.2,0.5h-0.2l-0.2-0.2l-0.2-0.1v0.3v0.2l-0.4-0.3l-0.2,0.3l-0.1,0.5
                l-0.6,0.7l-0.6-0.1l-0.1,0.1l0.2,0.1v0.1l-0.1,0.1h-0.2l-0.3,0.1h-0.2l-0.1,0.3l-0.1,0.4l-0.3,0.2h-0.2l-0.1,0.2h0.5v0.2v0.2
                l-0.1,0.2l-0.6,0.3l-0.1,0.2l-0.1,0.1h-0.3v-0.1v-0.1h-0.4l-0.1-0.3l-0.1,0.1v0.3l0.1,0.3l0.1,0.4l-0.1,0.3l-0.1,0.1l0.1,0.1
                l0.2,0.1l0.1,0.2l-0.3,0.1l-0.3,0.5h-0.3l-0.2,0.3h-0.2l-0.2-0.2l-0.3-0.2l-0.1,0.3v0.2l0.2,0.6l0.3,0.4l0.3,0.2l-0.2,0.1l-0.1,0.3
                l-0.2,0.9l-0.1,0.3l-0.1,0.6l0.1,0.5l0.1,0.2l0.1,0.3h-0.4l-0.4-0.2l0.1,0.4v0.2v0.4v0.3l-0.1,0.4l0.1,0.1l0.1,0.3l-0.1,0.2v0.2v0.6
                l0.1,0.9v0.2l0.2,0.8v0.3v0.4l0.3,0.3h0.3h0.3l0.1,0.1l0.1,0.2l0.1,0.3h0.2l0.4-0.2l0.2-0.1l0.2,0.4l0.4,0.6l0.2,0.3l0.4,0.1
                l0.4,0.5l-0.1,0.6l0.2,0.2l0.5,0.2l0.2,0.3l0.1,0.3l0.1,0.2l0.2,0.6l-0.1,0.4l-0.2,0.1l-0.5,0.4l-0.2,0.3l-0.2,0.2l-0.5,0.4
                l-0.2,0.1l-0.2,0.2l-0.2,0.1l-0.2-0.1l-0.6,0.4l-0.4-0.1l-0.2-0.2l0.1-0.8l-0.1-0.2l-0.1-0.1l-0.2,0.1v0.3h-0.4l-0.4-0.2l-0.1,0.5
                l-0.9-0.4l-0.2,0.1l-0.4-0.2l-0.2,0.2l-0.3-0.1l-0.2-0.3l-0.3,0.5l-0.6,0.1l-0.6,0.1l0.3,0.2h1.3l0.4,0.1l0.5-0.1l0.4,0.4l0.3,0.1
                l0.4,0.5l0.2-0.1h0.3l0.3,0.1h0.9l0.3-0.3l0.4,0.1l0.2-0.1l0.2-0.2h0.2h0.2l0.2-0.2l0.1-0.1l0.1,0.1l0.2,0.4l-0.3,0.2h-0.2l-0.1,0.6
                l-0.1,0.3l-0.1,0.1l-0.5,0.3l-0.3,0.3l-0.4,0.3l-0.2-0.1l-0.3,0.3l-0.6,0.3l-0.3,0.4L523,259l-0.3,0.3h-1l-0.9-0.1l-0.3,0.1h0.3
                l0.2,0.1l0.2-0.1l0.6,0.1l0.3,0.1l0.4,0.5l-0.3,0.2l-0.5,0.1l0.2,0.7l0.1,0.5l-0.2,0.3v1.3h-0.3l-0.1,0.5l0.1,0.3v0.6l0.1,0.4
                l0.1,0.4l-0.1,0.4l-0.4,0.9v0.4l0.1,0.2l0.1,0.4l-0.2,0.7l-0.1,0.6l-0.2,0.5l-0.4,0.6l-0.2,0.5l-0.4,1.4l-0.2,0.3l-0.3,0.2l-0.3-0.2
                l-0.3-0.1h-0.3l-0.5,0.2l-0.8-0.1l-0.8,0.1l-0.2,0.1l0.1,0.5L516,273l-0.3-0.1l-0.2,0.2l-0.2,0.2l-0.4,0.4l-0.1,0.3v0.5l0.2,0.5
                l0.2,0.5l-0.5,0.6h-0.3l-0.8-0.2l-1.4,0.4L511,276l0.2-0.3v-0.3l0.1-0.4v-0.4v-0.3l-0.1-0.3l-0.3-0.4l-0.7-1.3v-0.4l-0.1-0.2h0.1
                l0.6,0.3h0.1l0.1-0.1l-0.2-0.4l-0.1-0.2l-0.1-0.3l0.3-0.1h0.2l0.2-0.3l-0.1-0.5l-0.3-0.2l-0.2-0.1l-0.4-0.8l-0.4-0.4l-0.7-1.7
                l-0.3-1.1l-0.3,0.1l-0.1-0.5l-0.1-0.5v-0.3l-0.4-0.2v-0.2l-0.1-1.1l-0.4-0.1l-0.3-0.6v-1.2l-0.3-0.2l-0.2,0.1v-0.3l0.1-0.3l-0.1-1.1
                v-1l-0.1-0.3l-0.1-0.4l0.1-0.3l0.1-0.2h0.3l0.3,0.3l0.2,0.7l0.2,0.1l0.3-0.2l0.2-0.5l0.1-0.8l0.1-0.7l-0.2-0.8l-0.2-0.7v-0.2
                l0.4-0.6l0.1-0.5l0.1-0.5l0.2-0.2h0.2l0.3-0.1l0.3-0.3l0.3-0.4l0.2-0.4l0.1-0.7v-0.4l0.1-0.3l0.1-0.5l-0.1-0.5l-0.3-0.8l-0.4-1.2
                l-0.1-0.6l0.2-0.1l0.3-0.1h0.6l0.1-0.1l0.1-0.3l0.1-0.4l0.1-0.3l0.1-0.4v-0.4l-0.3-0.5l-0.5-0.6L510,243l-0.5-0.5l-0.4-0.4l0.2-1.5
                l0.2-1.1v-0.3v-0.4l-0.5-1.9v-0.4v-0.4l-0.1-0.6v-0.6l0.1-0.2l0.2-0.3v-0.5l0,0l-0.4-1.3l0.6-1.3l-0.1-0.7l0.4-0.5l0.6-1.1l0.4-0.6
                l0.1-0.1l0.3-0.3l0.5-0.3l0.6-0.2h0.3l1,0.2l0.8,0.2l0.1-0.2l0.2-0.4l0.2-0.5v-0.6l-0.1-0.8l-0.1-0.5l-0.6-0.3l-0.6-0.4l0.8-1.4
                l0.5-1l0.7-1.5l0.2-0.6l0.2-0.2l0.2-2.3l0.1-0.7l0.1-0.3v-0.4l-0.1-0.6l-0.2-1.4l1.1-0.2l0.3-0.1l0.3-0.2l0.6-0.4l0.3-0.4l-0.2-1.3
                l0.4-0.4l0.9-1.5l1-1.5l0.5-0.6v-0.3v-0.4l-0.2-0.7l-0.2-0.4l-0.4-0.8l0.2-0.6l0.3-0.1l0.4-0.2l0.3-0.5v-0.1l0.6-2l1.1-1l0.4-0.6
                l0.7,0.4l1,0.6l0.4-0.9l0.1-0.3l0.1-0.5l-0.1-0.9v-1.1v-0.5l0.4-0.2l0.2-0.1l1.1,0.5h0.3l0.5,0.1l0.6,0.2l1.2,0.6l0.5,0.2h0.3
                l0.2-0.3l0.5-0.7l-0.8-0.5l0.5-0.5l0.3-0.5l0.2-0.6l0.1-0.7v-0.4l-0.2-0.3l-0.5-0.7l1.1-0.1h0.4l0.8,0.4v0.1v0.2v0.2l0.7,0.6
                l0.2,0.3l0.5,0.5l0.1,0.3l0.4,0.3l0.3,0.3l0.4,0.3l0.4,0.4l0.6,0.3l0.5,0.1l1.2,0.6l0.2,0.2l0.3,0.4l0.3,0.4l0.2,0.9h0.4l0.1,0.3
                l0.3,0.5l0.5,0.5v0.2l-0.4,0.4v0.6v0.7l0.1,0.6v0.2l-0.1,0.2l-0.1,0.3v0.3v0.1v0.1h0.2l0.3,0.1l0.2,0.1l0.1,0.7v0.1l-0.3,0.3
                l-0.1,0.2v0.4l0.1,0.4l0.1,0.4l0.2,0.5l0.3,0.5l0.2,0.4l0.1,0.3v0.2l-0.1,0.2l-0.1,0.5v0.6l-0.1,0.4l-0.3,0.5l-0.2,0.2l-0.1,0.3v0.4
                l0.1,0.5v0.4l0.1,0.3l0.1,0.2l0.4,0.3l0.2,0.6l0.2,0.5L542.9,216.1L542.9,216.1z M527.3,254.9l-0.1,0.2l-0.1-0.2l0,0v-0.2l0.1-0.1
                l0.2,0.1v0.1l-0.2,0.2L527.3,254.9L527.3,254.9z M526.8,257.4l-0.1,0.1h-0.1l0.1-0.3l0.1-0.1l0.2-0.1h0.1L526.8,257.4L526.8,257.4z
                M528.9,263.3l-0.1,0.3h-0.1l-0.1-0.2l0.3-0.4h0.4l0.1,0.1L528.9,263.3L528.9,263.3z M528.6,263.7l-0.2,0.1l-0.1,0.4l-0.2,0.1
                l-0.2,0.1l-0.1,1.2l0.3,0.4l-0.2,0.1l-0.2,0.1l-0.1,0.2l-0.1,0.4L527,267l-0.2,0.2l-0.3,0.4l-0.1,0.6l-0.3,0.2l-0.3,0.1l0.2-0.5
                l0.2-0.4l-0.2-0.3l-0.1-0.4l-0.2-0.3l0.1-0.3l-0.1-0.6v-0.6l0.2-0.3l0.2-0.2l0.3-0.5l0.4-0.4l0.5-0.2l0.2,0.2l0.1-0.4l0.2-0.1
                l0.2,0.1L528.6,263.7z M521.5,271.7l-0.1,0.3h-0.1l-0.1-0.3v-0.9l0.1-0.4l0.6-1.6l0.3-0.1l0.4-1l0.1-0.4l0.2-0.4l0.1-0.4l0.1-0.1
                l0.2,0.1l0.1,0.1l-0.2,0.2v0.3v0.1l-0.5,1.1l-0.1,0.7l-0.2,0.2L521.5,271.7L521.5,271.7z"/>
            <linearGradient id="SG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="206.8578" x2="0.1864" y2="206.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SG" class="stcustom0 st199" d="M766.9,459.1l-0.4,0.2l-0.5-0.2l0.2-0.3l0.3-0.1l0.3,0.1l0.1,0.1L766.9,459.1L766.9,459.1z"/>
            <linearGradient id="SH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="169.4579" x2="0.1864" y2="169.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SH" class="stcustom0 st200" d="M434.8,485.1L434.8,485.1h-0.1V485v-0.1v-0.1h0.1l0.1,0.1l0.1,0.1v0.1H434.8L434.8,485.1z M459.1,508
                h-0.3v-0.1l0.2-0.1h0.1L459.1,508L459.1,508L459.1,508z"/>
            <linearGradient id="SI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="348.5079" x2="0.1864" y2="348.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SI" class="stcustom0 st201" d="M521.4,315.8l-0.2-0.1h-0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.4l-0.1,0.1h-0.3h-0.1l-0.2,0.3l-0.2,0.1
                l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.1,0.1v0.1v0.1l0.2,0.2v0.3v0.3v0.2l-0.1,0.1l-0.5,0.1l-0.5,0.3v0.1l0.2,0.2v0.1l-0.2,0.1
                v0.1v0.2l0.1,0.2v0.1l-0.3,0.1h-0.4l-0.4-0.2h-0.2l-0.1,0.1h-0.2l-0.2-0.1l-0.2-0.3l-0.1-0.2v-0.2h-0.1h-0.1l-0.1,0.2l-0.2,0.4
                l-0.2,0.1H515h-0.3h-0.2l-0.3-0.1h-0.1v0.1l-0.1,0.1l-0.2,0.1l-0.7-0.2l-0.1-0.2l0.2-0.1l0.2-0.2h0.2h0.2l0.1-0.1l-0.1-0.3l-0.3-0.3
                l-0.2-0.1l-0.2-0.1v-0.1l0.1-0.5V318h-0.3l-0.1-0.1v-0.1v-0.1l0.2-0.2l0.2-0.2l0.1-0.1v-0.1l-0.2-0.1l-0.1-0.1h-0.1h-0.1l-0.1-0.1
                l-0.1-0.1l0.1-0.2l0.2-0.2l0.2-0.2l0.2-0.1l0.1-0.1l0.1-0.2h0.1h0.2l0.3,0.1l0.3,0.1l0.2,0.1l0.5,0.1l0.4,0.1h0.1h0.1l0.1,0.1
                l0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.1l0.2-0.2l0.1-0.1l0.1-0.1h0.2l0.1-0.1l0.6-0.1l0.6,0.1l0.3-0.1l0.2-0.2h0.4l0,0l0.5,0.1v-0.1l0,0
                v-0.4l0.2-0.2l0.2-0.1h0.5l0.1,0.1v0.2v0.2l0.1,0.1v0.1v0.2l0.1,0.1L521.4,315.8L521.4,315.8z"/>
            <linearGradient id="SJ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="544.7579" x2="0.1864" y2="544.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SJ" class="stcustom0 st202" d="M450,177.8l-0.3,0.1l-0.1-0.2l0.4-0.5l1.2-1l0.5-0.9l1-0.3l0.1,0.5l-0.1,0.7l-0.8,0.5l-0.9,0.4
                L450,177.8L450,177.8z M529,144.3l-0.3,0.4l-0.5-0.6l-0.3-0.8l0.2-0.3h0.9l0.2,0.4v0.2L529,144.3L529,144.3z M535.7,93.4l0.4,0.3
                l0.8-0.1l0.5,2.4l0.3,2.5l0.4,0.2l0.8-0.3l0.7-0.2l0.4,0.2l0.7,0.7l0.3,0.5l-0.2,0.4l-0.6,0.4l-0.1,1.3l0.6,0.5l1,1.1l0.6,0.1l1-0.5
                l0.9,0.9l0.9,1l-2.2,1.3l-0.2,0.4l-0.3,1l-0.3,0.8l-0.3,0.5l-0.6,0.8l-0.3,0.3l-0.8-0.1l-0.3,0.3l-0.3,0.6l-0.3,0.5l-0.7,0.1
                l-0.4-0.6l0.1-0.2v-0.4l-0.1-0.9l0.7-0.9l0.2-0.5l-0.1-0.2l-0.2,0.1l-0.5-0.3h-0.1l-0.4,0.5l-0.6,0.4l-0.6,0.1l-2.3,0.7l-0.3-0.2
                l-0.2-1.4l0.9-0.7l0.1-1.2l0.2-0.8l0.3-0.5l0.5-1.4l0.1-0.1l-1.2-1.1l-0.5-0.7l-0.5-1.5l-0.2-1.2l-0.7-1l0.1-1.3l-0.5,0.1l-0.4-0.9
                l0.4-0.5l1.9-0.8l1.1-0.6L535.7,93.4L535.7,93.4z M550.5,92.6h-0.4l-0.8-1.1l-0.1-0.9l0.1-0.4h0.4l0.6,1.3l0.6,0.4
                C550.9,91.9,550.5,92.6,550.5,92.6z M506.7,93.2v1l0.5-0.1l0.5,1l0.6,0.5l0.2,0.4l0.1,0.5l0.4,1l0.2,1l-0.4,0.1l-0.6-1.4l-0.5-0.8
                l-0.6-0.7h-0.5l-0.2-0.3l-0.8-2.6l-0.1-0.6l-0.5-1l-0.2-1.2v-1l0.6,0.2l0.5,0.6l0.5,1.3l0.1,0.4l-0.2,0.6l0.2,0.6L506.7,93.2
                L506.7,93.2z M556.6,88.8l0.8,0.1l0.8-0.2l0.1,0.2l-1.1,0.8l-1.2-0.4l-1.1-0.1l-1.3,0.8l-0.4-0.3l0.7-0.8l0.7-0.3l0.1-0.5L555,88
                l0.9-0.1L556.6,88.8L556.6,88.8z M522.2,73.6L522.2,73.6l0.2-0.2l0.1-0.4l0.1-0.2l0.7,0.3l1,0.9l0.3,0.4l0.4,0.9l0.3,1.5l-0.1,1.1
                l-0.4,1l-0.1,0.6l0.1,0.8l-0.1,0.8l-0.1,0.7l0.5-0.8l1.2-2.5l0.2-0.2l0.2,0.1l0.5,0.5l0.5,1.3l0.1,0.4l0.1,0.5v0.6v0.7v0.5l-0.2,0.3
                l-0.1,0.3h0.3l0.3,0.4l0.3,0.8l0.3,0.3l1.1-0.3l0.7,0.4l0.4,1.3l0.6-0.3v-0.7l0.1-0.3l0.8,0.2l0.4,0.3l0.4,0.7l-0.7,1.1l0.6,1.5
                l1,0.8l0.6,0.8l0.1,0.3l0.1,0.5l-0.4,0.6l-0.4,0.3l-1,0.1l-0.9,0.4l-1.7,0.3l-0.3,0.2l-0.1,0.2v0.5l-0.7,1.2l-0.6,1.4l-0.3,0.8
                l-0.2,1.2l-0.1,0.7l0.1,0.7v0.7l-0.5,0.5h-0.3l-0.4-0.1l-0.4,0.3v0.5v0.7l-0.1,2l-0.1,1.5l-0.2,1.4l-0.2,0.7l-0.3,0.2l-0.8,0.1
                l-0.6,1.3l-0.5,2.2l-0.3,0.9l-0.5,1.4l0.1,0.5l0.2,0.5l-0.3,0.7l-0.5,1v0.4l0.2,0.7l0.1,0.7l-0.4,0.6l-0.7,0.3l-0.7-0.4l-0.3-0.4
                l-0.3-0.7l-0.3-0.5l-0.3-0.3l-1.3-1.5l-1.2-2.5l-1.1-1l-0.7-0.5l-0.3-0.4l-0.3-0.6l-0.3-0.7l-0.3-0.9l-0.1-0.5v-0.8l0.1-0.5l0.1-0.2
                l0.9-0.2l0.3,0.1l0.3,0.4l0.3,0.2l0.6-2.1l3.6-1.2l1.2-0.2h1.2l-0.2-0.6l-0.2-0.7l-0.2-0.2l-0.9,0.4l-1.3,0.4h-0.7l-0.7-0.3
                l-0.7,0.2l-0.7,0.6l-0.7,0.4l-0.7,0.2l-1.4-0.1l-0.4-0.3l-0.5-0.8l-0.1-0.4l-0.1-0.5l-0.1-1.5l0.1-0.4l0.1-0.2l0.2-0.1h0.3l0.3,0.3
                l0.7,0.8l-0.2-0.9l2.1-1.1l1-1l0.5-0.2l0.5,0.1l-0.1-0.5v-0.5l0.4-0.4l0.3-0.2l0.8-0.2h1.8l0.6-0.3l0.5-0.7l-0.5,0.2h-0.5l-0.2-0.1
                l-0.5-0.6l-0.2-0.8l0.7-1.5l0.2-0.7l-0.7,0.1l-0.2,0.3l-0.8,1.4l-0.6,0.6l-0.7,0.3h-0.7l-0.4,0.2L518,94l-0.1-0.5v-0.3l0.2-0.8
                l0.1-0.8v-0.7l-0.2-0.1l-0.3,0.7l-0.2,1l-0.3,0.4l-0.3-0.1l-0.1-0.4l-0.1-0.6l-0.1-0.2h-0.2l-0.3,0.2l-0.3,0.4l0.1,0.6v0.7l-0.1,0.6
                l-0.1,0.7l0.3,0.4l0.2,0.7l-0.4,0.3l-0.4,0.5l-0.4,0.7l-0.4,0.5L514,98l-0.7,0.3l-1.4,0.1l-0.7-0.9l-0.1-0.4l-0.1-0.3l-0.4-0.5
                l-0.6-1.4l-0.5-1.6l-0.3-0.2l-0.5-0.5l-0.3-0.4l-0.3-0.6l-0.1-0.7v-0.7l0.3-0.3l-0.7-0.7l-0.7-1l0.3-0.3l0.3-0.1l2,1.1l0.1-0.1
                l0.2-0.6l-0.1-0.2l-0.3-0.1h-0.5l-0.1-0.1l-0.2-0.6l-0.2-0.8l-0.1-0.5v-0.6l0.3-0.9l0.2-0.8l-0.3-0.4h-0.8l-0.3,0.1l0.1,1.2
                l-0.3,0.8l-0.5,0.6l-0.4-0.3l-0.3-1.5l-0.4-1.1l-0.1-0.7l-0.1-1l-0.1-0.7l-0.3-0.9v-0.5v-0.4l0.2-0.9l-0.2-0.8l-0.2-0.7V76l0.2-0.5
                l0.2-0.2h0.2l0.5,0.6l0.3,0.7l0.1-0.1l0.2-1l0.3-0.2l1-0.3l1.1,1.3l0.3,0.3l0.2,0.1l-0.1-0.6l-0.1-0.8l0.2-0.3l0.9,0.7h0.4l1-0.9
                l1.6-0.5l0.6,0.7v0.4v0.5v0.2l-0.4,0.6l-2.1,0.5L510,79l1.9-0.3l0.3,0.2l0.1,1.5l0.1,0.2l0.5,0.2l0.3,0.4l0.3,0.8l0.3,0.5l0.2-0.1
                l0.1-0.6l-0.1-0.7v-0.8v-0.9l0.1-0.7l0.4-0.5l0.6-1.7l0.6-1.1l0.7,0.5l0.6,1.4l0.6,2l0.5,2.1l0.6,2.6l0.3,0.9l0.3,0.2l1.2,2.6
                l0.1,0.1l-0.3-2l-0.6-3.5l-0.4-2.7l-0.1-1l-0.1-1.5v-0.4l0.1-0.4l0.3-1.6l0.4-0.8l0.3-1l0.1-0.9l0.4-0.7l0.4-0.1l0.4,0.5L522.2,73.6
                L522.2,73.6z M566.4,70.2l-2.7,0.6l-0.3-0.4l4.3-1.8l0.2-0.2l0.8-0.2l0.7,0.4l-0.2,0.3L566.4,70.2L566.4,70.2z M527.7,67.2l-0.6,0.9
                l-1-0.7l0.1-0.7l0.2-0.4l0.6,0.2L527.7,67.2z M533.7,68l0.3,0.2l1.5-0.1l0.3,0.4l0.1,1l0.3,0.4l0.3,0.1l0.8,1.2l0.3,0.2l0.2-0.7
                l0.2-1.6v-2l-0.1-1l0.1-0.6l0.3-0.2l0.3,0.1l0.3-0.3l0.3-0.6l0.3-0.1l0.7,0.5l0.2,0.4l-0.2,0.7l-0.1,1.1l-0.3,2.2l0.7,0.1l0.9-0.5
                l0.2-0.6l0.5-1l0.5,0.2l0.3-0.1L543,67v-0.5l0.3,0.1l0.4,1l0.2,0.2l0.3-0.3h0.1l0.3,0.4l1.6,0.7l0.5,0.4l0.2,0.4l0.2,0.2h1.7
                l1.2,0.3l0.4,0.6l0.4,1.1l0.1,2.5l-0.3,0.7l-2.4,3l-0.6,0.9l-0.3,0.9l-0.5,1.9l-0.2,0.6l-1.1,0.9l-0.3,0.1l-0.8-0.4h-0.3l-1,1
                l-0.4,0.6l-0.3,0.7l-0.5,0.3l-0.5-0.2l-2.4-0.4l-0.3-0.5l-0.3-1l0.5-1.3l-2.7,0.5l-2.9-0.3l-0.2-0.2l-0.1-0.5l-1-0.3l-0.8-0.4
                l-0.6-0.7l-0.6-0.9l0.2-0.4l0.2-0.2l0.5-0.1l0.5,0.1h0.9l0.2-0.9l0.3-0.3l0.3-0.7l-0.9-0.4l-0.9-0.1l-0.6,0.5l-0.7,0.2h-0.7
                l-1.3-0.2l-0.6-0.4l-0.8-1l-0.3-0.6l-0.1-0.4l-0.1-0.7l1-0.6l0.4-0.5l0.4-0.7l-1.4-0.4l-0.6-0.6l-0.6-0.8l0.5-0.5l1.9-0.4l0.5,0.3
                l0.5,0.6l0.6,0.4l0.5-0.8l-0.5-0.4l-0.5-1.3l-0.1-0.6l0.1-0.5l0.2-0.1l0.2,0.2l0.7,1.2l0.5,0.4l0.1-1.1v-0.5l-0.1-0.4l-0.2-0.8
                l-0.2-1l0.3-0.3l0.3,0.1l0.7,0.7l0.7,0.5l0.3,0.5l0.6,1.2L533.7,68L533.7,68z"/>
            <linearGradient id="SK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="358.9579" x2="0.1864" y2="358.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SK" class="stcustom0 st203" d="M538.3,305.1v0.2l-0.1,0.2l-0.1,0.2L538,306l-0.2,0.5l-0.1,0.3l-0.4,0.5v0.7h-0.1l-1,0.2h-0.1
                l-0.1-0.1l-0.1-0.1v-0.1l-0.1-0.2l-0.1-0.1l-0.2-0.1l-0.2-0.1h-0.2l-0.5,0.2h-0.4l-0.2-0.1l-0.3-0.1h-0.6l-0.4,0.1v0.1l-0.4,0.8
                l-0.6,0.3l-0.5,0.3l-0.1,0.1l-0.2-0.1l-0.3-0.2l-0.2-0.1h-0.2l-0.2,0.2l-0.1,0.2l-0.6,0.2l-1,0.1l-0.3,0.2l-0.1,0.3v0.2l0.1,0.2
                l-0.1,0.2v0.1h-0.7l-0.9,0.1h-0.6h-0.5l-0.4-0.2l-0.4-0.3l-0.5-0.4l0,0h-0.1h-0.3h-0.1l-0.2-0.1v-0.2l-0.3-0.5l-0.3-0.8V308l0.1-0.3
                l0.1-0.2v-0.2l0,0l0.1-0.3l0.2-0.4l0.2-0.3l0.1-0.1l0.3,0.1l0.5,0.1l0.4-0.1l0.4-0.2l0.2-0.2l0.2-0.2l0.1-0.1l0.1-0.1l0.3-0.1
                l0.1-0.1v-0.2v-0.3l0.1-0.2l0.1-0.1l0.6-0.3l0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.2l0.2-0.1h0.2h0.2l0.2-0.1h0.1l0.3,0.1l0.1,0.2v0.2h0.5
                l0.3-0.5l0.1-0.1l0.2-0.2l0.2-0.1l0.1,0.1l0.2,0.3l0.2,0.2l0.1,0.1v0.1h0.1h0.2l0.1,0.1v0.2v0.2l-0.1,0.1v0.1l0.1,0.1h0.2l0.1-0.1
                l0.4,0.2l0.1-0.4l0.2-0.2l0.2-0.1l0.2-0.1l0.2-0.1h0.1h0.1h0.1h0.2h0.2l0.3,0.1l0.2,0.2l0.2,0.1h0.2l0.2-0.1l0.2-0.3h0.2l0.2-0.1
                h0.4l0.8,0.1l0.2,0.1l0.5,0.2l0.2,0.2l0.1,0.2l0.1,0.2l0.5,0.2l0.8,0.3L538.3,305.1z"/>
            <linearGradient id="SL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="226.6579" x2="0.1864" y2="226.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SL" class="stcustom0 st204" d="M446.2,439.1L446.2,439.1l-0.1,0.5l-0.1,0.3l-0.1,0.1l-0.4,0.1l-0.2,0.2l-0.1,0.5l-0.1,0.4l-0.1,0.1
                l-0.5,0.6l-0.3,0.2l-0.2,0.2l-0.2,0.2l-0.3,0.2l-0.3,0.4l-0.2,0.4l-0.1,0.1l-0.1-0.1l-0.5-0.4l-0.5-0.3l-1.2-0.4l-0.4-0.1v-0.2
                l0.1-0.3l-0.2-0.3l0.1-0.2h-0.1l-0.2,0.1h-0.4l-0.2-0.2l-0.2-0.1L439,441l-0.1-0.6l-0.1-0.3l-0.2-0.2h-0.4l-0.1-0.3l-0.2-0.3v-0.2
                h0.2l0.1,0.1h0.2l0.3-0.3l0.2-0.2l0.1-0.1v-0.1l-0.1,0.1h-0.4l-0.1,0.1h-0.2l-0.1-0.3v-0.2l0.1-0.2h0.4v-0.1h-0.3l-0.3-0.3l-0.1-0.2
                l0.2-0.1h0.2h0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.3l0.4-0.1l0.2-0.2l0.2-0.3l0.1-0.2l0.1-0.1l0.1-0.1v-0.1l0.1-0.1l0.1-0.2l0.1-0.2
                l0.2-0.1l0.4-0.1l0.4,0.2l0.6-0.1v-0.2h0.6h0.7h0.6l0.2,0.1l0.1,0.1l0.2,0.2l0.2,0.2l0.2,0.4l0.3,0.4l0.3,0.4l0.2,0.2v0.1v0.1
                l-0.1,0.2l-0.1,0.2v0.1h0.1l0.3,0.1v0.2v0.3l0.2,0.3l0.1,0.2v0.1l-0.4,0.4l-0.1,0.4l-0.1,0.1v0.1h0.1h0.1h0.1h0.1l0.2-0.1l0.3-0.3
                h0.1L446.2,439.1L446.2,439.1z M439.9,442L439.9,442l-0.2-0.1l-1-0.3l0.3-0.1h0.7l0.2,0.1l0.1,0.1v0.1L439.9,442L439.9,442z"/>
            <linearGradient id="SM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="339.8579" x2="0.1864" y2="339.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SM" class="stcustom0 st205" d="M510.1,326.2h-0.2l-0.1-0.2l0.1-0.2h0.2v0.1V326.2z"/>
            <linearGradient id="SN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="243.9078" x2="0.1971" y2="243.9078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SN" class="stcustom0 st206" d="M440.6,421.1l0.3,0.5l-0.1,0.2l-0.1,0.3l0.1,0.2l0.2,0.2l0.1,0.1l0.1,0.2v0.4v0.3l0.1,0.1l0.1,0.2
                v0.1v0.1l-0.2,0.2v0.3l0.3,0.4l0.2,0.2v0.1v0.1l0.1,0.1h0.1l0.1-0.1v-0.1h0.2h0.1l0.2,0.2l0.1,0.2v0.2l0.2,0.2l0.1,0.2v0.1l0.1,0.1
                l-0.1,0.3v0.2l-0.1,0.4v0.2v0.1l0.2,0.2v0.2h-0.2h-0.3l-0.7,0.1h-0.2h-0.4l-0.3,0.1l-0.4,0.1h-0.3l-0.2-0.1h-0.2l-0.2-0.1l-0.3-0.1
                l-0.2-0.1l-0.3-0.2h-0.1l-0.1,0.1l-0.1,0.1h-0.1h-0.1l-0.1-0.1v-0.1v-0.1l-0.1-0.1h-0.2h-0.3h-0.4h-0.1h-0.9h-0.9h-0.8h-1h-0.7h-0.7
                l-0.5,0.3l-0.6,0.3l-0.7,0.1l-0.9-0.1h-0.3l-0.3,0.1l-0.2,0.1l-0.3,0.1h-0.4h-0.2l-0.1-0.1l-0.1-0.2l0.1-0.2l0.2-0.1l0.3-0.1
                l0.2,0.1h0.1v-0.1l0,0l-0.3-0.1l-0.1-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.1h-0.1l-0.1-0.1v-0.1l0.1-0.1v-0.5v-0.3v-0.2l0.2-0.2l0.2-0.1
                h0.6h0.6h0.5h0.6l0.1-0.5h0.2h0.3l0.5-0.1l0.5-0.1l0.1-0.1l0.1-0.2l0.1-0.1l0.1-0.1h0.2l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.4,0.2
                l0.7,0.2l0.5,0.1l0.7-0.2l0.5-0.1l0.1-0.2v-0.6l-0.4-0.2h-0.5h-0.1l-0.2,0.1h-0.1h-0.2l-0.3-0.2l-0.2-0.2l-0.3-0.1l-0.3-0.1
                l-0.5-0.3l-0.2-0.1h-0.2l-0.5,0.1l-0.4,0.2l-0.2,0.4h-0.4h-0.9h-0.9h-0.7l-0.1-0.3l-0.2-0.2l-0.3-0.2l-0.1-0.2l0.1-0.2l0.3-0.1
                l0.1-0.1h-0.1l-0.2,0.1h-0.1V423l-0.2-0.3l-0.3-0.6l-0.3-0.2l-0.2-0.5l-0.3-0.2l-0.2-0.1h-0.2l-0.1,0.2L426,421l0.3-0.1l0.7-0.4
                l0.9-1.1l0.8-1.3l0.1-0.3l0.1-0.2l0.1-0.5l0.1-0.3l0.1-0.1l0.1-0.2l0.2-0.4l0.2-0.2h0.2h0.2l0.1,0.1l0.3,0.1h0.5l0.4-0.1l0.3-0.1
                l0.4-0.1h0.5l0.3-0.1v-0.1h0.1h0.1h0.1l0.1-0.1h0.1l0.1,0.1h0.4h0.7l0.7,0.2l0.6,0.5l0.3,0.3v0.2l0.1,0.2l0.2,0.2h0.2l0.1-0.1h0.1
                l0.1,0.1h0.2l0.2-0.1h0.1v0.1l0,0h0.1l0.1,0.1l0.2,0.2l0.1,0.3l0.1,0.4l0.1,0.2h0.2l0.1,0.1v0.1l0.1,0.1h0.1h0.2l0.2,0.1l0.2,0.3
                v0.1v0.1v0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.2,0.1l0.3,0.1l0.2,0.2l0.1,0.2L440.6,421.1L440.6,421.1z"/>
            <linearGradient id="SO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="217.7079" x2="0.1864" y2="217.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SO" class="stcustom0 st207" d="M591.7,467.5v-0.2v-0.1l-0.3-0.3l-0.5-0.6l-0.4-0.5l-0.4-0.5v-0.4v-1.2v-2.3V459v-2.4v-1.2v-0.5v-0.1
                l0.4-0.4l0.6-0.6l0.8-1.1l0.4-0.6l0.3-0.5l0.1-0.1l0.3-0.3l0.6-0.2h0.4l1.2-0.2l0.2-0.1l0.1-0.1l0.1-0.2l0.2-0.3l0.3-0.2l0.6-0.3
                l0.6-0.3h0.1l0.7-0.2l0.2-0.1l0.3-0.1h0.1l1,0.1h0.8h0.8h0.1l0.5-0.6l0.9-0.9l0.5-0.6l0.8-0.9l0.7-0.7l0.7-0.7l0.7-0.7l0.8-0.8
                l0.5-0.5l0.8-0.8l0.8-0.8l0.7-0.7h-1h-0.9H607l-0.2-0.1l-0.8-0.3l-1-0.3l-1.2-0.4l-0.9-0.3l-0.9-0.3l-0.9-0.3l-0.7-0.2l-0.9-0.3
                l-0.8-0.3l-0.1-0.1l-0.4-0.4l-0.6-0.5h-0.1l-0.3-0.1l-0.2-0.3l-0.3-0.4l-0.2-0.5l-0.1-0.3l-0.3-0.1l-0.2-0.2l-0.3-0.4l-0.2-0.2
                l-0.1-0.2l-0.1-0.3l-0.2-0.3l-0.2-0.2v-0.1v-0.1l0.3-0.5l0.1-0.2l0.1-0.2l0.1-0.2v-0.1l0.4-0.6l0.3-0.5l0.2-0.4l0.5,0.4l0.5,0.9
                l0.6,0.7l0.9,0.7l0.3,0.2l0.3,0.1h1.6l1.1-0.6l1-0.4l0.3-0.1l0.6,0.1h0.6l0.6,0.1h0.3l1.1-0.5l0.7-0.5l0.5-0.2h0.2l0.7,0.2l0.9-0.1
                l1.2-0.4l0.4-0.1h0.3l0.6,0.2h0.1h0.3l0.9-0.2l0.7-0.3l1.3-0.2l1-0.6l0.2-0.3l0.3-0.3l0.4-0.1l1.1,0.4h0.2l-0.1,0.2v0.2l-0.2,0.4
                l-0.1,0.5l0.1,0.7l0.1,1.2v0.2l-0.1,0.2v0.1h-0.1l-0.1,0.1h0.1l0.3-0.1v-0.1v-0.1l0.3,0.2l0.2,0.1l0.1,0.1v0.1h-0.3l-0.2-0.1
                l-0.5,0.1l-0.3,0.1l-0.1,0.2l-0.1,0.9l-0.1,0.6v0.8l-0.4,0.5l-0.1,0.4l-0.6,0.7l-0.3,0.6l-0.1,0.3l-0.5,0.9l-0.7,0.7l-0.3,0.9
                l-0.3,0.5l-0.3,0.5l-0.6,0.9l-0.3,0.6l-0.4,1l-0.1,0.7l-1.1,1.9l-1.2,1.5l-0.7,1.3l-1.3,1.5l-1.8,1.9l-2.3,2.3l-0.6,0.5l-2.5,1.4
                l-1.6,1.2l-0.8,0.8l-0.9,0.7l-0.7,0.7l-2.1,2.2l-0.2,0.2l-0.2,0.2l-0.3,0.4l-0.2,0.1l-0.5,0.6l-0.3,0.3l-0.4,0.3l-0.2,0.2l-0.1,0.3
                l-0.1,0.1l-0.3,0.6l-0.3,0.4L591.7,467.5z"/>
            <linearGradient id="SR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="213.9578" x2="0.1864" y2="213.9578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SR" class="stcustom0 st208" d="M323.1,447.9l-0.2,0.2l-0.3,0.3l-0.3,0.5v0.2l-0.1,0.1v0.2v0.2l0.1,0.2v0.3l-0.1,0.3v0.2l0.1,0.3
                l0.1,0.3v0.1l0.1,0.1l0.1,0.1v0.2l0.3,0.4l0.2,0.2l0.2,0.2l0.1,0.2l0.1,0.2h0.1v0.1v0.2v0.2l-0.2,0.3l-0.4,0.5v0.1l0.1,0.4v0.3v0.2
                l-0.2,0.3l-0.4,0.7l-0.2,0.1l-0.1,0.2h-0.1H322l0,0h-0.1l-0.1-0.1v-0.1l-0.1-0.1h-0.1h-0.2h-0.1l-0.1-0.1l-0.1-0.1v-0.1H321
                l-0.2,0.1h-0.1h-0.1h-0.1l-0.3,0.1H320l-0.1,0.1l-0.8,0.1h-0.2l-0.7-0.4l-0.1-0.1H318h-0.1v0.1l-0.1,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1
                v0.1l0.2,0.1l0.1,0.2l0.2,0.2l0.1,0.2v0.2v0.2l-0.1,0.1h-0.2l-0.6-0.1l-0.4-0.1h-0.2h-0.1l-0.1-0.1l-0.1-0.1h-0.2l-0.2-0.1l-0.2-0.2
                l-0.2-0.3l-0.1-0.1l-0.1-0.1l-0.1-0.2v-0.2l-0.1-0.2v-0.1l-0.1-0.2v-0.1l0,0l-0.1-0.1l-0.1-0.2v-0.1l0,0l-0.1-0.2l-0.1-0.1v-0.1
                v-0.2l-0.1-0.1v-0.2v-0.1v-0.1l-0.1-0.1v-0.2v-0.4v-0.1h-0.3l0,0H314h-0.2h-0.1l-0.1-0.1v-0.1v-0.3l-0.2-0.2l-0.3-0.2l-0.1-0.3
                l-0.1-0.2l-0.3-0.4l-0.1-0.3V451l0.1-0.2l0.2-0.3l0.1-0.3l0.1-0.1l0.1-0.2l0.1-0.3l-0.1-0.2l-0.1-0.2v-0.1l0.1-0.2l0.1-0.1h0.1
                l0.1-0.1l0.1-0.1h0.2h0.2h0.4h0.2l0.1-0.1v-0.2l0.1-0.1l0.1-0.1l0,0V448l0,0l0,0h-0.1l-0.5-0.1l0.1-0.1l0.1-0.2v-0.1l0.1-0.2v0.1
                l0.1-0.3v-0.3l0.1-0.3l0.1-0.3l0.2-0.2l1.4,0.2l0.6,0.1l0.8,0.3l0.1,0.3v-0.3v-0.3l0.2-0.2l0.5-0.1l0.8,0.1l0.6-0.1h0.9l1.3,0.2
                l0.6,0.1l0.2,0.1v0.2v0.3l-0.1,0.3L323.1,447.9z"/>
            <linearGradient id="SS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="225.0581" x2="0.1864" y2="225.0581" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SS" class="stcustom0 st209" d="M570.4,435.2l-0.2,0.4v0.3v0.2v0.1v0.1l0,0l0,0l0,0l0,0h0.5l0,0l0,0v0.1v0.6v0.5v0.8v0.2v0.3
                l-0.1,0.1l-0.1,0.1l-0.2,0.1h-0.5h-0.4h-0.3h-0.4h-0.4l-0.1,0.1l-0.2,0.4l-0.3,0.6l-0.1,0.3v0.1v0.1l0.2,0.1l0.4,0.2l0.5,0.1h0.3
                h0.2l0.2,0.1l0.7,0.5l0.2,0.2l0.1,0.2v0.2l0.1,0.2l0.4,0.4l0.2,0.2l0.6,0.3l0.2,0.3l0.2,0.2l0.2,0.2l0.1,0.3l0.2,0.8l0.2,0.4
                l0.2,0.3l0.1,0.5l0.1,0.2l0.1,0.3l0.2,0.3l0.2,0.2v0.1l-0.5,0.5l-0.6,0.6l-0.7,0.7l-0.7,0.7l-0.6,0.6l-0.6,0.6l-0.7,0.7l-0.5,0.5
                l-0.1,0.1l-0.1,0.1h-0.5l-0.5-0.1l-0.4-0.3l-0.4,0.2l-0.3,0.1h-0.2h-0.4l-0.6,0.1l-0.3,0.2l-0.1,0.1l-0.1,0.2h-0.1h-0.1l-0.1-0.1
                l-0.3-0.1l-0.2-0.3l-0.1-0.2l-0.1-0.1l-0.5,0.3l-0.2,0.1h-0.2l-0.3-0.2l-0.4-0.1H562l-0.3,0.2l-0.3,0.3l-0.2,0.3l-0.1,0.1l-0.1-0.1
                l-0.1-0.1l-0.1-0.1l-0.2-0.1h-0.1h-0.2l-0.1-0.1v-0.2v-0.2l-0.1-0.1l-0.2-0.1l-0.6-0.3l-0.5-0.5l-0.2-0.3l-0.2-0.2l-0.3-0.4
                l-0.3-0.3l-0.3-0.1l-0.2,0.1l-0.2,0.3l-0.4,0.3h-0.2l-0.3-0.2l-0.3-0.1h-0.6l-0.2,0.1l-0.3,0.2l-0.3,0.1h-0.2l-0.2-0.1h-0.2h-0.2
                l-0.3-0.2l-0.2-0.2l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.5-0.3l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.3
                l-0.1-0.4v-0.3v-0.2l-0.1-0.2l-0.1-0.1l-0.2-0.1l-0.4-0.2l-0.4-0.3l-0.2-0.1l-0.4-0.1l-0.2-0.1l-0.2-0.3l-0.1-0.3l-0.2-0.2l-0.1-0.1
                V445l0.2-0.5l-0.2-0.2l-0.3-0.2l-0.2-0.3l-0.1-0.2l-0.4-0.3l-0.9-0.5l-0.5-0.3l-0.3-0.3l-0.2-0.3v-0.1l0.2-0.3v-0.2l-0.1-0.2
                l-0.5-0.4l-0.4-0.5l-0.3-0.2l-0.8-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.3l0.1-0.4l-0.1-0.1h-0.1l0,0v-0.1l0.1-0.2l0.2-0.1l0.7-0.2
                V438v-0.3l0.1-0.1l0.2-0.4v-0.1v-0.3v-0.1l0.1-0.1l0.2-0.2l0.1-0.1v-0.2v-0.5l0.1-0.2l0.4-0.4l0.1-0.2v-0.2v-0.2v-0.2l0.1-0.2
                l0.1-0.1l0.3-0.1h0.2l1.4-0.3h0.2l0.1,0.2v0.3v0.1l0.1,0.1l0.2,0.1l0.2,0.2l0.1,0.1l0.2,0.1l1.1,1.2l0.3,0.1h0.3l0.6-0.3l0.3-0.1
                l2,0.1h0.2l0,0l0.3,0.6l0.1,0.1h2.2v-0.2V436l0.3-0.3l0.1-0.1l0,0h0.1l0.3-0.2l0.3-0.1l0.6-0.1l0.2-0.2l0.1-0.2v-0.4l0.1-0.1
                l0.2-0.1l0.7-0.4l0.1-0.1l1.3,0.8l0.7,0.7l0,0l0,0l0,0l0,0h0.1l0,0h0.3h0.6l0.2-0.1l1.2-1.2l0.3-0.4l0.1-0.1l0.2-0.3l0.2-0.5v-0.1
                l1.3-1.1v-0.1v-0.1l-0.2-0.4v-0.2v-0.3v-0.5v-0.3l0,0l0,0l0,0l-0.7-0.8h1.9v-0.1l0,0l0,0v-0.1v-0.1l0,0l0,0v-0.1l0,0l0,0l0,0l0,0
                l0,0h1.3v0.2l-0.2,0.6v0.3v0.4l0,0v0.1l0,0l0,0l0,0l0,0l0,0l0.3,2.1v0.1l0,0l-0.1,0.1l0,0l0,0l0,0l0.6,0.2l0,0l0,0l0.2,0.3l1.2,1
                l0,0l0.1,0.3l0,0v0.1v0.1v0.2v0.1v0.1L570.4,435.2L570.4,435.2L570.4,435.2L570.4,435.2z"/>
            <linearGradient id="ST_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="205.4579" x2="0.1864" y2="205.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ST" class="stcustom0 st210" d="M495.9,458.5l-0.1,0.1l-0.1-0.1v-0.1l0.2-0.2l0.1-0.1h0.1v0.1v0.1L495.9,458.5z M493.8,462.5
                l-0.3,0.2l-0.1-0.1l-0.1-0.1l-0.1-0.3v-0.1l0.1-0.2l0.3-0.2h0.2l0.2,0.2v0.2L493.8,462.5z"/>
            <linearGradient id="SV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="241.8578" x2="0.1864" y2="241.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SV" class="stcustom0 st211" d="M224.3,422.2L224.3,422.2l0.5,0.2h0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.4,0.3l0.1,0.1l0.3,0.2l0.1,0.1h0.1
                h0.2l0.2,0.1l0,0v0.2v0.1h0.1l0.1-0.1l0.4-0.2l0.4-0.1l0.2,0.1l0.1,0.2l0.1,0.1h0.3h0.3l0.2,0.1v0.1l-0.1,0.5v0.2v0.2h0.1l0.1,0.1
                v0.1l-0.2,0.1h-0.1l0.1,0.3l-0.2,0.2l-0.1,0.1h-0.3h-0.4l-0.7-0.1l-0.5-0.2h-0.3l0.1,0.1h0.2l0.3,0.1h-0.1l-1-0.3l-1.2-0.6l-0.7-0.1
                l-0.8-0.1l-0.5-0.4l-0.4-0.2v-0.1v-0.1l0.2-0.2l0.3-0.3l0.2-0.1h0.1h0.1l0.1-0.1l0.1-0.2l0.1-0.1l0.3-0.1h0.1v-0.1l-0.1-0.2v-0.1
                l0.1-0.1h0.1h0.2L224.3,422.2L224.3,422.2z"/>
            <linearGradient id="SX_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.1579" x2="0.1864" y2="254.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SX" class="stcustom0 st212" d="M297.9,411.6h0.3v0.1v0.1L297.9,411.6L297.9,411.6L297.9,411.6z"/>
            <linearGradient id="SY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="306.7581" x2="0.1864" y2="306.7581" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SY" class="stcustom0 st213" d="M594,351.2L594,351.2v0.2l-0.3,0.3l-0.4,0.5l-0.3,0.3l-0.5,0.5l-0.4,0.1l-0.7,0.2l-0.2,0.2l-0.2,0.3
                l-0.1,0.4v0.2v0.4l0.2,0.5l0.1,0.4v0.3v0.3l-0.1,0.3l-0.2,0.4l-0.1,0.5v0.9v0.8v0.1l-0.3,0.5l-0.3,0.6l-0.1,0.1l-0.7,0.2l-0.8,0.5
                l-0.8,0.5l-0.8,0.5l-0.8,0.5l-0.8,0.5l-0.6,0.4l-0.8,0.5l-0.7,0.5l-0.7,0.5l-0.6,0.3l-0.8,0.5l-0.5,0.3l-0.7,0.5l-0.6,0.4l-0.8,0.5
                l-1-0.1l-0.3-0.1l-0.2-0.2l-0.2-0.1l-0.5-0.1l-0.3-0.4l-0.2-0.2l-0.3-0.1v-0.2l0.2-0.3l0.2-0.3l-0.1-0.2v-0.1V365l0.1-0.2l-0.1-0.2
                l-0.1-0.2l-0.1-0.1v-0.2V364v-0.2l0.1-0.1v-0.1l0.1-0.1l0.2-0.1v-0.1l0,0l-0.2-0.1l-0.1-0.1l0.1-0.2v-0.1l0.1-0.1l0.2-0.2h0.2h0.1
                h0.2h0.2l0,0v-0.1l-0.2-0.1v-0.1l0.1-0.1l0.2-0.2l0.2-0.1h0.1l0.2-0.3l0.1-0.3l-0.2-0.7l-0.1-0.1l-0.2-0.1H577l0,0l0.2-0.2l0.1-0.2
                l-0.1-0.1l-0.2-0.1l-0.1,0.2h-0.3h-0.5l-0.2-0.8v-0.3v-0.4l0.1-0.6l-0.1-0.3v-0.2v-0.2l-0.4-0.5l0.2-1l0.1-0.2h0.2l0.4,0.3h0.1
                l0.1-0.4l0.1-0.1l0.3-0.1l0.1-0.6l0.1-0.1l0.2-0.1h0.2h0.2v-0.1l-0.3-0.7v-0.2l0.1-0.7l0.1-0.3l0.1-0.1h0.3l0.5,0.1l0.1,0.2l0.2,0.2
                h0.3h0.4h0.3l0.2-0.1l0.6-0.2l0.3-0.1l0.2-0.1l0.8-0.4h0.3h0.2l0.2,0.1l0.4,0.3l0.3,0.3l0.2,0.1h0.4h0.6h0.7l0.4-0.1l0.5-0.1
                l0.9-0.3l1.2-0.6l0.7-0.3h0.3h0.4l0.4,0.1l0.5,0.1h0.2l0.5-0.1l0.6-0.1l0.4-0.1l0.5-0.2l0.3-0.3h0.1l0.1,0.1h0.1l0.1,0.2L594,351.2z
                "/>
            <linearGradient id="SZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="126.3078" x2="0.1864" y2="126.3078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="SZ" class="stcustom0 st214" d="M564.8,537.8L564.8,537.8l0.3,0.2v0.3v0.3l-0.1,0.2v0.2l0.1,0.3l0.1,0.2v1h-0.1h-0.2h-0.1l-0.1,0.4
                l-0.1,0.7v0.4H564h-0.8l-0.5-0.2l-0.6-0.4l-0.4-0.6l-0.2-0.4h-0.2v-0.1v-0.5v-0.5v-0.1l0.4-0.6l0.2-0.4l0.2-0.4l0.3-0.4l0.4-0.3h0.1
                h0.1l0.6,0.4l0.6,0.4L564.8,537.8L564.8,537.8z"/>
            <linearGradient id="TC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="265.5579" x2="0.1864" y2="265.5579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TC" class="stcustom0 st215" d="M273.4,400.4L273.4,400.4l-0.2-0.1v-0.1h-0.1l-0.1-0.1V400h0.2L273.4,400.4L273.4,400.4L273.4,400.4z
                M274,400.6L274,400.6l-0.2-0.1h-0.3v-0.2h0.1l0.4,0.1V400.6L274,400.6L274,400.6z M272.1,400.4l0.3,0.2h0.2l0,0h-0.1l0,0h-0.3h-0.1
                v-0.1V400.4L272.1,400.4z"/>
            <linearGradient id="TD_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="247.8075" x2="0.1864" y2="247.8075" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TD" class="stcustom0 st216" d="M542.4,407.4v1.3v1.3v1.3v1.3v1.3v1.3v1.3v1.3v0.4v0.2l0,0h-0.1l-0.7-0.1h-0.3l-0.4,0.1h-0.6h-0.4
                l-0.3,0.2l-0.2,0.3l0.1,0.6v0.2l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.1,0.1l-0.1,0.3l-0.1,0.1v0.2v0.2l-0.1,0.1l-0.3,0.1l-0.2,0.1
                l-0.1,0.1l-0.1,0.1l0.1,0.1l0.1,0.2v0.3v0.2l0.1,0.1l0.1,0.1v0.1l-0.1,0.1l-0.3,0.2l-0.1,0.1l-0.2,0.1h-0.1l-0.2,0.2l-0.1,0.2
                l-0.1,0.1v0.2l0.1,0.3l0.1,0.3l0.1,0.2v0.2v0.2l-0.1,0.2l-0.1,0.2l-0.5,0.3l-0.2,0.3l-0.2,0.4v0.2v0.1l0.1,0.1l0.1,0.1h0.2l0.3-0.1
                h0.3l0.3,0.1l0.2,0.3l-0.1,0.2l0.1,0.4l0.1,0.5v0.2v0.1h0.2v0.1l-0.1,0.9l0.1,0.3l0.1,0.2l0.2,0.1l0.2,0.1l0.1,0.1h0.2l0.2,0.2
                l0.1,0.2v0.2l-0.1,0.5L539,432h-0.1l-0.2-0.1l-0.3-0.1l-0.4-0.1l-0.3,0.1l-0.4,0.2l-0.1,0.1l-0.1,0.1h-0.2h-0.2l-0.1,0.1l-0.1,0.1
                l-0.5,0.3l-0.1,0.1l-0.1,0.1v0.1l0.1,0.2v0.3l-0.1,0.2l-0.1,0.1l-0.2,0.1h-0.1l-0.1,0.1l-0.3,0.5l-0.1,0.1h-0.2l-0.7,0.7l-0.1,0.2
                l-0.3,0.3l-0.3,0.3l-0.3,0.2v0.1l-0.1,0.1l-0.2,0.1l-0.6,0.4h-0.8l-0.3,0.2l-0.3,0.1l-0.5,0.1h-0.1h-0.6h-0.7l-0.3,0.1l-0.3,0.2
                l-0.2,0.1l0,0v0.1l0,0l0.5,0.3l0.1,0.2l-0.1,0.2h-0.1l0,0l-0.1,0.1l-0.3,0.4l-0.4,0.5l-0.2,0.1l-0.1,0.1l-0.1,0.3h-0.1h-0.3h-0.6
                l-0.8,0.1h-0.5h-0.3l-0.4,0.2l-0.2,0.1h-0.1l-0.4,0.2l-0.4,0.3l-0.1,0.1l-0.5,0.1l-0.2,0.2h-0.1l-0.3-0.3l-0.2-0.3l-0.1-0.3v-0.1
                h-0.1l-0.2,0.1l-0.2,0.1l-0.1,0.2l-0.5,0.2l-0.5,0.1l-0.2,0.2l-0.3,0.1h-0.4l-0.3-0.1h-0.3l0.1-0.2l0.1-0.2v-0.2v-0.1l-0.2-0.1
                l-0.1-0.1l-0.3-0.7l-0.3-0.7l-0.4-0.7l-0.4-0.4l-0.3-0.3h-0.1l-0.2-0.1l-0.1-0.1l-0.6-0.5l-0.6-0.5l-0.1-0.2l-0.3-0.3l-0.3-0.4
                l-0.2-0.2l-0.1-0.3l0.2-0.3l0.2-0.3l0.3-0.2h0.4l0.6,0.1h0.7l0.7-0.1h0.2h0.2l0.4,0.1h0.6l0.3-0.1l-0.3-0.2l-0.4-0.4l-0.3-0.4
                l-0.2-0.4l-0.2-0.5l-0.2-0.6l-0.1-0.7v-0.4l0.1-0.3l0.2-0.5l-0.1-0.3v-0.2V429l-0.1-0.2l-0.2-0.6v-0.1l-0.2-0.4l-0.1-0.7l-0.2-0.4
                l-0.4-0.2l-0.2-0.3l-0.1-0.5l-0.2-0.1l-0.6-0.2h-0.5l-0.4-0.5l-0.5-0.7l-0.4-0.6l-0.3-1.2l-0.2-0.7l0.2-0.2l0.4-0.5l0.5-1l1-1.5
                l0.5-0.8l1.1-1.1l1.3-1.4l0.7-0.8l0.1-1.5l0.1-1.5l0.1-1.2l0.1-1.4l0.1-1.2l0.1-0.9l0.1-1.2l0.1-0.2l0.5-1v-0.1l-0.1-0.2l-0.7-0.8
                l-0.2-0.2l-0.1-0.4l0.2-0.2l-0.9-1.4l-0.2-0.2l-0.1-0.2v-0.2v-1l-0.2-1.5l-0.3-1.7l1-0.5l0.8-0.4l1-0.5l0.9,0.5l1.3,0.7l1.3,0.7
                l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7l1.3,0.7L542.4,407.4z"/>
            <linearGradient id="TF_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="49.6578" x2="0.1864" y2="49.6578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TF" class="stcustom0 st217" d="M620.6,609.6h-0.2l-0.2-0.1l-0.1-0.2l0.2-0.2l0.1,0.1l0.1,0.1L620.6,609.6L620.6,609.6z M669.3,620.7
                h0.2h0.1l0.6-0.6h0.2v0.4l0.2,0.2h-0.2H670l-0.1,0.2l0.4,0.3h0.2h0.2l0.3-0.1l0.2-0.1l0.4-0.3l0.2-0.1h0.4l0.2-0.2l0.1-0.1h0.2
                l0.2,0.1l0.1,0.2l0.1,0.3l-0.1,0.3l-0.1,0.3l-0.3,0.2l0.1,0.2l-0.1,0.1h-0.1h-0.1l-0.2-0.2l-0.2-0.1h-0.5h-0.2v0.2l-0.1,0.1
                l-0.1,0.1h-0.2v0.1l0.1,0.2l0.2,0.2l0.4,0.2h0.2v-0.3h0.3l0.2,0.1l0.2,0.2l-0.1,0.1h-0.2v0.2l-0.2,0.2h-0.1l-0.4-0.1l-0.3-0.2
                l-0.1-0.1l-0.2-0.1l-0.2,0.2h-0.2l-0.4-0.1l-0.4-0.2l-0.2-0.1l-0.3-0.1L669,623l-0.3,0.2h-0.3h-0.2l-0.1-0.2v-0.2l0.1-0.2l0.1-0.2
                l0.1-0.2V622l-0.1-0.2l0.1-0.3l-0.1-0.2v-0.2l0.2-0.1l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.2l0.1-0.3l0.1-0.3v-0.3l0.2-0.3l0.2-0.3
                l0.1-0.1h0.2l0.1,0.1v0.2l-0.1,0.1l0.1,0.1v0.4l-0.1,0.2v0.2l-0.2,0.3l0.1,0.3L669.3,620.7L669.3,620.7z M669.5,620.5h-0.2l-0.1-0.1
                v-0.2l-0.1-0.1l0.1-0.2v-0.2h0.3h0.3l0.1,0.3l-0.2,0.4L669.5,620.5z"/>
            <linearGradient id="TG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="227.1579" x2="0.1864" y2="227.1579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TG" class="stcustom0 st218" d="M477.6,432l-0.1,0.3l-0.1,0.4l-0.1,0.1l-0.1,0.9v0.1l0,0l0.5,0.3l0.6,0.4l0.4,0.3v0.1v0.6v0.5
                l0.1,0.3v0.3l0.1,0.2l0.4,0.4l0.1,0.2v0.8v0.6l0.1,0.8v0.7v0.9v1v1h-0.3l0.1,0.3v0.3v0.1l-0.1,0.1l0.1,0.2l0.1,0.1l0.3,0.4l0.1,0.4
                l-0.5,0.1v0.1l-0.9,0.2l-0.3,0.2v-0.2H478l-0.2-0.1l-0.1-0.1l-0.1-0.2V445h-0.2l-0.3-0.2l-0.2-0.2l-0.1-0.2v-0.1v-0.1h-0.1l-0.2-0.4
                l-0.1-0.2l-0.1-0.1v-0.1v-0.1v-0.1l0.1-0.1V443v-0.2l0.1-0.4V442l-0.1-0.1h-0.1l-0.1-0.1v-0.2v-0.1l0.3-0.5l-0.1-1.2v-0.2l0.1-0.1
                l0.1-0.1v-0.1l-0.2-0.4l-0.4-0.3l-0.2-0.2l-0.1-0.2v-0.1l0.2-0.2l0.1-0.1v-0.1l-0.1-0.2v-0.4l0.1-0.3l0.1-0.4v-0.1l-0.2-0.2h-0.1
                h-0.1l-0.2,0.2h-0.1h-0.1v-0.1l0.1-0.1V436l0.1-0.1h0.1l0,0h-0.2v-0.1v-0.1h0.1h0.1l0,0v-0.3v-0.1v-0.2v-0.9v-0.1V434h-0.1l-0.3-0.2
                l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.3-0.2l-0.1-0.1v-0.1l0.1-0.2l0.1-0.3l0.1-0.4v-0.1l-0.2-0.2l0.6,0.1l0.9,0.2l0,0v0.1h0.2l0.3-0.1
                H477.6z"/>
            <linearGradient id="TH_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="239.9577" x2="0.1864" y2="239.9577" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TH" class="stcustom0 st219" d="M756.1,404.9v0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.1l0.2,0.1l0.2,0.3l0.2,0.2h0.1l0.1,0.1v0.1l-0.2,0.4
                l-0.1,0.3l-0.2,0.4l0.1,0.3l0.3,0.3l0.3,0.2h0.3l0.2-0.1l0.1-0.1l0.1-0.1h0.2l0.5,0.1l0.2,0.1v0.2l-0.1,0.5l0.1,0.3l0.1,0.4v0.3
                l-0.2,0.5l-0.2,0.5l-0.2,0.2l-0.1,0.2v0.1v0.1l0.1,0.2l0.1,0.2v0.2v0.2l-0.1,0.3l-0.3,0.7l-0.3,0.6l0.1,0.1l0.3,0.1l0.2,0.1l0.2-0.1
                l0.4-0.4l0.3-0.3l0.4-0.3l0,0l0.3-0.2l0.2-0.2l0.1-0.2l0.1-0.1l0.2,0.1l0.2-0.1l0.2-0.3l0.2-0.1h0.1l0.2,0.2l0.3,0.3l0.3,0.2
                l0.3,0.1l0.1,0.1v0.2l0.1,0.1h0.1h0.1v-0.1l0.1-0.1l0.3-0.2l0.3-0.1h0.3l0.2-0.1l0.1-0.3l0.2-0.2l0.1-0.1l0.2-0.1v-0.1l-0.1-0.1
                v-0.1l0.1-0.1h0.2h0.3l0.4,0.1l0.5,0.2l0.3,0.1l0.1-0.1l0.3,0.3l0.4,0.7l0.4,0.5l0.3,0.3l0.3,0.3l0.3,0.2l0.2,0.2l0.2,0.5l-0.2,0.7
                v0.6v0.7l0.2,0.5l0.4,0.4l0.2,0.3l0.1,0.2l0.3,0.2l0.5,0.2l0.2,0.1l-0.1,0.1v0.2l0.1,0.2l0.2,0.1l0.3,0.1l0.2,0.1l0.1,0.1v0.2
                l-0.1,0.3l-0.1,0.2l-0.2,0.2v0.1v0.2v0.4l0.1,0.2v0.3l-0.1,0.3l-0.1,0.5v0.2L771,422l-0.1,0.2l-0.2,0.2l-0.3,0.1l-0.2,0.1l0,0
                l-0.1,0.2L770,423h-0.1l-0.1-0.1l-0.1-0.1v-0.2l-0.3-0.1l-0.3-0.1l-0.6,0.1l-0.5,0.1l-0.5-0.1l-0.5,0.1h-0.2h-0.2h-0.2h-0.2
                l-0.4-0.1h-0.2l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.5,0.2l-0.3,0.3l-0.1,0.2l-0.2,0.2l-0.2,0.4l-0.3,0.4l-0.2,0.2l0.1,0.1l-0.1,0.1
                l-0.3,0.1h-0.3v0.1v0.7l0.1,0.3l0.2,0.3l0.1,0.2l0.1,0.5v0.5l0.4,0.3l0.2,0.2l0.1,0.2l-0.1,0.1l-0.1,0.4l0.1,0.5l0.5,1v0.1h-0.1
                l-0.1-0.2l-0.3-0.3l-0.1-0.4l-0.3-0.4l-0.2-0.2l-0.1,0.1l-0.1,0.1l-0.3-0.2l-0.3-0.2l-0.2-0.4v0.1l-0.1,0.1l-0.3-0.3l-0.3-0.3
                l-0.3-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1l-0.8,0.2l-1-0.2l-0.4,0.1l-0.2-0.1l-0.1-0.2l0.1-0.3v-0.6l0.1-0.4l-0.1-0.3l0.1-0.2v-0.2
                l-0.2-0.1l-0.7-0.2l-0.1-0.1l-0.2,0.2l-0.8,0.1l-0.3,0.1l-0.3,0.2l-0.1,0.3l0.2,0.2l0.1,0.4l-0.3,0.8l-0.1,0.2l0.1,1v0.5l-0.2,0.4
                l-0.3,0.3l-0.1,0.5l-0.2,0.2l-0.3,0.6l-0.2,0.7l-0.1,0.3l-0.1,0.6l-0.6,0.9l-0.1,0.5l-0.2,0.2l0.1,0.2v0.3l-0.1,0.7v0.6l0.1,0.3
                l0.3,0.6L753,437v0.2l0.2,0.1h0.2l0.9-0.3l0.3,0.1l0.1,0.3l0.1,0.2l0.2,1.2l0.1,0.2l0.2,0.2l0.2,0.2h0.1v-0.1v-0.1l0.2,0.2l0.1,0.4
                l0.5,2.3l0.1,0.3l0.1,0.3l-0.3-0.2l-0.1-0.5l-0.1-0.2H756h-0.2v-0.1l0.1-0.2v-0.2l-0.2-0.2l-0.3,0.1v0.4l0.1,0.3l0.5,0.6l0.1,0.3
                l0.2,0.1h0.3l0.3,0.3l0.3,0.2l0.6,0.4h0.4l0.4-0.1h0.3l0.3,0.1l0.3,0.3l0.5,0.8l0.8,0.7l-0.1,0.2v0.2l-0.3,0.3l-0.1,0.2l-0.1,0.2
                l-0.2,0.1h-0.2h-0.1h-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.4,0.2l-0.4,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.1h-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1
                v-0.2l0.2-0.3v-0.2V446l-0.1-0.2h-0.1h-0.1h-0.3l-0.2-0.2l-0.1-0.3l-0.1-0.1l-0.1-0.1l-0.2,0.1l-0.2-0.1l-0.6-0.2l-0.2-0.4h-0.1
                h-0.1v0.1l-0.1,0.4l-0.1,0.1l-0.7-0.9l-0.5-0.4l0.1-0.6l-0.1-0.1h-0.2l-0.1-0.2l0.1-0.4l-0.2,0.1h-0.3l-0.2-0.1l-0.2-0.5l-0.1-0.2
                l-0.2-0.3h0.6l-0.1-0.1v-0.3l-0.2-0.2l-0.3-0.2l-0.2-0.1l-0.2-0.6l-0.2-0.1l-0.2-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.2h-0.2l-0.2-0.1
                l-0.2-0.6v-0.3v-0.6l0.2-0.6l0.1-0.9l0.2-0.6l0.1-0.2l0.2-0.8l0.4-1v-0.2l0.1-0.2l0.1-0.2v-0.4l-0.1-0.2v-0.5l0.1-0.1l0.3-0.2
                l0.4-0.4l0.5-0.5l0.5-0.8l0.2-0.5l0.1-0.2l0.1-0.1l0.2-0.2l0.1-0.2v-0.1l-0.3-0.9l-0.2-0.3l-0.1-0.3v-0.2l-0.1-0.2v-0.2l-0.1-0.1
                l-0.2-0.2l-0.2-0.2l-0.1-0.4v-0.2l-0.1-0.2V426l0.1-0.2l0.1-0.2v-0.8v-0.2l-0.1-0.4l-0.1-0.3l-0.2-0.4l-0.2-0.3l-0.6-0.5l-0.4-0.4
                l-0.2-0.3l-0.3-0.4l-0.2-0.3l-0.2-0.3l-0.1-0.5l-0.1-0.5v-0.2l0.1-0.1l0.2-0.1h0.1l0.3-0.2h0.2h0.1v-0.1v-0.5v-0.6v-0.5l0.1-0.3
                l0.6-0.4l0.1-0.2l0.1-0.2v-0.2l-0.1-0.1l-0.1-0.1l-0.4,0.3l-0.1-0.1l-0.2-0.5l-0.1-0.2l-0.1-0.2l-0.1-0.3v-0.5l-0.1-0.2l-0.5-0.5
                l-0.2-0.3l-0.3-0.4l-0.4-0.5l-0.4-0.4l-0.2-0.3l-0.1-0.1v-0.1l0.1-0.3l-0.1-0.3l-0.2-0.4l-0.1-0.2v-0.1h-0.1h-0.2l-0.2-0.2l-0.2-0.4
                v-0.1h0.1l0.2,0.1h0.1l0.2-0.1l0.3-0.1h0.2h0.1v-0.1l-0.1-0.9v-0.2l0.3-0.4v-0.4l0.1-0.6l0.3-0.4l0.2-0.2l0.1-0.3l0.1-0.1h0.2
                l0.4,0.2h0.2h0.2h0.2h0.1l0.7-0.2h0.2h0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.5l0.1-0.1l0.1-0.1l0.2-0.1h0.2l0.2,0.1h0.2h0.2l0.1-0.1
                l0.1-0.1v-0.1v-0.2l-0.1-0.3l0,0l0.2,0.1l0.3,0.1h0.2h0.1l0.1-0.1l0.2-0.1h0.2l0.1,0.1L756.1,404.9L756.1,404.9z M762.6,429.2
                L762.6,429.2L762.6,429.2h-0.2h-0.2l-0.1-0.4v-0.1h0.1l0.2,0.2l0.1,0.1L762.6,429.2L762.6,429.2z M763.1,430v0.3l-0.2-0.1v-0.1
                l0.1-0.1L763.1,430z M756,435.7L756,435.7L756,435.7l-0.2-0.1v-0.1h0.2l0.1,0.1L756,435.7z M755.9,436v0.2l-0.1,0.2l-0.3,0.1
                l-0.1-0.2v-0.2V436H755.9L755.9,436z M751,437.5h-0.2v-0.2v-0.1h0.1L751,437.5L751,437.5z M751.3,440.7v0.2h-0.1l-0.1,0.1H751
                l-0.1-0.4l0.1-0.6l0.1-0.1l0.1,0.2l0.2,0.1l-0.1,0.3L751.3,440.7L751.3,440.7z M751.8,440.6L751.8,440.6l-0.2-0.5l0.2,0.1V440.6
                L751.8,440.6z M753.2,441.6l0.1,0.3l-0.1-0.1V441.6l-0.1-0.1l0,0L753.2,441.6L753.2,441.6z M754.8,444.6L754.8,444.6l-0.2-0.2
                l0.1-0.3l0.1,0.4L754.8,444.6L754.8,444.6z"/>
            <linearGradient id="TJ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="321.4579" x2="0.1864" y2="321.4579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TJ" class="stcustom0 st220" d="M674.2,340L674.2,340v0.2l-0.6,0.2l-0.3,0.2l-0.1,0.3l0,0l-0.1,0.1H673l-0.2-0.4l-0.2-0.1l-0.3-0.1
                l-0.6-0.2l-0.3-0.1l-0.6,0.2l-0.7,0.2l-0.1,0.1l-0.1,0.1v0.1v0.1v0.1h-0.1l-0.2-0.1l-0.2-0.1l-0.1,0.2l-0.1,0.3v0.2l0.1,0.3v0.5h0.3
                h0.2l0.4-0.2h0.2l0.3,0.1h0.5h0.4h0.1l0.1-0.1h0.1l0.1,0.1l0.4-0.1h0.3h0.2h0.1l0.2,0.3l0.2,0.2l0.2,0.1l0.6-0.1l0.2-0.3l0.1-0.1
                h0.2l0.2-0.1l0.2-0.1l0.2-0.1h0.2l0.1,0.1v0.1v0.1v0.1l0.1,0.1h0.4l0.2,0.1v0.2v0.3l0.1,0.1h0.1l0.5-0.3h0.1l0.1,0.1l0.2,0.2
                l0.2,0.2h0.1l0.1-0.2l0.2-0.2l0.4-0.1l0.2-0.1h0.2l0.7,0.1h0.2h0.4h0.4l0.3-0.1l0.1-0.1l0.2-0.1h0.3h0.2v0.2v0.4v0.2l0.2,0.4
                l0.1,0.2l0.1,0.2v0.1v0.1l-0.2,0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.4l0.1,0.3l0.2,0.2l0.3,0.1h0.2l0.1-0.2l0.2-0.2h0.2h0.3l0.7,0.2
                l0.6,0.3l0.2,0.2l0.1,0.2l-0.2,0.5v0.3v0.3l0.1,0.2l0.1,0.4v0.3l0.1,0.1v0.1l-0.1,0.3L685,349l0.1,0.1l0.2,0.1l0.3,0.3l0.1,0.2
                l-0.1,0.1l-0.2,0.2l-0.3,0.2l-0.1,0.1l0,0l-0.1-0.2l-0.3-0.2l-0.2-0.1H684h-0.2l-0.3-0.1h-0.3l-0.2,0.2l-0.1,0.2h-0.3l-0.4,0.1
                l-0.6,0.2h-0.3l-0.1-0.1l0.1-0.1l0.2-0.1v-0.2v-0.2h-0.2h-0.1h-0.1l-0.3-0.1h-0.3l-0.5,0.2l-0.9,0.5l-0.4,0.3l-0.3,0.5l-0.8,0.2
                l-0.6,0.3l-0.6,0.5l-0.4,0.3h-0.2h-0.2l-0.2-0.1l-0.2-0.4l-0.2-0.6l-0.1-0.4l0.1-0.5l0.1-0.6l0.1-0.6l0.1-0.7l0.1-0.2v-0.2l-0.1-0.1
                h-0.2l-0.3,0.1h-0.2l-0.1-0.1v-0.3l0.1-0.6l-0.2-0.5l-0.6-0.4l-0.5-0.1l-0.4,0.1l-0.3,0.3l-0.3,0.5l-0.3,0.4l-0.3,0.3l-0.2,0.2
                l-0.1,0.1v0.1l0.2,0.4v0.4l-0.2,0.3l-0.2,0.1H671l-0.2-0.1l-0.1-0.1h-0.3l-0.5,0.1l-0.4,0.1l-0.2,0.2l-0.1,0.3l0.1,0.4v0.3l-0.2,0.2
                l-0.1,0.1h-0.1l-0.2-0.2l-0.4-0.4L668,350h-0.1h-0.1h-0.1l0,0v0.1h-0.1h-0.2h-0.2l-0.1,0.1l-0.3,0.1l-0.4,0.2l-0.2,0.2v0.2l-0.1,0.1
                H666l-0.4,0.3l-0.3-0.1l-0.3-0.3l-0.2-0.3v-0.1l0.1-0.3v-0.9l0.1-0.3l0.4-0.5l0.2-0.4l0.2-0.3l0.2-0.1l0.2-0.3l0.1-0.3v-0.2v-0.1
                v-0.1l-0.2-0.2l-0.3-0.3l-0.2-0.3l-0.1-0.4v-0.3l0.3-0.8v-0.1l-0.1-0.1l-0.2-0.1h-0.2h-0.2h-0.3h-0.2l0,0v-0.4l-0.1-0.1l-0.1-0.1
                l-0.6-0.2l-0.1-0.1v-0.1l0.2-0.8l0.1-0.1l0.1-0.1l0.1-0.1l0.5-0.2l0.5,0.1l0.5,0.1l0.5,0.1h0.2h0.3h0.2l0.1-0.1l0.2-0.3l0.1-0.4
                l0.1-0.3h0.1h0.1l0.1-0.1v-0.1v-0.1h0.1l0.1,0.1h0.1v-0.1v-0.1l-0.1-0.1l-0.1-0.2v-0.1v-0.1l0.3-0.1h0.1v-0.1v-0.1l-0.1-0.1h-0.4
                h-0.4v-0.1v-0.1l0.1-0.1l0.8-0.1l0.4,0.1l0.3,0.1h0.1l-0.2-0.3h0.2v-0.1l-0.3-0.9l0.2-0.1l0.2-0.2v-0.3l0.1-0.2l0.2-0.1l0.2,0.1
                l0.4,0.3l0.1,0.1h0.1l0.2-0.1l0.7-0.3l0.4-0.2l0.4-0.3l0.1-0.1l0.2-0.4h0.1h0.1l0.4,0.4l0.2,0.3v0.1l-0.1,0.1v0.1l0.3,0.1v0.1
                l-0.1,0.1v0.1l0,0l-0.4,0.4l-0.5,0.4v0.1v0.1v0.1l0.1,0.1l0.2,0.1l0.2,0.1l0.1,0.2l0.1,0.2h0.1l0.7-0.1L674.2,340L674.2,340z
                M673.4,337.4L673.4,337.4l-0.2-0.2l-0.1-0.1h0.1l0.1,0.1L673.4,337.4L673.4,337.4L673.4,337.4z M673.5,341.5l-0.3,0.1h-0.1
                l-0.1-0.1l-0.1-0.1v-0.1l0,0l0.2,0.1h0.3L673.5,341.5L673.5,341.5L673.5,341.5z"/>
            <linearGradient id="TK_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="177.9579" x2="0.1864" y2="177.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TK" class="stcustom0 st221" d="M1001.4,486.8L1001.4,486.8L1001.4,486.8L1001.4,486.8L1001.4,486.8L1001.4,486.8L1001.4,486.8
                L1001.4,486.8L1001.4,486.8L1001.4,486.8z M1005,489L1005,489L1005,489L1005,489L1005,489L1005,489L1005,489L1005,489L1005,489
                L1005,489z"/>
            <linearGradient id="TL_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="178.3079" x2="0.1864" y2="178.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TL" class="stcustom0 st222" d="M827.7,485.6l-0.2,0.5l-0.2-0.1l0.2-0.3L827.7,485.6L827.7,485.6z M826.1,489.5l-0.1-0.4l-0.1-0.2
                l-0.1-0.1v-0.1v-0.1v-0.1h0.3l0.1-0.2v-0.2l-0.1-0.1H826l-0.4,0.2h-0.1l-0.1-0.1v-0.3l0.3-0.2l0.2-0.4l0.2-0.2l0.4-0.2h0.2l1.2-0.2
                h0.3h0.8l1-0.1h0.2l0.3-0.1l0.3-0.1l0.2-0.1l0.2-0.1l0.3,0.1l0.4,0.1l0.1,0.1l0.1,0.1l-0.5,0.4l-0.6,0.4l-0.3,0.1l-0.4,0.1l-0.3,0.1
                l-0.2,0.2l-0.3,0.1h-0.3l-0.3,0.1l-0.3,0.1l-0.4,0.2h-0.1h-0.2l-0.3,0.1l-0.9,0.3l-0.6,0.4V489.5z M823.2,489l0.5-0.2l0.7-0.2v0.1
                l-0.1,0.2l-0.1,0.1l-0.2,0.2h-0.1h-0.4h-0.1h-0.1L823.2,489L823.2,489L823.2,489z"/>
            <linearGradient id="TM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="321.7578" x2="0.1864" y2="321.7578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TM" class="stcustom0 st223" d="M661.8,350.3L661.8,350.3l-0.4-0.1l-0.7-0.2l-0.6-0.3l-0.4-0.2h-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.4
                l-0.1,0.4h-0.7h-0.6l-0.4,0.2L657,351l-0.1,0.3l-0.1,0.3l-0.2,0.7l-0.2,0.7l-0.1,0.4l-0.2,0.3l-0.4,0.4l-0.5,0.3l-0.3,0.1l-0.1,0.2
                v0.1h-0.1h-0.2h-0.2l-0.5,0.2l-0.5,0.2l-0.6,0.2h-0.3h-0.1l-0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.4l-0.2,0.1
                l-0.3,0.2l-0.4,0.3l-0.1,0.1l-0.2,0.1h-0.2h-0.2l-0.2,0.1l-0.2,0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.3-0.3l-0.3-0.2h-0.1l-0.3,0.1
                h-0.3h-0.3l-0.2-0.1l-0.3-0.3l-0.1-0.2l-0.1-0.1h-0.2l-0.1-0.1V356l0.1-0.2v-0.4l-0.1-0.3l-0.1-0.1v-0.1l0.1-0.2l0.1-0.2v-0.3
                l-0.1-0.3v-0.5v-0.5l-0.1-0.2H646h-1l-0.1-0.1l-0.4-0.6l-0.3-0.5l-0.3-0.3l-0.7-0.3l-0.4-0.1l-0.3-0.3l-0.2-0.3l-0.1-0.4v-0.1
                l-0.1-0.1H642h-0.1l-0.9-0.5l-0.3-0.1l-0.3,0.1h-0.1L640,349l-0.3,0.2h-0.1h-0.2l-0.2-0.1l-0.4-0.4l-0.4-0.2l-0.3-0.1l-0.5-0.2
                l-0.5-0.1l-0.3-0.1l-0.2-0.1l-0.1-0.1v-0.2v-0.2l-0.1-0.1l-0.1-0.2l-0.2-0.1h-0.3h-0.5l-0.4-0.1h-0.3h-0.4h-0.3l-0.2,0.1l-0.1,0.1
                l-0.1,0.3l-0.1,0.1h-0.1h-0.2h-0.3l-0.6-0.1h-0.7l-0.6,0.2l-0.4,0.2l-0.4,0.3l-0.5,0.4l-0.1,0.2l-0.3,0.8l-0.1,0.1l-0.2,0.1h-0.2
                l-0.3,0.1L628,350l-0.3,0.1h-0.8v-0.2l-0.1-0.9l-0.1-0.9v-0.4l0.1-0.8v-0.4v-0.4v-0.4l0.1-0.4v-0.4v-0.3l-0.2-0.2l-0.3-0.3v-0.2
                v-0.2h-0.2l-0.2-0.2l-0.2-0.1l-0.4-0.1h-0.2l-0.2,0.1l-0.1,0.2l-0.1-0.3V343l0.3-0.6l0.2,0.2l0.2,0.1h0.3h0.3l-0.1-0.2l-0.1-0.1
                l-0.2-0.1l-0.1-0.3v-0.3l0.1-0.3l-0.1-0.1l-0.1-0.1h-0.3l-1-0.3h-0.4l-0.1,0.3l0.2,0.4l-0.2-0.2l-0.2-0.3l-0.3-0.5L623,340v-0.7
                l0.1-0.5l0.2-0.5l0.1-0.7l0.2-0.6l0.2,0.3l0.2,0.3l0.2,0.2l0.1,0.1l0.4,0.1h0.3l0.3-0.1h0.3l0.2,0.3l0.2,0.3l0.3,0.1l0.6-0.2h0.3
                l0.3,0.1h0.1h0.1l-0.1-0.3v-0.3l0.2-0.1l0.5,0.1l0.3-0.1l0.1-0.1l0.1-0.1v-0.2v-0.2V337l-0.1-0.2l-0.2-0.3l-0.9-0.6l-0.3-0.3
                l-0.2-0.3l-0.2-0.5l-0.1-0.5l-0.1-0.4l-0.3-0.8l-0.1-0.1h-0.1h-0.4l-0.4,0.1l-0.6,0.1h-0.3l-0.2,0.1l-0.4,0.4l-0.2,0.3l-0.3,0.7
                l0.2,0.2v0.1l-0.1,1l0.1,0.5l0,0l-0.1-0.1l-0.2-0.5l-0.4-0.6l-0.3-0.9l0.6-0.6l0.5-0.4l0.4-0.3l0.1-0.1l0.5-0.2l0.7-0.2l0.5-0.1
                l0.7-0.1h0.2h0.3l0.3,0.1l0.2,0.1l0.6,0.4l0.6,0.4l0.5,0.4l0.1,0.2l0.1,0.2l0.1,0.2l0.4,0.6l0.2,0.3l0.2,0.4l0.2,0.2l0.2,0.2
                l0.1,0.2l0.1,0.1h0.2l0.4-0.1l0.5-0.1h0.3h0.1h0.7h0.7h0.8h0.2h0.3h0.1l0.1-0.2l0.1-0.1l0.1-0.1v-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.8
                l-0.1-0.7l0.2-0.2l0.2-0.2l0.3-0.5l0.2-0.1l0.3-0.1h0.9l0.4-0.1l0.1-0.2l0.2-0.4l0.1-0.3l0.1-0.1l0.1-0.1h0.1l0.3,0.1l0.2,0.1
                l0.1,0.1l0.1,0.1l0.1,0.2v0.1l0.1,0.1h0.1h0.1l0,0v-0.1v-0.1l-0.2-0.3l-0.4-0.5l-0.2-0.2l-0.1-0.1V331l0.2-0.1l0.1-0.1l0.3,0.1h0.3
                l0.2-0.1l0.2-0.4l0.4,0.4l0.4,0.4l0.2,0.1h0.3h0.2h0.1l0.1,0.1l0.2,0.5l0.2,0.1l0.3,0.1h0.9h0.3l0.2,0.2l0.1,0.1l0.1,0.1v0.1
                l-0.1,0.1v0.2v0.2l-0.1,0.1v0.1l0.1,0.1l0.4,0.2l0.1,0.2l0.1,0.1v0.1l-0.1,0.1H644l-0.1,0.1v0.2l0.1,0.2v0.2l-0.1,0.2l-0.1,0.3v0.2
                l0.1,0.1l0.3,0.2l0.7,0.5h0.2l0.7-0.1h0.3l0.2,0.1l0.5,0.1l0.2,0.1h0.2h0.2l0.2-0.2h0.1h0.1h0.1l0.4,0.1l0.4,0.3l0.3,0.3l0.1,0.2
                l0.2,0.5l0.2,0.8l0.3,0.5l0.3,0.3l0.2,0.5l0.2,1.1l0.1,0.2l0.1,0.1l0.4,0.3l0.7,0.5l0.4,0.3l0.7,0.5l0.6,0.4l0.6,0.7l0.1,0.1
                l0.5,0.4l0.6,0.4l0.4-0.1l0.6,0.6l0.3,0.2l0.1,0.1l0.5,0.2l0.7,0.5l0.9,0.7l0.6,0.4h0.2h0.2l0.2-0.1l0.2-0.1l0.3,0.1l0.3,0.2
                l0.2,0.1l0.3,0.2l0.2,0.2l0.2,0.1l0.5,0.1l0.1,0.1l0.1,0.1v0.1l-0.3,0.5v0.7v0.5L661.8,350.3L661.8,350.3z M624.1,345.2v0.2
                l-0.2-0.5l-0.1-0.6l0.1-0.2h0.1l-0.1,0.2L624.1,345.2z"/>
            <linearGradient id="TN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="303.3578" x2="0.1864" y2="303.3578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TN" class="stcustom0 st224" d="M507.4,364.6L507.4,364.6l-0.1,0.7v0.2v0.4v0.5l0.2,0.4v0.2l-0.1,0.2l-0.4,0.2l-0.5,0.3L506,368
                l-0.5,0.3l-0.2,0.2l-0.2,0.2l-0.2,0.2v0.1l-0.1,0.3l-0.2,0.2l-0.5,0.1l-0.1,0.1l-0.2,0.3l-0.1,0.1l-0.1,0.3l0.2,0.7l0.2,0.7v0.3v0.2
                l-0.1,0.3l-0.3,0.4l-0.2,0.3l-0.4,0.5l-0.1,0.1l-0.2,0.1l-0.5,0.2l-0.3,0.2l-0.2-0.8l-0.1-0.7l-0.1-0.5l-0.2-1l-0.2-0.8l-0.2-0.8
                l-0.2-0.7l-0.2-0.7l-0.1-0.1l-0.5-0.4l-0.5-0.3l-0.5-0.4l-0.5-0.4l-0.1-0.5l-0.3-0.8l-0.3-0.4l-0.1-0.1l-0.6-0.3l-0.3-0.2l-0.1-0.1
                l-0.1-0.3l-0.2-0.6l-0.3-0.6l-0.1-0.4V362l0.1-0.4l0.1-0.1l0.5-0.4l0.3-0.5l0.3-0.2l0.3-0.1l0.2-0.2l0.2-0.3l0.1-0.3v-0.3l0.1-0.5
                l0.1-0.4l0.2-0.4l-0.1-0.3l-0.1-0.4v-0.6v-0.2l-0.1-0.2l-0.1-0.3v-0.2l0.1-0.6l0.1-0.5l0.1-0.6v-0.2l-0.1-0.1l-0.3-0.1v-0.1l0.1-0.1
                l0.4-0.3l0.2-0.4l0.2-0.1l0.3-0.2v-0.2L499,352l0.7-0.2l0.7-0.6l0.2-0.1l1.5-0.5h0.2l0.2,0.1l-0.1,0.2l-0.1,0.2l0.1,0.3l0.2-0.2
                v-0.1V351h0.3h0.3l0.3,0.2v0.6l0.4,0.6l-0.1,0.3l0.3,0.2l0.3-0.2l0.1-0.3l0.5-0.2l0.5-0.5h0.3l0.1,0.4l0.1,0.3l-0.2,0.1l-0.2,0.3
                l-0.5,0.9l-0.4,0.3l-0.3,0.3l-0.1,0.2v0.3l0.1,0.5l0.2,0.5l0.3,0.3l0.3,0.1l0.6,0.5v0.3l0.1,0.3v0.4l0.2,0.3l-0.5,0.7l-0.3,0.5
                l-0.5,0.7l-0.4,0.5l-0.9,0.7l-0.2,0.2l-0.1,0.2l-0.1,0.2v0.3l0.3,0.7l0.4,0.4l0.4,0.2l0.7-0.1v0.3l0.1,0.3h0.3h0.2l0.2-0.3l0.4,0.2
                l0.2,0.6l0.3,0.2v0.1h-0.1l-0.1,0.1l0.1,0.1l0.3,0.1l0.2-0.1L507.4,364.6L507.4,364.6z M506.7,359.3l-0.4,0.2l0.1-0.2l0.3-0.3
                l0.1,0.1L506.7,359.3z M505.8,362.8L505.8,362.8l-0.2,0.1h-0.1l-0.2-0.1h-0.1l-0.1-0.1v-0.4v-0.1h0.5l0.3,0.2v0.1v0.1L505.8,362.8
                L505.8,362.8L505.8,362.8z"/>
            <linearGradient id="TO_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="146.0578" x2="0.1864" y2="146.0578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TO" class="stcustom0 st225" d="M997.2,515.7l-0.1,0.2H997l-0.1-0.1v-0.1l0.2-0.2h0.1l0.1,0.1L997.2,515.7L997.2,515.7L997.2,515.7z
                M993.8,523.2L993.8,523.2L993.8,523.2l0.2-0.1v0.1l-0.2,0.3l-0.1-0.1l-0.4-0.2l-0.1-0.2l0.1-0.1v0.1h0.1h0.2L993.8,523.2
                L993.8,523.2L993.8,523.2L993.8,523.2z M994.5,523.6v0.5l-0.1-0.2v-0.1L994.5,523.6L994.5,523.6L994.5,523.6z"/>
            <linearGradient id="TR_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="321.6079" x2="0.1864" y2="321.6079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TR" class="stcustom0 st226" d="M553.7,333.5l-0.1,0.4l0.2,0.5l0.4,0.7l0.4,0.3l1.7,0.8l0.3,0.1l-0.1,0.3l-0.1,0.3l-0.1,0.2l-0.5,0.1
                l-1.4-0.4h-0.3l-0.2,0.1l-0.5,0.3l-0.5-0.1l-0.7,0.1l-0.2,0.5l-0.5,0.6l-0.8,0.5l-0.6,0.2l-0.9,0.9l-0.4,0.5l-0.2,0.1l-0.2,0.1
                l0.1-0.2l0.1-0.2V340v-0.2l0.3-0.3l0.3-0.2l0.8-0.4l0.2-0.3h-0.6l-0.6,0.1h-0.4h-0.3l-0.1-0.3l-0.1-0.2h0.1h0.1l0.2-0.3l0.2-0.2
                l0.3-0.3l0.1-0.2v-0.1L549,337v-0.1v-0.2v-0.4l0.6-0.4h0.2l0.1-0.2v-0.4l-0.1-0.3h-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.3-0.1v-0.1
                v-0.1l0.1-0.1l0.4-0.1v-0.1l0.1-0.2l0.1-0.2l0.1-0.1h0.2h0.3l0.2-0.1l0.2-0.1l0.1-0.1l0.5-0.1l0.1-0.1l0.1,0.1l0.2,0.2l0.3,0.3
                l0.2,0.1h0.1l0.2-0.1h0.2h0.2l0.1-0.1h0.1L553.7,333.5L553.7,333.5z M591.6,335.2l0.2,0.1l0.4,0.1l0.2,0.1h0.1l0.3-0.2h0.4h0.4h0.2
                l0.2,0.1l0.3,0.1l0.1-0.1l0.2-0.3h0.1l0,0h0.2h0.2l0.1,0.1l0.1,0.3l0.2,0.1l0.4,0.4l0.3,0.2l0.1,0.1l-0.1,0.1v0.1l0.1,0.1l0.2,0.1
                h0.2h0.1l0.1,0.1v0.1v0.1v0.2l0.2,0.2l0.2,0.1l0.1,0.1l0.2,0.5l0.1,0.3v0.3l-0.1,0.3l-0.3,0.3l0.1,0.1l0.1,0.2v0.1l0.2,0.4l0.1,0.3
                l-0.1,0.1v0.1l0.4,0.2l0.4,0.2h0.2l0.5-0.1h0.3l0.3,0.2l0.5,0.4l0.5,0.5l0.1,0.2v0.1l0.1,0.1h-0.1l-0.2-0.1l-0.4-0.3l-0.2,0.1
                l-0.2,0.2l-0.2,0.9l-0.2,0.1h-0.3h-0.3h-0.2l-0.1,0.1v0.1l0.1,0.1v0.2v0.1l0.1,0.1l0.2,0.1v0.1v0.2v0.1v0.1l0.1,0.2l0.2,0.3l0.1,0.1
                v0.5l0.1,0.2v0.3v0.5v0.1h0.1h0.2h0.2l0.1,0.1v0.1l-0.2,0.2v0.2l-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.1,0.3v0.2v0.1h0.3l0.2,0.1l0.5,0.3
                l0.1,0.1l-0.1,0.2v0.1l0.1,0.1v0.2v0.2v0.2h0.1l0.3,0.2l0.2,0.2v0.1l-0.1,0.2v0.2l0,0l-0.1-0.1h-0.2h-0.2l-0.1,0.1l-0.2,0.2
                l-0.3,0.2l-0.2,0.2l-0.1,0.1h-0.1l-0.1-0.1v-0.1v-0.5v-0.2l-0.1-0.1l-0.1-0.1h-0.1H599l-0.2,0.2l-0.3,0.2H598h-0.3h-0.1l-0.6-0.2
                h-0.1l-0.2-0.1l-0.3-0.1l-0.4,0.1h-0.2l-0.3-0.1h-0.1l-0.3,0.4l-0.5,0.4l-0.3,0.1l-0.1-0.4l-0.1-0.2H594l-0.1-0.1h-0.1l-0.3,0.3
                l-0.5,0.2l-0.4,0.1l-0.6,0.1l-0.5,0.1h-0.2l-0.5-0.1l-0.4-0.1H590h-0.3l-0.7,0.3l-1.2,0.6l-0.9,0.3l-0.5,0.1l-0.4,0.1h-0.7h-0.6
                h-0.4l-0.2-0.1l-0.3-0.3l-0.4-0.3l-0.2-0.1H583h-0.3l-0.8,0.4l-0.2,0.1l-0.3,0.1l-0.6,0.2l-0.2,0.1h-0.3h-0.4h-0.3l-0.2-0.2
                l-0.1-0.2l-0.5-0.1h-0.3l-0.1,0.1l-0.1,0.3l-0.1,0.7v0.2l0.3,0.7v0.1h-0.2h-0.2l-0.2,0.1l-0.1,0.1l-0.1,0.6l-0.3,0.1l-0.1,0.1
                l-0.1,0.4h-0.1l-0.4-0.3h-0.2l0.2-0.3l-0.2-0.6l-0.2-0.5l0.2-0.3l0.4-0.4l0.4-0.5v-0.3v-0.2l-0.1-0.2L577,352l-0.4,0.2l-0.3,0.2
                h-0.2l-0.2,0.1l-0.1,0.2l-0.2,0.2l-0.4,0.1l-0.6-0.2l-0.7-0.3l-0.4-0.3l-0.3-0.1l-0.3,0.1l-0.8,0.6l-0.8,0.9l-0.2,0.2l-0.7,0.4
                l-0.5,0.1h-0.2l-1,0.2h-0.5l-0.4,0.2l-0.7-0.2l-0.4-0.3l-0.3-0.3l-0.4-0.6l-0.3-0.3l-0.7-0.3l-1.2-0.7l-0.3-0.1l-0.8-0.1l-0.9-0.1
                l-0.2,0.2l-0.1,0.9l-0.1,0.3l-0.1,0.5l-0.1,0.1l-0.2,0.1l-0.3-0.2l-0.2-0.1l-0.4,0.2l-0.8,0.3H559l-1-0.4l-0.3-0.2l-0.2-0.3
                l-0.1-0.4l-0.1-0.2v-0.2l-0.1-0.2l-0.2-0.1l-0.2,0.1h-0.2l-0.3-0.1l-0.7-0.4h-0.5l-0.3,0.4l-0.2,0.1h-0.3v-0.1l0.2-0.3l-0.8,0.1
                l-0.4,0.2H553l-0.2-0.1v-0.1h0.2l0.2-0.1l0.9-0.1l0.2-0.1l0.2-0.3l0.4-0.3l0.1-0.1h-0.3l-1.3,0.1h-0.9l-0.1,0.1h-0.1v-0.4l0.1-0.2
                h0.2l0.5-0.1v-0.3l-0.3-0.2l-0.1-0.1h-0.2l-0.2-0.1v-0.4l-0.2-0.4l-0.2-0.2v-0.1l0.4-0.1l0.1-0.6l-0.1-0.3h-0.2l-0.6-0.3h-0.2
                l-0.2-0.3l-0.3-0.2l-0.2,0.1l-0.1,0.1h-0.2l-0.3-0.2l-0.3-0.1l-0.1-0.1l0.1-0.3h0.2V346l-0.2-0.4v-0.2l0.2-0.1h0.2l0.2,0.3l0.1,0.2
                v0.2l0.1,0.2l0.1,0.1l0.1-0.2h0.1l0.1,0.1l0.3,0.1l0.7-0.2l0.1-0.1h-0.5l-0.2-0.1l-0.2-0.3l-0.1-0.2v-0.1l-0.1-0.2l0.1-0.1l0.3-0.1
                l0.3-0.4l-0.1-0.1l-0.1-0.1h-0.2l-0.1-0.1V344l0.1-0.2v-0.2l-0.4-0.5l-0.1-0.1l0.1-0.2l0.3-0.3l0.3-0.4V342h-0.2l-1,0.2l-0.4,0.1
                l-0.7,0.1v-0.2V342l0.2-0.3v-0.8l0.1-0.4l0.4-0.1l0.5-0.6l0.7-0.7h0.8l0.3-0.2h0.5l0.1,0.1l0.1,0.1l0.4,0.2h0.7l0.2-0.1l0.2-0.1
                l-0.3-0.4l0.1-0.1h0.3l0.3,0.1v0.1l-0.1,0.1l-0.1,0.2h0.1l0.9-0.1l1,0.1l0.3-0.1h0.8l0.1-0.1l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.1
                l0.5-0.4l0.3-0.1l1.3-0.2l0.9-0.1v-0.1h-0.1l-1.2-0.2l-0.3-0.1l-0.4-0.3l-0.1-0.1l-0.1-0.2l0.1-0.3l0.1-0.3l0.2-0.2h0.5l1.7,0.3
                l1.2-0.2l1.3,0.4l1.2-0.1l0.3-0.2l0.3-0.6l1.8-1l0.6-0.5l0.7-0.3l1.1-0.3l0.9-0.4h0.3l2.3,0.2h1.6l0.7-0.4l0.4,0.1v0.1l-0.1,0.1v0.2
                l0.2,0.4l0.2,0.2l0.7,0.4l1-0.3h0.2l0.2,0.1l0.4,1l0.3,0.3l0.4,0.2h0.3l0.2-0.2l0.2-0.1h0.4l0.6,0.3l0.2,0.3l1,0.3l0.9,0.1l0.4,0.3
                l1.3,0.3h0.5l0.8-0.3l1.6-0.3l1.1,0.5l0.3,0.1h0.2l0.4,0.1l0.4-0.1l1.2-0.5l0.4-0.3l0.4-0.1l0.3-0.2l0.9-0.6L591.6,335.2
                L591.6,335.2z M548,340.3l-0.6,0.1l-0.2-0.1l0.2-0.2l0.4-0.1h0.1l0.2,0.2L548,340.3L548,340.3z"/>
            <linearGradient id="TT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="233.0079" x2="0.1864" y2="233.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TT" class="stcustom0 st227" d="M304.6,431.5h-0.2v-0.1l0.3-0.2l0.4-0.1h0.1l-0.1,0.2L304.6,431.5z M303.9,434.4l-0.5,0.2h-1.2
                l-0.5-0.1h-0.4l0.7-0.3l0.1-0.1h0.3h0.1l0.1-0.8v-0.2l-0.1-0.1l-0.1-0.1l-0.3-0.1v-0.1l0.2-0.1h0.4l0.3-0.1h0.6l0.3-0.1h0.5
                l-0.2,0.4l-0.1,0.1v0.3l-0.1,0.2l0.1,0.3l0.1,0.2l-0.1,0.2L303.9,434.4L303.9,434.4L303.9,434.4z"/>
            <linearGradient id="TV_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="182.5079" x2="0.1864" y2="182.5079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TV" class="stcustom0 st228" d="M972.7,479.9L972.7,479.9L972.7,479.9L972.7,479.9L972.7,479.9L972.7,479.9L972.7,479.9z
                M969.9,480.4L969.9,480.4L969.9,480.4L969.9,480.4L969.9,480.4L969.9,480.4L969.9,480.4z M976.6,483.7L976.6,483.7L976.6,483.7
                L976.6,483.7L976.6,483.7L976.6,483.7z M978.1,486.8L978.1,486.8l-0.1-0.2l0,0l0,0l0,0l0,0L978.1,486.8L978.1,486.8L978.1,486.8
                L978.1,486.8z"/>
            <linearGradient id="TW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="270.9579" x2="0.1864" y2="270.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TW" class="stcustom0 st229" d="M814.7,398l-0.2,0.4l-0.1,0.4l-0.1,0.4v0.4v0.3l-0.1,0.3l-0.3-0.1l-0.1-0.2v-0.4l-0.2-0.5l-0.1-0.1
                l-0.3-0.3l-0.3-0.1l-0.2-0.2l0,0l-0.1-0.3l-0.1-0.3l-0.2-0.8l-0.1-0.2l-0.1-0.2v-0.2v-0.2l0.1-0.3l0.1-0.3v-0.4v-0.4l0.1-0.2
                l1.3-2.4l0.4-0.5l0.2-0.2l0.2-0.3l0.2-0.4l0.2-0.3l0.2-0.1l0.8-0.3l0.2-0.3l0.2-0.1h0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.3,0.2l0.1,0.1
                l0.1,0.3l-0.2,0.2L817,391v0.2v0.3v0.3l-0.3,0.8l-0.3,0.5l-0.1,0.2l-0.1,0.6v1l-0.1,0.7l-0.2,0.8l-0.1,0.3l-0.2,0.3l-0.4,0.6
                L814.7,398L814.7,398z M807.4,392.2l0.1,0.2l-0.1,0.1l-0.4-0.1v-0.1h0.1L807.4,392.2L807.4,392.2z"/>
            <linearGradient id="TZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="185.3078" x2="0.1864" y2="185.3078" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="TZ" class="stcustom0 st230" d="M586.5,485.1h-0.2h-0.1l-0.1-0.1l0.2-0.1l0.2-0.2l0.4-0.3l0.1-0.2l0,0v0.2l-0.2,0.5h-0.2L586.5,485.1
                L586.5,485.1z M585.9,480.1l0.2,0.6v0.1l-0.2,0.1h-0.1l-0.1-0.1l-0.1-0.2h-0.1l-0.2-0.2h-0.2l-0.2-0.3l0.1-0.2v-0.4l0.2-0.2l0.1-0.4
                l0.1,0.3v0.4l0.2,0.5L585.9,480.1L585.9,480.1L585.9,480.1z M587,476.5v0.1v0.1v0.4v0.3l-0.2,0.4l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.1
                l0.2-0.7l-0.1-0.5h0.3L587,476.5L587,476.5z M561.6,465.6L561.6,465.6h0.4h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5
                h0.5h0.5h0.3h0.2l0.2,0.1l0.2,0.1l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3
                l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.3,0.2v0.1v0.3v0.2v0.2l-0.2,0.3v0.1v0.1l0,0h0.1l0.1,0.1v0.1l0.1,0.2l0.1,0.1l0.3,0.2
                l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.2,0.2h0.1l-0.1,0.2l-0.2,0.6v0.2l-0.1,0.3l-0.1,0.2l-0.2,0.8
                l-0.2,0.3l-0.3,0.7v0.5l0.1,0.4l0.1,0.4l0.3,0.3l0.2,0.1l0.2,0.2l0.3,0.4l0.2,0.4l0.5,0.2l0.2,0.4l-0.1,0.3l-0.2,0.2l-0.2,0.4
                l-0.2,0.5v0.8l0.1-0.1l0.3,0.2v0.6l-0.3,0.7l-0.1,0.3v0.3l0.2,0.8l0.3,0.4v0.1l-0.1,0.1l0.5,0.7v0.6l0.2,0.5l0.1,0.4l0.1,0.3v0.2
                l-0.2,0.2l0.4,0.1l0.2,0.2l0.1,0.2h0.3l0.2,0.1l0.2,0.1l0.5,0.3l0.1,0.2v0.1v0.1l-0.3,0.2l-0.5,0.4l-0.5,0.4l-0.5,0.3l-0.3,0.1
                l-0.4,0.1l-0.3,0.2l-0.3,0.2l-0.4,0.1h-0.5l-0.5,0.2l-0.5,0.3l-0.3,0.2l-0.5-0.3l-0.4-0.1h-0.4h-0.3l-0.1,0.1l-0.1,0.2l-0.1,0.3
                l-0.3,0.3l-0.5,0.3l-0.5,0.1l-0.4-0.1l-0.3-0.1l-0.2-0.2l-0.2-0.1h-0.3l-0.3,0.1l-0.3,0.2l-0.4,0.1h-0.6l-0.3-0.1v-0.2l-0.3-0.2
                l-0.5-0.2h-0.4l-0.2,0.2l-0.2,0.1l-0.2,0.1h-0.2h-0.1h-0.1h-0.7h-0.6V495v-0.2l-0.1-0.2l-0.1-0.1h-0.1h-0.1l-0.1-0.1l-0.1-0.2
                l-0.1-0.2l-0.1-0.1h0.2v-0.1v-0.1l0.1-0.3v-0.2V493l-0.1-0.2l-0.1-0.3v-0.1v-0.2v-0.1v-0.2v-0.2l-0.1-0.5v-0.3l-0.1-0.2l-0.4-0.5
                v-0.1l-0.7-0.5l-0.3-0.1l-0.1,0.1v0.1v0.2v0.1l0,0h-0.2h-0.1l-0.2-0.1h-0.2h-0.5H569h-0.1l-0.3-0.3l-0.3-0.1H568l-0.4-0.3h-0.1l0,0
                l-0.2-0.1l-0.3-0.2l-0.4-0.1l-0.3-0.2l-0.2-0.2l-0.3-0.1h-0.3l-0.3-0.1h-0.3h-0.3l-0.1-0.1v-0.2l-0.1-0.1l-0.2-0.1H564h-0.1h-0.1
                l-0.2-0.1l-0.2-0.2l-0.1-0.3l-0.2-0.2l-0.3-0.1H562h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.3l-0.1-0.2l-0.1-0.3l-0.2-0.4l-0.3-0.5
                l-0.2-0.4l-0.2-0.5l-0.1-0.3l-0.2-0.4l-0.3-0.5l-0.1-0.2l-0.2-0.2l-0.4-0.3l-0.5-0.3l-0.2-0.2l-0.3-0.6l-0.1-0.2l-0.1-0.4l-0.1-0.4
                v-0.2l0.3-0.5v-0.1v-0.2l-0.1-0.4l-0.1-0.3l-0.1-0.2l-0.2-0.4l-0.2-0.5l-0.1-0.2v-0.2l0.1-0.5l0.1-0.5v-0.1h0.9l0.1-0.1l0.5-0.3
                l0.6-0.6l0.1-0.3l0.2-0.4l0.2-0.2l0.1-0.1l0.1-0.2l0.1-0.2l0.3-0.3l0.3-0.2v-0.1v-0.1v-0.1l0.2-0.1l0.3-0.1l0.1-0.2v-0.2v-0.1v-0.2
                v-0.1H561l-0.3-0.1h-0.2l-0.2-0.1l-0.1-0.1v-0.1v-0.2l0,0l0.1-0.2l-0.1-0.1l0,0v-0.1l0.3-0.5l0.1-0.1h0.1l0.2-0.1h0.2h0.1h0.1
                l0.1-0.1l0.1-0.2l0.1-0.3v-0.3l-0.1-0.2v-0.3l0.1-0.4v-0.4l-0.1-0.3l-0.1-0.2l-0.2-0.1l-0.3-0.4l-0.1-0.2V466h0.1l0,0h0.2l0.2-0.1
                l0.2-0.1L561.6,465.6L561.6,465.6L561.6,465.6z"/>
            <linearGradient id="UA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="358.3579" x2="0.1864" y2="358.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UA" class="stcustom0 st231" d="M582.3,313.4L582.3,313.4l-1.1-0.1l-0.8,0.1l-0.6,0.6h-0.3l-0.5,0.2l-0.3,0.2l-0.4,0.5l-0.3-0.2h-0.4
                l-0.4,0.1l-0.4,0.3l-0.2,0.1l-0.5-0.1l-0.6,0.2l-1.2,1l-0.4,0.7l-0.1,0.1l-0.2,0.2l-0.2,0.1h-0.1l0.6-0.5l0.2-0.2v-0.1v-0.2
                l-0.2-0.3l-0.5,0.7l-0.2,0.1l-0.3,0.2v0.5v0.3l0.1,0.4l0.3,0.7l0.7,1l0.3,0.4l0.2,0.1h0.3l0.5-0.3h0.2l0.5,0.1l0.2-0.2l0.3-0.1h0.3
                l0.4,0.1l0.4,0.2l-0.2,0.4l-0.2,0.3l-0.1,0.3l-0.1,0.3l-0.5,0.2H576l-0.5,0.1l-0.2-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.3,0.1
                l-0.3,0.5l-0.6,0.3l-0.2,0.4l-0.6-0.1l-0.5,0.1l-0.7,0.3l-0.5,0.7l-0.6,0.4l-0.5,0.1h-0.4l-0.3-0.1l-0.6-0.5v-0.2l0.1-0.1l0.1-0.2
                l0.2-0.9V322l-0.1-0.5l-0.5-0.4l-0.4,0.1l-0.2-0.1l-0.8-0.6h-0.4l-0.5,0.1l-0.2-0.1l-0.1-0.2l0.9-0.8l0.9-0.6l0.4-0.1l0.5-0.3
                l0.6-0.4l-0.1-0.3l-0.1-0.3l-0.3,0.1l-0.2,0.1l-0.5-0.3l-0.2-0.2l-0.7,0.2h-0.4l-0.9,0.2l-0.4-0.2l-0.8-0.5l-0.3-0.1h-0.3l-0.1-0.2
                l0.2-0.1h0.2l0.2-0.1l0.1-0.1v-0.2l-0.4-0.1h-0.4l-0.3-0.2l-0.2-0.2h0.5l0.5,0.1h0.7l0.6,0.1l0.2-0.2l0.4-0.3l0.1-0.1l-0.6,0.2
                l-0.6-0.1l-0.2-0.2l-0.2-0.3l-0.1-0.3l0.1-0.3l-0.1-0.5l-0.2-0.5l-0.1-0.3l0,0l0.2,0.5l0.1,0.3l0.1,0.3v0.8l-0.1,0.3l-0.3,0.1h-0.3
                l-0.3-0.1l0.1-0.5l-0.2,0.2l-0.3,0.4l-0.2,0.1h-0.5l-1,0.3l-0.1,0.3l-0.1,0.4l-0.1,0.3v0.2l-0.4,0.7l-0.1,0.1l-0.8,0.9l-0.1,0.1
                l-0.5,0.2L559,319l-0.2,0.1l-0.4-0.1l-0.2,0.1l-0.1,0.2v0.3l0.2,0.2l0.2,0.8l-0.1,0.3l-0.2-0.2l-0.2-0.2l-0.5-0.2l-0.5,0.1l-0.6,0.3
                l-0.4,0.1l-0.2-0.1h-0.1v0.1v0.1l0.1,0.1l0,0h-0.1l-0.9-0.2l-0.4-0.2l-0.3-0.4l0.1-0.1l0.1-0.1h0.5h0.1v-0.1v-0.1v-0.2v-0.2l0.2-0.3
                l0.3-0.2l0.2-0.2v-0.3l0.3-0.2l0.3-0.3l0.1-0.3l0.1-0.2l-0.2-0.5v-0.3v-0.3l0.1-0.1l0.3-0.2l0.3-0.1h0.1v0.1v0.5h0.1l0.1-0.1
                l0.1-0.3l0.1,0.1h0.2l0.1-0.1h0.1l0.1,0.1l0.2,0.1h0.2l0.1-0.1l0.1-0.1h0.1l0.2,0.4h0.1l0.6-0.1l0.1-0.1l0.1-0.1l-0.6-0.5v-0.4v-0.4
                l-0.1-0.2l-0.1-0.2l-0.4-0.2l-0.3-0.2l-0.1-0.1v-0.1v-0.2l-0.1-0.2v-0.2l0.1-0.2v-0.3v-0.1l-0.1-0.1h-0.2l-0.2-0.1l-0.1-0.2
                l-0.4-0.3H557l-0.1-0.1v-0.2l0.1-0.2l0.1-0.3l0.1-0.3v-0.2v-0.4L557,310h-0.1l-0.2,0.1l-0.2,0.1l-0.1-0.1l-0.2-0.2l-0.3-0.5
                l-0.5-0.1h-0.2l-0.2,0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.1,0.1v-0.1l0.1-0.2l-0.2-0.1h-0.4l-0.2-0.1v-0.2l-0.1-0.1l-0.2-0.1l-0.2-0.1
                l-0.2-0.3l-0.3-0.1l-0.4-0.1l0,0l0,0l-0.3,0.1l-0.2,0.1l-0.2-0.1l-0.3,0.3h-0.6h-0.3l-0.2-0.1l-0.6,0.4l-0.1,0.1h-0.1l-0.4,0.1
                l-0.4,0.1l-0.1,0.4l-0.1,0.2l-0.2,0.3l-0.7,0.1l-0.5,0.2l-0.6,0.1l-0.8,0.4l-0.3,0.3l-0.3,0.1H545l-0.2-0.2l-0.5-0.5l-0.2-0.2
                l-0.3-0.1h-0.3l-0.3,0.1h-0.3l-0.3-0.2l0,0h-0.1l-0.8-0.2h-0.1l0,0h-0.1h-0.6l-0.6-0.4h-0.2l-0.1,0.2l-0.1,0.2l-0.4,0.2l-0.1,0.1
                l-0.1-0.1v-0.2v-0.1l-0.1-0.1l-0.2-0.1v-0.1h-0.2h-0.1l0,0l-0.3-0.1l-0.2-0.3l-0.3-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1l0,0
                v-0.2h-0.1l0,0H537v-0.7l0.4-0.5l0.1-0.3l0.2-0.5l0.1-0.3l0.1-0.2l0.1-0.2v-0.2h0.1l0.3,0.2l0.3,0.1l0.1-0.1V305v-0.1l-0.2-0.2
                l-0.2-0.1v-0.1l0.1-0.2v-0.2v-0.3l-0.2-0.6V303l0.2-0.3l0.5-0.7l0.2-0.3l0.2-0.3l0.6-0.8l0.4-0.4l0.3-0.2l0.4-0.4l0.2-0.2l0.7-0.1
                l0.1-0.2l0.1-0.2l0.1-0.1v-0.4l-0.1-0.5l-0.1-0.2l-0.1-0.1l0.1-0.1h0.1h0.1l0.1-0.1v-0.1l-0.3-0.3l-0.1-0.2l-0.2-0.6l-0.4-0.6
                l-0.1-0.2v-0.2l0.1-0.2l-0.1-0.2l-0.2-0.3l0,0v-0.4l0.1-0.1l0.2-0.1h0.2l0.2,0.1l0.2,0.2h0.1l0.4-0.3l0.4-0.5l0.1-0.3l0.1-0.1
                l0.4-0.1h0.3h0.2h0.5l0.3-0.1l0.3-0.1h0.6l0.9,0.1h0.6h0.4l1,0.3h0.4l0.2,0.1l0.3,0.1l0.6,0.1l0.5,0.1h0.3h0.2l0.4,0.6l0.1,0.1h0.1
                l0.3-0.1h0.4l0.2,0.1v0.4l0.1,0.1h0.1l0.1-0.2l0.1-0.2l0.1-0.1l0.4,0.1h0.2l0.2-0.2h0.1l0.3,0.1l0.4,0.1h0.3l0.2,0.1l0.1,0.4
                l0.1,0.1h0.1l0.2-0.3l0.2-0.1l0.2-0.1h0.1l0.1-0.1l0.1-0.1h0.1h0.1l0.1,0.2l0.2,0.4l0.2,0.4l0.1,0.1l0.3-0.1l0.2-0.1h0.4l0.6-0.1
                l0.4-0.1h0.3l0.2,0.1l0.3,0.2l0.1,0.3l0.3,0.2h0.3l0.1-0.2l0.2-0.2l-0.1-0.2v-0.3l-0.1-0.3l-0.1-0.3l0.1-0.4l0.2-0.4l0.1-0.2
                l0.2-0.4l0.3-0.3l0.4-0.4l0.3-0.1l0.2,0.1l0.1,0.1l0.4-0.3h0.6h0.5h0.1l0.3,0.1l0.3,0.1h0.2h0.2l0.3-0.1l0.2-0.1l0.2-0.7l0.1-0.1
                l0.1-0.1h0.2l0.4,0.1l0.5,0.1h0.3l0.7-0.4l0.4-0.1l0.5,0.1h0.5l0.3-0.1l0.2,0.1l0.3,0.3l0.3,0.4l0.3,0.8l0.8,0.9v0.2l-0.1,0.1
                l-0.4,0.1l-0.3,0.1v0.2l0.1,0.2l0.2,0.2v0.3v0.3l0.1,0.3l0.1,0.1v0.1l-0.1,0.2v0.1l0.1,0.1h0.7l0.4,0.2l0.3,0.1h0.1l0.3-0.1l0.3-0.1
                h0.2l0.1,0.1l0.1,0.3l0.1,0.3l0.1,0.1h0.2h0.1l0.1,0.1l-0.1,0.2v0.2l0.1,0.2l0.1,0.5l0.1,0.1l0.1,0.2v0.2l-0.1,0.2l-0.1,0.1l0.1,0.3
                l0.2,0.4l0.2,0.1l0.1,0.3l0.2,0.1l0.3-0.3l0.3-0.1l0.3,0.1h0.3l0.2,0.2l0.2,0.2l0.2,0.1l0.2-0.1l0.4,0.1l0.2,0.2l0.2,0.1l0.2-0.2
                l0.2-0.2l0.6-0.2l0.4-0.1h0.1l0.2-0.1l0.2-0.1h0.2l0.2,0.3l0.2,0.2l0.1,0.3l0.3,0.5l0.7,0.6l0.3,0.2h0.2h0.1v-0.1v-0.3l0.1-0.1h0.1
                l0.5,0.4h0.3h0.3l0.4,0.3l0.4,0.3h0.3l0.2-0.1l0.2-0.1l0.1,0.1l0.1,0.2l0.2,0.2h0.2h0.3l0.5,0.3l0.4,0.3h0.3l0.2-0.1h0.2l0.1,0.1
                v0.1l-0.1,0.2v0.3l0.2,0.3v0.3l-0.1,0.2l-0.1,0.2l-0.3,0.3l-0.2,0.3l-0.4,0.1l-0.2,0.1l0.1,0.2l0.1,0.2l0.3,0.2l0.4,0.1v0.1
                l-0.1,0.1l-0.2,0.1l-0.3-0.1l-0.1,0.1l-0.1,0.2l-0.1,0.3l-0.1,0.3l0.3,0.1l0.2,0.1l0.1,0.2l0.1,0.3v0.2l-0.1,0.1v0.1l0.1,0.1h0.1
                l0.1,0.1v0.1l-0.2,0.3l-0.2,0.6l-0.1,0.3v0.3l-0.1,0.2h-0.2h-0.8h-0.7h-0.3h-0.4l-0.2,0.1l-0.3,0.5l-0.2,0.2l-0.4,0.2l-0.4,0.1
                l-0.2,0.2l-0.1,0.3v0.3v0.1l-0.1,0.1v0.1v0.1h0.1h0.1v0.1v0.1l-0.1,0.1l-0.1,0.2v0.2L582.3,313.4L582.3,313.4z M564.9,317l0.4,0.2
                l-0.4-0.1l-0.9-0.2l-0.4-0.2l-0.1-0.2l-0.1-0.3l0.2,0.3l0.2,0.1L564.9,317L564.9,317z"/>
            <linearGradient id="UG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="206.9079" x2="0.1864" y2="206.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UG" class="stcustom0 st232" d="M570.2,465.6h-0.3h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5h-0.5
                h-0.3h-0.1l0,0h-0.2l-0.2,0.1h-0.2h-0.2l0,0h-0.1h-0.2h-0.1l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.2l-0.1,0.1l-0.3,0.2l-0.2,0.1H559
                h-0.1l-0.1-0.3h-0.1l-0.6,0.1H558v-0.1v-0.7v-0.4l0.1-0.3v-0.3v-0.3l0.1-0.4v-0.3l0.1-0.9v-0.1l0.1-0.4l0.1-0.1l0.1-0.1l0.1-0.3
                l0.2-0.4l0.1-0.2v-0.5v-0.3v-0.1l0.3-0.1l0.4-0.3l0.2-0.4l0.2-0.2l0.4-0.1l0,0l1.3-1.2l0.6-0.7l0.3-0.3v-0.1l0.1-0.2l-0.1-0.1
                l-0.1-0.1v-0.1l-0.1-0.1h-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.4l-0.3-0.2v-0.2l0.1-0.4l0.2-0.5v-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
                l0.1-0.3l0.1-0.3l0.1-0.2l0.1-0.2v-0.2l-0.2-0.1l0.1-0.1l0.2-0.3l0.3-0.3l0.3-0.2h0.2l0.4,0.1l0.3,0.2h0.2l0.2-0.1l0.5-0.3l0.1,0.1
                l0.1,0.2l0.2,0.3l0.3,0.1l0.1,0.1h0.1h0.1l0.1-0.2l0.1-0.1l0.3-0.2l0.6-0.1h0.4h0.2l0.3-0.1l0.4-0.2l0.4,0.3l0.5,0.1h0.5l0.1-0.1
                l0.1-0.1l0.5-0.5l0.7-0.7l0.4,0.9l0.2,0.1v0.1v0.1l0.3,0.2l0.4,0.1l0.1,0.1v0.1l-0.1,0.5v0.2l0.1,0.5l0.2,0.1l0.2,0.5l0.4,0.2
                l0.1,0.1l0.1,0.3l0.1,0.3l0.1,0.1h0.1l0.1,0.3l-0.1,0.2l0.1,0.5l0.1,0.5v0.6v0.2v0.2v0.2l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2
                l-0.1,0.1l0.1,0.3v0.1l0,0h-0.2l-0.2,0.1l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.5l-0.3,0.4l-0.1,0.1l-0.3,0.2l-0.1,0.3l-0.1,0.3
                l-0.1,0.2l-0.3,0.3l-0.1,0.5v1.1l-0.1,1.2L570.2,465.6z"/>
            <linearGradient id="UM-DQ_1_" gradientUnits="userSpaceOnUse" x1="26.0043" y1="201.9579" x2="26.0043" y2="201.9579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-DQ" class="stcustom0 st233" d="M26,463.9L26,463.9L26,463.9L26,463.9L26,463.9L26,463.9L26,463.9L26,463.9L26,463.9z"/>
            <linearGradient id="UM-FQ_1_" gradientUnits="userSpaceOnUse" x1="990.2043" y1="203.6579" x2="990.2043" y2="203.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-FQ" class="stcustom0 st234" d="M990.2,462.2L990.2,462.2L990.2,462.2L990.2,462.2z"/>
            <linearGradient id="UM-HQ_1_" gradientUnits="userSpaceOnUse" x1="989.7043" y1="205.2579" x2="989.7043" y2="205.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-HQ" class="stcustom0 st235" d="M989.7,460.6L989.7,460.6L989.7,460.6L989.7,460.6L989.7,460.6L989.7,460.6z"/>
            <linearGradient id="UM-JQ_1_" gradientUnits="userSpaceOnUse" x1="1009.7043" y1="250.3579" x2="1009.7043" y2="250.3579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-JQ" class="stcustom0 st236" d="M1009.7,415.5L1009.7,415.5L1009.7,415.5L1009.7,415.5z"/>
            <linearGradient id="UM-MQ_1_" gradientUnits="userSpaceOnUse" x1="987.6044" y1="285.1079" x2="987.6044" y2="285.1079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-MQ" class="stcustom0 st237" d="M987.6,380.8L987.6,380.8L987.6,380.8L987.6,380.8v-0.1l0,0V380.8L987.6,380.8z"/>
            <linearGradient id="UM-WQ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="257.9079" x2="203.3996" y2="257.9079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UM-WQ" class="stcustom0 st238" d="M942.8,408L942.8,408L942.8,408L942.8,408v-0.1h0.1L942.8,408L942.8,408L942.8,408z"/>
            <linearGradient id="US_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="375.0079" x2="0.1864" y2="375.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="US" class="stcustom0 st239" d="M79.4,187.6v2.3v2.3v2.3v2.2v2.2v2.2v2.2v2.1v2.1v2.1v2.1v2v2v2v2v1.9v1.9v1.9v1.9v1.9v1.8v1.8v1.8
                v1.8v1.8v1.8v1.7v1.7v1.7v1.7v1.7v1.7l0.7,0.2l0.7,0.2l0.2-0.5l0.7,0.4l0.6,0.3l0.4-0.4l0.4-0.4h0.6h0.7h0.4v0.4l-0.2,0.6l-0.1,0.5
                l0.4,0.5l0.5,0.3l0.5,0.2l0.2,0.7l0.5,0.5l0.4,0.4l0.4,0.4l0.5,0.5l0.4,0.4l0.5,0.5l0.3,0.3l0.1,0.6l0.2,0.7l-0.1,0.4l0.2,0.1
                l0.5-0.5l0.4-0.3l0.5-0.4l0.4-0.2H92l0.3-0.7v-1h0.3l0.2-0.1l0.1-0.3l-0.2-0.4l0.6-0.2l0.5-0.1l0.7-0.4l0.6-0.4l0.3,0.3l0.3,0.3
                l0.6,0.6v0.2v0.3l-0.1,0.3l0.4,0.8l0.1,0.1L97,256l0.4,0.3l0.2,0.2l0.5,0.4l0.1,0.2v0.3l0.1,0.2l0.1,0.2l0.1,0.2l0.2,0.3l0.4,0.3
                l0.3,0.2l0.4,0.3l0.4,0.6l0.4,0.5l0.4,0.5l-0.1,0.4l0.4,0.6l0.4,0.8l0.3,0.7l0.2,0.4l0.3,0.5l0.3,0.7l0.4,0.8l0.3,0.5l0.4,0.7
                l0.2,0.4l-0.1,0.3l-0.2,0.3l0.5,0.2l0.4,0.1l-0.1,0.4l-0.1,0.5l0.4,0.2l0.3,0.1l-0.1,0.3l0.1,0.3v0.5h0.5h0.2l0.3,0.2l0.4,0.3
                l0.4,0.3l0.3,0.2l0.4,0.1l0.5,0.2l0.3,0.4l0.5,0.2l0.2,0.5l0.6,0.2l0.3-0.1l0.1,0.2l0.1,0.3v0.3v0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3v0.3
                v0.3l0.1,0.3l0.2,0.6l0.1,0.3v0.2l-0.4,0.8l-0.1,0.4v0.2l-0.3,0.4l-0.5,0.5l-0.2,0.3l-0.1-0.1l-0.7-0.1l-0.2-0.7l-0.1-0.5l-0.2-0.5
                v-0.1l0.2-0.3l0.7-0.3v-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.5v-0.5v-0.3l-0.1-0.6l-0.2-0.4l-0.4-0.7v-0.2l0.2-0.2l0.1-0.2l-0.7,0.4l-1,0.4
                l-0.4,0.3l-0.4-0.2v0.1l0.1,0.3v0.1l-0.1,0.2l-0.1,0.4l-0.2,0.5l-0.1,0.1l-0.4-0.2l-0.1-0.1l-0.2-0.6v-0.2l0.1-0.1l0.2-0.3l0.2-0.5
                l0.5-1.2h0.3l0.5-0.2l-0.8-0.1L105,272l-0.1-0.2l-0.1-0.3l-0.2-0.3l-0.3-0.1l-0.1-0.1l-0.2-0.3l-0.1-0.2l-0.1-0.2v-0.2l-0.1-0.1
                h-0.2l-0.1-0.1v-0.6l-0.4-0.2l-0.2-0.1l-0.3-0.4l-0.1-0.2v-0.2l0.1-0.4v-0.1h-0.2l-1.6-0.6l0.1-0.9l-0.3-1.1l-0.3-0.5l0.1-0.2
                l0.1-0.1h0.1l0.6,0.3l0.6,0.4l0.1-0.1l-0.9-0.8l-0.2-0.3l-0.1-0.3v-0.2l0.1-0.1l0.8,0.1l0.1-0.1l-0.9-0.2h-0.2l-0.2,0.4l-0.1,0.1
                H100l-0.1-0.1l-0.2-0.4l-0.2-0.3l-0.4-0.4L99,262v-0.4l0.1-0.4l0.3-1l0.1-0.2v-0.1h-0.1l-0.1,0.1l-0.3,0.4l-0.3,0.7l-0.2,0.2
                l-0.1-0.1l-0.2-0.3l-0.4-0.4l-0.5-0.1L97,260l-0.5-1l-0.1-0.5l-0.1-0.1l-0.3-0.2l-0.2-0.2l-0.2-1.3l-0.3-0.9l-0.1-0.5v-0.5v-0.1
                l-0.1,0.4v0.2l-0.2,0.1l0.2,0.4v0.2H95h-0.2l0.3,0.6l0.1,1l0.2,0.7l0.1,0.6l0.1,0.4l0.1,0.4l0.3,0.9v0.2v0.1l-0.1,0.2l-0.1,0.1
                l-0.5-0.1l-0.2-0.2l-0.2-0.4l-0.3-0.2l-0.8,0.1h-0.1v-0.3l0.1-0.6l-0.1-0.2l-0.4-0.9v-0.2l0.6-0.4h-0.3l-0.2,0.2l-0.1-0.1l-0.1-0.6
                l-0.1-0.2l0,0v0.6l0.1,0.3v0.2v0.2l-0.1,0.2l-0.1,0.1h-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.2-0.2l-0.7-0.2
                l-0.4-0.3v0.1l0.1,0.3v0.2l-0.1,0.1l-0.2,0.3h0.1l0.2-0.1h0.2l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.2v0.1l0.3,0.2v0.1l-0.2,0.3h0.5
                l0.3,0.1l0.3,0.5l0.1,0.3v0.4h-0.2l-0.1,0.1l-0.9,0.1l-0.3,0.4h-0.1l-0.3-0.1L91,261l-0.6-0.3l-1.3-1l0,0v-0.2l-0.1-0.1l-0.2-0.1
                l-0.2-0.3l-0.3-0.4l-0.2-0.3l-0.1-0.2l-0.2-0.3l-0.6-0.6l-0.3-0.2l-0.3-0.1h-0.2l-0.1-0.1l0.1-0.1v-0.1l-0.5-0.1l-0.5-0.3l-1.3-0.8
                l-0.7-0.5l-0.4-0.2l-0.2-0.1l-0.1-0.1l0.1-0.1l0.3-0.1l0.2-0.1l0.3-0.5v-0.2l-0.1-0.4l-0.1-0.3v-0.2v-0.2v-0.1l0.1-0.1l0.1-0.1h0.1
                l0.3,0.4v0.2v0.6l0.1,0.7v-0.1v-0.2v-0.4v-0.2l0.1-0.2l0.1-0.1l0.4,0.1h0.2l-0.7-0.3l-0.4-0.6l-0.1-0.1h-0.2l-0.3,0.2l-0.7,0.8
                l-0.2,0.1l-0.8,0.4l-0.6,0.1l-0.6-0.1l-0.5-0.1l-1.4-0.7l-0.2-0.2l0.3-0.4v-0.1l-0.1-0.4l-0.1-0.1l-0.1-0.1v0.1v0.1v0.2l-0.1,0.1
                l-0.2,0.1l-0.4,0.1l-1.2-0.4l-1.2-0.3l-1.1-0.1l-1.6,0.2l-0.8,0.2h-0.5h-0.5v-0.2l0.2-0.1v-0.1l-0.3-0.4l-0.4-0.2l-0.6-0.1l-0.3-0.1
                l-0.1-0.1l-0.2-0.1l-0.3-0.1l-0.1-0.2l0.1-0.7l0.1-0.4l0.1-0.3l0.3-0.5H69l-0.4,0.4l-0.3,0.4l-0.3,0.5l-0.2,0.2l-0.2,0.2h-0.4
                l-0.5-0.3l-0.4-0.2h-0.4l-0.1-0.1l0.2-0.3l0.1-0.2l0.2-0.3v-0.2L65,248v-0.2v-0.1v-0.1l-0.2-0.1l-0.3,0.1l-0.4,0.2l-0.2-0.2l0.1-0.1
                l0.1-0.1l0.3-0.3l-0.4-0.2l-0.2-0.2l-0.1-0.2V246l0.1-0.4l0.9-0.3l-0.3-0.1h-0.6l-0.4,0.3l-0.4,0.4l-0.3,0.2l-0.2-0.1h-0.2h-0.2
                l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.1,0.1h-0.1l-0.1-0.2l-0.3-0.1l-0.1-0.1l-0.1,0.1l-0.1,0.3l-0.1,0.1l-0.4,0.1h-0.2l-0.3-0.3v-0.1
                l0.1-0.3l0.6-1.2h-0.1l-0.2,0.2l-0.4,0.5l-0.2,0.1h-0.3l-0.1-0.1H59l-0.2,0.1l-0.1,0.1l-0.1,0.1l0,0l0.3-0.2h0.2v0.1l-0.2,0.5
                l-0.1,0.5l-0.1,0.1h-0.2h-0.2v0.1l0.4,0.4l0.2,0.1l0.2,0.1v0.1L59,248l-0.1,0.1l-0.1,0.1h-0.4h-0.1l-0.2,0.2l-0.1,0.2l0,0l0.2-0.2
                l0.3-0.1h0.4l0.3,0.6l0.2-0.2h0.2l0.2,0.2l0.1,0.2l-0.1,0.2l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.1,0.3v0.2v0.3l0.1,0.6v0.1h-0.1h-0.1
                l-0.1,0.1l-0.3,0.7l-0.1,0.1l-0.1-0.1h-0.1l-0.1,0.1l-0.2,0.1l-0.3,0.1h-0.3L57,252l-0.2-0.1l-0.1-0.2l-0.4,0.2l-0.1-0.1l-0.3-0.5
                h-0.1v0.6l-0.1,0.2l-0.3,0.4l-0.1,0.7l0,0v-0.1l-0.2-0.6l-0.1-0.1l-0.2,0.4v0.1l0.1,0.5l-0.1,0.1l-0.5-0.2h-0.1l0,0l0.2,0.4v0.1
                l-0.7,0.7h-0.2l-0.1-0.1h-0.1l-0.4,0.3h-0.1l-0.2-0.1h-0.1v0.2v0.3l-0.2,0.3l-0.5,0.5l-0.1,0.2l-0.1,0.3h-0.1l-0.3-0.2l-0.3-0.1v0.1
                l0.1,0.2v0.1h-0.1h-0.2l-0.2-0.1l-0.3,0.1l-0.4,0.2h-0.3l-0.5-0.4h-0.1v-0.1l0.1-0.3l0.1-0.2l0.1-0.1l0.4-0.3l0.5-0.1l0.3-0.2
                l0.4-0.4l0.2-0.3l0.4-0.7v-0.1h-0.1l-1.1,0.4l-0.1,0.1h-0.2l-0.7-0.3l-0.1-0.1l-0.1-0.3l0.2-0.8l0.1-0.4l0.3-0.6l0.5-0.6l0.2-0.4
                l0.2-1.1v-0.5l-0.1-0.6v-0.4l0.1-0.1l1-0.6l0.5-0.4l0.9-0.6h0.3l0.2,0.2l0.2,0.2l0.3,0.1h0.3l0.4-0.2l0.6,0.1l1.3,0.4h0.3v-0.1
                l-0.2-0.3l-0.9-0.2l-0.4-0.2l-1.1-0.7l-0.2-0.3l0.1-0.1l0.3-0.1l0.1-0.1v-0.2l0.1-0.3l0.3-0.3l0.4-0.3l0.7-0.5h-0.3l-0.5,0.1
                l-0.2,0.1l-0.4,0.3l-0.1,0.2l-0.2,0.5l-0.1,0.1l-0.4,0.1h-1l-0.2-0.2h-0.1h-0.1l-0.9,0.6l-0.3,0.3l-0.2,0.3l-0.4,0.3l-0.5,0.2
                l-0.4,0.2l-0.4,0.4l-0.1,0.3v0.1l0.1,0.4l-0.1,0.1H49l-0.4,0.3l-0.8,0.9l-0.1,0.3v0.1l0.1,0.2l-0.1,0.2l-0.2,0.3l-0.5,0.4l-0.3,0.2
                h-0.2l-0.2-0.1l-0.4-0.3h-0.3l0,0l0.4,0.3l0.4,0.4l0.2,0.3l0.1,0.2v0.2l-0.1,0.2l-0.3,0.4l-0.3,0.1l-0.7,0.1l-0.2,0.1l-0.1,0.1
                l0.5,0.2v0.1l-0.1,0.4l-0.1,0.1l-0.4,0.2l-0.4,0.1h-0.1l0.1-0.3v-0.1l-0.1-0.1l-0.2,0.1l-0.5,0.4l-0.1,0.1l0.2,0.1v0.1l-0.3,0.3
                l-0.1,0.2l-0.2,0.2l-0.8,0.6l0.1,0.1l-0.2,0.5l-0.1,0.5l0.1,0.2l0.6,0.2l0.3,0.1l0.4,0.2l0.7,0.4l0.2,0.3v0.1v0.1l-0.1,0.2l-0.2,0.4
                l-0.5,0.5l-0.2,0.2l-0.3,0.1l-0.1,0.1l-0.4,0.5l-0.1,0.3v0.2l-0.1,0.2l-0.6,0.3v0.1h0.2l-0.1,0.3v0.4l-0.1,0.1h-0.4l-0.5,0.2l0,0
                v0.3l-1.2,0.2l-0.3,0.5l-0.1,0.2l-0.2,0.6l-0.3,0.2l-0.3,0.1l-0.2,0.1v0.2l-0.1,0.1l-0.3,0.2l-0.1,0.3h-0.1l-0.5,0.1l-0.1,0.1
                l-0.1,0.4H37l-0.2-0.1l-0.2,0.1l-0.5,0.5l-0.1,0.2v0.1l0.1,0.1l0.1,0.3v0.2l-0.2,0.5l-0.1,0.1l-0.3,0.1l-0.1,0.3h-0.2l-0.2,0.1
                l-0.1,0.2l-0.1,0.1h-0.1l-0.2,0.1l-0.2,0.3l-0.2,0.2l-0.2,0.1h-0.2h-0.2h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.4l-0.1,0.2h-0.1h-0.2
                l-0.3-0.1h-0.3l-0.4,0.3l-0.1,0.2h0.3l0.1,0.1v0.1l-0.1,0.1h-0.2h-0.1l-0.2,0.1l-0.5,0.1l-0.2,0.1l-0.3,0.5v0.1l0,0l0.2-0.1l0.2,0.1
                l0.1,0.1l0.1,0.1l0.1,0.3l0,0l-0.4,0.4l-0.1,0.2l-0.1,0.1l-0.1-0.1l-0.1-0.5v-0.1h-0.1l-0.1,0.1l-0.2,0.6l-0.2,0.3l-1.8,0.7
                l-0.3,0.2l-0.1,0.3l-0.1,0.3l-0.2,0.2l-0.1,0.1v-0.1v-0.7v-0.1l-0.2-0.1h-0.1h-0.1l-0.2,0.2h-0.1h-0.1l-0.2,0.2l-0.6,0.5l-0.4,0.1
                l-0.1,0.1l-0.2,0.3l-0.1,0.1h-0.2l-0.2-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.4-0.1l-0.2,0.1l-0.1,0.1l-0.2,0.2l-0.2,0.1l-0.6,0.1
                l-0.2-0.1v-0.1v-0.3l0.1-0.2l0.1-0.1l0.1-0.1h0.2l0.3,0.1v-0.1l-0.1-0.1l-0.3-0.2l-0.3-0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.1,0.2
                l-0.1,0.5l-0.1,0.1l-0.7,0.9l-0.3,0.3h-0.3l-0.1,0.1l-0.2,0.2l-0.2,0.1h-0.1h-0.1l-0.1-0.1v-0.1l0.1-0.1v-0.2l-0.2-0.3l-0.1-0.2
                h-0.2v0.1l0.1,0.7v0.2l-0.2,0.2l-0.4,0.2h-0.1l-0.4-0.4l-0.3-0.1v0.1l0.1,0.3l-0.1,0.3l-0.3,0.3l-0.2,0.1h-0.1v-0.2l0.1-0.4v-0.3
                l-0.1-0.2v-0.2l0.1-0.1l0.5-0.4l0.2-0.1l0.1,0.1h0.1l0.2-0.1l0.1-0.1v-0.2l0.2-0.2l0.4-0.3l0.4-0.5l0.5-0.7l0.5-0.6l0.6-0.5l0.7-0.4
                l1.4-0.6h0.1l-0.1,0.2l0.1,0.1H23l0.5-0.1l0.2-0.1l0.1,0.1l-0.1,0.1l-0.3,0.1v0.1l0.4,0.6l0.1,0.1H24v-0.1v-0.4l0.1-0.1h0.3l0.2,0.1
                l0.1,0.1l0.2,0.1l0.3,0.1h0.2l0.1-0.1l-0.1-0.2l-0.5-0.4l-0.1-0.1v-0.2l0.1-0.3l0.2-0.4l0.2-0.5l0.2-0.4l0.4-0.4l0.3-0.2l0.7-0.6
                l1.4-0.6l0.3-0.4l0.5-0.5l0.2-0.1v0.2l0.1,0.2l0.3,0.1h0.2H30v-0.2v-0.3V268v-0.3v-0.2l0.2-0.4l0.3-0.4l0.4-0.5l0.3-0.2l0.3-0.1
                l0.2-0.2l0.4-0.5l0.1-0.1l0.3-0.1h0.1l0.1,0.1l0.1,0.1l0.3,0.1l0.2-0.1v-0.1h-0.2L33,265l-0.1-0.3l-0.2-0.2v-0.2V264l0.2-0.8v-0.8
                l0.2-0.5l0.3-0.2l0.7-0.1l-0.4-0.2h-0.2l-0.3-0.1l-0.1-0.5v-0.4l0.2-0.4l0.7-0.7l0.7-0.5h-0.1l-0.1-0.2l0.3-1l0.3-0.9l-0.4,0.8
                l-0.5,0.6l-1.5,0.7l-1,0.6l-0.5,0.1l-0.3-0.1l-0.2-0.6l-0.1-0.2l-0.2-0.4l0.1-0.5l0.1-0.3l0.3-0.1l0.4,0.2h0.3l-0.4-0.3l-0.6-0.3
                l-0.3,0.1l-0.2,0.5l-0.3,0.3l-0.2-0.1l-0.1-0.1l0.1,0.4l-0.2,0.6l-0.1,0.4l0.2,1.1v0.4l-0.5,0.2l-0.4-0.3l-0.8-1.4l-0.3-0.4
                l-0.6-0.6l-0.2,0.1l-0.3,0.3l-0.2,0.1l-0.7-0.5l-0.3-0.4l-0.3-0.4l-0.4,0.2l-0.4,0.3l-0.5,0.5h-0.3l-0.8,0.4h-0.1l-0.1,0.2l-0.1,0.1
                l-0.1,0.4l-1.1,0.3l-1.1-0.2l0.4-0.2l0.4-0.2l0.4-0.4l-0.2-0.6v-0.3v-0.4l0.4-0.5h-0.4l-0.3,0.2l-0.3-0.4l-0.1-0.8l0.3-0.4l0.1-0.3
                l0.1-0.5v-0.4l-0.2-0.7l-0.6-1.5l-0.3-1.1l-0.5-0.6l0.4-1l0.4-0.9l0.5-0.4v-0.1h-0.3l-0.2,0.1l-0.2,0.3l-0.2,0.2l-0.6,1.1l-0.4,0.6
                l-0.2,0.2l0.4,0.2l0.1,0.2l0.1,0.4l-0.1,0.5l-0.1,0.3h-0.5l-0.4,0.4l-1,0.4l-1.3,0.2h-0.6l-0.7-0.5v-0.3v-0.3l-1-0.9l-0.5-0.9h-0.4
                l-0.3-0.2l-0.4-0.4v-0.3l0.1-0.2l-0.2-0.1h-0.3l-0.4-0.1l0.9-1.1l0.3-0.8l0.3-0.1l0.3,0.1l0.5,0.3l0.4,0.1l0.1,0.1l0.1,0.3l-0.2,0.4
                l-0.1,0.3l0.2-0.1l0.5-0.5l0.4-0.4h0.2l0.1,0.1l0.2,0.4l0.3,0.4l0.6-0.4l0.3-0.5l-0.3-0.2l-0.3-0.1l-0.8-0.2l0.2-0.2h0.5l0.2-0.1
                l-0.2-0.2l-0.3-0.2l-0.7,0.6h-1.3l-0.9-0.4l-0.9,0.1l-0.1-0.1l-0.2-0.2l0.5-0.5l0.3-0.2v-0.1h-0.2l-0.4,0.1l-0.2-0.2v-0.4H11
                l-0.2,0.2l-0.2-0.1l-0.2-0.2l0.1-0.2l0.2-0.2h-0.1l-0.2,0.1l-0.2,0.3v0.3v0.4l-0.3,0.1H9.7l-0.2-0.4l-0.2-0.8l-0.5-0.2l0.7-0.3
                l0.3-0.5l-0.1-0.3L9.4,243L9,243.3l-0.2-0.2v-0.3v-0.4h0.1l0.1,0.1l0.7-0.2l0.1-0.1l-0.6-0.3L9,241.6l0.2-0.2h0.4l0.6-0.2l-0.2-0.3
                l-0.1-0.2l-0.1-0.3l0.1-0.5l0.7-1.2l0.7-1l0.2-0.2l0.3-0.1l0.3,0.1l0.3,0.2l0.1-0.1l-0.1-0.1l-0.1-0.4l0.4-0.2l0.3-0.5v-0.1
                l-0.3,0.2l-0.3,0.3l-0.1-0.3l-0.1-0.8l0.1-0.7l0.1-0.3l0.2-0.3l0.7-0.1l0.1,0.1v-0.1l-0.4-0.5l0.2-0.4l0.2-0.2l0.8-0.3l0.5,0.1
                l0.6,0.3l0.3,0.4v0.2l-0.1,0.1l-0.2,0.1l-0.1,0.1v0.1l0.2-0.2l0.4-0.3l0.2,0.1l0.2,0.2h0.2l0.6-0.2l0.3-0.2l0.4-0.6l0.5-0.4l0.7-1.2
                l0.2-0.5l0.3-0.1h0.2l0.2,0.4l0.2,0.1l1.3-0.1l0.7-0.2l0.5-0.4l0.5-0.6l0.3-0.4l0.1-0.6l-0.2-0.7l-0.2-0.6l-0.2-1.4l-0.7-0.9
                l-0.5-0.3h-0.3l0.2-0.6l0.6,0.1l0.4-0.1l0.3-0.3l0.1-0.2l0.2-0.5l-0.1-0.5l-0.1-0.3l-0.2-0.3l-0.3-0.4l-0.2-0.1H22l-0.8,0.9h-0.5
                l-0.4-0.2l-0.3,0.5l-0.9,0.4l-0.5,0.4l-0.8,1.1l-0.2,0.5h-0.3l-0.2-0.9l-0.9-0.9l-0.3,0.3l0.2,0.3l0.2,0.2l0.3,0.1l-0.1,0.3
                l-0.1,0.4l-0.3-0.3l-0.6-0.5l-0.6-0.3h-1.7l-1.1,0.5l-0.1-0.1h-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.1h-0.2l-0.4-0.1l-0.9-0.3l-2-0.5
                l-0.5-0.3l-0.4-0.7v-0.5l0.2-0.2v-0.7l0.4,0.1l-0.8-1l-0.3-0.4h0.1l0.1,0.1l0.3,0.1l0.7-0.1L8,220l0.5-0.2l0.4,0.1l-0.1-0.2
                l-0.1-0.1l-1.2-0.3l-0.2,0.1l-2.1-0.6l-1.6-1l-0.1-0.2l-0.1-0.4l0.2-0.4l0.2-0.2v0.2v0.2l0.9-0.5l0.5-0.7l0.9-0.1l0.2-0.2l0.3-0.4
                l0.4-0.6l0.6-0.3l0.4-0.3l0.5-0.2l0.4,0.3H9l0.8,0.1l0.3-0.1l0.1-0.1l0.1-0.2l-0.8-0.5l0.1-0.3l0.1-0.2l0.9-0.6l0.7-0.2h0.4l1.1-0.8
                l0.6-0.2l1.1-0.2h0.9l0.3,0.3l-0.5-0.1L15,211l0.2,0.1l0.2,0.2l-0.1,0.3l-0.3,0.8v0.6l-0.2,0.2l-0.2,0.3l0.9,0.9l1.5,0.1l0.8-0.2
                l0.5,0.3l0.4,0.1l1-0.1l0.8,0.2l0.3-0.1l0.7-1.4l0.3-0.2l0.3,0.2l0.4,0.2l0.3-0.1l0.2,0.4l-0.1-0.7l-0.1-0.3l-1.2-0.5l-0.8,0.3
                l-0.2-0.3l0.1-0.6l-0.9-1.4l-0.4-0.3H19l-0.2-0.5l-0.2-0.6l0.4-0.3l0.3-0.1l0.3,0.2l0.3,0.8l0.3,0.1l-0.1,0.8l0.4,0.8l0.9,0.7
                l0.7-0.3h0.5l0.3,0.1l0.7,0.6l0.4,0.1l1.2-0.3v-0.6l-0.1-0.4l-0.3-0.3l-0.8,0.1l-0.6-0.5l-0.5,0.1l-1,0.7l-0.5-0.3l-0.3-0.4
                l-0.5-0.4l-0.1-0.7l0.4-0.8l0.3-0.4l-0.3-0.3l-0.7-0.2l-1.2,0.2l-0.1-0.3v-0.3l-0.5,0.6l-0.5-0.1l-0.7,0.1l-1.5-0.5l-0.5-0.7
                l-0.2-0.5l-0.4-1.5l-0.5-0.9l-3.5-3.3L9,199.7l-0.8-0.9l-0.5-0.2l-0.5-0.1l-0.6-0.3l0.4-0.4l0.3-0.1L7,198.1l0.2,0.1l0.3-0.2
                l0.2-0.3l0.3-1.1l0.3-1.7l-0.1-0.7l2,0.1l1.3-0.1l0.4-0.2l1.6-0.3l0.4-0.2l0.8-0.6l0.9-1l0.8-1.4l0.1-0.4l0.1,0.1l0.1-0.1l0.1-0.5
                l0.1-1.3l0.4-1.2l1.7-2.7l0.8-1.1l0.3-0.5l0.3-0.4l0.2,0.3l0.1,0.1l0.1,0.2l-0.2,0.1l-0.3,0.4l-0.4,0.2l-0.1,0.1H20l0.6-0.3l0.4-0.3
                l1.8-0.6l1-1v-0.2l1.5-1.2h0.2l0.2,0.1l-0.4,0.8l0.3,0.2l-0.3,1h0.5l0.1,0.4v-0.4v-0.5v-0.5l0.1-0.4l0.4,0.2l0.8-0.4l-1-0.1
                l-0.6-0.9h-0.3l1.1-1.3l1-0.8h0.2l0.1,0.1v0.2l-0.2,0.2l-0.2,0.3l0.1,0.2h0.1l0.5-0.2l0.2-0.3h1.1l0.9-0.2l0.1-0.2h1.4l0.2-0.1
                l0.9-0.7l0.8-0.8l0.4-0.5l0.6-1.2l0.5-0.8l0.9-0.8l0.2,0.1l-0.3,0.2l-0.2,0.3l0.3,0.4l1.8,0.9h0.5l0.2,0.5l-0.2,0.5l-0.5,0.6l-1,0.6
                l0.3,0.2l0.2,0.5l0.3,0.1l0.5-0.2l0.4-0.3l0.7-1l0.2-0.6l0.2-0.1l0.6,0.1l0.4,0.3l0.4,0.5l-0.1,0.5l-0.2,0.3l0.5,0.4l0.6,0.1
                l0.6,0.3l0.8-0.7l0.6-0.1h0.6l0.7-0.4l1.3,0.5l0.3-0.1l0.5,0.1l0.5,0.3l0.2,0.3l-0.6,0.7l-0.1,0.7l0.2,0.3h0.4v0.4l0.2,0.1h1.1
                l-0.1,0.2l-0.1,0.2l-0.4,0.5l2,0.3l0.3-0.3l0.4-0.1l0.9-0.4l0.3,0.2l0.4,0.4l0.4,0.1l0.3-0.1l0.8-0.6h0.9l0.4,0.2l0.4-0.1l1.2,0.6
                l0.4,0.1l0.6,0.8h0.3l0.3-0.3h0.3l0.3,0.3l0.5,0.1l0.2,0.5l0.2,0.2l1.8,0.4l0.9-0.2h1.3l0.6,0.2h0.7l1.1,0.9l0.6,0.1l0.1,0.2
                l1.8,0.4l0.6-0.5l1-0.1l0.9-0.4h0.5l0.6,0.1H73l0.2-0.2l1.4,0.7l0.8,0.8l0.4,0.6l1.7,0.8l0.5,0.5l0.3,0.5l0.2,0.1l0.1-0.1h0.6
                L79.4,187.6L79.4,187.6z M8.9,213.2L8.9,213.2l-0.1-0.1l0.3-0.3l0.6-0.4v0.1l-0.3,0.4L8.9,213.2z M59.9,247.3h-0.4l-0.2-0.1v-0.1
                l0.1-0.3v-0.1h0.2l0.2,0.1l0.1,0.1C59.9,247,59.9,247.3,59.9,247.3z M48.5,250L48.5,250l0.1-0.3l0.1-0.5l0.2,0.1v0.1L48.5,250
                L48.5,250L48.5,250z M60.7,249.4v0.1l-0.1,0.1l0.1,0.3l-0.1,0.5l-0.1,0.3l-0.1,0.2l-0.1,0.1h-0.1v-0.1v-0.2H60l-0.1-0.4l0.1-0.1V250
                v-0.1l0.1-0.5l0,0v0.1l0,0h0.1l0.1-0.2l0,0L60.7,249.4L60.7,249.4z M64.2,249.5l0.1,0.2h0.5H65l0.1,0.1l-0.1,0.1l-0.2,0.1l-0.6,0.2
                l-0.5,0.3h-0.1l-0.1-0.4l-0.1-0.1l-0.1-0.2v-0.1l0.1-0.1l0.2-0.2l0.1-0.1L64.2,249.5L64.2,249.5z M67.8,250.1l-0.1,0.1h-0.2
                l-0.1-0.1l0.4-0.4l0.1,0.1C67.9,249.8,67.8,250.1,67.8,250.1z M8.8,249.8l0.3,0.3h0.1l0.4-0.1l0.2,0.1l0.2,0.1l0.1,0.2v0.3L10,251
                v0.4v0.2l0.2,0.2l0.1,0.3v0.3l-0.5,0.1H9.3l-0.4,0.2l-0.1,0.2l0.1,0.2l-0.1,0.1l-0.1-0.1l-0.2-0.2H8.3l-0.8-0.2l-1-0.7L6,251.9
                l-0.4-0.5l-0.4-0.6l0.3-0.1l0.3-0.1l1.2,0.1l0.1-0.4l0.2-0.1l0.4-0.1l0.3-0.2h0.2l0,0l0.3-0.1h0.2L8.8,249.8L8.8,249.8z M60.5,253
                l-0.3,0.1l-0.1-0.2l0.2-0.4l0.1-0.2l0.1-0.1l0.4-0.5l0.4-0.3l0.4-0.5l0.4-0.7l0.1-0.3h0.2l0.3,0.2l0.2,0.2l-0.1,0.2l-0.9,1l-0.1,0.1
                l-0.1,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.1H61l-0.1,0.1l-0.1,0.1L60.5,253L60.5,253z M59.7,251.6l-0.1,0.2l-0.6-0.1
                l0.1-0.3l0.4-0.2l0.5,0.3L59.7,251.6L59.7,251.6z M69.4,253L69.4,253l0.1-0.4l0.3-0.4l0.3-0.3l0.3-0.1v0.2l-0.4,0.3L69.4,253z
                M23.5,259.8l-0.2,0.1h-0.2l-0.2-0.5H23l0.3-0.4l0.6-0.3H24C24.1,258.6,23.5,259.8,23.5,259.8z M47.1,260.3L47.1,260.3l-0.3-0.1
                l-0.1-0.2l0.1-0.1l0.4-0.3h0.2h0.1v0.1v0.1l-0.1,0.2C47.4,260,47.1,260.3,47.1,260.3z M47.3,261L47.3,261l0.2-0.3h0.1l0.3,0.3
                l0.3-0.1l0.1,0.3h0.1h0.1h0.1v0.3l-0.2,0.4l-0.1,0.1l-0.2-0.1H48l-0.1-0.2l-0.1-0.2l0,0l-0.2,0.2v0.1l0.1,0.2v0.1l-0.2,0.1h-0.2
                l-0.2,0.1l-0.1-0.1v-0.3h-0.1l-0.1,0.3l-0.1,0.2l-0.2,0.2v0.1h-0.2l-0.2,0.1h-0.2l-0.9-0.3l-0.2-0.1l0.7-0.8l0.4-0.3h0.2l0.2,0.1
                h0.1V261l-0.2-0.3v-0.1l0.5-0.2h0.2l0.2,0.1l0.2,0.2V261L47.3,261z M97.1,262l0.7,0.1h0.5l0.5,0.8l0.3,0.6l0.2,0.4l0.1,0.4l0.1,0.4
                v0.1l-0.3-0.3l-0.2-0.6l-0.1-0.2l-0.1-0.1l-0.1-0.2l-0.2-0.5v-0.2l-0.1-0.1l-0.1-0.1h-0.1v0.1v0.4l0.1,0.4l0.5,0.9l0.3,0.5l0.1,0.2
                v0.5l-0.1,0.2l0.2,0.4v0.1v0.1l-0.5,0.2l-0.4,0.8l-0.5,0.5l-0.2,0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2v-0.3l0.1-0.2l0.2-1v-0.3l-0.3-0.4
                l-0.2-0.4l-0.1-0.5l-0.2-1.4l-0.1-0.4l-0.1-0.4l-0.1-0.3l-0.1-0.3l-0.1-0.4V261l0.2,0.2l0.3,0.5L97.1,262L97.1,262z M98.1,261.7v0.1
                h-0.4l-0.4-0.2l-0.2-0.3v-0.1l0.4-0.1l0.3,0.2C97.9,261.3,98.1,261.7,98.1,261.7z M94.2,261.6l0.4,0.5v0.1l-0.1,0.4l-0.2,0.1
                l0.1,0.1l0.2,0.1l0.1-0.1l0.4-0.5l0.1-0.1h0.1l0.5,0.1l0.5,0.2l0.1,0.2l0.1,0.3l-0.1,0.7l-0.4,0.1h-0.2l-0.2-0.1l-0.3,0.2l0.2,0.2
                h0.8l0.2,0.4l0.1,0.3l-0.2,0.6l-0.4-0.2l-0.4-0.3l-0.8-0.5h-0.2l-0.1,0.1v0.3v0.6l-0.2,0.3l-0.6-0.1l-0.2-0.5l-0.2-0.7l-0.8-0.9
                l-0.2-0.2l-0.3-0.5l0.1-0.4v-0.2l0.2-0.1l0.2-0.2l0.1-0.4l0.2,0.3l0.3,0.3v-0.3l0.1-0.2h0.3h0.1l0.2-0.2l0.3-0.1L94.2,261.6
                L94.2,261.6z M46,263.8v0.3h0.1l0.4-0.3l0.2-0.1H47l0.2,0.1v0.1v0.1l-0.2,0.3v0.2l0.2,0.3l0.5,0.2l0.1,0.1v0.1l-0.3,0.5l-0.1,0.1
                h-0.1l-0.6-0.1l-0.6-0.2h-0.2l-0.1,0.1l-0.2,0.2h0.1h0.5l0.2,0.2l0.1,0.2v0.2l-0.1,0.1l-0.2,0.1h-0.3l-0.3,0.2l-0.2,0.2l-0.6,0.1
                l-0.5,0.3l-0.2,0.2l-0.1,0.2l-0.2,0.1l-0.4,0.1l0.2,0.1v0.1v0.1v0.1l-0.3,0.5l-0.6,0.4h-0.2l-0.1-0.1l-0.1-0.1V269l0.8-0.9l0,0h-0.2
                l-0.3-0.2l-0.2,0.2h-0.1l0.1-0.2l0.2-0.2v-0.1l-0.1-0.1h-0.2H42l-0.2,0.1l-0.1,0.1v0.1H42l0.1,0.1l0.1,0.1l0.1,0.1v0.2l-0.1,0.2
                l-0.1,0.3h-0.2l-0.4-0.6l-0.2-0.9l-0.4-0.7v-0.2L41,266l0.4-0.6l0.4-0.2l0.3-0.2l0.3-0.1h0.2l0.2,0.1l0.1,0.2l-0.1,0.1v0.1l0.2,0.1
                l0.2,0.5l0.2,0.4l0.1,0.2l0.2,0.1l-0.2-0.3l-0.1-0.4l-0.1-0.8l-0.1-0.2l0.1-0.1h0.3v-0.1l-0.3-0.3l-0.2-0.2l-0.1-0.2v-0.2l0.2-0.2
                l0.1-0.1h0.1h0.2l0.1,0.1l0.3,0.5l0.1,0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.1l0.3,0.1H45V264v-0.2l0.1-0.1v-0.2l-0.2-0.2l0.2-0.1
                l0.6,0.2l0.3,0.2L46,263.8L46,263.8z M45,263.7l-0.1,0.1h-0.1l-0.2-0.2l-0.3-0.2l-0.1-0.2v-0.1l0.1-0.1l0.4,0.2l0.2,0.2L45,263.7
                L45,263.7z M96.3,266.3l0.2,0.6l0.2,0.4l0.2,0.5l0.3,1.1l0.1,0.4v0.2v0.6v0.1l-0.1,0.1v0.2l0.1,0.4v0.7l-0.1,0.4l-0.1,0.1l-0.2-0.1
                l-0.2-0.2l-0.1-0.2l-0.3-0.7l-0.1-0.3v-0.2v-0.2l0.1-0.1l0.2-0.3l0,0l-0.1,0.1h-0.3l-0.2-0.2l-0.2-0.1v-0.4v-0.1l-0.3,0.1l-0.1-0.1
                V269v-0.2l0.1-0.2l0.3-0.5V268h-0.2l-0.2-0.2l-0.1-0.6l-0.2-0.3h-0.1l-0.3,0.9l-0.1,0.2l-0.4,0.1l0.1-0.2v-0.2L94,267v-0.3l0.1-0.2
                l0.3-0.1l0.2-0.1l0.1-0.2v-0.2l0.2-0.5l0.1-0.1h0.3l0.6,0.5l0.2,0.1L96.3,266.3L96.3,266.3z M45.7,267.4l-0.4,0.2h-0.1l-0.2,0.3
                l-0.2,0.1l-0.2-0.3l0.1-0.4l0.2-0.3l1,0.1l0.1,0.1v0.1l-0.1,0.1L45.7,267.4z M100.8,268.1l0.2,0.2l0.1-0.2h0.2l0.4,0.1l0.2,0.2
                l0.1,0.3v0.2v0.3v0.4v0.2v0.2l-0.1,0.1h-0.1l-0.3-0.3l-0.3-0.6L101,269v0.1l0.1,0.2l0.2,0.3v0.2l0.1,0.2l0.1,0.2v0.2v0.2v0.2
                l-0.1,0.1h-0.1h-0.5l-0.3,0.1l-0.3-0.1l-0.1-0.1l-0.1-0.2v-0.4l-0.1-0.6v-0.4l-0.2-0.4l-0.2-0.2l-0.3-0.2l-0.2-0.2V268l0.3-0.1h0.4
                L100.8,268.1L100.8,268.1z M99.1,268.9l0.2,0.4h0.3l0.1,0.3l0.1,0.4l-0.1,0.3l-0.1-0.1l-0.1,0.1l-0.1,0.5v0.5v0.5l-0.2,0.5v0.3
                l-0.1,0.1H99l-0.1-0.1l-0.1-0.1l-0.2,0.3h-0.2l-0.2-0.6l0.1-1.1l0.3-0.2l-0.2-0.3l-0.4-0.3v-0.2l-0.3-0.5v-0.1V269l0.3-0.4l0.4-0.1
                l0.3,0.2l0.1,0.1L99.1,268.9L99.1,268.9z M102.5,270.5L102.5,270.5l-0.4,0.1l-0.1-0.1v-0.2v-0.2l0.1-0.2l0.1-0.3l0.1-0.5l0.5,0.6
                l0.2,0.2l0.1,0.3l-0.2,0.1L102.5,270.5C102.6,270.4,102.5,270.5,102.5,270.5z M42.3,270.6L42.3,270.6l-0.3-0.1v-0.2l0.3-0.2h0.3v0.1
                v0.1v0.1l-0.1,0.1L42.3,270.6z M41,271l-0.2,0.1h-0.1V271v-0.2l0.1-0.2l0.3-0.3l0.3-0.2h0.2l0.1,0.1l-0.2,0.3L41,271L41,271z
                M104.3,272.6l-0.1,0.8h-0.1H104l-0.2,0.1l-0.2-0.1l-0.1-0.1v-0.1V273l-0.1-0.1H103l-0.2-0.1l-0.1-0.3v-0.3l0.1-0.1l0.2-0.1l0.2-0.4
                l0.1-0.1l0.4-0.8l0.2,0.1l0.3,0.5l0.4,0.7L104.3,272.6L104.3,272.6z M102.5,271.9L102.5,271.9l-0.3-0.1l-0.4-0.4v-0.1l0.1-0.1
                l0.2-0.3l0.1-0.1h0.6l0.2,0.1v0.1v0.1l-0.1,0.1v0.1v0.1l-0.1,0.1L102.5,271.9L102.5,271.9z M100.2,271.5l0.5,0.1h0.5l0.2,0.2
                l0.1,0.2l0.1,0.2v0.2v0.1l-0.1,0.1l0,0l1,0.5l0.4,0.5l0.2,0.3l0.1,0.2l0.2,0.6l0.4,0.6l0.2,0.2l0.1,0.2H104l-0.3-0.1l-0.6-0.4H103
                l-0.1,0.2l-0.1,0.2l-0.1,0.1h0.1l0.5-0.1l0.4,0.4l0.2,0.1l0.2,0.3v0.1l-0.1,0.2l-0.1,0.1v0.1h0.1l0.5-0.1l0.1,0.1l-0.1,0.9l0.1,0.3
                v0.1l-0.1,0.2v0.2v0.2v0.1l-0.1,0.4l-0.1,0.1h-0.2l-0.2-0.1l-0.2-0.3l-0.2-0.5l-0.1-0.1l-0.3-0.1l-0.1-0.1h-0.2l-0.1-0.2v-0.3
                l-0.1-0.3v-0.1l-0.1-0.1l-0.1,0.1l0.1,0.3l-0.1,0.2l-0.2-0.1l-0.4-0.7l-0.4-0.6l-0.2-0.1v-0.2l0.2-0.1h0.2v-0.1l-0.3-0.5v-0.2
                l0.1-0.3l-0.2-0.1l-0.4,0.1l-0.2-0.1l-0.1-0.2l-0.1-0.2H100h-0.1l-0.2-0.3l-0.1-0.2v-0.1l0.2-0.2h0.1l0.3,0.2h0.1l0.3-0.2v-0.2
                l0.2-0.2V273l-0.1-0.3l-0.2-0.1l-0.5,0.2l-0.4,0.3l-0.2-0.1v-0.2l0.4-0.5l0.2-0.3v-0.2l-0.1-0.2v-0.5L100.2,271.5z M107.5,275.7
                l-0.1,0.5l-0.2,0.6l-0.3,0.3l-0.2-0.1l-0.2-0.2h-0.1h-0.2l-0.1-0.2l0.1-0.3l-0.1-0.2l-0.1,0.2l-0.1,0.2l-0.3,0.2l-0.2,0.4l-0.1,0.3
                l-0.1-0.3l-0.1-0.7v-0.3l0.2-0.4l0.3-0.4l0.1-1.2l1-0.6h0.1l0.3,0.5l0.3,0.6l0.1,0.3v0.5L107.5,275.7L107.5,275.7z M38.5,274.1
                l-0.1,0.2h-0.2l-0.1-0.1v-0.1l0.3-0.4l0.1-0.1h0.1v0.2L38.5,274.1L38.5,274.1z M101,275.4l0.1,0.1v0.1l-0.4,0.3v0.1l-0.1,0.2
                l-0.1,0.1l-0.2,0.2l-0.3,0.2V276l-0.3-0.4l0.3-0.2l0.2,0.1h0.3l0.3-0.2L101,275.4L101,275.4z M24.1,276.6L24.1,276.6h0.1l0.2-0.3
                h0.1v0.1l-0.1,0.3l0.1,0.4l0.1,0.2v0.1l-0.3,0.1l-0.3-0.1h-0.1l-0.1,0.1l-0.1-0.1l-0.1-0.7v-0.1l0.1-0.2l0.2-0.1H24l0.1,0.1v0.1
                L24.1,276.6L24.1,276.6z M25.1,276.4v0.4l-0.4-0.2l-0.1-0.1l0.1-0.1L25.1,276.4L25.1,276.4z M102.2,278.6L102.2,278.6L102.2,278.6
                l0.2-0.2h0.2l0.1,0.1l0.1,0.1v0.3v0.4l-0.1,0.1l-0.1,0.2l-0.3-0.1l-0.2-0.3l-0.3-0.4l-0.2-0.3v-0.1l-0.1-0.1l-0.2-0.6l-0.1-0.4
                l-0.2-0.1l-0.3-0.1l-0.1-0.2l0.1-0.2l0.4-0.1l0.6,0.5l0.1,0.2l0.2,0.3v0.4l0.1,0.1L102.2,278.6L102.2,278.6z M26.4,277.5l-0.2,0.1
                l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.4,0.5l-0.2,0.1l0.2-0.4V278v-0.1l-0.1-0.3h0.1l0.1-0.1l0.2-0.3H26l0.2-0.3h0.1l0,0l-0.1,0.2l0.2,0.2
                V277.5L26.4,277.5L26.4,277.5z M106.5,277.7l0.3,0.6v0.2l-0.3,0.1h-0.2l-0.1-0.1v-0.1l0.1-0.3l-0.1-0.2l-0.2-0.1l-0.1,0.1v-0.3
                l0.1-0.2l-0.1-0.3v-0.2v-0.1h0.1l0.3,0.2L106.5,277.7L106.5,277.7z M27.4,277.4v0.4v0.1l-0.1-0.1l-0.2,0.1l-0.1-0.1v-0.1v-0.1h0.1
                v-0.2v-0.1l0.1-0.2h0.1L27.4,277.4L27.4,277.4z M27.8,278.2L27.8,278.2h-0.2l-0.1-0.3v-0.1l0.2,0.1L27.8,278.2L27.8,278.2
                L27.8,278.2z M16.3,278.2l0.3,0.8l0.1,0.2l0.2,0.1l0.2,0.1l0.1,0.1l0.1,0.2v0.1l-0.8-0.3L16,280l-0.1,0.1h-1.4l-0.3,0.1l-0.2,0.2
                l-0.3,0.4l-0.2,0.2l-0.2,0.1l-0.4,0.1h-0.4l-0.2-0.1l-0.1-0.2l-0.1-0.4v-0.1v-0.2l0.4-0.3l0.1-0.1l0.5-0.9l0.1-0.1h0.2l0.4,0.1
                l0.4-0.3l1-0.7h0.2H16l0.2,0.1L16.3,278.2L16.3,278.2L16.3,278.2z M19.6,278.8L19.6,278.8l-0.3-0.1l-0.1-0.1l-0.1-0.2l0.4-0.2h0.1
                l0.1,0.1v0.1L19.6,278.8L19.6,278.8z M18.9,281l-0.2,0.1l-0.3-0.1l-0.2-0.2v-0.2l0.5,0.2l0.1,0.1L18.9,281L18.9,281z M10.4,282.3
                h-0.1l0,0v-0.2l-0.1-0.3l0.2-0.1h0.1v0.1l0.1,0.2l0.1,0.1l0.1,0.1l-0.2,0.1L10.4,282.3L10.4,282.3z M9.7,282.6l-0.1,0.1H9.5H9.4
                l-0.3,0.1H9l-0.1-0.3v-0.1l0.1-0.1l0.1-0.1l0.2-0.1h0.2l0.4,0.3l0.2,0.2v0.1l-0.1,0.1L9.7,282.6L9.7,282.6z M7.5,283.4l0.1,0.2
                l0.2-0.1l0.2-0.2l0.1-0.3l0.1-0.1l0.1,0.1l0.3,0.2l-0.2,0.3l-0.6,0.4l-0.2,0.3v0.1l0.5-0.1h0.1l0.1,0.1l-0.1,0.2l-0.3,0.1l-0.2,0.2
                l-0.5,0.3L7,285.4l-0.2,0.1H6.5L6,285.7l-0.3,0.2L5.6,286H5.5H5.4l-0.2,0.1l-0.1,0.2L5,286.4H4.8l-0.1,0.1H4.6l-0.4-0.3l-0.1-0.1
                l0.3-0.2l0.2-0.1h0.3l0.3-0.2l0.6-0.3l0.2-0.1l0.1-0.5l0.1-0.1l0.1-0.2h0.3l0.2,0.2h0.1l0,0v-0.2l0.2-0.1L7,284.2l-0.3-0.1L6.5,284
                H6.3H6.2l-0.1-0.1v-0.1v-0.1l0.1-0.2l0.1-0.2l0.2-0.1l0.4-0.1l0.3-0.2h0.2h0.1L7.5,283.4L7.5,283.4z M8.6,284.2L8.6,284.2l-0.1-0.1
                V284v-0.1l0.1-0.2l0.1-0.1h0.1l0,0v0.3L8.7,284C8.7,284.1,8.6,284.2,8.6,284.2z M3.7,286l-0.9,0.5l-0.2,0.4l-0.2,0.4l-0.2,0.1H2.1
                L2,287.5l-0.3,0.2H1.6l-0.9,0.6H0.6v-0.1l0.3-0.2l0.2-0.2l0.2-0.3l0.1-0.1v-0.2v-0.3l0.1-0.1l0.2-0.2l0.1-0.1H2h0.4l0.2-0.1v-0.1
                l-0.1-0.1v-0.1v-0.3l0.1-0.2l0.2-0.2l0.3-0.2l0.3-0.1h0.2l0.4,0.2l0.1,0.1L4,285.6l-0.1,0.2L3.7,286L3.7,286z M213,307.1l0.3-0.1
                h0.3h0.3h0.2l0.4,0.2l0.3,0.2l0.4,0.3l0.2,0.1l0.1,0.3l0.1,0.4h0.2l0.1-0.2h0.4l0.5,0.2l0.4,0.4l0.6,0.4l0.4,0.2h0.4l0.5-0.2
                l0.5-0.4l0.4-0.1h0.2l0.1,0.3l0.1,0.1h0.4l0.8,0.1l0.6-0.1l0.1,0.2l0.1,0.3l0.3,0.1l0.4-0.1l0.6,0.1h-0.1h-0.1l-0.1,0.1l-0.1,0.1
                l-0.3,0.2l-0.5,0.2l-0.6,0.2l-1.1,0.4l-0.7,0.4l-0.3,0.3l-1.2,1.3l-0.7,0.7l-1.2,0.9l-0.1,0.1v0.1l0.1,0.1l0.2,0.2h0.2h0.2l0.9-0.3
                l0.4-0.2l0.4-0.3l0,0l0.2,0.1h0.1l0.5-0.4h0.2h0.1l0.2,0.1v0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.2l-0.1,0.2l-0.2,0.2l0.1,0.1l0.4-0.2
                l0.1-0.1l0,0l0,0l0,0l0.3,0.2l0.2,0.1l0.2,0.1l0.4-0.1l0.5-0.2l0.4-0.3l0.4-0.3l0.5-0.2l0.6-0.1l0.4-0.1l0.2-0.1l0.3-0.3l0.2-0.1
                h0.2l0.2-0.1l0.1-0.2l0.2-0.2l0.3-0.3l0.2-0.1l0,0v0.1v0.1l0.1,0.1l0.2,0.1l0.1,0.1v0.1v0.1l0.1,0.1v0.2l-0.1,0.3v0.2v0.1v0.1h0.1
                l0.1-0.1v-0.1v-0.1l0.5-0.3h0.1l0.2,0.1l0.7,0.1l0.3,0.1l0.3,0.2l0.1,0.1l0.4,0.6l0.2,0.2l0.1,0.1l0.1,0.1v0.2l0.1,0.1h0.5h0.2
                l0.1-0.1l0.2,0.1l0.2,0.2l0.2,0.1l0.2-0.1h0.2l0.2,0.1l0.1,0.1l0,0l0.3-0.3l0.4-0.3l0.5-0.3l0.3-0.1h0.1l0.4-0.1h0.4l0.5,0.1
                l0.5-0.1l0.5-0.2l0.4-0.1h0.3l0.1,0.1l-0.1,0.1v0.2v0.3v0.2l-0.1,0.1v0.1v0.1h0.3l0.2,0.1h0.2l0.2-0.1h0.2l0.1,0.2h0.2l0.2-0.1
                l0.1-0.1h0.1h0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.1,0.2v0.3v0.1v0.1l-0.1,0.1v0.1l0.1,0.1h0.3l0.2,0.1l0.1,0.1v0.1v0.1l0.1,0.1l0.3,0.2
                l0.1,0.1v0.1l-0.1,0.1h-0.1l-1-0.2h-0.3v0.1l0,0l0,0h-0.1l0,0v-0.1l-0.1-0.1H238l-0.1,0.1l-0.1,0.3v0.1v0.2v0.1l0,0l0,0h-0.1h-0.1
                l-0.5-0.5l-0.4-0.2l-0.4-0.2l-0.4-0.1h-0.3l-0.2,0.1l-0.2,0.2l-0.3,0.1h-0.4l-0.2,0.1v0.1h-0.1l-0.2-0.1h-0.2h-0.2l-0.2,0.1
                l-0.2,0.3v0.1l-0.1,0.1l-0.4,0.2v0.1l-0.2,0.2l-0.1,0.1v0.1h-0.1l-0.1-0.1v-0.2l0.1-0.2l0.1-0.1h0.1l0.1-0.1v-0.2v-0.1h-0.2
                l-0.1,0.1h-0.1h-0.1L232,319l-0.1,0.3l-0.1,0.2l-0.2,0.1l-0.1-0.1l-0.1-0.3V319l0.1-0.3l0,0l-0.1,0.1l-0.1,0.2l-0.1,0.5l-0.1,0.1
                l-0.1,0.1l-0.2,0.3l-0.2,0.5l-0.3,0.5l-0.3,0.5l-0.2,0.2h-0.1v0.1v0.3l-0.1,0.2l-0.2,0.2l-0.4,0.2l-0.1,0.1v0.1l-0.3,0.5l-0.1,0.3
                l-0.1,0.3v0.2l0.1,0.1h0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.1-0.2l0.2-0.4l0.2-0.2h0.1l0,0l0.2-0.1h0.2l0.1,0.2l0.1,0.1v0.1l-0.1,0.2
                l-0.1,0.2l-0.1,0.2l-0.1,0.4l-0.1,0.5v0.4l0.1,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.3l-0.1,0.3l-0.1,0.3v0.2v0.2l0.1,0.2v0.1l-0.2,0.5
                l-0.1,0.2v0.2l-0.1,0.2l-0.2,0.4l-0.1,0.2v0.2v0.3v0.1v0.1v0.1v0.1v0.1v0.1l0.1,0.1v0.1v0.2l0.1,0.2l0.1,0.1v0.2l-0.1,0.3v0.5v0.8
                v0.5l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.3l0.1,0.5l0.1,0.4l0.1,0.2l0.1,0.1l0.1,0.1h0.1v0.1h0.2h0.3h0.3l0.2-0.1l0.8-0.4l0.3-0.2
                l0.2-0.2l0.2-0.3l0.2-0.4l0.3-0.5l0.2-0.3l0.1-0.4l0.1-0.4l0.1-0.5v-0.5v-0.5l-0.1-0.5l-0.1-0.5l-0.1-0.3v-0.1l-0.3-0.6l-0.3-0.9
                v-0.1l0.3-0.5v-0.1v-0.4l-0.1-0.2l-0.1-0.3v-0.1l0.3-0.4l0.2-0.3l0.2-0.3l0.1-0.4v-0.5v-0.3l-0.1-0.2v-0.1l0.1-0.1l0.3-0.1l0.1-0.2
                v-0.3l0.1-0.2h0.1l0.1-0.1l0.1-0.1h0.1l0.1,0.1l0.2-0.2l0.3-0.5l0.2-0.3l0.1-0.1l0,0l-0.1,0.1v0.2v0.2v0.2l-0.1,0.2v0.1l-0.1,0.3
                v0.2l0.1,0.1h0.1l0.1-0.2V323l0,0v-0.1l0.1-0.2l0.1-0.1h0.1v0.1v0.2l-0.2,0.4v0.1l0,0l0.2-0.3l0.2-0.4l0.1-0.3v-0.2v-0.5v-0.2v-0.1
                l0.2-0.1l0.3-0.2l0.3-0.1h0.3h0.2l0.1-0.1v-0.1h-0.2l-0.1-0.1l-0.1-0.2v-0.2v-0.2l0.1-0.2l0.2-0.2l0.1-0.1l-0.1-0.1h0.1h0.2h0.2
                l0.1-0.1l0,0l0.2,0.1l0.4,0.2l0.3,0.1h0.3l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.2l0.2,0.1h0.2l0.2,0.1l0.1,0.1l0.8,0.3l0.3,0.2l0.2,0.2
                l0.1,0.1v0.1v0.1l0.1,0.2h0.1v0.1v0.2v0.1l0.1,0.1l0,0l-0.2-0.1h-0.1l-0.1,0.1v0.1v0.1l0.3,0.4l0.1,0.3v0.4v0.2v0.3v0.5v0.2
                l-0.1,0.2l-0.3,0.3l0,0h-0.1H241l-0.1,0.2l-0.1,0.4l-0.1,0.2h-0.1l-0.1,0.1v0.1l-0.1,0.1h-0.2l-0.2,0.1l-0.1,0.3v0.2l-0.1,0.2v0.2
                l0.1,0.1l0.2,0.1l0.3,0.1h0.2l0.1-0.1l0.1-0.2l0.1-0.1h0.2l0.1-0.1l0.3-0.5v-0.1h-0.1l0,0h0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.4-0.1
                l0.2-0.1h0.1l0.2,0.1l0.3,0.2l0.2,0.3l0.1,0.3l0.2,0.9l0.2,1.4l0.2,0.8l0.1,0.2v0.1l-0.2,1.1l-0.2,0.4l-0.3,0.3l-0.3,0.2l-0.3,0.4
                l-0.4,0.2l-0.2,0.1v0.1l0,0l-0.1,0.2l-0.1,0.3v0.2v0.1l-0.1,0.1l-0.1,0.2v0.1v0.1l0,0h-0.1h-0.1l-0.1,0.2l-0.2,0.4v0.1v0.1h0.3
                l0.2,0.1l0.1,0.1l0.5,0.2l0.1,0.1l0.1,0.1h0.1h0.1l0.1-0.1h0.1l0.1,0.1l0.3,0.1l0,0l-0.8,0.2l-0.2,0.1l0.2,0.1h0.2l0.2-0.1h0.2h0.2
                h0.1l0,0l0.3,0.2h0.1h0.1l0.1-0.1l0.3-0.1l0.5-0.1l0.4-0.1h0.4h0.3l0.2-0.1l0.5-0.5l0.6-0.4l1-0.5l1.5-0.6l0.2-0.1l0.2-0.1l0.1-0.1
                l0.1-0.1l1-0.6l0.5-0.3l0.3-0.2l0.3-0.2l0.3-0.2l0.2-0.2l0.2-0.3l0.2-0.2l0.2-0.1l0.1-0.1v-0.1v-0.2l-0.1-0.2v-0.2l0.1-0.1v-0.1l0,0
                l-0.4-0.1v-0.7l0,0l0,0l1-0.3l0.7-0.1h0.8l0.9,0.1l0.4,0.1l0.2,0.2h0.3l0.3-0.1h0.4h0.5h0.3v0.1l0,0v-0.1l0.1-0.1l0.4-0.1h0.1
                l0.1-0.1l0.1-0.2l0.2-0.2l0.3-0.2l0.3-0.1h0.2h0.2l0.1-0.1v-0.1v-0.1V328l0,0l0,0v-0.1v-0.3v-0.2l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.1
                v0.1l0,0h0.1l0.2-0.2v-0.1l0.1-0.1l0.1-0.1h-0.1h-0.2l0,0l0.1-0.2v-0.1l0,0h-0.1l-0.2,0.1H261v-0.1l-0.1-0.2l0.3-0.3l1.1-0.8
                l0.1-0.1v-0.1v-0.1l0,0l0,0l1.1-1.1l0.6-0.5l0.5-0.3l0.4-0.1h0.3h0.1h0.1h0.7h1.2h1.2h1.2h1.2h1.2h1.2h1.2l0.3-0.8l0.3-0.4l0.4,0.1
                h0.2l0.2-0.2l0.2-0.1h0.1l0.1,0.2l0.1,0.1h0.1l0.1-0.2v-0.2l0.1-0.2h0.1h0.1l0.1-0.1v-0.1v-0.2v-0.2l0.3-0.4l0.4-0.3l0.1-0.1v-0.3
                l0.2-0.3l0.1-0.2v-0.1l-0.1-0.2v-0.3l0.1-0.4l0.1-0.4l0.2-0.4l0.3-0.4l0.1-0.5l0.1-0.6l0.4-0.5l0.4-0.6l0.2-0.4l0.4-0.6l0.3-0.5
                l0.2-0.2l0.2-0.3l0.3,0.1l0.3,0.1v0.4v0.3l0.1,0.2l0.2,0.1h0.1h0.2l0.5-0.2l0.5-0.1l0.3-0.1l0.1-0.1h0.1h0.2l0.4,0.3l0.5,0.4
                l0.4,0.3v0.6v0.6v0.7v0.5v0.7v0.5v0.7v0.4v0.1v0.1v0.1v0.1v0.1v0.1v0.1v0.1v0.2v0.1l0.1,0.1h0.1l0.1,0.1l0.1,0.1l0.2,0.1h0.2h0.1
                l0.2,0.1l0.1,0.1v0.1l-0.1,0.1l-0.1,0.1v0.1v0.1l0.1,0.1l0.1,0.2v0.1l-0.1,0.1v0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1-0.1l0.1-0.1
                l0.1-0.1h0.1h0.1h0.1v0.1l0.1,0.2l0.1,0.4l-0.1,0.2v0.2l0.3,0.1l0.1,0.1v0.1l-0.6,0.6l-0.5-0.1l-0.3,0.2h-0.3l-0.1,0.3l-0.2,0.1
                h-0.2l-0.2-0.1h-0.1l-0.2,0.5H285l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1-0.2l-0.1-0.2h-0.1l-0.1-0.1h-0.1h-0.1l-0.1,0.1l-0.2,0.1
                l-0.1-0.1l-0.1-0.2l-0.1,0.2l-0.1,0.3v0.3l-0.1,0.2h-0.1l-0.1-0.2l-0.3-0.1h-0.2l0.1-0.2l0.2-0.2h-0.1h-0.1l0,0l0.1-0.2v-0.2
                l-0.1,0.1l-0.1,0.3l-0.3,0.2v0.3l-0.3,0.7v0.3l-0.2,0.2l-0.2,0.2l-0.3-0.1l-0.3,0.2l-0.1,0.2h-0.1l-0.1-0.3v-0.1l-0.1,0.4h-0.1v-0.3
                v-0.2l-0.1,0.2l-0.1,0.4h-0.1v-0.1h-0.1v0.2v0.2v0.1l-0.1-0.1l-0.1-0.1l-0.1,0.1h-0.1v-0.1v-0.1l-0.3,0.1l-0.3,0.3l-0.3,0.4l0.1,0.1
                l0.1,0.1l-0.4,0.6l-0.5,0.5l-0.3,0.8l-0.1,0.1l-0.1,0.1l-0.1,0.5l-0.1,0.4l0.1,0.2l0.1,0.2l0.1,0.2h0.1h0.1h0.1l0.1,0.1v0.1H278
                l-0.3,0.2l-0.2,0.1l-0.1,0.2l-0.2,0.2l-0.3,0.4l0.1,0.1l0.5,0.1l0.2,0.1l0.3,0.7l-0.1,0.1v0.1l0.3,0.2l0.1,0.5l0.2,0.2l0.4,0.1
                l0.5-0.2l0.4-0.2v-0.2l-0.2-0.4l-0.1-0.2l-0.2-0.1l-0.1,0.1l-0.1-0.1v-0.1h0.1h0.1l0.1,0.1l0.4,0.4l0.1,0.6v0.4v0.1h-0.1h-0.2
                l-1,0.2l-0.2,0.2l-0.5,0.2v-0.1v-0.2v-0.4h-0.1l-0.8,0.6h-0.3l-0.2,0.2l-0.1-0.1v-0.5l0.2-0.4h-0.1l-0.3,0.2l-0.1-0.1L276,335
                l-0.1-0.1h-0.1l0.1,0.3l-0.2,0.3v0.7l-0.2,0.3l-0.7,0.2h-0.5l-0.4,0.1l-0.5,0.1l-0.3-0.1l-0.3,0.1h-1l-0.2-0.1l-0.3,0.3l-0.4,0.2
                l-1.1,0.6l-0.2,0.2l-0.3,0.3l-0.2,0.2l-0.2,0.1l-0.1,0.1l-0.1,0.1l0.1-0.3l0.1-0.3l0.1-0.5v-0.4l-0.1-0.2l-0.1-0.1l0.1,0.4v0.5
                l-0.1,0.3l-0.3,0.6l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.3l0.1,0.3l0.5,0.1l0.1-0.1l0.1,0.2v0.3v0.3l-0.1,0.3
                l-0.1,0.4l-0.1,0.5l-0.1,0.5v-0.1l0.1-0.6l-0.1,0.1l-0.1,0.1l-0.2,0.8l-0.2,0.4l-0.2,0.3h-0.2v0.2l-0.1,0.1v0.2l-0.1,0.2h-0.1
                l-0.2,0.1l-0.1,0.1v0.2l-0.1,0.1l-0.4,0.7l-0.4,0.2h-0.1l0.1-0.4l0.1-0.4l-0.2-0.2l-0.2-0.1h-0.2l-0.3-0.3l-0.3-0.2l-0.5-0.5v-0.2
                V343l0.1-0.4l0.1-0.3l0.2-0.1l0.6-0.1l0.1-0.2l0.1-0.2l-0.3,0.3l-0.4,0.1l-0.2,0.1l-0.2,0.2l-1.2-0.5l-0.2,0.3v0.2v0.1v0.3l0.2,0.3
                l0.3,0.4l0.1,0.7l0.2,0.5l0.4,0.5l0.3,0.1v0.2l-0.1,0.3l-0.2,0.1h0.2l0.1,0.1l0.1,0.3v0.3v0.2l-0.1,0.1v-0.2v-0.1l-0.1,0.1v0.1v0.3
                l-0.1,0.2h-0.2l-0.2,0.4l-0.2,0.2l-0.7,1.5v0.3l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.5l-0.2,0.5l-0.1-0.2v-0.2l0.1-0.5
                l0.2-0.8l0.3-0.5l0.2-0.2l0.2-0.5l-0.2-0.1h-0.3l0.1-0.2l0.1-0.2l-0.2-0.2h-0.1l-0.1-0.1l0.1-0.2l0.1-0.2V347l0.1-0.2h-0.1l-0.1,0.2
                l-0.1,0.1l-0.1-0.1h-0.1v0.1l-0.1,0.1l-0.2-0.1l-0.3-0.2l-0.1-0.3l-0.1-0.2l0.1-0.4l0.2-0.1l0.2,0.1h0.3v-0.1H262l-0.3-0.3l-0.1-0.2
                l-0.2-0.1l-0.1,0.2h-0.1l0.1-0.4h0.1l0.2-0.1l-0.1-0.2l-0.1-0.1l-0.3,0.1v-0.2v-0.2h0.2l0.2,0.1l0.1-0.3v-0.1l-0.2,0.2l-0.1-0.5
                l0.2-0.4l0.2-0.2h0.3h0.3l-0.2-0.1h-0.2l0.1-0.2h0.1l0.1-0.2h-0.3v-0.3l-0.1,0.1h-0.2l-0.1,0.1v0.2v0.1l-0.1,0.1l-0.2,0.1V343
                l-0.1-0.1v0.3l-0.1,0.1l-0.2-0.3v0.1v0.1v0.1l-0.1,0.1v0.2l-0.1,0.1l-0.4-0.2v0.1l0.2,0.3l0.2,0.1v0.2l-0.1,0.2l-0.2-0.1l0,0
                l0.1,0.2l0.1,0.2l-0.1,0.2v0.2v0.2v0.2l0.1,0.8l0.1,0.2l0.1,0.2l0.1,0.2h-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.4v-0.1l-0.1-0.1v0.3l0.1,0.3
                l0.7,0.7l0.1,0.3l0.1,0.2v0.2l-0.2-0.1l-0.1-0.2l-0.4-0.2l-0.5-0.1l-0.3-0.5v0.2l-0.1,0.2l-0.2-0.2l-0.1-0.2v-0.2h-0.2l-0.2,0.2
                h-0.2v-0.3l0.1-0.2l0.2-0.4l0.2-0.2l0.1-0.3v-0.4v0.4l-0.1,0.2l-0.2,0.2l-0.3,0.3l-0.1,0.3l-0.1,0.5l0.1,0.2h0.1l0.3-0.1h0.2
                l0.4,0.6l0.7,0.2l0.3,0.1l0.2,0.3l0.3,0.2l0.3,0.2v0.2l-0.1,0.2v0.3l-0.1,0.2h-0.3h-0.2l-0.8-0.9l-0.1-0.1l-0.3-0.5l-0.4-0.3h-0.1
                l0.5,0.5l0.2,0.3l0.4,0.5l0.3,0.2l0.2,0.3l0.2,0.1l0.5,0.2l-0.2,0.1l0.3,0.1v0.2v0.3l-0.4-0.1v0.2v0.1l-0.2,0.1l-0.2-0.1l-0.6-0.7
                v0.1v0.1l0.4,0.4l0.3,0.3l0.3,0.1l0.2,0.2l0.1,0.1v0.2l-0.2,0.1l-0.2,0.1l-0.2-0.1l-0.1-0.1l-0.3-0.2l-0.1-0.3h-0.2l-0.9-0.4h-0.7
                l0.1,0.1h0.1l0.5,0.1l0.2,0.2l0.4,0.1h0.3l0.1,0.4l0.4,0.3v0.2h0.2l0.4-0.2l0.3,0.1l0.4,0.1l0.1,0.2l0.1,0.3l0.1,0.4l0.4,1.5
                l0.6,1.2l0.1,0.2l-0.1-0.2l-0.4-0.8l-0.2-0.6l-0.2-1l-0.1-0.2l-0.1-0.1l-0.1,0.1v0.1v0.1l-0.1,0.3v0.2l0.1,0.2l0.2,0.4l0.1,0.5
                l-0.2-0.2l-0.2-0.1l-0.3-0.1l-0.3-0.2v0.2v0.2l-0.2-0.1l-0.1-0.1l0.1,0.3l-0.3-0.1h-0.2l-0.1,0.2l-0.2,0.1h-0.2l-0.3-0.2l-0.1-0.3
                v-0.3v0.3l0.1,0.3v0.3h0.3h0.3h0.4h0.3l0.2-0.1l0.4,0.1v0.3v0.3v0.3h0.1l0.1-0.1l0.1-0.6l0.4-0.2h0.1l0.1,0.2v0.2v0.3l-0.1,0.4
                l-0.5,0.5l-0.4,0.4l-0.2,0.1l-0.3-0.1l-0.3-0.1h-0.2h-0.1l-0.1-0.1v-0.2l-0.1-0.1H260l-0.1,0.3l-0.3,0.1l-0.4-0.1l-0.4-0.2l0.2,0.2
                l1.1,0.4l0.1,0.1l0.1,0.1l-0.1,0.2l-0.1,0.2v0.2v0.1l-0.4,0.3h-0.2l-0.6-0.5l0.3,0.4l0.2,0.2l0.4,0.1l0.8-0.2l0.3,0.2l-0.2,0.3
                l-0.2,0.2h-0.3l-0.2,0.1l-0.1,0.2h-0.2h-0.3H259h-0.2l-0.3,0.3h-0.1l-0.2-0.1l-0.1-0.3l-0.1-0.1v0.5v0.1l0.1,0.1l-0.4,0.3l-0.4,0.3
                l-0.1,0.1L257,361l-0.3,0.5l-0.1,0.3l-0.1,0.4V362v-0.3l-0.1-0.3v0.6l-0.1,0.3h-1.1l-0.5,0.1l-0.7,0.5l-0.2,0.2l-0.6,0.8l-0.2,0.5
                l-0.1-0.2V364v-0.1l-0.2,0.3l0.1,0.4l-0.1,0.2l-0.4,0.3h-0.2l-0.2,0.1l-0.1,0.3l-0.3,0.3l-0.2,0.1l-0.4-0.1l0.1,0.3l-0.1,0.2
                l-0.2,0.2l-0.3,0.1h-0.2l-0.1,0.1l-0.1,0.1l-0.3,0.1l-0.3-0.1h-0.3l-0.2,0.1l0.3,0.1l0.2,0.2v0.2l-0.1,0.1l-0.2,0.1H249v-0.1
                l-0.1-0.2h-0.1v0.1h-0.1l-0.3-0.4v0.3l0.1,0.2l0.1,0.1l0.1,0.1v0.1l-0.2,0.2l-0.1,0.1h-0.2l-0.1,0.1v0.1l-0.1,0.3l-0.3,0.2h-0.1
                l-0.1,0.1l0.1,0.1l0.1,0.1v0.1l-0.1,0.1h-0.2l-0.1,0.1v0.1l0.1,0.1v0.1l-0.2,0.1v0.1h0.1h0.1h0.1l-0.1,0.2l-0.1,0.1l-0.1,0.2
                l-0.2,0.1v0.1l0.1,0.1l0.1,0.2l-0.2,0.3h-0.1l-0.1-0.1l-0.1,0.2v0.1l-0.1,0.3l-0.1,0.3l-0.1,0.1v0.2v0.2l0.1,0.3l0.2,1.2l0.1,0.4
                l0.2,1.1l0.4,1.1l0.6,1.3l0.9,1.6l0.1,0.2l-0.1,0.2v0.2v0.3v0.3l0.1,0.4l0.2,0.5l-0.1-0.1l-0.3-0.8v-0.5v-0.6h-0.1l-0.1,0.2v0.2
                l-0.1,0.1l-0.1-0.4v-0.2l0.1-0.2v-0.1l-0.2-0.1v-0.2v-0.2l-0.1-0.1h-0.1l0.1,0.4l0.1,0.2l0.1,0.6l0.2,0.3l0.1,0.3l1.2,3.1l0.3,0.4
                l0.1,0.3l0.1,0.6v0.7l-0.2,1.4v0.9l0,0v-0.1l0,0l-0.2,0.4l-0.2,0.4l-0.1,0.6l-0.1,0.3l-0.3,0.3h-0.2l-0.5,0.2l-0.4-0.1l-0.4,0.1
                h-0.3l-0.2-0.3v-0.1l0.1-0.1h0.1l0.4,0.3l0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.7l-0.3-0.5l-0.1-0.3l-0.6-0.2l-0.4-0.3
                l-0.3-0.5l-0.2-0.9l-0.2-0.1l-0.1-0.1l0.2-0.3l0.2-0.3l-0.1,0.1l-0.1,0.1l-0.1,0.2H245h-0.1l-0.1-0.5v-0.6l0.1-0.2h-0.2l-0.2,0.1
                v0.2v0.1h-0.2l-0.1-0.1l-0.2-0.2l-0.2-0.4l-0.5-1.1l-0.1-0.2l-0.2-0.2h0.1h0.1l0.3-0.5l0.3-0.3l0.1-0.2v-0.1l-0.1-0.1l-0.1,0.1h-0.1
                l-0.2-0.3l-0.2-0.1l-0.1,0.1l0.1,0.2l0.1,0.1v0.3v0.1l-0.1,0.1H243l-0.1,0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2l0.3-1.2l0.2-0.8v-0.9v-0.1
                v-0.2l-0.3-0.5l-1.5-1.3l-1.1-1.5l-1-0.6l-0.7,0.1l-0.1,0.1l-0.1,0.1v0.2l-0.1,0.1H238h-0.3l-0.7,0.4h-0.2l-0.2,0.1l-0.2,0.1H236
                l-0.4,0.1h-0.2l-0.1-0.2v-0.2l0.1,0.2l0.1,0.1l0.1-0.1v-0.1l-0.1-0.3l-0.4-0.3l-0.5-0.5h0.1v-0.1l-0.1-0.1l0.1-0.2l0.1-0.2h-0.2
                l-0.2,0.1v0.1v0.1h-0.1l-0.2-0.1l-0.9-0.4l-0.8-0.2l0.6-0.1l0.3,0.1v-0.1l-0.1-0.1l-0.3-0.1h-0.3h-0.2l-0.2,0.1l-0.2,0.1l-0.2,0.1
                l-0.8,0.1l-0.7,0.1l0.1-0.1l0.1-0.1l0.4-0.1l0.1-0.2l-0.1-0.2l-0.1,0.1l-0.1,0.2l-0.1-0.1h-0.1v0.3l-0.2,0.2l-0.1,0.2l-0.5,0.1h-0.1
                l0.2-0.2v-0.1l-0.2,0.1l-0.3,0.3l-1.1,0.1l0.1-0.1h0.2l0.3-0.1l-0.1-0.2l-0.1-0.2h-0.1l-0.1-0.1v-0.4l-0.1-0.2l-0.2-0.2H228
                l-0.1,0.4l-0.1,0.5l-0.1,0.2h-0.3h-0.3l-1,0.1l-0.4-0.2h-0.1h-0.1l-0.4,0.2l-0.5,0.1h-0.1h-0.2l-0.3,0.4l-0.4,0.2l-1-0.3l-0.3-0.3
                l-0.2-0.1h-0.3l-0.3,0.3l-0.2,0.4l0.4,0.2l0.3,0.1l0.5-0.1l0.3-0.2h0.2h0.1l0.1-0.1l0.2,0.1v0.1l-0.1,0.1l-0.2,0.1l-0.1,0.1l0.2,0.2
                l0.3,0.1h0.1l0.1-0.3l0.2-0.2h0.3v0.1v0.1l0.1,0.2v0.3v0.1l-0.3,0.1h-0.2l-0.2,0.2l0.1,0.1l-0.2,0.1h-0.1h-0.1v0.1l-0.1,0.1l0.1,0.3
                l0.3,0.2l0.2,0.2l0.8,0.3h0.2l0.2,0.3l0.1,0.1h0.1v0.2l-0.2,0.1l-0.1,0.2l-0.1,0.1l-0.1-0.1l-0.1-0.1l-0.3,0.3l-0.1,0.1l0.1-0.3
                l-0.1-0.1l-0.2-0.3l-0.2-0.2l-0.2-0.1l-0.1-0.1h-0.1h-0.1l-0.2-0.1v-0.2l-0.1-0.1l-0.2-0.1l-0.8-0.3v0.1l0.1,0.1l0.1,0.1l0.1,0.1
                v0.3l-0.1,0.1v0.2l-0.1,0.2l-0.1,0.1l-0.2,0.1l-0.1-0.1l-0.2-0.4l-0.2-0.1H221l-0.2,0.1l-0.3,0.4l-0.2,0.1l-0.7-0.2l-0.8-0.3v-0.1
                h0.1h0.2v-0.1l-0.2-0.3v-0.2v-0.2h-0.1l-0.1,0.2l-0.5-0.1l-0.1-0.2l-0.3-0.5h-0.4l-0.2-0.3l-0.3,0.1l-0.2,0.1l-0.2,0.2l0.1,0.1
                l0.2,0.2l-0.1,0.1l-0.5,0.1l-1.2-0.1l-0.3-0.1l-0.5-0.3l-0.6-0.2h-0.3H213h-0.9l-0.2,0.1l-0.2,0.1l-0.1-0.1l-0.1-0.2h0.1l0.1-0.1
                l0.1-0.2v-0.1l-0.1-0.1h-0.1l-0.3,0.5l0.2,0.3v0.1l-0.6,0.1l-1.3,0.6l-0.5,0.3v-0.1l0.6-0.4l-0.2-0.1l-0.4,0.1H209l0.2-0.3v-0.3
                h-0.3l-0.2,0.2h-0.1l-0.1-0.1h-0.1l0.1,0.6l0.2,0.2l0.1,0.3l-0.4,0.4l-0.3,0.3v0.3l-0.3,0.4l-0.3,0.2l-0.8,0.5l-0.2,0.1l-0.3,0.2
                l-0.5,0.2l-0.4,0.3h-0.2l0.3-0.2l0.3-0.2h-0.3l-0.4-0.1h-0.3v0.1l-0.2,0.1l-0.2-0.2l-0.1-0.1v-0.1h-0.1H204l0.3,0.7h0.1l0.2,0.1
                l-0.2,0.2l-0.2,0.1l-0.3,0.1l-0.3-0.3l-0.1,0.3v0.3l-0.1,0.1h-0.2l-0.1-0.1v-0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.2v0.1v0.1l0.3-0.1
                l-0.1,0.3l-0.3,0.3l-0.2,0.3h-0.3h-0.1l-0.1,0.1l0.4,0.5l-0.3,0.8l-0.2,0.3h-0.1h-0.1l-0.4-0.3l-0.2-0.2l0.2,0.5l0.6,0.2v0.2v0.2
                l-0.1,0.2l-0.1,0.3l0.1,0.2l0.1,0.5l0.1,0.2l0.1,0.6l0.1,0.3l0.5,1h0.2v0.1v0.2l-0.4,0.1L202,388v0.1l0,0l0,0l-0.2-0.1l-0.4-0.3
                l-0.6-0.2l-0.8-0.1l-0.5-0.1l-0.3-0.2l-0.3-0.1h-0.3l-0.3-0.1l-0.2-0.2l-0.3-0.1l-0.4-0.1l-0.3-0.1l-0.2-0.4l0,0l-0.2-0.6l-0.2-0.4
                l-0.4-0.5v-0.1l0,0v-0.1V384v-0.2l-0.1-0.2v-0.2l0.1-0.2V383l-0.1-0.3l-0.3-0.3l-0.4-0.3l-0.4-0.4l-0.3-0.6l-0.3-0.4l-0.3-0.2
                l-0.2-0.3l-0.1-0.3v-0.2v-0.1l-0.2-0.4l-0.4-0.7l-0.2-0.5l-0.1-0.3l-0.3-0.4l-0.5-0.4l-0.3-0.3l-0.1-0.2l0,0l-0.7-0.6l-0.2-0.3
                l-0.2-0.1h-0.2h-0.1v-0.1h-0.1l-0.1,0.1h-0.4h-0.7l-0.5-0.1l-0.3-0.1h-0.2l-0.1,0.2l-0.3,0.1l-0.4,0.1l-0.3,0.3l-0.3,0.6l-0.1,0.4
                v0.2l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.3l-0.2,0.1h-0.3l-0.5-0.2l-0.7-0.4l-0.5-0.3l-0.4-0.1l-0.3-0.2l-0.3-0.3l-0.3-0.2l-0.2-0.1
                l-0.3-0.3l-0.3-0.6l-0.2-0.4v-0.5l-0.4-1l-0.2-0.4l-0.2-0.2l-0.3-0.2l-0.5-0.3l-0.7-0.6l-0.8-0.8l-0.6-0.5l-0.3-0.2l-0.3-0.3
                l-0.3-0.4l-0.2-0.3l0,0l0,0h-0.6h-0.6h-0.6H174h-0.6h-0.6h-0.6h-0.6v0.4v0.4v0.4v0.4h-1h-1h-1h-1h-1h-1h-1h-1l-1.3-0.5l-1.3-0.5
                l-1.3-0.5l-1.3-0.5l-1.3-0.5l-1.3-0.5l-1.3-0.5l-1.3-0.5l0.1-0.2l0.2-0.5h-0.3l-0.8,0.1l-0.8,0.1l-0.8,0.1l-0.8,0.1l-0.8,0.1
                l-0.8,0.1l-0.8,0.1l-0.8,0.1l0,0v-0.4l-0.1-0.1l-0.2,0.1l-0.1-0.5v-0.2v-0.2l-0.2-0.5l-0.4-0.7l-0.9-0.8l-0.5-0.3l-0.4-0.3l-0.2-0.1
                h-0.3l-0.1,0.2l-0.3-0.1v-0.4l-0.3-0.5l-0.3-0.1h-0.7l-0.9-0.3l-0.3-0.2l-0.1-0.3l-0.4-0.3l-0.5-0.3l-0.3,0.1h-0.4l-0.6-0.2H138
                l-0.6,0.1h-0.2l-0.2-0.2l-0.2-0.1l0.1-0.3v-0.3v-0.2l-0.1-0.5l0.1-0.4l-0.1-0.2l-0.1-0.1l-0.4-0.2l-0.1-0.2l0.1-0.3l-0.1-0.2
                l-0.3-0.2l-0.3-0.4l-0.4-0.2l-0.2-0.4l-0.3-0.2l-0.1-0.2l-0.6-0.8l-0.6-0.6l-0.1-0.4v-0.5l0.2-0.3l0.1-0.3v-0.2v-0.2l-0.2-0.3
                l-0.8-0.2l-0.6-0.8v-0.6l-0.3-0.6v-0.4v-0.4l0.2-0.1h0.2v0.2l0.1,0.3l0.2,0.2l0.2,0.1l0.2,0.2l0.1,0.1h0.1l-0.1-0.1l-0.1-0.1
                l-0.1-0.3l-0.2-0.4l-0.2-0.2l-0.1-0.4l-0.1-0.1l-0.1-0.1l0.2-0.2l0.3-0.1h0.4l1,0.1l0.2-0.1h0.2h0.1l-0.3-0.1h-0.2h-0.2h-0.4h-0.1
                l-0.2-0.1h-0.1l-0.3,0.2h-0.2l-0.4-0.2h-0.2l-0.3,0.1v0.6l0.1,0.4h-0.2l-0.2-0.2l-0.3-0.1l-0.2-0.2l-0.3-0.3l-0.2-0.1l-0.2,0.2v-0.1
                l0.1-0.3v-0.5l0.3,0.4l-0.1-0.3l-0.2-0.3l-0.2-0.1l-0.2-0.5l-0.5-0.3l-0.4-0.5L128,345l-0.1-0.7l-0.3-0.9l0.1-0.5v-0.4l-0.1-0.6
                l-0.1-0.3l-0.6-0.9l-0.7-0.8l-0.1-0.4v-0.4l0.1-0.4l0.1-0.4l0.1-0.1l0,0v0.1h0.1v-0.2l0.1-0.1h-0.1v-0.1l0.1-0.1l0.2-0.5v-0.7
                l0.2-0.8v-0.3l-0.1-0.6l-0.1-0.4l-0.2-0.3l0.1-0.4v-0.4l-0.4-0.5l-0.2-0.7v-0.3v-0.8l-0.1-0.3l-0.3-0.5l0.1-0.5l0.1-0.3l0.3-1.3
                l0.1-0.1h0.1l0.2-0.2h-0.1l-0.2,0.1l0.1-0.5l0.2-0.4l0.1-0.2l0.1-1.4l0.1-1.1l0.1-0.4l-0.1-0.4l0.1-0.5v-0.5l0.3-2.5v-0.3l0.1-0.4
                l-0.1-1.1v-1.2l-0.1-0.2v-0.2h0.1l0.1,0.2h0.7l0.4-0.2l0.2,0.1l0.2,0.2h0.2h0.3l-0.1-0.1h-0.1l-0.3-0.2l-0.2-0.2h-0.5l-0.1-0.1
                l-0.6,0.1l-0.2-0.1l-0.3,0.1l0.1-0.4v-0.5v-0.5l0.1,0.3l0.2,0.4l0.1-0.4l0.1-0.5l-0.2-0.2l-0.3-0.1l-0.1-0.5l0.8-0.4l-0.4-0.1
                l-0.2-0.2H127v0.1l-0.1,0.2l-0.1-0.2v-0.3l-0.1-0.5l-0.3-0.8l-0.2-1.1l-0.2-0.5l-0.5-0.5l-0.1-0.3l-0.1-0.7l0.1-0.6l-0.1-0.4h0.2
                l0.6,0.3l0.7,0.2l0.2,0.2l0.3,0.1l1.9,0.2h0.1l0.2-0.1h0.1l0.3,0.3h0.1h0.2l0.1-0.1l0.2-0.2v0.1v0.2l0.1,0.3l0.2,0.3l0.1,0.2
                l-0.3,0.6h-0.1v-0.2l0,0l-0.6,1l-0.2,0.5v0.2v0.1h0.1h0.2l0.3-0.2l0,0l-0.3,0.1h-0.1v-0.2v-0.1l0.2-0.3l0.2-0.2l0.3-0.2l0.2-0.2
                l0.1-0.3l0.3-0.3l0.1-0.1v-0.3l0,0h0.1l0.1,0.4v0.2l-0.3,0.2v0.1v0.3l0,0h-0.1l0,0l0.3,0.3l0.1,0.3v0.2l-0.1,0.5l-0.1,0.1h-0.1
                l-0.2-0.1l0,0l-0.1,0.4l0,0l-0.1-0.4l0,0l-0.3,0.2l-0.1,0.2l-0.1,0.3l-0.1,0.1h0.3l0.3-0.1l0.2,0.1h0.1l0.2-0.1l0.1-0.1l0.2-0.4
                l0.1-0.1h0.1l0.1-0.1l0.2-0.2v-0.1l-0.1-0.6V311v-0.1l-0.1-0.1v-0.1l0.1-0.2v-0.1l-0.1-0.1v-0.2l0.2-0.3v-0.1l0.2-0.3l-0.1-0.1
                l-0.2-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1l0,0l0.1,0.4l0,0l-0.3-0.2l-0.1-0.1v-0.2v-0.1l0.1-0.1h0.2v-0.1l-0.2-0.3l-0.3-0.1l-0.1-0.1
                h-0.2l-0.1-0.1v-0.1v-0.1h0.1h0.2l0.1-0.1v-0.1v-0.1v-0.5l-0.1-0.4v-0.1H131l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.2l-0.2-0.6h0.3h1.2h1.2
                h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2
                h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2h1.2
                h0.7v-0.9v-0.7l0.6,0.1l0.2,0.1l0.1,0.1v0.2l0.1,0.6l0.1,0.5l0.3,0.6l0,0v0.2l0.1,0.1l0.2,0.1l0.6,0.2l1,0.2l0.6,0.2l0.1,0.2
                l0.3,0.1h0.4l0.3-0.1L213,307.1L213,307.1z M130.5,306.8L130.5,306.8l-0.1,0.2h-0.1l-0.1-0.1h-0.1v0.2v0.1l-0.3-0.1V307l0.1-0.1
                l0.2-0.1h0.1H130.5L130.5,306.8z M129.9,307.6l0.1,0.1l-0.3-0.1l-0.1-0.1v-0.1v-0.3V307h0.1l0.3,0.3L129.9,307.6L129.9,307.6z
                M130.4,307.9L130.4,307.9h-0.2l-0.1-0.1v-0.1l0.1-0.2l0.1-0.1l0,0v0.2L130.4,307.9L130.4,307.9z M131.1,309l0.1,0.5l0.1-0.2
                l0.4,0.4v0.2v0.1h-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.2l-0.1-0.2v-0.1v-0.3V309h-0.1l-0.1-0.1l-0.1-0.2v-0.1l0.1-0.2l0.2-0.3
                l0.1-0.1h0.1l0.1,0.1l0.1,0.2v0.1l-0.4,0.2v0.1l0.2,0.1l0.1,0.1L131.1,309L131.1,309z M225.4,310L225.4,310L225.4,310l0.1,0.1
                l-0.1,0.1l-0.3,0.1h-0.2h-0.1v-0.1l0.1-0.1V310h-0.1l0,0l0.1-0.1l1.1-0.6l0.5-0.2l0.2-0.1l0,0l-0.3,0.2v0.1v0.1l-0.1,0.1l-0.8,0.4
                L225.4,310L225.4,310z M131.3,311.4L131.3,311.4h-0.2l-0.1-0.1v-0.2V311l0,0l0.1,0.1l0,0L131.3,311.4L131.3,311.4z M131.6,312.2
                L131.6,312.2l-0.1,0.2h-0.1v-0.1l0,0l-0.1,0.1v-0.3l0.1-0.3h0.1l0.1,0.2V312.2L131.6,312.2z M227.5,312.9l-0.1,0.1v0.1l-0.3,0.4
                l-0.1,0.2h-0.1l-0.1-0.2v-0.1l0.1-0.1l0.1-0.1V313v-0.1l-0.1,0.2h0.1l0,0v0.1l-0.4-0.1l-0.1-0.1v-0.2l0.1-0.2l0.5-0.5l0.2-0.1
                l0.6-0.2h0.3h0.3l0.2,0.1l0.1,0.1l-0.1,0.1l-0.6,0.1l0,0l0.1,0.1v0.1l-0.1,0.1L227.5,312.9L227.5,312.9z M130.3,313L130.3,313
                L130.3,313l-0.1-0.1v-0.1l0.1-0.1V313L130.3,313L130.3,313z M221.3,313.7L221.3,313.7h-0.1v-0.1v-0.1h0.1l0,0V313.7L221.3,313.7z
                M220.5,314.1L220.5,314.1h-0.1V314v-0.1h0.1V314.1L220.5,314.1L220.5,314.1z M221,314.1L221,314.1h-0.3V314l0.2-0.1h0.1l0,0V314.1
                L221,314.1z M220.5,314.7l-0.2,0.1h-0.1v-0.1l0.1-0.1l0.4-0.2h0.1v0.1l-0.2,0.1L220.5,314.7L220.5,314.7L220.5,314.7L220.5,314.7z
                M239,316.4L239,316.4L239,316.4h-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.2l0.2-0.2h0.1h0.1v0.1l-0.1,0.2v0.2L239,316.4L239,316.4z
                M239,316.9L239,316.9l-0.2-0.1v-0.1v-0.1h0.1l0.1,0.1l0.1,0.1L239,316.9L239,316.9L239,316.9z M240.7,317.8L240.7,317.8v0.2
                l-0.2,0.1h-0.4l-0.3-0.1l-0.1-0.1l0,0v-0.1h0.1l0.1,0.1h0.1l0.2-0.1l0.1-0.1v-0.1l-0.1-0.1l0,0h0.2l0.1,0.1L240.7,317.8L240.7,317.8
                L240.7,317.8z M237.8,318.6l0.4,0.1l0,0l0.1,0.1v0.1v0.1h-0.1h-0.1l-0.1-0.1L237.8,318.6L237.8,318.6z M235,319.5L235,319.5h-0.2
                v-0.2l0.1-0.3l0.1-0.1l0.1,0.1l0,0v0.4v0.2L235,319.5L235,319.5z M231.3,320.5L231.3,320.5h-0.2l-0.1-0.1l0.1-0.2l0,0h0.1l0,0v0.1
                L231.3,320.5z M231,320.9L231,320.9h-0.1v0.1v0.2v0.1h-0.1l0,0l0.1,0.1v0.1l-0.1,0.1h-0.1l0,0l-0.1,0.1l-0.1,0.2v0.1l0,0v0.1
                l-0.3,0.3h-0.1H230l-0.1-0.1v-0.2V322v-0.1l0.2-0.3l0.1-0.2l0.1-0.2l0.1-0.1l0.2-0.1l0.1-0.1l0.1-0.2l0.1-0.1h0.1l0.1,0.1L231,320.9
                L231,320.9z M233.8,321.5L233.8,321.5l-0.1,0.1l-0.1-0.1l-0.1-0.2l0,0h0.2l0.1,0.1L233.8,321.5L233.8,321.5z M283.7,324.5l-0.2,0.1
                h-0.2v0.2v0.1l-0.2-0.1l-0.1-0.1v-0.3l0.2-0.3l0.1-0.1l0.2,0.1l0.1,0.3L283.7,324.5L283.7,324.5z M261.5,324.7L261.5,324.7h-0.1
                H261.5v-0.1h0.1L261.5,324.7L261.5,324.7L261.5,324.7z M282.5,325l-0.1,0.1h-0.1v-0.2v-0.1l0,0l0.1,0.1L282.5,325z M261.1,325.8
                L261.1,325.8L261.1,325.8v-0.1h0.1l0,0L261.1,325.8z M253.6,329.6l-0.1,0.1h-0.1h-0.1v-0.1v-0.1h0.1h0.2L253.6,329.6L253.6,329.6
                L253.6,329.6z M275.2,335.3l-0.1,0.1h-0.2l0.1-0.1v-0.2l0.1-0.2v-0.1l0.1-0.1V335.3z M274.8,335.4L274.8,335.4l-0.1-0.1l0.1-0.2h0.1
                v0.1L274.8,335.4z M277.2,335.8l-0.8,0.2l-0.1-0.1l0.2-0.1l0.2-0.3h0.2L277.2,335.8L277.2,335.8z M278.7,336.2l-0.2,0.1l-0.5-0.1
                l0.4-0.1h0.1l0.1-0.2v-0.1l0.2,0.4L278.7,336.2L278.7,336.2z M271.6,337.2l-0.2,0.2h0.2l0.2-0.1l0.1-0.1l0.3-0.2l0.3-0.1h0.1
                l0.1,0.1l0.3-0.1l0.3-0.1l-1.2,0.6l-0.2,0.1l-0.4,0.2l-0.3,0.1H271l-1.2,0.5h-0.1h-0.1l-1,0.2h-0.4l-0.4,0.1l0.3-0.2v-0.1l-0.1-0.1
                h-0.1l-0.2,0.2l-0.2,0.1v-0.2l0.1-0.2l0.1-0.2l0.2-0.2l0.3-0.2l0.2-0.1l0.1,0.1v-0.2l0.1-0.1h0.1h0.2h0.1l0.1-0.1h0.1l0.3,0.1h0.3
                l0.2-0.1h0.2h0.6l0.6-0.1l0.2-0.1l0.5-0.4l0.3-0.1l-0.4,0.4L271.6,337.2L271.6,337.2z M266.9,338.9h-0.1l0.1-0.4l0.2-0.2h0.1v0.1
                v0.1l-0.2,0.2L266.9,338.9L266.9,338.9z M267,342l-0.3,0.5v-0.1l0.4-0.7L267,342z M263.7,348.4l-0.1,0.1l0.4-0.7l0.2-0.6l0.1-0.2
                l-0.1,0.4l-0.2,0.4L263.7,348.4L263.7,348.4z M262.8,355.5L262.8,355.5l-0.2-0.3l0.2,0.1V355.5L262.8,355.5L262.8,355.5z
                M263.1,357.6l-0.4,0.1l0,0l0.4-0.2l0.1-0.7v-0.3v-0.6v-0.1l0.1,0.2l0.1,0.5v0.4l-0.1,0.6L263.1,357.6L263.1,357.6z M262.4,357.8
                l-0.5,0.2h-0.1l0.3-0.2H262.4L262.4,357.8z M260.4,359.7L260.4,359.7l0.2-0.4l0.5-0.5l0.1-0.1l-0.4,0.5L260.4,359.7L260.4,359.7z
                M260.3,359.6L260.3,359.6h-0.2l-0.2-0.1v-0.1h0.1L260.3,359.6L260.3,359.6z M138.6,361.6l0.6,0.2l0.3-0.1l0.1,0.1v0.1l-0.7,0.1
                l-0.2-0.1v-0.1L138.6,361.6L138.6,361.6z M137.5,361.8h-0.1h-0.2l0.1-0.1l0.1-0.1l0,0L137.5,361.8L137.5,361.8z M138.2,362.1H138
                h-0.1l-0.2-0.3h0.5l0.2,0.1l0,0L138.2,362.1L138.2,362.1z M143,363.9l0.1,0.2h-0.2h-0.2l-0.1-0.1l-0.1-0.2l0,0h-0.1l0,0v-0.1l0,0
                l0.5,0.2H143z M139.9,364.5h-0.1h-0.2l-0.1-0.2h0.1h0.1L139.9,364.5L139.9,364.5L139.9,364.5z M142.9,365.8h-0.2l-0.2-0.1l-0.2-0.3
                l-0.2-0.3l0.1-0.1l0.1,0.2L142.9,365.8L142.9,365.8z M246.6,371.9l-0.1,0.8l-0.1-0.3v-0.3L246.6,371.9L246.6,371.9L246.6,371.9z
                M227.9,374.2l-0.2,0.1h-0.4h-0.1h0.1l0.4-0.1H227.9L227.9,374.2z M226.6,374.3L226.6,374.3h-0.3l-0.2-0.1v-0.1L226.6,374.3
                L226.6,374.3L226.6,374.3z M224.7,374.8v0.1l-0.1-0.1h-0.2l0.1-0.1h0.1v-0.1l0.3-0.2l-0.1,0.1L224.7,374.8z M225.8,375.7l-0.1,0.1
                l0.1-0.5l-0.1-0.4l0.1,0.2v0.2V375.7L225.8,375.7z M225.6,376l-0.2,0.2v-0.1L225.6,376v-0.1V376L225.6,376z M236.8,376.2l-0.3,0.1
                l-0.3-0.1h0.2h0.1l0.3-0.2l0.2-0.1h0.2L236.8,376.2L236.8,376.2z M217.5,376.6h-0.1l-0.5-0.3v-0.1l0.2-0.1h0.1l0.2,0.1h0.1v0.1v0.1
                V376.6L217.5,376.6z M208.4,377.8h-0.1l0.6-0.5l0.1-0.2h0.2l-0.3,0.3L208.4,377.8L208.4,377.8z M203.5,380.9h-0.1l0.1-0.2l0.2-0.1
                l0.5-0.3h0.2l0.1-0.1l0,0v0.1l-0.4,0.2L203.5,380.9L203.5,380.9z M202.8,381.7L202.8,381.7l0.1-0.3v-0.1l0.2-0.3h0.1v0.1l-0.2,0.2
                L202.8,381.7L202.8,381.7z M250.1,383.7v0.2l-0.3-0.5l-0.3-0.8l-0.2-0.7l0.1,0.2l0.1,0.4L250.1,383.7L250.1,383.7z M201.9,383.6
                l-0.1,0.2L201.9,383.6l0.2-0.7l0.5-0.8l0.2-0.1l-0.6,0.9L201.9,383.6L201.9,383.6z M202.4,387.2v0.1l-0.2-0.7l-0.4-1.5v-0.9l0.1-0.3
                l0.1,1.2l0.4,1.6L202.4,387.2L202.4,387.2z M244.7,386v0.2l-0.1-0.3l-0.1-0.3l0.1,0.1L244.7,386z M244.9,386.3l-0.1,0.1l-0.2-0.1
                l-0.1-0.1V386l0.2,0.2h0.1L244.9,386.3L244.9,386.3z M249.5,390.3l-0.6,0.6l0.1-0.1l0.2-0.3l0.1-0.1L249.5,390.3v-0.2v-0.2l0.2-0.1
                h0.1L249.5,390.3L249.5,390.3z M248.8,391.1L248.8,391.1V391l0,0V391.1z M248.2,391.4L248.2,391.4L248.2,391.4l0.1-0.1l0,0l0,0
                L248.2,391.4z M247.6,391.6l-0.1,0.1l-0.1-0.1l0.1-0.1l0.4-0.1l-0.2,0.1L247.6,391.6L247.6,391.6z M246.8,391.9L246.8,391.9h-0.1
                v-0.1l-0.1-0.2v-0.1l0.3,0.2L246.8,391.9L246.8,391.9L246.8,391.9z M246.2,392H246H246.2v-0.3l0.1,0.1L246.2,392L246.2,392
                L246.2,392z M245.6,392.2L245.6,392.2L245.6,392.2L245.6,392.2l0.1-0.1v0.1H245.6L245.6,392.2z M27.8,400.1l-0.2,0.2l-0.1-0.1h-0.3
                l-0.1-0.1l-0.3-0.1l-0.1-0.2l0.2-0.3l0.4-0.2h0.6l0.1,0.2v0.1l-0.1,0.2v0.2L27.8,400.1L27.8,400.1z M25.6,400.4l-0.1,0.1h-0.1v-0.1
                l0.1-0.2l0.2-0.1l0.2-0.2H26l-0.1,0.1v0.2l-0.2,0.1L25.6,400.4L25.6,400.4z M32.2,401.5L32.2,401.5h0.2v0.2l0.1,0.1l0.1,0.1
                l-0.2,0.1h-0.3l-0.1-0.1l-0.1-0.1h-0.2v-0.1l0,0h-0.1l0.1,0.2h-0.3h-0.1l-0.1-0.2l-0.3-0.3v-0.1l-0.1-0.2h0.4l0.3-0.3h0.2l0.3,0.4
                v0.1L32.2,401.5C32.1,401.5,32.2,401.5,32.2,401.5z M33.9,402.3l0.6,0.1h0.1l0.1,0.1h0.5h0.1l-0.1,0.2l-0.3,0.1l-0.5-0.1h-0.8v-0.1
                l0.1-0.1v-0.1L33.9,402.3L33.9,402.3z M35.9,403.1L35.9,403.1h0.4h0.2l0.4,0.2l0.1,0.1l0.2,0.1l0.1,0.1l-0.1,0.1l-0.3,0.2h-0.4
                l-0.2,0.1H36h-0.1v-0.3l-0.1-0.3h-0.2l-0.2-0.1l-0.2-0.2V403l0.1-0.2h0.2l0.1,0.1L35.9,403.1L35.9,403.1z M34.9,403.6l-0.2,0.1h-0.2
                v-0.2l-0.2-0.3l0.3-0.1l0.2,0.1l0.1,0.1l0.1,0.1C35,403.4,34.9,403.6,34.9,403.6z M38.5,408.8l-0.1,0.1h-0.2l-0.6-0.3l-0.1-0.2v-0.8
                l-0.2-0.6l-0.2-0.5l0.2-0.2l0.2-0.2l0.2-0.4l-0.2-0.5l0.1-0.3h0.1l0.6,0.3l1.2,0.5l0.3,0.4l0.1,0.4h0.2l0.1,0.4l0.3,0.2l0.1,0.1
                l-0.1,0.2l-0.6,0.4l-0.7,0.2l-0.6,0.4L38.5,408.8L38.5,408.8z"/>
            <linearGradient id="UY_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="106.8579" x2="0.1864" y2="106.8579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UY" class="stcustom0 st240" d="M325.3,562.9l-0.1,0.1l-0.1,0.2l-0.2,0.6l-0.6,0.8l-0.1,0.4l-0.6,0.5l-0.4,0.5h-0.3l-0.3,0.2
                l-1.5,0.7l-0.5-0.1h-0.4l-0.4-0.3l-0.8-0.1l-0.5,0.1l-0.7,0.3h-0.2h-0.2l-0.4-0.1l-0.2-0.3l-1.1-0.3l-0.9-0.8h-1l-0.8,0.1l-0.1-0.1
                l-0.1-0.2l-0.2-0.3l-0.7-0.7l-0.5-0.7l-0.1-0.7l0.1-0.7l0.2-0.8v-0.3l0.2-0.1h0.2l0.2-0.2l0.2-0.3v-0.2l-0.1-0.5l-0.1-0.6l-0.1-0.3
                l0.2-0.5V558l-0.1-0.2v-0.2l0.1-0.2v-0.2l-0.1-0.2l0.1-0.2l0.2-0.1l0.2-0.2l0.1-0.3l0.1-0.2v-0.1l-0.1-0.1l-0.1-0.1l0.1-0.3l0.2-0.4
                l0.2-0.3l0.1-0.3v-0.2l-0.1-0.2v-0.1l0.1-0.1l0.1-0.2v-0.5l-0.2-0.4l0.1-0.3l0.3-0.4l0.2-0.3v-0.2l0.1-0.1l0.2,0.2l0.5,0.1h0.5
                l0.1-0.1l0.2-0.4l0.2-0.1h0.3h0.3l0.3,0.3l0.9,0.8l0.6,0.6l0.2,0.3l0.2,0.2l0.1,0.2l-0.1,0.5v0.2v0.1h0.1h0.2l0.2-0.1l0.1-0.2
                l0.1-0.1l0.1-0.1v-0.1l0.1-0.1h0.1l0.1,0.1l0.3,0.3l0.2,0.3l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.1l0.2-0.1l0.4,0.4
                l0.9,0.3l0.2,0.2l0.1,0.3l0.3,0.4l0.4,0.4l0.3,0.2l0.3,0.1l0.2,0.1l0.1,0.1l0.2,0.1l0.1,0.1v0.1l0.1,0.3l0.1,0.4l0.1,0.3l0.3,0.3
                l0.4,0.3l0.4,0.1l0.2,0.2l0.1,0.2l-0.2,0.3l-0.3,0.4l-0.2,0.3l-0.2,0.2l-0.1,0.1l-0.1,0.2v1.1v0.4v0.1v0.1l0.2,0.1L325.3,562.9
                L325.3,562.9z"/>
            <linearGradient id="UZ_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="330.408" x2="0.1864" y2="330.408" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="UZ" class="stcustom0 st241" d="M674.2,332.5L674.2,332.5l0.3-0.1l0.3,0.1l0.2,0.2l0.1,0.1v0.1l-0.6,0.3l-0.3,0.1H674l0,0l-0.1,0.4
                l-0.2,0.1l-0.3,0.1l-0.2,0.2l-0.3,0.4l-0.8,0.6v0.1l0.1,0.1l0.2,0.1l0.3,0.2l0.2,0.1l0.5-0.2h0.1l0.1,0.2l0.1,0.5l0.2,0.1l0.3,0.1
                h0.2l0.2,0.1h0.3h0.2l0.3,0.1l0,0v-0.8l0.2,0.1h0.1l0.1-0.1l0.1-0.1v-0.3v-0.3l0.1-0.1h0.1h0.1v0.1v0.2l0.2,0.1l0.1,0.1l0.1,0.2
                l0.1,0.2l0.1,0.4h0.2l0.3,0.1l0.2-0.1h0.1v0.2v0.2v0.2h0.1l0.2-0.1h0.2l0.2,0.1l0.2,0.1l0.3,0.4l0.1,0.1h0.5l0.1,0.1h0.2l0.2-0.1
                l0.4,0.1v0.1l-0.1,0.1l-1,0.5l-0.1,0.2l-0.2,0.2l-0.2,0.1h-0.1l-0.5-0.2H678v0.1v0.1l0.1,0.2v0.1l-0.1,0.1l-0.3-0.1l-0.1-0.1v-0.1
                h-0.1l-0.2,0.1l-0.3,0.4l-0.1,0.2v0.1l-0.1,0.1h-0.2l-0.2,0.2l-0.2,0.1l-0.1-0.1v-0.1H676h-0.1h-0.2l-0.2-0.1l-0.2-0.1h-0.2
                l-0.6,0.1l-0.3,0.1l-0.1,0.1h-0.2l-0.7,0.1h-0.1l-0.1-0.2l-0.1-0.2l-0.2-0.1l-0.2-0.1l-0.1-0.1v-0.1v-0.1v-0.1l0.5-0.4l0.4-0.4l0,0
                v-0.1l0.1-0.1V338l-0.3-0.1v-0.1l0.1-0.1v-0.1l-0.2-0.3l-0.4-0.4h-0.1h-0.1l-0.2,0.4l-0.1,0.1l-0.4,0.3l-0.4,0.2l-0.7,0.3l-0.2,0.1
                h-0.1l-0.1-0.1l-0.4-0.3l-0.2-0.1l-0.2,0.1l-0.1,0.2v0.3l-0.2,0.2l-0.2,0.1l0.3,0.9v0.1H669l0.2,0.3h-0.1l-0.3-0.1l-0.4-0.1
                l-0.8,0.1l-0.1,0.1v0.1v0.1h0.4h0.4l0.1,0.1v0.1v0.1h-0.1l-0.3,0.1v0.1v0.1l0.1,0.2l0.1,0.1v0.1v0.1h-0.1l-0.1-0.1h-0.1v0.1v0.1
                l-0.1,0.1h-0.1h-0.1l-0.1,0.3l-0.1,0.4l-0.2,0.3l-0.1,0.1h-0.2h-0.3h-0.2l-0.5-0.1l-0.5-0.1l-0.5-0.1l-0.5,0.2l-0.1,0.1l-0.1,0.1
                l-0.1,0.1l-0.2,0.8v0.1l0.1,0.1l0.6,0.2l0.1,0.1l0.1,0.1v0.4l0,0h0.2h0.3h0.2h0.2l0.2,0.1l0.1,0.1v0.1l-0.3,0.8v0.3l0.1,0.4l0.2,0.3
                l0.3,0.3l0.2,0.2v0.1v0.1v0.2l-0.1,0.3l-0.2,0.3l-0.2,0.1l-0.2,0.3l-0.2,0.4l-0.4,0.5l-0.1,0.3v0.9L665,351v-0.1l-0.1-0.1h-0.3h-0.2
                l-0.1-0.1h-0.2l-0.3,0.2l-0.3-0.1l-0.4-0.4l-0.7-0.1l-0.9,0.1V350v-0.5v-0.7l0.3-0.5v-0.1l-0.1-0.1l-0.1-0.1l-0.5-0.1l-0.2-0.1
                l-0.2-0.2l-0.3-0.2l-0.2-0.1l-0.3-0.2l-0.3-0.1l-0.2,0.1l-0.2,0.1H659h-0.2l-0.6-0.4l-0.9-0.7l-0.7-0.5l-0.5-0.2l-0.1-0.1l-0.3-0.2
                l-0.6-0.6l-0.4,0.1l-0.6-0.4l-0.5-0.4l-0.1-0.1l-0.6-0.7l-0.6-0.4l-0.7-0.5l-0.4-0.3l-0.7-0.5l-0.4-0.3l-0.1-0.1l-0.1-0.2l-0.2-1.1
                l-0.2-0.5l-0.3-0.3l-0.3-0.5l-0.2-0.8l-0.2-0.5l-0.1-0.2l-0.3-0.3l-0.4-0.3l-0.4-0.1h-0.1h-0.1H647l-0.2,0.2h-0.2h-0.2l-0.2-0.1
                l-0.5-0.1l-0.2-0.1h-0.3l-0.7,0.1h-0.2l-0.7-0.5l-0.3-0.2l-0.1-0.1v-0.2l0.1-0.3l0.1-0.2v-0.2l-0.1-0.2V334l0.1-0.1h0.2l0.1-0.1
                v-0.1l-0.1-0.1l-0.1-0.2l-0.4-0.2l-0.1-0.1V333l0.1-0.1v-0.2v-0.2l0.1-0.1v-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.2h-0.3h-0.9l-0.3-0.1
                l-0.2-0.1l-0.2-0.5l-0.1-0.1h-0.1h-0.2h-0.3L640,331l-0.4-0.4l-0.4-0.4l-0.2,0.4l0.2,0.3h-0.3l-0.3-0.1l-0.1,0.1l-0.2,0.1v0.1
                l0.1,0.1l0.2,0.2l0.4,0.5l0.2,0.3v0.1v0.1l0,0h-0.1H639l-0.1-0.1v-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.1H638l-0.1,0.1
                l-0.1,0.1l-0.1,0.3l-0.2,0.4l-0.1,0.2l-0.4,0.1h-0.9l-0.3,0.1l-0.2,0.1l-0.3,0.5l-0.2,0.2l-0.2,0.2l0.1,0.7l0.1,0.8l0.2,0.2l0.1,0.1
                v0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2H635h-0.3h-0.2h-0.8H633h-0.7v-0.9v-0.9v-0.9v-0.9v-0.9v-0.9v-0.9V329v-0.9v-0.9v-0.9v-0.9v-0.9
                v-0.9v-0.9v-0.8l0.3-0.1l0.4-0.1l0.4-0.1l0.5-0.2l0.6-0.2l0.5-0.2l0.6-0.2l0.4-0.1l0.4-0.1l0.5-0.2l0.8-0.2l0.5-0.1l0.5-0.1l0.4-0.1
                h0.3l0.3,0.2l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3l0.4,0.3
                l0.4,0.3l0.4,0.3l0.4,0.2l0.2,0.2l0.1,0.4l0.2,0.3l0.3,0.3l0.3,0.3l0.4,0.5l0.3,0.3l0.3,0.3l0.5,0.5l0.3,0.3h0.2l0.5-0.1l0.6-0.1
                l0.5-0.1l0.6-0.1l0.6-0.1l0.4-0.1l0.7,0.1l0.7,0.1h0.5h0.5h0.5h0.3h0.4l0.1-0.1l0.3-0.2l0.3-0.2l0.3-0.2l0.3-0.1l0.3,0.3l0.2,0.3
                l0.2,0.3l0.3,0.3l0.3,0.2l0.4,0.2l0.2,0.4l0.3,0.5l0.2,0.4l0.2,0.4l0.3-0.1l0.3-0.2l0.3-0.1v0.4v0.4v0.6v0.5l-0.1,0.6v0.5v0.4v0.3
                h0.5h0.4h0.5v0.4l0.1,0.6l0.1,0.5l0.1,0.4l0.1,0.5l0.1,0.3l0.1,0.3l0.1,0.1l0.2,0.1h0.6h0.5h0.4h0.4h0.6l0.2,0.1l0.2-0.1l0.2-0.1
                l0.2,0.2l0.1,0.1l0.1,0.1l0.2,0.1l-0.1,0.3l-0.1,0.4v0.2l0.2,0.2l0.1,0.1l0.4,0.2l0.3,0.1h0.2l0.2-0.1l0.1-0.1v-0.2l-0.1-0.2v-0.2
                l0.1-0.2l0.2-0.3l0.2-0.3l0.3-0.3l0.4-0.3l0.2-0.2l0.1-0.4l0.2-0.2l0.3-0.1l0.3-0.1l0.1-0.2l0.5-0.3l0.3-0.2l0.3-0.1l0.5-0.2
                l0.4-0.2l0.4-0.5l0.3-0.3l0.2-0.2h0.2l0.1,0.2h0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.1h0.3L674.2,332.5L674.2,332.5z M673.4,337.4
                L673.4,337.4l-0.1-0.2l-0.1-0.1h-0.1l0.1,0.1L673.4,337.4L673.4,337.4L673.4,337.4z M674.9,341.2L674.9,341.2h-0.4h-0.1l0.1-0.3
                v-0.1l0,0h-0.1l-0.1-0.1v-0.2v-0.2l0.1-0.1h0.1l0.2,0.3l0.1,0.1h0.3l-0.1,0.2L674.9,341.2L674.9,341.2L674.9,341.2z M676.5,341
                l-0.1,0.2h-0.1l-0.1-0.1V341H676.5L676.5,341L676.5,341L676.5,341z"/>
            <linearGradient id="VA_1_" gradientUnits="userSpaceOnUse" x1="510.0043" y1="332.0579" x2="510.0043" y2="332.0579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VA" class="stcustom0 st242" d="M510,333.8L510,333.8L510,333.8L510,333.8L510,333.8L510,333.8z"/>
            <linearGradient id="VC_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="239.6579" x2="0.1864" y2="239.6579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VC" class="stcustom0 st243" d="M303.4,425.8L303.4,425.8l-0.3-0.1v-0.2l0.1-0.1l0.1-0.1h0.1v0.2L303.4,425.8L303.4,425.8
                L303.4,425.8z M303.3,426.3L303.3,426.3L303.3,426.3L303.3,426.3L303.3,426.3L303.3,426.3h-0.1l0,0l0,0l0,0H303.3L303.3,426.3
                L303.3,426.3L303.3,426.3L303.3,426.3v-0.1l0,0l0,0l0,0l0,0l0,0V426.3L303.3,426.3L303.3,426.3z M303,427.1L303,427.1L303,427.1
                L303,427.1L303,427.1L303,427.1L303,427.1L303,427.1V427l0,0l0,0V427.1L303,427.1L303,427.1L303,427.1L303,427.1L303,427.1z"/>
            <linearGradient id="VE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="221.2076" x2="0.1864" y2="221.2076" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VE" class="stcustom0 st244" d="M306.7,438.9l0.5,0.7v0.1l-0.1,0.1l-0.3,0.2l-0.1,0.1l-0.1,0.3l-0.4,0.2l-0.3,0.2l-0.2,0.2l-0.1,0.1
                h-0.4l-0.1,0.1l-0.2,0.3l-0.1,0.1l-0.2,0.2v0.1l0.3,0.4v0.1l-0.1,0.2v0.1l0.1,0.2h0.2l0.2-0.1h0.2h0.1h0.1v0.1l-0.1,0.2l-0.1,0.2
                l-0.5,0.2l-0.2,0.1l-0.1,0.1l-0.3-0.1h-0.1l-0.1,0.1l-0.1,0.1h-0.2h-0.3h-0.1l-0.1,0.1l-0.1,0.2l0.1,0.2l0.1,0.2v0.2l0.1,0.5
                l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.3v0.1l0.6,0.7l0.6,0.7l0.6,0.7h0.1l0.1,0.1l0.1,0.2l0.1,0.2v0.1l-0.1,0.2l-0.1,0.2l-0.2,0.1
                l-0.3,0.1l-0.2,0.1l-0.2,0.3l-0.1,0.1h-0.1h-0.2h-0.3h-0.2l-0.2,0.2l-0.3,0.1l-0.2,0.3l-0.7,0.3l-0.7,0.2l-0.2,0.1l-0.7-0.2
                l-0.2,0.1l-0.2,0.2l-0.2,0.1h-0.2l-0.1,0.1l-0.1,0.2l-0.1,0.7l-0.3,0.2h-0.3l-0.2-0.3l-0.3-0.2l-0.4-0.5l-0.1-0.1h-0.1l-0.4,0.1
                l-0.2-0.1l-0.2-0.1h-0.3h-0.5h-0.3l-0.1-0.1l-0.1-0.3l-0.1-0.1l-0.1-0.1h-0.2H294h-0.1h-0.1l-0.1-0.2l-0.2-0.1h-0.2l-0.1,0.1
                l0.3,0.4l0.1,0.2l0.3,0.3l0.8,0.7l0.2,0.2v0.3v0.4v0.4l0.2,0.6l0.3,0.6l0.1,0.4l-0.1,0.3l-0.1,0.1v0.1l0.1,0.1l0.3,0.1l0.6,0.1h0.4
                l0.5,0.1v0.2l-0.1,0.3l-0.1,0.2l-0.1,0.1h-0.3l-0.3,0.2l-0.5,0.2h-0.3h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.4L295,458l-0.3,0.2l-0.3,0.2
                h-0.3h-0.2l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.1H293l-0.3,0.1l-0.3,0.2l-0.2,0.1l-0.2,0.2l-0.3,0.3l-0.3,0.2l-0.1,0.2l-0.2,0.3h-0.2
                v-0.2l0.1-0.3l-0.1-0.2l-0.2-0.1h-0.1h-0.1l-0.3,0.1l-0.3,0.2l-0.2,0.1l-0.2,0.1l-0.4,0.1h-0.3h-0.1l-0.2-0.2l-0.5-0.5l-0.7-0.6
                l-0.1-0.2v-0.2l-0.1-0.3l-0.1-0.3l-0.1-0.1v-0.2l-0.2-0.4l-0.1-0.3l-0.1-0.2v-0.1v-0.1l-0.1-0.1l-0.1-0.4V456v-0.2l-0.1-0.1v0.2
                l-0.2-0.2l-0.3-0.2l-0.1-0.1h-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.3-0.1l-0.3,0.1v-0.2l0.1-0.1l0.9-0.8l0.5-0.4v-0.1v-0.1v-0.1l-0.1-0.1
                l-0.4-0.6l-0.1-0.1l-0.1-0.1l-0.2-0.3l-0.2-0.6l-0.1-0.3V451v-0.3l-0.1-0.2l-0.1-0.1V450l0.1-0.7v-0.6l-0.1-0.4l0.1-0.3l0.3-0.2
                l0.1-0.3v-0.4l0.2-0.3l0.3-0.3l0.1-0.3l-0.1-0.3v-0.2l-0.2-0.2l-0.4-0.1h-0.4l-0.2,0.1l-0.6,0.1l-0.9,0.1h-0.8l-0.6-0.1h-0.4
                l-0.3,0.2h-0.2l-0.1-0.1h-0.1l-0.2,0.1l0,0l-0.4-0.5l-0.4-0.5l-0.5-0.6l-0.5-0.7h-0.1h-0.2H278l-0.3-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1
                h-0.2h-0.2l-0.6,0.2h-0.3h-0.2l-0.7-0.1h-0.5l-0.5,0.1h-0.2l-0.3-0.1l-0.2-0.2l-0.2-0.4l-0.1-0.3l-0.2-0.1l-0.3-0.1l-0.1-0.1
                l-0.1-0.2v-0.2v-0.4v-0.1v-0.4l0.2-0.2l0.1-0.1v-0.2l-0.1-0.4l-0.1-0.3l-0.3-0.3l-0.4-0.4l-0.2-0.6l-0.2-0.7l-0.2-0.1h-0.1h-0.2
                l-0.4-0.5l-0.1-0.1l-0.2,0.1l-0.2,0.1l-0.4,0.1l-0.1-0.1v-0.1l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.1-0.7l0.1-0.5l0.2-0.8
                l0.4-0.7l0.1-0.3l0.3-0.4l0.2-0.2l0.2-0.2l0.6-0.2l0.7-1.2l0.2-0.2l0.7-0.2l0.5-0.1l0.4-0.1l0.1-0.1h0.1l-0.1,0.1l-0.2,0.2l-0.2,0.1
                l-1.1,0.3l-0.1,0.1l-0.1,0.1v0.2v0.2l0.3,0.6l0.1,0.2l0.4,0.3H274h-0.2l0.1,0.5l0.3,0.3v0.2l-0.2,0.6l-0.4,0.4l-0.3,0.4l-0.2,0.2
                l-0.4,0.8l0.3,0.5v0.2l0.3,0.4l0.2,0.1l0.1,0.1l-0.1,0.2l0.1,0.3l0.1,0.2l0.2,0.1h0.2l0.7-0.2l0.2-0.1l0.1-0.2l0.3-0.4V436l0.1-0.6
                l-0.1-0.4l-0.4-0.5l-0.2-0.4l-0.3-0.3l-0.2-0.6l-0.1-0.2l-0.1-0.3l-0.1-0.4l0.2-0.2v-0.4l0.6-0.1l1.2-0.6l0.8-0.1l0.9-0.3l0.2-0.2
                l0.2-0.3h0.1l0.5,0.2l0.2-0.1l0.1-0.2l-0.1-0.4h-0.3l-0.8,0.1l-0.1-0.2v0.4l-0.2-0.4l0.1-0.3l0.1-0.3l0.2-0.1l0.3-0.1l0.3,0.2
                l0.2,0.2l0.1,0.2l0.1,0.5l0.1,0.5l0.1,0.3l0.2,0.2h0.2h0.1l0.8-0.1l0.5,0.2l0.6,0.1l0.6,0.3l0.6,0.4l0.2,0.3l0.1,0.3l0.1,0.2
                l-0.1,0.2l0.1,0.3l0.2,0.3l0.3,0.2l0.8,0.1l0.8-0.1l1.3-0.1l0.4-0.1l2.1-0.1l0.4,0.2v0.2v0.1l0.7,0.6l0.5,0.1l0.5,0.2l0.5,0.1
                l0.5,0.1h0.3l0.2-0.1h0.3l1.9-1h1l0.2-0.1l0.1-0.1l-0.4-0.2l-0.8-0.1l-0.3,0.1l-0.1-0.3h0.3l0.9-0.1l1.1,0.1l0.9-0.2h0.4h0.2
                l0.7-0.1l1.3,0.1l1-0.1l-0.1,0.2l-0.3,0.1h-0.5l-0.4,0.2h-0.9l-0.6,0.1l0.2,0.1v0.3l0.1,0.1h0.1l0.2,0.2l0.1,0.1l0.1,0.3l-0.1,0.3
                l-0.1,0.1h0.3l0.1-0.1v-0.1v-0.2h0.1l0.1,0.1l0.3,0.7l0.2,0.4h0.1h0.1l0.1-0.1l0.1-0.2l0.1,0.1h0.1h0.1v-0.2l0.1-0.2v-0.1h0.1h0.1
                l0.2,0.1l0.3,0.2l0.2,0.2v0.1l0.1,0.1l0.1,0.1l0.1,0.1v-0.2l-0.1-0.1v-0.2h0.4l0.1-0.2l0.2,0.1l0.6,0.6l0.2,0.1l0.6,0.1l0.4,0.3
                l0.2,0.3l-0.1,0.3l-0.4,0.1l-0.1,0.2l-0.1,0.2v0.2l-0.1,0.2v0.1l-0.1,0.3l-0.1,0.3l-0.2,0.4h-1l0.3,0.1l0.2,0.1l0.4,0.3l0.3-0.2h0.4
                l0.5-0.2h0.2l0.9,0.1l0.2-0.2l0.2-0.1L306.7,438.9L306.7,438.9L306.7,438.9z M295.9,431.6l0.1,0.4v0.1l-0.3,0.2h-0.2h-0.2h-0.1
                l-0.2-0.2h-0.2l-0.4-0.1l-0.1-0.1l0.2-0.2l0.3-0.1h0.1l0.1,0.1l0.2,0.1h0.2l0.1-0.2l0.3-0.3L295.9,431.6L295.9,431.6z M292.1,432.2
                l-0.2,0.1l-0.3-0.1l-0.1-0.1l0.1-0.1h0.2L292.1,432.2L292.1,432.2z M304.4,437.2l-0.3,0.1v-0.1l0.1-0.1h0.2h0.1h0.1h0.1h0.1
                l-0.2,0.1H304.4L304.4,437.2z M303.9,438l-0.2,0.1v-0.3l0.1-0.1l0.3-0.2h0.1h0.1v0.1l0.1,0.1v0.1l-0.2,0.1L303.9,438L303.9,438z"/>
            <linearGradient id="VG_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="255.7579" x2="0.1864" y2="255.7579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VG" class="stcustom0 st245" d="M294.7,409.6L294.7,409.6L294.7,409.6h-0.1h-0.1h-0.1l0,0H294.7L294.7,409.6z M294.4,410.4
                L294.4,410.4h-0.1l0,0v-0.1h0.3L294.4,410.4z M293.8,410.6h-0.2h-0.1l0.1-0.1h0.2h0.1L293.8,410.6z"/>
            <linearGradient id="VI_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="254.2579" x2="0.1864" y2="254.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VI" class="stcustom0 st246" d="M293.1,410.8h-0.2l-0.3-0.1l0.2-0.1h0.1L293.1,410.8L293.1,410.8z M293.6,410.7l-0.2,0.1h-0.1l0,0
                l0.1-0.1H293.6z M293.3,412.4l0.2,0.1h0.3l-0.3,0.1h-0.6v-0.2H293.3z"/>
            <linearGradient id="VN_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="248.3578" x2="0.1864" y2="248.3578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VN" class="stcustom0 st247" d="M778.1,401.4L778.1,401.4h-0.4l-0.3,0.3l-0.2,0.1l-0.3,0.1l-0.3,0.2l-0.1,0.3v0.2l-0.1,0.2l-0.5,0.3
                h-0.1l-0.1-0.1h-0.2l-0.1,0.1h-0.1l-0.1,0.1H775l-0.2-0.1h-0.1h-0.1v0.1l0.2,0.5v0.2l-0.6,0.6l0.1,0.4l-0.2,0.3l-0.3,0.2l-0.6,0.6
                h-0.3l-0.2,0.1l-0.5,1v0.4l-0.1,0.3v0.2l-0.2,0.5l-0.2,0.2v0.3l0.3,0.6v0.1l0.2,0.3l0.1,0.2l0.1,0.2l0.5,0.5l0.2,0.2l0.3,0.1
                l0.5,0.5l0.2,0.3l-0.1,0.2l0.1,0.5l-0.3-0.1v0.1l0.4,0.2l0.6,0.9l0.5,0.4l0.5,0.5l0.2,0.5l0.5,0.3l0.5,0.4v0.1l0.1,0.1l0.4,0.2
                l0.2,0.2l0.1,0.2h0.1l0.2-0.1h0.2h0.1l0.2,0.3l0.2,0.2l0.1,0.2h0.1h0.1v0.2v0.1l0.3,0.3l0.1,0.3l0.4,0.5l0.3,0.3l0.2,0.2l0.2,0.1
                l0.2,0.6l0.1,0.5l0.2,0.6l0.2,0.2v0.5l0.1,0.5l0.2,0.3v0.3v0.2l0.1,0.1l0.2,0.6v0.3l-0.1-0.3v0.8l0.1,0.4v0.5l0.1,0.2l0.2,0.6
                l0.1,0.2v0.7l0.1,0.3l-0.2-0.2l-0.1-0.2l-0.2,0.1l-0.2,0.2l0.2,0.7l-0.3-0.1v1l0.1,0.2v0.1v0.1l-0.1-0.1v-0.1l0,0v0.1l-0.1,0.2v0.2
                l0.1,0.2v0.1l-0.1,0.2l-0.1,0.2h-0.3l-0.1,0.4l-0.1,0.4l-0.5,0.1l-0.3,0.3l-0.4,0.1l-0.4,0.3l-0.4,0.3h-0.3l-0.2,0.1l-0.3,0.5
                l-0.4,0.1l-0.8,0.4l-0.3,0.2l-0.2,0.1l-0.3,0.2l-0.1-0.1l-0.1-0.1l-0.3-0.1l-0.1-0.2v-0.2V433l-0.1,0.1v0.5l-0.1,0.1h-0.1l-0.3-0.1
                l-0.2-0.3l-0.3,0.2h0.1h0.2h0.1l0.1,0.2v0.1l-0.1,0.1h-0.3h-0.4H774l0.4,0.2l0.4,0.1l0.2,0.1v0.1l-0.2,0.2l-0.2,0.2v0.1v0.1
                l-0.2,0.1h-0.1l-0.3-0.2l-0.9-0.8l0.1,0.2l0.9,0.9l0.2,0.3v0.2l-0.1,0.1l-0.2,0.1h-0.3l-0.5-0.3l-0.8-0.8l-0.3-0.1l0.8,0.9l0.1,0.2
                l0.1,0.3v0.2l-0.1,0.1l-1.9,0.9l-0.3,0.4l-0.2,0.5l-0.4,0.3l-0.2,0.2l-0.6,0.1h-0.3l0.4-0.4l-0.2-0.2v-1.1l0.1-1.2l0.2-0.6l0.2-0.1
                l0.3-0.1V435v-0.1l-0.2-0.2l-0.2-0.1h-0.3l-0.2-0.2H769l-0.2,0.1l-0.1-0.1l-0.1-0.2l-0.2-0.2l-0.3-0.2h0.1l0.1-0.1l0.1-0.1h0.4h0.4
                h0.1l0.1-0.2l0.2-0.2l0.2-0.1V433l-0.1-0.2v-0.2l0.1-0.1h0.3l0.4,0.1h0.1l0.2-0.3h0.1h0.1h0.3l0.3-0.1h0.2l0.2,0.2l0.1,0.2h0.1
                l0.2-0.1l0.1,0.1l0.3,0.2h0.2l-0.1-0.4l0.1-0.3V432l-0.2-0.1l-0.6-0.5l-0.1-0.1v-0.2v-0.3l-0.1-0.2v-0.1v-0.1h0.1h0.1l0.1-0.1
                l0.1-0.2h0.3l0.4,0.1l0.3,0.1h0.2l0,0v-0.1v-0.5v-0.1h0.2h0.4h0.2l0.2-0.2l0.5-0.1l0.3-0.3l0.3-0.3l0.1-0.1h0.2h0.1l0.2,0.2l0.1-0.1
                l0.2-0.2l0.1-0.2V428v-0.5l-0.1-0.4l-0.1-0.3v-0.3l0.2-0.6l0.2-0.6v-0.2l-0.2-0.4l-0.2-0.5l-0.2-0.5l-0.1-0.1l-0.1-0.1v-0.2l0.1-0.5
                v-0.2l0.2-0.2l0.1-0.3l0.1-0.3v-0.2v-0.3v-0.2l-0.1-0.1l-0.1-0.2v-0.1l0.2-0.1l0.1-0.2l0.1-0.2l0.1-0.2l-0.1-0.2l-0.2-0.2l-0.3-0.2
                l-0.3-0.3l-0.2-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l0.1-0.1l0.5-0.2l0.1-0.1l0.1-0.1v-0.1l-0.1-0.1H776l-0.2-0.2l-0.4-0.4l-0.2-0.1
                l-0.2-0.1l-0.1-0.1l-0.1-0.3h-0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1v-0.5v-0.2l-0.1-0.2l-0.1-0.1
                l-0.1-0.1l-0.3-0.4l-0.2-0.2l-0.7-0.6l-0.1-0.1l-0.2-0.2l-0.3-0.3l-0.2-0.3l-0.2-0.3l-0.1-0.2v-0.2l-0.2-0.3l-0.2-0.2l-0.2-0.1h-0.2
                l-0.2-0.1l-0.3-0.3L770,411l-0.1-0.1v-0.1l0.1-0.2l0.1-0.1v-0.1l-0.1-0.1l-0.3-0.1l-0.8-0.2l-0.3-0.2l-0.3-0.2l-0.1-0.7l-0.9-0.6
                l-0.3-0.1l-0.2-0.1l-0.1-0.1v-0.1l0.1-0.1l0.3-0.2l0.1-0.2v-0.2l-0.1-0.2l0.1-0.1h0.1h0.2h0.4l0.8,0.2h0.1l0.4-0.4l0.2-0.2v-0.2
                l0.1-0.1l0.2-0.2v-0.2l-0.1-0.3l-0.1-0.1h-0.1h-0.3l-0.1-0.1v-0.2V405l-0.1-0.1l-0.3-0.1h-0.3h-0.1l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1
                v-0.1l-0.1-0.1l-0.2-0.1l-0.3-0.3l-0.4-0.3l-0.3-0.1h-0.1l-0.5,0.2l-0.3,0.2l-0.2,0.3l-0.2,0.1l-0.2-0.1l-0.3-0.1l-0.7-0.2l-0.3-0.2
                l-0.6-0.9l-0.1-0.2l0.1-0.2V402l0.1-0.2l0.1-0.2v-0.2v-0.2l-0.1-0.1h-0.1H764l-0.1-0.2l0,0l-0.1,0.3l-0.1,0.1h-0.1h-0.1l-0.1-0.1
                V401l-0.1-0.2l-0.1-0.2l-0.3-0.2l-0.1-0.2l-0.4-0.5l-0.3-0.3l-0.2-0.3l0.1-0.1l0.2-0.2l0.2-0.2l0.2-0.3l0.1-0.2l0.1-0.1l0.1-0.1h0.1
                l0.2,0.1l0.3,0.2l0.3,0.2l0.1,0.2l0.2,0.2l0.1,0.1h0.1l0.2-0.1l0.2-0.1v-0.2l0.2-0.1l0.2-0.2l0.1-0.2h0.1h0.1l0.3,0.5h0.1l0.1-0.1
                l0.1-0.4l0.1-0.1l0,0l0.8,0.7h0.1h0.1l0.1-0.1l0.1-0.2l0.1-0.3l0.2-0.1h0.2l0.1,0.1l0.2,0.2h0.2l0.4-0.3h0.1h0.2h0.2l0.1-0.1
                l0.2-0.2l0.1-0.3V397l0.1-0.1l0.1-0.1l0.2-0.1l0.5-0.3l0.1-0.1l0.1-0.1l0.2,0.1l0.3,0.2l0.2,0.2l0.1,0.2v0.1l0.4,0.1l0.3,0.2
                l0.2,0.1h0.2h0.2l0.1-0.1h0.2h0.2h0.1l0.2,0.3h0.1h0.2l0.3-0.1h0.3l0.2,0.1l0.4,0.3l-0.1,0.2l-0.1,0.2l-0.2,0.2h-0.1l-0.1,0.2v0.3
                v0.2l0.1,0.1l0.1,0.1v0.1v0.3v0.4v0.1h0.1h0.1l0.2,0.1l0.2,0.1l0.1,0.1h0.1l0.1,0.1v0.2l0.1,0.1l0.3,0.2h0.3l0.2,0.3l0.2-0.1
                l0.1,0.1h0.5l0.3-0.1h0.1l0.3,0.3L778.1,401.4L778.1,401.4z M777.1,402.3l-0.4,0.4h-0.2l0.1-0.4l0.1-0.1l0.2,0.1H777.1L777.1,402.3z
                M776.9,403.1l-0.2,0.1h-0.2l0.2-0.1l0.1-0.2l0.1-0.1v0.2L776.9,403.1L776.9,403.1z M775,403.5L775,403.5l-0.2-0.1l-0.1-0.1l0.1-0.2
                l0.2,0.2V403.5L775,403.5z M775.5,403.7L775.5,403.7l-0.3-0.2l0.1-0.1l0.3,0.1l0.1,0.1v0.1H775.5L775.5,403.7L775.5,403.7z
                M775.9,433.7l-0.2,0.2v-0.1L775.9,433.7v-0.1h0.1L775.9,433.7z M767.1,433.7l0.1,0.1v0.3l-0.1,0.3v0.1l-0.1,0.1l-0.2-0.6l-0.2-0.3
                l-0.1-0.1h0.1l0.2-0.2h0.1L767.1,433.7L767.1,433.7z M774.3,438.5L774.3,438.5l-0.1-0.1l0.3-0.2v0.1L774.3,438.5L774.3,438.5z"/>
            <linearGradient id="VU_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="154.8578" x2="0.1864" y2="154.8578" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="VU" class="stcustom0 st248" d="M945.2,502L945.2,502L945.2,502l-0.3-0.3v-0.1l0.2-0.1l0.2,0.2v0.1v0.1v0.1L945.2,502L945.2,502
                L945.2,502z M945.4,503l-0.1,0.1H945h-0.1v-0.2l0.1-0.1l0.2-0.1l0.3,0.1L945.4,503L945.4,503z M943.1,504.6l0.2,1h0.2l0.1-0.1
                l0.1-0.2l0.1-0.4h0.1h0.1l-0.1,0.1v0.3l0.1,0.2h0.1l0.1,0.7l0.1,0.2v0.1l-0.3,0.3h-0.4l-0.3,0.2h-0.2v-0.2l-0.2-0.1l-0.2-0.3v-0.6
                l-0.3-1v-0.3l0.1-0.3h0.1l0.2,0.3L943.1,504.6L943.1,504.6z M947.1,506.1v0.2V506.1l-0.2-0.9v-0.1h0.1l0.1,0.6L947.1,506.1
                L947.1,506.1z M946.3,506.4l-0.2,0.1h-0.3l-0.1-0.1l0.4-0.4l0.5-0.1L946.3,506.4L946.3,506.4z M947.2,507.9L947.2,507.9l-0.1-0.1
                l-0.2-0.7l0.1-0.6l0.1,0.1l0.2,1.1L947.2,507.9L947.2,507.9L947.2,507.9z M944.4,507.2L944.4,507.2l-0.3-0.1l0.1-0.2l0.3,0.1
                L944.4,507.2L944.4,507.2z M944.9,508.3l0.1,0.1h0.1v0.1l0.4,0.3h0.1l0.1,0.2l0.2,0.1v0.2l0.1,0.2l-0.2,0.2l-0.4-0.1l-0.2,0.2
                l-0.2-0.1v-0.1l0,0l-0.1-0.3l-0.1-0.5l-0.1-0.3l-0.1-0.1l-0.2,0.1h-0.1l-0.2-0.2l0.1-0.4v-0.1h0.2l0.2,0.1L944.9,508.3L944.9,508.3z
                M947.4,509h-0.3l-0.5-0.1l-0.2-0.1l-0.1-0.1l0.2-0.1h0.2l0.3-0.3l0.1,0.1l0.1,0.3l0.1,0.1l0.1,0.1L947.4,509L947.4,509z
                M947.8,510.3L947.8,510.3v0.2l-0.4-0.1l-0.3,0.1H947l-0.1-0.1l-0.1-0.2V510l0.1-0.1l0,0l0.1,0.1l0.1,0.1h0.1l0.2,0.2H947.8
                L947.8,510.3z M947.8,512.5l0.3,0.4h0.1l-0.2,0.3h-0.4l-0.4-0.1l0.2-0.1l-0.1-0.1h-0.1l-0.1,0.1H947l0.1-0.2l0.2-0.3h0.1h0.1h0.1
                L947.8,512.5L947.8,512.5z M950.3,516.6L950.3,516.6l-0.2,0.1l-0.7-0.3v-0.1V516l0.1-0.2l0.2-0.1h0.2l0.1,0.3l0.2,0.1l-0.2,0.1
                l0.3,0.2L950.3,516.6L950.3,516.6z M950.8,518.4l-0.1,0.3l-0.3-0.1l-0.2-0.2l-0.1-0.2l0.1-0.4l0.1-0.1h0.1l0.1,0.4L950.8,518.4
                L950.8,518.4z M951.9,520.3l-0.1,0.2h-0.2l-0.2-0.1v-0.1h0.2h0.1L951.9,520.3L951.9,520.3z"/>
            <linearGradient id="WF_1_" gradientUnits="userSpaceOnUse" x1="1003.2042" y1="164.3079" x2="0.1862" y2="164.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="WF" class="stcustom0 st249" d="M991,500.3L991,500.3L991,500.3V500l0.1-0.1l0.1,0.1L991,500.3z M985.7,503.2h-0.2h-0.2l-0.1-0.2
                v-0.1h0.1l0.1,0.1L985.7,503.2L985.7,503.2L985.7,503.2z"/>
            <linearGradient id="WS_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="164.3079" x2="0.1864" y2="164.3079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="WS" class="stcustom0 st250" d="M1001.8,500.7l0.3,0.3l0.1,0.4l-0.1,0.3l-0.3-0.1l-0.4,0.1h-0.1l-0.3-0.4l-0.2-0.2l-0.1-0.2h0.3
                l0.4-0.1L1001.8,500.7L1001.8,500.7z M1004.2,502.4h-0.8l-0.4-0.1h-0.1l-0.3-0.3v-0.1l0.2-0.1h0.4l0.7,0.2l0.1,0.2h0.2l0.1,0.1
                L1004.2,502.4L1004.2,502.4L1004.2,502.4z"/>
            <linearGradient id="YE_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="247.2579" x2="0.1864" y2="247.2579" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="YE" class="stcustom0 st251" d="M624.1,415.7l-1.4,0.5l-0.4,0.2l-0.3,0.3l-0.3,0.4l-0.2,0.6l0.1,0.6v0.3l-0.4,0.2l-0.3,0.1l-0.4,0.2
                l-0.2,0.1l-0.2,0.2l-0.2,0.1l-0.8,0.3l-0.9,0.2l-1.4,0.3l-0.5,0.3l-0.5,0.2l-0.7,0.1l-1,0.3l-0.6,0.2l-0.7,0.4l-0.2,0.1l-0.1,0.3
                l-0.2,0.3l-0.4,0.4l-0.3,0.2h-0.2l-0.4,0.1h-0.5l-0.8-0.1l-0.2,0.1l-0.2,0.2l-0.6,0.3l-0.6,0.6l-0.5,0.1l-0.7,0.2l-0.5,0.2l-0.4,0.1
                h-0.5h-0.8l-0.8,0.1l-0.7,0.2l-0.3,0.3l-0.4,0.5l-0.6,0.2l-0.2,0.2l-0.2,0.4l-0.4,0.1l-0.4,0.1l-0.4-0.2l-0.7,0.4l-0.3,0.1h-0.4
                l-0.3,0.1h-0.2l-0.3-0.2l-0.6-0.2l-0.4,0.1v-0.4l-0.7-1.2l0.1-1.1v-0.2l-0.1-0.5l-0.4-0.4v-0.6l-0.1-0.4l-0.1-0.4v-0.1v-0.1
                l-0.2-0.6l-0.1-0.1v-0.1l0.1-0.1v-0.1l-0.1-0.2l-0.1-0.4l-0.6-0.3l0.1-0.3l0.1,0.1l0.1,0.1v-0.2v-0.1l-0.2-0.8l0.3-1.1l-0.1-1
                l0.5-0.4l0.1-0.1l0.1-0.1l0.1-0.2l0.2-0.1l0.1-0.2v-0.1l-0.1-0.1l-0.1-0.3v-0.4v-0.1l0.1-0.3l0.2-0.1v-0.1l-0.1-0.2v-0.1l0.3-0.3
                l0.1-0.1l0.2-0.1h0.2h0.2l0.2,0.1l0.2,0.1l0.2,0.2l0.3,0.1h0.2l0.1,0.1h0.1l0.1-0.1h0.2l0.2-0.1h0.6h0.5l0.6-0.1h0.6h0.6h0.1h0.1
                l0.5,0.3l0.4,0.1l0.7,0.1l0.8,0.1l0.7,0.1l0.6-0.1h0.5h0.1l0.1,0.2l0.3,0.4l0.3,0.4h0.5l0.3-0.1l0.3-0.2l0.2-0.1l0.2-0.6l0.2-0.4
                l0.3-0.4l0.3-0.4l0.4-0.5l0.2-0.3l0.4-0.5l0.4-0.2l0.8-0.4l0.8-0.4l0.5-0.3l0.4-0.1l0.7-0.1l0.8-0.1l0.8-0.1l0.9-0.1l1-0.1l0.7-0.1
                l0.9-0.1l0.7-0.1l0.6-0.1l0.7-0.1l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3
                l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.2,0.1l0.1,0.3l0.2,0.4l0.2,0.4l0.2,0.4L624.1,415.7L624.1,415.7z M594.6,419.6
                L594.6,419.6L594.6,419.6v-0.3l0.1-0.1l0.1,0.2L594.6,419.6L594.6,419.6z M595.2,423.5L595.2,423.5L595.2,423.5l-0.3-0.1l0.2-0.2
                l0.1,0.2V423.5L595.2,423.5z M595.1,424.2l-0.2,0.1l0.1-0.2h0.1l0,0L595.1,424.2L595.1,424.2z M626,427.3h0.2l0.3-0.1h0.8l0.9,0.3
                l-0.2,0.1l-0.1,0.1l-0.4,0.1l-0.4,0.2l-1.2,0.1l-0.3-0.1l-0.4-0.1l-0.5-0.3l0.2-0.2v-0.1l0.1-0.1l0.3-0.1h0.3L626,427.3L626,427.3z"
                />
            <linearGradient id="YT_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="167.0079" x2="0.1864" y2="167.0079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="YT" class="stcustom0 st252" d="M601.9,499.3h-0.2l-0.1-0.1l-0.1-0.2l0.1-0.2v-0.1l-0.1-0.2l0.1-0.1l0.1,0.2h0.1l0.2,0.1l-0.1,0.2
                v0.1l-0.1,0.2L601.9,499.3z"/>
            <linearGradient id="ZA_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="97.2577" x2="0.1864" y2="97.2577" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ZA" class="stcustom0 st253" d="M562.9,526.9v0.2v0.1l0.1,0.4l0.2,0.6l0.1,0.6l0.2,0.8v0.4v0.2l0.2,0.2l0.2,0.4l0.1,0.2l0.1,0.2
                l0.2,0.3l0.2,0.5l0.1,0.6l0.1,0.3v0.1l0.1,0.3v0.5v0.6v0.7v0.6v0.3v0.8l-0.2,0.4v0.3l0.1,0.2h-0.1h-0.1l-0.6-0.4l-0.6-0.4h-0.1h-0.1
                l-0.4,0.3l-0.3,0.4l-0.2,0.4l-0.2,0.4l-0.4,0.6v0.1v0.5v0.5v0.1h0.2l0.2,0.4l0.4,0.6l0.6,0.4l0.5,0.2h0.8h0.6v-0.4l0.1-0.7l0.1-0.4
                h0.1h0.2h0.1h0.2l0.4,0.1h0.3h0.3h0.5h0.3l-0.1,0.7l-0.4,1.1l-0.1,0.5l-0.3,1.9l-0.4,0.9l-0.3,0.4l-0.7,0.7l-0.2,0.1l-0.2,0.1
                l-0.3,0.1l-1.2,1.4l-0.5,0.7l-0.4,1l-0.4,0.5l-0.6,1.2l-0.5,0.9l-0.5,0.8l-1.2,1.5l-0.4,0.3l-0.3,0.2l-0.7,0.7l-1,1.1l-0.8,1
                l-1.1,1.1l-0.7,0.5l-1,0.9l-0.3,0.1l-1.1,0.9l-0.8,0.5l-1.3,0.6l-0.5,0.2l-1.2-0.2l-0.5,0.1l-0.4,0.4v0.5l-0.2,0.1h-0.3l-0.9-0.2
                h-0.5l-0.3,0.3l-0.2,0.4H544l-1.2-0.4l-1.4-0.2h-0.3l-0.7,0.3h-0.2l-1-0.1l-0.5-0.2h-0.5l-0.4,0.1l-0.5,0.1l-1.3,1h-0.7l-0.6,0.1
                h-0.3l-0.5-0.1h-0.2l-0.3,0.1l-0.3,0.2l-0.7,0.1l-0.3,0.2l-1.2,0.9h-0.3l-0.2-0.1h-0.6l-0.7-0.5h-0.3l0.1-0.2v-0.3l-0.1-0.2
                l-0.1-0.1h-0.3l-0.1-0.2h-0.4l-0.1,0.1h-0.2v-0.2v-0.1v-0.2v-0.3l-0.2-0.1h-0.1H527h-0.2l-0.1,0.1l-0.1,0.2v0.6l-0.1-0.2l-0.2-0.4
                l-0.1-0.4l0.1-0.5l0.3-0.2V563l-0.1-0.3l-0.3-0.7l-0.1-0.3l-0.3-0.2l-0.2-0.5l-0.2-0.2l-0.1-0.4l-0.2-0.3l0.1-0.2l0.1-0.3l0.2-0.1
                l0.2,0.2l0.2-0.1l0.4-0.3l0.2-0.5V558l-0.1-0.5l-0.3-1.2L526,556l-0.6-0.9l-0.7-1.2l-0.9-1.9l-0.4-1.1l-0.7-2.2l-0.6-1.3l-0.7-1.2
                l-0.1-0.1l0.1-0.1l0.4-0.3l0.2-0.1h0.1l0.1-0.1l0.1-0.2v-0.2v-0.2l0.1-0.1l0.1-0.3l0.2-0.2l0.3-0.1l0.3,0.2l0.1,0.2v0.2l0.1,0.1h0.2
                l0.1,0.1l0.1,0.3v0.2l-0.1,0.1v0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.5,0.1l0.2,0.1h0.4l0.4,0.1l0.4,0.2h0.6l0.8-0.1h0.7l0.5,0.2h0.4
                l0.2-0.1l0.1-0.2v-0.2l0.1-0.1l0.3-0.1l0.2-0.2l0.2-0.3l0.4-0.2l0.6-0.2h0.3V545v-1.4v-1.4v-1.4v-1.4V538v-1.4v-1.4v-1.3l0.1,0.1
                l0.9,0.7l0.2,0.4l0.1,0.2l0.4,0.8l0.3,0.7l0.2,0.6v0.3v0.3v0.1v0.1l-0.2,0.3l-0.2,0.2L533,539v0.4l0.1,0.5l0.1,0.3l0.2,0.1l0.4-0.1
                h0.2l0.3,0.1l1.1-0.1h0.1h0.4h0.1l0.1-0.1l0.1-0.3l0.1-0.1l0.2-0.1l0.3-0.1l0.2-0.2l0.4-0.6l0.7-0.5l0.2-0.1l0.1-0.1l0.1-0.2
                l0.2-0.7l0.2-0.6l0.1-0.3l0.2-0.4l0.2-0.3l0.2-0.1h0.1l0.3-0.1l0.3-0.1l0.3,0.1l0.4,0.2l0.4,0.3l0.4,0.3l0.2,0.2l0.2,0.1h0.4h0.2
                l0.4,0.3h0.2l0.4,0.1l0.5,0.1h0.3l0.4-0.2h0.3h0.3h0.4l0.3-0.1l0.2-0.2l0.2-0.2l0.2-0.5l0.1-0.4l0.2-0.5l0.2-0.7l0.1-0.5l0.1-0.1
                l0.3-0.1l0.3-0.1l0.7-0.2l0.2-0.1l0.1-0.2l0.3-0.4l0.4-0.3l0.2-0.2l0.4-1.5v-0.2l0.3-0.4l0.2-0.2h0.1l0.2-0.1l0.2-0.2l0.2-0.1h0.3
                l0.2-0.1l0.1-0.2l0.1-0.1h0.2l0.1-0.1v-0.1l0.1-0.1l0.2-0.1l0.1-0.1v-0.1l0.3-0.3l0.5-0.5l0.5-0.3l0.5-0.1l0.4-0.1l0.4-0.2l0.3-0.3
                l0.2-0.4l0.3-0.2l0.7-0.1l0,0l0.8-0.1l0.7,0.1l0.8,0.3l0.8,0.1l0.7-0.1h0.6l0.4,0.1l0.3,0.1L562.9,526.9L562.9,526.9z M555.7,550.9
                l0.5-0.2l0.2-0.1l0.2-0.1l0.2-0.2l0.1-0.4l0.1-0.3l0.1-0.2l0.2-0.1l0.1-0.2l0.2-0.4l0.1-0.4v-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2h-0.1
                l-0.2-0.1l-0.3-0.3l-0.3-0.2l-0.3-0.3l-0.1-0.1l-0.3-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1h-0.1l-0.3,0.1l-0.7,0.3l-0.4,0.2l-0.3,0.3
                l-0.4,0.1L553,547l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.2,0.3l-0.2,0.2l-0.2,0.1l-0.3,0.1l-0.1,0.1v0.1
                l0.1,0.3l0.1,0.3l0.2,0.3l0.1,0.2l0.2,0.3l0.1,0.2v0.3v0.1l0.1,0.1h0.1h0.1l0.2,0.1v0.1l0.1,0.1l0.1,0.2l0.2,0.2l0.2,0.2l0.4,0.1
                l0.3,0.1h0.1l0.1-0.2l0.1-0.2v-0.2l0.1-0.1l0.4-0.6l0.2-0.2h0.1h0.2h0.2h0.2l0,0L555.7,550.9L555.7,550.9z M581.3,611.6L581.3,611.6
                h-0.7l-0.1-0.2l0.2-0.2l0.1-0.1l0.3,0.1l0.2,0.2v0.1V611.6L581.3,611.6z"/>
            <linearGradient id="ZM_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="166.2077" x2="0.1864" y2="166.2077" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ZM" class="stcustom0 st254" d="M567.5,489.2L567.5,489.2l0.1,0.2l0.1,0.1v0.1v0.1h0.1h0.1l0.1-0.1h0.1l0.1,0.1v0.2l0.1,0.2l0.2,0.1
                l0.1,0.1v0.3l-0.1,0.2l0.2,0.2l0.3,0.2l0.1,0.1v0.3v0.1l0.2,0.3l0.1,0.2v0.1l-0.5,0.5l-0.2,0.1h-0.2l-0.1,0.1l-0.1,0.1v0.1l0.1,0.2
                l0.1,0.3l0.1,0.2l-0.1,0.3l-0.2,0.4h-0.1v0.3l0.1,0.1l0.1,0.1v0.2v0.3v0.3l-0.1,0.6l0.2,0.6l0.1,0.1h0.3h0.1l-0.1,0.2l-0.1,0.2
                l-0.1,0.1l-0.4,0.2l-0.6,0.2l-0.1,0.2l-0.1,0.3l0.1,0.2l0.1,0.1v0.3l-0.1,0.3v0.2v0.2l-0.1,0.1l-0.1,0.3l-0.1,0.3l-0.1,0.1l-0.2,0.1
                l-0.2,0.1v0.1l0.3,0.1l0.1,0.1v0.1l-0.1,0.1l-0.1,0.1l0.1,0.1l0.2,0.1l0.1,0.2l0.1,0.3v0.1l0,0l0,0h0.1l0.2-0.1l0.1-0.1l0.2,0.2
                l-0.6,0.2l-0.3,0.1l-0.9,0.3l-0.8,0.3l-0.2,0.1l-0.4,0.1l-0.2,0.1l-0.7,0.2l-0.3,0.1l-0.2,0.1l-0.6,0.2l-0.6,0.2l-0.6,0.2l-0.7,0.2
                l-0.4,0.1l-0.3,0.1l-0.6,0.2v0.1v0.2l0.1,0.3l0.2,0.3l0.1,0.2l0.1,0.5v0.4h-0.4h-0.7h-0.7l-0.7,0.2l-0.6,0.2l-0.7,0.4l-0.2,0.1
                l-0.2,0.1l-0.1,0.1l-0.1,0.3v0.5l-0.1,0.3l-0.2,0.3l0,0l-1,0.4l-0.7,0.3l-0.7,0.4l-0.5,0.5l-0.3,0.6l-0.6,0.7l-0.6,0.6l-0.6,0.7
                l-0.7,0.2l-0.6-0.1l-0.7-0.3l-0.5-0.1l-0.4,0.2l-0.4-0.1l-0.3-0.3l-0.3-0.1l-0.2,0.1h-0.3l-0.5-0.2l-0.5-0.5l-0.3-0.2l-0.2-0.1
                l-0.6-0.1l-1.3-0.1h-0.1l-0.5,0.1l-0.7,0.1l-0.6,0.1l-0.6,0.1l-0.6-0.5l-0.6-0.6l-0.7-0.6l-0.5-0.5l-0.2-0.3l-0.4-0.4l-0.3-0.2
                l-0.1-0.1l-0.3-1l-0.2-0.9v-0.7v-0.9v-0.9V504v-0.9v-0.9v-0.9v-0.9v-0.9v-0.4h0.6h0.7h0.8h0.8h0.8h0.8h0.6h0.2h0.2V499l-0.2-0.5
                v-0.2l0.1-0.3l0.1-0.3l0.1-0.3v-0.2l-0.1-0.7v-0.4v-0.4v-0.4V495v-0.1l0.1-0.2v-0.2v-0.1v-0.1v-0.2v-0.4l-0.1-0.5l-0.1-0.4h0.1h0.2
                l0.1,0.2l0.1,0.2h0.1l0.4,0.1l0.1,0.2l0.1,0.4l-0.1,0.2l-0.1,0.1l0.1,0.1l0.2,0.1h0.1l0.4-0.2h0.2h0.2l0.2-0.1l0.6-0.1h0.3l0.2-0.1
                h0.1l0.1,0.1l-0.1,0.3v0.2l0.1,0.4l0.1,0.2l0.2,0.1l0.1,0.1l0.1,0.2h0.3l0.7,0.2l0.2,0.1l0.3,0.1h0.2l0.7,0.1l0.3,0.1l0.5,0.1h0.4
                h0.3l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.2l0.1-0.5l0.1-0.1l0.1-0.1h0.2l0.1,0.1l0.1,0.5l0.5,0.5l0.2,0.4l0.1,0.3l0.1,0.1l0.2,0.1h0.3
                h0.3l0.6,0.2l0.5,0.2l0.3,0.1l0.2,0.1l0.1,0.2l0.1,0.1l0.1,0.3l0.1,0.3l0.2,0.1h0.2l0.2,0.2l0.1,0.2l0.2,0.4l0.2,0.3l0.1,0.3
                l0.2,0.2l0.3,0.1h0.3l0.1-0.1l0.4-0.1l0.3-0.2l0.2-0.1h0.1l0.1,0.1v0.2v0.1l0.2,0.1h0.1l0.1-0.1v-0.1v-0.6V499v-0.5v-0.6v-0.5V497
                v-0.4h-0.1l-0.2,0.1h-0.4l-0.1,0.1v0.1v0.1v0.2l-0.1,0.1h-0.2l-0.2-0.1l-0.4-0.1l-0.4-0.1l-0.3-0.3l-0.3-0.4l-0.2-0.2l-0.5-0.4
                l-0.1-0.1l-0.2-0.2l-0.1-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.2l0.1-0.4l0.2-0.7l0.1-0.5l0.1-0.4l0.3-0.4v-0.3l-0.1-0.4v-0.2v-0.6v-0.5
                v-0.2l-0.1-0.4l-0.2-0.5l-0.4-0.7v-0.1l0.2-0.2l0.4-0.3l0.2-0.2l0.2-0.2l0.1-0.1l0.2-0.3l0.1-0.2v-0.3l-0.1-0.3l0.2-0.1l0.7-0.1
                l0.8-0.1l0.8-0.1l0.8-0.1l0.8-0.1l0.7-0.1l0.5-0.1l0.1,0.2l0.2,0.3l0.2,0.2l0.2,0.2l0.2,0.1h0.1h0.8l0.3,0.1l0.2,0.2l0.1,0.3
                l0.2,0.2l0.2,0.1h0.1h0.1h0.2l0.2,0.1l0.1,0.1v0.2l0.1,0.1h0.3h0.3l0.3,0.1h0.3l0.3,0.1l0.2,0.2l0.3,0.2l0.4,0.1l0.3,0.2
                L567.5,489.2z"/>
            <linearGradient id="ZW_1_" gradientUnits="userSpaceOnUse" x1="1003.2043" y1="148.7079" x2="0.1864" y2="148.7079" gradientTransform="matrix(1 0 0 -1 0 665.8898)">
                <stop  offset="0" style="stop-color:#2BD5FF"/>
                <stop  offset="1" style="stop-color:#023DFA"/>
            </linearGradient>
            <path id="ZW" class="stcustom0 st255" d="M562.9,526.9l-0.3-0.2l-0.3-0.1l-0.4-0.1h-0.6l-0.7,0.1l-0.8-0.1l-0.8-0.3l-0.7-0.1l-0.8,0.1l0,0
                l-0.1-0.1l-0.2-0.2h-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.4l0,0h-0.1h-0.2l-0.5-0.2l-0.6-0.2l-1-0.2h-0.4l-0.1-0.1l-0.1-0.1
                l-0.2-0.4l-0.2-0.3l-0.4-0.5l-0.1-0.1v-0.4v-0.3v-0.2v-0.2v-0.3v-0.2l-0.1-0.1l-0.2-0.1H552h-0.5v-0.3l-0.1-0.4l-0.1-0.3l-0.1-0.1
                L551,520l-0.5-0.2l-0.7-0.3l-0.6-0.4l-0.7-0.5l-0.2-0.1l-0.2-0.5l-0.4-0.9v-0.3l-0.1-0.1l-0.4-0.4l-0.1-0.2l-0.1-0.2l-0.6-0.6
                l-0.2-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.1l-0.2-0.2l-0.1-0.2l-0.1-0.2v-0.2l0.1-0.1l0.5,0.2h0.3l0.2-0.1l0.3,0.1l0.3,0.3l0.4,0.1
                l0.4-0.2l0.5,0.1l0.7,0.3l0.6,0.1l0.7-0.2l0.6-0.7l0.6-0.6l0.6-0.7l0.3-0.6l0.5-0.5l0.7-0.4l0.7-0.3l1-0.4l0,0l0.2-0.3l0.1-0.3v-0.5
                l0.1-0.3l0.1-0.1l0.2-0.1l0.2-0.1l0.7-0.4l0.6-0.2l0.7-0.2h0.7h0.7h0.4v0.5v0.5h0.1h0.5h0.9h0.8l0.5,0.4l0.2,0.1l0.6,0.1l0.7,0.6
                l0.9,0.1l0.6,0.2l0.5,0.2l0.3,0.3l0.2,0.1h0.3h0.1v0.2l-0.2,0.3v0.5l0.2,0.6v0.5l-0.1,1v0.9v0.3v0.2l0.1,0.1v0.1l-0.1,0.4l-0.1,0.4
                v0.2v0.1l-0.1,0.1l-0.4,0.2l-0.1,0.1v0.2v0.2l0.1,0.1l0.2,0.1l0.1,0.1v0.1l-0.1,0.3l-0.2,0.4l0.2,0.5l0.2,0.3l0.2,0.4l0.1,0.2v0.2
                v0.2l-0.3,0.7l-0.2,0.4l-0.3,0.5l-0.4,0.3l-0.1,0.1v0.2v0.3v0.4l-0.3,0.6l0.2,0.5l0,0l-0.1,0.1l-0.5,0.5l-0.5,0.5l-0.4,0.4l-0.4,0.5
                l-0.5,0.5l-0.4,0.4H562.9z"/>
        </svg>

        <div #tooltipState *ngIf="hoverStateCode" class="position-fixed text-center" style="pointer-events: none; top: -9999px;">
            <div class="chatBubble">
                <img class="border border-info" style="width: 120px; object-fit: contain;" src="{{hoverFlagUrl}}"/> 
                <h3 class="fw-bold text-primary m-0 p-0 p-0 pt-3 text-center" style="max-width: 240px;">{{hoverStateName}}</h3>   
            </div>
            <div class="triangle-down"></div>
        </div>
    </div>

    <div id="state-scroll" [style]="{opacity: currentStateCode ? 0.2 : 1,}" (scroll)="onStateScroll($event)" class="ts300 w-100 position-fixed bottom-0 d-flex hide-scroll px-5" style="height: 128px; overflow: hidden; overflow-x: auto; background-color: #73BAF322; z-index: 2;">
        @for (item of indexStates; track item) {
            <div class="h-100 p-3">
                <div (click)="clickState(item)" role="button" class="card h-100 shadow-sm" style="width: 100px; overflow: hidden;">
                    <div class="border border-info w-100" style="height: 60px;" >
                        <img class="w-100 h-100 bg-info" [src]="'/assets/images/country-flags-main/svg/' + item.toLocaleLowerCase() + '.svg'">
                    </div>
                    <h3 class="text-center pt-1" style="font-size: 0.9rem; line-height: 1.1em;" [innerText]="getStateName(item)"></h3>
                </div>
            </div>
        }
        <div (click)="scrollByLeftState()" role="button" class="move-state-hover position-fixed bottom-0 p-4 d-flex justify-content-center align-items-center" style="background-image: linear-gradient(to left, #0dcaf000, #fff2, #fff2); left: 0; height: 128px">
            <h1><i class="bi bi-caret-left-fill text-primary"></i></h1>
        </div>
        <div (click)="scrollByRightState()" role="button" class="move-state-hover position-fixed bottom-0 p-4 d-flex justify-content-center align-items-center" style="background-image: linear-gradient(to right, #0dcaf000, #fff2, #fff2); right: 0; height: 128px">
            <h1><i class="bi bi-caret-right-fill text-primary"></i></h1>
        </div>
    </div>
</div>


<style>
    body {
        background: #060e1b;
    }

    canvas {
        opacity: 0.9;
        position: fixed;
        left: 0; 
        top: 0; 
        right: 0; 
        bottom: 0;
        z-index: -1;
    }
</style>

<canvas id="canvas"></canvas>

<div id="state-dialog" class="ts300" *ngIf="currentStateCode" [@fadeInOut] style="position: fixed; left: 0; top: 3rem; right: 0; bottom: 0; overflow-y: auto;">

    
    <div class="row m-0 p-3 p-xl-5" style="background-color: #2222;" style="max-height: 100vh;">

        <div class="col-12 col-lg-5 pt-3 pb-3" style="padding-left: 6%; padding-right: 6%;">
            <div class="row">
                <div class="col-6 p-3 pt-2 text-center">
                    <div class="card card-body shadow-sm rounded p-1 w-100">
                        <img *ngIf="currentStateCode" (click)="openState()" role="button" src="/assets/images/country-flags-main/svg/{{currentStateCode.toLocaleLowerCase()}}.svg"/>
                    </div>
                </div>
                <div class="col-6 col-lg-12 p-3 pt-1 text-center">
                    <div class="w-100 pt-2">
                        <button (click)="openState()" class="btn btn-primary shadow-sm m-0 px-5 p-3 w-100 fs-5"
                            style="pointer-events: all;" role="button" (focus)="true">
                            <i class="bi bi-door-open"></i>&nbsp;Vào xem
                        </button>
                    </div>
                    <div class="w-100 pt-4">
                        <button (click)="closeDialogState()" class="btn btn-secondary shadow-sm px-5 p-3 w-100 fs-5" 
                            style="pointer-events: all;" role="button">
                            <i class="bi bi-arrow-left"></i>&nbsp;Thế giới
                        </button>
                    </div>
                </div>
                <div class="col-6 p-3">
                    <div class="card card-body shadow-sm rounded p-xl-4">
                        <div class="fw-light fs-5 m-2 text-primary">Tên</div>
                        <div class="fw-bold fs-4 m-2 text-primary lh-sm">{{currentStateName ?? ''}}</div>
                    </div>
                </div>
                <div class="col-6 p-3">
                    <div class="card card-body shadow-sm rounded p-xl-4">
                        <div class="fw-light fs-5 m-2 text-primary">Thuộc loại</div>
                        <div class="fw-bold fs-4 m-2 text-primary lh-sm">Quốc Gia</div>
                    </div>
                </div>
                <div class="col-12 p-3">
                    <div class="card card-body shadow-sm rounded p-xl-4">
                        <div class="fw-light fs-5 m-2 text-primary">GDP</div>
                        <div class="fw-bold fs-4 m-2 text-primary lh-sm">10,2218 tỷ</div>
                    </div>
                </div>
                <div class="col-6 p-3">
                    <div class="card card-body shadow-sm rounded p-xl-4">
                        <div class="fw-light fs-5 m-2 text-primary">Dân số</div>
                        <div class="fw-bold fs-4 m-2 text-primary lh-sm">97,47 triệu</div>
                    </div>
                </div>
                <div class="col-6 p-3">
                    <div class="card card-body shadow-sm rounded p-xl-4">
                        <div class="fw-light fs-5 m-2 text-primary">Doanh nghiệp</div>
                        <div class="fw-bold fs-4 m-2 text-primary lh-sm">857.551</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-7" style="margin-top: 142px;">
            <img class="w-100 animate-state" src="/assets/images/russia.svg"/>
        </div>

    </div>
</div>