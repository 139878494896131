import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuraService } from '../../services/aura_services';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogPrimaryComponent } from '../dialog-primary/dialog-primary.component';

@Component({
  selector: 'app-info-lms-paid',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    AuraService,
  ],
  templateUrl: './info-lms-paid.component.html',
  styleUrl: './info-lms-paid.component.css'
})
export class InfoLmsPaidComponent {

  constructor(
    private auraService: AuraService,
    private router: Router,
    public dialog: MatDialog,
  ){}

  public indexSelected = 1;
  public prefCode: string = '';
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  public profile: any;
  public isLogin: boolean = false;
  public refs = [
    "CAPITALX",
    "AURACAPITAL",
    "KYBALION",
    "MSBICH",
    "CHUYENVIEN01",
    "CHUYENVIEN02",
    "CODONG01",
    "CODONG02",
    "DOITAC01",
    "DOITAC02",
  ];

  public clickContent($event: any) {
    $event.stopPropagation();
  }
  public back($event: any) {
    this.onBack.emit(['back']);
  }

  public async regist($event: any) {
    try {
      this.profile = await this.auraService.profile();
      this.isLogin = true;
    }
    catch {
      this.isLogin = false;
    }

    if (this.isLogin) {
      if (this.indexSelected == 0) {
        const dialog = this.dialog.open(DialogPrimaryComponent, {
          data: {
            title: 'Thông báo.',
            content: 'Bạn đã sở hữu khóa học này rồi!'
          }
        });
        return;
      }

      let statusPayment = '';
      for (let course of (this.profile?.data?.courses ?? [])) {
        console.log('course', course);
        if (course.code == '3D-LMS') {
          statusPayment = `${course.status}` == '1' ? 'payment' : 'watingpayment';
        }
      }

      if (statusPayment == 'payment') {
        this.dialog.open(DialogPrimaryComponent, {
          data: {
            title: 'Thông báo.',
            content: 'Bạn đã sở hữu khóa học này rồi!'
          }
        });
        return;
      }
      if (statusPayment == 'watingpayment') {
        this.dialog.open(DialogPrimaryComponent, {
          data: {
            title: 'Thông báo.',
            content: 'Hệ thống đã ghi nhận thông tin thanh toán của bạn. Nhân viên Aura đang kiểm tra và xử lý tài khoản!'
          }
        });
      }

      this.router.navigate(['payment', 'aura-lms'], {
        queryParams: {
          ref: this.prefCode ?? '',
        }
      });
      return;
    }
    
    this.dialog.open(DialogPrimaryComponent, {
      data: {
        title: 'Thông báo.',
        content: 'Đăng ký hoặc đăng nhập để học tập!'
      }
    });
    this.router.navigate(['login']);
    // this.auraService.signIn('0978557728','An3Ha7Va9@123');
    // alert('Hệ thống bận, vui lòng thử lại sau!');
  }
}
