import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe, CommonModule } from '@angular/common';
import * as uuid from 'uuid';
import { PanoComponent } from '../../pano.component';

export interface DialogHotSpotConfigData {
  panoViewer: any;
  createTooltipFunc: any;
  root: PanoComponent;
}

@Component({
  selector: 'app-dialog-hot-spot-config',
  standalone: true,
  imports: [ReactiveFormsModule, MatAutocompleteModule, AsyncPipe, CommonModule],
  templateUrl: './dialog-hot-spot-config.component.html',
  styleUrl: './dialog-hot-spot-config.component.css'
})
export class DialogHotSpotConfigComponent {

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogHotSpotConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogHotSpotConfigData,
  ){
    this.sceneIds = this.inputSceneId.valueChanges.pipe(
      startWith(''),
      map(value => this.filterSceneId(value)),
    );
    this.typeIds = this.inputTypeId.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTypeId(value)), 
    );
    this.productSlugs = this.inputProductSlug.valueChanges.pipe(
      startWith(''),
      map(value => this.filterProductSlug(value)), 
    );
  }

  sceneIds: Observable<string[]>;
  typeIds: Observable<string[]>;
  productSlugs: Observable<string[]>;
  private filterSceneId(value: string): string[] {
    value = value.toLowerCase();
    const options = Object.keys(this.data.panoViewer.getConfig().scenes);
    return options.filter(option => option.toLowerCase().includes(value)).reverse();
  }
  private filterTypeId(value: string): string[] {
    value = value.toLowerCase();
    const options = ['scene-custom', 'product', 'scene-break'];
    // return options.filter(option => option.toLowerCase().includes(value));
    return options;
  }
  private filterProductSlug(value: string): string[] {
    value = value.toLowerCase();
    const scenes = this.data.panoViewer.getConfig().scenes;
    const options: Array<string> = [];
    for (let ks in scenes) {
      const vs = scenes[ks];
      const hotSpots = vs.hotSpots;
      HS: for (let kh in hotSpots) {
        const vh = hotSpots[kh];
        console.log(vh, vh.type);
        if (vh.type != 'product') {
          continue HS;
        }
        options.push(vh.createTooltipArgs?.productSlug);
      }
    }
    const clearOptions : Array<string> = []; 
    options.forEach((v) => {
      if (!clearOptions.includes(v)){
        clearOptions.push(v);
      }
    })
    return clearOptions.filter(option => option?.toLowerCase().includes(value)).reverse();
  }

  public inputSceneId : FormControl = new FormControl('');
  public inputTypeId : FormControl = new FormControl('');
  public inputProductSlug : FormControl = new FormControl('');
  
  public addHotSpot() {
    const yaw = this.data.panoViewer.getYaw();
    const pitch = this.data.panoViewer.getPitch();
    const type = this.inputTypeId.value;
    const config = {
      id: uuid.v4(),
      createTooltipFunc: (div:any, args:any) => this.data.createTooltipFunc(div, args, this.data.root),
      yaw: yaw,
      targetYaw: yaw,
      pitch: pitch,
      targetPitch: pitch,
      type: type,
      createTooltipArgs: {
        yaw: yaw,
        targetYaw: yaw,
        pitch: pitch,
        targetPitch: pitch,
        type: type,
        productSlug: '',
        sceneId: '',
      },
    }
    if (type == 'scene-custom' || type == 'scene-break') {
      const sceneId = this.inputSceneId.value.trim();
      config.createTooltipArgs.sceneId = sceneId;

      const scenes = this.data.panoViewer.getConfig().scenes;
      const keyScenes = Object.keys(scenes);
      if (!keyScenes.includes(sceneId)) { // add scene if not fund
        this.data.panoViewer.addScene(sceneId, {
          // "pitch": 0,
          // "yaw": 0,
          // "hfov": 100,
          "type": "equirectangular",
          "panorama": `http://localhost:4200/assets/pano/${this.data.panoViewer.worldId}/images/${sceneId}.png`,
          "compass": true,
          "hotSpots": [],
        });
      }
    }
    if (type == 'product') {
      const productSlug = this.inputProductSlug.value.trim();
      config.createTooltipArgs.productSlug = productSlug;
    }
    this.data.panoViewer.addHotSpot(config);
    this.close();
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
