<p-toast></p-toast>


<section class="p-3 pt-4" style="background-color: #555; min-height: 100%; width: 30rem; max-width: 100%;">

  <h3 class="mx-3 text-white mb-2">
    Đăng ký ngay để tham gia chương trình <br/>
    <b>Cấp số nhân doanh nghiệp bằng cơ chế vốn</b> <br/>
    Hoàn toàn miễn phí
  </h3>

  <div class="container-fluid h-custom mt-1" style="background-color: #555; height: 100%;">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <form>

          <hr class="mb-4"/>

          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p class="lead fw-normal mb-0 me-3 text-white">Đăng nhập với: </p>
            <button (click)="btnSignInFacebook()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faFacebookF" />
            </button>
            <button (click)="btnSignInGoogle()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faGoogle" />
            </button>
          </div>

          <br/>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3">Số điện thoại:</label>
            <input (focus)="0" type="tel" id="form3Example3" class="form-control {{validInputPhone == '' ? 'is-valid' : validInputPhone == undefined ? '' : 'is-invalid'}}" (change)="checkValidEmail($event)" placeholder="Nhập số điện thoại" [(ngModel)]="formLogin.inputPhone" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3" class="invalid-feedback" [innerText]="validInputPhone"></div>
          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example4">Mật khẩu:</label>
            <input type="password" id="form3Example4" class="form-control {{validInputPassworld == '' ? 'is-valid' : validInputPassworld == undefined ? '' : 'is-invalid'}}" (change)="checkValidPassworld($event)" placeholder="Nhập mật khẩu" [(ngModel)]="formLogin.inputPassworld" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example4" class="invalid-feedback" [innerText]="validInputPassworld"></div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2 text-white" [(ngModel)]="formLogin.isRememberMe" type="checkbox" value="" id="form2Example3"  [ngModelOptions]="{standalone: true}"/>
              <label class="form-check-label text-white" for="form2Example3">
                Nhớ mật khẩu
              </label>
            </div>
            <a class="text-body"><span class="text-white">Quên mật khẩu?</span></a>
          </div>

          <div class="row text-center text-lg-start mt-3 pt-2 mx-0 px-0">
            <button type="button" class="btn btn-outline-secondary col me-2" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="close()" [disabled]="isLoading">
              Trở lại
            </button>
            <button type="button" class="btn btn-danger col ms-2" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="btnSignInPhone()" [disabled]="isLoading">
              Đăng nhập
            </button>
            <p class="small fw-bold mt-3 pt-1 mb-3 text-white">Chưa có tài khoản? <a role="button" (click)="showSignUp()" class="link-danger" ><span style="color: #f99;">Đăng ký</span></a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>