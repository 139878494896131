import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbCarousel, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { json } from 'stream/consumers';
import { DialogCartComponent } from '../dialog-cart/dialog-cart.component';
import { lastValueFrom } from 'rxjs';
import { DialogWarningComponent } from '../dialog-warning/dialog-warning.component';
import { DialogPrimaryComponent } from '../dialog-primary/dialog-primary.component';
import { CdsServices } from '../logics/cds-services';

export interface DialogProductDetailData {
  args: any;
}

@Component({
  selector: 'app-dialog-product-detail',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NgbCarouselModule,],
  providers: [CdsServices],
  templateUrl: './dialog-product-detail.component.html',
  styleUrl: './dialog-product-detail.component.css'
})
export class DialogProductDetailComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogProductDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogProductDetailData,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private cdsServices: CdsServices,
  ) {
    const productSlug = this.data.args.productSlug; // 'delicate-cutting-kk01' 
    const getScr = httpClient.get(
      `${this.domain}/v1/client/product-detail-by-slug/${productSlug}`,
      { headers: this.headerRequrest }
    ).subscribe(async (value: any) => {
      this.productInfo = value?.data;
      getScr.unsubscribe();
    });
    this.inputQtyProduct.valueChanges.subscribe((val) => {
      let newVal = parseInt(val?.toString());
      if (!newVal) {
        newVal = 1;
      }
      let newValString = newVal.toString().replaceAll('.', '').replaceAll(',', '');
      this.inputQtyProduct.setValue(newValString);
    })
    this.getSession();
  }
  private domain: string = 'https://api-demo.dsp.one';
  private headerRequrest: any = {
    Authorization: 'Bearer 226b116857c2788c685c66bf601222b56bdc3751b4f44b944361e84b2b1f002b',
  };
  public productInfo?: any = null;
  public inputQtyProduct: FormControl = new FormControl('1');
  public isLoading: number = 0;

  private async addCurentProductToCart() {
    try {
      this.isLoading++;
      const cartSession = await this.getSession();
      const postAddToCartApi = this.httpClient.post(
        `${this.domain}/v1/client/add-to-cart`,
        {
          product_id: this.productInfo?.id,
          qty: this.inputQtyProduct.value,
          session_id: cartSession,
        },
        { headers: this.headerRequrest }
      );
      const response: any = await lastValueFrom(postAddToCartApi);
    } finally {
      this.isLoading--;
    }
  }

  public async addToCart() {
    try {
      await this.addCurentProductToCart();
      this.dialog.open(DialogPrimaryComponent, {
        data: {
          "title": "Product Successfully Added to Cart",
          "content": "Great news! The product has been successfully added to your cart. Feel free to continue shopping or proceed to checkout."
        }
      });
    } catch (e) {
      this.dialog.open(DialogWarningComponent, {
        data: {
          "title": "Product Addition to Cart Unsuccessful",
          "content": "Unfortunately, the product couldn't be added to the cart. Please be patient and try again after a short while."
        }
      });
    }
  }

  public async buyNow() {
    try {
      await this.addCurentProductToCart();
      this.close();
      this.dialog.open(DialogCartComponent, {
        maxHeight: '100%',
        maxWidth: '100%',
      })
    } catch (e) {
      this.dialog.open(DialogWarningComponent, {
        data: {
          "title": "Product Addition to Cart Unsuccessful",
          "content": "Unfortunately, the product couldn't be added to the cart. Please be patient and try again after a short while."
        }
      });
    }
  }

  public addProduct() {
    let num: number = parseInt(this.inputQtyProduct.value);
    if (!num) {
      num = 1;
    }
    num = Math.floor(num);
    this.inputQtyProduct.setValue(num + 1)
  }
  public subtractProduct() {
    let num: number = parseInt(this.inputQtyProduct.value);
    if (!num) {
      num = 1;
    }
    num = Math.floor(num);
    if (num - 1 <= 0) {
      return;
    }
    this.inputQtyProduct.setValue(num - 1);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  private async getSession(): Promise<string> {
    let cartSesson: string | null = localStorage.getItem('cart-session');
    if (!cartSesson) {
      const getSessionApi = this.httpClient.get(
        `${this.domain}/v1/client/session`,
        { headers: this.headerRequrest }
      );
      cartSesson = (await lastValueFrom<any>(getSessionApi))?.data?.session_id;
    }
    localStorage.setItem('cart-session', cartSesson ?? '');
    return cartSesson ?? '';
  }

}
