{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5w~qRjf8V[jua}WCj[ofayWBjtfiayayayayaxayjuj[WBRjoeWBj[kCayf6a{fQayfQayayfQ",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 4,
        "cubeResolution": 2544
    }
}