import { Component, EventEmitter, Output } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AuraService } from '../../services/aura_services';
import { MatDialog } from '@angular/material/dialog';
import { DialogPrimaryComponent } from '../dialog-primary/dialog-primary.component';
import { DialogConfirmInfoComponent } from '../pano/dev/dialog-confirm-info/dialog-confirm-info.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-lms-trailer',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
  ],
  providers: [AuraService],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('300ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('300ms', style({ height: '0px', opacity: 0 })),
      ]),
    ],
    ),
  ],
  templateUrl: './info-lms-trailer.component.html',
  styleUrl: './info-lms-trailer.component.css'
})
export class InfoLmsTrailerComponent {

  constructor(
    private auraService: AuraService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  public profile: any;

  async ngOnInit() {
    const lession = await this.auraService.getLession();
    // this.lessionData = lession['data']; // add it when release
    try {
      this.profile = (await this.auraService.profile()).data;
    }
    catch {
      this.profile = undefined;
    }
    this.clickContent(null, '/assets/FITI_ONLINE/trailer-aura-lms.mp4', '0');
  }

  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Output() showPayment: EventEmitter<any> = new EventEmitter();
  @Output() showSign: EventEmitter<any> = new EventEmitter();

  public lessionData = [
    {
      "id": "1",
      "label": "Phần 1: Tại sao cần IPO doanh nghiệp",
      "items": [
        {
          "id": "101",
          "label": "1.1: 4 kịch bản của doanh nghiệp",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.1.mp4"
        },
        {
          "id": "102",
          "label": "1.2: IPO theo phương pháp truyền thống khác phương pháp cơ chế vốn như thế nào?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.2.mp4"
        },
        {
          "id": "103",
          "label": "1.3: Hành trình IPO của Facebook – hình mẫu của giới công nghệ",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.3.mp4"
        },
        {
          "id": "104",
          "label": "1.4: Thời gian để trở thành công ty tỷ đô mất bao lâu?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.4.mp4"
        },
        {
          "id": "105",
          "label": "1.5: Lịch sử IPO – hành trình vĩ đại của công ty có giá trị nhất lịch sử thế giới",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.5.mp4"
        },
        {
          "id": "106",
          "label": "1.6: Hành trình bước vào đại dương của những người doanh nhân",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.6.mp4"
        },
        {
          "id": "107",
          "label": "1.7: Thời gian và tốc độ tác động đến sự thành công của doanh nghiệp như thế nào?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.7.mp4"
        },
        {
          "id": "108",
          "label": "1.8: Hành trình cấp số nhân và cấp số cộng",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.8.mp4"
        },
        {
          "id": "109",
          "label": "1.9: Sự khác biệt của doanh nghiệp cơ chế thị trường và doanh nghiệp cơ chế vốn",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.9.mp4"
        },
        {
          "id": "110",
          "label": "1.10: Nhà đầu tư tương tác với doanh nghiệp cơ chế vốn như thế nào?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.10.mp4"
        },
        {
          "id": "111",
          "label": "1.11: Nhà đầu tư muốn gì khi đầu tư cho doanh nghiệp?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.11.mp4"
        },
        {
          "id": "112",
          "label": "1.12: Tại sao cần IPO doanh nghiệp?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/1.12.mp4"
        }
      ]
    },
    {
      "id": "2",
      "label": "Phần 2: Hành trình từ ý tưởng đến IPO",
      "items": [
        {
          "id": "113",
          "label": "Hành trình từ ý tưởng đến IPO",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/2.0.1.mp4"
        },
        {
          "id": "114",
          "label": "2.1: Hành trình xây dựng siêu tập đoàn",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/2.1.mp4"
        }
      ]
    },
    {
      "id": "3",
      "label": "Phần 3: Nhà sáng lập nơi khởi nguồn của mọi ý tưởng",
      "items": [
        {
          "id": "115",
          "label": "3.1: Sự khác biệt giữa các Nhà Sáng Lập? - Khát vọng doanh nghiệp",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.1.mp4"
        },
        {
          "id": "116",
          "label": "3.2: Như thế nào là dự án tốt?",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.2.mp4"
        },
        {
          "id": "117",
          "label": "3.3: Làm thế nào để kích hoạt sự sáng tạo của Nhà Sáng Lập hoặc HĐQT",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.3.mp4"
        },
        {
          "id": "118",
          "label": "3.4: Hành trình tâm thức – sự thành công tiềm ẩn từ bên trong",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.4.mp4"
        },
        {
          "id": "119",
          "label": "3.5: Sản phẩm vs Mô hình kinh doanh",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.5.mp4"
        },
        {
          "id": "120",
          "label": "3.6: Mô hình kinh doanh của các tập đoàn – KFC",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.6.mp4"
        },
        {
          "id": "121",
          "label": "3.7: Mô hình kinh doanh của Mc Donald",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.7.mp4"
        },
        {
          "id": "122",
          "label": "3.8: Mô hình kinh doanh của DELL",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.8.mp4"
        },
        {
          "id": "123",
          "label": "3.9: Mô hình kinh doanh của Công ty xây dựng Trung Quốc",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.9.mp4"
        },
        {
          "id": "124",
          "label": "3.10: Mô hình kinh doanh của Grab",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.10.mp4"
        },
        {
          "id": "125",
          "label": "3.11: Mô hình kinh doanh của Mafia",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.11.mp4"
        },
        {
          "id": "126",
          "label": "3.12: Lý thuyết trò chơi và sự liên kết của các tập đoàn",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/3.12.mp4"
        }
      ]
    },
    {
      "id": "4",
      "label": "Phần 4: Nhà Đồng Sáng lập, Nhà Đầu Tư Thiên Thần, Quỹ đầu tư, Ngân hàng đầu tư và IPO",
      "items": [
        {
          "id": "127",
          "label": "Chiến lược thương hiệu",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.0.1.mp4"
        },
        {
          "id": "128",
          "label": "Hành trình trải nghiệm khách hàng",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.0.2.mp4"
        },
        {
          "id": "129",
          "label": "4.1+4.2: Định nghĩa Đồng Sáng Lập - Nhà đầu tư thiên thần",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.1_4.2.mp4"
        },
        {
          "id": "130",
          "label": "4.3: Quỹ đầu tư mạo hiểm và cách vận hành của quỹ",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.3.mp4"
        },
        {
          "id": "131",
          "label": "4.4: Quỹ đầu tư tư nhân và cách vận hành của quỹ",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.4.mp4"
        },
        {
          "id": "132",
          "label": "4.5: Sự khác nhau giữa quỹ đầu tư mạo hiểm và quỹ đầu tư tư nhân",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.5.mp4"
        },
        {
          "id": "133",
          "label": "4.6: Ngân hàng đầu tư",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.6.mp4"
        },
        {
          "id": "134",
          "label": "4.7: Các bước để thực hiện IPO",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/4.7.mp4"
        }
      ]
    },
    {
      "id": "5",
      "label": "Phần 5: Thiết kế thương vụ IPO",
      "items": [
        {
          "id": "135",
          "label": "5.1: 5 mục tiêu cuộc đời",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/5.1.mp4"
        },
        {
          "id": "136",
          "label": "5.2: Hành trình IPO là hành trình gieo hạt trên đường đi",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/5.2.mp4"
        },
        {
          "id": "137",
          "label": "5.3: Xác định đích đến của doanh nghiệp",
          "video": "https://class.aura.vn/assets/FITI_ONLINE/5.3.mp4"
        },
        {
          "id": "138",
          "label": "5.4: Sức mạnh của IPO – hành trình 5 năm với mục tiêu IPO 200 mil USD",
          "video": "https://class.aura.vn"
        },
        {
          "id": "139",
          "label": "5.5: 3 phương thức định giá và cách ứng dụng",
          "video": "https://class.aura.vn"
        },
        {
          "id": "140",
          "label": "5.6: Lộ trình tài chính doanh nghiệp – thiết kế thương vụ IPO như thế nào?",
          "video": "https://class.aura.vn"
        }
      ]
    },
    {
      "id": "6",
      "label": "Phần 6: Quan hệ cổ đông",
      "items": [
        {
          "id": "141",
          "label": "6.1: Chân dung của nhà đầu tư",
          "video": "https://class.aura.vn"
        },
        {
          "id": "142",
          "label": "6.2: Doanh nghiệp cần chuẩn bị gì trước khi gọi vốn?",
          "video": "https://class.aura.vn"
        },
        {
          "id": "143",
          "label": "6.3: Tài liệu gọi vốn",
          "video": "https://class.aura.vn"
        },
        {
          "id": "144",
          "label": "6.4: Nguyên tắc thiết kế bản thuyết trình gọi vốn",
          "video": "https://class.aura.vn"
        },
        {
          "id": "145",
          "label": "6.5: 5 bài học quý giá khi gọi vốn – câu chuyện về 2 nhà Sư",
          "video": "https://class.aura.vn"
        }
      ]
    },
    {
      "id": "7",
      "label": "Phần 7: Quản trị giá trị doanh nghiệp",
      "items": [
        {
          "id": "146",
          "label": "7.1: Cách quản trị giá trị khi có nhiều danh mục đầu tư",
          "video": "https://class.aura.vn"
        }
      ]
    },
    {
      "id": "8",
      "label": "Tổng kết",
      "items": [
        {
          "id": "147",
          "label": "Đồng hành cùng Aura, hành trình lan toả sứ mệnh",
          "video": "https://class.aura.vn"
        }
      ]
    }
  ];  

  public isPlayData = {
    id: '0',
    label: 'Cấp số nhân doanh nghiệp',
    parentId: '0',
    parentLabel: 'Cơ bản',
    video: '/assets/FITI_ONLINE/trailer-aura-lms.mp4',
  }

  public idCollapseShow: Array<string> = [];

  // ngAfterViewInit() {
  //   this.clickContent({}, '/assets/FITI_ONLINE/trailer-aura-lms.mp4', 0);
  // }

  public clickHeader($event: any, id: string) {
    if (this.idCollapseShow.includes(id)) {
      this.idCollapseShow.splice(this.idCollapseShow.indexOf(id))
    }
    else {
      this.idCollapseShow.push(id);
    }
  }

  public isStatusVideo(status: String): boolean {
    return this.isPlayData.video == status;
  }

  public clickContent($event: any, url: string, id: string) {
    if (id == '0') {
      this.isPlayData = {
        id: '0',
        parentId: '0',
        label: 'Cấp số nhân doanh nghiệp',
        parentLabel: 'Cơ bản',
        video: '/assets/FITI_ONLINE/trailer-aura-lms.mp4',
      };
    }

    for (let i = 0; i < this.lessionData.length; i++) {
      const lession = this.lessionData[i];
      for (let j = 0; j < lession.items.length; j++) {
        const item = lession.items[j];
        if (item.id == id) {
          this.isPlayData = {
            id: id,
            parentId: lession.id,
            label: item.label,
            parentLabel: lession.label,
            video: item.video,
          };
        }
      }
    }

    let statusPayment = 'unpayment';
    if (!this.profile) {
      statusPayment = 'unauthen';
    }
    else if (id != '0') {
      for (let course of (this.profile?.courses ?? [])) {
        console.log('course', course);
        if (course.code == '3D-LMS') {
          statusPayment = `${course.status}` == '1' ? '' : 'watingpayment';
        }
      }
    }
    else {
      statusPayment = '';
    }

    if (statusPayment == 'unauthen') {
      this.showSign.emit();
    }
    if (statusPayment == 'unpayment') {
      this.showPayment.emit(this.profile)
    }

    // statusPayment = ''; // remove it if release

    if (statusPayment) {
      this.isPlayData.video = statusPayment;
    }

    try {
      const videoElement = document.getElementById('fullscreen-video') as HTMLVideoElement;
      videoElement.src = encodeURI(url);
      videoElement.load();
    }
    catch { }
  }

  public disableOut($event: any) {
    $event.stopPropagation();
  }

  public back($event: any) {
    this.onBack.emit(['back']);
  }

  public regist($event: any) {
    alert('Hệ thống bận, vui lòng thử lại sau!');
  }

  public signIn() {
    this.showSign.emit();
  }
  
  public payment() {
    this.showPayment.emit(this.profile);
  }

}
