import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmInfoComponent } from '../dialog-confirm-info/dialog-confirm-info.component';

export interface DialogImportConfigData {
  panoViewer: any;
}

@Component({
  selector: 'app-dialog-import-config',
  standalone: true,
  imports: [],
  templateUrl: './dialog-import-config.component.html',
  styleUrl: './dialog-import-config.component.css'
})
export class DialogImportConfigComponent {
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogImportConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogImportConfigData,
  ){}

  public jsonFile?: File = undefined;
  public clearInputFileImport() {
    this.jsonFile = undefined;
  }
  public async selectFile(ev: any) {
    this.jsonFile = ev?.target?.files[0];
  }

  public close(): void {
    this.dialogRef.close(false);
  }
  public async import(){
    if (!this.jsonFile) {
      this.dialog.open(DialogConfirmInfoComponent, {
        data: {
          title: 'Can\'t import',
          content: 'File not found!',
        }
      });
      return;
    }
    try {
      const configText = await this.jsonFile?.text();
      const config = JSON.parse(configText ?? '');
      this.dialogRef.close(config);
    }
    catch (e) {
      this.dialog.open(DialogConfirmInfoComponent, {
        data: {
          title: 'Can\'t import',
          content: e,
        }
      });
    }
  }
}
