import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogUpdateAddressData {
  old_address: any,
}

@Component({
  selector: 'app-dialog-update-address',
  standalone: true,
  imports: [],
  templateUrl: './dialog-update-address.component.html',
  styleUrl: './dialog-update-address.component.css'
})
export class DialogUpdateAddressComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogUpdateAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogUpdateAddressData,
  ) { }

  public confirm() {
    
  }

  public close() {
    this.dialogRef.close(false);
  }

}
