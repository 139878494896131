import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, isDevMode } from '@angular/core';
import { DialogExportConfigComponent } from './dev/dialog-export-config/dialog-export-config.component';
import { DialogImportConfigComponent } from './dev/dialog-import-config/dialog-import-config.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmInfoComponent } from './dev/dialog-confirm-info/dialog-confirm-info.component';
import { DialogSceneConfigComponent } from './dev/dialog-scene-config/dialog-scene-config.component';
import { DialogHotSpotConfigComponent } from './dev/dialog-hot-spot-config/dialog-hot-spot-config.component';
import { DialogProductDetailComponent } from '../dialog-product-detail/dialog-product-detail.component';
import { DialogWarningComponent } from '../dialog-warning/dialog-warning.component';
import { DialogCartComponent } from '../dialog-cart/dialog-cart.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RiveModule, RiveStateMachine } from 'ng-rive';
import { InfoLmsPaidComponent } from '../info-lms-paid/info-lms-paid.component';
import { InfoLmsTrailerComponent } from '../info-lms-trailer/info-lms-trailer.component';
import { DialogPrimaryComponent } from '../dialog-primary/dialog-primary.component';
import { AuraService } from '../../services/aura_services';
import { SignComponent } from './aura_dialog/sign/sign.component';
import { SignUpComponent } from './aura_dialog/sign-up/sign-up.component';

declare var pannellum: any;

@Component({
  selector: 'app-pano',
  standalone: true,
  imports: [
    InfoLmsPaidComponent,
    InfoLmsTrailerComponent,
    DialogImportConfigComponent,
    DialogExportConfigComponent,
    SignComponent,
    SignUpComponent,
    CommonModule,
    RouterModule,
    RiveModule,
    RiveStateMachine,
  ],
  providers: [AuraService],
  templateUrl: './pano.component.html',
  styleUrl: './pano.component.css'
})
export class PanoComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer,
    private auraService: AuraService,
  ){}
  public panoViewer: any;
  // public panoOverlayViewer: any;
  private isOnMouseDown = false;
  private rotateTimeoutId?: ReturnType<typeof setTimeout>;
  private timeoutAutoRotate = 7000;
  private hfov = 100;
  public isPlayThemeMusic: boolean = true;
  public isUserStopMusic: boolean = false;
  public worldIdDefault = 'aura-lms'
  public worldId: string = this.worldIdDefault;
  public musicLink: string = `/assets/audio/${this.worldId}/scenes/default.mp3`;
  public videoIntroUrl: string = '';
  public isShowIntro: boolean = true;
  public fullscreenVideoUrl: string = '';
  public fullscreenVideoOpacity = 0.0;
  public sizeItem = 36;
  public animationFullscreenName: string = '';
  
  public isShowFullscreenVideo: boolean = false;
  public clickPanoramaCache: number[] = [0,0];
  public isShowCusor: boolean = true;
  
  top:any;
  left:any;
  rotateX:any;
  rotateRatio:any;
  expand=false;

  @HostListener('document:click', ['$event'])
  onClick($event: any) {
     this.expand=true;
     setTimeout(() => {
      this.expand=false;
     }, 500)
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove($event: any) {
    const coords = this.panoViewer.mouseEventToCoords($event);
    this.rotateRatio = (1- (90 / (this.rotateX % 180))) * this.sizeItem * 8 * 1.2;
    this.top = ($event.pageY - (this.rotateRatio / 2))+ "px";
    this.left = ($event.pageX - (this.rotateRatio / 2))+ "px";
    this.rotateX = this.cacularRotateX(coords[0]);
  }

  public clickPanorama($event: any) {
    if ($event.button == 2) {
      return;
    }

    if (($event.srcElement as HTMLDivElement).classList.contains('inside-hotspot')) {
      // ($event.srcElement as HTMLDivElement).click();
      return;
    }
    const dis = (Math.abs(this.clickPanoramaCache[0] - $event.clientX) + Math.abs(this.clickPanoramaCache[1] - $event.clientY))
    if (dis > 8) {
      return;
    }

    const [y1, x1] = this.panoViewer.mouseEventToCoords($event);

    const rotateX = this.cacularRotateX(y1);
    if (rotateX == 90) {
      return;
    }
    const currentSceneId = this.panoViewer.getScene();
    const hotSpots = this.panoViewer.getConfig().scenes[currentSceneId].hotSpots;

    const hotSpotsScenes = hotSpots.filter((v: any) => {
      return v.type == 'scene-custom';
    });

    function distanceCaculator(x1: number, y1: number, x2: number, y2: number): number {
      return Math.sqrt(Math.pow(x2-x1,2) + Math.pow(y2-y1,2));
    }
    let result = hotSpotsScenes[0];
    let disResult = distanceCaculator(x1, y1, result.yaw, result.pitch);
    for (let k in hotSpotsScenes) {
      const resultTmp = hotSpotsScenes[k];
      const disResultTmp = distanceCaculator(x1, y1, resultTmp.yaw, resultTmp.pitch);
      if (disResultTmp < disResult) {
        disResult = disResultTmp;
        result = resultTmp;
      }
    }

    const config = require(`./../../assets/pano/${this.worldId}/config.json`);
    const args = config.scenes[currentSceneId].hotSpots.find((v: any) => {
      return v.id == result.id;
    }).createTooltipArgs;

    this.panoViewer.lookAt((this.panoViewer.getPitch() + args.pitch) / 2 + 10, (this.panoViewer.getYaw() + args.yaw) / 2, (this.hfov ?? this.panoViewer.getHfov()) - 35);
    setTimeout(
      () => this.panoViewer.loadScene(args.sceneId, this.panoViewer.getPitch(), this.panoViewer.getYaw()), 
      500,
    );
    this.panoViewer.stopAutoRotate();
    if (this.rotateTimeoutId) {
      clearTimeout(this.rotateTimeoutId);
    }
  }

  
  public createLinkMusic(sceneId: string) {
    try {
      const config = require(`./../../assets/audio/${this.worldId}/config.json`);
      if (config[sceneId]) {
        return config[sceneId];
      }
      else {
        return this.musicLink;
      }
    } catch (e) {
      return `/assets/audio/${this.worldId}/scenes/default.mp3`;
    }
  }

  public async playFullscreenVideo() {
    const videoElement = document.getElementById('fullscreen-video') as HTMLVideoElement;
    if (this.checkIsPlayMusic()) {
      await this.toggleMusic();
    }
    videoElement.src = this.fullscreenVideoUrl;
    videoElement.play();
  }

  public async skipFullscreenVideo() {
    const videoElement = document.getElementById('fullscreen-video') as HTMLVideoElement;
    videoElement.pause();
    this.isShowFullscreenVideo = false;
    this.initPlayMusic();
    if (!this.checkIsPlayMusic()) {
      await this.toggleMusic();
    }
    this.panoViewer.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov);
  }

  public async playFullscreenAnimation(pathName: string) {
    const panoramaEle = document.getElementById('panorama');
    const width = panoramaEle?.offsetWidth ?? 0;
    const height = panoramaEle?.offsetHeight ?? 0;
    this.animationFullscreenName = pathName;
    const riveCanvas = document.getElementById('rive-canvas') as HTMLCanvasElement;
    riveCanvas.width = width;
    riveCanvas.height = height;
    this.animationFullscreenName = pathName;
  }

  public async skipFullscreenAnimation() {
    this.animationFullscreenName = '';
    this.panoViewer.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov);
    if (!this.checkIsPlayMusic()) {
      this.toggleMusic();
    }
  }
  

  public async playIntroVideo() {
    const introVideoElement = document.getElementById('intro-video') as HTMLVideoElement;
    if (this.checkIsPlayMusic()) {
      await this.toggleMusic();
    }
    if ((introVideoElement.src || '') == '') {
      introVideoElement.src = this.videoIntroUrl;
    }
    introVideoElement.play();
  }

  public async skipIntroVideo() {
    const currentDate = new Date();
    currentDate.setHours(23,59, 59, 999)
    localStorage.setItem(`is_show_trailer_${this.worldId}`, currentDate.getTime().toString())
    const introVideoElement = document.getElementById('intro-video') as HTMLVideoElement;
    introVideoElement.pause();
    this.isShowIntro = false;
    this.initPlayMusic();
  }

  public initPlayMusic() {
    const audioEle = document.getElementById('theme-music') as HTMLAudioElement;
    if (!this.isUserStopMusic && !this.checkIsPlayMusic()) {
      this.toggleMusic();
    }
  }

  public checkIsPlayMusic() {
    const audioEle = document.getElementById('theme-music') as HTMLAudioElement;
    this.isPlayThemeMusic = !audioEle?.paused;
    return this.isPlayThemeMusic;
  }

  public async loadMusic(sceneId: string) {
    if (!this.isUserStopMusic) {
      const audioEle = document.getElementById('theme-music') as HTMLAudioElement;
      const musicLink = this.createLinkMusic(sceneId);
      if (!this.isUserStopMusic && !this.isPlayThemeMusic) {
        if (this.musicLink == musicLink) {
          this.toggleMusic();
        }
        this.musicLink = musicLink;
      }
      if (this.musicLink != musicLink) {
        this.musicLink = musicLink;
      }
    }
  }

  public async toggleMusic() {
    const audioEle = document.getElementById('theme-music') as HTMLAudioElement;
    try {
      if (audioEle?.paused) {
        this.isUserStopMusic = false;
        this.isPlayThemeMusic = true;
        const newLinkMusic = this.createLinkMusic(this.panoViewer.getScene());
        if (this.musicLink != newLinkMusic) {
          this.musicLink = newLinkMusic;
        }
        else {
          audioEle.currentTime = 0;
          await audioEle?.play();
        }
      }
      else {
        this.isUserStopMusic = true;
        this.isPlayThemeMusic = false;
        await audioEle?.pause();
      }
    } catch (e) {
      console.log(e);
      this.checkIsPlayMusic();
    }
  }

  private createCustomHotSpot(hotSpotDiv: HTMLElement, args: any, root:this) {
    hotSpotDiv.onmouseenter = () => {
      this.isShowCusor = false;
    }
    hotSpotDiv.onmouseout = () => {
      this.isShowCusor = true;
    }


    if (args.type == 'scene-custom' || args.type == 'scene-break') {
      if (args.type == 'scene-custom') {
        let rotateX = this.cacularRotateX(args.pitch)
        if (rotateX > 80) {
          rotateX = 80;
        }
        let rotateRatio = (1- (90 / (rotateX % 180))) * this.sizeItem * 8;
        // if (rotateRatio < 30) {
        //   rotateRatio = 30;
        // }
        hotSpotDiv.innerHTML = `<div class="hotspot-scene inside-hotspot" style="rotate: 1 0 0 ${rotateX}deg; width: ${rotateRatio}px; height: ${rotateRatio}px;"></div>`
        if (root.isDev) {
          hotSpotDiv.innerHTML = `<div class="hotspot-scene inside-hotspot" style="rotate: 1 0 0 ${rotateX}deg; width: ${rotateRatio}px;"><div style="color: #fff; background-color: #222a; font-weight:bold">${args.sceneId}</div></div>`
        }
      }
      if (args.type == 'scene-break') {
        hotSpotDiv.innerHTML = '<div class="hotspot-breakscene inside-hotspot"></div>'
        if (root.isDev) {
          hotSpotDiv.innerHTML = `<div class="hotspot-breakscene inside-hotspot"><div style="color: #fff; background-color: #222a; font-weight:bold">${args.sceneId}</div></div>`
        }
      }
      hotSpotDiv.onclick = () => {
        root.panoViewer.lookAt(args.pitch + 10, args.yaw, (this.hfov ?? this.panoViewer.getHfov()) - 35);
        setTimeout(() => {
          if (args.sceneId.startsWith('http') || args.sceneId.startsWith('/')) {
            window.location = args.sceneId;
          }
          else {
            root.panoViewer.loadScene(args.sceneId, args.targetPitch + 10, args.targetYaw);
          }
        }, 900);
        root.panoViewer.stopAutoRotate();
        if (this.rotateTimeoutId) {
          clearTimeout(this.rotateTimeoutId);
        }
      }
    }
    if (args.type == 'product') {
      hotSpotDiv.innerHTML = '<div class="hotspot-product inside-hotspot"></div>';
      if (root.isDev) {
        hotSpotDiv.innerHTML = `<div class="hotspot-product inside-hotspot"><div style="color: #fff; background-color: #222a; font-weight:bold">${args.productSlug}</div></div>`
      }
      hotSpotDiv.onclick = () => {
        root.panoViewer.lookAt(args.pitch, args.yaw, root.hfov - 25);
        setTimeout(() => {
          const dialogRef = root.dialog.open(DialogProductDetailComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            data: {
              args: args,
            }
          });
          const scr = dialogRef.afterClosed().subscribe(() => {
            root.panoViewer.lookAt(args.pitch, args.yaw, this.hfov);
            scr?.unsubscribe();
          })
        }, 500);
        root.panoViewer.stopAutoRotate();
        if (this.rotateTimeoutId) {
          clearTimeout(this.rotateTimeoutId);
        }
      }
    }
    if (args.type == 'info') {
      if (args.productSlug.startsWith('video-')) {
        hotSpotDiv.innerHTML = '<div class="hotspot-info inside-hotspot"></div>'
        if (root.isDev) {
          hotSpotDiv.innerHTML = `<div class="hotspot-info inside-hotspot" style="color: #fff; background-color: #222a; font-weight:bold">${args.productSlug}</div>`
        }
        hotSpotDiv.onclick = () => {
          root.panoViewer.lookAt(args.pitch + 10, args.yaw, root.hfov - 25);

          const videoSlug = args.productSlug.replaceAll('video-', '');
          this.fullscreenVideoOpacity = 0;
          this.isShowFullscreenVideo = true;
          this.fullscreenVideoUrl = `/assets/pano/${this.worldId}/${videoSlug}`;
          setTimeout(() => {
            this.playFullscreenVideo();
            this.fullscreenVideoOpacity = 1;
          }, 500);

          // setTimeout(() => {
          //   root.panoViewer.lookAt(args.pitch, args.yaw, this.hfov);
          // }, 2500);
          root.panoViewer.stopAutoRotate();
          if (this.rotateTimeoutId) {
            clearTimeout(this.rotateTimeoutId);
          }
        }
      }
      else {
        hotSpotDiv.innerHTML = '<div class="hotspot-info inside-hotspot"> </div>';
        if (root.isDev) {
          hotSpotDiv.innerHTML = `<div class="hotspot-info inside-hotspot" style="color: #fff; background-color: #222a; font-weight:bold">${args.productSlug}</div>`;
        }
        hotSpotDiv.onclick = () => {
          if (args.productSlug == 'aura-lms_info-right') {
            root.panoViewer.lookAt(5, 0, 120);
          }
          else {
            root.panoViewer.lookAt(0, 0, 120);
          }
          setTimeout(() => {
            this.playFullscreenAnimation(args.productSlug);
            this.panoViewer.stopAutoRotate();
            if (this.rotateTimeoutId) {
              clearTimeout(this.rotateTimeoutId);
            }
            if (this.checkIsPlayMusic()) {
              this.toggleMusic();
            }
          }, 500);
          // setTimeout(() => {
          //   root.panoViewer.lookAt(0, 0, this.hfov);
          // }, 2500);
          root.panoViewer.stopAutoRotate();
          if (this.rotateTimeoutId) {
            clearTimeout(this.rotateTimeoutId);
          }
        }
      }


    }
  }

  private createPanoViewer(config: any) {
    if (this.panoViewer) {
      this.panoViewer.destroy();
      this.panoViewer = null;
    }
    const scenes = config.scenes;
    for (let sceneIndex in scenes) {
      const scene = scenes[sceneIndex];
      const hotSpots = scene.hotSpots;
      for (let hotSpotIndex in hotSpots) {
        config.scenes[sceneIndex].hotSpots[hotSpotIndex].id = `${sceneIndex}-${hotSpotIndex}`;
        config.scenes[sceneIndex].hotSpots[hotSpotIndex].createTooltipFunc = (p0: any, p1: any) => this.createCustomHotSpot(p0, p1, this)
      }
    }
    this.panoViewer = pannellum.viewer('panorama', config);
    this.panoViewer.worldId = this.worldId
    // this.panoOverlayViewer = pannellum.viewer('panorama-overlay', {
    //   "type": "equirectangular",
    //   "panorama": "https://pannellum.org/images/alma.jpg",
    //   "autoLoad": true,
    //   "minYaw": -90,
    //   "maxYaw": 90,
    //   "minPitch": -25,
    //   "maxPitch": 25,
      
    // });

    try {
      const compassElement = document.getElementsByClassName('pnlm-compass')[0] as HTMLElement;
      compassElement.onclick = () => {
        if (this.panoViewer.isOrientationSupported()) {
          if (!this.panoViewer.isOrientationActive()) {
            this.panoViewer.startOrientation();
          }
          else {
            this.panoViewer.stopOrientation();
          }
        } else {
          const dialogRef = this.dialog.open(DialogWarningComponent, {
            data: {title: 'Device Does Not Support Compass!', content: 'Unfortunately, your device does not support the compass function. Please use another device, such as a mobile phone with compass support, to experience this feature.'},
          });
        }
      }
    } catch(e) {console.log(e);}
    this.panoViewer.on('mousedown', (ev: MouseEvent) => {
      this.isOnMouseDown = true;
      this.panoViewer.stopAutoRotate();
      this.timeoutAutoRotate = 7000;
      if (this.rotateTimeoutId) {
        clearTimeout(this.rotateTimeoutId);
      }
    });
    this.panoViewer.on('touchstart', (ev: MouseEvent) => {
      this.isOnMouseDown = true;
      this.panoViewer.stopAutoRotate();
      this.timeoutAutoRotate = 7000;
      if (this.rotateTimeoutId) {
        clearTimeout(this.rotateTimeoutId);
      }
    });
    this.panoViewer.on('mouseup', (ev: MouseEvent) => {
      this.isOnMouseDown = false;
      this.initPlayMusic();
      if (this.rotateTimeoutId) {
        clearTimeout(this.rotateTimeoutId);
      }
      if (this.isDev) {
        return;
      }
      this.rotateTimeoutId = setTimeout(() => {
        this.panoViewer.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov);
        this.panoViewer.startAutoRotate(-2, 5);
      }, this.timeoutAutoRotate);
    });
    this.panoViewer.on('touchend', (ev: MouseEvent) => {
      this.isOnMouseDown = false;
      this.initPlayMusic();
      if (this.rotateTimeoutId) {
        clearTimeout(this.rotateTimeoutId);
      }
      if (this.isDev) {
        return;
      }
      this.rotateTimeoutId = setTimeout(() => {
        this.panoViewer.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov);
        this.panoViewer.startAutoRotate(-2, 5);
      }, this.timeoutAutoRotate);
    });

    this.panoViewer.on('load', () => {
      const currentSceneId = this.panoViewer.getScene();
      this.loadMusic(currentSceneId);
      this.panoramaResized(this);
    });
    this.panoramaResized(this);
    document.getElementById('panorama')?.addEventListener('mousemove', (ev: MouseEvent) => {
      this.onMouseMove(ev);
    });
    document.getElementById('panorama')?.addEventListener('touchmove', (ev: TouchEvent) => {
      this.onMouseMove(ev);
    });
  }

  private onMouseMove(ev: MouseEvent | TouchEvent) {
    if (!this.isOnMouseDown) return;
    // const hotSpotCoords = [-90, 10]
    const coords = [this.panoViewer.getYaw(), this.panoViewer.getPitch()]
    // console.log('mousemove', coords);
    // this.panoOverlayViewer.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov, 10)
    // const box = document.getElementById('youtube-movie');
    // if (box != null) {
    //   box.style.backgroundColor = 'lime';
    //   box.style.color = 'white';
    //   box.style.fontSize = '2em';
    // }
  }

  public panoramaResized(root: this) {
    const panoramaEle = document.getElementById('panorama');
    const width = panoramaEle?.offsetWidth ?? 0;
    const height = panoramaEle?.offsetHeight ?? 0;

    console.log('log wh', width, height);

    let sizeItem = '36px';
    if (width < 576) {
      root.hfov = 70;
      sizeItem = '24px'
    }
    else if (width < 768) {
      root.hfov = 80;
      sizeItem = '28px';
    }
    else if (width < 992) {
      root.hfov = 90;
      sizeItem = '32px';
    }
    // else if (width < 1200) {
    //   root.hfov = 100;
    //   sizeItem = '36px';
    // }
    this.sizeItem = Number.parseInt(sizeItem.replaceAll('px', ''));
    root.panoViewer.lookAt(root.panoViewer.getPitch(), root.panoViewer.getYaw(), root.hfov)

    const outHotspotEles = document.getElementsByClassName('pnlm-hotspot');
    // const inHotspotEles = document.getElementsByClassName('inside-hotspot');
    for (let i = 0; i < outHotspotEles.length; i++) {
      const item = outHotspotEles.item(i) as HTMLElement;
      item.style.width = sizeItem;
      item.style.height = sizeItem;
    }
    // for (let i = 0; i < inHotspotEles.length; i++) {
    //   const item = inHotspotEles.item(i) as HTMLElement;
    //   item.style.width = sizeItem;
    //   item.style.height = sizeItem;
    // }
  }

  public ngAfterViewInit(): void {
    this.checkSignAuraLMS().then(val => {
      if (!val) {
        this.showSign();
      }
    });
    let initPos: string = '';
    let initY: string = '';
    let initP: string = '';
    let initH: string = '';
    this.activatedRoute.queryParams.subscribe((params: any) => {
      initPos = params.pos ?? '';
      initY = params.y ?? '';
      initP = params.p ?? '';
      initH = params.h ?? '';
      if ((params.isDev == '1' && isDevMode()) || params.isDev == 'pJNfOKYbsOBkZyJcNo45lVxUrHnM9N5a') {
        this.isDev = true;
      }
      let urls = this.router.url.split('/');
      this.worldId = urls[urls.length - 1].split('?')[0]
      if (!this.worldId) this.worldId = this.worldIdDefault;

      this.musicLink = `/assets/audio/${this.worldId}/scenes/default.mp3`;
      this.videoIntroUrl = `/assets/pano/${this.worldId}/intro.mp4`;

      try {
        if (this.worldId != 'aura-lms') {
          throw 'Not have intro video';
        }
        const delayTrailer = localStorage.getItem(`is_show_trailer_${this.worldId}`) ?? '0'
        const date = new Date()
        if (date.getTime() < Number.parseInt(delayTrailer)) {
          throw `Block trailer to: ${date}`;
        }
      } 
      catch (e) {
        this.isShowIntro = false;
        console.log(e, 'this.isShowIntro', this.isShowIntro);
      }

      console.log('initPos', initPos);

      try {
        const config = require(`./../../assets/pano/${this.worldId}/config.json`);
        if (initPos) {
          config.default.firstScene = initPos;
        }
        if (initY) {
          config.default.yaw = +initY;
        }
        if (initP) {
          config.default.pitch = +initP;
        }
        if (initH) {
          config.default.hfov = +initH;
        }
        this.createPanoViewer(config);
      }
      catch (e) {
        this.worldId = this.worldIdDefault
        const config = require(`./../../assets/pano/${this.worldId}/config.json`);
        if (initPos) {
          config.default.firstScene = initPos;
        }
        this.createPanoViewer(config);
        console.log(e, `Change default world to '${this.worldId}' world`);
      }
    });
    this.initPlayMusic()
    setTimeout(() => {
      this.checkIsPlayMusic();
    }, 2000);
    // const canvasEle = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
    // const webglContext = canvasEle.getContext('webgl');
  }

  public openCart() {
    this.dialog.open(DialogCartComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      disableClose: true,
    });
  }

  public async showPayment() {
    const isSign = await this.checkSignAuraLMS();
    if (!isSign){
      this.showSign();
      return;
    }
    this.panoViewer.lookAt(10, 0, this.hfov + 25);
    const videoSlug = 'aura-lms_info-right';
    setTimeout(() => {
      this.playFullscreenAnimation(videoSlug);
    }, 500);
    this.panoViewer.stopAutoRotate();
    if (this.rotateTimeoutId) {
      clearTimeout(this.rotateTimeoutId);
    }
    if (this.checkIsPlayMusic()) {
      this.toggleMusic();
    }
  }

  public async showProfile() {
    const isSign = await this.checkSignAuraLMS();
    if (isSign){
      this.router.navigate(['profile'])
      return;
    }
    this.showSign();
  }

  public async showSign() {
    const isSign = await this.checkSignAuraLMS();
    if (isSign) {
      return;
    }
    
    const dialogRef = this.dialog.open(SignComponent, {
      data: {}
    });
    const scr = dialogRef.afterClosed().subscribe(result => {
      this.panoViewer?.lookAt(this.panoViewer.getPitch(), this.panoViewer.getYaw(), this.hfov);
      scr.unsubscribe();
    });
    

    this.panoViewer.stopAutoRotate();
    if (this.rotateTimeoutId) {
      clearTimeout(this.rotateTimeoutId);
    }
    if (this.checkIsPlayMusic()) {
      this.toggleMusic();
    }
  }

  public openAuraBook() {
    this.panoViewer.lookAt(5, 0, (this.hfov ?? this.panoViewer.getHfov()) - 35);
    setTimeout(
      () => {
        this.panoViewer.loadScene('vkh', this.panoViewer.getPitch(), this.panoViewer.getYaw());
        setTimeout(
          () => {
            this.panoViewer.lookAt(10, 0, this.hfov + 25);
            const videoSlug = 'aura-lms_trailer';
            setTimeout(() => {
              this.playFullscreenAnimation(videoSlug);
            }, 500);
            this.panoViewer.stopAutoRotate();
            if (this.rotateTimeoutId) {
              clearTimeout(this.rotateTimeoutId);
            }
            if (this.checkIsPlayMusic()) {
              this.toggleMusic();
            }
          },
          500,
        );
      },
      500,
    );
  }

  public ngOnDestroy() {
    if (this.panoViewer) {
      this.panoViewer.destroy();
    }
  }

  public cacularRotateX(pitch: number) {
    const heightRotate = 4;
    var rotateXx = 90 + heightRotate - (pitch || 0);
    if (pitch > -heightRotate) {
      return 90;
    }
    return rotateXx + heightRotate + 180; 
  }

  public async checkSignAuraLMS() {
    try {
      const profile = await this.auraService.profile();
      return true;
    }
    catch {
      // const dialogRef = this.dialog.open(DialogConfirmInfoComponent, {
      //   data: {
      //     title: 'Thông báo.',
      //     content: 'Bạn cần đăng nhập hoặc đăng ký một tài khoản để bắt đầu học tập!'
      //   }
      // });
      // const scr = dialogRef.afterClosed().subscribe(result => {
      //   if (result) {
      //     this.router.navigate(['login']);
      //   }
      //   scr.unsubscribe();
      // });
      return false;
    }
  }


  // dev feature
  public contextmenu: boolean = false;
  public contextmenuX: number = 0;
  public contextmenuY: number = 0;
  public isDev = false;

  public onrightClick(event: MouseEvent){
    if (!this.isDev) {
      return;
    }
    this.contextmenuX=event.clientX
    this.contextmenuY=event.clientY
    this.contextmenu=true;

    // this.panoViewer.setYawBounds([90, 120]);
    // this.panoViewer.getYawBounds();
    // this.panoViewer.getPitchBounds();
  }

  public disableContextMenu(){
    this.contextmenu= false;
  }
  
  public sceneConfig() {
    this.dialog.open(DialogSceneConfigComponent, {
      data: {panoViewer: this.panoViewer},
    });
  }

  public hotSpotConfig() {
    this.dialog.open(DialogHotSpotConfigComponent, {
      data: {
        panoViewer: this.panoViewer,
        createTooltipFunc: this.createCustomHotSpot,
        root: this,
      },
    });
  }

  public removeHotSpot() {
    const nearHotSpot = this.getNearhotSpot(undefined, undefined);
    const idHotSpot = nearHotSpot.id;

    this.panoViewer.lookAt(nearHotSpot.pitch, nearHotSpot.yaw);
    const dialogRef = this.dialog.open(DialogConfirmInfoComponent, {
      data: {title: 'Delete this hot spot?', content: 'This hot spot is irreversible and cannot be recovered, but you can recreate it. Do you want to delete it?'},
    });
    const scr = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.panoViewer.removeHotSpot(idHotSpot);
      }
      scr.unsubscribe();
    });
  }

  public exportConfig() {
    this.dialog.open(DialogExportConfigComponent, {
      data: {panoViewer: this.panoViewer},
    });
  }

  public importConfig() {
    const dialogRef = this.dialog.open(DialogImportConfigComponent, {
      data: {panoViewer: this.panoViewer},
    });
    const scr = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createPanoViewer(result);
      }
      scr.unsubscribe();
    });
  }

  private getNearhotSpot(x1Iniput: any, y1Input: any) {
    const currentSceneId = this.panoViewer.getScene();
    const hotSpots = this.panoViewer.getConfig().scenes[currentSceneId].hotSpots;
    function distanceCaculator(x1: number, y1: number, x2: number, y2: number): number {
      return Math.sqrt(Math.pow(x2-x1,2) + Math.pow(y2-y1,2));
    }
    const x1 = x1Iniput != undefined ? x1Iniput : this.panoViewer.getYaw();
    const y1 = y1Input != undefined ? x1Iniput : this.panoViewer.getPitch();
    let result = hotSpots[0];
    let disResult = distanceCaculator(x1, y1, result.yaw, result.pitch);
    for (let k in hotSpots) {
      const resultTmp = hotSpots[k];
      const disResultTmp = distanceCaculator(x1, y1, resultTmp.yaw, resultTmp.pitch);
      if (disResultTmp < disResult) {
        disResult = disResultTmp;
        result = resultTmp;
      }
    }
    return result;
  }


}
