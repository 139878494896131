{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5U~WMxt7s;j[kCayayofayayj[j[jtjtWBayayoff6fjofj[ofWBf7j[ayj@ofayfkj[j[ayay",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}