{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5$~Wt7WBogj[ogWBj[fPfPWBfQofayoLj[j[ofj[a}WBayoKa}kCWBf6WCj[WBa}jZj[ayoLWC",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 3,
        "cubeResolution": 1296
    }
}