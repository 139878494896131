{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5z~qRka}t8WCWUoJRkj[a|RjWBjufkayWCj[ayayazayfkazayayayj[f6WBj?jtj[ayj@j[az",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}