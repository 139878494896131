import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogWarningData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-dialog-warning',
  standalone: true,
  imports: [],
  templateUrl: './dialog-warning.component.html',
  styleUrl: './dialog-warning.component.css'
})
export class DialogWarningComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWarningData,
  ) { }

  public close(): void {
    this.dialogRef.close(false);
  }
  public confirm(): void {
    this.dialogRef.close(true);
  }
}
