<div class="row">
    <!-- <div class="col-12 col-sm-7 mt-1"><a routerLink="/" style="text-decoration: none;"><h1 class="text-light fw-bold"><i class="bi bi-globe"></i>&nbsp;Kết nối vòng quanh thế giới</h1></a></div> -->
    <div class="col-12 col-sm-7 mt-1"><a routerLink="/" style="text-decoration: none;">
        <img src="/assets/images/logo_aura.svg"/>
    </a></div>
    <div class="col mt-2">
        <div *ngIf="isSignIn" class="text-end">
            <a (click)="logout()" class="btn btn-danger">
                Logout
            </a>
        </div>
        <div *ngIf="!isSignIn" class="text-end">
            <a class="btn btn-outline-danger me-3" routerLink="/login">
                Đăng nhập
            </a>
            <a class="btn btn-danger" routerLink="/signup">
                Đăng ký
            </a>
        </div>
    </div>
</div>