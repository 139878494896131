{
    "default": {
        "autoLoad": true,
        "compass": true,
        "firstScene": "i13",
        "sceneFadeDuration": 1000,
        "showControls": false,
        "yaw": -108.01314517500646,
        "pitch": -1.8149023387049046
    },
    "scenes": {
        "o1": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "o1-0",
                    "yaw": 21.276091645708334,
                    "targetYaw": 77.32712642918705,
                    "pitch": -0.6640550159828216,
                    "targetPitch": -3.0916372630924016,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 21.276091645708334,
                        "targetYaw": 77.32712642918705,
                        "pitch": -0.6640550159828216,
                        "targetPitch": -3.0916372630924016,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "o8"
                    },
                    "div": {}
                },
                {
                    "id": "o1-1",
                    "yaw": -9.34289419764309,
                    "targetYaw": -9.34289419764309,
                    "pitch": -13.476486593083747,
                    "targetPitch": -13.476486593083747,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -9.34289419764309,
                        "targetYaw": -9.34289419764309,
                        "pitch": -13.476486593083747,
                        "targetPitch": -13.476486593083747,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa1"
                    },
                    "div": {}
                },
                {
                    "id": "o1-2",
                    "yaw": -0.685253110232452,
                    "targetYaw": -0.685253110232452,
                    "pitch": -16.358012603560947,
                    "targetPitch": -16.358012603560947,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.685253110232452,
                        "targetYaw": -0.685253110232452,
                        "pitch": -16.358012603560947,
                        "targetPitch": -16.358012603560947,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa2"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5X~qNLocWCjsofaet7aza#WAofWBfkfQofR*j@axj[ayj[j[t7ofoffSfRayoLofWBWCj?j[j[",
                "path": "/assets/pano/default/o1/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/o1/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 4,
                "cubeResolution": 2544
            }
        },
        "i1": {
            "type": "multires",
            "panorama": null,
            "hotSpots": [
                {
                    "id": "i1-0",
                    "yaw": -38.47507433416229,
                    "targetYaw": -38.47507433416229,
                    "pitch": -8.143859476395395,
                    "targetPitch": -8.143859476395395,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -38.47507433416229,
                        "targetYaw": -38.47507433416229,
                        "pitch": -8.143859476395395,
                        "targetPitch": -8.143859476395395,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i1-1",
                    "yaw": 33.73735556000429,
                    "targetYaw": 33.73735556000429,
                    "pitch": -8.443222598407628,
                    "targetPitch": -8.443222598407628,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 33.73735556000429,
                        "targetYaw": 33.73735556000429,
                        "pitch": -8.443222598407628,
                        "targetPitch": -8.443222598407628,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i1-2",
                    "yaw": -22.574801475775757,
                    "targetYaw": -22.574801475775757,
                    "pitch": -11.215688144809821,
                    "targetPitch": -11.215688144809821,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -22.574801475775757,
                        "targetYaw": -22.574801475775757,
                        "pitch": -11.215688144809821,
                        "targetPitch": -11.215688144809821,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i1-3",
                    "yaw": -0.09039366307081309,
                    "targetYaw": -0.09039366307081309,
                    "pitch": -11.849720354370735,
                    "targetPitch": -11.849720354370735,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.09039366307081309,
                        "targetYaw": -0.09039366307081309,
                        "pitch": -11.849720354370735,
                        "targetPitch": -11.849720354370735,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i1-4",
                    "yaw": 21.43235136727924,
                    "targetYaw": 21.43235136727924,
                    "pitch": -11.931924594929724,
                    "targetPitch": -11.931924594929724,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 21.43235136727924,
                        "targetYaw": 21.43235136727924,
                        "pitch": -11.931924594929724,
                        "targetPitch": -11.931924594929724,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i1-5",
                    "yaw": 94.08237841740441,
                    "targetYaw": 94.08237841740441,
                    "pitch": -23.91651434321532,
                    "targetPitch": -23.91651434321532,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 94.08237841740441,
                        "targetYaw": 94.08237841740441,
                        "pitch": -23.91651434321532,
                        "targetPitch": -23.91651434321532,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i1-6",
                    "yaw": -178.59941887117947,
                    "targetYaw": -178.59941887117947,
                    "pitch": -26.880288575141087,
                    "targetPitch": -26.880288575141087,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -178.59941887117947,
                        "targetYaw": -178.59941887117947,
                        "pitch": -26.880288575141087,
                        "targetPitch": -26.880288575141087,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i1-7",
                    "yaw": -90.42110644123744,
                    "targetYaw": -90.42110644123744,
                    "pitch": -29.663629412938388,
                    "targetPitch": -29.663629412938388,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -90.42110644123744,
                        "targetYaw": -90.42110644123744,
                        "pitch": -29.663629412938388,
                        "targetPitch": -29.663629412938388,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i1-8",
                    "yaw": 11.634683209763125,
                    "targetYaw": 11.634683209763125,
                    "pitch": -7.526911724208131,
                    "targetPitch": -7.526911724208131,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 11.634683209763125,
                        "targetYaw": 11.634683209763125,
                        "pitch": -7.526911724208131,
                        "targetPitch": -7.526911724208131,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i1-9",
                    "yaw": -0.2387092650027398,
                    "targetYaw": -0.2387092650027398,
                    "pitch": -7.69859787806717,
                    "targetPitch": -7.69859787806717,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.2387092650027398,
                        "targetYaw": -0.2387092650027398,
                        "pitch": -7.69859787806717,
                        "targetPitch": -7.69859787806717,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i1-10",
                    "yaw": -14.618425565032567,
                    "targetYaw": -14.618425565032567,
                    "pitch": -7.448770579296078,
                    "targetPitch": -7.448770579296078,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -14.618425565032567,
                        "targetYaw": -14.618425565032567,
                        "pitch": -7.448770579296078,
                        "targetPitch": -7.448770579296078,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5;~pRja}offkkCofj[jsj@ayoffkazf6kCj[kCfkfkj[j?azayayaya}ayjsf6j@j[j[fPjtf6",
                "path": "/assets/pano/default/i1/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i1/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i2": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i2-0",
                    "yaw": -179.8518041544055,
                    "targetYaw": -179.8518041544055,
                    "pitch": -5.809295722234075,
                    "targetPitch": -5.809295722234075,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -179.8518041544055,
                        "targetYaw": -179.8518041544055,
                        "pitch": -5.809295722234075,
                        "targetPitch": -5.809295722234075,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-1",
                    "yaw": -43.215344666220915,
                    "targetYaw": -43.215344666220915,
                    "pitch": -8.022331045690809,
                    "targetPitch": -8.022331045690809,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -43.215344666220915,
                        "targetYaw": -43.215344666220915,
                        "pitch": -8.022331045690809,
                        "targetPitch": -8.022331045690809,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-2",
                    "yaw": 159.30084720897503,
                    "targetYaw": 159.30084720897503,
                    "pitch": -8.256390506893325,
                    "targetPitch": -8.256390506893325,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 159.30084720897503,
                        "targetYaw": 159.30084720897503,
                        "pitch": -8.256390506893325,
                        "targetPitch": -8.256390506893325,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-3",
                    "yaw": -162.46846012306275,
                    "targetYaw": -162.46846012306275,
                    "pitch": -9.139135641903422,
                    "targetPitch": -9.139135641903422,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -162.46846012306275,
                        "targetYaw": -162.46846012306275,
                        "pitch": -9.139135641903422,
                        "targetPitch": -9.139135641903422,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-4",
                    "yaw": 37.5841084347137,
                    "targetYaw": 37.5841084347137,
                    "pitch": -9.383802508226058,
                    "targetPitch": -9.383802508226058,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 37.5841084347137,
                        "targetYaw": 37.5841084347137,
                        "pitch": -9.383802508226058,
                        "targetPitch": -9.383802508226058,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-5",
                    "yaw": -179.6913667264953,
                    "targetYaw": -179.6913667264953,
                    "pitch": -10.575318769019326,
                    "targetPitch": -10.575318769019326,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -179.6913667264953,
                        "targetYaw": -179.6913667264953,
                        "pitch": -10.575318769019326,
                        "targetPitch": -10.575318769019326,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i2-6",
                    "yaw": -62.534799955911694,
                    "targetYaw": -62.534799955911694,
                    "pitch": -11.209231925159578,
                    "targetPitch": -11.209231925159578,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -62.534799955911694,
                        "targetYaw": -62.534799955911694,
                        "pitch": -11.209231925159578,
                        "targetPitch": -11.209231925159578,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-7",
                    "yaw": -96.52983119833675,
                    "targetYaw": -96.52983119833675,
                    "pitch": -11.37007930186629,
                    "targetPitch": -11.37007930186629,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -96.52983119833675,
                        "targetYaw": -96.52983119833675,
                        "pitch": -11.37007930186629,
                        "targetPitch": -11.37007930186629,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-8",
                    "yaw": 20.031041823510577,
                    "targetYaw": 20.031041823510577,
                    "pitch": -12.900842682390444,
                    "targetPitch": -12.900842682390444,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 20.031041823510577,
                        "targetYaw": 20.031041823510577,
                        "pitch": -12.900842682390444,
                        "targetPitch": -12.900842682390444,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i2-9",
                    "yaw": -27.762940301096478,
                    "targetYaw": -27.762940301096478,
                    "pitch": -12.994281016883747,
                    "targetPitch": -12.994281016883747,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -27.762940301096478,
                        "targetYaw": -27.762940301096478,
                        "pitch": -12.994281016883747,
                        "targetPitch": -12.994281016883747,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                },
                {
                    "id": "i2-10",
                    "yaw": 56.80020327614545,
                    "targetYaw": 56.80020327614545,
                    "pitch": -13.10512661691989,
                    "targetPitch": -13.10512661691989,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 56.80020327614545,
                        "targetYaw": 56.80020327614545,
                        "pitch": -13.10512661691989,
                        "targetPitch": -13.10512661691989,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-11",
                    "yaw": 97.18877980646755,
                    "targetYaw": 97.18877980646755,
                    "pitch": -13.95316850522805,
                    "targetPitch": -13.95316850522805,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 97.18877980646755,
                        "targetYaw": 97.18877980646755,
                        "pitch": -13.95316850522805,
                        "targetPitch": -13.95316850522805,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i2-12",
                    "yaw": -35.622528497140195,
                    "targetYaw": -35.622528497140195,
                    "pitch": -19.363936655777778,
                    "targetPitch": -19.363936655777778,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -35.622528497140195,
                        "targetYaw": -35.622528497140195,
                        "pitch": -19.363936655777778,
                        "targetPitch": -19.363936655777778,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i2-13",
                    "yaw": 30.063294622253466,
                    "targetYaw": 30.063294622253466,
                    "pitch": -19.866046128734137,
                    "targetPitch": -19.866046128734137,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 30.063294622253466,
                        "targetYaw": 30.063294622253466,
                        "pitch": -19.866046128734137,
                        "targetPitch": -19.866046128734137,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i2-14",
                    "yaw": -0.016756717150769873,
                    "targetYaw": -0.016756717150769873,
                    "pitch": -23.554438860190487,
                    "targetPitch": -23.554438860190487,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.016756717150769873,
                        "targetYaw": -0.016756717150769873,
                        "pitch": -23.554438860190487,
                        "targetPitch": -23.554438860190487,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i2-15",
                    "yaw": -94.48551887292263,
                    "targetYaw": -94.48551887292263,
                    "pitch": -25.860420605671482,
                    "targetPitch": -25.860420605671482,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -94.48551887292263,
                        "targetYaw": -94.48551887292263,
                        "pitch": -25.860420605671482,
                        "targetPitch": -25.860420605671482,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i2-16",
                    "yaw": 97.98318470788877,
                    "targetYaw": 97.98318470788877,
                    "pitch": -27.74210167437375,
                    "targetPitch": -27.74210167437375,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 97.98318470788877,
                        "targetYaw": 97.98318470788877,
                        "pitch": -27.74210167437375,
                        "targetPitch": -27.74210167437375,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i2-17",
                    "yaw": 128.2292716664856,
                    "targetYaw": 128.2292716664856,
                    "pitch": -8.62168797847935,
                    "targetPitch": -8.62168797847935,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 128.2292716664856,
                        "targetYaw": 128.2292716664856,
                        "pitch": -8.62168797847935,
                        "targetPitch": -8.62168797847935,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "o8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~pRja}offQayofjsazj@j[j[j[fQjsj]fkj[ayj[j[fRayfPazfQjtj[fRfQj[kCfQayf6az",
                "path": "/assets/pano/default/i2/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i2/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i3": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i3-0",
                    "yaw": 162.4668528277079,
                    "targetYaw": 162.4668528277079,
                    "pitch": -5.092545026454515,
                    "targetPitch": -5.092545026454515,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 162.4668528277079,
                        "targetYaw": 162.4668528277079,
                        "pitch": -5.092545026454515,
                        "targetPitch": -5.092545026454515,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-1",
                    "yaw": 51.91064898410781,
                    "targetYaw": 51.91064898410781,
                    "pitch": -6.322878120028968,
                    "targetPitch": -6.322878120028968,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 51.91064898410781,
                        "targetYaw": 51.91064898410781,
                        "pitch": -6.322878120028968,
                        "targetPitch": -6.322878120028968,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-2",
                    "yaw": 141.32644351830157,
                    "targetYaw": 141.32644351830157,
                    "pitch": -6.627208491763687,
                    "targetPitch": -6.627208491763687,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 141.32644351830157,
                        "targetYaw": 141.32644351830157,
                        "pitch": -6.627208491763687,
                        "targetPitch": -6.627208491763687,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-3",
                    "yaw": 94.11050668570113,
                    "targetYaw": 94.11050668570113,
                    "pitch": -8.063061122136851,
                    "targetPitch": -8.063061122136851,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 94.11050668570113,
                        "targetYaw": 94.11050668570113,
                        "pitch": -8.063061122136851,
                        "targetPitch": -8.063061122136851,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-4",
                    "yaw": 69.81303713466326,
                    "targetYaw": 69.81303713466326,
                    "pitch": -8.149860236943601,
                    "targetPitch": -8.149860236943601,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 69.81303713466326,
                        "targetYaw": 69.81303713466326,
                        "pitch": -8.149860236943601,
                        "targetPitch": -8.149860236943601,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-5",
                    "yaw": -1.1680818347643935,
                    "targetYaw": -1.1680818347643935,
                    "pitch": -9.202879611437444,
                    "targetPitch": -9.202879611437444,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -1.1680818347643935,
                        "targetYaw": -1.1680818347643935,
                        "pitch": -9.202879611437444,
                        "targetPitch": -9.202879611437444,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i18"
                    },
                    "div": {}
                },
                {
                    "id": "i3-6",
                    "yaw": 158.6171052648109,
                    "targetYaw": 158.6171052648109,
                    "pitch": -9.988166262777014,
                    "targetPitch": -9.988166262777014,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 158.6171052648109,
                        "targetYaw": 158.6171052648109,
                        "pitch": -9.988166262777014,
                        "targetPitch": -9.988166262777014,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i3-7",
                    "yaw": 42.18693353724734,
                    "targetYaw": 42.18693353724734,
                    "pitch": -10.255311906562367,
                    "targetPitch": -10.255311906562367,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 42.18693353724734,
                        "targetYaw": 42.18693353724734,
                        "pitch": -10.255311906562367,
                        "targetPitch": -10.255311906562367,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i3-8",
                    "yaw": 177.8658607162413,
                    "targetYaw": 177.8658607162413,
                    "pitch": -11.866562723850434,
                    "targetPitch": -11.866562723850434,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 177.8658607162413,
                        "targetYaw": 177.8658607162413,
                        "pitch": -11.866562723850434,
                        "targetPitch": -11.866562723850434,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-9",
                    "yaw": 54.70681044749108,
                    "targetYaw": 54.70681044749108,
                    "pitch": -13.32889554206316,
                    "targetPitch": -13.32889554206316,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 54.70681044749108,
                        "targetYaw": 54.70681044749108,
                        "pitch": -13.32889554206316,
                        "targetPitch": -13.32889554206316,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i3-10",
                    "yaw": 27.654785342632636,
                    "targetYaw": 27.654785342632636,
                    "pitch": -13.353181244053339,
                    "targetPitch": -13.353181244053339,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 27.654785342632636,
                        "targetYaw": 27.654785342632636,
                        "pitch": -13.353181244053339,
                        "targetPitch": -13.353181244053339,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-11",
                    "yaw": 0.0885823782633679,
                    "targetYaw": 0.0885823782633679,
                    "pitch": -14.956270100503644,
                    "targetPitch": -14.956270100503644,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.0885823782633679,
                        "targetYaw": 0.0885823782633679,
                        "pitch": -14.956270100503644,
                        "targetPitch": -14.956270100503644,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                },
                {
                    "id": "i3-12",
                    "yaw": 94.09063232230221,
                    "targetYaw": 94.09063232230221,
                    "pitch": -15.796985868662114,
                    "targetPitch": -15.796985868662114,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 94.09063232230221,
                        "targetYaw": 94.09063232230221,
                        "pitch": -15.796985868662114,
                        "targetPitch": -15.796985868662114,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i3-13",
                    "yaw": 37.577764952488906,
                    "targetYaw": 37.577764952488906,
                    "pitch": -19.187940313313764,
                    "targetPitch": -19.187940313313764,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 37.577764952488906,
                        "targetYaw": 37.577764952488906,
                        "pitch": -19.187940313313764,
                        "targetPitch": -19.187940313313764,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i3-14",
                    "yaw": -40.720869304113194,
                    "targetYaw": -40.720869304113194,
                    "pitch": -20.056924931316242,
                    "targetPitch": -20.056924931316242,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -40.720869304113194,
                        "targetYaw": -40.720869304113194,
                        "pitch": -20.056924931316242,
                        "targetPitch": -20.056924931316242,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-15",
                    "yaw": 10.547395137198919,
                    "targetYaw": 10.547395137198919,
                    "pitch": -21.865488995138143,
                    "targetPitch": -21.865488995138143,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 10.547395137198919,
                        "targetYaw": 10.547395137198919,
                        "pitch": -21.865488995138143,
                        "targetPitch": -21.865488995138143,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i3-16",
                    "yaw": -103.44286095252221,
                    "targetYaw": -103.44286095252221,
                    "pitch": -22.476414520991774,
                    "targetPitch": -22.476414520991774,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -103.44286095252221,
                        "targetYaw": -103.44286095252221,
                        "pitch": -22.476414520991774,
                        "targetPitch": -22.476414520991774,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i3-17",
                    "yaw": 95.49966503912356,
                    "targetYaw": 95.49966503912356,
                    "pitch": -30.658794312328833,
                    "targetPitch": -30.658794312328833,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 95.49966503912356,
                        "targetYaw": 95.49966503912356,
                        "pitch": -30.658794312328833,
                        "targetPitch": -30.658794312328833,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i3-18",
                    "yaw": 120.27591497877347,
                    "targetYaw": 120.27591497877347,
                    "pitch": -7.1201268878478645,
                    "targetPitch": -7.1201268878478645,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 120.27591497877347,
                        "targetYaw": 120.27591497877347,
                        "pitch": -7.1201268878478645,
                        "targetPitch": -7.1201268878478645,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "o8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WWBa}ofazWVj[jsayfkWCj@j[j[j@azfRj[fQayj[j?kBjtaya|fkaya}j[ayfRjtfQj[j[",
                "path": "/assets/pano/default/i3/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i3/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i4": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i4-0",
                    "yaw": -165.08361787236709,
                    "targetYaw": -165.08361787236709,
                    "pitch": -5.577783595465704,
                    "targetPitch": -5.577783595465704,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -165.08361787236709,
                        "targetYaw": -165.08361787236709,
                        "pitch": -5.577783595465704,
                        "targetPitch": -5.577783595465704,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-1",
                    "yaw": -53.68479796175501,
                    "targetYaw": -53.68479796175501,
                    "pitch": -6.390986485886132,
                    "targetPitch": -6.390986485886132,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -53.68479796175501,
                        "targetYaw": -53.68479796175501,
                        "pitch": -6.390986485886132,
                        "targetPitch": -6.390986485886132,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-2",
                    "yaw": -70.03856638529567,
                    "targetYaw": -70.03856638529567,
                    "pitch": -7.687420868888542,
                    "targetPitch": -7.687420868888542,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -70.03856638529567,
                        "targetYaw": -70.03856638529567,
                        "pitch": -7.687420868888542,
                        "targetPitch": -7.687420868888542,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-3",
                    "yaw": -145.2375111950588,
                    "targetYaw": -145.2375111950588,
                    "pitch": -7.781682028038485,
                    "targetPitch": -7.781682028038485,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -145.2375111950588,
                        "targetYaw": -145.2375111950588,
                        "pitch": -7.781682028038485,
                        "targetPitch": -7.781682028038485,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-4",
                    "yaw": -2.003341462335584,
                    "targetYaw": -2.003341462335584,
                    "pitch": -7.9591605128081095,
                    "targetPitch": -7.9591605128081095,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -2.003341462335584,
                        "targetYaw": -2.003341462335584,
                        "pitch": -7.9591605128081095,
                        "targetPitch": -7.9591605128081095,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i4-5",
                    "yaw": -94.54503080635396,
                    "targetYaw": -94.54503080635396,
                    "pitch": -8.139261704538407,
                    "targetPitch": -8.139261704538407,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -94.54503080635396,
                        "targetYaw": -94.54503080635396,
                        "pitch": -8.139261704538407,
                        "targetPitch": -8.139261704538407,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-6",
                    "yaw": -161.44299037198414,
                    "targetYaw": -161.44299037198414,
                    "pitch": -10.03744938571572,
                    "targetPitch": -10.03744938571572,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -161.44299037198414,
                        "targetYaw": -161.44299037198414,
                        "pitch": -10.03744938571572,
                        "targetPitch": -10.03744938571572,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i4-7",
                    "yaw": 172.90248691437034,
                    "targetYaw": 172.90248691437034,
                    "pitch": -10.614419195514635,
                    "targetPitch": -10.614419195514635,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 172.90248691437034,
                        "targetYaw": 172.90248691437034,
                        "pitch": -10.614419195514635,
                        "targetPitch": -10.614419195514635,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-8",
                    "yaw": -31.078144245404644,
                    "targetYaw": -31.078144245404644,
                    "pitch": -13.316236319723899,
                    "targetPitch": -13.316236319723899,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -31.078144245404644,
                        "targetYaw": -31.078144245404644,
                        "pitch": -13.316236319723899,
                        "targetPitch": -13.316236319723899,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-9",
                    "yaw": -2.3171766749859444,
                    "targetYaw": -2.3171766749859444,
                    "pitch": -13.85221889182204,
                    "targetPitch": -13.85221889182204,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -2.3171766749859444,
                        "targetYaw": -2.3171766749859444,
                        "pitch": -13.85221889182204,
                        "targetPitch": -13.85221889182204,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i4-10",
                    "yaw": -53.01451803379382,
                    "targetYaw": -53.01451803379382,
                    "pitch": -14.027989006845974,
                    "targetPitch": -14.027989006845974,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -53.01451803379382,
                        "targetYaw": -53.01451803379382,
                        "pitch": -14.027989006845974,
                        "targetPitch": -14.027989006845974,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i4-11",
                    "yaw": -91.89385196354237,
                    "targetYaw": -91.89385196354237,
                    "pitch": -17.47702348987829,
                    "targetPitch": -17.47702348987829,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -91.89385196354237,
                        "targetYaw": -91.89385196354237,
                        "pitch": -17.47702348987829,
                        "targetPitch": -17.47702348987829,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i4-12",
                    "yaw": 35.395823496417506,
                    "targetYaw": 35.395823496417506,
                    "pitch": -18.307720547773748,
                    "targetPitch": -18.307720547773748,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 35.395823496417506,
                        "targetYaw": 35.395823496417506,
                        "pitch": -18.307720547773748,
                        "targetPitch": -18.307720547773748,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-13",
                    "yaw": -31.419826496363157,
                    "targetYaw": -31.419826496363157,
                    "pitch": -19.985194111175907,
                    "targetPitch": -19.985194111175907,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -31.419826496363157,
                        "targetYaw": -31.419826496363157,
                        "pitch": -19.985194111175907,
                        "targetPitch": -19.985194111175907,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i4-14",
                    "yaw": -3.648512860007031,
                    "targetYaw": -3.648512860007031,
                    "pitch": -22.3763850052281,
                    "targetPitch": -22.3763850052281,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -3.648512860007031,
                        "targetYaw": -3.648512860007031,
                        "pitch": -22.3763850052281,
                        "targetPitch": -22.3763850052281,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i4-15",
                    "yaw": 103.58151295379503,
                    "targetYaw": 103.58151295379503,
                    "pitch": -26.10551381275699,
                    "targetPitch": -26.10551381275699,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 103.58151295379503,
                        "targetYaw": 103.58151295379503,
                        "pitch": -26.10551381275699,
                        "targetPitch": -26.10551381275699,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i4-16",
                    "yaw": -91.75633436468866,
                    "targetYaw": -91.75633436468866,
                    "pitch": -31.384722867229105,
                    "targetPitch": -31.384722867229105,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -91.75633436468866,
                        "targetYaw": -91.75633436468866,
                        "pitch": -31.384722867229105,
                        "targetPitch": -31.384722867229105,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i4-17",
                    "yaw": 144.32152965618013,
                    "targetYaw": 144.32152965618013,
                    "pitch": -11.641264750645085,
                    "targetPitch": -11.641264750645085,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 144.32152965618013,
                        "targetYaw": 144.32152965618013,
                        "pitch": -11.641264750645085,
                        "targetPitch": -11.641264750645085,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "o8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WV@a#oLayayj[jsayayayj[jsfQj[fkfRfQfQfQjsa}WBayayfQjtj[jtayj[j@fQazfQfQ",
                "path": "/assets/pano/default/i4/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i4/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i5": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i5-0",
                    "yaw": -179.4892106461931,
                    "targetYaw": -179.4892106461931,
                    "pitch": -7.928510819922642,
                    "targetPitch": -7.928510819922642,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -179.4892106461931,
                        "targetYaw": -179.4892106461931,
                        "pitch": -7.928510819922642,
                        "targetPitch": -7.928510819922642,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i5-1",
                    "yaw": 18.503942510134152,
                    "targetYaw": 18.503942510134152,
                    "pitch": -11.977184661676219,
                    "targetPitch": -11.977184661676219,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 18.503942510134152,
                        "targetYaw": 18.503942510134152,
                        "pitch": -11.977184661676219,
                        "targetPitch": -11.977184661676219,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i5-2",
                    "yaw": -45.95318191468948,
                    "targetYaw": -45.95318191468948,
                    "pitch": -17.877074653654564,
                    "targetPitch": -17.877074653654564,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -45.95318191468948,
                        "targetYaw": -45.95318191468948,
                        "pitch": -17.877074653654564,
                        "targetPitch": -17.877074653654564,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                },
                {
                    "id": "i5-3",
                    "yaw": -132.91628336538457,
                    "targetYaw": -132.91628336538457,
                    "pitch": -19.281152363414257,
                    "targetPitch": -19.281152363414257,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -132.91628336538457,
                        "targetYaw": -132.91628336538457,
                        "pitch": -19.281152363414257,
                        "targetPitch": -19.281152363414257,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i5-4",
                    "yaw": 141.45879403779082,
                    "targetYaw": 141.45879403779082,
                    "pitch": -20.998059867741983,
                    "targetPitch": -20.998059867741983,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 141.45879403779082,
                        "targetYaw": 141.45879403779082,
                        "pitch": -20.998059867741983,
                        "targetPitch": -20.998059867741983,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i5-5",
                    "yaw": 36.23963154667126,
                    "targetYaw": 36.23963154667126,
                    "pitch": -23.484336138610857,
                    "targetPitch": -23.484336138610857,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 36.23963154667126,
                        "targetYaw": 36.23963154667126,
                        "pitch": -23.484336138610857,
                        "targetPitch": -23.484336138610857,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i5-6",
                    "yaw": -178.2176922323422,
                    "targetYaw": -178.2176922323422,
                    "pitch": -27.403122699793737,
                    "targetPitch": -27.403122699793737,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -178.2176922323422,
                        "targetYaw": -178.2176922323422,
                        "pitch": -27.403122699793737,
                        "targetPitch": -27.403122699793737,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i5-7",
                    "yaw": -85.83462153068228,
                    "targetYaw": -85.83462153068228,
                    "pitch": -29.006790449653025,
                    "targetPitch": -29.006790449653025,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -85.83462153068228,
                        "targetYaw": -85.83462153068228,
                        "pitch": -29.006790449653025,
                        "targetPitch": -29.006790449653025,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i5-8",
                    "yaw": 84.52157897915981,
                    "targetYaw": 84.52157897915981,
                    "pitch": -32.977963798682005,
                    "targetPitch": -32.977963798682005,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 84.52157897915981,
                        "targetYaw": 84.52157897915981,
                        "pitch": -32.977963798682005,
                        "targetPitch": -32.977963798682005,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i5-9",
                    "yaw": -12.33552416389955,
                    "targetYaw": -12.33552416389955,
                    "pitch": -31.19121190392826,
                    "targetPitch": -31.19121190392826,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -12.33552416389955,
                        "targetYaw": -12.33552416389955,
                        "pitch": -31.19121190392826,
                        "targetPitch": -31.19121190392826,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-10",
                    "yaw": -60.377308916698325,
                    "targetYaw": -60.377308916698325,
                    "pitch": -10.477368691517675,
                    "targetPitch": -10.477368691517675,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -60.377308916698325,
                        "targetYaw": -60.377308916698325,
                        "pitch": -10.477368691517675,
                        "targetPitch": -10.477368691517675,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-11",
                    "yaw": -90.32878199700855,
                    "targetYaw": -90.32878199700855,
                    "pitch": -12.460536117708163,
                    "targetPitch": -12.460536117708163,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -90.32878199700855,
                        "targetYaw": -90.32878199700855,
                        "pitch": -12.460536117708163,
                        "targetPitch": -12.460536117708163,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-12",
                    "yaw": -120.98140219025129,
                    "targetYaw": -120.98140219025129,
                    "pitch": -9.550181017351855,
                    "targetPitch": -9.550181017351855,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -120.98140219025129,
                        "targetYaw": -120.98140219025129,
                        "pitch": -9.550181017351855,
                        "targetPitch": -9.550181017351855,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-13",
                    "yaw": -165.74000023256986,
                    "targetYaw": -165.74000023256986,
                    "pitch": -7.3754955105748685,
                    "targetPitch": -7.3754955105748685,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -165.74000023256986,
                        "targetYaw": -165.74000023256986,
                        "pitch": -7.3754955105748685,
                        "targetPitch": -7.3754955105748685,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-14",
                    "yaw": 175.0880217237422,
                    "targetYaw": 175.0880217237422,
                    "pitch": -4.487832589181684,
                    "targetPitch": -4.487832589181684,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 175.0880217237422,
                        "targetYaw": 175.0880217237422,
                        "pitch": -4.487832589181684,
                        "targetPitch": -4.487832589181684,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-15",
                    "yaw": 162.4121912265341,
                    "targetYaw": 162.4121912265341,
                    "pitch": -6.756923868248185,
                    "targetPitch": -6.756923868248185,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 162.4121912265341,
                        "targetYaw": 162.4121912265341,
                        "pitch": -6.756923868248185,
                        "targetPitch": -6.756923868248185,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-16",
                    "yaw": 125.16547918956019,
                    "targetYaw": 125.16547918956019,
                    "pitch": -11.028765090487733,
                    "targetPitch": -11.028765090487733,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 125.16547918956019,
                        "targetYaw": 125.16547918956019,
                        "pitch": -11.028765090487733,
                        "targetPitch": -11.028765090487733,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-17",
                    "yaw": 88.29499991800981,
                    "targetYaw": 88.29499991800981,
                    "pitch": -13.606658023102339,
                    "targetPitch": -13.606658023102339,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 88.29499991800981,
                        "targetYaw": 88.29499991800981,
                        "pitch": -13.606658023102339,
                        "targetPitch": -13.606658023102339,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i5-18",
                    "yaw": 54.18166679628155,
                    "targetYaw": 54.18166679628155,
                    "pitch": -11.597362906792293,
                    "targetPitch": -11.597362906792293,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 54.18166679628155,
                        "targetYaw": 54.18166679628155,
                        "pitch": -11.597362906792293,
                        "targetPitch": -11.597362906792293,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WV@kCofaxWVj[ayj[j[fkj[ofayayj[ayj[j[j[j@j[j[fQj[ayayfkayj[j[fQj@j[ayfQ",
                "path": "/assets/pano/default/i5/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i5/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i6": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i6-0",
                    "yaw": 173.46355655679122,
                    "targetYaw": 173.46355655679122,
                    "pitch": -5.126200684072725,
                    "targetPitch": -5.126200684072725,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 173.46355655679122,
                        "targetYaw": 173.46355655679122,
                        "pitch": -5.126200684072725,
                        "targetPitch": -5.126200684072725,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-1",
                    "yaw": 145.1351346718231,
                    "targetYaw": 145.1351346718231,
                    "pitch": -5.270530666478613,
                    "targetPitch": -5.270530666478613,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 145.1351346718231,
                        "targetYaw": 145.1351346718231,
                        "pitch": -5.270530666478613,
                        "targetPitch": -5.270530666478613,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-2",
                    "yaw": -178.66059778511578,
                    "targetYaw": -178.66059778511578,
                    "pitch": -7.2655989521737645,
                    "targetPitch": -7.2655989521737645,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -178.66059778511578,
                        "targetYaw": -178.66059778511578,
                        "pitch": -7.2655989521737645,
                        "targetPitch": -7.2655989521737645,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-3",
                    "yaw": 64.57975328480188,
                    "targetYaw": 64.57975328480188,
                    "pitch": -7.333324861601242,
                    "targetPitch": -7.333324861601242,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 64.57975328480188,
                        "targetYaw": 64.57975328480188,
                        "pitch": -7.333324861601242,
                        "targetPitch": -7.333324861601242,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-4",
                    "yaw": 167.02682932718665,
                    "targetYaw": 167.02682932718665,
                    "pitch": -7.491501637847961,
                    "targetPitch": -7.491501637847961,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 167.02682932718665,
                        "targetYaw": 167.02682932718665,
                        "pitch": -7.491501637847961,
                        "targetPitch": -7.491501637847961,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i6-5",
                    "yaw": 114.06744706389964,
                    "targetYaw": 114.06744706389964,
                    "pitch": -7.691353792466968,
                    "targetPitch": -7.691353792466968,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 114.06744706389964,
                        "targetYaw": 114.06744706389964,
                        "pitch": -7.691353792466968,
                        "targetPitch": -7.691353792466968,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-6",
                    "yaw": 88.98323162503118,
                    "targetYaw": 88.98323162503118,
                    "pitch": -8.538333384371303,
                    "targetPitch": -8.538333384371303,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 88.98323162503118,
                        "targetYaw": 88.98323162503118,
                        "pitch": -8.538333384371303,
                        "targetPitch": -8.538333384371303,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-7",
                    "yaw": -6.892055543680613,
                    "targetYaw": -6.892055543680613,
                    "pitch": -8.858329806314776,
                    "targetPitch": -8.858329806314776,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -6.892055543680613,
                        "targetYaw": -6.892055543680613,
                        "pitch": -8.858329806314776,
                        "targetPitch": -8.858329806314776,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i6-8",
                    "yaw": 7.965441731252099,
                    "targetYaw": 7.965441731252099,
                    "pitch": -9.552340420723525,
                    "targetPitch": -9.552340420723525,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 7.965441731252099,
                        "targetYaw": 7.965441731252099,
                        "pitch": -9.552340420723525,
                        "targetPitch": -9.552340420723525,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-9",
                    "yaw": -23.626964740516655,
                    "targetYaw": -23.626964740516655,
                    "pitch": -9.552340420723525,
                    "targetPitch": -9.552340420723525,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -23.626964740516655,
                        "targetYaw": -23.626964740516655,
                        "pitch": -9.552340420723525,
                        "targetPitch": -9.552340420723525,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-10",
                    "yaw": 58.341096192990676,
                    "targetYaw": 58.341096192990676,
                    "pitch": -14.78362594580435,
                    "targetPitch": -14.78362594580435,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 58.341096192990676,
                        "targetYaw": 58.341096192990676,
                        "pitch": -14.78362594580435,
                        "targetPitch": -14.78362594580435,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i6-11",
                    "yaw": -9.093097626126905,
                    "targetYaw": -9.093097626126905,
                    "pitch": -14.845491690374192,
                    "targetPitch": -14.845491690374192,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -9.093097626126905,
                        "targetYaw": -9.093097626126905,
                        "pitch": -14.845491690374192,
                        "targetPitch": -14.845491690374192,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i18"
                    },
                    "div": {}
                },
                {
                    "id": "i6-12",
                    "yaw": 125.92819249567788,
                    "targetYaw": 125.92819249567788,
                    "pitch": -14.932323482163861,
                    "targetPitch": -14.932323482163861,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 125.92819249567788,
                        "targetYaw": 125.92819249567788,
                        "pitch": -14.932323482163861,
                        "targetPitch": -14.932323482163861,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i6-13",
                    "yaw": -42.10307443094729,
                    "targetYaw": -42.10307443094729,
                    "pitch": -15.01815095837333,
                    "targetPitch": -15.01815095837333,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -42.10307443094729,
                        "targetYaw": -42.10307443094729,
                        "pitch": -15.01815095837333,
                        "targetPitch": -15.01815095837333,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-14",
                    "yaw": -137.02694212855403,
                    "targetYaw": -137.02694212855403,
                    "pitch": -15.129714769971534,
                    "targetPitch": -15.129714769971534,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -137.02694212855403,
                        "targetYaw": -137.02694212855403,
                        "pitch": -15.129714769971534,
                        "targetPitch": -15.129714769971534,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-15",
                    "yaw": 84.64917375990797,
                    "targetYaw": 84.64917375990797,
                    "pitch": -18.44592217295864,
                    "targetPitch": -18.44592217295864,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 84.64917375990797,
                        "targetYaw": 84.64917375990797,
                        "pitch": -18.44592217295864,
                        "targetPitch": -18.44592217295864,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i6-16",
                    "yaw": -87.37954396150997,
                    "targetYaw": -87.37954396150997,
                    "pitch": -20.144608130246013,
                    "targetPitch": -20.144608130246013,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -87.37954396150997,
                        "targetYaw": -87.37954396150997,
                        "pitch": -20.144608130246013,
                        "targetPitch": -20.144608130246013,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-17",
                    "yaw": 155.50282977685833,
                    "targetYaw": 155.50282977685833,
                    "pitch": -21.807655367726586,
                    "targetPitch": -21.807655367726586,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 155.50282977685833,
                        "targetYaw": 155.50282977685833,
                        "pitch": -21.807655367726586,
                        "targetPitch": -21.807655367726586,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i6-18",
                    "yaw": -171.58262718844298,
                    "targetYaw": -171.58262718844298,
                    "pitch": -22.996227696550093,
                    "targetPitch": -22.996227696550093,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -171.58262718844298,
                        "targetYaw": -171.58262718844298,
                        "pitch": -22.996227696550093,
                        "targetPitch": -22.996227696550093,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i6-19",
                    "yaw": 40.3849991860817,
                    "targetYaw": 40.3849991860817,
                    "pitch": -24.116055115537645,
                    "targetPitch": -24.116055115537645,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 40.3849991860817,
                        "targetYaw": 40.3849991860817,
                        "pitch": -24.116055115537645,
                        "targetPitch": -24.116055115537645,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i6-20",
                    "yaw": -12.342119546921936,
                    "targetYaw": -12.342119546921936,
                    "pitch": -26.587342655124196,
                    "targetPitch": -26.587342655124196,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -12.342119546921936,
                        "targetYaw": -12.342119546921936,
                        "pitch": -26.587342655124196,
                        "targetPitch": -26.587342655124196,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                },
                {
                    "id": "i6-21",
                    "yaw": 82.83631666177371,
                    "targetYaw": 82.83631666177371,
                    "pitch": -36.43460780813865,
                    "targetPitch": -36.43460780813865,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 82.83631666177371,
                        "targetYaw": 82.83631666177371,
                        "pitch": -36.43460780813865,
                        "targetPitch": -36.43460780813865,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WV@j]ofayayj[f6ayj[ayj[j@fkayj[ayj@j[ayayj@j[ayf6j[j[ayj[ayayazayayfQfR",
                "path": "/assets/pano/default/i6/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i6/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i8": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i8-0",
                    "yaw": -176.60921546932798,
                    "targetYaw": -176.60921546932798,
                    "pitch": -4.584672948696463,
                    "targetPitch": -4.584672948696463,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -176.60921546932798,
                        "targetYaw": -176.60921546932798,
                        "pitch": -4.584672948696463,
                        "targetPitch": -4.584672948696463,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-1",
                    "yaw": -113.24313704896497,
                    "targetYaw": -113.24313704896497,
                    "pitch": -6.772466224751151,
                    "targetPitch": -6.772466224751151,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -113.24313704896497,
                        "targetYaw": -113.24313704896497,
                        "pitch": -6.772466224751151,
                        "targetPitch": -6.772466224751151,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-2",
                    "yaw": -152.5835600449327,
                    "targetYaw": -152.5835600449327,
                    "pitch": -7.210433657115015,
                    "targetPitch": -7.210433657115015,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -152.5835600449327,
                        "targetYaw": -152.5835600449327,
                        "pitch": -7.210433657115015,
                        "targetPitch": -7.210433657115015,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-3",
                    "yaw": -168.32608511570396,
                    "targetYaw": -168.32608511570396,
                    "pitch": -7.606525389701577,
                    "targetPitch": -7.606525389701577,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -168.32608511570396,
                        "targetYaw": -168.32608511570396,
                        "pitch": -7.606525389701577,
                        "targetPitch": -7.606525389701577,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i8-4",
                    "yaw": 172.43230762547375,
                    "targetYaw": 172.43230762547375,
                    "pitch": -7.663191159060997,
                    "targetPitch": -7.663191159060997,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 172.43230762547375,
                        "targetYaw": 172.43230762547375,
                        "pitch": -7.663191159060997,
                        "targetPitch": -7.663191159060997,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-5",
                    "yaw": -89.9692282928188,
                    "targetYaw": -89.9692282928188,
                    "pitch": -7.911572775798615,
                    "targetPitch": -7.911572775798615,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -89.9692282928188,
                        "targetYaw": -89.9692282928188,
                        "pitch": -7.911572775798615,
                        "targetPitch": -7.911572775798615,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-6",
                    "yaw": -0.775946006744789,
                    "targetYaw": -0.775946006744789,
                    "pitch": -12.23451017481893,
                    "targetPitch": -12.23451017481893,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.775946006744789,
                        "targetYaw": -0.775946006744789,
                        "pitch": -12.23451017481893,
                        "targetPitch": -12.23451017481893,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i8-7",
                    "yaw": 141.58014172117998,
                    "targetYaw": 141.58014172117998,
                    "pitch": -15.734211070607033,
                    "targetPitch": -15.734211070607033,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 141.58014172117998,
                        "targetYaw": 141.58014172117998,
                        "pitch": -15.734211070607033,
                        "targetPitch": -15.734211070607033,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-8",
                    "yaw": 37.4463720627424,
                    "targetYaw": 37.4463720627424,
                    "pitch": -16.254472884304235,
                    "targetPitch": -16.254472884304235,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 37.4463720627424,
                        "targetYaw": 37.4463720627424,
                        "pitch": -16.254472884304235,
                        "targetPitch": -16.254472884304235,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-9",
                    "yaw": -121.78152319448589,
                    "targetYaw": -121.78152319448589,
                    "pitch": -16.270607711926104,
                    "targetPitch": -16.270607711926104,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -121.78152319448589,
                        "targetYaw": -121.78152319448589,
                        "pitch": -16.270607711926104,
                        "targetPitch": -16.270607711926104,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i8-10",
                    "yaw": -86.90023719327377,
                    "targetYaw": -86.90023719327377,
                    "pitch": -18.06129950065583,
                    "targetPitch": -18.06129950065583,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -86.90023719327377,
                        "targetYaw": -86.90023719327377,
                        "pitch": -18.06129950065583,
                        "targetPitch": -18.06129950065583,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i8-11",
                    "yaw": -51.3794160380196,
                    "targetYaw": -51.3794160380196,
                    "pitch": -21.05053174482851,
                    "targetPitch": -21.05053174482851,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -51.3794160380196,
                        "targetYaw": -51.3794160380196,
                        "pitch": -21.05053174482851,
                        "targetPitch": -21.05053174482851,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-12",
                    "yaw": -0.5654488873058199,
                    "targetYaw": -0.5654488873058199,
                    "pitch": -25.83689566626817,
                    "targetPitch": -25.83689566626817,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.5654488873058199,
                        "targetYaw": -0.5654488873058199,
                        "pitch": -25.83689566626817,
                        "targetPitch": -25.83689566626817,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i8-13",
                    "yaw": -148.13869063182528,
                    "targetYaw": -148.13869063182528,
                    "pitch": -25.962694267809084,
                    "targetPitch": -25.962694267809084,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -148.13869063182528,
                        "targetYaw": -148.13869063182528,
                        "pitch": -25.962694267809084,
                        "targetPitch": -25.962694267809084,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i8-14",
                    "yaw": 87.24308560451186,
                    "targetYaw": 87.24308560451186,
                    "pitch": -28.136853264074915,
                    "targetPitch": -28.136853264074915,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 87.24308560451186,
                        "targetYaw": 87.24308560451186,
                        "pitch": -28.136853264074915,
                        "targetPitch": -28.136853264074915,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i8-15",
                    "yaw": 167.00644180634805,
                    "targetYaw": 167.00644180634805,
                    "pitch": -28.57943522396495,
                    "targetPitch": -28.57943522396495,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 167.00644180634805,
                        "targetYaw": 167.00644180634805,
                        "pitch": -28.57943522396495,
                        "targetPitch": -28.57943522396495,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i8-16",
                    "yaw": -84.49044683804459,
                    "targetYaw": -84.49044683804459,
                    "pitch": -39.36802058950856,
                    "targetPitch": -39.36802058950856,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -84.49044683804459,
                        "targetYaw": -84.49044683804459,
                        "pitch": -39.36802058950856,
                        "targetPitch": -39.36802058950856,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WWBj[ofayayj[f6aya|ayj[j@j[ayj[fRj[j[ayayfRayj[j[ayayj[ayfkj[j[j[j[j[j@",
                "path": "/assets/pano/default/i8/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i8/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i9": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i9-0",
                    "yaw": -170.7671452377167,
                    "targetYaw": -170.7671452377167,
                    "pitch": -4.880307217525362,
                    "targetPitch": -4.880307217525362,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -170.7671452377167,
                        "targetYaw": -170.7671452377167,
                        "pitch": -4.880307217525362,
                        "targetPitch": -4.880307217525362,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i9-1",
                    "yaw": -6.5598059749085,
                    "targetYaw": -6.5598059749085,
                    "pitch": -6.988745996462782,
                    "targetPitch": -6.988745996462782,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -6.5598059749085,
                        "targetYaw": -6.5598059749085,
                        "pitch": -6.988745996462782,
                        "targetPitch": -6.988745996462782,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i9-2",
                    "yaw": -142.79884855024102,
                    "targetYaw": -142.79884855024102,
                    "pitch": -10.780216080781155,
                    "targetPitch": -10.780216080781155,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -142.79884855024102,
                        "targetYaw": -142.79884855024102,
                        "pitch": -10.780216080781155,
                        "targetPitch": -10.780216080781155,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i9-3",
                    "yaw": -1.016918193847232,
                    "targetYaw": -1.016918193847232,
                    "pitch": -11.211294910954058,
                    "targetPitch": -11.211294910954058,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -1.016918193847232,
                        "targetYaw": -1.016918193847232,
                        "pitch": -11.211294910954058,
                        "targetPitch": -11.211294910954058,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i9-4",
                    "yaw": -161.61785462205262,
                    "targetYaw": -161.61785462205262,
                    "pitch": -13.017132511282906,
                    "targetPitch": -13.017132511282906,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -161.61785462205262,
                        "targetYaw": -161.61785462205262,
                        "pitch": -13.017132511282906,
                        "targetPitch": -13.017132511282906,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i9-5",
                    "yaw": 177.4521164697304,
                    "targetYaw": 177.4521164697304,
                    "pitch": -13.586957285479171,
                    "targetPitch": -13.586957285479171,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 177.4521164697304,
                        "targetYaw": 177.4521164697304,
                        "pitch": -13.586957285479171,
                        "targetPitch": -13.586957285479171,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i9-6",
                    "yaw": -119.09244019319044,
                    "targetYaw": -119.09244019319044,
                    "pitch": -14.896163088411805,
                    "targetPitch": -14.896163088411805,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -119.09244019319044,
                        "targetYaw": -119.09244019319044,
                        "pitch": -14.896163088411805,
                        "targetPitch": -14.896163088411805,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i9-7",
                    "yaw": 29.04695598609385,
                    "targetYaw": 29.04695598609385,
                    "pitch": -17.47569945690549,
                    "targetPitch": -17.47569945690549,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 29.04695598609385,
                        "targetYaw": 29.04695598609385,
                        "pitch": -17.47569945690549,
                        "targetPitch": -17.47569945690549,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i9-8",
                    "yaw": -0.29186045046810705,
                    "targetYaw": -0.29186045046810705,
                    "pitch": -20.562516545323408,
                    "targetPitch": -20.562516545323408,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.29186045046810705,
                        "targetYaw": -0.29186045046810705,
                        "pitch": -20.562516545323408,
                        "targetPitch": -20.562516545323408,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i9-9",
                    "yaw": 141.13223914000082,
                    "targetYaw": 141.13223914000082,
                    "pitch": -21.01173357974016,
                    "targetPitch": -21.01173357974016,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 141.13223914000082,
                        "targetYaw": 141.13223914000082,
                        "pitch": -21.01173357974016,
                        "targetPitch": -21.01173357974016,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i9-10",
                    "yaw": -23.019613644334754,
                    "targetYaw": -23.019613644334754,
                    "pitch": -24.066352182512293,
                    "targetPitch": -24.066352182512293,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -23.019613644334754,
                        "targetYaw": -23.019613644334754,
                        "pitch": -24.066352182512293,
                        "targetPitch": -24.066352182512293,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i9-11",
                    "yaw": -140.16700296203857,
                    "targetYaw": -140.16700296203857,
                    "pitch": -25.865121196163027,
                    "targetPitch": -25.865121196163027,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -140.16700296203857,
                        "targetYaw": -140.16700296203857,
                        "pitch": -25.865121196163027,
                        "targetPitch": -25.865121196163027,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i9-12",
                    "yaw": 74.99700491693909,
                    "targetYaw": 74.99700491693909,
                    "pitch": -28.098466280273225,
                    "targetPitch": -28.098466280273225,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 74.99700491693909,
                        "targetYaw": 74.99700491693909,
                        "pitch": -28.098466280273225,
                        "targetPitch": -28.098466280273225,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i9-13",
                    "yaw": 178.87240634293403,
                    "targetYaw": 178.87240634293403,
                    "pitch": -30.74099315746077,
                    "targetPitch": -30.74099315746077,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 178.87240634293403,
                        "targetYaw": 178.87240634293403,
                        "pitch": -30.74099315746077,
                        "targetPitch": -30.74099315746077,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i9-14",
                    "yaw": -91.69150008216172,
                    "targetYaw": -91.69150008216172,
                    "pitch": -39.18261645346492,
                    "targetPitch": -39.18261645346492,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -91.69150008216172,
                        "targetYaw": -91.69150008216172,
                        "pitch": -39.18261645346492,
                        "targetPitch": -39.18261645346492,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5=~Waej]j[WBayj[oLjuj[ayayayWBaya|j[j[j[j[ayazazj[ayjtj[ofj[aya|fkayj[f6ay",
                "path": "/assets/pano/default/i9/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i9/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i10": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i10-0",
                    "yaw": -26.63042006602518,
                    "targetYaw": -26.63042006602518,
                    "pitch": -6.193187589687605,
                    "targetPitch": -6.193187589687605,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -26.63042006602518,
                        "targetYaw": -26.63042006602518,
                        "pitch": -6.193187589687605,
                        "targetPitch": -6.193187589687605,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i10-1",
                    "yaw": -9.322639232159077,
                    "targetYaw": -9.322639232159077,
                    "pitch": -7.561840574190633,
                    "targetPitch": -7.561840574190633,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -9.322639232159077,
                        "targetYaw": -9.322639232159077,
                        "pitch": -7.561840574190633,
                        "targetPitch": -7.561840574190633,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i10-2",
                    "yaw": -178.9549541618088,
                    "targetYaw": -178.9549541618088,
                    "pitch": -8.585966689536995,
                    "targetPitch": -8.585966689536995,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -178.9549541618088,
                        "targetYaw": -178.9549541618088,
                        "pitch": -8.585966689536995,
                        "targetPitch": -8.585966689536995,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i10-3",
                    "yaw": 160.40817890439348,
                    "targetYaw": 160.40817890439348,
                    "pitch": -11.074428325940119,
                    "targetPitch": -11.074428325940119,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 160.40817890439348,
                        "targetYaw": 160.40817890439348,
                        "pitch": -11.074428325940119,
                        "targetPitch": -11.074428325940119,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i10-4",
                    "yaw": -13.096941415181163,
                    "targetYaw": -13.096941415181163,
                    "pitch": -11.83852206039252,
                    "targetPitch": -11.83852206039252,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -13.096941415181163,
                        "targetYaw": -13.096941415181163,
                        "pitch": -11.83852206039252,
                        "targetPitch": -11.83852206039252,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i10-5",
                    "yaw": -178.7286407849698,
                    "targetYaw": -178.7286407849698,
                    "pitch": -13.572116992891193,
                    "targetPitch": -13.572116992891193,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -178.7286407849698,
                        "targetYaw": -178.7286407849698,
                        "pitch": -13.572116992891193,
                        "targetPitch": -13.572116992891193,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i8"
                    },
                    "div": {}
                },
                {
                    "id": "i10-6",
                    "yaw": -161.02595898510526,
                    "targetYaw": -161.02595898510526,
                    "pitch": -13.699594814853514,
                    "targetPitch": -13.699594814853514,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -161.02595898510526,
                        "targetYaw": -161.02595898510526,
                        "pitch": -13.699594814853514,
                        "targetPitch": -13.699594814853514,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i10-7",
                    "yaw": 30.026232029006565,
                    "targetYaw": 30.026232029006565,
                    "pitch": -16.015664687597436,
                    "targetPitch": -16.015664687597436,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 30.026232029006565,
                        "targetYaw": 30.026232029006565,
                        "pitch": -16.015664687597436,
                        "targetPitch": -16.015664687597436,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i10-8",
                    "yaw": 144.7515174058268,
                    "targetYaw": 144.7515174058268,
                    "pitch": -18.10279266137826,
                    "targetPitch": -18.10279266137826,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 144.7515174058268,
                        "targetYaw": 144.7515174058268,
                        "pitch": -18.10279266137826,
                        "targetPitch": -18.10279266137826,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i10-9",
                    "yaw": -178.86551006972468,
                    "targetYaw": -178.86551006972468,
                    "pitch": -21.038970251288774,
                    "targetPitch": -21.038970251288774,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -178.86551006972468,
                        "targetYaw": -178.86551006972468,
                        "pitch": -21.038970251288774,
                        "targetPitch": -21.038970251288774,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i10-10",
                    "yaw": 0.08405247007794969,
                    "targetYaw": 0.08405247007794969,
                    "pitch": -21.2315758162507,
                    "targetPitch": -21.2315758162507,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.08405247007794969,
                        "targetYaw": 0.08405247007794969,
                        "pitch": -21.2315758162507,
                        "targetPitch": -21.2315758162507,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i10-11",
                    "yaw": -35.48362628840391,
                    "targetYaw": -35.48362628840391,
                    "pitch": -30.7153921232793,
                    "targetPitch": -30.7153921232793,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -35.48362628840391,
                        "targetYaw": -35.48362628840391,
                        "pitch": -30.7153921232793,
                        "targetPitch": -30.7153921232793,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i10-12",
                    "yaw": 78.68731220133054,
                    "targetYaw": 78.68731220133054,
                    "pitch": -32.19237718105908,
                    "targetPitch": -32.19237718105908,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 78.68731220133054,
                        "targetYaw": 78.68731220133054,
                        "pitch": -32.19237718105908,
                        "targetPitch": -32.19237718105908,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i10-13",
                    "yaw": -108.2313895388844,
                    "targetYaw": -108.2313895388844,
                    "pitch": -48.04036435237843,
                    "targetPitch": -48.04036435237843,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -108.2313895388844,
                        "targetYaw": -108.2313895388844,
                        "pitch": -48.04036435237843,
                        "targetPitch": -48.04036435237843,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5:~WoMfQayj[a}offRfPayWVj[azj[j@ofofoffQayayWBayj@ayWBf6ayj[ayayofWBj@aya|",
                "path": "/assets/pano/default/i10/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i10/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i11": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i11-0",
                    "yaw": -33.10167581152575,
                    "targetYaw": -33.10167581152575,
                    "pitch": -6.560252557943545,
                    "targetPitch": -6.560252557943545,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -33.10167581152575,
                        "targetYaw": -33.10167581152575,
                        "pitch": -6.560252557943545,
                        "targetPitch": -6.560252557943545,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i11-1",
                    "yaw": -41.99770672944271,
                    "targetYaw": -41.99770672944271,
                    "pitch": -9.393847462157055,
                    "targetPitch": -9.393847462157055,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -41.99770672944271,
                        "targetYaw": -41.99770672944271,
                        "pitch": -9.393847462157055,
                        "targetPitch": -9.393847462157055,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i11-2",
                    "yaw": -177.77621984595226,
                    "targetYaw": -177.77621984595226,
                    "pitch": -11.198448681743518,
                    "targetPitch": -11.198448681743518,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -177.77621984595226,
                        "targetYaw": -177.77621984595226,
                        "pitch": -11.198448681743518,
                        "targetPitch": -11.198448681743518,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i11-3",
                    "yaw": -68.37667311915379,
                    "targetYaw": -68.37667311915379,
                    "pitch": -12.632118726014767,
                    "targetPitch": -12.632118726014767,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -68.37667311915379,
                        "targetYaw": -68.37667311915379,
                        "pitch": -12.632118726014767,
                        "targetPitch": -12.632118726014767,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i11-4",
                    "yaw": -14.923824670411818,
                    "targetYaw": -14.923824670411818,
                    "pitch": -13.390887114502679,
                    "targetPitch": -13.390887114502679,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -14.923824670411818,
                        "targetYaw": -14.923824670411818,
                        "pitch": -13.390887114502679,
                        "targetPitch": -13.390887114502679,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i11-5",
                    "yaw": -100.97269473872916,
                    "targetYaw": -100.97269473872916,
                    "pitch": -13.946025617614021,
                    "targetPitch": -13.946025617614021,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -100.97269473872916,
                        "targetYaw": -100.97269473872916,
                        "pitch": -13.946025617614021,
                        "targetPitch": -13.946025617614021,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i11-6",
                    "yaw": -177.40466154433648,
                    "targetYaw": -177.40466154433648,
                    "pitch": -23.29894994094883,
                    "targetPitch": -23.29894994094883,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -177.40466154433648,
                        "targetYaw": -177.40466154433648,
                        "pitch": -23.29894994094883,
                        "targetPitch": -23.29894994094883,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i11-7",
                    "yaw": -24.466266069784183,
                    "targetYaw": -24.466266069784183,
                    "pitch": -30.37068358255343,
                    "targetPitch": -30.37068358255343,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -24.466266069784183,
                        "targetYaw": -24.466266069784183,
                        "pitch": -30.37068358255343,
                        "targetPitch": -30.37068358255343,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i11-8",
                    "yaw": -134.25649365163918,
                    "targetYaw": -134.25649365163918,
                    "pitch": -36.191074199659006,
                    "targetPitch": -36.191074199659006,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -134.25649365163918,
                        "targetYaw": -134.25649365163918,
                        "pitch": -36.191074199659006,
                        "targetPitch": -36.191074199659006,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i11-9",
                    "yaw": -160.09114230884558,
                    "targetYaw": -160.09114230884558,
                    "pitch": -23.51276769211607,
                    "targetPitch": -23.51276769211607,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -160.09114230884558,
                        "targetYaw": -160.09114230884558,
                        "pitch": -23.51276769211607,
                        "targetPitch": -23.51276769211607,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i11-10",
                    "yaw": 161.5876296957369,
                    "targetYaw": 161.5876296957369,
                    "pitch": -9.807312196177175,
                    "targetPitch": -9.807312196177175,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 161.5876296957369,
                        "targetYaw": 161.5876296957369,
                        "pitch": -9.807312196177175,
                        "targetPitch": -9.807312196177175,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i11-11",
                    "yaw": 146.49381676798805,
                    "targetYaw": 146.49381676798805,
                    "pitch": -19.146393156321295,
                    "targetPitch": -19.146393156321295,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 146.49381676798805,
                        "targetYaw": 146.49381676798805,
                        "pitch": -19.146393156321295,
                        "targetPitch": -19.146393156321295,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i11-12",
                    "yaw": 86.16116651025334,
                    "targetYaw": 86.16116651025334,
                    "pitch": -30.210674345765316,
                    "targetPitch": -30.210674345765316,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 86.16116651025334,
                        "targetYaw": 86.16116651025334,
                        "pitch": -30.210674345765316,
                        "targetPitch": -30.210674345765316,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5@~pWBaxofj[ayj[fQf6j[ayayj[ayj[fQj[j[ayayazayayf6fQj[f6j[a|ayazj[ayayazfP",
                "path": "/assets/pano/default/i11/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i11/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i12": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i12-0",
                    "yaw": -38.48037861085828,
                    "targetYaw": -38.48037861085828,
                    "pitch": -7.491802013644865,
                    "targetPitch": -7.491802013644865,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -38.48037861085828,
                        "targetYaw": -38.48037861085828,
                        "pitch": -7.491802013644865,
                        "targetPitch": -7.491802013644865,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-1",
                    "yaw": 5.9518324535217175,
                    "targetYaw": 5.9518324535217175,
                    "pitch": -7.543241990248605,
                    "targetPitch": -7.543241990248605,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 5.9518324535217175,
                        "targetYaw": 5.9518324535217175,
                        "pitch": -7.543241990248605,
                        "targetPitch": -7.543241990248605,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-2",
                    "yaw": -51.79936441652626,
                    "targetYaw": -51.79936441652626,
                    "pitch": -7.682765006494379,
                    "targetPitch": -7.682765006494379,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -51.79936441652626,
                        "targetYaw": -51.79936441652626,
                        "pitch": -7.682765006494379,
                        "targetPitch": -7.682765006494379,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-3",
                    "yaw": -30.508368935196074,
                    "targetYaw": -30.508368935196074,
                    "pitch": -8.070065285421638,
                    "targetPitch": -8.070065285421638,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -30.508368935196074,
                        "targetYaw": -30.508368935196074,
                        "pitch": -8.070065285421638,
                        "targetPitch": -8.070065285421638,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i12-4",
                    "yaw": -130.31074142976297,
                    "targetYaw": -130.31074142976297,
                    "pitch": -8.431591881692071,
                    "targetPitch": -8.431591881692071,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -130.31074142976297,
                        "targetYaw": -130.31074142976297,
                        "pitch": -8.431591881692071,
                        "targetPitch": -8.431591881692071,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-5",
                    "yaw": 171.03912593755882,
                    "targetYaw": 171.03912593755882,
                    "pitch": -8.720912210068823,
                    "targetPitch": -8.720912210068823,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 171.03912593755882,
                        "targetYaw": 171.03912593755882,
                        "pitch": -8.720912210068823,
                        "targetPitch": -8.720912210068823,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i12-6",
                    "yaw": -20.182730995680686,
                    "targetYaw": -20.182730995680686,
                    "pitch": -10.13012159492822,
                    "targetPitch": -10.13012159492822,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -20.182730995680686,
                        "targetYaw": -20.182730995680686,
                        "pitch": -10.13012159492822,
                        "targetPitch": -10.13012159492822,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-7",
                    "yaw": -75.584967811708,
                    "targetYaw": -75.584967811708,
                    "pitch": -10.14463291793465,
                    "targetPitch": -10.14463291793465,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -75.584967811708,
                        "targetYaw": -75.584967811708,
                        "pitch": -10.14463291793465,
                        "targetPitch": -10.14463291793465,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-8",
                    "yaw": 17.697580685684954,
                    "targetYaw": 17.697580685684954,
                    "pitch": -10.225811889909432,
                    "targetPitch": -10.225811889909432,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 17.697580685684954,
                        "targetYaw": 17.697580685684954,
                        "pitch": -10.225811889909432,
                        "targetPitch": -10.225811889909432,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-9",
                    "yaw": -104.68861982330952,
                    "targetYaw": -104.68861982330952,
                    "pitch": -10.231162311834296,
                    "targetPitch": -10.231162311834296,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -104.68861982330952,
                        "targetYaw": -104.68861982330952,
                        "pitch": -10.231162311834296,
                        "targetPitch": -10.231162311834296,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-10",
                    "yaw": 144.97876598705568,
                    "targetYaw": 144.97876598705568,
                    "pitch": -11.292628454504243,
                    "targetPitch": -11.292628454504243,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 144.97876598705568,
                        "targetYaw": 144.97876598705568,
                        "pitch": -11.292628454504243,
                        "targetPitch": -11.292628454504243,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-11",
                    "yaw": -45.61064735081936,
                    "targetYaw": -45.61064735081936,
                    "pitch": -11.38215815606243,
                    "targetPitch": -11.38215815606243,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -45.61064735081936,
                        "targetYaw": -45.61064735081936,
                        "pitch": -11.38215815606243,
                        "targetPitch": -11.38215815606243,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i12-12",
                    "yaw": 166.32469964351276,
                    "targetYaw": 166.32469964351276,
                    "pitch": -15.43733018554937,
                    "targetPitch": -15.43733018554937,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 166.32469964351276,
                        "targetYaw": 166.32469964351276,
                        "pitch": -15.43733018554937,
                        "targetPitch": -15.43733018554937,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i12-13",
                    "yaw": -129.33469834337916,
                    "targetYaw": -129.33469834337916,
                    "pitch": -15.542084230109669,
                    "targetPitch": -15.542084230109669,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -129.33469834337916,
                        "targetYaw": -129.33469834337916,
                        "pitch": -15.542084230109669,
                        "targetPitch": -15.542084230109669,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i12-14",
                    "yaw": 112.11819604779919,
                    "targetYaw": 112.11819604779919,
                    "pitch": -15.88056503195588,
                    "targetPitch": -15.88056503195588,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 112.11819604779919,
                        "targetYaw": 112.11819604779919,
                        "pitch": -15.88056503195588,
                        "targetPitch": -15.88056503195588,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-15",
                    "yaw": -86.21654440269634,
                    "targetYaw": -86.21654440269634,
                    "pitch": -16.732733374754737,
                    "targetPitch": -16.732733374754737,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -86.21654440269634,
                        "targetYaw": -86.21654440269634,
                        "pitch": -16.732733374754737,
                        "targetPitch": -16.732733374754737,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i12-16",
                    "yaw": -0.14421720427390028,
                    "targetYaw": -0.14421720427390028,
                    "pitch": -20.370420634283786,
                    "targetPitch": -20.370420634283786,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.14421720427390028,
                        "targetYaw": -0.14421720427390028,
                        "pitch": -20.370420634283786,
                        "targetPitch": -20.370420634283786,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i12-17",
                    "yaw": -160.2351529668053,
                    "targetYaw": -160.2351529668053,
                    "pitch": -23.383236215367774,
                    "targetPitch": -23.383236215367774,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -160.2351529668053,
                        "targetYaw": -160.2351529668053,
                        "pitch": -23.383236215367774,
                        "targetPitch": -23.383236215367774,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i12-18",
                    "yaw": 141.74394262289377,
                    "targetYaw": 141.74394262289377,
                    "pitch": -33.71994783908993,
                    "targetPitch": -33.71994783908993,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 141.74394262289377,
                        "targetYaw": 141.74394262289377,
                        "pitch": -33.71994783908993,
                        "targetPitch": -33.71994783908993,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i12-19",
                    "yaw": 62.45977485192077,
                    "targetYaw": 62.45977485192077,
                    "pitch": -8.763775778746105,
                    "targetPitch": -8.763775778746105,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 62.45977485192077,
                        "targetYaw": 62.45977485192077,
                        "pitch": -8.763775778746105,
                        "targetPitch": -8.763775778746105,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "o8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WV[f6ofj[aeoffQj[a#ayaykCfkf6fQj[ayj@fQfPjZayazazj[azfQj[j[f6j[jtj[j[az",
                "path": "/assets/pano/default/i12/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i12/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i13": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i13-0",
                    "yaw": 174.31149765297732,
                    "targetYaw": 174.31149765297732,
                    "pitch": -5.8743628016832785,
                    "targetPitch": -5.8743628016832785,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 174.31149765297732,
                        "targetYaw": 174.31149765297732,
                        "pitch": -5.8743628016832785,
                        "targetPitch": -5.8743628016832785,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i9"
                    },
                    "div": {}
                },
                {
                    "id": "i13-1",
                    "yaw": 89.34854093476577,
                    "targetYaw": 89.34854093476577,
                    "pitch": -8.540154607811598,
                    "targetPitch": -8.540154607811598,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 89.34854093476577,
                        "targetYaw": 89.34854093476577,
                        "pitch": -8.540154607811598,
                        "targetPitch": -8.540154607811598,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa7"
                    },
                    "div": {}
                },
                {
                    "id": "i13-2",
                    "yaw": 171.0217798176974,
                    "targetYaw": 171.0217798176974,
                    "pitch": -9.576343320044849,
                    "targetPitch": -9.576343320044849,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 171.0217798176974,
                        "targetYaw": 171.0217798176974,
                        "pitch": -9.576343320044849,
                        "targetPitch": -9.576343320044849,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i10"
                    },
                    "div": {}
                },
                {
                    "id": "i13-3",
                    "yaw": -108.00343451961521,
                    "targetYaw": -108.00343451961521,
                    "pitch": -10.321331613806604,
                    "targetPitch": -10.321331613806604,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -108.00343451961521,
                        "targetYaw": -108.00343451961521,
                        "pitch": -10.321331613806604,
                        "targetPitch": -10.321331613806604,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-4",
                    "yaw": -74.25618159502335,
                    "targetYaw": -74.25618159502335,
                    "pitch": -10.581856717104868,
                    "targetPitch": -10.581856717104868,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -74.25618159502335,
                        "targetYaw": -74.25618159502335,
                        "pitch": -10.581856717104868,
                        "targetPitch": -10.581856717104868,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-5",
                    "yaw": 145.82286427678633,
                    "targetYaw": 145.82286427678633,
                    "pitch": -10.818681106887663,
                    "targetPitch": -10.818681106887663,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 145.82286427678633,
                        "targetYaw": 145.82286427678633,
                        "pitch": -10.818681106887663,
                        "targetPitch": -10.818681106887663,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-6",
                    "yaw": -151.04120119519257,
                    "targetYaw": -151.04120119519257,
                    "pitch": -11.669617072557728,
                    "targetPitch": -11.669617072557728,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -151.04120119519257,
                        "targetYaw": -151.04120119519257,
                        "pitch": -11.669617072557728,
                        "targetPitch": -11.669617072557728,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i13-7",
                    "yaw": -169.305026022952,
                    "targetYaw": -169.305026022952,
                    "pitch": -11.958707983740494,
                    "targetPitch": -11.958707983740494,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -169.305026022952,
                        "targetYaw": -169.305026022952,
                        "pitch": -11.958707983740494,
                        "targetPitch": -11.958707983740494,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-8",
                    "yaw": 13.44905633471842,
                    "targetYaw": 13.44905633471842,
                    "pitch": -12.84401252405704,
                    "targetPitch": -12.84401252405704,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 13.44905633471842,
                        "targetYaw": 13.44905633471842,
                        "pitch": -12.84401252405704,
                        "targetPitch": -12.84401252405704,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-9",
                    "yaw": -128.79150995333828,
                    "targetYaw": -128.79150995333828,
                    "pitch": -13.342638524639403,
                    "targetPitch": -13.342638524639403,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -128.79150995333828,
                        "targetYaw": -128.79150995333828,
                        "pitch": -13.342638524639403,
                        "targetPitch": -13.342638524639403,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i13-10",
                    "yaw": -55.85122356643336,
                    "targetYaw": -55.85122356643336,
                    "pitch": -13.536830082074488,
                    "targetPitch": -13.536830082074488,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -55.85122356643336,
                        "targetYaw": -55.85122356643336,
                        "pitch": -13.536830082074488,
                        "targetPitch": -13.536830082074488,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i13-11",
                    "yaw": 166.01189670514634,
                    "targetYaw": 166.01189670514634,
                    "pitch": -15.201043644903844,
                    "targetPitch": -15.201043644903844,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 166.01189670514634,
                        "targetYaw": 166.01189670514634,
                        "pitch": -15.201043644903844,
                        "targetPitch": -15.201043644903844,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i13-12",
                    "yaw": -90.74190105947184,
                    "targetYaw": -90.74190105947184,
                    "pitch": -15.845758692643336,
                    "targetPitch": -15.845758692643336,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -90.74190105947184,
                        "targetYaw": -90.74190105947184,
                        "pitch": -15.845758692643336,
                        "targetPitch": -15.845758692643336,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i13-13",
                    "yaw": 32.862843884711424,
                    "targetYaw": 32.862843884711424,
                    "pitch": -19.795859301454875,
                    "targetPitch": -19.795859301454875,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 32.862843884711424,
                        "targetYaw": 32.862843884711424,
                        "pitch": -19.795859301454875,
                        "targetPitch": -19.795859301454875,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-14",
                    "yaw": -41.97819764707293,
                    "targetYaw": -41.97819764707293,
                    "pitch": -19.950411185849354,
                    "targetPitch": -19.950411185849354,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -41.97819764707293,
                        "targetYaw": -41.97819764707293,
                        "pitch": -19.950411185849354,
                        "targetPitch": -19.950411185849354,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i13-15",
                    "yaw": -178.3580349171475,
                    "targetYaw": -178.3580349171475,
                    "pitch": -20.742944100479857,
                    "targetPitch": -20.742944100479857,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -178.3580349171475,
                        "targetYaw": -178.3580349171475,
                        "pitch": -20.742944100479857,
                        "targetPitch": -20.742944100479857,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5=~WV@kCofazjZofayj[ayWBj@flayayayj[azj@jtayoLj[ayazayayayayfQayoLj[j[ayay",
                "path": "/assets/pano/default/i13/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i13/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i14": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i14-0",
                    "yaw": 179.35977259642652,
                    "targetYaw": 179.35977259642652,
                    "pitch": -13.292531671741271,
                    "targetPitch": -13.292531671741271,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.35977259642652,
                        "targetYaw": 179.35977259642652,
                        "pitch": -13.292531671741271,
                        "targetPitch": -13.292531671741271,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i14-1",
                    "yaw": 130.89980478380068,
                    "targetYaw": 130.89980478380068,
                    "pitch": -13.810312970167152,
                    "targetPitch": -13.810312970167152,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 130.89980478380068,
                        "targetYaw": 130.89980478380068,
                        "pitch": -13.810312970167152,
                        "targetPitch": -13.810312970167152,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i14-2",
                    "yaw": 91.01599137949734,
                    "targetYaw": 91.01599137949734,
                    "pitch": -17.573029238646125,
                    "targetPitch": -17.573029238646125,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 91.01599137949734,
                        "targetYaw": 91.01599137949734,
                        "pitch": -17.573029238646125,
                        "targetPitch": -17.573029238646125,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i14-3",
                    "yaw": 178.9005631445009,
                    "targetYaw": 178.9005631445009,
                    "pitch": -21.261749631138745,
                    "targetPitch": -21.261749631138745,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 178.9005631445009,
                        "targetYaw": 178.9005631445009,
                        "pitch": -21.261749631138745,
                        "targetPitch": -21.261749631138745,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i14-4",
                    "yaw": 1.3370292425059915,
                    "targetYaw": 1.3370292425059915,
                    "pitch": -25.106173855022412,
                    "targetPitch": -25.106173855022412,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 1.3370292425059915,
                        "targetYaw": 1.3370292425059915,
                        "pitch": -25.106173855022412,
                        "targetPitch": -25.106173855022412,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i14-5",
                    "yaw": -136.50804807835033,
                    "targetYaw": -136.50804807835033,
                    "pitch": -22.666603859401533,
                    "targetPitch": -22.666603859401533,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -136.50804807835033,
                        "targetYaw": -136.50804807835033,
                        "pitch": -22.666603859401533,
                        "targetPitch": -22.666603859401533,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i14-6",
                    "yaw": -46.18323908470512,
                    "targetYaw": -46.18323908470512,
                    "pitch": -26.035458517724184,
                    "targetPitch": -26.035458517724184,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -46.18323908470512,
                        "targetYaw": -46.18323908470512,
                        "pitch": -26.035458517724184,
                        "targetPitch": -26.035458517724184,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i14-7",
                    "yaw": 35.21955534993185,
                    "targetYaw": 35.21955534993185,
                    "pitch": -23.36170022722845,
                    "targetPitch": -23.36170022722845,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 35.21955534993185,
                        "targetYaw": 35.21955534993185,
                        "pitch": -23.36170022722845,
                        "targetPitch": -23.36170022722845,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i14-8",
                    "yaw": 67.16623932584548,
                    "targetYaw": 67.16623932584548,
                    "pitch": -10.755497761972336,
                    "targetPitch": -10.755497761972336,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 67.16623932584548,
                        "targetYaw": 67.16623932584548,
                        "pitch": -10.755497761972336,
                        "targetPitch": -10.755497761972336,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i14-9",
                    "yaw": 50.264705887082414,
                    "targetYaw": 50.264705887082414,
                    "pitch": -8.523656784075161,
                    "targetPitch": -8.523656784075161,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 50.264705887082414,
                        "targetYaw": 50.264705887082414,
                        "pitch": -8.523656784075161,
                        "targetPitch": -8.523656784075161,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i14-10",
                    "yaw": -21.345757750475286,
                    "targetYaw": -21.345757750475286,
                    "pitch": -15.084855943128808,
                    "targetPitch": -15.084855943128808,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -21.345757750475286,
                        "targetYaw": -21.345757750475286,
                        "pitch": -15.084855943128808,
                        "targetPitch": -15.084855943128808,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5=~pWBj[ofayf5j[ayayayaej[fRj[WVf6j[f7j[afaykCofjtayf6bHWBfRayj[j@fQf6fQfj",
                "path": "/assets/pano/default/i14/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i14/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i15": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i15-0",
                    "yaw": 146.27928227410973,
                    "targetYaw": 146.27928227410973,
                    "pitch": -9.70430003741697,
                    "targetPitch": -9.70430003741697,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 146.27928227410973,
                        "targetYaw": 146.27928227410973,
                        "pitch": -9.70430003741697,
                        "targetPitch": -9.70430003741697,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i15-1",
                    "yaw": 178.81325447156792,
                    "targetYaw": 178.81325447156792,
                    "pitch": -12.16626298556422,
                    "targetPitch": -12.16626298556422,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 178.81325447156792,
                        "targetYaw": 178.81325447156792,
                        "pitch": -12.16626298556422,
                        "targetPitch": -12.16626298556422,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i15-2",
                    "yaw": 131.7553287505532,
                    "targetYaw": 131.7553287505532,
                    "pitch": -14.895874976734657,
                    "targetPitch": -14.895874976734657,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 131.7553287505532,
                        "targetYaw": 131.7553287505532,
                        "pitch": -14.895874976734657,
                        "targetPitch": -14.895874976734657,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i15-3",
                    "yaw": 178.9005631445009,
                    "targetYaw": 178.9005631445009,
                    "pitch": -21.65924208088271,
                    "targetPitch": -21.65924208088271,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 178.9005631445009,
                        "targetYaw": 178.9005631445009,
                        "pitch": -21.65924208088271,
                        "targetPitch": -21.65924208088271,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i15-4",
                    "yaw": 92.76759532971346,
                    "targetYaw": 92.76759532971346,
                    "pitch": -32.164452679807965,
                    "targetPitch": -32.164452679807965,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 92.76759532971346,
                        "targetYaw": 92.76759532971346,
                        "pitch": -32.164452679807965,
                        "targetPitch": -32.164452679807965,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i15-5",
                    "yaw": 52.73144635802623,
                    "targetYaw": 52.73144635802623,
                    "pitch": -22.40053969366444,
                    "targetPitch": -22.40053969366444,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 52.73144635802623,
                        "targetYaw": 52.73144635802623,
                        "pitch": -22.40053969366444,
                        "targetPitch": -22.40053969366444,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i15-6",
                    "yaw": -43.64022641004499,
                    "targetYaw": -43.64022641004499,
                    "pitch": -26.303754993635764,
                    "targetPitch": -26.303754993635764,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -43.64022641004499,
                        "targetYaw": -43.64022641004499,
                        "pitch": -26.303754993635764,
                        "targetPitch": -26.303754993635764,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i15-7",
                    "yaw": -119.82755997380946,
                    "targetYaw": -119.82755997380946,
                    "pitch": -29.098912569696918,
                    "targetPitch": -29.098912569696918,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -119.82755997380946,
                        "targetYaw": -119.82755997380946,
                        "pitch": -29.098912569696918,
                        "targetPitch": -29.098912569696918,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i15-8",
                    "yaw": -156.8059654433032,
                    "targetYaw": -156.8059654433032,
                    "pitch": -12.233010090911348,
                    "targetPitch": -12.233010090911348,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -156.8059654433032,
                        "targetYaw": -156.8059654433032,
                        "pitch": -12.233010090911348,
                        "targetPitch": -12.233010090911348,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5-~WWBt7offRj?ayj[WBf6ayoeWBf6WCofayazfQj[j[j[ofjZayj[azWBfkj[fkj[ayf6j[j[",
                "path": "/assets/pano/default/i15/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i15/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i16": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i16-0",
                    "yaw": 179.4176304345251,
                    "targetYaw": 179.4176304345251,
                    "pitch": -10.976995077780499,
                    "targetPitch": -10.976995077780499,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.4176304345251,
                        "targetYaw": 179.4176304345251,
                        "pitch": -10.976995077780499,
                        "targetPitch": -10.976995077780499,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i18"
                    },
                    "div": {}
                },
                {
                    "id": "i16-1",
                    "yaw": 0.1360100359572698,
                    "targetYaw": 0.1360100359572698,
                    "pitch": -13.046546493785694,
                    "targetPitch": -13.046546493785694,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.1360100359572698,
                        "targetYaw": 0.1360100359572698,
                        "pitch": -13.046546493785694,
                        "targetPitch": -13.046546493785694,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i16-2",
                    "yaw": 115.24344460108,
                    "targetYaw": 115.24344460108,
                    "pitch": -15.029219230802301,
                    "targetPitch": -15.029219230802301,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 115.24344460108,
                        "targetYaw": 115.24344460108,
                        "pitch": -15.029219230802301,
                        "targetPitch": -15.029219230802301,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i16-3",
                    "yaw": 48.52777675709092,
                    "targetYaw": 48.52777675709092,
                    "pitch": -17.81583425901344,
                    "targetPitch": -17.81583425901344,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 48.52777675709092,
                        "targetYaw": 48.52777675709092,
                        "pitch": -17.81583425901344,
                        "targetPitch": -17.81583425901344,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i16-4",
                    "yaw": 179.6988516506094,
                    "targetYaw": 179.6988516506094,
                    "pitch": -18.688654674473135,
                    "targetPitch": -18.688654674473135,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.6988516506094,
                        "targetYaw": 179.6988516506094,
                        "pitch": -18.688654674473135,
                        "targetPitch": -18.688654674473135,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i16-5",
                    "yaw": 100.76160638029717,
                    "targetYaw": 100.76160638029717,
                    "pitch": -22.219362905120946,
                    "targetPitch": -22.219362905120946,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 100.76160638029717,
                        "targetYaw": 100.76160638029717,
                        "pitch": -22.219362905120946,
                        "targetPitch": -22.219362905120946,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i16-6",
                    "yaw": -0.07378168175273458,
                    "targetYaw": -0.07378168175273458,
                    "pitch": -25.319757076278766,
                    "targetPitch": -25.319757076278766,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.07378168175273458,
                        "targetYaw": -0.07378168175273458,
                        "pitch": -25.319757076278766,
                        "targetPitch": -25.319757076278766,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i16-7",
                    "yaw": -143.78783353962808,
                    "targetYaw": -143.78783353962808,
                    "pitch": -21.880315891428808,
                    "targetPitch": -21.880315891428808,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -143.78783353962808,
                        "targetYaw": -143.78783353962808,
                        "pitch": -21.880315891428808,
                        "targetPitch": -21.880315891428808,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-8",
                    "yaw": 143.67005305564226,
                    "targetYaw": 143.67005305564226,
                    "pitch": -16.14149057412608,
                    "targetPitch": -16.14149057412608,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 143.67005305564226,
                        "targetYaw": 143.67005305564226,
                        "pitch": -16.14149057412608,
                        "targetPitch": -16.14149057412608,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-9",
                    "yaw": 107.84592265528363,
                    "targetYaw": 107.84592265528363,
                    "pitch": -8.318369243735459,
                    "targetPitch": -8.318369243735459,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 107.84592265528363,
                        "targetYaw": 107.84592265528363,
                        "pitch": -8.318369243735459,
                        "targetPitch": -8.318369243735459,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-10",
                    "yaw": 48.453019315070264,
                    "targetYaw": 48.453019315070264,
                    "pitch": -8.623713637563924,
                    "targetPitch": -8.623713637563924,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 48.453019315070264,
                        "targetYaw": 48.453019315070264,
                        "pitch": -8.623713637563924,
                        "targetPitch": -8.623713637563924,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-11",
                    "yaw": 17.42238187290843,
                    "targetYaw": 17.42238187290843,
                    "pitch": -11.386475402771001,
                    "targetPitch": -11.386475402771001,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 17.42238187290843,
                        "targetYaw": 17.42238187290843,
                        "pitch": -11.386475402771001,
                        "targetPitch": -11.386475402771001,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-12",
                    "yaw": 27.323265519037363,
                    "targetYaw": 27.323265519037363,
                    "pitch": -7.899490675000851,
                    "targetPitch": -7.899490675000851,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 27.323265519037363,
                        "targetYaw": 27.323265519037363,
                        "pitch": -7.899490675000851,
                        "targetPitch": -7.899490675000851,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i16-13",
                    "yaw": -22.15171131426439,
                    "targetYaw": -22.15171131426439,
                    "pitch": -13.833300934979828,
                    "targetPitch": -13.833300934979828,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -22.15171131426439,
                        "targetYaw": -22.15171131426439,
                        "pitch": -13.833300934979828,
                        "targetPitch": -13.833300934979828,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5?~WWBj[offQayj[ayf6azayfQj[azayayfkayjtayjtoeofj@ayayfkaefPayj[jtj[ayfQay",
                "path": "/assets/pano/default/i16/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i16/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i17": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i17-0",
                    "yaw": 0.32576570717094455,
                    "targetYaw": 0.32576570717094455,
                    "pitch": -7.222081688058042,
                    "targetPitch": -7.222081688058042,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.32576570717094455,
                        "targetYaw": 0.32576570717094455,
                        "pitch": -7.222081688058042,
                        "targetPitch": -7.222081688058042,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i15"
                    },
                    "div": {}
                },
                {
                    "id": "i17-1",
                    "yaw": 26.69992755682017,
                    "targetYaw": 26.69992755682017,
                    "pitch": -10.169172305264874,
                    "targetPitch": -10.169172305264874,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 26.69992755682017,
                        "targetYaw": 26.69992755682017,
                        "pitch": -10.169172305264874,
                        "targetPitch": -10.169172305264874,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "i17-2",
                    "yaw": 0.03401864266197663,
                    "targetYaw": 0.03401864266197663,
                    "pitch": -11.667362930563108,
                    "targetPitch": -11.667362930563108,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.03401864266197663,
                        "targetYaw": 0.03401864266197663,
                        "pitch": -11.667362930563108,
                        "targetPitch": -11.667362930563108,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i14"
                    },
                    "div": {}
                },
                {
                    "id": "i17-3",
                    "yaw": 178.86091142607268,
                    "targetYaw": 178.86091142607268,
                    "pitch": -12.928415918495066,
                    "targetPitch": -12.928415918495066,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 178.86091142607268,
                        "targetYaw": 178.86091142607268,
                        "pitch": -12.928415918495066,
                        "targetPitch": -12.928415918495066,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                },
                {
                    "id": "i17-4",
                    "yaw": 79.3580233842215,
                    "targetYaw": 79.3580233842215,
                    "pitch": -15.678855403771218,
                    "targetPitch": -15.678855403771218,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 79.3580233842215,
                        "targetYaw": 79.3580233842215,
                        "pitch": -15.678855403771218,
                        "targetPitch": -15.678855403771218,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i11"
                    },
                    "div": {}
                },
                {
                    "id": "i17-5",
                    "yaw": 39.97119842149209,
                    "targetYaw": 39.97119842149209,
                    "pitch": -17.93908296875126,
                    "targetPitch": -17.93908296875126,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 39.97119842149209,
                        "targetYaw": 39.97119842149209,
                        "pitch": -17.93908296875126,
                        "targetPitch": -17.93908296875126,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i17-6",
                    "yaw": -179.9264836922573,
                    "targetYaw": -179.9264836922573,
                    "pitch": -20.915057840339735,
                    "targetPitch": -20.915057840339735,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -179.9264836922573,
                        "targetYaw": -179.9264836922573,
                        "pitch": -20.915057840339735,
                        "targetPitch": -20.915057840339735,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i18"
                    },
                    "div": {}
                },
                {
                    "id": "i17-7",
                    "yaw": 0.03401864266197663,
                    "targetYaw": 0.03401864266197663,
                    "pitch": -22.006561688021844,
                    "targetPitch": -22.006561688021844,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.03401864266197663,
                        "targetYaw": 0.03401864266197663,
                        "pitch": -22.006561688021844,
                        "targetPitch": -22.006561688021844,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i17-8",
                    "yaw": 160.4423816027138,
                    "targetYaw": 160.4423816027138,
                    "pitch": -12.386856124781342,
                    "targetPitch": -12.386856124781342,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 160.4423816027138,
                        "targetYaw": 160.4423816027138,
                        "pitch": -12.386856124781342,
                        "targetPitch": -12.386856124781342,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i17-9",
                    "yaw": 138.92668339883483,
                    "targetYaw": 138.92668339883483,
                    "pitch": -13.083985981369738,
                    "targetPitch": -13.083985981369738,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 138.92668339883483,
                        "targetYaw": 138.92668339883483,
                        "pitch": -13.083985981369738,
                        "targetPitch": -13.083985981369738,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i17-10",
                    "yaw": 123.40030628604143,
                    "targetYaw": 123.40030628604143,
                    "pitch": -34.24175668622467,
                    "targetPitch": -34.24175668622467,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 123.40030628604143,
                        "targetYaw": 123.40030628604143,
                        "pitch": -34.24175668622467,
                        "targetPitch": -34.24175668622467,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i17-11",
                    "yaw": 82.27820697608001,
                    "targetYaw": 82.27820697608001,
                    "pitch": -8.960237996994165,
                    "targetPitch": -8.960237996994165,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 82.27820697608001,
                        "targetYaw": 82.27820697608001,
                        "pitch": -8.960237996994165,
                        "targetPitch": -8.960237996994165,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i17-12",
                    "yaw": -61.11337201815064,
                    "targetYaw": -61.11337201815064,
                    "pitch": -27.58508517928406,
                    "targetPitch": -27.58508517928406,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -61.11337201815064,
                        "targetYaw": -61.11337201815064,
                        "pitch": -27.58508517928406,
                        "targetPitch": -27.58508517928406,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i17-13",
                    "yaw": -143.04449713448446,
                    "targetYaw": -143.04449713448446,
                    "pitch": -22.79711477104377,
                    "targetPitch": -22.79711477104377,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -143.04449713448446,
                        "targetYaw": -143.04449713448446,
                        "pitch": -22.79711477104377,
                        "targetPitch": -22.79711477104377,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5;~WWBayofj[fQj[ayayj[fQayayayazfkj[j[fQj[WBj[j[bHj[ayj[ayoej[jsayj[j[fQj[",
                "path": "/assets/pano/default/i17/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i17/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i18": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i18-0",
                    "yaw": 21.423453678168244,
                    "targetYaw": 21.423453678168244,
                    "pitch": -8.224792983437835,
                    "targetPitch": -8.224792983437835,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 21.423453678168244,
                        "targetYaw": 21.423453678168244,
                        "pitch": -8.224792983437835,
                        "targetPitch": -8.224792983437835,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                },
                {
                    "id": "i18-1",
                    "yaw": 162.30893684964104,
                    "targetYaw": 162.30893684964104,
                    "pitch": -8.41302710753422,
                    "targetPitch": -8.41302710753422,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 162.30893684964104,
                        "targetYaw": 162.30893684964104,
                        "pitch": -8.41302710753422,
                        "targetPitch": -8.41302710753422,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i18-2",
                    "yaw": 175.72681769127405,
                    "targetYaw": 175.72681769127405,
                    "pitch": -9.259350604101561,
                    "targetPitch": -9.259350604101561,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 175.72681769127405,
                        "targetYaw": 175.72681769127405,
                        "pitch": -9.259350604101561,
                        "targetPitch": -9.259350604101561,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i18-3",
                    "yaw": -0.4527119685648131,
                    "targetYaw": -0.4527119685648131,
                    "pitch": -9.5899400597503,
                    "targetPitch": -9.5899400597503,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.4527119685648131,
                        "targetYaw": -0.4527119685648131,
                        "pitch": -9.5899400597503,
                        "targetPitch": -9.5899400597503,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i18-4",
                    "yaw": -22.857557720750947,
                    "targetYaw": -22.857557720750947,
                    "pitch": -12.393906041153794,
                    "targetPitch": -12.393906041153794,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -22.857557720750947,
                        "targetYaw": -22.857557720750947,
                        "pitch": -12.393906041153794,
                        "targetPitch": -12.393906041153794,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-5",
                    "yaw": 170.6838233095936,
                    "targetYaw": 170.6838233095936,
                    "pitch": -16.038776173753064,
                    "targetPitch": -16.038776173753064,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 170.6838233095936,
                        "targetYaw": 170.6838233095936,
                        "pitch": -16.038776173753064,
                        "targetPitch": -16.038776173753064,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i18-6",
                    "yaw": 85.62400901658293,
                    "targetYaw": 85.62400901658293,
                    "pitch": -19.615355671396692,
                    "targetPitch": -19.615355671396692,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 85.62400901658293,
                        "targetYaw": 85.62400901658293,
                        "pitch": -19.615355671396692,
                        "targetPitch": -19.615355671396692,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-7",
                    "yaw": 47.8316545447899,
                    "targetYaw": 47.8316545447899,
                    "pitch": -20.31420602438297,
                    "targetPitch": -20.31420602438297,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 47.8316545447899,
                        "targetYaw": 47.8316545447899,
                        "pitch": -20.31420602438297,
                        "targetPitch": -20.31420602438297,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-8",
                    "yaw": 129.3759411761912,
                    "targetYaw": 129.3759411761912,
                    "pitch": -20.64986311561761,
                    "targetPitch": -20.64986311561761,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 129.3759411761912,
                        "targetYaw": 129.3759411761912,
                        "pitch": -20.64986311561761,
                        "targetPitch": -20.64986311561761,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-9",
                    "yaw": -136.92239453733578,
                    "targetYaw": -136.92239453733578,
                    "pitch": -20.790695971777097,
                    "targetPitch": -20.790695971777097,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -136.92239453733578,
                        "targetYaw": -136.92239453733578,
                        "pitch": -20.790695971777097,
                        "targetPitch": -20.790695971777097,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-10",
                    "yaw": 1.3934547581155812,
                    "targetYaw": 1.3934547581155812,
                    "pitch": -23.58183368970291,
                    "targetPitch": -23.58183368970291,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 1.3934547581155812,
                        "targetYaw": 1.3934547581155812,
                        "pitch": -23.58183368970291,
                        "targetPitch": -23.58183368970291,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i18-11",
                    "yaw": -60.00075591306137,
                    "targetYaw": -60.00075591306137,
                    "pitch": -30.279364331653564,
                    "targetPitch": -30.279364331653564,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -60.00075591306137,
                        "targetYaw": -60.00075591306137,
                        "pitch": -30.279364331653564,
                        "targetPitch": -30.279364331653564,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i18-12",
                    "yaw": -179.6574218783869,
                    "targetYaw": -179.6574218783869,
                    "pitch": -35.35665804697012,
                    "targetPitch": -35.35665804697012,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -179.6574218783869,
                        "targetYaw": -179.6574218783869,
                        "pitch": -35.35665804697012,
                        "targetPitch": -35.35665804697012,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i19"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5;~WoLj[WBf6WCofayoeayWBj[WBfQjtj[j[offPayayj[kBayayj[oejufQWBfQWBa|j[fQfQ",
                "path": "/assets/pano/default/i18/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i18/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "i19": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "i19-0",
                    "yaw": 165.76051019275985,
                    "targetYaw": 165.76051019275985,
                    "pitch": -4.993849440289924,
                    "targetPitch": -4.993849440289924,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 165.76051019275985,
                        "targetYaw": 165.76051019275985,
                        "pitch": -4.993849440289924,
                        "targetPitch": -4.993849440289924,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i1"
                    },
                    "div": {}
                },
                {
                    "id": "i19-1",
                    "yaw": 0.05951442565429943,
                    "targetYaw": 0.05951442565429943,
                    "pitch": -7.456105539875486,
                    "targetPitch": -7.456105539875486,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.05951442565429943,
                        "targetYaw": 0.05951442565429943,
                        "pitch": -7.456105539875486,
                        "targetPitch": -7.456105539875486,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i16"
                    },
                    "div": {}
                },
                {
                    "id": "i19-2",
                    "yaw": 138.68820814742026,
                    "targetYaw": 138.68820814742026,
                    "pitch": -8.782693077692308,
                    "targetPitch": -8.782693077692308,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 138.68820814742026,
                        "targetYaw": 138.68820814742026,
                        "pitch": -8.782693077692308,
                        "targetPitch": -8.782693077692308,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i4"
                    },
                    "div": {}
                },
                {
                    "id": "i19-3",
                    "yaw": 153.72389950086222,
                    "targetYaw": 153.72389950086222,
                    "pitch": -10.448347359102366,
                    "targetPitch": -10.448347359102366,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 153.72389950086222,
                        "targetYaw": 153.72389950086222,
                        "pitch": -10.448347359102366,
                        "targetPitch": -10.448347359102366,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i2"
                    },
                    "div": {}
                },
                {
                    "id": "i19-4",
                    "yaw": 174.25508986315094,
                    "targetYaw": 174.25508986315094,
                    "pitch": -12.03259377113532,
                    "targetPitch": -12.03259377113532,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 174.25508986315094,
                        "targetYaw": 174.25508986315094,
                        "pitch": -12.03259377113532,
                        "targetPitch": -12.03259377113532,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i3"
                    },
                    "div": {}
                },
                {
                    "id": "i19-5",
                    "yaw": 1.6488786971725062,
                    "targetYaw": 1.6488786971725062,
                    "pitch": -14.81686181620574,
                    "targetPitch": -14.81686181620574,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 1.6488786971725062,
                        "targetYaw": 1.6488786971725062,
                        "pitch": -14.81686181620574,
                        "targetPitch": -14.81686181620574,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i17"
                    },
                    "div": {}
                },
                {
                    "id": "i19-6",
                    "yaw": 138.57183577722475,
                    "targetYaw": 138.57183577722475,
                    "pitch": -19.14334732987411,
                    "targetPitch": -19.14334732987411,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 138.57183577722475,
                        "targetYaw": 138.57183577722475,
                        "pitch": -19.14334732987411,
                        "targetPitch": -19.14334732987411,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i5"
                    },
                    "div": {}
                },
                {
                    "id": "i19-7",
                    "yaw": 167.31220457466907,
                    "targetYaw": 167.31220457466907,
                    "pitch": -23.875562099243485,
                    "targetPitch": -23.875562099243485,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 167.31220457466907,
                        "targetYaw": 167.31220457466907,
                        "pitch": -23.875562099243485,
                        "targetPitch": -23.875562099243485,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i6"
                    },
                    "div": {}
                },
                {
                    "id": "i19-8",
                    "yaw": 4.67383758254249,
                    "targetYaw": 4.67383758254249,
                    "pitch": -38.95329981968385,
                    "targetPitch": -38.95329981968385,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 4.67383758254249,
                        "targetYaw": 4.67383758254249,
                        "pitch": -38.95329981968385,
                        "targetPitch": -38.95329981968385,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i18"
                    },
                    "div": {}
                },
                {
                    "id": "i19-9",
                    "yaw": -153.92548086180216,
                    "targetYaw": -153.92548086180216,
                    "pitch": -17.84927293495953,
                    "targetPitch": -17.84927293495953,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -153.92548086180216,
                        "targetYaw": -153.92548086180216,
                        "pitch": -17.84927293495953,
                        "targetPitch": -17.84927293495953,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i19-10",
                    "yaw": -100.27677167773288,
                    "targetYaw": -100.27677167773288,
                    "pitch": -29.081999590380764,
                    "targetPitch": -29.081999590380764,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -100.27677167773288,
                        "targetYaw": -100.27677167773288,
                        "pitch": -29.081999590380764,
                        "targetPitch": -29.081999590380764,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i19-11",
                    "yaw": -33.839281743490744,
                    "targetYaw": -33.839281743490744,
                    "pitch": -18.175681680267182,
                    "targetPitch": -18.175681680267182,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -33.839281743490744,
                        "targetYaw": -33.839281743490744,
                        "pitch": -18.175681680267182,
                        "targetPitch": -18.175681680267182,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i19-12",
                    "yaw": 32.571623143050715,
                    "targetYaw": 32.571623143050715,
                    "pitch": -15.373943467769491,
                    "targetPitch": -15.373943467769491,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 32.571623143050715,
                        "targetYaw": 32.571623143050715,
                        "pitch": -15.373943467769491,
                        "targetPitch": -15.373943467769491,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i19-13",
                    "yaw": 63.36971452212879,
                    "targetYaw": 63.36971452212879,
                    "pitch": -17.961160118495098,
                    "targetPitch": -17.961160118495098,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 63.36971452212879,
                        "targetYaw": 63.36971452212879,
                        "pitch": -17.961160118495098,
                        "targetPitch": -17.961160118495098,
                        "type": "product",
                        "productSlug": "double-flat-arm-sofa",
                        "sceneId": ""
                    },
                    "div": {}
                },
                {
                    "id": "i19-14",
                    "yaw": 118.1672342007871,
                    "targetYaw": 118.1672342007871,
                    "pitch": -33.01098114926004,
                    "targetPitch": -33.01098114926004,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 118.1672342007871,
                        "targetYaw": 118.1672342007871,
                        "pitch": -33.01098114926004,
                        "targetPitch": -33.01098114926004,
                        "type": "product",
                        "productSlug": "neva-chair-with-wooden-arms-ghenevamatgo01",
                        "sceneId": ""
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5=~Wf6kCayWCWCofj[j[j[ayayfPWBjtazj[j[ofayayofj?j@oLayfPWBj[j[ayoLayayj[j@",
                "path": "/assets/pano/default/i19/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/i19/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "o8": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "o8-0",
                    "yaw": 89.65061523408326,
                    "targetYaw": -37.770492351965686,
                    "pitch": 0.9448400265856916,
                    "targetPitch": -3.0648860355019085,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 89.65061523408326,
                        "targetYaw": -37.770492351965686,
                        "pitch": 0.9448400265856916,
                        "targetPitch": -3.0648860355019085,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "i12"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5w~qRjf8V[jua}WCj[ofayWBjtfiayayayayaxayjuj[WBRjoeWBj[kCayf6a{fQayfQayayfQ",
                "path": "/assets/pano/default/o8/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/o8/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 4,
                "cubeResolution": 2544
            }
        },
        "aa1": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa1-0",
                    "yaw": 90.27125683994808,
                    "targetYaw": 90.27125683994808,
                    "pitch": -7.196852549209488,
                    "targetPitch": -7.196852549209488,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 90.27125683994808,
                        "targetYaw": 90.27125683994808,
                        "pitch": -7.196852549209488,
                        "targetPitch": -7.196852549209488,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa2"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5R~qE2ayRjayRjoefQayoeofj[oej[j[fkj[ayj@fRayV@t7Rkt7WCj[WBfQj[oLRkj[ayj[WB",
                "path": "/assets/pano/default/aa1/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa1/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa2": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa2-0",
                    "yaw": 89.93203152571243,
                    "targetYaw": 89.93203152571243,
                    "pitch": -2.3989242827567203,
                    "targetPitch": -2.3989242827567203,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 89.93203152571243,
                        "targetYaw": 89.93203152571243,
                        "pitch": -2.3989242827567203,
                        "targetPitch": -2.3989242827567203,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa4"
                    },
                    "div": {}
                },
                {
                    "id": "aa2-1",
                    "yaw": 90.18930678435345,
                    "targetYaw": 90.18930678435345,
                    "pitch": -8.425737591558626,
                    "targetPitch": -8.425737591558626,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 90.18930678435345,
                        "targetYaw": 90.18930678435345,
                        "pitch": -8.425737591558626,
                        "targetPitch": -8.425737591558626,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa3"
                    },
                    "div": {}
                },
                {
                    "id": "aa2-2",
                    "yaw": -89.30115415516366,
                    "targetYaw": -89.30115415516366,
                    "pitch": -9.821246074895464,
                    "targetPitch": -9.821246074895464,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -89.30115415516366,
                        "targetYaw": -89.30115415516366,
                        "pitch": -9.821246074895464,
                        "targetPitch": -9.821246074895464,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa1"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5Q~q9Zj[WBWBRjt6j[ayoLayoLoej[j[j[f6jtj[j[ayWBj[WBt7WBj[j[j[j[j[R*j[fQj[WV",
                "path": "/assets/pano/default/aa2/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa2/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa3": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa3-0",
                    "yaw": 89.68870352025135,
                    "targetYaw": 89.68870352025135,
                    "pitch": -7.620764434640113,
                    "targetPitch": -7.620764434640113,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 89.68870352025135,
                        "targetYaw": 89.68870352025135,
                        "pitch": -7.620764434640113,
                        "targetPitch": -7.620764434640113,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa4"
                    },
                    "div": {}
                },
                {
                    "id": "aa3-1",
                    "yaw": -89.49910736700838,
                    "targetYaw": -89.49910736700838,
                    "pitch": -8.378622620068555,
                    "targetPitch": -8.378622620068555,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -89.49910736700838,
                        "targetYaw": -89.49910736700838,
                        "pitch": -8.378622620068555,
                        "targetPitch": -8.378622620068555,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa2"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5R~qD*j[WBWBM|j?j[WBofayj[j[j[j[offQj@j[aya|RjWBWBt7WVj[t7fQofj[WBj[oej[j[",
                "path": "/assets/pano/default/aa3/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa3/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa4": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa4-0",
                    "yaw": -90.25219386281287,
                    "targetYaw": -90.25219386281287,
                    "pitch": -8.072961264711367,
                    "targetPitch": -8.072961264711367,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -90.25219386281287,
                        "targetYaw": -90.25219386281287,
                        "pitch": -8.072961264711367,
                        "targetPitch": -8.072961264711367,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa3"
                    },
                    "div": {}
                },
                {
                    "id": "aa4-1",
                    "yaw": 90.37523933615034,
                    "targetYaw": 90.37523933615034,
                    "pitch": -9.177962257932666,
                    "targetPitch": -9.177962257932666,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 90.37523933615034,
                        "targetYaw": 90.37523933615034,
                        "pitch": -9.177962257932666,
                        "targetPitch": -9.177962257932666,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa5"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5r~qD%fQfkayRjf6f6RjoLWBj[WVj[WBayj[WBfQWBayM{WCWBj[WCWBaej[j[j@ofj[ofj[kC",
                "path": "/assets/pano/default/aa4/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa4/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa5": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa5-0",
                    "yaw": 54.03999665983412,
                    "targetYaw": 54.03999665983412,
                    "pitch": 11.152450252990729,
                    "targetPitch": 11.152450252990729,
                    "type": "scene-break",
                    "createTooltipArgs": {
                        "yaw": 54.03999665983412,
                        "targetYaw": 54.03999665983412,
                        "pitch": 11.152450252990729,
                        "targetPitch": 11.152450252990729,
                        "type": "scene-break",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                },
                {
                    "id": "aa5-1",
                    "yaw": 102.99688001221034,
                    "targetYaw": 102.99688001221034,
                    "pitch": -2.9587385457596422,
                    "targetPitch": -2.9587385457596422,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 102.99688001221034,
                        "targetYaw": 102.99688001221034,
                        "pitch": -2.9587385457596422,
                        "targetPitch": -2.9587385457596422,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                },
                {
                    "id": "aa5-2",
                    "yaw": 68.39166602584925,
                    "targetYaw": 68.39166602584925,
                    "pitch": -3.6749657728644602,
                    "targetPitch": -3.6749657728644602,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 68.39166602584925,
                        "targetYaw": 68.39166602584925,
                        "pitch": -3.6749657728644602,
                        "targetPitch": -3.6749657728644602,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa10"
                    },
                    "div": {}
                },
                {
                    "id": "aa5-3",
                    "yaw": 89.93415509798831,
                    "targetYaw": 89.93415509798831,
                    "pitch": -6.19017054820952,
                    "targetPitch": -6.19017054820952,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 89.93415509798831,
                        "targetYaw": 89.93415509798831,
                        "pitch": -6.19017054820952,
                        "targetPitch": -6.19017054820952,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa7"
                    },
                    "div": {}
                },
                {
                    "id": "aa5-4",
                    "yaw": -88.41137622450515,
                    "targetYaw": -88.41137622450515,
                    "pitch": -7.662801724429452,
                    "targetPitch": -7.662801724429452,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -88.41137622450515,
                        "targetYaw": -88.41137622450515,
                        "pitch": -7.662801724429452,
                        "targetPitch": -7.662801724429452,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa4"
                    },
                    "div": {}
                },
                {
                    "id": "aa5-5",
                    "yaw": 140.2127920338611,
                    "targetYaw": 140.2127920338611,
                    "pitch": -8.74505872848243,
                    "targetPitch": -8.74505872848243,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 140.2127920338611,
                        "targetYaw": 140.2127920338611,
                        "pitch": -8.74505872848243,
                        "targetPitch": -8.74505872848243,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa6"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5*~qM{fQofayoft7ayofoLj@j[oLj[j[ayj[j[fQfQfQRjRjWBaeayWBayfQWBj[WBj[afj[ay",
                "path": "/assets/pano/default/aa5/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa5/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa7": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa7-0",
                    "yaw": -87.41541788780904,
                    "targetYaw": -87.41541788780904,
                    "pitch": -6.444837718950428,
                    "targetPitch": -6.444837718950428,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -87.41541788780904,
                        "targetYaw": -87.41541788780904,
                        "pitch": -6.444837718950428,
                        "targetPitch": -6.444837718950428,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa5"
                    },
                    "div": {}
                },
                {
                    "id": "aa7-1",
                    "yaw": -138.01754544740413,
                    "targetYaw": -138.01754544740413,
                    "pitch": -6.837598074838517,
                    "targetPitch": -6.837598074838517,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -138.01754544740413,
                        "targetYaw": -138.01754544740413,
                        "pitch": -6.837598074838517,
                        "targetPitch": -6.837598074838517,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa6"
                    },
                    "div": {}
                },
                {
                    "id": "aa7-2",
                    "yaw": 29.635298873947704,
                    "targetYaw": 29.635298873947704,
                    "pitch": -7.617398974638347,
                    "targetPitch": -7.617398974638347,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 29.635298873947704,
                        "targetYaw": 29.635298873947704,
                        "pitch": -7.617398974638347,
                        "targetPitch": -7.617398974638347,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa10"
                    },
                    "div": {}
                },
                {
                    "id": "aa7-3",
                    "yaw": 31.6673774973209,
                    "targetYaw": 31.6673774973209,
                    "pitch": 15.524691612804354,
                    "targetPitch": 15.524691612804354,
                    "type": "scene-break",
                    "createTooltipArgs": {
                        "yaw": 31.6673774973209,
                        "targetYaw": 31.6673774973209,
                        "pitch": 15.524691612804354,
                        "targetPitch": 15.524691612804354,
                        "type": "scene-break",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5-~qM{fQt7a|t7s:ayofj[WBfQj[fQjuWBj[WVfQf6fQbbjZj[t7jtayozj[j[fQWBfQjZjtjt",
                "path": "/assets/pano/default/aa7/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa7/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa6": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa6-0",
                    "yaw": 78.38381695951011,
                    "targetYaw": 78.38381695951011,
                    "pitch": -5.901695024581663,
                    "targetPitch": -5.901695024581663,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 78.38381695951011,
                        "targetYaw": 78.38381695951011,
                        "pitch": -5.901695024581663,
                        "targetPitch": -5.901695024581663,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                },
                {
                    "id": "aa6-1",
                    "yaw": 49.72349389078363,
                    "targetYaw": 49.72349389078363,
                    "pitch": -8.284244359489193,
                    "targetPitch": -8.284244359489193,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 49.72349389078363,
                        "targetYaw": 49.72349389078363,
                        "pitch": -8.284244359489193,
                        "targetPitch": -8.284244359489193,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa7"
                    },
                    "div": {}
                },
                {
                    "id": "aa6-2",
                    "yaw": -52.07826600639612,
                    "targetYaw": -52.07826600639612,
                    "pitch": -9.05082705934457,
                    "targetPitch": -9.05082705934457,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -52.07826600639612,
                        "targetYaw": -52.07826600639612,
                        "pitch": -9.05082705934457,
                        "targetPitch": -9.05082705934457,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa5"
                    },
                    "div": {}
                },
                {
                    "id": "aa6-3",
                    "yaw": -70.29451294303499,
                    "targetYaw": -70.29451294303499,
                    "pitch": -3.778032421577947,
                    "targetPitch": -3.778032421577947,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -70.29451294303499,
                        "targetYaw": -70.29451294303499,
                        "pitch": -3.778032421577947,
                        "targetPitch": -3.778032421577947,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa4"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5*~qRjayt6j[ofxtfQt7WBj[ayoeayfQWBfQfQayj[ayRkaza{oLayWBofayayj[ayayayayay",
                "path": "/assets/pano/default/aa6/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa6/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa8": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa8-0",
                    "yaw": 144.81815057387894,
                    "targetYaw": 144.81815057387894,
                    "pitch": -3.471412242998084,
                    "targetPitch": -3.471412242998084,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 144.81815057387894,
                        "targetYaw": 144.81815057387894,
                        "pitch": -3.471412242998084,
                        "targetPitch": -3.471412242998084,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa14"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-1",
                    "yaw": -70.19484688912593,
                    "targetYaw": -70.19484688912593,
                    "pitch": -3.824959438886025,
                    "targetPitch": -3.824959438886025,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -70.19484688912593,
                        "targetYaw": -70.19484688912593,
                        "pitch": -3.824959438886025,
                        "targetPitch": -3.824959438886025,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa5"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-2",
                    "yaw": 100.2081065756986,
                    "targetYaw": 100.2081065756986,
                    "pitch": -4.092865431434509,
                    "targetPitch": -4.092865431434509,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 100.2081065756986,
                        "targetYaw": 100.2081065756986,
                        "pitch": -4.092865431434509,
                        "targetPitch": -4.092865431434509,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-3",
                    "yaw": 171.21297596939075,
                    "targetYaw": 171.21297596939075,
                    "pitch": -5.3572715791307335,
                    "targetPitch": -5.3572715791307335,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 171.21297596939075,
                        "targetYaw": 171.21297596939075,
                        "pitch": -5.3572715791307335,
                        "targetPitch": -5.3572715791307335,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa15"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-4",
                    "yaw": -97.00118143622308,
                    "targetYaw": -97.00118143622308,
                    "pitch": -6.504340472553531,
                    "targetPitch": -6.504340472553531,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -97.00118143622308,
                        "targetYaw": -97.00118143622308,
                        "pitch": -6.504340472553531,
                        "targetPitch": -6.504340472553531,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa6"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-5",
                    "yaw": 49.28546023803068,
                    "targetYaw": 49.28546023803068,
                    "pitch": -7.265817306816699,
                    "targetPitch": -7.265817306816699,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 49.28546023803068,
                        "targetYaw": 49.28546023803068,
                        "pitch": -7.265817306816699,
                        "targetPitch": -7.265817306816699,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa9"
                    },
                    "div": {}
                },
                {
                    "id": "aa8-6",
                    "yaw": -32.40674856414762,
                    "targetYaw": -32.40674856414762,
                    "pitch": -10.504006611412686,
                    "targetPitch": -10.504006611412686,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -32.40674856414762,
                        "targetYaw": -32.40674856414762,
                        "pitch": -10.504006611412686,
                        "targetPitch": -10.504006611412686,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa7"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5,~qM{juofj[ofofbGozf6WBaybHfQayWVj[WBfQj[j[a#ofayt7ayayofayf6j[WBj[fQayj[",
                "path": "/assets/pano/default/aa8/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa8/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa9": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa9-0",
                    "yaw": -118.46668764893116,
                    "targetYaw": -118.46668764893116,
                    "pitch": -8.47953918042464,
                    "targetPitch": -8.47953918042464,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -118.46668764893116,
                        "targetYaw": -118.46668764893116,
                        "pitch": -8.47953918042464,
                        "targetPitch": -8.47953918042464,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5,~qM{fka#ayofj[ayoffkWBfQayf6ayayj[ayayjtj[oft7j[t7j[jtofj@f6j[WBfQayj[jt",
                "path": "/assets/pano/default/aa9/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa9/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa10": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa10-0",
                    "yaw": -110.01661279938332,
                    "targetYaw": -110.01661279938332,
                    "pitch": -5.1467814422622205,
                    "targetPitch": -5.1467814422622205,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -110.01661279938332,
                        "targetYaw": -110.01661279938332,
                        "pitch": -5.1467814422622205,
                        "targetPitch": -5.1467814422622205,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa5"
                    },
                    "div": {}
                },
                {
                    "id": "aa10-1",
                    "yaw": -153.62352686991403,
                    "targetYaw": -153.62352686991403,
                    "pitch": -9.666475404545531,
                    "targetPitch": -9.666475404545531,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -153.62352686991403,
                        "targetYaw": -153.62352686991403,
                        "pitch": -9.666475404545531,
                        "targetPitch": -9.666475404545531,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa7"
                    },
                    "div": {}
                },
                {
                    "id": "aa10-2",
                    "yaw": 34.97690007134463,
                    "targetYaw": 34.97690007134463,
                    "pitch": 25.185691933167472,
                    "targetPitch": 25.185691933167472,
                    "type": "scene-break",
                    "createTooltipArgs": {
                        "yaw": 34.97690007134463,
                        "targetYaw": 34.97690007134463,
                        "pitch": 25.185691933167472,
                        "targetPitch": 25.185691933167472,
                        "type": "scene-break",
                        "productSlug": "",
                        "sceneId": "i13"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5,~qM{kCofaeofofWBozj[WBkCfkfkf6WVj[WBayjtaxfkofj[t7ofj[ofj[ayayWBayfRj[j[",
                "path": "/assets/pano/default/aa10/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa10/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa11": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa11-0",
                    "yaw": 118.22933212121472,
                    "targetYaw": 118.22933212121472,
                    "pitch": -19.17511561032922,
                    "targetPitch": -19.17511561032922,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 118.22933212121472,
                        "targetYaw": 118.22933212121472,
                        "pitch": -19.17511561032922,
                        "targetPitch": -19.17511561032922,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5.~qM_a}t7ayf6t7ayj[ayf5ayayayjta{ayf6j[j[fRfRj[j[ayfQayWBfQfQayayj[ayayf7",
                "path": "/assets/pano/default/aa11/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa11/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa12": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa12-0",
                    "yaw": -131.2473079439671,
                    "targetYaw": -131.2473079439671,
                    "pitch": -3.654904580623565,
                    "targetPitch": -3.654904580623565,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -131.2473079439671,
                        "targetYaw": -131.2473079439671,
                        "pitch": -3.654904580623565,
                        "targetPitch": -3.654904580623565,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa15"
                    },
                    "div": {}
                },
                {
                    "id": "aa12-1",
                    "yaw": -69.3072081397579,
                    "targetYaw": -69.3072081397579,
                    "pitch": -4.35230056355636,
                    "targetPitch": -4.35230056355636,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -69.3072081397579,
                        "targetYaw": -69.3072081397579,
                        "pitch": -4.35230056355636,
                        "targetPitch": -4.35230056355636,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                },
                {
                    "id": "aa12-2",
                    "yaw": -158.43100364110023,
                    "targetYaw": -158.43100364110023,
                    "pitch": -6.745655529797826,
                    "targetPitch": -6.745655529797826,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -158.43100364110023,
                        "targetYaw": -158.43100364110023,
                        "pitch": -6.745655529797826,
                        "targetPitch": -6.745655529797826,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa14"
                    },
                    "div": {}
                },
                {
                    "id": "aa12-3",
                    "yaw": 122.4945705797868,
                    "targetYaw": 122.4945705797868,
                    "pitch": -7.013858120261746,
                    "targetPitch": -7.013858120261746,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 122.4945705797868,
                        "targetYaw": 122.4945705797868,
                        "pitch": -7.013858120261746,
                        "targetPitch": -7.013858120261746,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa13"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5%~qM{ofWBfjofazfjkBayWBj[ayf6ayayjuayj[ayayt7ofayofayj[kBf7f7j[WBj[ayfQj[",
                "path": "/assets/pano/default/aa12/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa12/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa13": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa13-0",
                    "yaw": -65.47177582406573,
                    "targetYaw": -65.47177582406573,
                    "pitch": -2.2090265666489888,
                    "targetPitch": -2.2090265666489888,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -65.47177582406573,
                        "targetYaw": -65.47177582406573,
                        "pitch": -2.2090265666489888,
                        "targetPitch": -2.2090265666489888,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                },
                {
                    "id": "aa13-1",
                    "yaw": -103.65526569644919,
                    "targetYaw": -103.65526569644919,
                    "pitch": -2.5716264418605093,
                    "targetPitch": -2.5716264418605093,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -103.65526569644919,
                        "targetYaw": -103.65526569644919,
                        "pitch": -2.5716264418605093,
                        "targetPitch": -2.5716264418605093,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa15"
                    },
                    "div": {}
                },
                {
                    "id": "aa13-2",
                    "yaw": -149.77314740995564,
                    "targetYaw": -149.77314740995564,
                    "pitch": -3.8180935418837514,
                    "targetPitch": -3.8180935418837514,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -149.77314740995564,
                        "targetYaw": -149.77314740995564,
                        "pitch": -3.8180935418837514,
                        "targetPitch": -3.8180935418837514,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa17"
                    },
                    "div": {}
                },
                {
                    "id": "aa13-3",
                    "yaw": -121.30154498741535,
                    "targetYaw": -121.30154498741535,
                    "pitch": -4.968844552808521,
                    "targetPitch": -4.968844552808521,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -121.30154498741535,
                        "targetYaw": -121.30154498741535,
                        "pitch": -4.968844552808521,
                        "targetPitch": -4.968844552808521,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa14"
                    },
                    "div": {}
                },
                {
                    "id": "aa13-4",
                    "yaw": -54.123015877679904,
                    "targetYaw": -54.123015877679904,
                    "pitch": -9.101366462916557,
                    "targetPitch": -9.101366462916557,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -54.123015877679904,
                        "targetYaw": -54.123015877679904,
                        "pitch": -9.101366462916557,
                        "targetPitch": -9.101366462916557,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5$~qM{ofV[ofj[ayfij[WBWBfjfQayfRayj[ayj[ayj[ofogaxj[ayj[fif7ayj[ayj[ayj[fQ",
                "path": "/assets/pano/default/aa13/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa13/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa14": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa14-0",
                    "yaw": -30.481183003460444,
                    "targetYaw": -30.481183003460444,
                    "pitch": -3.7986351175587405,
                    "targetPitch": -3.7986351175587405,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -30.481183003460444,
                        "targetYaw": -30.481183003460444,
                        "pitch": -3.7986351175587405,
                        "targetPitch": -3.7986351175587405,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                },
                {
                    "id": "aa14-1",
                    "yaw": 63.03760601351763,
                    "targetYaw": 63.03760601351763,
                    "pitch": -4.970338004679556,
                    "targetPitch": -4.970338004679556,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 63.03760601351763,
                        "targetYaw": 63.03760601351763,
                        "pitch": -4.970338004679556,
                        "targetPitch": -4.970338004679556,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa13"
                    },
                    "div": {}
                },
                {
                    "id": "aa14-2",
                    "yaw": 18.592181125510024,
                    "targetYaw": 18.592181125510024,
                    "pitch": -5.229402731950425,
                    "targetPitch": -5.229402731950425,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 18.592181125510024,
                        "targetYaw": 18.592181125510024,
                        "pitch": -5.229402731950425,
                        "targetPitch": -5.229402731950425,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                },
                {
                    "id": "aa14-3",
                    "yaw": 134.16210775221714,
                    "targetYaw": 134.16210775221714,
                    "pitch": -15.112200690416838,
                    "targetPitch": -15.112200690416838,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 134.16210775221714,
                        "targetYaw": 134.16210775221714,
                        "pitch": -15.112200690416838,
                        "targetPitch": -15.112200690416838,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa17"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5-~qRjayt7ofaxofofj[WBWUayj[ayj[j[axayjuayj[ofofaekBWBj[fPWBayj[juayWVj@ay",
                "path": "/assets/pano/default/aa14/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa14/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa15": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa15-0",
                    "yaw": 53.66303054846048,
                    "targetYaw": 53.66303054846048,
                    "pitch": -3.1623680907353124,
                    "targetPitch": -3.1623680907353124,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 53.66303054846048,
                        "targetYaw": 53.66303054846048,
                        "pitch": -3.1623680907353124,
                        "targetPitch": -3.1623680907353124,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                },
                {
                    "id": "aa15-1",
                    "yaw": -4.669640044499829,
                    "targetYaw": -4.669640044499829,
                    "pitch": -3.485382155384786,
                    "targetPitch": -3.485382155384786,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -4.669640044499829,
                        "targetYaw": -4.669640044499829,
                        "pitch": -3.485382155384786,
                        "targetPitch": -3.485382155384786,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa8"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5.~qM{ofoyofofoffQofayWBayayayjZWVj[WBayfkfjWBj[ayofWBjZofWBkCj[ayfRj[kBf8",
                "path": "/assets/pano/default/aa15/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa15/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa16": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5.~qRjt7ofofofoLfQkBayWBj[WBayj[ayj[WBfQj[afWCa|a{kBWBfRjtWBofj[WBa{fQj[af",
                "path": "/assets/pano/default/aa16/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa16/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa17": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa17-0",
                    "yaw": 11.517175513794314,
                    "targetYaw": 11.517175513794314,
                    "pitch": -3.4835280782285594,
                    "targetPitch": -3.4835280782285594,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 11.517175513794314,
                        "targetYaw": 11.517175513794314,
                        "pitch": -3.4835280782285594,
                        "targetPitch": -3.4835280782285594,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa12"
                    },
                    "div": {}
                },
                {
                    "id": "aa17-1",
                    "yaw": 36.22385205421671,
                    "targetYaw": 36.22385205421671,
                    "pitch": -3.494533074192859,
                    "targetPitch": -3.494533074192859,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 36.22385205421671,
                        "targetYaw": 36.22385205421671,
                        "pitch": -3.494533074192859,
                        "targetPitch": -3.494533074192859,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa13"
                    },
                    "div": {}
                },
                {
                    "id": "aa17-2",
                    "yaw": -139.4793465477701,
                    "targetYaw": -139.4793465477701,
                    "pitch": -7.927320902269343,
                    "targetPitch": -7.927320902269343,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -139.4793465477701,
                        "targetYaw": -139.4793465477701,
                        "pitch": -7.927320902269343,
                        "targetPitch": -7.927320902269343,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa18"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5u~qt7NFxuoft7j[oMfQayWUWBayayWBayaxj[aya|WBs;j[s;ayayflj[ayofofayofj[WBay",
                "path": "/assets/pano/default/aa17/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa17/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa18": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa18-0",
                    "yaw": -140.51888985894948,
                    "targetYaw": -140.51888985894948,
                    "pitch": -3.906178849106035,
                    "targetPitch": -3.906178849106035,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -140.51888985894948,
                        "targetYaw": -140.51888985894948,
                        "pitch": -3.906178849106035,
                        "targetPitch": -3.906178849106035,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa19"
                    },
                    "div": {}
                },
                {
                    "id": "aa18-1",
                    "yaw": 39.71788014145676,
                    "targetYaw": 39.71788014145676,
                    "pitch": -5.904571324898203,
                    "targetPitch": -5.904571324898203,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 39.71788014145676,
                        "targetYaw": 39.71788014145676,
                        "pitch": -5.904571324898203,
                        "targetPitch": -5.904571324898203,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa17"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5s~qxubbxuofj[j[ayj[WBWBWBfQayR%ofaxj[juWBayjGWBoza{j[WBfjoffRj[j[ofj[j[oe",
                "path": "/assets/pano/default/aa18/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa18/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa19": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa19-0",
                    "yaw": -131.98112739629244,
                    "targetYaw": -131.98112739629244,
                    "pitch": -4.022235032880342,
                    "targetPitch": -4.022235032880342,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -131.98112739629244,
                        "targetYaw": -131.98112739629244,
                        "pitch": -4.022235032880342,
                        "targetPitch": -4.022235032880342,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa20"
                    },
                    "div": {}
                },
                {
                    "id": "aa19-1",
                    "yaw": 39.41591352273127,
                    "targetYaw": 39.41591352273127,
                    "pitch": -4.04476030738013,
                    "targetPitch": -4.04476030738013,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 39.41591352273127,
                        "targetYaw": 39.41591352273127,
                        "pitch": -4.04476030738013,
                        "targetPitch": -4.04476030738013,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa18"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5t~qxuWBt8ayj[WCoff6ayWAoMbGj[WBWBa|j[jtaekCs;azt7Woofj[fkj[j[ayofayf6azWB",
                "path": "/assets/pano/default/aa19/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa19/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        },
        "aa20": {
            "type": "multires",
            "panorama": null,
            "compass": true,
            "hotSpots": [
                {
                    "id": "aa20-0",
                    "yaw": 44.603096737411306,
                    "targetYaw": 44.603096737411306,
                    "pitch": -5.067203846367992,
                    "targetPitch": -5.067203846367992,
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 44.603096737411306,
                        "targetYaw": 44.603096737411306,
                        "pitch": -5.067203846367992,
                        "targetPitch": -5.067203846367992,
                        "type": "scene-custom",
                        "productSlug": "",
                        "sceneId": "aa19"
                    },
                    "div": {}
                }
            ],
            "hfov": 100,
            "multiRes": {
                "shtHash": "5w~qt7RjtRWVWUbHjuWVj[WAoMj[jtayaej[oLjtaxjZt7oLozWVoyjsWVofj[kCa}aykCj[fk",
                "path": "/assets/pano/default/aa20/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/default/aa20/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 5,
                "cubeResolution": 5208
            }
        }
    }
}