import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuraService } from '../../services/aura_services';
import { DialogPrimaryComponent } from '../dialog-primary/dialog-primary.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmInfoComponent } from '../pano/dev/dialog-confirm-info/dialog-confirm-info.component';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [CommonModule],
  providers: [AuraService],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.css'
})
export class PaymentComponent {
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private auraService: AuraService,
    private dialog: MatDialog,
  ){};

  public price:string = '';
  public pricePayment:number = 0;
  public ref:string = '';
  public qrLink: string = '';
  public egContent: string = 'NguyenVanA-0856538112-FitiOnline'
  public profile: any;
  public statusPayment: string = '';

  private refs = [
    "CAPITALX",
    "AURACAPITAL",
    "KYBALION",
    "MSBICH",
    "CHUYENVIEN01",
    "CHUYENVIEN02",
    "CODONG01",
    "CODONG02",
    "DOITAC01",
    "DOITAC02",
  ];
  

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async queryParamMap => {
      this.ref = queryParamMap.get('ref') ?? '';
      if (this.refs.includes(this.ref.toUpperCase())) {
        this.price = '5,500,000đ';
        this.pricePayment = 5500000;
      }
      else {
        this.price = '6,600,000đ';
        this.pricePayment = 6600000;
      }
      await this.updateQR();
    });
    this.auraService.updatePayment(
      false,
      this.pricePayment,
      this.ref,
    );
  }

  public isStatusPayment(status: string) {
    return status == this.statusPayment;
  }

  private removeAccents(text: string) {
    return text.normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/đ/g, 'd').replace(/Đ/g, 'D');
  }

  private async updateQR() {
    try {
      this.profile = (await this.auraService.profile())['data'] ?? {};
    }
    catch {
      this.profile = undefined;
      this.router.navigate(['/']);
      return;
    }

    for (let course of (this.profile?.courses ?? [])) {
      console.log('course', course);
      if (course.code == '3D-LMS') {
        this.statusPayment = `${course.status}` == '1' ? 'payment' : 'watingpayment';
      }
    }

    const name = this.profile?.full_name ?? 'Nguyễn Văn A';
    const phone = this.profile?.username ?? '0123456789';
    const nameReplace = this.removeAccents(name).replaceAll(/[^a-zA-Z]/g, '');
    this.egContent = `${nameReplace} ${phone} FitiOnline`

    if (name == 'Nguyễn Văn A' || phone == '0123456789') {
      this.qrLink = `https://api.vietqr.io/image/970436-0071003456147-i5OATs2.jpg?accountName=VND-GTGT-CTCP%20AURA%20CAPITAL&amount=${this.pricePayment}&addInfo=`;
    }
    else {
      this.qrLink = `https://api.vietqr.io/image/970436-0071003456147-i5OATs2.jpg?accountName=VND-GTGT-CTCP%20AURA%20CAPITAL&amount=${this.pricePayment}&addInfo=${this.egContent}`;
    }
    
  }


  async back() {
    const dialogRef = this.dialog.open(DialogConfirmInfoComponent, {
      data: {
        title: 'Bạn đã thanh toán?',
        content: 'Nếu bạn đã thanh toán, hãy ấn xác nhận để thông báo đến nhân viên kiểm duyệt của Aura Capital. Nếu chưa, bạn có thể trở về tham quan lớp học 3D!',
      }
    });

    const scr = dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.auraService.updatePayment(
          true,
          this.pricePayment,
          this.ref,
        );
        await this.auraService.startPayment();
        this.dialog.open(DialogPrimaryComponent, {
          data: {
            title: 'Thông báo.',
            content: 'Dữ liệu của bạn đã được ghi nhận, vui lòng chờ nhân viên kiểm duyệt!'
          }
        });
        this.router.navigate(['/']);
      }
      else {
        this.router.navigate(['/']);
      }
      scr.unsubscribe();
    });
  }

  async success() {
    // if (this.isStatusPayment('payment')) {
    //   this.dialog.open(DialogPrimaryComponent, {
    //     data: {
    //       title: 'Thông báo.',
    //       content: 'Bạn đã mua khóa học này rồi!'
    //     }
    //   });
    //   return;
    // }

    const dialogRef = this.dialog.open(DialogConfirmInfoComponent, {
      data: {
        title: 'Xác nhận thanh toán?',
        content: 'Nếu bạn đã thanh toán, hãy ấn xác nhận để thông báo đến nhân viên kiểm duyệt của Aura Capital!'
      }
    });

    const scr = dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.auraService.updatePayment(
          true,
          this.pricePayment,
          this.ref,
        );
        await this.auraService.startPayment();
        await this.auraService.notiStartPayment(
          this.price,
          this.profile.full_name,
          this.profile.username,
          this.profile.email,
          this.egContent,
          this.ref,
        );
        this.dialog.open(DialogPrimaryComponent, {
          data: {
            title: 'Thông báo.',
            content: 'Dữ liệu của bạn đã được ghi nhận, vui lòng chờ nhân viên kiểm duyệt!'
          }
        });
        this.router.navigate(['/']);
      }
      scr.unsubscribe();
    });
    
  }

}
