{
    "default": {
        "autoLoad": true,
        "compass": true,
        "firstScene": "bin3",
        "sceneFadeDuration": 1000,
        "showControls": true
    },
    "scenes": {
        "bin0": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin1.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": -37.91844502635388,
                    "targetYaw": -37.91844502635388,
                    "pitch": -62.99498670599525,
                    "targetPitch": -62.99498670599525,
                    "sceneId": "bin1",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -37.91844502635388,
                        "targetYaw": -37.91844502635388,
                        "pitch": -62.99498670599525,
                        "targetPitch": -62.99498670599525,
                        "sceneId": "bin1",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -4.755263886866032,
                    "targetYaw": -4.755263886866032,
                    "pitch": -67.78814594287854,
                    "targetPitch": -67.78814594287854,
                    "sceneId": "bin2",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -4.755263886866032,
                        "targetYaw": -4.755263886866032,
                        "pitch": -67.78814594287854,
                        "targetPitch": -67.78814594287854,
                        "sceneId": "bin2",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 22.84897641861471,
                    "targetYaw": 22.84897641861471,
                    "pitch": -64.8810346777961,
                    "targetPitch": -64.8810346777961,
                    "sceneId": "bin3",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 22.84897641861471,
                        "targetYaw": 22.84897641861471,
                        "pitch": -64.8810346777961,
                        "targetPitch": -64.8810346777961,
                        "sceneId": "bin3",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -23.757527032539315,
                    "targetYaw": -23.757527032539315,
                    "pitch": -50.49595459035826,
                    "targetPitch": -50.49595459035826,
                    "sceneId": "bin4",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -23.757527032539315,
                        "targetYaw": -23.757527032539315,
                        "pitch": -50.49595459035826,
                        "targetPitch": -50.49595459035826,
                        "sceneId": "bin4",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -0.8133624598973483,
                    "targetYaw": -0.8133624598973483,
                    "pitch": -53.639749177797285,
                    "targetPitch": -53.639749177797285,
                    "sceneId": "bin5",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.8133624598973483,
                        "targetYaw": -0.8133624598973483,
                        "pitch": -53.639749177797285,
                        "targetPitch": -53.639749177797285,
                        "sceneId": "bin5",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 19.728913902308136,
                    "targetYaw": 19.728913902308136,
                    "pitch": -51.593717741610845,
                    "targetPitch": -51.593717741610845,
                    "sceneId": "bin6",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 19.728913902308136,
                        "targetYaw": 19.728913902308136,
                        "pitch": -51.593717741610845,
                        "targetPitch": -51.593717741610845,
                        "sceneId": "bin6",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5x~WofWBozR*jYf6jYWVWBWWf6ofkCofj]azfkj[j[fQWBfRaxa}kCayWBj[ayj[WBj[ayayof",
                "path": "/assets/pano/nuocmam/bin0/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin0/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin1": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin1.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": 0.0710701780162708,
                    "targetYaw": 0.0710701780162708,
                    "pitch": -20.101192882922685,
                    "targetPitch": -20.101192882922685,
                    "sceneId": "bin2",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.0710701780162708,
                        "targetYaw": 0.0710701780162708,
                        "pitch": -20.101192882922685,
                        "targetPitch": -20.101192882922685,
                        "sceneId": "bin2",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -0.6356539737153071,
                    "targetYaw": -0.6356539737153071,
                    "pitch": -10.509463474221233,
                    "targetPitch": -10.509463474221233,
                    "sceneId": "bin3",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.6356539737153071,
                        "targetYaw": -0.6356539737153071,
                        "pitch": -10.509463474221233,
                        "targetPitch": -10.509463474221233,
                        "sceneId": "bin3",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 52.7894049617322,
                    "targetYaw": 52.7894049617322,
                    "pitch": -34.60952821796006,
                    "targetPitch": -34.60952821796006,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 52.7894049617322,
                        "targetYaw": 52.7894049617322,
                        "pitch": -34.60952821796006,
                        "targetPitch": -34.60952821796006,
                        "type": "product",
                        "productSlug": "rich-flavorful-kk03",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": 15.206010989818424,
                    "targetYaw": 15.206010989818424,
                    "pitch": -15.076569752545854,
                    "targetPitch": -15.076569752545854,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 15.206010989818424,
                        "targetYaw": 15.206010989818424,
                        "pitch": -15.076569752545854,
                        "targetPitch": -15.076569752545854,
                        "type": "product",
                        "productSlug": "taste-of-home-sc-500ml-kk06",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -40.60858383336398,
                    "targetYaw": -40.60858383336398,
                    "pitch": -12.387832860876824,
                    "targetPitch": -12.387832860876824,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -40.60858383336398,
                        "targetYaw": -40.60858383336398,
                        "pitch": -12.387832860876824,
                        "targetPitch": -12.387832860876824,
                        "type": "product",
                        "productSlug": "102-fish-sauce-sa-chau-kk05",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -29.077323736109406,
                    "targetYaw": -29.077323736109406,
                    "pitch": -8.804720842172946,
                    "targetPitch": -8.804720842172946,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -29.077323736109406,
                        "targetYaw": -29.077323736109406,
                        "pitch": -8.804720842172946,
                        "targetPitch": -8.804720842172946,
                        "type": "product",
                        "productSlug": "102-fish-sauce-phu-quoc-kk04",
                        "sceneId": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5x~WofWBozR*jYf6jYWVWBWWf6ofkCofj]azfkj[j[fQWBfRaxa}kCayWBj[ayj[WBj[ayayof",
                "path": "/assets/pano/nuocmam/bin1/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin1/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin2": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin2.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": -0.9750573038809307,
                    "targetYaw": -0.9750573038809307,
                    "pitch": -19.77956987366411,
                    "targetPitch": -19.77956987366411,
                    "sceneId": "bin3",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.9750573038809307,
                        "targetYaw": -0.9750573038809307,
                        "pitch": -19.77956987366411,
                        "targetPitch": -19.77956987366411,
                        "sceneId": "bin3",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 174.8444807688761,
                    "targetYaw": 174.8444807688761,
                    "pitch": -19.231381995038884,
                    "targetPitch": -19.231381995038884,
                    "sceneId": "bin1",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 174.8444807688761,
                        "targetYaw": 174.8444807688761,
                        "pitch": -19.231381995038884,
                        "targetPitch": -19.231381995038884,
                        "sceneId": "bin1",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -44.11084024231377,
                    "targetYaw": -44.11084024231377,
                    "pitch": -7.901206550103698,
                    "targetPitch": -7.901206550103698,
                    "sceneId": "bin4",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -44.11084024231377,
                        "targetYaw": -44.11084024231377,
                        "pitch": -7.901206550103698,
                        "targetPitch": -7.901206550103698,
                        "sceneId": "bin4",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -32.692655136944154,
                    "targetYaw": -32.692655136944154,
                    "pitch": -5.542253623649087,
                    "targetPitch": -5.542253623649087,
                    "sceneId": "bin6",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -32.692655136944154,
                        "targetYaw": -32.692655136944154,
                        "pitch": -5.542253623649087,
                        "targetPitch": -5.542253623649087,
                        "sceneId": "bin6",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 155.0217772186653,
                    "targetYaw": 155.0217772186653,
                    "pitch": -20.723700451067447,
                    "targetPitch": -20.723700451067447,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 155.0217772186653,
                        "targetYaw": 155.0217772186653,
                        "pitch": -20.723700451067447,
                        "targetPitch": -20.723700451067447,
                        "type": "product",
                        "productSlug": "rich-flavorful-kk03",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": 79.15499919199692,
                    "targetYaw": 79.15499919199692,
                    "pitch": -42.615431953222526,
                    "targetPitch": -42.615431953222526,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 79.15499919199692,
                        "targetYaw": 79.15499919199692,
                        "pitch": -42.615431953222526,
                        "targetPitch": -42.615431953222526,
                        "type": "product",
                        "productSlug": "taste-of-home-sc-500ml-kk06",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -110.06029826483638,
                    "targetYaw": -110.06029826483638,
                    "pitch": -17.137654764143335,
                    "targetPitch": -17.137654764143335,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -110.06029826483638,
                        "targetYaw": -110.06029826483638,
                        "pitch": -17.137654764143335,
                        "targetPitch": -17.137654764143335,
                        "type": "product",
                        "productSlug": "102-fish-sauce-sa-chau-kk05",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -77.23224247730042,
                    "targetYaw": -77.23224247730042,
                    "pitch": -19.09916399489053,
                    "targetPitch": -19.09916399489053,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -77.23224247730042,
                        "targetYaw": -77.23224247730042,
                        "pitch": -19.09916399489053,
                        "targetPitch": -19.09916399489053,
                        "type": "product",
                        "productSlug": "102-fish-sauce-phu-quoc-kk04",
                        "sceneId": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5$~Wt7WBogj[ogWBj[fPfPWBfQofayoLj[j[ofj[a}WBayoKa}kCWBf6WCj[WBa}jZj[ayoLWC",
                "path": "/assets/pano/nuocmam/bin2/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin2/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin3": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin3.png",
            "compass": true,
            "yaw": -88.13603494071816,
            "pitch": -10.897559945080179,
            "hotSpots": [
                {
                    "yaw": 179.97292028002266,
                    "targetYaw": 179.97292028002266,
                    "pitch": -20.020278151163296,
                    "targetPitch": -20.020278151163296,
                    "sceneId": "bin2",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.97292028002266,
                        "targetYaw": 179.97292028002266,
                        "pitch": -20.020278151163296,
                        "targetPitch": -20.020278151163296,
                        "sceneId": "bin2",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 177.44007221479842,
                    "targetYaw": 177.44007221479842,
                    "pitch": -10.11685478240732,
                    "targetPitch": -10.11685478240732,
                    "sceneId": "bin1",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 177.44007221479842,
                        "targetYaw": 177.44007221479842,
                        "pitch": -10.11685478240732,
                        "targetPitch": -10.11685478240732,
                        "sceneId": "bin1",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -88.13603494071816,
                    "targetYaw": -88.13603494071816,
                    "pitch": -10.897559945080179,
                    "targetPitch": -10.897559945080179,
                    "sceneId": "bin4",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -88.13603494071816,
                        "targetYaw": -88.13603494071816,
                        "pitch": -10.897559945080179,
                        "targetPitch": -10.897559945080179,
                        "sceneId": "bin4",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -58.42862092844018,
                    "targetYaw": -58.42862092844018,
                    "pitch": -8.280638082484337,
                    "targetPitch": -8.280638082484337,
                    "sceneId": "bin6",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -58.42862092844018,
                        "targetYaw": -58.42862092844018,
                        "pitch": -8.280638082484337,
                        "targetPitch": -8.280638082484337,
                        "sceneId": "bin6",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -119.59208728905634,
                    "targetYaw": -119.59208728905634,
                    "pitch": -8.696095027963773,
                    "targetPitch": -8.696095027963773,
                    "sceneId": "bin5",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -119.59208728905634,
                        "targetYaw": -119.59208728905634,
                        "pitch": -8.696095027963773,
                        "targetPitch": -8.696095027963773,
                        "sceneId": "bin5",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 168.50506871243715,
                    "targetYaw": 168.50506871243715,
                    "pitch": -9.6298452444411,
                    "targetPitch": -9.6298452444411,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 168.50506871243715,
                        "targetYaw": 168.50506871243715,
                        "pitch": -9.6298452444411,
                        "targetPitch": -9.6298452444411,
                        "type": "product",
                        "productSlug": "rich-flavorful-kk03",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": 161.11233664907985,
                    "targetYaw": 161.11233664907985,
                    "pitch": -17.588165509421877,
                    "targetPitch": -17.588165509421877,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 161.11233664907985,
                        "targetYaw": 161.11233664907985,
                        "pitch": -17.588165509421877,
                        "targetPitch": -17.588165509421877,
                        "type": "product",
                        "productSlug": "taste-of-home-sc-500ml-kk06",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -153.95877908864156,
                    "targetYaw": -153.95877908864156,
                    "pitch": -7.92478379525488,
                    "targetPitch": -7.92478379525488,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -153.95877908864156,
                        "targetYaw": -153.95877908864156,
                        "pitch": -7.92478379525488,
                        "targetPitch": -7.92478379525488,
                        "type": "product",
                        "productSlug": "102-fish-sauce-sa-chau-kk05",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -144.28938251390196,
                    "targetYaw": -144.28938251390196,
                    "pitch": -9.388339271568647,
                    "targetPitch": -9.388339271568647,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -144.28938251390196,
                        "targetYaw": -144.28938251390196,
                        "pitch": -9.388339271568647,
                        "targetPitch": -9.388339271568647,
                        "type": "product",
                        "productSlug": "102-fish-sauce-phu-quoc-kk04",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": 24.785611716161043,
                    "targetYaw": 24.785611716161043,
                    "pitch": -0.9105894424350325,
                    "targetPitch": -0.9105894424350325,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": 24.785611716161043,
                        "targetYaw": 24.785611716161043,
                        "pitch": -0.9105894424350325,
                        "targetPitch": -0.9105894424350325,
                        "type": "product",
                        "productSlug": "taste-of-home-pq-500ml-kk07",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -24.445692949561135,
                    "targetYaw": -24.445692949561135,
                    "pitch": -1.6527340007088167,
                    "targetPitch": -1.6527340007088167,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -24.445692949561135,
                        "targetYaw": -24.445692949561135,
                        "pitch": -1.6527340007088167,
                        "targetPitch": -1.6527340007088167,
                        "type": "product",
                        "productSlug": "taste-of-home-pq-500ml-kk07",
                        "sceneId": ""
                    }
                }
            ],
            "showControls": true,
            "hfov": 100.0,
            "multiRes": {
                "shtHash": "5.~WkDayt8jZbHa}ayj[ayWCfQbIWBofofoLf6jtj[fRe.j[j[a}fQazaejZayj[aya}j[jsay",
                "path": "/assets/pano/nuocmam/bin3/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin3/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin4": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin4.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": 90.5431325616479,
                    "targetYaw": 90.5431325616479,
                    "pitch": -19.305607038830985,
                    "targetPitch": -19.305607038830985,
                    "sceneId": "bin3",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 90.5431325616479,
                        "targetYaw": 90.5431325616479,
                        "pitch": -19.305607038830985,
                        "targetPitch": -19.305607038830985,
                        "sceneId": "bin3",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 128.48328214248227,
                    "targetYaw": 128.48328214248227,
                    "pitch": -14.284348257130379,
                    "targetPitch": -14.284348257130379,
                    "sceneId": "bin2",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 128.48328214248227,
                        "targetYaw": 128.48328214248227,
                        "pitch": -14.284348257130379,
                        "targetPitch": -14.284348257130379,
                        "sceneId": "bin2",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -179.11274880571932,
                    "targetYaw": -179.11274880571932,
                    "pitch": -19.666866732732025,
                    "targetPitch": -19.666866732732025,
                    "sceneId": "bin5",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -179.11274880571932,
                        "targetYaw": -179.11274880571932,
                        "pitch": -19.666866732732025,
                        "targetPitch": -19.666866732732025,
                        "sceneId": "bin5",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -0.2927694644129133,
                    "targetYaw": -0.2927694644129133,
                    "pitch": -16.366226642360562,
                    "targetPitch": -16.366226642360562,
                    "sceneId": "bin6",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.2927694644129133,
                        "targetYaw": -0.2927694644129133,
                        "pitch": -16.366226642360562,
                        "targetPitch": -16.366226642360562,
                        "sceneId": "bin6",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -18.969293854867942,
                    "targetYaw": -18.969293854867942,
                    "pitch": -10.802809131957797,
                    "targetPitch": -10.802809131957797,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -18.969293854867942,
                        "targetYaw": -18.969293854867942,
                        "pitch": -10.802809131957797,
                        "targetPitch": -10.802809131957797,
                        "type": "product",
                        "productSlug": "taste-of-home-pq-500ml-kk07",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -79.43902137792537,
                    "targetYaw": -79.43902137792537,
                    "pitch": -31.275853394530348,
                    "targetPitch": -31.275853394530348,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -79.43902137792537,
                        "targetYaw": -79.43902137792537,
                        "pitch": -31.275853394530348,
                        "targetPitch": -31.275853394530348,
                        "type": "product",
                        "productSlug": "kinh-ky-500ml-kk08",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -165.68405788992226,
                    "targetYaw": -165.68405788992226,
                    "pitch": -11.668849064363526,
                    "targetPitch": -11.668849064363526,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -165.68405788992226,
                        "targetYaw": -165.68405788992226,
                        "pitch": -11.668849064363526,
                        "targetPitch": -11.668849064363526,
                        "type": "product",
                        "productSlug": "delicate-cutting-kk01",
                        "sceneId": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5$~VtRfkjYj]V[f6j[ofj[azjtj]fkjtfkfPayj[ayj[jYofj[ofazj[jtfQfkj[j[ayj[fQoe",
                "path": "/assets/pano/nuocmam/bin4/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin4/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin5": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin5.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": 0.04750315295245855,
                    "targetYaw": 0.04750315295245855,
                    "pitch": -15.523410465606416,
                    "targetPitch": -15.523410465606416,
                    "sceneId": "bin4",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 0.04750315295245855,
                        "targetYaw": 0.04750315295245855,
                        "pitch": -15.523410465606416,
                        "targetPitch": -15.523410465606416,
                        "sceneId": "bin4",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -0.691187576944003,
                    "targetYaw": -0.691187576944003,
                    "pitch": -8.454624374209597,
                    "targetPitch": -8.454624374209597,
                    "sceneId": "bin6",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": -0.691187576944003,
                        "targetYaw": -0.691187576944003,
                        "pitch": -8.454624374209597,
                        "targetPitch": -8.454624374209597,
                        "sceneId": "bin6",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -125.18423617389033,
                    "targetYaw": -125.18423617389033,
                    "pitch": -33.18002301321757,
                    "targetPitch": -33.18002301321757,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -125.18423617389033,
                        "targetYaw": -125.18423617389033,
                        "pitch": -33.18002301321757,
                        "targetPitch": -33.18002301321757,
                        "type": "product",
                        "productSlug": "delicate-cutting-kk01",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -22.73118197172994,
                    "targetYaw": -22.73118197172994,
                    "pitch": -12.7212085952401,
                    "targetPitch": -12.7212085952401,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -22.73118197172994,
                        "targetYaw": -22.73118197172994,
                        "pitch": -12.7212085952401,
                        "targetPitch": -12.7212085952401,
                        "type": "product",
                        "productSlug": "kinh-ky-500ml-kk08",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -11.550596704521581,
                    "targetYaw": -11.550596704521581,
                    "pitch": -5.7354718911547256,
                    "targetPitch": -5.7354718911547256,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -11.550596704521581,
                        "targetYaw": -11.550596704521581,
                        "pitch": -5.7354718911547256,
                        "targetPitch": -5.7354718911547256,
                        "type": "product",
                        "productSlug": "taste-of-home-pq-500ml-kk07",
                        "sceneId": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5#~VtRWCofa}aej[offkbHfkkCofazayayfQayj@fRj?oJkCofofWBofjufkj[fkayofj[ofof",
                "path": "/assets/pano/nuocmam/bin5/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin5/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        },
        "bin6": {
            "autoRotate": 0,
            "type": "multires",
            "panorama": "/img_source/bin6.png",
            "compass": true,
            "hfov": 100.0,
            "hotSpots": [
                {
                    "yaw": 179.40510123508318,
                    "targetYaw": 179.40510123508318,
                    "pitch": -17.69652465981006,
                    "targetPitch": -17.69652465981006,
                    "sceneId": "bin4",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.40510123508318,
                        "targetYaw": 179.40510123508318,
                        "pitch": -17.69652465981006,
                        "targetPitch": -17.69652465981006,
                        "sceneId": "bin4",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 179.26728353134519,
                    "targetYaw": 179.26728353134519,
                    "pitch": -7.371834312833622,
                    "targetPitch": -7.371834312833622,
                    "sceneId": "bin5",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 179.26728353134519,
                        "targetYaw": 179.26728353134519,
                        "pitch": -7.371834312833622,
                        "targetPitch": -7.371834312833622,
                        "sceneId": "bin5",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 124.3417518618885,
                    "targetYaw": 124.3417518618885,
                    "pitch": -16.630304438996404,
                    "targetPitch": -16.630304438996404,
                    "sceneId": "bin3",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 124.3417518618885,
                        "targetYaw": 124.3417518618885,
                        "pitch": -16.630304438996404,
                        "targetPitch": -16.630304438996404,
                        "sceneId": "bin3",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": 143.65970712032237,
                    "targetYaw": 143.65970712032237,
                    "pitch": -10.765120083157015,
                    "targetPitch": -10.765120083157015,
                    "sceneId": "bin2",
                    "type": "scene-custom",
                    "createTooltipArgs": {
                        "yaw": 143.65970712032237,
                        "targetYaw": 143.65970712032237,
                        "pitch": -10.765120083157015,
                        "targetPitch": -10.765120083157015,
                        "sceneId": "bin2",
                        "type": "scene-custom",
                        "productSlug": ""
                    }
                },
                {
                    "yaw": -52.55270471586363,
                    "targetYaw": -52.55270471586363,
                    "pitch": -23.149677566451004,
                    "targetPitch": -23.149677566451004,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -52.55270471586363,
                        "targetYaw": -52.55270471586363,
                        "pitch": -23.149677566451004,
                        "targetPitch": -23.149677566451004,
                        "type": "product",
                        "productSlug": "taste-of-home-pq-500ml-kk07",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -153.52553004284596,
                    "targetYaw": -153.52553004284596,
                    "pitch": -14.613411940757436,
                    "targetPitch": -14.613411940757436,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -153.52553004284596,
                        "targetYaw": -153.52553004284596,
                        "pitch": -14.613411940757436,
                        "targetPitch": -14.613411940757436,
                        "type": "product",
                        "productSlug": "kinh-ky-500ml-kk08",
                        "sceneId": ""
                    }
                },
                {
                    "yaw": -171.3274909300365,
                    "targetYaw": -171.3274909300365,
                    "pitch": -6.534011155334434,
                    "targetPitch": -6.534011155334434,
                    "type": "product",
                    "createTooltipArgs": {
                        "yaw": -171.3274909300365,
                        "targetYaw": -171.3274909300365,
                        "pitch": -6.534011155334434,
                        "targetPitch": -6.534011155334434,
                        "type": "product",
                        "productSlug": "delicate-cutting-kk01",
                        "sceneId": ""
                    }
                }
            ],
            "pitch": 0,
            "showControls": true,
            "yaw": 0,
            "multiRes": {
                "shtHash": "5x~VtRt7jsfkRjaxaxj[j[ayf6azofayj[azj[ofazbHjYoyWBkCofofj[j[a}ayoLWBj[afof",
                "path": "/assets/pano/nuocmam/bin6/%l/%s%y_%x",
                "fallbackPath": "/assets/pano/nuocmam/bin6/fallback/%s",
                "extension": "jpg",
                "tileResolution": 512,
                "maxLevel": 3,
                "cubeResolution": 1296
            }
        }
    }
}