import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';

declare function star_space(): any;

@Component({
  selector: 'app-world',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
  templateUrl: './world.component.html',
  styleUrl: './world.component.css',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1})) 
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0})) 
      ])
    ])
  ]
})
export class WorldComponent {
  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) {}

  public indexStates: Array<string> = [
    'VN',
    'KR',
    'IN',
    'CN',
    'JP',
    'AU',
    'ID',
    'TH',
    'PL',
    'DE',
    'GB',
    'US',
  ];

  public currentStateName?: string;
  public currentStateCode?: string;
  public hoverStateCode?: string;
  public hoverStateName?: string;
  public hoverFlagUrl?: string;
  private resizewait?: ReturnType<typeof setTimeout>;
  private scrollLeftStateTime: number = 0;
  private scrollLeftStateTimeRun: boolean = false;
  private scrollRightStateTime: number = 0;
  private scrollRightStateTimeRun: boolean = false;

  @ViewChild('containerMapSvg') containerMapElement?: ElementRef<HTMLElement>;
  @ViewChild('mapSvg') mapElement?: ElementRef<SVGElement>;
  @ViewChild('bgMapSvg') bgMapElement?: ElementRef<SVGElement>;
  @ViewChild('tooltipState') tooltipState?: ElementRef<HTMLElement>;

  public onStateScroll(ev: any) {
    console.log(ev);
  }

  public scrollByLeftState() {
    if (this.scrollLeftStateTimeRun) {
      return;
    }
    let el = document.getElementById("state-scroll") as HTMLElement;

    this.scrollLeftStateTimeRun = true;
    if (this.scrollLeftStateTime <= 0) {
      this.scrollLeftStateTime = el.clientWidth / 3;
    }
    const px: number = 20;
    el.scrollBy(-px, 0);
    this.scrollLeftStateTime -= px;

    if (this.scrollLeftStateTime > 0) {
      setTimeout(() => {
        this.scrollLeftStateTimeRun = false;
        this.scrollByLeftState();
      }, 20);
    } 
    else {
      this.scrollLeftStateTimeRun = false;
    }
  }

  public scrollByRightState() {
    if (this.scrollRightStateTimeRun) {
      return;
    }
    let el = document.getElementById("state-scroll") as HTMLElement;
    this.scrollRightStateTimeRun = true;
    if (this.scrollRightStateTime <= 0) {
      this.scrollRightStateTime = el.clientWidth / 3;
    }
    const px: number = 20;
    el.scrollBy(px, 0);
    this.scrollRightStateTime -= px;

    if (this.scrollRightStateTime > 0) {
      setTimeout(() => {
        this.scrollRightStateTimeRun = false;
        this.scrollByRightState();
      }, 20);
    } 
    else {
      this.scrollRightStateTimeRun = false;
    }
  }

  public onResize(ev?: Event) {
    this.clearToolTip();
    clearTimeout(this.resizewait);
    this.resizewait = setTimeout(() => {
      const a2 = this.mapElement?.nativeElement.clientHeight ?? 0;
      const a4 = this.containerMapElement?.nativeElement.clientHeight ?? 0;
      let ot = (a4 - a2 - 16) / 2;
      if (ot < 64) {
        ot = 64;
      }
      this.mapElement!.nativeElement.style.top = `${ot}px`;
      this.bgMapElement!.nativeElement.style.top = `${ot}px`;
      
    }, 350);
  }

  private clearToolTip() {
    this.hoverStateCode = undefined;
  }

  public clickState(stateCode: string) {
    this.clearToolTip();
    const stateName = stateNames[stateCode];
    if (!stateName || !stateCode) {
      return;
    }
    this.currentStateCode = stateCode;
    this.currentStateName = stateName;
    // const dialogRef = this.dialog.open(DialogStateInfoComponent, {
    //   panelClass: ['animate__animated','animate__slideInLeft'],
    //   // hasBackdrop: false,
    //   disableClose: true,
    //   maxHeight: '100%',
    //   maxWidth: '100%',
    //   position: {
    //     left: '0px',
    //   },
    //   data: {
    //     stateCode: stateCode,
    //     stateName: stateName,
    //   }
    // });
    // const scr = dialogRef.afterClosed().subscribe((value) => {
    //   this.clearToolTip();
    //   this.currentStateCode = undefined;
    //   this.currentStateName = undefined;
    //   if(value) {
    //     this.router.navigateByUrl(value);
    //   }
    //   scr.unsubscribe();
    // })
  }

  public ngAfterViewInit() {
    this.mapElement?.nativeElement.addEventListener('mousemove', (ev) => {
      // const stateCode = (ev.target as SVGElement).classList.value.toUpperCase().replaceAll('SM_STATE SM_STATE_'.toUpperCase(), '');
      const stateCode = (ev.target as SVGElement).id.toUpperCase();
      this.hoverStateCode = stateCode;
      if (!this.hoverStateCode || !stateNames[this.hoverStateCode]) {
        this.hoverStateCode = undefined;
      }
      if (!stateCode) return;
      this.hoverStateName = stateNames[stateCode];
      this.hoverFlagUrl = `/assets/images/country-flags-main/svg/${stateCode.toLocaleLowerCase()}.svg`;
      let left = ev.clientX - 29;
      let top = ev.clientY - 6 - (this.tooltipState?.nativeElement.clientHeight ?? 0);
      // if (left + 120 > (this.containerMapElement?.nativeElement.clientWidth ?? 0)) {
      //   left = (this.containerMapElement?.nativeElement.clientWidth ?? 0) - 180;  
      // }
      this.tooltipState?.nativeElement.style.setProperty('left', `${left}px`);
      this.tooltipState?.nativeElement.style.setProperty('top', `${top}px`);
    });
    this.bgMapElement?.nativeElement.addEventListener('click', (ev) => {
      const svgElement = ev.target;
      let stateCode = (ev.target as SVGElement).id.toUpperCase();
      let f: HTMLElement | null | undefined = svgElement as HTMLElement;
      while (f != null && f != undefined) {
        if (f.tagName.toLowerCase() == 'g') {
          let idf = f.id.toUpperCase();
          if (stateNames[idf]) {
            stateCode = idf;
          }
        }
        f = f?.parentElement;
      }
      if (!stateCode) return;
      this.clickState(stateCode);
    });
    this.mapElement?.nativeElement.addEventListener('click', (ev) => {
      // const stateCode = (ev.target as SVGElement).classList.value.toUpperCase().replaceAll('SM_STATE SM_STATE_'.toUpperCase(), '');
      const stateCode = (ev.target as SVGElement).id.toUpperCase();
      if (!stateCode) return;
      this.clickState(stateCode);
    });
    this.onResize(undefined);
    star_space();
  }

  public getStateName(code: string) {
    return stateNames[code];
  }

  public openState(){
    if(this.currentStateCode) {
      this.router.navigateByUrl(`/home/${this.currentStateCode}`);
    }
    // this.closeDialogState();
  }

  public closeDialogState() {
    this.clearToolTip();
    this.currentStateCode = undefined;
    this.currentStateName = undefined;
  }

}

const stateNames: any = {
  AF: 'Afghanistan',
  AO: 'Angola',
  AL: 'Albania',
  AE: 'United Arab Emirates',
  AR: 'Argentina',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BI: 'Burundi',
  BE: 'Belgium',
  BJ: 'Benin',
  BF: 'Burkina Faso',
  BD: 'Bangladesh',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BA: 'Bosnia and Herzegovina',
  BY: 'Belarus',
  BZ: 'Belize',
  BO: 'Bolivia',
  BR: 'Brazil',
  BN: 'Brunei Darussalam',
  BT: 'Bhutan',
  BW: 'Botswana',
  CF: 'Central African Republic',
  CA: 'Canada',
  CH: 'Switzerland',
  CL: 'Chile',
  CN: 'China',
  CI: 'Côte d\'Ivoire',
  CM: 'Cameroon',
  CD: 'Democratic Republic of the Congo',
  CG: 'Republic of Congo',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EG: 'Egypt',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GE: 'Georgia',
  GH: 'Ghana',
  GN: 'Guinea',
  GM: 'The Gambia',
  GW: 'Guinea-Bissau',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GL: 'Greenland',
  GT: 'Guatemala',
  GY: 'Guyana',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IN: 'India',
  IE: 'Ireland',
  IR: 'Iran',
  IQ: 'Iraq',
  IS: 'Iceland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KR: 'Republic of Korea',
  XK: 'Kosovo',
  KW: 'Kuwait',
  LA: 'Lao PDR',
  LB: 'Lebanon',
  LR: 'Liberia',
  LY: 'Libya',
  LK: 'Sri Lanka',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MA: 'Morocco',
  MD: 'Moldova',
  MG: 'Madagascar',
  MX: 'Mexico',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  ME: 'Montenegro',
  MN: 'Mongolia',
  MZ: 'Mozambique',
  MR: 'Mauritania',
  MW: 'Malawi',
  MY: 'Malaysia',
  NA: 'Namibia',
  NE: 'Niger',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NZ: 'New Zealand',
  OM: 'Oman',
  PK: 'Pakistan',
  PA: 'Panama',
  PE: 'Peru',
  PH: 'Philippines',
  PG: 'Papua New Guinea',
  PL: 'Poland',
  KP: 'Dem. Rep. Korea',
  PT: 'Portugal',
  PY: 'Paraguay',
  PS: 'Palestine',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  EH: 'Western Sahara',
  SA: 'Saudi Arabia',
  SD: 'Sudan',
  SS: 'South Sudan',
  SN: 'Senegal',
  SL: 'Sierra Leone',
  SV: 'El Salvador',
  RS: 'Serbia',
  SR: 'Suriname',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SE: 'Sweden',
  SZ: 'Swaziland',
  SY: 'Syria',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TM: 'Turkmenistan',
  TL: 'Timor-Leste',
  TN: 'Tunisia',
  TR: 'Turkey',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UG: 'Uganda',
  UA: 'Ukraine',
  UY: 'Uruguay',
  US: 'United States',
  UZ: 'Uzbekistan',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  YE: 'Yemen',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  SO: 'Somalia',
  GF: 'France',
  FR: 'France',
  ES: 'Spain',
  AW: 'Aruba',
  AI: 'Anguilla',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  BS: 'Bahamas',
  BM: 'Bermuda',
  BB: 'Barbados',
  KM: 'Comoros',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  DM: 'Dominica',
  FK: 'Falkland Islands',
  FO: 'Faeroe Islands',
  GD: 'Grenada',
  HK: 'Hong Kong',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  MF: 'Saint Martin (French)',
  MV: 'Maldives',
  MT: 'Malta',
  MS: 'Montserrat',
  MU: 'Mauritius',
  NC: 'New Caledonia',
  NR: 'Nauru',
  PN: 'Pitcairn Islands',
  PR: 'Puerto Rico',
  PF: 'French Polynesia',
  SG: 'Singapore',
  SB: 'Solomon Islands',
  ST: 'São Tomé and Principe',
  SX: 'Saint Martin (Dutch)',
  SC: 'Seychelles',
  TC: 'Turks and Caicos Islands',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  VC: 'Saint Vincent and the Grenadines',
  VG: 'British Virgin Islands',
  VI: 'United States Virgin Islands',
  CY: 'Cyprus',
  RE: 'Reunion (France)',
  YT: 'Mayotte (France)',
  MQ: 'Martinique (France)',
  GP: 'Guadeloupe (France)',
  CW: 'Curaco (Netherlands)',
  IC: 'Canary Islands (Spain)',
}