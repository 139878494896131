import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PanoComponent } from './pano/pano.component';

import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [PanoComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  public async ngOnInit() {
    const firebaseConfig = {
      apiKey: "AIzaSyAR0t1_ECIJ40o7RR8qK5rcbJYHy0cjQko",
      authDomain: "d-store-9d943.firebaseapp.com",
      projectId: "d-store-9d943",
      storageBucket: "d-store-9d943.appspot.com",
      messagingSenderId: "444494588339",
      appId: "1:444494588339:web:24a5c43d7f6613e51a83c6",
      measurementId: "G-X4XNNQZ5R7"
    };
    
    const app = initializeApp(firebaseConfig);
    if (await isSupported()) {
      const analytics = getAnalytics(app);
      analytics.app.automaticDataCollectionEnabled = true;
    }
  }
}
