<div class="card">
    <h2 class="card-header mb-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div>Scenes</div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body">
        <div class="row">
            <div class="col col-12 mb-3">
                <div class="input-group w-100">
                    <label class="input-group-text" for="scene-id">Scene ID</label>
                    <input [formControl]="inputSceneId" type="text"class="form-control" id="scene-id" [matAutocomplete]="autoSceneId">
                    <mat-autocomplete autoActiveFirstOption #autoSceneId="matAutocomplete">
                        @for (option of sceneIds | async; track option) {
                          <mat-option [value]="option">{{option}}</mat-option>
                        }
                    </mat-autocomplete>
                </div>
            </div>
            <div class="col col-12 m-0 p-0 mb-2">
                <hr class="p-0 m-0"/>
            </div>
            <div class="col col-6 mb-0 mt-2">
                <button (click)="gotoScene()" class="btn btn-primary w-100">Goto</button>
            </div>
            <div class="col col-6 mb-0 mt-2">
                <button (click)="addScene()" class="btn btn-primary w-100">Add</button>
            </div>
        </div>
    </div>
</div>