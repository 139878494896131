{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5U~qM_a}oIfkaet6ayaxfRWBaxayj[a}WCf6a#j[ayaytRozoeWCj@ofWBayj[j[jrazf6oLbH",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}