import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CdsServices } from '../logics/cds-services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterLink } from '@angular/router';
import { HeaderComponent } from '../world/header/header.component';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, } from "firebase/auth";
import { AuraService } from '../../services/aura_services';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [FontAwesomeModule, ReactiveFormsModule, FormsModule, CommonModule, ToastModule, MatProgressSpinnerModule, HeaderComponent, RouterLink],
  providers: [MessageService, CdsServices, AuraService],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent {
  constructor(
    private messageService: MessageService,
    // private cdsService: CdsServices,
    private router: Router,
    private auraService: AuraService,
  ){}
  public faFacebookF = faFacebookF
  public faGoogle = faGoogle;
  public isLoading = false;
  private firebaseAuth = getAuth();
  private googleAuthProvider = new GoogleAuthProvider();
  private facebookAuthProvider = new FacebookAuthProvider();

  // public formLogin = new FormGroup({
  //   inputEmail: new FormControl,
  //   inputPassworld: new FormControl,
  // });
  public formLogin = {
    inputPhone: '',
    inputPassworld: '',
    isRememberMe: false,
  };
  public validInputPhone: string | undefined = undefined;
  public validInputPassworld: string | undefined = undefined;

  
  public ngOnInit() {
    let uphonermm = localStorage.getItem('uphone_rmm') || localStorage.getItem('cache_uphone')
    this.formLogin.inputPhone = uphonermm || '';

    localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
  }

  public checkValidEmail(value: Event | undefined) {
    if (this.formLogin.inputPhone.trim() == '') {
      this.validInputPhone = 'Vui lòng nhập số điện thoại!';
    }
    else if (!RegExp('^[0-9\-\+]{9,15}$').test(this.formLogin.inputPhone)) {
      this.validInputPhone = 'Số điện thoại không hợp lệ!';
    }
    else {
      this.validInputPhone = '';
    }
    localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
  }
  public checkValidPassworld(value: Event | undefined) {
    if (this.formLogin.inputPassworld.trim() == '') {
      this.validInputPassworld = 'Vui lòng nhập mật khẩu!';
    }
    else {
      this.validInputPassworld = '';
    }
  }

  public validAll() {
    this.formLogin.inputPhone ||= '';
    this.formLogin.inputPassworld ||= '';
    this.checkValidEmail(undefined);
    this.checkValidPassworld(undefined);
    return !(this.validInputPhone || this.validInputPassworld)
  }

  public async btnSignInPhone() {
    let isValid = this.validAll();
    if (!isValid) {
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Có lỗi.', 
        detail: this.validInputPhone || this.validInputPassworld || 'Số điện thoại hoặc mật khẩu chưa đúng!', 
      });
      return;
    }
    try {
      this.isLoading = true;
      await this.auraService.signIn(this.formLogin.inputPhone, this.formLogin.inputPassworld);
      // const result: any = await this.cdsService.postSignIn(this.formLogin.inputPhone, this.formLogin.inputPassworld);
      // localStorage.setItem('utoken', `Bearer ${result.token}`)
      // if (this.formLogin.isRememberMe) {
      //   localStorage.setItem('uphone_rmm', `${this.formLogin.inputPhone}`);
      // }
      // else {
      //   localStorage.removeItem('uphone_rmm');
      // }
      // localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
      this.router.navigateByUrl('/');
    } catch (e) {
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Có lỗi.', 
        detail: 'Số điện thoại hoặc mật khẩu chưa đúng!', 
      });
    } finally {
      this.isLoading = false;
    }
  }

  public btnSignInGoogle() {
    signInWithPopup(this.firebaseAuth, this.googleAuthProvider).then((userCredential) => {
      const credential = GoogleAuthProvider.credentialFromResult(userCredential);
      const accessToken = credential?.accessToken;
      const user = userCredential.user;
      console.log(accessToken, user.displayName, user.phoneNumber, user.email)
    });
  }

  public btnSignInFacebook() {
    signInWithPopup(this.firebaseAuth, this.facebookAuthProvider).then((userCredential) => {
      const credential = FacebookAuthProvider.credentialFromResult(userCredential);
      const accessToken = credential?.accessToken;
      const user = userCredential.user;
      console.log(accessToken, user.displayName, user.phoneNumber, user.email)
    });
  }

}
