import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface DialogExportConfigData {
  panoViewer: any;
}

@Component({
  selector: 'app-dialog-export-config',
  standalone: true,
  imports: [],
  templateUrl: './dialog-export-config.component.html',
  styleUrl: './dialog-export-config.component.css'
})
export class DialogExportConfigComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogExportConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogExportConfigData,
    private sanitizer: DomSanitizer,
  ) {
    const originConfig = JSON.parse(JSON.stringify(this.data.panoViewer.getConfig()));
    const config: any = {};
    config.default = originConfig.default;
    config.default.yaw = this.data.panoViewer.getYaw();
    config.default.pitch = this.data.panoViewer.getPitch();
    config.default.firstScene = this.data.panoViewer.getScene();
    config.scenes = {...originConfig.scenes};
    // config.scenes[config.default.firstScene] = originConfig.scenes[config.default.firstScene];

    for (const ks in config.scenes) {
      const vs = config.scenes[ks];
      for (const kh in vs.hotSpots) {
        config.scenes[ks].hotSpots[kh].div = {};
        
        // const vh = vs.hotSpots[kh]
        // if (vh.createTooltipArgs?.sceneId && !Object.keys(config.scenes).includes(vh.createTooltipArgs.sceneId)) {
        //   config.scenes[vh.createTooltipArgs.sceneId] = originConfig.scenes[vh.createTooltipArgs.sceneId];
        // }
      }
    }
    var contentFile = JSON.stringify(config);

    // create download link
    var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(contentFile));
    this.linkConfigFile = uri;

    // create link auto download and download
    var blob = new Blob([contentFile], {type: "application/json"});
    var urlBlob  = URL.createObjectURL(blob);
    const a = document.createElement('a')
    a.href = urlBlob;
    a.download = 'megaverse-config.json'
    a.click()
  }

  public linkConfigFile: SafeUrl;

  public close(): void {
    this.dialogRef.close(false);
  }
}
