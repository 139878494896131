import { Component } from '@angular/core';
import { HeaderComponent } from '../world/header/header.component';
import { ToastModule } from 'primeng/toast';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { CdsServices } from '../logics/cds-services';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { error } from 'node:console';
import { AuraService } from '../../services/aura_services';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [HeaderComponent, ToastModule, FontAwesomeModule, CommonModule, ReactiveFormsModule, FormsModule, RouterLink],
  providers: [MessageService, CdsServices, AuraService],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})
export class SignUpComponent {
  constructor(
    private messageService: MessageService,
    private auraService: AuraService,
    private router: Router,
  ){}
  public faFacebookF = faFacebookF
  public faGoogle = faGoogle;
  public isLoading = false;
  // public formLogin = new FormGroup({
  //   inputEmail: new FormControl,
  //   inputPassworld: new FormControl,
  // });
  public formLogin = {
    inputName: '',
    inputPhone: '',
    inputEmail: '',
    inputPassworld: '',
    inputRePasworld: '',
  };
  public validInputName: string | undefined = undefined;
  public validInputPhone: string | undefined = undefined;
  public validInputEmail: string | undefined = undefined;
  public validInputPassworld: string | undefined = undefined;
  public validInputRePassworld: string | undefined = undefined;
  
  public ngOnInit() {
    let uphonermm = localStorage.getItem('uphone_rmm') || localStorage.getItem('cache_uphone')
    this.formLogin.inputPhone = uphonermm || '';

    localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
  }

  public checkValidName(value: Event | undefined) {
    if (this.formLogin.inputName.trim() == '') {
      this.validInputName = 'Vui lòng nhập họ và tên!';
    }
    else {
      this.validInputName = '';
    }
  }
  public checkValidPhone(value: Event | undefined) {
    if (this.formLogin.inputPhone.trim() == '') {
      this.validInputPhone = 'Vui lòng nhập số điện thoại!';
    }
    else if (!RegExp('^[0-9\-\+]{9,15}$').test(this.formLogin.inputPhone)) {
      this.validInputPhone = 'Số điện thoại không đúng định dạng!';
    }
    else {
      this.validInputPhone = '';
    }
    localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
  }
  public checkValidEmail(value: Event | undefined) {
    if (this.formLogin.inputEmail.trim() == '') {
      this.validInputEmail = 'Vui lòng nhập địa chỉ email!';
    }
    else if (!RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(this.formLogin.inputEmail)) {
      this.validInputEmail = 'Địa chỉ email không đúng định dạng!';
    }
    else {
      this.validInputEmail = '';
    }
  }
  public checkValidPassworld(value: Event | undefined) {
    if (this.formLogin.inputPassworld.trim() == '') {
      this.validInputPassworld = 'Vui lòng nhập mật khẩu!';
    }
    else if (this.formLogin.inputPassworld.trim().length < 6) {
      this.validInputPassworld = 'Mật khẩu ít nhất 6 ký tự!';
    }
    else {
      this.validInputPassworld = '';
    }

    this.checkValidRePassworld(undefined);
  }
  public checkValidRePassworld(value: Event | undefined) {
    if (this.formLogin.inputRePasworld.trim() == '') {
      this.validInputRePassworld = 'Vui lòng nhập lại mật khẩu!';
    }
    else if (this.formLogin.inputRePasworld != this.formLogin.inputPassworld) {
      this.validInputRePassworld = 'Mật khẩu không khớp!';
    }
    else {
      this.validInputRePassworld = '';
    }
  }

  public validAll() {
    this.formLogin.inputPhone ||= '';
    this.formLogin.inputPassworld ||= '';
    this.checkValidName(undefined);
    this.checkValidPhone(undefined);
    this.checkValidEmail(undefined);
    this.checkValidPassworld(undefined);
    this.checkValidRePassworld(undefined);
    return !(this.validInputPhone || this.validInputPassworld || this.validInputRePassworld || this.validInputEmail || this.validInputName)
  }

  public async btnLogin() {

    let isValid = this.validAll();
    if (!isValid) {
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Có lỗi.', 
        detail: this.validInputPhone || this.validInputPassworld || this.validInputRePassworld || 'Nội dung nhập chưa đầy đủ hoặc sai sót!', 
      });
      return;
    }
    try {
      this.isLoading = true;
      const result: any = await this.auraService.signUp(
        this.formLogin.inputPhone, 
        this.formLogin.inputPassworld,
        this.formLogin.inputName,
        this.formLogin.inputEmail,
      );
      // localStorage.setItem('utoken', `Bearer ${result.token}`)
      // localStorage.setItem('cache_uphone', `${this.formLogin.inputPhone}`);
      this.router.navigateByUrl('/');
    } catch (e: any) {
      console.log(e);
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Có lỗi.', 
        detail: e?.error.errors?.username || e?.error.errors?.email || e?.error.errors?.password || '', 
      });
    } finally {
      this.isLoading = false;
    }
  }
}
