import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RiveCanvas, RiveLinearAnimation } from 'ng-rive';

@Component({
  selector: 'app-new-world',
  standalone: true,
  imports: [
    CommonModule,
    RiveCanvas,
    RiveLinearAnimation,
  ],
  templateUrl: './new-world.component.html',
  styleUrl: './new-world.component.css'
})
export class NewWorldComponent {
  public onAfterViewInit() {
  }
}
