<p-toast></p-toast>

<div class="position-absolute p-3 pb-1 bg-dark" style="z-index: 3; left: 0; right: 0;">
  <app-header/>
</div>

<section style="background-color: #555; min-height: 100%; padding-top: 120px;">
  <div class="container-fluid h-custom" style="background-color: #555; height: 100%;">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src="/assets/images/signin_panner.png" class="img-fluid p-5"
          alt="Sample image">
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p class="lead fw-normal mb-0 me-3 text-white">Đăng nhập với: </p>
            <button (click)="btnSignInFacebook()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faFacebookF" />
            </button>
            <button (click)="btnSignInGoogle()" type="button" class="btn btn-outline-danger btn-floating mx-2" style="width: 2.5em; height: 2.5em;">
              <fa-icon [icon]="faGoogle" />
            </button>
          </div>

          <div class="divider d-flex align-items-center my-4">
            <p class="text-center fw-bold mx-3 mb-0 text-white">Hoặc</p>
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3">Số điện thoại:</label>
            <input type="tel" id="form3Example3" class="form-control {{validInputPhone == '' ? 'is-valid' : validInputPhone == undefined ? '' : 'is-invalid'}}" (change)="checkValidEmail($event)" placeholder="Nhập số điện thoại" [(ngModel)]="formLogin.inputPhone" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3" class="invalid-feedback" [innerText]="validInputPhone"></div>
          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example4">Mật khẩu:</label>
            <input type="password" id="form3Example4" class="form-control {{validInputPassworld == '' ? 'is-valid' : validInputPassworld == undefined ? '' : 'is-invalid'}}" (change)="checkValidPassworld($event)" placeholder="Nhập mật khẩu" [(ngModel)]="formLogin.inputPassworld" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example4" class="invalid-feedback" [innerText]="validInputPassworld"></div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2 text-white" [(ngModel)]="formLogin.isRememberMe" type="checkbox" value="" id="form2Example3"  [ngModelOptions]="{standalone: true}"/>
              <label class="form-check-label text-white" for="form2Example3">
                Nhớ mật khẩu
              </label>
            </div>
            <a class="text-body"><span class="text-white">Quên mật khẩu?</span></a>
          </div>

          <div class="text-center text-lg-start mt-3 pt-2">
            <button type="button" class="btn btn-danger" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="btnSignInPhone()" [disabled]="isLoading">
              Đăng nhập
            </button>
            <p class="small fw-bold mt-3 pt-1 mb-3 text-white">Chưa có tài khoản? <a  class="link-danger" routerLink="/signup"><span style="color: #f99;">Đăng ký</span></a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>