<div class="card">
    <h2 class="card-header mb-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="text-warning">
                <b class="bi bi-exclamation-triangle-fill"></b>&nbsp;
                {{data.title}}
            </div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body">
        <div class="row">
            <div class="col col-12 mb-2">
                <h3 class="mb-2">{{data.content}}</h3>
            </div>
            <div class="col col-12 m-0 p-0 mb-2">
                <hr class="p-0 m-0"/>
            </div>
            <div class="col"></div>
            <div class="col col-3 mb-0 mt-2">
                <button class="btn btn-primary w-100"(click)="confirm()">OK</button>
            </div>
        </div>
    </div>
</div>