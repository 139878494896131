<style>
    label {
        width: 7em;
    }
</style>

<div class="card">
    <h2 class="card-header mb-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div>Hot Spots</div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body">
        <div class="row">
            <div class="col col-12 mb-3">
                <div class="input-group w-100">
                    <label class="input-group-text" for="scene-id">Type</label>
                    <input [formControl]="inputTypeId" type="text"class="form-control" id="scene-id" [matAutocomplete]="autoTypeId">
                    <mat-autocomplete autoActiveFirstOption #autoTypeId="matAutocomplete">
                        @for (option of typeIds | async; track option) {
                          <mat-option [value]="option">{{option}}</mat-option>
                        }
                    </mat-autocomplete>
                </div>
            </div>
            <div *ngIf="inputTypeId.value == 'scene-custom' || inputTypeId.value == 'scene-break'" class="col col-12 mb-3">
                <div class="input-group w-100">
                    <label class="input-group-text" for="scene-id">Scene ID</label>
                    <input [formControl]="inputSceneId" type="text"class="form-control" id="scene-id" [matAutocomplete]="autoSceneId">
                    <mat-autocomplete autoActiveFirstOption #autoSceneId="matAutocomplete">
                        @for (option of sceneIds | async; track option) {
                          <mat-option [value]="option">{{option}}</mat-option>
                        }
                    </mat-autocomplete>
                </div>
            </div>
            <div *ngIf="inputTypeId.value == 'product'" class="col col-12 mb-3">
                <div class="input-group w-100">
                    <label class="input-group-text" for="product-slug">Product Slug</label>
                    <input [formControl]="inputProductSlug" type="text"class="form-control" id="product-slug" [matAutocomplete]="productSlug">
                    <mat-autocomplete autoActiveFirstOption #productSlug="matAutocomplete">
                        @for (option of productSlugs | async; track option) {
                          <mat-option [value]="option">{{option}}</mat-option>
                        }
                    </mat-autocomplete>
                </div>
            </div>
            <div class="col col-12 m-0 p-0 mb-2">
                <hr class="p-0 m-0"/>
            </div>
            <div class="col col-6 mb-0 mt-2">
            </div>
            <div class="col col-6 mb-0 mt-2">
                <button (click)="addHotSpot()" class="btn btn-primary w-100">Add</button>
            </div>
        </div>
    </div>
</div>