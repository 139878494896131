{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5$~qM{ofV[ofj[ayfij[WBWBfjfQayfRayj[ayj[ayj[ofogaxj[ayj[fif7ayj[ayj[ayj[fQ",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}