import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { faFileShield } from "@fortawesome/free-solid-svg-icons";
import { lastValueFrom } from "rxjs";

declare var localStorage: any;

@Injectable()
export class AuraService {
    constructor(
        private http: HttpClient,
    ) { };

    private getToken(): string {
        return localStorage.getItem('aura_access_token') || '';
    }
    private setToken(token: string) {
        localStorage.setItem('aura_access_token', token);
    }

    public async isLogin(): Promise<boolean> {
        try {
            const profileInfo = await this.profile();
            return true;
        }
        catch {
            return false;
        }
    }

    public async profile(): Promise<any> {
        const raw = JSON.stringify({
            'Authorization': this.getToken(),
        });

        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/profile', {
            body: raw,
        });
        const rsp = await lastValueFrom(rspO);
        return rsp;
    }

    public async signIn(phone: string, password: string) {
        const raw = JSON.stringify({
            "username": phone,
            "password": password,
        });

        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/login', {
            body: raw,
        });

        const rsp = await lastValueFrom(rspO);

        this.setToken(JSON.parse(JSON.stringify(rsp)).access_token)

        return rsp;
    }

    public async signUp(phone: string, password: string, fullName: string, email: string) {
        const raw = JSON.stringify({
            "username": phone,
            "password": password,
            "full_name": fullName,
            "email": email,
        });

        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/signup', {
            body: raw,
        });

        const rsp = await lastValueFrom(rspO);

        this.setToken(JSON.parse(JSON.stringify(rsp)).access_token)

        return rsp;
    }

    public async startPayment() {
        const raw = JSON.stringify({
            "course_code": "3D-LMS",
            'Authorization': this.getToken(),
        });


        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/register-course', {
            body: raw,
        });
        const rsp = await lastValueFrom(rspO);
        return rsp;
    }

    public async notiStartPayment(price: string, name: string, phone: string, email: string, content: string, ref: string) {
        var today  = new Date();
        const contentNotifi = encodeURIComponent(`
            # Attention (${today.getHours()}:${today.getMinutes()}:${today.getSeconds()} - ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()})
            The customer confirms the transfer:
            *. Price: ${price}
            *. Fullname: ${name} 
            *. Phone: ${phone} 
            *. Email: ${email}
            *. Content: ${content}
            *. REF: ${ref}
        `.replaceAll('#', '\\#')
        .replaceAll('.', '\\.')
        .replaceAll('*', '\\*')
        .replaceAll('-', '\\-')
        .replaceAll('(', '\\(')
        .replaceAll(')', '\\)'));
        const notifiLink = `https://api.telegram.org/bot7428316708:AAE-_v2Xic7RWtjPlaOtE2jfvW9317TcP58/sendMessage?chat_id=@f03f4a6042a94c61941d830d2ec1&parse_mode=MarkdownV2&text=${contentNotifi}`;
        const rsp1 = this.http.request('GET', notifiLink);
        await lastValueFrom(rsp1);
    }


    public async updatePayment(status: boolean = false, price: number = 0, ref: string = '') {
        const raw = JSON.stringify({
            "course_code": "3D-LMS",
            "status": status ? '1' : '0',
            "price": price,
            "promotion_code": ref,
            'Authorization': this.getToken(),
        });
        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/update-payment', {
            body: raw,
        });
        const rsp = await lastValueFrom(rspO);
        return rsp;
    }
    public async getLession(): Promise<any> {
        const raw = JSON.stringify({
            "course_code": "3D-LMS",
            'Authorization': this.getToken(),
        });
        const rspO = this.http.request('POST', 'https://demo.capitalx.vn/api/lessons-course', {
            body: raw,
        });
        const rsp = await lastValueFrom(rspO);
        return rsp;
    }

}