{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5$~VtRfkjYj]V[f6j[ofj[azjtj]fkjtfkfPayj[ayj[jYofj[ofazj[jtfQfkj[j[ayj[fQoe",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 3,
        "cubeResolution": 1296
    }
}