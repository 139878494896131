{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5@~pWBaxofj[ayj[fQf6j[ayayj[ayj[fQj[j[ayayazayayf6fQj[f6j[a|ayazj[ayayazfP",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}