<div (click)="back($event)" style="width: 100%; height: 100%; position: relative;">

    <div (click)="clickContent($event)" class="d-block d-md-none bg-dark p-4 shadow-sm rounded" style="
    max-width: 96%;
    max-height: 100%;
    top: 24px;
    right: 24px;
    left: 24px;
    bottom: 24px;
    position: absolute;
    overflow-y: auto;
    color: white;">
    <h1 class="mt-4">Chọn gói thành viên</h1>
    Các gói thành viên đều đảm bảo chất lượng.

    <div class="bg-danger mt-3" style="width: 3em; height: 0.5em;"></div>
    <div class="mt-3 w-100">
        <h2 class="mb-1">Mã giới thiệu</h2>
        <input [(ngModel)]="prefCode" type="text" class="form-control" placeholder="Nhập mã giới thiệu để được giảm học phí..." aria-label="Mã giới thiệu">
    </div>

    <div id="table-price" class="mt-5" style="position: relative;">
        <div class="row" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: -1;">
            <div class="col rounded" style="transition: 300ms;" [style.backgroundColor]="indexSelected == 0 ? '#fff2' : ''"></div>
            <div class="col rounded" style="transition: 300ms;" [style.backgroundColor]="indexSelected == 1 ? '#fff2' : ''"></div>
        </div>
        <div class="row" style="height: 8em;">
            <div class="col py-3" style="text-align: center; position: relative;">
                <div style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -ms-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                    ">
                    <h2>Miễn phí</h2>
                    <button (click)="indexSelected = 0" [innerText]="indexSelected == 0 ? 'Đã chọn' : 'Chọn'" [ngClass]="indexSelected == 0 ? 'btn btn-danger' : 'btn btn-outline-danger'" style="transition: 300ms;">
                    </button>
                </div>
            </div >
            <div class="col py-3" style="text-align: center; display: inline-block; position: relative;">
                <div style="position: absolute; left: 0; right: 0; top: -10%; text-align: center;">
                    <div class="px-2" style="background-color: white; border-radius: 1080px; font-weight: 500; color: black; font-size: 0.8em; width: fit-content; margin: 0 auto;">
                        Best value
                    </div>
                </div>
                <div style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -ms-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                    ">
                    <h2>Premium</h2>
                    <button (click)="indexSelected = 1" [innerText]="indexSelected == 1 ? 'Đã chọn' : 'Chọn'" [ngClass]="indexSelected == 1 ? 'btn btn-danger' : 'btn btn-outline-danger'" style="transition: 300ms;">
                    </button>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12" style="position: relative;">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Học phí</span>
                </div>
            </div>
            <div class="col text-center">
                <div class="text-success">
                    Lớp trải nghiệm
                    <br/>
                    miễn phí
                </div>
                <br/>
                <b [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Miễn phí
                </b>
            </div>
            <div class="col text-center">
                <div class="text-success">
                    Voucher giới hạn
                    <br/>
                    giảm 40% học phí
                </div>
                <del class="text-secondary">10,000,000đ</del>
                <br/>
                <b [ngClass]="(indexSelected == 1 && prefCode.length <= 3) ? '' : 'text-secondary'" [style.textDecoration]="prefCode.length > 2 ? 'line-through' : ''" style="transition: 300ms;">6,000,000đ</b>
                <br/>
                <b *ngIf="prefCode.length > 3" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    5,000,000đ
                </b>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Thời gian học</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                90 ngày
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                90 ngày
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Nội dung</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                Nội dung cơ bản
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                Tất cả nội dung
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Giảm giá mô hình kinh doanh</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                Không
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                10%
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Giảm giá IPO Master</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                Không
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                5%
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Tham gia chia sẻ Offline</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                Không
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                Có
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-2" style="font-weight: 500;">
                    <span class="bg-dark py-1 px-2 rounded-pill">Giảm giá 50% khi đăng ký học lại</span>
                </div>
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                Không
            </div>
            <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                Có
            </div>
        </div>
        <div class="py-2"></div>
    </div>
    <button (click)="regist($event)" class="btn btn-danger mt-4 w-100" style="padding: 0.8em 0;">
        Đăng ký
    </button>
</div>

    <div (click)="clickContent($event)" class="d-none d-md-block bg-dark p-4 shadow-sm rounded" style="
        max-width: 90%;
        width: auto;
        min-width: 47rem;
        max-height: 100%;
        height: auto;
        right: 5%;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: white;">
        <h1>Chọn gói thành viên</h1>
        Các gói thành viên đều đảm bảo chất lượng.

        <div id="table-price" style="position: relative;">
            <div class="row" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: -1;">
                <div class="col-6"></div>
                <div class="col rounded" style="transition: 300ms;" [style.backgroundColor]="indexSelected == 0 ? '#fff2' : ''"></div>
                <div class="col rounded" style="transition: 300ms;" [style.backgroundColor]="indexSelected == 1 ? '#fff2' : ''"></div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="bg-danger mt-3" style="width: 3em; height: 0.5em;"></div>
                    <div class="mt-3 w-100">
                        <h2 class="mb-1">Mã giới thiệu</h2>
                        <input [(ngModel)]="prefCode" type="text" class="form-control" placeholder="Nhập mã giới thiệu để được giảm học phí..." aria-label="Mã giới thiệu">
                    </div>
                </div>
                <div class="col py-3" style="text-align: center; position: relative;">
                    <div style="
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -ms-transform: translateX(-50%) translateY(-50%);
                            transform: translateX(-50%) translateY(-50%);
                        ">
                        <h2>Miễn phí</h2>
                        <button (click)="indexSelected = 0" [innerText]="indexSelected == 0 ? 'Đã chọn' : 'Chọn'" [ngClass]="indexSelected == 0 ? 'btn btn-danger' : 'btn btn-outline-danger'" style="transition: 300ms;">
                        </button>
                    </div>
                </div >
                <div class="col py-3" style="text-align: center; display: inline-block; position: relative;">
                    <div style="position: absolute; left: 0; right: 0; top: -10%; text-align: center;">
                        <div class="px-2" style="background-color: white; border-radius: 1080px; font-weight: 500; color: black; font-size: 0.8em; width: fit-content; margin: 0 auto;">
                            Best value
                        </div>
                    </div>
                    <div style="
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -ms-transform: translateX(-50%) translateY(-50%);
                            transform: translateX(-50%) translateY(-50%);
                        ">
                        <h2>Premium</h2>
                        <button (click)="indexSelected = 1" [innerText]="indexSelected == 1 ? 'Đã chọn' : 'Chọn'" [ngClass]="indexSelected == 1 ? 'btn btn-danger' : 'btn btn-outline-danger'" style="transition: 300ms;">
                        </button>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6" style="position: relative;">
                    <div style="
                        position: absolute;
                        top: 50%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        ">
                        Học phí
                    </div>
                </div>
                <div class="col text-center">
                    <div class="text-success">
                        Lớp trải nghiệm
                        <br/>
                        miễn phí
                    </div>
                    <br/>
                    <b [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                        Miễn phí
                    </b>
                </div>
                <div class="col text-center">
                    <div class="text-success">
                        Voucher giới hạn
                        <br/>
                        giảm 40% học phí
                    </div>
                    <del class="text-secondary">10,000,000đ</del>
                    <br/>
                    <b [ngClass]="(indexSelected == 1 && refs.includes(prefCode.toUpperCase())) ? '' : 'text-secondary'" [style.textDecoration]="prefCode.length > 2 ? 'line-through' : ''" style="transition: 300ms;">6,600,000đ</b>
                    <br/>
                    <b *ngIf="refs.includes(prefCode.toUpperCase())" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                        5,500,000đ
                    </b>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Thời gian học
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    90 ngày
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    90 ngày
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Nội dung
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Nội dung cơ bản
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Tất cả nội dung
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Giảm giá mô hình kinh doanh
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Không
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    10%
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Giảm giá IPO Master
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Không
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    5%
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Tham gia chia sẻ Offline
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Không
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Có
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-6">
                    Giảm giá 50% khi đăng ký học lại
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 0 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Không
                </div>
                <div class="col text-center" [ngClass]="indexSelected == 1 ? '' : 'text-secondary'" style="transition: 300ms;">
                    Có
                </div>
            </div>
            <div class="py-2"></div>
        </div>

        <button (click)="regist($event)" class="btn btn-danger mt-4 w-100" style="padding: 0.8em 0;">
            Đăng ký
        </button>
    </div>
</div>
