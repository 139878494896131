import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogPrimaryData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-dialog-primary',
  standalone: true,
  imports: [],
  templateUrl: './dialog-primary.component.html',
  styleUrl: './dialog-primary.component.css'
})
export class DialogPrimaryComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogPrimaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPrimaryData,
  ) { }

  public close(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }
  
}
