import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogConfirmInfoData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-dialog-confirm-info',
  standalone: true,
  imports: [],
  templateUrl: './dialog-confirm-info.component.html',
  styleUrl: './dialog-confirm-info.component.css'
})
export class DialogConfirmInfoComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogConfirmInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmInfoData,
  ) {}

  public close(): void {
    this.dialogRef.close(false);
  }
  public confirm(): void {
    this.dialogRef.close(true);
  }
}
