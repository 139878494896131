{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5-~WWBt7offRj?ayj[WBf6ayoeWBf6WCofayazfQj[j[j[ofjZayj[azWBfkj[fkj[ayf6j[j[",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}