{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5=~Waej]j[WBayj[oLjuj[ayayayWBaya|j[j[j[j[ayazazj[ayjtj[ofj[aya|fkayj[f6ay",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}