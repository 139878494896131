{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5=~Wf6kCayWCWCofj[j[j[ayayfPWBjtazj[j[ofayayofj?j@oLayfPWBj[j[ayoLayayj[j@",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}