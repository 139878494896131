<div class="card position-relative" style="max-width: 40rem; min-width: 40vw; max-height: 100%;">
    <h2 class="card-header mb-0 position-absolute bg-white" style="left: 0; top: 0; right: 0; z-index: 4;">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="text-info">
                <i class="bi bi-geo-alt"></i>&nbsp;
                Update shipping address
            </div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body position-relative" style="overflow-y: auto; max-height: 100vh;">
        <div class="my-5"></div>
        <div class="my-5"></div>
        <div class="my-5"></div>
        <div class="row h-100 w-100 m-0 p-0">
            <div class="col-12 mb-3">
                <div>
                    <form>
                        <div class="form-group">
                            <label for="Phone">Phone</label>
                            <input type="tel" class="form-control" id="phone"aria-describedby="phone" placeholder="Enter phone">
                        </div>
                        <div class="form-group mt-3">
                            <label for="fullname">Fullname</label>
                            <input type="text" class="form-control" id="fullname" placeholder="Enter fullname">
                        </div>
                        <div class="form-group mt-3">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter email">
                        </div>
                        <div class="form-group mt-3">
                            <label for="city">City</label>
                            <input type="text" class="form-control" id="city" placeholder="Enter city">
                        </div>
                        <div class="form-group mt-3">
                            <label for="district">District</label>
                            <input type="text" class="form-control" id="district" placeholder="Enter district">
                        </div>
                        <div class="form-group mt-3">
                            <label for="ward">Ward</label>
                            <input type="text" class="form-control" id="ward" placeholder="Enter ward">
                        </div>
                        <div class="form-group mt-3">
                            <label for="street">Street address</label>
                            <input type="text" class="form-control" id="street" placeholder="Enter street address">
                        </div>
                        <div class="form-group mt-3">
                            <label for="type">Address Type</label>
                            <input type="text" class="form-control" id="type" placeholder="Enter address type">
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-6"> </div>
            <div class="col-6">
                <button class="w-100 btn btn-primary">Submit</button>
            </div>
        </div>
    </div>
</div>