{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5-~qM{fQt7a|t7s:ayofj[WBfQj[fQjuWBj[WVfQf6fQbbjZj[t7jtayozj[j[fQWBfQjZjtjt",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}