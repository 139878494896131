<div class="card bg-dark">
    <h2 class="card-header mb-0 text-danger">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div>{{data.title}}</div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body text-white">
        <div class="row">
            <div class="col col-12 mb-2">
                <h3 class="mb-2">{{data.content}}</h3>
            </div>
            <div class="col col-12 m-0 p-0 mb-2">
                <hr class="p-0 m-0" style="color: #fff0;"/>
            </div>
            <div class="col col-6 mb-0 mt-2">
                <button class="btn btn-outline-danger w-100" (click)="close()">Trở lại</button>
            </div>
            <div class="col col-6 mb-0 mt-2">
                <button class="btn btn-danger w-100" (click)="confirm()">Xác nhận</button>
            </div>
        </div>
    </div>
</div>
