import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

@Injectable()
export class CdsServices {
    constructor(
        private httpClient: HttpClient,
    ) {}
    private domain: string = 'https://api-demo.dsp.one';
    private storeToken = '226b116857c2788c685c66bf601222b56bdc3751b4f44b944361e84b2b1f002b'

    public authorization() {
        return localStorage.getItem('utoken') || `Bearer ${this.storeToken}`
    }

    public headers() {
        return {
            'Authorization': this.authorization(),
        };
    }

    public async postSignIn(phone: string, passworld: string) {
        const url = `${this.domain}/client/auth/login`
        const request = this.httpClient.post(url, {
            'phone': phone,
            'password': passworld,
            'group_id': 144,
            'store_token': this.storeToken,
        }, {
            headers: this.headers(),
        });
        const result = await lastValueFrom(request);
        return result;
    }

    public async postSignUp(phone: string, passworld: string, name: string, email: string) {
        const url = `${this.domain}/client/auth/register`
        const request = this.httpClient.post(url, {
            "name": name,
            "phone": phone,
            "password": passworld,
            "confirm_password": passworld,
            "firstname": null,
            "lastname": null,
            "verify_otp": 0,
            "store_token": this.storeToken,
            "email": email,
            "birthday": "",
            "accept_the_terms": 1,
            "receive_promotion": 1
        }, {
            headers: this.headers(),
        });
        const result = await lastValueFrom(request);
        return result;
    }
}