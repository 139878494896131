<p-toast></p-toast>

<div class="p-3 pb-1 bg-dark" style="z-index: 3; left: 0; right: 0;">
  <app-header/>
</div>

<section class="vh-100" style="background-color: #555;">
  <div class="container-fluid h-custom" style="background-color: #555;">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src="/assets/images/signin_panner.png" class="img-fluid p-5" alt="Sample image">
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p class="lead fw-normal mb-0 me-3 text-white">Quản lý tài khoản</p>
          </div>

          <div class="divider d-flex align-items-center my-4">
            <!-- <p class="text-center fw-bold mx-3 mb-0"></p> -->
          </div>

          <!-- Phone input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example2">Họ và tên:</label>
            <input readonly="readonly" type="text" id="form3Example2" class="form-control {{validInputName == '' ? 'is-valid' : validInputName == undefined ? '' : 'is-invalid'}}" (change)="checkValidName($event)" placeholder="Nhập tên bạn" [(ngModel)]="formLogin.inputName" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example2" class="invalid-feedback" [innerText]="validInputName"></div>
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3x">Địa chỉ Email:</label>
            <input readonly="readonly" type="email" id="form3Example3x" class="form-control {{validInputEmail == '' ? 'is-valid' : validInputEmail == undefined ? '' : 'is-invalid'}}" (change)="checkValidEmail($event)" placeholder="Nhập địa chỉ email" [(ngModel)]="formLogin.inputEmail" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3x" class="invalid-feedback" [innerText]="validInputEmail"></div>
          </div>

          <!-- Phone input -->
          <div class="form-outline mb-4">
            <label class="form-label fw-bold mb-1 text-white" for="form3Example3">Số điện thoại:</label>
            <input readonly="readonly" type="tel" id="form3Example3" class="form-control {{validInputPhone == '' ? 'is-valid' : validInputPhone == undefined ? '' : 'is-invalid'}}" (change)="checkValidPhone($event)" placeholder="Nhập số điện thoại" [(ngModel)]="formLogin.inputPhone" [ngModelOptions]="{standalone: true}" />
            <div for="form3Example3" class="invalid-feedback" [innerText]="validInputPhone"></div>
          </div>

          <div class="text-center text-lg-start mt-3 pt-2">
            <button type="button" class="btn btn-danger" style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="btnLogin()" [disabled]="isLoading">
              Trở về
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>