<div class="card">
    <h2 class="card-header mb-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div>Import config</div>
            <button class="btn btn-secondary close" type="button" (click)="close()"><b>&times;</b></button>
        </div>
    </h2>
    <div class="card-body">
        <div class="row">
            <div class="col col-12 mb-1">
                <div class="alert alert-warning" role="alert">
                    <b class="bi bi-exclamation-triangle-fill"></b>&nbsp;
                    After importing, the current configuration will be lost. Please export and back up before proceeding, and be aware of what may happen!
                </div>
            </div>
            <div class="col col-12 mb-1">
                <div class="alert alert-warning" role="alert">
                    <b class="bi bi-exclamation-triangle-fill"></b>&nbsp;
                    After importing, if there is a file error, the content will be affected. Please check the file and refresh the page!
                </div>
            </div>
            <div class="col col-12 mb-3">
                <div class="input-group w-100">
                    <input (change)="selectFile($event)" (click)="clearInputFileImport()" onclick="this.value=null" type="file" accept=".json,application/json" class="form-control" id="inputFileImport">
                    <label class="input-group-text" for="inputFileImport">JSON</label>
                </div>
            </div>
            <div class="col col-12 m-0 p-0 mb-2">
                <hr class="p-0 m-0"/>
            </div>
            <div class="col col mb-0 mt-2">
            </div>
            <div class="col col-4 mb-0 mt-2">
                <button class="btn btn-primary w-100" (click)="import()" [disabled]="!jsonFile">Import</button>
            </div>
        </div>
    </div>
</div>