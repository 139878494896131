{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5?~WWBj[offQayj[ayf6azayfQj[azayayfkayjtayjtoeofj@ayayfkaefPayj[jtj[ayfQay",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}