import { Routes } from '@angular/router';
import { PanoComponent } from './pano/pano.component';
import { WorldComponent } from './world/world.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NewWorldComponent } from './new-world/new-world.component';
import { PaymentComponent } from './payment/payment.component';
import { ProfileComponent } from './profile/profile.component';

export const routes: Routes = [
    {path: 'home/:homeCode', component: PanoComponent},
    {path: 'login', component: SignInComponent},
    {path: 'signup', component: SignUpComponent},
    {path: 'newworld', component: NewWorldComponent},
    {path: 'world', component: WorldComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'payment/:code', component: PaymentComponent},
    // {path: '',  component: PaymentComponent},
    {path: '',  component: PanoComponent},
];
