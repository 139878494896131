{
    "hfov": 100.0,
    "type": "multires",
    "multiRes": {
        "shtHash": "5O~Bt7xYkCs.s:WCRkn$oLaes:WBa#WCWBkCjsoeWBWCf7WCWCoLazWCa|a|ayj[jtoLfQj[j[",
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 5,
        "cubeResolution": 5208
    }
}